/* global _ */
/* global dataLayer */

// ==================== ACCOUNT DETAILS COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AccountFocus = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        POP_UP_SELECTOR: 'js-popup',
        ACCOUNT_SELECTOR: 'js-account',
        ACCOUNT_FOCUS_MODAL_SELECTOR: 'js-set-account-focus',
        CLOSE_ACCOUNT_FOCUS: 'js-close-account-focus',
        PAGE_LOADING: 'js-page-loading',
        PAGE_LOADING_HIDDEN: 'page-loading--hidden',
        HIDDEN_CLASS: 'hidden',
        HERO_GREETING_CLASS: 'js-hero-greeting',
        QUESTION_MARK: '?',
        AMPERSAND: '&',
        ACCOUNT: 'account='
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AccountFocus = function ($container) {
        /**
         * PRIVATE METHODS
         */
        var $accounts,
            $accountFocusModal,
            $popup,
            $closeAccountFocusBtn,
            $pageLoading,
            _serviceUrl,
            _pageId;

        /**
         * Make service call for the selected account
         * @param {Event} event Click or null
         */
        var selectAccount = function (event) {
            event && event.preventDefault();
            var target =  event ? event.currentTarget : $accounts[0];

            var params = {
                Maid: target.dataset.maid,
                PageId: _pageId
            };

            // If just closing the modal send additional param so BE sets the 
            // required cookie not to show it again on the same session
            if (!event) {
                params.isClosingModal = true;
            }

            params = JSON.stringify(params);

            query.postData(_serviceUrl, successSetAccount, errorSetAccount, params, true);

            //Tagging
            dataLayer.push({
                selectedAccountImage: target.dataset.accountImage
            });
        };

        var successSetAccount = function (data) {
            if (coned.utils.isPatternLab()) {
                // Set noRedirect to true to test close button or esc keypress.
                // Set noRedirect to false to test redirects.
                query.getData(coned.plConstants.GET_ACCOUNT_FOCUS, setAccount, function () {});
            } else {
                setAccount(data);
            }
        };

        var setAccount = function (data) {
            var queryString = window.location.search,
                $maid = data.maid;

            //Hide modal
            $accountFocusModal.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (data.noRedirect) {
                return;
            }

            if ($pageLoading) {
                if (!coned.utils.isPatternLab()) {
                    $pageLoading.classList.remove(CONSTANTS.PAGE_LOADING_HIDDEN);
                }
            }

            if (data) {
                if (data.redirectUrl && !coned.utils.isPatternLab()) {
                    window.location.href = data.redirectUrl + window.location.search.replace(CONSTANTS.QUESTION_MARK, CONSTANTS.AMPERSAND);
                } else {
                    if (queryString === '') {
                        window.location.href = window.location.href + CONSTANTS.QUESTION_MARK + CONSTANTS.ACCOUNT + $maid;
                    } else {
                        window.location.href = window.location.href + CONSTANTS.QUESTION_MARK + window.location.search.replace(CONSTANTS.QUESTION_MARK, CONSTANTS.AMPERSAND) + CONSTANTS.AMPERSAND + CONSTANTS.ACCOUNT + $maid;
                    }
                }
            }         
        };

        var errorSetAccount = function () {
            //Errors not expected
        };

        var closeModal = function (event) {
            event.preventDefault();

            selectAccount(null);

            //Tagging
            dataLayer.push({
                event: 'account.focus.cancel'
            });
        };

        var initializeData = function () {
            $popup = document.getElementsByClassName(CONSTANTS.POP_UP_SELECTOR)[0];
            $accountFocusModal = document.getElementsByClassName(
                CONSTANTS.ACCOUNT_FOCUS_MODAL_SELECTOR
            )[0];
            $accounts = $container.getElementsByClassName(CONSTANTS.ACCOUNT_SELECTOR);
            $closeAccountFocusBtn = $accountFocusModal.getElementsByClassName(CONSTANTS.CLOSE_ACCOUNT_FOCUS)[0];
            $pageLoading = document.getElementsByClassName(CONSTANTS.PAGE_LOADING)[0];
            _serviceUrl = $container.dataset.serviceUrl;
            _pageId = $container.dataset.pageId;
        };

        var initializeEvents = function () {
            _.each($accounts, function ($account) {
                coned.utils.addGeneralListeners($account, selectAccount);
            });

            new coned.utils.addFocusTrap($accountFocusModal);

            $popup.addEventListener('keydown', function (event) {
                if (event.keyCode == 27) {
                    $closeAccountFocusBtn.click();
                }
            });

            $closeAccountFocusBtn.addEventListener('click', function (event) {
                closeModal(event);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AccountFocus.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AccountFocus;
})();
