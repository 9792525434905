// ==================== DASHBOARD CONCERN COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardConcern = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ACCOUNT_MAID: 'account-maid',
        ENROLL_FORM_SELECTOR: 'js-concern-enroll-form',
        MANAGE_FORM_SELECTOR: 'js-concern-manage-form',
        ERROR_FORM_SELECTOR: 'js-concern-error-form',
        SECONDARY_CONTACT_RADIO: 'js-secondary-contact-radio',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        LINK_DESCRIPTION: 'js-link-description',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        ASSISTANCE_PROGRAM_ID: 'assistanceProgramsSection',
        DEEP_LINK_TARGET_ID: 'data-deep-link-id',
        ICON_STATUS_TEXT: 'icon-status-text',
        HIDDEN_CLASS: 'hidden',
        CHECKBOX_SELECTOR: 'js-checkbox-selector',
        SERVICE_ERROR: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        FORM_SELECTOR: 'js-concern-form',
        DATA_ENROLLED: 'data-enrolled',
        SCID: 'ScId',
        ELDERLY_CHECKBOX: 'age62',
        BLIND_CHECKBOX: 'blind',
        DISABILITY_CHECKBOX: 'permanentlyDisabled',
        SECONDARY_CONTACT_YES: 'js-secondary-contact-yes',
        SECONDARY_CONTACT_FORM: 'js-secondary-contact-form',
        SECONDARY_CONTACT_FIRST_NAME: 'secondaryContactFirstName',
        SECONDARY_CONTACT_LAST_NAME: 'secondaryContactLastName',
        SECONDARY_CONTACT_ADDRESS: 'secondaryContactAddress',
        SECONDARY_CONTACT_APARTMENT: 'secondaryContactApartment',
        SECONDARY_CONTACT_CITY: 'secondaryContactCity',
        SECONDARY_CONTACT_STATE: 'secondaryContactState',
        SECONDARY_CONTACT_ZIPCODE: 'secondaryContactZipCode',
        CONCERN_PARAMETER_NAME: 'concern',

        // Lazy Load
        LAZY_LOAD_CLASS: 'js-lazy-load',
        LAZY_LOAD_TAB_CLASS: 'js-lazy-load-manage-my-account',
        LAZY_LOAD_LOADED_CLASS: 'js-lazy-load-element-loaded'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardConcern = function ($accordionElement) {
        /**
         * PRIVATE METHODS
         */
        var $enrollFormSelector,
            $manageFormSelector,
            $errorFormSelector,
            $linkAccordionSelector,
            $accordionSelector,
            $header,
            $formLoading,
            $secondaryContactRadioSelector,
            $secondaryContactForm,
            $lazyLoadTab,
            $checkBoxesSelector,
            $iconStatusTextSpan,
            _isLazyLoad,
            _hasSecondaryContact,
            _isEligible;

        var onSubmitForm = function () {
            var serviceUrl = $accordionElement.dataset.serviceUrl;

            var params = {
                Maid: document.getElementById(CONSTANTS.ACCOUNT_MAID).value,
                ScId: query.getFormInputValue($enrollFormSelector, CONSTANTS.SCID),
                Elderly: query.getCheckboxInputValueById(
                    $enrollFormSelector,
                    CONSTANTS.ELDERLY_CHECKBOX
                ),
                Blind: query.getCheckboxInputValueById(
                    $enrollFormSelector,
                    CONSTANTS.BLIND_CHECKBOX
                ),
                Disability: query.getCheckboxInputValueById(
                    $enrollFormSelector,
                    CONSTANTS.DISABILITY_CHECKBOX
                ),
                OptIn: _hasSecondaryContact
            };

            if (_hasSecondaryContact) {
                params.FirstName = query.getFormInputValue(
                    $secondaryContactForm,
                    CONSTANTS.SECONDARY_CONTACT_FIRST_NAME
                );
                params.LastName = query.getFormInputValue(
                    $secondaryContactForm,
                    CONSTANTS.SECONDARY_CONTACT_LAST_NAME
                );
                params.Address = query.getFormInputValue(
                    $secondaryContactForm,
                    CONSTANTS.SECONDARY_CONTACT_ADDRESS
                );
                params.City = query.getFormInputValue(
                    $secondaryContactForm,
                    CONSTANTS.SECONDARY_CONTACT_CITY
                );
                params.State = query.getFormInputValue(
                    $secondaryContactForm,
                    CONSTANTS.SECONDARY_CONTACT_STATE
                );
                params.ZipCode = query.getFormInputValue(
                    $secondaryContactForm,
                    CONSTANTS.SECONDARY_CONTACT_ZIPCODE
                );
            }

            // Service Call
            params = JSON.stringify(params);
            query.postData(serviceUrl, successCallback, errorCallback, params, true, $formLoading);
        };

        var successCallback = function () {
            $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $manageFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $accordionElement.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
            $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                CONSTANTS.DATA_ENROLLED
            );
            $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
            query.scrollToElement($manageFormSelector, $header);
            $manageFormSelector.focus();
        };

        var errorCallback = function () {
            $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $errorFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            query.scrollToElement($errorFormSelector, $header);
            $errorFormSelector.focus();
        };

        var showSecondaryContactForm = function (event) {
            var $target = event.target;

            if (query.hasClass($target, CONSTANTS.SECONDARY_CONTACT_YES)) {
                $secondaryContactForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                _hasSecondaryContact = true;
                dataLayer.push({
                    'support-status': 'Added secondary contact'
                });
            } else {
                $secondaryContactForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                _hasSecondaryContact = false;
                dataLayer.push({
                    'support-status': 'No added secondary contact'
                });
            }
        };

        var addCheckboxTagging = function () {
            var checkBoxesValues = '';

            _.each($checkBoxesSelector, function ($checkbox) {
                if ($checkbox.checked) {
                    checkBoxesValues = checkBoxesValues + $checkbox.id + '/';
                }
            });

            dataLayer.push({
                condition: checkBoxesValues
            });
        };

        var initializeData = function () {
            $enrollFormSelector = $accordionElement.getElementsByClassName(
                CONSTANTS.ENROLL_FORM_SELECTOR
            )[0];
            $manageFormSelector = $accordionElement.getElementsByClassName(
                CONSTANTS.MANAGE_FORM_SELECTOR
            )[0];
            $errorFormSelector = $accordionElement.getElementsByClassName(
                CONSTANTS.ERROR_FORM_SELECTOR
            )[0];
            $linkAccordionSelector = $accordionElement.getElementsByClassName(
                CONSTANTS.LINK_ACCORDION_SELECTOR
            )[0];
            $accordionSelector = $accordionElement.getElementsByClassName(
                CONSTANTS.ACCORDION_SELECTOR
            )[0];
            $secondaryContactRadioSelector = $accordionElement.getElementsByClassName(
                CONSTANTS.SECONDARY_CONTACT_RADIO
            );
            $secondaryContactForm = $accordionElement.getElementsByClassName(
                CONSTANTS.SECONDARY_CONTACT_FORM
            )[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];
            $checkBoxesSelector = $accordionElement.getElementsByClassName(
                CONSTANTS.CHECKBOX_SELECTOR
            );
            $iconStatusTextSpan = $accordionElement.getElementsByClassName(
                CONSTANTS.ICON_STATUS_TEXT
            )[0];
            _hasSecondaryContact = true;
            _isEligible = $accordionSelector.dataset.isEligible === 'true' ? true : false;
        };

        var initializeEvents = function () {
            if (!_isEligible && !query.hasClass($accordionElement, CONSTANTS.LAZY_LOAD_CLASS)) {
                $manageFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $accordionElement.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_ENROLLED
                );
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.manage;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
            }

            if (_isEligible) {
                // form validation
                new coned.components.ValidateForm(
                    '.' + CONSTANTS.ENROLL_FORM_SELECTOR,
                    onSubmitForm
                );

                _.each($secondaryContactRadioSelector, function ($secondaryContactRadio) {
                    coned.utils.addGeneralListeners(
                        $secondaryContactRadio,
                        showSecondaryContactForm
                    );
                });

                _.each($checkBoxesSelector, function ($checkbox) {
                    coned.utils.addGeneralListeners($checkbox, addCheckboxTagging);
                });

                dataLayer.push({
                    'support-status': 'Added secondary contact'
                });
            }
        };

        // Loads all module data from service
        var loadDataServiceCall = function () {
            if (isLoaded) return;

            var serviceUrl = $accordionElement.dataset.serviceUrl,
                params;

            if (!serviceUrl) {
                errorLoadDataServiceCall();
                return;
            }

            query.getData(serviceUrl, successLoadDataServiceCall, errorLoadDataServiceCall, params);
        };

        // If call succeeded, load markup into module
        var successLoadDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {

                // SCENARIO #1: Concern enroll form
                // SCENARIO #2: Concern enrolled message
                // SCENARIO #3: Concern pending enroll
                // SCENARIO #4: Concern error 
                var scenario = coned.utils.getUrlParameterValue(CONSTANTS.CONCERN_PARAMETER_NAME) ? 
                                coned.utils.getUrlParameterValue(CONSTANTS.CONCERN_PARAMETER_NAME) :
                                coned.constants.DEFAULT_SCENARIO;

                var scenarioHTML = coned.plConstants.GET_CONCERN_SCENARIO_HTML_URL + scenario + coned.constants.HTML_EXTENSION;
                    query.getData(
                        scenarioHTML,
                        setContentMarkup,
                        errorLoadDataServiceCall
                    );
            } else {
                setContentMarkup(data);
            }
        };

        // If call errored, hide module
        var errorLoadDataServiceCall = function () {
            $accordionElement.classList.add(CONSTANTS.HIDDEN_CLASS);

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $accordionElement.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($accordionElement, 'lazy-load-element-loaded');
            }
        };

        var setContentMarkup = function (data) {
            if (data) {
                var parser = new DOMParser(),
                    HTMLObject,
                    $concernFormContent,
                    newConcernAttributes;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $concernFormContent = HTMLObject.getElementsByClassName(CONSTANTS.FORM_SELECTOR);

                if (
                    $concernFormContent.length !== 0 &&
                    $concernFormContent[0].dataset.content !== 'false'
                ) {
                    $concernFormContent = $concernFormContent[0];
                } else {
                    errorLoadDataServiceCall();
                    return;
                }

                $accordionElement.innerHTML = $concernFormContent.innerHTML;
                newConcernAttributes = $concernFormContent.attributes;

                while ($accordionElement.attributes.length > 0) {
                    $accordionElement.removeAttribute($accordionElement.attributes[0].name);
                }

                _.each(newConcernAttributes, function (attribute) {
                    $accordionElement.setAttribute(attribute.nodeName, attribute.nodeValue);
                });

                // If data-module is present, initialize modules
                if ($accordionElement.dataset.module) {
                    initializeData();
                    initializeEvents();
                    coned.utils.initializeModules($accordionElement);
                }
            } else {
                errorLoadDataServiceCall();
                return;
            }

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $accordionElement.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($accordionElement, 'lazy-load-element-loaded');
            }

            coned.utils.triggerEvent(window, 'service-loaded');
        };

        // Checks if module should be lazy-loaded of not
        var preInitialize = function () {
            _isLazyLoad = query.hasClass($accordionElement, CONSTANTS.LAZY_LOAD_CLASS);

            // If module is lazy loaded, wait for event to trigger
            if (_isLazyLoad) {
                $lazyLoadTab = document.getElementsByClassName(CONSTANTS.LAZY_LOAD_TAB_CLASS)[0];

                $lazyLoadTab.addEventListener('lazy-load-start', loadDataServiceCall);
            } else {
                initializeData();
                initializeEvents();
                isLoaded = true;
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            preInitialize();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardConcern.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardConcern;
})();
