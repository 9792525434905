// ==================== PAGINATION_SIMPLE_INFORMATION =========================

var coned = coned || {};
coned.components = coned.components || {};

/**
 * @returns the init function to start the module
 */
coned.components.PaginationSimpleInformation = (function () {
    var isLoaded = false;

    /**
     * @typedef {Object} PaginationState
     * @property {boolean} [isSeted]
     * @property {number} [totalItems]
     * @property {number} [itemsPerPage]
     * @property {number} [currentPage]
     * @property {number} [totalPages]
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        VALUES: {
            /**
             * @type {''}
             */
            EMPTY: '',
            /**
             * @type {'display'}
             */
            DISPLAY_KEY: 'display',
            /**
             * @type {'empty'}
             */
            EMPTY_KEY: 'empty'
        }
    }
    /**
     * @constructor
     * @param {HTMLElement} $container
     * @returns {}
     */
    var PaginationSimpleInformation = function ($container) {
        /**
         * PRIVATE VARIABLES
         */
        /**
         * @type {PaginationState}
         */
        var _paginationState,
            /**
             * @type {Record<string, string>}
             */
            _formatValues;

        /**
         * PRIVATE METHODS
         */

        var changeHTML = function () {
            if (!_paginationState.isSeted) {
                $container.innerHTML = CONSTANTS.VALUES.EMPTY;
            }
            else if (_paginationState.totalItems && _paginationState.currentPage && _paginationState.itemsPerPage) {
                var end = (_paginationState.currentPage * _paginationState.itemsPerPage),
                    start = end - _paginationState.itemsPerPage + 1,
                    total = _paginationState.totalItems;
                if (end > total) {
                    end = total;
                }
                if (start < 1) {
                    start = 1;
                }
                $container.innerHTML = coned.utils.formatString(
                    _formatValues[CONSTANTS.VALUES.DISPLAY_KEY],
                    [String(start), String(end), String(total)]
                );
            } else {
                $container.innerHTML = coned.utils.formatString(
                    _formatValues[CONSTANTS.VALUES.EMPTY_KEY],
                    [String(0)]
                );
            }
        }

        /**
         * 
         * @param {PaginationState} state 
         */
        var setPagination = function (state) {
            _paginationState = state;
            changeHTML();
        }
        /**
         * 
         * @param {CustomEvent<PaginationState>} event
         */
        var handlePaginationChange = function (event) {
            setPagination(event.detail);
        }
        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            _paginationState = {};
            _formatValues = $container.dataset.formats ? coned.utils.buildRecordString($container.dataset.formats) : {};
        }

        /**
         * Initialize the events in the module
         */
        var initializeEvents = function () {
            $container.addEventListener(
                coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                handlePaginationChange
            );
        }

        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            changeHTML();
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    PaginationSimpleInformation.prototype.isLoaded = function () {
        return isLoaded;
    };
    return PaginationSimpleInformation;
})();
