// ==================== SELECTSINGLE =========================

var coned = coned || {};

coned.components = coned.components || {};
/**
 * @returns the init function to start the module
 */
coned.components.SelectSingle = (function () {

    /**
     * @typedef {Object} State
     * @property {string} name
     * @property {string} value
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        SELECTORS: {
            /**
             * @type {'js-select-single-option'}
             */
            OPTIONS: 'js-select-single-option'
        },
        ATTRIBUTES: {
            /**
             * @type {'data-value'}
             */
            DATA_VALUE: 'data-value'
        }
    };
    var isLoaded = false;
    /**
     * Constructor
     * @param { HTMLDivElement } $selectSingle
     * @returns {}
     */
    var SelectSingle = function ($selectSingle) {
        /**
         * PRIVATE VARIABLES
         */
        /**
         * @type {State}
         */
        var _state,
            /**
             * @type {HTMLElement[]}
             */
            $options,
            /**
             * @type {String}
             */
            _defaultOption;

        /**
         * PRIVATE METHODS
         */
        var setDefaultOption = function () {
            _defaultOption = $selectSingle.dataset.default ? $selectSingle.dataset.default : '';
            if ($options.length > 0) {
                var $option = $options.find(function ($el) {
                    return $el.dataset.value === _defaultOption;
                });
                if ($option) {
                    _defaultOption = $option.dataset.value ? $option.dataset.value : '';
                } else {
                    _defaultOption = '';
                }
            } else {
                _defaultOption = '';
            }
            setState({ value: _defaultOption + '' });
        }
        /**
         * Updates the ARIA selected attribute for options based on the provided value.
         */
        var setSelectedHtml = function () {
            $options.forEach(function ($el) {
                var isSelected = $el.dataset.value === _state.value;
                if (isSelected) {
                    $el.setAttribute(coned.constants.ARIA.SELECTED, coned.constants.TRUE);
                } else {
                    $el.setAttribute(coned.constants.ARIA.SELECTED, coned.constants.FALSE);
                }
            });
        };
        /**
         * Update select state and set html changes
         * @param {State} newState 
         */
        var setState = function (newState) {
            _state.value = newState.value;
            $selectSingle.dataset.value = newState.value;
            setSelectedHtml();
            coned.utils.triggerEvent(
                $selectSingle,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _state
            );
        }
        /**
         * Callback function for CustomEvent SET_STATE_DETAIL
         * @param {CustomEvent<State>} event
         */
        var handleSetState = function (event) {
            setState(event.detail);
        }
        /**
         * Callback function for options keyboard events
         * @param {KeyboardEvent} event 
         */
        var handleOptionKeyboard = function (event) {
            var $element = event.target || event.currentTarget;
            if ($element instanceof HTMLElement) {
                if (
                    event.code === coned.constants.KEYBOARD_CODE.ENTER ||
                    event.code === coned.constants.KEYBOARD_CODE.SPACE ||
                    event.code === coned.constants.KEYBOARD_CODE.NUMPAD_ENTER
                ) {
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    $element.click()
                } else if (
                    event.code === coned.constants.KEYBOARD_CODE.ESC
                ) {
                    $element.blur();
                }
            }
        }
        /**
         * Callback function for options click
         * @param {MouseEvent} event 
         */
        var handleOptionClick = function (event) {
            if (event.currentTarget instanceof HTMLElement) {
                if (event.currentTarget.getAttribute(
                    coned.constants.ARIA.SELECTED) === coned.constants.TRUE
                ) {
                    if (_defaultOption.length > 0) {
                        setState({ value: '' + _defaultOption });
                    } else {
                        setState({ value: '' });
                    }

                } else {
                    setState({
                        value: event.currentTarget.dataset.value
                    })
                }
            }
        }
        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            _state = {
                name: $selectSingle.dataset.name ? $selectSingle.dataset.name : '',
                value: ''
            };
            $options = coned.utils.arrayFrom(
                $selectSingle.getElementsByClassName(CONSTANTS.SELECTORS.OPTIONS)
            );
            setDefaultOption();
        }
        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            $options.forEach(function ($el) {
                $el.addEventListener('click', handleOptionClick);
                $el.addEventListener('keydown', handleOptionKeyboard);
            })
            $selectSingle.addEventListener(
                coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                handleSetState
            );
        }
        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    SelectSingle.prototype.isLoaded = function () {
        return isLoaded;
    };
    return SelectSingle;
})();