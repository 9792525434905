// ==================== FINANCIAL ADVISOR QUESTIONNAIRE COMPONENT ============================

var query = query || {},
    coned = coned || {};

coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FinancialAdvisorQuestionnaire = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        STEPS_PARENT_SELECTOR: 'js-steps-parent',
        ERROR_STATUS: 'Error',
        PAGE_LOADING: 'js-page-loading',
        PAGE_LOADING_HIDDEN: 'page-loading--hidden',
        PROGRAMS_RESULT_SELECTOR: 'js-programs-result',
        HIDDEN_CLASS: 'hidden',
        ERROR_MESSAGING_MANAGED_SELECTOR: 'js-error-messaging-managed',
        HOUSEHOLD_SIZE_SELECTOR: 'js-household-size',
        INCOMES_SELECTOR: 'js-incomes',
        EXPENSES_SELECTOR: 'js-expenses',
        QUESTIONNAIRE_EVENT: 'questionnaire',
        LOAD_DATA_BUTTON: 'js-load-data-continue',
        HEATINGCOOLING_NAME: 'heatingCooling',
        YES_STRING: 'yes',
        TRUE_STRING: 'true'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var FinancialAdvisorQuestionnaire = function ($resultOverview) {
        var $financialAdvisorParent,
            $programsResult,
            $loadDataButton,
            $errorMessagingManaged,
            $pageLoading;

        /**
         * PRIVATE METHODS
        */

        /**
         * If call succeeded, load data in markup
         * @param {*} data 
         */
        var successQuestionairieAnswers = function (data) {
            if (coned.utils.isPatternLab()) {
                var questionnaireData = coned.plConstants.GET_FINANCIAL_ADVISOR_QUESTIONNAIRE_RESULTS;
                query.getData(
                    questionnaireData,
                    successQuestionnaireCallback,
                    errorQuestionairieAnswers
                );
            } else {
                successQuestionnaireCallback(data);
            }
        };

        /**
         * If call errored, hide module and show error
         */
        var errorQuestionairieAnswers = function () {
            query.addClass($pageLoading, CONSTANTS.PAGE_LOADING_HIDDEN);

            query.addClass($programsResult, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($errorMessagingManaged, CONSTANTS.HIDDEN_CLASS);
            $resultOverview.dataset.status = CONSTANTS.ERROR_STATUS;
            // Remove the listener when everything is already loaded
            $loadDataButton.removeEventListener(CONSTANTS.QUESTIONNAIRE_EVENT, sendQuestionarieAnswers);
            isLoaded = true;
        };

        /**
         *  Call the service
         */
        var sendQuestionarieAnswers = function () {

            if (!isLoaded) {
                // Extracting parameters and constructing parameters
                var serviceUrl = $financialAdvisorParent.dataset.serviceQuestionnaire,
                    ScId = $financialAdvisorParent.dataset.scIdQuestionnaire,
                    houseHoldSize = $financialAdvisorParent.getElementsByClassName(CONSTANTS.HOUSEHOLD_SIZE_SELECTOR)[0].value,
                    incomes = formatServicesCallNumber($financialAdvisorParent.getElementsByClassName(CONSTANTS.INCOMES_SELECTOR)[0].value),
                    expenses = formatServicesCallNumber($financialAdvisorParent.getElementsByClassName(CONSTANTS.EXPENSES_SELECTOR)[0].value),
                    params = {
                        ScId: ScId,
                        HouseholdSize: houseHoldSize,
                        Income: incomes,
                        Expense: expenses
                    };

                query.removeClass($pageLoading, CONSTANTS.PAGE_LOADING_HIDDEN);

                if (!serviceUrl) {
                    errorQuestionairieAnswers();
                    return;
                }
                query.getData(serviceUrl, successQuestionairieAnswers, errorQuestionairieAnswers, params);
            }

        };

        /**
         * Clear the numbers for service
         */
        var formatServicesCallNumber = function (number) {
            return number.replace('$', '').replace(/,/g, '');
        }

        /**
         * Load data in markup
         * @param {*} data 
         */
        var successQuestionnaireCallback = function (data) {
            var selectedHeating = document.querySelector("input[name=" + CONSTANTS.HEATINGCOOLING_NAME + "]:checked"),
                heatingCooling = selectedHeating.id === CONSTANTS.YES_STRING;

            $financialAdvisorParent.dataset.successQuestionnaireCallback = CONSTANTS.TRUE_STRING;
            $financialAdvisorParent.dataset.incomeTable = data.incomeTable;
            $financialAdvisorParent.dataset.greaterExpenses = data.greaterExpenses;
            $financialAdvisorParent.dataset.heatingCooling = heatingCooling;
        }


        var initializeData = function () {
            $financialAdvisorParent = document.getElementsByClassName(CONSTANTS.STEPS_PARENT_SELECTOR)[0];
            $programsResult = $resultOverview.getElementsByClassName(CONSTANTS.PROGRAMS_RESULT_SELECTOR)[0];
            $pageLoading = document.getElementsByClassName(CONSTANTS.PAGE_LOADING)[0];
            $loadDataButton = document.getElementsByClassName(CONSTANTS.LOAD_DATA_BUTTON)[0];
            $errorMessagingManaged = document.getElementsByClassName(CONSTANTS.ERROR_MESSAGING_MANAGED_SELECTOR)[0];
        };

        var initializeEvents = function () {
            $loadDataButton.addEventListener(CONSTANTS.QUESTIONNAIRE_EVENT, sendQuestionarieAnswers);
        }

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FinancialAdvisorQuestionnaire.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FinancialAdvisorQuestionnaire;

})();
