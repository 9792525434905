// ==================== SKIP TO MAIN CONTENT COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SkipToMainContentComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        MAIN_CONTENT: 'mainContent'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var SkipToMainContentComponent = function ($skipMainContentAnchor) {
        /**
         * PRIVATE METHODS
         */
        var $mainContent;

        var focusMainContent = function (event) {
            event.preventDefault();
            $mainContent.focus();
        };

        var initializeData = function () {
            $mainContent = document.getElementById(CONSTANTS.MAIN_CONTENT);
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($skipMainContentAnchor, focusMainContent);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SkipToMainContentComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SkipToMainContentComponent;
})();
