// ==================== ERROR PAGE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ErrorPage = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        NOT_FOUND_CONTAINER_CLASS: 'js-not-found',
        NOT_FOUND_ORU: 'not-found--oru',
        NOT_FOUND_HEADER_CLASS: 'js-header-wrapper',
        NOT_FOUND_HEADER_ORU: 'header--oru',
        NOT_FOUND_PAGE_CLASS: 'js-page-content',
        NOT_FOUND_LOGO_CLASS: 'js-header-logo',
        SRC_ATTRIBUTE: 'src',
        SRCSET_ATTRIBUTE: 'srcset',
        NOT_FOUND_PICTURE: 'js-picture',
        NOT_FOUND_PICTURE_MOBILE: 'js-picture-mobile',
        NOT_FOUND_PICTURE_DESKTOP: 'js-picture-desktop',
        NOT_FOUND_PICTURE_IMAGE: 'js-picture-image',
        NOT_FOUND_FOOTER_CLASS: 'js-footer',
        NOT_FOUND_FOOTER_ORU: 'footer--oru',
        LOADER_CLASS: 'js-form-loading',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ErrorPage = function ($module) {
        /**
         * PRIVATE METHODS
         */
        var $container,
            $logo,
            $loader,
            $footer,
            $picture,
            $pictureMobile,
            $pictureDesktop,
            $pictureImage,
            $pageContent,
            $header;

        var initializeFunctionality = function () {
            var mobileSrc, desktopSrc;

            if (window.location.href.indexOf('.oru') > -1) {
                mobileSrc = $picture.dataset.mobileOru;
                desktopSrc = $picture.dataset.desktopOru;

                $container.classList.add(CONSTANTS.NOT_FOUND_ORU);
                $logo.setAttribute(CONSTANTS.SRC_ATTRIBUTE, $logo.dataset.oruLogo);
                $header.classList.add(CONSTANTS.NOT_FOUND_HEADER_ORU);
                $footer.classList.add(CONSTANTS.NOT_FOUND_FOOTER_ORU);
                $pictureMobile.setAttribute(CONSTANTS.SRCSET_ATTRIBUTE, mobileSrc);
                $pictureDesktop.setAttribute(CONSTANTS.SRCSET_ATTRIBUTE, desktopSrc);
                $pictureImage.setAttribute(CONSTANTS.SRCSET_ATTRIBUTE, desktopSrc);
            }

            $pageContent.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $footer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $header.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $loader.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var initializeData = function () {
            $container = document.getElementsByClassName(CONSTANTS.NOT_FOUND_CONTAINER_CLASS)[0];
            $logo = document.getElementsByClassName(CONSTANTS.NOT_FOUND_LOGO_CLASS)[0];
            $footer = document.getElementsByClassName(CONSTANTS.NOT_FOUND_FOOTER_CLASS)[0];
            $loader = document.getElementsByClassName(CONSTANTS.LOADER_CLASS)[0];
            $pageContent = document.getElementsByClassName(CONSTANTS.NOT_FOUND_PAGE_CLASS)[0];
            $header = document.getElementsByClassName(CONSTANTS.NOT_FOUND_HEADER_CLASS)[0];

            $picture = $module.getElementsByClassName(CONSTANTS.NOT_FOUND_PICTURE)[0];
            $pictureMobile = $module.getElementsByClassName(CONSTANTS.NOT_FOUND_PICTURE_MOBILE)[0];
            $pictureDesktop = $module.getElementsByClassName(
                CONSTANTS.NOT_FOUND_PICTURE_DESKTOP
            )[0];
            $pictureImage = $module.getElementsByClassName(CONSTANTS.NOT_FOUND_PICTURE_IMAGE)[0];
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeFunctionality();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ErrorPage.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ErrorPage;
})();
