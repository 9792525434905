// ==================== BOPA ENROLL EMPTY COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.BopaEnrollEmptyModule = (function () {
    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var BopaEnrollEmptyModule = function () {
        // GENERAL
        var initializeAnalytics = function () {
            // Analytics data building
            dataLayer.push({
                event: 'bopa',
                bopa: 'step2.0'
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeAnalytics();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    BopaEnrollEmptyModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return BopaEnrollEmptyModule;
})();
