// ==================== ENERGY USAGE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DayAheadPeak = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        LOADING_IMAGE: 'js-form-loading', 
        PEAK_VALUE_TEXT: 'js-peak-value', 
        PEAK_PARAGRAPH: 'js-peak-text',
        TEMPERATURE_VALUE_TEXT: 'js-temperature-text',
        ERROR: 'js-service-error', 
        ERROR_MESSAGE: 'js-error-message', 
        HIDDEN: 'hidden', 

        CARD_TITLE: 'js-card-title',
        CARD_TITLE_BORDER: 'js-card-border',
        KILOWATTS_VALUE_FIELD: 'kiloWatts_value',
        PERCENTAGE_VALUE_FIELD: 'percentage_value',
        TOTAL_VALUE_FIELD: 'total_value', 
        UNIT_MEASURE_FIELD: 'unit_measure'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var DayAheadPeak = function ($dayAheadPeak) {
        /**
         * PRIVATE METHODS
         */
        var $loadingImage, 
            $cardTitle,
            $cardTitleBorder;

        var getdayAhead = function () {
            var serviceUrl = $dayAheadPeak.dataset.getDayAheadForecast,
                params;

            // Service Data
            params = {
                ScId: $dayAheadPeak.dataset.scid
            };

            // Service call
            params = JSON.stringify(params);
            query.postData(serviceUrl, successDayAheadPeak, errorDayAheadPeak, params, true, $loadingImage);
        };

        var successDayAheadPeak = function(data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.DAY_AHEAD_PEAK_DEMAND, addDayAheadPeakText, function () {});
            } else {
                addDayAheadPeakText(data);
            }
        }

        var addDayAheadPeakText = function (data) {
            var dayAheadAmountText = $dayAheadPeak.dataset.getAheadAmountText,
                dayAheadText = $dayAheadPeak.dataset.getAheadText,
                dayAheadTemperatureText,
                dayAheadTemperatureVariable = $dayAheadPeak.dataset.getTemperatureVariable,
                dayAheadTemperatureTitle = $dayAheadPeak.dataset.temperatureTitle,
                dayAheadPeakDemandTitle = $dayAheadPeak.dataset.peakDemandTitle,
                peakValue = $dayAheadPeak.getElementsByClassName(CONSTANTS.PEAK_VALUE_TEXT)[0],
                temperatureText = $dayAheadPeak.getElementsByClassName(CONSTANTS.TEMPERATURE_VALUE_TEXT)[0],
                peakParagraph = $dayAheadPeak.getElementsByClassName(CONSTANTS.PEAK_PARAGRAPH)[0];

            $cardTitle.classList.remove(CONSTANTS.HIDDEN);
            $cardTitleBorder.classList.remove(CONSTANTS.HIDDEN);

            dayAheadTemperatureText = data.temperatureField + ' ' + dayAheadTemperatureVariable;
            temperatureText.innerHTML = dayAheadTemperatureTitle  + '<span class="card-module__description--bold"> ' + dayAheadTemperatureText + '</span>';

            dayAheadAmountText = dayAheadAmountText.replace(CONSTANTS.KILOWATTS_VALUE_FIELD, data.kiloWattsField);
            dayAheadAmountText = dayAheadAmountText.replace(CONSTANTS.UNIT_MEASURE_FIELD, data.measurementUnitField);
            peakValue.innerHTML = dayAheadPeakDemandTitle + '<span class="card-module__description--bold"> ' + dayAheadAmountText + '</span>';

            dayAheadText = dayAheadText.replace(CONSTANTS.PERCENTAGE_VALUE_FIELD, data.percentageField);
            dayAheadText = dayAheadText.replace(CONSTANTS.TOTAL_VALUE_FIELD, data.totalLoadField);
            dayAheadText = dayAheadText.replace(CONSTANTS.UNIT_MEASURE_FIELD, data.measurementUnitField);
            peakParagraph.innerHTML = dayAheadText;
        }

        var errorDayAheadPeak = function(data) {
            var errorMessage = $dayAheadPeak.getElementsByClassName(CONSTANTS.ERROR)[0], 
                errorText = $dayAheadPeak.getElementsByClassName(CONSTANTS.ERROR_MESSAGE)[0];

            if (coned.utils.isPatternLab()) {
                data.errorMsg = 'Something went wrong. Please try again by refreshing the page.';
            }

            $cardTitle.classList.remove(CONSTANTS.HIDDEN);
            $cardTitleBorder.classList.remove(CONSTANTS.HIDDEN);
            errorText.innerHTML = data.errorMsg;
            errorMessage.classList.remove(CONSTANTS.HIDDEN);
            errorText.focus();
        }

        var initializeData = function () {
            $loadingImage = $dayAheadPeak.getElementsByClassName(CONSTANTS.LOADING_IMAGE)[0];
            $cardTitle = $dayAheadPeak.getElementsByClassName(CONSTANTS.CARD_TITLE)[0];
            $cardTitleBorder = $dayAheadPeak.getElementsByClassName(CONSTANTS.CARD_TITLE_BORDER)[0];
        };


        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            getdayAhead();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DayAheadPeak.prototype.isLoaded = function () {
        return isLoaded;
    };
    return DayAheadPeak;
})();
