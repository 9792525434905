// ==================== REPORT TABLE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ReportTableComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ITEM: 'js-item',
        ITEM_OPEN: 'table-item--open',
        ITEM_ARROW: 'js-open-arrow',
        EXPAND_ALL: 'js-expand-all',
        EXPAND_ACTIVE: 'js-expand-active',
        BY_ITEM: 'js-table-by-item',
        BY_ITEM_ACTIVE: 'report-table__navigation-by-item--active',
        TABLE: 'js-table',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ReportTableComponent = function ($reportTable) {
        /**
         * PRIVATE METHODS
         */
        var $items, $arrowItems, $expandAllButtons, $showByItems, $tables;

        var arrowEvent = function (event) {
            event.preventDefault();

            var $arrowParentItem = this.parentNode;

            if (query.hasClass($arrowParentItem, CONSTANTS.ITEM_OPEN)) {
                $arrowParentItem.classList.remove(CONSTANTS.ITEM_OPEN);
            } else {
                query.removeClass($items, CONSTANTS.ITEM_OPEN);
                $arrowParentItem.classList.add(CONSTANTS.ITEM_OPEN);
            }
        };

        var toggleItems = function (event) {
            event.preventDefault();

            var originalText = this.dataset.defaultText,
                replaceText = this.dataset.activeText,
                actualText = this.innerText;

            if (actualText == originalText) {
                toggleItemsVisibility(true);
                updatedButtonsText(replaceText);
            } else {
                toggleItemsVisibility();
                updatedButtonsText(originalText);
            }

            function updatedButtonsText(text) {
                for (var indexExpand = 0; indexExpand < $expandAllButtons.length; indexExpand++) {
                    $expandAllButtons[indexExpand].innerHTML = text;
                }
            }
        };

        var toggleItemsVisibility = function (status) {
            if (status) {
                query.addClass($items, CONSTANTS.ITEM_OPEN);
            } else {
                query.removeClass($items, CONSTANTS.ITEM_OPEN);
            }
        };

        var tabClick = function () {
            var selectedOrder = this.dataset.value,
                selectedTableItem = 'js-table-' + selectedOrder,
                $selectedTable = $reportTable.getElementsByClassName(selectedTableItem)[0];

            query.addClass($tables, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($showByItems, CONSTANTS.BY_ITEM_ACTIVE);
            $selectedTable.classList.remove(CONSTANTS.HIDDEN_CLASS);
            this.classList.add(CONSTANTS.BY_ITEM_ACTIVE);
        };

        var initializeData = function () {
            $items = $reportTable.getElementsByClassName(CONSTANTS.ITEM);
            $arrowItems = $reportTable.getElementsByClassName(CONSTANTS.ITEM_ARROW);
            $expandAllButtons = $reportTable.getElementsByClassName(CONSTANTS.EXPAND_ALL);
            $showByItems = $reportTable.getElementsByClassName(CONSTANTS.BY_ITEM);
            $tables = $reportTable.getElementsByClassName(CONSTANTS.TABLE);
        };

        var initializeEvents = function () {
            // Expand/collapse row
            for (var index = 0; index < $arrowItems.length; index++) {
                coned.utils.addGeneralListeners($arrowItems[index], arrowEvent);
            }

            if ($expandAllButtons.length > 0) {
                for (var indexExpand = 0; indexExpand < $expandAllButtons.length; indexExpand++) {
                    coned.utils.addGeneralListeners($expandAllButtons[indexExpand], toggleItems);
                }
            }

            if ($showByItems.length > 0) {
                for (var byIndex = 0; byIndex < $showByItems.length; byIndex++) {
                    coned.utils.addGeneralListeners($showByItems[byIndex], tabClick);
                }
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ReportTableComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ReportTableComponent;
})();
