// ==================== PREFERENCE CENTER COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PreferenceCenter = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        PREFERENCE_CENTER_FORM: '.js-preference-center-form',
        FORM_IGNORE_VALIDATION: 'js-validate-ignore',
        HIDDEN_CLASS: 'hidden',
        NOTIFICATION_CHECK: 'js-notification-check',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        CHECKBOX_SWITCH_FOCUS: 'coned-checkbox-switch--focus',
        EDIT_SCID: 'ScId',
        EDIT_NOTIFICATIONS_SCID: 'notificationsScId',
        TAGGING_CHECKED_ON: '.on',
        TAGGING_CHECKED_OFF: '.off'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var PreferenceCenter = function ($preferenceCenter) {
        /**
         * PRIVATE METHODS
         */
        var $checksNotification,
            $serviceErrorMessages,
            $serviceErrors,
            $formLoading,
            $actualField,
            $notificationCheckbox;

        var successEditNotification = function () {
            hideErrors();
        };

        // Notification options handler
        var editNotification = function () {
            $actualField = this.parentNode;
            $notificationCheckbox = $actualField.getElementsByClassName(
                CONSTANTS.NOTIFICATION_CHECK
            )[0];

            var serviceUrl = $actualField.dataset.service,
                params;

            params = {
                ScId: query.getFormInputValue($preferenceCenter, CONSTANTS.EDIT_SCID),
                NotificationsScId: query.getFormInputValue(
                    $preferenceCenter,
                    CONSTANTS.EDIT_NOTIFICATIONS_SCID
                ),
                PreferenceId: $actualField.dataset.preference,
                ChannelName: $actualField.dataset.channel,
                Value: $notificationCheckbox.checked
            };

            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successEditNotification,
                errorServiceCall,
                params,
                true,
                $formLoading
            );

            var dataTag = JSON.parse($notificationCheckbox.dataset.tag);
            if (dataTag) {
                dataTag.event += $notificationCheckbox.checked
                    ? CONSTANTS.TAGGING_CHECKED_ON
                    : CONSTANTS.TAGGING_CHECKED_OFF;
                dataLayer.push(dataTag);
            }
        };

        var errorServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.DEFAULT_SERVICE_ERROR, showError, showError, true);
            } else {
                showError(data);
            }
        };

        var showError = function (data) {
            var notificationField = query.hasClass($actualField, CONSTANTS.EDIT_NOTIFICATION);

            $actualField = notificationField ? $actualField.parentNode.parentNode : $actualField;

            if (notificationField) {
                $notificationCheckbox.checked = false;
            }

            var errorMsg = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE,
                $serviceError = $actualField.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0],
                $serviceErrorMessage = $actualField.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];

            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = errorMsg;
            $serviceError.focus();
        };

        var hideErrors = function () {
            _.each($serviceErrors, function ($serviceError) {
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorMessages, function ($serviceErrorMessage) {
                $serviceErrorMessage.innerHTML = '';
            });
        };

        var initializeData = function () {
            $checksNotification = $preferenceCenter.getElementsByClassName(
                CONSTANTS.NOTIFICATION_CHECK
            );
            $formLoading = $preferenceCenter.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $serviceErrors = $preferenceCenter.getElementsByClassName(CONSTANTS.SERVICE_ERROR);
            $serviceErrorMessages = $preferenceCenter.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            );
        };

        var initializeEvents = function () {
            // Edit notifications
            if ($checksNotification.length > 0) {
                _.each($checksNotification, function ($editNotification) {
                    coned.utils.addGeneralListeners($editNotification, editNotification);

                    $editNotification.addEventListener('focusin', function (event) {
                        query.addClass(event.target.parentElement, CONSTANTS.CHECKBOX_SWITCH_FOCUS);
                    });

                    $editNotification.addEventListener('focusout', function (event) {
                        query.removeClass(
                            event.target.parentElement,
                            CONSTANTS.CHECKBOX_SWITCH_FOCUS
                        );
                    });
                });
            }

            // Validate fields
            new coned.components.ValidateForm(
                CONSTANTS.PREFERENCE_CENTER_FORM,
                '',
                CONSTANTS.FORM_IGNORE_VALIDATION
            );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PreferenceCenter.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PreferenceCenter;
})();
