// ==================== USAGE HISTORY LINKS COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.UsageHistory = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ITEM_CLASS: 'js-item',
        ITEM_LINK_CLASS: 'js-item-link',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        ACCOUNT_MAID: 'accountMaid',
        HIDDEN_CLASS: 'hidden',
        FORM_LOADING: 'js-form-loading',
        USAGE_LOAD_MORE_MODULE: 'js-load-more-module',
        USAGE_HISTORY_WRAPPER_SELECTOR:'js-usage-history-wrapper'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var UsageHistory = function ($usageHistory) {
        /**
         * PRIVATE METHODS
         */
        var $usageHistoryLinks, 
            $serviceError, 
            $loadMoreModule, 
            $formLoading, 
            $itemLink, 
            $newTab,
            $usageHistoryAncestorWrapper;

        var usageLinkService = function (event) {
            event.preventDefault();

            var $item = query.selectParentElement(event.target, CONSTANTS.ITEM_CLASS),
                serviceUrl = $usageHistory.dataset.linkService,
                params;

            // Set the item link to be used
            $itemLink = query.selectParentElement(event.target, CONSTANTS.ITEM_LINK_CLASS);

            // Set $serviceError depending on the item clicked
            $serviceError = $item.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Service data
            params = {
                Maid: query.getFormInputValue(document, CONSTANTS.ACCOUNT_MAID),
                ScId: $usageHistory.dataset.scid,
                Type: $itemLink.dataset.type,
                StartDate: $itemLink.dataset.startDate,
                EndDate: $itemLink.dataset.endDate,
                TimeStamp: $itemLink.dataset.timestamp
            };

            // Before doing the service call, lets create the new tab where the link will be opened, so it won't be blocked by browser security
            $newTab = window.open();

            // Service call
            query.getData(
                serviceUrl,
                successUsageLinkService,
                errorUsageLinkService,
                params,
                $formLoading
            );
        };

        var successUsageLinkService = function () {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.GET_LINK, redirectPage, errorUsageLinkService);
            } else {
                redirectPage();
            }
        };

        var redirectPage = function () {
            // Set file location in the opened tab
            $newTab.location.href = $itemLink.href;
        };

        var errorUsageLinkService = function (data) {
            var $serviceErrorMessage = $serviceError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];

            // Close new tab opened
            $newTab.close();

            // Display error message
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var getUsageHistoryMarkup = function () {
            var serviceUrl = $usageHistory.dataset.serviceUrl,
                params;

            // Service data
            params = {
                ScId: $usageHistory.dataset.scid,
                AccountMaid: $usageHistory.dataset.accountMaid,
                AccountNumber: $usageHistory.dataset.accountNumber,
                ShowMoreInitialLoad: $usageHistory.dataset.initialLoad,
                ShowMoreLoadNumberPerClick: $usageHistory.dataset.loadMore
            };

            // Service call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successGetUsageHistoryMarkup,
                errorGetUsageHistoryMarkup,
                params,
                true,
                $formLoading
            );
        };

        var successGetUsageHistoryMarkup = function (data) {
            if (coned.utils.isPatternLab()) {
                if ($usageHistory.dataset.oruPatternlabEnergyUsageRtpPage) {
                    query.getData(
                        coned.plConstants.GET_USAGE_HISTORY_TABLE_ORU_ENERGY_USAGE_HTML,
                        setBillingUsageMarkup,
                        errorGetUsageHistoryMarkup
                    );
                } else {
                    query.getData(
                        coned.plConstants.GET_USAGE_HISTORY_TABLE_ORU_HTML,
                        setBillingUsageMarkup,
                        errorGetUsageHistoryMarkup
                    );
                }
            } else {
                setBillingUsageMarkup(data);
            }
        };

        var errorGetUsageHistoryMarkup = function (data) {
            var $usageHistoryError = $usageHistory.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR
                )[0],
                $usageHistoryErrorMessage = $usageHistoryError.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];

            $usageHistoryError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $usageHistoryErrorMessage.innerHTML =
                data && data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;
        };

        var setBillingUsageMarkup = function (data) {
            $usageHistory.innerHTML = data;
            $loadMoreModule = $usageHistory.getElementsByClassName(
                CONSTANTS.USAGE_LOAD_MORE_MODULE
            )[0];
            $usageHistoryLinks = $usageHistory.getElementsByClassName(CONSTANTS.ITEM_LINK_CLASS);
            $serviceError = $usageHistory.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            new coned.components.LoadMore($loadMoreModule, $usageHistoryAncestorWrapper);

            for (var index = 0; index < $usageHistoryLinks.length; index++) {
                coned.utils.addGeneralListeners($usageHistoryLinks[index], usageLinkService);
            }
        };

        var initializeData = function () {
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $usageHistoryAncestorWrapper = query.selectParentElement($usageHistory, CONSTANTS.USAGE_HISTORY_WRAPPER_SELECTOR);
        };

        var initializeEvents = function () {
            getUsageHistoryMarkup();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    UsageHistory.prototype.isLoaded = function () {
        return isLoaded;
    };
    return UsageHistory;
})();
