// ==================== START SERVICE CCG FORM COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.StartServiceCCG = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_SELECTOR: 'js-start-service-ccg-form',
        START_CCG_INITIAL_CLASS: 'js-start-service-ccg',
        PHONE_RADIO_BUTTON: 'js-phone-radio',
        EMAIL_RADIO_BUTTON: 'js-email-radio',
        PHONE_INPUT: 'js-phone-number',
        EMAIL_INPUT: 'js-email-address',
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        SUBMIT_ANIMATION: 'js-submit-progress-animation',
        FORM_LOADING: 'js-form-loading',
        FINISH_STEP_SELECTOR: 'js-finish-message',
        CAPTCHA_ELEMENT_CLASS: 'js-recaptcha',
        RECAPTCHA: 'js-recaptcha-container',
        IGNORE_VALIDATION: 'js-ignore-validation',
        SERVICE_ERROR: 'js-service-error',
        ERROR_MESSAGE: 'js-error-message',
        START_SERVICE_CCG_FINISH_MAIN: 'js-start-service-ccg-finish-main',

        TABINDEX: 'tabindex',
        FIELD_CONTAINER: 'coned-form__field-container',
        HIDDEN_CLASS: 'hidden',
        REQUIRED_ATTRIBUTE: 'required',

        FORM_ID: 'FormId',
        SERVICE_ADDRESS_NAME: 'serviceAddress',
        BUSINESS_NAME: 'businessName',
        FIRST_NAME: 'firstName',
        LAST_NAME: 'lastName',
        CONTACT_TYPE_NAME: 'contactType',
        PHONE_NAME: 'phoneNumber',
        EMAIL_NAME: 'emailAddress',

        EMAIL_VALUE: 'email',
        PHONE_VALUE: 'phone'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var StartServiceCCG = function ($startServiceCCG) {
        /**
         * PRIVATE METHODS
         */
        var $form,
            $startServiceCcgInitial,
            $startServiceCcgFinishMain,
            $phoneRadio,
            $emailRadio,
            $phoneInput,
            $emailInput,
            $formLoading,
            $submitButton,
            $submitAnimation,
            $serviceError,
            $errorMessage,
            $captchaElement,
            $finishStepSelector,
            recaptcha,
            _hasCaptcha,
            _userAuthenticated;

        /**
         * Handles click event of submit button.
         */
        var submitClickHandler = function () {
            if (_userAuthenticated) {
                startServiceCcgSubmit();
            } else {
                recaptcha.checkRecaptcha();
            }
        };

        /**
         * Activates the submit animation while service call is made.
         */
        var startServiceCcgSubmit = function () {
            new coned.components.SubmitAnimation(
                $submitAnimation,
                startServiceCcgCall,
                successStartServiceCcgCall,
                errorCallback
            );
        };

        /**
         * Handles service call form the form.
         */
        var startServiceCcgCall = function () {
            var response = _hasCaptcha ? recaptcha.getResponse() : null,
                serviceUrl = $form.dataset.serviceUrl,
                params = new FormData();

            params.append('FormId', query.getFormInputValue($startServiceCCG, CONSTANTS.FORM_ID));
            params.append(
                'ServiceAddress',
                query.getFormInputValue($startServiceCCG, CONSTANTS.SERVICE_ADDRESS_NAME)
            );
            params.append(
                'BusinessName',
                query.getFormInputValue($startServiceCCG, CONSTANTS.BUSINESS_NAME)
            );
            params.append(
                'FirstName',
                query.getFormInputValue($startServiceCCG, CONSTANTS.FIRST_NAME)
            );
            params.append(
                'LastName',
                query.getFormInputValue($startServiceCCG, CONSTANTS.LAST_NAME)
            );
            params.append(
                'ContactType',
                query.getFormInputValue($startServiceCCG, CONSTANTS.CONTACT_TYPE_NAME)
            );

            if ($phoneRadio.checked) {
                params.append(
                    'PhoneNumber',
                    query.getFormInputValue($startServiceCCG, CONSTANTS.PHONE_NAME)
                );
            } else {
                params.append(
                    'EmailAddress',
                    query.getFormInputValue($startServiceCCG, CONSTANTS.EMAIL_NAME)
                );
            }

            if (_hasCaptcha && response && response !== '') {
                params.append('Verify', response);
            }

            // params = JSON.stringify(params);

            query.postData(serviceUrl, successCallback, errorCallback, params, false, $formLoading);
        };

        /**
         * Handles the success of the service call.
         */
        var successStartServiceCcgCall = function () {
            $startServiceCcgInitial.classList.add(CONSTANTS.HIDDEN_CLASS);
            query.removeClass($finishStepSelector, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($startServiceCcgFinishMain, CONSTANTS.HIDDEN_CLASS);
            $startServiceCcgFinishMain.focus();

            window.scrollTo(0, 0);
        };

        /**
         * Handles the success of the service call.
         */
        var successCallback = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $submitAnimation.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        /**
         * Handles the error of the service call.
         */
        var errorCallback = function (data) {
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $submitAnimation.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);

            if (data.errorMsg && data.errorMsg !== '') {
                $errorMessage.innerHTML = data.errorMsg;
            } else {
                $errorMessage.innerHTML = coned.constants.ERROR_MESSAGE;
            }

            $serviceError.focus();
        };

        /**
         * Handles input changes to activate submit button.
         */
        var onInputChange = function () {
            $submitButton.disabled = !$($form).validate().checkForm();
        };

        /**
         * Switch.
         */
        var contactTypeSelect = function (event) {
            var $target = event.currentTarget,
                $phoneContainer = query.selectParentElement($phoneInput, CONSTANTS.FIELD_CONTAINER),
                $emailContainer = query.selectParentElement($emailInput, CONSTANTS.FIELD_CONTAINER);

            if ($target.value === CONSTANTS.PHONE_VALUE) {
                $phoneContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $emailContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                $phoneInput.classList.remove(CONSTANTS.IGNORE_VALIDATION);
                $phoneInput.setAttribute(
                    CONSTANTS.REQUIRED_ATTRIBUTE,
                    CONSTANTS.REQUIRED_ATTRIBUTE
                );
                $emailInput.classList.add(CONSTANTS.IGNORE_VALIDATION);
                $emailInput.removeAttribute(CONSTANTS.REQUIRED_ATTRIBUTE);
            } else if ($target.value === CONSTANTS.EMAIL_VALUE) {
                $phoneContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $emailContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $phoneInput.classList.add(CONSTANTS.IGNORE_VALIDATION);
                $phoneInput.removeAttribute(CONSTANTS.REQUIRED_ATTRIBUTE);
                $emailInput.classList.remove(CONSTANTS.IGNORE_VALIDATION);
                $emailInput.setAttribute(
                    CONSTANTS.REQUIRED_ATTRIBUTE,
                    CONSTANTS.REQUIRED_ATTRIBUTE
                );
            }

            onInputChange();
        };

        /**
         * Initializes all elements needed in the module
         */
        var initializeData = function () {
            $form = $startServiceCCG.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            $startServiceCcgInitial = document.getElementsByClassName(
                CONSTANTS.START_CCG_INITIAL_CLASS
            )[0];
            $phoneRadio = $startServiceCCG.getElementsByClassName(CONSTANTS.PHONE_RADIO_BUTTON)[0];
            $emailRadio = $startServiceCCG.getElementsByClassName(CONSTANTS.EMAIL_RADIO_BUTTON)[0];
            $phoneInput = $startServiceCCG.getElementsByClassName(CONSTANTS.PHONE_INPUT)[0];
            $emailInput = $startServiceCCG.getElementsByClassName(CONSTANTS.EMAIL_INPUT)[0];
            $formLoading = $startServiceCCG.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $submitButton = $startServiceCCG.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $submitAnimation = $startServiceCCG.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION
            )[0];
            $serviceError = $startServiceCCG.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $errorMessage = $startServiceCCG.getElementsByClassName(CONSTANTS.ERROR_MESSAGE)[0];
            $finishStepSelector = document.getElementsByClassName(CONSTANTS.FINISH_STEP_SELECTOR)[0];
            $startServiceCcgFinishMain = $startServiceCCG.getElementsByClassName(
                CONSTANTS.START_SERVICE_CCG_FINISH_MAIN
            )[0];
            $captchaElement = document.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT_CLASS);
            _hasCaptcha = $captchaElement.length;
            _userAuthenticated = $form.dataset.userStatus;
        };

        /**
         * Initializes all events needed in the module
         */
        var initializeEvents = function () {
            $form.addEventListener('input', onInputChange);
            $phoneRadio.addEventListener('change', contactTypeSelect);
            $emailRadio.addEventListener('change', contactTypeSelect);

            // Set tabindex on end screen for a11y
            $startServiceCcgFinishMain.setAttribute(CONSTANTS.TABINDEX, '-1');

            // Form Validation
            new coned.components.ValidateForm(
                '.' + CONSTANTS.FORM_SELECTOR,
                submitClickHandler,
                CONSTANTS.IGNORE_VALIDATION
            );

            recaptcha = new coned.components.Recaptcha(
                $form,
                startServiceCcgSubmit,
                startServiceCcgSubmit
            );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    StartServiceCCG.prototype.isLoaded = function () {
        return isLoaded;
    };

    return StartServiceCCG;
})();
