// ==================== UNSUPPORTED BROWSER BANNER COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.UnsupportedBrowserBanner = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        UA_OPERA: 'opr',
        UA_OPERA_LEGACY:'opera',
        UA_EDGE: 'edg/',
        UA_EDGE_LEGACY: 'edge',
        UA_CHROME:'chrome',
        UA_SAFARI: 'safari',
        UA_FIREFOX: 'firefox',
        UA_SEAMONKEY: 'seamonkey',
        UA_CHROMIUM: 'chromium',
        UA_IE: 'trident',
        BROWSER_OPERA: 'opera',
        BROWSER_OPERA_LEGACY: 'operalegacy',
        BROWSER_EDGE: 'edge',
        BROWSER_EDGE_LEGACY: 'edgelegacy',
        BROWSER_CHROME: 'chrome',
        BROWSER_SAFARI: 'safari',
        BROWSER_FIREFOX: 'firefox',
        BROWSER_IE: 'ie',
        BROWSER_OTHER: 'other',
        VERSION_STRING: 'version',
        HIDDEN_CLASS: 'hidden',
        VERTICAL_LINE_STRING: '|',
        COLON_STRING: ':'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var UnsupportedBrowserBanner = function ($unsupportedBrowserBanner) {
        /**
         * PRIVATE METHODS
         */
        var userAgent,
            browser,
            browserUA,
            browserAndUaArr,
            browserVersion,
            blacklist,
            hideList,
            showList,
            shouldRemainHidden,
            shouldShow;

        /**
         * Gets the current browser
         * User agent specs as per current feb/2024 MDN recommendations https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
         * @return {Array} [browserName, actualBrowserUA] Browser name, and browser user agent
         */
        var getBrowserAndUserAgent = function () {
            var browserName,
                actualBrowserUA;

            switch (true) {
                // Example for Legacy Opera user agent:
                // Opera/9.80 (X11; Linux i686; Ubuntu/14.10) Presto/2.12.388 Version/12.16.2
                case userAgent.indexOf(CONSTANTS.UA_OPERA_LEGACY) > -1:
                    browserName = CONSTANTS.BROWSER_OPERA_LEGACY;
                    actualBrowserUA = CONSTANTS.UA_OPERA_LEGACY;
                    break;

                // Current example as of feb/2024 of Opera user agent: 
                // 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36 Opr/106.0.0.0'
                case userAgent.indexOf(CONSTANTS.UA_OPERA) > -1:
                    browserName = CONSTANTS.BROWSER_OPERA;
                    actualBrowserUA = CONSTANTS.UA_OPERA;
                    break;

                // Example for Edge Legacy user agent:
                // 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.246'
                case userAgent.indexOf(CONSTANTS.UA_EDGE_LEGACY) > -1:
                    browserName = CONSTANTS.BROWSER_EDGE_LEGACY;
                    actualBrowserUA = CONSTANTS.UA_EDGE_LEGACY;
                    break;

                // Current example as of feb/2024 of Edge user agent:
                // 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36 Edg/121.0.0.0'
                case userAgent.indexOf(CONSTANTS.UA_EDGE) > -1:
                    browserName = CONSTANTS.BROWSER_EDGE;
                    actualBrowserUA = CONSTANTS.UA_EDGE;
                    break;

                // Current example as of feb/2024 of Chrome user agent:
                // 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36'
                case 
                    userAgent.indexOf(CONSTANTS.UA_CHROME) > -1 && 
                    userAgent.indexOf(CONSTANTS.UA_CHROMIUM) === - 1 &&
                    userAgent.indexOf(CONSTANTS.UA_EDGE) === - 1:
                    browserName = CONSTANTS.BROWSER_CHROME;
                    actualBrowserUA = CONSTANTS.UA_CHROME;
                    break;

                // Current example as of feb/2024 of Safari user agent:
                // 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6.1 Safari/605.1.15'
                case userAgent.indexOf(CONSTANTS.UA_SAFARI) > -1 &&
                    userAgent.indexOf(CONSTANTS.UA_CHROME) === -1 && 
                    userAgent.indexOf(CONSTANTS.UA_CHROMIUM) === - 1:
                    browserName = CONSTANTS.BROWSER_SAFARI;
                    actualBrowserUA = CONSTANTS.UA_SAFARI;
                    break;

                // Current example as of feb/2024 of Firefox user agent:
                // 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:122.0) Gecko/20100101 Firefox/122.0'
                case 
                    userAgent.indexOf(CONSTANTS.UA_FIREFOX) > -1 && 
                    userAgent.indexOf(CONSTANTS.UA_SEAMONKEY) === -1:
                    browserName = CONSTANTS.BROWSER_FIREFOX;
                    actualBrowserUA = CONSTANTS.UA_FIREFOX;
                    break;
                
                // Example of IE user agent:
                // 'Mozilla/5.0 (Windows; NT 10.0; Trident/7.0; .NET4.0E; .NET CLR 2.0.50727; .NET CLR 3.0.30729; .NET CLR 3.5.30729; InfoPath.3; Tablet PC 2.0 rv:11.0) like Gecko'                    
                case userAgent.indexOf(CONSTANTS.UA_IE) > -1:
                    browserName = CONSTANTS.BROWSER_IE;
                    actualBrowserUA = CONSTANTS.UA_IE;
                    break;
                
                // Unknown browsers
                default:
                    browserName = CONSTANTS.BROWSER_OTHER;
            }
            
            return [browserName, actualBrowserUA];
        };

        /**
         * Gets the browser version the user is on.
         * @return {Number} Current version of the browser
         */
        var getBrowserVersion = function () {
            var regexVersion,
                version;

            // These browsers are unsupported by default
            if (
                browser === CONSTANTS.BROWSER_IE ||
                browser === CONSTANTS.BROWSER_OPERA_LEGACY ||
                browser === CONSTANTS.BROWSER_OTHER
            ) {
                version = null;
                
                return version;
            }

            // These browsers version format is: Browser/n
            if (
                browser === CONSTANTS.BROWSER_OPERA || 
                browser === CONSTANTS.BROWSER_EDGE_LEGACY || 
                browser === CONSTANTS.BROWSER_CHROME || 
                browser === CONSTANTS.BROWSER_FIREFOX ||
                browser === CONSTANTS.BROWSER_EDGE
            ) {
                // Really old browsers could have no support for parts of the regex methods
                try {
                    regexVersion = new RegExp('(?:' + browserUA + ')\/?([0-9]+)', 'i');
                } catch (error) {
                    regexVersion = null;
                }
                            
            // Safari version format is: Version/n
            } else if (
                browser === CONSTANTS.BROWSER_SAFARI
            ) {
                // Really old browsers could have no support for parts of the regex methods
                try {
                    regexVersion = new RegExp('(?:' + CONSTANTS.VERSION_STRING + ')\/([0-9]+)', 'i');
                } catch (error) {
                    regexVersion = null;
                }
            }

            // Lack of sandard on userAgent string can have unexpected outcomes.
            // Like on Chrome on iPhone where the user agent does not match Chrome from the rest of OS's.
            // Chrome user agent on iPhone does not include 'Chrome' on the string as of Feb 2024 as seen here:
            // 'Mozilla/5.0 (iPhone; CPU iPhone OS 14_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/92.0.4515.90 Mobile/15E148 Safari/604.1'
            try {
                version = userAgent.match(
                    regexVersion
                )[1];
    
                return parseInt(version);
            } catch (error) {
                version = null;

                return version;
            }
        };

        /**
         * Gets the blacklist that includes blacklisted browsers/versions, if any
         * @return {Array} Contains the blacklisted browsers and versions in the format Browser:n
         */
        var getBrowsersBlacklist = function () {
            var blacklistArr = 
                $unsupportedBrowserBanner
                    .dataset
                    .browsersBlacklistVersions ?
                $unsupportedBrowserBanner
                    .dataset
                    .browsersBlacklistVersions
                    .split(
                        CONSTANTS.VERTICAL_LINE_STRING
                    ) :
                null;

            return blacklistArr;
        };

        /**
         * Gets the hide browser list, if any
         * @return {Array} Contains the browsers where the banner should remain hidden
         */
        var getHideBannerList = function () {
            var hideListArr =
                $unsupportedBrowserBanner
                    .dataset
                    .browsersHideBannerList ?
                $unsupportedBrowserBanner
                    .dataset
                    .browsersHideBannerList
                    .split(
                        CONSTANTS.VERTICAL_LINE_STRING
                    ) :
                null;

            return hideListArr;
        };

        /**
         * Handles if banner should remain hidden or not, if yes then no further validation would be done on it
         * @return Boolean to indicate if the banner should remain hidden or not
         */
        var handleHideBannerList = function () {
            var hideBanner = false;

            for (var index = 0; index < hideList.length; index++) {
                if (browser === hideList[index].toLowerCase()) {
                    hideBanner = true;
                    return hideBanner;
                }
            }

            return hideBanner;
        };

        /**
         * Gets the list of browsers to show the banner, if any
         * @return {Array} Contains the browsers that need to show the banner
         */
        var getShowBannerList = function () {
            var showListArr =
                $unsupportedBrowserBanner
                    .dataset
                    .browsersShowBannerList ?
                $unsupportedBrowserBanner
                    .dataset
                    .browsersShowBannerList                
                    .split(
                        CONSTANTS.VERTICAL_LINE_STRING
                    ) :
                null;

            return showListArr;
        };

        /**
         * Shows the banner if it's on the show list
         * Only validates by browser
         * @return Boolean to indicate if the browser should show or not
         */
        var handleShowBannerList = function () {
            var showBrowser = false;

            for (var index = 0; index < showList.length; index++) {
                if (browser === showList[index].toLowerCase()) {
                    query.removeClass($unsupportedBrowserBanner, CONSTANTS.HIDDEN_CLASS);

                    showBrowser = true;
                    return showBrowser;
                }
            }

            return showBrowser;
        };

        /**
         * Handles if banner should be visible or remain hidden depending on blacklist browser/version
         * Validates by browser and minimum version
         * Unknown browsers are ignored and no action is taken, hence banner remains hidden
         */
        var handleShowBannerBlacklist = function () {
            // IE and Opera legacy are blacklisted by default
            if (
                browser === CONSTANTS.BROWSER_IE ||
                browser === CONSTANTS.BROWSER_OPERA_LEGACY
            ) {
                query.removeClass($unsupportedBrowserBanner, CONSTANTS.HIDDEN_CLASS);
                return;
            }

            var blacklistedItem,
                blacklistedBrowser,
                blacklistedVersion;
            
            // Blacklist format is Browser:n|Browser:n
            for (var index = 0; index < blacklist.length; index++) {
                blacklistedItem = blacklist[index].split(CONSTANTS.COLON_STRING);
                blacklistedBrowser = blacklistedItem[0].toLowerCase();
                blacklistedVersion = parseInt(blacklistedItem[1].toLowerCase());

                if (browser && browser === blacklistedBrowser) {
                    if (browserVersion && browserVersion < blacklistedVersion) {
                        query.removeClass($unsupportedBrowserBanner, CONSTANTS.HIDDEN_CLASS);
                    }
                    return;
                }
            }
        };

        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            userAgent = navigator && navigator.userAgent && navigator.userAgent.toLowerCase();
            browserAndUaArr = getBrowserAndUserAgent();
            browser = browserAndUaArr[0];
            browserUA = browserAndUaArr[1];
            browserVersion = getBrowserVersion();
            hideList = getHideBannerList();
            shouldRemainHidden = hideList && handleHideBannerList();
            showList = getShowBannerList();
            shouldShow = showList && handleShowBannerList();
            blacklist = !shouldRemainHidden && !shouldShow && getBrowsersBlacklist();
        };

        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            !shouldRemainHidden && 
            !shouldShow && 
            blacklist &&
            handleShowBannerBlacklist();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    UnsupportedBrowserBanner.prototype.isLoaded = function () {
        return isLoaded;
    };

    return UnsupportedBrowserBanner;
})();
