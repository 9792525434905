// ==================== BACKGROUND COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.BackgroundComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        MOBILE_IMAGE_CLASS: 'js-mobile-image',
        DESKTOP_IMAGE_CLASS: 'js-desktop-image',
        IMAGE_SRC_ATTRIBUTE: 'srcset'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var BackgroundComponent = function ($containerElement) {
        /**
         * PRIVATE METHODS
         */
        var $mobileImageElement, $desktopImageElement, _onlyDesktop, _onlyMobile, $breakpoint;

        var setBackground = function () {
            var imageUrl,
                browserWidth = window.innerWidth;

            if (browserWidth < $breakpoint) {
                imageUrl = $mobileImageElement.getAttribute(CONSTANTS.IMAGE_SRC_ATTRIBUTE);

                if (_onlyDesktop.toLowerCase() !== 'true') {
                    $containerElement.style.backgroundImage = "url('" + imageUrl + "')";
                }
            } else {
                imageUrl = $desktopImageElement.getAttribute(CONSTANTS.IMAGE_SRC_ATTRIBUTE);

                if (_onlyMobile.toLowerCase() !== 'true') {
                    $containerElement.style.backgroundImage = "url('" + imageUrl + "')";
                }
            }
        };

        var initializeData = function () {
            $mobileImageElement = $containerElement.getElementsByClassName(
                CONSTANTS.MOBILE_IMAGE_CLASS
            )[0];
            $desktopImageElement = $containerElement.getElementsByClassName(
                CONSTANTS.DESKTOP_IMAGE_CLASS
            )[0];

            _onlyDesktop = $containerElement.dataset.onlyDesktop;
            if (!_onlyDesktop) {
                _onlyDesktop = 'false';
                $breakpoint =
                    $containerElement.dataset.bgbreakpoint || coned.constants.MOBILE_MAX_SIZE;
            } else {
                $breakpoint = coned.constants.MOBILE_MAX_SIZE;
            }

            _onlyMobile = $containerElement.dataset.onlyMobile;
            if (!_onlyMobile) {
                _onlyMobile = 'false';
            }
        };

        var initializeEvents = function () {
            if ($mobileImageElement != undefined || $desktopImageElement != undefined) {
                window.addEventListener('resize', function () {
                    setBackground();
                });

                setBackground();
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    BackgroundComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return BackgroundComponent;
})();
