// ==================== SUBMIT A METER COMPONENT =========================
/* global _ */
/* global $ */
/* global gsap */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SubmitMeterForm = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ELECTRIC_ELEMENT_CLASS: '.js-electric-input',
        GAS_ELEMENT_CLASS: '.js-gas-input',
        ELECTRIC_ELEMENT: 'js-electric-input',
        GAS_ELEMENT: 'js-gas-input',
        NO_EZ_OPTION_NAME: 'js-no-ez-options',
        ELECTRIC_METER_INPUT_SELECTOR: 'js-electric-meter-input-selector',
        GAS_METER_INPUT_SELECTOR: 'js-gas-meter-input-selector',
        INPUT_LABEL_CLASS: 'coned-input--filled'
    };

    var _isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var SubmitMeterForm = function ($submitMeterElement) {
        /**
         * PRIVATE METHODS
         */
        var $noEzInputsName,
            $electricMeterInputSelector,
            $gasMeterInputSelector,
            $gasElement,
            $electricElement;

        var initializeData = function () {

            
            ($noEzInputsName = $submitMeterElement.getElementsByClassName(
                CONSTANTS.NO_EZ_OPTION_NAME
            )),
            ($electricMeterInputSelector = $submitMeterElement.getElementsByClassName(
                CONSTANTS.ELECTRIC_METER_INPUT_SELECTOR
            )[0]);
            $gasMeterInputSelector = $submitMeterElement.getElementsByClassName(
                CONSTANTS.GAS_METER_INPUT_SELECTOR
            )[0];
            $gasElement = $submitMeterElement.getElementsByClassName(CONSTANTS.GAS_ELEMENT)[0];
            $electricElement = $submitMeterElement.getElementsByClassName(
                CONSTANTS.ELECTRIC_ELEMENT
            )[0];
        };

        var showElement = function ($ele) {
            gsap.to($ele, {
                duration: 0.3,
                display: 'block',
                height: 'auto',
                opacity: 1
            });
        };

        var hideElement = function ($ele) {
            gsap.to($ele, {
                duration: 0.3,
                display: 'none',
                height: '0',
                opacity: 0
            });
        };

        var getNoEzInputSelection = function (value) {
            if (value === 'electric') {
                showElement($electricMeterInputSelector);
                hideElement($gasMeterInputSelector);
                checkInputValue($electricElement);
            } else {
                hideElement($electricMeterInputSelector);
                showElement($gasMeterInputSelector);
                checkInputValue($gasElement);
            }
        };

        var checkInputValue = function ($element) {
            if ($element.value > 0) {
                query.addClass($element, CONSTANTS.INPUT_LABEL_CLASS);
            }
        };

        var initializeEvents = function () {
            // Non eZ Reader Options EventListener
            _.each($noEzInputsName, function (radio2) {
                radio2.addEventListener('change', function () {
                    getNoEzInputSelection(radio2.value);
                });
            });

            $(CONSTANTS.GAS_ELEMENT_CLASS).change(function () {
                $('#Gas').val(parseInt($(this).val()));
            });

            $(CONSTANTS.ELECTRIC_ELEMENT_CLASS).change(function () {
                $('#Electric').val(parseInt($(this).val()));
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();

            _isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SubmitMeterForm.prototype._isLoaded = function () {
        return _isLoaded;
    };

    return SubmitMeterForm;
})();
