// ==================== MULTIPLE ACCOUNTS COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.MultipleAccounts = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SELECT_ALL_ACCOUNTS_CHECK: 'js-add-all-accounts',
        SELECTED_ACCOUNTS_LINK: 'js-selected-account-link',
        SELECTED_ACCOUNTS_LINK_MIN_WIDTH: '250px',
        MAX_ACCOUNT_ERROR_SELECTOR: 'js-max-accounts-error',
        ACCOUNTS_BUTTON_DISABLED_CLASS: 'register-accounts__banner-button--disabled',
        ACCOUNTS_ITEM_JS: 'js-register-accounts-item',
        ACCOUNT_CHECKBOX_JS: 'js-checkbox-accounts-selector',
        SHOW_MORE_LINK_SELECTOR: 'js-show-more-link-selector',
        ACCOUNT_COUNTER: 'js-account-counter',
        REGISTER_SITECORE_ID: 'ScId',
        USER_ID: 'userId',
        HIDDEN_CLASS: 'hidden',
        SERVICE_ERROR_SELECTOR: 'js-service-error',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        NEXT_FOCUS_QUERY:
            'p:not(.hidden) [tabindex="0"], a[tabindex="0"]:not(.hidden), input:not(.hidden), button:not(.hidden):not(:disabled)',
        REGISTER_ACCOUNTS_SELECTOR: 'js-register-accounts',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',

        // Notification Onboarding
        NOTIFICATION_ONBOARDING_SELECTOR: 'js-notification-onboarding',
        TURN_ON_EMAIL_NOTIFICATION_EVENT: 'turn-on-email-notification'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var MultipleAccounts = function ($multipleAccounts) {
        /**
         * PRIVATE METHODS
         */
        var $selectAllAccountsCheckbox,
            $selectedAccountsLink,
            $accountItems,
            $accountCounter,
            $showMoreLinkSelector,
            $serviceError,
            $maxAccountsError,
            $registerAccountsStep,
            $notificationOnboardingStep,
            $submitAnimationButton,
            _firstClickAccountsAssociated,
            _data;

        var selectAllAccounts = function () {
            if (this.checked) {
                var maxAccounts =
                    $multipleAccounts.dataset.maxAccounts &&
                    parseInt($multipleAccounts.dataset.maxAccounts)
                        ? parseInt($multipleAccounts.dataset.maxAccounts)
                        : 750;
                $selectedAccountsLink.disabled = false;

                _.each($accountItems, function ($item) {
                    $item.checked = true;
                });

                // Analytics data building
                dataLayer.push({
                    event: 'add.account.process',
                    'add.account': 'step01a'
                });

                if (_firstClickAccountsAssociated) {
                    dataLayer.push({
                        event: 'add.account.process',
                        'add.account': 'step01opt'
                    });

                    _firstClickAccountsAssociated = false;
                }

                if ($accountItems.length > maxAccounts) {
                    showMaxAccountErrorMessage();
                }

                $accountCounter.innerHTML = $accountItems.length;

                this.parentElement.classList.add('coned-checkbox--checked');
            } else {
                $selectedAccountsLink.disabled = true;
                $accountCounter.innerHTML = '0';

                _.each($accountItems, function ($item) {
                    $item.checked = false;
                });

                this.parentElement.classList.remove('coned-checkbox--checked');
                hideErrorMessage();
            }
        };

        var showAccounts = function (event) {
            event.preventDefault();

            var temporalCheckbox;
            _.each($accountItems, function ($checkbox) {
                temporalCheckbox = query.selectParentElement($checkbox, CONSTANTS.ACCOUNTS_ITEM_JS);
                temporalCheckbox.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });

            $showMoreLinkSelector.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var hideAccounts = function () {
            var temporalCheckbox;

            _.each($accountItems, function ($checkbox, index) {
                if (index > 11) {
                    temporalCheckbox = query.selectParentElement(
                        $checkbox,
                        CONSTANTS.ACCOUNTS_ITEM_JS
                    );
                    temporalCheckbox.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            });

            if ($accountItems.length > 11) {
                $showMoreLinkSelector.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var selectedAccountsLinkEvent = function (event) {
            event.preventDefault();

            var nAccountsChecked = 0;

            _.each($accountItems, function ($checkbox) {
                if ($checkbox.checked) {
                    nAccountsChecked++;
                }
            });

            if (nAccountsChecked > 0) {
                $selectedAccountsLink.classList.remove(CONSTANTS.ACCOUNTS_BUTTON_DISABLED_CLASS);
                addAccountsHandler();
            }

            hideErrorMessage();
        };

        var accountSelected = function () {
            var itemsChecked = _.filter($accountItems, function ($checkbox) {
                    return $checkbox.checked;
                }).length,
                maxAccounts =
                    $multipleAccounts.dataset.maxAccounts &&
                    parseInt($multipleAccounts.dataset.maxAccounts)
                        ? parseInt($multipleAccounts.dataset.maxAccounts)
                        : 750;

            if (itemsChecked > 0) {
                $selectedAccountsLink.disabled = false;

                // Analytics data building
                if (_firstClickAccountsAssociated) {
                    dataLayer.push({
                        event: 'add.account.process',
                        'add.account': 'step01opt'
                    });

                    _firstClickAccountsAssociated = false;
                }
            } else {
                $selectedAccountsLink.disabled = true;
            }

            if (itemsChecked === $accountItems.length) {
                $selectAllAccountsCheckbox.checked = true;
            } else {
                $selectAllAccountsCheckbox.checked = false;
            }

            $accountCounter.innerHTML = itemsChecked;

            if (itemsChecked > maxAccounts) {
                showMaxAccountErrorMessage();
            } else {
                hideErrorMessage();
            }
        };

        /**
         * Shows the submit animation and handles add accounts
         */
        var addAccountsHandler =  function () {
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                addAccounts,
                showNotificationOnboardingStep,
                errorCallback,
                false,
                true
            );
        };

        /**
         * Add account service call
         */
        var addAccounts = function () {
            var serviceUrl = $multipleAccounts.dataset.addAccountsService,
                params,
                Accounts = [];

            _.each($accountItems, function ($checkbox) {
                if ($checkbox.checked) {
                    Accounts.push({ Maid: $checkbox.name });
                }
            });

            params = {
                ScId: query.getFormInputValue($multipleAccounts, CONSTANTS.REGISTER_SITECORE_ID),
                UserId: query.getFormInputValue($multipleAccounts, CONSTANTS.USER_ID),
                Accounts: Accounts
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successAddAcountsCallback,
                errorAddAccountsCallback,
                params,
                true
            );
        };

        /**
         * On success sets class to stop submit animation and triggers email notification event
         */
        var successAddAcountsCallback = function () {
            query.addClass(
                $submitAnimationButton,
                coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
            );
            $selectedAccountsLink.style.minWidth = CONSTANTS.SELECTED_ACCOUNTS_LINK_MIN_WIDTH;
            coned.utils.triggerEvent($notificationOnboardingStep, CONSTANTS.TURN_ON_EMAIL_NOTIFICATION_EVENT);
        };

        /**
         * Shows error on UI
         */ 
        var errorCallback = function () {
            var $msgTextSelector = $serviceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = _data.errorMsg
                ? _data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        /**
         * On error sets class to stop submit animation and sets global _data
         */        
        var errorAddAccountsCallback = function (data) {
            query.addClass(
                $submitAnimationButton,
                coned.constants.ERROR_SERVICE_RESPONSE_CLASS
            );

            _data = data
        };

        var showMaxAccountErrorMessage = function () {
            $maxAccountsError.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $maxAccountsError.focus();
        };

        var hideErrorMessage = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $maxAccountsError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Shows the notification onboarding step
         */
        var showNotificationOnboardingStep = function () {
            query.addClass($registerAccountsStep, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($notificationOnboardingStep, CONSTANTS.HIDDEN_CLASS);
            
            $notificationOnboardingStep.focus();
            window.scrollTo(0, 0);
        };

        var initializeData = function () {
            $accountItems = $multipleAccounts.getElementsByClassName(CONSTANTS.ACCOUNT_CHECKBOX_JS);
            $accountCounter = $multipleAccounts.getElementsByClassName(
                CONSTANTS.ACCOUNT_COUNTER
            )[0];
            $selectedAccountsLink = $multipleAccounts.getElementsByClassName(
                CONSTANTS.SELECTED_ACCOUNTS_LINK
            )[0];
            $selectAllAccountsCheckbox = $multipleAccounts.getElementsByClassName(
                CONSTANTS.SELECT_ALL_ACCOUNTS_CHECK
            )[0];
            $showMoreLinkSelector = $multipleAccounts.getElementsByClassName(
                CONSTANTS.SHOW_MORE_LINK_SELECTOR
            )[0];
            $serviceError = $multipleAccounts.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_SELECTOR
            )[0];
            $maxAccountsError = $multipleAccounts.getElementsByClassName(
                CONSTANTS.MAX_ACCOUNT_ERROR_SELECTOR
            )[0];
            $registerAccountsStep = $multipleAccounts.getElementsByClassName(
                CONSTANTS.REGISTER_ACCOUNTS_SELECTOR
            )[0];
            $notificationOnboardingStep = $multipleAccounts.getElementsByClassName(
                CONSTANTS.NOTIFICATION_ONBOARDING_SELECTOR
            )[0];
            $submitAnimationButton = $multipleAccounts.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            _firstClickAccountsAssociated = true;
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($showMoreLinkSelector, showAccounts);
            coned.utils.addGeneralListeners($selectedAccountsLink, selectedAccountsLinkEvent);
            $selectAllAccountsCheckbox.addEventListener('change', selectAllAccounts);
            hideAccounts();

            $selectedAccountsLink.disabled = true;

            _.each($accountItems, function ($accountCheck) {
                coned.utils.addGeneralListeners($accountCheck, accountSelected);
            });

            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/add-account/select-matched-accounts'
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    MultipleAccounts.prototype.isLoaded = function () {
        return isLoaded;
    };

    return MultipleAccounts;
})();
