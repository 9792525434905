// ==================== MULTI FACTOR AUTHENTICATION COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.MultiFactorAuthentication = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        MFA_FORM: 'js-mfa-parent-form',
        CHECKBOX_NO_PHONE: 'js-checkbox-selector',
        INPUT_PHONE_SELECTOR: 'js-input-phone-selector',
        PHONE_VERIFICATION_CONTAINER: 'js-phone-verification-container',
        SECURITY_QUESTION_CONTAINER: 'js-question-container',
        VERIFICATION_METHOD_SELECT: 'js-step-verification-select',
        VERIFICATION_METHOD_TEXT: 'js-verification-text',
        VERIFICATION_METHOD_CONTAINER: 'js-method-container',
        IGNORE_CLASS: 'js-validate-ignore',
        VALIDATE_CLASS: 'js-item-validate',
        SELECT_CLASS_SELECTOR: 'js-coned-select',
        INPUT_CLASS_SELECTOR: 'js-coned-input',
        FILLED_CLASS: 'coned-input--filled',
        HIDDEN_CLASS: 'hidden',
        ERROR_MESSAGE_CLASS: 'coned-input-message--error',
        VERIFICATION_OPTION_GOOGLE: 'google',
        VERIFICATION_OPTION_OKTA: 'okta',
        VERIFICATION_OPTION_CALL: 'call',
        VERIFICATION_OPTION_SMS: 'sms',
        VERIFICATION_OPTION_QUESTION: 'question',
        GET_CODE_BUTTON: 'js-get-code',
        GET_CODE_INPUT_CONTAINER: 'js-get-code-input-container',
        CODE_CALL_DESCRIPTION: 'js-call-description',
        CODE_SMS_DESCRIPTION: 'js-sms-description',
        TRY_AGAIN_BUTTON: 'js-try-again',
        DEVICE_CONTAINER: 'js-device-container',
        DEVICE_SELECTOR: 'js-select-device',
        DEVICE_DOWNLOAD_MESSAGE: 'js-device-message',
        SCAN_CODE_CONTAINER: 'js-scan-code',
        NO_SCAN_CONTAINER: 'js-no-scan',
        SCAN_CODE_INPUT_CONTAINER: 'js-enter-code',
        SCAN_LINK: 'js-scan-link',
        CANT_SCAN_LINK: 'js-cant',
        GOOGLE_GET_SCAN_CODE_BUTTON: 'js-get-scan-code-google',
        OKTA_GET_SCAN_CODE_BUTTON: 'js-get-scan-code-okta',
        GET_SCAN_CODE_BUTTON: 'js-get-scan-code',
        VERIFICATION_CODE_INPUT: 'js-verification-code',
        VERIFICATION_GOOGLE_CODE: 'js-google-code',
        VERIFICATION_OKTA_CODE: 'js-okta-code',
        VERIFICATION_SECURITY_ANSWER: 'js-security-answer',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        SITECORE_ID: 'ScId',
        USER_ID: 'userId',
        MOBILE_PHONE: 'mfaMobilePhone',
        VERIFICATON_BY: 'verificationOption',
        PASSWORD: 'registerPasword',
        QUESTION: 'securityQuestion',
        ANSWER: 'securityAnswer',
        VERIFICATION_CODE: 'verificationCode',
        VERIFICATION_INPUTS: 'js-verification-input',
        GOOGLE_SCAN_CODE_IMAGE: 'js-google-qrcode-img',
        GOOGLE_SCAN_CODE_TEXT: 'js-google-qrcode-text',
        OKTA_SCAN_CODE_IMAGE: 'js-okta-qrcode-img',
        OKTA_SCAN_CODE_TEXT: 'js-okta-qrcode-text',
        NO_PHONE_ERROR: 'js-no-phone-error',
        QUESTION_CODE: 'js-question-code',
        PASSWORD_VALIDATION_CONTAINER: 'js-password-validation',
        VALIDATION_ERROR_CLASS: 'register__validation--error',
        FORM_MFA_VALID: 'mfa-is-valid',
        CAPTCHA_ELEMENT: 'js-recaptcha',

        // Tagging
        TAGGING_MFA_SELECT: 'Step05.mfa',
        TAGGING_NO_PHONE: 'Step05.No.Mobile.Check.Box'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var MultiFactorAuthentication = function ($multiFactor) {
        /**
         * PRIVATE METHODS
         */
        var $activeScan,
            $activeNoScan,
            $activeScanCode,
            $activeScanButton,
            $activeScanMethod,
            $activeCantScanButton,
            $formParent,
            $checkboxNoPhone,
            $inputPhoneSelector,
            $userSelectedMethod,
            $activeDeviceVerification,
            $getCodeInputContainer,
            $codeSmsDescription,
            $codeCallDescription,
            $deviceDownloadMessage,
            $verificationMethodSelect,
            $getCodeButton,
            $tryAgainButton,
            $deviceSelector,
            $phoneVerificationContainer,
            $phoneVerificationSelect,
            $serviceErrorMessage,
            $serviceError,
            $securityQuestionContainer,
            $securityQuestionInput,
            $securityQuestionSelect,
            $verificationMethodText,
            $verificationMethodContainer,
            $scanLink,
            $googleGetScanCodeButton,
            $oktaGetScanCodeButton,
            $verificationCodeInput,
            $submitButton,
            $verificationAnswerInput,
            $verificationOktaInput,
            $verificationGoogleInput,
            $formLoading,
            $googleScanImage,
            $googleScanCode,
            $oktaScanImage,
            $oktaScanCode,
            $verificationInputs,
            $noPhoneError,
            $passwordValidationContainer,
            $captchaElement,
            recaptcha,
            _hasCaptcha,
            _activeDevice,
            _selectedVerificationMethod,
            _validator,
            _mfaTypeEditProfile,
            _isFirstSet;

        var validatedPhone = function () {
            var phoneValid = $($inputPhoneSelector).valid();

            if (phoneValid) {
                $verificationMethodSelect.disabled = false;
            } else {
                $verificationMethodSelect.disabled = true;
                $verificationMethodSelect.options[0].selected = true;

                _.each($verificationMethodContainer, function (container) {
                    container.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                _.each($verificationMethodText, function (optionDescription) {
                    optionDescription.classList.add(CONSTANTS.HIDDEN_CLASS);
                });
            }
        };

        var validateDevice = function () {
            if (coned.utils.isMobile()) {
                $activeScan.classList.add(CONSTANTS.HIDDEN_CLASS);
                $activeScanCode.classList.add(CONSTANTS.HIDDEN_CLASS);
                $activeNoScan.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $activeScanButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $activeScan.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $activeScanCode.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $activeNoScan.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var isVerificationValid = function () {
            var verificationValid = $(this).valid(),
                $passwordError = $passwordValidationContainer
                    ? $passwordValidationContainer.getElementsByClassName(
                          CONSTANTS.VALIDATION_ERROR_CLASS
                      )
                    : '';

            if (verificationValid && $passwordError.length <= 0) {
                $submitButton.disabled = false;
                $formParent.classList.add(CONSTANTS.FORM_MFA_VALID);
            } else {
                $submitButton.disabled = true;
                $formParent.classList.remove(CONSTANTS.FORM_MFA_VALID);
            }
        };

        var googleGetCode = function (event) {
            event.preventDefault();

            var $activeScanCode = $userSelectedMethod.getElementsByClassName(
                    CONSTANTS.SCAN_CODE_INPUT_CONTAINER
                )[0],
                $activeNoScan = $userSelectedMethod.getElementsByClassName(
                    CONSTANTS.NO_SCAN_CONTAINER
                )[0];

            $activeScanCode.classList.remove(CONSTANTS.HIDDEN_CLASS);
            this.classList.add(CONSTANTS.HIDDEN_CLASS);
            $activeNoScan.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var oktaGetCode = function (event) {
            event.preventDefault();

            var $activeScanCode = $userSelectedMethod.getElementsByClassName(
                    CONSTANTS.SCAN_CODE_INPUT_CONTAINER
                )[0],
                $activeNoScan = $userSelectedMethod.getElementsByClassName(
                    CONSTANTS.NO_SCAN_CONTAINER
                )[0];

            $activeScanCode.classList.remove(CONSTANTS.HIDDEN_CLASS);
            this.classList.add(CONSTANTS.HIDDEN_CLASS);
            $activeNoScan.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var scanLink = function (event) {
            event.preventDefault();

            if (query.hasClass(this, CONSTANTS.CANT_SCAN_LINK)) {
                $activeScan.classList.add(CONSTANTS.HIDDEN_CLASS);
                $activeScanCode.classList.add(CONSTANTS.HIDDEN_CLASS);
                $activeNoScan.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $activeScanButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $activeScan.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $activeScanCode.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $activeNoScan.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var deviceSelection = function () {
            var selectedDevice = this.options[this.selectedIndex].value,
                deviceMessage = 'js-message-' + selectedDevice,
                $messageToShow = $activeDeviceVerification.getElementsByClassName(deviceMessage)[0];

            $activeScan = $userSelectedMethod.getElementsByClassName(
                CONSTANTS.SCAN_CODE_CONTAINER
            )[0];
            $activeNoScan = $userSelectedMethod.getElementsByClassName(
                CONSTANTS.NO_SCAN_CONTAINER
            )[0];
            $activeScanCode = $userSelectedMethod.getElementsByClassName(
                CONSTANTS.SCAN_CODE_INPUT_CONTAINER
            )[0];
            $activeScanButton = $userSelectedMethod.getElementsByClassName(
                CONSTANTS.GET_SCAN_CODE_BUTTON
            )[0];
            $activeScanMethod = $userSelectedMethod.getElementsByClassName(
                CONSTANTS.SCAN_CODE_CONTAINER
            )[0];
            $activeCantScanButton = $userSelectedMethod.getElementsByClassName(
                CONSTANTS.CANT_SCAN_LINK
            )[0];

            _.each($deviceDownloadMessage, function (message) {
                message.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            $messageToShow.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $activeScanMethod.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $activeScanCode.classList.remove(CONSTANTS.HIDDEN_CLASS);

            if (coned.utils.isMobile()) {
                $activeCantScanButton.click();
            }

            window.addEventListener('resize', validateDevice);
        };

        var tryAgain = function (event) {
            if (event) {
                event.preventDefault();
            }

            if (!_mfaTypeEditProfile) {
                $submitButton.disabled = true;
            }

            $verificationCodeInput.value = '';
            $getCodeInputContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $getCodeButton.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Check if selected verification method is sms or call and the response is not empty to reset the recaptcha value.
            if (
                _selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_CALL ||
                _selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_SMS
            ) {
                if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                    recaptcha.reset();
                }
            }

            if (_hasCaptcha && $captchaElement[0].classList.contains(CONSTANTS.HIDDEN_CLASS)) {
                $captchaElement[0].classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        // no phone checkbox handler
        var noPhoneOption = function () {
            var isChecked = $checkboxNoPhone.checked;

            if (isChecked) {
                $inputPhoneSelector.disabled = true;
                $inputPhoneSelector.value = '';
                $inputPhoneSelector.classList.remove(CONSTANTS.FILLED_CLASS);
                $inputPhoneSelector.classList.remove(CONSTANTS.ERROR_MESSAGE_CLASS);
                $inputPhoneSelector.classList.add(CONSTANTS.IGNORE_CLASS);
                $verificationMethodSelect.disabled = true;

                _validator.resetForm();

                // Dropdown Switch
                $verificationCodeInput.classList.add(CONSTANTS.IGNORE_CLASS);
                $verificationMethodSelect.classList.add(CONSTANTS.IGNORE_CLASS);
                $phoneVerificationContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $inputPhoneSelector.classList.add(CONSTANTS.IGNORE_CLASS);
                $phoneVerificationSelect.classList.add(CONSTANTS.IGNORE_CLASS);
                $securityQuestionContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $securityQuestionInput.classList.remove(CONSTANTS.IGNORE_CLASS);
                $securityQuestionSelect.classList.remove(CONSTANTS.IGNORE_CLASS);
            } else {
                $inputPhoneSelector.disabled = false;
                $inputPhoneSelector.classList.remove(CONSTANTS.IGNORE_CLASS);

                // Dropdown Switch
                $securityQuestionContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $inputPhoneSelector.classList.remove(CONSTANTS.IGNORE_CLASS);
                $phoneVerificationSelect.classList.remove(CONSTANTS.IGNORE_CLASS);
                $securityQuestionInput.classList.add(CONSTANTS.IGNORE_CLASS);
                $securityQuestionSelect.classList.add(CONSTANTS.IGNORE_CLASS);
                $phoneVerificationContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $verificationCodeInput.classList.remove(CONSTANTS.IGNORE_CLASS);
                $verificationMethodSelect.classList.remove(CONSTANTS.IGNORE_CLASS);
            }

            hideError();

            _.each($verificationMethodContainer, function (container) {
                container.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($verificationMethodText, function (optionDescription) {
                optionDescription.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            $verificationMethodSelect.options[0].selected = true;
        };

        var switchVerificationMethod = function () {
            // set verification method description
            var phoneValid = $inputPhoneSelector.value !== '' && $($inputPhoneSelector).valid();

            _selectedVerificationMethod =
                $verificationMethodSelect.options[$verificationMethodSelect.selectedIndex].value;

            _.each($verificationMethodContainer, function (container) {
                container.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            if (
                phoneValid ||
                _selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_QUESTION
            ) {
                showHideVerificationMethod(_selectedVerificationMethod);

                if ($noPhoneError) {
                    $noPhoneError.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                if ($noPhoneError) {
                    $noPhoneError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            }
            _validator.resetForm();
            hideError();

            // Check if selected verification method is sms or call to initialize recaptcha
            if (
                _selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_CALL ||
                _selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_SMS
            ) {
                if (_hasCaptcha && !recaptcha) {
                    recaptcha = new coned.components.Recaptcha($formParent, getCode, getCode);
                }
            }
        };

        var showHideVerificationMethod = function (_selectedVerificationMethod) {
            var _selectedMethod = 'js-' + _selectedVerificationMethod + '-container',
                _selectedInput = 'js-' + _selectedVerificationMethod + '-code',
                activeMessageClass = 'js-' + _selectedVerificationMethod + '-message',
                activeDescription = $multiFactor.getElementsByClassName(activeMessageClass)[0],
                $userSelectedInput = $multiFactor.getElementsByClassName(_selectedInput)[0],
                isQuestionOption = query.hasClass($userSelectedInput, CONSTANTS.QUESTION_CODE);

            $userSelectedMethod = $multiFactor.getElementsByClassName(_selectedMethod)[0];

            if (_selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_SMS) {
                activeDescription.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $getCodeButton.firstElementChild.innerHTML = $getCodeButton.dataset.text;
                $codeSmsDescription.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $codeCallDescription.classList.add(CONSTANTS.HIDDEN_CLASS);

                tryAgain();
            } else if (_selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_CALL) {
                $getCodeButton.firstElementChild.innerHTML = $getCodeButton.dataset.replaceText;
                activeDescription.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $codeSmsDescription.classList.add(CONSTANTS.HIDDEN_CLASS);
                $codeCallDescription.classList.remove(CONSTANTS.HIDDEN_CLASS);

                tryAgain();
            } else if (_selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_QUESTION) {
                _activeDevice = 'js-device-container-' + _selectedVerificationMethod;
                $activeDeviceVerification = $multiFactor.getElementsByClassName(_activeDevice)[0];
                $verificationCodeInput.classList.add(CONSTANTS.IGNORE_CLASS);
            } else {
                _activeDevice = 'js-device-container-' + _selectedVerificationMethod;
                $activeDeviceVerification = $multiFactor.getElementsByClassName(_activeDevice)[0];
                $verificationCodeInput.classList.add(CONSTANTS.IGNORE_CLASS);

                getCode();
            }

            _.each($verificationMethodText, function (optionDescription) {
                optionDescription.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($verificationInputs, function ($verificationInput) {
                var isSecurityQuestion = query.hasClass(
                    $verificationInput,
                    CONSTANTS.QUESTION_CODE
                );

                if (!isSecurityQuestion) {
                    $verificationInput.name = '';
                }

                $verificationInput.classList.add(CONSTANTS.IGNORE_CLASS);
                $verificationInput.classList.remove(CONSTANTS.VALIDATE_CLASS);
            });

            if (!isQuestionOption) {
                $userSelectedInput.name = CONSTANTS.VERIFICATION_CODE;
            }

            $userSelectedInput.classList.remove(CONSTANTS.IGNORE_CLASS);
            $userSelectedInput.classList.add(CONSTANTS.VALIDATE_CLASS);
            $userSelectedMethod.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var getCodeResponse = function (data) {
            hideError();

            $getCodeInputContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $formParent.dataset.factorId = data.FactorId;

            if (_selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_GOOGLE) {
                $googleScanImage.src = data.ImageSource;
                $googleScanCode.innerHTML = data.SharedSecret;
            } else if (_selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_OKTA) {
                $oktaScanImage.src = data.ImageSource;
                $oktaScanCode.innerHTML = data.SharedSecret;
            }
        };

        var getCodeValidation = function (data) {
            if (coned.utils.isPatternLab()) {
                var getCodeValidationService = coned.plConstants.GET_CODE_REGISTER;

                query.getData(getCodeValidationService, getCodeResponse, function () {});
            } else {
                getCodeResponse(data);
            }

            $getCodeButton.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var getCode = function (event) {
            if (event) {
                event.preventDefault();
            }

            var servideUrl = $multiFactor.dataset.codeService,
                phoneValid = $inputPhoneSelector ? $($inputPhoneSelector).valid() : true,
                resetCurrentMfa = $multiFactor.dataset.resetCurrentMfa
                    ? $multiFactor.dataset.resetCurrentMfa
                    : null,
                params;

            if (phoneValid) {
                params = {
                    FirstSet: _isFirstSet,
                    ScId: query.getFormInputValue($formParent, CONSTANTS.SITECORE_ID),
                    UserId: query.getFormInputValue($formParent, CONSTANTS.USER_ID),
                    MobilePhoneNumber: query.getFormInputValue($formParent, CONSTANTS.MOBILE_PHONE),
                    StepVerification: query.getFormInputValue(
                        $formParent,
                        CONSTANTS.VERIFICATON_BY
                    ),
                    ResetCurrentMfaFactor: resetCurrentMfa
                };

                // verify if captcha has response
                if (
                    _selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_CALL ||
                    _selectedVerificationMethod == CONSTANTS.VERIFICATION_OPTION_SMS
                ) {
                    if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                        params.Verify = recaptcha.getResponse();
                    }
                }

                // hide captcha
                if (_hasCaptcha && !$captchaElement[0].classList.contains(CONSTANTS.HIDDEN_CLASS)) {
                    $captchaElement[0].classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                params = JSON.stringify(params);
                query.postData(
                    servideUrl,
                    getCodeValidation,
                    serviceError,
                    params,
                    true,
                    $formLoading
                );
            }
        };

        var serviceError = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.DEFAULT_SERVICE_ERROR, showError, showError);
            } else {
                showError(data);
                if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                    recaptcha.reset();
                }
            }
        };

        var showError = function (data) {
            var errorMsg = data.errorMsg,
                $moduleServiceError = $multiFactor.getElementsByClassName(CONSTANTS.SERVICE_ERROR),
                $moduleServiceErrorMessage = $multiFactor.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                );

            _.each($moduleServiceError, function (serviceError) {
                serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($moduleServiceErrorMessage, function (serviceErrorMessage) {
                serviceErrorMessage.innerHTML = errorMsg;
            });
        };

        var hideError = function () {
            _.each($serviceError, function (serviceError) {
                serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorMessage, function (serviceErrorMessage) {
                serviceErrorMessage.innerHTML = '';
            });
        };

        var checkRecaptcha = function (event) {
            if (event) {
                event.preventDefault();
            }

            if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                recaptcha.checkRecaptcha();
            } else if (!_hasCaptcha) {
                getCode();
            }
        };

        var initializeData = function () {
            $formParent = query.selectParentElement($multiFactor, CONSTANTS.MFA_FORM);
            $checkboxNoPhone = $multiFactor.getElementsByClassName(CONSTANTS.CHECKBOX_NO_PHONE)[0];
            $inputPhoneSelector = $formParent.getElementsByClassName(
                CONSTANTS.INPUT_PHONE_SELECTOR
            )[0];
            $phoneVerificationContainer = $multiFactor.getElementsByClassName(
                CONSTANTS.PHONE_VERIFICATION_CONTAINER
            )[0];
            $phoneVerificationSelect = $multiFactor.getElementsByClassName(
                CONSTANTS.PHONE_VERIFICATION_CONTAINER
            )[0];
            $securityQuestionContainer = $multiFactor.getElementsByClassName(
                CONSTANTS.SECURITY_QUESTION_CONTAINER
            )[0];
            $securityQuestionSelect = $securityQuestionContainer.getElementsByClassName(
                CONSTANTS.SELECT_CLASS_SELECTOR
            )[0];
            $securityQuestionInput = $securityQuestionContainer.getElementsByClassName(
                CONSTANTS.INPUT_CLASS_SELECTOR
            )[0];
            $verificationMethodSelect = $multiFactor.getElementsByClassName(
                CONSTANTS.VERIFICATION_METHOD_SELECT
            )[0];
            $verificationMethodText = $multiFactor.getElementsByClassName(
                CONSTANTS.VERIFICATION_METHOD_TEXT
            );
            $verificationMethodContainer = $multiFactor.getElementsByClassName(
                CONSTANTS.VERIFICATION_METHOD_CONTAINER
            );
            $getCodeButton = $multiFactor.getElementsByClassName(CONSTANTS.GET_CODE_BUTTON)[0];
            $getCodeInputContainer = $multiFactor.getElementsByClassName(
                CONSTANTS.GET_CODE_INPUT_CONTAINER
            )[0];
            $serviceError = document.getElementsByClassName(CONSTANTS.SERVICE_ERROR);
            $serviceErrorMessage = document.getElementsByClassName(CONSTANTS.SERVICE_ERROR_MESSAGE);
            $codeSmsDescription = $multiFactor.getElementsByClassName(
                CONSTANTS.CODE_SMS_DESCRIPTION
            )[0];
            $codeCallDescription = $multiFactor.getElementsByClassName(
                CONSTANTS.CODE_CALL_DESCRIPTION
            )[0];
            $tryAgainButton = $multiFactor.getElementsByClassName(CONSTANTS.TRY_AGAIN_BUTTON);
            $deviceSelector = $multiFactor.getElementsByClassName(CONSTANTS.DEVICE_SELECTOR);
            $deviceDownloadMessage = $multiFactor.getElementsByClassName(
                CONSTANTS.DEVICE_DOWNLOAD_MESSAGE
            );
            $scanLink = $multiFactor.getElementsByClassName(CONSTANTS.SCAN_LINK);
            $googleGetScanCodeButton = $multiFactor.getElementsByClassName(
                CONSTANTS.GOOGLE_GET_SCAN_CODE_BUTTON
            )[0];
            $oktaGetScanCodeButton = $multiFactor.getElementsByClassName(
                CONSTANTS.OKTA_GET_SCAN_CODE_BUTTON
            )[0];
            $verificationCodeInput = $multiFactor.getElementsByClassName(
                CONSTANTS.VERIFICATION_CODE_INPUT
            )[0];
            $verificationGoogleInput = $multiFactor.getElementsByClassName(
                CONSTANTS.VERIFICATION_GOOGLE_CODE
            )[0];
            $verificationOktaInput = $multiFactor.getElementsByClassName(
                CONSTANTS.VERIFICATION_OKTA_CODE
            )[0];
            $verificationAnswerInput = $multiFactor.getElementsByClassName(
                CONSTANTS.VERIFICATION_SECURITY_ANSWER
            )[0];
            $submitButton = $formParent.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $formLoading = $formParent.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $googleScanImage = $multiFactor.getElementsByClassName(
                CONSTANTS.GOOGLE_SCAN_CODE_IMAGE
            )[0];
            $googleScanCode = $multiFactor.getElementsByClassName(
                CONSTANTS.GOOGLE_SCAN_CODE_TEXT
            )[0];
            $oktaScanImage = $multiFactor.getElementsByClassName(CONSTANTS.OKTA_SCAN_CODE_IMAGE)[0];
            $oktaScanCode = $multiFactor.getElementsByClassName(CONSTANTS.OKTA_SCAN_CODE_TEXT)[0];
            $verificationInputs = $multiFactor.getElementsByClassName(
                CONSTANTS.VERIFICATION_INPUTS
            );
            $noPhoneError = $multiFactor.getElementsByClassName(CONSTANTS.NO_PHONE_ERROR)[0];
            $passwordValidationContainer = document.getElementsByClassName(
                CONSTANTS.PASSWORD_VALIDATION_CONTAINER
            )[0];
            $captchaElement = document.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT);
            _hasCaptcha = $captchaElement.length > 0;
            _validator = $($formParent).validate();
            _mfaTypeEditProfile = $multiFactor.dataset.editProfile;
            _isFirstSet = !!$multiFactor.dataset.firstSet;
        };

        var initializeEvents = function () {
            if (!_mfaTypeEditProfile) {
                $checkboxNoPhone.addEventListener('change', noPhoneOption);
                $inputPhoneSelector.addEventListener('keyup', validatedPhone);
                $inputPhoneSelector.addEventListener('change', validatedPhone);
                $verificationCodeInput.addEventListener('keyup', isVerificationValid);
                $verificationGoogleInput.addEventListener('keyup', isVerificationValid);
                $verificationOktaInput.addEventListener('keyup', isVerificationValid);
                $verificationAnswerInput.addEventListener('keyup', isVerificationValid);
            }

            $verificationMethodSelect.addEventListener('change', switchVerificationMethod);
            coned.utils.addGeneralListeners($getCodeButton, checkRecaptcha);

            coned.utils.addGeneralListeners($googleGetScanCodeButton, googleGetCode);
            coned.utils.addGeneralListeners($oktaGetScanCodeButton, oktaGetCode);

            _.each($tryAgainButton, function (tryButton) {
                coned.utils.addGeneralListeners(tryButton, tryAgain);
            });

            _.each($deviceSelector, function (selectDevice) {
                selectDevice.addEventListener('change', deviceSelection);
            });

            _.each($scanLink, function ($scanLink) {
                coned.utils.addGeneralListeners($scanLink, scanLink);
            });

            // Tagging
            if ($multiFactor.dataset.tagging) {
                if (!_mfaTypeEditProfile) {
                    $checkboxNoPhone.addEventListener('change', function () {
                        if ($checkboxNoPhone.checked) {
                            dataLayer.push({
                                event: $multiFactor.dataset.tagging + CONSTANTS.TAGGING_NO_PHONE
                            });
                        }
                    });
                }

                $verificationMethodSelect.addEventListener('change', function () {
                    dataLayer.push({
                        event: $multiFactor.dataset.tagging + CONSTANTS.TAGGING_MFA_SELECT,
                        pilotRegMFA: $verificationMethodSelect.options[
                            $verificationMethodSelect.selectedIndex
                        ].text.trim()
                    });
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    MultiFactorAuthentication.prototype.isLoaded = function () {
        return isLoaded;
    };

    return MultiFactorAuthentication;
})();
