// ==================== PAYMENT EXTENSION COMPONENT =========================
/* global dataLayer */
/* global QSI */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PaymentExtension = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        PAYMENT_EXTENSION_FORM: '.js-payment-extension-form',
        SUCCESS_SELECTOR: 'js-success-selector',
        SUCCESS_MESSAGE_SELECTOR: 'js-success-message',
        DATE_SELECTOR: 'js-date',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        FORM_SITECORE_ID_NAME: 'scId',
        FORM_DATE_NAME: 'date',
        FORM_LOADING: 'js-form-loading',
        SERVICE_ERROR: 'js-service-error',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        HEADER: 'js-header-wrapper',
        CANCEL_BUTTON: 'js-close-button',
        DATE_TOKEN: '@DATE'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var PaymentExtension = function ($paymentExtension) {
        /**
         * PRIVATE METHODS
         */
        var $successSelector,
            $successMessageSelector,
            $dateSelector,
            $accountMaidInput,
            $formLoading,
            $serviceError,
            $header,
            $cancelButton;

        var submitPaymentExtension = function () {
            var serviceUrl = $paymentExtension.dataset.serviceUrl,
                newDueDate = $paymentExtension.dataset.newDueDate,
                params;

            hideError();

            // Service Data
            params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($paymentExtension, CONSTANTS.FORM_SITECORE_ID_NAME),
                Date: newDueDate === '' ? $dateSelector.value : newDueDate
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successPaymentExtensionService,
                errorPaymentExtensionService,
                params,
                true,
                $formLoading
            );
        };

        var hideError = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var successPaymentExtensionService = function () {
            var locale = $dateSelector.dataset.locale,
                successMessage = $successMessageSelector.innerHTML,
                paymentDay = $dateSelector.dataset.paymentDay,
                newDueDay = $dateSelector.value,
                day = 24 * 60 * 60 * 1000,
                startDate = paymentDay !== '' ? new Date(paymentDay) : new Date(),
                endDate = new Date(newDueDay),
                diffDays = Math.ceil((endDate.getTime() - startDate.getTime()) / day),
                localeDateObj = { year: 'numeric', month: 'long', day: 'numeric' };

            // Setting dynamic values
            successMessage = successMessage.replace('{{DAYS}}', diffDays);
            successMessage = successMessage.replace(
                '{{DUE_DATE}}',
                endDate.toLocaleString(locale, localeDateObj)
            );
            $successMessageSelector.innerHTML = successMessage;

            // Analytics data building
            dataLayer.push({
                daysOfExtension: diffDays
            });

            $paymentExtension.classList.add(CONSTANTS.HIDDEN_CLASS);
            $successSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            query.scrollToElement($paymentExtension, $header);

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success',
                contentTitle: 'payment-extension'
            });

            // Qualtrics survey triggering
            $paymentExtension.classList.add(coned.constants.QUALTRICS_TRIGGER_CLASS);

            QSI.API.unload();
            QSI.API.load().done(QSI.API.run());
        };

        var errorPaymentExtensionService = function (data) {
            var $msgTextSelector = $serviceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var initializeData = function () {
            $successSelector = document.getElementsByClassName(CONSTANTS.SUCCESS_SELECTOR)[0];
            $successMessageSelector = $successSelector.getElementsByClassName(
                CONSTANTS.SUCCESS_MESSAGE_SELECTOR
            )[0];
            $dateSelector = $paymentExtension.getElementsByClassName(CONSTANTS.DATE_SELECTOR)[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $serviceError = $paymentExtension.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];
            $cancelButton = $paymentExtension.getElementsByClassName(CONSTANTS.CANCEL_BUTTON)[0];
        };

        var initializeEvents = function () {
            new coned.components.ValidateForm(
                CONSTANTS.PAYMENT_EXTENSION_FORM,
                submitPaymentExtension
            );

            coned.utils.addGeneralListeners($cancelButton, hideError);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PaymentExtension.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PaymentExtension;
})();
