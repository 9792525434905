// ==================== CONTACT US FORM COMPONENT =========================
/* global _ */
/* global gsap */


var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ContactUsFormModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SELECT_ACTIVE_CLASS: 'js-coned-select-active',
        LABEL_CLASS: 'js-coned-select-label',
        FIELD_HIDDEN_CLASS: 'coned-form__field-container--hidden',
        SELECT_CONTACT_ME_CLASS: 'js-contact-me',
        CONTACT_ME_HIDDEN_CLASS: 'js-contact-me-hidden',
        SELECT_CONTACT_TOPIC_CLASS: 'js-contact-topic',
        CONTACT_TOPIC_HIDDEN_CLASS: 'js-contact-topic-hidden',
        CONTACT_TOPIC_INPUT_CLASS: 'js-contact-topic-field',
        CONTACT_TOPIC_UPLOAD_CLASS: 'js-contact-topic-upload',
        CONTACT_ACCOUNT_VALUES: ['bill', 'account', 'meter', 'moving'],
        CONTACT_UPLOAD_VALUE: 'meter',
        CONTACT_US_EMAIL_CTA_CLASS: 'js-contact-us-email-cta',
        HEADER_WRAPPER_CLASS: 'js-header-wrapper',
        FORM_CONTACT_US: 'js-form-contact-us',
        INPUTS_QUERY: 'button, input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])',

        FORM_MESSAGE: 'js-form-messages',
        SUCCESS_MESSAGE: 'js-success-message',
        ERROR_MESSAGE: 'js-error-message', 
        RESET_FORM: 'js-reset-button',

        DISPLAY: "display",
        INLINE_BLOCK: "inline-block", 
        NONE: "none",
        HIDDEN_CLASS: 'hidden',

        LOAD_METER_READING_PARAM: 'enableMeterReading'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ContactUsFormModule = function ($contactUsFormContainer) {
        /**
         * PRIVATE METHODS
         */
        var $contactMeSelectElement,
            $contactMeSelectLabel,
            $contactMeHiddenFields,
            $contactTopicSelectElement,
            $contactTopicHiddenFields,
            $contactTopicInputElements,
            $contactTopicUploadElement,
            $contactEmailCtaElement,
            $contactUsFormElement, 
            
            $formMessage,
            $successMessage,
            $errorMessage,
            $resetForm,
            $header;

        var modifyContactMeSelect = function () {
            $contactMeSelectLabel.style.display = 'block';

            query.addClass($contactMeSelectElement, CONSTANTS.SELECT_ACTIVE_CLASS);

            gsap.to($contactMeSelectLabel, {
                duration: 0.2,
                top: '10',
                opacity: '1'
            });
        };

        var changeContactMeOption = function () {
            var selectedOption =
                    $contactMeSelectElement.options[$contactMeSelectElement.selectedIndex].value,
                $selectedElement = document.getElementById(selectedOption);

            _.each($contactMeHiddenFields, function (hiddenField) {
                query.addClass(hiddenField, CONSTANTS.FIELD_HIDDEN_CLASS);
            });

            // Clearing selected field and toggling its display
            $selectedElement.value = '';
            query.removeClass(
                $selectedElement.parentElement.parentElement,
                CONSTANTS.FIELD_HIDDEN_CLASS
            );
        };

        var changeContactTopicOption = function () {
            var selectedOption =
                $contactTopicSelectElement.options[$contactTopicSelectElement.selectedIndex].value;

            // Hide all topic fields
            _.each($contactTopicHiddenFields, function (hiddenField) {
                query.addClass(hiddenField, CONSTANTS.FIELD_HIDDEN_CLASS);
            });

            // if the selectedOption value is part of the array of options that use the hidden fields, shown them
            if (CONSTANTS.CONTACT_ACCOUNT_VALUES.indexOf(selectedOption) != -1) {
                _.each($contactTopicInputElements, function (hiddenField) {
                    query.removeClass(hiddenField, CONSTANTS.FIELD_HIDDEN_CLASS);
                });

                // If option is meter, then also show the image upload field
                if (CONSTANTS.CONTACT_UPLOAD_VALUE == selectedOption) {
                    query.removeClass($contactTopicUploadElement, CONSTANTS.FIELD_HIDDEN_CLASS);
                }
            }
        };

        var contactEmailCtaClick = function (event) {
            event.preventDefault();

            if ($contactUsFormElement.style[CONSTANTS.DISPLAY] === CONSTANTS.NONE) {
                $resetForm.click();

                $contactUsFormElement.style.display = CONSTANTS.INLINE_BLOCK;

                $formMessage.style.removeProperty(CONSTANTS.DISPLAY);
                $successMessage.style.removeProperty(CONSTANTS.DISPLAY);
                $errorMessage.style.removeProperty(CONSTANTS.DISPLAY);
            }

            coned.utils.focusFirstFormInputField($contactUsFormElement);
            query.scrollToElement($contactUsFormElement, $header);
        };

        var initializeContactMeSelect = function () {
            $contactMeSelectElement.selectedIndex = 1;

            changeContactMeOption();
            modifyContactMeSelect();
        };

        var initializeData = function () {
            $contactMeSelectElement = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.SELECT_CONTACT_ME_CLASS
            )[0];
            $contactMeSelectLabel = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.LABEL_CLASS
            )[0];
            $contactMeHiddenFields = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.CONTACT_ME_HIDDEN_CLASS
            );

            $contactTopicSelectElement = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.SELECT_CONTACT_TOPIC_CLASS
            )[0];
            $contactTopicHiddenFields = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.CONTACT_TOPIC_HIDDEN_CLASS
            );
            $contactTopicInputElements = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.CONTACT_TOPIC_INPUT_CLASS
            );
            $contactTopicUploadElement = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.CONTACT_TOPIC_UPLOAD_CLASS
            )[0];
            $contactUsFormElement = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.FORM_CONTACT_US
            )[0];
            $contactEmailCtaElement = document.getElementsByClassName(
                CONSTANTS.CONTACT_US_EMAIL_CTA_CLASS
            )[0];
            $formMessage = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.FORM_MESSAGE
            )[0];
            $successMessage = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.SUCCESS_MESSAGE
            )[0];
            $errorMessage = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.ERROR_MESSAGE
            )[0];
            $resetForm = $contactUsFormContainer.getElementsByClassName(
                CONSTANTS.RESET_FORM
            )[0];
            $header = document.getElementsByClassName(
                CONSTANTS.HEADER_WRAPPER_CLASS
            )[0];
            
        };

        var initializeEvents = function () {
            if ($contactMeSelectElement) {
                $contactMeSelectElement.addEventListener('change', changeContactMeOption);
                $contactTopicSelectElement.addEventListener('change', changeContactTopicOption);

                // Set initial selection for the contact me dropdown
                initializeContactMeSelect();

                //If urlParameters run the action set
                coned.utils.doActionByURLParam(CONSTANTS.LOAD_METER_READING_PARAM,function(){
                    $contactTopicSelectElement.value = 'meter';
                    changeContactTopicOption();
                });
            }

            $contactUsFormElement &&
                coned.utils.addGeneralListeners($contactEmailCtaElement, contactEmailCtaClick);
        };


        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ContactUsFormModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ContactUsFormModule;
})();
