// ==================== STOP SERVICE COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.StopService = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ARIA_LABELLED_BY: 'aria-labelledby',
        SUBMIT_BUTTON_SELECTOR: 'js-transactional-submit-selector',
        SUBMIT_ANIMATION_SELECTOR: 'js-submit-progress-animation',
        FINISH_STEP_ERROR_MESSAGE: 'js-service-error-msg',
        FINISH_STEP_MESSAGES_CONTAINER: 'js-form-messages',
        FINISH_STEP_ERROR_CONTAINER: 'js-finish-step-selector-error',
        FINISH_STEP_CONTAINER: 'js-finish-step-selector',
        DATE_PICKER_INPUT: 'js-current-datepicker-input',
        STOP_FORM: '.transactional__form',
        STOP_FORM_WRAPPER: 'js-stop-service-form-wrapper',
        DROPDOWN_BUTTON: 'js-dropdown-button-contain',
        LIVE_CHAT_AVAILABLE_SELECTOR: 'js-live-chat-available',
        LIVE_CHAT_ERROR_MESSAGE: 'js-live-chat-error-message',
        LIVE_CHAT_AVAILABLE_MESSAGE: 'js-live-chat-available-message',
        LIVE_CHAT_BUTTON: 'js-live-chat-cta',
        LIVE_CHAT_ON_PARAM: 'liveChatOn',
        INIT_CHAT:  'init',
        STOP_CHAT: 'stop',
        COUNTRY_SELECTOR: 'js-country-selector',
        STATE_SELECTOR: 'js-state-selector',
        SELECT_LABEL_SELECTOR: 'js-coned-select-label',
        OTHER_COUNTRY: 'js-other-country',
        DROPDOWN_OPTION_USA: 'js-country-usa',
        DROPDOWN_OPTION_CANADA: 'js-country-canada',
        DROPDOWN_LABEL_SELECTOR: 'js-coned-select-label',
        ACTIVE_DROPDOWN: 'coned-select--active',
        ACCOUNT_DROPDOWN_ITEMS: 'js-dropdown-item',
        ACCOUNT_DROPDOWN_ITEM: 'address-box',
        USA_ZIPCODE_SELECTOR: 'js-zipcode-selector',
        USA_ZIPCODE_INPUT: 'js-zipcode-input',
        CANADA_ZIPCODE_SELECTOR: 'js-canada-zipcode-selector',
        CANADA_ZIPCODE_INPUT: 'js-canada-zipcode-input',
        CITY_SELECTOR: 'js-city-selector',
        SERVICE_ERROR_SELECTOR: 'js-service-error',
        INPUT_ERROR_MESSAGE: 'coned-input-message--error',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        VALIDATE_IGNORE: 'js-validate-ignore',
        INPUT_VALIDATE: 'js-item-validate',
        HIDDEN_CLASS: 'hidden',
        CHECK_AVAILABILITY_ERROR: 'js-check-availability-service-error',
        ACTIVE_DROPDOWN_CLASS: 'coned-select--active',
        UNAUTHENTICATED_STOP_SERVICE: 'js-unauthenticated-stop-service',
        ACTIVE_DROPDOWN_LABEL_CLASS: 'coned-select__label--active',
        RADIO_LABEL_ADDRESS: 'js-radio-label-selector',
        RADIO_ADDRESS_NAME: 'addressType',
        ADDRESS_RADIO_BUTTONS: 'js-address-radio-button',
        HAS_EBILL_SELECTOR: 'js-has-ebill',
        HAS_EMAIL_SELECTOR: 'js-has-email',
        HAS_NO_EMAIL_SELECTOR: 'js-has-no-email',
        SET_ADDRESS_SELECTOR: 'js-set-address-selector',
        PRIMARY_PHONE_INPUT: 'js-primary-phone-input',
        ADDRESS_INPUTS: 'js-stop-optional-input',
        ADDRESS_BOX: 'js-address-box',
        NAME_CANADA_ZIP: 'stopServiceCanadaZip',
        NAME_INPUT_ZIP: 'stopServiceZip',
        NAME_COUNTRY: 'stopServiceCountry',
        NAME_OTHER_COUNTRY: 'stopServiceOtherCountryName',
        NAME_STREET: 'stopServiceStreetNumber',
        NAME_ADDRESS: 'stopServiceAddress',
        NAME_SECONDARY_ADDRESS: 'stopServiceSecondaryAddress',
        NAME_INPUT_NAME: 'stopServiceName',
        FORM_ADDRESS_SCID_NAME: 'AddressScId',
        NAME_CITY: 'stopServiceCity',
        NAME_STATE: 'stopServiceState',
        NAME_PHONE: 'stopServicePhone',
        NAME_SITECORE_ID: 'stopServiceScId',
        FORM_LOADING: 'js-form-loading',
        INPUT_FULL: 'transactional__half--full',
        FILLED_CLASS: 'coned-input--filled',
        STOP_DATE_ERROR_SELECTOR: 'js-stop-date-service-error',
        CONED_INPUT: 'js-coned-input',
        CONED_SELECT: 'js-coned-select',
        VALID_CLASS: 'valid',
        EMAIL_MASK: '@EMAIL',
        ADDRESS_MASK: '@ADDRESS',
        CURRENT_ADDRESS: 'currentAddress',
        FIELD_ERROR_CLASS: 'coned-field-error-wrapper',
        RADIO_BUTTON_CLASS: 'coned-radio',
        OPTION_ELEMENT: 'option',
        LIST_TYPE_ELEMENT: 'li',
        RADIO_GROUP_CLASS: 'js-radio-group',
        RADIO_GROUP_HAS_EBILL_LABEL_ID: 'radioGroupHasEbillLabel',
        RADIO_GROUP_HAS_EMAIL_LABEL_ID: 'radioGroupHasEmailLabel',
        RADIO_GROUP_HAS_NO_EMAIL_LABEL_ID: 'radioGroupHasNoEmailLabel',
        FORM_FIELD: 'js-form-field',

        // Tagging Constants
        TAGGING_SERVICE_SUBMIT_SUCCESS: 'Submit.Success',
        TAGGING_SERVICE_SUBMIT_FAILURE: 'Submit.Failure',
        TAGGING_SERVICE_END_DATE_EBILL: 'End.Date.Ebill',
        TAGGING_SERVICE_END_DATE_FORM_ERROR: 'End.Date.Form.Error',
        TAGGING_SERVICE_END_DATE_NO_EBILL: 'End.Date.No.Ebill',
        TAGGING_SERVICE_END_DATE_NO_EMAIL: 'End.Date.No.Email',
        TAGGING_PREFFIX_UNAUTHENTICATED: 'Unauthenticated.',
        TAGGING_LIVE_CHAT_DISPLAYS:'flow.address.error',
        TAGGING_LIVE_CHAT_EVENT:'live-chat-tagging'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var StopService = function ($stopForm) {
        /**
         * PRIVATE METHODS
         */
        var $submitButtonSelector,
            $submitAnimationSelector,
            $finishStepSelector,
            $finishStepMessagesContainer,
            $finishStepErrorSelector,
            $finishStepErrorMessage,
            $stopformWrapper,
            $datePickerInput,
            $dropdownButton,
            $countrySelector,
            $stateSelector,
            $dropdownOptionCanada,
            $dropdownOptionUSA,
            $otherCountryName,
            $USAZipcodeSelector,
            $USAZipcodeInput,
            $canadaZipcodeSelector,
            $canadaZipcodeInput,
            $serviceErrorSelector,
            $formLoading,
            $checkAvailabilityError,
            $liveChatErrorMessage,
            $liveChatAvailableMessage,
            $liveChatAvailable,
            $accountDropDownItems,
            $stopDateServiceError,
            $addressBox,
            $citySelector,
            $optionalFields,
            $radioLabelAddress,
            $addressRadioButtons,
            $hasEbillContent,
            $hasEmailContent,
            $hasNoEmailContent,
            $setAddressContainer,
            $primaryPhoneInput,
            $errorInputMessage,
            $radioButtons,
            $radioGroup,
            _liveChatOn,
            _hasEmail,
            _hasEbill,
            _taggingPreffix,
            _accountIsValid,
            _useSameAddress,
            _addressData,
            _premiseNumber,
            _account,
            _data,
            _liveChatTagging;

        var finishStep = function () {
            var getCountryValue = query.getFormInputValue($stopForm, CONSTANTS.NAME_COUNTRY),
                getStreetValue = query.getFormInputValue($stopForm, CONSTANTS.NAME_STREET),
                getAddressValue = query.getFormInputValue($stopForm, CONSTANTS.NAME_ADDRESS),
                dataCountry = getCountryValue ? getCountryValue : null,
                dataStreetAddress = getStreetValue ? getStreetValue : getAddressValue,
                serviceUrl = $stopForm.dataset.stopServiceUrl,
                $addressFields = $finishStepSelector.querySelectorAll(
                    '.' +
                        [
                            CONSTANTS.HAS_EBILL_SELECTOR,
                            CONSTANTS.HAS_EMAIL_SELECTOR,
                            CONSTANTS.HAS_NO_EMAIL_SELECTOR
                        ].join(', .')
                ),
                optionalAddress = null,
                dataZipCode,
                params;

            // Hide Error
            $serviceErrorSelector.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);

            // ZipCode
            if ($countrySelector && $countrySelector.value.toLowerCase() == 'canada') {
                dataZipCode = query
                    .getFormInputValue($stopForm, CONSTANTS.NAME_CANADA_ZIP)
                    .toUpperCase();
            } else {
                dataZipCode = query.getFormInputValue($stopForm, CONSTANTS.NAME_INPUT_ZIP);
            }

            // Optional Address
            if (_useSameAddress) {
                optionalAddress = {
                    Name: _addressData.Name,
                    StreetOrAddress: _addressData.StreetOrAddress,
                    Address2: _addressData.Address2,
                    UnitNumber: _addressData.UnitNumber,
                    Country: _addressData.Country,
                    OtherCountryName: _addressData.OtherCountryName,
                    City: _addressData.City,
                    StateOrProvince: _addressData.StateOrProvince,
                    ZipCode: _addressData.ZipCode
                };
            } else {
                optionalAddress = {
                    Name: query.getFormInputValue($stopForm, CONSTANTS.NAME_INPUT_NAME),
                    StreetOrAddress: dataStreetAddress,
                    Address2: query.getFormInputValue(
                        $stopForm,
                        CONSTANTS.NAME_SECONDARY_ADDRESS
                    ),
                    Country: dataCountry,
                    UnitNumber: '',
                    OtherCountryName: query.getFormInputValue(
                        $stopForm,
                        CONSTANTS.NAME_OTHER_COUNTRY
                    ),
                    City: query.getFormInputValue($stopForm, CONSTANTS.NAME_CITY),
                    StateOrProvince: query.getFormInputValue($stopForm, CONSTANTS.NAME_STATE),
                    ZipCode: dataZipCode
                };
            }

            if ($addressFields) {
                if (optionalAddress) {
                    var addressLabel =
                        optionalAddress.Address2 && optionalAddress.Address2 !== ''
                            ? optionalAddress.StreetOrAddress &&
                              optionalAddress.StreetOrAddress !== ''
                                ? optionalAddress.StreetOrAddress + ', ' + optionalAddress.Address2
                                : optionalAddress.Address2
                            : optionalAddress.StreetOrAddress;

                    addressLabel +=
                        optionalAddress.UnitNumber && optionalAddress.UnitNumber !== ''
                            ? ', ' + optionalAddress.UnitNumber
                            : '';
                    addressLabel +=
                        ', ' +
                        optionalAddress.City +
                        ', ' +
                        optionalAddress.StateOrProvince +
                        ', ' +
                        optionalAddress.ZipCode;

                    _.each($addressFields, function (field) {
                        field.innerHTML = field.innerHTML.replace(
                            CONSTANTS.ADDRESS_MASK,
                            '<b>' + addressLabel + '</b>'
                        );
                    });
                }
            }
            // Service Data
            params = {
                ScId: query.getFormInputValue($stopForm, CONSTANTS.NAME_SITECORE_ID),
                AccessToken: coned.utils.getUrlParameterValue('token'),
                MaskedAccountNumber: $dropdownButton
                    ? $dropdownButton.dataset.accountMaid
                    : $addressBox.dataset.accountMaid,
                StopServiceDate: coned.utils.serviceDateFormat($datePickerInput.value),
                Phone: query.getFormInputValue($stopForm, CONSTANTS.NAME_PHONE),
                PhoneType: 'Home',
                FinalBillAddress: optionalAddress,
                premiseNumber: ($dropdownButton && $dropdownButton.dataset.premise) ? $dropdownButton.dataset.premise : _premiseNumber
            };

            // Service Call
            params = JSON.stringify(params);
            query.deleteData(
                serviceUrl,
                successFinishEventCall,
                errorFinishEventCall,
                params,
                true
            );
        };

        var successFinishEventCall = function () {
            if (_taggingPreffix) {
                if (_hasEbill) {
                    dataLayer.push({
                        event: _taggingPreffix + CONSTANTS.TAGGING_SERVICE_END_DATE_EBILL
                    });
                } else if (_hasEmail) {
                    dataLayer.push({
                        event: _taggingPreffix + CONSTANTS.TAGGING_SERVICE_END_DATE_NO_EBILL
                    });
                } else {
                    dataLayer.push({
                        event: _taggingPreffix + CONSTANTS.TAGGING_SERVICE_END_DATE_NO_EMAIL
                    });
                }
            }

            $submitAnimationSelector.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        var errorFinishEventCall = function (data) {
            $submitAnimationSelector.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var serviceSuccess = function () {
            $stopForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $finishStepMessagesContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $finishStepSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Tagging for unauthenticated stop service
            if (
                _taggingPreffix &&
                _taggingPreffix !== '' &&
                _taggingPreffix.indexOf(CONSTANTS.TAGGING_PREFFIX_UNAUTHENTICATED !== -1)
            ) {
                // Tagging Confirm Unauthorized Account
                dataLayer.push({
                    event: _taggingPreffix + CONSTANTS.TAGGING_SERVICE_SUBMIT_SUCCESS
                });
            }

            if (!$stopForm.classList.contains(CONSTANTS.UNAUTHENTICATED_STOP_SERVICE)) {
                // Analytics data building
                dataLayer.push({
                    event: 'coned.form.success'
                });
            }
            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($stopForm);
        };

        var serviceError = function () {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.DELETE_STOP_SERVICE_ERROR, showError, showError);
            } else {
                showError(_data);
            }

            // Tagging for unauthenticated stop service
            if (
                _taggingPreffix &&
                _taggingPreffix !== '' &&
                _taggingPreffix.indexOf(CONSTANTS.TAGGING_PREFFIX_UNAUTHENTICATED !== -1)
            ) {
                // Tagging Confirm Unauthorized Account
                dataLayer.push({
                    event: _taggingPreffix + CONSTANTS.TAGGING_SERVICE_SUBMIT_FAILURE
                });
            }
        };

        var showError = function (data) {
            var errorMsg = data.errorMsg;

            if (coned.utils.isOru()) {
                //Display error on same page
                $serviceErrorSelector.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $serviceErrorSelector.innerHTML = errorMsg;
                $serviceErrorSelector.parentElement.focus();
            } else {
                //Display error on final step
                $finishStepErrorMessage.innerHTML =
                    errorMsg && 
                    errorMsg !== '' ? 
                    '<p>' + errorMsg + '</p>' : 
                    $finishStepErrorMessage.innerHTML;

                $stopForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $finishStepMessagesContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $finishStepErrorSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $finishStepErrorSelector.parentElement.scrollIntoView();
            }
        };

        var enabledFinish = function () {
            var inputValid = $($datePickerInput).valid();

            if (inputValid && _accountIsValid) {
                $submitButtonSelector.disabled = false;
            } else {
                $submitButtonSelector.disabled = true;
            }
        };

        var countryDropdownEvent = function () {
            var $stateLabel = $stateSelector.parentElement.getElementsByClassName(
                CONSTANTS.SELECT_LABEL_SELECTOR
            )[0];

            query.addClass($dropdownOptionCanada, CONSTANTS.HIDDEN_CLASS);
            query.addClass($dropdownOptionUSA, CONSTANTS.HIDDEN_CLASS);
            coned.utils.changeListType($dropdownOptionCanada, CONSTANTS.LIST_TYPE_ELEMENT);
            coned.utils.changeListType($dropdownOptionUSA, CONSTANTS.LIST_TYPE_ELEMENT);
            $canadaZipcodeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $USAZipcodeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Reset state dropdown
            $stateSelector.parentElement.parentElement.parentElement.classList.remove(
                CONSTANTS.HIDDEN_CLASS
            );
            $stateLabel.removeAttribute('style');
            $stateSelector.removeAttribute('style');
            $stateSelector.selectedIndex = 0;
            $stateSelector.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);

            if ($countrySelector.value.toLowerCase() == 'usa') {
                query.removeClass($dropdownOptionUSA, CONSTANTS.HIDDEN_CLASS);
                coned.utils.changeListType($dropdownOptionUSA, CONSTANTS.OPTION_ELEMENT);
                $stateSelector.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $USAZipcodeSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $USAZipcodeInput.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $canadaZipcodeInput.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $canadaZipcodeInput.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $citySelector.classList.remove(CONSTANTS.INPUT_FULL);
                toggleOtherCountry(false);
            } else if ($countrySelector.value.toLowerCase() == 'canada') {
                query.removeClass($dropdownOptionCanada, CONSTANTS.HIDDEN_CLASS);
                coned.utils.changeListType($dropdownOptionCanada, CONSTANTS.OPTION_ELEMENT);
                $stateSelector.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $canadaZipcodeSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $canadaZipcodeInput.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $USAZipcodeInput.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $USAZipcodeInput.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $citySelector.classList.remove(CONSTANTS.INPUT_FULL);
                toggleOtherCountry(false);
            } else {
                $stateSelector.parentElement.parentElement.parentElement.classList.add(
                    CONSTANTS.HIDDEN_CLASS
                );
                $stateSelector.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $USAZipcodeInput.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $USAZipcodeInput.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $canadaZipcodeInput.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $canadaZipcodeInput.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $citySelector.classList.add(CONSTANTS.INPUT_FULL);
                toggleOtherCountry(true);
            }
        };

        var toggleOtherCountry = function (toggleFlag) {
            if (toggleFlag) {
                $otherCountryName.parentNode.parentNode.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $otherCountryName.classList.remove(CONSTANTS.INPUT_ERROR_MESSAGE);
                $otherCountryName.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $otherCountryName.classList.add(CONSTANTS.INPUT_VALIDATE);
                $USAZipcodeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $canadaZipcodeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                $otherCountryName.parentNode.parentNode.classList.add(CONSTANTS.HIDDEN_CLASS);
                $otherCountryName.classList.add(CONSTANTS.INPUT_ERROR_MESSAGE);
                $otherCountryName.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $otherCountryName.classList.remove(CONSTANTS.INPUT_VALIDATE);
            }
        };

        var setCountryDefault = function () {
            var $dropdownLabel = $countrySelector.parentElement.getElementsByClassName(
                CONSTANTS.DROPDOWN_LABEL_SELECTOR
            )[0];
            $countrySelector.selectedIndex = 1;
            $countrySelector.classList.add(CONSTANTS.ACTIVE_DROPDOWN_CLASS);
            $dropdownLabel.classList.add(CONSTANTS.ACTIVE_DROPDOWN_LABEL_CLASS);

            countryDropdownEvent();
        };

        var handleKeyDownOnAccountItem = function (event) {
            var keycode = event.keyCode;

            if (keycode == coned.constants.KEY_CODE.ENTER) {
                checkAvailability(event);
            }
        };

        var checkAvailability = function (event) {
            // If function is called from a dropdown change or from javascript (initial load)
            var $addressOption;

            if (event) {
                $addressOption = event.currentTarget.children[0];
            } else {
                if ($dropdownButton) {
                    $addressOption = $dropdownButton;
                } else {
                    $addressOption = $addressBox;
                }
            }

            var accountMaid = $addressOption.dataset.accountMaid,
                serviceUrl = $stopForm.dataset.checkAvailabilityService;

            if (!serviceUrl || serviceUrl === '') {
                //Use data stored in previous step
                var accountData = coned.accountData;

                if (accountData) {
                    //Update property to match backend
                    if (!accountData.Phone) {
                        accountData.Phone = accountData.PhoneNumber;
                    }

                    successCheckAvailability(accountData);
                } else {
                    failCheckAvailability();
                }
            } else {
                _accountIsValid = false;
                $submitButtonSelector.disabled = true;

                // Hide Error
                $checkAvailabilityError.classList.add(CONSTANTS.HIDDEN_CLASS);

                // Service Data
                var params = {
                    ScId: query.getFormInputValue($stopForm, CONSTANTS.NAME_SITECORE_ID),
                    AccountMaid: accountMaid,
                    IsStartService: false,
                    IsTransferService: false
                };

                //Adding live chat testing scenario
                if(coned.utils.isPatternLab() && _liveChatOn && _liveChatOn === 'true') {
                    // Service Call
                    params = JSON.stringify(params);
                    query.postData(
                        serviceUrl,
                        failCheckAvailability,
                        failCheckAvailability,
                        params,
                        true,
                        $formLoading
                    );
                }else {
                    // Service Call
                    params = JSON.stringify(params);
                    query.postData(
                        serviceUrl,
                        successCheckAvailability,
                        failCheckAvailability,
                        params,
                        true,
                        $formLoading
                    );
                }
            }
        };

        var successCheckAvailability = function (data) {
            if (coned.utils.isPatternLab()) {
                var tomorrow = new Date(),
                    dateNextMonth = new Date();

                // Set dates
                tomorrow.setDate(tomorrow.getDate() + 1);
                dateNextMonth.setUTCMonth(dateNextMonth.getUTCMonth() + 1);

                data = {
                    MinDate: tomorrow.toJSON(),
                    MaxDate: dateNextMonth.toJSON(),
                    AccountType: 'R',
                    InitialDepositAmount: '5950',
                    ServiceAddress: null,
                    GenericServiceError: null,
                    FinalBillAddress: {
                        Name: 'John Smith',
                        StreetOrAddress: '910 Boston Post Rd',
                        Address2: null,
                        City: 'Rye',
                        StateOrProvince: 'NY',
                        FormattedStateOrProvince: null,
                        ZipCode: '10580',
                        Country: '',
                        OtherCountryName: null,
                        FormattedCountry: null,
                        UnitNumber: 'PD'
                    },
                    HasEbill: true,
                    EbillEmail: 'john.smith@some.com',
                    Phone: '9149670142',
                    PremiseNumber: '00PD' 
                };
            }

            setAvailability(data);
        };

        var setAvailability = function (data) {
            _accountIsValid = true;
            _data = data;
            _premiseNumber = data.PremiseNumber;

            //If live chat is available clear interval
            if($liveChatAvailable) {
                var $liveChatAvailableBtn = $liveChatAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_BUTTON)[0];
                coned.utils.triggerEvent($liveChatAvailableBtn, CONSTANTS.STOP_CHAT);
            }

            data.MinDate = data.MinDate.replace(/-/g, '/').slice(0, 10);
            data.MaxDate = data.MaxDate.replace(/-/g, '/').slice(0, 10);

            setDatesLimit(data.MinDate, data.MaxDate);

            // Enable fields and button
            $datePickerInput.disabled = false;

            //Disable button until date selection
            $submitButtonSelector.disabled = true;

            for (var index = 0; index < $optionalFields.length; index++) {
                $optionalFields[index].disabled = false;
            }

            // clearing values for service end date.
            $datePickerInput.value = '';

            // conditionals to reset styles for datePickerInput.
            if ($datePickerInput.classList.contains(CONSTANTS.FILLED_CLASS)) {
                $datePickerInput.classList.remove(CONSTANTS.FILLED_CLASS);
            }

            if ($datePickerInput.classList.contains(CONSTANTS.VALID_CLASS)) {
                $datePickerInput.classList.remove(CONSTANTS.VALID_CLASS);
            }

            if ($datePickerInput.classList.contains(CONSTANTS.INPUT_ERROR_MESSAGE)) {
                $datePickerInput.classList.remove(CONSTANTS.INPUT_ERROR_MESSAGE);
            }

            var $inputs = $setAddressContainer.querySelectorAll(
                    ['.' + CONSTANTS.CONED_INPUT, '.' + CONSTANTS.CONED_SELECT].join(', ')
                ),
                radioLabel =
                    data.FinalBillAddress.Address2 && data.FinalBillAddress.Address2 !== ''
                        ? data.FinalBillAddress.StreetOrAddress &&
                          data.FinalBillAddress.StreetOrAddress !== ''
                            ? data.FinalBillAddress.StreetOrAddress +
                              ', ' +
                              data.FinalBillAddress.Address2
                            : data.FinalBillAddress.Address2
                        : data.FinalBillAddress.StreetOrAddress;

            radioLabel +=
                data.FinalBillAddress.UnitNumber && data.FinalBillAddress.UnitNumber !== ''
                    ? ', ' + data.FinalBillAddress.UnitNumber
                    : '';
            radioLabel +=
                ', ' +
                data.FinalBillAddress.City +
                ', ' +
                data.FinalBillAddress.StateOrProvince +
                ', ' +
                data.FinalBillAddress.ZipCode;

            _.each($inputs, function (input) {
                input.classList.add(CONSTANTS.VALIDATE_IGNORE);
            });

            $radioLabelAddress.innerHTML = radioLabel;
            _addressData = data.FinalBillAddress;

            if (data.Phone && data.Phone !== '') {
                $primaryPhoneInput.value = data.Phone;
                $($primaryPhoneInput).trigger('keyup');

                $primaryPhoneInput.classList.add(CONSTANTS.VALID_CLASS);
                $primaryPhoneInput.classList.add(CONSTANTS.FILLED_CLASS);
            } else {
                // clearing values for primary phone.
                $primaryPhoneInput.value = '';

                // conditionals to reset styles for primaryPhoneInput.
                if ($primaryPhoneInput.classList.contains(CONSTANTS.FILLED_CLASS)) {
                    $primaryPhoneInput.classList.remove(CONSTANTS.FILLED_CLASS);
                }

                if ($primaryPhoneInput.classList.contains(CONSTANTS.VALID_CLASS)) {
                    $primaryPhoneInput.classList.remove(CONSTANTS.VALID_CLASS);
                }

                if ($primaryPhoneInput.classList.contains(CONSTANTS.INPUT_ERROR_MESSAGE)) {
                    $primaryPhoneInput.classList.remove(CONSTANTS.INPUT_ERROR_MESSAGE);
                }
            }

            // loop to hide the error input messages
            $errorInputMessage = $stopForm.getElementsByClassName(CONSTANTS.FIELD_ERROR_CLASS);
            for (var i = 0; i < $errorInputMessage.length; i++) {
                if ($errorInputMessage[i]) {
                    $errorInputMessage[i].style.display = 'none';
                }
            }

            // click the first radio button.
            $radioButtons = $stopForm.getElementsByClassName(CONSTANTS.RADIO_BUTTON_CLASS);
            $radioButtons[0].checked = true;

            if (data.HasEbill) {
                _hasEbill = true;
                _hasEmail = true;

                _.each($hasEbillContent, function (element) {
                    element.innerHTML = element.dataset.originalMessage.replace(
                        CONSTANTS.EMAIL_MASK,
                        '<b>' + data.EbillEmail + '</b>'
                    );
                    element.classList.remove(CONSTANTS.HIDDEN_CLASS);
                });

                $radioGroup.setAttribute(
                    CONSTANTS.ARIA_LABELLED_BY,
                    CONSTANTS.RADIO_GROUP_HAS_EBILL_LABEL_ID
                );
                query.addClass($hasEmailContent, CONSTANTS.HIDDEN_CLASS);
                query.addClass($hasNoEmailContent, CONSTANTS.HIDDEN_CLASS);
            } else {
                if (data.EbillEmail && data.EbillEmail !== '') {
                    _hasEmail = true;

                    _.each($hasEmailContent, function (element) {
                        element.innerHTML = element.dataset.originalMessage.replace(
                            CONSTANTS.EMAIL_MASK,
                            '<b>' + data.EbillEmail + '</b>'
                        );
                        element.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    });

                    $radioGroup.setAttribute(
                        CONSTANTS.ARIA_LABELLED_BY,
                        CONSTANTS.RADIO_GROUP_HAS_EMAIL_LABEL_ID
                    );
                    query.addClass($hasNoEmailContent, CONSTANTS.HIDDEN_CLASS);
                } else {
                    $radioGroup.setAttribute(
                        CONSTANTS.ARIA_LABELLED_BY,
                        CONSTANTS.RADIO_GROUP_HAS_NO_EMAIL_LABEL_ID
                    );
                    query.removeClass($hasNoEmailContent, CONSTANTS.HIDDEN_CLASS);
                    query.addClass($hasEmailContent, CONSTANTS.HIDDEN_CLASS);
                }

                query.addClass($hasEbillContent, CONSTANTS.HIDDEN_CLASS);
            }

            //Show stop form when the account is elegible.
            $stopformWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var changeSetAddress = function (event) {
            var $inputs = $setAddressContainer.querySelectorAll(
                ['.' + CONSTANTS.CONED_INPUT, '.' + CONSTANTS.CONED_SELECT].join(', ')
            );

            if (event.target.value === CONSTANTS.CURRENT_ADDRESS) {
                _useSameAddress = true;

                $setAddressContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                _.each($inputs, function (input) {
                    input.classList.add(CONSTANTS.VALIDATE_IGNORE);
                });
            } else {
                _useSameAddress = false;

                $setAddressContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                _.each($inputs, function ($input) {
                    !coned.utils.isElementHidden($input) &&
                    $input.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                });
            }
        };

        var setDatesLimit = function (minDate, maxDate) {
            var minimunDate = new Date(minDate),
                maximunDate = new Date(maxDate);

            minimunDate.setDate(minimunDate.getDate());
            maximunDate.setDate(maximunDate.getDate());

            // set input validation rules
            $datePickerInput.dataset.minDateDays = minDate;
            $datePickerInput.dataset.maxDateDays = maxDate;

            // set the date picker dates limit and restrict weekends/holidays
            setTimeout(function () {
                $($datePickerInput).datepicker('option', 'minDate', new Date(minimunDate));
                $($datePickerInput).datepicker('option', 'maxDate', new Date(maximunDate));

                // Restrict weekends from being selected for ORU
                if (coned.utils.isOru()) {
                    $($datePickerInput).datepicker(
                        'option',
                        'beforeShowDay',
                        $.datepicker.noWeekends
                    );
                }
            }, 1);
        };

        var failCheckAvailability = function (data) {
            var $msgTextSelector = $checkAvailabilityError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0],
            liveChatAvailable = $stopForm.dataset.liveChatAvailable;

            if (liveChatAvailable && liveChatAvailable === 'true') {
                var $liveChatAvailableBtn = $liveChatAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_BUTTON)[0];
                coned.utils.triggerEvent($liveChatAvailableBtn, CONSTANTS.INIT_CHAT);

                if (data && data.errorMsg && data.liveChatAvailableMessage) {
                    $liveChatErrorMessage.innerHTML = data.errorMsg;
                    $liveChatAvailableMessage.innerHTML = data.liveChatAvailableMessage;
                }

                //This event listen for the first time the chat appears to be triggered
                $liveChatAvailableBtn.addEventListener(CONSTANTS.TAGGING_LIVE_CHAT_EVENT, function(){
                    if(_liveChatTagging) {
                        dataLayer.push({
                            event: CONSTANTS.TAGGING_LIVE_CHAT_DISPLAYS
                        });
                        _liveChatTagging = false;
                    }
                });

            } else {
                $msgTextSelector.innerHTML = data.errorMsg
                    ? data.errorMsg
                    : coned.constants.ERROR_MESSAGE;
                $checkAvailabilityError.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $checkAvailabilityError.focus();
            }

            // Disable fields and button
            $datePickerInput.disabled = true;
            $submitButtonSelector.disabled = true;

            for (var index = 0; index < $optionalFields.length; index++) {
                $optionalFields[index].disabled = true;
            }

            //Hide form to avoid user confusion when to eligible account
            $stopformWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);

            $checkAvailabilityError.focus();
        };

        var stopServiceSubmit = function () {
            new coned.components.SubmitAnimation(
                $submitAnimationSelector,
                finishStep,
                serviceSuccess,
                serviceError,
                false,
                true
            );
        };

        var verifyDate = function (event) {
            var $target = event.target,
                validator = $($stopForm).validate(),
                dateStatus = validator.element($target),
                serviceUrl = $stopForm.dataset.verifyDateServiceUrl,
                params;

            if (!dateStatus) return;

            $target.disabled = true;

            //hideError
            $stopDateServiceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Service Data
            params = {
                ScServiceAddressId: query.getFormInputValue(
                    $stopForm,
                    CONSTANTS.FORM_ADDRESS_SCID_NAME
                ),
                ScStopDateId: query.getFormInputValue($stopForm, CONSTANTS.NAME_SITECORE_ID),
                UnitNumber: $dropdownButton
                    ? $dropdownButton.dataset.unit
                    : $addressBox.dataset.unit,
                Maid: $dropdownButton
                    ? $dropdownButton.dataset.accountMaid
                    : $addressBox.dataset.accountMaid,
                StopServiceDate: $target.value
            };

            if (coned.utils.isOru()) {
                params.CompanyCode = $dropdownButton.dataset.company;
                params.PremiseNumber = ($dropdownButton && $dropdownButton.dataset.premise) ? $dropdownButton.dataset.premise : _premiseNumber;
            }

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successVerifyDate,
                failVerifyDate,
                params,
                true,
                $formLoading
            );
        };

        var successVerifyDate = function () {
            $datePickerInput.disabled = false;
            $submitButtonSelector.disabled = !_accountIsValid;
            $datePickerInput.focus();
        };

        var failVerifyDate = function (data) {
            // Analytics data building
            if (_taggingPreffix) {
                dataLayer.push({
                    event: _taggingPreffix + CONSTANTS.TAGGING_SERVICE_END_DATE_FORM_ERROR
                });
            }

            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.VERIFY_DATE_ERROR,
                    errorVerifyDate,
                    errorVerifyDate
                );
            } else {
                errorVerifyDate(data);
            }
        };

        var errorVerifyDate = function (data) {
            var $msgTextSelector = $stopDateServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $stopDateServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;

            $datePickerInput.value = '';
            $datePickerInput.classList.remove(CONSTANTS.FILLED_CLASS);
            $datePickerInput.disabled = false;
            $datePickerInput.focus();
            $submitButtonSelector.disabled = true;
        };

        var initializeData = function () {
            $submitButtonSelector = $stopForm.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON_SELECTOR
            )[0];
            $submitAnimationSelector = $stopForm.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_SELECTOR
            )[0];
            $stopformWrapper = $stopForm.getElementsByClassName(CONSTANTS.STOP_FORM_WRAPPER)[0];
            $datePickerInput = $stopForm.getElementsByClassName(CONSTANTS.DATE_PICKER_INPUT)[0];
            $finishStepSelector = document.getElementsByClassName(
                CONSTANTS.FINISH_STEP_CONTAINER
            )[0];
            $finishStepMessagesContainer = document.getElementsByClassName(
                CONSTANTS.FINISH_STEP_MESSAGES_CONTAINER
            )[0];
            $finishStepErrorSelector = document.getElementsByClassName(
                CONSTANTS.FINISH_STEP_ERROR_CONTAINER
            )[0];
            $finishStepErrorMessage = document.getElementsByClassName(
                CONSTANTS.FINISH_STEP_ERROR_MESSAGE
            )[0];
            $dropdownButton = $stopForm.getElementsByClassName(CONSTANTS.DROPDOWN_BUTTON)[0];
            $countrySelector = $stopForm.getElementsByClassName(CONSTANTS.COUNTRY_SELECTOR)[0];
            $dropdownOptionCanada = $stopForm.getElementsByClassName(
                CONSTANTS.DROPDOWN_OPTION_CANADA
            );
            $dropdownOptionUSA = $stopForm.getElementsByClassName(CONSTANTS.DROPDOWN_OPTION_USA);
            $otherCountryName = $stopForm.getElementsByClassName(CONSTANTS.OTHER_COUNTRY)[0];
            $stateSelector = $stopForm.getElementsByClassName(CONSTANTS.STATE_SELECTOR)[0];
            $USAZipcodeSelector = $stopForm.getElementsByClassName(
                CONSTANTS.USA_ZIPCODE_SELECTOR
            )[0];
            $USAZipcodeInput = $stopForm.getElementsByClassName(CONSTANTS.USA_ZIPCODE_INPUT)[0];
            $canadaZipcodeSelector = $stopForm.getElementsByClassName(
                CONSTANTS.CANADA_ZIPCODE_SELECTOR
            )[0];
            $canadaZipcodeInput = $stopForm.getElementsByClassName(
                CONSTANTS.CANADA_ZIPCODE_INPUT
            )[0];
            $citySelector = $stopForm.getElementsByClassName(CONSTANTS.CITY_SELECTOR)[0];
            $optionalFields = $stopForm.getElementsByClassName(CONSTANTS.ADDRESS_INPUTS);
            $serviceErrorSelector = $stopForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_SELECTOR
            )[0];
            $checkAvailabilityError = $stopForm.getElementsByClassName(
                CONSTANTS.CHECK_AVAILABILITY_ERROR
            )[0];
            $liveChatAvailable = $stopForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_AVAILABLE_SELECTOR
            )[0];
            $liveChatErrorMessage = $stopForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_ERROR_MESSAGE
            )[0];
            $liveChatAvailableMessage = $stopForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_AVAILABLE_MESSAGE
            )[0];
            $formLoading = $stopForm.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $accountDropDownItems = $stopForm.getElementsByClassName(
                CONSTANTS.ACCOUNT_DROPDOWN_ITEMS
            );
            $stopDateServiceError = $stopForm.getElementsByClassName(
                CONSTANTS.STOP_DATE_ERROR_SELECTOR
            )[0];
            $primaryPhoneInput = $stopForm.getElementsByClassName(CONSTANTS.PRIMARY_PHONE_INPUT)[0];

            $addressBox = $stopForm.getElementsByClassName(CONSTANTS.ADDRESS_BOX)[0];
            $addressRadioButtons = $stopForm.getElementsByClassName(
                CONSTANTS.ADDRESS_RADIO_BUTTONS
            );
            $radioLabelAddress = $stopForm.getElementsByClassName(CONSTANTS.RADIO_LABEL_ADDRESS)[0];
            $hasEbillContent = document.getElementsByClassName(CONSTANTS.HAS_EBILL_SELECTOR);
            $hasEmailContent = document.getElementsByClassName(CONSTANTS.HAS_EMAIL_SELECTOR);
            $hasNoEmailContent = document.getElementsByClassName(CONSTANTS.HAS_NO_EMAIL_SELECTOR);
            $setAddressContainer = $stopForm.getElementsByClassName(
                CONSTANTS.SET_ADDRESS_SELECTOR
            )[0];
            $radioGroup = $stopForm.getElementsByClassName(CONSTANTS.RADIO_GROUP_CLASS)[0];

            _useSameAddress = true;
            _hasEbill = false;
            _hasEmail = false;
            _taggingPreffix = $stopForm.dataset.taggingPreffix;
            _account = '';
            _liveChatOn = coned.utils.getUrlParameterValue(CONSTANTS.LIVE_CHAT_ON_PARAM);
            _liveChatTagging = true;
        };

        var initializeEvents = function () {
            // Verify Date Service call
            $($datePickerInput).on('change', verifyDate);

            if ($countrySelector) {
                $countrySelector.addEventListener('change', countryDropdownEvent);
                setCountryDefault();
            }

            $('.' + CONSTANTS.DATE_PICKER_INPUT).change(enabledFinish);

            _.each($addressRadioButtons, function (radio) {
                radio.addEventListener('change', changeSetAddress);
            });

            // Initial availability validation
            checkAvailability();

            // Update the dropdown to show the account in context selected
            for (var i = 0; $accountDropDownItems[i]; i++) {
                _account = $dropdownButton.dataset.account;

                if ($accountDropDownItems[i].children[0].dataset.account === _account) {
                    setTimeout(function () {
                        query.fireEvent($accountDropDownItems[i],'accountInContext'); 
                    }, 500);
                    break;
                }
            }

            // Validate account availability
            _.each($accountDropDownItems, function ($accountItem) {
                coned.utils.addGeneralListeners($accountItem, checkAvailability);
                $accountItem.addEventListener('keydown', handleKeyDownOnAccountItem);
            });

            // form validation
            new coned.components.ValidateForm(
                CONSTANTS.STOP_FORM,
                stopServiceSubmit,
                '.' + CONSTANTS.VALIDATE_IGNORE
            );

            var $formStopService = $(CONSTANTS.STOP_FORM);
            if ($formStopService && $formStopService[0].hasAttribute('tabindex')) {
                $formStopService.focus();
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    StopService.prototype.isLoaded = function () {
        return isLoaded;
    };

    return StopService;
})();
