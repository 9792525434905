// ==================== FILE INPUT COMPONENT =========================
/* global _ */
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FileInputComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        INPUT_SELECTOR: 'js-coned-inputfile',
        INPUT_ID: 'files',
        DRAG_SELECTOR: 'js-coned-inputfile-drag',
        MESSAGE_SELECTOR: 'js-label-message',
        ERROR_MESSAGE_SELECTOR: 'js-error-message',
        ERROR_CONTAINER_SELECTOR: 'coned-field-error-wrapper',
        FORM_RESET_BUTTON: 'js-reset-button',
        FILE_LIST: 'js-file-list'
    };

    var isLoaded = false,
        fileSizeList = [];

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var FileInputComponent = function ($fileInput) {
        /**
         * PRIVATE METHODS
         */
        var $fileSelect, $message, $resetButton, $erroMessage, $fileListWrapper, $fileDragSelector;

        var initFileUpload = function () {
            $fileSelect.addEventListener(
                'change',
                function (event) {
                    var files =
                        event.dataTransfer && event.dataTransfer.files.length
                            ? event.dataTransfer.files
                            : event.target.files && event.target.files.length
                            ? event.target.files
                            : null;
                    files && FileSelectHandler(files);
                },
                false
            );
            $fileSelect.addEventListener('blur', function () {
                $erroMessage.innerHTML = '';
            });
        };

        // file selection
        function FileSelectHandler(files) {
            var $errorContainer = $fileInput.getElementsByClassName(
                CONSTANTS.ERROR_CONTAINER_SELECTOR
            )[0];
            files = files == '' ? $fileSelect.files : files;
            $erroMessage.innerHTML = '';

            if ($errorContainer) {
                $errorContainer.style.display = 'none';
            }

            if (
                !$fileSelect.dataset.maxAmount ||
                files.length + $fileSelect.fileList.length <=
                    parseInt($fileSelect.dataset.maxAmount)
            ) {
                _.each(files, function (fileItem) {
                    validateFiles(fileItem);
                });
            } else {
                $fileSelect.value = '';
                $erroMessage.innerHTML = $fileSelect.dataset.errorAmountMsg.replace(
                    '@AMOUNT',
                    $fileSelect.dataset.maxAmount
                );
            }
        }

        // change bytes format
        function bytesToSize(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

            if (bytes == 0) return 'n/a';

            var sizeIndex = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

            if (sizeIndex == 0) return bytes + ' ' + sizes[sizeIndex];

            return (bytes / Math.pow(1024, sizeIndex)).toFixed(1) + ' ' + sizes[sizeIndex];
        }

        // output file information
        function ParseFile(file) {
            var addedFileModifier =
                    $fileSelect.dataset && $fileSelect.dataset.addedFileClass
                        ? ' ' + $fileSelect.dataset.addedFileClass
                        : '',
                fileIndex,
                filesLength,
                notDuplicated,
                newFileString =
                    '<div class="coned-inputfile__item">' +
                    '<p class="coned-inputfile__item-name' +
                    addedFileModifier +
                    '"><span class="coned-inputfile__item-title">File name:</span> ' +
                    file.name +
                    '</p>' +
                    '<p class="coned-inputfile__item-name' +
                    addedFileModifier +
                    '"><span class="coned-inputfile__item-title">Type:</span> ' +
                    file.type +
                    '</p>' +
                    '<p class="coned-inputfile__item-name' +
                    addedFileModifier +
                    '"><span class="coned-inputfile__item-title">Size:</span> ' +
                    bytesToSize(file.size) +
                    '</p>' +
                    '<button data-file="' +
                    file.name +
                    '" class="coned-inputfile__label-remove js-remove-file">Delete</button></div>';

            if ($fileSelect.multiple) {
                $fileSelect.fileList.push(file);
                fileSizeList.push(file.size);

                if ($fileSelect.fileList.length) {
                    notDuplicated = true;

                    for (
                        fileIndex = 0, filesLength = $fileSelect.fileList.length;
                        fileIndex < filesLength - 1;
                        fileIndex++
                    ) {
                        if (
                            $fileSelect.fileList[fileIndex].name === file.name &&
                            $fileSelect.fileList[fileIndex].size === file.size &&
                            $fileSelect.fileList[fileIndex].lastModifiedDate.toString() ===
                                file.lastModifiedDate.toString()
                        ) {
                            var filePosition = $fileSelect.fileList.indexOf(file);
                            $fileSelect.fileList.splice(filePosition, 1);
                            fileSizeList.splice(filePosition, 1);
                            notDuplicated = false;
                        }
                    }
                }

                // show file information if not duplicated
                if (notDuplicated) {
                    $fileListWrapper.innerHTML += newFileString;
                }
            } else {
                $fileSelect.fileList = [file];
                fileSizeList = [file.size];
                $fileListWrapper.innerHTML = newFileString;
            }
        }

        // input files validation
        function validateFiles(fileItem) {
            if (fileItem === undefined) {
                return;
            }

            var validFileExtensions = $fileSelect.dataset.formats.split(','),
                fileExtension = fileItem.name
                    .split('.')[fileItem.name.split('.').length - 1].toLowerCase(),
                fileSize = fileItem.size,
                fileFormatError =
                    $fileSelect.dataset.errorExtensionMsg +
                    ' ' +
                    $fileSelect.dataset.formats.split(',').join(', ').toUpperCase(),
                fileSizeLimit = parseInt($fileSelect.dataset.maxSize),
                fileSizeError =
                    $fileSelect.dataset.errorFileMsg + ' ' + $fileSelect.dataset.maxSize + 'MB',
                totalFileSizeLimit = $fileSelect.dataset.maxTotalSize
                    ? parseInt($fileSelect.dataset.maxTotalSize)
                    : null,
                totalFileSizeError = $fileSelect.dataset.maxTotalSize
                    ? $fileSelect.dataset.errorMaxSizeFileMsg +
                      ' ' +
                      $fileSelect.dataset.maxTotalSize +
                      'MB'
                    : null,
                extensionValid = false,
                validFileSize = true,
                maxFileSizeValue = fileSizeLimit * 1048576,
                maxTotalFileSizeValue = totalFileSizeLimit * 1048576,
                currentExtension,
                currentFilesSize = $fileSelect.dataset.maxTotalSize
                    ? _.reduce(fileSizeList, function (memo, num) {
                          return memo + num;
                      })
                    : null;

            for (var fileIndex = 0; fileIndex < validFileExtensions.length; fileIndex++) {
                currentExtension = validFileExtensions[fileIndex];

                if (currentExtension == fileExtension) {
                    extensionValid = true;
                    break;
                }
            }

            if (
                $fileSelect.dataset.maxTotalSize &&
                fileSize > maxTotalFileSizeValue - currentFilesSize
            ) {
                $erroMessage.innerHTML = '';
                $erroMessage.innerHTML += totalFileSizeError;
                validFileSize = false;
                $fileSelect.value = '';
                if ($fileSelect.required && !$fileSelect.fileList.length) {
                    query.fireEvent($fileSelect, 'inputFileInvalid');
                }

                return false;
            } else if (fileSize > maxFileSizeValue) {
                $erroMessage.innerHTML = '';
                $erroMessage.innerHTML += fileSizeError;
                validFileSize = false;
                $fileSelect.value = '';
                if ($fileSelect.required && !$fileSelect.fileList.length) {
                    query.fireEvent($fileSelect, 'inputFileInvalid');
                }

                return false;
            } else if (!extensionValid) {
                $erroMessage.innerHTML = '';
                $erroMessage.innerHTML += fileFormatError;
                $fileSelect.value = '';
                if ($fileSelect.required && !$fileSelect.fileList.length) {
                    query.fireEvent($fileSelect, 'inputFileInvalid');
                }

                return false;
            }

            if (extensionValid && validFileSize) {
                query.fireEvent($fileSelect, 'inputFileValid');

                ParseFile(fileItem);
                $erroMessage.innerHTML = '';
            }
        }

        // reset input messages
        function resetInputMessages(event) {
            event.preventDefault();

            $message.innerHTML = '';
            $erroMessage.innerHTML = '';
            $fileSelect.value = '';
            $fileListWrapper.innerHTML = '';
            $fileSelect.fileList = [];
            fileSizeList = [];
        }

        // remove files
        function removeFile() {
            var file = this.dataset.file;
            $fileSelect.value = '';
            $erroMessage.innerHTML = '';

            for (var fileIndex = 0; fileIndex < $fileSelect.fileList.length; fileIndex++) {
                if ($fileSelect.fileList[fileIndex].name === file) {
                    $fileSelect.fileList.splice(fileIndex, 1);
                    fileSizeList.splice(fileIndex, 1);
                    break;
                }
            }

            this.parentNode.remove();
        }

        var initializeData = function () {
            $fileSelect = $fileInput.getElementsByClassName(CONSTANTS.INPUT_SELECTOR)[0];
            $message = $fileInput.getElementsByClassName(CONSTANTS.MESSAGE_SELECTOR)[0];
            $erroMessage = $fileInput.getElementsByClassName(CONSTANTS.ERROR_MESSAGE_SELECTOR)[0];
            ($resetButton = document.getElementsByClassName(CONSTANTS.FORM_RESET_BUTTON)[0]),
                ($fileListWrapper = $fileInput.getElementsByClassName(CONSTANTS.FILE_LIST)[0]);
            $fileDragSelector = $fileInput.getElementsByClassName(CONSTANTS.DRAG_SELECTOR)[0];

            $fileSelect.fileList = [];
        };

        var initializeEvents = function () {
            if (window.File && window.FileList && window.FileReader) {
                initFileUpload();
            }

            // clear file input
            if ($resetButton) {
                coned.utils.addGeneralListeners($resetButton, resetInputMessages);
            }

            // remove file listener
            $('body').on('click', '.js-remove-file', removeFile);

            // prevent page redirect on drop outside de drag area
            window.addEventListener(
                'dragenter',
                function (event) {
                    event.preventDefault();
                },
                false
            );

            window.addEventListener('dragover', function (event) {
                event.preventDefault();
            });

            window.addEventListener('drop', function (event) {
                event.preventDefault();

                if (event.target === $fileInput || $fileInput.contains(event.target)) {
                    if (event.dataTransfer && event.dataTransfer.files.length) {
                        $fileInput.value = event.dataTransfer.files[0].name;
                        FileSelectHandler(event.dataTransfer.files);
                    } else {
                        FileSelectHandler(event.target.files);
                    }
                }
            });

            $fileSelect.addEventListener('focusin', function () {
                $fileDragSelector.classList.add('coned-inputfile__drag--focus');
            });

            $fileSelect.addEventListener('focusout', function () {
                $fileDragSelector.classList.remove('coned-inputfile__drag--focus');
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FileInputComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FileInputComponent;
})();
