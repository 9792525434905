// ==================== TIPS FILTERS COMPONENT =========================
/* global _ */
/* global gsap */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.TipsFiltersComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HEADER_WRAPPER: 'header-wrapper',
        DROPDOWN_SORT_CLASS: 'js-dropdown-sort',
        DROPDOWN_BUTTON_CLASS: 'js-dropdown-button',
        DROPDOWN_BUTTON_ACTIVE_CLASS: 'tips-filters__item-button--active',
        DROPDOWN_BUTTON_TITLE_CLASS: 'js-dropdown-title',
        DROPDOWN_BUTTON_ICON_CLASS: 'js-icon-carrot',
        DROPDOWN_BUTTON_ICON_OPEN_CLASS: 'drop-down-title-icon--open',
        DROPDOWN_LIST_CLASS: 'js-dropdown-list',
        DROPDOWN_ITEM_CLASS: 'js-dropdown-item',
        DROPDOWN_ITEM_ACTIVE_CLASS: 'dropdown-list-item-link--active',
        TIPS_LIST_CLASS: 'js-tips-list',
        TIPS_ITEM_CLASS: 'js-tips-item',
        TIPS_ALL_VALUE: 'ALL',
        TIPS_CARD_SELECTOR: 'js-card-selector',
        TIPS_CARD_ANIMATION_CLASS: 'card-tile__zoom--animation',
        PAGINATION_MODULE: 'js-pagination-wrapper',
        PAGINATION_RESET_CLASS: 'js-pagination-reset',
        PAGINATION_RESULTS_CLASS: 'js-pagination-results',
        PAGINATION_RESULTS_TOTAL: 'js-total',
        CARD_CLASS_WRAP: 'card-tile__link-wrapper',
        CARD_CLASS_FOCUS: 'card-tile--focus',

        //A11Y
        ARIA_EXPANDED: 'aria-expanded',
        ARIA_CURRENT: 'aria-current'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var TipsFiltersComponent = function ($tipsFiltersComponent) {
        /**
         * PRIVATE METHODS
         */
        var $paginationModule,
            $paginationResults,
            $paginationTotal,
            $paginationReset,
            $header,
            $dropdownButtons,
            $dropdownArrows,
            $dropdownSort,
            $dropdownSortList,
            $dropdownLists,
            $dropdownItems,
            $tipsContainer,
            $tipsList,
            _tipsList,
            _tipsFilteredList,
            _resultsFiltered,
            _resultsFilterCategory,
            _resultsFilterValue;

        var dropdownAnimation = function (event) {
            event.preventDefault();

            var isActive = query.hasClass(this, CONSTANTS.DROPDOWN_BUTTON_ACTIVE_CLASS),
                $selectedDropdown = this.parentNode,
                $selectedList = $selectedDropdown.getElementsByClassName(
                    CONSTANTS.DROPDOWN_LIST_CLASS
                )[0],
                $selectedIcon = this.getElementsByClassName(
                    CONSTANTS.DROPDOWN_BUTTON_ICON_CLASS
                )[0];

            if (!isActive) {
                closeDropdowns();

                gsap.from($selectedList, {
                    duration: 0.2,
                    y: -20,
                    opacity: 0,
                    ease: "power1.inOut"
                });

                $selectedList.style.display = 'block';
                query.addClass(this, CONSTANTS.DROPDOWN_BUTTON_ACTIVE_CLASS);
                query.addClass($selectedIcon, CONSTANTS.DROPDOWN_BUTTON_ICON_OPEN_CLASS);

                //set aria-expanded: TRUE
                this.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            } else {
                closeDropdowns();
            }
        };

        var closeDropdowns = function () {
            _.each($dropdownLists, function ($dropdownList) {
                $dropdownList.style.display = 'none';
            });

            query.removeClass($dropdownButtons, CONSTANTS.DROPDOWN_BUTTON_ACTIVE_CLASS);
            query.removeClass($dropdownArrows, CONSTANTS.DROPDOWN_BUTTON_ICON_OPEN_CLASS);

            //Set all buttons aria-expanded: FALSE.
            _.each($dropdownButtons, function (dropdownButton) {
                if (dropdownButton.getAttribute(CONSTANTS.ARIA_EXPANDED)) {
                    dropdownButton.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
                }
            });
        };

        var updateAttributesSelectedItemA11Y = function (target, dropdownItems) {
            _.each(dropdownItems, function (dropdownItem) {
                if (dropdownItem.getAttribute(CONSTANTS.ARIA_CURRENT)) {
                    dropdownItem.setAttribute(CONSTANTS.ARIA_CURRENT, false);
                }
            });

            target.setAttribute(CONSTANTS.ARIA_CURRENT, true);
        }

        var selectedValue = function (event) {
            event.preventDefault();

            if (query.hasClass(this, CONSTANTS.DROPDOWN_ITEM_ACTIVE_CLASS)) return;

            var $selectedDropdown = this.parentNode.parentNode.parentNode.parentNode,
                $selectedDropdownButton = $selectedDropdown.getElementsByClassName(
                    CONSTANTS.DROPDOWN_BUTTON_CLASS
                )[0],
                $selectedDropdownItems = this.parentNode.parentNode.getElementsByClassName(
                    CONSTANTS.DROPDOWN_ITEM_CLASS
                ),
                $dropdownTitle = $selectedDropdownButton.getElementsByClassName(
                    CONSTANTS.DROPDOWN_BUTTON_TITLE_CLASS
                )[0],
                optionTitle = this.innerText.trim();

            // Set selected item icon
            query.removeClass($selectedDropdownItems, CONSTANTS.DROPDOWN_ITEM_ACTIVE_CLASS);
            query.addClass(this, CONSTANTS.DROPDOWN_ITEM_ACTIVE_CLASS);
            updateAttributesSelectedItemA11Y(this, $selectedDropdownItems);

            // Change dropdown title to selected value
            $dropdownTitle.innerText = optionTitle;
            closeDropdowns();

            // Manage selection action to do
            if (query.hasClass($selectedDropdown, CONSTANTS.DROPDOWN_SORT_CLASS)) {
                sortTips(this.dataset.value);
            } else {
                filterTips(this.dataset.value, this.dataset.subValue);
            }

            displayResults();
            paginationFiltering();
            $paginationReset.click();
        };

        var sortTips = function (sortType) {
            // Sort the array by the type selected
            _tipsFilteredList = _tipsFilteredList.sort(function (a, b) {
                return (
                    parseFloat(b.getAttribute('data-' + sortType)) -
                    parseFloat(a.getAttribute('data-' + sortType))
                );
            });

            setTipsContainer();
        };

        var selectFilterValue = function (filterValue) {
            // Select the load filter value
            var $selectedItem = $tipsFiltersComponent.querySelector(
                '[data-value="' + filterValue + '"]'
            ),
                $selectedDropdown = $tipsFiltersComponent.querySelector(
                    '[data-value="' + filterValue + '"]'
                ).parentNode.parentNode.parentNode.parentNode,
                $selectedDropdownButton = $selectedDropdown.getElementsByClassName(
                    CONSTANTS.DROPDOWN_BUTTON_CLASS
                )[0],
                $selectedDropdownItems = $selectedDropdownButton.parentNode.parentNode.getElementsByClassName(
                    CONSTANTS.DROPDOWN_ITEM_CLASS
                ),
                $dropdownTitle = $selectedDropdownButton.getElementsByClassName(
                    CONSTANTS.DROPDOWN_BUTTON_TITLE_CLASS
                )[0],
                optionTitle = $selectedItem.innerText.trim();

            // Set selected item icon
            query.removeClass($selectedDropdownItems, CONSTANTS.DROPDOWN_ITEM_ACTIVE_CLASS);
            query.addClass($selectedItem, CONSTANTS.DROPDOWN_ITEM_ACTIVE_CLASS);

            // Change dropdown title to selected value
            $dropdownTitle.innerText = optionTitle;
        };

        var filterTips = function (filterValue, filterType) {
            // Reset filtered list
            _tipsFilteredList = [];

            // If all is selected, no filtering is needed
            if (filterValue.toUpperCase() == CONSTANTS.TIPS_ALL_VALUE) {
                _tipsFilteredList = _tipsList;
            } else {
                // Filter tips by the type>value selected
                for (var index = 0; index < _tipsList.length; index++) {
                    var tipsItem = _tipsList[index].cloneNode(true),
                        tipFilterValue = tipsItem.getAttribute('data-' + filterType);

                    if (tipFilterValue == null) continue;

                    // If '|' is included, item has more than value, so an array should be used
                    if (tipFilterValue.includes('|')) {
                        var tipFilterValues = tipFilterValue.toUpperCase().split('|');

                        if (tipFilterValues.includes(filterValue.toUpperCase())) {
                            _tipsFilteredList.push(tipsItem);
                        }
                    } else {
                        if (tipFilterValue.toUpperCase() == filterValue.toUpperCase()) {
                            _tipsFilteredList.push(tipsItem);
                        }
                    }
                }

                // If filtered list has no content, show ALL instead
                if (_tipsFilteredList.length === 0) {
                    _tipsFilteredList = _tipsList;
                }
            }

            // Sort after filtering
            var $sortSelectedItem = $dropdownSortList.getElementsByClassName(
                CONSTANTS.DROPDOWN_ITEM_ACTIVE_CLASS
            )[0];

            if ($sortSelectedItem != undefined) {
                sortTips($sortSelectedItem.dataset.value);
            }

            setTipsContainer();
        };

        var setTipsContainer = function () {
            var filteredList = '';

            for (var index = 0; index < _tipsFilteredList.length; index++) {
                var filteredItem = _tipsFilteredList[index].cloneNode(true);

                filteredList += filteredItem.outerHTML;
            }

            $tipsContainer.innerHTML = filteredList;
        };

        var displayResults = function () {
            var $tipsFiltered = $tipsContainer.getElementsByClassName(CONSTANTS.TIPS_CARD_SELECTOR);

            window.scrollTo(0, $tipsFiltersComponent.offsetTop - $header.offsetHeight);
            coned.utils.initializeModules($tipsContainer);

            // Apply load animation
            _.each($tipsFiltered, function ($tipFiltered) {
                query.addClass($tipFiltered, CONSTANTS.TIPS_CARD_ANIMATION_CLASS);
            });

            new coned.components.LoadCardTiles($tipsContainer);
        };

        var paginationFiltering = function () {
            var paginationDivider = $paginationModule.dataset.divider,
                displayTotal = paginationDivider * 1,
                showPageIndex,
                $resultToShow;

            // Hide all results
            _.each($paginationResults.children, function ($result) {
                query.addClass($result, $paginationResults.dataset.resultsHide);
            });

            // Show the corresponding page results
            for (
                showPageIndex = displayTotal - paginationDivider;
                showPageIndex < displayTotal && showPageIndex < $paginationResults.children.length;
                showPageIndex++
            ) {
                $resultToShow = $paginationResults.children[showPageIndex];

                query.removeClass($resultToShow, $paginationResults.dataset.resultsHide);
            }

            $paginationTotal.innerHTML = $paginationResults.children.length;
        };

        var initializeData = function () {
            $paginationModule = document.getElementsByClassName(CONSTANTS.PAGINATION_MODULE)[0];
            $paginationResults = document.getElementsByClassName(
                CONSTANTS.PAGINATION_RESULTS_CLASS
            )[0];
            $paginationTotal = document.getElementsByClassName(
                CONSTANTS.PAGINATION_RESULTS_TOTAL
            )[0];
            $paginationReset = document.getElementsByClassName(CONSTANTS.PAGINATION_RESET_CLASS)[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER_WRAPPER)[0];
            $dropdownButtons = $tipsFiltersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_BUTTON_CLASS
            );
            $dropdownArrows = $tipsFiltersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_BUTTON_ICON_CLASS
            );
            $dropdownSort = $tipsFiltersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_SORT_CLASS
            )[0];
            $dropdownSortList = $dropdownSort.getElementsByClassName(
                CONSTANTS.DROPDOWN_LIST_CLASS
            )[0];
            $dropdownLists = $tipsFiltersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_LIST_CLASS
            );
            $dropdownItems = $tipsFiltersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_ITEM_CLASS
            );
            $tipsContainer = document.getElementsByClassName(CONSTANTS.TIPS_LIST_CLASS)[0];
            $tipsList = $tipsContainer.getElementsByClassName(CONSTANTS.TIPS_ITEM_CLASS);
            _resultsFiltered = $tipsFiltersComponent.dataset.filteredResults;
            _resultsFilterCategory = $tipsFiltersComponent.dataset.filterCategory;
            _resultsFilterValue = $tipsFiltersComponent.dataset.filterValue;

            // Initialize tips' arrays
            var tipsAmount = $tipsList.length;

            _tipsList = new Array(tipsAmount);
            _tipsFilteredList = new Array(tipsAmount);

            for (var index = 0; index < tipsAmount; index++) {
                _tipsList[index] = $tipsList[index].cloneNode(true);
                _tipsFilteredList[index] = $tipsList[index].cloneNode(true);
            }

            //Filtered results on load
            if (
                _resultsFiltered &&
                _resultsFiltered.toLowerCase() === 'true' &&
                _resultsFilterValue &&
                _resultsFilterCategory
            ) {
                filterTips(_resultsFilterValue, _resultsFilterCategory);
                displayResults();
                if (_tipsFilteredList.length !== _tipsList.length) {
                    selectFilterValue(_resultsFilterValue);
                    paginationFiltering();
                    $paginationReset.click();
                }
            }
        };

        var initializeEvents = function () {
            // add event to dropdown buttons
            _.each($dropdownButtons, function ($dropdownButton) {
                coned.utils.addGeneralListeners($dropdownButton, dropdownAnimation);
            });

            // add event to dropdown items
            _.each($dropdownItems, function ($dropdownItem) {
                coned.utils.addGeneralListeners($dropdownItem, selectedValue);
            });

            // Adding the focus event on all the cards
            var listWrapperElements = document.getElementsByClassName(CONSTANTS.CARD_CLASS_WRAP);

            for (var wrapElement = 0; wrapElement < listWrapperElements.length; wrapElement++) {
                listWrapperElements[wrapElement].addEventListener('focus', function (event) {
                    var cardWrapperElement = document.getElementsByClassName(
                        CONSTANTS.CARD_CLASS_FOCUS
                    );

                    if (cardWrapperElement.length > 0) {
                        cardWrapperElement[0].classList.remove(CONSTANTS.CARD_CLASS_FOCUS);
                    }
                    event.currentTarget.parentElement.parentElement.classList.add(
                        CONSTANTS.CARD_CLASS_FOCUS
                    );
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    TipsFiltersComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return TipsFiltersComponent;
})();
