// ==================== SEARCH SUBMIT COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SearchSubmit = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        INPUT_SELECTOR: 'js-search-box-input',
        INPUT_ANIMATION_SELECTOR: 'js-input-selector',
        INPUT_CLASS_ANIMATION: 'search-box__animation--start',
        SEARCH_INPUT: 'js-search-box-input',
        CAPTCHA_ELEMENT: 'js-button-recaptcha',
        CAPTCHA_VERIFY_SELECTOR: 'js-recaptcha-verify-selector'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var SearchSubmit = function ($input) {
        /**
         * PRIVATE METHODS
         */
        var $inputSelector,
            $inputAnimationSelector,
            $searchForm,
            $searchFormAction,
            $formInput,
            $captchaElement,
            recaptcha,
            _hasCaptcha;

        var toggleFocus = function ($element, animationClass) {
            if (query.hasClass($element, CONSTANTS.INPUT_CLASS_ANIMATION)) {
                query.removeClass($element, animationClass);
            } else {
                query.addClass($element, animationClass);
            }
        };

        var searchFormHandler = function (value) {
            var searchTerm = encodeURI(value);

            $searchForm.action = '';
            $searchForm.action = $searchFormAction + '?search=' + searchTerm;
        };

        var searchFormHandlerRecaptcha = function () {
            var value = $formInput.value;
            var searchTerm = encodeURI(value);

            if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                var $verify = document.getElementById(CONSTANTS.CAPTCHA_VERIFY_SELECTOR);

                if (!$verify) {
                    $verify = document.createElement('INPUT');
                    $verify.setAttribute('id', CONSTANTS.CAPTCHA_VERIFY_SELECTOR);
                    $verify.setAttribute('name', 'verify');
                    $verify.setAttribute('class', 'hidden');
                    $verify.setAttribute('value', recaptcha.getResponse());
                    $searchForm.appendChild($verify);
                } else {
                    $verify.setAttribute('value', recaptcha.getResponse());
                }
            }
            $searchForm.action = '';
            $searchForm.action = $searchFormAction + '?search=' + searchTerm;
            $searchForm.submit();
            recaptcha.reset();
        };

        var initializeData = function () {
            ($inputSelector = $input.getElementsByClassName(CONSTANTS.INPUT_SELECTOR)[0]),
                ($inputAnimationSelector = $input.getElementsByClassName(
                    CONSTANTS.INPUT_ANIMATION_SELECTOR
                )[0]),
                ($searchForm = $input);
            ($formInput = $input.getElementsByClassName(CONSTANTS.SEARCH_INPUT)[0]),
                ($searchFormAction = $input.action);
            $captchaElement = $searchForm.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT);
            _hasCaptcha = $captchaElement.length > 0;
        };

        var initializeEvents = function () {
            $inputSelector.addEventListener('focus', function () {
                toggleFocus($inputAnimationSelector, CONSTANTS.INPUT_CLASS_ANIMATION);
            });

            $inputSelector.addEventListener('focusout', function () {
                toggleFocus($inputAnimationSelector, CONSTANTS.INPUT_CLASS_ANIMATION);
            });

            if (_hasCaptcha) {
                recaptcha = new coned.components.Recaptcha(
                    $searchForm,
                    searchFormHandlerRecaptcha,
                    searchFormHandlerRecaptcha
                );
                $searchForm.addEventListener('submit', function () {
                    searchFormHandlerRecaptcha();
                });
            } else {
                $searchForm.addEventListener('submit', function () {
                    searchFormHandler($formInput.value);
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SearchSubmit.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SearchSubmit;
})();
