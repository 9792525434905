// ==================== MY ACCOUNT COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.MyAccount = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        LAZY_LOAD_CLASS: 'js-lazy-load'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var MyAccount = function ($myAccount) {
        /**
         * PRIVATE METHODS
         */
        var $formLoading;

        /**
         * Checks if module should be lazy-loaded of not
         */
         var preInitialize = function () {
            if (query.hasClass($myAccount, CONSTANTS.LAZY_LOAD_CLASS, true)) {
                // Add listener to wait for all modules to be loaded
                window.addEventListener('modules-loaded', function () {
                    // As soon as eveything is loaded, fire event
                    coned.utils.triggerEvent($myAccount, 'lazy-load-start');
                });
            } else {
                initializeData();
                initializeEvents();
                isLoaded = true;
            }
        };

        var initializeData = function () {
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
        };


        var initializeEvents = function () {
            // Trying to solve dashboard issues, loading will be displayed until this point
            if($formLoading) {
                $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            preInitialize();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     MyAccount.prototype.isLoaded = function () {
        return isLoaded;
    };
    return MyAccount;
})();
