// ==================== SUGGESTION TILE COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SuggestionTile = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SUGGESTION_TILE_CTA: 'js-suggestion-tile-cta',
        IOS_BUTTON_SELECTOR: 'js-ios-button',
        ANDROID_BUTTON_SELECTOR: 'js-android-button',
        MARGIN_TOP_MODIFIER: 'overview-bill-card-side__cta--margin-top',
        HIDDEN_CLASS: 'hidden',
        toMatchAndroid: [/Android/i],
        toMatchIos: [/iPhone/i, /iPad/i, /iPod/i]
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var SuggestionTile = function ($suggestionTileCard) {
        var $suggestionTileCTAs, $iOSButton, $androidButton, _tileScenario;

        var addGTMForSuggestionTileCTA = function (event) {
            var gtmData = { event: 'scenario' + _tileScenario + '.click' },
                eventLabel = event.target.dataset.eventLabel
                    ? event.target.dataset.eventLabel
                    : event.target.parentElement.dataset.eventLabel;

            if (eventLabel) {
                gtmData.contentTitle = eventLabel;
            }

            // Add GTM for cta click event
            dataLayer.push(gtmData);
        };

        var detectMobile = function () {
            var isAndroid, isIos;

            isAndroid = CONSTANTS.toMatchAndroid.some(getMatch);
            isIos = CONSTANTS.toMatchIos.some(getMatch);

            if (isAndroid || isIos) {
                if (isAndroid) {
                    $iOSButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $androidButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                $iOSButton.classList.add(CONSTANTS.MARGIN_TOP_MODIFIER);
            }
        };

        var getMatch = function (toMatchItem) {
            return navigator.userAgent.match(toMatchItem);
        };

        var initializeData = function () {
            $suggestionTileCTAs = $suggestionTileCard.getElementsByClassName(
                CONSTANTS.SUGGESTION_TILE_CTA
            );
            $iOSButton = $suggestionTileCard.getElementsByClassName(
                CONSTANTS.IOS_BUTTON_SELECTOR
            )[0];
            $androidButton = $suggestionTileCard.getElementsByClassName(
                CONSTANTS.ANDROID_BUTTON_SELECTOR
            )[0];
            _tileScenario = $suggestionTileCard.dataset.tileScenario;
        };

        var initializeEvents = function () {
            if (_tileScenario) {
                for (var i = 0; i < $suggestionTileCTAs.length; ++i) {
                    coned.utils.addGeneralListeners(
                        $suggestionTileCTAs[i],
                        addGTMForSuggestionTileCTA
                    );
                }
            }
            if ($iOSButton && $androidButton) {
                detectMobile();
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SuggestionTile.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SuggestionTile;
})();
