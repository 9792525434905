// ==================== COMPARISON TABLE COMPONENT =========================
/* global ResizeObserver */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ComparisonTable = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SITECORE_ID_NAME: 'ScId',
        COLUMN_0_DROPDOWN_SELECTOR: 'js-select-column-0',
        COLUMN_1_DROPDOWN_SELECTOR: 'js-select-column-1',
        ROW_SELECTOR: 'js-row',
        CELL_SELECTOR: 'js-cell',
        IMAGE_SELECTOR: 'js-image',
        TEXT_SELECTOR: 'js-text',
        ERROR_MESSAGE_SELECTOR: 'js-service-error',
        HEADER_SELECTOR: 'js-header-wrapper',
        STICKY_ROW_SELECTOR: 'js-sticky-row',
        STICKY_NAV_MODULE_SELECTOR: 'js-anchor-link-module',
        HIDDEN_CLASS: 'hidden',
        CHANGE_EVENT: 'change',
        TOP_PROPERTY: 'top',
        OPTION_ELEMENT: 'option'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var ComparisonTable = function ($comparisonTable) {
        /**
         * PRIVATE METHODS
         */
        var tableData,
            dropDownsSelectedItemIndex,
            headerHeight,
            stickyNavHeight,
            $column0Dropdown,
            $column1Dropdown,
            $tableRows,
            $errorMessage,
            $header,
            $stickyNavModule,
            $stickyRow;

        /**
         * Get table data from the service.
         */
        var getTableData = function () {
            var serviceUrl = $comparisonTable.dataset.getServiceUrl,
                params;

            // Service Data
            params = {
                scId: query.getFormInputValue($comparisonTable, CONSTANTS.SITECORE_ID_NAME)
            };

            query.getData(
                serviceUrl,
                successGetTableData,
                errorGetTableData,
                params,
                false
            );
        };

        /**
         * On successful service call handler. 
         * Call to set data and update dropdowns.
         * @param {Object} data Object containing the response of the service call.
         */
        var successGetTableData = function (data) {
            if (coned.utils.isPatternLab()) {
                // Set content for different tables configurations
                var url = 
                    $comparisonTable.dataset.plDataLong ?
                        coned.plConstants.COMPARISON_TABLE_DATA_LONG :
                        coned.plConstants.COMPARISON_TABLE_DATA;

                query.getData(
                    url,
                    function (data) {
                        setTableData(data);
                        addDropdownsOptions();
                    },
                    errorGetTableData
                )
            } else {
                setTableData(data);
                addDropdownsOptions();
            }
        };

        /**
         * On error service call handler. 
         * Show error message.
         */
        var errorGetTableData = function () {
            query.removeClass($errorMessage, CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Set data to a global var.
         * @param {Object} data Object containing the response of the service call.
         */
        var setTableData = function (data) {
            tableData = data;
        };

        /**
         * Add all the additional options to each dropdown with the data from the service.
         */        
        var addDropdownsOptions = function () {
            var dropdownOptions = tableData[0].Cells,
                $dropDowns = [
                    $column0Dropdown, 
                    $column1Dropdown
                ],
                option;

            $dropDowns.forEach(function ($dropdown) {
                // Start at index 3, since index 0 is the label, and 1 & 2 already exist.
                for (var index = 3; index < dropdownOptions.length; index++) {
                    option = document.createElement(
                        CONSTANTS.OPTION_ELEMENT
                    );
                    option.value = option.text = dropdownOptions[index];
                    $dropdown.add(option);
                }
            });
        };

        /**
         * Update content for each cell of the column based on dropdown selection.
         * @param {Event} event Change event.
         */
        var updateTableColumn = function (event) {
            var $dropdown = event.target,
                selectedItemIndex = $dropdown.selectedIndex,
                currentColumn = 
                    $dropdown === $column0Dropdown ?  
                        0 : 
                        1,
                dropdownStateIndex = [
                    null, null
                ],
                $tableRow,
                $tableCell,
                $tableImg,
                $tableText,
                dataCellContent,
                dataRow,
                dataImgAlt,
                dataImgSrc;

            // If the selection matches what is already selected on the other dropdown,
            // swap columns content and dropdown selected state.
            if (isExistingSelection(selectedItemIndex)) {
                swapColumns($dropdown);
                swapDropdownsState();
                
                return;

            // Update the state of the index selection for the dropdown on the current column
            } else {
                dropdownStateIndex[currentColumn] = selectedItemIndex;
                setDropdownsState(dropdownStateIndex);
            }

            // Loop through each row to get the cell for the actual column and update its content
            // based off of the data structure from the service and the dropdown selection made
            for (var index = 0; index < tableData.length; index++) {
                $tableRow = $tableRows[index];
                $tableCell = $tableRow.getElementsByClassName(
                    CONSTANTS.CELL_SELECTOR
                )[currentColumn];
                dataRow = tableData[index];
                dataCellContent = dataRow.Cells[
                    selectedItemIndex + 1
                ];

                // Cells with images need alt and src attribute updates
                if (tableData[index].IsImage === true) {
                    $tableImg = $tableCell.getElementsByClassName(
                        CONSTANTS.IMAGE_SELECTOR
                    )[0];
                    dataImgAlt = dataCellContent.split(
                        '|||'
                    )[0]
                    .trim()
                    .replace('alt="', '')
                    .replace('\"', '');
                    dataImgSrc = dataCellContent.split(
                        '|||'
                    )[1]
                    .trim()
                    .replace('src="', '')
                    .replace('\"', '');

                    $tableImg.alt = dataImgAlt;
                    $tableImg.src = dataImgSrc;
                    
                // Regular cells need body updates, cell headers do not change
                } else {
                    $tableText = $tableCell.getElementsByClassName(
                        CONSTANTS.TEXT_SELECTOR
                    )[0];
                    
                    $tableText.innerHTML = dataCellContent;
                }
            }
        };

        /**
         * Check if the selection on both dropdowns matches.
         */
        var isExistingSelection = function () {
            if ($column0Dropdown.selectedIndex === $column1Dropdown.selectedIndex) {
                return true;
            }

            return false;
        };

        /**
         * Swap the content between columns.
         * @param {Element} $selectedDropdown The dropdown that triggered the change event.
         */
        var swapColumns = function ($selectedDropdown) {
            var cellCol1Content,
                $cells,
                $cellCol0,
                $cellCol1,
                $cellCol0Text,
                $cellCol1Text,
                $cellCol0Img,
                $cellCol1Img,
                cellCol1ImgAlt,
                cellCol1ImgSrc,
                _isImageRow;

            // Loop through each row to swap columns content
            for (var index = 0; index < $tableRows.length; index++) {
                _isImageRow = $tableRows[index].dataset.isImageRow;
                $cells = $tableRows[index].getElementsByClassName(
                    CONSTANTS.CELL_SELECTOR
                );
                $cellCol0 = $cells[0];
                $cellCol1 = $cells[1];

                // Cells with images need alt and src attributes swap
                if (_isImageRow) {
                    $cellCol0Img = $cellCol0.getElementsByClassName(
                        CONSTANTS.IMAGE_SELECTOR
                    )[0];
                    $cellCol1Img = $cellCol1.getElementsByClassName(
                        CONSTANTS.IMAGE_SELECTOR
                    )[0];

                    // Save col1 values temporarily
                    cellCol1ImgAlt = $cellCol0Img.alt;
                    cellCol1ImgSrc = $cellCol0Img.src;
                    // Swap columns values
                    $cellCol0Img.alt = $cellCol1Img.alt;
                    $cellCol0Img.src = $cellCol1Img.src;
                    $cellCol1Img.alt = cellCol1ImgAlt;
                    $cellCol1Img.src = cellCol1ImgSrc;

                // Regular cells need body swap, cell headers do not change
                } else {
                    $cellCol0Text = $cellCol0.getElementsByClassName(
                        CONSTANTS.TEXT_SELECTOR
                    )[0];
                    $cellCol1Text = $cellCol1.getElementsByClassName(
                        CONSTANTS.TEXT_SELECTOR
                    )[0];
    
                    // Save col1 value temporarily
                    cellCol1Content = $cellCol0Text.innerHTML;
                    // Swap columns values
                    $cellCol0Text.innerHTML = $cellCol1Text.innerHTML;
                    $cellCol1Text.innerHTML = cellCol1Content;
                }
            }

            // Update the other dropdown with the previous selection from the selected dropdown
            if ($selectedDropdown === $column0Dropdown) {
                $column1Dropdown.selectedIndex = getDropdownState([true, null]);
            } else {
                $column0Dropdown.selectedIndex = getDropdownState([null, true]);
            }
        };

        /**
         * Set the selected index state of each dropdown.
         * Useful to be able to retrieve the previous value of the dropdown selection later on.
         * @param {Array} dropdownsSelectedIndex [column0Dropdown, column1Dropdown] Selected index on each position of the array, to set state for each dropdown. Use null if one didn't change.
         */
        var setDropdownsState = function (dropdownsSelectedIndex) {
            if (dropdownsSelectedIndex[0] !== null) {
                dropDownsSelectedItemIndex.dropdownCol0 = dropdownsSelectedIndex[0];
            }

            if (dropdownsSelectedIndex[1] !== null) {
                dropDownsSelectedItemIndex.dropdownCol1 = dropdownsSelectedIndex[1];
            }
        };

        /**
         * Get the selected index state for a given dropdown.
         * @param {Array} dropdownIndex  [column0Dropdown, column1Dropdown] Use true on the array position corresponding to the dropdown, to get its selected index state. Use null on the other position.
         * @return {Number} Selected index of the requested dropdown.
         */
        var getDropdownState = function (dropdownIndex) {
            if (dropdownIndex[0]) {
                return dropDownsSelectedItemIndex.dropdownCol0;
            }

            if (dropdownIndex[1]) {
                return dropDownsSelectedItemIndex.dropdownCol1;
            }
        };

        /**
         * Swap the selected index state between dropdowns.
         */
        var swapDropdownsState = function () {
            var dropdownCol0State = getDropdownState([true, null]),
                dropdownCol1State = getDropdownState([null, true]);

            setDropdownsState([
                dropdownCol1State, dropdownCol0State
            ]);
        };

        /**
         * Set the header height on the state.
         * Useful to be able to retrieve the previous value of the header height later on.
         * @param {Number} updatedHeight Header height in pixels, with no unit.
         */
        var setHeaderHeightState = function (updatedHeight) {
            headerHeight = updatedHeight;
        };
    
        /**
         * Get the header height stored on the state.
         * @return {Number} Header height in pixels, with no unit.
         */
        var getHeaderHeightState = function () {
            return headerHeight;
        };

        /**
         * Set the sticky nav height on the state.
         * Useful to be able to retrieve the previous value of the sticky nav height later on.
         * @param {Number} updatedHeight Sticky nav height in pixels, with no unit.
         */
        var setStickyNavHeightState = function (updatedHeight) {
            stickyNavHeight = updatedHeight;
        };
    
        /**
         * Get the sticky nav height stored on the state.
         * @return {Number} Sticky nav height in pixels, with no unit.
         */
        var getStickyNavHeightState = function () {
            return stickyNavHeight;
        };

        /**
         * Observe for element height changes, on change set elements accordingly.
         * @param {HTMLElement} $element Element to observe
         * @param {Function} getElementHeightStateCB Call back function to get height state for that element.
         * @param {Function} setElementHeightStateCB Call back function to set height state for that element.
         */
        var observeElementHeight = function ($element, getElementHeightStateCB, setElementHeightStateCB) {
            var observer; 

            var onResizeObserved = function (entries) {
                entries.forEach(function (entry) {
                    var updatedHeight;

                    // Current observed height vs previous height stored on state
                    if (entry.contentRect.height !== getElementHeightStateCB()) {
                        updatedHeight = entry.contentRect.height;

                        setElementHeightStateCB(updatedHeight);
                        setStickyRowTop();
                    }
                });
            };

            observer = new ResizeObserver(onResizeObserved);
            observer.observe($element);
        };

        /**
         * Set sticky row's top to header + sticky nav (if it exists) height, so it can stick right on their bottom.
         */
        var setStickyRowTop = function () {
            var stickyRowTop = parseFloat(window
                    .getComputedStyle($stickyRow, null)
                    .getPropertyValue(CONSTANTS.TOP_PROPERTY)
                );

            if ($stickyNavModule) {
                if (
                    stickyRowTop !== 
                    (getHeaderHeightState() + getStickyNavHeightState())
                ) {
                    $stickyRow.style.top = getHeaderHeightState() + getStickyNavHeightState() + 'px';
                }
            } else {
                if (stickyRowTop !== getHeaderHeightState()) {
                    $stickyRow.style.top = getHeaderHeightState() + 'px';
                }
            }
        };

        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            $column0Dropdown = $comparisonTable.getElementsByClassName(
                CONSTANTS.COLUMN_0_DROPDOWN_SELECTOR
            )[0];
            $column1Dropdown = $comparisonTable.getElementsByClassName(
                CONSTANTS.COLUMN_1_DROPDOWN_SELECTOR
            )[0];
            $tableRows = $comparisonTable.getElementsByClassName(
                CONSTANTS.ROW_SELECTOR
            );
            $errorMessage = $comparisonTable.getElementsByClassName(
                CONSTANTS.ERROR_MESSAGE_SELECTOR
            )[0];
            $header = document.getElementsByClassName(
                CONSTANTS.HEADER_SELECTOR
            )[0];
            $stickyNavModule = document.getElementsByClassName(
                CONSTANTS.STICKY_NAV_MODULE_SELECTOR
            )[0];
            $stickyRow = $comparisonTable.getElementsByClassName(
                CONSTANTS.STICKY_ROW_SELECTOR
            )[0];
            // Selected item index state for each dropdown
            dropDownsSelectedItemIndex = {
                dropdownCol0: $column0Dropdown.selectedIndex,
                dropdownCol1: $column1Dropdown.selectedIndex
            };
            // Header height state
            headerHeight = $header.offsetHeight;
            // Sticky Nav height state
            if ($stickyNavModule) {
                stickyNavHeight = $stickyNavModule.offsetHeight;
            }
            // Set sticky top position if needed
            setStickyRowTop();
            // Load table data from the service
            getTableData();
        };

        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            $column0Dropdown.addEventListener(CONSTANTS.CHANGE_EVENT, updateTableColumn);
            $column1Dropdown.addEventListener(CONSTANTS.CHANGE_EVENT, updateTableColumn);
            
            observeElementHeight(
                $header, 
                getHeaderHeightState, 
                setHeaderHeightState
            );
            $stickyNavModule && observeElementHeight(
                $stickyNavModule, 
                getStickyNavHeightState, 
                setStickyNavHeightState
            );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ComparisonTable.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ComparisonTable;
})();
