// ==================== RESULTS OVERVIEW COMPONENT ============================
/* global _ */

var query = query || {},
    coned = coned || {};

coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FinancialAdvisorResultsOverview = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        STEPS_PARENT_SELECTOR: 'js-steps-parent',
        ELIGIBLE_PROGRAMS_SELECTOR: 'js-eligible-programs',
        FURTHER_PROGRAMS_SELECTOR: 'js-further-programs',
        FALSE: 'false',
        ERROR_STATUS: 'Error',
        PAGE_LOADING: 'js-page-loading',
        PAGE_LOADING_HIDDEN: 'page-loading--hidden',
        PROGRAM_SELECTOR: 'js-program',
        PROGRAMS_RESULT_SELECTOR: 'js-programs-result',
        HIDDEN_CLASS: 'hidden',
        ERROR_MESSAGING_MANAGED_SELECTOR: 'js-error-messaging-managed',
        LOAD_PROGRAM_DATA: 'load-data-programs',
        LOAD_DATA_BUTTON: 'js-load-data-continue',
        NOT_MATCHES_FOUND_SELECTOR: 'js-not-matches-found',
        TRUE_STRING: 'true',
        YES_STRING: 'yes',
        PARAMETER_ACCOUNT_STATE: 'accountstate',
        BNA_ACCOUNT_STATE: 'bna',
        EAP_RADIO_NAME: 'eap'

    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var FinancialAdvisorResultsOverview = function ($resultOverview) {
        var $financialAdvisorParent,
            $eligibleProgram,
            $furtherProgram,
            $programsResult,
            $loadDataButton,
            $errorMessagingManaged,
            $pageLoading,
            isLoadedEligible,
            isLoadedFurther;

        /**
         * PRIVATE METHODS
        */

        // Loads eligible program and further program
        var loadDataServicesCall = function () {
            var intervalIdPrograms = setInterval(function () {
                if ($financialAdvisorParent.dataset.successQuestionnaireCallback) {
                    loadEligibleDataServiceCall();
                    loadFurtherDataServiceCall();
                    $loadDataButton.removeEventListener(CONSTANTS.LOAD_PROGRAM_DATA, loadDataServicesCall);
                    clearInterval(intervalIdPrograms);
                    query.removeClass($pageLoading, CONSTANTS.PAGE_LOADING_HIDDEN);
                }
            }, 100);

            var intervalIdCheckMatches = setInterval(function () {
                if (isLoadedEligible && isLoadedFurther) {
                    var hasEligiblePrograms = hasPrograms($eligibleProgram),
                        hasFurtherPrograms = hasPrograms($furtherProgram),
                        notShowError = $errorMessagingManaged.classList.contains(CONSTANTS.HIDDEN_CLASS);
                    if (!hasEligiblePrograms && !hasFurtherPrograms) {
                        showNotMatchesFoundContent();
                    } else if ((hasEligiblePrograms || hasFurtherPrograms) && notShowError) {
                        query.removeClass($programsResult, CONSTANTS.HIDDEN_CLASS);
                    }
                    query.addClass($pageLoading, CONSTANTS.PAGE_LOADING_HIDDEN);
                    clearInterval(intervalIdCheckMatches);
                }
            }, 100);
        }

        var hasPrograms = function ($program) {
            var $programsInRows = $program.getElementsByClassName(CONSTANTS.PROGRAM_SELECTOR),
                hasProgramsInRows = $programsInRows.length !== 0,
                hasProgramsInFirstRow = hasProgramsInRows ? $programsInRows[0].childElementCount > 0 : false;

            return hasProgramsInRows && hasProgramsInFirstRow;
        }

        // Loads eligible program all module data from service
        var loadEligibleDataServiceCall = function () {
            if (!isLoaded) {
                var serviceUrl = $eligibleProgram.dataset.eligibilityService;
                if (!serviceUrl) {
                    errorLoadDataServiceCall();
                    return;
                }
                query.getData(serviceUrl, successLoadEligibleDataServiceCall, errorLoadDataServiceCall);
            }
        };

        // If call succeeded, load markup into module 
        var successLoadEligibleDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                var accountState = coned.utils.getUrlParameterValue(CONSTANTS.PARAMETER_ACCOUNT_STATE),
                    eligibleProgramHTML;

                if (accountState === CONSTANTS.BNA_ACCOUNT_STATE) {
                    eligibleProgramHTML = coned.plConstants.GET_FINANCIAL_ADVISOR_ELIGIBLE_PROGRAMS_BNA_HTML_URL;
                    query.getData(
                        eligibleProgramHTML,
                        setEligibleContentMarkup,
                        errorLoadDataServiceCall
                    );
                } else {
                    eligibleProgramHTML = coned.plConstants.GET_FINANCIAL_ADVISOR_ELIGIBLE_PROGRAMS_HTML_URL;
                    query.getData(
                        eligibleProgramHTML,
                        setEligibleContentMarkup,
                        errorLoadDataServiceCall
                    );
                }
            } else {
                setEligibleContentMarkup(data);
            }
        };

        // Call the set markup on the corresponding containers
        var setEligibleContentMarkup = function (data) {
            var notShowError = $errorMessagingManaged.classList.contains(CONSTANTS.HIDDEN_CLASS);

            if ((data && data.trim() !== "") && notShowError) {
                var parser = new DOMParser(),
                    HTMLObject,
                    $programContent;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $programContent = HTMLObject.getElementsByClassName(CONSTANTS.ELIGIBLE_PROGRAMS_SELECTOR);

                if ($programContent) {
                    setProgramContentMarkup($programContent, $eligibleProgram);
                }
            }
            isLoaded = true;
            isLoadedEligible = true;
        };

        // Sets the markup to the eligible or further program container
        var setProgramContentMarkup = function ($programContent, $currentProgram) {
            var hasEligibleContent = $programContent.length !== 0 && $programContent[0].dataset.content !== CONSTANTS.FALSE,
                newProgramAttrs;

            if (hasEligibleContent) {
                $programContent = $programContent[0];
            } else {
                errorLoadDataServiceCall();
                return;
            }
            $currentProgram.innerHTML = $programContent.innerHTML;
            newProgramAttrs = $programContent.attributes;

            while ($currentProgram.attributes.length > 0) {
                $currentProgram.removeAttribute($currentProgram.attributes[0].name);
            }

            _.each(newProgramAttrs, function (attribute) {
                $currentProgram.setAttribute(attribute.nodeName, attribute.nodeValue);
            });

            var $programs = $currentProgram.getElementsByClassName(CONSTANTS.PROGRAM_SELECTOR)[0];
            if ($programs.dataset.module) {
                coned.utils.initializeModules($currentProgram);
            }
        }

        // Loads Further program all module data from service
        var loadFurtherDataServiceCall = function () {
            var successQuestionnaireCallback = $financialAdvisorParent.dataset.successQuestionnaireCallback === CONSTANTS.TRUE_STRING,
                ignoreFurther = !coned.utils.isPatternLab() || successQuestionnaireCallback;
            if (!isLoaded && ignoreFurther) {
                var serviceUrl = $furtherProgram.dataset.furtherService,
                    incomeTable = $financialAdvisorParent.dataset.incomeTable,
                    greaterExpenses = $financialAdvisorParent.dataset.greaterExpenses,
                    heatingCooling = $financialAdvisorParent.dataset.heatingCooling,
                    accountType = $financialAdvisorParent.dataset.accountType,
                    dataLocation = $financialAdvisorParent.dataset.location,
                    dataDisconnectState = $financialAdvisorParent.dataset.disconnectState,
                    // EAP
                    eapElementExists = document.querySelectorAll("input[name=" + CONSTANTS.EAP_RADIO_NAME + "]").length > 0,
                    selectedEAP = document.querySelector("input[name=" + CONSTANTS.EAP_RADIO_NAME + "]:checked"),
                    eapSelection,

                    params = {
                        asynchronous: 1,
                        incomeTable: incomeTable,
                        greaterExpenses: greaterExpenses,
                        heatingCooling: heatingCooling,
                        accountType: accountType,
                        dataLocation: dataLocation,
                        dataDisconnectState: dataDisconnectState,
                        furtherPrograms: true
                    };

                // If EAP exists
                if (eapElementExists && selectedEAP) {
                    eapSelection = selectedEAP.id === CONSTANTS.YES_STRING;
                    params.eapSelection = eapSelection;
                }

                if (!serviceUrl) {
                    errorLoadDataServiceCall();
                    return;
                }

                query.getData(serviceUrl, successLoadFurtherDataServiceCall, errorLoadDataServiceCall, params);
            } else if (!ignoreFurther) {
                isLoadedFurther = true;
            }
        }

        // If call succeeded, load markup into module 
        var successLoadFurtherDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                var furtherProgramHTML = coned.plConstants.GET_FINANCIAL_ADVISOR_FURTHER_PROGRAMS_HTML_URL;

                query.getData(
                    furtherProgramHTML,
                    setFurtherContentMarkup,
                    errorLoadDataServiceCall
                );
            } else {
                setFurtherContentMarkup(data);
            }
        };

        // Call the set markup on the corresponding containers
        var setFurtherContentMarkup = function (data) {
            var notShowError = $errorMessagingManaged.classList.contains(CONSTANTS.HIDDEN_CLASS);

            if ((data && data.trim() !== "") && notShowError) {
                var parser = new DOMParser(),
                    HTMLObject,
                    $programContent;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $programContent = HTMLObject.getElementsByClassName(CONSTANTS.FURTHER_PROGRAMS_SELECTOR);

                if ($programContent) {
                    setProgramContentMarkup($programContent, $furtherProgram);
                }
            }
            isLoaded = true;
            isLoadedFurther = true;
        };

        // If call errored, hide module
        var errorLoadDataServiceCall = function () {
            query.addClass($pageLoading, CONSTANTS.PAGE_LOADING_HIDDEN);

            query.removeClass($errorMessagingManaged, CONSTANTS.HIDDEN_CLASS);
            $resultOverview.dataset.status = CONSTANTS.ERROR_STATUS;
            // Remove the listener when everything is already loaded
            $loadDataButton.removeEventListener(CONSTANTS.LOAD_PROGRAM_DATA, loadDataServicesCall);
            isLoaded = true;
        };

        // Not matches found content
        var showNotMatchesFoundContent = function () {
            var $notMatches = document.getElementsByClassName(CONSTANTS.NOT_MATCHES_FOUND_SELECTOR);
            query.removeClass($notMatches, CONSTANTS.HIDDEN_CLASS);
        }

        var initializeData = function () {
            $financialAdvisorParent = document.getElementsByClassName(CONSTANTS.STEPS_PARENT_SELECTOR)[0];
            $eligibleProgram = $resultOverview.getElementsByClassName(CONSTANTS.ELIGIBLE_PROGRAMS_SELECTOR)[0];
            $furtherProgram = $resultOverview.getElementsByClassName(CONSTANTS.FURTHER_PROGRAMS_SELECTOR)[0];
            $programsResult = $resultOverview.getElementsByClassName(CONSTANTS.PROGRAMS_RESULT_SELECTOR)[0];
            $pageLoading = document.getElementsByClassName(CONSTANTS.PAGE_LOADING)[0];
            $loadDataButton = document.getElementsByClassName(CONSTANTS.LOAD_DATA_BUTTON)[0];
            $errorMessagingManaged = document.getElementsByClassName(CONSTANTS.ERROR_MESSAGING_MANAGED_SELECTOR)[0];
            isLoadedEligible = false;
            isLoadedFurther = false;


        };

        var initializeEvents = function () {
            $loadDataButton.addEventListener(CONSTANTS.LOAD_PROGRAM_DATA, loadDataServicesCall);
        }

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FinancialAdvisorResultsOverview.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FinancialAdvisorResultsOverview;

})();
