// ==================== GLOBAL/CONSTANTS =========================

var query = query || {},
    coned = coned || {};
coned.constants = coned.constants || {};

/**
 * Constants available to be used in all modules.
 * @type {Object}
 */
coned.constants = {
    TRUE: 'true',
    FALSE: 'false',
    TABLET_MAX_SIZE: 1024,
    TABLET_MIN_SIZE: 768,
    MOBILE_MAX_SIZE: 767,
    DEFAULT_SCENARIO: '1',
    HTML_EXTENSION: '.html',
    ACTIVE_CLASS: 'js-active',
    FIXED_CLASS: 'js-fixed',
    HIDDEN_CLASS: 'js-hidden',
    MODULE_CLASS: 'js-module',
    HEADER_WRAPPER_CLASS: 'js-header-wrapper',
    MODAL_SELECTOR_CLASS: 'js-modal-selector-wrapper',
    PAGE_CONTENT_CLASS: 'js-page-content',
    PAGE_DISABLED: 'page-content--disabled',
    BODY_CLASS: 'js-body',
    HTML_CLASS: 'js-html',
    MOBILE_MODAL_OPEN: 'mobile-modal-open',
    MOBILE_OVERLAY_OPEN: 'mobile-overlay-open',
    MODAL_OPEN: 'modal-open',
    ERROR_MESSAGE: 'Error: Sorry, an error has occurred. Please try again.',
    ERROR_MESSAGE_VIEW_BILL: 'Error: Sorry, your bill isn’t currently viewable. Try again later.',
    ICON_ERROR_MESSAGE: 'error icon',
    SUCCESS_SERVICE_RESPONSE_CLASS: 'js-success-service-response',
    ERROR_SERVICE_RESPONSE_CLASS: 'js-error-service-response',
    QUALTRICS_TRIGGER_CLASS: 'js-qualtrics-trigger',
    CONED_TABS_VISIBLE_CLASS: 'coned-tabs--visible-parent',
    INPUT_ERROR_CLASS: 'coned-input-message--error',
    DEEP_LINK_URL_PARAM_NAME: 'sectionId',
    DEEP_LINK_URL_PARAM_TAB: 'tab1',
    DEEP_LINK_URL_PARAM_ACTION: 'action',
    CARD_FOCUS_CLASS: 'card-tile--focus',
    MOUSE_USER: 'mouse-user',
    VALID_CLASS: 'valid',
    /**
     * @type {'a[href]:not([tabindex="-1"]):not(.hidden), area[href]:not([tabindex="-1"]):not(.hidden), input:not([disabled]):not([tabindex="-1"]):not(.hidden), select:not([disabled]):not([tabindex="-1"]):not(.hidden), textarea:not([disabled]):not([tabindex="-1"]):not(.hidden), button:not([disabled]):not([tabindex="-1"]):not(.hidden), iframe:not([tabindex="-1"]):not(.hidden), object:not([tabindex="-1"]):not(.hidden), embed:not([tabindex="-1"]):not(.hidden), [tabindex]:not([tabindex="-1"]):not(.hidden), [contenteditable]:not([tabindex="-1"]):not(.hidden)'}
     */
    FOCUSABLE_ELEMENTS_DROPDOWN_MODAL_QUERY:
        'a[href]:not([tabindex="-1"]):not(.hidden), area[href]:not([tabindex="-1"]):not(.hidden), input:not([disabled]):not([tabindex="-1"]):not(.hidden), select:not([disabled]):not([tabindex="-1"]):not(.hidden), textarea:not([disabled]):not([tabindex="-1"]):not(.hidden), button:not([disabled]):not([tabindex="-1"]):not(.hidden), iframe:not([tabindex="-1"]):not(.hidden), object:not([tabindex="-1"]):not(.hidden), embed:not([tabindex="-1"]):not(.hidden), [tabindex]:not([tabindex="-1"]):not(.hidden), [contenteditable]:not([tabindex="-1"]):not(.hidden)',
    FOCUSABLE_ELEMENTS_QUERY:
        '[href]:not(.hidden), button:not(.hidden), input:not([type="hidden"]):not(.hidden), select:not(.hidden), textarea:not(.hidden), [tabindex]:not([tabindex="-1"]):not(.hidden)',
    FOCUSABLE_ELEMENTS_POPUP:
        'a[href]:not([disabled]), button:not([disabled])',
    MULTIPLE_GLOBAL_LAZY_LOAD_EVENT_DONE: 'multiple-global-lazy-load-event-done',
    MULTIPLE_GLOBAL_LAZY_LOAD_ELEMENT: 'js-multiple-global-lazy-load-element',

    CONED_INPUT: 'coned-input',
    INPUT_FILLED_CLASS: 'coned-input--filled',
    CONED_TEXT_AREA: 'coned-textarea',
    TEXT_AREA_FILLED_CLASS: 'coned-textarea--filled',
    CONED_CHECKBOX: 'coned-checkbox__input',
    CHECKBOX_INPUT_FILLED: 'coned-checkbox--checked',
    CONED_SELECT: 'coned-select',
    SELECT_INPUT_FILLED: 'coned-select--active',

    //SERVICE ERRORS
    INTERNAL_SERVICE_ERROR: '500',
    BAD_REQUEST: '400',
    UNATHORIZED: '401',
    /**
     * Deprecated instead use KEYBOARD_CODE below
     * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
     */
    //KEY CODES
    KEY_CODE: {
        BACKSPACE: 8,
        TAB: 9,
        ENTER: 13,
        SHIFT: 16,
        ESC: 27,
        SPACE: 32,
        END: 35,
        HOME: 36,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40,
        DELETE: 46
    },
    /**
     * KeyboardEvent.code constants values
     * usage https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
     */
    KEYBOARD_CODE: {

        /**
         * @type {'Backspace'}
         */
        BACKSPACE: 'Backspace',

        /**
         * @type {'Tab'}
         */
        TAB: 'Tab',

        /**
         * @type {'Enter'}
         */
        ENTER: 'Enter',
        /**
         * @type {'NumpadEnter'}
         */
        NUMPAD_ENTER: 'NumpadEnter',
        /**
         * @type {'Shift'}
         */
        SHIFT: 'Shift',

        /**
         * @type {'Escape'}
         */
        ESC: 'Escape',

        /**
         * @type {'Space'}
         */
        SPACE: 'Space',

        /**
         * @type {'End'}
         */
        END: 'End',

        /**
         * @type {'Home'}
         */
        HOME: 'Home',

        /**
         * @type {'ArrowLeft'}
         */
        LEFT: 'ArrowLeft',

        /**
         * @type {'ArrowUp'}
         */
        UP: 'ArrowUp',

        /**
         * @type {'ArrowRight'}
         */
        RIGHT: 'ArrowRight',

        /**
         * @type {'ArrowDown'}
         */
        DOWN: 'ArrowDown',

        /**
         * @type {'Delete'}
         */
        DELETE: 'Delete'
    },
    /**
     * @type {','}
     */
    COMMA: ',',
    /**
     * @type {';'}
     */
    SEMICOLON: ';',
    /**
     * @type {':'}
     */
    COLON: ':',
    /**
     * Dispatch custom events with detail information like:
     * CustomEvent<{value:string; count:string}> them you can
     * get the value passed in event.detail
     */
    CUSTOM_EVENTS: {

        /**
         * @type {'change-state-detail'}
         */
        CHANGE_STATE_DETAIL: 'change-state-detail',

        /**
         * @type {'set-state-detail'}
         */
        SET_STATE_DETAIL: 'set-state-detail',
        /**
         * @type {'get-state-detail'}
         */
        GET_STATE_DETAIL: 'get-state-detail'
    },
    ORDER: {
        /**
         * @type {'prev'}
         */
        PREV: 'prev',
        /**
         * @type {'next'}
         */
        NEXT: 'next'
    },
    NOT_FOCUSABLE: {
        /**
         * @type {'hidden'}
         */
        HIDDEN: 'hidden',
        /**
         * @type {'disabled'}
         */
        DISABLED: 'disabled',
        /**
         * @type {'tabindex'}
         */
        TABINDEX: 'tabindex',
        /**
         * @type {'none'}
         */
        DISPLAY_NONE: 'none',
        /**
         * @type {'0'}
         */
        TABINDEX_ENABLE: '0',
        /**
         * @type {'-1'}
         */
        TABINDEX_NEGATIVE: '-1',
        /**
         * @type {'a input button select textarea iframe'}
         */
        FOCUSABLE_TAGS: 'a input button select textarea iframe',
        /**
         * @type {'button link checkbox radio tab'}
         */
        FOCUSABLE_ROLES: 'button link checkbox radio tab'
    },
    /**
     * A11Y aria attributes
     */
    ARIA: {
        /**
         * Indicates whether the element is visible or not to assistive technologies.
         * @type {'aria-hidden'}
         */
        HIDDEN: 'aria-hidden',

        /**
         * Provides an accessible label for an element.
         * @type {'aria-label'}
         */
        LABEL: 'aria-label',

        /**
         * References the ID of another element that serves as the label for the current element.
         * @type {'aria-labelledby'}
         */
        LABELLEDBY: 'aria-labelledby',

        /**
         * References the ID of another element that provides additional description.
         * @type {'aria-describedby'}
         */
        DESCRIBEDBY: 'aria-describedby',

        /**
         * Indicates the elements that are controlled by the current element.
         * @type {'aria-controls'}
         */
        CONTROLS: 'aria-controls',

        /**
         * Indicates whether an element, such as an accordion control, is expanded or collapsed.
         * @type {'aria-expanded'}
         */
        EXPANDED: 'aria-expanded',

        /**
         * Indicates the selected state of an element in a selectable group.
         * @type {'aria-selected'}
         */
        SELECTED: 'aria-selected',

        /**
         * Indicates the pressed state of a button.
         * @type {'aria-pressed'}
         */
        PRESSED: 'aria-pressed',

        /**
         * Indicates the checked state of elements like checkboxes or radio buttons.
         * @type {'aria-checked'}
         */
        CHECKED: 'aria-checked',

        /**
         * Defines the role of an element, providing information about its function in the interface.
         * @type {'role'}
         */
        ROLE: 'role',

        /**
         * Indicates whether an element has a popup menu, sub-menu, or other popup type.
         * @type {'aria-haspopup'}
         */
        HASPOPUP: 'aria-haspopup',

        /**
         * Indicates the importance of dynamic updates for screen readers.
         * @type {'aria-live'}
         */
        LIVE: 'aria-live',

        /**
         * Indicates whether updates should be treated as a single atomic unit.
         * @type {'aria-atomic'}
         */
        ATOMIC: 'aria-atomic',

        /**
         * Indicates that a section of the page is busy and not available for interaction.
         * @type {'aria-busy'}
         */
        BUSY: 'aria-busy',

        /**
         * Indicates whether an element is currently being grabbed in a drag-and-drop operation.
         * @type {'aria-grabbed'}
         */
        GRABBED: 'aria-grabbed',

        /**
         * Indicates the type of drag-and-drop operation allowed.
         * @type {'aria-dropeffect'}
         */
        DROPEFFECT: 'aria-dropeffect',

        /**
         * Indicates that the current element owns the referenced element, providing an ownership relationship.
         * @type {'aria-owns'}
         */
        OWNS: 'aria-owns',

        /**
         * Indicates what types of content changes are relevant and should be announced by screen readers.
         * @type {'aria-relevant'}
         */
        RELEVANT: 'aria-relevant',

        /**
         * Indicates the next element in the navigation sequence.
         * @type {'aria-flowto'}
         */
        FLOWTO: 'aria-flowto',

        /**
         * Indicates the child element that has the current focus.
         * @type {'aria-activedescendant'}
         */
        ACTIVEDESCENDANT: 'aria-activedescendant',

        /**
         * Indicates the sort order of elements.
         * @type {'aria-sort'}
         */
        SORT: 'aria-sort',

        /**
         * Defines the semantics of the element to provide additional context to assistive technologies.
         * @type {'aria-semantics'}
         */
        SEMANTICS: 'aria-semantics',

        /**
         * Indicates the hierarchical level of an element within a structure, such as a tree.
         * @type {'aria-level'}
         */
        LEVEL: 'aria-level',

        /**
         * Defines the minimum value in a range control.
         * @type {'aria-valuemin'}
         */
        VALUEMIN: 'aria-valuemin',

        /**
         * Defines the maximum value in a range control.
         * @type {'aria-valuemax'}
         */
        VALUEMAX: 'aria-valuemax',

        /**
         * Defines the current value in a range control.
         * @type {'aria-valuenow'}
         */
        VALUENOW: 'aria-valuenow',

        /**
         * Provides a textual representation of the current value in a range control.
         * @type {'aria-valuetext'}
         */
        VALUETEXT: 'aria-valuetext',

        /**
         * Indicates whether an element, such as a text field, supports multiple lines of input.
         * @type {'aria-multiline'}
         */
        MULTILINE: 'aria-multiline',

        /**
         * Indicates whether multiple items can be selected in a group.
         * @type {'aria-multiselectable'}
         */
        MULTISELECTABLE: 'aria-multiselectable',

        /**
         * Indicates whether an element is read-only.
         * @type {'aria-readonly'}
         */
        READONLY: 'aria-readonly',

        /**
         * Indicates whether a field is required to complete the form.
         * @type {'aria-required'}
         */
        REQUIRED: 'aria-required',

        /**
         * Indicates whether the current input in a field is invalid.
         * @type {'aria-invalid'}
         */
        INVALID: 'aria-invalid',

        /**
         * Indicates that an element is a modal container that requires interaction before returning to the main page.
         * @type {'aria-modal'}
         */
        MODAL: 'aria-modal',

        /**
         * Indicates whether the element has a horizontal or vertical orientation.
         * @type {'aria-orientation'}
         */
        ORIENTATION: 'aria-orientation',

        /**
         * Indicates whether auto-suggestions are available for an input field.
         * @type {'aria-autocomplete'}
         */
        AUTOCOMPLETE: 'aria-autocomplete',

        /**
         * Provides an accessible placeholder for an input field.
         * @type {'aria-placeholder'}
         */
        PLACEHOLDER: 'aria-placeholder',

        /**
         * Defines the position of an element within a set.
         * @type {'aria-posinset'}
         */
        POSINSET: 'aria-posinset',

        /**
         * Defines the total size of the set to which an element belongs.
         * @type {'aria-setsize'}
         */
        SETSIZE: 'aria-setsize'
    }
};

/**
 * Pattern Lab specific constants available to be used in all modules.
 * @type {Object}
 */
coned.plConstants = {
    PATTERNLAB_CLASS: 'js-patternlab',

    // SEARCH RELATED
    PATTERNLAB_SEARCH_RESULTS_NONE_PATH: '/get-content/search-results-none.html',
    PATTERNLAB_SEARCH_RESULTS_ALL_PATH: '/get-content/search-results-all.html',
    PATTERNLAB_SEARCH_RESULTS_PAGE_PATH: '/get-content/search-results-page.html',
    PATTERNLAB_SEARCH_RESULTS_TOPIC_PATH: '/get-content/search-results-topic.html',
    PATTERNLAB_SEARCH_RESULTS_TYPE_PATH: '/get-content/search-results-type.html',
    PATTERNLAB_SEARCH_RESULTS_VIEWED_PATH: '/get-content/search-results-viewed.html',

    // CAPTCHA JSON RESPONSE
    CAPTCHA_FAKE_RESPONSE: 'https://jsonplaceholder.typicode.com/posts/',

    // LOGIN
    GET_LOGIN: '/get-content/login.json',
    GET_LOGIN_TEXT: '/get-content/login-text.json',
    GET_LOGIN_ORU: '/get-content/login-oru.json',
    GET_LOGIN_MFA: '/get-content/login-mfa.json',
    GET_UNAUTHENTICATED_LOGIN_MFA: '/get-content/unauthenticated-login-mfa.json',
    GET_LOGIN_MFA_FAIL: '/get-content/login-mfa-fail.json',

    // POWER YOUR WAY ESCO LIST
    PATTERNLAB_POWER_YOUR_WAY_ESCO_LIST: '/get-content/esco-list.html',

    // LEAD GEN COMPANY LIST
    PATTERNLAB_LEAD_GEN_COMPANY_LIST: '/get-content/company-list.html',

    // GET MARKET RATES
    GET_MARKET_RATES: '/get-content/get-market-rates.json',

    // GET CARDS HTML
    GET_MARKET_RATES_HTML: '/get-content/get-market-rates.html',
    GET_ORU_MARKET_RATES_HTML: '/get-content/get-oru-market-rates.html',
    GET_ORU_MARKET_RATES_MAUI_HTML: '/get-content/get-oru-market-rates-maui.html',
    // -- old --
    GET_METER_READING_HTML: '/get-content/get-meter-reading.html',
    GET_METER_PREVIOUS_READING_HTML: '/get-content/get-meter-previous-reading.html',
    GET_ESCO_COMPARISON_HTML: '/get-content/get-esco-comparison.html',
    GET_ORU_ESCO_COMPARISON_HTML: '/get-content/get-oru-esco-comparison.html',

    // STOP SERVICE
    DELETE_STOP_SERVICE_ERROR: '/get-content/stop-service-error.json',

    // GET OUTAGE STATUS
    GET_OUTAGE_STATUS: '/get-content/',

    // UNLOCK ACCOUNT GET INIT VERIFICATION
    INITIATE_FORGET_PASSWORD: '/get-content/initiate-forgot-password.json',

    // RESET PASSWORD GET SMS
    GET_SMS_VERIFICATION: '/get-content/reset-password-sms.json',

    // RESET PASSWORD GET ANSWER
    GET_ANSWER_VERIFICATION: '/get-content/reset-password-question.json',

    // UNLOCK ACCOUNT QUESTION
    GET_QUESTION_UNLOCK_ACCOUNT: '/get-content/unlock-account-question.json',

    // SET NEW PASWORD
    SET_NEW_PASSWORD: '/get-content/set-new-password.json',

    // DEFAULT SERVICE ERROR
    DEFAULT_SERVICE_ERROR: '/get-content/default-service-error.json',

    // REGISTER GET CODE SERVICE
    GET_CODE_REGISTER: '/get-content/register-get-code.json',

    // REGISTER GET ACCOUNTS
    GET_ACCOUNTS_REGISTER: '/get-content/register-get-accounts.json',

    // AUTOCOMPLETE INPUT LIST
    GET_EMAIL_DOMAIN_LIST: '/get-content/get-email-domain-list.json',
    GET_STREET_ADDRESS_LIST: '/get-content/get-street-address-list.json',
    GET_STREET_ADDRESS_NO_RESULTS: '/get-content/get-street-address-no-results.json',

    // ADDRESS LOOKUP RESPONSES
    GET_STREET_NAMES: '/get-content/get-street-names.json',
    GET_STREET_NUMBERS: '/get-content/get-street-numbers.json',
    GET_RESIDENCE_UNITS: '/get-content/get-residence-units.json',
    GET_VERIFY_ZIP_CODE: '/get-content/get-verify-zip-code.json',
    GET_ADDRESS_UNIT_LIST: '/get-content/get-address-unit-list.json',
    GET_ADDRESS_SINGLE_UNIT: '/get-content/get-address-single-unit.json',
    GET_ADDRESS_NO_UNITS: '/get-content/get-address-no-units.json',

    // ACCOUNT ELEGIBLE EAP
    GET_ACCOUNT_EAP_ELIGIBLE: '/get-content/get-account-eap-eligible.json',
    GET_ACCOUNT_EAP_NON_ELIGIBLE: '/get-content/get-account-eap-non-eligible.json',

    // ACCOUNT FOCUS
    GET_ACCOUNT_FOCUS: '/get-content/get-account-focus.json',

    //HOMEPAGE HERO SCENARIO
    GET_ACCOUNT_HOMEPAGE_SCENARIO: '/get-content/get-account-homepage-scenario.json',

    // START/TRANSFER SERVICE
    VERIFY_DATE_ERROR: '/get-content/service-verify-date-error.json',

    // ADD ACCOUNT GET MAID
    GET_ACCOUNT_MAID: '/get-content/get-account-maid.json',

    // ADD ACCOUNTS REDIRECT
    GET_ADD_ACCOUNTS_REDIRECT: '/get-content/get-add-accounts-redirect.json',

    // UNAUTHENTICATED REDIRECT
    UNAUTHENTICATED_REDIRECT: '/get-content/get-unauthenticated-redirect.json',

    // ADD ACCOUNT GET ACCOUNT NUMBER
    GET_ACCOUNT_NUMBER: '/get-content/account-forgot-account.json',
    ADD_ACCOUNT_REDIRECT: '/get-content/account-success-redirect.json',

    // BILL LOOKUP
    GET_BILL: '/get-content/get-bill.json',

    // BILL LOOKUP NON AUTHENTICATED
    GET_BILL_NO_AUTH: '/get-content/get-bill-non-authenticated.json',

    // FINANCIAL ADVISOR ACCOUNT SELECTOR
    GET_FINANCIAL_ADVISOR_ACCOUNT_SELECTOR: '/get-content/get-financial-advisor-account-selector.json',

    // FINANCIAL ADVISOR QUESTIONNAIRE RESULTS
    GET_FINANCIAL_ADVISOR_QUESTIONNAIRE_RESULTS: '/get-content/get-financial-advisor-questionnaire-results.json',

    // PAYMENT RELATED
    POST_PAYMENT_SINGLE: '/get-content/post-payment-single.json',
    POST_PAYMENT_SINGLE_GUEST_PAY: '/get-content/post-payment-single-guest-pay.json',
    POST_PAYMENT_MULTIPLE: '/get-content/post-payment-multiple.json',
    PAYMENT_VIEW_DATA: '/get-content/payment-view-bill.csv',

    // PAY MY BILL MFA ACCOUNTS
    PMB_MFA_SINGLE: '/get-content/pmb-mfa-accounts-single.json',
    PMB_MFA_MULTIPLE: '/get-content/pmb-mfa-accounts-multiple.json',
    PMB_MFA_SEVERAL: '/get-content/pmb-mfa-accounts-several.json',

    // OUTAGE RELATED
    GET_LOOKUP_ACCOUNT: '/get-content/get-lookup-account.json',
    GET_LOOKUP_ACCOUNTS: '/get-content/get-lookup-accounts.json',
    GET_OUTAGE_STATUS_SINGLE: '/get-content/get-outage-status-single.json',
    GET_OUTAGE_STATUS_MULTIPLE: '/get-content/get-outage-status-multiple.json',
    GET_NOTIFICATION_MESSAGE: '/get-content/get-notification-message.json',

    // GET BILL LINK
    GET_BILL_LINK: '/get-content/get-bill-link.json',

    // GET GENERAL LINK
    GET_LINK: '/get-content/get-link.json',

    // GET BILL PAYMENT TABLE
    GET_BILLING_PAYMENT_MASTER_LEVEL_TABLE_HTML: '/get-content/get-billing-payment-master-level-table.html',
    GET_BILLING_PAYMENT_TABLE_HTML: '/get-content/get-billing-payment-table.html',
    GET_BILLING_PAYMENT_TABLE_ORU_HTML: '/get-content/get-billing-payment-table-oru.html',

    //GET LIFE SUPPORT EQUIPMENT URL
    GET_LSE_SCENARIO_HTML_URL: '/get-content/get-lse-scenario-',

    //GET MEDICAL HARDSHIP FORM URL
    GET_MHS_SCENARIO_HTML_URL: '/get-content/get-mhs-scenario-',

    //GET CONCERN FORM URL
    GET_CONCERN_SCENARIO_HTML_URL: '/get-content/get-concern-scenario-',

    // GET LEVEL PAYMENT PLAN URL
    GET_LPP_SCENARIO_HTML_URL: '/get-content/get-level-payment-plan-scenario-',

    // GET BUDGET BILLING PLAN URL
    GET_BUDGET_BILLING_SCENARIO_HTML_URL: '/get-content/get-budget-billing-scenario-',

    // GET PAYMENT AGREEMENT HTML URL
    GET_PAYMENT_AGREEMENT_SCENARIO_HTML_URL: '/get-content/get-payment-agreement-scenario-',

    //GET PAYMENT ASSISTANCE CONTENT
    GET_PAYMENT_ASSISTANCE_ENROLLED_SCENARIO_HTML: '/get-content/get-payment-assistance-form-enrolled.html',

    // GET CORRESPONDENCE TABLE
    GET_CORRESPONDENCE_TABLE_HTML: '/get-content/get-correspondence-table.html',

    // GET CORRESPONDENCE TABLE BILLING
    GET_CORRESPONDENCE_TABLE_BILLING_HTML: '/get-content/get-correspondence-table-billing.html',

    // GET USAGE HISTORY TABLE
    GET_USAGE_HISTORY_TABLE_ORU_HTML: '/get-content/get-oru-usage-history.html',
    // GET USAGE HISTORY TABLE ENERGY USAGE
    GET_USAGE_HISTORY_TABLE_ORU_ENERGY_USAGE_HTML: '/get-content/get-oru-usage-history-energy-usage.html',

    // GET LIVE CHAT AVAILABILITY
    GET_AVAILABLE_LIVE_CHAT: '/get-content/get-available-live-chat.json',

    // POST DATA CONNECTIONS FORM
    POST_DATA_CONNECTIONS_FORM: '/get-content/post-data-connections-form.json',

    // UNIT RELATED
    GET_UNENROLL_UNITS_LIST: '/get-content/get-unenroll-units.html',
    GET_UNENROLL_NO_UNITS: '/get-content/get-unenroll-no-units.html',
    POST_ENROLL_UNIT_LIST: '/get-content/post-enroll-list.json',

    // GET DASHBOARD ORU MANAGE MY ACCOUNT CONTENT
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_0_HTML: '/get-content/get-oru-manage-my-account-pagr-sc-0.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_1_HTML: '/get-content/get-oru-manage-my-account-pagr-sc-1.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_2_HTML: '/get-content/get-oru-manage-my-account-pagr-sc-2.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_3_HTML: '/get-content/get-oru-manage-my-account-pagr-sc-3.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_4_HTML: '/get-content/get-oru-manage-my-account-pagr-sc-4.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_5_HTML: '/get-content/get-oru-manage-my-account-pagr-sc-5.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_6_HTML: '/get-content/get-oru-manage-my-account-pagr-sc-6.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_9_HTML: '/get-content/get-oru-manage-my-account-pagr-sc-9.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_10_HTML:
        '/get-content/get-oru-manage-my-account-pagr-sc-10.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_11_HTML:
        '/get-content/get-oru-manage-my-account-pagr-sc-11.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_12_HTML:
        '/get-content/get-oru-manage-my-account-pagr-sc-12.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_13_HTML:
        '/get-content/get-oru-manage-my-account-pagr-sc-13.html',
    GET_MANAGE_MY_ACCOUNT_ORU_PAGR_14_HTML:
        '/get-content/get-oru-manage-my-account-pagr-sc-14.html',

    GET_MANAGE_MY_ACCOUNT_ORU_COMMERCIAL_PAGR_1_HTML:
        '/get-content/get-oru-commercial-manage-my-account-pagr-sc-1.html',

    // GET DASHBOARD MANAGE MY ACCOUNT CONTENT
    GET_MANAGE_MY_ACCOUNT_HTML: '/get-content/get-manage-my-account.html',

    // GET DASHBOARD THIRD PARTY
    GET_REQUEST_ACCESS_HTML: '/get-content/get-request-access.html',
    GET_REQUEST_ACCESS_SUCCESS: '/get-content/get-request-access-success.json',
    GET_REQUEST_ACCESS_ERRORS: '/get-content/get-request-access-errors.json',
    GET_CUSTOMER_ACCOUNTS_HTML: '/get-content/get-customer-accounts.html',

    // MANAGE ACCESS
    GET_MANAGE_ACCESS: '/get-content/get-manage-access.html',

    // REPORT OUTAGE VALIDATED ACCOUNT
    GET_ACCOUNT_REPORT_OUTAGE: '/get-content/report-valid-account.json',

    // REPORT OUTAGE LANDLINE/MOBILE
    GET_CHECK_CONTACT_NUMBER: '/get-content/get-check-contact-number.json',

    // REPORT OUTAGE CONFIRMATION SUCESSS
    GET_REPORT_OUTAGE_CONFIRMATION_SUCCESS: '/get-content/get-report-outage-confirmation-success.json',

    // LAST USED BANK ACCOUNT NUMBER
    GET_LAST_USED_BANK_ACCOUNT_NUMBER: '/get-content/last-bank-account-number-bill.json',

    // HEADER DYNAMIC LINKS
    GET_HEADER_DYNAMIC_LINKS: '/get-content/get-header-dynamic-links.json',

    // ENROLLED UNITS DOWNLOAD
    GET_ENROLLED_UNITS_DOWNLOAD: '/get-content/get-enrolled-units-download.json',
    GET_ENROLLED_UNITS_DOWNLOAD_ALREADY: '/get-content/get-enrolled-units-download-already.json',

    // BILLING
    GET_ENERGY_INSIGHTS_DATA_BILLING: '/get-content/get-energy-insights-data-billing.html',
    GET_ENERGY_INSIGHTS_DATA_BILLING_COMMERCIAL: '/get-content/get-energy-insights-data-billing-commercial.html',
    GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_SPP: '/get-content/get-energy-insights-data-billing-ipp-spp.html',
    GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_2: '/get-content/get-energy-insights-data-billing-ipp-rate-2.html',
    GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_4: '/get-content/get-energy-insights-data-billing-ipp-rate-4.html',
    GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_5: '/get-content/get-energy-insights-data-billing-ipp-rate-5.html',
    GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_6: '/get-content/get-energy-insights-data-billing-ipp-rate-6.html',
    GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_7: '/get-content/get-energy-insights-data-billing-ipp-rate-7.html',
    GET_ENERGY_INSIGHTS_DATA_BILLING_SHR: '/get-content/get-energy-insights-data-billing-shr.html',
    GET_ORU_ENERGY_INSIGHTS_DATA_BILLING: '/get-content/get-oru-energy-insights-data-billing.html',
    GET_ORU_ENERGY_INSIGHTS_DATA_BILLING_COMMERCIAL: '/get-content/get-oru-energy-insights-data-billing-commercial.html',
    // LEGACY - STILL USING LEGACY DASHBOARD WITH TABS, NEEDS TO BE UPDATED TO INDIVIDUAL ENERGY USE, BILLING, MANAGE MY ACCOUNT PAGES AND OPOWER V2 WIDGETS TO WORK
    GET_ENERGY_INSIGHTS_DATA: '/get-content/get-energy-insights-data.html',

    // ENERGY USAGE
    GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RESIDENTIAL: '/get-content/get-energy-insights-data-energy-usage-residential.html',
    GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_IPP_SHR_SPP_RESIDENTIAL: '/get-content/get-energy-insights-data-energy-usage-ipp-shr-spp-residential.html',
    GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_2_RESIDENTIAL: '/get-content/get-energy-insights-data-energy-usage-rate-2-residential.html',
    GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_4_RESIDENTIAL: '/get-content/get-energy-insights-data-energy-usage-rate-4-residential.html',
    GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_5_RESIDENTIAL: '/get-content/get-energy-insights-data-energy-usage-rate-5-residential.html',
    GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_6_RESIDENTIAL: '/get-content/get-energy-insights-data-energy-usage-rate-6-residential.html',
    GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_PILOTS_COMMERCIAL: '/get-content/get-energy-insights-data-energy-usage-rate-pilots-commercial.html',
    GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_NYPA: '/get-content/get-energy-insights-data-energy-usage-nypa.html',
    GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_COMMERCIAL: '/get-content/get-energy-insights-data-energy-usage-commercial.html',
    GET_ORU_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RESIDENTIAL: '/get-content/get-oru-energy-insights-data-energy-usage-residential.html',
    GET_ORU_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_IPP_SHR_SPP_RESIDENTIAL: '/get-content/get-oru-energy-insights-data-energy-usage-ipp-shr-spp-residential.html',
    GET_ORU_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_COMMERCIAL: '/get-content/get-oru-energy-insights-data-energy-usage-commercial.html',
    GET_ORU_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RTP: '/get-content/get-oru-energy-insights-data-energy-usage-rtp.html',
    // CORE - STILL USING LEGACY DASHBOARD WITH TABS, NEEDS TO BE UPDATED TO INDIVIDUAL ENERGY USE, BILLING, MANAGE MY ACCOUNT PAGES AND OPOWER V2 WIDGETS TO WORK
    GET_ENERGY_INSIGHTS_DATA_USAGE: '/get-content/get-energy-insights-data-usage.html',
    // LEGACY - STILL USING LEGACY DASHBOARD WITH TABS, NEEDS TO BE UPDATED TO INDIVIDUAL ENERGY USE, BILLING, MANAGE MY ACCOUNT PAGES AND OPOWER V2 WIDGETS TO WORK
    GET_ORU_ENERGY_INSIGHTS_DATA: '/get-content/get-oru-energy-insights-data.html',

    // GET ELIGIBLE PROGRAMS
    GET_FINANCIAL_ADVISOR_ELIGIBLE_PROGRAMS_HTML_URL: '/get-content/get-financial-advisor-eligible-programs.html',
    //GET ELIGIBLE PROGRAMS WITH BNA STATE
    GET_FINANCIAL_ADVISOR_ELIGIBLE_PROGRAMS_BNA_HTML_URL: '/get-content/get-financial-advisor-eligible-programs-bna.html',
    // GET FURTHER PROGRAMS
    GET_FINANCIAL_ADVISOR_FURTHER_PROGRAMS_HTML_URL: '/get-content/get-financial-advisor-further-programs.html',

    // CHART DATA
    GET_CHART_LEGEND_DATA: '/get-content/charts/legendDataAttributes.json',
    GET_CHART_BILLING_PERIODS: '/get-content/charts/billingPeriods.json',
    GET_CHART_BILLING_PERIODS_RATE2: '/get-content/charts/B/billingPeriods.json',
    GET_CHART_BILLING_PERIODS_RATE5: '/get-content/charts/E/billingPeriods.json',
    GET_CHART_BILLING_PERIODS_RATE6: '/get-content/charts/F/billingPeriods.json',
    GET_CHART_BILLING_PERIODS_THERMOSTAT_A: '/get-content/charts/T-A/billingPeriods.json',
    GET_CHART_BILLING_PERIODS_THERMOSTAT_B: '/get-content/charts/T-B/billingPeriods.json',
    GET_CHART_BILLING_PERIODS_SOLAR: '/get-content/charts/Solar/billingPeriods.json',
    GET_CHART_BILLING_PERIODS_ODBR: '/get-content/charts/SPP/billingPeriods.json',
    GET_CHART_MONTHLY_DEMAND: '/get-content/charts/montlyDemand.json',

    //BILL COMPARISON
    GET_BILL_COMPARISON: '/get-content/billComparison.json',

    //SESSION TIMEOUT
    GET_SESSION_TIMEOUT: '/get-content/sessionTimeout.json',

    //DAY AHEAD PEAK DEMAND
    DAY_AHEAD_PEAK_DEMAND: '/get-content/dayAheadPeak.json',

    //MARKET SUPPLY CALCULATOR
    GET_MARKET_SUPPLY_CALCULATOR_SERVICES_ERROR: '/get-content/get-msc-result-error.json',
    GET_MARKET_SUPPLY_CALCULATOR_CALCULATE: '/get-content/get-msc-result-success.json',

    //NYISO DAY AHEAD CALCULATOR
    GET_NYISO_CALCULATOR_SERVICES_ERROR: '/get-content/get-nyiso-result-error.json',
    GET_NYISO_CALCULATOR_CALCULATE: '/get-content/get-nyiso-result-success.json',

    //SUB METERING CALCULATOR
    GET_SM_CALCULATOR_SERVICES_ERROR: '/get-content/get-sm-result-error.json',
    GET_SM_CALCULATOR_CALCULATE: '/get-content/get-sm-result-success.json',

    //REAL TIME PRICING CALCULATOR
    REAL_TIME_PRICES_DATA: '/get-content/real-time-pricing-data.csv',

    // COMPARISON TABLE
    COMPARISON_TABLE_DATA: '/get-content/get-comparison-table-data.json',
    COMPARISON_TABLE_DATA_LONG: '/get-content/get-comparison-table-data-long.json'

};
