// ==================== ACCOUNT DETAILS COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AccountDetails = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        EDIT_BUTTON_SELECTOR: 'js-edit-button',
        CLOSE_BUTTON: 'js-close-button',
        TEXT_INFORMATION: 'js-text-info',
        INPUT_INFORMATION: 'js-input-info',
        OPEN_CONTAINER_CLASS: 'account-details__container--open',
        INPUT_SELECTOR: 'js-coned-input',
        SERVICE_ERROR: 'js-service-error',
        HIDDEN_CLASS: 'hidden',
        FILLED_CLASS: 'coned-input--filled',
        FIELD_DISABLED_CLASS: 'coned-field--disabled',
        HEADER: 'js-header-wrapper',
        MAILING_OPTION_CONTAINER: 'js-mailing-address-container',
        ARIA_EXPANDED: 'aria-expanded'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AccountDetails = function ($accountForm) {
        /**
         * PRIVATE METHODS
         */
        var $editButtonSelector,
            $textInfo,
            $inputInfo,
            $closeButton,
            $inputSelector,
            $serviceError,
            $header,
            $mailingOptionContainer

        var openEdit = function (event) {
            event.preventDefault();
            var focusableEls = $accountForm.querySelectorAll(
                    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), input[type="email"]:not([disabled]), input[type="tel"]:not([disabled])'
                ),
                firstFocusableEl = focusableEls[0];
            if ($mailingOptionContainer) {
                $mailingOptionContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
            query.addClass($textInfo, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($inputInfo, CONSTANTS.HIDDEN_CLASS);
            $editButtonSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $editButtonSelector.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            $accountForm.classList.add(CONSTANTS.OPEN_CONTAINER_CLASS);

            firstFocusableEl.focus();
        };

        var closeEdit = function (event) {
            event.preventDefault();

            hideError();
            query.removeClass($textInfo, CONSTANTS.HIDDEN_CLASS);
            query.addClass($inputInfo, CONSTANTS.HIDDEN_CLASS);
            $editButtonSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $editButtonSelector.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
            $accountForm.classList.remove(CONSTANTS.OPEN_CONTAINER_CLASS);
            query.scrollToElement($accountForm, $header);

            if ($mailingOptionContainer) {
                $mailingOptionContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
            $editButtonSelector.focus();
        };

        var addRequired = function (event) {
            var $input = event.target;
            $input.setAttribute('required', '');
        };

        var hideError = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var initializeData = function () {
            $editButtonSelector = $accountForm.getElementsByClassName(
                CONSTANTS.EDIT_BUTTON_SELECTOR
            )[0];
            $textInfo = $accountForm.getElementsByClassName(CONSTANTS.TEXT_INFORMATION);
            $inputInfo = $accountForm.getElementsByClassName(CONSTANTS.INPUT_INFORMATION);
            $closeButton = $accountForm.getElementsByClassName(CONSTANTS.CLOSE_BUTTON)[0];
            $inputSelector = $accountForm.getElementsByClassName(CONSTANTS.INPUT_SELECTOR);
            $serviceError = $accountForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $mailingOptionContainer = $accountForm.getElementsByClassName(
                CONSTANTS.MAILING_OPTION_CONTAINER
            )[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];            
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($closeButton, closeEdit);
            coned.utils.addGeneralListeners($editButtonSelector, openEdit);

            _.each($inputSelector, function ($input) {

                if(!$input.dataset.optional) {
                    $input.addEventListener('change', addRequired);
                } 
                $input.classList.add(CONSTANTS.FILLED_CLASS);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AccountDetails.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AccountDetails;
})();
