// ==================== UNLOCK ACCOUNT COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.UnlockAccount = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_CLASS_SELECTOR: '.js-unlock-form',
        SELECT_WIDTH_DROPDOWN: 'js-coned-select',
        EMAIL_SELECTOR_CONTAINER: 'js-email-selector',
        PHONE_SELECTOR_CONTAINER: 'js-phone-selector',
        SMS_MESSAGE_CONTIANER: 'js-message-selector',
        DISPLAY_CLASS: 'transactional--finish',
        SUBMIT_BUTTON_SELECTOR: 'js-transactional-submit-selector',
        INPUT_CLASS_SELECTOR: 'js-coned-input',
        FORM_IGNORE_VALIDATION: 'js-validation-ignore',
        FINISH_STEP_SELECTOR: 'js-finish-step-selector',
        FINISH_STEP_CLASS: 'transactional--finish',
        FINISH_MESSAGE_SELECTOR: 'js-sucess-receptor',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        FINISH_MESSAGE_CONTAINER: 'js-data-message',
        FIRST_STEP_FORM: 'js-first-step-form',
        CODE_VERIFICATION_FORM: 'js-code-verification-code',
        UNLOCK_PASSWORD_FORM: 'js-unlock-password',
        UNLOCK_PASSWORD_SELECTOR: 'js-unlock-password-selector',
        REVERT_UNLOCK_SELECTOR: 'js-revert-unlock-selector',
        SECURITY_QUESTION_CONTAINER: 'js-security-container',
        SECURITY_QUESTION: 'js-security-question',
        SECURITY_ANSWER: 'js-security-answer',
        UNLOCK_ERROR: 'js-unlock-error',
        UNLOCK_ERROR_MESSAGE: 'js-unlock-error-message',
        UNLOCK_DESCRIPTION: 'js-unlock-description',
        HIDDEN_CLASS: 'hidden',
        SMS_OPTION: 'sms',
        EMAIL_OPTION: 'email',
        UNLOCK_QUESTION_INPUT: 'unlockSecurityQuestion',
        UNLOCK_EMAIL_INPUT: 'unlockEmail',
        UNLOCK_VERIFICATION_CODE_INPUT: 'verificationCode',
        UNLOCK_VIA: 'js-unlock-via',
        UNLOCK_ALTERNATIVE: 'js-unlock-alternative',
        UNLOCK_SITECORE_ID: 'ScId'
    };

    var isLoaded = false;
    var smsActive = false;
    var securityQuestionActive = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var UnlockAccount = function ($unlockForm) {
        /**
         * PRIVATE METHODS
         */
        var $selectWidthDropdown,
            $emailSelectorContainer,
            $smsMessageContainer,
            $submitButtonSelector,
            $emailInputSelector,
            $finishStepSelector,
            $finishMessageSelector,
            $submitAnimationButton,
            $finishMessageContainer,
            $firstStepForm,
            $codeVerificationForm,
            $securityQuestionContainer,
            $securityQuestion,
            $securityAnswer,
            $unlockError,
            $revertUnlockSelector,
            $unlockDescription,
            $unlockErrorMessage,
            $unlockVia,
            $unlockAlternative,
            _data,
            _successServiceCallback;

        var revertUnlock = function (event) {
            event.preventDefault();

            var $verificationInput = $codeVerificationForm.getElementsByClassName(
                CONSTANTS.INPUT_CLASS_SELECTOR
            )[0];

            smsActive = false;
            $firstStepForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $codeVerificationForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $verificationInput.classList.add(CONSTANTS.FORM_IGNORE_VALIDATION);
        };

        var enableInput = function () {
            if ($selectWidthDropdown.value == CONSTANTS.SMS_OPTION) {
                query.removeClass($smsMessageContainer, CONSTANTS.HIDDEN_CLASS);
                $unlockAlternative.innerHTML = CONSTANTS.EMAIL_OPTION;

                _.each($unlockVia, function (viaItem) {
                    viaItem.innerHTML = CONSTANTS.SMS_OPTION;
                });
            } else {
                query.addClass($smsMessageContainer, CONSTANTS.HIDDEN_CLASS);
                $unlockAlternative.innerHTML = CONSTANTS.SMS_OPTION;

                _.each($unlockVia, function (viaItem) {
                    viaItem.innerHTML = CONSTANTS.EMAIL_OPTION;
                });
            }
            $submitButtonSelector.disabled = false;
        };

        var unlockAccountCall = function () {
            var params,
                initiateUnlockService = $unlockForm.dataset.initiateService,
                smsVerificationService = $unlockForm.dataset.smsService,
                questionService = $unlockForm.dataset.questionService,
                unlockSelectedFactor = $selectWidthDropdown.value;

            $unlockDescription.classList.add(CONSTANTS.HIDDEN_CLASS);

            // rest with sms
            if (smsActive) {
                if (securityQuestionActive) {
                    params = {
                        questionAnswer: query.getFormInputValue(
                            $unlockForm,
                            CONSTANTS.UNLOCK_QUESTION_INPUT
                        ),
                        ScId: query.getFormInputValue($unlockForm, CONSTANTS.UNLOCK_SITECORE_ID)
                    };

                    params = JSON.stringify(params);
                    query.postData(
                        questionService,
                        successSubmitEventCall,
                        errorSubmitEventCall,
                        params,
                        true
                    );
                } else {
                    params = {
                        PassCode: query.getFormInputValue(
                            $unlockForm,
                            CONSTANTS.UNLOCK_VERIFICATION_CODE_INPUT
                        ),
                        ScId: query.getFormInputValue($unlockForm, CONSTANTS.UNLOCK_SITECORE_ID)
                    };

                    params = JSON.stringify(params);
                    query.postData(
                        smsVerificationService,
                        successSubmitEventCall,
                        errorSubmitEventCall,
                        params,
                        true
                    );
                }
            } else {
                params = {
                    email: query.getFormInputValue($unlockForm, CONSTANTS.UNLOCK_EMAIL_INPUT),
                    unlockWithFactor: unlockSelectedFactor,
                    ScId: query.getFormInputValue($unlockForm, CONSTANTS.UNLOCK_SITECORE_ID)
                };

                params = JSON.stringify(params);
                query.postData(
                    initiateUnlockService,
                    successSubmitEventCall,
                    errorSubmitEventCall,
                    params,
                    true
                );
            }
        };

        var successSubmitEventCall = function (data) {
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var errorSubmitEventCall = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var unlockValidate = function () {
            hideError();

            if (coned.utils.isPatternLab()) {
                var unlockValidateService = coned.plConstants.INITIATE_FORGET_PASSWORD;

                query.getData(unlockValidateService, initiateUnlockAccount, function () {});
            } else {
                initiateUnlockAccount(_data);
            }
        };

        var initiateUnlockAccount = function (data) {
            if (data && data.success) {
                var $verificationInput = $codeVerificationForm.getElementsByClassName(
                    CONSTANTS.INPUT_CLASS_SELECTOR
                )[0];
                smsActive = true;
                $firstStepForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $codeVerificationForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $verificationInput.classList.remove(CONSTANTS.FORM_IGNORE_VALIDATION);
            } else {
                $unlockError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $unlockErrorMessage.innerHTML = data.errorMsg;
                $unlockError.focus();
            }
        };

        var validateSecurityQuestion = function () {
            hideError();

            if (coned.utils.isPatternLab()) {
                var getAnswerService = coned.plConstants.GET_ANSWER_VERIFICATION;
                query.getData(getAnswerService, securityQuestion, function () {});
            } else {
                securityQuestion(_data);
            }
        };

        var securityQuestion = function (data) {
            if (data && data.success) {
                $securityQuestionContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $securityAnswer.classList.add(CONSTANTS.FORM_IGNORE_VALIDATION);
                $unlockError.classList.add(CONSTANTS.HIDDEN_CLASS);
                showFinishStep();
            } else {
                $securityQuestionContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $securityAnswer.classList.remove(CONSTANTS.FORM_IGNORE_VALIDATION);
                $unlockError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $unlockErrorMessage.innerHTML = data.errorMsg;
                $unlockError.focus();
            }
        };

        var validateVerificationCode = function () {
            hideError();
            if (coned.utils.isPatternLab()) {
                var getVerificationSerice = coned.plConstants.GET_QUESTION_UNLOCK_ACCOUNT;

                query.getData(getVerificationSerice, verificationCode, function () {});
            } else {
                verificationCode(_data);
            }
        };

        var verificationCode = function (data) {
            if (data && data.success) {
                $codeVerificationForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $unlockError.classList.add(CONSTANTS.HIDDEN_CLASS);
                $securityQuestionContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $securityAnswer.classList.remove(CONSTANTS.FORM_IGNORE_VALIDATION);
                $securityQuestion.innerHTML = data.recoveryQuestion;
                securityQuestionActive = true;
            } else {
                $codeVerificationForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $unlockError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $unlockErrorMessage.innerHTML = data.errorMsg;
                $unlockError.focus();
            }
        };

        var errorServiceCallback = function () {
            $unlockError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $unlockErrorMessage.innerHTML = _data.errorMsg;
            $unlockError.focus();
        };

        var hideError = function () {
            $unlockError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $unlockErrorMessage.innerHTML = '';
        };

        var showFinishStep = function () {
            var selectMessage;

            $unlockForm.style.display = 'none';

            if ($selectWidthDropdown.value == CONSTANTS.SMS_OPTION) {
                var typeSMSMessage = $finishMessageContainer.dataset.sms;

                selectMessage = typeSMSMessage;
                $finishMessageSelector.innerHTML = selectMessage;
            } else {
                var typeEmailMessage = $finishMessageContainer.dataset.email;
                selectMessage = typeEmailMessage;
                selectMessage = selectMessage.replace(
                    '@DATA',
                    '<b>' + $emailInputSelector.value + '</b>'
                );
            }

            $finishStepSelector.classList.remove(CONSTANTS.FINISH_STEP_CLASS);
            $finishMessageSelector.innerHTML = selectMessage;
        };

        var unlockAccountSubmit = function () {
            if (smsActive) {
                if (securityQuestionActive) {
                    _successServiceCallback = validateSecurityQuestion;
                } else {
                    _successServiceCallback = validateVerificationCode;
                }
            } else {
                _successServiceCallback = unlockValidate;
            }

            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                unlockAccountCall,
                _successServiceCallback,
                errorServiceCallback,
                false,
                true
            );
        };

        var initializeData = function () {
            $selectWidthDropdown = $unlockForm.getElementsByClassName(
                CONSTANTS.SELECT_WIDTH_DROPDOWN
            )[0];
            $emailSelectorContainer = $unlockForm.getElementsByClassName(
                CONSTANTS.EMAIL_SELECTOR_CONTAINER
            )[0];
            $smsMessageContainer = $unlockForm.getElementsByClassName(
                CONSTANTS.SMS_MESSAGE_CONTIANER
            )[0];
            $submitButtonSelector = $unlockForm.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON_SELECTOR
            )[0];
            $emailInputSelector = $emailSelectorContainer.getElementsByClassName(
                CONSTANTS.INPUT_CLASS_SELECTOR
            )[0];
            $finishStepSelector = document.getElementsByClassName(
                CONSTANTS.FINISH_STEP_SELECTOR
            )[0];
            $finishMessageSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.FINISH_MESSAGE_SELECTOR
            )[0];
            $submitAnimationButton = $unlockForm.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            $finishMessageContainer = $unlockForm.getElementsByClassName(
                CONSTANTS.FINISH_MESSAGE_CONTAINER
            )[0];
            $firstStepForm = $unlockForm.getElementsByClassName(CONSTANTS.FIRST_STEP_FORM)[0];
            $codeVerificationForm = $unlockForm.getElementsByClassName(
                CONSTANTS.CODE_VERIFICATION_FORM
            )[0];
            $revertUnlockSelector = $unlockForm.getElementsByClassName(
                CONSTANTS.REVERT_UNLOCK_SELECTOR
            )[0];
            $securityQuestionContainer = $unlockForm.getElementsByClassName(
                CONSTANTS.SECURITY_QUESTION_CONTAINER
            )[0];
            $securityAnswer = $unlockForm.getElementsByClassName(CONSTANTS.SECURITY_ANSWER)[0];
            $securityQuestion = $unlockForm.getElementsByClassName(CONSTANTS.SECURITY_QUESTION)[0];
            $unlockError = $unlockForm.getElementsByClassName(CONSTANTS.UNLOCK_ERROR)[0];
            $unlockErrorMessage = $unlockForm.getElementsByClassName(
                CONSTANTS.UNLOCK_ERROR_MESSAGE
            )[0];
            $unlockDescription = $unlockForm.getElementsByClassName(
                CONSTANTS.UNLOCK_DESCRIPTION
            )[0];
            $unlockVia = $unlockForm.getElementsByClassName(CONSTANTS.UNLOCK_VIA);
            $unlockAlternative = $unlockForm.getElementsByClassName(
                CONSTANTS.UNLOCK_ALTERNATIVE
            )[0];
        };

        var initializeEvents = function () {
            $selectWidthDropdown.addEventListener('change', enableInput);

            coned.utils.addGeneralListeners($revertUnlockSelector, revertUnlock);

            // form validation
            new coned.components.ValidateForm(CONSTANTS.FORM_CLASS_SELECTOR, unlockAccountSubmit);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    UnlockAccount.prototype.isLoaded = function () {
        return isLoaded;
    };

    return UnlockAccount;
})();
