// ==================== SMART ENERGY COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SmartEnergyOptOut = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        OPT_OUT_START_FORM: 'js-opt-out-start',
        OPT_OUT_REASON_FORM: 'js-opt-out-reason',
        OPT_OUT_FINALIZE_FORM: 'js-opt-out-finalize',
        OPT_OUT_SUCCESS_FORM: 'js-opt-out-success',
        OPT_OUT_ERROR_FORM: 'js-opt-out-error',
        OPT_OUT_NEXT_BUTTON: 'js-opt-out-next',
        OPT_OUT_COMMENT: 'js-opt-out-reason-comment',
        OPT_OUT_LOADING_ERROR: 'js-form-loading',
        OPT_OUT_COMMENT_FIELD: 'js-opt-out-comment',
        OPT_OUT_RADIO_CHECKED: 'input[type=radio]:checked',
        OPT_OUT_REQUESTED_ERROR_FORM: 'js-opt-out-requested-error',
        MONEY_RADIO_OPTION: 'js-money-option',
        UNKNOWN_RADIO_OPTION: 'js-unknown-option',
        PEAK_RADIO_OPTION: 'js-peak-option',
        OTHER_RADIO_OPTION: 'js-other-option',
        CLOSE_SELECTOR: 'js-accordion-close',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        ACCORDION_CONTAIN: 'js-accordion-contain',
        DATA_IS_EMAIL_REQUEST: 'data-is-email-request',
        ICON_STATUS_TEXT: 'icon-status-text',
        ENROLLED: 'Enrolled',
        NOT_ENROLLED: 'Not enrolled',
        DATA_MAID: 'data-maid',
        ENERGY_USAGE: 'energy-usage',
        EMAIL_REQUEST: 'EmailRequest',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        REASON_FORM_0PTION: 'reason',
        MONEY_OPTION: 'money',
        HOURS_OPTION: 'peak',
        UNDERSTAND_OPTION: 'know',
        OTHER_OPTION: 'Other',
        TAGGING_MONEY: 'Save Money',
        TAGGING_HOURS: 'Hours',
        TAGGING_UNDERSTAND: 'Understand',
        OTHER_FORM_OPTION: 'Other',
        OTHER_REQUIRED_TEXT: 'Other*',
        OTHER_OPTION_LABEL_TEXT: 'js-other-option-label',
        COMMENT_TEXT_AREA: 'otherComment',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;
    var optedOutSuccesful = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var SmartEnergyOptOut = function ($smartEnergy) {
        /**
         * PRIVATE METHODS
         */
        var $startOptOut,
            $reasonOptOut,
            $commentOptOut,
            $otherOption,
            $moneyOption,
            $unknownOption,
            $peakOption,
            $closeSelector,
            $finalizeOptOut,
            $successOptOut,
            $errorOptOut,
            $nextButton,
            $accordionSelector,
            $formLoading,
            $commentField,
            $alreadyRequestedErrorOptOut;

        var startOptOutForm = function () {
            $startOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
            $reasonOptOut.classList.remove(CONSTANTS.HIDDEN_CLASS);

            dataLayer.push({
                event: 'ipp.authoptout.start.1'
            });
        };

        var restartOptOutForm = function () {
            if (optedOutSuccesful) {
                return;
            }

            hideCommentText();

            // TODO reset ALL States and classnames
            var $reasonChecked = $reasonOptOut.querySelector(CONSTANTS.OPT_OUT_RADIO_CHECKED);

            // returns to first item
            $startOptOut.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // If all the way to result this hides errors of results
            $successOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
            $errorOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
            $alreadyRequestedErrorOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Removes the resons radio panel and removes previous selection
            $reasonOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
            if ($reasonChecked) {
                $reasonChecked.checked = false;
            }

            // Removes teh finalize panel
            $finalizeOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var showCommentText = function () {
            $nextButton.disabled = true;
            $commentOptOut.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $smartEnergy.getElementsByClassName(CONSTANTS.OTHER_OPTION_LABEL_TEXT)[0].innerText =
                CONSTANTS.OTHER_REQUIRED_TEXT;
        };

        var hideCommentText = function () {
            $commentField.value = '';
            $nextButton.disabled = false;
            $commentOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
            $smartEnergy.getElementsByClassName(CONSTANTS.OTHER_OPTION_LABEL_TEXT)[0].innerText =
                CONSTANTS.OTHER_FORM_OPTION;
        };

        var optOutDoneForm = function () {
            var reason,
                reasonValue = query.getFormInputValue($smartEnergy, CONSTANTS.REASON_FORM_0PTION);

            if (reasonValue.includes(CONSTANTS.MONEY_OPTION)) {
                reason = CONSTANTS.TAGGING_MONEY;
            } else if (reasonValue.includes(CONSTANTS.HOURS_OPTION)) {
                reason = CONSTANTS.TAGGING_HOURS;
            } else if (reasonValue.includes(CONSTANTS.UNDERSTAND_OPTION)) {
                reason = CONSTANTS.TAGGING_UNDERSTAND;
            } else {
                reason = CONSTANTS.OTHER_FORM_OPTION;
            }

            $reasonOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
            $finalizeOptOut.classList.remove(CONSTANTS.HIDDEN_CLASS);

            dataLayer.push({
                event: 'ipp.authoptout.reason.1',
                optoutReason: reason + '1'
            });
        };

        var ableNextButton = function () {
            var comment = query.getFormInputValue($smartEnergy, CONSTANTS.COMMENT_TEXT_AREA);
            if (comment !== '') {
                $nextButton.disabled = false;
            } else {
                $nextButton.disabled = true;
            }
        };

        var submitEvent = function () {
            // event.preventDefault();
            var serviceUrl = $smartEnergy.dataset.serviceUrl,
                maid,
                params,
                accordionContain,
                userName,
                userEmail,
                accountNumber,
                isEmailRequest,
                reasonValue = query.getFormInputValue($smartEnergy, CONSTANTS.REASON_FORM_0PTION),
                comment = '';

            accordionContain = $smartEnergy.getElementsByClassName(CONSTANTS.ACCORDION_CONTAIN)[0];

            userName = accordionContain.dataset.userName;
            userEmail = accordionContain.dataset.userEmail;
            accountNumber = accordionContain.dataset.accountNumber;
            maid = accordionContain.dataset.maid;
            isEmailRequest = $smartEnergy.getAttribute(CONSTANTS.DATA_IS_EMAIL_REQUEST);

            if (reasonValue == CONSTANTS.OTHER_FORM_OPTION) {
                comment = query.getFormInputValue($smartEnergy, CONSTANTS.COMMENT_TEXT_AREA);
            }

            // Service Data
            params = {
                AccountMaid: maid,
                Name: userName,
                Email: userEmail,
                Account: accountNumber,
                Reason: reasonValue,
                Comment: comment
            };

            if (isEmailRequest) {
                params[CONSTANTS.EMAIL_REQUEST] = isEmailRequest;
            }

            // Service Call
            params = JSON.stringify(params);
            query.postData(serviceUrl, successReason, errorReason, params, true, $formLoading);
        };

        var successReason = function () {
            $finalizeOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
            $successOptOut.classList.remove(CONSTANTS.HIDDEN_CLASS);
            optedOutSuccesful = true;

            setStatement();

            dataLayer.push({
                event: 'ipp.authoptout.submit.1',
                confMessage: 'Success1'
            });
        };

        var errorReason = function (data) {
            if (data.errorMsg === 'AlreadyRequested') {
                $finalizeOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
                $alreadyRequestedErrorOptOut.classList.remove(CONSTANTS.HIDDEN_CLASS);
                optedOutSuccesful = true;

                setStatement();
            } else {
                $finalizeOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
                $errorOptOut.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            dataLayer.push({
                event: 'ipp.authoptout.submit.1',
                confMessage: 'Failure1'
            });
        };

        var setStatement = function () {
            var statementFlag = $accordionSelector.dataset.statement;

            if (statementFlag == 'false') {
                $smartEnergy.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                $smartEnergy.getElementsByClassName(CONSTANTS.ICON_STATUS_TEXT)[0].innerText =
                    CONSTANTS.ENROLLED;
            } else {
                $smartEnergy.classList.add(CONSTANTS.UNENROLLMENT_CLASS);
                $smartEnergy.getElementsByClassName(CONSTANTS.ICON_STATUS_TEXT)[0].innerText =
                    CONSTANTS.NOT_ENROLLED;
            }
        };

        var initializeData = function () {
            $startOptOut = $smartEnergy.getElementsByClassName(CONSTANTS.OPT_OUT_START_FORM)[0];
            $reasonOptOut = $smartEnergy.getElementsByClassName(CONSTANTS.OPT_OUT_REASON_FORM)[0];
            $commentOptOut = $smartEnergy.getElementsByClassName(CONSTANTS.OPT_OUT_COMMENT)[0];
            $finalizeOptOut = $smartEnergy.getElementsByClassName(
                CONSTANTS.OPT_OUT_FINALIZE_FORM
            )[0];
            $successOptOut = $smartEnergy.getElementsByClassName(CONSTANTS.OPT_OUT_SUCCESS_FORM)[0];
            $errorOptOut = $smartEnergy.getElementsByClassName(CONSTANTS.OPT_OUT_ERROR_FORM)[0];
            $alreadyRequestedErrorOptOut = $smartEnergy.getElementsByClassName(
                CONSTANTS.OPT_OUT_REQUESTED_ERROR_FORM
            )[0];
            $nextButton = $smartEnergy.getElementsByClassName(CONSTANTS.OPT_OUT_NEXT_BUTTON)[0];
            $moneyOption = $smartEnergy.getElementsByClassName(CONSTANTS.MONEY_RADIO_OPTION)[0];
            $unknownOption = $smartEnergy.getElementsByClassName(CONSTANTS.UNKNOWN_RADIO_OPTION)[0];
            $peakOption = $smartEnergy.getElementsByClassName(CONSTANTS.PEAK_RADIO_OPTION)[0];
            $otherOption = $smartEnergy.getElementsByClassName(CONSTANTS.OTHER_RADIO_OPTION)[0];
            $accordionSelector = $smartEnergy.getElementsByClassName(
                CONSTANTS.ACCORDION_SELECTOR
            )[0];
            $closeSelector = $smartEnergy.getElementsByClassName(CONSTANTS.CLOSE_SELECTOR);
            $commentField = $smartEnergy.getElementsByClassName(CONSTANTS.OPT_OUT_COMMENT_FIELD)[0];
            $formLoading = $smartEnergy.getElementsByClassName(CONSTANTS.OPT_OUT_LOADING_ERROR)[0];
        };

        var initializeEvents = function () {
            new coned.components.ValidateForm(
                '.' + CONSTANTS.OPT_OUT_START_FORM,
                startOptOutForm,
                ''
            );
            new coned.components.ValidateForm(
                '.' + CONSTANTS.OPT_OUT_REASON_FORM,
                optOutDoneForm,
                ''
            );
            new coned.components.ValidateForm(
                '.' + CONSTANTS.OPT_OUT_FINALIZE_FORM,
                submitEvent,
                ''
            );

            coned.utils.addGeneralListeners($moneyOption, hideCommentText);
            coned.utils.addGeneralListeners($unknownOption, hideCommentText);
            coned.utils.addGeneralListeners($peakOption, hideCommentText);
            coned.utils.addGeneralListeners($otherOption, showCommentText);
            if ($commentOptOut != undefined) {
                $commentOptOut.addEventListener('keyup', ableNextButton);
            }

            _.each($closeSelector, function ($closeSelector) {
                coned.utils.addGeneralListeners($closeSelector, function () {
                    restartOptOutForm();
                });
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SmartEnergyOptOut.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SmartEnergyOptOut;
})();
