// ==================== REGISTER SUCCESS COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.RegisterSuccess = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        REQUEST_POWER_ON_SELECTOR: 'js-request-power-on',
        ADD_ACCOUNT_SELECTOR: 'js-add-account'
    };

    var isLoaded = false;

    /**
     * Constructor
     */
    var RegisterSuccess = function ($registerSuccess) {
        /**
         * PRIVATE METHODS
         */
        var $requestPowerOnButton, $addAccountButton;

        var taggingRequestTurnPowerOn = function () {
            dataLayer.push({
                event: 'registration.process',
                registration: 'step05a'
            });
            dataLayer.push({
                event: 'registration.step05a'
            });
        };

        var taggingAddAccount = function () {
            dataLayer.push({
                event: 'registration.process',
                registration: 'step05b'
            });
            dataLayer.push({
                event: 'registration.step05b'
            });
        };

        var taggingRegistrationPageView = function () {
            dataLayer.push({
                event: 'registration.process',
                registration: 'step05'
            });
            dataLayer.push({
                event: 'registration.step05'
            });
        };

        var initializeData = function () {
            $requestPowerOnButton = $registerSuccess.getElementsByClassName(
                CONSTANTS.REQUEST_POWER_ON_SELECTOR
            )[0];
            $addAccountButton = $registerSuccess.getElementsByClassName(
                CONSTANTS.ADD_ACCOUNT_SELECTOR
            )[0];
        };

        var initializeEvents = function () {
            $requestPowerOnButton.addEventListener('click', taggingRequestTurnPowerOn);
            $addAccountButton.addEventListener('click', taggingAddAccount);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            taggingRegistrationPageView();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    RegisterSuccess.prototype.isLoaded = function () {
        return isLoaded;
    };

    return RegisterSuccess;
})();
