// ==================== GLOBAL/INIT =========================

var query = query || {},
    coned = coned || {};

/**
 * Function executed when document is ready. It calls all modules' init function.
 */
query.documentReady(function () {
    
    //Check if passive property is accessed on the current browser, code for feature detection based on W3C recommendation
    coned.supportsPassive = false;

    try {
        //Sets the support passive property
        var opts = Object.defineProperty({}, 'passive', {
            get: function() {
                coned.supportsPassive = true;
                return false;
            }
        });
        
        window.addEventListener("testPassive", null, opts);
        window.removeEventListener("testPassive", null, opts);

    } catch (e) {
        //No action to be taken
    }

    /**
     * Initialize all modules.
     */
    coned.utils.initializeModules();

    /**
     * Initialize event to select the input user
     */
    coned.utils.setInputUser();

    // Check if there is any lazy-load items in the site
    var lazyLoadElements = document.getElementsByClassName('js-lazy-load');

    // If there's any lazy-load elements, trigger event on window that tell lazy-load elements that all modules are loaded
    // This makes it so the loading order of modules its irrelevant
    if (lazyLoadElements.length) {
        coned.utils.triggerEvent(window, 'modules-loaded');
    }

    coned.accountData = null;

    /**
     * Initialize moxie.
     */
    coned.moxie.initEvents();

});
