// ==================== PAY MY BILL CREDIT CARD COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CreditCardPayBill = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var CreditCardPayBill = function ($creditCardForm) {
        /**
         * PRIVATE METHODS
         */

        var initializeEvents = function () {
            $creditCardForm.submit();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CreditCardPayBill.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CreditCardPayBill;
})();
