// ==================== FINANCIAL ADVISOR ACCOUNT SELECTOR COMPONENT ============================

/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FinancialAdvisorAccountSelector = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HIDDEN_PAGE_LOADING_CLASS: 'page-loading--hidden',
        CHANGE_EVENT: 'change',
        ACCOUNT_STRING: 'account',
        CONTINUE_STRING: 'continue',
        TRUE_STRING: 'true',
        DATA_MASTER_ATTRIBUTE: 'data-master-account',
        DATA_NYPA_ATTRIBUTE: 'data-nypa',
        DATA_INACTIVE_ATTRIBUTE: 'data-inactive-account',
        DATA_FINAL_ATTRIBUTE: 'data-final-account',
        ARIA_SELECTED_ATRRIBUTE: 'aria-selected',
        DATA_ACCOUNT_ATTRIBUTE: 'data-account',
        KEYDOWN: 'keydown',
        TOUCHSTART: 'touchstart',
        TOUCHMOVE: 'touchmove',

        // Selectors
        ADDRESS_DROPDOWN_SELECTOR: 'js-address-dropdown',
        ACCOUNT_DROPDOWN_ITEMS: 'js-dropdown-item',
        CONTINUE_BUTTON_SELECTOR: 'js-continue-button',
        DROPDOWN_BUTTON_CONTAINER_SELECTOR: 'js-dropdown-button-contain',
        PAGE_LOADING_SELECTOR: 'js-page-loading',
        DROPDOWN_ITEM_CONTAIN: 'js-dropdown-item-contain',
        MOUSE_USER_SELECTOR: 'mouse-user',
        KEY_BOARD_USER_STRING: 'keyboardUser',
        BODY_SELECTOR: 'js-body'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @return {}        Encapsulated modules with its function.
     */
    var FinancialAdvisorAccountSelector = function ($account) {
        /**
         * PRIVATE METHODS
         */

        var $body,
            $accountsInDropdown,
            $accountDropdown,
            $accountDropDownItems,
            $pageLoading,
            $continueButton,
            _isScroll;

        var initializeData = function () {
            $body = document.getElementsByClassName(CONSTANTS.BODY_SELECTOR)[0];
            $accountsInDropdown = $account.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_SELECTOR)[0];
            $pageLoading = document.getElementsByClassName(CONSTANTS.PAGE_LOADING_SELECTOR)[0];
            $accountDropdown = document.getElementsByClassName(CONSTANTS.DROPDOWN_BUTTON_CONTAINER_SELECTOR)[0];
            $accountDropDownItems = $accountsInDropdown.getElementsByClassName(CONSTANTS.ACCOUNT_DROPDOWN_ITEMS);
            $continueButton = document.getElementsByClassName(CONSTANTS.CONTINUE_BUTTON_SELECTOR)[0];
        };

        var initializeEvents = function () {
            // Validate account change
            _.each($accountDropDownItems, function ($accountItem) {
                coned.utils.addGeneralListeners($accountItem, accountChange);
                $accountItem.addEventListener(CONSTANTS.KEYDOWN, handleKeyDownOnAccountItem);

                // Prevent scrolling to be confused as click
                $accountItem.addEventListener(CONSTANTS.TOUCHSTART, function () {
                    _isScroll = false;
                }, coned.supportsPassive ? { passive: true } : false);
                $accountItem.addEventListener(CONSTANTS.TOUCHMOVE, function () {
                    _isScroll = true;
                }, coned.supportsPassive ? { passive: true } : false);
            });
            buttonStatus();
            setFocusInContinueButton();
        }

        var handleKeyDownOnAccountItem = function (event) {
            if (event.keyCode === coned.constants.KEY_CODE.ENTER) {
                accountChange(event, true);
            }
        }

        /**
         *   Function triggered to account change and to see if it is supported for programs
         */
        var accountChange = function (event, isHandleKeyDown) {

            if (_isScroll) {
                // user was trying to scroll, not click
                return;
            }

            var url = new URL(window.location.origin + window.location.pathname),
                $accountItem = event.currentTarget.getElementsByClassName(CONSTANTS.DROPDOWN_ITEM_CONTAIN)[0],
                account = $accountItem.getAttribute(CONSTANTS.DATA_ACCOUNT_ATTRIBUTE),
                isKeyboardUser = !query.hasClass($body, CONSTANTS.MOUSE_USER_SELECTOR);

            // Show loading spinner
            query.removeClass($pageLoading, CONSTANTS.HIDDEN_PAGE_LOADING_CLASS);

            url.searchParams.set(CONSTANTS.ACCOUNT_STRING, account);
            // Set account id in the link
            if (isHandleKeyDown) {
                // Set continue focus in the link
                url.searchParams.set(CONSTANTS.CONTINUE_STRING, true);
                // Set keyBoardUser in true in the link
                if (isKeyboardUser) {
                    // Set keyBoardUser String in the link
                    url.searchParams.set(CONSTANTS.KEY_BOARD_USER_STRING, true);
                }
            }
            window.location.href = url.href;
        }

        /**
         * Get continue button and disabled it, if is account nypa or master.
         */
        var buttonStatus = function () {
            var $continueButton = document.getElementsByClassName(CONSTANTS.CONTINUE_BUTTON_SELECTOR)[0],
                disabledButton =
                    $accountDropdown.getAttribute(CONSTANTS.DATA_MASTER_ATTRIBUTE) === CONSTANTS.TRUE_STRING ||
                    $accountDropdown.getAttribute(CONSTANTS.DATA_NYPA_ATTRIBUTE) === CONSTANTS.TRUE_STRING ||
                    $accountDropdown.getAttribute(CONSTANTS.DATA_INACTIVE_ATTRIBUTE) === CONSTANTS.TRUE_STRING ||
                    $accountDropdown.getAttribute(CONSTANTS.DATA_FINAL_ATTRIBUTE) === CONSTANTS.TRUE_STRING;
            // Button is disabled if the account is master, nypa, inactive and final
            $continueButton.disabled = disabledButton;
        }

        /**
         * Set focus in continue button when page is loaded
         */
        var setFocusInContinueButton = function () {
            // If continue is part of the link, focus will be added to the button.
            var queryString = window.location.search,
                urlParams = new URLSearchParams(queryString),
                isFocusContinue = urlParams.get(CONSTANTS.CONTINUE_STRING),
                isKeyboardUser = urlParams.get(CONSTANTS.CONTINUE_STRING);

            if (isFocusContinue) {
                // Removes the class if the user is using the keyboard prior to reloading, to focus the button
                if (isKeyboardUser) {
                    query.removeClass($body, CONSTANTS.MOUSE_USER_SELECTOR);
                }
                $continueButton.focus();
            }
        }

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FinancialAdvisorAccountSelector.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FinancialAdvisorAccountSelector;

})();
