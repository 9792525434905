// ==================== DASHBOARD LIFE SAVING EQUIPMENT COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.MarketSupplyCalculator = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM: 'js-market-supply-calculator-form',
        CLEAR_BTN_CLASS: 'js-reset-button',
        MSC_RESULT_TABLE_CLASS: 'js-msc-result',
        MSC_SERVICE_ERROR : 'js-msc-service-error',
        MSC_ELECTRICAL_RATE_SELECT : 'js-msc-electrical-rate',
        MSC_HEADER_SUPER_PEAK: 'js-msc-super-peak-header',
        MSC_HEADER_STANDARD_CUSTOMER: 'js-msc-standard-customer-total-header',
        MSC_RESULT_SUPER_PEAK: 'js-msc-super-peak',
        MSC_RESULT_STANDARD_CUSTOMER: 'js-msc-standard-customer-total',
        MSC_RESULT_VOLUNTARY_CUSTOMER_ON_PEAK: 'js-msc-voluntary-customer-on-peak',
        MSC_RESULT_VOLUNTARY_CUSTOMER_OFF_PEAK: 'js-msc-voluntary-customer-off-peak',
        FORM_SITECORE_ID: 'scId',
        FORM_ELECTRICAL_RATE: 'mscElectricalRate',
        FORM_ZONE: 'mscZone',
        FORM_START_DATE: 'mscStartDate',
        FORM_END_DATE: 'mscEndDate',
        FORM_LOADING: 'js-form-loading',
        ERROR_MESSAGE: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        RECAPTCHA_ELEMENT: 'js-recaptcha'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var MarketSupplyCalculator = function ($marketSupplyCalculator) {
        /**
         * PRIVATE METHODS
         */
        var $marketSupplyCalculatorForm,
            $clearBtn,
            $mscResultTable,
            $mscErrorMessage,
            $mscElectricalRateSelect,
            $mscHeaderSuperPeak,
            $mscHeaderStandardCustomer,
            $mscResultSuperPeak,
            $mscResultStandardCustomer,
            $mscResultVoluntaryCustomerOnPeak,
            $mscResultVoluntaryCustomerOffPeak,
            $formLoading,
            recaptcha,
            _hasCaptcha;

        var resetCalculator = function () {
            $mscErrorMessage.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mscResultTable.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (_hasCaptcha && recaptcha) {
                recaptcha.reset();
            }

            $mscElectricalRateSelect.focus();
        };

        var calculateRate = function() {
            var serviceUrl = $marketSupplyCalculator.dataset.serviceUrl,
                params,
                verify,
                formData;

            //Hide Error
            $mscErrorMessage.classList.add(CONSTANTS.HIDDEN_CLASS);

            //Hide Result
            $mscResultTable.classList.add(CONSTANTS.HIDDEN_CLASS);

            //Get verify for unauthenticated user
            if (_hasCaptcha && recaptcha) {
                verify = recaptcha.getResponse();
            }else {
                verify = '';
            }

            // Service Data
            params = {
                scId: query.getFormInputValue(document, CONSTANTS.FORM_SITECORE_ID),
                loadZone: query.getDropdownSelectedValue($marketSupplyCalculatorForm, CONSTANTS.FORM_ZONE),
                serviceClass: query.getDropdownSelectedValue($marketSupplyCalculatorForm, CONSTANTS.FORM_ELECTRICAL_RATE).split(',')[0],
                serviceClassType: query.getDropdownSelectedValue($marketSupplyCalculatorForm, CONSTANTS.FORM_ELECTRICAL_RATE).split(',')[1],
                fromDate: coned.utils.serviceDateFormat(query.getInputValue($marketSupplyCalculatorForm, CONSTANTS.FORM_START_DATE)),
                toDate: coned.utils.serviceDateFormat(query.getInputValue($marketSupplyCalculatorForm, CONSTANTS.FORM_END_DATE)),
                verify: verify
            };

            // Serialize JSON into string to append to FormData
            formData = JSON.stringify(params);

            // Service Call
            query.postData(
                serviceUrl,
                successCalculateRatesService,
                errorCalculateRatesService,
                formData,
                true,
                $formLoading
            );
            
        };

        var successCalculateRatesService = function (data) {
            if (coned.utils.isPatternLab()) {
                data = query.getData(coned.plConstants.GET_MARKET_SUPPLY_CALCULATOR_CALCULATE, setResultTableValues, function () {});
            }else {
                setResultTableValues(data);
            }  
        };

        var setResultTableValues = function (data) {
            if(data.MarketSupplySuperPeak) {
                $mscResultSuperPeak.innerText = data.MarketSupplySuperPeak;
                $mscHeaderSuperPeak.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $mscResultSuperPeak.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $mscHeaderStandardCustomer.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $mscResultStandardCustomer.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);                
            }else {
                $mscResultStandardCustomer.innerText = data.MarketSupplyTotal;
                $mscHeaderSuperPeak.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $mscResultSuperPeak.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $mscHeaderStandardCustomer.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $mscResultStandardCustomer.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);                
            }
            
            $mscResultVoluntaryCustomerOnPeak.innerText = data.MarketSupplyOnPeak;
            $mscResultVoluntaryCustomerOffPeak.innerText = data.MarketSupplyOffPeak;

            $mscResultTable.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $mscResultTable.focus();
        };

        var errorCalculateRatesService = function (data) {
            if (coned.utils.isPatternLab()) {
                data = query.getData(coned.plConstants.GET_MARKET_SUPPLY_CALCULATOR_SERVICES_ERROR, setErrorMessage, function () {});
            }else {
                setErrorMessage(data);
            }            
        };

        var setErrorMessage = function(data) {
            var $msgText = $mscErrorMessage.getElementsByClassName(CONSTANTS.ERROR_MESSAGE)[0];
            $msgText.innerText = data.errorMsg && data.errorMsg !== '' ? data.errorMsg : $msgText.innerText;
            $mscErrorMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $mscErrorMessage.focus();
        };

        /**
         *  Check Recaptcha
         **/
         var checkRecaptcha = function () {
            $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
            if (_hasCaptcha && recaptcha) {
                if(recaptcha.getResponse() !== '') {
                    recaptcha.reset();
                }
                recaptcha.checkRecaptcha();
                
            }
        };

        var initializeData = function () {
            $marketSupplyCalculatorForm = $marketSupplyCalculator.getElementsByClassName(CONSTANTS.FORM)[0];
            $clearBtn = $marketSupplyCalculatorForm.getElementsByClassName(CONSTANTS.CLEAR_BTN_CLASS)[0];
            $mscResultTable = $marketSupplyCalculator.getElementsByClassName(CONSTANTS.MSC_RESULT_TABLE_CLASS)[0];
            $mscErrorMessage = $marketSupplyCalculatorForm.getElementsByClassName(CONSTANTS.MSC_SERVICE_ERROR)[0];
            $mscElectricalRateSelect = $marketSupplyCalculatorForm.getElementsByClassName(CONSTANTS.MSC_ELECTRICAL_RATE_SELECT)[0];
            $mscHeaderSuperPeak = $marketSupplyCalculator.getElementsByClassName(CONSTANTS.MSC_HEADER_SUPER_PEAK)[0];
            $mscHeaderStandardCustomer = $marketSupplyCalculator.getElementsByClassName(CONSTANTS.MSC_HEADER_STANDARD_CUSTOMER)[0];
            $mscResultSuperPeak = $marketSupplyCalculator.getElementsByClassName(CONSTANTS.MSC_RESULT_SUPER_PEAK)[0];
            $mscResultStandardCustomer = $marketSupplyCalculator.getElementsByClassName(CONSTANTS.MSC_RESULT_STANDARD_CUSTOMER)[0];
            $mscResultVoluntaryCustomerOnPeak = $marketSupplyCalculator.getElementsByClassName(CONSTANTS.MSC_RESULT_VOLUNTARY_CUSTOMER_ON_PEAK)[0];
            $mscResultVoluntaryCustomerOffPeak = $marketSupplyCalculator.getElementsByClassName(CONSTANTS.MSC_RESULT_VOLUNTARY_CUSTOMER_OFF_PEAK)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            recaptcha = false;
            _hasCaptcha = $marketSupplyCalculator.getElementsByClassName(CONSTANTS.RECAPTCHA_ELEMENT)[0];
        };

        var initializeEvents = function () {

            if (_hasCaptcha) {
                new coned.components.ValidateForm($marketSupplyCalculatorForm, checkRecaptcha);
                recaptcha = new coned.components.Recaptcha($marketSupplyCalculator, calculateRate, calculateRate);
            }else {
                new coned.components.ValidateForm($marketSupplyCalculatorForm, calculateRate);
            }

            coned.utils.addGeneralListeners($clearBtn, resetCalculator);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        }

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     MarketSupplyCalculator.prototype.isLoaded = function () {
        return isLoaded;
    };

    return MarketSupplyCalculator;
})();
