// ==================== DATE PICKER COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DatePickerModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ARIA_EXPANDED: 'aria-expanded',
        ARIA_LABEL: 'aria-label',
        CALENDAR_ICON: 'js-calendar-icon',
        CALENDAR_VIEW_TEXT: 'Calendar View',
        DATE_PICKER_CLASS: '.ui-datepicker',
        DATE_PICKER_INSTRUCTIONS: 'js-date-picker-instructions',
        DATE_PICKER_INSTRUCTIONS_CLASS: '.js-date-picker-instructions',
        DATE_PICKER_INSTRUCTIONS_ACC_TITLE_CLASS: '.js-date-picker-instructions-acc-title',
        DATE_PICKER_INSTRUCTIONS_LIST_CLASS: '.js-date-picker-instructions-list',
        DATE_PICKER_INSTRUCTIONS_BODY_CLASS: '.js-date-picker-instructions-body',
        DATE_PICKER_INSTRUCTIONS_BODY: 'js-date-picker-instructions-body',
        DATE_PICKER_INSTRUCTIONS_ACC_BTN_CLASS: '.js-date-picker-instructions-acc-btn',
        DATE_PICKER_INSTRUCTIONS_ACC_BTN: 'js-date-picker-instructions-acc-btn',
        DATE_PICKER_NEXT_DATE: 'js-next-datepicker-input',
        DISABLED_TEXT: 'disabled',
        DIV_CONTAINER: '#ui-datepicker-div',
        ELEMENT_STATE_DEFAULT: '.ui-state-default',
        FORM_DATE: 'js-date',
        HAS_DATE_PICKER_CLASS: '.hasDatepicker',
        ICON_CARROT: 'icon-carrot',
        JS_DATE_CLASS: '.js-date',
        LINK_STATE_DEFAULT: 'a.ui-state-default',
        LINK_STATE_ACTIVE: 'a.ui-state-active',
        NEXT_MONTH_TEXT: 'Next Month',
        ARIA_CONTROLS: 'aria-controls',
        ARIA_LIVE: 'aria-live',
        ROLE: 'role',
        REGION: 'region',
        POLITE: 'polite',
        MONTHS: [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december'
        ],
        PREV_MONTH_TEXT: 'Previous Month',
        SELECTED_STATUS: 'Selected',
        TITLE: 'title',
        UI_DIV_CONTAINER: 'ui-datepicker-div',
        UI_DATE_PREV: '.ui-datepicker-prev',
        UI_DATE_NEXT: '.ui-datepicker-next',
        UI_INSTRUCTIONS: 'ui-instructions',
        UI_INSTRUCTIONS_BODY: 'ui-instructions-body',
        UI_INSTRUCTIONS_BODY_COLLAPSED: 'ui-instructions-body--collapsed',
        UI_INSTRUCTIONS_BODY_EXPANDED: 'ui-instructions-body--expanded',
        UI_INSTRUCTIONS_HEADER: 'ui-instructions-header',
        UI_INSTRUCTIONS_ACC_BTN: 'ui-instructions-acc-btn',
        UI_INSTRUCTIONS_ACC_BTN_ICON: 'ui-instructions-acc-btn-icon'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DatePickerModule = function ($datePicker) {
        /**
         * PRIVATE METHODS
         */
        var $datePickerInput,
            _inputWidth = $($datePicker).width();

        var calendarClickEvent = function (event) {
            event.preventDefault();

            _inputWidth = $($datePicker).width();
            $(this).datepicker('show');
        };

        var setMinMaxDates = function () {
            setMaxDate();
            setMinDate();
        };

        var setMaxDate = function () {
            if ($datePickerInput.dataset.maxDateDays) {
                var maxDate = coned.utils.dateFormat($datePickerInput.dataset.maxDateDays);

                // Set input validation rules
                $datePickerInput.dataset.maxDateDays = maxDate.toString();

                // Set the date picker dates limit
                setTimeout(function () {
                    $($datePickerInput).datepicker('option', 'maxDate', maxDate);
                }, 1);
            }
        };

        var setMinDate = function () {
            if ($datePickerInput.dataset.minDateDays) {
                var minDate = coned.utils.dateFormat($datePickerInput.dataset.minDateDays);

                // Set input validation rules
                $datePickerInput.dataset.minDateDays = minDate.toString();

                // Set the date picker dates limit
                setTimeout(function () {
                    $($datePickerInput).datepicker('option', 'minDate', minDate);
                }, 1);
            }
        };

        function appendOffscreenMonthText(button) {
            var $button = $(button),
                buttonText,
                isNext = $(button).hasClass('ui-datepicker-next'),
                months = CONSTANTS.MONTHS,
                currentMonth = $('.ui-datepicker-title .ui-datepicker-month').text().toLowerCase(),
                monthIndex = $.inArray(currentMonth.toLowerCase(), months),
                currentYear = $('.ui-datepicker-title .ui-datepicker-year').text().toLowerCase(),
                adjacentIndex = isNext ? monthIndex + 1 : monthIndex - 1;

            if (isNext && currentMonth === CONSTANTS.MONTHS[11]) {
                currentYear = parseInt(currentYear, 10) + 1;
                adjacentIndex = 0;
            } else if (!isNext && currentMonth === CONSTANTS.MONTHS[0]) {
                currentYear = parseInt(currentYear, 10) - 1;
                adjacentIndex = months.length - 1;
            }

            buttonText = isNext
                ? CONSTANTS.NEXT_MONTH_TEXT + ' ' + months[adjacentIndex] + ' ' + currentYear
                : CONSTANTS.PREV_MONTH_TEXT + ' ' + months[adjacentIndex] + ' ' + currentYear;

            if ($button.hasClass('ui-state-disabled')) {
                $button.attr('aria-disabled', true);
            }

            $button.find('.ui-icon').html(buttonText);
        }

        function addInstructionsElement() {
            var $context = $(CONSTANTS.DIV_CONTAINER);
            if ($context.find(CONSTANTS.DATE_PICKER_INSTRUCTIONS_CLASS).length === 0) {
                var $datePickerElement = $($datePicker);
                var $instructions = document.createElement('div');
                var $instructionsHeader = document.createElement('div');
                var $instructionsBody = document.createElement('div');
                var $instructionsAccBtnHeader = document.createElement('button');
                var $instructionsAccBtnChev = document.createElement('span');
                var $instructionsTitle = $datePickerElement.find(
                    CONSTANTS.DATE_PICKER_INSTRUCTIONS_ACC_TITLE_CLASS
                );

                $instructions.classList.add(CONSTANTS.UI_INSTRUCTIONS);
                $instructions.classList.add(CONSTANTS.DATE_PICKER_INSTRUCTIONS);
                $instructionsHeader.classList.add(CONSTANTS.UI_INSTRUCTIONS_HEADER);
                $instructionsBody.classList.add(CONSTANTS.UI_INSTRUCTIONS_BODY);
                $instructionsBody.classList.add(CONSTANTS.UI_INSTRUCTIONS_BODY_COLLAPSED);
                $instructionsBody.classList.add(CONSTANTS.DATE_PICKER_INSTRUCTIONS_BODY);
                $instructionsBody.setAttribute(CONSTANTS.ROLE, CONSTANTS.REGION);
                $instructionsBody.setAttribute(CONSTANTS.ARIA_LIVE, CONSTANTS.POLITE);
                $instructionsBody.setAttribute('id', CONSTANTS.DATE_PICKER_INFO_ID);
                $instructionsAccBtnHeader.classList.add(CONSTANTS.UI_INSTRUCTIONS_ACC_BTN);
                $instructionsAccBtnHeader.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
                $instructionsAccBtnHeader.setAttribute(
                    CONSTANTS.ARIA_CONTROLS,
                    CONSTANTS.DATE_PICKER_INFO_ID
                );
                $instructionsAccBtnHeader.classList.add(CONSTANTS.DATE_PICKER_INSTRUCTIONS_ACC_BTN);
                $instructionsAccBtnChev.classList.add(CONSTANTS.ICON_CARROT);
                $instructionsAccBtnChev.classList.add(CONSTANTS.UI_INSTRUCTIONS_ACC_BTN_ICON);

                $instructionsAccBtnHeader.innerHTML = $instructionsTitle.html();
                $instructionsAccBtnHeader.innerHTML += $instructionsAccBtnChev.outerHTML;
                $instructionsHeader.innerHTML = $instructionsAccBtnHeader.outerHTML;
                $($instructions).append($instructionsHeader).append($instructionsBody);
                $context.append($instructions);
            }
        }

        function updateElementsToA11y() {
            var $context = $(CONSTANTS.DIV_CONTAINER);

            if (!$context) {
                return;
            }
            var nextBtn = $context.find(CONSTANTS.UI_DATE_NEXT),
                prevBtn = $context.find(CONSTANTS.UI_DATE_PREV);

            prevBtn.attr('tabindex', 0).attr('role', 'button').removeAttr('title');

            nextBtn.attr('tabindex', 0).attr('role', 'button').removeAttr('title');

            addDayAriaLabels();
            addInstructionsElement();
            appendOffscreenMonthText(nextBtn);
            appendOffscreenMonthText(prevBtn);
        }

        function addDayAriaLabels() {
            var dates = $(CONSTANTS.LINK_STATE_DEFAULT);

            $(dates).each(function (index, date) {
                var currentRow = $(date).closest('tr'),
                    currentTds = $('td', currentRow),
                    currentIndex = $.inArray(date.parentNode, currentTds),
                    headThs = $('thead tr th'),
                    dayIndex = headThs[currentIndex],
                    daySpan = $('span', dayIndex)[0],
                    monthName = $('.ui-datepicker-month')[0].innerHTML,
                    year = $('.ui-datepicker-year')[0].innerHTML,
                    number = date.innerHTML;

                if (!daySpan || !monthName || !number || !year) {
                    return;
                }

                var dateText = date.innerHTML + ' ' + monthName + ' ' + year + ' ' + daySpan.title;
                date.setAttribute(CONSTANTS.ARIA_LABEL, dateText);
            });

            var activeDate = $(CONSTANTS.LINK_STATE_ACTIVE)[0];

            if (activeDate) {
                addSelectedStatusToDate(activeDate);
            }
        }

        function closeCalendar() {
            var $datePickerInputs = $(CONSTANTS.JS_DATE_CLASS);
            for (var x = 0; x < $datePickerInputs.length; x++) {
                $($datePickerInputs[x]).datepicker('hide');
            }
        }

        function handleNextClicks() {
            setTimeout(function () {
                var nextMon = $(CONSTANTS.UI_DATE_NEXT)[0];
                nextMon.click();
                updateElementsToA11y();
                initGlobalKeyboardActions();
                initDayPickerKeyboardActions();
                $(CONSTANTS.UI_DATE_NEXT).focus();
            }, 0);
        }

        function handlePrevClicks() {
            setTimeout(function () {
                var prevMon = $(CONSTANTS.UI_DATE_PREV)[0];
                prevMon.click();
                updateElementsToA11y();
                initGlobalKeyboardActions();
                initDayPickerKeyboardActions();
                $('.ui-datepicker-prev').focus();
            }, 0);
        }

        function nextDay(dateLink) {
            if (!dateLink) {
                return;
            }
            var td = $(dateLink).closest('td');
            if (!td) {
                return;
            }
            var nextTd = $(td).next(),
                nextDateLink = $(CONSTANTS.LINK_STATE_DEFAULT, nextTd)[0];

            if (nextTd && nextDateLink) {
                nextDateLink.focus();
            } else {
                handleNext(dateLink);
            }
        }

        function nextWeek(dateLink) {
            if (!dateLink) {
                return;
            }

            var currentRow = $(dateLink).closest('tr'),
                nextRow = $(currentRow).next(),
                currentRowLinks = $(CONSTANTS.ELEMENT_STATE_DEFAULT, currentRow),
                currentDayIndex = $.inArray(dateLink, currentRowLinks);

            if (!nextRow || nextRow.length === 0) {
                nextMonth(currentDayIndex);
            } else {
                var nextRowSameDay = getDayIfAvailableInWeek(currentDayIndex, nextRow);

                if (nextRowSameDay) {
                    nextRowSameDay.focus();
                }
            }
        }

        function nextMonth(firstDayIndex) {
            firstDayIndex = firstDayIndex || 0;

            var nextMon = $(CONSTANTS.UI_DATE_NEXT)[0],
                container = document.getElementById(CONSTANTS.UI_DIV_CONTAINER);

            nextMon.click();

            setTimeout(function () {
                updateElementsToA11y();
                initDayPickerKeyboardActions();
                initGlobalKeyboardActions();
                var firstDate = $(CONSTANTS.LINK_STATE_DEFAULT, container)[firstDayIndex];
                firstDate.focus();
            }, 0);
        }

        function handleNext(target) {
            if (!target) {
                return;
            }
            var currentRow = $(target).closest('tr'),
                nextRow = $(currentRow).next();

            if (!nextRow || nextRow.length === 0) {
                nextMonth();
            } else {
                var nextRowFirstDate = $(CONSTANTS.LINK_STATE_DEFAULT, nextRow)[0];
                if (nextRowFirstDate) {
                    nextRowFirstDate.focus();
                }
            }
        }

        function previousDay(dateLink) {
            if (!dateLink) {
                return;
            }
            var td = $(dateLink).closest('td');
            if (!td) {
                return;
            }

            var prevTd = $(td).prev(),
                prevDateLink = $(CONSTANTS.LINK_STATE_DEFAULT, prevTd)[0];

            if (prevTd && prevDateLink) {
                prevDateLink.focus();
            } else {
                handlePrevious(dateLink);
            }
        }

        function previousWeek(dateLink) {
            if (!dateLink) {
                return;
            }

            var currentRow = $(dateLink).closest('tr'),
                currentRowLinks = $(CONSTANTS.ELEMENT_STATE_DEFAULT, currentRow),
                currentDayIndex = $.inArray(dateLink, currentRowLinks),
                prevRow = $(currentRow).prev();

            if (!prevRow || prevRow.length === 0) {
                previousMonth(currentDayIndex);
            } else {
                var prevRowSameDay = getDayIfAvailableInWeek(currentDayIndex, prevRow);

                if (prevRowSameDay) {
                    prevRowSameDay.focus();
                }
            }
        }

        function previousMonth(lastDayIndex) {
            var prevLink = $(CONSTANTS.UI_DATE_PREV)[0],
                container = document.getElementById(CONSTANTS.UI_DIV_CONTAINER);

            prevLink.click();

            var trs = $('tr', container),
                lastRowTdLinks = $('td a.ui-state-default', trs[trs.length - 1]);

            if (lastDayIndex === undefined) {
                lastDayIndex = lastRowTdLinks.length - 1;
            }

            var lastDate = lastRowTdLinks[lastDayIndex];

            setTimeout(function () {
                updateElementsToA11y();
                initDayPickerKeyboardActions();
                initGlobalKeyboardActions();
                lastDate.focus();
            }, 0);
        }

        function handlePrevious(target) {
            if (!target) {
                return;
            }
            var currentRow = $(target).closest('tr');

            if (!currentRow) {
                return;
            }
            var previousRow = $(currentRow).prev();

            if (!previousRow || previousRow.length === 0) {
                previousMonth();
            } else {
                var prevRowDates = $('td a.ui-state-default', previousRow);
                var prevRowDate = prevRowDates[prevRowDates.length - 1];

                if (prevRowDate) {
                    setTimeout(function () {
                        prevRowDate.focus();
                    }, 0);
                }
            }
        }

        function getDayIfAvailableInWeek(dayIndex, rowObject) {
            var rowElements = $(CONSTANTS.ELEMENT_STATE_DEFAULT, rowObject),
                day = rowElements[dayIndex],
                isDayEnabled = $(day).is(CONSTANTS.LINK_STATE_DEFAULT);

            if (isDayEnabled) {
                return day;
            }
        }

        function addSelectedStatusToDate(dateSelected) {
            dateSelected.setAttribute(CONSTANTS.TITLE, CONSTANTS.SELECTED_STATUS);
        }

        var initGlobalKeyboardActions = function () {
            var $context = $(CONSTANTS.DIV_CONTAINER),
                container = document.getElementById(CONSTANTS.UI_DIV_CONTAINER),
                prevBtn = $context.find(CONSTANTS.UI_DATE_PREV),
                nextBtn = $context.find(CONSTANTS.UI_DATE_NEXT),
                instructionsBtn = $context.find(CONSTANTS.DATE_PICKER_INSTRUCTIONS_ACC_BTN_CLASS);

            $context.off('keydown');
            $context.on('keydown', function (e) {
                if (e.keyCode === coned.constants.KEY_CODE.ESC) {
                    e.preventDefault();
                    closeCalendar();
                }
            });

            nextBtn.off('keydown');
            nextBtn.on('keydown', function (e) {
                if (
                    e.keyCode === coned.constants.KEY_CODE.ENTER ||
                    e.keyCode === coned.constants.KEY_CODE.SPACE
                ) {
                    e.preventDefault();
                    handleNextClicks();
                }
            });

            prevBtn.off('keydown');
            prevBtn.on('keydown', function (e) {
                var isTabPressed = e.key === 'Tab' || e.keyCode === coned.constants.KEY_CODE.TAB;
                if (isTabPressed) {
                    // TAB and SHIFT-TAB arrow key
                    if (e.shiftKey) {
                        e.preventDefault();
                        instructionsBtn.focus();
                    }
                } else if (
                    e.keyCode === coned.constants.KEY_CODE.ENTER ||
                    e.keyCode === coned.constants.KEY_CODE.SPACE
                ) {
                    e.preventDefault();
                    handlePrevClicks();
                }
            });

            instructionsBtn.off('keydown');
            instructionsBtn.on('keydown', function (e) {
                var isTabPressed = e.key === 'Tab' || e.keyCode === coned.constants.KEY_CODE.TAB;
                if (isTabPressed) {
                    // TAB and SHIFT-TAB arrow key
                    if (e.shiftKey) {
                        e.preventDefault();
                        var firstDate = $(CONSTANTS.LINK_STATE_DEFAULT, container)[0];
                        firstDate.focus();
                    } else {
                        e.preventDefault();
                        prevBtn.focus();
                    }
                    closeInstructions();
                }
            });
        };

        var initDayPickerKeyboardActions = function () {
            var $context = $(CONSTANTS.DIV_CONTAINER);

            $context.find(CONSTANTS.LINK_STATE_DEFAULT).off('keydown');

            $context.find(CONSTANTS.LINK_STATE_DEFAULT).on('click', function (e) {
                addSelectedStatusToDate(e.target);
            });

            // CALENDAR DATES KEYBOARD ACTIONS
            $context.find(CONSTANTS.LINK_STATE_DEFAULT).on('keydown', function (e) {
                var isTabPressed = e.key === 'Tab' || e.keyCode === coned.constants.KEY_CODE.TAB;
                if (isTabPressed) {
                    // TAB and SHIFT-TAB arrow key
                    e.preventDefault();
                    if (e.shiftKey) {
                        $context.find(CONSTANTS.UI_DATE_NEXT).focus();
                    } else {
                        $context.find(CONSTANTS.DATE_PICKER_INSTRUCTIONS_ACC_BTN_CLASS).focus();
                    }
                } else if (e.keyCode === coned.constants.KEY_CODE.LEFT) {
                    // LEFT arrow key
                    e.preventDefault();
                    previousDay(e.target);
                } else if (e.keyCode === coned.constants.KEY_CODE.RIGHT) {
                    // RIGHT arrow key
                    e.preventDefault();
                    nextDay(e.target);
                } else if (e.keyCode === coned.constants.KEY_CODE.UP) {
                    // UP arrow key
                    e.preventDefault();
                    previousWeek(e.target);
                } else if (e.keyCode === coned.constants.KEY_CODE.DOWN) {
                    // DOWN arrow key
                    e.preventDefault();
                    nextWeek(e.target);
                }
            });
        };

        var addOnClickEvent = function ($datePickerInput) {
            if (
                $datePickerInput &&
                query.hasClass($datePickerInput, CONSTANTS.DATE_PICKER_NEXT_DATE)
            ) {
                $datePickerInput.nextSibling.disabled = true;
            }
            $datePickerInput.addEventListener('click', function () {
                $($datePickerInput).datepicker('show');
            });
        };

        var closeInstructions = function () {
            var $instructionsBody = document.querySelector(
                CONSTANTS.DATE_PICKER_INSTRUCTIONS_BODY_CLASS
            );
            var $instructionsAccBtnHeader = document.querySelector(
                CONSTANTS.DATE_PICKER_INSTRUCTIONS_ACC_BTN_CLASS
            );

            $instructionsAccBtnHeader.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
            $instructionsBody.classList.add(CONSTANTS.UI_INSTRUCTIONS_BODY_COLLAPSED);
            $instructionsBody.classList.remove(CONSTANTS.UI_INSTRUCTIONS_BODY_EXPANDED);
            $instructionsBody.innerHTML = '';
        };

        var openInstructions = function () {
            var $datePickerElement = $($datePicker);
            var $instructionsList = $datePickerElement.find(
                CONSTANTS.DATE_PICKER_INSTRUCTIONS_LIST_CLASS
            );
            var $instructionsBody = document.querySelector(
                CONSTANTS.DATE_PICKER_INSTRUCTIONS_BODY_CLASS
            );
            var $instructionsAccBtnHeader = document.querySelector(
                CONSTANTS.DATE_PICKER_INSTRUCTIONS_ACC_BTN_CLASS
            );

            $instructionsAccBtnHeader.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            $instructionsBody.classList.remove(CONSTANTS.UI_INSTRUCTIONS_BODY_COLLAPSED);
            $instructionsBody.classList.add(CONSTANTS.UI_INSTRUCTIONS_BODY_EXPANDED);
            setTimeout(function () {
                $instructionsBody.innerHTML = $instructionsList.html();
            }, 10);
        };

        var addOnClickEventAccordion = function () {
            var $context = $(CONSTANTS.DIV_CONTAINER);
            var $target = $context.find(CONSTANTS.DATE_PICKER_INSTRUCTIONS_ACC_BTN_CLASS);

            $target.off('click');
            $target.on('click', function (event) {
                if (
                    event &&
                    event.target.classList.contains(CONSTANTS.DATE_PICKER_INSTRUCTIONS_ACC_BTN)
                ) {
                    event.preventDefault();

                    if (event.target.attributes[CONSTANTS.ARIA_EXPANDED].value === 'false') {
                        openInstructions();
                    } else {
                        closeInstructions();
                    }
                }
            });
        };

        var initCalendarA11y = function () {
            updateElementsToA11y();
            initDayPickerKeyboardActions();
            initGlobalKeyboardActions();
            addInstructionsElement();
            addOnClickEventAccordion();
        };

        var initializeDates = function () {
            $($datePickerInput).datepicker({
                inline: true,
                showOn: 'button',
                buttonText: CONSTANTS.CALENDAR_VIEW_TEXT,
                nextText: CONSTANTS.NEXT_MONTH_TEXT,
                prevText: CONSTANTS.PREV_MONTH_TEXT,
                showOtherMonths: true,
                selectOtherMonths: true,
                beforeShow: function () {
                    var _inputWidth = $(this).width(),
                        $datePickerInputEl = $(CONSTANTS.DATE_PICKER_CLASS);
                    $datePickerInputEl.css('min-width', _inputWidth + 'px');

                    setTimeout(function () {
                        var $context = $(CONSTANTS.DIV_CONTAINER);

                        $context.attr('tabIndex', -1);
                        initCalendarA11y($datePickerInputEl);

                        $datePickerInputEl.css('width', _inputWidth + 'px');
                        $datePickerInputEl.css('visibility', 'visible');

                        $datePickerInputEl.attr('role', 'application');
                    }, 0);

                    setTimeout(function () {
                        var $context = $(CONSTANTS.DIV_CONTAINER),
                            $today = $context.find('.ui-datepicker-today a'),
                            $current = $context.find('.ui-datepicker-current-day a');

                        if ($current.length > 0) {
                            $current.focus();
                        } else if ($today.length > 0) {
                            $today.focus();
                        } else {
                            $context.focus();
                        }
                    }, 500);
                },
                onClose: function () {
                    var $target = $(this);
                    $target.focus();
                    $target.valid();
                },
                onSelect: function () {
                    var $target = $(this);
                    $target.change();
                    coned.utils.triggerEvent($target[0], 'dateSelected');
                },
                onChangeMonthYear: function () {
                    _inputWidth = $(this).width();

                    setTimeout(function () {
                        $(CONSTANTS.DIV_CONTAINER).css('width', _inputWidth + 'px');
                        addInstructionsElement();
                        addOnClickEventAccordion();
                    }, 0);
                }
            });

            addOnClickEvent($datePickerInput);
            setMinMaxDates();
        };

        var initializeData = function () {
            $datePickerInput = $datePicker.getElementsByClassName(CONSTANTS.FORM_DATE)[0];
        };

        var initializeEvents = function () {
            var $calendarIcon = $datePicker.getElementsByClassName(CONSTANTS.CALENDAR_ICON)[0];
            coned.utils.addGeneralListeners($calendarIcon, calendarClickEvent);

            // resize date picker listener
            $(window).resize(function () {
                var field = $(CONSTANTS.FORM_DATE);
                _inputWidth = $($datePicker).width();

                if (field.is(CONSTANTS.HAS_DATE_PICKER_CLASS)) {
                    field.datepicker('hide');
                }
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            initializeDates();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DatePickerModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DatePickerModule;
})();
