// ==================== PRE REGISTER VALIDATION COMPONENT =========================
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PreRegisterValidation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        FORM_CLASS_SELECTOR: '.js-register-form',
        SUCCESS_BANNER: 'js-finish-selector',
        FORM_CONTAINER_SELECTOR: 'js-register-container',
        SUCCESS_BANNER_TITLE: 'js-success-email',
        INPUT_EMAIL_SELECTOR: 'js-email-selector',
        HIDDEN_CLASS: 'hidden',
        PRE_REGISTER_SITECORE_ID: 'ScId',
        PRE_REGISTER_FIRST_NAME: 'registerFirstName',
        PRE_REGISTER_LAST_NAME: 'registerLastName',
        PRE_REGISTER_EMAIL: 'registerEmail',
        PRE_REGISTER_ERROR: 'js-password-error',
        PRE_REGISTER_ERROR_MESSAGE: 'js-error-message',
        FROM_URI_PARAMETER_NAME: 'fromURI',
        RESET_PASSWORD: 'js-pre-register-reset-password',
        ACCOUNT_ERROR: 'js-remove-account-error',
        FORM_LOADING: 'js-form-loading',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        CAPTCHA_ELEMENT_CLASS: 'js-recaptcha',
        CAPTCHA_ERROR_CLASS: 'js-captcha-error',
        VALIDATE_IGNORE_CLASS: 'js-validate-ignore',
        CHECKBOX_CHECKED_CLASS: 'coned-checkbox--checked',
        EMPTY_STRING: '',
        DISABLED_ATTRIBUTE: 'disabled',

        // Third Party Constants
        THIRD_PARTY_CHECKBOX: 'js-third-party-checkbox',
        THIRD_PARTY_COMPANY_INPUT: 'js-third-party-company',
        THIRD_PARTY_COMPANY_INPUT_NAME: 'thirdPartyCompany',
        THIRD_PARTY_COMPANY_INPUT_CONTAINER: 'js-third-party-company-container',

        // Tagging
        TAGGING_CHECK_BOX: 'Step01.Check.Box',
        TAGGING_SUBMIT: 'Step01.Submit',
        TAGGING_REGISTRATION_SUCCESS: 'Step02.Page.Load'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var PreRegisterValidation = function ($register) {
        /**
         * PRIVATE METHODS
         */
        var $submitButton,
            $submitAnimationButton,
            $succesBanner,
            $succesBannerTitle,
            $inputEmailSelector,
            $formContainerSelector,
            $preRegisterError,
            $preRegisterErrorMessage,
            $resetPassword,
            $accountError,
            $formLoading,
            $captchaElement,
            $thirdPartyCompanyInput,
            $thirdPartyCompanyInputContainer,
            $thirdPartyCheckbox,
            recaptcha,
            _hasCaptcha,
            _data,
            _isFormValidOnLoad;

        var preRegisterCall = function () {
            if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            } else {
                hideError();

                var params,
                    preRegisterService = $register.dataset.preRegisterService,
                    fromUri = coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                        ? coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                        : '';

                params = {
                    ScId: query.getFormInputValue($register, CONSTANTS.PRE_REGISTER_SITECORE_ID),
                    FirstName: query.getFormInputValue(
                        $register,
                        CONSTANTS.PRE_REGISTER_FIRST_NAME
                    ),
                    LastName: query.getFormInputValue($register, CONSTANTS.PRE_REGISTER_LAST_NAME),
                    Email: query.getFormInputValue($register, CONSTANTS.PRE_REGISTER_EMAIL),
                    FromUri: fromUri
                };

                if ($thirdPartyCheckbox) {
                    params.UserType = $thirdPartyCheckbox.checked ? 'MyAccount/AccountManager' : '';
                    params.Organization = $thirdPartyCheckbox.checked
                        ? query.getFormInputValue(
                              $register,
                              CONSTANTS.THIRD_PARTY_COMPANY_INPUT_NAME
                          )
                        : '';
                }

                if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                    params.Verify = recaptcha.getResponse();
                }

                params = JSON.stringify(params);
                query.postData(
                    preRegisterService,
                    successPreRegisterCall,
                    errorPreRegisterCall,
                    params,
                    true
                );
            }
        };

        var successPreRegisterCall = function () {
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        var errorPreRegisterCall = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var successPreRegisterServiceCallback = function () {
            // Analytics data building
            dataLayer.push({
                event: 'registration.process',
                registration: 'step02'
            });

            var successTitle = $succesBannerTitle.innerHTML;

            $succesBannerTitle.parentElement.setAttribute('data-email', $inputEmailSelector.value);
            $succesBannerTitle.innerHTML = successTitle.replace(
                '@EMAIL',
                '<b>' + $inputEmailSelector.value + '</b>'
            );
            query.removeClass($succesBanner, CONSTANTS.HIDDEN_CLASS);
            query.addClass($formContainerSelector, CONSTANTS.HIDDEN_CLASS);
            $succesBanner.focus();

            $resetPassword = $succesBanner.getElementsByClassName(CONSTANTS.RESET_PASSWORD)[0];
            coned.utils.addGeneralListeners($resetPassword, resetPasswordEmail);

            // Analytics data building
            dataLayer.push({
                event: 'registration.process',
                registration: 'step03'
            });

            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/register/email-activation-sent'
            });

            // Tagging
            if ($register.dataset.tagging && $thirdPartyCheckbox && $thirdPartyCheckbox.checked) {
                dataLayer.push({
                    event: $register.dataset.tagging + CONSTANTS.TAGGING_REGISTRATION_SUCCESS
                });
            }
        };

        var errorPreRegisterServiceCallback = function () {
            if (_data && _data.errorMsg) {
                $preRegisterError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $preRegisterErrorMessage.innerHTML = _data.errorMsg;
                $preRegisterError.focus();
                //Reset the recaptcha
                recaptcha.reset();
            }
        };

        var hideError = function () {
            $preRegisterError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $preRegisterErrorMessage.innerHTML = '';
        };

        var preRegisterSubmit = function () {
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                preRegisterCall,
                successPreRegisterServiceCallback,
                errorPreRegisterServiceCallback,
                false,
                true
            );
        };

        var resetPasswordEmail = function (event) {
            event.preventDefault();

            var serviceUrl = $register.dataset.updatePasswordService,
                currentUserEmail = $succesBannerTitle.parentElement.dataset.email.toLowerCase(),
                params = {
                    email: encodeURIComponent(currentUserEmail)
                };

            if (currentUserEmail) {
                // Service Call
                query.getData(
                    serviceUrl,
                    successResetPassword,
                    errorResetPassword,
                    params,
                    $formLoading
                );
            }
        };

        var successResetPassword = function () {
            $accountError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var errorResetPassword = function (data) {
            var $accountErrorMessage = $accountError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];

            $accountError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $accountErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $accountErrorMessage.focus();
        };

        /**
         * Third party checkbox handler.
         */
        var thirdPartyCheckEvent = function () {
            if ($thirdPartyCheckbox.checked) {
                $thirdPartyCompanyInput.required = true;

                $thirdPartyCompanyInput.classList.remove(CONSTANTS.VALIDATE_IGNORE_CLASS);
                $thirdPartyCompanyInputContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $submitButton.disabled = !$($register).validate().checkForm();

                if ($register.dataset.tagging) {
                    dataLayer.push({
                        event: $register.dataset.tagging + CONSTANTS.TAGGING_CHECK_BOX
                    });
                }
            } else {
                $thirdPartyCompanyInput.required = false;

                $thirdPartyCompanyInput.classList.add(CONSTANTS.VALIDATE_IGNORE_CLASS);
                $thirdPartyCompanyInputContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                $submitButton.disabled = !$($register).validate().checkForm();
            }
        };

        /**
         * Reset Recaptcha.
         */
        var resetRecaptcha = function () {
            if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                recaptcha.reset();
            }
        };

        /**
         * Check form validity on page load to display it accordingly.
         * Adds the correct classes/attributes to inputs if needed.
         * Enables or disables submit button as needed.
         * Resets recaptcha to allow submit if needed.
         */
        var isformValidOnLoad = function () {
            setTimeout(function () {
                if ($thirdPartyCheckbox) {
                    thirdPartyCheckEvent();
                }

                coned.utils.checkInputsFilled($register);

                _isFormValidOnLoad = coned.components.ValidateForm.isFormValid(
                    CONSTANTS.FORM_CLASS_SELECTOR,
                    CONSTANTS.EMPTY_STRING,
                    CONSTANTS.VALIDATE_IGNORE_CLASS
                );

                if (_isFormValidOnLoad) {
                    $submitButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                } else {
                    $submitButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, '');
                }

                resetRecaptcha();
            }, 1000);
        };

        var initializeData = function () {
            $submitButton = $register.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $inputEmailSelector = $register.getElementsByClassName(
                CONSTANTS.INPUT_EMAIL_SELECTOR
            )[0];
            $succesBanner = document.getElementsByClassName(CONSTANTS.SUCCESS_BANNER)[0];
            $succesBannerTitle = document.getElementsByClassName(CONSTANTS.SUCCESS_BANNER_TITLE)[0];
            $submitAnimationButton = $register.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            $formContainerSelector = document.getElementsByClassName(
                CONSTANTS.FORM_CONTAINER_SELECTOR
            )[0];
            $preRegisterError = $register.getElementsByClassName(CONSTANTS.PRE_REGISTER_ERROR)[0];
            $preRegisterErrorMessage = $register.getElementsByClassName(
                CONSTANTS.PRE_REGISTER_ERROR_MESSAGE
            )[0];
            $thirdPartyCheckbox = $register.getElementsByClassName(
                CONSTANTS.THIRD_PARTY_CHECKBOX
            )[0];
            $thirdPartyCompanyInput = $register.getElementsByClassName(
                CONSTANTS.THIRD_PARTY_COMPANY_INPUT
            )[0];
            $thirdPartyCompanyInputContainer = $register.getElementsByClassName(
                CONSTANTS.THIRD_PARTY_COMPANY_INPUT_CONTAINER
            )[0];
            $accountError = $succesBanner.getElementsByClassName(CONSTANTS.ACCOUNT_ERROR)[0];
            $formLoading = $succesBanner.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $captchaElement = document.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT_CLASS);
            _hasCaptcha = $captchaElement.length;
        };

        var initializeEvents = function () {
            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/register/who-is-registering'
            });

            $register.addEventListener('input', function () {
                $submitButton.disabled = !$($register).validate().checkForm();
            });

            if ($thirdPartyCheckbox) {
                $thirdPartyCheckbox.addEventListener('change', thirdPartyCheckEvent);
            }

            if ($register.dataset.tagging) {
                coned.utils.addGeneralListeners($submitButton, function () {
                    if ($thirdPartyCheckbox && $thirdPartyCheckbox.checked) {
                        dataLayer.push({
                            event: $register.dataset.tagging + CONSTANTS.TAGGING_SUBMIT
                        });
                    }
                });
            }

            // form validation
            new coned.components.ValidateForm(
                CONSTANTS.FORM_CLASS_SELECTOR,
                checkRecaptcha,
                CONSTANTS.VALIDATE_IGNORE_CLASS
            );

            recaptcha = new coned.components.Recaptcha(
                document.querySelector(CONSTANTS.FORM_CLASS_SELECTOR),
                preRegisterSubmit,
                preRegisterSubmit
            );

            isformValidOnLoad();
        };

        var checkRecaptcha = function () {
            if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                recaptcha.checkRecaptcha();
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PreRegisterValidation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PreRegisterValidation;
})();
