// ==================== ANCHOR LINK COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AnchorLink = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ANCHOR_BUTTON: 'js-anchor-button',
        ANCHOR_ACTIVE_CLASS: 'anchor-link__nav-item--active',
        ANCHOR_SECTION: 'js-section',
        SELECT_ELEMENT: 'js-coned-select',
        SELECT_TEXT: 'js-select-text',
        HEADER_WRAPPER: 'header-wrapper',
        ARTICLE_HEADER: 'article-header',
        LABEL_CLASS: 'js-coned-select-label',
        ANCHOR_WRAPPER_SELECTOR: 'js-anchors-wrappers',
        ANCHOR_WRAPPER_CLASS: 'anchors-wrappers',
        ALERTS_CONTAINER: 'js-alerts-container',
        ALERT_CLOSE_BUTTON: 'js-close-alert',
        MODAL_SELECTOR_WRAPPER: 'js-modal-selector-wrapper',
        MODAL_OPEN_BUTTON: 'js-open-modal',
        MODAL_CLOSE_BUTTON: 'js-close-modal',
        BUTTON_CONTAINER_SELECTOR: 'js-anchor-link_list',
        SELECT_CONTAINER_SELECTOR: 'js-anchor-link-select',
        HIDE_CLASS: 'anchor-link--hidden',
        HIDE_CLASS_DISPLAY: 'anchor-link--hidden-display',
        ROLE: 'role',
        ROLE_TAB: 'tab',
        ROLE_TABLIST: 'tablist',
        ARIA_SELECTED: 'aria-selected',
        TAB_INDEX: 'tabindex',
        CLICK_EVENT:'click'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var AnchorLink = function ($AnchorLinkModule) {
        /**
         * PRIVATE METHODS
         */
        var $anchorButtons,
            $selectElement,
            $selectText,
            $articleHeader,
            $header,
            $alertsContainer,
            $anchorWrapper,
            $alertClose,
            $modalWrapper,
            $modalClose,
            $modalOpen,
            $buttonContainerSelector,
            $selectContainerSelector,
            $activeTab,
            $sections,
            _totalWidthButtons,
            _isFixed;

        /**
         * Scrolls to selected section.
         * @param {String} event Event type.
         */
        var scrollToSection = function (event) {
            event.preventDefault();
            
            var isClickEvent = event.type === CONSTANTS.CLICK_EVENT,
                $itemClicked = query.selectParentElement(event.target, CONSTANTS.ANCHOR_BUTTON),
                sectionName = isClickEvent
                    ? $itemClicked.dataset.value
                    : event.target.options[event.target.selectedIndex].value,
                sectionActive = document.getElementById(sectionName),
                offsetReduction =
                    $AnchorLinkModule.style.position == 'fixed'
                        ? 25
                        : coned.utils.isDesktop()
                        ? 50
                        : 25,
                totalReduction =
                    $header.offsetHeight + $AnchorLinkModule.offsetHeight + offsetReduction;

            coned.utils.scrollTo(sectionActive.offsetTop - totalReduction, 1000);
            sectionActive.focus();
        };

        /**
         * Sets the active section and anchor nav CSS position depending on the vertical scroll position.
        */
        var onScrollHandler = function () {
            var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop,
                headerHeight = $header.offsetHeight,
                anchorLinkHeight = $AnchorLinkModule.offsetHeight;

            setFixedPosition(scrollPosition, headerHeight, anchorLinkHeight);
            setActiveSection(scrollPosition, headerHeight, anchorLinkHeight);
        };

        /**
         * Sets the position of the anchor nav to fixed or relative depending on the scroll position.
         * @param {Number} scrollPosition Current scroll position.
         * @param {Number} headerHeight Current header height. (can change on resize)
         * @param {Number} anchorLinkHeight Current anchor link nav height. (can change on resize)
         */
        var setFixedPosition = function (scrollPosition, headerHeight, anchorLinkHeight) {
            var distance = $AnchorLinkModule.offsetTop - scrollPosition,
                offset = scrollPosition,
                realDistance = distance - headerHeight,
                marginTop = coned.utils.isDesktop() ? -35 : -10,
                staticPoint =
                    $articleHeader.offsetHeight +
                    anchorLinkHeight +
                    marginTop -
                    10;

            // Set the anchor nav to fixed or relative
            if (realDistance <= 0 && !_isFixed) {
                $AnchorLinkModule.style.position = 'fixed';
                $AnchorLinkModule.style.top = $header.offsetHeight + 'px';
                $AnchorLinkModule.style.marginTop = 0;
                $anchorWrapper.classList.toggle(CONSTANTS.ANCHOR_WRAPPER_CLASS);
                _isFixed = true;
            } else if (_isFixed && offset <= staticPoint) {
                $AnchorLinkModule.style.position = 'relative';
                $AnchorLinkModule.style.top = 0;
                $AnchorLinkModule.style.marginTop = marginTop + 'px';
                $anchorWrapper.classList.toggle(CONSTANTS.ANCHOR_WRAPPER_CLASS);
                _isFixed = false;
            }
        };

        /**
         * Sets the active section's tab, dropdown option and title based off of the current scroll position.
         * @param {Number} scrollPosition Current scroll position.
         * @param {Number} headerHeight Current header height. (can change on resize)
         * @param {Number} anchorLinkHeight Current anchor link nav height. (can change on resize)
         */
        var setActiveSection = function (scrollPosition, headerHeight, anchorLinkHeight) {
            var additionalMargin = 35,
                sectionsOffset = {},
                activeSectionId;

            // Set the offset of each section 
            // Get current active section
            _.each($sections, function ($section) {
                sectionsOffset[$section.id] = $section.offsetTop - headerHeight - anchorLinkHeight - additionalMargin;

                if (sectionsOffset[$section.id] <= scrollPosition) {
                    activeSectionId = $section.id;
                }
            });

            // On load there is no active section
            if (activeSectionId) {
                // Active tab
                var $newActiveTab = $AnchorLinkModule.querySelector(
                    'button[data-value*=' + activeSectionId + ']'
                );
                setInactiveTab($activeTab);
                setActiveTab($newActiveTab);
    
                // Active dropdown & title
                $selectElement.value = activeSectionId;
                $selectText.innerHTML =
                    $selectElement.options[$selectElement.selectedIndex].text;
            }
        };

        var closeAlert = function (event) {
            event.preventDefault();

            var actualAnchorPosition = $AnchorLinkModule.style.top,
                actualPositionValue = parseInt(actualAnchorPosition, 10);

            $AnchorLinkModule.style.top =
                actualPositionValue - $alertsContainer.offsetHeight + 'px';
        };

        var openModalBar = function (event) {
            event.preventDefault();

            if (coned.utils.isDesktop()) {
                var actualAnchorPosition = $AnchorLinkModule.style.top,
                    actualPositionValue = parseInt(actualAnchorPosition, 10);

                if ($modalWrapper.style.height > '0px') {
                    $AnchorLinkModule.style.top = actualPositionValue - 125 + 'px';
                } else {
                    $AnchorLinkModule.style.top = actualPositionValue + 125 + 'px';
                }
            }
        };

        var closeModalBar = function (event) {
            event.preventDefault();

            if (coned.utils.isDesktop()) {
                var actualAnchorPosition = $AnchorLinkModule.style.top,
                    actualPositionValue = parseInt(actualAnchorPosition, 10);

                $AnchorLinkModule.style.top = actualPositionValue - 125 + 'px';
            }
        };

        var setAnchorLinks = function () {
            query.removeClass($buttonContainerSelector, CONSTANTS.HIDE_CLASS_DISPLAY);
            query.removeClass($selectContainerSelector, CONSTANTS.HIDE_CLASS_DISPLAY);
            if ($buttonContainerSelector.offsetWidth > _totalWidthButtons) {
                query.removeClass($buttonContainerSelector, CONSTANTS.HIDE_CLASS);
                query.addClass($selectContainerSelector, CONSTANTS.HIDE_CLASS);
                query.addClass($selectContainerSelector, CONSTANTS.HIDE_CLASS_DISPLAY);
            } else {
                query.addClass($buttonContainerSelector, CONSTANTS.HIDE_CLASS);
                query.addClass($buttonContainerSelector, CONSTANTS.HIDE_CLASS_DISPLAY);
                query.removeClass($selectContainerSelector, CONSTANTS.HIDE_CLASS);
            }
        };

        var setActiveTab = function ($tab) {
            $tab.setAttribute(CONSTANTS.ARIA_SELECTED, true);
            $tab.setAttribute(CONSTANTS.TAB_INDEX, 0);
            query.addClass($tab, CONSTANTS.ANCHOR_ACTIVE_CLASS);
            $activeTab = $tab;
        }

        var setInactiveTab = function ($tab) {
            $tab.setAttribute(CONSTANTS.ARIA_SELECTED, false);
            $tab.setAttribute(CONSTANTS.TAB_INDEX, -1);
            query.removeClass($tab, CONSTANTS.ANCHOR_ACTIVE_CLASS);
        }

        /**
         *
         * @param {*} event Default event with button was clicked
         * @param {*} listElements list of tabs or options of dropdown
         */
         var handleKeydownEventListener = function (event, listElements) {
            event.stopPropagation();

            switch (event.keyCode) {
                case coned.constants.KEY_CODE.HOME:
                    event.preventDefault();
                    listElements[0].focus();
                    break;

                case coned.constants.KEY_CODE.END:
                    event.preventDefault();
                    listElements[listElements.length - 1].focus();
                    break;

                case coned.constants.KEY_CODE.RIGHT:
                case coned.constants.KEY_CODE.LEFT:
                case coned.constants.KEY_CODE.UP:
                case coned.constants.KEY_CODE.DOWN:
                    event.preventDefault();
                    moveUsingArrowKeys(event, listElements);
                    break;
            }
        };

        var moveUsingArrowKeys = function (event, listElementNav) {
            var key = event.keyCode,
                listElement = Array.prototype.slice.call(listElementNav),
                index = listElement.indexOf(event.target),
                newTab;

            if (key === coned.constants.KEY_CODE.RIGHT || key === coned.constants.KEY_CODE.DOWN) {
                newTab = getNextTab(index, listElementNav);
            } else {
                newTab = getPrevTab(index, listElementNav);
            }

            newTab.focus();
        };

        var getPrevTab = function (index, listElementNav) {
            return listElementNav[index - 1] || listElementNav[listElementNav.length - 1];
        };

        var getNextTab = function (index, listElementNav) {
            return listElementNav[index + 1] || listElementNav[0];
        };

        var initializeData = function () {
            $anchorButtons = $AnchorLinkModule.getElementsByClassName(CONSTANTS.ANCHOR_BUTTON);
            $selectElement = $AnchorLinkModule.getElementsByClassName(CONSTANTS.SELECT_ELEMENT)[0];
            $selectText = $AnchorLinkModule.getElementsByClassName(CONSTANTS.SELECT_TEXT)[0];
            $anchorWrapper = document.getElementsByClassName(CONSTANTS.ANCHOR_WRAPPER_SELECTOR)[0];
            $articleHeader = document.getElementsByClassName(CONSTANTS.ARTICLE_HEADER)[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER_WRAPPER)[0];
            $modalWrapper = document.getElementsByClassName(CONSTANTS.MODAL_SELECTOR_WRAPPER)[0];
            $modalOpen = document.getElementsByClassName(CONSTANTS.MODAL_OPEN_BUTTON)[0];
            $modalClose = document.getElementsByClassName(CONSTANTS.MODAL_CLOSE_BUTTON)[0];
            $alertsContainer = document.getElementsByClassName(CONSTANTS.ALERTS_CONTAINER)[0];
            $buttonContainerSelector = $AnchorLinkModule.getElementsByClassName(
                CONSTANTS.BUTTON_CONTAINER_SELECTOR
            )[0];
            $selectContainerSelector = $AnchorLinkModule.getElementsByClassName(
                CONSTANTS.SELECT_CONTAINER_SELECTOR
            )[0];
            $alertClose = document.getElementsByClassName(CONSTANTS.ALERT_CLOSE_BUTTON);
            $activeTab = $AnchorLinkModule.getElementsByClassName(CONSTANTS.ANCHOR_BUTTON)[0];
            $sections = document.getElementsByClassName(CONSTANTS.ANCHOR_SECTION);
            _totalWidthButtons = 0;
            _isFixed = false;
        };

        var initializeEvents = function () {
            // Throttle to improve performance, a longer delay could produce sections title to get out of sync
            coned.utils.onScrollThrottler(onScrollHandler, 20);
            $buttonContainerSelector.setAttribute(CONSTANTS.ROLE, CONSTANTS.ROLE_TABLIST);

            // Add listeners and attributes to each tab button.
            _.each($anchorButtons, function ($anchorButton) {
                coned.utils.addGeneralListeners($anchorButton, scrollToSection);
                $anchorButton.addEventListener('keydown', function (event) {
                    handleKeydownEventListener(event, $anchorButtons);
                });

                $anchorButton.setAttribute(CONSTANTS.ROLE, CONSTANTS.ROLE_TAB);
                setInactiveTab($anchorButton);

                //Setting total width Button
                _totalWidthButtons += $anchorButton.offsetWidth;
            });

            $activeTab && setActiveTab($activeTab);

            // width addition so the first link is not covered by the feedback button
            _totalWidthButtons += 40;

            // close alert handler
            _.each($alertClose, function ($closeAlertButton) {
                coned.utils.addGeneralListeners($closeAlertButton, closeAlert);
            });

            // select change listener
            $selectElement.addEventListener('change', scrollToSection);

            // modal bar
            if ($modalOpen != undefined) {
                coned.utils.addGeneralListeners($modalOpen, openModalBar);
                coned.utils.addGeneralListeners($modalClose, closeModalBar);
            }

            // resize listener
            window.addEventListener('resize', function () {
                setAnchorLinks();

                if ($AnchorLinkModule.style.position == 'fixed') {
                    $AnchorLinkModule.style.top = $header.offsetHeight + 'px';
                }
            });

            setAnchorLinks();

            // Setting mobile select text
            if ($selectText && $selectElement.length && $selectElement.options[1]) {
                $selectText.innerHTML = $selectElement.options[1].text;
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AnchorLink.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AnchorLink;
})();
