// ==================== CARDS CLOSED COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CardsClosed = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        OPEN_LINK_SELECTOR: 'js-manage-cards-selector',
        CARD_SELECTOR: 'js-cards-selector',
        CARD_CONTAINER_SELECTOR: 'js-manage-cards-container',
        DISABLED_SELECTOR: 'js-disabled-selector',
        CLOSE_CARD_SELECTOR: 'js-close-card-selector',
        DISABLED_CARD_CLASS: 'manage-cards__card--disabled',
        ACTIVE_CARD_CLASS: 'manage-cards__card--active',
        CLOSE_BUTTON: 'js-close-button',
        HIDDEN_CLASS: 'hidden',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        FORM_LOADING: 'js-form-loading',
        ERROR_CONTAINER_CLASS: 'transactional__error',
        ERROR_ICON_CLASSES: 'transactional__error-icon icon-report-problem',
        CARD_BUTTON_SPAN_CLASS: 'manage-cards__link-description',
        DATA_ELECTRICITY_PREV_READ_DATE: 'electricity-current-read-date',
        DATA_GAS_PREV_READ_DATE: 'gas-current-read-date',
        DATA_ELECTRICITY_CURRENT_READ: 'electricity-current-read',
        DATA_GAS_CURRENT_READ: 'gas-current-read',
        MANAGE_CARDS_WRAPPER_ACTIVE: 'manage-cards__contain-wrapper-active'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var CardsClosed = function ($cards) {
        /**
         * PRIVATE METHODS
         */
        var $openLinkElements,
            $openLinkClicked,
            $openedCard,
            $cardSelector,
            $closeCardsSelector,
            $cardContainerElement,
            $cardContainerToShow,
            $closeButtons,
            $accountMaidInput,
            $formLoading,
            _serviceType;

        var openCard = function ($targetElement) {
            $openedCard = query.selectParentElement($targetElement, CONSTANTS.CARD_SELECTOR);
            $openLinkClicked = query.selectParentElement(
                $targetElement,
                CONSTANTS.OPEN_LINK_SELECTOR
            );

            if (!$openLinkClicked) return;

            // Set index correctly
            for (var index = 0; index < $openLinkElements.length; index++) {
                if ($openLinkElements[index] === $openLinkClicked) {
                    break;
                }
            }

            // Disabled Cards
            query.addClass($cardSelector, CONSTANTS.DISABLED_CARD_CLASS);
            query.removeClass($cardSelector, CONSTANTS.ACTIVE_CARD_CLASS);
            $openedCard.classList.remove(CONSTANTS.DISABLED_CARD_CLASS);
            $openedCard.classList.add(CONSTANTS.ACTIVE_CARD_CLASS);

            // Show/Hide Container
            query.addClass($cardContainerElement, CONSTANTS.HIDDEN_CLASS);
            // Container could be received through a service call
            _serviceType = $openLinkElements[index].dataset.serviceType;

            if (_serviceType && _serviceType !== '') {
                $cardContainerToShow = $cardContainerElement[index];

                switch (_serviceType) {
                    case 'MarketRates':
                        getMarketRates();
                        break;
                    case 'MeterReading':
                        getMeterReading();
                        break;
                    case 'EscoComparison':
                        getEscoComparison();
                        break;
                }
            } else {
                $cardContainerElement[index].classList.remove(CONSTANTS.HIDDEN_CLASS);
                $cardContainerElement[index].focus();
            }
            _.each($cardContainerElement, function (containerElement) {
                containerElement.parentNode.classList.remove(CONSTANTS.MANAGE_CARDS_WRAPPER_ACTIVE);
            });
            query.addClass(
                $cardContainerElement[index].parentNode,
                CONSTANTS.MANAGE_CARDS_WRAPPER_ACTIVE
            );

            // Show Close link
            query.addClass($closeCardsSelector, CONSTANTS.HIDDEN_CLASS);
            $openedCard
                .getElementsByClassName(CONSTANTS.CLOSE_CARD_SELECTOR)[0]
                .classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Hide Open Link
            query.removeClass($openLinkElements, CONSTANTS.HIDDEN_CLASS);
            $openLinkClicked.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var openCardEvent = function (event) {
            event.preventDefault();

            openCard(event.target);
        };

        var closeCard = function (event) {
            event.preventDefault();

            // Set index correctly
            for (var index = 0; index < $cardSelector.length; index++) {
                if (query.hasClass($cardSelector[index], CONSTANTS.ACTIVE_CARD_CLASS)) {
                    break;
                }
            }

            // Enable cards
            query.removeClass($cardSelector, CONSTANTS.DISABLED_CARD_CLASS);
            query.removeClass($cardSelector, CONSTANTS.ACTIVE_CARD_CLASS);

            // Hide containers
            query.addClass($cardContainerElement, CONSTANTS.HIDDEN_CLASS);

            // Hide close links
            query.addClass($closeCardsSelector, CONSTANTS.HIDDEN_CLASS);

            // Show open links
            query.removeClass($openLinkElements, CONSTANTS.HIDDEN_CLASS);
            $cardContainerElement[index].parentNode.classList.remove(
                CONSTANTS.MANAGE_CARDS_WRAPPER_ACTIVE
            );

            // Put focus back on card
            $openLinkElements[index].focus();
        };

        var getMarketRates = function () {
            var serviceUrl = $openLinkClicked.dataset.serviceUrl,
                serviceIndicatorValue = $openLinkClicked.dataset.serviceIndicator
                    ? $openLinkClicked.dataset.serviceIndicator
                    : '',
                gasRateValue = $openLinkClicked.dataset.gasRate
                    ? $openLinkClicked.dataset.gasRate
                    : '',
                lightingRateValue = $openLinkClicked.dataset.lightingRate
                    ? $openLinkClicked.dataset.lightingRate
                    : '',
                electricRetailValue = $openLinkClicked.dataset.electricRetailChoice
                    ? $openLinkClicked.dataset.electricRetailChoice
                    : '',
                electricCapacityObValue = $openLinkClicked.dataset.electricCapacityObligation
                    ? $openLinkClicked.dataset.electricCapacityObligation
                    : '',
                electricTransObValue = $openLinkClicked.dataset.electricTransmissionObligation
                    ? $openLinkClicked.dataset.electricTransmissionObligation
                    : '',
                lightingStrataValue = $openLinkClicked.dataset.lightingStrata
                    ? $openLinkClicked.dataset.lightingStrata
                    : '',
                showRatesTableValue = $openLinkClicked.dataset.showRatesTable ? true : false,
                lightingIndicatorValue = $openLinkClicked.dataset.lightingIndicator ? true : false,
                marketRate = {
                    ElectricRate: $openLinkClicked.dataset.electricRate,
                    Zone: $openLinkClicked.dataset.zone,
                    GasRate: gasRateValue,
                    LightingRate: lightingRateValue,
                    ElectricRetailChoice: electricRetailValue,
                    ElectricCapacityObligation: electricCapacityObValue,
                    ElectricTransmissionObligation: electricTransObValue,
                    LightingStrata: lightingStrataValue
                },
                params;

            // Service Data
            params = {
                AccountMaid: $accountMaidInput.value,
                MarketRatesScId: $openLinkClicked.dataset.marketRatesScid,
                IsOru: coned.utils.isOru(),
                MarketRate: marketRate,
                ServiceIndicator: serviceIndicatorValue,
                ShowRatesTable: showRatesTableValue,
                LightingIndicator: lightingIndicatorValue
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successGetMarketRatesService,
                errorGetCardService,
                params,
                true,
                $formLoading
            );
        };

        var successGetMarketRatesService = function (data) {
            if (coned.utils.isPatternLab()) {
                if (coned.utils.isOru()) {
                    var isOruMarketRatesMaui = $cards.dataset.cardsPatternlabMasterLevelAccount,
                        content = isOruMarketRatesMaui ?
                            coned.plConstants.GET_ORU_MARKET_RATES_MAUI_HTML 
                                : coned.plConstants.GET_ORU_MARKET_RATES_HTML;
                    query.getData(
                        content,
                        setCardContainer,
                        errorGetCardService
                    );
                } else {
                    query.getData(
                        coned.plConstants.GET_MARKET_RATES_HTML,
                        setCardContainer,
                        errorGetCardService
                    );
                }
            } else {
                setCardContainer(data);
            }
        };

        var getMeterReading = function () {
            var serviceUrl = $openLinkClicked.dataset.serviceUrl,
                params;

            // Service Data
            params = {
                EnterMeterReadingScId: $openLinkClicked.dataset.meterReadingScid,
                ShowElectricService: $openLinkClicked.dataset.showElectricService,
                ShowGasService: $openLinkClicked.dataset.showGasService,
                ElectricityCurrentRead: $openLinkClicked.dataset.electricityCurrentRead || null,
                GasCurrentRead: $openLinkClicked.dataset.gasCurrentRead || null,
                GasPrevReadDate: $openLinkClicked.dataset.gasPrevReadDate || null,
                ElectricityPrevReadDate: $openLinkClicked.dataset.electricityPrevReadDate || null,
                ElectricityReadingType: $openLinkClicked.dataset.electricityReadingType || null,
                GasReadingType: $openLinkClicked.dataset.gasReadingType || null,
                ElectricityNumberOfDials: $openLinkClicked.dataset.electricityNumberOfDials || null,
                ElectricityReadingLowerBound: $openLinkClicked.dataset.electricityReadingLowerBound || null,
                ElectricityReadingUpperBound: $openLinkClicked.dataset.electricityReadingUpperBound || null,
                GasNumberOfDials: $openLinkClicked.dataset.gasNumberOfDials || null,
                GasReadingLowerBound: $openLinkClicked.dataset.gasReadingLowerBound || null,
                GasReadingUpperBound: $openLinkClicked.dataset.gasReadingUpperBound || null
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successGetMeterReadingService,
                errorGetCardService,
                params,
                true,
                $formLoading
            );
        };

        var successGetMeterReadingService = function (data) {
            if (coned.utils.isPatternLab()) {
                if (coned.utils.isOru()) {
                    query.getData(
                        coned.plConstants.GET_METER_READING_HTML,
                        setCardContainer,
                        errorGetCardService
                    );
                } else {
                    query.getData(
                        coned.plConstants.GET_METER_PREVIOUS_READING_HTML,
                        setCardContainer,
                        errorGetCardService
                    );
                }
            } else {
                setCardContainer(data);
            }
        };

        var getEscoComparison = function () {
            var serviceUrl = $openLinkClicked.dataset.serviceUrl,
                electricEsco,
                gasEsco,
                params;

            // Service Data
            electricEsco = {
                Name: $openLinkClicked.dataset.electricEscoName,
                Phone: $openLinkClicked.dataset.electricEscoPhone,
                Price: $openLinkClicked.dataset.electricEscoCurrentPrice,
                SuppEffectiveDate: $openLinkClicked.dataset.electricEscoEffectiveDate,
                PriceEffectiveDate: $openLinkClicked.dataset.electricPriceEffectiveDate
            };

            gasEsco = {
                Name: $openLinkClicked.dataset.gasEscoName,
                Phone: $openLinkClicked.dataset.gasEscoPhone,
                Price: $openLinkClicked.dataset.gasEscoCurrentPrice,
                SuppEffectiveDate: $openLinkClicked.dataset.gasEscoEffectiveDate,
                PriceEffectiveDate: $openLinkClicked.dataset.gasPriceEffectiveDate
            };

            params = {
                AccountMaid: $accountMaidInput.value,
                EscoComparisonScId: $openLinkClicked.dataset.escoComparisonScid,
                ShowComparisonTable: $openLinkClicked.dataset.showComparisonTable,
                ElectricEsco: electricEsco,
                GasEsco: gasEsco
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successGetEscoComparisonService,
                errorGetCardService,
                params,
                true,
                $formLoading
            );
        };

        var successGetEscoComparisonService = function (data) {
            if (coned.utils.isPatternLab()) {
                if (coned.utils.isOru()) {
                    query.getData(
                        coned.plConstants.GET_ORU_ESCO_COMPARISON_HTML,
                        setCardContainer,
                        errorGetCardService
                    );
                } else {
                    query.getData(
                        coned.plConstants.GET_ESCO_COMPARISON_HTML,
                        setCardContainer,
                        errorGetCardService
                    );
                }
            } else {
                setCardContainer(data);
            }
        };

        var setCardContainer = function (data) {
            $cardContainerToShow.innerHTML = data;

            // Initialize modules
            coned.utils.initializeModules($cardContainerToShow);
            new coned.components.FormValidationModule($cardContainerToShow);

            // Set close functionality to the cancel button
            var $cardCloseButtons = $cardContainerToShow.getElementsByClassName(
                CONSTANTS.CLOSE_BUTTON
            );

            for (var index = 0; index < $cardCloseButtons.length; index++) {
                coned.utils.addGeneralListeners($cardCloseButtons[index], closeCard);
            }

            $cardContainerToShow.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $cardContainerToShow.focus();

            // Since this only has to be loaded once,
            // the serviceUrl will be removed so the service call won't be triggered again
            $openLinkClicked.dataset.serviceType = '';
        };

        var errorGetCardService = function (data) {
            var $errorContainer = document.createElement('p'),
                $errorIcon = document.createElement('span'),
                $errorMessage = document.createElement('span');

            $errorContainer.className = CONSTANTS.ERROR_CONTAINER_CLASS;
            $errorIcon.classList = CONSTANTS.ERROR_ICON_CLASSES;
            $errorMessage.innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;

            $errorContainer.appendChild($errorIcon);
            $errorContainer.appendChild($errorMessage);
            $cardContainerToShow.innerHTML = '';
            $cardContainerToShow.appendChild($errorContainer);
            $cardContainerToShow.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var initializeData = function () {
            $openLinkElements = $cards.getElementsByClassName(CONSTANTS.OPEN_LINK_SELECTOR);
            $cardSelector = $cards.getElementsByClassName(CONSTANTS.CARD_SELECTOR);
            $closeCardsSelector = $cards.getElementsByClassName(CONSTANTS.CLOSE_CARD_SELECTOR);
            $cardContainerElement = $cards.getElementsByClassName(
                CONSTANTS.CARD_CONTAINER_SELECTOR
            );
            $closeButtons = $cards.getElementsByClassName(CONSTANTS.CLOSE_BUTTON);
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
        };

        var initializeEvents = function () {
            var params = coned.utils.getUrlParameters() || {},
                deepLinkSectionId = params[coned.constants.DEEP_LINK_URL_PARAM_NAME],
                $cardElementWithSectionId = _.where($cardSelector, function ($item) {
                    return $item.dataset.deepLinkId === deepLinkSectionId;
                });

            for (var index = 0; index < $openLinkElements.length; index++) {
                coned.utils.addGeneralListeners($openLinkElements[index], openCardEvent);
            }

            for (index = 0; index < $closeCardsSelector.length; index++) {
                coned.utils.addGeneralListeners($closeCardsSelector[index], closeCard);
            }

            for (index = 0; index < $closeButtons.length; index++) {
                coned.utils.addGeneralListeners($closeButtons[index], closeCard);
            }

            // The event is unsubscribed if the deep link section id is not a card
            _.each($cardElementWithSectionId, function ($element) {
                $element.addEventListener('deeplink-event', function (event) {
                    var $target = event.target.getElementsByClassName(
                        CONSTANTS.CARD_BUTTON_SPAN_CLASS
                    )[0];

                    openCard($target);
                });
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CardsClosed.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CardsClosed;
})();
