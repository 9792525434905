// ==================== FILTERTABLE =========================

var coned = coned || {};

coned.components = coned.components || {};
/**
 * @returns the init function to start the module
 */
coned.components.FilterTable = (function () {
    /**
     * @typedef {Object} ROW
     * @property {string} value
     * @property {Record<string, string>[]} data
     */

    /**
     * @typedef {Object} FilterState
     * @property {string} name
     * @property {string[]} value
     */

    /**
     * @typedef {Object} FilterGroupState
     * @property {FilterState[]} filters
     * @property {number} count
     * @property {boolean} isLoadedData
     */

    /**
     * @typedef {Object} FilterTableState
     * @property {FilterGroupState | null} filterGroup
     * @property {ROW[]} rows
     * @property {ROW[]} rowsFiltered
     * @property {boolean} isLoadedData
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        SELECTORS: {
            /**
             * @type {'js-filter-group'}
             */
            FILTER_GROUP: 'js-filter-group',

            /**
             * @type {'table tbody > tr'}
             */
            ROWS: 'table tbody > tr',

            /**
             * @type {'table tbody > tr[data-value="no-results"]'}
             */
            DEFAULT_ROW: 'table tbody > tr[data-value="no-results"]'
        },
        ATTRIBUTES: {
            /**
             * @type {'data-columns'}
             */
            DATA_COLUMNS: 'data-columns',
            /**
             * @type {'data-value'}
             */
            DATA_VALUE: 'data-value'
        },
        /**
         * @type {'no-results'}
         */
        DEFAULT_ROW_DATA_VALUE: 'no-results',
        /**
         * @type {Number}
         */
        MAX_TIMEOUT_CALLBACKS: 10
    };
    var isLoaded = false;
    /**
     * Constructor
     * @param { HTMLDivElement } $filterTable
     * @returns {}
     */
    var FilterTable = function ($filterTable) {
        /**
         * PRIVATE VARIABLES
         */
        /**
         * @type {FilterTableState}
         */
        var _state,
            /**
             * @type {HTMLElement}
             */
            $filterGroup,
            /**
             * @type {HTMLElement[]}
             */
            $rows,
            /**
             * @type {HTMLElement}
             */
            $defaultRow,
            /**
             * @type {Number}
             */
            countTimeOutsToLoad;

        /**
         * PRIVATE METHODS
         */

        /**
         * create rows data
         * @param {string} columns
         * @param {string} value
         * @returns {ROW | null}
         */
        var createStateRows = function (columns, value) {
            var columnNames = columns.split(', ').map(function (col) { return col.trim(); }),
                dataValues = value.split(', ');
            /**
             * @type {Record<string, string>[]}
             */
            var data = dataValues.reduce(function (acc, dataValue, index) {
                acc[columnNames[index]] = dataValue;
                return acc;
            }, {});
            return { value: value, data: data };
        }
        
        /**
         * Update Rows Html
         */
        var setRowsSelectedHTML = function () {
            if (_state.rowsFiltered.length === _state.rows.length) {
                $defaultRow.setAttribute(coned.constants.ARIA.HIDDEN, coned.constants.TRUE);
                $rows.forEach(function($el){
                    $el.setAttribute(coned.constants.ARIA.HIDDEN, coned.constants.FALSE);                    
                })
            } else if (_state.rowsFiltered.length === 0) {
                $defaultRow.setAttribute(coned.constants.ARIA.HIDDEN, coned.constants.FALSE);
                $rows.forEach(function ($el) {
                    $el.setAttribute(coned.constants.ARIA.HIDDEN, coned.constants.TRUE);
                });
            } else {
                $defaultRow.setAttribute(coned.constants.ARIA.HIDDEN, coned.constants.TRUE);
                var valuesToShow = _state.rowsFiltered.map(function (el) { return el.value; });
                $rows.forEach(function ($el) {
                    var value = $el.dataset.value;
                    if (!value) {
                        $el.setAttribute(coned.constants.ARIA.HIDDEN, coned.constants.TRUE);
                    }
                    else if (value && !valuesToShow.includes(value)) {
                        $el.setAttribute(coned.constants.ARIA.HIDDEN, coned.constants.TRUE);
                    } else {
                        $el.setAttribute(coned.constants.ARIA.HIDDEN, coned.constants.FALSE);
                    }
                });
            }
        }
        /**
         * Update state
         * @param {FilterGroupState} filterGroupState 
         */
        var setFilterGroupState = function (filterGroupState) {
            _state.filterGroup = filterGroupState;
            var newRowsFiltered = _state.rows.concat(),
                columnNameValues = filterGroupState.filters
                    .filter(function (el) { return el.value.length > 0 })
                    .map(function (el) {
                        return {
                            name: el.name,
                            values: el.value.join(',')
                        };
                    });
            if (columnNameValues.length === 0) {
                _state.rowsFiltered = newRowsFiltered.concat();
            } else {
                while (columnNameValues.length > 0) {
                    var nameValues = columnNameValues.shift();
                    newRowsFiltered = newRowsFiltered.filter(function (row) {
                        return nameValues.values.includes(row.data[nameValues.name]);
                    });
                }
            }
            _state.rowsFiltered = newRowsFiltered.concat();
            setRowsSelectedHTML();
            if(_state.isLoadedData === true){
                coned.utils.triggerEvent(
                    $filterTable,
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    _state
                );
            }
            
        }
        /**
         * Listen FilterGroup change
         * @param {CustomEvent<FilterGroupState>} event 
         */
        var handleFilterGroupChange = function (event) {
            setFilterGroupState(event.detail);
        }
        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            _state = {
                filterGroup: null,
                rows: [],
                rowsFiltered: [],
                isLoadedData: false
            };
            $filterGroup = $filterTable.getElementsByClassName(CONSTANTS.SELECTORS.FILTER_GROUP)[0];
            $defaultRow = $filterTable.querySelector(CONSTANTS.SELECTORS.DEFAULT_ROW);
            $rows = [];
            $filterTable.querySelectorAll(CONSTANTS.SELECTORS.ROWS).forEach(function ($el) {
                if ($el.getAttribute(CONSTANTS.ATTRIBUTES.DATA_VALUE) !== CONSTANTS.DEFAULT_ROW_DATA_VALUE) {
                    $rows.push($el);
                    var columns = $el.getAttribute(CONSTANTS.ATTRIBUTES.DATA_COLUMNS),
                        data = $el.getAttribute(CONSTANTS.ATTRIBUTES.DATA_VALUE);
                    if (columns, data) {
                        var row = createStateRows(columns, data);
                        if (row) {
                            _state.rows.push(row);
                            _state.rowsFiltered.push(row);
                        }
                    }
                }
            });
        }
        /**
         * Initialize the events in the module
         */
        var initializeEvents = function () {
            $filterGroup.addEventListener(
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                handleFilterGroupChange
            );
        }
        /**
         * Get first state filter group
         */
        var getFirstStateFilterGroup = function () {
            countTimeOutsToLoad += 1;
            if (
                !_state.filterGroup &&
                countTimeOutsToLoad < CONSTANTS.MAX_TIMEOUT_CALLBACKS
            ) {
                setTimeout(getFirstStateFilterGroup, 100)
            } else {
                _state.isLoadedData = true;
                coned.utils.triggerEvent(
                    $filterTable,
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    _state
                );
            }
        }
        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            getFirstStateFilterGroup();
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    FilterTable.prototype.isLoaded = function () {
        return isLoaded;
    };
    return FilterTable;
})();