// ==================== OPTOUT UNAUTHENTICATED FORM COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.OptOutUnauthenticated = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */

    var CONSTANTS = {
        REQUIRED_COMMENT_SELECTOR: 'js-required-comment',
        NO_REQUIRED_COMMENT_SELECTOR: 'js-no-required-comment',
        COMMENT_SECTION: 'js-optout-comments',
        SUBMIT_BUTTON: 'js-submit-button',
        COMMENT_FIELD_CLASS: 'coned-form__field-container--comment',
        TEXT_AREA_FILLED_CLASS: 'coned-textarea--filled',
        INPUT_ERROR_CLASS: 'coned-input-message--error',
        FIELD_ERROR_CLASS: 'coned-field-error-wrapper',
        REQUIRED: 'required',
        DISABLED: 'disabled',
        FORM_REASON: 'reason',
        MONEY_OPTION: 'money',
        HOURS_OPTION: 'hours',
        TAGGING_MONEY: 'Save Money',
        TAGGING_HOURS: 'Hours',
        TAGGING_OTHER: 'Other'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var OptOutUnauthenticated = function ($selectMenu) {
        /**
         * PRIVATE METHODS
         */
        var $selectElement,
            $commentsElement,
            $commentContainer,
            $moneyOption,
            $hoursOption,
            $submitButtom;

        var commentRequired = function () {
            $commentsElement.setAttribute(CONSTANTS.REQUIRED, '');
            $commentsElement.removeAttribute(CONSTANTS.DISABLED);
        };

        var noCommentRequired = function () {
            $commentsElement.removeAttribute(CONSTANTS.REQUIRED);
            $commentsElement.setAttribute(CONSTANTS.DISABLED, '');
            $commentsElement.value = '';
            query.removeClass($commentsElement, CONSTANTS.TEXT_AREA_FILLED_CLASS);

            // clear error message in comment field
            $commentsElement.classList.remove(CONSTANTS.INPUT_ERROR_CLASS);
            var $errorInputMessage = $commentContainer.getElementsByClassName(
                CONSTANTS.FIELD_ERROR_CLASS
            )[0];
            if ($errorInputMessage) {
                $errorInputMessage.style.display = 'none';
            }
        };

        var addSubmitTagging = function () {
            var reasonTag,
                reasonValue = query.getFormInputValue($selectMenu, CONSTANTS.FORM_REASON);

            if (reasonValue.includes(CONSTANTS.MONEY_OPTION)) {
                reasonTag = CONSTANTS.TAGGING_MONEY;
            } else if (reasonValue.includes(CONSTANTS.HOURS_OPTION)) {
                reasonTag = CONSTANTS.TAGGING_HOURS;
            } else {
                reasonTag = CONSTANTS.TAGGING_OTHER;
            }

            dataLayer.push({
                event: 'ipp.optout.submit',
                optoutReason: reasonTag
            });
        };

        var initializeData = function () {
            $selectElement = $selectMenu.getElementsByClassName(
                CONSTANTS.REQUIRED_COMMENT_SELECTOR
            )[0];
            $moneyOption = $selectMenu.getElementsByClassName(
                CONSTANTS.NO_REQUIRED_COMMENT_SELECTOR
            )[0];
            $hoursOption = $selectMenu.getElementsByClassName(
                CONSTANTS.NO_REQUIRED_COMMENT_SELECTOR
            )[1];
            $commentsElement = $selectMenu.getElementsByClassName(CONSTANTS.COMMENT_SECTION)[0];
            $submitButtom = $selectMenu.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $commentContainer = $selectMenu.getElementsByClassName(
                CONSTANTS.COMMENT_FIELD_CLASS
            )[0];
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($selectElement, commentRequired);
            coned.utils.addGeneralListeners($moneyOption, noCommentRequired);
            coned.utils.addGeneralListeners($hoursOption, noCommentRequired);
            if (!coned.utils.isOru()) {
                coned.utils.addGeneralListeners($submitButtom, addSubmitTagging);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    OptOutUnauthenticated.prototype.isLoaded = function () {
        return isLoaded;
    };

    return OptOutUnauthenticated;
})();
