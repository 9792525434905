// ==================== THIRD PARTY ACCESS CONFIRMATION COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ThirdPartyAccessConfirmation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        MAIN_CONFIRMATION_BANNER: 'js-main-confirmation',
        ACCESS_CONFIRMATION_BANNER: 'js-accept-confirmation',
        GRANTED_ACCESS_BANNER: 'js-granted-access',
        DENIED_ACCESS_BANNER: 'js-denied-access',
        EXPIRED_LINK_BANNER: 'js-expired-link',
        ALREADY_COMPLETED_BANNER: 'js-already-completed',

        ALLOW_CONFIRMATION_BUTTON: 'js-allow-confirmation',
        ALLOW_ACCESS_BUTTON: 'js-allow-access',
        DENY_ACCESS_BUTTON: 'js-deny-access',
        ALLOW_VALUE: 'allow',
        DENY_VALUE: 'deny',

        TAGGING_GENERAL_PREFFIX: 'Pilot.Reg.3P.Acct.',
        TAGGING_ALLOW_ACCESS: 'Step02.Allow',
        TAGGING_DENY_ACCESS: 'Step02.Deny',
        TAGGING_LINK_EXPIRED: 'Step02.Link.Expired',
        TAGGING_CONFIRM_ALLOW_ACCESS: 'Step03.Confirm.Allow',
        TAGGING_CONFIRM_DENY_ACCESS: 'Step03.Confirm.Deny',
        TAGGING_ACCESS_GRANTED: 'Step04.Access.Granted.Page.Load',
        TAGGING_ACCESS_DENIED: 'Step04.Access.Denied.Page.Load',
        TAGGING_ALREADY_COMPLETED: 'Step04.Already.Completed.Page.Load',

        CONFIRMATION_SCID: 'ScId',
        CONFIRMATION_REQUEST_ID: 'RequestId',
        CONFIRMATION_TOKEN: 'Token',

        SERVICE_ERROR: 'js-service-error',
        FORM_LOADING: 'js-form-loading',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ThirdPartyAccessConfirmation = function ($thirdPartyAccessConfirmation) {
        /**
         * PRIVATE METHODS
         */
        var $mainConfirmationBanner,
            $accessConfirmationBanner,
            $grantedAccessBanner,
            $deniedAccessBanner,
            $expiredLinkBanner,
            $alreadyCompletedBanner,
            $allowConfirmationButton,
            $allowAccessButton,
            $denyAccessButton,
            $formLoading,
            $serviceError,
            _allowAccess;

        var confirmationSubmit = function (event) {
            event.preventDefault();

            $mainConfirmationBanner.classList.add(CONSTANTS.HIDDEN_CLASS);
            $accessConfirmationBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var accessSubmit = function (event) {
            event.preventDefault();

            var serviceUrl = $thirdPartyAccessConfirmation.dataset.confirmServiceUrl,
                params;

            _allowAccess =
                event.currentTarget.dataset.value === CONSTANTS.ALLOW_VALUE ? true : false;

            params = {
                ScId: query.getFormInputValue(
                    $thirdPartyAccessConfirmation,
                    CONSTANTS.CONFIRMATION_SCID
                ),
                RequestId: query.getFormInputValue(
                    $thirdPartyAccessConfirmation,
                    CONSTANTS.CONFIRMATION_REQUEST_ID
                ),
                VerificationToken: query.getFormInputValue(
                    $thirdPartyAccessConfirmation,
                    CONSTANTS.CONFIRMATION_TOKEN
                ),
                AllowAccess: _allowAccess
            };

            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successAccessSubmit,
                errorAccessSubmit,
                params,
                true,
                $formLoading
            );
        };

        var successAccessSubmit = function () {
            $accessConfirmationBanner.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mainConfirmationBanner.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (_allowAccess) {
                $grantedAccessBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);

                // Tagging
                dataLayer.push({
                    event: CONSTANTS.TAGGING_GENERAL_PREFFIX + CONSTANTS.TAGGING_ACCESS_GRANTED
                });
            } else {
                $deniedAccessBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);

                // Tagging
                dataLayer.push({
                    event: CONSTANTS.TAGGING_GENERAL_PREFFIX + CONSTANTS.TAGGING_ACCESS_DENIED
                });
            }
        };

        var errorAccessSubmit = function (data) {
            if (data && data.errorMsg) {
                $serviceError.innerHTML = data.errorMsg;
            } else {
                $serviceError.innerHTML = $serviceError.dataset.defaultMsg;
            }

            query.removeClass($serviceError, CONSTANTS.HIDDEN_CLASS);
            $serviceError.focus();
        };

        var initializeData = function () {
            $mainConfirmationBanner = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.MAIN_CONFIRMATION_BANNER
            )[0];
            $accessConfirmationBanner = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.ACCESS_CONFIRMATION_BANNER
            )[0];
            $grantedAccessBanner = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.GRANTED_ACCESS_BANNER
            )[0];
            $deniedAccessBanner = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.DENIED_ACCESS_BANNER
            )[0];
            $expiredLinkBanner = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.EXPIRED_LINK_BANNER
            )[0];
            $alreadyCompletedBanner = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.ALREADY_COMPLETED_BANNER
            )[0];

            $allowConfirmationButton = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.ALLOW_CONFIRMATION_BUTTON
            )[0];
            $allowAccessButton = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.ALLOW_ACCESS_BUTTON
            )[0];
            $denyAccessButton = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.DENY_ACCESS_BUTTON
            );

            $formLoading = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.FORM_LOADING
            )[0];
            $serviceError = $thirdPartyAccessConfirmation.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR
            );
        };

        var initializeEvents = function () {
            if ($expiredLinkBanner) {
                dataLayer.push({
                    event: CONSTANTS.TAGGING_GENERAL_PREFFIX + CONSTANTS.TAGGING_LINK_EXPIRED
                });
            } else if ($alreadyCompletedBanner) {
                dataLayer.push({
                    event: CONSTANTS.TAGGING_GENERAL_PREFFIX + CONSTANTS.TAGGING_ALREADY_COMPLETED
                });
            } else {
                coned.utils.addGeneralListeners($allowConfirmationButton, confirmationSubmit);
                coned.utils.addGeneralListeners($allowAccessButton, accessSubmit);

                _.each($denyAccessButton, function ($button) {
                    coned.utils.addGeneralListeners($button, accessSubmit);
                });

                // Tagging
                coned.utils.addGeneralListeners($allowConfirmationButton, function () {
                    dataLayer.push({
                        event: CONSTANTS.TAGGING_GENERAL_PREFFIX + CONSTANTS.TAGGING_ALLOW_ACCESS
                    });
                });

                coned.utils.addGeneralListeners($allowAccessButton, function () {
                    dataLayer.push({
                        event:
                            CONSTANTS.TAGGING_GENERAL_PREFFIX +
                            CONSTANTS.TAGGING_CONFIRM_ALLOW_ACCESS
                    });
                });

                coned.utils.addGeneralListeners(
                    $mainConfirmationBanner.getElementsByClassName(CONSTANTS.DENY_ACCESS_BUTTON)[0],
                    function () {
                        dataLayer.push({
                            event: CONSTANTS.TAGGING_GENERAL_PREFFIX + CONSTANTS.TAGGING_DENY_ACCESS
                        });
                    }
                );

                coned.utils.addGeneralListeners(
                    $accessConfirmationBanner.getElementsByClassName(
                        CONSTANTS.DENY_ACCESS_BUTTON
                    )[0],
                    function () {
                        dataLayer.push({
                            event:
                                CONSTANTS.TAGGING_GENERAL_PREFFIX +
                                CONSTANTS.TAGGING_CONFIRM_DENY_ACCESS
                        });
                    }
                );
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ThirdPartyAccessConfirmation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ThirdPartyAccessConfirmation;
})();
