// ==================== LOAD MORE BILL PAYMENT COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoadMoreBillPayment = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SHOW_MORE_CLASS: 'js-show-more',
        ITEM_CLASS: 'js-item',
        HIDDEN_CLASS: 'hidden',
        BILL_LINK_SELECTOR: 'js-bill-link',
        PAYMENT_ITEM_SELECTOR: 'js-payment-item',
        BILL_ITEM_SELECTOR: 'js-bill-item',
        PAYMENT_HISTORY_WRAPPER_SELECTOR: 'js-billing-payment-history-wrapper',
        DATA_CELL: 'js-data-cell',
        DATA_GRID_MODULE: 'js-data-grid',
        LOAD_MORE: 'js-load-more-selector',
        SHOW_MORE_GRID_FOCUS_EVENT: 'show-more-grid-focus'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var LoadMoreBillPayment = function ($LoadMoreBillPayment) {
        /**
         * PRIVATE METHODS
         */
        var $showMore,
            $items,
            $selectedItems,
            $loadMore,
            _singleOptionLoadMoreCount,
            _multipleOptionLoadMoreCount,
            _loadMoreAmount;

        var showMoreClickEvent = function (event) {
            event.stopPropagation();
            event.preventDefault();

            var hiddenItems = [],
                filterCriteria = $LoadMoreBillPayment.dataset.filterCriteria;

            $selectedItems = $LoadMoreBillPayment.getElementsByClassName(filterCriteria);

            //If both options are selected rule is to show the double of the items
            if (filterCriteria === CONSTANTS.ITEM_CLASS) {
                _loadMoreAmount = _multipleOptionLoadMoreCount;
            } else {
                _loadMoreAmount = _singleOptionLoadMoreCount;
            }

            _.each($selectedItems, function ($selectedItem) {
                if (query.hasClass($selectedItem, CONSTANTS.HIDDEN_CLASS)) {
                    hiddenItems.push($selectedItem);
                }
            });
            
            // If requested index end exceeds the items length, the load more button is hidden
            if (hiddenItems.length <= _loadMoreAmount) {
                query.addClass($showMore, CONSTANTS.HIDDEN_CLASS);
            }
            
            showMoreItems(hiddenItems, filterCriteria);
        };

        /**
         * Shows more items based on load amount and moves focus appropriately
         */
        var showMoreItems = function (hiddenItems) {            
            _.each(hiddenItems, function ($hideItem, index) {
                var $focusLink;

                if (index < _loadMoreAmount) {
                    $hideItem.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    
                    if (index === 0) {
                        $focusLink = $hideItem.getElementsByClassName(CONSTANTS.DATA_CELL)[0];

                        if ($focusLink) {
                            $focusLink.focus();
                            // Trigger focus event so data grid module can keep track of current focused element
                            coned.utils.triggerEvent($loadMore, CONSTANTS.SHOW_MORE_GRID_FOCUS_EVENT, $focusLink);
                        }
                    }
                }
            });
        };

        var initializeData = function () {
            $showMore = $LoadMoreBillPayment.getElementsByClassName(CONSTANTS.SHOW_MORE_CLASS)[0];
            $items = $LoadMoreBillPayment.getElementsByClassName(CONSTANTS.ITEM_CLASS);
            $loadMore = document.getElementsByClassName(CONSTANTS.LOAD_MORE)[0];
            _singleOptionLoadMoreCount = parseInt($LoadMoreBillPayment.dataset.loadMore, 10);
            _multipleOptionLoadMoreCount = _singleOptionLoadMoreCount * 2;
            _loadMoreAmount = _singleOptionLoadMoreCount;
        };

        var initializeEvents = function () {
            // Render initial amount of items
            if ($items) {
                coned.utils.addGeneralListeners($showMore, showMoreClickEvent);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };
        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoadMoreBillPayment.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LoadMoreBillPayment;
})();
