// ==================== CALCULATOR FORM ACTION COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CalculatorFormAction = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SELECT_CLASS: 'js-coned-select'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var CalculatorFormAction = function ($element) {
        /**
         * PRIVATE METHODS
         */
        var $selectedClass;

        var redirectLink = function ($option) {
            window.location.href = $option.value;
        };

        var resetFormOnLoad = function () {
            _.each($selectedClass, function (select) {
                select.value = '';
            });
        };

        var initializeData = function () {
            $selectedClass = $element.getElementsByClassName(CONSTANTS.SELECT_CLASS);
        };

        var initializeEvents = function () {
            _.each($selectedClass, function ($select) {
                $select.addEventListener('change', function () {
                    redirectLink($select);
                });
            });

            resetFormOnLoad();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CalculatorFormAction.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CalculatorFormAction;
})();
