// ==================== DASHBOARD LIFE SAVING EQUIPMENT COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SubMeteringCalculator = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */

     var CONSTANTS = {
        FORM: 'js-sub-metering-calculator-form',
        CLEAR_BTN_CLASS: 'js-reset-button',
        SM_ZIPCODE: 'js-sm-zipcode',
        SM_START_DATE: 'js-sm-start-date',
        SM_END_DATE: 'js-sm-end-date',
        SM_RESULT_TABLE_DESCRIPTION: 'js-result-table-description',
        SM_RESULT_TABLE: 'js-sm-result',
        SM_RESULT_ELECTRICITY_CHARGES: 'js-sm-electricity-charges',
        SM_RESULT_OTHER_FEES: 'js-sm-other-fees',
        SM_RESULT_TOTAL_CHARGES: 'js-sm-total-charges',
        SM_SERVICE_ERROR : 'js-sm-service-error ',
        FORM_SITECORE_ID: 'scId',
        FORM_ZIPCODE: 'smZipcode',
        FORM_START_DATE: 'smStartDate',
        FORM_END_DATE: 'smEndDate',
        FORM_CONSUMPTION: 'smConsumption',
        FORM_LOADING: 'js-form-loading',
        ERROR_MESSAGE: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        RECAPTCHA_ELEMENT: 'js-recaptcha',
        HEADER: 'js-header-wrapper'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var SubMeteringCalculator = function ($subMeteringCalculator) {

        var $subMeteringCalculatorForm,
            $clearBtn,
            $smZipcode,
            $smStartDate,
            $smEndDate,
            $smResultTableDescription,
            $smResultTable,
            $smResultElectricityCharges,
            $smResultOtherFees,
            $smResultTotalCharges,
            $smErrorMessage,
            $formLoading,
            $header,
            recaptcha,
            _hasCaptcha;

        /**
         * PRIVATE METHODS
         */
         var resetCalculator = function () {
            $smErrorMessage.classList.add(CONSTANTS.HIDDEN_CLASS);
            $smResultTableDescription.classList.add(CONSTANTS.HIDDEN_CLASS);
            $smResultTable.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (_hasCaptcha && recaptcha) {
                recaptcha.reset();
            }

            //Set the min and max date on the initial ones
            $smEndDate.dataset.minDateDays = $subMeteringCalculator.dataset.calendarsMinDate;
            $smEndDate.dataset.maxDateDays = $subMeteringCalculator.dataset.calendarsMaxDate;
            $($smEndDate).datepicker('option', 'minDate',  coned.utils.dateFormat($smEndDate.dataset.minDateDays));
            $($smEndDate).datepicker('option', 'maxDate',  coned.utils.dateFormat($smEndDate.dataset.maxDateDays));

            $smStartDate.dataset.minDateDays = $subMeteringCalculator.dataset.calendarsMinDate;
            $smStartDate.dataset.maxDateDays = $subMeteringCalculator.dataset.calendarsMaxDate;
            $($smStartDate).datepicker('option', 'minDate',  coned.utils.dateFormat($smStartDate.dataset.minDateDays));           
            $($smStartDate).datepicker('option', 'maxDate',  coned.utils.dateFormat($smStartDate.dataset.maxDateDays));

            $smZipcode.focus();
            query.scrollToElement($smZipcode, $header);
        };

        var setEndDateLimit = function () {
            var endDate = new Date($smStartDate.value),
                today = new Date(),
                //Value used on datepicker validation too, so it needs to match
                endDateRangeDays = $smEndDate.dataset.ruleRangedays,
                endDateLimit = endDateRangeDays.split(',')[2];

            //Add number of days or today to apply the rule. Subtract -1 for selected day to be included.
            endDate.setDate(endDate.getDate() + (parseInt(endDateLimit, 10) - 1));

            if(endDate > today) {
                endDate = today;
            }

            //Set the max date for the end date
            $smEndDate.dataset.maxDateDays = endDate.toString();
            $($smEndDate).datepicker('option', 'maxDate',  coned.utils.dateFormat($smEndDate.dataset.maxDateDays));
        
            //Set date to be greater than start date
            $smEndDate.dataset.minDateDays = new Date($smStartDate.value).toString();
            $($smEndDate).datepicker('option', 'minDate',  coned.utils.dateFormat($smEndDate.dataset.minDateDays));
        };

        var setStartDateLimit = function () {
            var startDate = new Date($smEndDate.value),
                //Value used on datepicker validation too, so it needs to match
                startDateRangeDays = $smStartDate.dataset.ruleRangedays,
                startDateLimit = startDateRangeDays.split(',')[2];

            //Remove number of days to apply the rule.
            startDate.setDate(startDate.getDate() - parseInt(startDateLimit, 10));
            //Set the start date to apply the rule
            $smStartDate.dataset.minDateDays = startDate.toString();
            //$($smStartDate).datepicker('option', 'minDate',  coned.utils.dateFormat($smStartDate.dataset.minDateDays));
        };

        var calculateRate = function() {
            var serviceUrl = $subMeteringCalculator.dataset.serviceUrl,
                params,
                verify,
                formData;

            //Hide Error
            $smErrorMessage.classList.add(CONSTANTS.HIDDEN_CLASS);

            //Hide Result
            $smResultTable.classList.add(CONSTANTS.HIDDEN_CLASS);

            //Get verify for unauthenticated user
            if (_hasCaptcha && recaptcha) {
                verify = recaptcha.getResponse();
            }else {
                verify = '';
            }

            // Service Data
            params = {
                scId: query.getFormInputValue(document, CONSTANTS.FORM_SITECORE_ID),
                postalCode: query.getFormInputValue($subMeteringCalculatorForm, CONSTANTS.FORM_ZIPCODE),
                fromDate: coned.utils.serviceDateFormat(query.getInputValue($subMeteringCalculatorForm, CONSTANTS.FORM_START_DATE)),
                toDate: coned.utils.serviceDateFormat(query.getInputValue($subMeteringCalculatorForm, CONSTANTS.FORM_END_DATE)),
                consumption: query.getFormInputValue($subMeteringCalculatorForm, CONSTANTS.FORM_CONSUMPTION),
                verify: verify
            };

            // Serialize JSON into string to append to FormData
            formData = JSON.stringify(params);

            // Service Call
            query.postData(
                serviceUrl,
                successCalculateChargesService,
                errorCalculateChargesService,
                formData,
                true,
                $formLoading
            );
            
        };


        var successCalculateChargesService = function (data) {
            if (coned.utils.isPatternLab()) {
                data = query.getData(coned.plConstants.GET_SM_CALCULATOR_CALCULATE, setResultTableValues, function () {});
            }else {
                setResultTableValues(data);
            }  
        };

        var setResultTableValues = function (data) {
            //Fill result table values
            $smResultElectricityCharges.innerText = data.ElectricCharges;
            $smResultOtherFees.innerText = data.OtherFees;
            $smResultTotalCharges.innerText = data.TotalCharges;

            $smResultTableDescription.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $smResultTable.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $smResultTable.focus();
        };

        var errorCalculateChargesService = function (data) {
            if (coned.utils.isPatternLab()) {
                data = query.getData(coned.plConstants.GET_SM_CALCULATOR_SERVICES_ERROR, setErrorMessage, function () {});
            }else {
                setErrorMessage(data);
            }            
        };

        var setErrorMessage = function(data) {
            var $msgText = $smErrorMessage.getElementsByClassName(CONSTANTS.ERROR_MESSAGE)[0];
            $msgText.innerText = data.errorMsg && data.errorMsg !== '' ? data.errorMsg : $msgText.innerText;
            $smErrorMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $smErrorMessage.focus();
        };

        /**
         *  Check Recaptcha
         **/
         var checkRecaptcha = function () {
            $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
            if (_hasCaptcha && recaptcha) {
                if(recaptcha.getResponse() !== '') {
                    recaptcha.reset();
                }
                recaptcha.checkRecaptcha();
                
            }
        };

        var initializeData = function () {
            $subMeteringCalculatorForm = $subMeteringCalculator.getElementsByClassName(CONSTANTS.FORM)[0];
            $clearBtn = $subMeteringCalculator.getElementsByClassName(CONSTANTS.CLEAR_BTN_CLASS)[0];
            $smZipcode = $subMeteringCalculatorForm.getElementsByClassName(CONSTANTS.SM_ZIPCODE)[0];
            $smStartDate = $subMeteringCalculatorForm.getElementsByClassName(CONSTANTS.SM_START_DATE)[0];
            $smEndDate = $subMeteringCalculatorForm.getElementsByClassName(CONSTANTS.SM_END_DATE)[0];
            $smResultTableDescription = $subMeteringCalculator.getElementsByClassName(CONSTANTS.SM_RESULT_TABLE_DESCRIPTION)[0];
            $smResultTable = $subMeteringCalculator.getElementsByClassName(CONSTANTS.SM_RESULT_TABLE)[0];
            $smResultElectricityCharges = $subMeteringCalculator.getElementsByClassName(CONSTANTS.SM_RESULT_ELECTRICITY_CHARGES)[0];
            $smResultOtherFees = $subMeteringCalculator.getElementsByClassName(CONSTANTS.SM_RESULT_OTHER_FEES)[0];
            $smResultTotalCharges = $subMeteringCalculator.getElementsByClassName(CONSTANTS.SM_RESULT_TOTAL_CHARGES)[0];
            $smErrorMessage = $subMeteringCalculator.getElementsByClassName(CONSTANTS.SM_SERVICE_ERROR)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];
            recaptcha = false;
            _hasCaptcha = $subMeteringCalculator.getElementsByClassName(CONSTANTS.RECAPTCHA_ELEMENT)[0];
        };

        var initializeEvents = function () {

            if (_hasCaptcha) {
                new coned.components.ValidateForm($subMeteringCalculatorForm, checkRecaptcha);
                recaptcha = new coned.components.Recaptcha($subMeteringCalculator, calculateRate, calculateRate);
            }else {
                new coned.components.ValidateForm($subMeteringCalculatorForm, calculateRate);
            }

            //Check difference between start and end  can not be more than 34 days 
            $smStartDate.addEventListener('dateSelected', setEndDateLimit);

           //Check difference between start and end  can not be more than 34 days
            $smEndDate.addEventListener('dateSelected', setStartDateLimit);
            
            coned.utils.addGeneralListeners($clearBtn, resetCalculator);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        }

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     SubMeteringCalculator.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SubMeteringCalculator;
})();
