// ==================== ORU GENERAL CLAIM FORM COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.OruClaimForm = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Selector Classes
        PAGE_HEADER: 'js-page-header',
        LOCATION_ADDRESS_CONTAINER: 'js-location-address',
        ORU_CLAIM_FORM_CONTAINER: 'js-oru-account-number-container',
        TYPE_OF_INCIDENT_EXPLAIN: 'js-toi-explain',
        WEATHER_EXPLAIN: 'js-weather-explain',
        SUBMIT_POPUP_BUTTON: 'js-submit-popup',
        CLOSE_POPUP_BUTTON: 'js-close-popup',
        UPLOAD_FILE_INPUT: 'js-file-upload-input',
        ERROR_UPLOAD_FILE_INPUT: 'coned-field-error-wrapper',
        UPLOAD_FILE_LIST: 'js-file-list',
        POPUP_CLAIM_FORM: 'js-popup-claim-form',
        REQUIRED_FIELD: 'required',
        DATE: 'js-date',
        NO_PADDING_TOP: 'coned-form__field-container--description-no-padding-top',
        FORM_WRAPPER_NO_BACKGROUND_CLASS: 'coned-form-wrapper--no-background',
        SUCCESS_MESSAGE: 'js-success-message',
        SUCCESS_MESSAGE_FOCUS: 'js-success-message-focus',
        SUCCESS_ALT_MESSAGE: 'js-success-alt-message',
        SUCCESS_ALT_MESSAGE_FOCUS: 'js-success-alt-message-focus',
        ERROR_MESSAGE: 'js-error-message',
        ERROR_MESSAGE_FOCUS: 'js-error-message-focus',
        RECAPTCHA_ELEMENT: 'js-recaptcha',
        FORM_WRAPPER: 'js-form-wrapper',
        MESSAGE_WRAPPER: 'js-form-messages',
        FORM_LOADING: 'js-form-loading',
        INCIDENT_RELATED: 'js-incident-related',
        TRY_AGAIN: 'js-try-again',
        FILE_UPLOAD_INPUT: 'js-coned-inputfile',
        IS_INVOLVED_CONTRACTOR: 'js-oru-is-involved-contractor',
        ORU_CONTACT_CHECKBOXES: 'js-oru-contact-radio',
        ORU_CONTACT_EXPLAIN: 'js-oru-contact-explain',
        POLICE_RADIOS: 'js-police-radio',
        POLICE_MESSAGE: 'js-police-message',
        INSURANCE_RADIOS: 'js-insurance-radio',
        INSURANCE_INFO: 'js-insurance-info',
        INSURANCE_EXPLAIN: 'js-insurance-explain',
        RESET_BUTTON: 'js-reset-button',
        FORM_INPUT_QUERY: 'input:not([type="hidden"]):not(.hidden)',
        INCIDENT_MOTOR_VEHICLE_CHECKBOX_ID: 'motor-checkbox',
        TOTAL_AMOUNT_INPUT: 'js-total-amount',

        //Form data
        FORM_ID: 'generalClaimFormId',
        CONTACT_NAME: 'not-outage-claim-form-name',
        CONTACT_TYPE: 'userType',
        CONTACT_BUSINESS: 'not-outage-claim-form-business',
        CONTACT_ADDRESS: 'not-outage-claim-form-address',
        CONTACT_APT: 'not-outage-claim-form-apartment-number',
        CONTACT_CITY: 'not-outage-claim-form-city',
        CONTACT_STATE: 'not-outage-claim-form-state',
        CONTACT_ZIP_CODE: 'not-outage-claim-form-zipcode',
        CONTACT_DAY_PHONE: 'not-outage-claim-form-phone',
        CONTACT_EMAIL: 'not-outage-claim-form-email',
        RELATED_TO_ORU: 'incidentRelated',
        CONTACT_ACCOUNT_NUMBER: 'oru-claim-form-account-number',
        DATE_INCIDENT: 'not-outage-claim-form-date',
        TIME: 'not-outage-claim-form-time',
        TIME_FRAME: 'not-outage-claim-form-time-frame',
        LOCATION_DIFERENT: 'js-location-is-diferent',
        INCIDENT_ADDRESS: 'generalClaimIncidentAddress',
        INCIDENT_APT: 'generalClaimIncidentApartmentNumber',
        INCIDENT_CITY: 'generalClaimIncidentCity',
        INCIDENT_STATE: 'generalClaimIncidentState',
        INCIDENT_ZIP: 'generalClaimIncidentZipcode',
        INCIDENT_IS_RELATED: 'js-incident-checkbox',
        INCIDENT_EXPLAIN: 'generalClaimOtherIncident',
        WEATHER_CONDITION: 'js-weather-checkbox',
        WEATHER_EXPLAIN_INPUT: 'generalClaimOtherWeather',
        IS_INVOLVED: 'js-is-involved-checkbox',
        ORU_CONTRACTOR: 'generalClaimFormContractor',
        TAKE_ACTIONS: 'take-actions',
        TAKE_ACTIONS_EXPLAIN: 'take-actions-explain',
        PREMISES: 'premisis',
        CONTACT_REPORT: 'generalClaimOruContact',
        CALL_DETAILS: 'generalClaimOruContactExplain',
        POLICE_INVOLVED: 'generalClaimPolice',
        CLAIM_INSURANCE: 'generalClaimInsurance',
        INSURANCE_CARRIER: 'generalClaimInsuranceCarrier',
        POLICY_NUMBER: 'generalClaimInsurancePolicy',
        INSURANCE_EXPLAIN_INPUT: 'generalClaimInsuranceExplain',
        INCIDENT_DESCRIPTION: 'incident-description',
        DETAILS_DESCRIPTION: 'details-description',
        TOTAL_AMOUNT: 'total-amount',
        SIGNATURE: 'not-outage-claim-form-signature',
        SIGNATURE_DATE_INPUT: 'not-outage-claim-form-signature-date',
        SIGNATURE_DATE: 'js-date-format',

        //Helper Constants
        ARIA_HIDDEN: 'aria-hidden',
        HIDDEN: 'hidden',
        ARIA_REQUIRED: 'aria-required',
        OTHER_VALUE: 'Other',
        YES: 'Yes',
        NO: 'No',
        INSURANCE_VALUE: 'Insurance',
        TRUE_VALUE: 'true',
        FALSE_VALUE: 'false',
        TABINDEX: 'tabindex',

        // Tagging
        LESS_EQUAL_TAG: '<=reference claim amount',
        GREATER_TAG: '>reference claim amount',
        VEHICLE_TAG: 'vehicle',
        NO_VEHICLE_TAG: 'novehicle',
        CONTRACTOR_TAG: 'contractor',
        NO_CONTRACTOR_TAG: 'nocontractor'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var OruClaimForm = function ($oruClaimForm) {
        /**
         * PRIVATE METHODS
         */
        var $pageHeader,
            $oruClaimFormContainer,
            $oruContactExplain,
            $incidentExplain,
            $weatherExplain,
            $uploadFileList,
            $submitPopUpButton,
            $closePopUpButton,
            $incidentRadio,
            $signatureDateInput,
            $popUpClaimForm,
            $date,
            $locationIsDiferent,
            $locationAddress,
            $contractorInvolved,
            $oruContactRadios,
            $policeRadios,
            $policeMessage,
            $insuranceRadios,
            $insuranceInfo,
            $insuranceExplain,
            $checkboxIncidentList,
            $checkboxWeatherList,
            $incidentMotorVehicleCheckbox,
            $isInvolved,
            $fileInput,
            $totalAmountInput,
            $formLoading,
            $formWrapper,
            $messageWrapper,
            $successMessage,
            $successAltMessage,
            $errorMessage,
            $tryAgain,
            $resetButton,
            recaptcha,

            //Helper Variables
            _hasCaptcha,
            _hasFile,
            _distributionListLimit;

        /**
         * Shows/hide and element according to visibility flag
         * @param {Boolean} isVisible Visibility flag (true: shows element, false: hides element)
         * @param {HTMLElement} $element Element to get set the visibility to.
         */
         var setElementVisibility = function (isVisible, $element) {
            if (isVisible) {
                $element.classList.remove(CONSTANTS.HIDDEN);
                $element.setAttribute(CONSTANTS.ARIA_HIDDEN, CONSTANTS.FALSE_VALUE);
            } else {
                $element.classList.add(CONSTANTS.HIDDEN);
                $element.setAttribute(CONSTANTS.ARIA_HIDDEN, CONSTANTS.TRUE_VALUE);
            }
        };

        /*
        * Check if an incident related with ORU to show or hide the field
        */
        var checkORUIncident = function (event) {
            var radioValue = query.getRadioInputValue(
                $oruClaimForm,
                event.target.name
            );

            if(radioValue === CONSTANTS.YES) {
                $oruClaimFormContainer && setElementVisibility(true, $oruClaimFormContainer);
                $date.classList.add(CONSTANTS.NO_PADDING_TOP);
            } else {
                $oruClaimFormContainer && setElementVisibility(false, $oruClaimFormContainer);
                $date.classList.remove(CONSTANTS.NO_PADDING_TOP);
            }
        };

        //Hide the section to the initial state
        var hideExtraFields = function () {
            //Reset oru account number field
            $oruClaimFormContainer && setElementVisibility(false, $oruClaimFormContainer);
            $incidentExplain && setElementVisibility(false, $incidentExplain);
            $weatherExplain && setElementVisibility(false, $weatherExplain);
            $locationAddress && setElementVisibility(false, $locationAddress);
            $oruContactExplain && setElementVisibility(false, $oruContactExplain);
            $policeMessage && setElementVisibility(false, $policeMessage);
            $insuranceInfo && setElementVisibility(false, $insuranceInfo);
            $insuranceExplain && setElementVisibility(false, $insuranceExplain);
        };

        /**
        * Tagging for motor vehicle checkbox and oru contractor radio button
        */
          var vehicleContractorTagging = function () {
            var contractorSelected = $isInvolved.checked,
                vehicleSelected = $incidentMotorVehicleCheckbox.checked;

            dataLayer.push({
                selections: 
                    (vehicleSelected ? CONSTANTS.VEHICLE_TAG : CONSTANTS.NO_VEHICLE_TAG) + 
                    '.' + 
                    (contractorSelected ? CONSTANTS.CONTRACTOR_TAG : CONSTANTS.NO_CONTRACTOR_TAG)
            })
        };

        var validateUploadFile = function() {
            _hasFile = $uploadFileList.hasChildNodes();

            if (!_hasFile) {
                setTimeout(function () {
                    // If there is no file uploaded, show the popup
                    $popUpClaimForm.classList.remove(CONSTANTS.HIDDEN);
                    $submitPopUpButton.focus();
                }, 150);
            } else {
                onSubmitForm();
            }            
        };

        /**
        *  Check Recaptcha
        **/
        var checkRecaptcha = function () {
            if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                recaptcha.checkRecaptcha();
            }
        };

        /**
        *  Reset Recaptcha
        **/
        var resetRecaptcha = function () {
            if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                recaptcha.reset();
            }
        }
        
        /**
        *  Updates page styles for Confirmation Screen
        **/
        var updatePageForConfirmationScreen = function () {
            query.addClass($formWrapper, CONSTANTS.FORM_WRAPPER_NO_BACKGROUND_CLASS);
            $pageHeader.style.display = 'none';
        }

        /**
        *  Resets page styles when Confirmation Screen hides
        **/
        var resetPageForTryAgain = function () {
            query.removeClass($formWrapper, CONSTANTS.FORM_WRAPPER_NO_BACKGROUND_CLASS);
            $pageHeader.style.display = 'block';
        }

        /**
         *  Handles submit form
         **/
         var onSubmitForm = function () {
            vehicleContractorTagging();

            var serviceUrl = $oruClaimForm.dataset.serviceUrl,
                formData = new FormData(),
                incidents = [],
                weather = [],
                $incidentElement,
                $weatherCondition,
                params;

            // Incidents related
            for (var i = 0; i < $checkboxIncidentList.length; i++) {    
                $incidentElement = $checkboxIncidentList[i];

                if ($incidentElement.checked) {
                    incidents.push($incidentElement.value);
                }
            }

            // Weather conditions
            for (var index = 0; index < $checkboxWeatherList.length; index++) {    
                $weatherCondition = $checkboxWeatherList[index];

                if ($weatherCondition.checked) {
                    weather.push($weatherCondition.value);
                }
            }

            // Service Data
            params = {
                FormId: query.getFormInputValue($oruClaimForm, CONSTANTS.FORM_ID),
                Name: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_NAME),
                UserType: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_TYPE),
                BussinessName: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_BUSINESS),
                Address: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_ADDRESS),
                Apartment: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_APT),
                City: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_CITY),
                State: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_STATE),
                Zipcode: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_ZIP_CODE),
                DaytimePhone: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_DAY_PHONE),
                Email: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_EMAIL),
                IsRelatedToORU: query.getFormInputValue($oruClaimForm, CONSTANTS.RELATED_TO_ORU),
                Account: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_ACCOUNT_NUMBER),
                DateOfIncident: query.getFormInputValue($oruClaimForm, CONSTANTS.DATE_INCIDENT),
                Time: query.getFormInputValue($oruClaimForm, CONSTANTS.TIME),
                TimeFrame: query.getFormInputValue($oruClaimForm,CONSTANTS.TIME_FRAME),
                LocationIsDiferent: $locationIsDiferent.checked,
                ContactAddress: query.getFormInputValue($oruClaimForm, CONSTANTS.INCIDENT_ADDRESS),
                ContactApartment: query.getFormInputValue($oruClaimForm, CONSTANTS.INCIDENT_APT),
                ContactCity: query.getFormInputValue($oruClaimForm, CONSTANTS.INCIDENT_CITY),
                ContactState: query.getFormInputValue($oruClaimForm, CONSTANTS.INCIDENT_STATE),
                ContactZipCode: query.getFormInputValue($oruClaimForm, CONSTANTS.INCIDENT_ZIP),
                IncidentIsRelated: JSON.stringify(incidents),
                OtherIncident: query.getFormInputValue($oruClaimForm, CONSTANTS.INCIDENT_EXPLAIN),
                WeatherConditions: JSON.stringify(weather),
                OtherWeather: query.getFormInputValue($oruClaimForm, CONSTANTS.WEATHER_EXPLAIN_INPUT),
                InvolvedORUContractor: $isInvolved.checked,
                ORUContractor: query.getFormInputValue($oruClaimForm, CONSTANTS.ORU_CONTRACTOR),
                TakeActions: query.getFormInputValue($oruClaimForm, CONSTANTS.TAKE_ACTIONS),
                TakeActionsExplain: query.getFormInputValue($oruClaimForm, CONSTANTS.TAKE_ACTIONS_EXPLAIN),
                Premises: query.getFormInputValue($oruClaimForm, CONSTANTS.PREMISES),
                ContactReport: query.getFormInputValue($oruClaimForm, CONSTANTS.CONTACT_REPORT),
                CallDetails: query.getFormInputValue($oruClaimForm, CONSTANTS.CALL_DETAILS),
                PoliceInvolved: query.getFormInputValue($oruClaimForm, CONSTANTS.POLICE_INVOLVED),
                ClaimInsurance: query.getFormInputValue($oruClaimForm, CONSTANTS.CLAIM_INSURANCE),
                InsuranceCarrier: query.getFormInputValue($oruClaimForm, CONSTANTS.INSURANCE_CARRIER),
                PolicyNumber: query.getFormInputValue($oruClaimForm, CONSTANTS.POLICY_NUMBER),
                InsuranceExplain: query.getFormInputValue($oruClaimForm, CONSTANTS.INSURANCE_EXPLAIN_INPUT),
                IncidentDescription: query.getFormInputValue($oruClaimForm, CONSTANTS.INCIDENT_DESCRIPTION),
                DetailsDescription: query.getFormInputValue($oruClaimForm, CONSTANTS.DETAILS_DESCRIPTION),
                TotalAmount: query.getFormInputValue($oruClaimForm, CONSTANTS.TOTAL_AMOUNT),
                Signature: query.getFormInputValue($oruClaimForm, CONSTANTS.SIGNATURE),
                SignatureDate: query.getFormInputValue($oruClaimForm, CONSTANTS.SIGNATURE_DATE_INPUT)
            };

            if (_hasCaptcha && recaptcha) {
                params.Verify = recaptcha.getResponse();
            }

            // Serialize JSON into string to append to FormData
            params = JSON.stringify(params);
            formData.append('data', params);

            if ($fileInput && $fileInput.fileList.length) {
                _.each($fileInput.fileList, function ($file) {
                    formData.append('filesAttached', $file);
                });
            }

            // Service Call
            query.postData(
                serviceUrl,
                successSubmitForm,
                errorSubmitForm,
                formData,
                false,
                $formLoading
            );
        };

        /**
         * Handle Submit Code service call success
         */
        var successSubmitForm = function () {
            if(_hasFile){
                updatePageForConfirmationScreen();
                $oruClaimForm.classList.add(CONSTANTS.HIDDEN);
                $messageWrapper.style.display = 'block';
                $successMessage.style.display = 'block';
                window.scrollTo(0, 0);
                $successMessage.focus();
            } else {
                updatePageForConfirmationScreen();
                $oruClaimForm.classList.add(CONSTANTS.HIDDEN);
                $messageWrapper.style.display = 'block';
                $successAltMessage.style.display = 'block';
                window.scrollTo(0, 0);
                $successAltMessage.focus();
            }

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success'
            });
        };

        /**
         * Handle Submit Code service call error
         */
        var errorSubmitForm = function () {
            updatePageForConfirmationScreen();
            $oruClaimForm.classList.add(CONSTANTS.HIDDEN);
            $messageWrapper.style.display = 'block';
            $errorMessage.style.display = 'block';
            $successAltMessage.style.display = 'none';
            window.scrollTo(0, 0);
            $errorMessage.focus();

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.fail.submission'
            });
        };

        /**
         * Close popup with keyboard: ESC key_code : 27
         * @param {*} event
         * @param {*} popup
        */
        var resetPopUpKeyBoard = function (event, popup) {
            if (event.keyCode == coned.constants.KEY_CODE.ESC) {
                //Check if a popup is visible on the screen.
                var popUpOpen = !query.hasClass(popup, CONSTANTS.HIDDEN_CLASS);
                if (popUpOpen) {
                    resetRecaptcha();
                }
            }
        };
            
        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            $pageHeader = document.getElementsByClassName(CONSTANTS.PAGE_HEADER)[0];
            $oruClaimFormContainer = $oruClaimForm.getElementsByClassName(CONSTANTS.ORU_CLAIM_FORM_CONTAINER)[0];
            $incidentExplain = $oruClaimForm.getElementsByClassName(CONSTANTS.TYPE_OF_INCIDENT_EXPLAIN)[0];
            $weatherExplain = $oruClaimForm.getElementsByClassName(CONSTANTS.WEATHER_EXPLAIN)[0];
            $incidentRadio = $oruClaimForm.getElementsByClassName(CONSTANTS.INCIDENT_RELATED);
            $uploadFileList = $oruClaimForm.getElementsByClassName(CONSTANTS.UPLOAD_FILE_LIST)[0];
            $submitPopUpButton = document.getElementsByClassName(CONSTANTS.SUBMIT_POPUP_BUTTON)[0];
            $closePopUpButton = document.getElementsByClassName(CONSTANTS.CLOSE_POPUP_BUTTON)[0];
            $signatureDateInput = $oruClaimForm.getElementsByClassName(CONSTANTS.SIGNATURE_DATE)[0];
            $popUpClaimForm = document.getElementsByClassName(CONSTANTS.POPUP_CLAIM_FORM)[0];
            $date = $oruClaimForm.getElementsByClassName(CONSTANTS.DATE)[0];
            $locationIsDiferent = $oruClaimForm.getElementsByClassName(CONSTANTS.LOCATION_DIFERENT)[0];
            $checkboxIncidentList = $oruClaimForm.getElementsByClassName(CONSTANTS.INCIDENT_IS_RELATED);
            $checkboxWeatherList = $oruClaimForm.getElementsByClassName(CONSTANTS.WEATHER_CONDITION);
            $isInvolved = $oruClaimForm.getElementsByClassName(CONSTANTS.IS_INVOLVED)[0];
            $formWrapper = document.getElementsByClassName(CONSTANTS.FORM_WRAPPER)[0];
            $messageWrapper = document.getElementsByClassName(CONSTANTS.MESSAGE_WRAPPER)[0];
            $successMessage = $messageWrapper.getElementsByClassName(CONSTANTS.SUCCESS_MESSAGE)[0];
            $successAltMessage = $messageWrapper.getElementsByClassName(CONSTANTS.SUCCESS_ALT_MESSAGE)[0];
            $errorMessage = $messageWrapper.getElementsByClassName(CONSTANTS.ERROR_MESSAGE)[0];
            $tryAgain = document.getElementsByClassName(CONSTANTS.TRY_AGAIN)[0];
            $fileInput = $oruClaimForm.getElementsByClassName(CONSTANTS.FILE_UPLOAD_INPUT)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $locationAddress = $oruClaimForm.getElementsByClassName(CONSTANTS.LOCATION_ADDRESS_CONTAINER)[0];
            $contractorInvolved = $oruClaimForm.getElementsByClassName(CONSTANTS.IS_INVOLVED_CONTRACTOR)[0];
            $oruContactRadios = $oruClaimForm.getElementsByClassName(CONSTANTS.ORU_CONTACT_CHECKBOXES);
            $oruContactExplain = $oruClaimForm.getElementsByClassName(CONSTANTS.ORU_CONTACT_EXPLAIN)[0];
            $policeRadios = $oruClaimForm.getElementsByClassName(CONSTANTS.POLICE_RADIOS);
            $policeMessage = $oruClaimForm.getElementsByClassName(CONSTANTS.POLICE_MESSAGE)[0];
            $insuranceRadios = $oruClaimForm.getElementsByClassName(CONSTANTS.INSURANCE_RADIOS);
            $insuranceInfo = $oruClaimForm.getElementsByClassName(CONSTANTS.INSURANCE_INFO)[0];
            $insuranceExplain = $oruClaimForm.getElementsByClassName(CONSTANTS.INSURANCE_EXPLAIN)[0];
            $resetButton = $oruClaimForm.getElementsByClassName(CONSTANTS.RESET_BUTTON)[0];
            $incidentMotorVehicleCheckbox = document.getElementById(CONSTANTS.INCIDENT_MOTOR_VEHICLE_CHECKBOX_ID);
            $totalAmountInput = $oruClaimForm.getElementsByClassName(CONSTANTS.TOTAL_AMOUNT_INPUT)[0];
            _hasFile = false;
            recaptcha = false;
            _hasCaptcha = $oruClaimForm.getElementsByClassName(CONSTANTS.RECAPTCHA_ELEMENT)[0];

            _distributionListLimit =
                $oruClaimForm.dataset &&
                $oruClaimForm.dataset.distributionListAmount &&
                parseInt($oruClaimForm.dataset.distributionListAmount)
                    ? parseInt($oruClaimForm.dataset.distributionListAmount)
                    : 3000;
        };

        /**
         * Initialize aria atrributes in the module.
         */
        var initializeAriaAttributes = function () {

            if ($successMessage && query.hasClass($successMessage, CONSTANTS.SUCCESS_MESSAGE_FOCUS)) {
                $successMessage.setAttribute(CONSTANTS.TABINDEX, '-1');
            }

            if ($errorMessage && query.hasClass($errorMessage, CONSTANTS.ERROR_MESSAGE_FOCUS)) {
                $errorMessage.setAttribute(CONSTANTS.TABINDEX, '-1');
            }

            if ($successAltMessage && query.hasClass($successAltMessage, CONSTANTS.SUCCESS_ALT_MESSAGE_FOCUS)) {
                $successAltMessage.setAttribute(CONSTANTS.TABINDEX, '-1');
            }
        }

        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            new coned.components.ValidateForm($oruClaimForm, checkRecaptcha);
            recaptcha = new coned.components.Recaptcha($oruClaimForm, validateUploadFile, validateUploadFile);
            
            //Reset all the form
            $oruClaimForm.addEventListener('reset-form', hideExtraFields);

            //Prefilled the date
            var today = new Date();
            coned.utils.fillInputWithFormattedDate($signatureDateInput, today);

            //Add valid state to prefilled inputs
            setTimeout(function () {
                coned.utils.checkInputsFilled($oruClaimForm);
            }, 1000);

            // Show oru incident field (YES/NO question)
            _.each($incidentRadio, function ($radio) {
                coned.utils.addGeneralListeners($radio, checkORUIncident);
            });

            $locationIsDiferent.addEventListener('change', function (event) {
                setElementVisibility(event.target.checked, $locationAddress);
            });

            $isInvolved.addEventListener('change', function (event) {
                setElementVisibility(event.target.checked, $contractorInvolved);
            });

            _.each($checkboxIncidentList, function ($checkbox) {
                $checkbox.addEventListener('change', function (event) {
                    if (event.target.value === CONSTANTS.OTHER_VALUE) {
                        setElementVisibility(event.target.checked, $incidentExplain);
                    }
                });
            });

            _.each($checkboxWeatherList, function ($checkbox) {
                $checkbox.addEventListener('change', function (event) {
                    if (event.target.value === CONSTANTS.OTHER_VALUE) {
                        setElementVisibility(event.target.checked, $weatherExplain);
                    }
                });
            });

            _.each($oruContactRadios, function ($radio) {
                $radio.addEventListener('change', function (event) {
                    setElementVisibility(
                        event.target.checked && event.target.value === CONSTANTS.YES,
                        $oruContactExplain
                    );
                });
            });

            _.each($policeRadios, function ($radio) {
                $radio.addEventListener('change', function (event) {
                    setElementVisibility(
                        event.target.checked && event.target.value === CONSTANTS.YES,
                        $policeMessage
                    );
                });
            });

            _.each($insuranceRadios, function ($radio) {
                $radio.addEventListener('change', function (event) {
                    setElementVisibility(
                        event.target.checked && event.target.value === CONSTANTS.INSURANCE_VALUE,
                        $insuranceInfo
                    );
                    setElementVisibility(
                        event.target.checked && event.target.value === CONSTANTS.OTHER_VALUE,
                        $insuranceExplain
                    );
                });
            });

            $totalAmountInput.addEventListener('blur', function (event) {
                if (event.target.value !== '') {
                    dataLayer.push({
                        DLS:
                            parseFloat(event.target.value.replace(/[\$\,]/g, '')) <=
                            _distributionListLimit
                                ? CONSTANTS.LESS_EQUAL_TAG
                                : CONSTANTS.GREATER_TAG
                    });
                }
            });
    
            coned.utils.addGeneralListeners($submitPopUpButton, function () {
                onSubmitForm();
            });

            //Try again button
            coned.utils.addGeneralListeners($tryAgain, function () {
                $oruClaimForm.classList.remove(CONSTANTS.HIDDEN);
                $messageWrapper.style.display = 'none';
                $errorMessage.style.display = 'none';
                $oruClaimForm.scrollIntoView(true);

                resetPageForTryAgain();
                resetRecaptcha();
            });

            // Reset button
            coned.utils.addGeneralListeners($resetButton, function () {
                var firstInputField = $oruClaimForm.querySelectorAll(CONSTANTS.FORM_INPUT_QUERY)[0];
                firstInputField && firstInputField.focus();
            });

            // Reset recaptcha on close button of popup
            coned.utils.addGeneralListeners($closePopUpButton, function () {
                $fileInput.focus();
                resetRecaptcha();
            });

            $popUpClaimForm.addEventListener('keydown', function (event) {
                resetPopUpKeyBoard(event, $popUpClaimForm);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeAriaAttributes();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     OruClaimForm.prototype.isLoaded = function () {
        return isLoaded;
    };

    return OruClaimForm;
})();
