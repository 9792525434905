// ==================== LOAD SURVEY OPOWER COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoadSurveyOpower = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SURVEY_CONTAINER: 'js-survey-container',
        MODULE_CLASS: 'js-module',
        LOAD_ENERGY_INSIGHTS_ERROR: 'js-load-energy-insights-error',
        SURVEY_FULL_WIDGET_TAG: '<opower-widget-survey opower-instance="{{widget-survey-full-instance}}"></opower-widget-survey>',
        SCRIPT_ELEMENT: 'script',
        OPOWER_CUSTOMER_ID: 'js-opower-customer-id',
        SERVICE_ERROR: '<p class="service-unavailable-error">Service Not Available</p>',
        OPOWER_UNAUTHORIZED_EVENT: 'opower:unauthorized',
        OPOWER_START_EVENT: 'opower:start',
        ENERGY_USAGE_LOADED_EVENT: 'energy-usage-loaded',
        STATE_URL_PARAMETER_KEY: '&state=',
        OPOWER_VERTICAL_BAR_URL_PARAMETER_VALUE: "opower|",
        REDIRECT_URL_PARAMETER_KEY: '&redirect_uri='
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LoadSurveyOpower = function ($surveyOpower) {
        /**
         * PRIVATE METHODS
         */
        var $surveyContainer,
            customerId,
            authorize;


        var setLoadingError = function () {
            if (!query.hasClass($surveyOpower, CONSTANTS.LOAD_ENERGY_INSIGHTS_ERROR)) {
                query.addClass($surveyOpower, CONSTANTS.LOAD_ENERGY_INSIGHTS_ERROR);
                showServiceError();
            }
        };

        var setWidget = function () {
            var script = document.createElement(CONSTANTS.SCRIPT_ELEMENT);
            var scriptSrcOpower = $surveyOpower.dataset.scriptSrcOpower;

                // If IE don't load the widgets since opower widgets don't support it
                // Trying to load the widgets makes the whole page crash, so we just show nothing as IE usage is fairly minimal
                if (!coned.utils.isIE()) {
                    if (scriptSrcOpower) {
                        script.src = scriptSrcOpower;
                        document.head.appendChild(script);
                    } else {
                        setLoadingError();
                    }
                }
            if ($surveyContainer && !query.hasClass($surveyOpower, CONSTANTS.LOAD_ENERGY_INSIGHTS_ERROR)) {
                $surveyContainer.innerHTML = 
                    CONSTANTS.SURVEY_FULL_WIDGET_TAG
                        .replace(
                            '{{widget-survey-full-instance}}',
                            $surveyOpower.dataset.widgetSurveyFullInstance
                        );
            }
        };

        var showServiceError = function () {
            if ($surveyContainer) {
                $surveyContainer.innerHTML = CONSTANTS.SERVICE_ERROR;
            }
        };

        var getOpowerToken = function () {
            var tokenServiceUrlOpower = $surveyOpower.dataset.tokenServiceUrlOpower;

            if (!tokenServiceUrlOpower) {
                setLoadingError();
                return;
            }

            query.getData(tokenServiceUrlOpower, successGetOpowerToken, errorGetOpowerToken);
        };

        // Try to get the token, if empty call the redirect service which upon reload should have the token
        var successGetOpowerToken = function (token) {
            var redirectUrlOpower = $surveyOpower.dataset.redirectUrlOpower;

            if (!redirectUrlOpower) {
                setLoadingError();
                return;
            }

            if (token) {
                var authorization = {
                    accessToken: token
                }
                authorize(null, authorization);
            } else {
                query.getData(redirectUrlOpower, successOpowerRedirect, errorOpowerRedirect);
            }
        };

        var errorGetOpowerToken = function () {
            setLoadingError();
        };

        // The only way from OKTA to get the corresponding token on the BE is after a redirect, so we force it
        var successOpowerRedirect = function (uriData) {
            if (uriData) {
                uriData += CONSTANTS.REDIRECT_URL_PARAMETER_KEY + encodeURIComponent(window.location.protocol + "//" + window.location.host);
                uriData += CONSTANTS.STATE_URL_PARAMETER_KEY + encodeURIComponent(CONSTANTS.OPOWER_VERTICAL_BAR_URL_PARAMETER_VALUE + window.location.pathname + window.location.search);
                window.location.href = uriData;
            } else {
                setLoadingError();
            }
        };

        var errorOpowerRedirect = function () {
            setLoadingError();
        };

        var initializeData = function () {
            $surveyContainer = $surveyOpower.getElementsByClassName(
                CONSTANTS.SURVEY_CONTAINER
            )[0];
        };

        var initializeEvents = function () {
            setWidget();

            if (!coned.utils.isPatternLab()) {
                // If IE don't load the widgets since opower widgets don't support it
                // Trying to load the widgets makes the whole page crash, so we just show nothing as IE usage is fairly minimal
                if (!coned.utils.isIE()) {
                    window.addEventListener(CONSTANTS.OPOWER_UNAUTHORIZED_EVENT, function (event) {
                        authorize = event.detail.authorize;
                        event.preventDefault();
                        getOpowerToken();
                    });
    
                    window.addEventListener(CONSTANTS.OPOWER_START_EVENT, function (event) {
                        customerId = document.getElementsByClassName(
                            CONSTANTS.OPOWER_CUSTOMER_ID
                        )[0];
                        
                        if (customerId) {
                            window.opowerApi = event.detail;
                            window.opowerApi.setEntityIds([customerId.value]);
                            window.opowerApi.start();
                        }
                    });
                }
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();

            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoadSurveyOpower.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LoadSurveyOpower;
})();
