// ==================== ENERGY AFFORDABLE PROGRAM FORM =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.EnergyAffordableProgramApplication = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Selector Classes
        ENROLLED_PROGRAM: 'js-enrolled-program',
        NOT_ENROLLED_PROGRAM: 'js-not-enrolled-program',
        ENROLLED_RADIO: 'js-enrolled-radio',
        ENROLLED_SELECT: 'js-enrolled-program-select',
        SUPPORTING_DOCUMENTS: 'js-supporting-documents',
        SUPPORTING_DOCUMENTS_RADIO: 'js-supporting-documents-radio',
        SUPPORTING_DOCUMENTS_INPUT: 'js-coned-inputfile',
        ADD_ACCOUNT_SITECORE_ID: 'ScId',
        ADDRESS_DROPDOWN: 'js-address-dropdown',
        ADDRESS_DROPDOWN_CONTAIN: 'js-dropdown-item-contain',
        ADDRESS_DROPDOWN_ITEMS: 'js-dropdown-item',
        ADDRESS_DROPDOWN_ELIGIBILITY_ERROR_MSG: 'js-error-message',
        ADDRESS_DROPDOWN_ELIGIBILITY_ERROR: 'js-eligibility-error',
        DROPDOWN_BUTTON: 'js-dropdown-button-contain',
        CONTACT_NAME: 'js-contact-name',
        CONTACT_PHONE: 'js-contact-phone',
        CONTACT_STREET: 'js-contact-street',
        CONTACT_CITY: 'js-contact-city',
        CONTACT_STATE: 'js-contact-state',
        CONTACT_ZIPCODE: 'js-contact-zipcode',
        CONTACT_ACCOUNT_NUMBER_INPUT: 'contactAccountNumber',
        CONTACT_NAME_INPUT: 'contactName',
        CONTACT_PHONE_INPUT: 'contactPhone',
        CONTACT_STREET_INPUT: 'contactStreet',
        CONTACT_CITY_INPUT: 'contactCity',
        CONTACT_STATE_INPUT: 'contactState',
        CONTACT_ZIPCODE_INPUT: 'contactZipcode',
        SIGNATURE_DATE: 'js-signature-date',
        BQP_NAME: 'js-bqp-name',
        BQP_SIGNATURE_CONTAINER: 'js-bqp-signature-container',
        BQP_SIGNATURE_DATE: 'js-bqp-signature-date',
        BQP_SIGNATURE: 'js-bqp-signature',
        BQP_NAME_INPUT: 'energyAffordableProgramApplicationBQPName',
        BQP_SIGNATURE_INPUT: 'energyAffordableProgramApplicationBQPSignature',
        DROP_FILE_CLASS: 'js-coned-inputfile-drag',
        SUBMIT_BUTTON: 'js-submit-button',
        UPLOAD_FILE_LIST: 'js-file-list',
        UPLOAD_FILE_INPUT: 'js-file-upload-input',
        ERROR_UPLOAD_FILE_INPUT: 'coned-field-error-wrapper',
        YES: 'yes',
        NO: 'no',
        HIDDEN: 'hidden',
        EMPTY_STRING: '',
        ARIA_REQUIRED: 'aria-required',
        REQUIRED_FIELD: 'required',
        FORM_LOADING: 'js-form-loading',
        TAG_ENROLLED_PROGRAM_RADIO: 'Low Income - Step 2 Government assistance program',
        TAG_ENROLLED_PROGRAM_SELECT: 'Low Income - Step 2.1 Government assistance program',
        TAG_ADDRESS_DROPDOWN_SELECTION: 'Low Income - Step 1 Service Address Selection',
        TAG_ATTACHED_DOCUMENTATION: 'Low Income – Step 3. Attach file',
        TAG_ERROR_ATTACHED_DOCUMENTATION: 'Low Income – Step 3 Error/submit with no attachment'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var EnergyAffordableProgramApplication = function ($applicationForm) {
        /**
         * PRIVATE METHODS
         */
        var $enrolledProgram,
            $notEnrolledProgram,
            $enrolledRadio,
            $enrolledSelect,
            $addressDropdown,
            $accountDropDownItems,
            $dropdownButton,
            $eligibilityError,
            $eligibilityErrorMsg,
            $signatureDateInput,
            $bqpName,
            $bqpSignatureContainer,
            $bqpSignatureInput,
            $bqpSignatureDateInput,
            $fileSelect,
            $submitButton,
            $uploadFileList,
            $formLoading,
            _account,
            _eligibleAddress,
            _hasFile;
        
        /*
        * Check if the user is already enrolled in a program show the dropdown and make it required
        * otherwise hide it and make the select optional
        */
        var checkEnrolledPrograms = function (event) {
            var radioValue = query.getFormInputValue(
                $applicationForm,
                event.target.name
            );

            if(radioValue === CONSTANTS.YES) {
                $notEnrolledProgram.classList.add(CONSTANTS.HIDDEN);
                $enrolledProgram.classList.remove(CONSTANTS.HIDDEN);
                $enrolledSelect.setAttribute(CONSTANTS.REQUIRED_FIELD, CONSTANTS.EMPTY_STRING);
                $enrolledSelect.setAttribute(CONSTANTS.ARIA_REQUIRED, 'true');

                dataLayer.push({
                    event: CONSTANTS.TAG_ENROLLED_PROGRAM_RADIO,
                    optionSelected: CONSTANTS.YES
                });
            }else {
                $enrolledProgram.classList.add(CONSTANTS.HIDDEN);
                $notEnrolledProgram.classList.remove(CONSTANTS.HIDDEN);
                $enrolledSelect.removeAttribute(CONSTANTS.ARIA_REQUIRED);
                $enrolledSelect.removeAttribute(CONSTANTS.REQUIRED_FIELD);

                dataLayer.push({
                    event: CONSTANTS.TAG_ENROLLED_PROGRAM_RADIO,
                    optionSelected: CONSTANTS.NO
                });
            }
        }

        //Hide all the sections to the initial state
        var hideExtraFields = function () {
            //Reset enrolled programs
            $enrolledProgram.classList.add(CONSTANTS.HIDDEN);
            $enrolledSelect.removeAttribute(CONSTANTS.ARIA_REQUIRED);
            $enrolledSelect.removeAttribute(CONSTANTS.REQUIRED_FIELD);

            //Reset BQP Signature
            $bqpSignatureContainer.classList.add(CONSTANTS.HIDDEN);
            $bqpSignatureInput.removeAttribute(CONSTANTS.REQUIRED_FIELD);
            $bqpSignatureInput.removeAttribute(CONSTANTS.ARIA_REQUIRED);
            $bqpSignatureDateInput.removeAttribute(CONSTANTS.REQUIRED_FIELD);
            $bqpSignatureDateInput.removeAttribute(CONSTANTS.ARIA_REQUIRED);
        }

        var updateContactInformation = function (event) {
            var $accountInformation = event.currentTarget.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_CONTAIN)[0];
            query.setFormTextInputValue($applicationForm, CONSTANTS.CONTACT_ACCOUNT_NUMBER_INPUT,$accountInformation.dataset.account);
            var $contactName = $applicationForm.getElementsByClassName(CONSTANTS.CONTACT_NAME)[0];
            $contactName.innerHTML = $accountInformation.dataset.name;
            query.setFormTextInputValue($applicationForm, CONSTANTS.CONTACT_NAME_INPUT,$accountInformation.dataset.name);
            var $contactPhone = $applicationForm.getElementsByClassName(CONSTANTS.CONTACT_PHONE)[0];
            if ($accountInformation.dataset.phone) {
                $contactPhone.innerHTML = $accountInformation.dataset.phone;
                query.setFormTextInputValue($applicationForm, CONSTANTS.CONTACT_PHONE_INPUT,$accountInformation.dataset.phone);
                $contactPhone.classList.remove(CONSTANTS.HIDDEN);
            } else {
                $contactPhone.innerHTML = CONSTANTS.EMPTY_STRING;
                $contactPhone.classList.add(CONSTANTS.HIDDEN);
                query.setFormTextInputValue($applicationForm, CONSTANTS.CONTACT_PHONE_INPUT,CONSTANTS.EMPTY_STRING);
            }
            var $contactStreet = $applicationForm.getElementsByClassName(CONSTANTS.CONTACT_STREET)[0];
            $contactStreet.innerHTML = $accountInformation.dataset.street + ', ' + $accountInformation.dataset.address2;
            query.setFormTextInputValue($applicationForm, CONSTANTS.CONTACT_STREET_INPUT,$accountInformation.dataset.street + ', ' + $accountInformation.dataset.address2);
            var $contactCity = $applicationForm.getElementsByClassName(CONSTANTS.CONTACT_CITY)[0];
            $contactCity.innerHTML = $accountInformation.dataset.city;
            query.setFormTextInputValue($applicationForm, CONSTANTS.CONTACT_CITY_INPUT,$accountInformation.dataset.city);
            var $contactState = $applicationForm.getElementsByClassName(CONSTANTS.CONTACT_STATE)[0];
            $contactState.innerHTML = $accountInformation.dataset.state;
            query.setFormTextInputValue($applicationForm, CONSTANTS.CONTACT_STATE_INPUT,$accountInformation.dataset.state);
            var $contactZipcode = $applicationForm.getElementsByClassName(CONSTANTS.CONTACT_ZIPCODE)[0];
            $contactZipcode.innerHTML = $accountInformation.dataset.zipcode;
            query.setFormTextInputValue($applicationForm, CONSTANTS.CONTACT_ZIPCODE_INPUT,$accountInformation.dataset.zipcode);

            dataLayer.push({
                event: CONSTANTS.TAG_ADDRESS_DROPDOWN_SELECTION,
                optionSelected: null
            });
        };

        var handleKeyDownOnAccountItem = function (event) {
            var keycode = event.keyCode;

            if (keycode == coned.constants.KEY_CODE.ENTER) {
                updateContactInformation(event);
            }
        };

        var showBQPSignature = function() {
            var bqpNameValue = query.getFormTextInputValue($applicationForm,CONSTANTS.BQP_NAME_INPUT);

            if(bqpNameValue !== CONSTANTS.EMPTY_STRING) {
                $bqpSignatureContainer.classList.remove(CONSTANTS.HIDDEN);
                $bqpSignatureInput.setAttribute(CONSTANTS.REQUIRED_FIELD, CONSTANTS.EMPTY_STRING);
                $bqpSignatureInput.setAttribute(CONSTANTS.ARIA_REQUIRED, 'true');
                $bqpSignatureDateInput.setAttribute(CONSTANTS.REQUIRED_FIELD, CONSTANTS.EMPTY_STRING);
                $bqpSignatureDateInput.setAttribute(CONSTANTS.ARIA_REQUIRED, 'true');
            }else {
                $bqpSignatureContainer.classList.add(CONSTANTS.HIDDEN);
                query.setFormTextInputValue($applicationForm,CONSTANTS.BQP_SIGNATURE_INPUT, CONSTANTS.EMPTY_STRING);
                $bqpSignatureInput.removeAttribute(CONSTANTS.REQUIRED_FIELD);
                $bqpSignatureInput.removeAttribute(CONSTANTS.ARIA_REQUIRED);
                $bqpSignatureInput.classList.remove(coned.constants.INPUT_FILLED_CLASS);
                $bqpSignatureInput.classList.remove(CONSTANTS.VALID_CLASS);
                $bqpSignatureInput.classList.remove(coned.constants.INPUT_ERROR_CLASS);
                $bqpSignatureDateInput.removeAttribute(CONSTANTS.REQUIRED_FIELD);
                $bqpSignatureDateInput.removeAttribute(CONSTANTS.ARIA_REQUIRED);
            }

        }

        var uploadFileTag = function() {
            _hasFile = $uploadFileList.hasChildNodes();

            if(_hasFile){
                dataLayer.push({
                    event: CONSTANTS.TAG_ATTACHED_DOCUMENTATION
                });
            }
        }

        var errorUploadFileTag = function() {
            
            // set time out to wait for the form to be validated and if it fails the tag will display
            setTimeout(function () {
                _hasFile = $uploadFileList.hasChildNodes();
                
                if (!_hasFile) {
                    dataLayer.push({
                        event: CONSTANTS.TAG_ERROR_ATTACHED_DOCUMENTATION
                    });
                }   
            }, 500);
        }

        /**
         * 
         * @param {} event click event to the submit button
         * Check if the account is elegible so the form continue to submit.
         */
        var checkSelectedAccountElegible = function(event) {
            
            if (!_eligibleAddress) {
                event.preventDefault();
                query.scrollToElement($applicationForm, $eligibilityErrorMsg);
                $eligibilityError.focus();
            } else {
                // Add tag when the form is submitted and there is no file attached 
                errorUploadFileTag();
            }
        }

        /**
         * Check if the selected account is eleigible for an EAP
         */
        var checkEligibility = function(event) {

            var serviceUrl = $addressDropdown.dataset.eligibleAccountService,
            mat = $applicationForm.dataset.mat,
            params = {
                ScId: query.getFormInputValue($applicationForm, CONSTANTS.ADD_ACCOUNT_SITECORE_ID),
                Maid: event.target.dataset.maid
            };

            if (mat) {
                params.mat = mat;
            }

            //Check if the service is available 
            if(serviceUrl) {
                //Dataset to test the different scenarios on the FE enviroment
                if (coned.utils.isPatternLab()) {
                    if (event.target.dataset.eligible === 'true') {
                        _eligibleAddress = true;
                    } else {
                        _eligibleAddress = false;
                    }
                }

                params = JSON.stringify(params);
                
                //Call eligibility service
                query.postData(serviceUrl, successCheckEligibity, errorCheckEligibity, params, true, $formLoading);
            } else {
                //Set elegible true since there is no validation call all the accounts will be set to true
                _eligibleAddress = true;

                //If no service available remove the loading from the page to let the user continue filling the form
                $formLoading.classList.add(CONSTANTS.HIDDEN);
            }
        }

        var successCheckEligibity = function (data) {
            if (coned.utils.isPatternLab()) {
                if(_eligibleAddress) {
                    query.getData(coned.plConstants.GET_ACCOUNT_EAP_ELIGIBLE, checkEligibilityResponse, function () {});
                } else {
                    query.getData(coned.plConstants.GET_ACCOUNT_EAP_NON_ELIGIBLE, checkEligibilityResponse, function () {});
                }
            } else {
                checkEligibilityResponse(data);
            }
        }

        var checkEligibilityResponse = function (data) {
            if(data.eligible) {
                $eligibilityError.classList.add(CONSTANTS.HIDDEN);
                _eligibleAddress = true;
            } else {
                $eligibilityError.classList.remove(CONSTANTS.HIDDEN);
                $eligibilityErrorMsg.innerText = data.msg;
                $eligibilityError.focus();
                _eligibleAddress = false;
            }
        }

        var errorCheckEligibity = function (data) {
            $eligibilityError.classList.remove(CONSTANTS.HIDDEN);
            $eligibilityError.innerHTML = data.msg;
        }
            
        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            $enrolledProgram = $applicationForm.getElementsByClassName(CONSTANTS.ENROLLED_PROGRAM)[0];
            $notEnrolledProgram = $applicationForm.getElementsByClassName(CONSTANTS.NOT_ENROLLED_PROGRAM)[0];
            $enrolledRadio = $applicationForm.getElementsByClassName(CONSTANTS.ENROLLED_RADIO);
            $enrolledSelect = $applicationForm.getElementsByClassName(CONSTANTS.ENROLLED_SELECT)[0];
            $accountDropDownItems = $applicationForm.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_ITEMS);
            $dropdownButton = $applicationForm.getElementsByClassName(CONSTANTS.DROPDOWN_BUTTON)[0];
            $eligibilityError = $applicationForm.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_ELIGIBILITY_ERROR)[0];
            $eligibilityErrorMsg = $eligibilityError ? $eligibilityError.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_ELIGIBILITY_ERROR_MSG)[0] : null;
            $signatureDateInput = $applicationForm.getElementsByClassName(CONSTANTS.SIGNATURE_DATE)[0];
            $bqpName = $applicationForm.getElementsByClassName(CONSTANTS.BQP_NAME)[0];
            $bqpSignatureContainer = $applicationForm.getElementsByClassName(CONSTANTS.BQP_SIGNATURE_CONTAINER)[0];
            $bqpSignatureInput = $applicationForm.getElementsByClassName(CONSTANTS.BQP_SIGNATURE)[0];
            $bqpSignatureDateInput = $applicationForm.getElementsByClassName(CONSTANTS.BQP_SIGNATURE_DATE)[0];
            $fileSelect = $applicationForm.getElementsByClassName(CONSTANTS.DROP_FILE_CLASS)[0];
            $submitButton = $applicationForm.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $uploadFileList = $applicationForm.getElementsByClassName(CONSTANTS.UPLOAD_FILE_LIST)[0];
            $addressDropdown = $applicationForm.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            _hasFile = false;
        };

        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            //Add the form-loading to the pages while checcking if eligible account
            $formLoading.classList.remove('form-loading--hidden');

            // Enrolled programs radios (YES/NO question)
            _.each($enrolledRadio, function ($radio) {
                coned.utils.addGeneralListeners($radio, checkEnrolledPrograms);
            });

            //Tagging on the selected program
            $enrolledSelect.addEventListener('change', function() {
                dataLayer.push({
                    event: CONSTANTS.TAG_ENROLLED_PROGRAM_SELECT,
                    optionSelected: $enrolledSelect.value
                });
            });

            // Add event to account selection to update contact information and event to check eligibility
            _.each($accountDropDownItems, function ($accountItem) {
                coned.utils.addGeneralListeners($accountItem, updateContactInformation);
                $accountItem.addEventListener('keydown', handleKeyDownOnAccountItem);
                //check eligibility of the selected account                
                $accountItem.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_CONTAIN)[0].addEventListener('checkEligibility', checkEligibility);
            });

            // Update the dropdown to show the account in context selected 
            for (var i = 0; $accountDropDownItems[i]; i++) {
                _account = $dropdownButton.dataset.account;

                if ($accountDropDownItems[i].children[0].dataset.account === _account) {
                    setTimeout(function () {
                        query.fireEvent($accountDropDownItems[i],'accountInContext'); 
                    }, 500);
                    break;
                }
            }

            //Reset all the form
            $applicationForm.addEventListener('reset-form', hideExtraFields);

            //Prefilled the date
            var today = new Date();
            coned.utils.fillInputWithFormattedDate($signatureDateInput, today);
            coned.utils.fillInputWithFormattedDate($bqpSignatureDateInput, today);

            //Add valid state to prefilled inputs
            setTimeout(function () {
                coned.utils.checkInputsFilled($applicationForm);
            }, 1000);

            $bqpName.addEventListener('input',showBQPSignature);

            // Add tag when a file is attached
            $fileSelect.addEventListener('change', uploadFileTag);

            // Check the account is elegible otherwise the form should not submit
            $submitButton.addEventListener('click', checkSelectedAccountElegible);

            
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     EnergyAffordableProgramApplication.prototype.isLoaded = function () {
        return isLoaded;
    };

    return EnergyAffordableProgramApplication;
})();
