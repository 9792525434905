// ==================== SLEEPING HOURS COMPONENT =========================
/* global _ */
/* global $ */
/* global gsap */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SleepingHours = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        RADIO_OPTION: 'js-radio-life-support',
        RADIO_OPTION_YES: 'js-radio-life-support-yes',
        INPUT_MASK: 'js-mask',
        INPUT_ITEM: 'js-item-validate',
        FIELD_CONTAINER: 'js-sleeping-input',
        FORM_ITEM_IGNORE: 'js-validate-ignore',
        INPUT_ERROR_CLASS: 'coned-input-message--error',
        INPUT: 'js-slepping-hours-input',
        FORM: '.transactional__form'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var SleepingHours = function ($SleepingHoursModule) {
        /**
         * PRIVATE METHODS
         */
        var $radioOption, $fieldContainer, $mask, $input, $radioOptionYes;

        var changeHandler = function () {
            var validator = $(CONSTANTS.FORM).validate();

            if ($radioOptionYes.checked) {
                var itemHeight = $fieldContainer.offsetHeight + 35;
                $input.classList.add(CONSTANTS.INPUT_ITEM);
                $input.classList.remove(CONSTANTS.FORM_ITEM_IGNORE);

                // animate the form fields
                gsap.to($mask, {
                    duration: 0.4,
                    height: itemHeight
                });
                validator.resetForm();
            } else {
                $input.classList.add(CONSTANTS.FORM_ITEM_IGNORE);
                $input.classList.remove(CONSTANTS.INPUT_ERROR_CLASS);
                $input.classList.remove(CONSTANTS.INPUT_ITEM);
                $input.value = '';

                // animate the form fields
                gsap.to($mask, {
                    duration: 0.4,
                    height: 0
                });
                validator.resetForm();
            }
        };

        var initializeData = function () {
            $radioOption = $SleepingHoursModule.getElementsByClassName(CONSTANTS.RADIO_OPTION);
            $radioOptionYes = $SleepingHoursModule.getElementsByClassName(
                CONSTANTS.RADIO_OPTION_YES
            )[0];
            $fieldContainer = $SleepingHoursModule.getElementsByClassName(
                CONSTANTS.FIELD_CONTAINER
            )[0];
            $mask = $SleepingHoursModule.getElementsByClassName(CONSTANTS.INPUT_MASK)[0];
            $input = $SleepingHoursModule.getElementsByClassName(CONSTANTS.INPUT)[0];
        };

        var initializeEvents = function () {
            _.each($radioOption, function ($radioButton) {
                $radioButton.addEventListener('change', changeHandler);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SleepingHours.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SleepingHours;
})();
