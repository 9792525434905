// ==================== DROPDOWN =========================

var coned = coned || {};

coned.components = coned.components || {};
/**
 * @returns the init function to start the module
 */
coned.components.Dropdown = (function () {
    /**
     * @typedef {Object} SelectSingleState
     * @property {string} value
     */

    /**
     * @typedef {Object} DropdownState
     * @property {boolean | undefined} open
     * @property {number | undefined} count
     * @property {string | undefined} value
     */

    /**
     * @typedef {Object} SelectState
     * @property {string} name
     * @property {string | string[]} value
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        SELECTORS: {
            /**
             * @type {'js-dropdown-button'}
             */
            BUTTON: 'js-dropdown-button',

            /**
             * @type {'js-dropdown-button-text'}
             */
            BUTTON_TEXT: 'js-dropdown-button-text',

            /**
             * @type {'js-dropdown-content'}
             */
            CONTENT: 'js-dropdown-content',
            /**
             * @type {'js-select-multiple'}
             */
            SELECT_MULTIPLE: 'js-select-multiple',
            /**
             * @type {'js-select-single'}
             */
            SELECT_SINGLE: 'js-select-single'
        },
        ATTRIBUTES: {
            /**
             * @type {'data-count'}
             */
            DATA_COUNT: 'data-count',
            /**
             * @type {'data-value'}
             */
            DATA_VALUE: 'data-value',
            /**
             * @type {'single-selection'}
             */
            SINGLE_SELECTION: 'single-selection'
        }
    };
    var isLoaded = false;
    /**
     * Constructor
     * @param { HTMLDivElement } $dropDown
     * @returns {}
     */
    var DropDown = function ($dropDown) {
        /**
         * PRIVATE VARIABLES
         */

        /**
         * @type {DropdownState}
         */
        var _state,
            /**
             * @type {HTMLElement}
             */
            $button,
            /**
             * @type {HTMLElement}
             */
            $buttonText,
            /**
             * @type {string}
             */
            buttonTextInitialValue,
            /**
             * @type {HTMLElement}
             */
            $content,
            /**
             * @type {boolean}
             */
            _hasDataCount,
            /**
             * @type {boolean}
             */
            _hasDataValue,
            /**
             * @type {HTMLElement | null}
             */
            $selectMultiple,
            /**
             * @type {HTMLElement[]}
             */
            $focusableElements,
            /**
             * @type {boolean}
             */
            isSingle,
            /**
             * @type {HTMLElement | null}
             */
            $selectSingle;
        /**
         * PRIVATE METHODS
         */

        /**
         * Update html changes in state
         */
        var updateDataCountHTML = function () {
            if (_hasDataCount) {
                $dropDown.dataset.count = '' + _state.count;
                $buttonText.innerHTML = _state.count > 0
                    ? buttonTextInitialValue + '(' + _state.count + ')'
                    : buttonTextInitialValue;
            }
        }
        /**
         * Update html changes in state
         */
        var updateDataValueHTML = function () {
            if (_hasDataValue && _state.value) {
                $dropDown.dataset.value = '' + _state.value;
                $buttonText.innerHTML = _state.value.length > 0
                    ? _state.value
                    : buttonTextInitialValue;
            }
        }
        /**
         * Update html changes in state
         */
        var updateOpenHTML = function () {
            $button.setAttribute(
                coned.constants.ARIA.EXPANDED,
                _state.open ? coned.constants.TRUE : coned.constants.FALSE
            );
            $content.setAttribute(
                coned.constants.ARIA.HIDDEN,
                _state.open ? coned.constants.FALSE : coned.constants.TRUE
            );
        }
        /**
         * Handle keyboard event
         * @param {KeyboardEvent} event 
         */
        var handleKeyboardEvent = function (event) {
            var keyCode = event.code,
                $element = event.target,
                isShiftPressed = event.shiftKey,
                isArrowUP = keyCode === coned.constants.KEYBOARD_CODE.UP,
                isArrowDown = keyCode === coned.constants.KEYBOARD_CODE.DOWN,
                isTab = keyCode === coned.constants.KEYBOARD_CODE.TAB,
                isEsc = keyCode === coned.constants.KEYBOARD_CODE.ESC;

            if (_state.open && (isTab || isArrowDown || isArrowUP ||
                isEsc)
            ) {
                event.preventDefault();
                if ((isTab && isShiftPressed) || isArrowUP) {
                    coned.utils.handleNextPrevFocus(coned.constants.ORDER.PREV, $element, $focusableElements);
                } else if ((isTab && !isShiftPressed) || isArrowDown) {
                    coned.utils.handleNextPrevFocus(coned.constants.ORDER.NEXT, $element, $focusableElements);
                }
                if (isEsc) {
                    $button.focus();
                    $button.click();
                }
            }
        }

        /**
         * Update module state
         * @param {DropdownState} newState 
         */
        var setState = function (newState) {
            if (typeof newState.open !== 'undefined') {
                _state.open = newState.open;
                updateOpenHTML();
            }
            if (typeof newState.count !== 'undefined') {
                _state.count = newState.count;
                updateDataCountHTML();
            }
            if (typeof newState.value !== 'undefined') {
                _state.value = newState.value;
                updateDataValueHTML();
            }
            coned.utils.triggerEvent(
                $dropDown,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _state
            );
        }

        /**
         * Handle dropdown button click
         */
        var handleButtonClick = function () {
            setState({ open: !_state.open });
        }
        /**
         * 
         * @param {CustomEvent<SelectSingleState>} event 
         */
        var handleSelectSingleChange = function (event) {
            if (isSingle) {
                $buttonText.innerHTML = event.detail.value.length > 0
                    ? event.detail.value
                    : buttonTextInitialValue;
                setState({ open: false })
            }
        }
        /**
         * Listen select change custom event 
         * @param {CustomEvent<SelectState>} event 
         */
        var handleSelectChange = function (event) {
            if (Array.isArray(event.detail.value)) {
                setState({ count: event.detail.value.length });
            } else {
                setState({ value: event.detail.value });
            }
        }
        /**
         * Handle click outside
         * @param {MouseEvent} event 
         */
        var handleOutsideClick = function (event) {
            if (!$dropDown.contains(event.target) && _state.open) {
                setState({ open: false });
            }
        }

        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            _state = {
                open: false
            };
            $button = $dropDown.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON)[0];
            $buttonText = $button.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON_TEXT)[0];
            buttonTextInitialValue = $buttonText.innerHTML;
            $content = $dropDown.getElementsByClassName(CONSTANTS.SELECTORS.CONTENT)[0];
            _hasDataCount = $dropDown.hasAttribute(CONSTANTS.ATTRIBUTES.DATA_COUNT);
            _hasDataValue = $dropDown.hasAttribute(CONSTANTS.ATTRIBUTES.DATA_VALUE);
            $selectMultiple = $dropDown.getElementsByClassName(CONSTANTS.SELECTORS.SELECT_MULTIPLE)[0];
            $focusableElements = coned.utils.arrayFrom(
                $content.querySelectorAll(
                    coned.constants.FOCUSABLE_ELEMENTS_DROPDOWN_MODAL_QUERY
                )
            ).sort(coned.utils.arrayFocusSort);
            isSingle = $dropDown.hasAttribute(CONSTANTS.ATTRIBUTES.SINGLE_SELECTION);
            $selectSingle = $dropDown.getElementsByClassName(CONSTANTS.SELECTORS.SELECT_SINGLE)[0];
        }

        /**
         * Initialize the events in the module
         */
        var initializeEvents = function () {
            $dropDown.addEventListener('keydown', handleKeyboardEvent);
            $button.addEventListener('click', handleButtonClick);
            if ($selectMultiple) {
                $selectMultiple.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handleSelectChange
                )
            }
            if ($selectSingle) {
                $selectSingle.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handleSelectSingleChange
                );
            }
            document.addEventListener('click', handleOutsideClick);
        }
        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            coned.utils.triggerEvent(
                $dropDown,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _state
            );
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Returns true if the Module is loaded
     * @returns {boolean}
     */
    DropDown.prototype.isLoaded = function () {
        return isLoaded;
    };
    return DropDown;
})();