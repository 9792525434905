// ==================== CURRENTLY ENROLLED COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CurrentlyEnrolled = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HIDDEN_CLASS: 'hidden',
        ITEM_ARROW: 'js-open-arrow',
        ITEM_CTA_CLASS: 'js-cta-item',
        ENROLLED_ITEM_CLASS: 'js-currently-enrolled-item',
        ENROLLED_ITEM_OPEN_CLASS: 'currently-enrolled__item--open',
        ENROLLED_DESCRIPTION_CLASS: 'js-currently-enrolled-description',
        ENROLLED_DOWNLOAD_BUTTON: 'js-download-enrolled-button',
        UNENROLL_CONTAINER_CLASS: 'js-currently-unenroll',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        LOADED_ATTRIBUTE: 'data-loaded',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        ARIA_EXPANDED_ATTRIBUTE: 'aria-expanded'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var CurrentlyEnrolled = function ($currentlyEnrolled) {
        /**
         * PRIVATE METHODS
         */
        var $currentEnrolledCtaExpanded,
            $currentEnrolledItemExpanded,
            $enrolledDescription,
            $enrolledDownloadButton,
            $formLoading;

        var closeUnits = function ($enrolledItem, $unitsButton) {
            var closeText = $enrolledItem.dataset.closeName;
            query.removeClass($enrolledItem, CONSTANTS.ENROLLED_ITEM_OPEN_CLASS);
            $unitsButton.setAttribute(CONSTANTS.ARIA_EXPANDED_ATTRIBUTE, false);   
            $unitsButton.firstElementChild.textContent = closeText;
            $currentEnrolledItemExpanded = null;
            $currentEnrolledCtaExpanded = null;
        };

        var openUnits = function ($enrolledItem, $unitsButton) {
            var openText = $enrolledItem.dataset.openName;
            query.addClass($enrolledItem, CONSTANTS.ENROLLED_ITEM_OPEN_CLASS);
            $unitsButton.setAttribute(CONSTANTS.ARIA_EXPANDED_ATTRIBUTE, true)
            $unitsButton.firstElementChild.textContent = openText;
            $currentEnrolledItemExpanded = $enrolledItem;
            $currentEnrolledCtaExpanded = $unitsButton;
        };

        var currentlyEnrolledTableItemEvent = function (target, event) {
            event.preventDefault();
            
            var $itemClicked = target,
                $enrolledItem = query.selectParentElement(
                    $itemClicked,
                    CONSTANTS.ENROLLED_ITEM_CLASS
                ),
                $serviceError = $enrolledItem.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0],
                $unitsButton = $enrolledItem.getElementsByClassName(CONSTANTS.ITEM_CTA_CLASS)[0];

            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        
            if (query.hasClass($enrolledItem, CONSTANTS.ENROLLED_ITEM_OPEN_CLASS)) {
                closeUnits($enrolledItem, $unitsButton);
            } else {
                // Before opening a new button, close the previous one, if any.
                $currentEnrolledItemExpanded && 
                $currentEnrolledCtaExpanded && 
                closeUnits($currentEnrolledItemExpanded, $currentEnrolledCtaExpanded);
                
                openUnits($enrolledItem, $unitsButton);

                if (!$enrolledItem.dataset.loaded) {
                    getEnrollUnitsList($enrolledItem, $unitsButton);
                    $enrolledItem.dataset.loaded = true;
                }
            }
        };

        var getEnrollUnitsList = function ($enrolledItem, $unitsButton) {
            var params = {
                ParentMaid: $enrolledItem.dataset.parentMaid,
                ScId: $currentlyEnrolled.dataset.scId
            };

            params = JSON.stringify(params);

            query.postData(
                $currentlyEnrolled.dataset.serviceUnits,
                function (data) {
                    successEnrollUnitsListCall(data, $enrolledItem, $unitsButton);
                },
                function (data) {
                    errorEnrollUnitsListCall(data, $enrolledItem, $unitsButton);
                },
                params,
                true
            );
        };

        var successEnrollUnitsListCall = function (data, $enrolledItem, $unitsButton) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_UNENROLL_UNITS_LIST,
                    function (data) {
                        processEnrollUnitsListCallData(data, $enrolledItem, $unitsButton);
                    },
                    function (data) {
                        errorEnrollUnitsListCall(data, $enrolledItem, $unitsButton);
                    },
                    null
                );
            } else {
                processEnrollUnitsListCallData(data, $enrolledItem, $unitsButton);
            }
        };

        var processEnrollUnitsListCallData = function (data, $enrolledItem, $unitsButton) {
            var $unenrollContainer = $enrolledItem.getElementsByClassName(
                CONSTANTS.UNENROLL_CONTAINER_CLASS
            )[0];

            $unenrollContainer.innerHTML = data;
            coned.utils.initializeModules($unenrollContainer);
            $unitsButton.focus();
        };

        var errorEnrollUnitsListCall = function (data, $enrolledItem, $unitsButton) {
            var $serviceError = $enrolledItem.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0],
                $serviceErrorMessage = $serviceError.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];

            closeUnits($enrolledItem, $unitsButton);

            $enrolledItem.removeAttribute(CONSTANTS.LOADED_ATTRIBUTE);
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var downloadEnrolledEvent = function (event) {
            event.preventDefault();

            // Analytics data building
            dataLayer.push({
                event: 'lofll',
                lofll: 'download.csv'
            });

            // If file source is available, trigger the file download
            if ($enrolledDownloadButton.dataset.fileLink) {
                window.location = $enrolledDownloadButton.dataset.fileLink;

                return;
            }

            // Service data
            var params = {
                Maid: $currentlyEnrolled.dataset.maid,
                ScId: $currentlyEnrolled.dataset.scId
            };

            params = JSON.stringify(params);

            // Show loading animation
            $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);

            // Service call
            query.postData(
                $currentlyEnrolled.dataset.downloadEnrolled,
                downloadEnrolledServiceSuccess,
                downloadEnrolledServiceError,
                params,
                true
            );
        };

        var downloadEnrolledServiceSuccess = function (data) {
            // Add file's url to the download button
            if (coned.utils.isPatternLab()) {
                $enrolledDownloadButton.dataset.fileLink = coned.plConstants.PAYMENT_VIEW_DATA;
            } else {
                if (data.url && data.url != '') {
                    $enrolledDownloadButton.dataset.fileLink = data.url;
                } else {
                    downloadEnrolledServiceError(data);

                    return;
                }
            }

            // Analytics data building
            dataLayer.push({
                event: 'lofll',
                lofll: 'success.data.request'
            });

            // Hide loading animation
            $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);

            // Trigger the file download
            window.location = $enrolledDownloadButton.dataset.fileLink;
        };

        var downloadEnrolledServiceError = function (data) {
            var $serviceError = $enrolledDescription.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR
            )[0],
                $serviceErrorMessage = $serviceError.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];

            // Analytics data building
            dataLayer.push({
                event: 'lofll',
                lofll: 'error.message'
            });

            // Set error message
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;

            // Hide loading animation
            $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
            $serviceError.focus();
        };

        var initializeData = function () {
            $enrolledDescription = document.getElementsByClassName(
                CONSTANTS.ENROLLED_DESCRIPTION_CLASS
            )[0];
            $enrolledDownloadButton = $enrolledDescription.getElementsByClassName(
                CONSTANTS.ENROLLED_DOWNLOAD_BUTTON
            )[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
        };

        var initializeEvents = function () {
            coned.utils.addParentListener(
                $currentlyEnrolled,
                coned.utils.generalEvents(),
                CONSTANTS.ITEM_ARROW,
                currentlyEnrolledTableItemEvent
            );

            if ($enrolledDownloadButton) {
                coned.utils.addGeneralListeners($enrolledDownloadButton, downloadEnrolledEvent);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CurrentlyEnrolled.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CurrentlyEnrolled;
})();
