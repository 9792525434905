// ==================== VALIDATE IMAGE COMPONENT =========================
/* global */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ValidateImage = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    // var CONSTANTS = {
    // };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ValidateImage = function ($image) {
        /**
         * PRIVATE METHODS
         */
        var _imgDefaultUrl, _imgSrc, $testImg;

        var testImgUrl = function () {
            $testImg = new Image();
            $testImg.onerror = imgError;
            $testImg.src = _imgSrc;
        };

        var imgError = function () {
            $image.setAttribute('src', _imgDefaultUrl);
        };

        var initializeData = function () {
            _imgDefaultUrl = $image.dataset.imageDefault;
            _imgSrc = $image.getAttribute('src', _imgSrc);
        };

        var initializeEvents = function () {
            testImgUrl();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ValidateImage.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ValidateImage;
})();
