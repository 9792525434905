// ==================== FACETED FILTERS COMPONENT =========================
/* global _ */
/* global $ */
/* global gsap */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FacetedFiltersComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        WRAPPER_BY_FILTER: 'js-search-filters-by',
        WRAPPER_TOPIC_FILTER: 'js-dropdown-topic',
        WRAPPER_TYPE_FILTER: 'js-dropdown-type',
        WRAPPER_FILTER_OPTIONS: 'js-search-filters-options',
        DROPDOWN_TITLE_LINK: 'js-dropdown-link',
        DROPDOWN_TITLE: 'js-dropdown-title',
        DROPDOWN_LIST: 'js-dropdown-list',
        FACETED_LIST: 'js-faceted-list',
        FACETED_ITEM: 'js-faceted-item',
        OPTION_TITLE: 'js-dropdown-list-item-title',
        OPTION_LINK: 'js-dropdown-list-item-link',
        OPTION_ACTIVE_CLASS: 'dropdown-list-item-link--active',
        BUTTON_ACTIVE_CLASS: 'dropdown-title--active',
        MOBILE_DROPDOWN_TITLE: 'dropdown-title--mobile',
        FACETED_CRITERIA: 'js-faceted-criteria',
        CATEGORY_BUTTON: 'js-category-button',
        FILTER_BUTTON: 'js-category-button',
        FILTER_ACTIVE_CLASS: 'search-filters__by-nav-item--active',
        SHOW_FILTERS_BUTTON: 'js-faceted-show-filters',
        SHOW_FILTERS_DESKTOP_BUTTON: 'js-faceted-show-filters-desktop',
        HIDE_FILTERS_BUTTON: 'js-faceted-bar-back',
        FILTER_OPTIONS: 'js-search-filters-options',
        FILTER_OPTIONS_VISIBLE_CLASS: 'faceted-search__filters-options--visible',
        FACETED_FILTER_BAR: 'js-faceted-filters-bar',
        FACETED_FILTER_WRAP: 'faceted-search__filters-options--wrap',
        FACETED_DROPDOWN_CLASS: 'dropdown-title--faceted-search',
        FACETED_CLEAR: 'js-faceted-bar-clear',
        FACETED_ARROW_ICON: 'js-icon-carrot',
        FACETED_ARROW_OPEN: 'drop-down-title-icon--open',
        RESULTS_TOTAL: 'js-total',
        PAGINATION_START_CLASS: 'js-start',
        PAGINATION_END_CLASS: 'js-end',
        FACETED_RESULTS: 'js-faceted-results',
        FACETED_NO_RESULTS: 'js-faceted-no-results',
        FACETED_VISIBLE: 'faceted-search--visible',
        FACETED_HIDDEN: 'faceted-search--hidden',
        PAGINATION_RESET_CLASS: 'js-pagination-reset',
        FACETED_PLACEHOLDER: 'js-faceted-placeholder',
        PAGINATION_MODULE: 'js-pagination-wrapper',
        DEPENDENCY_FILTERS: 'js-dependency-filters',
        DEPENDENCY_FILTER_CLASS: 'faceted-search__filters-options--dependency',
        HAS_FILTER_DEPENDENCY: 'has-dependency',
        PAGE_MIN_HEIGHT_CLASS: 'faceted-search__page-min-height',
        PAGINATION_CONTENT: 'js-pagination-content',
        NAV_ITEM_NAME: 'NAV',
        FACETED_NAV: 'js-faceted-criteria-nav',

        ARIA_EXPANDED: 'aria-expanded'
    };

    var isLoaded = false;
    var firstLoad = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var FacetedFiltersComponent = function ($FacetedFiltersComponent) {
        /**
         * PRIVATE METHODS
         */
        var $dropDownTitleLinks,
            $dropDownList,
            $optionLinks,
            $filterWrapper,
            $placeHolderTitle,
            $facetedList,
            $facetedItem,
            $categoryButtons,
            $facetedCriteria,
            $showFilterButton,
            $showFilterDesktopButton,
            $hideFilterButton,
            $clearFilterButton,
            $filterButtons,
            $filterOptions,
            $filterByOptions,
            $facetedFilterBar,
            $categoryButton,
            $dropDownArrows,
            $paginationResetElement,
            $total,
            $cloneItem,
            $facetedPlaceHolder,
            $dependencyFilters,
            $facetedNav,
            _$facetedResults,
            _$facetedNoResults,
            _$paginationModule,
            _paginationStart,
            _paginationEnd,
            _filterList,
            _items,
            _paginationContent,
            _isScroll;

        var removeActiveClass = function ($elements) {
            _.each($elements, function (element) {
                element.classList.remove(CONSTANTS.OPTION_ACTIVE_CLASS);
            });
        };

        var arrangeFilters = function () {
            var filtersVisible = query.hasClass($filterWrapper, CONSTANTS.DEPENDENCY_FILTER_CLASS),
                totalFiltersWidth = 0,
                pageWidth = _paginationContent.offsetWidth;

            _.each($facetedCriteria, function (factedItem) {
                totalFiltersWidth += factedItem.offsetWidth;
            });

            if (!filtersVisible && !coned.utils.isMobile() && totalFiltersWidth > pageWidth) {
                $filterWrapper.classList.add(CONSTANTS.DEPENDENCY_FILTER_CLASS);
                $filterWrapper.classList.add(CONSTANTS.FACETED_FILTER_WRAP);
                $showFilterDesktopButton.style.display = 'block';
                $showFilterDesktopButton.firstElementChild.innerHTML =
                    $showFilterDesktopButton.dataset.activeText;
                $filterWrapper.classList.add(CONSTANTS.FILTER_OPTIONS_VISIBLE_CLASS);
            } else if (totalFiltersWidth < pageWidth) {
                $filterWrapper.classList.remove(CONSTANTS.DEPENDENCY_FILTER_CLASS);
                $filterWrapper.classList.remove(CONSTANTS.FACETED_FILTER_WRAP);
                $showFilterDesktopButton.style.display = 'none';
            }
        };

        var elementsDisplay = function ($elements, displayValue) {
            _.each($elements, function (element) {
                element.style.display = displayValue;
            });
        };

        var selectedCategory = function (event) {
            event.preventDefault();

            var selectValue = event.target.dataset.value,
                defaultValue = event.target.parentNode.dataset.defaultFilter,
                dependencyFilter = document.querySelectorAll(
                    '[data-dependency-id="' + selectValue + '"]'
                ),
                dependecySiblings = document.querySelectorAll(
                    '[data-dependency-parent="' + defaultValue + '"]'
                );

            // clear previous results
            $facetedPlaceHolder.innerHTML = '';

            // remove active class parent filter
            _.each($filterButtons, function (filterButtons) {
                filterButtons.classList.remove(CONSTANTS.FILTER_ACTIVE_CLASS);
            });

            elementsDisplay(dependecySiblings, 'none');

            if (!firstLoad) {
                // reset dependencySiblings filter value and set default title
                _.each(dependecySiblings, function (siblings) {
                    siblings.dataset.filterCriteria = siblings.dataset.defaultFilter;
                    siblings.dataset.filterCriteria = siblings.dataset.defaultFilter;
                    var buttons = siblings.getElementsByClassName(CONSTANTS.OPTION_LINK);
                    removeUrlParameter(siblings.dataset.defaultFilter);

                    _.each(buttons, function (button) {
                        var buttonTitle = button.parentNode.parentNode.parentNode.getElementsByClassName(
                            CONSTANTS.DROPDOWN_TITLE
                        )[0];
                        buttonTitle.innerHTML = buttonTitle.parentNode.dataset.defaultTitle;
                        button.classList.remove(CONSTANTS.OPTION_ACTIVE_CLASS);
                    });
                });
            } else {
                firstLoad = false;
            }

            if (dependencyFilter.length > 0) {
                $dependencyFilters.style.display = 'flex';
                elementsDisplay(dependencyFilter, 'block');
            }

            this.classList.toggle(CONSTANTS.FILTER_ACTIVE_CLASS);
            this.parentNode.dataset.filterCriteria = selectValue;

            updateUrl(defaultValue, selectValue);

            arrangeFilters();
            doFilter();
        };

        var firstFilterElements = function () {
            var paginationDivider = _$paginationModule.dataset.divider,
                index = 1,
                displayTotal = paginationDivider * index,
                paginationTotal = $facetedPlaceHolder.getElementsByClassName(CONSTANTS.FACETED_ITEM)
                    .length,
                showPageIndex;

            if (paginationTotal) {
                _$facetedResults.style.display = 'block';
                _$facetedNoResults.style.display = 'none';
                $total.innerHTML = paginationTotal;
                _$paginationModule.dataset.activePage = 1;
                _paginationStart.innerHTML = paginationDivider * index - (paginationDivider - 1);
                _paginationEnd.innerHTML =
                    paginationDivider * index < paginationTotal
                        ? paginationDivider * index
                        : paginationTotal;
            } else {
                _$facetedResults.style.display = 'none';
                _$facetedNoResults.style.display = 'block';
            }

            $('.js-faceted-placeholder li').addClass('faceted-search__list-item--hidden');

            for (
                showPageIndex = displayTotal - paginationDivider;
                showPageIndex < displayTotal && showPageIndex < paginationTotal;
                showPageIndex++
            ) {
                $('.js-faceted-placeholder li:eq(' + showPageIndex + ')').removeClass(
                    'faceted-search__list-item--hidden'
                );
            }
        };

        // Prevent scrolling to be confused as click
        var setScrollState = function ($element) {
            $element.addEventListener('touchstart', function () {
                _isScroll = false;
            });
            $element.addEventListener('touchmove', function () {
                _isScroll = true;
            });
        };

        var selectedValue = function (event) {
            event.preventDefault();
            
            if (_isScroll) {
                // User was trying to scroll, not click
                return;
            }

            var $optionTitle = this.getElementsByClassName(CONSTANTS.OPTION_TITLE)[0].innerHTML,
                $selectTitle = this.parentNode.parentNode.parentNode.getElementsByClassName(
                    CONSTANTS.DROPDOWN_TITLE
                )[0],
                $defaultTitle = $selectTitle.parentNode.dataset.defaultTitle,
                $optionLinkActive = this.parentNode.parentNode.parentNode.getElementsByClassName(
                    CONSTANTS.OPTION_LINK
                ),
                isOptionActive = query.hasClass(this, CONSTANTS.OPTION_ACTIVE_CLASS),
                filterValue = this.dataset.value,
                defaultFilter = this.parentNode.parentNode.parentNode.dataset.defaultFilter,
                filterParent = this.parentNode.parentNode.parentNode,
                fakeClickMobile = filterParent.getElementsByClassName(
                    CONSTANTS.DROPDOWN_TITLE_LINK
                )[0],
                dependencyFilter = document.querySelectorAll(
                    '[data-dependency-id="' + filterValue + '"]'
                ),
                dependecySiblings = document.querySelectorAll(
                    '[data-dependency-parent="' + defaultFilter + '"]'
                ),
                arrow = this.parentNode.parentNode.parentNode.getElementsByClassName(
                    CONSTANTS.FACETED_ARROW_ICON
                )[0];

            arrow.classList.remove(CONSTANTS.FACETED_ARROW_OPEN);

            // remove active class for options
            removeActiveClass($optionLinkActive);

            // hide all dependency filter Siblings
            _.each(dependecySiblings, function (dependecySibling) {
                var $siblingsLinks = dependecySibling.getElementsByClassName(CONSTANTS.OPTION_LINK);
                dependecySibling.style.display = 'none';
                dependecySibling.dataset.filterCriteria = dependecySibling.dataset.defaultFilter;

                var buttons = dependecySibling.getElementsByClassName(CONSTANTS.OPTION_LINK);
                removeUrlParameter(dependecySibling.dataset.defaultFilter);

                _.each(buttons, function (button) {
                    var buttonTitle = button.parentNode.parentNode.parentNode.getElementsByClassName(
                        CONSTANTS.DROPDOWN_TITLE
                    )[0];
                    buttonTitle.innerHTML = buttonTitle.parentNode.dataset.defaultTitle;
                    button.classList.remove(CONSTANTS.OPTION_ACTIVE_CLASS);
                });
                // remove active class for options
                removeActiveClass($siblingsLinks);
            });

            updateUrl(defaultFilter, filterValue);

            // validate if dependency filter parent is active
            if (query.hasClass(filterParent, CONSTANTS.HAS_FILTER_DEPENDENCY) && dependencyFilter) {
                $dependencyFilters.style.display = 'flex';
            }

            if (isOptionActive) {
                $selectTitle.innerHTML = $defaultTitle;
                filterValue = defaultFilter;
                removeUrlParameter(defaultFilter);
            } else {
                $selectTitle.innerHTML = $optionTitle;
                this.classList.add(CONSTANTS.OPTION_ACTIVE_CLASS);

                if (dependencyFilter.length > 0) {
                    $dependencyFilters.style.display = 'flex';
                    elementsDisplay(dependencyFilter, 'block');
                }
            }

            // set the filter criteria value
            filterParent.dataset.filterCriteria = filterValue;
            arrangeFilters();
            doFilter();

            //Selected current open dropdown
            var dropDownActive = $selectTitle.parentNode;
            //redirected focus
            dropDownActive && query.hasClass(dropDownActive, CONSTANTS.BUTTON_ACTIVE_CLASS);

            if (!coned.utils.isMobile()) {
                dropDownActive && dropDownActive.focus();
                closeDropDown();
            } else {
                //If it is clicked, it means that the event occurs via the keyboard.
                //Then a redirect focus should be done
                if (event.type == 'click') {
                    dropDownActive && dropDownActive.focus();
                }
                fakeClickMobile.click();
            }
        };

        var updateUrl = function (filterName, filterValue) {
            var params = coned.utils.getUrlParameters();
            var paramKeys = _.keys(params);

            if (!paramKeys.includes(filterName) || params[filterName] !== filterValue) {
                params[filterName] = filterValue;
                coned.utils.setUrlParameters(params);
            }
        };

        /**
         * Gets the query string parameters and updates the filters values
         */
        var initializeFilters = function () {
            var params = coned.utils.getUrlParameters();
            var paramKeys = _.keys(params);

            _.each($facetedCriteria, function ($itemValue) {
                for (var i = 0; i < paramKeys.length; i++) {
                    if ($itemValue.dataset.filterCriteria == paramKeys[i]) {
                        // if is a nav element
                        if ($itemValue.tagName == CONSTANTS.NAV_ITEM_NAME) {
                            $categoryButton = $itemValue.querySelectorAll(
                                '[data-value="' + params[paramKeys[i]] + '"]'
                            )[0];
                            $itemValue.dataset.filterCriteria = params[paramKeys[i]];
                        } else {
                            var $title = $itemValue.getElementsByClassName(
                                CONSTANTS.DROPDOWN_TITLE
                            )[0];
                            var $listElement = $itemValue.querySelectorAll(
                                '[data-value="' + params[paramKeys[i]] + '"]'
                            )[0];
                            $title.innerHTML = $listElement.getElementsByClassName(
                                CONSTANTS.OPTION_TITLE
                            )[0].innerHTML;
                            $listElement.classList.add(CONSTANTS.OPTION_ACTIVE_CLASS);
                            $itemValue.dataset.filterCriteria = params[paramKeys[i]];

                            var dependencyFilter = document.querySelectorAll(
                                '[data-dependency-id="' + params[paramKeys[i]] + '"]'
                            );
                            // validate if dependency filter parent is active
                            if (
                                query.hasClass($itemValue, CONSTANTS.HAS_FILTER_DEPENDENCY) &&
                                dependencyFilter
                            ) {
                                $dependencyFilters.style.display = 'flex';
                            }

                            if (dependencyFilter.length > 0) {
                                elementsDisplay(dependencyFilter, 'block');
                            }
                        }
                    }
                }
            });
            // first loading of the page
            firstLoad = true;

            // force first filter
            if ($categoryButton != undefined) {
                $categoryButton.click();
            }

            firstFilterElements();
            
            if (!isLoaded) {
                checkCategoryButtons();
            }
        };

        var setFilterList = function ($facetedCriteria) {
            var filterFormat;
            var filterListArray = [];

            _.each($facetedCriteria, function (itemValue) {
                filterFormat = itemValue.dataset.filterCriteria;
                filterListArray.push(filterFormat);
            });

            return filterListArray;
        };

        var compareFilters = function (actualFilter, preFilter) {
            var filterArray = [];

            for (var compareIndex = 0; compareIndex < preFilter.length; compareIndex++) {
                if (preFilter[compareIndex] != actualFilter[compareIndex]) {
                    filterArray.push(compareIndex);
                }
            }

            return filterArray;
        };

        var doFilter = function () {
            var actualFilters = setFilterList($facetedCriteria);
            var actualFilterIndex = compareFilters(actualFilters, _filterList);

            // clear previous results
            $facetedPlaceHolder.innerHTML = '';

            _.each($facetedItem, function (facetedItem) {
                var actualState = true;
                var actualAttribute;

                for (var itemIndex = 0; itemIndex < actualFilterIndex.length; itemIndex++) {
                    actualAttribute = facetedItem.getAttribute(
                        'data-' + _filterList[actualFilterIndex[itemIndex]]
                    );

                    if (actualAttribute.includes('|')) {
                        var actualAttributes = actualAttribute.split('|');
                        var filterState = actualState;

                        for (
                            var attributeIndex = 0;
                            attributeIndex < actualAttributes.length;
                            attributeIndex++
                        ) {
                            if (
                                actualAttributes[attributeIndex] !=
                                actualFilters[actualFilterIndex[itemIndex]]
                            ) {
                                filterState = false;
                            } else {
                                filterState = true;
                                break;
                            }
                        }

                        if (!filterState) {
                            actualState = actualState && false;
                        } else {
                            actualState = true && actualState;
                        }
                    } else {
                        if (actualAttribute != actualFilters[actualFilterIndex[itemIndex]]) {
                            actualState = actualState && false;
                        } else {
                            actualState = true && actualState;
                        }
                    }
                }

                if (actualState) {
                    $cloneItem = facetedItem.cloneNode(true);
                    $facetedPlaceHolder.appendChild($cloneItem);
                }
            });

            _items = $facetedPlaceHolder.getElementsByClassName(CONSTANTS.FACETED_ITEM);
            $total.innerHTML = _items.length;

            firstFilterElements();
            $paginationResetElement.click();

            // set up faqs if it's the faceted faqs instance
            if (query.hasClass($facetedPlaceHolder, 'faceted-search__list--faq')) {
                new coned.components.CollapsibleContainerComponent($facetedPlaceHolder);
            }
        };

        var dropDownAnimation = function (event) {
            event.preventDefault();

            if (_isScroll) {
                // User was trying to scroll, not click
                return;
            }

            var isActive = query.hasClass(this, CONSTANTS.BUTTON_ACTIVE_CLASS),
                isMobile = query.hasClass(this, CONSTANTS.MOBILE_DROPDOWN_TITLE),
                $selectedDropDown = this.parentNode,
                deviceType = isMobile ? $FacetedFiltersComponent : $selectedDropDown,
                $selectedList = deviceType.getElementsByClassName(CONSTANTS.DROPDOWN_LIST),
                $arrowSelected = this.getElementsByClassName(CONSTANTS.FACETED_ARROW_ICON)[0];

            _.each($dropDownArrows, function (arrow) {
                arrow.classList.remove(CONSTANTS.FACETED_ARROW_OPEN);
            });

            $arrowSelected.classList.add(CONSTANTS.FACETED_ARROW_OPEN);

            if (!isActive) {
                closeDropDown();

                // animate the list
                gsap.from($selectedList, {
                    duration: 0,
                    y: -20,
                    opacity: 0
                });

                // show all list element
                _.each($selectedList, function (listItem) {
                    listItem.style.display = 'block';
                });

                _.each($dropDownTitleLinks, function (titleItem) {
                    titleItem.style.display = 'flex';
                });

                this.classList.toggle(CONSTANTS.BUTTON_ACTIVE_CLASS);
            } else {
                $arrowSelected.classList.remove(CONSTANTS.FACETED_ARROW_OPEN);
                closeDropDown();
            }

            if (coned.utils.isMobile()) {
                $facetedFilterBar.classList.add(CONSTANTS.FACETED_VISIBLE);
            }

            _.each($dropDownTitleLinks, function (titleItem) {
                query.hasClass(titleItem, CONSTANTS.BUTTON_ACTIVE_CLASS) &&
                    titleItem.setAttribute(CONSTANTS.ARIA_EXPANDED, 'true');
            });
        };

        var closeDropDown = function () {
            var defaultText = $showFilterButton.dataset.defaultText;

            _.each($dropDownList, function (dropDownList) {
                dropDownList.style.display = 'none';
                query.removeClass($dropDownTitleLinks, CONSTANTS.BUTTON_ACTIVE_CLASS);
            });

            _.each($dropDownTitleLinks, function (titleItem) {
                titleItem.setAttribute(CONSTANTS.ARIA_EXPANDED, 'false');
            });

            if (!coned.utils.isMobile()) {
                $showFilterButton.firstElementChild.innerHTML = defaultText;
                _.each($placeHolderTitle, function (placeholderTitle) {
                    placeholderTitle.style.display = 'flex';
                });
            }

            $facetedFilterBar.classList.remove(CONSTANTS.FACETED_VISIBLE);
        };

        // show the filter bar on mobile.
        var showFiltersMobile = function (event) {
            event.preventDefault();

            var defaultText = $showFilterButton.dataset.defaultText,
                activeText = $showFilterButton.dataset.activeText;

            $facetedFilterBar.classList.toggle(CONSTANTS.FACETED_VISIBLE);
            _paginationContent.classList.toggle(CONSTANTS.PAGE_MIN_HEIGHT_CLASS);

            // updated button copy
            if (query.hasClass($facetedFilterBar, CONSTANTS.FACETED_VISIBLE)) {
                this.firstElementChild.innerHTML = activeText;

                _.each($dropDownTitleLinks, function (filterTitle) {
                    filterTitle.classList.add(CONSTANTS.FACETED_DROPDOWN_CLASS);
                    filterTitle.style.display = 'flex';
                });
            } else {
                this.firstElementChild.innerHTML = defaultText;

                closeDropDown();
                _.each($dropDownTitleLinks, function (filterTitle) {
                    filterTitle.classList.remove(CONSTANTS.FACETED_DROPDOWN_CLASS);
                    filterTitle.style.display = 'none';
                });
            }
        };

        var showFiltersDesktop = function (event) {
            event.preventDefault();

            var defaultText = this.dataset.defaultText,
                activeText = this.dataset.activeText;

            $filterOptions.classList.toggle(CONSTANTS.FILTER_OPTIONS_VISIBLE_CLASS);

            // updated button copy
            if (query.hasClass($filterOptions, CONSTANTS.FILTER_OPTIONS_VISIBLE_CLASS)) {
                this.firstElementChild.innerHTML = activeText;
            } else {
                this.firstElementChild.innerHTML = defaultText;
            }
        };

        var closeFilterBar = function (event) {
            var _isHideFilterButton = event.currentTarget === $hideFilterButton;
            
            if (event != undefined) {
                event.preventDefault();
            }
            
            // Check if not scrolling, since on iOS when scrolling up, the bottom bar
            // of the browser tends to hide and a resize event is fired
            if (!_isScroll || _isHideFilterButton) {
                var defaultText = $showFilterButton.dataset.defaultText;
                $showFilterButton.firstElementChild.innerHTML = defaultText;

                $facetedFilterBar.classList.remove(CONSTANTS.FACETED_VISIBLE);

                _.each($dropDownArrows, function (arrow) {
                    arrow.classList.remove(CONSTANTS.FACETED_ARROW_OPEN);
                });

                _.each($placeHolderTitle, function (placeholderTitle) {
                    placeholderTitle.style.display = 'none';
                });

                _paginationContent.classList.remove(CONSTANTS.PAGE_MIN_HEIGHT_CLASS);
                closeDropDown();

                $showFilterButton.focus();
            }
        };

        // clear filters and remove active class from options lists
        var clearFilters = function (event) {
            event.preventDefault();

            var activeByButton = $filterByOptions.getElementsByClassName(
                CONSTANTS.FILTER_ACTIVE_CLASS
            )[0];

            // reset filters criteria
            _.each($facetedCriteria, function (filter) {
                filter.dataset.filterCriteria = filter.dataset.defaultFilter;
            });

            // set the by filter criteria
            activeByButton.click();

            _.each($optionLinks, function (optionLink) {
                optionLink.classList.remove(CONSTANTS.OPTION_ACTIVE_CLASS);
            });

            doFilter();
        };

        var removeUrlParameter = function (filterName) {
            var params = coned.utils.getUrlParameters();
            params = _.omit(params, filterName);
            coned.utils.setUrlParameters(params);
        };

        // check if there is exactly one element in the category nav, if there is, convert the nav and 
        // buttons to div elements as buttons will not be needed
        var checkCategoryButtons = function () {
            var childElements = $facetedNav.children.length;

            if (childElements === 1) {
                // Replace nav for div
                $filterByOptions.innerHTML = $filterByOptions.innerHTML.replace(/<nav/gi, "<div");
                $filterByOptions.innerHTML = $filterByOptions.innerHTML.replace(/<\/nav>/gi, "</div>");

                // Replace button for div
                $filterByOptions.innerHTML = $filterByOptions.innerHTML.replace(/<button/gi, "<div");
                $filterByOptions.innerHTML = $filterByOptions.innerHTML.replace(/<\/button>/gi, "</div>");

                // Replace span for p
                $filterByOptions.innerHTML = $filterByOptions.innerHTML.replace(/<span/gi, "<p");
                $filterByOptions.innerHTML = $filterByOptions.innerHTML.replace(/<\/span>/gi, "</p>");
            }
        }

        var initializeData = function () {
            $dropDownTitleLinks = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_TITLE_LINK
            );
            $dropDownList = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_LIST
            );
            $optionLinks = $FacetedFiltersComponent.getElementsByClassName(CONSTANTS.OPTION_LINK);
            $filterWrapper = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.WRAPPER_FILTER_OPTIONS
            )[0];
            $filterOptions = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.FILTER_OPTIONS
            )[0];
            $placeHolderTitle = $filterWrapper.getElementsByClassName(
                CONSTANTS.DROPDOWN_TITLE_LINK
            );
            $facetedList = document.getElementsByClassName(CONSTANTS.FACETED_LIST)[0];
            $total = document.getElementsByClassName(CONSTANTS.RESULTS_TOTAL)[0];
            $facetedItem = $facetedList.getElementsByClassName(CONSTANTS.FACETED_ITEM);
            $categoryButtons = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.CATEGORY_BUTTON
            );
            $categoryButton = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.CATEGORY_BUTTON
            )[0];
            $filterButtons = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.FILTER_BUTTON
            );
            $facetedFilterBar = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.FACETED_FILTER_BAR
            )[0];
            $showFilterButton = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.SHOW_FILTERS_BUTTON
            )[0];
            $showFilterDesktopButton = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.SHOW_FILTERS_DESKTOP_BUTTON
            )[0];
            $hideFilterButton = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.HIDE_FILTERS_BUTTON
            )[0];
            $clearFilterButton = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.FACETED_CLEAR
            )[0];
            $dropDownArrows = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.FACETED_ARROW_ICON
            );
            $facetedCriteria = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.FACETED_CRITERIA
            );
            $paginationResetElement = document.getElementsByClassName(
                CONSTANTS.PAGINATION_RESET_CLASS
            )[0];
            $facetedPlaceHolder = document.getElementsByClassName(CONSTANTS.FACETED_PLACEHOLDER)[0];
            $facetedNav = $FacetedFiltersComponent.getElementsByClassName(CONSTANTS.FACETED_NAV)[0];
            _$paginationModule = document.getElementsByClassName(CONSTANTS.PAGINATION_MODULE)[0];
            _paginationEnd = document.getElementsByClassName(CONSTANTS.PAGINATION_END_CLASS)[0];
            _paginationStart = document.getElementsByClassName(CONSTANTS.PAGINATION_START_CLASS)[0];
            _$facetedResults = document.getElementsByClassName(CONSTANTS.FACETED_RESULTS)[0];
            _$facetedNoResults = document.getElementsByClassName(CONSTANTS.FACETED_NO_RESULTS)[0];
            _paginationContent = document.getElementsByClassName(CONSTANTS.PAGINATION_CONTENT)[0];
            _filterList = setFilterList($facetedCriteria);
            $dependencyFilters = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.DEPENDENCY_FILTERS
            )[0];
            $filterByOptions = $FacetedFiltersComponent.getElementsByClassName(
                CONSTANTS.WRAPPER_BY_FILTER
            )[0];
        };

        var initializeEvents = function () {
            // add event listener to dropdown list
            _.each($dropDownTitleLinks, function ($dropDownTitleLink) {
                setScrollState($dropDownTitleLink);
                coned.utils.addGeneralListeners($dropDownTitleLink, dropDownAnimation);
            });

            // add event listener to optionLinks
            _.each($optionLinks, function ($optionLink) {
                setScrollState($optionLink);
                coned.utils.addGeneralListeners($optionLink, selectedValue);
            });

            // add event to category filter buttons
            _.each($categoryButtons, function ($categoryButton) {
                coned.utils.addGeneralListeners($categoryButton, selectedCategory);
            });

            // show filters for mobile
            coned.utils.addGeneralListeners($showFilterButton, showFiltersMobile);

            // hide filters mobile
            coned.utils.addGeneralListeners($hideFilterButton, closeFilterBar);

            // add event to clear filter button
            coned.utils.addGeneralListeners($clearFilterButton, clearFilters);

            // add event to show filters on desktop small screens
            coned.utils.addGeneralListeners($showFilterDesktopButton, showFiltersDesktop);

            // resize listener
            window.addEventListener('resize', closeFilterBar);
            window.addEventListener('resize', arrangeFilters);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            initializeFilters();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FacetedFiltersComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FacetedFiltersComponent;
})();
