// ==================== FORM TO TABLE =========================
var coned = coned || {};

coned.components = coned.components || {};
/**
 * @returns the init function to start the module
 */
coned.components.FormToTable = (function () {

    /**
     * @typedef {Object} SelectState
     * @property {string} name
     * @property {string} value
     */

    /**
     * @typedef {Object} ROW
     * @property {Record<string, string>} combination
     * @property {HTMLElement} $row
     */

    /**
     * @typedef {Object} State
     * @property {SelectState[]} selects
     * @property {ROW[]} rows
     * @property {ROW[]} selectedRows
     * @property {Record<string, string>} [selectedCombination]
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        CLASSES: {
            /**
             * @type {'form-to-table__wrapper-table--show'}
             */
            SHOW_TABLE: 'form-to-table__wrapper-table--show'
        },
        SELECTORS: {
            /**
             * @type {'js-select-single'}
             */
            SELECTS: 'js-select-single',
            /**
             * @type {'table tbody > tr'}
             */
            ROWS: 'table tbody > tr',
            /**
            * @type {'table tbody > tr[data-value="no-results"]'}
            */
            DEFAULT_ROW: 'table tbody > tr[data-value="no-results"]',
            /**
             * @type {'js-form-to-table-wrapper-table'}
             */
            WRAPPER_TABLE: 'js-form-to-table-wrapper-table'
        }
    };
    var isLoaded = false;
    /**
     * Constructor
     * @param { HTMLDivElement } $formToTable
     * @returns {}
     */
    var FormToTable = function ($formToTable) {
        /**
         * PRIVATE VARIABLES
         */
        /**
         * @type {State}
         */
        var _state,
            /**
             * @type {HTMLElement[]}
             */
            $selects,
            /**
             * @type {HTMLElement[]}
             */
            $rows,
            /**
             * @type {HTMLElement}
             */
            $defaultRow,
            /**
             * @type {HTMLElement}
             */
            $wrapperTable;


        /**
         * PRIVATE METHODS
         */

        /**
         * Update table selected in state
         * @param {Record<string, string>} combination
         */
        var setSelectedRows = function (combination) {
            var isEmptyCombination = Object.keys(combination).length === 0 || !combination;
            if (_state.selectedCombination &&
                coned.utils.isSameRecord(_state.selectedCombination, combination)
            ) {
                return;
            }
            _state.selectedCombination = combination;
            _state.selectedRows.forEach(function (el) {
                el.$row.setAttribute(
                    coned.constants.ARIA.HIDDEN,
                    coned.constants.TRUE
                );
            });
            $defaultRow.setAttribute(
                coned.constants.ARIA.HIDDEN,
                coned.constants.TRUE
            );
            _state.selectedRows = _state.rows.filter(function (el) {
                return !isEmptyCombination && coned.utils.isMatchedRecord(el.combination, combination);
            });
            if (_state.selectedRows.length > 0) {
                _state.selectedRows.forEach(function (el) {
                    el.$row.setAttribute(
                        coned.constants.ARIA.HIDDEN,
                        coned.constants.FALSE
                    );
                });
                if ($wrapperTable && !$wrapperTable.classList.contains(
                    CONSTANTS.CLASSES.SHOW_TABLE
                )) {
                    $wrapperTable.classList.add(
                        CONSTANTS.CLASSES.SHOW_TABLE
                    );
                }
                $defaultRow.setAttribute(
                    coned.constants.ARIA.HIDDEN,
                    coned.constants.TRUE
                );
            } else {
                if (!isEmptyCombination) {
                    $defaultRow.setAttribute(
                        coned.constants.ARIA.HIDDEN,
                        coned.constants.FALSE
                    );
                } else if ($wrapperTable && $wrapperTable.classList.contains(
                    CONSTANTS.CLASSES.SHOW_TABLE
                )) {
                    $wrapperTable.classList.remove(
                        CONSTANTS.CLASSES.SHOW_TABLE
                    );
                }
            }
        }
        /**
         * Update state
         * @param {SelectState} selectState 
         */
        var setState = function (selectState) {
            /**
             * @type {Record<string, string>}
             */
            var newCombination = {}
            _state.selects = _state.selects.map(function (el) {
                if (el.name === selectState.name) {
                    el.value = selectState.value
                }
                if (el.value.length > 0) {
                    newCombination[el.name] = el.value
                }
                return el;
            });
            setSelectedRows(newCombination);
            coned.utils.triggerEvent(
                $formToTable,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _state
            );
        }
        /**
         * Get array of Slects Initial State
         * @returns {SelectState[]}
         */
        var getSelects = function () {
            return $selects.map(function ($el) {
                return {
                    name: $el.dataset.name ? $el.dataset.name : '',
                    value: ''
                };
            });
        }
        /**
         * Get array of combination to show and table Element
         * @returns {TableItem[]}
         */
        var getRows = function () {
            /**
             * @type {ROW[]}
             */
            var rows = [];
            $rows.forEach(function ($el) {
                if (typeof $el.dataset.combination === 'string') {
                    rows.push({
                        combination: $el.dataset.combination.split(', ').reduce(function (acc, value) {
                            var propertyValue = value.split(':');
                            acc[propertyValue[0]] = propertyValue[1];
                            return acc
                        }, {}),
                        $row: $el
                    });
                }
            });
            return rows;
        }
        /**
         * Callback function to listen selects change
         * @param {CustomEvent<SelectState>} event 
         */
        var handleChangeSelect = function (event) {
            setState(event.detail);
        }
        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            $selects = coned.utils.arrayFrom(
                $formToTable.getElementsByClassName(CONSTANTS.SELECTORS.SELECTS)
            );
            $wrapperTable = $formToTable.getElementsByClassName(CONSTANTS.SELECTORS.WRAPPER_TABLE)[0];
            $rows = coned.utils.arrayFrom(
                $formToTable.querySelectorAll(CONSTANTS.SELECTORS.ROWS)
            );
            $defaultRow = $formToTable.querySelector(CONSTANTS.SELECTORS.DEFAULT_ROW);
            _state = {
                selects: getSelects(),
                rows: getRows(),
                selectedRows: []
            };
        }
        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            $selects.forEach(function ($el) {
                $el.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handleChangeSelect
                );
            });
        }
        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    FormToTable.prototype.isLoaded = function () {
        return isLoaded;
    };
    return FormToTable;
})();