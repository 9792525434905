// ==================== ACCORDION LIST COMPONENT =========================
/* global _ */
/* global query */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AccordionList = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        OPEN_ACCORDION_SELECTOR: 'js-accordion-selector',
        LAZY_LOAD_CLASS: 'js-lazy-load',
        SCENARIO_CLASS: 'js-scenario-page',
        LAZY_LOAD_LOADED_CLASS: 'js-lazy-load-element-loaded',
        ACCORDION_CONTAIN: 'js-accordion-contain',
        ITEM_CONTAIN: 'js-item-contain',
        ICON_SELECTOR: 'js-icon-selector',
        SUBMIT_BUTTON: 'js-submit-button',
        CLOSE_ACCORDION_SELECTOR: 'js-accordion-close',
        HIDDEN_CLASS: 'hidden',
        ACTIVE_CLASS: 'active',
        VISIBLE_CLASS: 'visible',
        INACTIVE_CLASS: 'manage-enroll__list--inactive',
        ALERT_CLASS: 'manage-enroll__list--alert',
        COLLAPSIBLE_CLASS: 'manage-enroll__contain--close',
        LINK_DESCRIPTION: 'js-link-description',
        ITEM_ANIMATION: 'check-list__item--animation',
        ICON_ENROLL: 'js-icon-enroll',
        ICON_CHECK_STATUS: 'icon-check-status',
        ICON_CLOSE: 'icon-close',
        ICON_WARNING: 'icon-safety-precaution',
        SERVICE_ERROR: 'js-service-error',
        DIV_LOADING: 'js-div-loading',
        ROTATE_ICON_SELECTOR: 'js-rotate-icon-selector',
        ROTATE_ICON_MODIFIER: 'transactional-review__edit-link--icon-rotate',

        // Accessibility Constants
        ARIA_EXPANDED_ATTRIBUTE: 'aria-expanded',
        ARIA_HIDDEN_ATTRIBUTE: 'aria-hidden',
        FOCUS_PLACEHOLDER_CLASS: 'js-focus-placeholder'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AccordionList = function ($accordion) {
        /**
         * PRIVATE METHODS
         */
        var $accordionsSelector,
            $submitButtons,
            $itemContain,
            $serviceError,
            $lazyLoadItems,
            $scenarioItems,
            $tabListenerElement,
            $divLoading,
            $currentOpenedItem,
            $focusPlaceholder,
            deepLinkClick,
            _isMultipleGlobalLazyLoadElement;

        // Handles the opening of any of the accordion items
        var openAccordion = function ($target) {
            var $targetAccordionSelector = $target.getElementsByClassName(
                    CONSTANTS.OPEN_ACCORDION_SELECTOR
                )[0],
                $targetIconContain = $target.getElementsByClassName(CONSTANTS.ICON_ENROLL)[0],
                $targetAccordionContain = $target.getElementsByClassName(
                    CONSTANTS.ACCORDION_CONTAIN
                )[0],
                closeText = $targetAccordionContain.dataset.closeName;

            // Changes the text of the button
            $targetAccordionSelector.getElementsByClassName(
                CONSTANTS.LINK_DESCRIPTION
            )[0].innerHTML = closeText;

            // Shows target element
            $target.classList.remove(CONSTANTS.ITEM_ANIMATION);
            if ($targetIconContain.classList.contains(CONSTANTS.ROTATE_ICON_SELECTOR)) {
                $targetIconContain.classList.add(CONSTANTS.ROTATE_ICON_MODIFIER);
            } else {
                $targetIconContain.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
            $targetAccordionSelector.classList.add(CONSTANTS.ACTIVE_CLASS);
            $targetAccordionContain.classList.remove(CONSTANTS.COLLAPSIBLE_CLASS);
            $targetAccordionContain.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Sets current opened item to the target
            $currentOpenedItem = $target;

            // Sets Accessibility attributes
            $targetAccordionSelector.setAttribute(CONSTANTS.ARIA_EXPANDED_ATTRIBUTE, true);
            $targetAccordionContain.setAttribute(CONSTANTS.ARIA_HIDDEN_ATTRIBUTE, false);
            if ($focusPlaceholder) {
                $focusPlaceholder.focus({ preventScroll: true });
            }
            setTimeout(function () {
                $targetAccordionSelector.focus();
            }, 500);
        };

        // Handles the collapsing of any of the accordion items
        var closeAccordion = function ($target, noFocusFlag) {
            var $targetAccordionSelector = $target.getElementsByClassName(
                    CONSTANTS.OPEN_ACCORDION_SELECTOR
                )[0],
                $targetIconContain = $target.getElementsByClassName(CONSTANTS.ICON_ENROLL)[0],
                $targetAccordionContain = $target.getElementsByClassName(
                    CONSTANTS.ACCORDION_CONTAIN
                )[0],
                openText = $targetAccordionContain.dataset.openName;

            // Changes the text of the button
            $targetAccordionSelector.getElementsByClassName(
                CONSTANTS.LINK_DESCRIPTION
            )[0].innerHTML = openText;

            // Add openable class to target
            $targetAccordionContain.classList.add(CONSTANTS.COLLAPSIBLE_CLASS);
            $targetAccordionContain.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Triggers Closing animation for the target element
            $target.classList.add(CONSTANTS.ITEM_ANIMATION);
            if ($targetIconContain.classList.contains(CONSTANTS.ROTATE_ICON_SELECTOR)) {
                $targetIconContain.classList.remove(CONSTANTS.ROTATE_ICON_MODIFIER);
            } else {
                $targetIconContain.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
            $targetAccordionSelector.classList.remove(CONSTANTS.ACTIVE_CLASS);

            // Hides any error on the target item in case there's any
            $serviceError = $targetAccordionContain.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR
            )[0];
            if ($serviceError) {
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            // Sets current opened item to null
            $currentOpenedItem = null;

            // Sets Accessibility attributes
            $targetAccordionSelector.setAttribute(CONSTANTS.ARIA_EXPANDED_ATTRIBUTE, false);
            $targetAccordionContain.setAttribute(CONSTANTS.ARIA_HIDDEN_ATTRIBUTE, true);
            if (!noFocusFlag) {
                $focusPlaceholder && $focusPlaceholder.focus({ preventScroll: true });
                setTimeout(function () {
                    var isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

                    if (isSafari) { // if is safari
                        $targetAccordionSelector.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center' });
                    }
                    $targetAccordionSelector.focus();
                }, 500);
            }
        };

        // Handles which event should be triggered with the items
        var accordionEvent = function ($target) {
            if ($currentOpenedItem) {
                if ($currentOpenedItem === $target) {
                    if (!deepLinkClick) {
                        closeAccordion($target);
                    // Don't close the accordion since click is coming from 
                    // a deep link so section needs to be open
                    } else {
                        deepLinkClick = false;
                    }
                } else {
                    closeAccordion($currentOpenedItem, true);
                    openAccordion($target);
                }
            } else {
                openAccordion($target);
            }
        };

        // Handles the submit event click
        var submitEvent = function ($target) {
            var $targetIconSelector = $target.getElementsByClassName(CONSTANTS.ICON_SELECTOR)[0];

            // Closes current element
            closeAccordion($target);

            // Removes any error or warning classes
            $target.classList.remove(CONSTANTS.ALERT_CLASS);
            $target.classList.remove(CONSTANTS.INACTIVE_CLASS);
            $targetIconSelector.classList.remove(CONSTANTS.ICON_CLOSE);
            $targetIconSelector.classList.remove(CONSTANTS.ICON_WARNING);

            // Sets icon to checked as the form is now completed
            $targetIconSelector.classList.add(CONSTANTS.ICON_CHECK_STATUS);
        };

        // Hides content and toggles the loading animation on lazy-load start
        var onLazyLoadStart = function () {
            $accordion.style.visibility = CONSTANTS.HIDDEN_CLASS;
            if ($divLoading.length) {
                $divLoading[0].classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        // Handles lazy-load modules inside the accordion
        var onLazyLoadModuleLoad = function () {
            // Wait until all modules are loaded
            if (query.listHasClass($lazyLoadItems, CONSTANTS.LAZY_LOAD_LOADED_CLASS)) {
                $accordion.style.visibility = CONSTANTS.VISIBLE_CLASS;
                if ($divLoading.length) {
                    $divLoading[0].classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                // Initialize accordion elements that relied on lazy-load items
                $accordionsSelector = $accordion.getElementsByClassName(
                    CONSTANTS.OPEN_ACCORDION_SELECTOR
                );
                $submitButtons = $accordion.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON);

                // Add missing events
                _.each($accordionsSelector, function ($accordionSelector) {
                    coned.utils.addGeneralListeners($accordionSelector, function (event) {
                        if (event !== undefined) {
                            event.preventDefault();
                        }

                        var $target = query.selectParentElement(
                            event.target,
                            CONSTANTS.ITEM_CONTAIN
                        );

                        accordionEvent($target);
                    });
                });

                _.each($submitButtons, function ($submitButton) {
                    coned.utils.addGeneralListeners($submitButton, function (event) {
                        if (event !== undefined) {
                            event.preventDefault();
                        }

                        var $target = query.selectParentElement(
                            event.target,
                            CONSTANTS.ITEM_CONTAIN
                        );

                        submitEvent($target);
                    });
                });

                // Remove lazy-load listeners so they don't trigger anymore
                if ($tabListenerElement.length) {
                    $tabListenerElement[0].removeEventListener('lazy-load-start', onLazyLoadStart);
                }
                _.each($lazyLoadItems, function (item) {
                    item.removeEventListener('lazy-load-element-loaded', onLazyLoadModuleLoad);
                });
                
                if (_isMultipleGlobalLazyLoadElement) {
                    coned.utils.triggerEvent(window, coned.constants.MULTIPLE_GLOBAL_LAZY_LOAD_EVENT_DONE);
                }
            }
        };

        var closeThisAccordion = function (event) {
            if (event) {
                if (
                    query.selectParentElement(event.target, CONSTANTS.CLOSE_ACCORDION_SELECTOR)
                ) {
                    event.preventDefault();
                    closeAccordion(query.selectParentElement(event.target, CONSTANTS.ITEM_CONTAIN));
                }
            }
        };

        var initializeData = function () {
            $itemContain = $accordion.getElementsByClassName(CONSTANTS.ITEM_CONTAIN);
            $lazyLoadItems = $accordion.getElementsByClassName(CONSTANTS.LAZY_LOAD_CLASS);
            $scenarioItems = $accordion.getElementsByClassName(CONSTANTS.SCENARIO_CLASS);
            $tabListenerElement = document.getElementsByClassName(
                CONSTANTS.LAZY_LOAD_CLASS + '-' + $accordion.dataset.lazyLoadTrigger
            );
            $divLoading = $accordion.parentElement.getElementsByClassName(CONSTANTS.DIV_LOADING);
            $focusPlaceholder = document.getElementsByClassName(
                CONSTANTS.FOCUS_PLACEHOLDER_CLASS
            )[0];
            $currentOpenedItem = null;
            deepLinkClick = false;

            // If any accordion element is being lazy-loaded, wait to assign variables
            if (!$lazyLoadItems.length) {
                $accordionsSelector = $accordion.getElementsByClassName(
                    CONSTANTS.OPEN_ACCORDION_SELECTOR
                );
                $submitButtons = $accordion.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON);
                _isMultipleGlobalLazyLoadElement = false;
            } else {
                query.addClass($accordion, coned.constants.MULTIPLE_GLOBAL_LAZY_LOAD_ELEMENT);
                _isMultipleGlobalLazyLoadElement = true;
            }
        };

        var initializeEvents = function () {
            _.each($itemContain, function ($item) {
                coned.utils.addGeneralListeners($item, closeThisAccordion);
                $item.addEventListener('deeplink-event', function (event) {
                    deepLinkClick = true;
                    accordionEvent(event.target);
                });
            });

            // If any accordion element is being lazy-loaded, wait to bind events
            if (!$lazyLoadItems.length) {
                _.each($accordionsSelector, function ($accordionSelector) {
                    coned.utils.addGeneralListeners($accordionSelector, function (event) {
                        if (event !== undefined) {
                            event.stopPropagation();
                            event.preventDefault();
                        }

                        var $target = query.selectParentElement(
                            event.target,
                            CONSTANTS.ITEM_CONTAIN
                        );

                        accordionEvent($target);
                    });
                });

                _.each($submitButtons, function ($submitButton) {
                    coned.utils.addGeneralListeners($submitButton, function (event) {
                        if (event !== undefined) {
                            event.preventDefault();
                        }

                        var $target = query.selectParentElement(
                            event.target,
                            CONSTANTS.ITEM_CONTAIN
                        );

                        submitEvent($target);
                    });
                });
            }

            // Check if the accordion has any lazy-load items
            if ($lazyLoadItems.length) {
                // Start the loading animation while items load
                if ($tabListenerElement.length) {
                    $tabListenerElement[0].addEventListener('lazy-load-start', onLazyLoadStart);
                }

                // As soon as all items with lazy-load are loaded, remove loading animation and assign/remove necessary events
                _.each($lazyLoadItems, function (item) {
                    item.addEventListener('lazy-load-element-loaded', onLazyLoadModuleLoad);
                });

            }

            if (coned.utils.isPatternLab() && $scenarioItems.length) {
                // As soon as all items with lazy-load are loaded, remove loading animation and assign/remove necessary events
                _.each($scenarioItems, function (item) {
                    item.addEventListener('scenarios-element-loaded', function (evt) {
                        var $accordionSelector = evt.target;

                        coned.utils.addGeneralListeners($accordionSelector, function (event) {
                            if (event !== undefined) {
                                event.preventDefault();
                            }

                            var $target = query.selectParentElement(
                                event.target,
                                CONSTANTS.ITEM_CONTAIN
                            );

                            accordionEvent($target);
                        });
                    });
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AccordionList.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AccordionList;
})();
