// ==================== CONTENT BLOCK COMPONENT =========================
/* global dataLayer */
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

coned.components.ContentBlock = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CONTENT_BLOCK_DESCRIPTION: '.content-block__description',
        CONTENT_BLOCK_CTA: '.content-block__cta'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */

    var ContentBlock = function ($contentBlock) {
        /**
         * PRIVATE METHODS
         */

        var addTagEventsToDescriptionLinks = function () {
            var contentBlockDescription = $contentBlock.querySelector(
                CONSTANTS.CONTENT_BLOCK_DESCRIPTION
            );
            var paragraphs = contentBlockDescription.children;

            for (var x = 0; x < paragraphs.length; x++) {
                var paragraph = paragraphs[x],
                    links = _.filter(paragraph.children, function (elem) {
                        return elem.tagName && elem.tagName.toLowerCase() === 'a';
                    });

                for (var y = 0; y < links.children; y++) {
                    var link = links[y];

                    link.addEventListener('click', function (e) {
                        if (e.target.dataset.tagValue) {
                            dataLayer.push({ event: e.target.dataset.tagValue });
                        }
                    });
                }
            }
        };

        var addTagEventsToCtas = function () {
            var contentBlockCTA = $contentBlock.querySelector(CONSTANTS.CONTENT_BLOCK_CTA);

            if (contentBlockCTA) {
                contentBlockCTA.addEventListener('click', function (e) {
                    if (e.currentTarget.dataset.tagValue) {
                        dataLayer.push({ event: e.currentTarget.dataset.tagValue });
                    }
                });
            }
        };

        var initializeEvents = function () {
            addTagEventsToDescriptionLinks();
            addTagEventsToCtas();
        };

        function init() {
            initializeEvents();
            isLoaded = true;
        }

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ContentBlock.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ContentBlock;
})();
