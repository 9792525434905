// ==================== DASHBOARD DIRECT PAYMENT COMPONENT =========================
/* global _ */
/* global dataLayer */
/* global QSI */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardDirectPaymentPlan = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM: '.js-direct-payment-form',
        ENROLL_FORM_SELECTOR: 'js-direct-payment-form',
        MANAGE_FORM_SELECTOR: 'js-direct-payment-manage',
        ORU_MANAGE_FORM: 'js-direct-payment-oru-manage',
        ENROLL_SUCCESS_MESSAGE: 'js-direct-payment-enroll-message',
        CONFIRM_ENROLL_BUTTON: 'js-confirm-enroll-button',
        ENROLL_CLOSE_BUTTON: 'js-enroll-close-button',
        CLOSE_SELECTOR: 'js-accordion-close',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        ICON_STATUS_TEXT: 'icon-status-text',
        CONTAIN_CHECKING_ACCOUNT: 'js-checking-account-contain',
        CONTAIN_ROUTING_ABANUMBER: 'js-routing-abanumber-contain',
        CONTAIN_ACCOUNT_NUMBER: 'js-account-number-contain',
        CONTAINER_CHECKING_ACCOUNT: 'js-checking-account-container',
        CONTAINER_ROUTING_ABANUMBER: 'js-routing-abanumber-container',
        CONTAINER_ACCOUNT_NUMBER: 'js-account-number-container',
        UNENROLL_BUTTON_SELECTOR: 'js-unenroll-button',
        UNENROLL_CONFIRMATION_SELECTOR: 'js-direct-payment-unenroll-confirmation',
        UNENROLL_CONFIRMATION_CANCEL_SELECTOR: 'js-direct-payment-unenroll-cancel-confirmation',
        LINK_DESCRIPTION: 'js-link-description',
        DATA_ENROLLED: 'data-enrolled',
        DATA_NOT_ENROLLED: 'data-not-enrolled',
        RADIO_LABEL_CLASS: 'coned-radio__label-name',
        HIDDEN_CLASS: 'hidden',
        FORM_SCID: 'scId',
        FORM_ACCOUNT_NUMBER: 'accountNumber',
        FORM_ACCOUNT_TYPE: 'directPaymentAccountType',
        FORM_ROUTING_ABA: 'routingAbaNumber',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        HEADER: 'js-header-wrapper',
        UPDATED_MESSAGE: 'js-updated-message',
        DEFAULT_VALIDATOR_HIDDEN_SELECTOR: ':hidden'
    };

    var isLoaded = false;
    var _isManage = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardDirectPaymentPlan = function ($directPaymentForm) {
        /**
         * PRIVATE METHODS
         */
        var $linkAccordionSelector,
            $enrollFormSelector,
            $manageFormSelector,
            $oruManageForm,
            $enrollSuccessMessage,
            $confirmEnrollButton,
            $enrollCloseButton,
            $accordionSelector,
            $containCheckingAccount,
            $containRoutingAbaNumber,
            $containAccountNumber,
            $containerCheckingAccount,
            $containerRoutingAbaNumber,
            $containerAccountNumber,
            $unenrollButtonSelector,
            $unenrollConfirmationSelector,
            $unenrollConfirmationCancelButton,
            $serviceError,
            $serviceErrorMessage,
            $accountMaidInput,
            $header,
            $formLoading,
            $updateMessage,
            $iconStatusTextSpan,
            _hasCloseEvent,
            _dppFlag;

        var setStatement = function () {
            var statementFlag = $accordionSelector.dataset.statement;

            if (statementFlag == 'false') {
                $enrollFormSelector && $enrollFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $directPaymentForm && $directPaymentForm.classList.add(CONSTANTS.UNENROLLMENT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_NOT_ENROLLED
                );
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.enroll;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.enroll;
                _isManage = false;
            } else {
                if ($manageFormSelector !== undefined) {
                    $manageFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else if ($oruManageForm) {
                    $oruManageForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }

                $enrollFormSelector && $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $directPaymentForm && $directPaymentForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_ENROLLED
                );
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.manage;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
                _isManage = true;
            }
        };

        var onSubmitForm = function () {
            var $radioText;

            // setting new data
            if ($containerRoutingAbaNumber !== undefined) {
                $containerRoutingAbaNumber.innerHTML = $containRoutingAbaNumber.value.replace(
                    /.(?=.{4})/g,
                    '*'
                );
                $containerAccountNumber.innerHTML = $containAccountNumber.value.replace(
                    /.(?=.{4})/g,
                    '*'
                );
            }

            if ($containerCheckingAccount !== undefined) {
                _.each($containCheckingAccount, function ($radio) {
                    if ($radio.checked) {
                        $radioText = $radio.parentElement.getElementsByClassName(
                            CONSTANTS.RADIO_LABEL_CLASS
                        )[0].innerHTML;
                        $containerCheckingAccount.innerHTML = $radioText;
                    }
                });
            }

            // Set dpp flag
            _dppFlag = true;

            submitPaymentDetails();
        };

        var confirmEnrollEvent = function (event) {
            event.preventDefault();

            $enrollSuccessMessage.classList.add(CONSTANTS.HIDDEN_CLASS);
            $enrollCloseButton.classList.add(CONSTANTS.CLOSE_SELECTOR);
            $accordionSelector.dataset.statement = 'true';
            $linkAccordionSelector.click();
            $updateMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            setStatement();
        };

        var submitPaymentDetails = function () {
            var serviceUrl = $directPaymentForm.dataset.serviceEnrollUrl,
                params;
          
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
          
            // Service Data
            params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($directPaymentForm, CONSTANTS.FORM_SCID),
                BankAccountNumber: query.getFormInputValue($directPaymentForm, CONSTANTS.FORM_ACCOUNT_NUMBER) || '',
                BankRoutingNumber: query.getFormInputValue($directPaymentForm, CONSTANTS.FORM_ROUTING_ABA) || '',
                BankAccountType: query.getFormInputValue($directPaymentForm, CONSTANTS.FORM_ACCOUNT_TYPE) || ''
            };
        
            // Service Call
            query.scrollToElement($directPaymentForm, $header);
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successPaymentDetails,
                errorPaymentDetails,
                params,
                true,
                $formLoading
            );
        };

        var submitUnenrollDPP = function () {
            var serviceUrl = $directPaymentForm.dataset.serviceUnenrollUrl,
                params = {
                    Maid: $accountMaidInput.value,
                    ScId: query.getFormInputValue($directPaymentForm, CONSTANTS.FORM_SCID)
                };

            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            query.scrollToElement($directPaymentForm, $header);

            query.deleteData(
                serviceUrl,
                successPaymentDetails,
                errorPaymentDetails,
                JSON.stringify(params),
                true,
                $formLoading
            );
        };

        var successPaymentDetails = function () {
            if (_dppFlag) {
                $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $enrollSuccessMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $unenrollConfirmationSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $accordionSelector.dataset.statement = 'false';
                $linkAccordionSelector.click();
                $updateMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
                setStatement();
            }

            query.scrollToElement($directPaymentForm, $header);

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success',
                contentTitle: 'direct-payment-plan'
            });

            // Qualtrics survey triggering
            $directPaymentForm.classList.add(coned.constants.QUALTRICS_TRIGGER_CLASS);

            QSI.API.unload();
            QSI.API.load().done(QSI.API.run());
        };

        var errorPaymentDetails = function (data) {
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var unEnrollEvent = function (event) {
            event.preventDefault();

            if ($manageFormSelector !== undefined) {
                $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                $oruManageForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            query.scrollToElement($directPaymentForm, $header);
            $unenrollConfirmationSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var cancelUnEnroll = function (event) {
            event.preventDefault();

            if ($manageFormSelector !== undefined) {
                $manageFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $oruManageForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            query.scrollToElement($directPaymentForm, $header);
            $unenrollConfirmationSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var confirmationUnenroll = function (event) {
            event.preventDefault();

            _dppFlag = false;
            submitUnenrollDPP();
            $updateMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var newBankAccount = function (event) {
            event.preventDefault();

            $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $enrollFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $enrollCloseButton.classList.remove(CONSTANTS.CLOSE_SELECTOR);

            if (!_hasCloseEvent) {
                coned.utils.addGeneralListeners($enrollCloseButton, cancelNewAccount);
                _hasCloseEvent = true;
            }
        };

        var cancelNewAccount = function (event) {
            event.preventDefault();

            if (_isManage) {
                $manageFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $enrollCloseButton.classList.add(CONSTANTS.CLOSE_SELECTOR);
            }
        };

        var initializeData = function () {
            $linkAccordionSelector = $directPaymentForm.getElementsByClassName(
                CONSTANTS.LINK_ACCORDION_SELECTOR
            )[0];
            $enrollFormSelector = $directPaymentForm.getElementsByClassName(
                CONSTANTS.ENROLL_FORM_SELECTOR
            )[0];
            $manageFormSelector = $directPaymentForm.getElementsByClassName(
                CONSTANTS.MANAGE_FORM_SELECTOR
            )[0];
            $oruManageForm = $directPaymentForm.getElementsByClassName(
                CONSTANTS.ORU_MANAGE_FORM
            )[0];
            $enrollSuccessMessage = $directPaymentForm.getElementsByClassName(
                CONSTANTS.ENROLL_SUCCESS_MESSAGE
            )[0];
            $confirmEnrollButton = $directPaymentForm.getElementsByClassName(
                CONSTANTS.CONFIRM_ENROLL_BUTTON
            )[0];
            $accordionSelector = $directPaymentForm.getElementsByClassName(
                CONSTANTS.ACCORDION_SELECTOR
            )[0];
            $containerCheckingAccount = $directPaymentForm.getElementsByClassName(
                CONSTANTS.CONTAINER_CHECKING_ACCOUNT
            )[0];
            $containerRoutingAbaNumber = $directPaymentForm.getElementsByClassName(
                CONSTANTS.CONTAINER_ROUTING_ABANUMBER
            )[0];
            $containerAccountNumber = $directPaymentForm.getElementsByClassName(
                CONSTANTS.CONTAINER_ACCOUNT_NUMBER
            )[0];
            $containCheckingAccount = $directPaymentForm.getElementsByClassName(
                CONSTANTS.CONTAIN_CHECKING_ACCOUNT
            );
            $containRoutingAbaNumber = $directPaymentForm.getElementsByClassName(
                CONSTANTS.CONTAIN_ROUTING_ABANUMBER
            )[0];
            $containAccountNumber = $directPaymentForm.getElementsByClassName(
                CONSTANTS.CONTAIN_ACCOUNT_NUMBER
            )[0];
            $unenrollButtonSelector = $directPaymentForm.getElementsByClassName(
                CONSTANTS.UNENROLL_BUTTON_SELECTOR
            )[0];
            $unenrollConfirmationSelector = $directPaymentForm.getElementsByClassName(
                CONSTANTS.UNENROLL_CONFIRMATION_SELECTOR
            )[0];
            $unenrollConfirmationCancelButton = $directPaymentForm.getElementsByClassName(
                CONSTANTS.UNENROLL_CONFIRMATION_CANCEL_SELECTOR
            )[0];
            $enrollCloseButton = $directPaymentForm.getElementsByClassName(
                CONSTANTS.ENROLL_CLOSE_BUTTON
            )[0];
            $serviceError = $directPaymentForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceErrorMessage = $directPaymentForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $updateMessage = $directPaymentForm.getElementsByClassName(
                CONSTANTS.UPDATED_MESSAGE
            )[0];
            $iconStatusTextSpan = $directPaymentForm.getElementsByClassName(
                CONSTANTS.ICON_STATUS_TEXT
            )[0];

            _hasCloseEvent = false;
        };

        var initializeEvents = function () {
            setStatement();
            new coned.components.ValidateForm(
                CONSTANTS.FORM, 
                onSubmitForm,
                CONSTANTS.DEFAULT_VALIDATOR_HIDDEN_SELECTOR,
                true
            );
            coned.utils.addGeneralListeners($confirmEnrollButton, confirmEnrollEvent);

            if ($manageFormSelector !== undefined) {
                coned.utils.addGeneralListeners($unenrollButtonSelector, unEnrollEvent);
                coned.utils.addGeneralListeners($unenrollConfirmationCancelButton, cancelUnEnroll);
                $unenrollConfirmationSelector.addEventListener('submit', confirmationUnenroll);
                $manageFormSelector.addEventListener('submit', newBankAccount);

                if (_isManage) {
                    coned.utils.addGeneralListeners($enrollCloseButton, cancelNewAccount);
                    _hasCloseEvent = true;
                }
            }

            $enrollFormSelector && coned.utils.addMultipleListeners($enrollFormSelector, ['change', 'keyup'], function () {
                coned.components.ValidateForm.isFormValid(
                    $enrollFormSelector,
                    '',
                    CONSTANTS.FORM_IGNORE_VALIDATION
                );
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardDirectPaymentPlan.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardDirectPaymentPlan;
})();
