// ==================== CUSTOMER MESSAGING COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CustomerMessaging = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SHOW_LIST_BTN_SELECTOR: 'js-show-full-list',
        ACCOUNT_NUMBER_SELECTOR: 'js-account-number',
        SET_COOKIE_SELECTOR: 'js-set-cookie',
        HIDDEN_CLASS: 'hidden',
        COOKIE_MESSAGE_NAME: 'CE_CUSTOMERMESSAGEREAD',
        FIRST_HIDDEN_ACCOUNT: 4,

        // Multiple Messages
        LIST_MESSAGE_SELECTOR: "js-list-message",
        MESSAGE_SELECTOR: "js-message",
        MULTIPLE_MESSAGES_SELECTOR: 'js-multiple-messages',
        LIST_BOX_SELECTOR: 'js-listbox',
        MESSAGE_READ_STATE_SELECTOR: 'js-message-read-state',
        ACTIVE_MESSAGES_CONTAINER_SELECTOR: 'js-active-messages-container',
        LIST_MESSAGES_CONTAINER_SELECTOR: 'js-list-messages-container',
        BACK_BUTTON_SELECTOR: 'js-go-back',
        BACK_BUTTON_CONTAINER_SELECTOR: 'js-go-back-container',
        POPUP_SELECTOR: 'js-popup',
        POPUP_FOOTER_SELECTOR: 'js-popup-footer',
        MOBILE_NAV_SELECTOR: 'js-mobile-nav',
        REMIND_LATER_SELECTOR: 'js-remind-me-later',
        TEXT_SPAN_REPLACE_SELECTOR: 'js-text-span-replace',
        MESSAGE_ACTIVE_SELECTOR: 'js-list-message-active',
        MESSAGE_READ_SELECTOR: 'js-list-message-read',
        REMIND_AGAIN_PARAGRAPH_CONTAINER_SELECTOR: 'js-remind-again-text',
        MESSAGE_UNREAD_CLASS: 'customer-messaging__list-message--unread',
        MESSAGE_ACTIVE_CLASS: 'customer-messaging__list-message--active',
        MESSAGE_CONTAINER_ACTIVE_CLASS: 'customer-messaging__message-container--active',
        NAV_MOBILE_SHADOW_CLASS: 'customer-messaging__nav-mobile--shadow',
        SHOW_ACTIVE_MESSAGE_ACTION: 'show',
        HIDE_ACTIVE_MESSAGE_ACTION: 'hide',
        READ_MESSAGES_ACTION: 'read-messages',
        ALL_MESSAGES_ACTION: 'all-messages',
        COOKIE_DIVIDER: '|',
        PX_UNIT: 'px',
        EMPTY_STRING: '',
        PARAGRAPH_TAG: 'p',

        // A11y 
        TABINDEX: 'tabindex',
        ARIA_SELECTED_ATTRIBUTE: 'aria-selected',
        ARIA_ACTIVE_DESCENDANT_ATTRIBUTE: 'aria-activedescendant',
        TRUE_VALUE: true,
        FALSE_VALUE: false,
        MINUS_ONE_TAB_INDEX: '-1',
        ZERO_TAB_INDEX: '0',

        // Events
        CLICK_EVENT: 'click',
        KEYDOWN_EVENT: 'keydown',
        RESIZE_EVENT: 'resize',

        // Tagging
        CLOSE_TAGGING_BUTTON_SELECTOR: 'js-close-tagging',
        CUSTOMER_MESSAGE_CLOSE_TAG: 'customer.message.close'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var CustomerMessaging = function ($customerMessaging) {
        /**
         * PRIVATE METHODS
         */
        var $showFullListBtns,
            $setCookieBtns,
            $closeTaggingButtons,

            // Multiple Messages
            $listMessages,
            $listBox,
            $messages,
            $activeMessagesContainer,
            $listMessagesContainer,
            $backButton,
            $backButtonContainer,
            $parentPopup,
            $popupFooter,
            $mobileNav,
            $remindLaterButton,
            $textSpanReplace,
            $remindAgainParagraphContainer,
            listboxState,
            readMessageScreenReaderText,
            _isMultipleMessages,
            
            // Tagging
            elapsedSeconds,
            visibleInterval;

        /**
         * Toggle between show/hide the full list of accounts.
         * @param {Event} event Event that triggered the function.
         */
        var toggleFullList = function (event) {
            var $showFullListBtn = event.currentTarget,
                $parentMessage = query.selectParentElement($showFullListBtn, CONSTANTS.MESSAGE_SELECTOR),
                $AccountNumbers;
                
                // Single message version can only have one list of account numbers on the whole module
                if (!_isMultipleMessages) {
                    $AccountNumbers = $customerMessaging.getElementsByClassName(
                        CONSTANTS.ACCOUNT_NUMBER_SELECTOR
                    );

                // Multiple messages version could have a list of account numbers per message
                } else {
                    $AccountNumbers = $parentMessage.getElementsByClassName(
                        CONSTANTS.ACCOUNT_NUMBER_SELECTOR
                    );
                }

            // Show hidden accounts.
            // If the fifth account is hidden, show it and all subsequent.
            if (
                query.hasClass(
                    $AccountNumbers[CONSTANTS.FIRST_HIDDEN_ACCOUNT],
                    CONSTANTS.HIDDEN_CLASS
                )
            ) {
                // First 4 accounts will always be visible
                for (var indexShow = CONSTANTS.FIRST_HIDDEN_ACCOUNT; indexShow < $AccountNumbers.length; indexShow++) {
                    query.removeClass($AccountNumbers[indexShow], CONSTANTS.HIDDEN_CLASS);
                }
    
                $showFullListBtn.textContent = $showFullListBtn.dataset.replaceText;
                // Focus first account that just became visible
                $AccountNumbers[CONSTANTS.FIRST_HIDDEN_ACCOUNT].focus();

            // Hide visible accounts.
            // If the fifth account is visible, hide it and all subsequent.
            } else {
                // First 4 accounts will always remain visible
                for (var indexHide = CONSTANTS.FIRST_HIDDEN_ACCOUNT; indexHide < $AccountNumbers.length; indexHide++) {
                    query.addClass($AccountNumbers[indexHide], CONSTANTS.HIDDEN_CLASS);
                }
    
                $showFullListBtn.textContent = $showFullListBtn.dataset.text;
                // Focus last account that is still visible
                $AccountNumbers[CONSTANTS.FIRST_HIDDEN_ACCOUNT-1].focus();
            }
        };

        /**
         * Set the cookie so the BE can decide whether or not the message or the popup needs to be added.
         * @param {String} cookieName Cookie name to set.
         * @param {String} cookieValue Cookie value to set.
         */
        var setCookie = function (cookieName, cookieValue) {
            var _variableName = cookieName,
                _currentCookieValue = query.getCookie(_variableName),
                _cookieValue = cookieValue,
                _expiryDate;
                
            _expiryDate = new Date(
                new Date().setFullYear(
                    new Date().getFullYear() + 1
                )
            );

            if (
                !_currentCookieValue ||
                (_currentCookieValue && _currentCookieValue !== _cookieValue)
            ) {
                query.setCookie(_variableName, _cookieValue, _expiryDate);
            }
        };

        /**
         * Handle setting cookies for messages in a multi message popup. Can be set for all messages or just for read ones.
         * Afterwards BE will check against cookies not to display the corresponding messages again.
         * @param {String} action Either read-messages or all-messages to set cookies for.
         */
        var handleMultipleMessagesCookies = function (action) {
            var currentCookieValue = query.getCookie(CONSTANTS.COOKIE_MESSAGE_NAME),
                concatenatedCookieValues = CONSTANTS.EMPTY_STRING,
                newCookieValue;

            var setCookies = function ($listMessage) {
                var messageCookieValue = $listMessage.dataset.cookieMessage;

                // If cookie does not exist and the var initial value has not been assigned we just set it with the cookie-message value
                if (!currentCookieValue && !newCookieValue) {
                    concatenatedCookieValues = messageCookieValue;

                // If cookie exists, we need to concatenate each cookie-message value to it
                } else {
                    concatenatedCookieValues += CONSTANTS.COOKIE_DIVIDER + messageCookieValue;
                }

                // If cookie already has a value we concatenate
                if (currentCookieValue) {
                    newCookieValue = currentCookieValue + concatenatedCookieValues;

                // If cookie is empty we just assign a value
                } else {
                    newCookieValue = concatenatedCookieValues;                            
                }
            };

            Array.from($listMessages).forEach(function ($listMessage) {
                // Set cookies just for read messages
                if (action === CONSTANTS.READ_MESSAGES_ACTION) {
                    // If the message was already read, we need to set it's cookie value
                    if (query.hasClass($listMessage, CONSTANTS.MESSAGE_READ_SELECTOR)) {
                        setCookies($listMessage);
                    }

                // Set cookies for all messages
                } else if (action === CONSTANTS.ALL_MESSAGES_ACTION) {
                    setCookies($listMessage);
                }
            });

            if (newCookieValue) {
                setCookie(CONSTANTS.COOKIE_MESSAGE_NAME, newCookieValue);
            }
        };

        /**
         * Handle setting a cookie for a single message popup.
         * Afterwards BE will check against the cookie not to display the message again.
         */
        var handleSingleMessageCookie = function () {
            var currentCookieValue = query.getCookie(CONSTANTS.COOKIE_MESSAGE_NAME),
                messageCookieValue = $customerMessaging.dataset.cookieMessage,
                newCookieValue;

            // If cookie does not exist we just set it with the cookie-message value
            if (!currentCookieValue) {
                newCookieValue = messageCookieValue;
            
            // If cookie exists, we need to concatenate the cookie-message value to it
            } else {
                newCookieValue = currentCookieValue + CONSTANTS.COOKIE_DIVIDER + messageCookieValue;
            }

            setCookie(CONSTANTS.COOKIE_MESSAGE_NAME, newCookieValue);
        };

        /**
         * Add event listeners to buttons that will set all the message's cookies.
         */
        var setAllCookiesButtonsListeners = function () {
            for (var cookieBtnsIndex = 0; cookieBtnsIndex < $setCookieBtns.length; cookieBtnsIndex++) {
                $setCookieBtns[cookieBtnsIndex].addEventListener(CONSTANTS.CLICK_EVENT, function () {
                    // For multiple messages each has it's own message-cookie
                    if (_isMultipleMessages) {
                        handleMultipleMessagesCookies(CONSTANTS.ALL_MESSAGES_ACTION);

                    // For single message the component only has one message-cookie
                    } else {
                        handleSingleMessageCookie();
                    }
                });                        
            }
        };

        /**
         * Shows or hides active-message and messages-list sections on mobile.
         * @param {String} action Accepts two values: 'show' or 'hide'.
         */
        var handleActiveMessageVisibilityOnMobile = function (action) {
            if (action === CONSTANTS.SHOW_ACTIVE_MESSAGE_ACTION) {
                // Hide list of messages and other elements
                query.addClass($listMessagesContainer, CONSTANTS.HIDDEN_CLASS);
                query.addClass($popupFooter, CONSTANTS.HIDDEN_CLASS);
                // Show Active message and necessary elements
                query.addClass($activeMessagesContainer, CONSTANTS.MESSAGE_CONTAINER_ACTIVE_CLASS);
                query.addClass($mobileNav, CONSTANTS.NAV_MOBILE_SHADOW_CLASS);
                query.removeClass($backButtonContainer, CONSTANTS.HIDDEN_CLASS);
    
                $backButton.focus();
            } else if (action === CONSTANTS.HIDE_ACTIVE_MESSAGE_ACTION) {
                var $activeMessageSelected = $listMessagesContainer.getElementsByClassName(
                    CONSTANTS.MESSAGE_ACTIVE_SELECTOR
                )[0];

                // Show list of messages and other elements
                query.removeClass($listMessagesContainer, CONSTANTS.HIDDEN_CLASS);
                query.removeClass($popupFooter, CONSTANTS.HIDDEN_CLASS);
                // Hide Active message and necessary elements
                query.removeClass($activeMessagesContainer, CONSTANTS.MESSAGE_CONTAINER_ACTIVE_CLASS);
                query.removeClass($mobileNav, CONSTANTS.NAV_MOBILE_SHADOW_CLASS);
                query.addClass($backButtonContainer, CONSTANTS.HIDDEN_CLASS);
    
                $activeMessageSelected.focus();
            }
        };

        /**
         * Handles focus for active-message after a selection is made on desktop.
         */
        var handleActiveMessageOnDesktop = function () {
            $activeMessagesContainer.focus();
        };

        /**
         * Handles listbox options to display the appropriate active message.
         * @param {Event} event Event that triggered the function.
         */
        var handleListboxSelection = function (event) {
            var _isWindowResize = !event,
                $listMessage = _isWindowResize ? 
                    $customerMessaging.getElementsByClassName(CONSTANTS.MESSAGE_ACTIVE_SELECTOR)[0] :
                    event.currentTarget,
                listMessageIndex = Array.from($listMessages).indexOf($listMessage);

            // Handles on resize when no selection has been made yet
            if (_isWindowResize && !$listMessage) return;

            for (var index = 0; index < $messages.length; index++) {
                if (index === listMessageIndex) {
                    query.removeClass($messages[index], CONSTANTS.HIDDEN_CLASS);
                } else {
                    query.addClass($messages[index], CONSTANTS.HIDDEN_CLASS);
                }
            }

            if (coned.utils.isMobile()) {
                // Avoid switching to the active message view on mobile if the resize occurs while the $listMessagesContainer is already visible
                if (
                    !_isWindowResize &&
                    !query.hasClass($listMessagesContainer, CONSTANTS.HIDDEN_CLASS)
                ) {
                    handleActiveMessageVisibilityOnMobile(CONSTANTS.SHOW_ACTIVE_MESSAGE_ACTION);
                }
            } else {
                if (_isWindowResize) {
                    handleActiveMessageOnDesktop(true);
                } else {
                    handleActiveMessageOnDesktop(false);
                }
            }
        };

        /**
         * Handle keys inside listbox of messages.
         * @param {Event} event Event that triggered the function.
         */
        var listBoxKeyboardHandler = function (event) {
            event.stopPropagation();

            var currentPosition = listboxState.currentIndex,
                newPosition,
                $element = event.currentTarget;

            switch (event.keyCode) {
                case coned.constants.KEY_CODE.UP:
                    event.preventDefault();
                    newPosition = currentPosition - 1;
                    moveListboxOption(newPosition);
                    break;

                case coned.constants.KEY_CODE.DOWN:
                    event.preventDefault();
                    newPosition = currentPosition + 1;
                    moveListboxOption(newPosition);
                    break;
              
                case coned.constants.KEY_CODE.SPACE:
                    event.preventDefault();
                    setListboxOptionState($element);
                    handleListboxSelection(event);
                    // If all messages have been read update UI
                    if (checkReadMessages()) {
                        updateUiWhenReadMessages();
                    }
                    break;
            }                     
        };

        /**
         * Handle escape key when closing the popup to set all cookies and add tagging.
         * @param {Event} event Event that triggered the function.
         */
        var escapeKeyboardHandler = function (event) {
            if (event.keyCode === coned.constants.KEY_CODE.ESC) {
                if (_isMultipleMessages) {
                    handleMultipleMessagesCookies(CONSTANTS.ALL_MESSAGES_ACTION);
                } else {
                    handleSingleMessageCookie();
                }

                taggingOnClose();
            }                     
        };

        /**
         * Updates the UI with new copy after all messages have been read.
         */
        var updateUiWhenReadMessages = function () {
            var paragraphReplaceText = $remindAgainParagraphContainer.dataset.replaceText,
                $paragraph = $remindAgainParagraphContainer.getElementsByTagName(CONSTANTS.PARAGRAPH_TAG)[0],
                buttonTextReplace = $remindLaterButton.dataset.replaceText;
                
                if ($paragraph) {
                    $paragraph.textContent = paragraphReplaceText;
                }

                $textSpanReplace.textContent = buttonTextReplace;
        };

        /**
         * Checks if all messages have been read.
         * @return {Boolean} If all messages have been read or not.
         */
        var checkReadMessages = function () {
            var _areAllMessagesRead = true;

            for (var index = 0 ; index < $listMessages.length; index++) {
                if (!query.hasClass($listMessages[index], CONSTANTS.MESSAGE_READ_SELECTOR)) {
                    _areAllMessagesRead = false;
                    break;
                }
            }

            return _areAllMessagesRead;
        };

        /**
         * Update listbox option state with styles and aria attributes.
         * @param {HTMLElement} $selectedOption Element to set states to.
         */
        var setListboxOptionState = function ($selectedOption) {
            Array.from($listMessages).forEach(function ($listMessage) {
                var $listMessageReadState = $listMessage.getElementsByClassName(
                    CONSTANTS.MESSAGE_READ_STATE_SELECTOR
                )[0];

                if ($listMessage !== $selectedOption) {
                    $listMessage.setAttribute(
                        CONSTANTS.ARIA_SELECTED_ATTRIBUTE, 
                        CONSTANTS.FALSE_VALUE
                    );

                    query.removeClass($listMessage, CONSTANTS.MESSAGE_ACTIVE_CLASS);
                    query.removeClass($listMessage, CONSTANTS.MESSAGE_ACTIVE_SELECTOR);
                } else {
                    var currentOptionId = $listMessage.id;

                    $listMessage.setAttribute(
                        CONSTANTS.ARIA_SELECTED_ATTRIBUTE, 
                        CONSTANTS.TRUE_VALUE
                    );

                    $listBox.setAttribute(
                        CONSTANTS.ARIA_ACTIVE_DESCENDANT_ATTRIBUTE,
                        currentOptionId
                    );

                    // Set the visually hidden text for the current list message
                    $listMessageReadState.textContent = readMessageScreenReaderText;

                    query.addClass($listMessage, CONSTANTS.MESSAGE_ACTIVE_CLASS);
                    query.addClass($listMessage, CONSTANTS.MESSAGE_ACTIVE_SELECTOR);
                    query.addClass($listMessage, CONSTANTS.MESSAGE_READ_SELECTOR);
                    query.removeClass($listMessage, CONSTANTS.MESSAGE_UNREAD_CLASS);
                }
            });
        };

        /**
         * Move focus to the indicated position.
         * @param {Number} newPosition New listbox position to move.
         */
        var moveListboxOption = function (newPosition) {
            if (
                newPosition >= 0 && 
                newPosition < $listMessages.length
            ) {
                var $previousOption = listboxState.$currentOption,
                    $currentOption = $listMessages[newPosition];

                // Update global state
                listboxState.$currentOption = $currentOption;
                listboxState.currentIndex = newPosition;
                
                if ($previousOption) {
                    $previousOption.setAttribute(
                        CONSTANTS.TABINDEX, 
                        CONSTANTS.MINUS_ONE_TAB_INDEX
                    );
                }
                
                if ($currentOption) {                    
                    $currentOption.setAttribute(
                        CONSTANTS.TABINDEX, 
                        CONSTANTS.ZERO_TAB_INDEX
                    );

                    $currentOption.focus();
                }
            }
        };

        /**
         * Resets desktop elements visibility and styles.
         * Removes mobile exclusive classes.
         */
        var resetDesktopView = function () {
            // Show list of messages and other elements
            query.removeClass($listMessagesContainer, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($popupFooter, CONSTANTS.HIDDEN_CLASS);
            // Hide Active message and necessary elements
            query.removeClass($activeMessagesContainer, CONSTANTS.MESSAGE_CONTAINER_ACTIVE_CLASS);
            query.removeClass($mobileNav, CONSTANTS.NAV_MOBILE_SHADOW_CLASS);
            query.addClass($backButtonContainer, CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Gets the height of the tallest message.
         * @param {Boolean} resizeTriggered Whether or not the function trigger was initiated by a window resize event.
         * @return {Number} The height of the tallest message.
         */
        var getTallestMessageHeight = function (resizeTriggered) {
            var tallestMessageHeight,
                _isPopupHidden = false;

            // Make popup visible so height is actually available
            if (query.hasClass($parentPopup, CONSTANTS.HIDDEN_CLASS)) {
                query.removeClass($parentPopup, CONSTANTS.HIDDEN_CLASS);
                
                _isPopupHidden = true;
            }

            // When resizing from mobile to desktop some parts are hidden so we reset the desktop view
            // so the layout is complete and height can be calculated accurately.
            if (resizeTriggered) {
                resetDesktopView();
            }

            Array.from($messages).forEach (function ($message) {
                var messageHeight;

                // Remove js set height, so height can be calculated from CSS values
                $message.style.height = CONSTANTS.EMPTY_STRING;

                if (!query.hasClass($message, CONSTANTS.HIDDEN_CLASS)) {
                    messageHeight = $message.getBoundingClientRect().height;

                } else {
                    // Make message visible so height is actually available
                    query.removeClass($message, CONSTANTS.HIDDEN_CLASS);

                    messageHeight = $message.getBoundingClientRect().height;

                    // Return message to it's initial hidden state
                    query.addClass($message, CONSTANTS.HIDDEN_CLASS);
                }

                // Look for the tallest height across all messages
                if (
                    !tallestMessageHeight ||
                    messageHeight > tallestMessageHeight
                ) {
                    tallestMessageHeight = messageHeight;
                }
            });

            // Return popup to it's initial hidden state
            if (_isPopupHidden) {
                query.addClass($parentPopup, CONSTANTS.HIDDEN_CLASS);
            }

            return tallestMessageHeight;
        };

        /**
         * On desktop sets the height of each message to that of the tallest one, so the popup height is consistent.
         * On Mobile, removes any height applied directly by js and let's the CSS handle full screen height.
         * @param {Boolean} resizeTriggered Whether or not the function was triggered by a window resize event.
         */
        var setPopupHeight = function (resizeTriggered) {
            if (coned.utils.isMobile()) {
                Array.from($messages).forEach(function ($message) {
                    $message.style.height = CONSTANTS.EMPTY_STRING;
                });
            
            } else {
                var height = getTallestMessageHeight(resizeTriggered);
    
                Array.from($messages).forEach(function ($message) {
                    $message.style.height = height + CONSTANTS.PX_UNIT;
                });
            }
        };

        /**
         * Tracks the seconds the popup is visible.
         */
        var startTrackTimeVisible = function () {
            visibleInterval = setInterval(function() {
                ++elapsedSeconds;
            }, 1000);
        };

        /**
         * Cancels the interval that tracked the time the popup was visible.
         */
        var stopTrackTimeVisible = function () {
            clearInterval(visibleInterval);
            visibleInterval = null;
        };

        /**
         * Gets the time the popup was visible.
         * @return {Number} Time in seconds.
         */
        var getTimeVisible = function () {
            return elapsedSeconds;
        };

        /**
         * Add tagging upon closing the poppup.
         */        
        var taggingOnClose = function () {
            // Analytics data building
            dataLayer.push({
                event: CONSTANTS.CUSTOMER_MESSAGE_CLOSE_TAG,
                timeOfMessage: getTimeVisible()
            });

            stopTrackTimeVisible();
        };

        /**
         * Add tagging with elapsed seconds upon clicking on an element.
         * @param {Boolean} stopTrackTime Whether to stop the timer that tracks elapsed time or not.
         */
        var taggingTimeOnClickElement =  function (stopTrackTime) {
            // Analytics data building
            dataLayer.push({
                timeOfMessage: getTimeVisible()
            });

            if (stopTrackTime) {
                stopTrackTimeVisible();                
            }
        };

        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            _isMultipleMessages = query.hasClass($customerMessaging, CONSTANTS.MULTIPLE_MESSAGES_SELECTOR);
            $showFullListBtns = $customerMessaging.getElementsByClassName(
                CONSTANTS.SHOW_LIST_BTN_SELECTOR
            );
            $setCookieBtns = $customerMessaging.getElementsByClassName(
                CONSTANTS.SET_COOKIE_SELECTOR
            );
            $closeTaggingButtons = $customerMessaging.getElementsByClassName(
                CONSTANTS.CLOSE_TAGGING_BUTTON_SELECTOR
            );
            $remindLaterButton = $customerMessaging.getElementsByClassName(
                CONSTANTS.REMIND_LATER_SELECTOR
            )[0];
            $parentPopup = query.selectParentElement($customerMessaging, CONSTANTS.POPUP_SELECTOR);
            elapsedSeconds = 0;

            // Multiple messages version
            if (_isMultipleMessages) {
                $listMessages = $customerMessaging.getElementsByClassName(
                    CONSTANTS.LIST_MESSAGE_SELECTOR
                ); 
                $messages = $customerMessaging.getElementsByClassName(
                    CONSTANTS.MESSAGE_SELECTOR
                ); 
                $listBox = $customerMessaging.getElementsByClassName(
                    CONSTANTS.LIST_BOX_SELECTOR
                )[0]; 
                $activeMessagesContainer = $customerMessaging.getElementsByClassName(
                    CONSTANTS.ACTIVE_MESSAGES_CONTAINER_SELECTOR
                )[0]; 
                $listMessagesContainer = $customerMessaging.getElementsByClassName(
                    CONSTANTS.LIST_MESSAGES_CONTAINER_SELECTOR
                )[0]; 
                $backButton = $customerMessaging.getElementsByClassName(
                    CONSTANTS.BACK_BUTTON_SELECTOR
                )[0]; 
                $backButtonContainer = $customerMessaging.getElementsByClassName(
                    CONSTANTS.BACK_BUTTON_CONTAINER_SELECTOR
                )[0]; 
                $popupFooter = $customerMessaging.getElementsByClassName(
                    CONSTANTS.POPUP_FOOTER_SELECTOR
                )[0]; 
                $mobileNav = $customerMessaging.getElementsByClassName(
                    CONSTANTS.MOBILE_NAV_SELECTOR
                )[0]; 
                $textSpanReplace = $remindLaterButton.getElementsByClassName(
                    CONSTANTS.TEXT_SPAN_REPLACE_SELECTOR
                )[0];
                $remindAgainParagraphContainer = $customerMessaging.getElementsByClassName(
                    CONSTANTS.REMIND_AGAIN_PARAGRAPH_CONTAINER_SELECTOR
                )[0];
                readMessageScreenReaderText = $customerMessaging.dataset.readMessageScreenreaderText;
                
                // Focus listbox item state
                listboxState = {
                    currentIndex: 0,
                    $currentOption: $listMessages[0]
                }
            } 
        };

        var initializeEvents = function () {
            if ($showFullListBtns.length > 0) {
                Array.from($showFullListBtns).forEach(function ($showFullListBtn) {
                    $showFullListBtn.addEventListener(CONSTANTS.CLICK_EVENT, toggleFullList);
                });
            }

            if (_isMultipleMessages) {
                // List messages listeners for mouse and keyboard interactions including a11y
                Array.from($listMessages).forEach(function ($listMessage) {
                    // Mouse interactions
                    coned.utils.addGeneralListeners($listMessage, function (event) {
                        var $element = event.currentTarget;

                        handleListboxSelection(event);
                        setListboxOptionState($element);
                        
                        // If all messages have been read update UI
                        if (checkReadMessages()) {
                            updateUiWhenReadMessages();
                        }
                    });
                    
                    // Keyboard interactions
                    $listMessage.addEventListener(CONSTANTS.KEYDOWN_EVENT, listBoxKeyboardHandler);
                });

                // Listener for back button on mobile
                coned.utils.addGeneralListeners($backButton, function () {
                    handleActiveMessageVisibilityOnMobile(CONSTANTS.HIDE_ACTIVE_MESSAGE_ACTION);
                });

                // Set popup height to be uniform across all messages
                setPopupHeight();

                // On resize listener so popup height is uniform across all messages
                coned.utils.onResizeThrottler(function () {
                    setPopupHeight(true);
                });

                // On multiple messages popup the button sets cookies just for already read messages
                $remindLaterButton.addEventListener(CONSTANTS.CLICK_EVENT, function () {
                    handleMultipleMessagesCookies(CONSTANTS.READ_MESSAGES_ACTION);
                });

                // On resize listener so popup layout is handled accordingly if switching between mobile and desktop
                // ie: if selecting a message on mobile and resizing to desktop
                coned.utils.onResizeThrottler(handleListboxSelection);

                // Tagging
                Array.from($listMessages).forEach(function ($listMessage) {
                    $listMessage.addEventListener(CONSTANTS.CLICK_EVENT, function () {
                        taggingTimeOnClickElement(false);
                    });
                });
            }

            // Cookies that would be set for all messages not to show again upon clicking the close button
            setAllCookiesButtonsListeners();
            // Cookies that would be set for all messages not to show again upon hitting the escape key
            $parentPopup.addEventListener(CONSTANTS.KEYDOWN_EVENT, escapeKeyboardHandler);

            // Tagging
            startTrackTimeVisible();

            Array.from($closeTaggingButtons).forEach(function ($closeTaggingButton) {
                $closeTaggingButton.addEventListener(CONSTANTS.CLICK_EVENT, taggingOnClose);
            });

            $remindLaterButton.addEventListener(CONSTANTS.CLICK_EVENT, function () {
                taggingTimeOnClickElement(true);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CustomerMessaging.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CustomerMessaging;
})();
