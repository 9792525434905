// ==================== PLANNED CONTRIBUTION FORM COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PlannedContributionModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        RADIO_CLASS: 'input[name=donation]:radio',
        RADIO_OTHER_VALUE: 'other',
        RADIO_HIDDEN_FIELD: 'js-other',
        RADIO_HIDE_FIELD: 'coned-form__field-container--hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var PlannedContributionModule = function ($plannedContributionElement) {
        /**
         * PRIVATE METHODS
         */
        var $energyBillRadio, $energyBillOther;

        var clickedEvent = function () {
            var selectedValue = this.value;

            if (selectedValue == CONSTANTS.RADIO_OTHER_VALUE) {
                query.removeClass($energyBillOther, CONSTANTS.RADIO_HIDE_FIELD);
            } else {
                query.addClass($energyBillOther, CONSTANTS.RADIO_HIDE_FIELD);
            }
        };

        var initializeData = function () {
            $energyBillRadio = $(CONSTANTS.RADIO_CLASS);
            $energyBillOther = $plannedContributionElement.getElementsByClassName(
                CONSTANTS.RADIO_HIDDEN_FIELD
            )[0].parentElement.parentElement;
        };

        var initializeEvents = function () {
            //Adding event to tabs elements
            $energyBillRadio.change(clickedEvent);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PlannedContributionModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PlannedContributionModule;
})();
