// ==================== PROGRAM_SAVINGS_FILTERS =========================
/* global dataLayer */
/* global query */

// Create a namespace for the coned object if it doesn't already exist
var coned = coned || {};
coned.components = coned.components || {};

/**
 * @returns the init function to start the ProgramSavingsFilters module
 */
coned.components.ProgramSavingsFilters = (function () {
    var isLoaded = false; // Indicates if the module has been loaded
    /**
     * @typedef StateModal
     * @property {boolean} open
     */
    /**
     * @typedef {Object} PaginationState
     * @property {boolean} [isSeted] - Indicates if the pagination state is set
     * @property {number} [totalItems] - Total number of items in pagination
     * @property {number} [itemsPerPage] - Number of items per page
     * @property {number} [currentPage] - Current active page number
     * @property {number} [totalPages] - Total number of pages
     * @property {boolean} [canScroll] - scroll on pagination only
     * @property {HTMLElement} [$srLive] - Screen Reader Live Element
     */

    /**
     * @typedef {Object} MultipleSelect
     * @property {string} name - Name of the multiple select filter
     * @property {string[]} value - Selected values of the multiple select filter
     */

    /**
     * @typedef {Object} SingleSelect
     * @property {string} name - Name of the single select filter
     * @property {string} value - Selected value of the single select filter
     */

    /**
     * @typedef {Object} SortedItem
     * @property {number} order - Order of the item in the sorted list
     * @property {string} itemId - Unique identifier for the item
     */

    /**
     * @typedef {Object} PaginationItem
     * @property {string} id - Unique identifier for the pagination item
     * @property {string} title - Title of the pagination item
     * @property {Record<string, string[]>} categories - Categories associated with the item
     * @property {boolean} isFeatured - Indicates if the item is featured
     * @property {HTMLElement} $element - DOM element associated with the item
     * @property {HTMLElement[]} $collapsibles - DOM collapsible elements associated with the item
     */

    /**
     * @typedef {Object} PaginationItemsState
     * @property {boolean} [isSeted] - Indicates if the pagination items state is set
     * @property {PaginationItem[]} items - Array of pagination items
     * @property {PaginationItem[]} displayItems - Array of items to be displayed
     * @property {PaginationState} [pagination] - Current pagination state
     * @property {SortedItem[][]} [sortedPages] - Sorted pages of items
     */

    /**
     * @typedef {Object} SrLiveState
     * @property {Record<string, string>} [values]
     * @property {string} [valueKey]
     * @property {string[]} [formatValues]
     */

    /**
     * @typedef {Object} PrivateState
     * @property {StateModal} modal
     * @property {MultipleSelect[]} filtersState - Current state of multiple select filters
     * @property {SingleSelect} [paginationSortState] - Current state of the pagination sort
     * @property {PaginationState} [paginationState] - Current pagination state
     * @property {PaginationItemsState} [paginationItemsState] - Current state of pagination items
     * @property {PaginationItem[]} [filteredItems] - Array of currently filtered items
     * @property {HTMLElement} [$modal] - DOM element for pagination
     * @property {HTMLElement} [$modalFooter] - DOM element for pagination
     * @property {HTMLElement} [$pagination] - DOM element for pagination     * 
     * @property {HTMLElement} [$paginationInformation] - DOM element for pagination information
     * @property {HTMLElement} [$paginationInformationMobile] - DOM element for pagination information mobile
     * @property {HTMLElement} [$paginationItems] - DOM element for pagination items
     * @property {HTMLElement} [$paginationSort] - DOM element for pagination sort
     * @property {HTMLElement} [$paginationSortMobile] - DOM element for pagination sort
     * @property {HTMLElement[]} [$filters] - Array of filter DOM elements
     * @property {HTMLElement} [$buttonClear] - DOM element for the clear button
     * @property {HTMLElement} [$buttonSrClear] - DOM element for the clear screen reader functionality
     * @property {HTMLElement} [$buttonClearMobile] - DOM element for the clear button
     * @property {HTMLElement} [$spanFilterCount] - DOM element for displaying filter count
     * @property {string} [spanFilterCountHTML] - HTML content for the filter count span
     * @property {HTMLElement} [$clearCount] - DOM element for displaying filter count
     * @property {HTMLElement} [$srLive] - DOM element for screen reader live content change
     * @property {SrLiveState} [srLiveState] State of screen reader live content
     * @property {string} [clearCountHTML] - HTML content for the filter count span
     * @property {boolean} isSetedSort - Indicates if the sort state is set
     * @property {boolean} isSetedFilters - Indicates if the filters state is set
     * @property {boolean} isSetedPagination - Indicates if the pagination state is set
     * @property {boolean} isSetedItems - Indicates if the items state is set
     * @property {boolean} isHandledFilterState - Indicates if the filter state has been handled
     * @property {string} textSortAz - Indicates the value for sort ascendant
     * @property {string} textSortZa - Indicates the value for sort descendant
     * @property {string} textSortFeatured - Indicates the value for sort featured
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        CLASSES: {
            /**
             * @type {'modal-footer--show'}
             */
            FOOTER_MOBILE_SHOW: 'modal-footer--show',
            /**
             * @type {'modal-enable'}
             */
            MODAL_ENABLE: 'modal-enable',
            /**
             * @type {'program-savings-filters__button-clear--focus-visible'}
             */
            FOCUS_VISIBLE: 'program-savings-filters__button-clear--focus-visible'
        },
        SELECTORS: {
            /**
             * @type {'js-sr-live'}
             * Selector for the span screen reader live element
             */
            SR_LIVE: 'js-sr-live',
            /**
             * @type {'js-modal'}
             * Selector for the modal element
             */
            MODAL: 'js-modal',
            /**
             * @type {'js-modal-footer'}
             */
            MODAL_FOOTER: 'js-modal-footer',
            /**
             * @type {'js-pagination-simple'}
             * Selector for the pagination element
             */
            PAGINATION: 'js-pagination-simple',
            /**
             * @type {'js-pagination-simple-information'}
             * Selector for the pagination information element
             */
            PAGINATION_INFORMATION: 'js-pagination-simple-information',
            /**
             * @type {'js-pagination-information-mobile'}
             * Selector for the pagination information mobile element
             */
            PAGINATION_INFORMATION_MOBILE: 'js-pagination-information-mobile',
            /**
             * @type {'js-pagination-simple-items'}
             * Selector for the pagination items element
             */
            PAGINATION_ITEMS: 'js-pagination-simple-items',
            /**
             * @type {'js-select-single'}
             * Selector for the single select element used for sorting
             */
            PAGINATION_SORT: 'js-select-single',
            /**
             * @type {'js-radio-group'}
             * Selector for the radio group element used for sorting
             */
            PAGINATION_SORT_MOBILE: 'js-radio-group',
            /**
             * @type {'js-select-multiple'}
             * Selector for the multiple select filter elements
             */
            FILTERS: 'js-select-multiple',
            /**
             * @type {'js-select-miltiple-option'}
             */
            FILTERS_OPTIONS: 'js-select-miltiple-option',
            /**
             * @type {'js-select-multiple-header'}
             * Selector for the select multiple header element
             */
            FILTERS_HEADER: 'js-select-multiple-header',
            /**
             * @type {'js-program-savings-filters-button-clear'}
             * Selector for the clear button element
             */
            BUTTON_CLEAR: 'js-program-savings-filters-button-clear',
            /**
             * @type {'js-program-savings-filters-button-sr-clear'}
             * Selector for the screen reader clear button element
             */
            BUTTON_SR_CLEAR: 'js-program-savings-filters-button-sr-clear',
            /**
             * @type {'js-program-savings-filters-button-clear-mobile'}
             * Selector for the clear button mobile element
             */
            BUTTON_CLEAR_MOBILE: 'js-program-savings-filters-button-clear-mobile',
            /**
             * @type {'js-program-savings-filters-count'}
             * Selector for the filter count display element
             */
            FILTER_COUNT: 'js-program-savings-filters-count',
            /**
             * @type {'js-program-savings-filters-clear-count'}
             * Selector for the clear count display element
             */
            CLEAR_COUNT: 'js-program-savings-filters-clear-count',
            /**
             * @type {'js-result-cta'}
             * Selector for the filter cta element
             */
            RESULT_CTA: 'js-result-cta',
            /**
             * @type {'js-checkbox-selector'}
             * Selector for the checkbox element
             */
            CHECKBOX: 'js-checkbox-selector'
        },
        // ATTRIBUTES: {
        //     // Define any custom attributes used in the module here
        // },
        VALUES: {
            /**
             * @type {''}
             * Value for empty string
             */
            EMPTY: '',
            /**
             * @type {'Programs: A–Z'}
             * Value for sorting items in ascending order
             */
            SORT_AZ: 'Programs: A–Z',
            /**
             * @type {'Programs: Z–A'}
             * Value for sorting items in descending order
             */
            SORT_ZA: 'Programs: Z–A',
            /**
             * @type {'Featured'}
             * Value for sorting items by featured status
             */
            SORT_FEATURED: 'Featured',
            /**
             * @type {' Results'}
             * Value for information mobile status
             */
            RESULTS: ' Results',
            /**
             * @type {'-mobile'}
             * Value Part for filter mobile name
             */
            MOBILE_NAME: '-mobile',
            /**
             * @type {1024}
             * Value Part for filter mobile name
             */
            DESKTOP_BREAKPOINT: 1024,
            /**
             * @type {'change-focus'}
             */
            CHANGE_FOCUS_KEY: 'change-focus'
        },
        EVENTS: {
            /**
             * @type {'pagination'}
             * Event type for pagination changes
             */
            PAGINATION: 'pagination',
            /**
             * @type {'filter'}
             * Event type for filter changes
             */
            FILTER: 'filter',
            /**
             * @type {'sort'}
             * Event type for sort changes
             */
            SORT: 'sort',
            /**
             * @type {'items'}
             * Event type for item changes
             */
            ITEMS: 'items',
            /**
             * @type {'click'}
             * Event type for clicks
             */
            CLICK: 'click'
        },
        TAGGING: {
            EXPLORE_ENERGY_CLICK: 'explore.energy.click'
        }
    }
    /**
     * Constructor for the ProgramSavingsFilters module
     * @constructor
     * @param {HTMLElement} $container - The container element for the module
     * @returns {}
     */
    var ProgramSavingsFilters = function ($container) {
        /**
         * PRIVATE VARIABLES
         */
        /**
         * @type {PrivateState}
         * Holds the private state of the module
         */
        var _privateState,
            /**
             * @type {HTMLElement}
             */
            $resultsCta,
            /**
             * @type {HTMLElement}
             */
            $filters;

        /**
         * PRIVATE METHODS
         */

        /**
         * Sorts items in ascending order based on their title
         * @param {PaginationItem} a - First item to compare
         * @param {PaginationItem} b - Second item to compare
         * @returns {number} - Comparison result
         */
        var sortTitleAZ = function (a, b) {
            var titleA = a.title.toUpperCase();
            var titleB = b.title.toUpperCase();

            if (titleA < titleB) {
                return -1;
            }
            if (titleA > titleB) {
                return 1;
            }
            return 0;
        };
        /**
         * Sorts items in descending order based on their title
         * @param {PaginationItem} a - First item to compare
         * @param {PaginationItem} b - Second item to compare
         * @returns {number} - Comparison result
         */
        var sortTitleZA = function (a, b) {
            var titleA = a.title.toUpperCase();
            var titleB = b.title.toUpperCase();

            if (titleA < titleB) {
                return 1;
            }
            if (titleA > titleB) {
                return -1;
            }
            return 0;
        };
        /**
         * Sorts items based on their featured status
         * @param {PaginationItem} a - First item to compare
         * @param {PaginationItem} b - Second item to compare
         * @returns {number} - Comparison result
         */
        var sortFeatured = function (a, b) {
            var titleA = a.title.toUpperCase();
            var titleB = b.title.toUpperCase();

            if (titleA < titleB) {
                return -1;
            }
            if (titleA > titleB) {
                return 1;
            }
            return 0;
        };

        /**
         * Builds pages of sorted items based on the current filter and sort state
         * @returns {SortedItem[][]} - Array of sorted items grouped by page
         */
        var buildPages = function () {
            if (_privateState.filteredItems.length > 0) {
                var items = _privateState.filteredItems.concat(),
                    sortType = _privateState.paginationSortState ? _privateState.paginationSortState.value : _privateState.textSortAz;
                if (sortType === _privateState.textSortFeatured) {
                    items = items.sort(sortFeatured);
                }
                if (sortType === _privateState.textSortZa) {
                    items = items.sort(sortTitleZA);
                }
                else {
                    items = items.sort(sortTitleAZ);
                }
                if (typeof _privateState.paginationState.itemsPerPage === 'number' &&
                    _privateState.paginationState.itemsPerPage > 0
                ) {
                    /**
                     * Reduce function to group items into pages
                     * @param {SortedItem[][]} acc - Accumulator for grouped items
                     * @param {SortedItem} value - Current item to add
                     * @param {number} index - Current index of the item
                     * @returns {SortedItem[][]} - Updated accumulator
                     */
                    var reducePage = function (acc, value, index) {
                        // Determine the current page index based on items per page
                        var currentPage = Math.floor(index / _privateState.paginationState.itemsPerPage);

                        // If the current page doesn't exist in the accumulator, create it
                        if (!acc[currentPage]) {
                            acc[currentPage] = [];
                        }

                        // Add the current value to the current page
                        acc[currentPage].push(value);

                        return acc;
                    };
                    return items.map(function (item, index) {
                        return {
                            order: index + 1, // Assign order based on index
                            itemId: item.id // Store the item's ID
                        }
                    }).reduce(reducePage, [])
                }
            }
            return [] // Return an empty array if no items are filtered
        }
        /**
         * Reduce function to calculate the total count of selected filters
         * @param {number} acc - Accumulator for the count
         * @param {MultipleSelect} select - Current multiple select filter
         * @returns {number} - Updated count
         */
        var reduceFiltersCount = function (acc, select) {
            return acc += select.value.length; // Increment count by the number of selected values
        }

        /**
         * Returns the total count of selected filters
         * @returns {number} - Total count of selected filters
         */
        var countFilters = function () {
            if (_privateState.filtersState) {
                return _privateState.filtersState.concat().reduce(reduceFiltersCount, 0); // Calculate total count
            }
            return 0 // Return 0 if no filters are set
        }
        /**
         * Filters items based on selected categories
         */
        var getFilteredItems = function () {
            var recordCategories = {};
            _privateState.filtersState.forEach(function (filter) {
                if (filter.value.length > 0) {
                    recordCategories[filter.name] = filter.value // Record selected values for each filter
                }
            });
            // Filter items based on the recorded categories
            _privateState.filteredItems = _privateState.paginationItemsState.items.concat().filter(function (item) {
                return coned.utils.hasMatchingRecord(item.categories, recordCategories);
            });
        }

        /**
         * Changes the state of the module based on the event type
         * @param {'pagination' | 'sort' | 'items' | 'filter'} event - The type of event that triggered the state change
         */
        var changeState = function (event) {
            if (_privateState.isSetedFilters && _privateState.isSetedItems && _privateState.isSetedPagination &&
                _privateState.paginationState && _privateState.filtersState && _privateState.paginationItemsState &&
                _privateState.isSetedSort && _privateState.paginationSortState
            ) {
                var count = countFilters(); // Get the count of selected filters
                if (count === 0) {
                    _privateState.filteredItems = _privateState.paginationItemsState.items.concat(); // Reset filtered items if no filters are set
                    if (_privateState.$buttonClear) {
                        coned.utils.setAriaHidden(_privateState.$buttonClear, true); // Hide the clear button
                        _privateState.$buttonClear.blur()
                    }
                    if (_privateState.$buttonSrClear) {
                        coned.utils.setAriaHidden(_privateState.$buttonSrClear, true); // Hide the clear button
                        _privateState.$buttonClear.blur()
                    }
                    if (_privateState.$spanFilterCount && _privateState.spanFilterCountHTML) {
                        _privateState.$spanFilterCount.innerHTML = _privateState.spanFilterCountHTML; // Reset filter count display
                    }
                    if (_privateState.$clearCount && _privateState.clearCountHTML) {
                        _privateState.$clearCount.innerHTML = _privateState.clearCountHTML; // Reset clear count display
                    }
                } else {
                    if (_privateState.$buttonClear) {
                        coned.utils.setAriaHidden(_privateState.$buttonClear, false); // Show the clear button
                    }
                    if (_privateState.$buttonSrClear) {
                        coned.utils.setAriaHidden(_privateState.$buttonSrClear, false); // Show the clear button
                    }
                    if (_privateState.$spanFilterCount && _privateState.spanFilterCountHTML) {
                        _privateState.$spanFilterCount.innerHTML = _privateState.spanFilterCountHTML + ' (' + count + ')'; // Update filter count display
                    }
                    if (_privateState.$clearCount && _privateState.clearCountHTML) {
                        _privateState.$clearCount.innerHTML = _privateState.clearCountHTML + ' (' + count + ')'; // Update clear count display
                    }
                    getFilteredItems(); // Get the filtered items based on current filters
                }
                // Update pagination state if filters or sort have changed
                if (event === CONSTANTS.EVENTS.FILTER || event === CONSTANTS.EVENTS.SORT || _privateState.paginationState.totalItems !== _privateState.filteredItems.length) {
                    _privateState.paginationState.totalItems = _privateState.filteredItems.length; // Update total items count
                    _privateState.paginationState.currentPage = 1; // Reset to the first page
                    _privateState.paginationState.canScroll = false;
                    coned.utils.triggerEvent(
                        _privateState.$pagination,
                        coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                        _privateState.paginationState // Trigger event to update pagination state
                    );
                }
                // Handle pagination state changes
                if (event === CONSTANTS.EVENTS.PAGINATION) {
                    _privateState.paginationItemsState.pagination = _privateState.paginationState; // Update pagination items state
                    _privateState.paginationItemsState.sortedPages = buildPages(); // Build sorted pages
                    if (_privateState.$paginationItems) {
                        coned.utils.triggerEvent(
                            _privateState.$paginationItems,
                            coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,                            
                            _privateState.paginationItemsState // Trigger event to update pagination items state
                        );
                    }
                }
            }
        }

        /**
         * Handles changes to the pagination state
         * @param {CustomEvent<PaginationState>} event - The custom event containing pagination state details
         */
        var handlePaginationChange = function (event) {
            if (!event.detail.isSeted) {
                _privateState.paginationState = event.detail // Set pagination state from event detail
                _privateState.paginationState.isSeted = true; // Mark pagination state as set
                _privateState.paginationState.$srLive = _privateState.$srLive;
                coned.utils.triggerEvent(
                    _privateState.$pagination,
                    coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                    _privateState.paginationState // Trigger event to update pagination state
                );
            }
            else {
                _privateState.isSetedPagination = true; // Mark pagination as set
                _privateState.paginationState = event.detail // Update pagination state from event detail
                if (_privateState.$paginationInformation) {
                    coned.utils.triggerEvent(
                        _privateState.$paginationInformation,
                        coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                        _privateState.paginationState // Trigger event to update pagination information
                    );
                }
                if (_privateState.$paginationInformationMobile) {
                    _privateState.$paginationInformationMobile.innerHTML =
                        _privateState.paginationState.totalItems + CONSTANTS.VALUES.RESULTS;
                }
                changeState(CONSTANTS.EVENTS.PAGINATION) // Change state based on pagination event
            }
        }

        /**
         * Handles changes to the pagination items state
         * @param {CustomEvent<PaginationItemsState>} event - The custom event containing pagination items state details
         */
        var handlePaginationItemsChange = function (event) {
            if (!event.detail.isSeted) {
                _privateState.paginationItemsState = event.detail // Set pagination items state from event detail
                _privateState.paginationItemsState.isSeted = true; // Mark pagination items state as set
                coned.utils.triggerEvent(
                    _privateState.$paginationItems,
                    coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                    _privateState.paginationItemsState // Trigger event to update pagination items state
                );
                _privateState.isSetedItems = true; // Mark items as set
                changeState(CONSTANTS.EVENTS.ITEMS); // Change state based on items event
            }
        }
        /**
         * Handles changes to the pagination sort state
         * @param {CustomEvent<SingleSelect>} event - The custom event containing sort state details
         */
        var handlePaginationSortChange = function (event) {
            var $target = event.target || event.currentTarget,
                isTargetMobile = $target instanceof HTMLElement &&
                    $target.classList.contains(CONSTANTS.SELECTORS.PAGINATION_SORT_MOBILE);
            if (!_privateState.isSetedSort) {
                _privateState.isSetedSort = true // Mark sort state as set
            }
            _privateState.paginationSortState = event.detail; // Update sort state from event detail 
            if (!isTargetMobile) {
                // Change state based on sort event
                changeState(CONSTANTS.EVENTS.SORT);
                if (!_privateState.modal.open) {
                    coned.utils.triggerEvent(
                        _privateState.$paginationSortMobile,
                        coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                        { value: event.detail.value === '' ? CONSTANTS.VALUES.SORT_AZ : event.detail.value }
                    )
                }
            } else {
                if (_privateState.modal.open) {
                    coned.utils.triggerEvent(
                        _privateState.$paginationSort,
                        coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                        { value: event.detail.value === CONSTANTS.VALUES.SORT_AZ ? '' : event.detail.value }
                    )
                }
            }
        }
        /**
         * Handles changes to the filter state
         * @param {CustomEvent<MultipleSelect>} event - The custom event containing filter state details
         */
        var handleFilterChange = function (event) {
            var isFilterMobile = event.detail.name.includes(CONSTANTS.VALUES.MOBILE_NAME),
                isMobileTableView = window.innerWidth < CONSTANTS.VALUES.DESKTOP_BREAKPOINT,
                desktopName = event.detail.name.replace(CONSTANTS.VALUES.MOBILE_NAME, CONSTANTS.VALUES.EMPTY),
                mobileName = desktopName + CONSTANTS.VALUES.MOBILE_NAME;

            if (!isFilterMobile) {
                if (!_privateState.isSetedFilters) {
                    var indexFound = _privateState.filtersState.indexOf(function (el) {
                        return el.name === desktopName;
                    });

                    if (indexFound === -1) {
                        _privateState.filtersState.push({
                            name: event.detail.name, // Add new filter state
                            value: event.detail.value
                        });
                    }
                }
                else {
                    var newState = _privateState.filtersState.concat().map(function (el) {
                        if (el.name === event.detail.name) {
                            return {
                                name: event.detail.name,
                                value: event.detail.value // Update existing filter state
                            }
                        }
                        return el; // Return unchanged filter state
                    });
                    _privateState.filtersState = newState.concat(); // Update filters state
                }
                _privateState.isSetedFilters = _privateState.$filters.length - _privateState.filtersState.length === _privateState.filtersState.length; // Check if all filters are set
                if (_privateState.isSetedFilters && !_privateState.isHandledFilterState) {
                    changeState(CONSTANTS.EVENTS.FILTER); // Change state based on filter event
                }
                if (!isMobileTableView && _privateState.$filters) {
                    var $filterMobile = _privateState.$filters.find(function ($el) {
                        return $el.dataset.name === mobileName;
                    })
                    if ($filterMobile) {
                        coned.utils.triggerEvent(
                            $filterMobile,
                            coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                            {
                                value: event.detail.value
                            }
                        );
                    }
                }
            } else {
                if (isMobileTableView && _privateState.$filters && _privateState.isSetedFilters) {
                    var $filterDesktop = _privateState.$filters.find(function ($el) {
                        return $el.dataset.name === desktopName;
                    })
                    if ($filterDesktop) {
                        coned.utils.triggerEvent(
                            $filterDesktop,
                            coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                            {
                                value: event.detail.value
                            }
                        );
                    }
                }
            }
        }
        /**
         * Handles the clear button click event
         * @param {MouseEvent} event - The mouse event triggered by clicking the clear button
         */
        var handleClearClick = function (event) {
            var $target = event.currentTarget || event.target; // Get the target element of the event
            if ($target instanceof HTMLButtonElement &&
                !$target.classList.contains(CONSTANTS.SELECTORS.BUTTON_CLEAR_MOBILE)
            ) {
                var isVisuallyHiddenTarget = $target.classList.contains(CONSTANTS.CLASSES.FOCUS_VISIBLE),
                    $nextFocus = coned.utils.getNextPrevFocus({ order: isVisuallyHiddenTarget ? 'prev' : 'next' });
                if ($nextFocus) {
                    $target.blur();
                    $nextFocus.focus();
                    if (_privateState.$srLive) {
                        var targetFocusSR = $target.textContent;
                        var nextFocusSR = $nextFocus.textContent;
                        if ($nextFocus.getAttribute(coned.constants.ARIA.LABEL)) {
                            nextFocusSR = $nextFocus.getAttribute(coned.constants.ARIA.LABEL) +
                                CONSTANTS.VALUES.EMPTY + nextFocusSR;
                        }
                        if ($target.getAttribute(coned.constants.ARIA.LABEL)) {
                            targetFocusSR = $target.getAttribute(coned.constants.ARIA.LABEL) +
                                CONSTANTS.VALUES.EMPTY + targetFocusSR;
                        }
                        coned.utils.triggerEvent(
                            _privateState.$srLive,
                            coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                            {
                                valueKey: CONSTANTS.VALUES.CHANGE_FOCUS_KEY,
                                formatValues: [targetFocusSR, nextFocusSR]
                            }
                        );
                    }
                }
                coned.utils.setAriaHidden($target, true); // Hide the clear button
            }
            _privateState.isHandledFilterState = true; // Mark filter state as handled
            _privateState.$filters.forEach(function ($el, index) {
                if ((index + 1) === _privateState.$filters.length) {
                    _privateState.isHandledFilterState = false; // Reset handled state for the last filter
                }
                coned.utils.triggerEvent($el,
                    coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                    { value: [] } // Clear the filter value
                );
            });
        }
        /**
         * Update modal footer html
         * @param {boolean} show 
         */
        var setModalFooterShowHTML = function (show) {
            if (_privateState.$modalFooter) {
                if (show) {
                    if (!_privateState.$modalFooter.classList.contains(
                        CONSTANTS.CLASSES.FOOTER_MOBILE_SHOW)
                    ) {
                        _privateState.$modalFooter.classList.add(
                            CONSTANTS.CLASSES.FOOTER_MOBILE_SHOW);
                    }
                } else {
                    if (_privateState.$modalFooter.classList.contains(
                        CONSTANTS.CLASSES.FOOTER_MOBILE_SHOW)
                    ) {
                        _privateState.$modalFooter.classList.remove(
                            CONSTANTS.CLASSES.FOOTER_MOBILE_SHOW);
                    }
                }
            }

        }
        /**
         * Handles the modal state change
         * @param {CustomEvent<StateModal>} event - The mouse event triggered by clicking the clear button
         */
        var handleModalStateChange = function (event) {
            _privateState.modal = event.detail;
            setTimeout(function () {
                if (_privateState.modal.open) {
                    setModalFooterShowHTML(true);
                } else {
                    setModalFooterShowHTML(false);
                }
            }, 400);
        }
        /**
         * Listen SrLive Module change state
         * @param {CustomEvent<SrLiveState>} event 
         */
        var handleChangeSrLive = function (event) {
            _privateState.srLiveState = event.detail;
        }
        /**
         * Add tagging for results
         */
        var addResultsTagging = function () {
            // Analytics data building
            dataLayer.push({
                page_title: document.title,
                page_url: window.location.href
            });
        }
        /**
         * Add tagging for filters
         * @param {Event} event Click event
         */
        var addFiltersTagging = function (event) {
            var $filter = event.currentTarget,
                $selectMultipleParent = query.selectParentElement(
                    $filter, CONSTANTS.SELECTORS.FILTERS
                ),
                $header = $selectMultipleParent.getElementsByClassName(
                    CONSTANTS.SELECTORS.FILTERS_HEADER
                )[0],
                headerText = $header && $header.innerText,
                $filterCheckbox = $filter.getElementsByClassName(
                    CONSTANTS.SELECTORS.CHECKBOX
                )[0];

            dataLayer.push({
                event: CONSTANTS.TAGGING.EXPLORE_ENERGY_CLICK,
                filter_name: headerText,
                filter_value: $filterCheckbox.value
            });
        };
        /**
         * Initializes the data in the module
         */
        var initializeData = function () {
            _privateState = {
                $pagination: $container.getElementsByClassName(CONSTANTS.SELECTORS.PAGINATION)[0], // Get pagination element
                $paginationInformation: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.PAGINATION_INFORMATION)[0], // Get pagination information element
                $paginationInformationMobile: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.PAGINATION_INFORMATION_MOBILE)[0], // Get pagination information mobile element
                $paginationItems: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.PAGINATION_ITEMS)[0], // Get pagination items element
                $filters: coned.utils.arrayFrom($container.getElementsByClassName(
                    CONSTANTS.SELECTORS.FILTERS)), // Get all filter elements
                $paginationSort: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.PAGINATION_SORT)[0], // Get pagination sort element
                $paginationSortMobile: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.PAGINATION_SORT_MOBILE)[0], // Get pagination sort element mobile                    
                $modal: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.MODAL)[0],
                $modalFooter: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.MODAL_FOOTER)[0],
                filtersState: [], // Initialize filters state
                isSetedSort: false, // Initialize sort state
                isSetedFilters: false, // Initialize filters state
                isSetedPagination: false, // Initialize pagination state
                isSetedItems: false, // Initialize items state
                isHandledFilterState: false, // Initialize handled filter state
                modal: {
                    open: false
                },
                $buttonClear: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.BUTTON_CLEAR)[0], // Get clear button element
                $buttonSrClear: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.BUTTON_SR_CLEAR)[0], // Get clear button element
                $buttonClearMobile: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.BUTTON_CLEAR_MOBILE)[0], // Get clear button mobile element
                $spanFilterCount: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.FILTER_COUNT)[0], // Get filter count display element
                $clearCount: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.CLEAR_COUNT)[0], // Get clear count display element
                $srLive: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.SR_LIVE)[0], // Get sr live element
                textSortAz: typeof $container.dataset.textSortAz === 'string' 
                    ? $container.dataset.textSortAz //add support pass text of the dropdown value for ascendant
                    : CONSTANTS.VALUES.SORT_AZ, // set a default value if not pass dataset
                textSortZa: typeof $container.dataset.textSortZa === 'string' 
                    ? $container.dataset.textSortZa //add support pass text of the dropdown value for descendant
                    : CONSTANTS.VALUES.SORT_ZA, // set a default value if not pass dataset
                textSortFeatured: typeof $container.dataset.textSortFeatured === 'string' 
                    ? $container.dataset.textSortFeatured //add support pass text of the dropdown value for descendant
                    : CONSTANTS.VALUES.SORT_FEATURED // set a default value if not pass dataset
            };
            if (_privateState.$spanFilterCount) {
                _privateState.spanFilterCountHTML = _privateState.$spanFilterCount.innerHTML; // Store initial filter count HTML
            }
            if (_privateState.$clearCount) {
                _privateState.clearCountHTML = _privateState.$clearCount.innerHTML; // Store initial clear count HTML
            }
            $resultsCta = document.getElementsByClassName(CONSTANTS.SELECTORS.RESULT_CTA);
            $filters = document.getElementsByClassName(CONSTANTS.SELECTORS.FILTERS_OPTIONS);
        }

        /**
         * Initializes the events in the module
         */
        var initializeEvents = function () {
            if (_privateState.$pagination) {
                _privateState.$pagination.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handlePaginationChange // Handle pagination state changes
                );
            }
            if (_privateState.$paginationItems) {
                _privateState.$paginationItems.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handlePaginationItemsChange // Handle pagination items state changes
                );
            }
            if (_privateState.$paginationSort) {
                _privateState.$paginationSort.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handlePaginationSortChange // Handle pagination sort state changes
                );
            }
            if (_privateState.$paginationSortMobile) {
                _privateState.$paginationSortMobile.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handlePaginationSortChange // Handle pagination sort mobile state changes
                );
            }
            if (_privateState.$filters && Array.isArray(_privateState.$filters)) {
                _privateState.$filters.forEach(function ($el) {
                    $el.addEventListener(
                        coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                        handleFilterChange // Handle filter state changes
                    );
                });
            }
            if (_privateState.$buttonClear) {
                _privateState.$buttonClear.addEventListener('click',
                    handleClearClick // Handle clear button click
                );
            }
            if (_privateState.$buttonSrClear) {
                _privateState.$buttonSrClear.addEventListener('click',
                    handleClearClick // Handle clear button click
                );
            }
            if (_privateState.$buttonClearMobile) {
                _privateState.$buttonClearMobile.addEventListener('click',
                    handleClearClick // Handle clear button click
                );
            }
            if (_privateState.$modal) {
                _privateState.$modal.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handleModalStateChange
                );
            }
            if (_privateState.$srLive) {
                _privateState.$srLive.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handleChangeSrLive
                );
            }

            // Tagging
            Array.from($resultsCta).forEach(function($resultCta){
                $resultCta.addEventListener(CONSTANTS.EVENTS.CLICK, addResultsTagging);
            });
            Array.from($filters).forEach(function ($filter) {
                $filter.addEventListener(CONSTANTS.EVENTS.CLICK, addFiltersTagging);
            });
        }

        /**
         * Initializes functionality in the module
         */
        var init = function () {
            initializeData(); // Initialize data for the module
            initializeEvents(); // Initialize event listeners for the module
            isLoaded = true; // Mark the module as loaded
        }
        init(); // Call the init function to start the module
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to check if the module is loaded
     * @returns {boolean} - True if the module is loaded, false otherwise
     */
    ProgramSavingsFilters.prototype.isLoaded = function () {
        return isLoaded; // Return the loaded state of the module
    };
    return ProgramSavingsFilters; // Return the ProgramSavingsFilters constructor
})();
