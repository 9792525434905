// ==================== ONLOAD PAGE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.OnloadPageModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ONLOAD_HIDDEN_CLASS: 'onload-hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var OnloadPageModule = function () {
        /**
         * PRIVATE METHODS
         */
        var $onloadHiddenItems;

        var initializeData = function () {
            $onloadHiddenItems = document.getElementsByClassName(CONSTANTS.ONLOAD_HIDDEN_CLASS);
        };

        var initializeEvents = function () {
            while ($onloadHiddenItems && $onloadHiddenItems.length) {
                for (var index = 0; index < $onloadHiddenItems.length; ++index) {
                    $onloadHiddenItems[index].classList.remove(CONSTANTS.ONLOAD_HIDDEN_CLASS);
                }
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();

            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    OnloadPageModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return OnloadPageModule;
})();
