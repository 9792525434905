// ==================== PAGINATION COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PaginationComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        PAGINATION_CONTENT_CLASS: 'js-pagination-content',
        PAGINATION_RESET_CLASS: 'js-pagination-reset',
        PAGINATION_CLASS: 'pagination',
        PAGINATION_ORU_CLASS: 'pagination--oru',
        PAGINATION_MOBILE_HIDE_CLASS: 'pagination--desktop',
        PAGINATION_MOBILE_SHOW_CLASS: 'pagination--mobile',
        PAGINATION_ITEM_CLASS: 'pagination__item',
        PAGINATION_ITEM_WIDER_CLASS: 'pagination__item--wider',
        PAGINATION_LINK_CLASS: 'pagination__link',
        PAGINATION_ICON_CLASS: 'icon-carrot',
        PAGINATION_PREV_CLASS: 'pagination__link--prev',
        PAGINATION_NEXT_CLASS: 'pagination__link--next',
        PAGINATION_TEXT_CLASS: 'pagination__text',
        PAGINATION_ELEMENT: 'ul',
        PAGINATION_ITEM_ELEMENT: 'li',
        PAGINATION_LINK_ELEMENT: 'button',
        PAGINATION_TEXT_ELEMENT: 'p',
        PAGINATION_TOTAL_CLASS: 'js-total',
        PAGINATION_START_CLASS: 'js-start',
        PAGINATION_END_CLASS: 'js-end',
        PAGINATION_PAGE_URL_PARAMETER_NAME: 'page',
        PAGINATION_PAGE_URL_PARAMETER: 'page=',
        PAGINATION_PAGE_TEXT: 'Page ',
        PAGINATION_RESULTS_CLASS: 'js-pagination-results',
        PAGINATION_LIMIT: 5,
        PAGINATION_ANIMATION_TIME: 300,
        HEADER_WRAPPER_CLASS: 'js-header-wrapper',

        PAGINATION_HIDDEN_ELEMENT: 'js-pagination-item-hidden',
        MAIN_CONTENT: '#mainContent',
        RESULTS_ANIMATION_SELECTOR: 'js-results-selector',
        RESULTS_ANIMATION_CLASS: 'search-results__item--animation',

        ARIA_LABEL: 'aria-label',
        ARIA_CURRENT: 'aria-current',
        ARIA_PAGE_TEXT: 'page',
        ARIA_LABEL_PAGE_TEXT: 'Go to Page ',
        ARIA_LABEL_NEXT_PAGE_TEXT: 'Go to Next Page',
        ARIA_LABEL_PREVIOUS_PAGE_TEXT: 'Go to Previous Page'
    };

    var isLoaded = false;
    var changeEvent;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var PaginationComponent = function ($paginationElement) {
        /**
         * PRIVATE METHODS
         */
        var $paginationList,
            $paginationMobileList,
            $paginationReset,
            $paginationContent,
            $paginationResults,
            $paginablePaginationResults,
            $headerWrapperElement,
            $mainContent,
            _paginationResultsHideClass,
            _paginationTotal,
            _paginationDivider,
            _pagesTotal,
            _paginationStart,
            _paginationEnd,
            _isStaticPagination,
            _paginationMiddle,
            _currentPage,
            _animationOnChange,
            _loaded = false,
            $resultsAnimationSelector;

        var pageChange = function (event) {
            event.preventDefault();

            // Create anchor to be able to use the location enabled functionality
            var location = document.createElement('a');
            location.href = event.target.dataset.href;

            var path = $paginationContent.dataset.service + location.search;

            // Parameters: state object, title, URL
            window.history.pushState(
                { page: location.href },
                '',
                location.pathname + location.search
            );

            // For Pattern Lab, redirect to template file
            if (coned.utils.isPatternLab()) {
                path = coned.plConstants.PATTERNLAB_SEARCH_RESULTS_PAGE_PATH;
            }

            query.getData(
                path,
                function (data) {
                    // Set the search results content with the data received
                    $paginationContent.innerHTML = data;

                    // Reset the pagination element so it represents the page change
                    resetPaginationItems();
                    animateItems();
                    window.scrollTo(0, 0);
                },
                function () {
                    // Fail Callback
                    // Manage error
                }
            );

            $mainContent && $mainContent.focus();
            query.fireEvent($paginationResults, 'page-change');
        };

        var staticPageChange = function (event) {
            event.preventDefault();

            var index = event.target.dataset.href,
                displayTotal = _paginationDivider * index,
                showPageIndex,
                $resultToShow,
                scrollToPosition,
                coordinates,
                $visibleResults = [];

            // Hide all results
            _.each($paginationResults.children, function ($result) {
                query.addClass($result, _paginationResultsHideClass);
            });

            // Show the corresponding page results
            $paginablePaginationResults = $paginationResults.parentElement.querySelectorAll(
                '.' +
                    CONSTANTS.PAGINATION_RESULTS_CLASS +
                    ' > :not(.' +
                    CONSTANTS.PAGINATION_HIDDEN_ELEMENT +
                    ')'
            );
            for (
                showPageIndex = displayTotal - _paginationDivider;
                showPageIndex < displayTotal && showPageIndex < _paginationTotal;
                showPageIndex++
            ) {
                $resultToShow = $paginablePaginationResults[showPageIndex];

                query.removeClass($resultToShow, _paginationResultsHideClass);

                $visibleResults.push($resultToShow);
            }

            if (_loaded) {
                if (_animationOnChange) {
                    coordinates = $paginationContent.getBoundingClientRect();

                    scrollToPosition =
                        coordinates.top + window.pageYOffset - $headerWrapperElement.offsetHeight;
                    coned.utils.scrollTo(scrollToPosition, CONSTANTS.PAGINATION_ANIMATION_TIME);
                }

                if (changeEvent) {
                    changeEvent($visibleResults);
                }
            } else {
                _loaded = !_loaded;
            }

            resetStaticPaginationItems(index);
            $mainContent && $mainContent.focus();
            query.fireEvent($paginationResults, 'page-change');
        };

        var manualPageChange = function (index) {
            var displayTotal = _paginationDivider * index,
                showPageIndex,
                $resultToShow,
                scrollToPosition,
                $visibleResults = [];

            // Hide all results
            _.each($paginationResults.children, function ($result) {
                query.addClass($result, _paginationResultsHideClass);
            });

            // Show the corresponding page results
            for (
                showPageIndex = displayTotal - _paginationDivider;
                showPageIndex < displayTotal && showPageIndex < _paginationTotal;
                showPageIndex++
            ) {
                $resultToShow = $paginationResults.children[showPageIndex];

                query.removeClass($resultToShow, _paginationResultsHideClass);
                $visibleResults.push($resultToShow);
            }

            if (_loaded) {
                if (_animationOnChange) {
                    scrollToPosition =
                        $paginationContent.offsetTop - $headerWrapperElement.offsetHeight;
                    coned.utils.scrollTo(scrollToPosition, CONSTANTS.PAGINATION_ANIMATION_TIME);
                }
                if (changeEvent) {
                    changeEvent();
                }
            } else {
                _loaded = !_loaded;
            }

            resetStaticPaginationItems(index);
            $mainContent && $mainContent.focus();
            query.fireEvent($paginationResults, 'page-change');
        };

        var resetPaginationItems = function (event) {
            if (event != undefined) {
                event.preventDefault();
            }

            _paginationTotal = $paginationContent.getElementsByClassName(
                CONSTANTS.PAGINATION_TOTAL_CLASS
            )[0];

            if (_paginationTotal != null) {
                _paginationTotal = parseInt(_paginationTotal.innerText);
            } else {
                _paginationTotal = 0;
            }

            _pagesTotal = Math.ceil(_paginationTotal / _paginationDivider);

            query.clearElement($paginationElement);

            if (_isStaticPagination) {
                pageValuesChange(1);
            }

            if (_pagesTotal > 1) {
                createPaginationItems();
            }
        };

        var resetStaticPaginationItems = function (index) {
            _paginationTotal = $paginationContent.getElementsByClassName(
                CONSTANTS.PAGINATION_TOTAL_CLASS
            )[0];

            if (_paginationTotal != null) {
                _paginationTotal = parseInt(_paginationTotal.innerText);
            } else {
                _paginationTotal = 0;
            }

            _pagesTotal = Math.ceil(_paginationTotal / _paginationDivider);

            query.clearElement($paginationElement);

            pageValuesChange(index);

            if (_pagesTotal > 1) {
                createPaginationItems();
            }
        };

        var animateItems = function () {
            var cascadeAnimation = 0;

            _.each($resultsAnimationSelector, function ($result) {
                cascadeAnimation += CONSTANTS.PAGINATION_ANIMATION_TIME;
                setTimeout(function () {
                    query.removeClass($result, CONSTANTS.RESULTS_ANIMATION_CLASS);
                }, cascadeAnimation);
            });
        };

        var pageValuesChange = function (index) {
            $paginationElement.dataset.activePage = index;

            _paginationStart.innerHTML = _paginationDivider * index - (_paginationDivider - 1);
            _paginationEnd.innerHTML =
                _paginationDivider * index < _paginationTotal
                    ? _paginationDivider * index
                    : _paginationTotal;
        };

        var createPaginationArrow = function (specificArrowClass, pageNumber, $paginationList) {
            var $arrowItem = document.createElement(CONSTANTS.PAGINATION_ITEM_ELEMENT);
            var $arrowLink = document.createElement(CONSTANTS.PAGINATION_LINK_ELEMENT);
            var urlSeparator = window.location.href.indexOf('?') === -1 ? '?' : '&';

            if (_isStaticPagination) {
                $arrowLink.dataset.href = pageNumber;
            } else {
                if (window.location.search.includes(CONSTANTS.PAGINATION_PAGE_URL_PARAMETER_NAME)) {
                    $arrowLink.dataset.href = coned.utils.updateUrlParameter(
                        CONSTANTS.PAGINATION_PAGE_URL_PARAMETER_NAME,
                        pageNumber
                    );
                } else {
                    $arrowLink.dataset.href =
                        window.location.href +
                        urlSeparator +
                        CONSTANTS.PAGINATION_PAGE_URL_PARAMETER +
                        pageNumber;
                }
            }

            var buttonLabel = specificArrowClass === CONSTANTS.PAGINATION_PREV_CLASS 
                            ? CONSTANTS.ARIA_LABEL_PREVIOUS_PAGE_TEXT  
                            : CONSTANTS.ARIA_LABEL_NEXT_PAGE_TEXT;
            $arrowLink.setAttribute(CONSTANTS.ARIA_LABEL, buttonLabel);
            $arrowLink.setAttribute(CONSTANTS.ARIA_CURRENT, false); 
            query.addClass($arrowItem, CONSTANTS.PAGINATION_ITEM_CLASS);
            query.addClass($arrowLink, CONSTANTS.PAGINATION_LINK_CLASS);
            query.addClass($arrowLink, specificArrowClass);
            query.addClass($arrowLink, CONSTANTS.PAGINATION_ICON_CLASS);

            if (_isStaticPagination) {
                coned.utils.addGeneralListeners($arrowLink, staticPageChange);
            } else {
                coned.utils.addGeneralListeners($arrowLink, pageChange);
            }

            $arrowItem.appendChild($arrowLink);
            $paginationList.appendChild($arrowItem);
        };

        var createPaginationItem = function (itemIndex, pageInitText, $paginationList) {
            var $paginationText,
                $paginationItem = document.createElement(CONSTANTS.PAGINATION_ITEM_ELEMENT),
                urlSeparator = window.location.href.indexOf('?') === -1 ? '?' : '&',
                isCurrentPage = false;

            // If page item to create is not the current page, it will be a link
            if (itemIndex != _currentPage) {
                $paginationText = document.createElement(CONSTANTS.PAGINATION_LINK_ELEMENT);
                
                if (_isStaticPagination) {
                    $paginationText.dataset.href = itemIndex;
                } else {
                    // For url related pagination, update the url with the page to go if parameter exists
                    if (
                        window.location.search.includes(
                            CONSTANTS.PAGINATION_PAGE_URL_PARAMETER_NAME
                        )
                    ) {
                        $paginationText.dataset.href = coned.utils.updateUrlParameter(
                            CONSTANTS.PAGINATION_PAGE_URL_PARAMETER_NAME,
                            itemIndex
                        );
                        // If not, create the url from scratch, adding the parameter + value
                    } else {
                        $paginationText.dataset.href =
                            window.location.href +
                            urlSeparator +
                            CONSTANTS.PAGINATION_PAGE_URL_PARAMETER +
                            itemIndex;
                    }
                }

                query.addClass($paginationText, CONSTANTS.PAGINATION_LINK_CLASS);

                if (_isStaticPagination) {
                    coned.utils.addGeneralListeners($paginationText, staticPageChange);
                } else {
                    coned.utils.addGeneralListeners($paginationText, pageChange);
                }
                // If it is, it'll be just text
            } else {
                $paginationText = document.createElement(CONSTANTS.PAGINATION_TEXT_ELEMENT);

                query.addClass($paginationText, CONSTANTS.PAGINATION_TEXT_CLASS);
                query.addClass($paginationItem, CONSTANTS.PAGINATION_ITEM_WIDER_CLASS);
                isCurrentPage = CONSTANTS.ARIA_PAGE_TEXT;
            }

            $paginationText.setAttribute(CONSTANTS.ARIA_CURRENT, isCurrentPage);
            $paginationText.setAttribute(CONSTANTS.ARIA_LABEL, CONSTANTS.ARIA_LABEL_PAGE_TEXT + itemIndex);
            $paginationText.innerText = pageInitText + itemIndex;

            query.addClass($paginationItem, CONSTANTS.PAGINATION_ITEM_CLASS);

            $paginationItem.appendChild($paginationText);
            $paginationList.appendChild($paginationItem);
        };

        var createPaginationItems = function () {
            (_pagesTotal = Math.ceil(_paginationTotal / _paginationDivider)), (_currentPage = 1); // For initial load

            // Set current page if it's not the initial load
            if (_isStaticPagination) {
                _currentPage = parseInt($paginationElement.dataset.activePage);
            } else {
                var currentPageString = coned.utils.getUrlParameterValue(
                        CONSTANTS.PAGINATION_PAGE_URL_PARAMETER_NAME
                    ),
                    parseCurrentPage = parseInt(currentPageString);

                if (!isNaN(parseCurrentPage)) {
                    _currentPage = parseCurrentPage;
                }
            }

            // Create pagination list containers
            $paginationList = document.createElement(CONSTANTS.PAGINATION_ELEMENT);
            $paginationMobileList = document.createElement(CONSTANTS.PAGINATION_ELEMENT);

            query.addClass($paginationList, CONSTANTS.PAGINATION_CLASS);
            query.addClass($paginationList, CONSTANTS.PAGINATION_MOBILE_HIDE_CLASS);
            query.addClass($paginationMobileList, CONSTANTS.PAGINATION_CLASS);
            query.addClass($paginationMobileList, CONSTANTS.PAGINATION_MOBILE_SHOW_CLASS);

            if ($paginationElement.dataset.desktopModifiers) {
                query.addClass($paginationList, $paginationElement.dataset.mobileModifiers);
            }

            if ($paginationElement.dataset.mobileModifiers) {
                query.addClass($paginationMobileList, $paginationElement.dataset.mobileModifiers);
            }

            // For each device, a different type of pagination is created
            createMobilePaginationItems();
            createDesktopPaginationItems();

            // Add list to pagination container
            $paginationElement.appendChild($paginationList);
            $paginationElement.appendChild($paginationMobileList);
        };

        var createMobilePaginationItems = function () {
            // If current page is greater than one, previous page item should be created
            if (_currentPage > 1) {
                createPaginationArrow(
                    CONSTANTS.PAGINATION_PREV_CLASS,
                    _currentPage - 1,
                    $paginationMobileList
                );
            }

            // Create the current page element
            createPaginationItem(
                _currentPage,
                CONSTANTS.PAGINATION_PAGE_TEXT,
                $paginationMobileList
            );

            // If current page is not the last, next page item should be created
            if (_currentPage != _pagesTotal) {
                createPaginationArrow(
                    CONSTANTS.PAGINATION_NEXT_CLASS,
                    _currentPage + 1,
                    $paginationMobileList
                );
            }
        };

        var createDesktopPaginationItems = function () {
            var pageCreationStart, pageCreationEnd, customBuild, exceedsLimit, itemIndex;

            // If current page is greater than one, previous page item should be created if pages limit is passed
            if (_currentPage > 1 && _pagesTotal > CONSTANTS.PAGINATION_LIMIT) {
                createPaginationArrow(
                    CONSTANTS.PAGINATION_PREV_CLASS,
                    _currentPage - 1,
                    $paginationList
                );
            }

            // Items creation cycle
            // If the currentPage has a value enough to be the middle one,
            //   the pages to create go from currentPage-paginationMiddle to currentPage+paginationMiddle
            // if not, then it's a value nearing the end of the pagination,
            //   so then the pages go from pagesTotal-(PAGINATION_LIMIT-1) to pagesTotal
            // last scenario would be to just create the pages from 1 until the PAGINATION_LIMIT or pageTotal
            pageCreationStart = _currentPage - _paginationMiddle;
            pageCreationEnd = _currentPage + _paginationMiddle;
            customBuild = _currentPage > _paginationMiddle + 1;
            exceedsLimit = _pagesTotal > CONSTANTS.PAGINATION_LIMIT;

            if (customBuild && exceedsLimit) {
                if (pageCreationEnd <= _pagesTotal) {
                    for (itemIndex = pageCreationStart; itemIndex <= pageCreationEnd; itemIndex++) {
                        createPaginationItem(itemIndex, '', $paginationList);
                    }
                } else {
                    pageCreationStart = _pagesTotal - (CONSTANTS.PAGINATION_LIMIT - 1);

                    for (itemIndex = pageCreationStart; itemIndex <= _pagesTotal; itemIndex++) {
                        createPaginationItem(itemIndex, '', $paginationList);
                    }
                }
            } else if (exceedsLimit) {
                for (itemIndex = 1; itemIndex <= CONSTANTS.PAGINATION_LIMIT; itemIndex++) {
                    createPaginationItem(itemIndex, '', $paginationList);
                }
            } else {
                for (itemIndex = 1; itemIndex <= _pagesTotal; itemIndex++) {
                    createPaginationItem(itemIndex, '', $paginationList);
                }
            }

            // If current page is not the last, next page item should be created if pages limit is passed
            if (_currentPage != _pagesTotal && _pagesTotal > CONSTANTS.PAGINATION_LIMIT) {
                createPaginationArrow(
                    CONSTANTS.PAGINATION_NEXT_CLASS,
                    _currentPage + 1,
                    $paginationList
                );
            }
        };

        var updatingPaginationDivider = function () {
            var tabletPaginationDivider = parseInt($paginationElement.dataset.tabletdivider);
            if (tabletPaginationDivider && coned.utils.isTablet()) {
                _paginationDivider = tabletPaginationDivider;
            } else {
                if (coned.utils.isDesktop()) {
                    _paginationDivider = parseInt($paginationElement.dataset.divider);
                }
            }
        };

        var initializeData = function () {
            $mainContent = document.querySelector(CONSTANTS.MAIN_CONTENT);

            $paginationContent = query.selectParentElement(
                $paginationElement,
                CONSTANTS.PAGINATION_CONTENT_CLASS
            );

            if (!$paginationContent) {
                $paginationContent = $paginationElement.parentNode.getElementsByClassName(
                    CONSTANTS.PAGINATION_CONTENT_CLASS
                )[0];
            }

            $paginationReset = $paginationContent.getElementsByClassName(
                CONSTANTS.PAGINATION_RESET_CLASS
            )[0];

            if (!$paginationReset) {
                $paginationReset = $paginationElement.parentNode.getElementsByClassName(
                    CONSTANTS.PAGINATION_RESET_CLASS
                )[0];
            }

            $resultsAnimationSelector = $paginationContent.getElementsByClassName(
                CONSTANTS.RESULTS_ANIMATION_SELECTOR
            );
            $paginationResults = $paginationContent.getElementsByClassName(
                CONSTANTS.PAGINATION_RESULTS_CLASS
            )[0];
            $headerWrapperElement = document.getElementsByClassName(
                CONSTANTS.HEADER_WRAPPER_CLASS
            )[0];

            if ($paginationResults != undefined) {
                _paginationResultsHideClass = $paginationResults.dataset.resultsHide;
            }

            _isStaticPagination = $paginationElement.dataset.staticPagination === 'true';
            _paginationTotal = $paginationContent.getElementsByClassName(
                CONSTANTS.PAGINATION_TOTAL_CLASS
            )[0];
            _paginationStart = $paginationContent.getElementsByClassName(
                CONSTANTS.PAGINATION_START_CLASS
            )[0];
            _paginationEnd = $paginationContent.getElementsByClassName(
                CONSTANTS.PAGINATION_END_CLASS
            )[0];
            _paginationMiddle = Math.floor(CONSTANTS.PAGINATION_LIMIT / 2);
            _paginationDivider = parseInt($paginationElement.dataset.divider);
            var tabletPaginationDivider = parseInt($paginationElement.dataset.tabletdivider);

            if (tabletPaginationDivider && coned.utils.isTablet()) {
                _paginationDivider = tabletPaginationDivider;
            }

            if (_paginationTotal != null) {
                _paginationTotal = parseInt(_paginationTotal.innerText);
            } else {
                _paginationTotal = 0;
            }

            _animationOnChange = $paginationElement.dataset.scrollTop === 'true';

            _pagesTotal = Math.ceil(_paginationTotal / _paginationDivider);
        };

        var initializeEvents = function () {
            // if ORU page rendering, add '--oru' modifier class
            if (coned.utils.isOru()) {
                query.addClass($paginationElement, CONSTANTS.PAGINATION_ORU_CLASS);
            }

            if ($paginationContent != null && _pagesTotal > 1) {
                createPaginationItems();
            }

            // Adding event listener for reset pagination button
            coned.utils.addGeneralListeners($paginationReset, resetPaginationItems);

            // If static pagination, set first page elements
            if (_isStaticPagination) {
                manualPageChange(1);
            }

            // resize listener
            window.addEventListener('resize', updatingPaginationDivider);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PaginationComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    /**
     * Adds subscriber to listen to changes
     * @param {Element}
     * @param {Function} Callback triggered when pagination changes.
     */
    PaginationComponent.prototype.setChangeEvent = function (callback) {
        changeEvent = callback;
    };

    return PaginationComponent;
})();
