// ==================== RADIO_GROUP =========================

var coned = coned || {};
coned.components = coned.components || {};
/**
 * @returns the init function to start the module
 */
coned.components.RadioGroup = (function () {
    var isLoaded = false;
    /**
     * @typedef PublicState
     * @property {string} name
     * @property {string} value
     */

    /**
     * @typedef PrivateState
     * @property {string | null} [selected]
     * @property {string[]} [options]
     * @property {HTMLElement[]} [$inputs]
     * @property {HTMLElement | null} [$selected]
     * 
     */


    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        ORDER: {
            /**
             * @type {'next'}
             */
            NEXT: 'next',
            /**
             * @type {'prev'}
             */
            PREV: 'prev'
        },
        SELECTORS: {
            /**
             * @type {'js-coned-radio'}
             */
            RADIO: 'js-coned-radio'
        }
    }
    /**
     * @constructor
     * @param {HTMLElement} $container
     * @returns {}
     */
    var RadioGroup = function ($container) {
        /**
         * PRIVATE VARIABLES
         */

        /**
         * @type {PublicState}
         */
        var _publicState,
            /**
             * @type {PrivateState}
             */
            _privateState;
        /**
         * PRIVATE METHODS
         */

        /**
         * Handle external event change state
         * @param {CustomEvent<PublicState>} event
         */
        var handleSetState = function (event) {
            if (_privateState.options && _privateState.$inputs) {
                if (_privateState.options.includes(event.detail.value)) {
                    _publicState.value = event.detail.value;
                } else {
                    _publicState.value = _privateState.default ? _privateState.default : '';
                }
                var $radio = _privateState.$inputs.find(function ($el) {
                    return $el instanceof HTMLInputElement && $el.value === event.detail.value;
                })
                if ($radio) $radio.click();
            }
        }
        /**
         * Handles input radio change event 
         */
        var handleRadioChange = function () {
            _privateState.$inputs.forEach(function ($el) {
                if ($el instanceof HTMLInputElement) {
                    $el.setAttribute(
                        coned.constants.ARIA.CHECKED,
                        $el.checked ? coned.constants.TRUE : coned.constants.FALSE
                    );
                    if ($el.checked) {
                        _publicState.value = $el.value;
                        _privateState.selected = $el.value;
                        _privateState.$selected = $el;
                        coned.utils.triggerEvent(
                            $container,
                            coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                            _publicState
                        );
                    }
                }
            });
        }

        /**
         * Handles keydown event for keyboard navigation
         * @param {KeyboardEvent} event - The keyboard event object.
         */
        var handleKeyDown = function (event) {
            var currentRadio = document.activeElement;
            if (!_privateState.$inputs.includes(currentRadio)) return;
            /**
             * @type {HTMLElement | null}
             */
            var nextTarget = null;
            switch (event.key) {
                case coned.constants.KEYBOARD_CODE.DOWN:
                case coned.constants.KEYBOARD_CODE.RIGHT:
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    nextTarget = coned.utils.getNextPrevFocus({
                        order: CONSTANTS.ORDER.NEXT,
                        $container: $container,
                        canLoop: true
                    });
                    if (nextTarget) {
                        nextTarget.click();
                        nextTarget.focus();
                    }
                    break;

                case coned.constants.KEYBOARD_CODE.UP:
                case coned.constants.KEYBOARD_CODE.LEFT:
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    nextTarget = coned.utils.getNextPrevFocus({
                        order: CONSTANTS.ORDER.PREV,
                        $container: $container,
                        canLoop: true
                    })
                    if (nextTarget) {
                        nextTarget.click();
                        nextTarget.focus();
                    }
                    break;

                case coned.constants.KEYBOARD_CODE.TAB:
                    if (_privateState.$inputs && _privateState.$inputs.length > 0) {
                        event.stopImmediatePropagation();
                        event.preventDefault();
                        if (event.shiftKey) {
                            nextTarget = coned.utils.getNextPrevFocus({
                                order: CONSTANTS.ORDER.PREV,
                                target: _privateState.$inputs[0]
                            })
                            nextTarget.focus();
                        } else {
                            nextTarget = coned.utils.getNextPrevFocus({
                                order: CONSTANTS.ORDER.NEXT,
                                target: _privateState.$inputs[_privateState.$inputs.length - 1]
                            })
                            nextTarget.focus();
                        }
                    }
                    break;

                default:
                    break;
            }
        };
        /**
         * Handles focus in event to prevent focusing on radios other than the selected one
         * @param {FocusEvent} event - The focus event object.
         */
        var handleFocusIn = function (event) {
            if (_privateState.$selected && event.target !== _privateState.$selected) {
                event.preventDefault();
                _privateState.$selected.focus();
            }
        };
        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            _publicState = {
                name: '',
                value: ''
            }
            _privateState = {
                $inputs: coned.utils.arrayFrom(
                    $container.getElementsByClassName(CONSTANTS.SELECTORS.RADIO)
                ),
                selected: $container.dataset.default ? $container.dataset.default : null
            }
            if (_privateState.$inputs) {
                _privateState.options = [];
                _privateState.$inputs.forEach(function ($el, index) {
                    if ($el instanceof HTMLInputElement) {
                        if (index === 0) {
                            _publicState.name = $el.name;
                        }
                        if (_privateState.selected && $el.value === _privateState.selected) {
                            _privateState.$selected = $el;
                            $el.checked = true;
                            $el.setAttribute(coned.constants.ARIA.CHECKED, coned.constants.TRUE)
                            _publicState.value = $el.value;
                        } else {
                            $el.setAttribute(coned.constants.ARIA.CHECKED, coned.constants.FALSE)
                        }
                        _privateState.options.push($el.value);
                    }
                })
                coned.utils.triggerEvent(
                    $container,
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    _publicState
                );
            }

        }

        /**
         * Initialize the events in the module
         */
        var initializeEvents = function () {
            if (_privateState.$inputs) {
                _privateState.$inputs.forEach(function ($el) {
                    $el.addEventListener('change', handleRadioChange);                    
                    $el.addEventListener('focusin', handleFocusIn);
                });
            }
            $container.addEventListener('keydown', handleKeyDown, { capture: true });
            $container.addEventListener(
                coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                handleSetState
            );
        }
        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    RadioGroup.prototype.isLoaded = function () {
        return isLoaded;
    };
    return RadioGroup;
})();
