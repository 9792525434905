// ==================== CHART/CHARTCOMPONENTS=========================

var query = query || {},
    coned = coned || {};
coned.chartComponents = coned.chartComponents || {};

/**
 * Callback to get the Billing Periods
 */
coned.chartComponents.getBillingPeriods = function (_rateType, _indexBillingPeriod, maid) {
    // Service Data
    var params = {
        AccountMaid: maid,
        Index: _indexBillingPeriod,
        Count: 12,
        Rate: _rateType
    };

    return params;
};

/**
 * Call the service to bring monthly data
 */
coned.chartComponents.callMonthlyService = function (
    maid,
    _rateType,
    _fromMonthlyPeriod,
    _toMonthlyPeriod,
    groupBy,
    isSolar
) {
    // Service Data
    var params = {
        AccountMaid: maid,
        Rate: _rateType,
        StartDate: _fromMonthlyPeriod,
        EndDate: _toMonthlyPeriod,
        Count: 12,
        GroupBy: groupBy,
        IsSolar: isSolar
    };

    return params;
};

/**
 * Create the daily chart date for the billing cycle text
 * @param {String} startDate          Date where the daily period starts
 * @param {String} endDate            Date where the daily period ends
 * @param {Boolean} updateFromDay     Boolean that determines if it need to add a day on the start date
 */
coned.chartComponents.generateDailyDate = function (startDate, endDate, updateFromDay, _allMonths) {
    startDate = startDate.replace(/T[\s\S]*/g, 'T12:00:00');
    endDate = endDate.replace(/T[\s\S]*/g, 'T12:00:00');
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    if (updateFromDay) {
        startDate.setDate(startDate.getDate() + 1);
    }

    var initialDay =
            _allMonths[startDate.getMonth()] +
            ' ' +
            startDate.getDate() +
            ', ' +
            startDate.getFullYear(),
        finalDay =
            _allMonths[endDate.getMonth()] + ' ' + endDate.getDate() + ', ' + endDate.getFullYear();

    return initialDay + ' - ' + finalDay;
};

/**
 * Create the monthly chart date for the billing cycle text
 * @param {String} fromDate     Date where the monthly period starts
 * @param {String} toDate       Date where the monthly period ends
 */
coned.chartComponents.generateMonthlyDate = function (fromDate, toDate, _allMonths) {
    var completeFromDate, completeToDate;

    fromDate = fromDate.replace(/T[\s\S]*/g, 'T12:00:00');
    toDate = toDate.replace(/T[\s\S]*/g, 'T12:00:00');
    fromDate = new Date(fromDate);
    toDate = new Date(toDate);

    completeFromDate = _allMonths[fromDate.getMonth()] + ' ' + fromDate.getFullYear();
    completeToDate = _allMonths[toDate.getMonth()] + ' ' + toDate.getFullYear();

    return completeFromDate + ' - ' + completeToDate;
};

/**
 * Call the service to bring daily and event data
 */
coned.chartComponents.getDailyChart = function (
    maid,
    _rateType,
    _fromDailyPeriod,
    _toDailyPeriod,
    groupBy,
    isSolar
) {
    var params = {
        AccountMaid: maid,
        Rate: _rateType,
        StartDate: _fromDailyPeriod,
        EndDate: _toDailyPeriod,
        GroupBy: groupBy,
        IsSolar: isSolar
    };

    return params;
};
