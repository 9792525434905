// ==================== GALLERY COMPONENT =========================
/* global Swiper */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.GalleryComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        BUTTON_NEXT: 'js-gallery-next',
        BUTTON_PREV: 'js-gallery-prev',
        BUTTON_NEXT_MOBILE: 'js-gallery-mob-next',
        BUTTON_PREV_MOBILE: 'js-gallery-mob-prev',
        GALLERY_TITLE: 'js-gallery-title',
        GALLERY_CAPTION: 'js-gallery-caption',
        GALLERY_LINK: 'js-gallery-link',
        GALLERY_DATE: 'js-gallery-date',
        GALLERY_COUNTER: 'js-gallery-counter',
        GALLERY_CONTROLS: 'js-gallery-controls',
        SLIDE_ITEM: 'swiper-slide',
        SWIPER_CONTAINER: 'swiper-container',
        ACTIVE_SLIDE: 'swiper-slide-active',
        SLIDE_CHANGE_TRANSITION_END_EVENT: 'slideChangeTransitionEnd'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var GalleryComponent = function ($galleryElement) {
        /**
         * PRIVATE METHODS
         */
        var $galleryTitle,
            $galleryCaption,
            $galleryLink,
            $galleryDate,
            $galleryCounter,
            $galleryControls,
            $activeSlide,
            $slides,
            $btnNextMobile,
            $btnPrevMobile,
            $btnNext,
            $btnPrev,
            $gallery,
            _prevBtnName,
            _nextBtnName,
            _totalSlides,
            _currentSlide,
            _dataName;

        var initializeData = function () {
            $galleryTitle = $galleryElement.getElementsByClassName(CONSTANTS.GALLERY_TITLE)[0];
            $galleryCaption = $galleryElement.getElementsByClassName(CONSTANTS.GALLERY_CAPTION)[0];
            $galleryLink = $galleryElement.getElementsByClassName(CONSTANTS.GALLERY_LINK)[0];
            $galleryDate = $galleryElement.getElementsByClassName(CONSTANTS.GALLERY_DATE)[0];
            $galleryCounter = $galleryElement.getElementsByClassName(CONSTANTS.GALLERY_COUNTER)[0];
            $galleryControls = $galleryElement.getElementsByClassName(
                CONSTANTS.GALLERY_CONTROLS
            )[0];
            $btnNextMobile = $galleryElement.getElementsByClassName(
                CONSTANTS.BUTTON_NEXT_MOBILE
            )[0];
            $btnPrevMobile = $galleryElement.getElementsByClassName(
                CONSTANTS.BUTTON_PREV_MOBILE
            )[0];
            $btnNext = $galleryElement.getElementsByClassName(CONSTANTS.BUTTON_NEXT);
            $btnPrev = $galleryElement.getElementsByClassName(CONSTANTS.BUTTON_PREV);
            $slides = $galleryElement.getElementsByClassName(CONSTANTS.SLIDE_ITEM);
            $gallery = $galleryElement.getElementsByClassName(CONSTANTS.SWIPER_CONTAINER)[0];
            _dataName = $galleryElement.dataset.name;
            _nextBtnName = 'next-' + _dataName;
            _prevBtnName = 'prev-' + _dataName;
        };

        var initializeEvents = function () {
            // Set controllers class
            query.addClass($btnNext, _nextBtnName);
            query.addClass($btnPrev, _prevBtnName);

            if ($slides.length > 1) {
                var gallery = new Swiper($gallery, {
                    loop: true,
                    followFinger: false,
                    navigation: {
                        nextEl: '.' + _nextBtnName,
                        prevEl: '.' + _prevBtnName
                    }
                });

                $activeSlide = $galleryElement.getElementsByClassName(CONSTANTS.ACTIVE_SLIDE)[0];
                updatedGalleryInfo($activeSlide);

                // Updated the gallery counter and set the active slide on slide change
                gallery.on(CONSTANTS.SLIDE_CHANGE_TRANSITION_END_EVENT, function () {
                    $activeSlide = $galleryElement.getElementsByClassName(
                        CONSTANTS.ACTIVE_SLIDE
                    )[0];
                    updatedGalleryInfo($activeSlide);
                });

                // Mobile gallery controls
                coned.utils.addGeneralListeners($btnNextMobile, gallery.slideNext);
                coned.utils.addGeneralListeners($btnPrevMobile, gallery.slidePrev);
            } else {
                $galleryCounter.style.display = 'none';
                $galleryControls.style.display = 'none';
                $activeSlide = $galleryElement.getElementsByClassName(CONSTANTS.SLIDE_ITEM)[0];
                changeInfoContent($activeSlide);
            }

            function updatedGalleryInfo($activeSlide) {
                $activeSlide = $galleryElement.getElementsByClassName(CONSTANTS.ACTIVE_SLIDE)[0];
                _currentSlide = parseInt($activeSlide.getAttribute('data-swiper-slide-index')) + 1;
                _totalSlides = gallery.slides.length - 2;
                $galleryCounter.innerHTML = _currentSlide + '/' + _totalSlides;
                changeInfoContent($activeSlide);
            }

            function changeInfoContent($activeSlide) {
                $galleryTitle.innerHTML = $activeSlide.dataset.title;
                $galleryCaption.innerHTML = $activeSlide.dataset.caption;
                $galleryLink.innerHTML = $activeSlide.dataset.source;
                $galleryLink.href = $activeSlide.dataset.link;
                $galleryDate.innerHTML = $activeSlide.dataset.date;

                if ($activeSlide.dataset.link.length < 1) {
                    $galleryLink.parentNode.style.display = 'none';
                } else {
                    $galleryLink.parentNode.style.display = 'block';
                }
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    GalleryComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return GalleryComponent;
})();
