// ==================== REAL TIME PRICES COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.RealTimePricingCalculator = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        START_DATE_INPUT: 'js-start-date',
        END_DATE_INPUT: 'js-end-date',
        MESSAGE_WRAPPER: 'js-form-messages',
        MESSAGE_ERROR: 'js-error-message',
        MESSAGE_SUCCESS: 'js-success-message',
        RTP_CALCULATOR_WRAPPER: 'js-rtp-calculator-wrapper',
        HIDDEN_CLASS: 'hidden',
        NONE_VALUE: 'none',
        BLOCK_VALUE: 'block',
        CHANGE_EVENT: 'change',
        FORM_SUMBIT_SUCCESS_EVENT: 'form-submit-success',
        FORM_SUMBIT_ERROR_EVENT: 'form-submit-error',
        FORM_TRY_AGAIN_EVENT: 'form-try-again'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var RealTimePricingCalculator = function ($realTimePricingCalculatorForm) {
        /**
         * PRIVATE VARIABLES
         */

        var $startDateInput,
            $endDateInput,
            $messageWrapper,
            $errorMessage,
            $succesMessage,
            $rtpCalculatorWrapper;

        /**
         * PRIVATE METHODS
         */

        /**
         * Success submit form handler
         */
        var successSubmitForm = function (data) {
            var dataObj;
            
            if (coned.utils.isPatternLab()) {
                window.location.href = coned.plConstants.REAL_TIME_PRICES_DATA;
            } else {
                dataObj = data && data.details;
                if (dataObj && dataObj.link && dataObj.link !== '') {
                    // Set file location
                    window.location.href = dataObj.link;
                } else {
                    errorDownloadFile();
                }
            }
        };

        /**
         * Error message download file
         */
        var errorDownloadFile = function () {
            // Hide form and success message
            $realTimePricingCalculatorForm.style.display = CONSTANTS.NONE_VALUE;
            $succesMessage.style.display = CONSTANTS.NONE_VALUE;
            // Show error message
            $messageWrapper.style.display = CONSTANTS.BLOCK_VALUE;
            $errorMessage.style.display = CONSTANTS.BLOCK_VALUE;
        };

        var initializeData = function () {
            $startDateInput = $realTimePricingCalculatorForm.getElementsByClassName(CONSTANTS.START_DATE_INPUT)[0];
            $endDateInput = $realTimePricingCalculatorForm.getElementsByClassName(CONSTANTS.END_DATE_INPUT)[0];
            $rtpCalculatorWrapper = document.getElementsByClassName(CONSTANTS.RTP_CALCULATOR_WRAPPER)[0];
            $messageWrapper = $rtpCalculatorWrapper.getElementsByClassName(CONSTANTS.MESSAGE_WRAPPER)[0];
            $errorMessage = $rtpCalculatorWrapper.getElementsByClassName(CONSTANTS.MESSAGE_ERROR)[0];
            $succesMessage = $rtpCalculatorWrapper.getElementsByClassName(CONSTANTS.MESSAGE_SUCCESS)[0];
        };

        var initializeEvents = function () {
            //Add valid state to prefilled inputs
            setTimeout(function () {
                coned.utils.checkInputsFilled($realTimePricingCalculatorForm);
            }, 1000);
            
            // Revalidate the end date field
            $('.' + CONSTANTS.START_DATE_INPUT).on(CONSTANTS.CHANGE_EVENT, function () {
                if ($endDateInput.value) {
                    $('.' + CONSTANTS.END_DATE_INPUT).valid();
                }
            });

            // Revalidate the start date field
            $('.' + CONSTANTS.END_DATE_INPUT).on(CONSTANTS.CHANGE_EVENT, function () {
                if ($startDateInput.value) {
                    $('.' + CONSTANTS.START_DATE_INPUT).valid();
                }
            });

            // Listen to form-validation events for custom functionality
            $realTimePricingCalculatorForm.addEventListener(CONSTANTS.FORM_SUMBIT_SUCCESS_EVENT, successSubmitForm);
            $realTimePricingCalculatorForm.addEventListener(CONSTANTS.FORM_SUMBIT_ERROR_EVENT, successSubmitForm);

            // Set datepickers disabled dates outside of range:
            // 2 years back from actual date, and current date +1 day
            coned.utils.setDatepickerStartEndDateLimit($startDateInput, 0, 0, -2, 0, 1, 0);
            coned.utils.setDatepickerStartEndDateLimit($endDateInput, 0, 0, -2, 0, 1, 0);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    RealTimePricingCalculator.prototype.isLoaded = function () {
        return isLoaded;
    };

    return RealTimePricingCalculator;
})();
