// ==================== ERROR PAGE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ServerErrorPage = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SERVER_ERROR_HEADER_CLASS: 'js-header-wrapper',
        SERVER_ERROR_HEADER_ORU: 'header--oru',
        SERVER_ERROR_PAGE_CLASS: 'js-page-content',
        SERVER_ERROR_LOGO_CLASS: 'js-header-logo',
        SRC_ATTRIBUTE: 'src',
        SRCSET_ATTRIBUTE: 'srcset',
        SERVER_ERROR_PICTURE: 'js-picture',
        SERVER_ERROR_PICTURE_MOBILE: 'js-picture-mobile',
        SERVER_ERROR_PICTURE_DESKTOP: 'js-picture-desktop',
        SERVER_ERROR_PICTURE_IMAGE: 'js-picture-image',
        SERVER_ERROR_FOOTER_CLASS: 'js-footer',
        SERVER_ERROR_FOOTER_ORU: 'footer--oru',
        LOADER_CLASS: 'js-form-loading',
        HIDDEN_CLASS: 'hidden',
        SERVER_ERROR_TEXT: 'js-error-text',
        SERVER_ERROR_TEXT_ORU: 'js-error-text-oru'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ServerErrorPage = function ($module) {
        /**
         * PRIVATE METHODS
         */
        var $logo,
            $loader,
            $footer,
            $picture,
            $pictureMobile,
            $pictureDesktop,
            $pictureImage,
            $pageContent,
            $header,
            $text,
            $textOru;

        var initializeFunctionality = function () {
            var mobileSrc, desktopSrc;

            if (window.location.href.indexOf('oru.') > -1) {
                mobileSrc = $picture.dataset.mobileOru;
                desktopSrc = $picture.dataset.desktopOru;

                $logo.setAttribute(CONSTANTS.SRC_ATTRIBUTE, $logo.dataset.oruLogo);
                $header.classList.add(CONSTANTS.SERVER_ERROR_HEADER_ORU);
                $footer.classList.add(CONSTANTS.SERVER_ERROR_FOOTER_ORU);
                $pictureMobile.setAttribute(CONSTANTS.SRCSET_ATTRIBUTE, mobileSrc);
                $pictureDesktop.setAttribute(CONSTANTS.SRCSET_ATTRIBUTE, desktopSrc);
                $pictureImage.setAttribute(CONSTANTS.SRC_ATTRIBUTE, desktopSrc);
                $text.classList.add(CONSTANTS.HIDDEN_CLASS);
                $textOru.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            $pageContent.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $footer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $header.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $loader.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var initializeData = function () {
            $logo = document.getElementsByClassName(CONSTANTS.SERVER_ERROR_LOGO_CLASS)[0];
            $footer = document.getElementsByClassName(CONSTANTS.SERVER_ERROR_FOOTER_CLASS)[0];
            $loader = document.getElementsByClassName(CONSTANTS.LOADER_CLASS)[0];
            $pageContent = document.getElementsByClassName(CONSTANTS.SERVER_ERROR_PAGE_CLASS)[0];
            $header = document.getElementsByClassName(CONSTANTS.SERVER_ERROR_HEADER_CLASS)[0];

            $picture = $module.getElementsByClassName(CONSTANTS.SERVER_ERROR_PICTURE)[0];
            $pictureMobile = $module.getElementsByClassName(
                CONSTANTS.SERVER_ERROR_PICTURE_MOBILE
            )[0];
            $pictureDesktop = $module.getElementsByClassName(
                CONSTANTS.SERVER_ERROR_PICTURE_DESKTOP
            )[0];
            $pictureImage = $module.getElementsByClassName(CONSTANTS.SERVER_ERROR_PICTURE_IMAGE)[0];

            $text = $module.getElementsByClassName(CONSTANTS.SERVER_ERROR_TEXT)[0];
            $textOru = $module.getElementsByClassName(CONSTANTS.SERVER_ERROR_TEXT_ORU)[0];
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeFunctionality();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ServerErrorPage.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ServerErrorPage;
})();
