// ==================== PAY MY BILL MULTIPLE COMPONENT =========================
/* global $ */
/* global dataLayer */
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.MultiplePayMyBill = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // STEP 1
        MULTIPLE_PAYMENT_FORM_STEP: 'js-step-multiple-payment',
        SELECT_BILLS_BUTTON_CLASS: 'js-select-bills',
        DOWNLOAD_BILLS_BUTTON_CLASS: 'js-download-bills',
        TOTAL_AMOUNT_INPUT: 'js-total-currency',
        MAIN_TOTAL_AMOUNT: 'js-main-total-amount',
        FILLED_INPUT: 'coned-input--filled',
        OTHER_AMOUNT_CONTAINER: 'js-other-amount-container',
        OTHER_AMOUNT_CHECKBOX: 'js-show-other-input',
        UL_CLASS: 'transactional__half',
        LI_CLASS: 'coned-form__field-container transactional__half-block',
        VALIDATION_SELECTOR: 'js-form-validation',
        INPUT_TITLE: 'js-input-title',
        INPUT_CURRENCY_SELECTOR: 'js-currency-input',
        HIDDEN_FORM_SELECTOR: 'js-form-content-selector',
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        SUBMIT_BUTTON_TEXT: 'js-transactional-submit-text',
        SUBMIT_ANIMATION_CONTAINER: 'js-submit-progress-animation',
        FORM_SELECTOR: 'js-multiple-pmb-form',
        FINISH_HIDDEN_ELEMENT: 'js-multiple-pmb',
        PAYMENT_REFERENCE_SELECTOR: 'js-payment-reference',
        ACCOUNT_EMAIL_SELECTOR: 'js-account-email',
        INPUT_DYNAMIC_INPUT: 'js-dynamic-currency',
        AMOUNT_TITLE_SELECTOR: 'js-amount-title',
        OWE_SELECTOR: 'js-owe-selector',
        LABEL_SELECTOR: 'js-coned-label',
        MINIMUM_PAYMENT_ALERT: 'js-minimum-payment-alert',
        ENERGY_SHARE_SELECTOR: 'js-energy-share-selector',
        ENERGY_AMOUNT_SELECTOR: 'js-energy-share',
        ROW_ENERGY_SHARE: 'js-row-energy-share',
        ROW_SERVICE_FEE: 'js-row-service-fee',
        ROW_TEXT_CLASS: 'billing-table__description',
        PAYMENT_METHOD_DROPDOWN: 'js-payment-method',
        PAYMENT_METHOD_TOOLTIP: 'js-payment-method-tooltip',
        TOTAL_AMOUNT_SELECTOR: 'js-total-selector',
        AMOUNT_SELECTOR: 'js-amount-selector',
        CREDIT_CARD_PAYMENT_SELECTOR: 'js-credit-card-payment',
        CREDIT_CARD_MESSAGE_RESIDENTIAL: 'js-credit-card-message-residential',
        CREDIT_CARD_MESSAGE_COMMERCIAL: 'js-credit-card-message-commercial',
        CREDIT_CARD_LINK: 'js-credit-card-link',
        SERVICE_FEE_SELECTOR: 'js-service-fee',
        PAYMENT_AMOUNT_SELECTOR: 'js-payment-amount',
        CHECKED_CLASS: 'coned-checkbox--checked',
        ENERGY_SHARE_MODULE: 'js-energy-share-module',
        ENERGY_SHARE_OTHER_STATE_TEXT: 'js-other-state-text',
        ENERGY_SHARE_NEW_JERSEY_TEXT: 'js-new-jersey-text',
        ENERGY_SHARE_CHECKBOX_TEXT: 'js-checkbox-name-selector',
        DPP_ENROLL_CONTAINER_SELECTOR: 'js-dpp-enroll-container',
        DPP_ENROLL_SELECTOR: 'js-dpp-enroll',
        DPP_ENROLL_HIDE_CLASS: 'transactional--hidden',
        CREDIT_CARD_OPTION_SELECTOR: 'js-credit-card-option-selector',
        LAST_ACCOUNT_OPTION_SELECTOR: 'js-last-account-option-selector',
        THANKS_MESSAGE_SELECTOR: 'js-pay-my-bill-thanks',
        CREDIT_CARD_MESSAGE_SELECTOR: 'js-credit-card-thanks',
        DATE_PICKER_SELECTOR: 'js-date-picker-selector',
        CREDIT_CARD_SELECTOR: 'js-credit-card-selector',
        BILL_PAYMENT_ERROR: 'js-bill-payment-error',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        FORM_SCID_NAME: 'ScId',
        FORM_PAYMENT_METHOD_NAME: 'paymentMethod',
        FORM_ROUTING_ABA_NUMBER_NAME: 'routingAbaNumber',
        FORM_BANK_ACCOUNT_NUMBER_NAME: 'bankAccountNumber',
        FORM_DPP_ENROLL_NAME: 'dppEnroll',
        FORM_PAYMENT_DATE_NAME: 'paymentDate',
        FORM_PAYMENT_TYPE_NAME: 'paymentType',
        FORM_ENERGY_SHARE_NAME: 'energyShare',
        INPUT_DATE_SELECTOR: 'js-current-datepicker-input',
        PAYMENT_AMOUNT_PARAMETER: 'PaymentAmount=',
        ACCOUNT_NUMBER_PARAMETER: 'AccountNumber=',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        HEADER_TOTAL_AMOUNT_CLASS: 'js-total-amount',
        OHTER_AMOUNT_CLASS: 'js-other-amount',
        BILL_HEADER_CLASS: 'js-bill-header',
        CREADIT_CARD_MESSAGE_TITLE_CLASS: 'js-credit-card-message-title',
        CREADIT_CARD_MESSAGE_DESCRIPTION_CLASS: 'js-credit-card-message-description',
        HEADER_MESSAGE_BILL: 'js-header-message',
        HEADER_MESSAGE_BILL_ALL: 'js-header-message-all',
        HEADER_MESSAGE_BILL_SOME: 'js-header-message-some',
        HEADER_MESSAGE_BILL_ONE: 'js-header-message-one',
        CANCEL_POPUP: 'js-cancel-popup',
        CONFIRM_CANCEL_BUTTON: 'js-confirm-cancel-button',
        MY_ACCOUNT_BTN: 'js-batch-my-account-btn',
        STEP_PAYMENT: 'js-step-payment',
        ACCOUNT_TOKEN: '@ACCOUNT',
        PARTIAL_PAYMENT_WARNING_MSG: '.js-partial-payment-warning',
        MASTER_ACCOUNT: 'master',
        MAID_MASK: '{{MAID}}',
        PAYMENT_ERROR: 'js-payment-error',
        RESIDENTIAL: 'R',

        // STEP 2
        MULTIPLE_PAYMENT_BILLS_STEP: 'js-step-bill-list',
        CHECKALL_CHECKBOX: 'js-check-all',
        RECEIPT_LABEL: 'js-check-receipt',
        RECEIPT_LABEL_BOX: 'js-check-receipt-box',
        RECEIPT_CHECKBOX: 'js-checkbox-selector',
        BILL_LINK_CLASS: 'js-bill-link',
        ACCOUNT_ADDRESS: 'js-account-address',
        PROCEED_PAYMENT_BUTTON_CLASS: 'js-unit-list-submit',
        SELECT_ALL_CHECKBOX_CLASS: 'js-select-all-bills',
        CLEAR_ALL_CTA_CLASS: 'js-clear-all-bills',
        BILLS_CONTAINER_CLASS: 'js-bill-list',
        BILLS_NUMBER_CLASS: 'js-bills-selected',

        // STEP 3
        ANIMATION_STEP_CLASS: 'js-step-animation',
        ANIMATION_TOTAL_CLASS: 'js-enroll-animation-total',
        ANIMATION_CURRENT_CLASS: 'js-enroll-animation-current',
        ANIMATION_BILL_CLASS: 'js-enroll-animation-bill',
        LOADING_ANIMATION_PLAY: 'js-animation-play',
        ACTIVE_BATCH: 'js-active-batch',

        BATCH_BANNER_CONTAINER: 'js-batch-banner',
        BATCH_BANNER_TITLE: 'js-batches-process-header',
        BATCH_BANNER_DESCRIPTION: 'js-batch-process',
        NO_BATCH_BANNER_CONTAINER: 'js-no-batch-banner',
        BATCH_PROCESS_CONTAINER: 'js-batch-process-container',
        BATCH_RESULTS_CONTAINER: 'js-batch-results',
        BATCH_ITEM: 'js-batch-item',
        BATCH_ICON: 'js-batch-icon',
        BATCH_LOADING: 'js-loading-animation',
        BATCH_DESCRPTION: 'js-batch-description',
        BATCH_DESCRPTION_TEXT: 'js-batch-description-text',
        BATCH_BUTTON_CONTAINER: 'js-batch-buttons-container',
        BATCH_PAY: 'js-batch-pay',
        BATCH_CANCEL: 'js-batch-cancel',
        BATCH_DESCRIPTION: 'js-batches-process-description',

        BATCH_ITEM_STRUCTURE:
            '<div class="pay-my-bill__batch-container js-batch-item" aria-live="polite">' +
            '<div class="pay-my-bill__batch-description js-batch-description">' +
            '<div class="pay-my-bill__batch-icon js-batch-icon" aria-hidden="true"></div>' +
            '<div class="pay-my-bill__batch-icon loading-animation-component loading-animation-component--bg-container loading-animation-component--small loading-animation-component--blue js-loading-animation js-module hidden" data-module="LoadingAnimation" data-no-page-disabled="true">' +
            '<div class="loading-animation-component__clip-right">' +
            '<div class="loading-animation-component__slice-right js-slice-right-selector loading-animation__slice-right--white"></div>' +
            '</div>' +
            '<div class="loading-animation-component__clip-left">' +
            '<div class="loading-animation-component__slice-left js-slice-left-selector loading-animation__slice-left--white"></div>' +
            '</div>' +
            '<div class="loading-animation-component__top js-top-selector"></div>' +
            '</div>' +
            '<p class="pay-my-bill__batch-description-paragraph js-batch-description-text"></p>' +
            '</div>' +
            '<div class="pay-my-bill__batch-buttons js-batch-buttons-container hidden">' +
            '<button class="transactional__link transactional__link--no-decoration js-batch-cancel"></button>' +
            '<button class="pay-my-bill__batch-buttons--pay-batch js-batch-pay"><span></span></button>' +
            '</div>' +
            '</div>',

        BATCH_TOKEN: '@BATCH',
        AMOUNT1_TOKEN: '@AMOUNT1',
        AMOUNT2_TOKEN: '@AMOUNT2',
        PERCENTAGE_TOKEN: '@PERCENTAGE',

        BATCH_CHECK_CLASS: 'pay-my-bill__batch-icon-check',
        BATCH_WARNING_CLASS: 'pay-my-bill__batch-icon-warning',
        BATCH_ERROR_CLASS: 'pay-my-bill__batch-icon-error',

        BATCH_SUCCESS_TOKEN: 'ready',
        BATCH_LOADING_TOKEN: 'loading',
        BATCH_CANCELLED_TOKEN: 'cancelled',
        BATCH_WARNINGS_TOKEN: 'warning',

        // STEP 4
        MULTIPLE_PAYMENT_STATUS_SUCCESS_BANNER: 'js-pay-multiple-status-success',
        MULTIPLE_PAYMENT_STATUS_SUCCESS_NUMBER: 'js-pay-multiple-status-success-number',
        MULTIPLE_PAYMENT_STATUS_SUCCESS_LIST: 'js-pay-multiple-status-success-list',
        MULTIPLE_PAYMENT_STATUS_SUCCESS_MORE: 'js-status-success-show-more',
        MULTIPLE_PAYMENT_STATUS_SUCCESS_TITLE: 'js-pay-multiple-status-success-title',
        MULTIPLE_PAYMENT_STATUS_SUCCESS_INFO: 'js-pay-multiple-status-success-info',
        MULTIPLE_PAYMENT_STATUS_FAILED_NUMBER: 'js-pay-multiple-status-failed-number',
        MULTIPLE_PAYMENT_STATUS_FAILED_LIST: 'js-pay-multiple-status-failed-list',
        MULTIPLE_PAYMENT_STATUS_FAILED_MORE: 'js-status-failed-show-more',
        MULTIPLE_PAYMENT_STATUS_FAILED_BANNER: 'js-pay-multiple-status-failed',
        MULTIPLE_PAYMENT_STATUS_SUCCESS_BILL: 'js-pay-multiple-status-success-bill',
        MULTIPLE_PAYMENT_STATUS_FAILED_BILL: 'js-pay-multiple-status-failed-bill',
        TAB_BILLS_PROCESSED_CONTAINER: 'js-bills-processed-container',
        TAB_NOT_PAYABLE_CHECK_CONTAINER: 'js-not-payable-check-container',
        TAB_BILLS_NOT_PROCESSED_CONTAINER: 'js-bills-not-processed-container',
        TAB_BILLS_PROCESSED: 'js-bills-processed',
        TAB_NOT_PAYABLE_CHECK: 'js-not-payable-check',
        TAB_BILLS_NOT_PROCESSED: 'js-bills-not-processed',
        BUTTON_BILLS_PROCESSED: 'js-show-more-bills-processed',
        BUTTON_BILLS_NOT_PROCESSED: 'js-show-more-bills-not-processed',
        BUTTON_NOT_PAYABLE: 'js-show-more-not-payable-check',
        SUCCESS_PICTURE_SELECTOR: 'js-success-picture',
        FAIL_PICTURE_SELECTOR: 'js-fail-picture',
        MULTIPLE_PAYMENT_STATUS_NOT_PAYABLE_CHECK_NUMBER: 'js-pay-multiple-status-pay-check-number',
        SHOW_MORE_CLASS: 'js-show-more',
        SUCCESS_VALUE: 'success',
        FAIL_VALUE: 'fail',
        CONFIRMATION_VALUE: 'Confirmation',
        ACCOUNT_NUMBER_VALUE: 'Account Number',
        LIST_ELEMENT_CLASS_SELECTOR: 'js-item',
        LIST_ELEMENT_CLASSESS_HIDDEN: ' hidden js-item',
        CONTAINER_RIGHT_ELEMENT_CLASSES: 'billing-payment-item__container-right',
        LIST_ELEMENT_TITLE_CLASSES: 'billing-payment-item__title',
        MARKUP_LIST_ELEMENT: 'li',
        MARKUP_PARAGRAPH_ELEMENT: 'p',
        MARKUP_SPAN_ELEMENT: 'span',

        STATUS_LIST_COMPLETE: 'success-banner__span--visible',
        NO_ERROR_CODES_PROVIDED_MESSAGE: 'No error codes provided',

        PAYMENT_STATUS_MESSAGE: 'js-payment-status-message',

        // TABLE CLASES
        TABLE_BODY: 'billing-payment-table__body',
        POST_PAYMENT: 'billing-payment-table--post-payment',
        PAYMENT_TABLE: 'billing-payment-table',
        SHOW_DESKTOP: 'billing-payment-table__data--desktop',
        SHOW_MOBILE: 'billing-payment-table__data--mobile',
        TEXT_RIGHT: 'billing-payment-table__data--right',
        TR_ELEMENT: 'tr',
        TD_ELEMENT: 'td',
        TH_ELEMENT: 'th',

        // GENERAL
        CHANGE_EVENT: 'change',
        RESIZE_EVENT: 'resize',
        HIDDEN_CLASS: 'hidden',
        DEFAULT_VALIDATOR_HIDDEN_SELECTOR: ':hidden',
        DISABLED_ATTRIBUTE: 'disabled',
        FONT_SIZE: 'font-size',
        CHILD_LIST_TYPE: 'childList',
        CLASS_ATTRIBUTE: 'class',
        TRUE_VALUE: true,
        NULL_VALUE: null,
        FINISH_STEP: 'js-finish-selector',
        THANKS_BANNER_CONTAIN: 'js-transactional-thanks',
        TRANSACTION_RESULT_IMG_SELECTOR: 'js-transaction-result-img',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        ACTIVE_DROPDOWN: 'coned-select--active',
        SELECT_LABEL_SELECTOR: 'js-coned-select-label',
        NUMBER_INPUT_SELECTOR: 'js-number-input',
        DATA_INPUT_SHOULD_START_VALIDATE: 'data-input-should-start-validate',
        VALIDATE_START_MIN_LENGTH_CLASS: 'js-validate-start-min-length',
        FIELD_ERROR_WRAPPER: 'coned-field-error-wrapper',
        INPUT_ERROR_CLASS: 'coned-input-message--error', 
        CLOSE_POPUP_BUTTON: 'js-popup-close',

        // tagging
        TAGGING_EVENT: 'Pay My Bill',
        LESS_THAN_THREE: 'Less than 3',
        THREE_TO_TEN: 'Between 3-10',
        ELEVEN_TO_TWENTY: 'Between 11-20',
        TWENTYONE_TO_FIFTY: 'Between 21-50',
        FIFTYONE_TO_HUNDRED: 'Between 51-100',
        ONEHUNDRED_TO_FIVEHUNDRED: 'Between 101-500',
        MORE_THAN_FIVEHUNDRED: 'More than 500',
        TAG_PAY_BILL_CREDIT_CARD: 'pay.bill.creditcard'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var MultiplePayMyBill = function ($multipleForm) {
        /**
         * PRIVATE METHODS
         */
        var $formValidationSelector,
            $checkAllCheckbox,
            $receiptLabels,
            $totalCurrency,
            $otherAmountContainer,
            $otherAmountCheckbox,
            $submitAnimationSelector,
            $finishHiddenElements,
            $dynamicInputs,
            $energyShareSelector,
            $energyAmountSelector,
            $rowEnergyShare,
            $rowEnergyShareText,
            $rowServiceFee,
            $paymentMethodSelector,
            $paymentMethodTooltip,
            $totalAmountSelector,
            $amountSelectors,
            $creditCardPaymentSelectors,
            $creditCardMessageResidential,
            $creditCardMessageCommercial,
            $serviceFeeSelector,
            $minimumPaymentAlert,
            $paymentAmountSelector,
            $energyShareModule,
            $energyShareOtherStateText,
            $energyShareNewJerseyText,
            $energyShareCheckboxText,
            $dppEnrollContainer,
            $dppEnrollCheckbox,
            $creditCardOptionSelector,
            $lastAccountOptionSelector,
            $datePickerSelector,
            $creditCardSelector,
            $billPaymentError,
            $inputDateSelector,
            $mainTotalAmount,
            $billServiceError,
            $formLoading,
            $proceedPaymentButtons,
            $clearAllCta,
            $billsContainer,
            $billsTotalNumber,
            $selectBillsButton,
            $downloadBillsButton,
            $currentBillLink,
            $formStep,
            $billsStep,
            $headerTotalAmount,
            $otherAmount,
            $statusStep,
            $statusSuccessNumber,
            $statusSuccessList,
            $statusSuccessMore,
            $statusFailedNumber,
            $statusFailBatchedNumber,
            $statusFailedList,
            $statusFailedMore,
            $statusBanner,
            $statusSuccessTitle,
            $statusBatchSuccessBill,
            $statusBatchFailedBill,
            $statusSuccessBatchedNumber,
            $billHeader,
            $billHeaderServiceErrorMessage,
            $billHeaderServiceError,
            $creditCardMessageTitle,
            $creditCardMessageDescription,
            $headerMessageBill,
            $headerMessageBillAll,
            $headerMessageBillSome,
            $headerMessageBillOne,
            $submitAnimation,
            $animationStep,
            $animationTotalUnits,
            $animationCurrentUnits,
            $animationBill,
            $statusSuccessBill,
            $statusFailedBill,
            $formButton,
            $formButtonText,
            $paymentForm,
            $newTab,
            $successPicture,
            $failPicture,
            $showMore,
            $notPayableByCheckTab,
            $numberInputs,
            $batchBanner,
            $cancelPopup,
            $partialPaymentWarning,
            $paymentStatusMessage,
            $paymentError,
            _creditCardRedirect,
            
            // $noBatchBanner,
            $batchProcessContainer,
            $batchResultsContainer,
            $batchBannerTitle,
            $batchBannerDescription,
            $batchMyAccountBtn,
            $confirmCancelButton,
            $currentBatch,
            index,
            _isBatched,
            _batchState,
            _totalFailAccounts,
            _totalSuccessAccounts,
            _amountFailAccounts,
            _currentBatchIndex,
            _resizeTimeout,
            _inputTemplateHTML,
            _batchSize,
            _data,
            _billsList,
            _first,
            _billsListCounter,
            _billsListPercentage,
            _billsListPercentageCounter,
            _billsListToSend,
            _totalAccount,
            _startAccountNumber,
            _lastUsedBankAccount,
            _billsListPercentageNoFloor,
            _billsListPercentageCounterNoFloor,
            _totalbillsBatchList,
            _isMoreThanHundred,
            _animationUnitsToSend,
            _isSummaryBillingProgram,
            _oneBillToPay,
            totalBillsGlobal,
            totalAmountGlobal,
            totalAmountFontSize,
            isClickLoop;

        // STEP 3: Status step

        var statusStepEvents = function () {
            coned.utils.addGeneralListeners($statusSuccessMore, function (event) {
                onClickShowMore(event, $statusSuccessMore, $statusSuccessList);
            });
            coned.utils.addGeneralListeners($statusFailedMore, function (event) {
                onClickShowMore(event, $statusFailedMore, $statusFailedList);
            });

            window.addEventListener(CONSTANTS.RESIZE_EVENT, resizeThrottler, false);
        };

        var onClickShowMore = function (event, $button, $List) {
            event.preventDefault();

            $List.classList.add(CONSTANTS.STATUS_LIST_COMPLETE);
            $button.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var resizeThrottler = function () {
            // ignore resize events as long as an actualResizeHandler execution is in the queue
            if (!_resizeTimeout) {
                _resizeTimeout = setTimeout(function () {
                    _resizeTimeout = null;

                    // The functions will execute at a rate of 15fps
                }, 66);
            }
        };

        // STEP 2: Select bills step
        var enableSubmitButtons = function () {
            Array.prototype.forEach.call($proceedPaymentButtons, function ($button) {
                $button.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
            });
        };

        var disableSubmitButtons = function () {
            Array.prototype.forEach.call($proceedPaymentButtons, function ($button) {
                $button.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, true);
            });
        };

        var clickOnProceedPaymentStep = function (target, event) {
            event.preventDefault();

            var $receiptCheckboxes = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)');
            _isSummaryBillingProgram && $minimumPaymentAlert.classList.remove(CONSTANTS.HIDDEN_CLASS);
            _isSummaryBillingProgram = false;

            // Set last four digits of last account option
            // async call to get last digits from last used bill
            if ($receiptCheckboxes.length === 1) {
                // Service Data
                var params = {
                    Maid: $receiptCheckboxes[0].dataset.maid,
                    ScId: $paymentForm.dataset.scid
                };

                // Service Call
                query.getData(_lastUsedBankAccount, successLastBankAccountService, errorLastBankAccountService, params, $formLoading);
                
                if ($receiptCheckboxes[0].dataset.accountType === CONSTANTS.MASTER_ACCOUNT) {
                    !_isSummaryBillingProgram && $minimumPaymentAlert.classList.add(CONSTANTS.HIDDEN_CLASS);
                    _isSummaryBillingProgram = true;
                }
            }

            resetDropdown();
            $formStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $billsStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            $selectBillsButton && $selectBillsButton.focus();
            window.scrollTo(0, 0);
            resetInputs();
            $formButton.disabled = true;
        };

        var amountAccountsTagging = function (amountAccounts) {
            var totalAccounts;

            switch (true) {
                case (amountAccounts < 3):
                    totalAccounts = CONSTANTS.LESS_THAN_THREE;
                    break;
                case (amountAccounts > 2 && amountAccounts < 11):
                    totalAccounts = CONSTANTS.THREE_TO_TEN;
                    break;
                case (amountAccounts > 10 && amountAccounts < 21):
                    totalAccounts = CONSTANTS.ELEVEN_TO_TWENTY;
                    break;
                case (amountAccounts > 20 && amountAccounts < 51):
                    totalAccounts = CONSTANTS.TWENTYONE_TO_FIFTY;
                    break;
                case (amountAccounts > 50 && amountAccounts < 101):
                    totalAccounts = CONSTANTS.FIFTYONE_TO_HUNDRED;
                    break;
                case (amountAccounts > 100 && amountAccounts < 501):
                    totalAccounts = CONSTANTS.ONEHUNDRED_TO_FIVEHUNDRED;
                    break;
                default:
                    totalAccounts = CONSTANTS.MORE_THAN_FIVEHUNDRED;
                    break;
            }

            dataLayer.push({
                event: CONSTANTS.TAGGING_EVENT,
                accountsCount: totalAccounts
            });
        };

        var resetInputs = function () {
            // reset payment method dropdown
            if ($paymentMethodSelector) {
                var $stateLabel = $paymentMethodSelector.parentElement.getElementsByClassName(CONSTANTS.SELECT_LABEL_SELECTOR)[0];
                $stateLabel.removeAttribute('style');
                $paymentMethodSelector.removeAttribute('style');
                $paymentMethodSelector.selectedIndex = 0;
                $paymentMethodSelector.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);
                $paymentMethodSelector.classList.remove(CONSTANTS.INPUT_ERROR_CLASS);
                hideErrorMessage($paymentMethodSelector);
            }

            // Deselect other amount
            if ($otherAmountCheckbox) {
                if ($otherAmountCheckbox.checked) {
                    $otherAmountCheckbox.click();
                }
            }

            // Deselect DPP enroll
            if ($dppEnrollCheckbox) {
                if ($dppEnrollCheckbox.checked) {
                    $dppEnrollCheckbox.click();
                }
            }

            // reset input for date
            if ($inputDateSelector) {
                $inputDateSelector.classList.remove(CONSTANTS.FILLED_INPUT);
                $inputDateSelector.classList.remove(CONSTANTS.INPUT_ERROR_CLASS);
                $inputDateSelector.value = '';
                hideErrorMessage($inputDateSelector);
            }

            if ($creditCardSelector) {
                $creditCardSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            if ($numberInputs) {
                Array.prototype.forEach.call($numberInputs, function ($input) {
                    $input.classList.remove(CONSTANTS.FILLED_INPUT);
                    $input.classList.remove(CONSTANTS.INPUT_ERROR_CLASS);
                    $input.value = '';
                    hideErrorMessage($input);

                    if ($input.hasAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE)){
                        $input.removeAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE)
                    }
                });
            }
        };

        var hideErrorMessage = function ($input) {
            var $errorMessage = $input.parentElement.parentElement.getElementsByClassName(CONSTANTS.FIELD_ERROR_WRAPPER)[0];
            if ($errorMessage) {
                $errorMessage.style.display = 'none';
            }
        };

        var onClickClearList = function (event) {
            event.preventDefault();

            var $checkedLabels = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)');

            isClickLoop = true;
            $selectBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            $downloadBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            $formButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            disableSubmitButtons();

            if ($checkedLabels.length >= 100) {
                $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
            }

            coned.utils.processLargeData(
                $checkedLabels,
                // Handler
                function ($element) {
                    $element.click();
                },
                // On complete enable submit buttons
                function () {
                    isClickLoop = false;

                    updateTotalAmount();
                    $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
                }
            );

            if ($checkAllCheckbox.checked) {
                $checkAllCheckbox.checked = false;
                $checkAllCheckbox.parentElement.classList.remove(CONSTANTS.CHECKED_CLASS);
            }
        };

        var onCheckReceipt = function (event, $target) {
            var $actualCheckbox = $target,
                checkboxId = $actualCheckbox.target.id,
                paymentValue = $paymentMethodSelector.value;

            $serviceFeeSelector.dataset.type = $actualCheckbox.target.dataset.accountType;

            // checks if the actual checkbox is checked
            if ($actualCheckbox.target.checked) {
                var data = {
                    id: checkboxId,
                    maid: $actualCheckbox.target.dataset.maid
                };

                // if the checkbox is checked, adds 1 to totalBillsGlobal and adds
                // the actual amount to totalAmountGlobal
                _billsList.push(data);
                _totalbillsBatchList.push(data);
                totalBillsGlobal += 1;
                totalAmountGlobal = parseFloat(totalAmountGlobal) + parseFloat($actualCheckbox.target.dataset.amount.replace(/,/g, ''));
            } else {
                // if is not checked, removes element, substracts from 1 totalBillsGlobal
                // and substracts actual amount from totalAmountGlobal.
                // also, unchecked the checkAllCheckbox and removes checked class.
                var position = _billsList.findIndex(function (element) {
                    return element.id == checkboxId;
                });

                if (position > -1) {
                    _billsList.splice(position, 1);
                }

                totalBillsGlobal -= 1;
                totalAmountGlobal = parseFloat(totalAmountGlobal) - parseFloat($actualCheckbox.target.dataset.amount.replace(/,/g, ''));
                $checkAllCheckbox.checked = false;
                $checkAllCheckbox.parentElement.classList.remove(CONSTANTS.CHECKED_CLASS);
            }

            // if the totalAmountGlobal is negative, the minimum amount should be 0.
            if (totalAmountGlobal < 0) {
                totalAmountGlobal = 0;
            }

            // Starts html update
            if (!isClickLoop) {
                // if 1 bill or more are selected, displays the downloadBillsButton, otherwise hides it.
                if (totalBillsGlobal) {
                    enableSubmitButtons();
                    $downloadBillsButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $selectBillsButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                    $downloadBillsButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                    $formButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                } else {
                    disableSubmitButtons();
                    $selectBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
                    $formButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
                    $selectBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
                    $downloadBillsButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $downloadBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
                    $formButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
                }

                updateCheckAll();
                updateTotalAmount();

                updateCreditUrl($actualCheckbox.target.checked, $actualCheckbox.target);

                // Adds donation element in case is 1 bill
                if (paymentValue != $multipleForm.dataset.creditOption && totalBillsGlobal === 1) {
                    $energyShareModule.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    unCheckEnergyDonation();
                }
                checkCreditCardOption(true);
                isClickLoop = false;
            }
        };

        var checkAllEvent = function () {
            var $allLabels = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':not(:disabled)'),
                $checkedLabels;

            if ($allLabels.length === 0) {
                return;
            }

            $selectBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            $downloadBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            $formButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            disableSubmitButtons();
            isClickLoop = true;

            if ($checkAllCheckbox.checked) {
                // Select only checkboxes that are not checked and not disabled
                $checkedLabels = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':not(:checked):not(:disabled)');

                if ($checkedLabels.length >= 100) {
                    $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
                }

                coned.utils.processLargeData(
                    $checkedLabels,
                    // Handler
                    function ($element) {
                        $element.click();
                        // updateTotalAmount();
                    },
                    // On complete enable submit buttons
                    function () {
                        $selectBillsButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                        $downloadBillsButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                        $formButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                        enableSubmitButtons();
                        isClickLoop = false;

                        updateTotalAmount();
                        checkCreditCardOption(true);
                        checkEnergyDonation(updateBillingTable);
                        $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
                    }
                );

                $checkAllCheckbox.parentElement.classList.add(CONSTANTS.CHECKED_CLASS);
            } else {
                // Select only checkboxes that are not checked and not disabled
                $checkedLabels = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)');

                if ($checkedLabels.length >= 100) {
                    $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
                }

                coned.utils.processLargeData(
                    $checkedLabels,
                    // Handler
                    function ($element) {
                        $element.click();
                    },
                    // On complete disable submit buttons
                    function () {
                        isClickLoop = false;

                        updateTotalAmount();
                        checkCreditCardOption(true);
                        $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
                    }
                );

                $checkAllCheckbox.parentElement.classList.remove(CONSTANTS.CHECKED_CLASS);
            }
            // updateTotalAmount(true);
        };

        var updateCheckAll = function () {
            var checkboxChecked = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)').length,
                totalCheckbox = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':not(:disabled)').length;

            if (totalCheckbox == checkboxChecked) {
                $checkAllCheckbox.checked = true;
                $checkAllCheckbox.parentElement.classList.add(CONSTANTS.CHECKED_CLASS);
            } else {
                $checkAllCheckbox.checked = false;
                $checkAllCheckbox.parentElement.classList.remove(CONSTANTS.CHECKED_CLASS);
            }
        };

        var addOtherAmount = function () {
            var $ulElement = document.createElement('ul'),
                $liElement = document.createElement('li'),
                $actualCheckbox = $billsStep.querySelector('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)'),
                $inputTitle,
                $currencyInput,
                $currencyLabel,
                actualAmount = parseFloat($actualCheckbox.dataset.amount.replace(/,/g, '')),
                paymentLimitsMsg;

            // Update container
            $otherAmountContainer.innerHTML = '';
            $ulElement.className = CONSTANTS.UL_CLASS;
            $otherAmountContainer.appendChild($ulElement);

            // Set account type in service fee
            $serviceFeeSelector.dataset.type = $actualCheckbox.dataset.accountType;

            // Setting elements data
            $liElement.className = CONSTANTS.LI_CLASS;
            $liElement.innerHTML = _inputTemplateHTML;

            // Updating Data
            $inputTitle = $liElement.getElementsByClassName(CONSTANTS.INPUT_TITLE)[0];
            $inputTitle.innerHTML = $actualCheckbox.dataset.address;

            // Set input listener and data
            $currencyLabel = $liElement.getElementsByClassName(CONSTANTS.LABEL_SELECTOR)[0];
            $currencyLabel.setAttribute('for', 'payment-amount' + (index ? '-' + index : ''));

            $currencyInput = $liElement.getElementsByClassName(CONSTANTS.INPUT_DYNAMIC_INPUT)[0];
            $currencyInput.setAttribute('name', 'paymentAmount' + (index ? index : ''));
            $currencyInput.setAttribute('id', 'payment-amount' + (index ? '-' + index : ''));
            $currencyInput.setAttribute('data-value', actualAmount);
            $currencyInput.setAttribute('data-amount', actualAmount);
            $currencyInput.setAttribute('data-maid', $actualCheckbox.dataset.maid);
            $currencyInput.addEventListener('keyup', updateAmount);

            // Set validation data
            paymentLimitsMsg = $actualCheckbox.dataset.msgPaymentlimits;
            paymentLimitsMsg = paymentLimitsMsg.replace('@MIN', $actualCheckbox.dataset.paymentMin);
            paymentLimitsMsg = paymentLimitsMsg.replace('@MAX', $actualCheckbox.dataset.paymentMax);
            $currencyInput.setAttribute('data-msg-paymentlimits', paymentLimitsMsg);
            $currencyInput.setAttribute('data-rule-paymentlimits', '#payment-amount' + (index ? '-' + index : ''));
            $currencyInput.setAttribute('data-payment-min', $actualCheckbox.dataset.paymentMin);
            $currencyInput.setAttribute('data-payment-max', $actualCheckbox.dataset.paymentMax);
            $currencyInput.setAttribute('data-address', $actualCheckbox.dataset.address);
            $currencyInput.setAttribute('data-account-number', $actualCheckbox.dataset.accountNumber);
            $currencyInput.setAttribute('data-maid', $actualCheckbox.dataset.maid);
            $currencyInput.setAttribute('data-bill-date', $actualCheckbox.dataset.billDate);
            
            // Allow display of dpp enroll
            if ($actualCheckbox.dataset.dppEnrolled == 'false') {
                $dppEnrollContainer.classList.remove(CONSTANTS.DPP_ENROLL_HIDE_CLASS);
            } else {
                $dppEnrollContainer.classList.add(CONSTANTS.DPP_ENROLL_HIDE_CLASS);

                // Deselect DPP enroll
                if ($dppEnrollCheckbox.checked) {
                    $dppEnrollCheckbox.click();
                }
            }

            // Change Donation text correspondingly for ORU
            if (coned.utils.isOru()) {
                if ($actualCheckbox.dataset.state === 'NJ') {
                    $energyShareOtherStateText.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $energyShareNewJerseyText.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $energyShareCheckboxText.innerHTML = $energyShareModule.dataset.newJerseyCheckbox;
                    $rowEnergyShareText.innerHTML = $energyShareModule.dataset.newJerseyRow;
                } else {
                    $energyShareOtherStateText.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $energyShareNewJerseyText.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $energyShareCheckboxText.innerHTML = $energyShareModule.dataset.otherStateCheckbox;
                    $rowEnergyShareText.innerHTML = $energyShareModule.dataset.otherStateRow;
                }
            }

            // Append Elements
            $ulElement.appendChild($liElement);

            // Show option to download Bills
            $downloadBillsButton.classList.remove(CONSTANTS.HIDDEN_CLASS);

            _.each($billsTotalNumber, function ($number) {
                $number.innerHTML = 1;
            });

            new coned.components.FormValidationModule($formValidationSelector);
        };

        var updateTotalAmount = function () {
            var totalAmount = totalAmountGlobal;

            // updates totalAmount data to update all html elements.
            $totalCurrency.value = totalAmount;

            // Updates amount for payment
            $paymentAmountSelector.dataset.amount = totalAmount;
            $paymentAmountSelector.innerHTML = currencyFormat(totalAmount.toFixed(2) + '');

            // Updates banner
            updateBannerTotalAmount(totalAmount);

            // Updates total of bills
            Array.prototype.forEach.call($billsTotalNumber, function ($number) {
                $number.innerHTML = totalBillsGlobal;
            });

            // Updates total amount
            Array.prototype.forEach.call($totalAmountSelector, function ($totalAmount) {
                $totalAmount.innerHTML = currencyFormat(totalAmount.toFixed(2) + '');
                $totalAmount.dataset.amount = totalAmount;
            });

            // Updates label from banner according the amount of bills
            if ($receiptLabels.length === totalBillsGlobal) {
                $headerMessageBill.innerHTML = $headerMessageBillAll.innerHTML;
                $formButtonText.innerHTML = $formButton.dataset.multipleBill;
            } else if (totalBillsGlobal === 1) {
                $headerMessageBill.innerHTML = $headerMessageBillOne.innerHTML;
                $formButtonText.innerHTML = $formButton.dataset.oneBill;
            } else {
                $headerMessageBill.innerHTML = $headerMessageBillSome.innerHTML;
                $formButtonText.innerHTML = $formButton.dataset.multipleBill;
            }

            if ($minimumPaymentAlert && !coned.utils.isOru()) {
                checkMinimumAmount();
            } else if (coned.utils.isOru()) {
                setSubmitButtonState();
            }   
        };

        var setSubmitButtonState = function () {
            $formButton.disabled = !$($paymentForm).validate().checkForm();
        };

        var checkMinimumAmount = function () {
            if (
                !_isSummaryBillingProgram &&
                $mainTotalAmount &&
                $mainTotalAmount.dataset.amount &&
                $mainTotalAmount.dataset.minAmount &&
                parseFloat($mainTotalAmount.dataset.amount) < parseFloat($mainTotalAmount.dataset.minAmount)
            ) {
                $minimumPaymentAlert.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $formButton.disabled = true;
            } else {
                $minimumPaymentAlert.classList.add(CONSTANTS.HIDDEN_CLASS);
                setSubmitButtonState();
            }
        };

        var successLastBankAccountService = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.GET_LAST_USED_BANK_ACCOUNT_NUMBER, processLastBankAccountServiceResponse, errorLastBankAccountService);
            } else {
                processLastBankAccountServiceResponse(data);
            }
        };

        var processLastBankAccountServiceResponse = function (data) {
            var lastAccountOptionText;

            if (data.LastBankAccountNumber && data.LastBankAccountNumber !== '') {
                lastAccountOptionText = $paymentMethodSelector.dataset.message;
                $lastAccountOptionSelector.text = lastAccountOptionText.replace(CONSTANTS.ACCOUNT_TOKEN, data.LastBankAccountNumber);
                $lastAccountOptionSelector.dataset.valid = true;
            } else {
                $lastAccountOptionSelector.dataset.valid = false;
            }

            if (data.CreditCardRedirectUrl && data.CreditCardRedirectUrl !== '') {
                $multipleForm.dataset.creditPayment = data.CreditCardRedirectUrl;
            }

            _creditCardRedirect = data.CreditCardRedirect;
            checkCreditCardOption(true);
        };

        var errorLastBankAccountService = function () {
            $lastAccountOptionSelector.dataset.valid = false;
            checkCreditCardOption();
        };

        var updateBannerTotalAmount = function (totalAmount) {
            var newTotalAmountArray = [],
                tempArray = [],
                splicedArray,
                newTotalAmount = '',
                newDigitAmount = '',
                newPreAmount = '',
                decimal = Math.round((totalAmount % 1) * 100).toString(),
                integer = Math.floor(totalAmount);

            newTotalAmountArray.push('$');

            // the number is converted to array to handle it easier
            newDigitAmount = integer.toString().split('');

            // for each three numbers, reverse them and then add a comma
            while (newDigitAmount.length > 3) {
                splicedArray = newDigitAmount.splice(-3, 3).reverse();
                tempArray = tempArray.concat(splicedArray);
                tempArray.push(',');
            }

            // if there's remainder, reverse it and add it to the partial result
            if (newDigitAmount.length) {
                tempArray = tempArray.concat(newDigitAmount.reverse());
            }

            // reverse partial result and add it to the new amount
            newTotalAmountArray = newTotalAmountArray.concat(tempArray.reverse());

            // if there's a decimal part, add it as a sup. If there isn't then add a double zero
            if (decimal.length === 1) {
                if (decimal[0] != '0') {
                    decimal = '0' + decimal;
                } else {
                    decimal = decimal.concat('0');
                }
            }
            newPreAmount = '<sup>.' + decimal + '</sup>';
            newTotalAmountArray.push(newPreAmount);

            newTotalAmount = newTotalAmountArray.join('');
            $headerTotalAmount.innerHTML = newTotalAmount;
        };

        var updateAmount = function (event) {
            var $inputTarget = event.target,
                inputValue = $inputTarget.value,
                inputMatch;

            // Parsing input value
            inputValue = inputValue.replace(/[^\d.]/g, '');
            // Trim to 2 decimals for special case when user types a 3rd decimal, 
            // it is passed here before input validation deletes it from the UI,
            inputMatch = inputValue.match(/^-?\d+(?:\.\d{0,2})?/);
            inputValue = parseFloat(inputMatch ? inputMatch[0] : 0);

            // Updating input amount
            if (isNaN(inputValue)) {
                $inputTarget.dataset.amount = $inputTarget.dataset.value;
            } else {
                $inputTarget.dataset.amount = inputValue;
            }

            calculateNewAmount();
        };

        // STEP 1: Pay my bill step
        var onSelectBillsButtonClick = function () {
            var $allLabels = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':not(:disabled)'),
                $checkedLabels = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)');

            if ($allLabels.length === 0) {
                return;
            }

            disableSubmitButtons();
            $selectBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            $downloadBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            $formButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            isClickLoop = true;

            if ($checkedLabels.length >= 100) {
                $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
            }

            coned.utils.processLargeData(
                $checkedLabels,
                // Handler
                function ($element) {
                    $element.click();
                },
                // On complete, set flag on false
                function () {
                    isClickLoop = false;

                    updateTotalAmount();
                    $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
                }
            );

            _billsList = [];
            _totalbillsBatchList = [];
            window.scrollTo(0, 0);
            if ($checkAllCheckbox.checked) {
                $checkAllCheckbox.parentElement.classList.remove(CONSTANTS.CHECKED_CLASS);
            }

            $formStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            $billsStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var onDownloadBillsButtonClick = function () {
            // If file source is available, trigger the file download
            if ($downloadBillsButton.dataset.fileLink) {
                window.location = $downloadBillsButton.dataset.fileLink;

                return;
            }

            var accountsData = [],
                $checkedLabels = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)');

            // Hide error message
            $billHeaderServiceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Setting accounts data array
            coned.utils.processLargeData(
                $checkedLabels,
                // Handler
                function ($element) {
                    var accountInfo = {
                        accountNumber: $element.dataset.accountNumber,
                        amount: $element.dataset.amount,
                        billDate: $element.dataset.billDate
                    };

                    accountsData.push(accountInfo);
                },
                // On complete, call service
                function () {
                    downloadBillServiceCall(accountsData);
                }
            );
        };

        var downloadBillServiceCall = function (accountsData) {
            // Service data
            var downloadBillUrl = $downloadBillsButton.dataset.url,
                params = {
                    AccountsData: accountsData
                };

            params = JSON.stringify(params);

            // Show loading animation
            $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);

            // Service call
            query.postData(downloadBillUrl, downloadBillSuccessCallback, downloadBillErrorCallback, params, true);
        };

        var downloadBillSuccessCallback = function (data) {
            // Add file's url to the download button
            if (coned.utils.isPatternLab()) {
                $downloadBillsButton.dataset.fileLink = coned.plConstants.PAYMENT_VIEW_DATA;
            } else {
                if (data.url && data.url != '') {
                    $downloadBillsButton.dataset.fileLink = data.url;
                } else {
                    downloadBillErrorCallback(data);

                    return;
                }
            }

            // Hide loading animation
            $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);

            // Trigger the file download
            window.location = $downloadBillsButton.dataset.fileLink;
        };

        var downloadBillErrorCallback = function (data) {
            // Set error message
            $billHeaderServiceErrorMessage.innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;
            $billHeaderServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Hide loading animation
            $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
        };

        var calculateNewAmount = function () {
            var totalAmount = 0,
                currentAmount = 0,
                dataAmount;

            for (var index = 0; index < $dynamicInputs.length; index++) {
                var inputAmount = $dynamicInputs[index].dataset.amount.replace(/,/g, ''),
                    inputValue = $dynamicInputs[index].dataset.value.replace(/,/g, '');

                if (inputAmount != inputValue) {
                    currentAmount = parseFloat(inputAmount);
                } else {
                    currentAmount = parseFloat(inputValue);
                }

                totalAmount += currentAmount;
            }

            dataAmount = totalAmount;
            totalAmount = currencyFormat(totalAmount.toFixed(2) + '');

            $paymentAmountSelector.dataset.amount = dataAmount;
            $paymentAmountSelector.innerHTML = totalAmount;

            updateBillingTable();
        };

        var paymentMethodChange = function () {
            var paymentValue = $paymentMethodSelector.value,
                messageIndex;

            if (paymentValue == $multipleForm.dataset.creditOption) {
                query.addClass($creditCardPaymentSelectors, CONSTANTS.HIDDEN_CLASS);
                $rowServiceFee.classList.remove(CONSTANTS.HIDDEN_CLASS);
                
                if (_creditCardRedirect === CONSTANTS.RESIDENTIAL) {
					for (messageIndex = 0; messageIndex < $creditCardMessageCommercial.length; messageIndex++) {
						$creditCardMessageCommercial[messageIndex].classList.add(CONSTANTS.HIDDEN_CLASS);
					}
					for (messageIndex = 0; messageIndex < $creditCardMessageResidential.length; messageIndex++) {
						$creditCardMessageResidential[messageIndex].classList.remove(CONSTANTS.HIDDEN_CLASS);
					}
				} else {
					for (messageIndex = 0; messageIndex < $creditCardMessageResidential.length; messageIndex++) {
						$creditCardMessageResidential[messageIndex].classList.add(CONSTANTS.HIDDEN_CLASS);
					}
					for (messageIndex = 0; messageIndex < $creditCardMessageCommercial.length; messageIndex++) {
						$creditCardMessageCommercial[messageIndex].classList.remove(CONSTANTS.HIDDEN_CLASS);
					}
				}

                $dppEnrollContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $energyShareModule.classList.add(CONSTANTS.HIDDEN_CLASS);
                $datePickerSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $creditCardSelector.classList.add(CONSTANTS.HIDDEN_CLASS);

                // Deselect DPP enroll
                if ($dppEnrollCheckbox.checked) {
                    $dppEnrollCheckbox.click();
                }

                // Deselect the other amount checkbox
                if ($otherAmountCheckbox.checked) {
                    $otherAmountCheckbox.click();
                }

                unCheckEnergyDonation();
                updateBillingTable();
            } else {
                query.removeClass($creditCardPaymentSelectors, CONSTANTS.HIDDEN_CLASS);
                $rowServiceFee.classList.add(CONSTANTS.HIDDEN_CLASS);

                for (messageIndex = 0; messageIndex < $creditCardMessageResidential.length; messageIndex++) {
                    $creditCardMessageResidential[messageIndex].classList.add(CONSTANTS.HIDDEN_CLASS);
                }
				for (messageIndex = 0; messageIndex < $creditCardMessageCommercial.length; messageIndex++) {
                    $creditCardMessageCommercial[messageIndex].classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                
                !_isSummaryBillingProgram && $dppEnrollContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $datePickerSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $creditCardSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $serviceFeeSelector.dataset.amount = 0;

                // If last account was selected, only routing and account number should be hidden
                if (paymentValue == $multipleForm.dataset.lastAccountOption) {
                    $creditCardSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                checkCreditCardOption();
                checkEnergyDonation(updateBillingTable);
            }
        };

        var showOtherInputs = function () {
            if ($otherAmountCheckbox.checked) {
                $partialPaymentWarning && $partialPaymentWarning.classList.remove(CONSTANTS.HIDDEN_CLASS);
                query.removeClass($otherAmountContainer, CONSTANTS.HIDDEN_CLASS);
                $formButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                calculateNewAmount();
                unCheckEnergyDonation();
                donateChange();
                $energyShareModule.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                $partialPaymentWarning && $partialPaymentWarning.classList.add(CONSTANTS.HIDDEN_CLASS);
                query.addClass($otherAmountContainer, CONSTANTS.HIDDEN_CLASS);
                $energyShareModule.classList.remove(CONSTANTS.HIDDEN_CLASS);
                updateTotalAmount();
            }
        };

        var checkCreditCardOption = function (isFromMultiplePayPage) {
            var counter = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)').length,
                paymentValue = $paymentMethodSelector.value;

            // If more than one bill is going to be paid, hide the credit card option from the dropdown
            // Also, the last account option should be hidden
            // And DPP can't be done

            if (counter === 1) {
                query.insertChildAtIndex($paymentMethodSelector, $creditCardOptionSelector, 5);
                !_isSummaryBillingProgram && $dppEnrollContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $otherAmount.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $paymentMethodTooltip.classList.add(CONSTANTS.HIDDEN_CLASS);

                if (isFromMultiplePayPage) {
                    addOtherAmount();
                }

                $headerMessageBill.innerHTML = $headerMessageBillOne.innerHTML;
                $formButtonText.innerHTML = $formButton.dataset.oneBill;

                if ($lastAccountOptionSelector.dataset.valid == 'true' && !$paymentMethodSelector.contains($lastAccountOptionSelector)) {
                    query.insertChildAtIndex($paymentMethodSelector, $lastAccountOptionSelector, 1);
                } else if ($lastAccountOptionSelector.dataset.valid != 'true' && $paymentMethodSelector.contains($lastAccountOptionSelector)) {
                    $lastAccountOptionSelector.remove();
                }

                if (
                    !_isSummaryBillingProgram && 
                    paymentValue !== $multipleForm.dataset.lastAccountOption &&
                    paymentValue !== $multipleForm.dataset.creditOption &&
                    paymentValue !== 'default' 
                ) {
                    $dppEnrollContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $dppEnrollContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                $creditCardOptionSelector.remove();
                $lastAccountOptionSelector.remove();
                $dppEnrollContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $otherAmount.classList.add(CONSTANTS.HIDDEN_CLASS);
                $paymentMethodTooltip.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $formButtonText.innerHTML = $formButton.dataset.multipleBill;

                if ($receiptLabels.length === counter) {
                    $headerMessageBill.innerHTML = $headerMessageBillAll.innerHTML;
                } else {
                    $headerMessageBill.innerHTML = $headerMessageBillSome.innerHTML;
                }

                // Deselect other amount
                if ($otherAmountCheckbox.checked) {
                    $otherAmountCheckbox.click();
                }

                // Deselect DPP enroll
                if ($dppEnrollCheckbox.checked) {
                    $dppEnrollCheckbox.click();
                }
            }
        };

        var checkEnergyDonation = function (callback) {
            var paymentAmount = parseFloat($paymentAmountSelector.dataset.amount.replace(/,/g, '')),
                amountValue = parseFloat($totalCurrency.value.replace(/,/g, '')),
                paymentValue = $paymentMethodSelector.value,
                counter = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)').length;

            if (paymentAmount == amountValue && paymentValue != $multipleForm.dataset.creditOption && counter === 1) {
                $energyShareModule.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                unCheckEnergyDonation();
            }

            callback();
        };

        var unCheckEnergyDonation = function () {
            $energyShareSelector.checked = false;
            $energyShareSelector.parentElement.classList.remove(CONSTANTS.CHECKED_CLASS);
            $energyShareModule.classList.add(CONSTANTS.HIDDEN_CLASS);
            $energyAmountSelector.dataset.amount = 0;
            $rowEnergyShare.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var donateChange = function () {
            if ($energyShareSelector.checked) {
                $rowEnergyShare.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $energyAmountSelector.dataset.amount = 1;
            } else {
                $rowEnergyShare.classList.add(CONSTANTS.HIDDEN_CLASS);
                $energyAmountSelector.dataset.amount = 0;
            }

            checkEnergyDonation(updateBillingTable);
        };

        var updateBillingTable = function () {
            var $amountRow,
                totalAmount = 0,
                actualAmount,
                dataAmount;

            for (index = 0; index < $amountSelectors.length; index++) {
                $amountRow = $amountSelectors[index];

                if ($paymentMethodSelector.value == $multipleForm.dataset.creditOption && $amountRow.dataset.type !== undefined) {
                    if ($amountRow.dataset.type == 'commercial') {
                        actualAmount =
                            (parseFloat($amountRow.dataset.commercialValue.replace(/,/g, '')) / 100) *
                            parseFloat($paymentAmountSelector.dataset.amount.replace(/,/g, ''));
                    } else {
                        actualAmount = parseFloat($amountRow.dataset.residentialValue.replace(/,/g, ''));
                    }

                    $amountRow.innerHTML = currencyFormat(actualAmount.toFixed(2) + '');
                } else {
                    actualAmount = parseFloat($amountRow.dataset.amount.replace(/,/g, ''));
                }

                totalAmount += actualAmount;
            }

            dataAmount = totalAmount;
            totalAmount = currencyFormat(totalAmount.toFixed(2) + '');

            Array.prototype.forEach.call($totalAmountSelector, function ($totalAmount) {
                $totalAmount.innerHTML = totalAmount;
                $totalAmount.dataset.amount = dataAmount;
            });

            if ($minimumPaymentAlert && !coned.utils.isOru()) {
                checkMinimumAmount();
            } else if (coned.utils.isOru()) {
                setSubmitButtonState();
            }   
        };

        var resetDropdown = function () {
            if ($paymentMethodSelector.value !== '' && $paymentMethodSelector.selectedIndex !== 0) {
                var optionsIndex = 1;

                while (optionsIndex <= $paymentMethodSelector.options.length) {
                    var $currentOption = $paymentMethodSelector.options[optionsIndex];

                    if (!query.hasClass($currentOption, CONSTANTS.HIDDEN_CLASS)) {
                        $paymentMethodSelector.selectedIndex = optionsIndex;
                        paymentMethodChange();

                        break;
                    } else {
                        optionsIndex++;
                    }
                }
            }
        };

        var currencyFormatNumber = function (number) {
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        };

        var currencyFormat = function (num) {
            var $currencyInput = num.replace(/[^\d.-]/g, '');
            var decimals = '';
            $currencyInput = $currencyInput.split('.', 2);

            if (typeof $currencyInput[1] != 'undefined') {
                decimals = '.' + $currencyInput[1];
            }

            return '$' + currencyFormatNumber($currencyInput[0]) + decimals;
        };

        var currencyStringToFloat = function (currencyString) {
            currencyString = currencyString.replace(/[^\d.-]/g, '');
            return parseFloat(currencyString);
        };

        /**
         * Call the Pay Bill Service with the according accounts 
         * ( all of them for the normal form - first batch for the batches one)
         */
        var payBillMultipleServiceCall = function () {
            var serviceUrl = $multipleForm.dataset.serviceUrl,
                autoPayFlag = query.getFormInputValue($multipleForm, CONSTANTS.FORM_DPP_ENROLL_NAME),
                energyShareFlag = query.getFormInputValue($multipleForm, CONSTANTS.FORM_ENERGY_SHARE_NAME),
                paymentType = query.getFormInputValue($multipleForm, CONSTANTS.FORM_PAYMENT_METHOD_NAME),
                accounts = [],
                lastAccountFlag = false,
                params,
                startAccountNumber;

            // Hide error on the start of the service call
            $billPaymentError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Set flag if last account was selected
            if (paymentType == $multipleForm.dataset.lastAccountOption) {
                lastAccountFlag = true;
            }

            if (_billsList.length) {
                if (_isBatched) {  // Batched Flow
                    var activeBatch = $batchProcessContainer.getElementsByClassName(CONSTANTS.ACTIVE_BATCH)[0],
                        activeBatchText = activeBatch.getElementsByClassName(CONSTANTS.BATCH_DESCRPTION_TEXT)[0], 
                        actualText = activeBatchText.innerText;

                    $formStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                    document.getElementsByClassName(CONSTANTS.STEP_PAYMENT)[0].classList.remove(CONSTANTS.HIDDEN_CLASS);

                    startAccountNumber = getStartAccountNumber(true, activeBatchText, actualText);

                } else {
                    if ($animationCurrentUnits) {  
                        startAccountNumber = getStartAccountNumber(false);
                    }
                }

                _billsListToSend = _billsList.splice(0, _animationUnitsToSend); 
                accounts = setAccounts(startAccountNumber, _billsListToSend);

                // Value changes
                if (accounts.length > 1) {
                    energyShareFlag = null;
                    autoPayFlag = null;
                }

                // Service Data
                params = {
                    ScId: query.getFormInputValue($multipleForm, CONSTANTS.FORM_SCID_NAME),
                    PaymentDate: query.getFormInputValue($multipleForm, CONSTANTS.FORM_PAYMENT_DATE_NAME),
                    PaymentType: paymentType,
                    BankAccountNumber: query.getFormInputValue($multipleForm, CONSTANTS.FORM_BANK_ACCOUNT_NUMBER_NAME),
                    BankRoutingNumber: query.getFormInputValue($multipleForm, CONSTANTS.FORM_ROUTING_ABA_NUMBER_NAME),
                    IsMultipay: true,
                    AutopayFlag: autoPayFlag,
                    EnergyShareFlag: energyShareFlag,
                    UseAccountFromLastPayment: lastAccountFlag,
                    Accounts: accounts
                };

                // Service Call
                params = JSON.stringify(params);
                query.postData(serviceUrl, successPayBillCall, errorPayBillCall, params, true);
            } else {
                finishSubmitAnimation();
            }
        };

        /**
         * Get the number of accounts that are going to be process and update the percentage text in the batches flow
         * @param {boolean} _isBatched 
         * @param {string} activeBatchText 
         * @param {string} actualText 
         */
        var getStartAccountNumber = function (_isBatched, activeBatchText, actualText) {
            var startAccountNumber, 
                reminder;

            if (_first) {
                startAccountNumber = _startAccountNumber;
                _billsListCounter = Math.floor(_billsList.length / _animationUnitsToSend);
                reminder = _billsList.length % _animationUnitsToSend;
            
                if (reminder > 0) {
                    _billsListCounter++;
                }
            
                _billsListPercentage = Math.floor(100 / _billsListCounter);
            
                // if the _billsListPercentage is 0, get the original percentage without floor function
                // and add percentage to display
                if (_billsListPercentage == 0) {
                    _billsListPercentageNoFloor = 100 / _billsListCounter;
                    _isMoreThanHundred = true;
                    _billsListPercentage = 1;
                }
                _first = false;
            
                if (_billsListPercentage !== 100) {
                    _billsListPercentageCounter += _billsListPercentage;

                    if (_isBatched) {
                        activeBatchText.innerHTML = actualText.replace(/[(][0-9]*[%][)]/g, "(" + _billsListPercentage + "%)");
                    } else {
                        $animationCurrentUnits.innerHTML = '(' + _billsListPercentageCounter + '%)';
                    }
                    
                }
            } else {
                startAccountNumber = _startAccountNumber + 1;
                if (_isMoreThanHundred) {
                    _billsListPercentageCounterNoFloor += _billsListPercentageNoFloor;
                    // add the percentage with no floor to total amount, if the amount with floor function
                    // applied is more than the current total unit, add 1 to the displayed percentage
                    // and update markup
                    if (Math.floor(_billsListPercentageCounterNoFloor) > _billsListPercentageCounter) {
                        _billsListPercentageCounter += _billsListPercentage;

                        if (_isBatched) {
                            activeBatchText.innerHTML = actualText.replace(/[(][0-9]*[%][)]/g, "(" + _billsListPercentageCounter + "%)");
                        } else {
                            $animationCurrentUnits.innerHTML = '(' + _billsListPercentageCounter + '%)';
                        }
                    }
                } else {
                    _billsListPercentageCounter += _billsListPercentage;
                    
                    if (_isBatched) {
                        activeBatchText.innerHTML = actualText.replace(/[(][0-9]*[%][)]/g, "(" + _billsListPercentageCounter + "%)");
                    } else {
                        $animationCurrentUnits.innerHTML = '(' + _billsListPercentageCounter + '%)';
                    }
                }
            }

            return startAccountNumber;
        }

        /**
         * Get the list of accounts that are going to be paid
         * @param {int} firstAccount 
         * @param {array} billsToPay 
         */
        var setAccounts = function (firstAccount, billsToPay) {
            var accounts = [];
            index = firstAccount;

            if (billsToPay.length === 1 && index == 0 && $otherAmountCheckbox.checked) {
                var currentAccount = _.filter($dynamicInputs, function (accountListItem) {
                    if (accountListItem.dataset.maid === billsToPay[0].maid) return accountListItem;
                });

                accounts.push(setAccount(currentAccount[0]));
                _startAccountNumber = index;
                index++;
            } else {
                _.each(billsToPay, function (bill) {
                    var $bill = $billsStep.querySelector('input[data-maid="' + bill.maid + '"]');

                    accounts.push(setAccount($bill));

                    _startAccountNumber = index;
                    index++;
                });
            }

            return accounts;
        };

        /**
         * Get the general information for the account
         * @param {HTMLElement} $dynamicInput 
         */
        var setAccount = function ($dynamicInput) {
            var account, paymentAmount, totalAmount;

            if ($dynamicInput.dataset.amount != $dynamicInput.dataset.value) {
                paymentAmount = parseFloat($dynamicInput.dataset.amount.replace(/,/g, ''));
            } else {
                paymentAmount = parseFloat($dynamicInput.dataset.value.replace(/,/g, ''));
            }

            // Handle donation addition
            if ($energyShareSelector.checked) {
                totalAmount = paymentAmount + 1;
            } else {
                totalAmount = paymentAmount;
            }

            account = {
                Maid: $dynamicInput.dataset.maid,
                PaymentLowerLimit: currencyStringToFloat($dynamicInput.dataset.paymentMin),
                PaymentUpperLimit: currencyStringToFloat($dynamicInput.dataset.paymentMax),
                AccountNumber: $dynamicInput.dataset.accountNumber,
                Address: $dynamicInput.dataset.address,
                PaymentAmount: paymentAmount,
                TotalAmount: totalAmount
            };

            return account;
        };

        /**
         * Show the form to fill credit card information
         */
        var creditCardPayment = function () {
            var creditCardLink = $multipleForm.dataset.creditPayment,
                title = $creditCardMessageTitle.innerHTML,
                description = $creditCardMessageDescription.innerHTML,
                $checkboxChecked = $billsStep.querySelector(
                    '.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)'
                ),
                accountType = $checkboxChecked && $checkboxChecked.dataset.accountType;

            description = description.replace('@LINK', creditCardLink);

            $statusBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $statusSuccessTitle.innerHTML = title;
            $statusSuccessList.innerHTML = description;
            $statusSuccessList.classList.add(CONSTANTS.STATUS_LIST_COMPLETE);
            $statusBanner.focus();

            // Analytics data building
            dataLayer.push({
                event: CONSTANTS.TAG_PAY_BILL_CREDIT_CARD,
                creditAccountType: accountType
            });

            window.open(creditCardLink);
        };

        /**
         * Call the processPayBillCallData function
         * @param {object} data 
         */
        var successPayBillCall = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.POST_PAYMENT_MULTIPLE, processPayBillCallData, errorPayBillCall, null);
            } else {
                processPayBillCallData(data);
            }
        };

        /**
         * In case the service fail it try to pay the next account
         */
        var errorPayBillCall = function () {
            if (coned.utils.isPatternLab()) {
                setTimeout(function () {
                    payBillMultipleServiceCall();
                }, 2000);
            } else {
                payBillMultipleServiceCall();
            }
        };

        /**
         * Show the Payment Status on the regular form and update the baner for the batch form
         */
        var finishSubmitAnimation = function () {
            if (_isBatched) {
                successPayBillMultipleServiceCallback(); 
                updateBatchBanner();
            } else {
                $submitAnimation.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            }
        };

        /**
         * Call the setBatchStatus function and 
         * update the Title and description when all the batch transactions are done if not activate the next batch
         */
        var updateBatchBanner = function () {
            var activeBatch = $batchProcessContainer.getElementsByClassName(CONSTANTS.ACTIVE_BATCH)[0], 
                state = (_batchState === CONSTANTS.FAIL_VALUE) ? CONSTANTS.BATCH_WARNINGS_TOKEN : CONSTANTS.BATCH_SUCCESS_TOKEN;

            setBatchStatus(activeBatch, { index: activeBatch.dataset.index, amount1: activeBatch.dataset.amount }, state);
            activeBatch.classList.remove(CONSTANTS.ACTIVE_BATCH);

            if (_totalbillsBatchList.length === 0) {
                var bannerDescription = $batchBannerDescription.getElementsByClassName(CONSTANTS.BATCH_DESCRIPTION)[0];

                if (_amountFailAccounts === 0) {
                    $batchBannerTitle.innerHTML = $batchBanner.dataset.successTitle;
                    bannerDescription.innerHTML = $batchBanner.dataset.successDescription;
                } else {
                    $batchBannerTitle.innerHTML = $batchBanner.dataset.partiallyTitle;
                    bannerDescription.innerHTML = $batchBanner.dataset.partiallyDescription;
                }
                $batchMyAccountBtn.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } {
                activateNextBatch();
            }
        };

        /**
         * Call the payBillMultipleServiceCall function
         * @param {object} data 
         */
        var processPayBillCallData = function (data) {
            if (coned.utils.isPatternLab()) {
                setTimeout(function () {
                    successCallback(data);
                    
                    payBillMultipleServiceCall();
                }, 2000);
            } else {
                successCallback(data);
                payBillMultipleServiceCall();
            }
        };

        /**
         * Set all the necesary data for the payment
         * @param {object} data 
         */
        var successCallback = function (data) {
            $submitAnimationSelector.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);

            if (_data) {
                var refNumbers = _data.RefNumbers.concat(data.RefNumbers),
                    transactionProducts = _data.eCommerceJson.transactionProducts.concat(data.eCommerceJson.transactionProducts),
                    transactionTotal = _data.eCommerceJson.transactionTotal + parseFloat(data.eCommerceJson.transactionTotal);

                _data.RefNumbers = refNumbers;
                _data.eCommerceJson.transactionProducts = transactionProducts;
                _data.eCommerceJson.transactionTotal = Math.round(transactionTotal * 100) / 100;
            } else {
                _data = data;
                _data.eCommerceJson.transactionTotal = Math.round(parseFloat(_data.eCommerceJson.transactionTotal) * 100) / 100;
            }
        };

        /**
         * Show the success page for Credit Cards payment
         */
        var successPayBillMultipleServiceCallback = function () {
            var partialResults = [];

            // If credit card payment, just show the success page
            if ($paymentMethodSelector.value == $multipleForm.dataset.creditOption) {
                $statusStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $formStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                window.scrollTo(0, 0);
                return;
            }

            // In PL, we should clean the RefNumbers array if it's a single payment
            if (coned.utils.isPatternLab()) {
                var $checkedLabels = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)');

                coned.utils.processLargeData(
                    $checkedLabels,
                    // Handler
                    function ($element) {
                        var currentMaid = _data.RefNumbers.find(function (value) {
                            return $element.dataset.maid === value.Maid;
                        });

                        partialResults.push(currentMaid);
                    },
                    // On complete, set new RefNumbers
                    function () {
                        _data.RefNumbers = partialResults;
                    }
                );
            }

            // Analytics data building
            dataLayer.push(_data.eCommerceJson);

            payBillMultipleTransactionResult(_data);

            if (_isBatched) {
                $batchResultsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else { 
                $animationStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                $statusStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $formStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            window.scrollTo(0, 0);

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($multipleForm);
        };

        var createBillsList = function (billsList, type, containerSelector, listSelector) {
            var $tabContainer,
                showMoreButton,
                $tbodyElement, 
                $ulElement,
                trElement,
                tdRightContainer,
                accountNumber, 
                listElements;
                
                if (billsList.length) {
                    if (_isBatched) { 
                        $tabContainer = $batchResultsContainer.getElementsByClassName(containerSelector)[0];
                    } else {
                        // get tab container to display it
                        $tabContainer = $statusBanner.getElementsByClassName(containerSelector)[0];
                    }

                // element where we'll be adding elements
                $ulElement = $tabContainer.getElementsByClassName(listSelector)[0];

                $tabContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $tbodyElement = $ulElement.getElementsByTagName('table')[0];
                
                Array.prototype.forEach.call(billsList, function (bill) {
                    var tableDesktopBody = document.createElement('tbody'),
                        tableMobileBody = document.createElement('tbody'),
                        tdElement = document.createElement(CONSTANTS.TD_ELEMENT),
                        trAddressElement = document.createElement(CONSTANTS.TR_ELEMENT),
                        trConfirmElement = document.createElement(CONSTANTS.TR_ELEMENT),
                        thConfirmTitle = document.createElement(CONSTANTS.TH_ELEMENT),
                        tdAccountNumber = document.createElement(CONSTANTS.TD_ELEMENT),
                        tdConfirmNumber = document.createElement(CONSTANTS.TD_ELEMENT);
                        tdConfirmNumber.classList.add(CONSTANTS.TEXT_RIGHT)

                    trElement = document.createElement(CONSTANTS.TR_ELEMENT);
                    trElement.classList.add(CONSTANTS.POST_PAYMENT);
                    trElement.classList.add(CONSTANTS.PAYMENT_TABLE);
                    tableDesktopBody.classList.add(CONSTANTS.SHOW_DESKTOP);
                    tableMobileBody.classList.add(CONSTANTS.SHOW_MOBILE);

                    listElements = $tabContainer.getElementsByClassName(CONSTANTS.LIST_ELEMENT_CLASS_SELECTOR).length / 2;

                    if (listElements > 9) {
                        tableDesktopBody.className += CONSTANTS.LIST_ELEMENT_CLASSESS_HIDDEN;
                        tableMobileBody.className += CONSTANTS.LIST_ELEMENT_CLASSESS_HIDDEN;
                    } else {
                        tableDesktopBody.classList.add(CONSTANTS.LIST_ELEMENT_CLASS_SELECTOR);
                        tableMobileBody.classList.add(CONSTANTS.LIST_ELEMENT_CLASS_SELECTOR);
                    } 

                    // Creating address element
                    tdElement.innerHTML = bill.ServiceAddress;

                    tdRightContainer = document.createElement(CONSTANTS.TD_ELEMENT);

                    // Creating address element
                    if (type == CONSTANTS.SUCCESS_VALUE) {
                        accountNumber = bill.RefNumber;
                    } else {
                        accountNumber = bill.AccountNumber;
                    }

                    tdRightContainer.innerHTML = accountNumber;
                    
                    // Rows for mobile
                    tdAccountNumber.setAttribute('colspan', '2');
                    tdAccountNumber.innerHTML = bill.ServiceAddress;
                    trAddressElement.appendChild(tdAccountNumber);

                    thConfirmTitle.innerHTML = CONSTANTS.CONFIRMATION_VALUE;
                    tdConfirmNumber.innerHTML = accountNumber;

                    trAddressElement.appendChild(tdAccountNumber);
                    trConfirmElement.appendChild(thConfirmTitle);
                    trConfirmElement.appendChild(tdConfirmNumber);
                    // ends mobile code

                    // create the tables
                    trElement.appendChild(tdElement);
                    trElement.appendChild(tdRightContainer);
                    tableDesktopBody.appendChild(trElement);

                    tableMobileBody.appendChild(trAddressElement)
                    tableMobileBody.appendChild(trConfirmElement)

                    $tbodyElement.appendChild(tableDesktopBody);
                    $tbodyElement.appendChild(tableMobileBody);
                    
                    // display button if there are more than 9 buttons 
                    if (listElements > 9) {
                        showMoreButton = $tabContainer.getElementsByClassName(CONSTANTS.SHOW_MORE_CLASS)[0];
                        showMoreButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }
                });
            }
        };

        var onShowMoreSelector = function (event) {
            event.preventDefault();
            var eventElement = event.srcElement.tagName.toLowerCase() === "span" ? event.srcElement.parentElement : event.srcElement;

            if (eventElement.classList.contains(CONSTANTS.BUTTON_BILLS_PROCESSED)) {
                showMoreClickEvent(CONSTANTS.TAB_BILLS_PROCESSED, CONSTANTS.BUTTON_BILLS_PROCESSED);
            } else if (eventElement.classList.contains(CONSTANTS.BUTTON_BILLS_NOT_PROCESSED)) {
                showMoreClickEvent(CONSTANTS.TAB_BILLS_NOT_PROCESSED, CONSTANTS.BUTTON_BILLS_NOT_PROCESSED);
            } else {
                showMoreClickEvent(CONSTANTS.TAB_NOT_PAYABLE_CHECK, CONSTANTS.BUTTON_NOT_PAYABLE);
            }
        };

        var showMoreClickEvent = function (listSelector, buttonSelector) {
            var resultsBanner = _isBatched ? $batchResultsContainer : $statusBanner,
                $ulElement = resultsBanner.getElementsByClassName(listSelector)[0],
                $liElements = $ulElement.getElementsByClassName(CONSTANTS.LIST_ELEMENT_CLASS_SELECTOR),
                hiddenItems = [],
                hiddenListElementsClasses = CONSTANTS.LIST_ELEMENT_CLASS_SELECTOR + ' ' + CONSTANTS.HIDDEN_CLASS;

            // get hidden items
            Array.prototype.forEach.call($liElements, function ($selectedItem) {
                if (query.hasClass($selectedItem, CONSTANTS.HIDDEN_CLASS)) {
                    hiddenItems.push($selectedItem);
                }
            });

            // loop to show hidden items
            _.each(hiddenItems, function ($hideItem, index) {
                if (index < 10) {
                    $hideItem.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            });

            // get list elements that are hidden
            $liElements = $ulElement.getElementsByClassName(hiddenListElementsClasses);

            // if length is 0 hide button
            if ($liElements.length == 0) {
                var button = resultsBanner.getElementsByClassName(buttonSelector)[0];
                button.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var getStaticBillsList = function (containerSelector, listSelector, numberTabSelector) {
            var $tabContainer, $ulElement, $liElements, $statusNotPayableCheckNumber, showMoreButton;

            $ulElement = $multipleForm.getElementsByClassName(listSelector)[0];
            $liElements = $ulElement.getElementsByClassName(CONSTANTS.LIST_ELEMENT_CLASS_SELECTOR);

            //remove hidden class to display tab
            $tabContainer = $multipleForm.getElementsByClassName(containerSelector)[0];
            $tabContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // add hidden to elements greater than 10
            _.each($liElements, function ($hideItem, index) {
                if (index > 9) {
                    $hideItem.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            });

            // display button if there are more than 9 buttons
            if ($liElements.length > 10) {
                showMoreButton = $tabContainer.getElementsByClassName(CONSTANTS.SHOW_MORE_CLASS)[0];
                showMoreButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            $statusNotPayableCheckNumber = $multipleForm.getElementsByClassName(numberTabSelector)[0];
            $statusNotPayableCheckNumber.innerHTML = $liElements.length;
            
        };

        var verifyPayableByCheck = function (listSelector){
            var $ulElement = $multipleForm.getElementsByClassName(listSelector)[0],
            $liElements = $ulElement.getElementsByClassName(CONSTANTS.LIST_ELEMENT_CLASS_SELECTOR);
            return $liElements.length ? true: false;        
        };

        var payBillMultipleTransactionResult = function (dataArray) {
            var successList = [],
                failList = [];

            if (dataArray && dataArray.RefNumbers && dataArray.RefNumbers.length) {
                successList = dataArray.RefNumbers.filter(function (refNumber) {
                    return refNumber.Success;
                });

                failList = dataArray.RefNumbers.filter(function (refNumber) {
                    return !refNumber.Success;
                });
            }

            if (_oneBillToPay) {
                 /**This payment status can return different status and display the corresponding message
                     * PaymentStatus = 3 TONP
                     * PaymentStatus = 9 DPP
                     */
                $paymentError.href = $paymentError.href.replace('{{MAID}}', dataArray.RefNumbers[0].Maid);
                if(dataArray.RefNumbers[0].SuccessCode) {
                    var paymentStatus = dataArray.RefNumbers[0].SuccessCode.split('_')[1];
                    for (var message = 0; message < $paymentStatusMessage.length; message++) {
                        if($paymentStatusMessage[message].dataset.paymentStatus === paymentStatus) {
                            $paymentStatusMessage[message].classList.remove(CONSTANTS.HIDDEN_CLASS);
                            $paymentStatusMessage[message].focus();
                        }
                    }
                }
            }

            if (!_isBatched) {
                // display image according list
                if (successList.length) {
                    $successPicture.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $failPicture.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
                $statusBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }


            if (successList.length) {
                _batchState = CONSTANTS.SUCCESS_VALUE;
                _totalSuccessAccounts = successList.length;
                createBillsList(successList, CONSTANTS.SUCCESS_VALUE, CONSTANTS.TAB_BILLS_PROCESSED_CONTAINER, CONSTANTS.TAB_BILLS_PROCESSED);
            }

            if (failList.length) {
                _batchState = CONSTANTS.FAIL_VALUE;
                _totalFailAccounts = failList.length;
                _amountFailAccounts += _totalFailAccounts;
                getTaggingForFail(failList);
                createBillsList(failList, CONSTANTS.FAIL_VALUE, CONSTANTS.TAB_BILLS_NOT_PROCESSED_CONTAINER, CONSTANTS.TAB_BILLS_NOT_PROCESSED);
            }

            if ($notPayableByCheckTab && verifyPayableByCheck(CONSTANTS.TAB_NOT_PAYABLE_CHECK)) {
                getStaticBillsList(
                    CONSTANTS.TAB_NOT_PAYABLE_CHECK_CONTAINER,
                    CONSTANTS.TAB_NOT_PAYABLE_CHECK,
                    CONSTANTS.MULTIPLE_PAYMENT_STATUS_NOT_PAYABLE_CHECK_NUMBER
                );

                if ($checkAllCheckbox.checked) {
                    $notPayableByCheckTab.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $notPayableByCheckTab.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }

            if (successList.length) {
                var successField = _isBatched ? $statusBatchSuccessBill : $statusSuccessBill;

                if (_isBatched) {
                    var totalBills = $statusSuccessBatchedNumber.innerText;

                    $statusSuccessBatchedNumber.innerText = (totalBills === "") ? successList.length : parseInt(totalBills) + successList.length;
                    successField.innerHTML = $statusSuccessBatchedNumber.innerText === "1" ? successField.dataset.oneBill : successField.dataset.multipleBill;
                } else {
                    $statusSuccessNumber.innerHTML = successList.length;
                    successField.innerHTML = successList.length === 1 ? successField.dataset.oneBill : successField.dataset.multipleBill;
                }
            }

            if (failList.length) {
                var failField = _isBatched ? $statusBatchFailedBill : $statusFailedBill;

                if (_isBatched) {
                    var totalFailBills = $statusFailBatchedNumber.innerText;

                    $statusFailBatchedNumber.innerText = (totalFailBills === "") ? failList.length : parseInt(totalFailBills) + failList.length;
                    failField.innerHTML = $statusFailBatchedNumber.innerText === "1" ? failField.dataset.oneBill : failField.dataset.multipleBill;
                } else {
                    $statusFailedNumber.innerHTML = failList.length;
                    failField.innerHTML = failList.length === 1 ? failField.dataset.oneBill : failField.dataset.multipleBill;
                }
            }
        };

        var errorPayBillMultipleServiceCallback = function () {
            var $msgTextSelector = $billPaymentError.getElementsByClassName(CONSTANTS.ERROR_TEXT_SELECTOR)[0];

            $msgTextSelector.innerHTML = _data.errorMsg ? _data.errorMsg : coned.constants.ERROR_MESSAGE;
            $billPaymentError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $billPaymentError.focus();
            $animationStep.classList.add(CONSTANTS.HIDDEN_CLASS);

            for (var elementIndex = 0; elementIndex < $finishHiddenElements.length; elementIndex++) {
                var $finishHiddenElement = $finishHiddenElements[elementIndex],
                    isEnrollUnitsList = $finishHiddenElement.classList.contains(CONSTANTS.MULTIPLE_PAYMENT_BILLS_STEP);

                if (!isEnrollUnitsList) {
                    $finishHiddenElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            }

            // Analytics data building
            dataLayer.push({
                event: 'pay.bill.error'
            });
        };

        var getTaggingForFail = function (_failList) {
            _.each(_failList, function (currentElement) {
                var dataLayerObj = {
                    event: 'pay.bill.error'
                };

                if (currentElement.PaymentErrors && currentElement.PaymentErrors.errorDetails) {
                    var errorCodes = [];

                    _.each(currentElement.PaymentErrors.errorDetails, function (error) {
                        errorCodes.push(error.errorCode);
                    });

                    dataLayerObj.payBillErrors = errorCodes.join('|');
                } else {
                    dataLayerObj.payBillErrors = CONSTANTS.NO_ERROR_CODES_PROVIDED_MESSAGE;
                }

                if (currentElement.Maid !== null) {
                    dataLayerObj.maid = currentElement.Maid;
                }

                dataLayer.push(dataLayerObj);
            });
        };

        var updateCreditUrl = function (checkedStatus, $checkbox) {
            var selectedItem, selectedUrl;

            // set checkbox selected url otherwise asign the url of the active checkbox
            if (checkedStatus) {
                $multipleForm.dataset.creditPayment = $checkbox.dataset.creditPaymentUrl;
            } else {
                var $checkboxIsActive = document.getElementsByClassName(CONSTANTS.CHECKED_CLASS)[0];

                if ($checkboxIsActive) {
                    selectedItem = $checkboxIsActive.getElementsByClassName(CONSTANTS.RECEIPT_CHECKBOX)[0];
                    selectedUrl = selectedItem.dataset.creditPaymentUrl;

                    $multipleForm.dataset.creditPayment = selectedUrl;
                }
            }
        };

        var billLinkEvent = function (target, event) {
            event.preventDefault();

            var $billLink = target,
                $billsContainer = query.selectParentElement($billLink, CONSTANTS.FINISH_HIDDEN_ELEMENT),
                $billItem = query.selectParentElement($billLink, CONSTANTS.RECEIPT_LABEL),
                $billItemParent = $billItem.parentElement,
                serviceUrl = $billsContainer.dataset.linkServiceUrl,
                params;

            // Set elements depending on the item clicked
            $currentBillLink = $billLink;
            $billServiceError = $billItemParent.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $billServiceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // If bill link is available, open the link in a new tab
            if ($currentBillLink.dataset.billLink) {
                window.open($currentBillLink.href, '_blank');

                return;
            }

            // Service data
            params = {
                ScId: $billLink.dataset.scid,
                Maid: $billLink.dataset.maid,
                BillDate: $billLink.dataset.billDate,
                Type: $billLink.dataset.type
            };

            // Before doing the service call, lets create the new tab where the link will be opened, so it won't be blocked by browser security
            $newTab = window.open();

            // Service call
            query.getData(serviceUrl, successBillService, errorBillService, params, $formLoading);
        };

        var successBillService = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.GET_BILL_LINK, redirectPage, errorBillService);
            } else if (data.Link && data.Link != '') {
                redirectPage(data);
            } else {
                errorBillService(data);
            }
        };

        var redirectPage = function (data) {
            // Set link and flag for future click
            $currentBillLink.dataset.billLink = 'true';
            $currentBillLink.href = data.Link;

            // Set file location in the opened tab
            $newTab.location.href = data.Link;
        };

        var errorBillService = function (data) {
            var $billServiceErrorMessage = $billServiceError.getElementsByClassName(CONSTANTS.SERVICE_ERROR_MESSAGE)[0];

            // Close new tab opened
            $newTab.close();

            // Display error message
            $billServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $billServiceErrorMessage.innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;
        };

        var validateDate = function (event) {
            var validator = $('.' + CONSTANTS.FORM_SELECTOR).validate();
            validator.element(event.target);
        };

        /**
         * Show the loading page on the normal flow
         */
        var updateAnimationData = function () {
            _totalAccount = _billsList.length;

            if (_totalAccount) {
                $animationTotalUnits.innerHTML = _totalAccount;
            }

            for (var elementIndex = 0; elementIndex < $finishHiddenElements.length; elementIndex++) {
                var $finishHiddenElement = $finishHiddenElements[elementIndex];

                $finishHiddenElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            $animationStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
            window.scrollTo(0, 0);
        };

        /**
         * Start the according process for the batches or the normal flow
         */
        var payMyBillMultipleSubmit = function () {
            var paymentType = query.getFormInputValue($multipleForm, CONSTANTS.FORM_PAYMENT_METHOD_NAME);

            if(_billsList.length === 1){
                _oneBillToPay = true;
            } else { 
                _oneBillToPay = false;
            }

            // Credit card payment process
            if (paymentType == $multipleForm.dataset.creditOption) {
                creditCardPayment();
                $formStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                $statusStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                window.scrollTo(0, 0);

                // Analytics data building
                dataLayer.push({
                    event: 'pay.bill.submit',
                    paymentMethod: paymentType
                });
            } else {
                if (_batchSize && _billsList.length > _batchSize) {  // total of accounts  > the maximum accounts allowed (50)
                    // Analytics data building
                    amountAccountsTagging(_billsList.length);

                    dataLayer.push({
                        event: 'pay.multiple.submit',
                        paymentMethod: paymentType
                    });

                    startBatchFlow();
                } else {  // current flow
                    updateAnimationData(); // show the page with the progress animation

                    if (_billsList.length === 1) {
                        $animationBill.innerHTML = $animationBill.dataset.oneBill;

                        // Analytics data building
                        dataLayer.push({
                            event: 'pay.bill.submit',
                            paymentMethod: paymentType
                        });
                    } else {
                        $animationBill.innerHTML = $animationBill.dataset.multipleBill;

                        // Analytics data building
                        amountAccountsTagging(_billsList.length);

                        dataLayer.push({
                            event: 'pay.multiple.submit',
                            paymentMethod: paymentType
                        });
                    }

                    new coned.components.SubmitAnimation(
                        $submitAnimation,
                        payBillMultipleServiceCall,
                        successPayBillMultipleServiceCallback,
                        errorPayBillMultipleServiceCallback
                    );
                }
            }
        };

        // Functions for the batches flow

        /**
         * Create all the batches and set the first one and start the payment process with that one
         */
        var startBatchFlow = function () {
            _isBatched = true;

            // Create batches
            for (var i = 0; i < Math.ceil(_billsList.length / _batchSize); i++) {
                if (_batchSize * (i + 1) <= _billsList.length) {
                    createBatchMarkup(i + 1, _batchSize);
                } else {
                    createBatchMarkup(i + 1, _billsList.length - _batchSize * i);
                }
            }

            // Sets Current Batch
            _currentBatchIndex = 1;
            $currentBatch = $batchProcessContainer.getElementsByClassName(CONSTANTS.BATCH_ITEM)[0];

            // Start Processing Payment
            setBatchStatus(
                $currentBatch,
                { index: $currentBatch.dataset.index, amount1: $currentBatch.dataset.amount, percentage: 0 },
                CONSTANTS.BATCH_LOADING_TOKEN
            );
            
            _billsList = _totalbillsBatchList.splice(0, _batchSize);
            payBillMultipleServiceCall();
            $batchBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);

            window.scrollTo(0, 0);
        };

        /**
         * Activate the next batch when the current one ends the payment process
         */
        var activateNextBatch = function () {
            $currentBatch = $batchProcessContainer.querySelector('.' + CONSTANTS.BATCH_ITEM + '[data-index="' + ++_currentBatchIndex + '"]');

            if (!$currentBatch) return;

            var $batchButtonsContainer = $currentBatch.getElementsByClassName(CONSTANTS.BATCH_BUTTON_CONTAINER)[0];

            $currentBatch.classList.add(CONSTANTS.ACTIVE_BATCH);
            $batchButtonsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            _billsListPercentageCounter = 0;
            _billsListPercentageCounterNoFloor = 0;
        };

        /**
         * Create the html for the batches
         * @param {int} index 
         * @param {int} amount 
         */
        var createBatchMarkup = function (index, amount) {
            var $batchElement = new DOMParser().parseFromString(CONSTANTS.BATCH_ITEM_STRUCTURE, 'text/html').getElementsByClassName(CONSTANTS.BATCH_ITEM)[0],
                $batchPayButton = $batchElement.getElementsByClassName(CONSTANTS.BATCH_PAY)[0],
                $batchCancelButton = $batchElement.getElementsByClassName(CONSTANTS.BATCH_CANCEL)[0],
                $cancelPopUpButtons = $cancelPopup.getElementsByClassName(CONSTANTS.CLOSE_POPUP_BUTTON);

            $batchElement.dataset.index = index;
            $batchElement.dataset.amount = amount;

            setBatchStatus($batchElement, { index: index, amount1: amount });

            $batchPayButton.getElementsByTagName('span')[0].innerHTML = $batchBanner.dataset.payButtonText;
            $batchCancelButton.innerHTML = $batchBanner.dataset.cancelButtonText;

            // show the "Are you sure?"" popup
            coned.utils.addGeneralListeners($batchCancelButton, function () {
                $cancelPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $cancelPopUpButtons[0].focus();
            });

            // "pay this batch" button funcionality
            coned.utils.addGeneralListeners($batchPayButton, function(event) {
                event.preventDefault();

                _billsList = _totalbillsBatchList.splice(0, _batchSize);
                _first = true;
                _startAccountNumber = 0;
                _data = undefined;
                
                setBatchStatus($batchElement, { index: index, amount1: amount, percentage: 0 }, CONSTANTS.BATCH_LOADING_TOKEN);
                payBillMultipleServiceCall();

            })

            // event for all close buttons on the cancel popup
            _.each($cancelPopUpButtons, function ($cancelPopUpButton) {
                coned.utils.addGeneralListeners($cancelPopUpButton, function () {
                    var activeBatch = $batchProcessContainer.getElementsByClassName(CONSTANTS.ACTIVE_BATCH)[0],
                        cancelButton = activeBatch.getElementsByClassName(CONSTANTS.BATCH_CANCEL)[0];

                    cancelButton.focus();
                })
            });

            $batchProcessContainer.appendChild($batchElement);
        };

        /**
         * Cancel the batch and update the title and the description
         */
        var cancelBatch = function () {
            var bannerDescription = $batchBannerDescription.getElementsByClassName(CONSTANTS.BATCH_DESCRIPTION)[0], 
                startIndex = $currentBatch.dataset.index, 
                totalBatches = $batchProcessContainer.getElementsByClassName(CONSTANTS.BATCH_ITEM);

            for (startIndex; startIndex <= totalBatches.length; startIndex++) {
                var actualBatch = totalBatches[startIndex-1];
                setBatchStatus(actualBatch, { index: startIndex, amount1: actualBatch.dataset.amount}, CONSTANTS.BATCH_CANCELLED_TOKEN);
            }
            
            $batchMyAccountBtn.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $batchBannerTitle.innerHTML = $batchBanner.dataset.partiallyTitle;
            bannerDescription.innerHTML = $batchBanner.dataset.cancelDescription;
        }

        /**
         * Set the correct state to the batch according with the payment step or result
         * @param {HTMLElement} $element 
         * @param {object} data 
         * @param {string} status 
         */
        var setBatchStatus = function ($element, data, status) {
            var $batchDescriptionText = $element.getElementsByClassName(CONSTANTS.BATCH_DESCRPTION_TEXT)[0],
                $batchIcon = $element.getElementsByClassName(CONSTANTS.BATCH_ICON)[0],
                $batchLoading = $element.getElementsByClassName(CONSTANTS.BATCH_LOADING)[0],
                $batchButtonsContainer = $element.getElementsByClassName(CONSTANTS.BATCH_BUTTON_CONTAINER)[0],
                descriptionText, 
                updateBillText = true,
                billsString = $batchBanner.dataset.bills,
                billString = $batchBanner.dataset.bill;

            // Reset icons to default
            cleanBatchIcons($element);

            // Sets correct status according to data
            switch (status) {
                case CONSTANTS.BATCH_SUCCESS_TOKEN:
                    $batchIcon.classList.add(CONSTANTS.BATCH_CHECK_CLASS);
                    $batchIcon.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $batchButtonsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                    descriptionText = $batchBanner.dataset.successAllText;
                    descriptionText = descriptionText.replace(CONSTANTS.BATCH_TOKEN, data.index).replace(CONSTANTS.AMOUNT1_TOKEN, data.amount1);

                    break;
                case CONSTANTS.BATCH_WARNINGS_TOKEN:
                    $batchIcon.classList.add(CONSTANTS.BATCH_WARNING_CLASS);
                    $batchIcon.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $batchButtonsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                    descriptionText = $batchBanner.dataset.successPartialText;
                    descriptionText = descriptionText
                        .replace(CONSTANTS.BATCH_TOKEN, data.index)
                        .replace(CONSTANTS.AMOUNT1_TOKEN, _totalSuccessAccounts)
                        .replace(CONSTANTS.AMOUNT2_TOKEN, _totalFailAccounts);


                    if (String(_totalSuccessAccounts) === "1") {
                        var firstText = descriptionText.match(/:\s[0-9\sA-Za-z]*-/g),
                            replaceBill =  firstText[0].replace(billsString, billString);

                        descriptionText = descriptionText.replace(/:\s[0-9\sA-Za-z]*-/g, replaceBill);
                    }

                    if (String(_totalFailAccounts) === "1") {
                        var secondText =  descriptionText.match(/-\s[0-9\sA-Za-z]*/g),
                            replaceSecondBill = secondText[0].replace(billsString, billString);

                        descriptionText = descriptionText.replace(/-\s[0-9\sA-Za-z]*/g, replaceSecondBill);
                    }

                    updateBillText = false;

                    break;
                case CONSTANTS.BATCH_LOADING_TOKEN:
                    if (!query.hasClass($batchLoading, CONSTANTS.LOADING_ANIMATION_PLAY)) {
                        new coned.components['LoadingAnimation']($batchLoading);
                        query.fireEvent($batchLoading, 'start-animation');
                    }

                    $element.classList.add(CONSTANTS.ACTIVE_BATCH);
                    $batchIcon.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $batchLoading.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $batchButtonsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                    descriptionText = $batchBanner.dataset.loadingText;
                    descriptionText = descriptionText
                        .replace(CONSTANTS.BATCH_TOKEN, data.index)
                        .replace(CONSTANTS.AMOUNT1_TOKEN, data.amount1)
                        .replace(CONSTANTS.PERCENTAGE_TOKEN, data.percentage);

                    break;
                case CONSTANTS.BATCH_CANCELLED_TOKEN:
                    $batchIcon.classList.add(CONSTANTS.BATCH_ERROR_CLASS);
                    $batchIcon.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $batchButtonsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                    descriptionText = $batchBanner.dataset.cancelledText;
                    descriptionText = descriptionText.replace(CONSTANTS.BATCH_TOKEN, data.index).replace(CONSTANTS.AMOUNT1_TOKEN, data.amount1);

                    break;
                default:
                    $batchIcon.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    descriptionText = $batchBanner.dataset.pendingText;
                    descriptionText = descriptionText.replace(CONSTANTS.BATCH_TOKEN, data.index).replace(CONSTANTS.AMOUNT1_TOKEN, data.amount1);

                    break;
            }

            if (updateBillText && String(data.amount1) === "1") {
                descriptionText = descriptionText.replace(billsString, billString);
                descriptionText = descriptionText.replace(billsString.toLowerCase(), billString.toLowerCase());
            }

            $batchDescriptionText.innerHTML = descriptionText;
        };

        /**
         * Clean all the icons
         * @param {HTMLElement} $element 
         */
        var cleanBatchIcons = function ($element) {
            var $batchIcon = $element.getElementsByClassName(CONSTANTS.BATCH_ICON)[0],
                $batchLoading = $element.getElementsByClassName(CONSTANTS.BATCH_LOADING)[0];

            $batchIcon.classList.remove(CONSTANTS.BATCH_WARNING_CLASS);
            $batchIcon.classList.remove(CONSTANTS.BATCH_ERROR_CLASS);
            $batchIcon.classList.remove(CONSTANTS.BATCH_CHECK_CLASS);
            $batchIcon.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $batchLoading.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Get total amount font size at current breakpoint
         */
        var getTotalAmountFontSize = function () {
            // Remove inline font-size so we can then get the actual CSS value
            $headerTotalAmount.style.fontSize = '';

            totalAmountFontSize = parseInt(
                window
                    .getComputedStyle($headerTotalAmount, CONSTANTS.NULL_VALUE)
                    .getPropertyValue(CONSTANTS.FONT_SIZE)
            );             
        };

        /**
         * Observe amount changes and update font size if needed
         */
        var amountChangeObserver = function () {
            var config = { childList: CONSTANTS.TRUE_VALUE },
                observer;

            // Callback function to execute when mutations on the child list are observed
            var changeHandler = function (mutationList) {
                if (
                    mutationList[0].type === CONSTANTS.CHILD_LIST_TYPE && 
                    !query.hasClass($formStep, CONSTANTS.HIDDEN_CLASS)
                ) {
                    getTotalAmountFontSize();

                    coned.utils.updateFontToFitAncestor(
                        $headerTotalAmount, 
                        $billHeader, 
                        totalAmountFontSize
                    );
                }
            };

            observer = new MutationObserver(changeHandler);
            observer.observe($headerTotalAmount, config);            
        };                

        /**
         * Observe amount visibility through its ancestor, and update font size if needed
         */
        var amountVisibleObserver = function () {                
            var config = { attributes: CONSTANTS.TRUE_VALUE },
                observer;

            // Callback function to execute when mutations on the class are observed
            var visibilityHandler = function (mutationList) {
                if (
                    mutationList[0].attributeName === CONSTANTS.CLASS_ATTRIBUTE &&
                    !query.hasClass($formStep, CONSTANTS.HIDDEN_CLASS)
                ) {
                    getTotalAmountFontSize();

                    coned.utils.updateFontToFitAncestor(
                        $headerTotalAmount, 
                        $billHeader, 
                        totalAmountFontSize
                    );
                }
            };

            observer = new MutationObserver(visibilityHandler);
            observer.observe($formStep, config);
        };

        // Initial site setup
        var initializeBills = function () {
            var $allBills = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':not(:disabled)');

            $selectBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            $downloadBillsButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            $formButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            disableSubmitButtons();
            isClickLoop = true;

            coned.utils.processLargeData(
                $allBills,
                // Handler
                function ($bill) {
                    if ($bill.checked) {
                        totalAmountGlobal += parseFloat($bill.dataset.amount.replace(/,/g, ''));
                        totalBillsGlobal++;
                    } else {
                        $bill.click();
                    }
                },
                // On complete, enable buttons again
                function () {
                    if (totalAmountGlobal) {
                        $selectBillsButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                        $downloadBillsButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                        $formButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                        enableSubmitButtons();
                    }

                    updateTotalAmount();
                    updateCheckAll();

                    isClickLoop = false;

                    // get amount of selected receipts, if is more than one, we should hide credit card option and last account option
                    // if is one we should get the last account number from the service and replace it
                    var $receiptCheckboxes = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':checked:not(:disabled)');

                    if ($receiptCheckboxes.length > 1 || $receiptCheckboxes.length === 0) {
                        $creditCardOptionSelector.remove();
                        $lastAccountOptionSelector.remove();
                    } else if ($receiptCheckboxes.length === 1) {
                        // Service Data
                        var params = {
                            Maid: $receiptCheckboxes[0].dataset.maid,
                            ScId: $paymentForm.dataset.scid
                        };

                        // Service Call
                        query.getData(_lastUsedBankAccount, successLastBankAccountService, errorLastBankAccountService, params, $formLoading);
                    }
                    $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
                }
            );
        };

        // General
        var initializeData = function () {
            $formValidationSelector = document.getElementsByClassName(CONSTANTS.VALIDATION_SELECTOR)[0];
            $checkAllCheckbox = document.getElementsByClassName(CONSTANTS.CHECKALL_CHECKBOX)[0];
            $receiptLabels = document.getElementsByClassName(CONSTANTS.RECEIPT_LABEL);
            $finishHiddenElements = document.getElementsByClassName(CONSTANTS.FINISH_HIDDEN_ELEMENT);
            $creditCardPaymentSelectors = $multipleForm.getElementsByClassName(CONSTANTS.CREDIT_CARD_PAYMENT_SELECTOR);
            $totalCurrency = $multipleForm.getElementsByClassName(CONSTANTS.TOTAL_AMOUNT_INPUT)[0];
            $otherAmountContainer = $multipleForm.getElementsByClassName(CONSTANTS.OTHER_AMOUNT_CONTAINER)[0];
            $otherAmountCheckbox = $multipleForm.getElementsByClassName(CONSTANTS.OTHER_AMOUNT_CHECKBOX)[0];
            $submitAnimationSelector = $multipleForm.getElementsByClassName(CONSTANTS.SUBMIT_ANIMATION_CONTAINER)[0];
            $dynamicInputs = $multipleForm.getElementsByClassName(CONSTANTS.INPUT_DYNAMIC_INPUT);
            $energyShareSelector = $multipleForm.getElementsByClassName(CONSTANTS.ENERGY_SHARE_SELECTOR)[0];
            $rowEnergyShare = $multipleForm.getElementsByClassName(CONSTANTS.ROW_ENERGY_SHARE)[0];
            $rowEnergyShareText = $rowEnergyShare.getElementsByClassName(CONSTANTS.ROW_TEXT_CLASS)[0];
            $rowServiceFee = $multipleForm.getElementsByClassName(CONSTANTS.ROW_SERVICE_FEE)[0];
            $energyAmountSelector = $multipleForm.getElementsByClassName(CONSTANTS.ENERGY_AMOUNT_SELECTOR)[0];
            $paymentMethodSelector = $multipleForm.getElementsByClassName(CONSTANTS.PAYMENT_METHOD_DROPDOWN)[0];
            $paymentMethodTooltip = $multipleForm.getElementsByClassName(CONSTANTS.PAYMENT_METHOD_TOOLTIP)[0];
            $totalAmountSelector = $multipleForm.getElementsByClassName(CONSTANTS.TOTAL_AMOUNT_SELECTOR);
            $amountSelectors = $multipleForm.getElementsByClassName(CONSTANTS.AMOUNT_SELECTOR);
            $creditCardMessageResidential = $multipleForm.getElementsByClassName(CONSTANTS.CREDIT_CARD_MESSAGE_RESIDENTIAL);
            $creditCardMessageCommercial = $multipleForm.getElementsByClassName(CONSTANTS.CREDIT_CARD_MESSAGE_COMMERCIAL);
            $serviceFeeSelector = $multipleForm.getElementsByClassName(CONSTANTS.SERVICE_FEE_SELECTOR)[0];
            $minimumPaymentAlert = $multipleForm.getElementsByClassName(CONSTANTS.MINIMUM_PAYMENT_ALERT)[0];
            $paymentAmountSelector = $multipleForm.getElementsByClassName(CONSTANTS.PAYMENT_AMOUNT_SELECTOR)[0];
            $energyShareModule = $multipleForm.getElementsByClassName(CONSTANTS.ENERGY_SHARE_MODULE)[0];
            $energyShareOtherStateText = $multipleForm.getElementsByClassName(CONSTANTS.ENERGY_SHARE_OTHER_STATE_TEXT)[0];
            $energyShareNewJerseyText = $multipleForm.getElementsByClassName(CONSTANTS.ENERGY_SHARE_NEW_JERSEY_TEXT)[0];
            $energyShareCheckboxText = $energyShareModule.getElementsByClassName(CONSTANTS.ENERGY_SHARE_CHECKBOX_TEXT)[0];
            $dppEnrollContainer = $multipleForm.getElementsByClassName(CONSTANTS.DPP_ENROLL_CONTAINER_SELECTOR)[0];
            $dppEnrollCheckbox = $multipleForm.getElementsByClassName(CONSTANTS.DPP_ENROLL_SELECTOR)[0];
            $creditCardOptionSelector = $multipleForm.getElementsByClassName(CONSTANTS.CREDIT_CARD_OPTION_SELECTOR)[0];
            $lastAccountOptionSelector = $multipleForm.getElementsByClassName(CONSTANTS.LAST_ACCOUNT_OPTION_SELECTOR)[0];
            $datePickerSelector = $multipleForm.getElementsByClassName(CONSTANTS.DATE_PICKER_SELECTOR)[0];
            $creditCardSelector = $multipleForm.getElementsByClassName(CONSTANTS.CREDIT_CARD_SELECTOR)[0];
            $billPaymentError = $multipleForm.getElementsByClassName(CONSTANTS.BILL_PAYMENT_ERROR)[0];
            $inputDateSelector = $multipleForm.getElementsByClassName(CONSTANTS.INPUT_DATE_SELECTOR)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $proceedPaymentButtons = $multipleForm.getElementsByClassName(CONSTANTS.PROCEED_PAYMENT_BUTTON_CLASS);
            $clearAllCta = $multipleForm.getElementsByClassName(CONSTANTS.CLEAR_ALL_CTA_CLASS)[0];
            $billsContainer = $multipleForm.getElementsByClassName(CONSTANTS.BILLS_CONTAINER_CLASS)[0];
            $selectBillsButton = $multipleForm.getElementsByClassName(CONSTANTS.SELECT_BILLS_BUTTON_CLASS)[0];
            $downloadBillsButton = $multipleForm.getElementsByClassName(CONSTANTS.DOWNLOAD_BILLS_BUTTON_CLASS)[0];
            $formStep = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_FORM_STEP)[0];
            $billsStep = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_BILLS_STEP)[0];
            $statusStep = $multipleForm.getElementsByClassName(CONSTANTS.STEP_PAYMENT)[0];
            $billsTotalNumber = $multipleForm.getElementsByClassName(CONSTANTS.BILLS_NUMBER_CLASS);
            $headerTotalAmount = $multipleForm.getElementsByClassName(CONSTANTS.HEADER_TOTAL_AMOUNT_CLASS)[0];
            $otherAmount = $multipleForm.getElementsByClassName(CONSTANTS.OHTER_AMOUNT_CLASS)[0];
            $mainTotalAmount = $multipleForm.getElementsByClassName(CONSTANTS.MAIN_TOTAL_AMOUNT)[0];
            $statusSuccessNumber = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_SUCCESS_NUMBER)[0];
            $statusSuccessList = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_SUCCESS_LIST)[0];
            $statusSuccessMore = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_SUCCESS_MORE)[0];
            $statusFailedNumber = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_FAILED_NUMBER)[0];
            $statusFailedList = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_FAILED_LIST)[0];
            $statusFailedMore = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_FAILED_MORE)[0];
            $statusBanner = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_SUCCESS_BANNER)[0];
            $statusSuccessTitle = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_SUCCESS_TITLE)[0];
            $billHeader = $multipleForm.getElementsByClassName(CONSTANTS.BILL_HEADER_CLASS)[0];
            $billHeaderServiceErrorMessage = $billHeader.getElementsByClassName(CONSTANTS.SERVICE_ERROR_MESSAGE)[0];
            $billHeaderServiceError = $billHeader.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $creditCardMessageTitle = $multipleForm.getElementsByClassName(CONSTANTS.CREADIT_CARD_MESSAGE_TITLE_CLASS)[0];
            $creditCardMessageDescription = $multipleForm.getElementsByClassName(CONSTANTS.CREADIT_CARD_MESSAGE_DESCRIPTION_CLASS)[0];
            $headerMessageBill = $multipleForm.getElementsByClassName(CONSTANTS.HEADER_MESSAGE_BILL)[0];
            $headerMessageBillAll = $multipleForm.getElementsByClassName(CONSTANTS.HEADER_MESSAGE_BILL_ALL)[0];
            $headerMessageBillSome = $multipleForm.getElementsByClassName(CONSTANTS.HEADER_MESSAGE_BILL_SOME)[0];
            $headerMessageBillOne = $multipleForm.getElementsByClassName(CONSTANTS.HEADER_MESSAGE_BILL_ONE)[0];
            $animationStep = $multipleForm.getElementsByClassName(CONSTANTS.ANIMATION_STEP_CLASS)[0];
            $confirmCancelButton = $multipleForm.getElementsByClassName(CONSTANTS.CONFIRM_CANCEL_BUTTON)[0];
            $partialPaymentWarning = $multipleForm.querySelector(CONSTANTS.PARTIAL_PAYMENT_WARNING_MSG);

            $batchBanner = $multipleForm.getElementsByClassName(CONSTANTS.BATCH_BANNER_CONTAINER)[0];
            // $noBatchBanner = $multipleForm.getElementsByClassName(CONSTANTS.NO_BATCH_BANNER_CONTAINER)[0];
            $batchProcessContainer = $multipleForm.getElementsByClassName(CONSTANTS.BATCH_PROCESS_CONTAINER)[0];
            $batchResultsContainer = $multipleForm.getElementsByClassName(CONSTANTS.BATCH_RESULTS_CONTAINER)[0];
            $batchBannerTitle = $multipleForm.getElementsByClassName(CONSTANTS.BATCH_BANNER_TITLE)[0];
            $batchBannerDescription = $multipleForm.getElementsByClassName(CONSTANTS.BATCH_BANNER_DESCRIPTION)[0];
            $batchMyAccountBtn= $batchResultsContainer.getElementsByClassName(CONSTANTS.MY_ACCOUNT_BTN)[0];

            $submitAnimation = $animationStep.getElementsByClassName(CONSTANTS.SUBMIT_ANIMATION_CONTAINER)[0];
            $animationTotalUnits = $animationStep.getElementsByClassName(CONSTANTS.ANIMATION_TOTAL_CLASS)[0];
            $animationCurrentUnits = $animationStep.getElementsByClassName(CONSTANTS.ANIMATION_CURRENT_CLASS)[0];
            $animationBill = $animationStep.getElementsByClassName(CONSTANTS.ANIMATION_BILL_CLASS)[0];
            $statusSuccessBill = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_SUCCESS_BILL)[0];
            $statusFailedBill = $multipleForm.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_FAILED_BILL)[0];
            $statusBatchSuccessBill = $batchResultsContainer.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_SUCCESS_BILL)[0];
            $statusBatchFailedBill = $batchResultsContainer.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_FAILED_BILL)[0];
            $statusSuccessBatchedNumber = $batchResultsContainer.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_SUCCESS_NUMBER)[0];
            $statusFailBatchedNumber = $batchResultsContainer.getElementsByClassName(CONSTANTS.MULTIPLE_PAYMENT_STATUS_FAILED_NUMBER)[0];
            $formButton = $multipleForm.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $formButtonText = $multipleForm.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON_TEXT)[0];
            $paymentForm = $multipleForm.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            $cancelPopup = $multipleForm.getElementsByClassName(CONSTANTS.CANCEL_POPUP)[0];

            $successPicture = $multipleForm.getElementsByClassName(CONSTANTS.SUCCESS_PICTURE_SELECTOR)[0];
            $failPicture = $multipleForm.getElementsByClassName(CONSTANTS.FAIL_PICTURE_SELECTOR)[0];
            $showMore = $multipleForm.getElementsByClassName(CONSTANTS.SHOW_MORE_CLASS);
            $notPayableByCheckTab = $multipleForm.getElementsByClassName(CONSTANTS.TAB_NOT_PAYABLE_CHECK_CONTAINER)[0];
            $numberInputs = $creditCardSelector.getElementsByClassName(CONSTANTS.NUMBER_INPUT_SELECTOR);
            $paymentStatusMessage = $multipleForm.getElementsByClassName(CONSTANTS.PAYMENT_STATUS_MESSAGE);
            $paymentError = $multipleForm.getElementsByClassName(CONSTANTS.PAYMENT_ERROR)[0];
            _inputTemplateHTML = $otherAmountContainer.innerHTML;
            _batchSize = $multipleForm.dataset.batchSize ? parseInt($multipleForm.dataset.batchSize) : null; // it should be 50 but in FE is 5 you can also use 10 for testing
            _isBatched = false;
            _billsList = [];
            _first = true;
            _billsListCounter = 0;
            _billsListPercentage = 0;
            _billsListPercentageCounter = 0;
            _totalAccount = 0;
            _startAccountNumber = 0;
            _currentBatchIndex = 0;
            _lastUsedBankAccount = $billsStep.dataset.lastUsedBankAccountService;
            totalBillsGlobal = 0;
            totalAmountGlobal = 0;
            isClickLoop = false;
            _billsListPercentageNoFloor = 0;
            _billsListPercentageCounterNoFloor = 0;
            _totalbillsBatchList = [];
            _isMoreThanHundred = false;
            _totalFailAccounts = 0;
            _totalSuccessAccounts = 0;
            _amountFailAccounts = 0;
            _animationUnitsToSend = $batchBanner.dataset.unitsToSend;
            _isSummaryBillingProgram = false;
        };

        var initializeEvents = function () {
            //STEP 1
            // Set the date picker dates limit
            setTimeout(function () {
                $($inputDateSelector).datepicker('option', 'minDate', '0');
                $($inputDateSelector).datepicker('option', 'maxDate', '5d');
            }, 1);

            $inputDateSelector.addEventListener('change', validateDate);

            // Submit handler
            new coned.components.ValidateForm(
                '.' + CONSTANTS.FORM_SELECTOR, 
                payMyBillMultipleSubmit, 
                CONSTANTS.DEFAULT_VALIDATOR_HIDDEN_SELECTOR,
                true
            );

            //Check the form on change
            coned.utils.addMultipleListeners($paymentForm, ['change', 'keyup'], function () {
                coned.components.ValidateForm.isFormValid($paymentForm, '', CONSTANTS.FORM_IGNORE_VALIDATION);
                if ($minimumPaymentAlert && !coned.utils.isOru()) {
                    checkMinimumAmount();
                } else if (coned.utils.isOru()) {
                    setSubmitButtonState();
                }   
            });
            
            coned.utils.addGeneralListeners($selectBillsButton, onSelectBillsButtonClick);
            coned.utils.addGeneralListeners($downloadBillsButton, onDownloadBillsButtonClick);

            // STEP 2
            // Checkbox related events
            coned.utils.addGeneralListeners($clearAllCta, onClickClearList);
            $checkAllCheckbox.addEventListener(CONSTANTS.CHANGE_EVENT, checkAllEvent);
            coned.utils.addParentListener($billsContainer, [CONSTANTS.CHANGE_EVENT], CONSTANTS.RECEIPT_LABEL_BOX, onCheckReceipt);

            $paymentMethodSelector.addEventListener(CONSTANTS.CHANGE_EVENT, paymentMethodChange);
            $otherAmountCheckbox.addEventListener(CONSTANTS.CHANGE_EVENT, showOtherInputs);
            $energyShareSelector.addEventListener(CONSTANTS.CHANGE_EVENT, donateChange);

            // Click pn proceed buttons
            coned.utils.addParentListener($billsStep, coned.utils.generalEvents(), CONSTANTS.PROCEED_PAYMENT_BUTTON_CLASS, clickOnProceedPaymentStep);

            // Click on links
            coned.utils.addParentListener($billsContainer, coned.utils.generalEvents(), CONSTANTS.BILL_LINK_CLASS, billLinkEvent);

            $($inputDateSelector).on('change', function () {
                coned.components.ValidateForm.isFormValid($paymentForm, '', CONSTANTS.FORM_IGNORE_VALIDATION);
                if ($minimumPaymentAlert && !coned.utils.isOru()) {
                    checkMinimumAmount();
                } else if (coned.utils.isOru()) {
                    setSubmitButtonState();
                }   
            });

            // Status page events
            statusStepEvents();

            Array.prototype.forEach.call($showMore, function ($button) {
                coned.utils.addGeneralListeners($button, onShowMoreSelector);
            });

            // Check for available bills
            var $allLabels = $billsStep.querySelectorAll('.' + CONSTANTS.RECEIPT_LABEL_BOX + ':not(:disabled)');

            if ($allLabels.length === 0) {
                $formButton.disabled = true;
                $selectBillsButton.disabled = true;
            }

            // Event for the popup confirm button
            coned.utils.addGeneralListeners($confirmCancelButton, cancelBatch);

            // Bill amount font-size dynamic resizing for big amounts ie: 6+ digits with cents
            if ($billHeader && $headerTotalAmount) {

                // Visibility observer for amount font-size
                amountVisibleObserver();

                // Change observer for amount font-size
                amountChangeObserver();

                // Resize event for amount font-size
                coned.utils.updateFontResizeThrottler(
                    coned.utils.updateFontToFitAncestor,
                    $headerTotalAmount,
                    $billHeader
                );
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            initializeBills();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    MultiplePayMyBill.prototype.isLoaded = function () {
        return isLoaded;
    };

    return MultiplePayMyBill;
})();
