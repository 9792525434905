// ==================== LOGIN FORM COMPONENT =========================
/* global gsap */
/* global dataLayer */
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoginFormComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Dropdown related constants
        OPEN_BUTTON_SELECTOR: 'js-nav-login',
        OPEN_BUTTON_MOBILE_SELECTOR: 'js-action-login',
        OPEN_MOBILE_SELECTOR: 'js-mobile-login-open',
        CLOSE_BUTTON_SELECTOR: 'js-close-login',
        LOGIN_ANIMATION_SELECTOR: 'js-login-animator',
        ANIMATOR_CLASS: 'login--animator',
        LOGIN_ACTIVE_CLASS: 'login--active',
        LOGIN_DROPDOWN: '.js-login-dropdown',
        LOGIN_DROPDOWN_SELECTOR: 'js-login-dropdown-selector',
        LOGIN_DROPDOWN_NEW_DEVICE_FORM: '.js-login-dropdown-new-device-form',
        LOGIN_DROPDOWN_NEW_DEVICE_FORM_SELECTOR: 'js-login-dropdown-new-device-form-selector',
        DROPDOWN_LOGIN_CONTAINER: 'js-dropdown-login-container',
        LOGIN_BUTTON_SELECTOR: 'js-login-button-selector',
        ARROW_LOGIN: 'js-arrow-login-in',
        ARROW_MODIFIER: 'primary-nav-item__arrow--active',
        LOGIN_DROPDOWN_HEADER_SELECTOR: 'js-login-dropdown-header-selector',
        HIDDEN_DESKTOP_CLASS: 'login__header-container--hidden-desktop',
        ACTIVE_LOGIN: 'login--active',
        SUBMIT_CONTAINER_REVERSED: 'submit-button__container--bg-container-reversed',
        SUBMIT_BUTTON_CONTAINER_SELECTOR: 'js-submit-container',
        DROPDOWN_VALUE: 'dropdown',
        NAV_SEARCH_SELECTOR: 'js-nav-search',
        LOGIN_LIST_CONTAINER: 'login__list--dropdown',

        // Form related constants
        LOGIN_FORM: '.js-login-form',
        LOGIN_FORM_SELECTOR: 'js-login-form-selector',
        LOGIN_NEW_DEVICE_FORM: '.js-login-new-device-form',
        LOGIN_NEW_DEVICE_FORM_SELECTOR: 'js-login-new-device-form-selector',
        LOGIN_INPUT_EMAIL: 'LoginEmail',
        LOGIN_INPUT_PASSWORD: 'LoginPassword',
        LOGIN_INPUT_REMEMBER_ME: 'LoginRememberMe',
        LOGIN_INPUT_MFA: 'LoginMFACode',

        // Shared constants
        SUBMIT_BUTTON: 'js-login-submit-button',
        NEW_DEVICE_SUBMIT_BUTTON: 'js-device-submit-button',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        HIDDEN_CLASS: 'hidden',
        FORM_IGNORE_VALIDATION: ':hidden',
        INPUT_TEXT_SELECTOR: 'js-coned-input',
        INPUT_NUMBER: 'js-number-input',
        INPUT_EMAIL: 'js-email-input',
        INPUT_PASSWORD: 'js-password-input',
        INPUT_DEVICE_CODE: 'js-device-code-input',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        SHOW_PASSWORD_BUTTON: 'js-show-password',
        NEW_DEVICE_ERROR: 'js-login-new-device-error-msg',
        LOGIN_FORM_ERROR: 'js-login-error-msg',
        NEW_DEVICE_INTRO_MSG: 'js-new-device-intro',
        NEW_DEVICE_INPUT_LABEL: 'js-device-code-label',
        CHECKBOX_LABEL: 'coned-checkbox__label',
        CHECKBOX_LABEL_CHECKED: 'coned-checkbox--checked',
        REMEMBER_ME_COOKIE_NAME: 'rememberme',
        RETURN_URL_PARAMETER_NAME: 'returnUrl',
        FROM_URI_PARAMETER_NAME: 'fromURI',
        RELAY_STATE_PARAMETER_NAME: 'RelayState',
        HIDDEN: 'hidden',

        // A11y
        ARIA_EXPANDED: 'aria-expanded',
        ARIA_MODAL: 'aria-modal',
        ARIA_LABEL: 'aria-label',
        ROLE: 'role',
        DIALOG: 'dialog',

        // Tagging
        LOGIN_SUCCESS_TAG: 'Login.Success',

        // Form Loading
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LoginFormComponent = function ($login) {
        /**
         * PRIVATE METHODS
         */
        var $openButtonMobileSelector,
            $openMobileSelector,
            $closeButtonSelector,
            $loginAnimationSelector,
            $loginDropdownSelector,
            $loginDropdownNewDeviceSelector,
            $DropdownShowPasswordButton,
            $DropdownInputPassword,
            $DropdownInputEmail,
            $DropdownInputDeviceCode,
            $DropdownSubmitButton,
            $DropdownNewDeviceSubmitButton,
            $DropdownNewDeviceError,
            $DropdownLoginError,
            $DropdownNewDeviceIntroMsg,
            $DropdownNewDeviceInputLabel,
            $loginFormSelector,
            $loginNewDeviceSelector,
            $FormShowPasswordButton,
            $FormInputPassword,
            $FormInputEmail,
            $FormInputDeviceCode,
            $FormSubmitButtons,
            $FormNewDeviceSubmitButton,
            $FormNewDeviceError,
            $FormLoginError,
            $FormNewDeviceIntroMsg,
            $FormNewDeviceInputLabel,
            $loginUrl,
            $loginNewDeviceUrl,
            $submitAnimationButton,
            $dropdownLoginContainer,
            $loginButton,
            $arrowLogin,
            $loginDropdownHeader,
            $SubmitButtonContainers,
            $redirectUrl,
            $navSearchButton,
            $jsNavLogin,
            $loginListLast,
            $formLoading,
            _loginType,
            _verifiedMFA,
            index;

        /**
        * Global login tagging event for all cases
        */
        var addGlobalLoginTagging = function () {
            dataLayer.push({
                event: CONSTANTS.LOGIN_SUCCESS_TAG
            });
        };

        var animateMenuItems = function ($item, animationTime, isOpen) {
            if (isOpen) {
                setTimeout(function () {
                    $item.classList.remove(CONSTANTS.ANIMATOR_CLASS);
                }, animationTime);
            } else {
                setTimeout(function () {
                    $item.classList.add(CONSTANTS.ANIMATOR_CLASS);
                }, animationTime);
            }
        };

        var openLoginModal = function (event) {
            event.preventDefault();

            // set modal height based on the page height and the border to be added
            var modalHeight = window.innerHeight, // the border height
                timer = 100,
                animationTime = 400;

            gsap.to($login, {
                duration: 0.4,
                height: modalHeight
            });

            for (index = 0; index < $loginAnimationSelector.length; index++) {
                animateMenuItems($loginAnimationSelector[index], timer, true);
                timer += animationTime;
            }

            $dropdownLoginContainer.classList.add(CONSTANTS.LOGIN_ACTIVE_CLASS);

            // A11y Handlers
            $jsNavLogin.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            setTimeout(function () {
                $DropdownInputEmail.focus();
            }, 500);

            event.target.focusBack = true;
        };

        var closeLoginModal = function () {
            gsap.to($login, {
                duration: 0.3,
                height: 0,
                borderBottom: 0
            });

            // Hide arrow in header if the login is closed
            if (!$arrowLogin.classList.contains(CONSTANTS.HIDDEN)) {
                $arrowLogin.classList.add(CONSTANTS.HIDDEN);
            }

            for (index = 0; index < $loginAnimationSelector.length; index++) {
                $loginAnimationSelector[index].classList.add(CONSTANTS.ANIMATOR_CLASS);
            }

            $dropdownLoginContainer.classList.remove(CONSTANTS.LOGIN_ACTIVE_CLASS);

            // A11y Handlers
            $jsNavLogin.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
            setTimeout(function () {
                if ($openButtonMobileSelector && $openButtonMobileSelector.focusBack) {
                    $openButtonMobileSelector.focus();
                    $openButtonMobileSelector.focusBack = false;
                } else if ($openMobileSelector && $openMobileSelector.focusBack) {
                    $openMobileSelector.focus();
                    $openMobileSelector.focusBack = false;
                }
            }, 500);
        };

        var setBtnModalExpanded = function (isOpened) {
            if (isOpened) {
                $jsNavLogin.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            } else {
                $jsNavLogin.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
            }
        };

        var setModalRole = function (isMobile) {
            if (isMobile) {
                $dropdownLoginContainer.setAttribute(CONSTANTS.ROLE, CONSTANTS.DIALOG);
                $dropdownLoginContainer.setAttribute(CONSTANTS.ARIA_MODAL, true);
            } else {
                $dropdownLoginContainer.removeAttribute(CONSTANTS.ROLE);
                $dropdownLoginContainer.removeAttribute(CONSTANTS.ARIA_MODAL);
            }
        };

        var resizeModal = function () {
            var isMobile = coned.utils.isMobile();
            var isLoginOpened = $login.style.height !== '' && $login.style.height !== '0px';

            $dropdownLoginContainer && setModalRole(isMobile);
            setBtnModalExpanded(isLoginOpened);

            if (isLoginOpened) {
                var currentHeight = window.innerHeight - 3;

                // take all the screen if is mobile, if not use modal height
                if (isMobile) {
                    gsap.to($login, {
                        duration: 0.1,
                        height: currentHeight
                    });
                } else {
                    // setting height of modal depending on which form is showing
                    if ($loginDropdownSelector.classList.contains(CONSTANTS.HIDDEN_CLASS)) {
                        currentHeight =
                            $loginDropdownNewDeviceSelector.offsetHeight +
                            $loginDropdownHeader.offsetHeight;
                    } else {
                        currentHeight =
                            $loginDropdownSelector.offsetHeight + $loginDropdownHeader.offsetHeight;
                    }

                    gsap.to($login, {
                        duration: 0.1,
                        height: currentHeight
                    });
                }
            }
        };

        var togglePassword = function (event) {
            event.preventDefault();

            var inputField;

            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                inputField = $DropdownInputPassword;
            } else {
                inputField = $FormInputPassword;
            }

            var inputType = inputField.type;
            var buttonText, buttonAriaText;

            if (inputType == 'password') {
                inputType = 'text';
                buttonText = this.dataset.replaceText;
                buttonAriaText = this.dataset.ariaReplaceText;
            } else {
                inputType = 'password';
                buttonText = this.dataset.text;
                buttonAriaText = this.dataset.ariaText;
            }

            modifyPasswordElemAttributes(
                event.target,
                inputField,
                inputType,
                buttonText,
                buttonAriaText
            );

            // A11y Handler
            if (_loginType === CONSTANTS.DROPDOWN_VALUE) {
                $DropdownInputPassword.focus();
            }
        };

        var modifyPasswordElemAttributes = function (
            button,
            inputField,
            inputType,
            buttonText,
            buttonAriaText
        ) {
            var buttonSpan = button.firstElementChild;
            inputField.type = inputType;
            buttonSpan.innerHTML = buttonText;
            button.setAttribute(CONSTANTS.ARIA_LABEL, buttonAriaText);
        };

        var enableButton = function () {
            var isFormValid = coned.components.ValidateForm.isFormValid(
                $login,
                '',
                CONSTANTS.FORM_IGNORE_VALIDATION,
                true
            );
            
            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                if (!isFormValid) {
                    $DropdownSubmitButton.disabled = true;
                } else {
                    $DropdownSubmitButton.disabled = false;
                }

                if ($DropdownInputDeviceCode.value.length === 0) {
                    $DropdownNewDeviceSubmitButton.disabled = true;
                } else {
                    $DropdownNewDeviceSubmitButton.disabled = false;
                }
            } else {
                if (!isFormValid) {
                    for (var i = 0; i < $FormSubmitButtons.length; i++) {
                        $FormSubmitButtons[i].disabled = true;
                    }
                } else {
                    for (i = 0; i < $FormSubmitButtons.length; i++) {
                        $FormSubmitButtons[i].disabled = false;
                    }
                }

                if ($FormInputDeviceCode.value.length === 0) {
                    $FormNewDeviceSubmitButton.disabled = true;
                } else {
                    $FormNewDeviceSubmitButton.disabled = false;
                }
            }
        };

        var displayErrorMessage = function ($errorMsg, errorMsg) {
            $errorMsg.innerHTML = errorMsg ? errorMsg : coned.constants.ERROR_MESSAGE;
            $errorMsg.parentElement.style.display = 'block';
            $errorMsg.parentElement.focus();
        };

        var hideErrorMessage = function ($errorMsg) {
            $errorMsg.innerHTML = '';
            $errorMsg.parentElement.style.display = 'none';
            $errorMsg.parentElement.focus();
        };

        var checkLogin = function (formSelector, data, $errorMsg) {
            var $newDeviceForm, $newDeviceIntroMsg, $newDeviceInput, $newDeviceInputLabel;

            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                $newDeviceForm = $loginDropdownNewDeviceSelector;
                $newDeviceIntroMsg = $DropdownNewDeviceIntroMsg;
                $newDeviceInput = $DropdownInputDeviceCode;
                $newDeviceInputLabel = $DropdownNewDeviceInputLabel;
            } else {
                $newDeviceForm = $loginNewDeviceSelector;
                $newDeviceInput = $FormInputDeviceCode;
                $newDeviceIntroMsg = $FormNewDeviceIntroMsg;
                $newDeviceInputLabel = $FormNewDeviceInputLabel;
            }

            if (data.login) {
                // login was successful
                // Add Set MFA page redirect
                if (data.noMfa) {
                    window.location.href = data.noMfaRedirectUrl;
                    return;
                }

                if (data.newDevice) {
                    // New device case scenario
                    window.scrollTo(0, 0);

                    // Only if loginType is dropdown should add timeout to restart dropdown and show dropdown header
                    if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                        // show the header
                        $loginDropdownHeader.classList.remove(CONSTANTS.HIDDEN_DESKTOP_CLASS);

                        setTimeout(function () {
                            // flag to check if the MFA was verified, if not restart form for desktop
                            // _verifiedMFA is only initialized in modal initializer
                            if (_verifiedMFA === 'false') {
                                if (!coned.utils.isMobile()) {
                                    restartLoginDropdown();
                                    hideLoginDropdown();
                                }
                            }
                        }, data.waitingTime);
                    }

                    // Stop animation manually, even though this won't be seen with the 'page' change
                    $submitAnimationButton.classList.add(
                        coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                    );

                    // Set innerTexts from service response
                    $newDeviceIntroMsg.innerText = data.newDeviceText;
                    $newDeviceInputLabel.innerText = data.newDeviceInputLabel;

                    // Switch input validation depending on the type of answer to enter
                    if (data.isNumeric) {
                        // If code is going to be added, number validation should be added
                        $newDeviceInput.classList.add(CONSTANTS.INPUT_NUMBER);
                        $newDeviceInput.type = 'tel';
                        $newDeviceInput.pattern = '[0-9]*';
                    } else {
                        // If not, it should be just text
                        $newDeviceInput.classList.remove(CONSTANTS.INPUT_NUMBER);
                        $newDeviceInput.type = 'text';
                        $newDeviceInput.patttern = '';
                    }

                    // Re-instance form validation module for it to change its text or numeric validation
                    new coned.components.FormValidationModule($newDeviceForm);

                    // Display new device verification form
                    formSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $newDeviceForm.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    setTimeout(function () {
                        $DropdownInputDeviceCode && $DropdownInputDeviceCode.focus();
                        $loginDropdownNewDeviceSelector &&
                            new coned.utils.addFocusTrap(
                                $loginDropdownNewDeviceSelector,
                                true,
                                false,
                                $closeButtonSelector[0]
                            );
                    }, 500);
                } else {
                    // Device is not new
                    // Analytics data building

                    if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                        dataLayer.push({
                            event: 'Nav.Login.Success'
                        });
                    } else {
                        dataLayer.push({
                            isLoggedIn: 'true'
                        });
                    }

                    addGlobalLoginTagging();

                    window.location.href = data.authRedirectUrl;
                }
            } else {
                // Login was unsucessful
                // Stop animation manually, since now the animation is not stopped by the success scenario
                $submitAnimationButton.classList.add(
                    coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                );

                setTimeout(function () {
                    displayErrorMessage($errorMsg, data.loginErrorMsg);
                }, 1500);
            }
        };

        var clearLoginFields = function () {
            var $loginIdInputs = document.getElementsByClassName(CONSTANTS.INPUT_EMAIL),
                $passwordInputs = document.getElementsByClassName(CONSTANTS.INPUT_PASSWORD);

            _.each($loginIdInputs, function ($inputLogin) {
                $inputLogin.value = '';
                $inputLogin.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
            });

            _.each($passwordInputs, function ($inputPassword) {
                $inputPassword.value = '';
                $inputPassword.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
            });

            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                $DropdownInputEmail.focus();
            } else {
                $FormInputEmail.focus();
            }
        };

        var clearMFAFields = function () {
            $DropdownInputDeviceCode.value = '';
            $DropdownInputDeviceCode.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
            hideErrorMessage($DropdownNewDeviceError);
        };

        var checkDeviceCode = function (data, $errorMsg) {
            if (coned.utils.isPatternLab()) {
                if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                    if ($DropdownInputDeviceCode.value === 'none') {
                        window.location.href = data.authRedirectUrl;
                    }
                }
            }
            if (data.code) {
                // flag to check if the MFA was verified
                _verifiedMFA = true;

                // Analytics data building
                if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                    dataLayer.push({
                        'Nav.Login.Success': 'true'
                    });
                } else {
                    dataLayer.push({
                        isLoggedIn: 'true'
                    });
                }

                addGlobalLoginTagging();

                window.location.href = data.authRedirectUrl;
            } else {
                // Stop animation manually, since now the animation is not stopped by the success scenario
                $submitAnimationButton.classList.add(
                    coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                );

                setTimeout(function () {
                    displayErrorMessage($errorMsg, data.codeErrorMsg);
                }, 1500);
            }
        };

        //Make the submit login a public class, this function will be use on registration
        var submitLoginHandler = function () {
            var $formSelector,
                $errorMsg,
                params,
                loginEmail = query.getFormInputValue($login, CONSTANTS.LOGIN_INPUT_EMAIL).trim(),
                rememberMe = query.getFormInputValue($login, CONSTANTS.LOGIN_INPUT_REMEMBER_ME),
                returnUrl = coned.utils.getUrlParameterValue(CONSTANTS.RETURN_URL_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.RETURN_URL_PARAMETER_NAME)
                    : '',
                fromUri = coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    : '',
                relayState = coned.utils.getUrlParameterValue(CONSTANTS.RELAY_STATE_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.RELAY_STATE_PARAMETER_NAME)
                    : '',
                actualUrl = encodeURIComponent(window.location.pathname + window.location.search),
                _data;

            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                $formSelector = $loginDropdownSelector;
                $errorMsg = $DropdownLoginError;
            } else {
                $formSelector = $loginFormSelector;
                $errorMsg = $FormLoginError;
            }

            // Verify which value to send as the returnUrl
            if (returnUrl === '') {
                returnUrl = fromUri;
            }

            // Only if loginType is dropdown should check if should add actualUrl
            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                if (returnUrl === '' && $redirectUrl === '') {
                    returnUrl = actualUrl;
                } else {
                    returnUrl = $redirectUrl;
                }
            }

            // Remember Me cookie handling
            if (rememberMe) {
                var expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
                query.setCookie(CONSTANTS.REMEMBER_ME_COOKIE_NAME, loginEmail, expiryDate);
            } else {
                query.deleteCookie(CONSTANTS.REMEMBER_ME_COOKIE_NAME);
            }

            // Delete login cookie before request
            var req = new XMLHttpRequest();

            req.open('DELETE', $login.dataset.logoutUrl);
            req.withCredentials = true;
            req.setRequestHeader('Accept', 'application/json');
            req.send(null);

            // Service Data
            params = {
                LoginEmail: loginEmail,
                LoginPassword: query.getFormInputValue($login, CONSTANTS.LOGIN_INPUT_PASSWORD),
                LoginRememberMe: rememberMe,
                ReturnUrl: returnUrl,
                FromURI: fromUri,
                OpenIdRelayState: relayState
            };
            params = JSON.stringify(params);

            hideErrorMessage($errorMsg);
            $submitAnimationButton = $formSelector.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];

            // Service Call
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                loginCall,
                function () { },
                errorPostLoginDataCallback,
                false,
                true
            );

            function loginCall() {
                query.postData($loginUrl, successLoginCallback, errorLoginCallback, params, true);
            }

            function successPostLoginDataCallback() {
                if (coned.utils.isPatternLab()) {
                    var serviceUrl;

                    if (coned.utils.isOru()) {
                        serviceUrl = coned.plConstants.GET_LOGIN_ORU;
                    } else if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                        // Show text in modal
                        serviceUrl = coned.plConstants.GET_LOGIN_TEXT;
                    } else {
                        serviceUrl = coned.plConstants.GET_LOGIN;
                    }

                    query.getData(
                        serviceUrl,
                        function (data) {
                            checkLogin($formSelector, data, $errorMsg);
                        },
                        function () {
                            displayErrorMessage($errorMsg, coned.constants.ERROR_MESSAGE);
                        }
                    );
                } else {
                    checkLogin($formSelector, _data, $errorMsg);
                }
            }

            function errorPostLoginDataCallback() {
                displayErrorMessage($errorMsg, coned.constants.ERROR_MESSAGE);
            }

            function successLoginCallback(data) {
                _data = data;
                successPostLoginDataCallback();
            }

            function errorLoginCallback() {
                $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            }
        };

        var submitNewDeviceHandler = function () {
            var $formSelector,
                $errorMsg,
                params,
                returnUrl = coned.utils.getUrlParameterValue(CONSTANTS.RETURN_URL_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.RETURN_URL_PARAMETER_NAME)
                    : '',
                fromUri = coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    : '',
                relayState = coned.utils.getUrlParameterValue(CONSTANTS.RELAY_STATE_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.RELAY_STATE_PARAMETER_NAME)
                    : '',
                actualUrl = encodeURIComponent(window.location.pathname + window.location.search),
                _data;

            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                $formSelector = $loginDropdownNewDeviceSelector;
                $errorMsg = $DropdownNewDeviceError;
            } else {
                $formSelector = $loginNewDeviceSelector;
                $errorMsg = $FormNewDeviceError;
            }

            // Verify which value to send as the returnUrl
            if (returnUrl === '') {
                returnUrl = fromUri;
            }

            // Only if loginType is dropdown should check if should add actualUrl
            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                if (returnUrl === '' && $redirectUrl === '') {
                    returnUrl = actualUrl;
                } else {
                    returnUrl = $redirectUrl;
                }
            }

            // Service Data
            params = {
                MFACode: query.getFormInputValue($login, CONSTANTS.LOGIN_INPUT_MFA),
                ReturnUrl: returnUrl,
                FromURI: fromUri,
                OpenIdRelayState: relayState
            };
            params = JSON.stringify(params);

            hideErrorMessage($errorMsg);
            $submitAnimationButton = $formSelector.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];

            // Service Call
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                newDeviceCall,
                function () { },
                errorPostDeviceDataCallback,
                false,
                true
            );

            function newDeviceCall() {
                query.postData(
                    $loginNewDeviceUrl,
                    successDeviceCallback,
                    errorDeviceCallback,
                    params,
                    true
                );
            }

            function successPostDeviceDataCallback() {
                if (coned.utils.isPatternLab()) {
                    var jsonLocalFile;

                    if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                        // local case scenario for modal MFA valid code
                        jsonLocalFile = coned.plConstants.GET_LOGIN_MFA;
                    } else {
                        // local case scenario for MFA invalid code
                        jsonLocalFile = coned.plConstants.GET_LOGIN_MFA_FAIL;
                    }

                    query.getData(
                        jsonLocalFile,
                        function (data) {
                            checkDeviceCode(data, $errorMsg);
                        },
                        function () {
                            displayErrorMessage($errorMsg, coned.constants.ERROR_MESSAGE);
                        }
                    );
                } else {
                    checkDeviceCode(_data, $errorMsg);
                }
            }

            function errorPostDeviceDataCallback() {
                displayErrorMessage($errorMsg, coned.constants.ERROR_MESSAGE);
            }

            function successDeviceCallback(data) {
                _data = data;
                successPostDeviceDataCallback();
            }

            function errorDeviceCallback() {
                $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            }
        };

        var showHideLoginDropdown = function () {
            // if dropdown login has active_login class, the dropdown is showing
            if (
                !$dropdownLoginContainer.classList.contains(CONSTANTS.ACTIVE_LOGIN) ||
                $dropdownLoginContainer.style.height == '0px'
            ) {
                showLoginDropdown();
                setTimeout(function () {
                    $DropdownInputEmail.focus();
                }, 100);
            } else {
                hideLoginDropdownMFA();
                setTimeout(function () {
                    $loginButton.focus();
                }, 100);
            }
        };

        var showLoginDropdown = function () {
            $dropdownLoginContainer.classList.add(CONSTANTS.ACTIVE_LOGIN);
            // added inline style in case mobile desktop resize
            $dropdownLoginContainer.style.height = 'auto';
            $arrowLogin.classList.remove(CONSTANTS.HIDDEN);
            $jsNavLogin.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
        };

        var closeLogin = function (event) {
            if (coned.utils.isMobile()) {
                closeLoginModal(event);
            } else {
                hideLoginDropdown();
            }
        };

        var hideLoginDropdownMFA = function (event) {
            restartLoginDropdown();
            // If it's mobile, use function that uses tween (default behavior for modal)
            // if not, use header behavior (remove opacity and height)
            closeLogin(event);
        };

        var closeLoginByKeyboard = function (event) {
            closeLogin(event);
            if (coned.utils.isDesktop()) {
                setTimeout(function () {
                    $jsNavLogin.focus();
                }, 100);
            }
        };

        var hideLoginDropdownMFAByKeyboard = function (event) {
            hideLoginDropdownMFA(event);
            if (coned.utils.isDesktop()) {
                setTimeout(function () {
                    $jsNavLogin.focus();
                }, 100);
            }
        };

        var hideLoginDropdown = function () {
            $dropdownLoginContainer.classList.remove(CONSTANTS.ACTIVE_LOGIN);
            // added inline style in case mobile desktop resize
            $dropdownLoginContainer.style.height = '0';
            $arrowLogin.classList.add(CONSTANTS.HIDDEN);
            $jsNavLogin.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
        };

        var restartLoginDropdown = function () {
            $loginDropdownSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $loginDropdownNewDeviceSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $loginDropdownHeader.classList.add(CONSTANTS.HIDDEN_DESKTOP_CLASS);
            $DropdownSubmitButton.disabled = true;

            Array.prototype.forEach.call($SubmitButtonContainers, function ($submitButton) {
                if ($submitButton.classList.contains(CONSTANTS.SUBMIT_CONTAINER_REVERSED)) {
                    $submitButton.classList.remove(CONSTANTS.SUBMIT_CONTAINER_REVERSED);
                }
            });

            clearLoginFields();
            clearMFAFields();
        };

        /**
         * Handle close dropdown login with keyboard
         * @param {Event} event
         */
        var closeLoginModalByTab = function (event) {
            var keyCode = event.keyCode;
            var isShiftPressed = event.shiftKey;

            if (
                !isShiftPressed &&
                !coned.utils.isMobile() &&
                keyCode === coned.constants.KEY_CODE.TAB
            ) {
                closeLoginModal(event);
            }
        };

        var closeLoginModalByShiftTab = function (event) {
            var key = event.keyCode;
            var isLoginOpened = $login.style.height !== '' && $login.style.height !== '0px';

            if (key == coned.constants.KEY_CODE.TAB && isLoginOpened) {
                closeLoginModal(event);
            }
        };

        var initializeData = function () {
            _loginType = $login.dataset.loginType;
            $loginUrl = $login.dataset.loginUrl;
            $redirectUrl = $login.dataset.redirectUrl;
            $loginNewDeviceUrl = $login.dataset.newDeviceUrl;

            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                initializeModalData();
            } else {
                initializePageData();
            }
        };

        var initializeModalData = function () {
            $openButtonMobileSelector = document.getElementsByClassName(
                CONSTANTS.OPEN_BUTTON_MOBILE_SELECTOR
            )[0];
            $openMobileSelector = document.getElementsByClassName(
                CONSTANTS.OPEN_MOBILE_SELECTOR
            )[0];
            $closeButtonSelector = $login.getElementsByClassName(CONSTANTS.CLOSE_BUTTON_SELECTOR);
            $loginAnimationSelector = $login.getElementsByClassName(
                CONSTANTS.LOGIN_ANIMATION_SELECTOR
            );
            $loginDropdownSelector = $login.getElementsByClassName(
                CONSTANTS.LOGIN_DROPDOWN_SELECTOR
            )[0];
            $loginDropdownNewDeviceSelector = $login.getElementsByClassName(
                CONSTANTS.LOGIN_DROPDOWN_NEW_DEVICE_FORM_SELECTOR
            )[0];
            $DropdownInputEmail = $loginDropdownSelector.getElementsByClassName(
                CONSTANTS.INPUT_EMAIL
            )[0];
            $DropdownInputPassword = $loginDropdownSelector.getElementsByClassName(
                CONSTANTS.INPUT_PASSWORD
            )[0];
            $DropdownShowPasswordButton = $loginDropdownSelector.getElementsByClassName(
                CONSTANTS.SHOW_PASSWORD_BUTTON
            )[0];
            $DropdownSubmitButton = $loginDropdownSelector.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON
            )[0];
            $DropdownLoginError = $loginDropdownSelector.getElementsByClassName(
                CONSTANTS.LOGIN_FORM_ERROR
            )[0];
            $DropdownInputDeviceCode = $loginDropdownNewDeviceSelector.getElementsByClassName(
                CONSTANTS.INPUT_DEVICE_CODE
            )[0];
            $DropdownNewDeviceSubmitButton = $loginDropdownNewDeviceSelector.getElementsByClassName(
                CONSTANTS.NEW_DEVICE_SUBMIT_BUTTON
            )[0];
            $DropdownNewDeviceError = $loginDropdownNewDeviceSelector.getElementsByClassName(
                CONSTANTS.NEW_DEVICE_ERROR
            )[0];
            $DropdownNewDeviceIntroMsg = $loginDropdownNewDeviceSelector.getElementsByClassName(
                CONSTANTS.NEW_DEVICE_INTRO_MSG
            )[0];
            $DropdownNewDeviceInputLabel = $loginDropdownNewDeviceSelector.getElementsByClassName(
                CONSTANTS.NEW_DEVICE_INPUT_LABEL
            )[0];
            $dropdownLoginContainer = document.getElementsByClassName(
                CONSTANTS.DROPDOWN_LOGIN_CONTAINER
            )[0];
            $loginButton = document.getElementsByClassName(CONSTANTS.LOGIN_BUTTON_SELECTOR)[0];
            $arrowLogin = document.getElementsByClassName(CONSTANTS.ARROW_LOGIN)[0];
            $loginDropdownHeader = document.getElementsByClassName(
                CONSTANTS.LOGIN_DROPDOWN_HEADER_SELECTOR
            )[0];
            $SubmitButtonContainers = $login.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON_CONTAINER_SELECTOR
            );
            $navSearchButton = document.getElementsByClassName(CONSTANTS.NAV_SEARCH_SELECTOR)[0];
            $jsNavLogin = document.getElementsByClassName(CONSTANTS.OPEN_BUTTON_SELECTOR)[0];
            _verifiedMFA = 'false';
        };

        var initializePageData = function () {
            $loginFormSelector = $login.getElementsByClassName(CONSTANTS.LOGIN_FORM_SELECTOR)[0];
            $loginNewDeviceSelector = $login.getElementsByClassName(
                CONSTANTS.LOGIN_NEW_DEVICE_FORM_SELECTOR
            )[0];
            $FormInputEmail = $loginFormSelector.getElementsByClassName(CONSTANTS.INPUT_EMAIL)[0];
            $FormInputPassword = $loginFormSelector.getElementsByClassName(
                CONSTANTS.INPUT_PASSWORD
            )[0];
            $FormShowPasswordButton = $loginFormSelector.getElementsByClassName(
                CONSTANTS.SHOW_PASSWORD_BUTTON
            )[0];
            $FormSubmitButtons = $loginFormSelector.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON);
            $FormLoginError = $loginFormSelector.getElementsByClassName(
                CONSTANTS.LOGIN_FORM_ERROR
            )[0];
            $FormInputDeviceCode = $loginNewDeviceSelector.getElementsByClassName(
                CONSTANTS.INPUT_DEVICE_CODE
            )[0];
            $FormNewDeviceSubmitButton = $loginNewDeviceSelector.getElementsByClassName(
                CONSTANTS.NEW_DEVICE_SUBMIT_BUTTON
            )[0];
            $FormNewDeviceError = $loginNewDeviceSelector.getElementsByClassName(
                CONSTANTS.NEW_DEVICE_ERROR
            )[0];
            $FormNewDeviceIntroMsg = $loginNewDeviceSelector.getElementsByClassName(
                CONSTANTS.NEW_DEVICE_INTRO_MSG
            )[0];
            $FormNewDeviceInputLabel = $loginNewDeviceSelector.getElementsByClassName(
                CONSTANTS.NEW_DEVICE_INPUT_LABEL
            )[0];
            $jsNavLogin = document.getElementsByClassName(CONSTANTS.OPEN_BUTTON_SELECTOR)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
        };

        var initializeEvents = function () {
            if (_loginType == CONSTANTS.DROPDOWN_VALUE) {
                // Events related to login modal
                coned.utils.addGeneralListeners($openButtonMobileSelector, openLoginModal);
                Array.prototype.forEach.call($closeButtonSelector, function ($closebutton) {
                    coned.utils.addGeneralListeners($closebutton, hideLoginDropdownMFA);
                });
                coned.utils.addGeneralListeners($openMobileSelector, openLoginModal);
                if ($dropdownLoginContainer) {
                    // Add listener to individual button to show dropdown login
                    $loginButton.addEventListener('click', showHideLoginDropdown);
                    $navSearchButton && $navSearchButton.addEventListener('click', hideLoginDropdownMFA);
                }

                window.addEventListener('resize', resizeModal);
                coned.utils.addGeneralListeners($DropdownShowPasswordButton, togglePassword);
                $DropdownInputEmail.addEventListener('keyup', enableButton);
                $DropdownInputEmail.addEventListener('selectedOption', enableButton);
                $DropdownInputPassword.addEventListener('keyup', enableButton);
                $DropdownInputDeviceCode.addEventListener('input', enableButton);
                $DropdownSubmitButton.disabled = true;
                $DropdownNewDeviceSubmitButton.disabled = true;

                //Getting last child of list on dropdown login
                $loginListLast = document.getElementsByClassName(CONSTANTS.LOGIN_LIST_CONTAINER)[0]
                    .firstElementChild.lastElementChild.firstElementChild;
                $loginListLast && $loginListLast.addEventListener('keydown', closeLoginModalByTab);
                $loginButton && $loginButton.addEventListener('keyup', closeLoginModalByShiftTab);

                // Submit modal
                new coned.components.ValidateForm(CONSTANTS.LOGIN_DROPDOWN, submitLoginHandler, '');
                new coned.components.ValidateForm(
                    CONSTANTS.LOGIN_DROPDOWN_NEW_DEVICE_FORM,
                    submitNewDeviceHandler,
                    ''
                );
            } else {
                // Events related to login form
                coned.utils.addGeneralListeners($FormShowPasswordButton, togglePassword);
                $FormInputEmail.addEventListener('keyup', enableButton);
                $FormInputEmail.addEventListener('selectedOption', enableButton);
                $FormInputPassword.addEventListener('keyup', enableButton);
                $FormInputDeviceCode.addEventListener('input', enableButton);

                // Disable Submit Buttons at first load
                for (var i = 0; i < $FormSubmitButtons.length; i++) {
                    $FormSubmitButtons[i].disabled = true;
                }
                $FormNewDeviceSubmitButton.disabled = true;

                new coned.components.ValidateForm(CONSTANTS.LOGIN_FORM, submitLoginHandler, '');
                new coned.components.ValidateForm(
                    CONSTANTS.LOGIN_NEW_DEVICE_FORM,
                    submitNewDeviceHandler,
                    ''
                );
            }

            // Set email value if remember me cookie exists
            var cookieValue = query.getCookie(CONSTANTS.REMEMBER_ME_COOKIE_NAME);
            if (cookieValue !== null) {
                var $rememberMeCheckbox = $login.querySelector(
                    '[name="' + CONSTANTS.LOGIN_INPUT_REMEMBER_ME + '"]'
                ),
                    $rememberMeLabel = $login.getElementsByClassName(CONSTANTS.CHECKBOX_LABEL)[0];

                query.setFormTextInputValue($login, CONSTANTS.LOGIN_INPUT_EMAIL, cookieValue);

                $rememberMeCheckbox.checked = true;
                $rememberMeLabel.classList.add(CONSTANTS.CHECKBOX_LABEL_CHECKED);
            }

            //A11y handlers
            $dropdownLoginContainer && setModalRole(coned.utils.isMobile());
            $loginDropdownSelector &&
                new coned.utils.addFocusTrap(
                    $loginDropdownSelector,
                    true,
                    false,
                    $closeButtonSelector[0]
                );

            $loginDropdownSelector &&
                new coned.utils.addKeyEvent(
                    $loginDropdownSelector,
                    coned.constants.KEY_CODE.ESC,
                    closeLoginByKeyboard
                );

            $loginDropdownSelector &&
                new coned.utils.addKeyEvent(
                    $loginDropdownNewDeviceSelector,
                    coned.constants.KEY_CODE.ESC,
                    hideLoginDropdownMFAByKeyboard
                );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();

            //For normal connections we need to wait 1 sec so the loading doesn't look like a jump on the page
            setTimeout(function () {
                if($formLoading) {
                    $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
                }
            }, 1000);

            

            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoginFormComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LoginFormComponent;
})();
