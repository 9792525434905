// ==================== Data Grid COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DataGrid = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        DATA_CELL: 'js-data-cell',
        GRID_ROW: 'js-grid-row',
        LOAD_MORE: 'js-load-more-selector',
        NON_SEQUENTIAL_ROWS: 'js-non-sequential-rows',
        TABINDEX: 'tabindex',
        CHECKBOX_CLICKED_EVENT: 'checkbox-clicked',
        TABINDEX_0_SELECTOR: '[tabindex="0"]'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var DataGrid = function ($dataGrid) {
        /**
         * PRIVATE METHODS
         */
        var $gridCells,
            $numCellsPerRow,
            $showMoreFocus = false,
            $showMoreFocusIndex,
            $loadMore,
            _currentGridCell,
            _isSequentialOrder,
            _isNotEmpty;
        /**
         * Move to the indicated position. Update grid attributes.
         * @param {Number} position New cell position to move.
         */
        var moveToCell = function (position) {
            if (0 <= position && 
                position < $gridCells.length && 
                !coned.utils.isElementHidden($gridCells[position])) {
                var $previousTableCell = _currentGridCell.tableCell;
                _currentGridCell.tableCell = $gridCells[position];
                _currentGridCell.index = position;

                $previousTableCell && $previousTableCell.setAttribute(CONSTANTS.TABINDEX, -1);
                _currentGridCell.tableCell && _currentGridCell.tableCell.setAttribute(CONSTANTS.TABINDEX, 0);
                _currentGridCell.tableCell && _currentGridCell.tableCell.focus();
            }
        };

        /**
         * Grid Keyboard Usage.
         * @param {Object} event 
         */
        var handleGridKeyboardUsage = function (event)  {
            event.stopPropagation();
            var currentPosition = _currentGridCell.index;                
            if($showMoreFocus){
                $showMoreFocus = false;
                currentPosition = $showMoreFocusIndex;
            } 
            var currentColumnPos = currentPosition % $numCellsPerRow,
                newPosition;

            switch (event.keyCode) {
                case coned.constants.KEY_CODE.HOME:
                    event.preventDefault();
                    newPosition = currentPosition - currentColumnPos;
                    break;

                case coned.constants.KEY_CODE.END:
                    event.preventDefault();
                    var remainingCells = ($numCellsPerRow - 1) - currentColumnPos;
                    newPosition = currentPosition + remainingCells
                    break;

                case coned.constants.KEY_CODE.UP:
                    newPosition = currentPosition - $numCellsPerRow;
                    break;

                case coned.constants.KEY_CODE.DOWN:
                    newPosition = currentPosition + $numCellsPerRow;
                    break;
                    
                case coned.constants.KEY_CODE.RIGHT:
                    newPosition = currentPosition + 1;
                    break;

                case coned.constants.KEY_CODE.LEFT:
                    newPosition = currentPosition - 1;
                    break;
            }         
            
            moveToCell(newPosition);
        };

        /**
         * Grid Keyboard Usage for billing payment pseudo table.
         * This pseudo table's rows are non sequential and don't have the same number of cells per row
         * Show more shows new rows in between of others that were already visible
         * @param {Object} event 
         */
        var handleGridKeyboardUsageNonSequential = function (event)  {
            event.stopPropagation();
            var currentPosition = _currentGridCell.index;                
            if($showMoreFocus){
                $showMoreFocus = false;
                currentPosition = $showMoreFocusIndex;
            } 
            var newPosition,
                index,
                $currentRow = query.selectParentElement(
                    $gridCells[currentPosition], 
                    CONSTANTS.GRID_ROW
                ),
                numCellsCurrentRow = $currentRow.getElementsByClassName(
                    CONSTANTS.DATA_CELL
                ).length,
                $nextRow = $currentRow.nextElementSibling,
                numCellsNextRow = $nextRow && $nextRow.getElementsByClassName(
                    CONSTANTS.DATA_CELL
                ).length,
                $previousRow = $currentRow.previousElementSibling,
                numCellsPreviousRow = $previousRow && $previousRow.getElementsByClassName(
                    CONSTANTS.DATA_CELL
                ).length,
                _isCell5 = $gridCells[currentPosition].dataset.cellPosition === "5",
                numCellsToMoveDown = 
                    _isCell5 ? 
                        numCellsNextRow : 
                        numCellsCurrentRow,
                numCellsToMoveUp = 
                    _isCell5 ? 
                        numCellsCurrentRow : 
                        numCellsPreviousRow;

            switch (event.keyCode) {
                case coned.constants.KEY_CODE.UP:
                    // Loop starting on the row right above until finding the next visible cell at the same position
                    for (
                        index = currentPosition - numCellsToMoveUp;
                        index >= 0;
                        index = index - numCellsToMoveUp
                    ) {
                        // If previous cell row is visible and cell position matches
                        // data-cell-position must match in order to move, because some rows are "incomplete", 
                        // meaning they have cells 1, 2, and then jump to 5, there are no 3 and 4 visually
                        if (
                            !coned.utils.isElementHidden($gridCells[index]) &&
                            $gridCells[index].dataset.cellPosition === $gridCells[currentPosition].dataset.cellPosition
                        ) {
                            newPosition = index;
                            break;
                        // If there is no match, move on to previous row and get the number of cells to move up
                        // Since the "table" is not consistent on the number of cells per row, we need diferent approaches
                        } else if ($previousRow) {
                            if (_isCell5) {
                                numCellsToMoveUp = $previousRow.getElementsByClassName(
                                    CONSTANTS.DATA_CELL
                                ).length;
                                $previousRow = $previousRow.previousElementSibling;
                            } else {
                                $previousRow = $previousRow.previousElementSibling;
                                numCellsToMoveUp = $previousRow && $previousRow.getElementsByClassName(
                                    CONSTANTS.DATA_CELL
                                ).length;
                            }
                        } else {
                            break;
                        }
                    }
                    break;

                case coned.constants.KEY_CODE.DOWN:
                    // Loop starting on the row right below until finding the next visible cell at the same position
                    for (
                        index = currentPosition + numCellsToMoveDown;
                        index < $gridCells.length; 
                        index = index + numCellsToMoveDown
                    ) {
                        // If next cell row is visible and cell position matches
                        // data-cell-position must match in order to move, because some rows are "incomplete", 
                        // meaning they have cells 1, 2, and then jump to 5, there are no 3 and 4 visually
                        if (
                            !coned.utils.isElementHidden($gridCells[index]) &&
                            $gridCells[index].dataset.cellPosition === $gridCells[currentPosition].dataset.cellPosition
                        ) {
                            newPosition = index;
                            break;
                        // If there is no match, move on to next row and get the number of cells to move down
                        // Since the "table" is not consistent on the number of cells per row, we need diferent approaches
                        } else if ($nextRow) {
                            if (_isCell5) {
                                $nextRow = $nextRow.nextElementSibling;
                                numCellsToMoveDown = $nextRow && $nextRow.getElementsByClassName(
                                    CONSTANTS.DATA_CELL
                                ).length;
                            } else {
                                numCellsToMoveDown = $nextRow.getElementsByClassName(
                                    CONSTANTS.DATA_CELL
                                ).length;
                                $nextRow = $nextRow.nextElementSibling;
                            }
                        } else {
                            break;
                        }
                    }
                    break;
                    
                case coned.constants.KEY_CODE.RIGHT:
                    // Loop up starting at current position + 1 until finding the next visible cell
                    for (index = currentPosition + 1; index < $gridCells.length; index++) {
                        if (!coned.utils.isElementHidden($gridCells[index])) {
                            newPosition = index;
                            break;
                        }
                    }
                    break;

                case coned.constants.KEY_CODE.LEFT:
                    // Loop down starting at current position - 1 until finding the previous visible cell
                    for (index = currentPosition - 1; index >= 0; index--) {
                        if (!coned.utils.isElementHidden($gridCells[index])) {
                            newPosition = index;
                            break;
                        }
                    }
                    break;
            }         
            
            moveToCell(newPosition);
        };

        var showMoreFocusEvent = function (event) {
            $showMoreFocus = true;
            var focusItem = event.detail,
            focusIndex = Object.values($gridCells).indexOf(focusItem);
            $showMoreFocusIndex = focusIndex !== -1 ? focusIndex: 0;
        }

        /**
         * Sets a tab index of 0 on the first visible cell of the table
         * Resets previous tab index 0 to -1
         */
        var setTabIndexOnFirstVisibleCell = function () {
            var $currentTabZeroCell = $dataGrid.querySelector(
                '.' + CONSTANTS.DATA_CELL + CONSTANTS.TABINDEX_0_SELECTOR
            );

            // Reset tab index to -1 if needed
            $currentTabZeroCell && $currentTabZeroCell.setAttribute(CONSTANTS.TABINDEX, -1);

            // Set tab index 0 to first visible cell
            for (var index = 0; index < $gridCells.length; index++) {
                if (!coned.utils.isElementHidden($gridCells[index])) {
                    $gridCells[index].setAttribute(CONSTANTS.TABINDEX, 0);
                    _currentGridCell.tableCell = $gridCells[index];
                    _currentGridCell.index = index;
                    break;
                }
            }
        };

        var preInitializeData = function () {
            _isNotEmpty = $dataGrid.getElementsByClassName(CONSTANTS.DATA_CELL).length;
        };

        var initializeData = function () {
            $gridCells = $dataGrid.querySelectorAll('.' + CONSTANTS.DATA_CELL);
            _currentGridCell = {
              tableCell: $gridCells[0],
              index: 0
            };
            $numCellsPerRow = $dataGrid.querySelector('.' + CONSTANTS.GRID_ROW)
              .querySelectorAll('.' + CONSTANTS.DATA_CELL)
              .length;
            $loadMore = document.getElementsByClassName(CONSTANTS.LOAD_MORE)[0];
            _isSequentialOrder = !query.hasClass($dataGrid, CONSTANTS.NON_SEQUENTIAL_ROWS);
        };

        var initializeEvents = function () {
            if (_isSequentialOrder) {
                _currentGridCell.tableCell.setAttribute(CONSTANTS.TABINDEX, 0);
                $dataGrid.addEventListener('keydown', handleGridKeyboardUsage);
            } else {
                setTabIndexOnFirstVisibleCell();
                $dataGrid.addEventListener('keydown', handleGridKeyboardUsageNonSequential);
                $dataGrid.addEventListener(CONSTANTS.CHECKBOX_CLICKED_EVENT, setTabIndexOnFirstVisibleCell);
            }
            $loadMore.addEventListener('show-more-grid-focus',showMoreFocusEvent);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            preInitializeData();
            // Prevents js to crash if no cells exist
            if (_isNotEmpty) {
                initializeData();
                initializeEvents();
            }
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DataGrid.prototype.isLoaded = function () {
        return isLoaded;
    };
    return DataGrid;
})();
