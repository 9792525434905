// ==================== LOAD ENERGY INSIGHTS OPOWER COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoadEnergyInsightsOpower = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        TIP_CARDS_CONTAINER: 'js-tips-cards',
        TIP_CARDS_DATA: 'js-tip-cards-data',
        BILL_ANALYSIS_CONTAINER: 'js-bill-analysis',
        BILL_ANALYSIS_DATA: 'js-bill-analysis-data',
        BILL_COMPARISON_CONTAINER: 'js-bill-comparison',
        BILL_COMPARISON_DATA: 'js-bill-comparison-data',
        ENERGY_USAGE_CONTAINER: 'js-energy-usage',
        ENERGY_USAGE_DATA: 'js-energy-usage-data',
        DIV_LOADING_CLASS: 'js-div-loading',
        DIV_LOADING_HIDDEN_CLASS: 'form-loading--hidden',
        ENERGY_USAGE_CLASS: 'energy-usage',
        OVERVIEW_BILL_ANALYSIS_CLASS: 'overview-bill-analysis',
        ERROR_PARAGRAPH_CLASS: 'js-service-error',
        ERROR_MESSAGE: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        GREEN_DATA_CONTAINER: 'js-green-data-container',
        DATA_BROWSER_CONTAINER: 'js-data-browser-container',
        DATA_BROWSER_CONTAINER_BILLING: 'js-data-browser-container-billing',
        DATA_BROWSER_CONTAINER_USAGE: 'js-data-browser-container-usage',
        REAL_TIME_CONTAINER: 'js-real-time-container',
        REAL_TIME_INFORMATIONAL_MESSAGE: 'js-real-time-informational-message',
        ENERGY_SNAPSHOT_CONTAINER: 'js-energy-snapshot-container',
        SIMILAR_HOMES_CONTAINER: 'js-similar-homes-container',
        HOME_ENERGY_CONTAINER: 'js-home-energy-container',
        POPUP: 'js-popup',
        REMOVE_ACCOUNT_MODULE: 'js-remove-account',
        UPLIGHT_WIDGET: 'js-ff-widget',
        UPLIGHT_WIDGET_ERROR_MESSAGE: 'js-uplight-widget-not-available',
        MODULE_CLASS: 'js-module',
        LOAD_ENERGY_INSIGHTS_ERROR: 'js-load-energy-insights-error',
        DATA_BROWSER_WIDGET_BILLING_TAG: '<opower-widget-data-browser opower-instance="billing"></opower-widget-data-browser>',
        DATA_BROWSER_WIDGET_USAGE_TAG: '<opower-widget-data-browser opower-instance="usage"></opower-widget-data-browser>',
        DATA_BROWSER_WIDGET_SIMILAR_HOMES_TAG: '<opower-widget-data-browser opower-instance="similar-homes"></opower-widget-data-browser>',
        NEIGHBOR_COMPARISON_WIDGET_TAG: '<opower-widget-neighbor-comparison></opower-widget-neighbor-comparison>',
        REAL_TIME_WIDGET_TAG: '<opower-widget-real-time-ami class="coned-tabs__widget--zero-z-index"></opower-widget-real-time-ami>',
        GREEN_BUTTON_WIDGET_TAG: '<opower-widget-usage-export></opower-widget-usage-export>',
        SCRIPT_ELEMENT: 'script',
        HOME_ENERGY_BREAKDOWN_WIDGET_TAGS: '<opower-widget-survey opower-instance="widget-survey-splash"></opower-widget-survey> <opower-widget-disaggregation opower-instance="widget-disaggregation"></opower-widget-disaggregation>',
        HOME_ENERGY_BREAKDOWN_WIDGET_V2_TAGS: '<opower-widget-survey opower-instance="{{heb-widget-survey-v2-instance}}"></opower-widget-survey> <opower-widget-usage-categories opower-instance="{{heb-widget-usage-categories-v2-instance}}"></opower-widget-usage-categories>',
        OPOWER_CUSTOMER_ID: 'js-opower-customer-id',
        SERVICE_ERROR: '<p class="service-unavailable-error">Service Not Available</p>',
        OPOWER_UNAUTHORIZED_EVENT: 'opower:unauthorized',
        OPOWER_START_EVENT: 'opower:start',
        ENERGY_USAGE_LOADED_EVENT: 'energy-usage-loaded',
        STATE_URL_PARAMETER_KEY: '&state=',
        OPOWER_VERTICAL_BAR_URL_PARAMETER_VALUE: "opower|",
        REDIRECT_URL_PARAMETER_KEY: '&redirect_uri='
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LoadEnergyInsightsOpower = function ($energyInsights) {
        /**
         * PRIVATE METHODS
         */
        var $tipCardsContainer,
            $tipCardsData,
            $billAnalysisContainer,
            $billAnalysisData,
            $billComparisonContainer,
            $billComparisonData,
            $energyUsageContainer,
            $dataBrowserContainerBilling,
            $dataBrowserContainerUsage,
            $realTimeContainer,
            $realTimeMessaging,
            $energySnapshotContainer,
            $similarHomesContainer,
            $homeEnergyContainer,
            $energyUsageData,
            $greenDataContainer,
            $errorParagraphBillAnalysis,
            $errorMessageBillAnalysis,
            $errorParagraphEnergyUsage,
            $errorMessageEnergyUsage,
            $errorParagraphTipCards,
            $errorMessageTipCards,
            $loadingDivBillAnalysis,
            $loadingDivEnergyUsage,
            $loadingDivBillComparison,
            $loadingDivTipCards,
            $popup,
            $removeAccountModule,
            $uplightWidget,
            $uplightWidgetNotAvailableMsg,
            customerId,
            authorize,
            _isOpowerTokenDoneOrErrored,
            _isEnergyUsageLoaded,
            _isHomeEnergyV2,
            _isMobileApp,
            _hideMobileAppUplightWidget,
            _isIosDevice;

        var getEnergyInsights = function () {
            var serviceUrl = $energyInsights.dataset.serviceUrl,
                params;

            if (!serviceUrl) {
                errorGetEnergyInsights();

                return;
            }

            // Service Data
            params = {
                async: 1,
                opowerData: $energyInsights.dataset.opowerData
            };

            query.getData(serviceUrl, successGetEnergyInsights, errorGetEnergyInsights, params);
        };

        var successGetEnergyInsights = function (data) {
            if (coned.utils.isPatternLab()) {
                if (coned.utils.isOru()) {
                    // Billing Residential page ORU
                    if ($energyInsights.dataset.oruPatternlabBillingPage) {
                        query.getData(
                            coned.plConstants.GET_ORU_ENERGY_INSIGHTS_DATA_BILLING,
                            setEnergyInsights,
                            errorGetEnergyInsights
                        );
                    // Billing Commercial page ORU
                    } else if ($energyInsights.dataset.oruPatternlabCommercialPage) {
                        query.getData(
                            coned.plConstants.GET_ORU_ENERGY_INSIGHTS_DATA_BILLING_COMMERCIAL,
                            setEnergyInsights,
                            errorGetEnergyInsights
                        );
                    // Billing SHR (Solar, Thermo A, Thermo B) page ORU
                    } else if ($energyInsights.dataset.oruPatternlabBillingShrPage) {
                        query.getData(
                            coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING_SHR,
                            setEnergyInsights,
                            errorGetEnergyInsights
                        );
                    // Energy Usage Residential page ORU
                    } else if ($energyInsights.dataset.oruPatternlabEnergyUsageResidentialPage) {
                        query.getData(
                            coned.plConstants.GET_ORU_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RESIDENTIAL,
                            setEnergyInsights,
                            errorGetEnergyInsights
                        );
                    // Energy Usage IPP SHR SPP Residential page ORU
                    } else if ($energyInsights.dataset.oruPatternlabEnergyUsageIppShrSppResidentialPage) {
                        query.getData(
                            coned.plConstants.GET_ORU_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_IPP_SHR_SPP_RESIDENTIAL,
                            setEnergyInsights,
                            errorGetEnergyInsights
                        );
                    // Energy Usage Commercial page ORU
                    } else if ($energyInsights.dataset.oruPatternlabEnergyUsageCommercialPage) {
                        query.getData(
                            coned.plConstants.GET_ORU_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_COMMERCIAL,
                            setEnergyInsights,
                            errorGetEnergyInsights
                        );
                    // Energy Usage RTP page ORU
                    } else if ($energyInsights.dataset.oruPatternlabEnergyUsageRtpPage) {
                        query.getData(
                            coned.plConstants.GET_ORU_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RTP,
                            setEnergyInsights,
                            errorGetEnergyInsights
                        );
                    // Legacy - Still using Legacy Dashboard with tabs, needs to be updated to individual 
                    // Energy Use, Billing, Manage My Account pages and oPower V2 widgets to work.          
                    } else {
                        query.getData(
                            coned.plConstants.GET_ORU_ENERGY_INSIGHTS_DATA,
                            setEnergyInsights,
                            errorGetEnergyInsights
                        );
                    }
                // CORE - Level Subordinate - Still using Legacy Dashboard with tabs, needs to be updated to
                // individual Energy Use, Billing, Manage My Account pages and oPower V2 widgets to work.
                // Actual widgets for each section need to be checked against a real account.
                } else if ($energyInsights.dataset.patternlabUsage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_USAGE,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Legacy - Still using Legacy Dashboard with tabs, needs to be updated to individual 
                // Energy Use, Billing, Manage My Account pages and oPower V2 widgets to work.          
                } else if ($energyInsights.dataset.patternlabEscoChart) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_CHART_DATA_ESCO,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Legacy - Still using Legacy Dashboard with tabs, needs to be updated to individual 
                // Energy Use, Billing, Manage My Account pages and oPower V2 widgets to work.          
                } else if ($energyInsights.dataset.patternlabNonEscoChart) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_CHART_DATA_NON_ESCO,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Billing Residential page
                } else if ($energyInsights.dataset.patternlabBillingPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Billing Commercial page
                } else if ($energyInsights.dataset.patternlabBillingCommercialPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING_COMMERCIAL,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Billing IPP SPP Residential/Commercial page
                } else if ($energyInsights.dataset.patternlabBillingIppSppPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_SPP,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Billing IPP Rate2 page (With multiyear Bill Comparison)
                } else if ($energyInsights.dataset.patternlabBillingIppRate2Page) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_2,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Billing IPP Rate4 page
                } else if ($energyInsights.dataset.patternlabBillingIppRate4Page) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_4,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    ); 
                // Billing IPP Rate5 page
                } else if ($energyInsights.dataset.patternlabBillingIppRate5Page) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_5,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Billing IPP Rate6 page
                } else if ($energyInsights.dataset.patternlabBillingIppRate6Page) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_6,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Billing IPP Rate7 page
                } else if ($energyInsights.dataset.patternlabBillingIppRate7Page) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING_IPP_RATE_7,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Billing SHR (Solar, Thermo A, Thermo B) page
                } else if ($energyInsights.dataset.patternlabBillingShrPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_BILLING_SHR,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Energy Usage Residential page
                } else if ($energyInsights.dataset.patternlabEnergyUsageResidentialPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RESIDENTIAL,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Energy Usage IPP SHR SPP Residential page (general version for IPP SHR SPP, no detailed widgets per rate type)
                } else if ($energyInsights.dataset.patternlabEnergyUsageIppShrSppResidentialPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_IPP_SHR_SPP_RESIDENTIAL,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Energy Usage IPP Rate 2 Residential page (demand widget version variation for rate 2)
                } else if ($energyInsights.dataset.patternlabEnergyUsageRate2ResidentialPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_2_RESIDENTIAL,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Energy Usage IPP Rate 4 Residential page (demand widget version variation for rate 4, and rate 4 usage blue widget)
                } else if ($energyInsights.dataset.patternlabEnergyUsageRate4ResidentialPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_4_RESIDENTIAL,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Energy Usage IPP Rate 5 Residential page (demand widget version variation for rate 5, with additional data attributes)
                } else if ($energyInsights.dataset.patternlabEnergyUsageRate5ResidentialPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_5_RESIDENTIAL,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Energy Usage IPP Rate 6 Residential page (demand widget version variation for rate 6, with additional data attributes)
                } else if ($energyInsights.dataset.patternlabEnergyUsageRate6ResidentialPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_6_RESIDENTIAL,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Energy Usage Rate Pilots Commercial page
                } else if ($energyInsights.dataset.patternlabEnergyUsageRatePilotsCommercialPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_RATE_PILOTS_COMMERCIAL,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Energy Usage NYPA page
                } else if ($energyInsights.dataset.patternlabEnergyUsageNypaPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_NYPA,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Energy Usage Commercial page
                } else if ($energyInsights.dataset.patternlabEnergyUsageCommercialPage) {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA_ENERGY_USAGE_COMMERCIAL,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                // Legacy - Still using Legacy Dashboard with tabs, needs to be updated to individual 
                // Energy Use, Billing, Manage My Account pages and oPower V2 widgets to work.          
                } else {
                    query.getData(
                        coned.plConstants.GET_ENERGY_INSIGHTS_DATA,
                        setEnergyInsights,
                        errorGetEnergyInsights
                    );
                }
            } else {
                setEnergyInsights(data);
            }
        };

        var errorGetEnergyInsights = function (data) {
            if ($loadingDivBillAnalysis) {
                $loadingDivBillAnalysis.classList.add(CONSTANTS.DIV_LOADING_HIDDEN_CLASS);
            }

            if ($loadingDivEnergyUsage) {
                $loadingDivEnergyUsage.classList.add(CONSTANTS.DIV_LOADING_HIDDEN_CLASS);
            }

            if ($loadingDivBillComparison) {
                $loadingDivBillComparison.classList.add(CONSTANTS.DIV_LOADING_HIDDEN_CLASS);
            }

            hideTipCardsLoading();

            if ($errorParagraphBillAnalysis) {
                $errorParagraphBillAnalysis.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
            
            if ($errorMessageBillAnalysis) {
                $errorMessageBillAnalysis.innerHTML = data
                   ? data.errorMsg
                       ? data.errorMsg
                       : coned.constants.ERROR_MESSAGE
                   : coned.constants.ERROR_MESSAGE;
            }

            $errorParagraphBillAnalysis && $errorParagraphBillAnalysis.focus();

            if ($errorParagraphEnergyUsage) {
                $errorParagraphEnergyUsage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
            $errorMessageEnergyUsage.innerHTML = data
                ? data.errorMsg
                    ? data.errorMsg
                    : coned.constants.ERROR_MESSAGE
                : coned.constants.ERROR_MESSAGE;

            $errorParagraphEnergyUsage && $errorParagraphEnergyUsage.focus();

            showTipCardsError(data);
        };

        var setEnergyUsageLoadingError = function () {
            if (!query.hasClass($energyInsights, CONSTANTS.LOAD_ENERGY_INSIGHTS_ERROR)) {
                query.addClass($energyInsights, CONSTANTS.LOAD_ENERGY_INSIGHTS_ERROR);
                _isOpowerTokenDoneOrErrored = true;
                showEnergyUsageServiceError();
            }
        };

        var setEnergyInsights = function (data) {
            var script = document.createElement(CONSTANTS.SCRIPT_ELEMENT);
            var scriptSrcOpower = $energyInsights.dataset.scriptSrcOpower;

                // If IE don't load the widgets since opower widgets don't support it
                // Trying to load the widgets makes the whole page crash, so we just show nothing as IE usage is fairly minimal
                if (!coned.utils.isIE()) {
                    if (scriptSrcOpower) {
                        script.src = scriptSrcOpower;
                        document.head.appendChild(script);
                    } else {
                        setEnergyUsageLoadingError();
                    }
                }

            if ($tipCardsContainer != []) {
                setTipCards(data);
            }

            if ($billAnalysisContainer) {
                setBillAnalysis(data);
            }

            if ($energyUsageContainer) {
                setEnergyUsage(data);
            }

            if ($billComparisonContainer) {
                setBillComparison(data);
            }
        };

        var setBillAnalysis = function (data) {
            var parser = new DOMParser(),
                HTMLObject;

            HTMLObject = parser.parseFromString(data, 'text/html');
            $billAnalysisData = HTMLObject.getElementsByClassName(CONSTANTS.BILL_ANALYSIS_DATA)[0];

            $billAnalysisContainer.innerHTML = $billAnalysisData.innerHTML;

            // Initialize modules
            coned.utils.initializeModules($billAnalysisContainer);
        };

        var setBillComparison = function (data) {
            var parser = new DOMParser(),
                HTMLObject;

            HTMLObject = parser.parseFromString(data, 'text/html');
            $billComparisonData = HTMLObject.getElementsByClassName(
                CONSTANTS.BILL_COMPARISON_DATA
            )[0];

            $billComparisonContainer.innerHTML = $billComparisonData.innerHTML;

            // Initialize modules
            coned.utils.initializeModules($billComparisonContainer);
        };

        var setTipCards = function (data) {
            var parser = new DOMParser(),
                HTMLObject;

            HTMLObject = parser.parseFromString(data, 'text/html');
            $tipCardsData = HTMLObject.getElementsByClassName(CONSTANTS.TIP_CARDS_DATA)[0];

            if ($tipCardsData !== undefined) {
                for (var index = 0; index < $tipCardsContainer.length; index++) {
                    $tipCardsContainer[index].innerHTML = $tipCardsData.innerHTML;

                    // Initialize modules
                    coned.utils.initializeModules($tipCardsContainer[index]);
                }
            } else {
                hideTipCardsLoading();
            }
        };

        var setEnergyUsage = function (data) {
            var parser = new DOMParser(),
                HTMLObject;

            HTMLObject = parser.parseFromString(data, 'text/html');
            $energyUsageData = HTMLObject.getElementsByClassName(
                CONSTANTS.ENERGY_USAGE_DATA
            )[0];
            $energyUsageContainer.innerHTML = $energyUsageData.innerHTML;
            $popup = document.getElementsByClassName(
                CONSTANTS.POPUP
            )[0];
            $removeAccountModule = document.getElementsByClassName(
                CONSTANTS.REMOVE_ACCOUNT_MODULE
            )[0];
            $greenDataContainer = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.GREEN_DATA_CONTAINER
            )[0];
            $dataBrowserContainerBilling = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.DATA_BROWSER_CONTAINER_BILLING
            )[0];
            $dataBrowserContainerUsage = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.DATA_BROWSER_CONTAINER_USAGE
            )[0];
            $realTimeContainer = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.REAL_TIME_CONTAINER
            )[0];
            $realTimeMessaging = $realTimeContainer && $realTimeContainer.getElementsByClassName(
                CONSTANTS.REAL_TIME_INFORMATIONAL_MESSAGE
            )[0];
            $energySnapshotContainer = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.ENERGY_SNAPSHOT_CONTAINER
            )[0];
            $similarHomesContainer = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.SIMILAR_HOMES_CONTAINER
            )[0];
            $homeEnergyContainer = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.HOME_ENERGY_CONTAINER
            )[0];

            // Initialize modules
            coned.utils.initializeModules($energyUsageContainer);

            if ($dataBrowserContainerUsage) {
               $dataBrowserContainerUsage.innerHTML = CONSTANTS.DATA_BROWSER_WIDGET_USAGE_TAG;
            }

            if ($dataBrowserContainerBilling) {
                $dataBrowserContainerBilling.innerHTML = CONSTANTS.DATA_BROWSER_WIDGET_BILLING_TAG;
             }

            if ($realTimeContainer) {
                $realTimeMessaging && query.removeClass($realTimeMessaging, CONSTANTS.HIDDEN_CLASS);
                $realTimeContainer.innerHTML += CONSTANTS.REAL_TIME_WIDGET_TAG;
            }

            if ($energySnapshotContainer) {
                $energySnapshotContainer.innerHTML = CONSTANTS.NEIGHBOR_COMPARISON_WIDGET_TAG;
            }

            if ($similarHomesContainer) {
                $similarHomesContainer.innerHTML = CONSTANTS.DATA_BROWSER_WIDGET_SIMILAR_HOMES_TAG;
            }

            if ($greenDataContainer) {
                $greenDataContainer.innerHTML = CONSTANTS.GREEN_BUTTON_WIDGET_TAG;
            }

            if ($homeEnergyContainer) {
                if (_isHomeEnergyV2) {
                    $homeEnergyContainer.innerHTML = 
                        CONSTANTS.HOME_ENERGY_BREAKDOWN_WIDGET_V2_TAGS
                            .replace(
                                '{{heb-widget-survey-v2-instance}}', 
                                $homeEnergyContainer.dataset.hebWidgetSurveyV2Instance
                            )
                            .replace(
                                '{{heb-widget-usage-categories-v2-instance}}',
                                $homeEnergyContainer.dataset.hebWidgetUsageCategoriesV2Instance
                            );
                } else {
                    $homeEnergyContainer.innerHTML = CONSTANTS.HOME_ENERGY_BREAKDOWN_WIDGET_TAGS;
                }
            }

            if ($popup && $removeAccountModule) {
                $popup.classList.add(CONSTANTS.MODULE_CLASS);
                $removeAccountModule.classList.add(CONSTANTS.MODULE_CLASS);
                coned.utils.initializeModules(document);
            }

            coned.utils.triggerEvent($energyInsights, CONSTANTS.ENERGY_USAGE_LOADED_EVENT);
        };

        /**
        * For Mobile App only
        */
        var setEnergyUsageMobileApp = function () {
            var script = document.createElement(CONSTANTS.SCRIPT_ELEMENT);
            var scriptSrcOpower = $energyInsights.dataset.scriptSrcOpower;

            if (scriptSrcOpower) {
                script.src = scriptSrcOpower;
                document.head.appendChild(script);
            } else {
                setEnergyUsageLoadingError();
            }

            $dataBrowserContainerBilling = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.DATA_BROWSER_CONTAINER_BILLING
            )[0];
            $dataBrowserContainerUsage = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.DATA_BROWSER_CONTAINER_USAGE
            )[0];
            $realTimeContainer = $energyUsageContainer.getElementsByClassName(
                CONSTANTS.REAL_TIME_CONTAINER
            )[0];


            if ($dataBrowserContainerUsage) {
               $dataBrowserContainerUsage.innerHTML = CONSTANTS.DATA_BROWSER_WIDGET_USAGE_TAG;
            }

            if ($dataBrowserContainerBilling) {
                $dataBrowserContainerBilling.innerHTML = CONSTANTS.DATA_BROWSER_WIDGET_BILLING_TAG;
             }

            if ($realTimeContainer) {
               $realTimeContainer.innerHTML = CONSTANTS.REAL_TIME_WIDGET_TAG;
            }

            if (_hideMobileAppUplightWidget) {
                query.removeClass($uplightWidgetNotAvailableMsg, CONSTANTS.HIDDEN_CLASS)
            } else {
                query.removeClass($uplightWidget, CONSTANTS.HIDDEN_CLASS);
            }
        
            coned.utils.triggerEvent($energyInsights, CONSTANTS.ENERGY_USAGE_LOADED_EVENT);
        };

        var showEnergyUsageServiceError = function () {
            if (_isOpowerTokenDoneOrErrored && _isEnergyUsageLoaded && query.hasClass($energyInsights, CONSTANTS.LOAD_ENERGY_INSIGHTS_ERROR)) {
                if ($dataBrowserContainerBilling) {
                    $dataBrowserContainerBilling.innerHTML = CONSTANTS.SERVICE_ERROR;
                }

                if ($dataBrowserContainerUsage) {
                    $dataBrowserContainerUsage.innerHTML = CONSTANTS.SERVICE_ERROR;
                }

                if ($realTimeContainer) {
                    $realTimeContainer.innerHTML = CONSTANTS.SERVICE_ERROR;
                }

                if ($energySnapshotContainer) {
                    $energySnapshotContainer.innerHTML = CONSTANTS.SERVICE_ERROR;
                }

                if ($similarHomesContainer) {
                    $similarHomesContainer.innerHTML = CONSTANTS.SERVICE_ERROR;
                }

                if ($greenDataContainer) {
                    $greenDataContainer.innerHTML = CONSTANTS.SERVICE_ERROR;
                }

                if ($homeEnergyContainer) {
                    $homeEnergyContainer.innerHTML = CONSTANTS.SERVICE_ERROR;
                }
            }
        };

        var getOpowerToken = function () {
            var tokenServiceUrlOpower = $energyInsights.dataset.tokenServiceUrlOpower;

            if (!tokenServiceUrlOpower) {
                setEnergyUsageLoadingError();
                return;
            }

            query.getData(tokenServiceUrlOpower, successGetOpowerToken, errorGetOpowerToken);
        };

        // Try to get the token, if empty call the redirect service which upon reload should have the token
        var successGetOpowerToken = function (token) {
            var redirectUrlOpower = $energyInsights.dataset.redirectUrlOpower;

            if (!redirectUrlOpower) {
                setEnergyUsageLoadingError();
                return;
            }

            if (token) {
                var authorization = {
                    accessToken: token
                }
                authorize(null, authorization);
            } else {
                if (!_isMobileApp) {
                    query.getData(redirectUrlOpower, successOpowerRedirect, errorOpowerRedirect);
                }
            }
        };

        var errorGetOpowerToken = function () {
            setEnergyUsageLoadingError();
        };

        // The only way from OKTA to get the corresponding token on the BE is after a redirect, so we force it
        var successOpowerRedirect = function (uriData) {
            if (uriData) {
                uriData += CONSTANTS.REDIRECT_URL_PARAMETER_KEY + encodeURIComponent(window.location.protocol + "//" + window.location.host);
                uriData += CONSTANTS.STATE_URL_PARAMETER_KEY + encodeURIComponent(CONSTANTS.OPOWER_VERTICAL_BAR_URL_PARAMETER_VALUE + window.location.pathname + window.location.search);
                window.location.href = uriData;
            } else {
                setEnergyUsageLoadingError();
            }
        };

        var errorOpowerRedirect = function () {
            setEnergyUsageLoadingError();
        };

        var hideTipCardsLoading = function () {
            for (var index = 0; index < $loadingDivTipCards.length; index++) {
                if ($loadingDivTipCards[index]) {
                    $loadingDivTipCards[index].classList.add(CONSTANTS.DIV_LOADING_HIDDEN_CLASS);
                }
            }
        };

        var showTipCardsError = function (data) {
            for (var index = 0; index < $errorParagraphTipCards.length; index++) {
                $errorParagraphTipCards[index].classList.remove(CONSTANTS.HIDDEN_CLASS);
                $errorMessageTipCards[index].innerHTML = data
                    ? data.errorMsg
                        ? data.errorMsg
                        : coned.constants.ERROR_MESSAGE
                    : coned.constants.ERROR_MESSAGE;
            }
        };

        var initializeData = function () {
            $tipCardsContainer = document.getElementsByClassName(CONSTANTS.TIP_CARDS_CONTAINER);
            $billAnalysisContainer = document.getElementsByClassName(
                CONSTANTS.BILL_ANALYSIS_CONTAINER
            )[0];
            $energyUsageContainer = document.getElementsByClassName(
                CONSTANTS.ENERGY_USAGE_CONTAINER
            )[0];
            $billComparisonContainer = document.getElementsByClassName(
                CONSTANTS.BILL_COMPARISON_CONTAINER
            )[0];

            if ($billAnalysisContainer) {
                $errorParagraphBillAnalysis = $billAnalysisContainer.getElementsByClassName(
                    CONSTANTS.ERROR_PARAGRAPH_CLASS
                )[0];
                $errorMessageBillAnalysis = $billAnalysisContainer.getElementsByClassName(
                    CONSTANTS.ERROR_MESSAGE
                )[0];
                $loadingDivBillAnalysis = $billAnalysisContainer.getElementsByClassName(
                    CONSTANTS.DIV_LOADING_CLASS
                )[0];
            }

            if ($energyUsageContainer) {
                $errorParagraphEnergyUsage = $energyUsageContainer.getElementsByClassName(
                    CONSTANTS.ERROR_PARAGRAPH_CLASS
                )[0];
                $errorMessageEnergyUsage = $energyUsageContainer.getElementsByClassName(
                    CONSTANTS.ERROR_MESSAGE
                )[0];
                $loadingDivEnergyUsage = $energyUsageContainer.getElementsByClassName(
                    CONSTANTS.DIV_LOADING_CLASS
                )[0];
            }

            if ($billComparisonContainer) {
                $loadingDivBillComparison = $billComparisonContainer.getElementsByClassName(
                    CONSTANTS.DIV_LOADING_CLASS
                )[0];
            }

            $loadingDivTipCards = [];
            $errorParagraphTipCards = [];
            $errorMessageTipCards = [];

            for (var index = 0; index < $tipCardsContainer.length; index++) {
                $loadingDivTipCards.push(
                    $tipCardsContainer[index].getElementsByClassName(CONSTANTS.DIV_LOADING_CLASS)[0]
                );
                $errorParagraphTipCards.push(
                    $tipCardsContainer[index].getElementsByClassName(
                        CONSTANTS.ERROR_PARAGRAPH_CLASS
                    )[0]
                );
                $errorMessageTipCards.push(
                    $tipCardsContainer[index].getElementsByClassName(CONSTANTS.ERROR_MESSAGE)[0]
                );
            }

            _isOpowerTokenDoneOrErrored = false;
            _isEnergyUsageLoaded = false;
            _isHomeEnergyV2 = $energyInsights.dataset.useHomeEnergyV2;
            _isMobileApp = $energyInsights.dataset.mobileApp;
            
            if (_isMobileApp) {
                $uplightWidget = document.getElementsByClassName(
                    CONSTANTS.UPLIGHT_WIDGET
                )[0];
                $uplightWidgetNotAvailableMsg = document.getElementsByClassName(
                    CONSTANTS.UPLIGHT_WIDGET_ERROR_MESSAGE
                )[0];
                _isIosDevice = coned.utils.isIOS();
                _hideMobileAppUplightWidget = $uplightWidget && _isIosDevice && $uplightWidget.dataset.widgetHideIos;
            }
        };

        var initializeEvents = function () {
            $energyInsights.addEventListener(CONSTANTS.ENERGY_USAGE_LOADED_EVENT, function () {
                _isEnergyUsageLoaded = true;
                showEnergyUsageServiceError();
            });

            if(!_isMobileApp) {
                getEnergyInsights();    
            } else {
                setEnergyUsageMobileApp();
            }
            
            if (!coned.utils.isPatternLab()) {
                // If IE don't load the widgets since opower widgets don't support it
                // Trying to load the widgets makes the whole page crash, so we just show nothing as IE usage is fairly minimal
                if (!coned.utils.isIE()) {
                    window.addEventListener(CONSTANTS.OPOWER_UNAUTHORIZED_EVENT, function (event) {
                        authorize = event.detail.authorize;
                        event.preventDefault();
                        getOpowerToken();
                    });

                    window.addEventListener(CONSTANTS.OPOWER_START_EVENT, function (event) {
                        customerId = document.getElementsByClassName(
                            CONSTANTS.OPOWER_CUSTOMER_ID
                        )[0];

                        if (customerId) {
                            window.opowerApi = event.detail;
                            window.opowerApi.setEntityIds([customerId.value]);
                            window.opowerApi.start();
                        }
                    });
                }
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();

            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoadEnergyInsightsOpower.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LoadEnergyInsightsOpower;
})();
