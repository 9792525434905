// ==================== INTERACTIVE FORM COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.InteractiveSurveyModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SELECT_CLASS: 'js-energy-sources',
        SELECT_TRIGGER_VALUE: 'oil',
        SELECT_HIDDEN_CLASS: 'js-energy-select-hidden',
        FIELD_HIDDEN_CLASS: 'coned-form__field-container--hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var InteractiveSurveyModule = function ($selectMenu) {
        /**
         * PRIVATE METHODS
         */
        var $selectElement, $hiddenElements;

        var showFields = function (event) {
            var selectedValue = event.target.value;

            if (selectedValue === CONSTANTS.SELECT_TRIGGER_VALUE) {
                _.each($hiddenElements, function (hiddenItem) {
                    hiddenItem.classList.remove(CONSTANTS.FIELD_HIDDEN_CLASS);
                });
            } else {
                _.each($hiddenElements, function (hiddenItem) {
                    hiddenItem.classList.add(CONSTANTS.FIELD_HIDDEN_CLASS);
                });
            }
        };

        var initializeData = function () {
            $selectElement = $selectMenu.getElementsByClassName(CONSTANTS.SELECT_CLASS)[0];
            $hiddenElements = $selectMenu.getElementsByClassName(CONSTANTS.SELECT_HIDDEN_CLASS);
        };

        var initializeEvents = function () {
            $selectElement.addEventListener('change', showFields);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    InteractiveSurveyModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return InteractiveSurveyModule;
})();
