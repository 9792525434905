// ==================== FILTERGROUP =========================

var coned = coned || {};

coned.components = coned.components || {};
/**
 * @returns the init function to start the module
 */
coned.components.FilterGroup = (function () {
    /**
     * @typedef StateModal
     * @property {boolean} open
     */

    /**
     * @typedef {Object} FilterState
     * @property {string} name
     * @property {string | string[]} value
     */

    /**
     * @typedef {Object} FilterGroupState
     * @property {FilterState[]} filters
     * @property {number} count
     * @property {boolean} isLoadedData
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        CLASSES: {
            /**
             * @type {'filter-group__wrapper--fade-in'}
             */
            FADE_IN: 'filter-group__wrapper--fade-in',
            /**
             * @type {'modal-footer--show'}
             */
            FOOTER_MOBILE_SHOW: 'modal-footer--show',
            /**
             * @type {'modal-enable'}
             */
            MODAL_ENABLE: 'modal-enable'
        },
        SELECTORS: {
            /**
             * @type {'js-filter-group'}
             */
            GROUP: 'js-filter-group',
            /**
             * @type {'js-modal'}
             */
            MODAL: 'js-modal',
            /**
             * @type {'js-modal-footer'}
             */
            MODAL_FOOTER: 'js-modal-footer',
            /**
             * @type {'js-select-multiple'}
             */
            FILTERS: 'js-select-multiple',
            /**
             * @type {'.js-filter-group-wrapper'}
             */
            FILTERS_WRAPPER: 'js-filter-group-wrapper',
            /**
             * @type {'js-modal-open'}
             */
            BUTTON_OPEN: 'js-modal-open',
            /**
             * @type {'js-filter-group-button-clear'}
             */
            BUTTON_CLEAR: 'js-filter-group-button-clear',
            /**
             * @type {'js-filter-group-button-clear-mobile'}
             */
            BUTTON_CLEAR_MOBILE: 'js-filter-group-button-clear-mobile',
            /**
             * @type {'js-filter-group-button-clear-text'}
             */
            BUTTON_CLEAR_TEXT: 'js-filter-group-button-clear-text',
            /**
             * @type {'js-filter-group-button-clear-mobile-text'}
             */
            BUTTON_CLEAR_MOBILE_TEXT: 'js-filter-group-button-clear-mobile-text'
        },
        ATTRIBUTES: {
            /**
             * @type {'data-count'}
             */
            DATA_COUNT: 'data-count',
            /**
             * @type {'tabindex'}
             */
            TABINDEX: 'tabindex',
            /**
             * @type {'0'}
             */
            TABINDEX_ENABLE: '0',
            /**
             * @type {'-1'}
             */
            TABINDEX_DISABLE: '-1',
            /**
             * @type {'role'}
             */
            ROLE: 'role',
            /**
             * @type {'button'}
             */
            BUTTON: 'button',
            /**
             * @type {'presentation'}
             */
            PRESENTATION: 'presentation'
        },
        /**
         * @type {'-mobile'}
         */
        NAME_PART_MOBILE: '-mobile',
        /**
         * @type {Number}
         */
        MAX_TIMEOUT_CALLBACKS: 10,
        GAP_WRAPPER: 20
    };
    var isLoaded = false;
    /**
     * Constructor
     * @param { HTMLDivElement } $filterGroup
     * @returns {}
     */
    var FilterGroup = function ($filterGroup) {
        /**
         * PRIVATE VARIABLES
         */

        /**
         * @type {FilterGroupState}
         */
        var _state,
            /**
             * @type {StateModal}
             */
            _modalState,
            /**
             * @type {HTMLElement}
             */
            $buttonOpen,
            /**
             * @type {HTMLElement}
             */
            $buttonClear,
            /**
             * @type {HTMLElement}
             */
            $buttonClearText,
            /**
            * @type {HTMLElement}
            */
            $buttonClearMobile,
            /**
             * @type {HTMLElement}
             */
            $buttonClearMobileText,
            /**
             * @type {string}
             */
            _buttonClearInitialText,
            /**
             * @type {string}
             */
            _buttonOpenInitialText,
            /**
             * @type {string}
             */
            _buttonClearMobileInitialText,
            /**
             * @type {HTMLElement[]}
             */
            $filterList,
            /**
             * @type {Number}
             */
            _countTimeOutsToLoad,
            /**
             * @type {boolean}
             */
            _hasDataCount,
            /**
             * help to not listen filterChange
             * @type {string}
             */
            _isHandledEvent,
            /**
             * @type {HTMLElement | null}
             */
            $filtersWrapper,
            /**
             * @type {Number}
             */
            filtersWrappersInitialWidth,
            /**
             * @type {boolean}
             */
            _isOverflowWrapper,
            /**
             * @type {HTMLElement}
             */
            $modal,
            /**
             * @type {HTMLElement}
             */
            $modalFooter;

        /**
         * PRIVATE METHODS
         */

        /**
         * ResizeFunction Callback
         */
        var handleResize = function () {
            var $parentWrapper = $filtersWrapper.parentElement,
                isModalEnable = $filtersWrapper.classList.contains(CONSTANTS.CLASSES.MODAL_ENABLE);
            if ($parentWrapper) {
                _isOverflowWrapper = $parentWrapper.clientWidth < filtersWrappersInitialWidth;
                if (_isOverflowWrapper && !isModalEnable) {
                    $filtersWrapper.classList.add(CONSTANTS.CLASSES.MODAL_ENABLE);
                } else if (!_isOverflowWrapper && isModalEnable) {
                    $filtersWrapper.classList.remove(CONSTANTS.CLASSES.MODAL_ENABLE);
                }
                if ($buttonOpen) {
                    if (_isOverflowWrapper) {
                        $buttonOpen.setAttribute(CONSTANTS.ATTRIBUTES.TABINDEX, CONSTANTS.ATTRIBUTES.TABINDEX_ENABLE);
                        $buttonOpen.setAttribute(CONSTANTS.ATTRIBUTES.ROLE, CONSTANTS.ATTRIBUTES.BUTTON);
                        if (_state.count > 0) {
                            $buttonOpen.innerHTML = _buttonOpenInitialText + '(' + _state.count + ')';
                        } else {
                            $buttonOpen.innerHTML = _buttonOpenInitialText
                        }
                    } else {
                        $buttonOpen.removeAttribute(CONSTANTS.ATTRIBUTES.TABINDEX);
                        $buttonOpen.removeAttribute(CONSTANTS.ATTRIBUTES.ROLE);
                        $buttonOpen.innerHTML = _buttonOpenInitialText;
                    }
                }
            }
        }

        /**
         * Update modal footer html
         * @param {boolean} show 
         */
        var setModalFooterShowHTML = function (show) {
            if (show) {
                if (!$modalFooter.classList.contains(CONSTANTS.CLASSES.FOOTER_MOBILE_SHOW)) {
                    $modalFooter.classList.add(CONSTANTS.CLASSES.FOOTER_MOBILE_SHOW)
                }
            } else {
                if ($modalFooter.classList.contains(CONSTANTS.CLASSES.FOOTER_MOBILE_SHOW)) {
                    $modalFooter.classList.remove(CONSTANTS.CLASSES.FOOTER_MOBILE_SHOW)
                }
            }
        }
        /**
         * Listen modal state custom event
         * @param {CustomEvent<StateModal>} event 
         */
        var handleModalStateChange = function (event) {
            _modalState = event.detail;
            setTimeout(function () {
                if (_modalState.open) {
                    setModalFooterShowHTML(_state.count > 0);
                } else {
                    setModalFooterShowHTML(false);
                }
            }, 400);
        }
        /**
         * get FilterElement by name
         * @param {string} name 
         * @returns {HTMLElement | null}
         */
        var findMatchFilterELement = function (name) {
            var $foundFilterElement = $filterList.filter(function ($el) {
                return $el.dataset.name === name;
            })
            if ($foundFilterElement.length > 0) {
                return $foundFilterElement[0]
            }
            return null
        }
        /**
         * Update clear button html
         */
        var updateCountHtml = function () {
            $filterGroup.dataset.count = '' + _state.count;
            if (_state.count > 0) {
                $buttonClear.setAttribute(coned.constants.ARIA.HIDDEN, 'false');
                $buttonClearMobileText.innerHTML = _buttonClearMobileInitialText +
                    '(' + _state.count + ')';
                if (_modalState.open) {
                    setModalFooterShowHTML(true);
                }
                if (_isOverflowWrapper) {
                    $buttonOpen.innerHTML = _buttonOpenInitialText + '(' + _state.count + ')';
                }
                if (_hasDataCount) {
                    $buttonClearText.innerHTML = _buttonClearInitialText + '(' + _state.count + ')';
                    
                }
            } else {
                if (_isOverflowWrapper) {
                    $buttonOpen.innerHTML = _buttonOpenInitialText;
                }
                if (_hasDataCount) {
                    $buttonClearText.innerHTML = _buttonClearInitialText                    
                }
                $buttonClearMobileText.innerHTML = _buttonClearMobileInitialText;
                $buttonClear.setAttribute(coned.constants.ARIA.HIDDEN, 'true');
            }
        }
        /**
         * Update filter group state
         * @param {Function | undefined} callback 
         */
        var updateCount = function (callback) {
            var newCount = 0;
            _state.filters.forEach(function (filter) {
                if (Array.isArray(filter.value)) {
                    newCount += filter.value.length;
                }
            });
            _state.count = newCount;
            updateCountHtml();
            if (callback) {
                callback();
            }
        }
        /**
         * Update filter group state
         * @param {FilterState} filterState
         * @param {Function | undefined} callback
         */
        var setFiltersState = function (filterState, callback) {
            var filterName = filterState.name.replace(CONSTANTS.NAME_PART_MOBILE, ''),
                filterChangeMobileName = filterName + CONSTANTS.NAME_PART_MOBILE,
                $filterChange = findMatchFilterELement(
                    filterName === filterState.name ? filterChangeMobileName : filterName);

            _state.filters = _state.filters.filter(function (el) {
                return el.name !== filterName;
            });
            _state.filters.push({ name: filterName, value: filterState.value });
            if ($filterChange) {
                coned.utils.triggerEvent(
                    $filterChange,
                    coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                    { value: filterState.value }
                );
            }
            return updateCount(callback);
        }
        /**
         * create reutilizable callback function
         */
        var callbackChange = function () {
            coned.utils.triggerEvent(
                $filterGroup,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _state
            );
        }
        /**
         * Listen all filters changes
         * @param {CustomEvent<FilterState>} event
         */
        var handleFilterChange = function (event) {
            var newFilterState = {
                name: event.detail.name,
                value: event.detail.value
            },
                isMobileFilter = newFilterState.name.includes(CONSTANTS.NAME_PART_MOBILE);
            if (!_isOverflowWrapper && isMobileFilter || _isOverflowWrapper && !isMobileFilter) {
                return;
            }
            if (!_state.isLoadedData) {
                return setFiltersState(newFilterState);
            }
            if (_isHandledEvent) {
                _isHandledEvent = false;
                return;
            }
            return setFiltersState(newFilterState, callbackChange);
        }
        /**
         * Listen button clear event  click
         */
        var handleClearClick = function () {
            $buttonClear.setAttribute('disabled', 'true');
            /**
             * @type {DropDownState[]}
             */
            var newFiltersList = [];
            while (_state.filters.length > 0) {
                var filter = _state.filters.shift();
                if (filter) {
                    /**
                     * @type {FilterState}
                     */
                    var newFilterState = {
                        name: filter.name,
                        value: []
                    },
                        filterMobileName = filter.name + CONSTANTS.NAME_PART_MOBILE,
                        $filterElement = findMatchFilterELement(filter.name),
                        $filterMobileElement = findMatchFilterELement(filterMobileName);

                    if ($filterElement || $filterMobileElement) {
                        _isHandledEvent = true;
                    }
                    if ($filterElement) {
                        coned.utils.triggerEvent(
                            $filterElement,
                            coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                            { value: [] }
                        );
                    }
                    if ($filterMobileElement) {
                        coned.utils.triggerEvent(
                            $filterMobileElement,
                            coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                            { value: [] }
                        );
                    }
                    newFiltersList.push(newFilterState);
                }
            }
            _state.filters = newFiltersList.concat();
            updateCount(function () {
                $buttonClear.removeAttribute('disabled');
                coned.utils.triggerEvent(
                    $filterGroup,
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    _state
                );
            });
        }
        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            _state = {
                filters: [],
                count: 0,
                isLoadedData: false
            };
            _isHandledEvent = false;
            _countTimeOutsToLoad = 0;
            _modalState = {
                open: false
            };
            $modal = $filterGroup.getElementsByClassName(CONSTANTS.SELECTORS.MODAL)[0];
            $modalFooter = $filterGroup.getElementsByClassName(CONSTANTS.SELECTORS.MODAL_FOOTER)[0];
            $buttonOpen = $filterGroup.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON_OPEN)[0];
            $filtersWrapper = $filterGroup.getElementsByClassName(CONSTANTS.SELECTORS.FILTERS_WRAPPER)[0];
            $buttonClear = $filterGroup.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON_CLEAR)[0];
            $buttonClearText = $filterGroup.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON_CLEAR_TEXT)[0];
            $buttonClearMobile = $filterGroup.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON_CLEAR_MOBILE)[0];
            $buttonClearMobileText = $filterGroup.getElementsByClassName(
                CONSTANTS.SELECTORS.BUTTON_CLEAR_MOBILE_TEXT
            )[0];
            _buttonClearInitialText = $buttonClearText.innerHTML;
            _buttonOpenInitialText = $buttonOpen.innerHTML;
            _buttonClearMobileInitialText = $buttonClearMobileText.innerHTML;
            _hasDataCount = $filterGroup.hasAttribute(CONSTANTS.ATTRIBUTES.DATA_COUNT);
            filtersWrappersInitialWidth = 0;
            coned.utils.arrayFrom($filtersWrapper.children).forEach(
                function ($el) {
                    if ($el instanceof HTMLElement) {
                        filtersWrappersInitialWidth += $el.clientWidth +
                            CONSTANTS.GAP_WRAPPER;
                    }
                }
            );
            $filterList = coned.utils.arrayFrom(
                $filterGroup.getElementsByClassName(CONSTANTS.SELECTORS.FILTERS)
            );
            if ($filtersWrapper) {
                handleResize();
            }
        }
        /**
         * Initialize the events in the module
         */
        var initializeEvents = function () {
            if ($filtersWrapper) {
                window.addEventListener('resize', handleResize);
            }
            if ($modal) {
                $modal.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handleModalStateChange
                );
            }
            $filterList.forEach(function ($filter) {
                $filter.addEventListener(
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    handleFilterChange
                );
            });
            $buttonClear.addEventListener('click', handleClearClick);
            if ($buttonClearMobile) {
                $buttonClearMobile.addEventListener('click', handleClearClick);
            }
        }
        /**
         * Get first state of filters
         */
        var getFirstStateFilters = function () {
            _countTimeOutsToLoad += 1;
            var $filtersLoad = $filterList.filter(function ($el) {
                return $el.dataset.moduleStarted === coned.constants.TRUE;
            });
            if ($filtersLoad.length !== $filterList.length &&
                _countTimeOutsToLoad < CONSTANTS.MAX_TIMEOUT_CALLBACKS
            ) {
                setTimeout(getFirstStateFilters, 100)
            } else {
                _state.isLoadedData = true;
                if ($filtersWrapper && !$filtersWrapper.classList.contains(CONSTANTS.CLASSES.FADE_IN)) {
                    $filtersWrapper.classList.add(CONSTANTS.CLASSES.FADE_IN);
                }
                coned.utils.triggerEvent(
                    $filterGroup,
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    _state
                );
            }
        }
        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            getFirstStateFilters();
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    FilterGroup.prototype.isLoaded = function () {
        return isLoaded;
    };
    return FilterGroup;
})();