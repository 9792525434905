// ==================== VALIDATE FORM COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ValidateForm = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CHECKBOX_CONTAINER: 'coned-checkbox__container--flex',
        ERROR_WRAPPER_CLASS: 'coned-field-error-wrapper',
        ARIA_LIVE_CLASS: 'js-aria-live-errors',
        INPUT_MESSAGE_ERROR_CLASS: 'coned-input-message--error',
        ARIA_LABEL: 'aria-label',
        ROLE: 'role',
        VALUE_IMG: 'img',
        ARIA_INVALID_ATTR: 'aria-invalid',
        PAYMENT_METHOD_CLASS: 'js-payment-method',
        FALSE_VALUE: 'false',
        NONE_VALUE: 'none',
        CHANGE_EVENT: 'change'
    };

    var isLoaded = false;

    var getFormValidator = function ($formElement, callback, ignoreClass, noValidateKeyup) {
        var validateOptions = {
            errorElement: 'span',
            wrapper: 'div',
            errorClass: CONSTANTS.INPUT_MESSAGE_ERROR_CLASS,
            ignore: ignoreClass, 
            invalidHandler: function(event, validator) {
                var errors = validator.errorList, 
                    firstElement =  errors[0];
                // You must to declare the set timeout, this runs our approach after all JQuery validations.
                // There is no option to set focus to the first element, so this overrides the library's default behavior of focusing on the last element,
                // adding focus functionality to the first element.
                setTimeout(function() { 
                    firstElement.element.focus(); 
                }, 0); 
            }, 
            errorPlacement: function (error, element) {
                var isPaymentMethodSelect = query.hasClass(element[0], CONSTANTS.PAYMENT_METHOD_CLASS);
                
                if (query.hasClass(element[0], CONSTANTS.ARIA_LIVE_CLASS)) {
                    error[0].setAttribute('aria-live', 'polite');
                }

                error.addClass(CONSTANTS.ERROR_WRAPPER_CLASS);

                if (
                    element.attr('type') == 'checkbox' &&
                    query.hasClass(element.parent().parent()[0], CONSTANTS.CHECKBOX_CONTAINER)
                ) {
                    error.insertBefore(element.parent().parent());
                } else if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                    error.insertBefore(element.parent());
                } else if (element.is('select') && isPaymentMethodSelect) {
                    error.insertAfter(element.parent());
                    var removeError = function () {
                        error[0].style.display = CONSTANTS.NONE_VALUE;
                        query.removeClass(element, CONSTANTS.INPUT_MESSAGE_ERROR_CLASS);
                        element[0].setAttribute(CONSTANTS.ARIA_INVALID_ATTR, CONSTANTS.FALSE_VALUE);
                    };
                    // errorPlacement function runs when the select is active for the first time and adds the errors dynamically,
                    // here we hide the error, needs the timeout to overwrite the actual jquery.validate plugin functionality
                    setTimeout(function () {
                        removeError();
                    }, 0);
                    element[0].addEventListener(CONSTANTS.CHANGE_EVENT, removeError);
                } else {
                    error.insertAfter(element.parent());
                }

                var errorIcon = document.createElement('span');
                errorIcon.classList.add(CONSTANTS.INPUT_MESSAGE_ERROR_CLASS);
                errorIcon.setAttribute(
                    CONSTANTS.ARIA_LABEL,
                    element[0].dataset.iconErrorTe || coned.constants.ICON_ERROR_MESSAGE
                );
                errorIcon.setAttribute(CONSTANTS.ROLE, CONSTANTS.VALUE_IMG);

                error[0].insertBefore(errorIcon, error[0].childNodes[0]);
            }, 
            submitHandler: function ($form) {
                callback($form);
            }
        };

        if (noValidateKeyup) {
            validateOptions.onkeyup = false;
        }

        return $($formElement).validate(validateOptions);
    };

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ValidateForm = function ($form, callback, ignoreClass, noValidateKeyup) {
        /**
         * PRIVATE METHODS
         */
        var initializeEvents = function () {
            $($form).each(function () {
                // attach to all form elements on page
                getFormValidator(this, callback, ignoreClass, noValidateKeyup);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ValidateForm.prototype.isLoaded = function () {
        return isLoaded;
    };

    ValidateForm.isFormValid = function ($formElement, callback, ignoreClass, noValidateKeyup) {
        var $formValidator = getFormValidator($formElement, callback, ignoreClass, noValidateKeyup);
        return $formValidator.checkForm();
    };

    return ValidateForm;
})();
