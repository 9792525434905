// ==================== CHART/CHART=========================
/* global c3 */
/* global d3 */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.chart = coned.chart || {};

/**
* Create a new bar chart
* @param {Obj} chartInfo
* @param {Boolean} truncateTotal
* @param {String} totalHoverText
*/
coned.chart.createBarChart = function createBarChart (chartType, chartInfo, truncateTotal, totalHoverText, chartsPosition, legendNames, fixedSubscriptionValues) {
    var _chart,
        chartId = chartInfo.chartId,
        chartLeftPadding = chartInfo.chartLeftPadding,
        chartData = chartInfo.chartData,
        colorsObj = chartInfo.colorsObj,
        dataType = chartInfo.dataType,
        xAxisLeftPadding = chartInfo.xAxisLeftPadding,
        yAxis = chartInfo.yAxis,
        kilowattSymbol = chartInfo.kilowattSymbol,
        dollarSymbol = chartInfo.dollarSymbol,
        lineArray = chartInfo.lineArray,
        initialLine = chartInfo.initialLine,
        allTotalAverageValue = chartInfo.allTotalAverageValue,
        dataMonthlyNames = chartInfo.dataMonthlyNames,
        monthlyPeriodsList = chartInfo.monthlyPeriodsList,
        size = chartInfo.size,
        chartPos = chartsPosition ? chartsPosition.toString() : '',
        patternOption = colorsObj['nonSummerPeakValue']
            ? 'nonSummer'
            : colorsObj['unbilledSubscriptionOverageValue']
            ? 'overage'
            : '',
        patternColor = patternOption === 'nonSummer' ? '#636363' : '#94000f',
        patternId =
            patternOption === 'nonSummer'
                ? 'nonSummerPeakValue' + chartPos
                : 'unbilledSubscriptionOverageValue' + chartPos;

    _chart = c3.generate({
        bindto: '#' + chartId,
        padding: {
            left: chartLeftPadding
        },
        data: {
            x: 'label',
            columns: chartData,
            type: 'bar',
            types: {
                fixedSubscriptionRate: 'line'
            },
            colors: colorsObj,
            groups: [dataType],
            order: function (data) {
                data;
            },
            selection: {
                grouped: true
            }
        },
        zoom: {
            enabled: false
        },
        legend: {
            show: false
        },
        tooltip: {
            grouped: true,
            contents: function (data, defaultTitleFormat, defaultValueFormat, color) {
                var total = '',
                    colors = [],
                    text = [],
                    values = [],
                    dates = String(defaultTitleFormat(data[0].x)),
                    date = monthlyPeriodsList[dates],
                    tagEvent = chartType + '.demand.hover.state';

                dataLayer.push({
                    event: tagEvent
                });

                for (var dataIndex = 0; dataIndex < data.length; dataIndex++) {
                    var actualvalue = data[dataIndex].value;

                    values.push(actualvalue);
                    colors.push(color(data[dataIndex]));
                    text.push(data[dataIndex].id);
                }

                var spans = '',
                    hoverToolip = '',
                    dataValue;

                for (dataIndex = 0; dataIndex < data.length; dataIndex++) {
                    if (values[dataIndex] && values[dataIndex] > 0) {
                        if (truncateTotal) {
                            var totalValue = allTotalAverageValue[chartId][data[0]['x']],
                                regexTruncateNumber = /[0-9]*(.[0-9])/g,
                                matchNumber;
                            total = totalValue
                                ? "<div class='coned-chart__chart-tooltip--paddingTop'><span class='coned-chart__chart-tooltip--type-color'></span><span class='coned-chart__chart-tooltip--type-text'>" +
                                  totalHoverText +
                                  "</span><div class='coned-chart__chart-tooltip--type-numbers'><span class='coned-chart__chart-tooltip--type-numbers-value'>" +
                                  totalValue +
                                  '</span></div></div>'
                                : '';
                            matchNumber = values[dataIndex].toString().match(regexTruncateNumber);
                            dataValue = matchNumber ? matchNumber[0] : values[dataIndex] + '.0';
                        } else {
                            dataValue = values[dataIndex].toFixed(1);
                        }

                        var name = text[dataIndex],
                            nonSummerPattern =
                                name === 'nonSummerPeakValue'
                                    ? 'coned-chart__chart-tooltip--type-nonSummer'
                                    : '',
                            overagePattern =
                                name === 'unbilledSubscriptionOverageValue'
                                    ? 'coned-chart__chart-tooltip--type-subscriptionOverage'
                                    : '',
                            barPattern =
                                nonSummerPattern !== '' ? nonSummerPattern : overagePattern,
                            overageUnbilled =
                                name === 'unBilledOverage'
                                    ? 'coned-chart__chart-tooltip--type-overage'
                                    : '',
                            dataNames = dataMonthlyNames[name],
                            colorsChart =
                                "<span class='" +
                                overageUnbilled +
                                barPattern +
                                " coned-chart__chart-tooltip--type-color' style='background-color:" +
                                colors[dataIndex] +
                                "'></span>",
                            textChart =
                                "<span class='coned-chart__chart-tooltip--type-text'>" +
                                dataNames +
                                '</span>',
                            valueChart =
                                "<span class='coned-chart__chart-tooltip--type-numbers-value'>" +
                                dollarSymbol +
                                dataValue +
                                '</span>',
                            div =
                                name === 'fixedSubscriptionRate'
                                    ? ''
                                    : "<div class='coned-chart__chart-tooltip--type'>" +
                                      colorsChart +
                                      textChart +
                                      "<div class='coned-chart__chart-tooltip--type-numbers'>" +
                                      valueChart +
                                      '</div></div>';

                        spans += div;
                    }
                    hoverToolip =
                        spans != ''
                            ? "<div class='coned-chart__chart-tooltip'><div class='coned-chart__chart-tooltip--date'>" +
                              date +
                              "</div><div class='coned-chart__chart-tooltip--data'>" +
                              spans +
                              total +
                              '</div> </div>'
                            : '';
                }
                return hoverToolip;
            }
        },
        axis: {
            x: {
                type: 'category',
                padding: {
                    left: xAxisLeftPadding
                },
                tick: {
                    multiline: false
                }
            },
            y: {
                inner: true,
                max: parseFloat(yAxis[yAxis.length - 1]),
                show: true,
                tick: {
                    values: yAxis,
                    format: function (d) {
                        return d + kilowattSymbol;
                    }
                }
            }
        },
        grid: {
            lines: {
                front: false
            },
            y: {
                lines: lineArray
            },
            x: {
                lines: initialLine
            }
        },
        bar: {
            width: size
        },
        onrendered: function () {
            // execute after drawn
            coned.chart.stripeBars(chartId, chartsPosition, [
                'nonSummerPeakValue',
                'unbilledSubscriptionOverageValue'
            ]);
        }
    });

    if (patternOption !== '') {
        coned.chart.addPatternDefs(chartId, patternId, patternColor, size);
    }

    // create Table with the chart information
    coned.chart.createTable(chartId, monthlyPeriodsList, truncateTotal, undefined, chartData, legendNames, "barChart", fixedSubscriptionValues)

    return _chart;
};

/**
 * Create a new bar with the according pattern style
 * @param {String} chartId
 * @param {int} chartPosition
 * @param {Array} barTypes
 */
coned.chart.stripeBars = function stripeBars(chartId, chartsPosition, barTypes) {
    for (var i = 0; i < barTypes.length; i++) {
        d3.select('#' + chartId)
            .select('.c3-bars-' + barTypes[i])
            .each(function () {
                d3.select(this)
                    .selectAll('path')
                    .each(function () {
                        var node = d3.select(this),
                            name = barTypes[i] + chartsPosition.toString();
                        node.style('fill', 'url(#' + name + '_rect' + ')');
                    });
            });
    }
};

/**
 *  Create the new pattern for each chart
 * @param {String} chartId
 * @param {String} patternId
 */
coned.chart.addPatternDefs = function addPatternDefs(chartId, patternId, stripeColor, barWidth) {
    var svgPattern = d3
        .select('#' + chartId)
        .select('defs')
        .append('svg');

    svgPattern
        .append('pattern')
        .attr('id', patternId + '_rect')
        .attr('width', 5)
        .attr('height', 5)
        .append('rect') // pattern border
        .attr('width', barWidth)
        .attr('height', '100%')
        .attr('fill', 'url(#' + patternId + ')')
        .attr('stroke', stripeColor)
        .attr('stroke-width', 1.5);

    svgPattern
        .append('pattern')
        .attr('id', patternId)
        .attr('width', 3) // width line
        .attr('height', 3) // distance between lines
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('patternTransform', 'rotate(45 0 0 )');

    svgPattern
        .select('#' + patternId) // create the pattern
        .append('line')
        .attr('y2', 14)
        .style('stroke', stripeColor)
        .attr('stroke-width', 2) // width of the gray line

}

 /**
* Create a new line chart
* @param {Obj} chartInfo
* @param {String} specialDayMessage
* @param {String} zeroValueMessage
* @param {String} regularDayMessage
*/
coned.chart.createLineChart = function createLineChart (chartType, chartInfo, zeroValueMessage, specialDayMessage, regularDayMessage, isSubscriptionRate, negativeValueMessage, legendNames, fixedSubscriptionValues) {
    var _chart,
        chartId = chartInfo.chartId,
        chartLeftPadding = chartInfo.chartLeftPadding,
        chartData = chartInfo.chartData,
        colorsObj = chartInfo.colorsObj,
        peakClass = chartInfo.peakClass,
        generalDailyData = chartInfo.generalDailyData,
        allCompleteMonths = chartInfo.allCompleteMonths,
        xAxisLeftPadding = chartInfo.xAxisLeftPadding,
        yAxis = chartInfo.yAxis,
        linesArray = chartInfo.linesArray,
        kilowattSymbol = chartInfo.kilowattSymbol;

    _chart = c3.generate({
        bindto: '#' + chartId,
        padding: {
            left: chartLeftPadding
        },
        data: {
            x: 'label',
            type: 'area',
            types: {
                fixedSubscriptionRate: 'line'
            },
            columns: chartData,
            colors: colorsObj,
            classes: {
                actualDemand: peakClass,
                peakdeterminantDay: 'coned-line-chart',
                offPeakdeterminantDay: 'coned-line-chart',
                nonSummerdeterminantDay: 'coned-line-chart',
                unbilledOverage: 'coned-line-chart-overage',
                billedOverage: 'coned-line-chart-billed',
                billedOverageDay: 'coned-line-chart-billed'
            }
        },
        zoom: {
            enabled: false
        },
        tooltip: {
            grouped: true,
            contents: function (data, defaultTitleFormat, defaultValueFormat, color) {
                var total = 0,
                    colors = [],
                    text = [],
                    values = [],
                    date = defaultTitleFormat(data[0].x),
                    demandDate = generalDailyData[chartId][String(date)][0],
                    specialDay = generalDailyData[chartId][String(date)][1],
                    month,
                    datapos,
                    dailyText,
                    year,
                    time,
                    tagEvent = chartType + '.demand.hover.state';

                demandDate = demandDate.replace(/-([\s\S][^T]*):[\s\S]*/g, '');
                demandDate = new Date(demandDate);
                (month = allCompleteMonths[demandDate.getMonth()]),
                    (year = demandDate.getFullYear());
                time = demandDate.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit'
                });
                date = date.replace(/-([A-Z])*\w+/g, '');

                dataLayer.push({
                    event: tagEvent
                });

                for (var dataSerie = 0; dataSerie < data.length; dataSerie++) {
                    values.push(data[dataSerie].value);
                    colors.push(color(data[dataSerie]));
                    text.push(data[dataSerie].id);
                }

                datapos = isSubscriptionRate ? 0 : values[1] && values[1] > 0 ? 1 : 0;
                total = data[datapos].value.toFixed(2);
                total = total === '0.00' || total === '0.0' ? '0' : total;

                dailyText =
                    specialDay && specialDayMessage !== ''
                        ? specialDayMessage
                        : total === '0'
                        ? zeroValueMessage
                        : total < 0
                        ? negativeValueMessage
                        : regularDayMessage;
                dailyText = dailyText.replace(
                    /{{demand}}/g,
                    "<span class='coned-chart__chart-tooltip--type-paragraph-bold'>" +
                        total +
                        kilowattSymbol +
                        '</span>'
                );
                dailyText = dailyText.replace(
                    '{{time}}',
                    "<span class='coned-chart__chart-tooltip--type-paragraph-bold'>" +
                        time +
                        ' </span>'
                );

                var textChart =
                    "<span class='coned-chart__chart-tooltip--type-paragraph'>" +
                    dailyText +
                    '</span>';
                return (
                    "<div class='coned-chart__chart-tooltip'> <div class='coned-chart__chart-tooltip--date'> " +
                    month +
                    ' ' +
                    date +
                    ', ' +
                    year +
                    " </div> <div class='coned-chart__chart-tooltip--data'> " +
                    textChart +
                    ' </div></div>'
                );
            }
        },
        legend: {
            show: false
        },
        axis: {
            x: {
                type: 'category',
                padding: {
                    left: xAxisLeftPadding
                },
                tick: {
                    multiline: false
                }
            },
            y: {
                inner: true,
                max: parseFloat(yAxis[yAxis.length - 1]),
                min: parseFloat(yAxis[0]),
                padding: { bottom: 0 },
                show: true,
                tick: {
                    values: yAxis,
                    format: function (d) {
                        return d + kilowattSymbol;
                    }
                }
            }
        },
        grid: {
            lines: {
                front: false
            },
            y: {
                lines: linesArray
            }
        },
        point: {
            r: 4,
            focus: {
                expand: {
                    r: 4.5
                }
            }
        }
    });

    // create Table with the chart information
    coned.chart.createTable(chartId, generalDailyData[chartId], undefined, allCompleteMonths, chartData, legendNames, "lineChart", fixedSubscriptionValues)

    return _chart;
};

/**
* Add legend to corresponding charts
* @param {String} types
* @param {String} _chart
* @param {String} chartId
*/
coned.chart.addLegend = function(types, chart, chartId, dataNames, legendTooltip, legendType, peakOption, rateType, isOru, isMonthly) {
    var oruTooltip = isOru ? ' coned-tooltip--oru ': '',
        monthlyContentClass = isMonthly ? '-monthly' : '';

    d3.select('#' + chartId)
        .insert('div', '.chart')
        .attr('class', 'coned-chart__legend')
        .selectAll('div')
        .data(types)
        .enter()
        .append('div')
        .attr('class', 'coned-chart__legend-type')
        .attr('data-id', function (id) {
            return id;
        })
        .html(function (id) {
            if (id !== 'fixedSubscriptionRate') {
                // CONSTANTS.CONED_CHART_FIXED_SUBSCRIPTION
                var colorSpan = '';
                if (id == 'actualDemand' || id == 'dailyUsageSupply' || id == 'offPeakDemand') {
                    colorSpan =
                        '<span class="coned-chart__legend-line coned-chart__legend-line--' +
                        legendType +
                        '" aria-hidden="true"></span>';
                } else {
                    var typeDeterminantDay =
                        id === 'determinantDay'
                            ? ' coned-chart__legend-' + id + peakOption + rateType
                            : '';
                    colorSpan =
                        '<span class="coned-chart__legend-color coned-chart__legend-' +
                        id +
                        typeDeterminantDay +
                        '" aria-hidden="true"></span>';
                }

                var code =
                    '<div class="coned-chart-tooltip">' +
                    '<div class="coned-chart__tooltip coned-tooltip ' +
                    oruTooltip +
                    ' js-module coned-chart__tooltip-' +
                    id +
                    '" data-module="ToolTipComponent">' +
                    colorSpan +
                    '<span class="coned-chart__legend-content'+ monthlyContentClass +'">' + dataNames[id] + '</span>' +
                    '<button type="button" class="coned-chart__tooltip-content' + ' coned-tooltip--open js-tooltip-open" aria-label="Information about ' + dataNames[id] + ' " '  + 'aria-expanded="false"></button>'+
                    '<div class="tooltip__templates">' +
                    '<div class="coned-tooltip__wrapper js-coned-tooltip-wrapper">' +
                    '<button class="coned-tooltip--close js-tooltip-close"></button>' +
                    '<div class="coned-tooltip__content">' +
                    '<p class="js-tooltip-content">' +
                    legendTooltip[id] +
                    '</p>' +
                    '</div></div></div></div>' +
                    '</div>';

                return code;
            } else {
                return '<div class="coned-chart-tooltip"><div class="coned-chart__tooltip coned-tooltip ' + oruTooltip + ' js-module" data-module="ToolTipComponent">' +
                '<hr class=" coned-chart__legend-line coned-chart__legend-line--demandSubsc" aria-hidden="true"></hr>' +  dataNames[id] +
                '<button type="button" class="coned-chart__tooltip-content coned-tooltip--open js-tooltip-open" aria-label="Information" aria-expanded="false"></button>' +
                '<div class="tooltip__templates">' +
                '<div class="coned-tooltip__wrapper js-coned-tooltip-wrapper">' +
                '<button class="coned-tooltip--close js-tooltip-close"></button>' +
                '<div class="coned-tooltip__content">' +
                '<p class="js-tooltip-content">' + legendTooltip[id] + '</p>' +
                '</div></div></div></div>' +
                '</div>';
            }
        })
        .each(function (id) {
            var color = chart.color(id);

            if (id === 'unBilledOverage') {
                d3.select(this)
                    .select('span')
                    .attr(
                        'class',
                        'coned-chart__legend-color coned-chart__target-unbilled-overage'
                    );
            } else {
                d3.select(this)
                    .select('.coned-chart__legend-' + id)
                    .style('background-color', color);
            }
        })
        .on('mouseover', function (id) {
            chart.focus(id);
        })
        .on('mouseout', function () {
            chart.revert();
        });
};

/**
 * Initilize legend tooltips
 * @param {String} chartId
 * @param {String} tooltipElement  // 'coned-chart-tooltip'
 */
coned.chart.initializeTooltips = function (chartType, chartId, tooltipElement) {
    var $chart = document.getElementById(chartId),
        $tooltips = $chart.getElementsByClassName(tooltipElement);

    for (var tooltipIndex = 0; tooltipIndex < $tooltips.length; tooltipIndex++) {
        new coned.components.ToolTipComponent($tooltips[tooltipIndex]);
        coned.utils.addGeneralListeners($tooltips[tooltipIndex], function () {
            coned.chart.tooltipTagging(chartType);
        });
    }
};

/**
 * Adding tooltip tagging
 */
coned.chart.tooltipTagging = function (chartType) {
    var tagEvent = chartType + '.demand.legend.tool.tip';

    dataLayer.push({
        event: tagEvent
    });
};

/**
 * Add y-axis lines
 * @returns {Array} arrayLines
 */
coned.chart.yAxisLines = function (
    fixedSubscription,
    fixedSubscriptionValues,
    yAxis,
    kilowatt_symbol
) {
    var arrayLines = [];

    for (var pos = 0; pos < yAxis.length; pos++) {
        arrayLines.push({ value: yAxis[pos], text: '', position: 'start' });
    }

    if (fixedSubscription) {
        fixedSubscriptionValues.forEach(function (element) {
            var newLine = {
                value: element,
                class: 'text-line-overage',
                text: element + kilowatt_symbol,
                position: 'start'
            };

            arrayLines.push(newLine);
        });
    }
    return arrayLines;
};

/**
 * Set chart height
 */
coned.chart.fixChartHeight = function (chartId) {
    var chart = document.getElementById(chartId),
        legendHeight = chart.getElementsByClassName('coned-chart__legend')[0].clientHeight,
        chartHeight = 350 + legendHeight,
        chartWidth = coned.utils.isMobile() ? ' width: 100%;' : '';

    document
        .getElementsByClassName('coned-chart__container')[0]
        .setAttribute('style', 'height:' + chartHeight + 'px;' + chartWidth);
};

/**
 * Fix year vertical line in the corresponding place
 * @param {String} chartId
 */
coned.chart.fixYearLine = function (chartId) {
    var chart = document.getElementById(chartId),
        existLine = chart.getElementsByClassName('text-line-year')[0];

    if (!existLine) return;

    var yearLine = existLine.getElementsByTagName('line')[0],
        yearText = existLine.getElementsByTagName('text')[0],
        x1 = parseInt(yearLine.getAttribute('x1')),
        linex1 = x1 - 20,
        textx1 = x1 + 50;

    yearLine.setAttribute('x1', linex1);
    yearLine.setAttribute('x2', linex1);

    yearText.setAttribute('transform', '');
    yearText.setAttribute('x', textx1 - 37);
    yearText.setAttribute('y', 16);

    d3.select('#' + chartId)
        .select('.text-line-year')
        .insert('rect', 'text')
        .attr('class', 'coned-chart__line-rect')
        .attr('width', '35')
        .attr('height', '15')
        .attr('x', linex1)
        .attr('y', '0');
};

/**
 * Create border on y-axis values
 * @param {String} chartId
 */
coned.chart.createRect = function (chartId, fixedSubscription, maxValue) {
    var width = maxValue <= 4 ? '41' : '47';

    d3.select('#' + chartId)
        .select('.c3-axis-y')
        .attr('clip-path', null);

    d3.select('#' + chartId)
        .select('.c3-axis-y')
        .selectAll('.tick')
        .select('.coned-chart__line-rect')
        .remove('rect');

    d3.select('#' + chartId)
        .select('.c3-axis-y')
        .selectAll('.tick')
        .insert('rect', 'text')
        .attr('class', 'coned-chart__line-rect')
        .attr('width', width)
        .attr('height', '15')
        .attr('x', '0.5')
        .attr('y', '-8');

    d3.select('#' + chartId)
        .select('.c3-axis-y')
        .selectAll('.tick')
        .selectAll('text')
        .selectAll('tspan')
        .attr('x', '4');

    if (fixedSubscription) {
        var fixedSubsYAxis = d3
            .select('#' + chartId)
            .select('.text-line-overage')
            .select('rect')._groups[0][0];

        if (!fixedSubsYAxis) {
            var allfixedSubscriptionYaxis = d3.select('#' + chartId).selectAll('.text-line-overage')
                    ._groups[0],
                allYvalue = [];

            for (var position = 0; position < allfixedSubscriptionYaxis.length; position++) {
                var actualYAxis = allfixedSubscriptionYaxis[position],
                    yValue = actualYAxis.getElementsByTagName('line')[0].getAttribute('y1');
                allYvalue.push(yValue);
            }

            for (var yAxis = 0; yAxis < allYvalue.length; yAxis++) {
                d3.select('#' + chartId)
                    .select('.text-line-overage')
                    .insert('rect', 'text')
                    .attr('class', 'coned-chart__line-rect')
                    .attr('width', width)
                    .attr('height', '15')
                    .attr('x', '0.5')
                    .attr('y', allYvalue[yAxis] - 7.5);

                d3.select('#' + chartId)
                    .selectAll('.text-line-overage')
                    .select('line')
                    .style('display', 'none');
            }
        }

        d3.select('#' + chartId)
            .selectAll('.text-line-overage')
            .select('text')
            .attr('dy', 3)
            .attr('dx', 2);

        setTimeout(function () {
            d3.select('#' + chartId)
                .selectAll('.text-line-overage')
                .select('text')
                .attr('x', 2)
                .attr('text-anchor', 'start');
        }, 100);
    }
};

/**
 * Delete several year vertical line
 * @param {String} chartId
 */
coned.chart.deleteYearRect = function (chartId) {
    var chart = document.getElementById(chartId),
        existLine = chart.getElementsByClassName('text-line-year')[0];

    if (!existLine) return;

    d3.select('#' + chartId)
        .select('.text-line-year')
        .remove('rect');

    d3.select('#' + chartId)
        .select('.c3-axis-y')
        .select('.tick')
        .remove('rect');
};

/**
 * Fix chart height
 */
coned.chart.resizeWindow = function (chartId, chart, regExp, fixedSubscription, amountDigits) {
    var $conedActualChart = document.getElementById(chartId);

    if ($conedActualChart) {
        setTimeout(function () {
            coned.chart.resizeChart(chartId, chart, fixedSubscription, amountDigits);
            coned.chart.fixXaxisValues(chartId, regExp);
        }, 300);

        coned.chart.fixChartHeight(chartId);
    }
};

/**
 * Resize chart when window is resize
 */
coned.chart.resizeChart = function (chartId, chart, fixedSubscription, amountDigits) {
    coned.chart.deleteYearRect(chartId);
    chart.resize();
    coned.chart.createRect(chartId, fixedSubscription, amountDigits);
    coned.chart.fixYearLine(chartId);
};

/**
 * Update the x-axis value to remove the month
 */
coned.chart.fixXaxisValues = function (chartId, regExp) {
    var chart = document.getElementById(chartId),
        xaxis = chart.getElementsByClassName('c3-axis-x')[0],
        tick = xaxis.getElementsByTagName('g'),
        date,
        newDate;

    for (var index = 0; index < tick.length; index++) {
        date = tick[index].getElementsByTagName('tspan')[0].textContent;
        newDate = date.replace(regExp, '');
        newDate = newDate.replace('-', ' ');

        tick[index].getElementsByTagName('tspan')[0].textContent = newDate;
    }
};

/**
 * Open or close the table with the chart information
 */
coned.chart.showTableInfo = function(table, chartId, showTable) {
    if (showTable === 'true') {
        table.getElementsByClassName('js-chart-table-content')[0].removeAttribute('hidden');
        table.getElementsByClassName('js-coned-chart-table-button')[0].setAttribute('aria-expanded', true);
        document.getElementsByClassName('js-chart-table')[0].classList.add('expanded');
        table.getElementsByClassName('js-table-icon')[0].classList.add('coned-chart__table-icon--up');
        document.getElementById(chartId + '_table').removeAttribute('hidden');
    } else {
        table.getElementsByClassName('js-chart-table-content')[0].setAttribute('hidden', true);
        table.getElementsByClassName('js-coned-chart-table-button')[0].setAttribute('aria-expanded', false);
        document.getElementsByClassName('js-chart-table')[0].classList.remove('expanded');
        table.getElementsByClassName('js-table-icon')[0].classList.remove('coned-chart__table-icon--up');
        document.getElementById(chartId + '_table').setAttribute('hidden', true);
    }
}

/**
 * Create the table with the chart information
 */
coned.chart.createTable = function(chartId, dateList, truncateTotal, allCompleteMonths, chartData, legendNames, typeChart, subscriptionValues) {
    var chartModuleContent = document.getElementsByClassName('js-chart-module-content')[0],
        isBarChart = typeChart === "barChart",
        barsData = chartData.slice(1),
        tableContainer = document.createElement("div"),
        table = document.createElement("table"),
        tblHead = table.createTHead(),
        headRow = tblHead.insertRow(0),
        dateTitle = document.createElement("th"),
        bodyRow = table.createTBody(), 
        determinantYAxis,
        subscriptionText = [], 
        subscriptionValue = [];

    // table head
    for (var legend = 0; legend < chartData.length; legend++) {
        if (chartData[legend][0] === "label") {
            headRow.appendChild(dateTitle);
            dateTitle.setAttribute("scope","col");
            dateTitle.innerHTML = (isBarChart) ? legendNames.billingCycleTable : legendNames.dateTable;
        } else {
            var determinant = chartData[legend][0] === "PeakdeterminantDay" || chartData[legend][0] === "OffPeakdeterminantDay" || chartData[legend][0] === "NonSummerdeterminantDay" ? 'determinantDay' : chartData[legend][0],
                columnTitle = legendNames[determinant];

            if (!columnTitle.includes("Determinant") && !columnTitle.includes("Fixed") && !columnTitle.includes("Level")) {
                var headColumn = document.createElement("th");

                headColumn.setAttribute("scope","col");
                headRow.appendChild(headColumn);
                headColumn.innerHTML = columnTitle;
            }
        }
    }

    // table body
    for (var barIndex = 0; barIndex < chartData[0].length-1; barIndex++) {
        var dataRow = bodyRow.insertRow(barIndex),
            dateColumn = document.createElement("td"),
            determinantText = "",
            dateString = chartData[0][barIndex+1],
            date;

        dataRow.appendChild(dateColumn);

        // add data
        for (var info = 0; info < barsData.length; info++) {
            if (barsData[info][0].includes("determinant") || barsData[info][0].includes("Determinant")) {
                if (barsData[info][barIndex+1] != null) {
                    determinantText = legendNames[barsData[info][0]] === undefined ? legendNames["determinantDay"] : legendNames[barsData[info][0]];
                }
            } else if (!barsData[info][0].includes("fixed")) {
                var infoColumn = document.createElement("td"),
                    value = barsData[info][barIndex+1];

                dataRow.appendChild(infoColumn);

                if (isBarChart) {
                    if (truncateTotal === true) {
                        var regexTruncateNumber = /[0-9]*(.[0-9])/g,
                            matchNumber;

                        matchNumber = (value).toString().match(regexTruncateNumber);
                        infoColumn.innerHTML =  matchNumber ? matchNumber[0] : value + '.0';
                    } else {
                        infoColumn.innerHTML = (value !== null) ? value.toFixed(1) : "";
                    }
                } else {
                    var total = value.toFixed(2);
                    total = (total === '0.00') || (total === '0.0') ? '0' : total;
                    infoColumn.innerHTML = total;
                }
            }
            else {
                var conedChartTableButton = chartModuleContent.getElementsByClassName(
                    'js-coned-chart-table-button'
                )[0];
                determinantYAxis = true;

                // add the top text on the table for the subscription values
                // thermostat b
                if (subscriptionValues.length === 1 && legendNames[barsData[info][0]].includes('Level') && subscriptionText.length === 0) {
                    subscriptionText.push('Your Subscription Level is ');
                    subscriptionValue.push(subscriptionValues[0]);
                } else { // ipp charts
                    if (barsData[info].includes(0)) {
                        var fixedDate = dateString.replace(/-[0-9]*-/g," "),
                            subscriptionStartingText = 
                                conedChartTableButton
                                .dataset.fixedSubscriptionStartingText
                                .replace('{{FIXEDDATE}}', fixedDate);

                        subscriptionText.push(subscriptionStartingText);
                        subscriptionValue.push(subscriptionValues[0]);
                    } else {
                        if (subscriptionValues.length === 1 && subscriptionValue.length === 0) {

                            if (isBarChart) {
                                var firstDate = chartData[0][barIndex+1].replace(/-[0-9]*-/g," "), 
                                    lastDate = chartData[0][chartData[0].length-1].replace(/-[0-9]*-/g," "),
                                    subscriptionRangeText = 
                                        conedChartTableButton
                                        .dataset.fixedSubscriptionRangeText
                                        .replace('{{FIRSTDATE}}', firstDate)
                                        .replace('{{LASTDATE}}', lastDate);

                                subscriptionText.push(subscriptionRangeText);
                                subscriptionValue.push(subscriptionValues[0]);
                            } else {
                                var subscriptionRateText = 
                                        conedChartTableButton
                                        .dataset.fixedSubscriptionRateText;
                                        
                                subscriptionText.push(subscriptionRateText);
                                subscriptionValue.push(subscriptionValues[0]);
                            }
                        } else if (subscriptionValues.length > 1) {
                            var startDate = dateString.replace(/-[0-9]*-/g," ");

                            if (subscriptionValue.length === 0) {
                                subscriptionValue.push(barsData[info][barIndex+1]);
                            } else if (subscriptionValue[0] === barsData[info][barIndex+1]) {
                                var subscriptionRateUntilText = 
                                        conedChartTableButton
                                        .dataset.fixedSubscriptionRateUntilText
                                        .replace('{{STARTDATE}}', startDate);

                                subscriptionText[0] = (subscriptionRateUntilText);
                            } else if (subscriptionValue[0] !== barsData[info][barIndex+1] && subscriptionValues.length > subscriptionValue.length) {
                                var subscriptionRateChangedText = 
                                        conedChartTableButton
                                        .dataset.fixedSubscriptionRateChangedText
                                        .replace('{{STARTDATE}}', startDate);

                                subscriptionValue.push(barsData[info][barIndex+1]);
                                subscriptionText[1] = (subscriptionRateChangedText);
                            }
                        }
                    }
                }
            }
        }

        // add Date Column
        if (isBarChart) {
            date = dateList[dateString];
            date = coned.utils.isDesktop() == true ? date : (date.replace('-','- <br>'));
            dateColumn.innerHTML = date;

        } else {
            var demandDate,
                month,
                year,
                day;
            demandDate = dateList[dateString][0];
            demandDate = demandDate.replace(/-([\s\S][^T]*):[\s\S]*/g,"");
            demandDate = new Date (demandDate);
            month = allCompleteMonths[demandDate.getMonth()],
            year = demandDate.getFullYear();
            day = demandDate.getDate();
            day = day < 10 ? "0" + day : day;

            date = month + " " +  day + ", " + year;
            dateColumn.innerHTML = date + '</br>' + determinantText;
        }

    }

    //text for fixed subscription and subscription level y-axis
    if (determinantYAxis) {
        for (var determinantsValues = 0; determinantsValues < subscriptionValues.length; determinantsValues++) {
            var determinantYAxisText = document.createElement("p");

            determinantYAxisText.className = 'coned-chart__table-text';
            tableContainer.appendChild(determinantYAxisText);
            determinantYAxisText.innerHTML =  subscriptionText[determinantsValues] + '<span class="coned-chart__table-text--bold">'  + subscriptionValue[determinantsValues] + 'kW.' +'</span>';
        }
    }

    // add chart id to the table
    tableContainer.setAttribute("id", chartId + "_table");
    tableContainer.appendChild(table);
    tableContainer.setAttribute("hidden", "");
    table.setAttribute("tabindex", "0");
    document.getElementsByClassName('js-chart-table-content')[0].appendChild(tableContainer);
}
