// ==================== MOXIE MODULE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.MoxieModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        REDIRECT_LINK_CLASS: 'js-redirect',
        CLOSE_CLASS: 'con-close-area',
        CONTAINER_ID: 'concierge'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var MoxieModule = function ($moxieModule) {
        /**
         * PRIVATE METHODS
         */
        var $redirectLink, $moxieClose, $moxieContainer;

        var redirectClick = function (event) {
            event.preventDefault();

            // Close the chat
            if (coned.utils.isPatternLab()) {
                $moxieContainer.style.display = 'none';
            } else {
                $moxieClose.click();
            }

            // Redirect the user's current page
            window.parent.window.location.href = this.href;
        };

        var initializeData = function () {
            $redirectLink = $moxieModule.getElementsByClassName(CONSTANTS.REDIRECT_LINK_CLASS)[0];
            $moxieClose = window.parent.document.getElementsByClassName(CONSTANTS.CLOSE_CLASS)[0];
            $moxieContainer = window.parent.document.getElementById(CONSTANTS.CONTAINER_ID);
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($redirectLink, redirectClick);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    MoxieModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return MoxieModule;
})();
