// ==================== BILL PAYMENT HISTORY COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.BillPaymentHistory = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        BILLING_PAYMENT_TABLE_CLASS: 'js-billing-payment-table',
        TITLES_CLASS: 'js-payment-titles',
        CHECKBOX_CHECKED_CLASS: 'coned-checkbox--checked',
        PAYMENTS_CHECKBOX_CLASS: 'js-check-payments',
        BILLING_PAYMENT_MODULE: 'js-billing-payment-body',
        ITEM_CLASS: 'js-item',
        PAYMENT_ITEM_CLASS: 'js-payment-item',
        BILLS_CHECKBOX_CLASS: 'js-check-bills',
        BILL_ITEM_CLASS: 'js-bill-item',
        BILL_SUMPORTAL_CLASS: 'js-sumportal-message',
        BILL_CANCELLED_CLASS: 'billing-payment-item--cancelled',
        BILL_ITEM_OPEN_CLASS: 'billing-payment-item--open',
        BILL_ITEM_ARROW_CLASS: 'js-open-arrow',
        BILL_LINK_CLASS: 'js-bill-link',
        SHOW_MORE_CLASS: 'js-show-more',
        HIDE_SHOW_MORE_CLASS: 'billing-payment-body__show-more--hide',
        ITEM_HIDDEN_CLASS: 'billing-payment-item--hidden',
        ITEM_TABLE_HIDDEN: 'animated-hero--animation-item',
        ITEM_TABLE_CLASS: 'js-item-table',
        FORM_LOADING: 'js-form-loading',
        DIV_LOADING_CLASS: 'js-div-loading',
        DIV_LOADING_HIDDEN_CLASS: 'form-loading--hidden',
        SITECORE_ID_NAME: 'billingUsageScId',
        ACCOUNT_MAID_NAME: 'accountMaid',
        TABLE_ERROR: 'js-table-error',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        CHECKBOX_SELECTOR: 'js-checkbox-selector',
        ERROR_CONTAINER_CLASS: 'billing-module--sides-padding billing-module--top-bottom-padding transactional__error',
        ERROR_ICON_CLASSES: 'transactional__error-icon icon-report-problem',
        HIDDEN_CLASS: 'hidden',
        LOAD_PAYMENTS_PARAM: 'loadPayments',
        FILTER_PAYMENTS_PARAM: 'filterPayments',
        CHECKBOX_CLICKED_EVENT: 'checkbox-clicked',
        DATA_GRID_MODULE: 'js-data-grid',

        // Tagging
        TAGGING_SELECTOR: '[data-tag-preffix]',
        NORMAL_TYPE_TAGGING: 'Normal',
        CANCELLED_TYPE_TAGGING: 'Cancelled',
        BILL_LINK_TAGGING: 'bill.click',
        CANCELLED_APPEARANCE_TAGGING: 'cancelledbill.appearance',

        //Class to modified focus in the checkbox
        FOCUS_LABEL_CHECKBOX: 'coned-checkbox--focus'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var BillPaymentHistory = function ($bpHistory) {
        /**
         * PRIVATE METHODS
         */
        var $billingPaymentTable,
            $tableTitles,
            $billsOption,
            $billSumportal,
            $billItems,
            $billLinks,
            $billLinksTag,
            $currentBillLink,
            $billPaymentModule,
            $paymentsOption,
            $paymentItems,
            $selectedItems,
            $itemTable,
            $arrowItems,
            $showMore,
            $tableError,
            $serviceError,
            $formLoading,
            $loadingDiv,
            $newTab,
            $dataGrid,
            $checkboxesSelector,
            _firstLoad,
            _initialLoadCount;

        /**
         * Load a limited number of elements, based on the _initialLoadCount variable.
         * @param {HTMLCollection} $items List of payment or bills items .
         */
        var loadCollapsedBillingHistory =  function($items) {
            _.each($items, function ($item, index) {
                if (index < _initialLoadCount) {
                    $item.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            });
        };

        var optionEvent = function ($checkbox, $items) {
            var isAnyItemVisible;

            query.removeClass($showMore, CONSTANTS.HIDE_SHOW_MORE_CLASS);
            query.removeClass($tableTitles, CONSTANTS.HIDDEN_CLASS);

            $tableError.classList.add(CONSTANTS.HIDDEN_CLASS);

            if ($checkbox.checked) {
                query.addClass($checkbox.parentElement, CONSTANTS.CHECKBOX_CHECKED_CLASS);

                _.each($items, function ($item) {
                    query.removeClass($item, CONSTANTS.ITEM_HIDDEN_CLASS);
                });

                //On first load needs to display the items
                if (_firstLoad) {
                    loadCollapsedBillingHistory($items);

                    if ($paymentsOption.checked) {
                        _firstLoad = false;
                    }
                }
            } else {
                query.removeClass($checkbox.parentElement, CONSTANTS.CHECKBOX_CHECKED_CLASS);

                _.each($items, function ($item) {
                    query.addClass($item, CONSTANTS.ITEM_HIDDEN_CLASS);
                });
            }

            // Hide tables and load more button if no option is checked
            if (!$billsOption.checked && !$paymentsOption.checked) {
                query.addClass($showMore, CONSTANTS.HIDE_SHOW_MORE_CLASS);
                query.addClass($tableTitles, CONSTANTS.HIDDEN_CLASS);
                $billPaymentModule.dataset.filterCriteria = '';
            }

            // change filter criteria
            if ($billsOption.checked && $paymentsOption.checked) {
                $billPaymentModule.dataset.filterCriteria = CONSTANTS.ITEM_CLASS;
            } else if ($billsOption.checked) {
                $billPaymentModule.dataset.filterCriteria = CONSTANTS.BILL_ITEM_CLASS;
            } else if ($paymentsOption.checked) {
                $billPaymentModule.dataset.filterCriteria = CONSTANTS.PAYMENT_ITEM_CLASS;
            }

            // Display summary portal if bills are selected
            if ($billSumportal) {
                $billsOption.checked ? $billSumportal.classList.remove(CONSTANTS.HIDDEN_CLASS) : $billSumportal.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            $selectedItems = $bpHistory.getElementsByClassName(
                $billPaymentModule.dataset.filterCriteria
            );

            if ($selectedItems.length) {
                // show load more cta if any item still hidden
                _.each($selectedItems, function ($item) {
                    if (query.hasClass($item, CONSTANTS.HIDDEN_CLASS)) {
                        isAnyItemVisible = true;
                    }
                });
              
                if (isAnyItemVisible) {
                    $showMore.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $showMore.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                if ($billsOption.checked || $paymentsOption.checked) {
                    $tableError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $showMore.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $tableError.focus();
                }
            }

            if ($dataGrid) {
                // Trigger checkbox event so data grid module can set tabindex 0 on first visible cell
                coned.utils.triggerEvent($dataGrid, CONSTANTS.CHECKBOX_CLICKED_EVENT);
            }
        };

        var billItemEvent = function (event) {
            event.preventDefault();

            query.addClass($itemTable, CONSTANTS.ITEM_TABLE_HIDDEN);

            var $itemClicked = event.target,
                $billItem = query.selectParentElement($itemClicked, CONSTANTS.BILL_ITEM_CLASS),
                $selectedParent = this.parentNode.parentNode.parentNode,
                $selectItems = $selectedParent.getElementsByClassName(CONSTANTS.ITEM_TABLE_CLASS),
                itemTimeAnimation = 0,
                itemTimeSpeed = 200;

            if (!query.hasClass($billItem, CONSTANTS.BILL_ITEM_OPEN_CLASS)) {
                query.removeClass($billItems, CONSTANTS.BILL_ITEM_OPEN_CLASS);
                query.addClass($billItem, CONSTANTS.BILL_ITEM_OPEN_CLASS);

                _.each($selectItems, function ($item) {
                    setTimeout(function () {
                        $item.classList.remove(CONSTANTS.ITEM_TABLE_HIDDEN);
                    }, itemTimeAnimation);

                    itemTimeAnimation += itemTimeSpeed;
                });
            } else {
                query.removeClass($billItem, CONSTANTS.BILL_ITEM_OPEN_CLASS);
                query.addClass($itemTable, CONSTANTS.ITEM_TABLE_HIDDEN);
            }
        };

        var billLinkEvent = function (event) {
            event.preventDefault();

            var $billItem = query.selectParentElement(event.target, CONSTANTS.BILL_ITEM_CLASS),
                serviceUrl,
                params,
                isCsv;

            // Set elements depending on the item clicked
            $currentBillLink = query.selectParentElement(event.target, CONSTANTS.BILL_LINK_CLASS);
            $serviceError = $billItem.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            isCsv = $currentBillLink.dataset.iscsv;
            serviceUrl = isCsv ? $bpHistory.dataset.documentCsvServiceUrl : $bpHistory.dataset.billServiceUrl

            // If bill link is available, open the link in a new tab
            if ($currentBillLink.dataset.billLink == 'true') {
                window.open($currentBillLink.href);

                return;
            }

            // Service data
            params = {
                ScId: $bpHistory.dataset.scid,
                Maid: $bpHistory.dataset.accountMaid
                
            };

            if (isCsv) {
                params.BillId = $currentBillLink.dataset.billId;
            } else {
                params.DocumentId = $currentBillLink.dataset.documentId
                params.BillDate = $currentBillLink.dataset.billDate;
                params.Type = $currentBillLink.dataset.type;
            }

            // Before doing the service call, lets create the new tab where the link will be opened, so it won't be blocked by browser security
            $newTab = window.open();

            // Service call
            query.getData(serviceUrl, successBillService, errorBillService, params, $formLoading);

            //Check if it's csv and safari cause it didn't support correctly the .close function
            if (isCsv && !coned.utils.isSafari()) {
                setTimeout(function () {
                    $newTab.close();
                }, 5000);
            }
        };

        var successBillService = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.GET_BILL_LINK, redirectPage, errorBillService);
            } else if (data.Link && data.Link != '') {
                redirectPage(data);
            } else {
                errorBillService(data);
            }
        };

        var taggingBillLink = function (event) {
            var $target = event.currentTarget,
                $bill = query.selectParentElement($target, CONSTANTS.BILL_ITEM_CLASS);

            dataLayer.push({
                event: $target.dataset.tagPreffix + CONSTANTS.BILL_LINK_TAGGING,
                billType: query.hasClass($bill, CONSTANTS.BILL_CANCELLED_CLASS)
                    ? CONSTANTS.CANCELLED_TYPE_TAGGING
                    : CONSTANTS.NORMAL_TYPE_TAGGING
            });
        };

        var taggingCheckCancelledBills = function () {
            var $cancelledBills = $bpHistory.getElementsByClassName(CONSTANTS.BILL_CANCELLED_CLASS);

            if ($cancelledBills.length > 0) {
                dataLayer.push({
                    event: CONSTANTS.CANCELLED_APPEARANCE_TAGGING
                });
            }
        };

        var redirectPage = function (data) {
            // Set link and flag for future click
            $currentBillLink.dataset.billLink = 'true';
            $currentBillLink.href = data.Link;

            // Set file location in the opened tab
            $newTab.location.href = data.Link;
        };

        var errorBillService = function (data) {
            var $serviceErrorMessage = $serviceError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];

            // Close new tab opened
            $newTab.close();

            // Display error message
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var getBillPaymentsTable = function () {
            var serviceUrl = $bpHistory.dataset.tableServiceUrl,
                params;

            // Service Data
            params = {
                AccountMaid: query.getFormInputValue(document, CONSTANTS.ACCOUNT_MAID_NAME),
                ScId: query.getFormInputValue(document, CONSTANTS.SITECORE_ID_NAME),
                isMasterAccount: $bpHistory.dataset.isMasterAccount,
                isSubordinateAccount: $bpHistory.dataset.isSubordinateAccount
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successGetBillPaymentTable,
                errorGetBillPaymentTable,
                params,
                true
            );
        };

        var successGetBillPaymentTable = function (data) {
            if (coned.utils.isPatternLab()) {
                var isMasterLevelAccount = $bpHistory.dataset.patternlabMasterLevelAccount,
                    content;
                if (coned.utils.isOru()) {
                        content = isMasterLevelAccount ?
                          coned.plConstants.GET_BILLING_PAYMENT_MASTER_LEVEL_TABLE_HTML 
                          : coned.plConstants.GET_BILLING_PAYMENT_TABLE_ORU_HTML;
                } else {
                    content = isMasterLevelAccount ?
                      coned.plConstants.GET_BILLING_PAYMENT_MASTER_LEVEL_TABLE_HTML
                      : coned.plConstants.GET_BILLING_PAYMENT_TABLE_HTML;
                }

                query.getData(
                  content,
                  setBillPaymentTable,
                  errorGetBillPaymentTable
                );
            } else {
                setBillPaymentTable(data);
            }
        };

        var setBillPaymentTable = function (data) {
            // Hide div loading and add billing and payment table received to the module container
            $loadingDiv.classList.add(CONSTANTS.DIV_LOADING_HIDDEN_CLASS);
            $billingPaymentTable.innerHTML = data;

            // Once the div is loaded, now initialize all related functionality
            initializeTableData();
            initializeEvents();
            coned.utils.initializeModules($billingPaymentTable);
            taggingCheckCancelledBills();
        };

        var errorGetBillPaymentTable = function (data) {
            var $errorContainer = document.createElement('p'),
                $errorIcon = document.createElement('span'),
                $errorMessage = document.createElement('span');

            $errorContainer.className = CONSTANTS.ERROR_CONTAINER_CLASS;
            $errorIcon.classList = CONSTANTS.ERROR_ICON_CLASSES;
            $errorMessage.innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;

            $errorContainer.appendChild($errorIcon);
            $errorContainer.appendChild($errorMessage);

            // Hide div loading and add error message to the module container
            $loadingDiv.classList.add(CONSTANTS.DIV_LOADING_HIDDEN_CLASS);
            $bpHistory.appendChild($errorContainer);

            // Also disable checkboxes, since they wont work without data
            $billsOption.disabled = true;
            $paymentsOption.disabled = true;
        };

        /**
         *  To do: For next approch review this functionality
         * @param {Event JS} event
         */
        var addFocusLabelCheckbox = function (event) {
            //Using for distinguish between checkbox and checkbox-equal-hierarchy
            var target = event.target.classList.contains(CONSTANTS.CHECKBOX_EQUAL_HIERARCHY)
                ? event.target.nextElementSibling
                : event.target.parentElement;
            query.addClass(target, CONSTANTS.FOCUS_LABEL_CHECKBOX);
        };

        /**
         *  To do: For next approch review this functionality
         * @param {Event JS} event
         */
        var deleteFocusLabelCheckbox = function (event) {
            //Using for distinguish between checkbox and checkbox-equal-hierarchy
            var target = event.target.classList.contains(CONSTANTS.CHECKBOX_EQUAL_HIERARCHY)
                ? event.target.nextElementSibling
                : event.target.parentElement;
            query.removeClass(target, CONSTANTS.FOCUS_LABEL_CHECKBOX);
        };

        var initializeData = function () {
            $billingPaymentTable = $bpHistory.getElementsByClassName(
                CONSTANTS.BILLING_PAYMENT_TABLE_CLASS
            )[0];
            $billsOption = $bpHistory.getElementsByClassName(CONSTANTS.BILLS_CHECKBOX_CLASS)[0];
            $paymentsOption = $bpHistory.getElementsByClassName(
                CONSTANTS.PAYMENTS_CHECKBOX_CLASS
            )[0];
            $loadingDiv = $bpHistory.getElementsByClassName(CONSTANTS.DIV_LOADING_CLASS)[0];
            $checkboxesSelector = $bpHistory.getElementsByClassName(CONSTANTS.CHECKBOX_SELECTOR);

            //Load the tables data from the service
            getBillPaymentsTable();
        };

        var initializeTableData = function () {
            $tableTitles = $bpHistory.getElementsByClassName(CONSTANTS.TITLES_CLASS)[0];
            $billItems = $bpHistory.getElementsByClassName(CONSTANTS.BILL_ITEM_CLASS);
            $billLinks = $bpHistory.getElementsByClassName(CONSTANTS.BILL_LINK_CLASS);
            $paymentItems = $bpHistory.getElementsByClassName(CONSTANTS.PAYMENT_ITEM_CLASS);
            $showMore = $bpHistory.getElementsByClassName(CONSTANTS.SHOW_MORE_CLASS)[0];
            $itemTable = $bpHistory.getElementsByClassName(CONSTANTS.ITEM_TABLE_CLASS);
            $arrowItems = $bpHistory.getElementsByClassName(CONSTANTS.BILL_ITEM_ARROW_CLASS);
            $billPaymentModule = $bpHistory.getElementsByClassName(
                CONSTANTS.BILLING_PAYMENT_MODULE
            )[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $tableError = $bpHistory.getElementsByClassName(CONSTANTS.TABLE_ERROR)[0];
            $dataGrid = $bpHistory.getElementsByClassName(CONSTANTS.DATA_GRID_MODULE)[0];
            $billSumportal = $bpHistory.getElementsByClassName(CONSTANTS.BILL_SUMPORTAL_CLASS)[0];
            _initialLoadCount = parseInt($billPaymentModule.dataset.initialLoad, 10);
            _firstLoad = true;

            // Check for bill links that need to be tagged
            $billLinksTag = $bpHistory.querySelectorAll(CONSTANTS.TAGGING_SELECTOR);

            //Bills option is selected by default
            if ($billsOption) {
                $billsOption.checked = true;
                query.addClass($billsOption.parentElement, CONSTANTS.CHECKBOX_CHECKED_CLASS);

                // Display summary portal
                if ($billSumportal) {
                    $billsOption.checked ? $billSumportal.classList.remove(CONSTANTS.HIDDEN_CLASS) : $billSumportal.classList.add(CONSTANTS.HIDDEN_CLASS);
                }

            }
            
            loadCollapsedBillingHistory($billItems);

            // If items amount exceeds the initial load, then display the show more button
            if (_initialLoadCount < $billItems.length) {
                query.removeClass($showMore, CONSTANTS.HIDDEN_CLASS);
            }
        };

        var initializeEvents = function () {
            // Click on bills checkbox
            $billsOption && $billsOption.addEventListener('change', function () {
                optionEvent($billsOption, $billItems);
            });

            // Click on payments checkbox
            $paymentsOption && $paymentsOption.addEventListener('change', function () {
                optionEvent($paymentsOption, $paymentItems);
            });

            // Add listener to the clicks
            _.each($billLinks, function ($billLink) {
                coned.utils.addGeneralListeners($billLink, billLinkEvent);
            });

            // Expand/collapse of bill row
            _.each($arrowItems, function ($arrowItem) {
                coned.utils.addGeneralListeners($arrowItem, billItemEvent);
            });

            // Add tag listeners to the clicks
            _.each($billLinksTag, function ($billLink) {
                coned.utils.addGeneralListeners($billLink, taggingBillLink);
            });

            for (
                var checkboxIndex = 0;
                checkboxIndex < $checkboxesSelector.length;
                checkboxIndex++
            ) {
                var $checkboxSelector = $checkboxesSelector[checkboxIndex];

                //Add Listener for active focus style in label element
                $checkboxSelector.addEventListener('focusin', addFocusLabelCheckbox);
                $checkboxSelector.addEventListener('focusout', deleteFocusLabelCheckbox);
            }

            coned.utils.doActionByURLParam(CONSTANTS.LOAD_PAYMENTS_PARAM, function(){
                $paymentsOption.checked = true;
                optionEvent($paymentsOption, $paymentItems);
            });

            coned.utils.doActionByURLParam(CONSTANTS.FILTER_PAYMENTS_PARAM, function(){
                $paymentsOption.checked = true;
                optionEvent($paymentsOption, $paymentItems);
                $billsOption.checked = false;
                optionEvent($billsOption, $billItems);
            });

            
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    BillPaymentHistory.prototype.isLoaded = function () {
        return isLoaded;
    };

    return BillPaymentHistory;
})();
