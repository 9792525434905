// ==================== ENERGY SAVINGS =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.EnergySavings = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Inputs selector
        INPUT_SELECTOR: '.js-coned-input',
        SELECT_SELECTOR: '.js-coned-select',
        CHECKBOX_SELECTOR: '.js-coned-checkbox',
        EMAIL_SELECTOR: 'js-energy-savings-email',
        EFFICIENCY_PROGRAM_CHECKBOXES: 'js-efficiency-program-checkbox',        
        CAPTCHA_ELEMENT: 'js-recaptcha',
        SUBMIT_SELECTOR: 'js-energy-savings-submit',
        
        // Form selector
        ENERGY_SAVINGS_FORM: 'js-energy-savings-form',
        ENERGY_SAVINGS_CONTAINER_SELECTOR: 'js-energy-savings-container',
        FORM_LOADING: 'js-form-loading',
        FORM_SITECORE_ID: 'ScId',
        
        // Messages selector
        CONFIRMATION_SCREEN_SELECTOR: 'js-confirmation-screen',
        SUCCESS_MESSAGE_SELECTOR: 'js-energy-savings-success',
        ERROR_MESSAGE_SELECTOR: 'js-energy-savings-error',
        SUCCESS_PARAGRAPHS_CONTAINER_SELECTOR: 'js-succes-paragraphs-container',

        // Other
        FORM_WRAPPER_NO_BACKGROUND_CLASS: 'coned-form-wrapper--no-background',
        ENERGY_SAVINGS_CONTAINER_NO_PADDING_CLASS: 'energy-savings__container--no-padding',
        HIDDEN_CLASS: 'hidden',
        URL_TILE_REDIRECT_PARAM: 'tileRedirect',
        INPUT_ERROR_MESSAGE: 'coned-input-message--error',
        FIELD_ERROR_CLASS: 'coned-field-error-wrapper',
        CONED_FIELD: '.coned-field',
        EMAIL_TOKEN: '@EMAIL',
        P_TAG: 'p'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var EnergySavings = function ($EnergySavings) {
        /**
         * PRIVATE METHODS
         */
        var // Fieldset Selectors
            $inputs,
            $selects,
            $checkboxes,
            $submitButton,
            $energyForm,
            $formLoading,
            $energySavingsContainer,
            $confirmationScreen,
            $successMessage,
            $errorMessage,
            $successParagraphsContainer,
            $emailInput,
            $efficiencyProgramCheckboxes,
            $captchaElement,
            recaptcha,
            _hasCaptcha,
            _userAuthenticated;

        var submitClickHandler = function () {
            if (_userAuthenticated) {
                submitForm();
            } else {
                checkRecaptcha();
            }
        };

        // /**
        //  * Handles the form submit
        //  */
        var submitForm = function () {
            var serviceUrl = $energyForm.dataset.serviceUrl,
                response = _hasCaptcha ? recaptcha.getResponse() : null,
                params = {},
                verify;

            if (_hasCaptcha && response && response !== '') {
                verify = response;
            }

            params = {
                ScId: query.getFormInputValue($energyForm, CONSTANTS.FORM_SITECORE_ID),
                FormName: $energyForm.dataset.formName,
                Verify: verify
            };

            _.each($checkboxes, function ($checkbox) {
                params[$checkbox.name] = $checkbox.checked;               
            })

            _.each($efficiencyProgramCheckboxes, function ($checkbox) {
                if($checkbox.checked) {
                    if (params[$checkbox.name]) {
                        params[$checkbox.name].push($checkbox.value);
                    } else {
                        params[$checkbox.name] = [$checkbox.value];
                    }
                }                
            })
            _.each($inputs, function ($input) {
                params[$input.name] = $input.value;
            });

            _.each($selects, function ($select) {
                params[$select.name] = $select.value;
            });            

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successEnergySavingsCallback,
                errorEnergySavingsCallback,
                params,
                true,
                $formLoading
            );
        };

        var successEnergySavingsCallback = function () {
            dataLayer.push({
                event: 'EEDM.submit.success'
            });
            showSucessMessage();
        };

        var errorEnergySavingsCallback = function () {
            dataLayer.push({
                event: 'EEDM.submit.error'
            });

            if (!_userAuthenticated) {
                recaptcha.reset();
            }

            showErrorMessage();
        };

        var updateEmailToken = function () {
            var finalEmail = $emailInput.value,
                $paragraphs = $successParagraphsContainer.getElementsByTagName(CONSTANTS.P_TAG);

            _.each($paragraphs, function ($paragrah) {
                $paragrah.innerHTML = $paragrah.innerHTML.replace(CONSTANTS.EMAIL_TOKEN, finalEmail);
            });            
        };

        var updateEnergySavingsContainerStyles = function () {
            query.addClass($energySavingsContainer, CONSTANTS.FORM_WRAPPER_NO_BACKGROUND_CLASS);
            query.addClass($energySavingsContainer, CONSTANTS.ENERGY_SAVINGS_CONTAINER_NO_PADDING_CLASS);
        };

        var showSucessMessage = function () {
            updateEmailToken();
            updateEnergySavingsContainerStyles();

            $confirmationScreen.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $successMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $energyForm.classList.add(CONSTANTS.HIDDEN_CLASS);

            dataLayer.push({
                event: 'EEDM.confirmation.message'
            });

            window.scrollTo(0, 0);
        };

        var showErrorMessage = function () {
            updateEnergySavingsContainerStyles();

            $confirmationScreen.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $errorMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $energyForm.classList.add(CONSTANTS.HIDDEN_CLASS);

            dataLayer.push({
                event: 'EEDM.system.error'
            });

            window.scrollTo(0, 0);
        };

        var modifySelect = function (event) {
            var $select = event.currentTarget,
                $field = $($select).closest(CONSTANTS.CONED_FIELD)[0],
                $errorInputMessage = $field.getElementsByClassName(CONSTANTS.FIELD_ERROR_CLASS)[0];

            $select.classList.remove(CONSTANTS.INPUT_ERROR_MESSAGE);
            if ($errorInputMessage) {
                $errorInputMessage.style.display = 'none';
            }
        };

        var initializeAnalytics = function () {
            var urlParameters = coned.utils.getUrlParameters() || {};

            dataLayer.push({
                energySavingsForm: $energyForm.dataset.formName
            });

            if (urlParameters[CONSTANTS.URL_TILE_REDIRECT_PARAM]) {
                dataLayer.push({
                    event: 'EEDM.start'
                });
            }
        };

        /**
         * Inits data in the module.
         */
        var initializeData = function () {
            $submitButton = $EnergySavings.getElementsByClassName(CONSTANTS.SUBMIT_SELECTOR)[0];
            $energyForm = $EnergySavings.getElementsByClassName(CONSTANTS.ENERGY_SAVINGS_FORM)[0];
            $formLoading = $EnergySavings.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $energySavingsContainer = $EnergySavings.getElementsByClassName(
                CONSTANTS.ENERGY_SAVINGS_CONTAINER_SELECTOR
            )[0];
            $confirmationScreen = $EnergySavings.getElementsByClassName(
                CONSTANTS.CONFIRMATION_SCREEN_SELECTOR
            )[0];
            $successMessage = $EnergySavings.getElementsByClassName(
                CONSTANTS.SUCCESS_MESSAGE_SELECTOR
            )[0];
            $errorMessage = $EnergySavings.getElementsByClassName(
                CONSTANTS.ERROR_MESSAGE_SELECTOR
            )[0];
            $successParagraphsContainer = $EnergySavings.getElementsByClassName(
                CONSTANTS.SUCCESS_PARAGRAPHS_CONTAINER_SELECTOR
            )[0];
            $emailInput = $EnergySavings.getElementsByClassName(CONSTANTS.EMAIL_SELECTOR)[0];
            $captchaElement = document.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT);
            $inputs = $energyForm.querySelectorAll(CONSTANTS.INPUT_SELECTOR);
            $selects = $energyForm.querySelectorAll(CONSTANTS.SELECT_SELECTOR);
            $checkboxes = $energyForm.querySelectorAll(CONSTANTS.CHECKBOX_SELECTOR);
            $efficiencyProgramCheckboxes = $EnergySavings.getElementsByClassName(
                CONSTANTS.EFFICIENCY_PROGRAM_CHECKBOXES
            )
            _hasCaptcha = $captchaElement.length > 0;
            _userAuthenticated = $energyForm.dataset.userStatus;
        };

        /**
         * Inits events in the module.
         */
        var initializeEvents = function () {
            $($inputs).bind('keyup', function (event) {
                event.preventDefault();

                $submitButton.disabled = !$($energyForm).validate().checkForm();
            });

            $($selects).bind('change', function (event) {
                event.preventDefault();
                modifySelect(event);

                $submitButton.disabled = !$($energyForm).validate().checkForm();
            });         

            new coned.components.ValidateForm(
                '.' + CONSTANTS.ENERGY_SAVINGS_FORM,
                submitClickHandler
            );

            _.each($efficiencyProgramCheckboxes, function ($checkbox) {
                $checkbox.addEventListener('change', function (event) {
                    event.preventDefault();
                    $submitButton.disabled = !$($energyForm).validate().checkForm();
                });
            }); 

            if (_hasCaptcha) {
                recaptcha = new coned.components.Recaptcha($energyForm, submitForm, submitForm);
            }
        };

        var checkRecaptcha = function () {
            if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                recaptcha.checkRecaptcha();
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            initializeAnalytics();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    EnergySavings.prototype.isLoaded = function () {
        return isLoaded;
    };

    return EnergySavings;
})();
