// ==================== GENERAL CLAIMS COMPONENT =========================
/* global $ */
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.GeneralClaims = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Selector Classes
        LOCATION_ADDRESS_CONTAINER: 'js-location-address',
        TYPE_OF_INCIDENT_EXPLAIN: 'js-toi-explain',
        WEATHER_EXPLAIN: 'js-weather-explain',
        CONED_CONTACT_EXPLAIN: 'js-coned-contact-explain',
        CONED_CONTRACTOR_NAME: 'js-coned-contractor-name',
        POLICE_MESSAGE: 'js-police-message',
        INSURANCE_INFO: 'js-insurance-info',
        INSURANCE_EXPLAIN: 'js-insurance-explain',

        LOCATION_CHECKBOX: 'js-location-contact-checkbox',
        INCIDENT_CHECKBOXES: 'js-type-incident-checkbox',
        INCIDENT_MOTOR_VEHICLE_CHECKBOX_ID: 'generalClaimTypeIncidentMotorVehicle',
        WEATHER_CHECKBOXES: 'js-weather-checkbox',
        CONED_CONTACT_CHECKBOXES: 'js-coned-contact-radio',
        CONED_CONTRACTOR_RADIOS: 'js-coned-contractor-radio',
        CONED_CONTRACTOR_RADIOS_NAME: 'generalClaimConedContractor',
        POLICE_RADIOS: 'js-police-radio',
        INSURANCE_RADIOS: 'js-insurance-radio',

        TOTAL_AMOUNT_INPUT: 'js-total-amount',
        SIGNATURE_DATE_INPUT: 'js-signature-date',

        SUBMIT_BUTTON_SELECTOR: 'js-submit-button',

        // Helper Constants
        HIDDEN_CLASS: 'hidden',
        FILLED_CLASS: 'coned-input--filled',
        ARIA_HIDDEN: 'aria-hidden',

        FALSE_VALUE: 'false',
        INSURANCE_VALUE: 'insurance',
        OTHER_VALUE: 'other',
        TRUE_VALUE: 'true',
        YES_VALUE: 'yes',

        // Tagging
        LESS_EQUAL_TAG: '<=reference claim amount',
        GREATER_TAG: '>reference claim amount',
        VEHICLE_TAG: 'vehicle',
        NO_VEHICLE_TAG: 'novehicle',
        CONTRACTOR_TAG: 'contractor',
        NO_CONTRACTOR_TAG: 'nocontractor'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var GeneralClaims = function ($generalClaimsForm) {
        /**
         * PRIVATE METHODS
         */
        var $locationAddress,
            $incidentExplain,
            $weatherExplain,
            $conedContactExplain,
            $conedContractorName,
            $policeMessage,
            $insuranceInfo,
            $insuranceExplain,
            $locationCheckbox,
            $incidentCheckboxes,
            $incidentMotorVehicleCheckbox,
            $weatherCheckboxes,
            $conedContactRadios,
            $conedContractorRadios,
            $policeRadios,
            $insuranceRadios,
            $totalAmountInput,
            $signatureDateInput,
            $submitButton,
            _distributionListLimit;

        /**
         * Shows/hide and element according to visibility flag
         * @param {Boolean} isVisible Visibility flag (true: shows element, false: hides element)
         * @param {HTMLElement} $element Element to get set the visibility to.
         */
        var setElementVisibility = function (isVisible, $element) {
            if (isVisible) {
                $element.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $element.setAttribute(CONSTANTS.ARIA_HIDDEN, CONSTANTS.FALSE_VALUE);
            } else {
                $element.classList.add(CONSTANTS.HIDDEN_CLASS);
                $element.setAttribute(CONSTANTS.ARIA_HIDDEN, CONSTANTS.TRUE_VALUE);
            }
        };

        /**
         * Hides all elements when form is reset
         */
        var hideExtraFields = function () {
            $locationAddress && setElementVisibility(false, $locationAddress);
            $incidentExplain && setElementVisibility(false, $incidentExplain);
            $weatherExplain && setElementVisibility(false, $weatherExplain);
            $conedContactExplain && setElementVisibility(false, $conedContactExplain);
            $conedContractorName && setElementVisibility(false, $conedContractorName);
            $policeMessage && setElementVisibility(false, $policeMessage);
            $insuranceInfo && setElementVisibility(false, $insuranceInfo);
            $insuranceExplain && setElementVisibility(false, $insuranceExplain);
        };

        /**
        * Tagging for motor vehicle checkbox and coned contractor radio button
        */
        var vehicleContractorTagging = function () {
            if ($($generalClaimsForm).validate().checkForm()) {
                var contractorSelection = query.getRadioInputValue($generalClaimsForm, CONSTANTS.CONED_CONTRACTOR_RADIOS_NAME),
                    conedContractorSelected = contractorSelection === CONSTANTS.YES_VALUE,
                    vehicleSelected = $incidentMotorVehicleCheckbox.checked;

                dataLayer.push({
                    selections: 
                        (vehicleSelected ? CONSTANTS.VEHICLE_TAG : CONSTANTS.NO_VEHICLE_TAG) + 
                        '.' + 
                        (conedContractorSelected ? CONSTANTS.CONTRACTOR_TAG : CONSTANTS.NO_CONTRACTOR_TAG)
                })
            }
        };

        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            $locationAddress = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.LOCATION_ADDRESS_CONTAINER
            )[0];
            $incidentExplain = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.TYPE_OF_INCIDENT_EXPLAIN
            )[0];
            $weatherExplain = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.WEATHER_EXPLAIN
            )[0];
            $conedContactExplain = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.CONED_CONTACT_EXPLAIN
            )[0];
            $conedContractorName = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.CONED_CONTRACTOR_NAME
            )[0];
            $policeMessage = $generalClaimsForm.getElementsByClassName(CONSTANTS.POLICE_MESSAGE)[0];
            $insuranceInfo = $generalClaimsForm.getElementsByClassName(CONSTANTS.INSURANCE_INFO)[0];
            $insuranceExplain = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.INSURANCE_EXPLAIN
            )[0];

            $locationCheckbox = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.LOCATION_CHECKBOX
            )[0];
            $incidentCheckboxes = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.INCIDENT_CHECKBOXES
            );
            $incidentMotorVehicleCheckbox = document.getElementById(
                CONSTANTS.INCIDENT_MOTOR_VEHICLE_CHECKBOX_ID
            );
            $weatherCheckboxes = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.WEATHER_CHECKBOXES
            );
            $conedContactRadios = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.CONED_CONTACT_CHECKBOXES
            );
            $conedContractorRadios = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.CONED_CONTRACTOR_RADIOS
            );
            $policeRadios = $generalClaimsForm.getElementsByClassName(CONSTANTS.POLICE_RADIOS);
            $insuranceRadios = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.INSURANCE_RADIOS
            );

            $totalAmountInput = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.TOTAL_AMOUNT_INPUT
            )[0];
            $signatureDateInput = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.SIGNATURE_DATE_INPUT
            )[0];

            $submitButton = $generalClaimsForm.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON_SELECTOR
            )[0];

            _distributionListLimit =
                $generalClaimsForm.dataset &&
                $generalClaimsForm.dataset.distributionListAmount &&
                parseInt($generalClaimsForm.dataset.distributionListAmount)
                    ? parseInt($generalClaimsForm.dataset.distributionListAmount)
                    : 3000;
        };

        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            $locationCheckbox.addEventListener('change', function (event) {
                setElementVisibility(event.target.checked, $locationAddress);
            });

            $totalAmountInput.addEventListener('blur', function (event) {
                if (event.target.value !== '') {
                    dataLayer.push({
                        DLS:
                            parseFloat(event.target.value.replace(/[\$\,]/g, '')) <=
                            _distributionListLimit
                                ? CONSTANTS.LESS_EQUAL_TAG
                                : CONSTANTS.GREATER_TAG
                    });
                }
            });

            _.each($incidentCheckboxes, function ($checkbox) {
                $checkbox.addEventListener('change', function (event) {
                    if (event.target.value === CONSTANTS.OTHER_VALUE) {
                        setElementVisibility(event.target.checked, $incidentExplain);
                    }
                });
            });

            _.each($weatherCheckboxes, function ($checkbox) {
                $checkbox.addEventListener('change', function (event) {
                    if (event.target.value === CONSTANTS.OTHER_VALUE) {
                        setElementVisibility(event.target.checked, $weatherExplain);
                    }
                });
            });

            _.each($conedContactRadios, function ($radio) {
                $radio.addEventListener('change', function (event) {
                    setElementVisibility(
                        event.target.checked && event.target.value === CONSTANTS.YES_VALUE,
                        $conedContactExplain
                    );
                });
            });

            _.each($conedContractorRadios, function ($radio) {
                $radio.addEventListener('change', function (event) {
                    setElementVisibility(
                        event.target.checked && event.target.value === CONSTANTS.YES_VALUE,
                        $conedContractorName
                    );
                });
            });

            _.each($policeRadios, function ($radio) {
                $radio.addEventListener('change', function (event) {
                    setElementVisibility(
                        event.target.checked && event.target.value === CONSTANTS.YES_VALUE,
                        $policeMessage
                    );
                });
            });

            _.each($insuranceRadios, function ($radio) {
                $radio.addEventListener('change', function (event) {
                    setElementVisibility(
                        event.target.checked && event.target.value === CONSTANTS.INSURANCE_VALUE,
                        $insuranceInfo
                    );
                    setElementVisibility(
                        event.target.checked && event.target.value === CONSTANTS.OTHER_VALUE,
                        $insuranceExplain
                    );
                });
            });

            $generalClaimsForm.addEventListener('reset-form', hideExtraFields);

            if ($signatureDateInput) {
                var today = new Date(),
                    dd = today.getDate(),
                    mm = today.getMonth() + 1,
                    yyyy = today.getFullYear();

                today = mm + '/' + dd + '/' + yyyy;
                $signatureDateInput.value = today;
                $signatureDateInput.disabled = true;
                $signatureDateInput.classList.add(CONSTANTS.FILLED_CLASS);
            }

            coned.utils.addGeneralListeners($submitButton, vehicleContractorTagging);

            setTimeout(function () {
                coned.utils.checkInputsFilled($generalClaimsForm);
            }, 1000);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    GeneralClaims.prototype.isLoaded = function () {
        return isLoaded;
    };

    return GeneralClaims;
})();
