// ==================== TEXT SEARCH COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.TextSearch = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        TEXT_SEARCH_INPUT_SELECTOR: 'js-text-search-input',
        NO_TEXT_SEARCH_SELECTOR: 'js-no-text-search',
        PAGINATION_SELECTOR: 'js-pagination-wrapper',
        PAGINATION_RESULTS_SELECTOR: 'js-pagination-results',
        PAGINATION_RESULTS_TOTAL_SELECTOR: 'js-total',
        PAGINATION_RESET_SELECTOR: 'js-pagination-reset',
        PAGINATION_HIDDEN_ELEMENT: 'js-pagination-item-hidden',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var TextSearch = function ($textSearch) {
        /**
         * PRIVATE METHODS
         */
        var $textSearchInput,
            $textSearchContainer,
            $textSearchItems,
            $textSearchNoResults,
            $textSearchNoResultsHide,
            $paginationModule,
            $paginationResults,
            $filteredResults,
            $paginationTotal,
            $paginationReset,
            _isPaginationSearch,
            _textSearchContainerClass,
            _textSearchItemClass,
            _textSearchNoResutsClass,
            _textSearchNoResutsHideClass,
            _highlightClass,
            _textSearchInitialItemsMarkup,
            _previousFilterValue;

        var filterSearchItems = function () {
            // If the text search input hasn't change doesn't search
            if (_previousFilterValue !== $textSearchInput.value) {
                var filterValue = (_previousFilterValue = $textSearchInput.value),
                    searchItemText,
                    highlightPattern,
                    highlightPatternWithoutChildren,
                    highlightReplaceWith,
                    highlightText,
                    formattedText,
                    filteredItems = false,
                    textSearchItemChildren,
                    isItemFiltered;

                highlightPattern = new RegExp('(>[^<.]*)(' + filterValue + ')([^<.]*)', 'gi');
                highlightPatternWithoutChildren = new RegExp(
                    '(>[^<.]*)*(' + filterValue + ')([^<.]*)',
                    'gi'
                );
                highlightReplaceWith = '$1<span class="' + _highlightClass + '">$2</span>$3';

                _.each($textSearchItems, function ($textSearchItem, index) {
                    // At start no item is filtered
                    isItemFiltered = false;
                    textSearchItemChildren = 0;

                    // Searches children by children
                    Array.prototype.forEach.call(
                        $textSearchItem.children,
                        function ($textSearchItemChildren) {
                            // If the children doesn't have the class, it's a searchable element
                            if (
                                !$textSearchItemChildren.classList.contains(
                                    CONSTANTS.NO_TEXT_SEARCH_SELECTOR
                                )
                            ) {
                                // Reset highlight
                                $textSearchItemChildren.innerHTML =
                                    _textSearchInitialItemsMarkup[index][textSearchItemChildren];
                                searchItemText = $textSearchItemChildren.innerText;

                                if (
                                    searchItemText
                                        .toLowerCase()
                                        .indexOf(filterValue.toLowerCase()) > -1
                                ) {
                                    // Highlighting text
                                    formattedText = _.unescape($textSearchItemChildren.innerHTML);
                                    if ($textSearchItemChildren.childElementCount > 0) {
                                        highlightText = formattedText.replace(
                                            highlightPattern,
                                            highlightReplaceWith
                                        );
                                    } else {
                                        highlightText = formattedText.replace(
                                            highlightPatternWithoutChildren,
                                            highlightReplaceWith
                                        );
                                    }
                                    $textSearchItemChildren.innerHTML = highlightText;

                                    // Turn item filtered flag on
                                    isItemFiltered = true;
                                }
                                textSearchItemChildren++;
                            }
                        }
                    );

                    // If at leat one of the childrens of the items includes filtered text, resets item display. If not, hide it
                    if (isItemFiltered) {
                        $textSearchItem.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $textSearchItem.classList.remove(CONSTANTS.PAGINATION_HIDDEN_ELEMENT);

                        // Turn filter results flag on
                        filteredItems = true;
                    } else {
                        $textSearchItem.classList.add(CONSTANTS.HIDDEN_CLASS);
                        $textSearchItem.classList.add(CONSTANTS.PAGINATION_HIDDEN_ELEMENT);
                    }
                });

                // Show no results text if the filter didn't match any item
                if (!filteredItems) {
                    $textSearchNoResults.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    if ($textSearchNoResultsHide && $textSearchNoResultsHide.length) {
                        _.each($textSearchNoResultsHide, function ($item) {
                            $item.classList.add(CONSTANTS.HIDDEN_CLASS);
                        });
                    }
                } else {
                    $textSearchNoResults.classList.add(CONSTANTS.HIDDEN_CLASS);
                    if ($textSearchNoResultsHide && $textSearchNoResultsHide.length) {
                        _.each($textSearchNoResultsHide, function ($item) {
                            $item.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        });
                    }
                }

                if (_isPaginationSearch) {
                    // Call the pagination reset
                    resetPagination();
                }

                query.fireEvent($textSearch, 'filter');
            }
        };

        var resetPagination = function () {
            var paginationDivider = $paginationModule.dataset.divider,
                displayTotal = paginationDivider * 1,
                showPageIndex,
                $resultToShow;

            // Select all the filtered result
            $filteredResults = $paginationResults.parentElement.querySelectorAll(
                '.' +
                    CONSTANTS.PAGINATION_RESULTS_SELECTOR +
                    ' > :not(.' +
                    CONSTANTS.PAGINATION_HIDDEN_ELEMENT +
                    ')'
            );

            // Hide all results
            Array.prototype.forEach.call($filteredResults, function ($result) {
                query.addClass($result, $paginationResults.dataset.resultsHide);
            });

            // Show the corresponding page results
            for (
                showPageIndex = displayTotal - paginationDivider;
                showPageIndex < displayTotal && showPageIndex < $filteredResults.length;
                showPageIndex++
            ) {
                $resultToShow = $filteredResults[showPageIndex];

                query.removeClass($resultToShow, $paginationResults.dataset.resultsHide);
            }

            $paginationTotal.innerHTML = $filteredResults.length;
            $paginationReset.click();
        };

        var initializeData = function () {
            _textSearchContainerClass = $textSearch.dataset.textSearchContainer;
            _textSearchItemClass = $textSearch.dataset.textSearchItem;
            _textSearchNoResutsClass = $textSearch.dataset.textSearchNoResults;
            _highlightClass = $textSearch.dataset.textSearchHighlight;
            _textSearchInitialItemsMarkup = [];

            if (
                $textSearch.dataset.textSearchNoResultsHide &&
                $textSearch.dataset.textSearchNoResultsHide !== ''
            ) {
                _textSearchNoResutsHideClass = $textSearch.dataset.textSearchNoResultsHide;
                $textSearchNoResultsHide = document.getElementsByClassName(
                    _textSearchNoResutsHideClass
                );
            }

            $textSearchContainer = document.getElementsByClassName(_textSearchContainerClass)[0];
            $textSearchItems = $textSearchContainer.getElementsByClassName(_textSearchItemClass);
            $textSearchNoResults = $textSearchContainer.getElementsByClassName(
                _textSearchNoResutsClass
            )[0];
            $textSearchInput = $textSearch.getElementsByClassName(
                CONSTANTS.TEXT_SEARCH_INPUT_SELECTOR
            )[0];

            // Pagination elements
            _isPaginationSearch = $textSearch.dataset.textSearchIsPaginationSearch === 'true';
            if (_isPaginationSearch) {
                var $paginationSearch = document.getElementsByClassName(
                    $textSearch.dataset.textSearchPaginationContainer
                )[0];
                $paginationModule = $paginationSearch.getElementsByClassName(
                    CONSTANTS.PAGINATION_SELECTOR
                )[0];
                $paginationResults = $paginationSearch.getElementsByClassName(
                    CONSTANTS.PAGINATION_RESULTS_SELECTOR
                )[0];
                $paginationTotal = $paginationSearch.getElementsByClassName(
                    CONSTANTS.PAGINATION_RESULTS_TOTAL_SELECTOR
                )[0];
                $paginationReset = $paginationSearch.getElementsByClassName(
                    CONSTANTS.PAGINATION_RESET_SELECTOR
                )[0];
            }

            // Creating array of item's initial markup for reset purposes
            _.each($textSearchItems, function ($textSearchItem, index) {
                _textSearchInitialItemsMarkup[index] = [];

                Array.prototype.forEach.call(
                    $textSearchItem.children,
                    function ($textSearchItemChildren) {
                        if (
                            !$textSearchItemChildren.classList.contains(
                                CONSTANTS.NO_TEXT_SEARCH_SELECTOR
                            )
                        ) {
                            _textSearchInitialItemsMarkup[index].push(
                                $textSearchItemChildren.innerHTML
                            );
                        }
                    }
                );
            });
        };

        var initializeEvents = function () {
            $textSearchInput.addEventListener('keyup', filterSearchItems);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };
        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    TextSearch.prototype.isLoaded = function () {
        return isLoaded;
    };
    return TextSearch;
})();
