// ==================== FOOTER COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FooterComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FOOTER_ITEM_BTN_CLASS: 'js-footer-item-btn',
        FOOTER_ITEM_ICON_CLASS: 'js-footer-item-icon',
        FOOTER_ITEM_ICON_OPEN_CLASS: 'footer__primary-item-icon--open',
        FOOTER_ITEM_LINKS_CLASS: 'js-footer-item-links',
        FOOTER_ITEM_LINKS_OPEN_CLASS: 'footer__primary-item-links--open',
        FOOTER_BUTON_HOVER_CLASS: 'footer__primary-item-btn--open'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var FooterComponent = function ($footerElement) {
        /**
         * PRIVATE METHODS
         */
        var $footerItems, $footerItemsLinks, $footerItemsIcon;

        // Footer Items Collapse/Expand Click Functions
        var closeLinks = function ($itemElement, $itemLinksElement) {
            var $itemIconElement = $itemElement.getElementsByClassName(
                CONSTANTS.FOOTER_ITEM_ICON_CLASS
            )[0];

            // Remove open classes
            query.removeClass($itemIconElement, CONSTANTS.FOOTER_ITEM_ICON_OPEN_CLASS);
            query.removeClass($itemLinksElement, CONSTANTS.FOOTER_ITEM_LINKS_OPEN_CLASS);
            // Re-add hover effect class for the footer item
            query.addClass($itemElement, CONSTANTS.FOOTER_BUTON_HOVER_CLASS);
        };

        var openLinks = function ($itemElement, $itemLinksElement) {
            var $itemIconElement = $itemElement.getElementsByClassName(
                CONSTANTS.FOOTER_ITEM_ICON_CLASS
            )[0];

            $itemElement.setAttribute('aria-expanded', 'true');
            
            // Remove open classes
            query.removeClass($footerItemsLinks, CONSTANTS.FOOTER_ITEM_LINKS_OPEN_CLASS);
            query.removeClass($footerItemsIcon, CONSTANTS.FOOTER_ITEM_ICON_OPEN_CLASS);
            // Add open classes to clicked item
            query.addClass($itemLinksElement, CONSTANTS.FOOTER_ITEM_LINKS_OPEN_CLASS);
            query.addClass($itemIconElement, CONSTANTS.FOOTER_ITEM_ICON_OPEN_CLASS);
            // Add hover effect to all items, but remove it from the clicked one
            query.addClass($footerItems, CONSTANTS.FOOTER_BUTON_HOVER_CLASS);
            query.removeClass($itemElement, CONSTANTS.FOOTER_BUTON_HOVER_CLASS);
        };

        var itemClickEvent = function (event) {
            event.preventDefault();

            var $itemElement = event.target;

            if (!query.hasClass($itemElement, CONSTANTS.FOOTER_ITEM_BTN_CLASS)) {
                $itemElement = $itemElement.parentElement;
            }

            var $itemLinksElement = $itemElement.parentElement.getElementsByClassName(
                CONSTANTS.FOOTER_ITEM_LINKS_CLASS
            )[0];

            for (var index = 0; index < $footerItems.length; index++) {
                $footerItems[index].setAttribute('aria-expanded', 'false');
            }

            if (query.hasClass($itemLinksElement, CONSTANTS.FOOTER_ITEM_LINKS_OPEN_CLASS)) {
                closeLinks($itemElement, $itemLinksElement);
            } else {
                openLinks($itemElement, $itemLinksElement);
            }
        };

        var initializeData = function () {
            $footerItems = $footerElement.getElementsByClassName(CONSTANTS.FOOTER_ITEM_BTN_CLASS);
            $footerItemsLinks = $footerElement.getElementsByClassName(
                CONSTANTS.FOOTER_ITEM_LINKS_CLASS
            );
            $footerItemsIcon = $footerElement.getElementsByClassName(
                CONSTANTS.FOOTER_ITEM_ICON_CLASS
            );
        };

        var initializeEvents = function () {
            // Footer Items Collapse/Expand Click Event
            for (var index = 0; index < $footerItems.length; index++) {
                coned.utils.addGeneralListeners($footerItems[index], itemClickEvent);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FooterComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FooterComponent;
})();
