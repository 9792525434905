// ==================== GRAPHICS CARD COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */

coned.components.GraphicsCard = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        GRAPHICS_GRID_INFO_CONTAINER_SELECTOR: 'js-graphics-grid-info-container-selector'
    };

    var isLoaded = false;

    /**
       * Constructor
       * @param  {[type]} [description]
       * @return {}        Encapsulated modules with its function.
       */

    var GraphicsCard = function ($graphicsCardElement) {
        /**
         * PRIVATE METHODS
         */
        var $cardElements,
            _cardElementHeights,
            _cardMaxHeight;

        /**
        * Set graphics card height
        */
        var setHeight = function () {
            //Loop through each element and track the tallest element 
            _cardElementHeights = Array.prototype.map.call($cardElements, function (el) {
                return el.clientHeight;
            });

            _cardMaxHeight = Math.max.apply(null, _cardElementHeights);

            //Loop again and set each element's height to be that of the tallest 
            Array.prototype.forEach.call($cardElements, function (el) {
                el.style.height = _cardMaxHeight + 'px';
            });
        }


        /**
         * Update the graphics card height whenever the window is resized
         */
        var updateCardHeight = function () {
            Array.prototype.map.call($cardElements, function (el) {
                el.style.height = '';
            });
            setHeight();
        }

        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            $cardElements = $graphicsCardElement.getElementsByClassName(CONSTANTS.GRAPHICS_GRID_INFO_CONTAINER_SELECTOR);
        }

        /**
        * Initialize the events in the module.
        */
        var initializeEvents = function () {
            setHeight();
            window.addEventListener('resize', updateCardHeight);
        }


        /**
        * Inits functionality in the module.
        */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
    *  PUBLIC METHODS
    */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    GraphicsCard.prototype.isLoaded = function () {
        return isLoaded;
    };

    return GraphicsCard;
})();

