// ==================== COLLAPSIBLE CONTAINER COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CollapsibleContainerComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ARIA_EXPANDED: 'aria-expanded',
        COLLAPSIBLE_CONTAINER_BTN_CLASS: 'js-collapsible-container',
        COLLAPSIBLE_CONTAINERS_BTN_CLASS: 'collapsible-container__item-button',
        COLLAPSIBLE_CONTAINER_ITEM_CLASS: 'collapsible-container__item',
        COLLAPSIBLE_CONTAINER_ITEM_ACTIVE_CLASS: 'collapsible-container__item--open',
        DEEP_LINK_EVENT: 'deeplink-event'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var CollapsibleContainerComponent = function ($collapsibleContainerElement) {
        /**
         * PRIVATE METHODS
         */
        var $collapsibleContainerBtnsElement, $collapsibleContainerItemsElement;

        /**
         * 
         * @param {CustomEvent<{collapseAll:boolean}} event 
         */
        var handleSetCollapseAll = function (event) {
            var needCollapseAll = event.detail.collapseAll;
            if (needCollapseAll) {
                Array.from(
                    $collapsibleContainerElement.getElementsByClassName(CONSTANTS.COLLAPSIBLE_CONTAINER_ITEM_ACTIVE_CLASS)
                ).forEach(function ($el) {
                    $el.classList.remove(CONSTANTS.COLLAPSIBLE_CONTAINER_ITEM_ACTIVE_CLASS);
                });
                Array.from(
                    $collapsibleContainerElement.getElementsByClassName(CONSTANTS.COLLAPSIBLE_CONTAINER_BTN_CLASS)
                ).forEach(function ($el) {
                    $el.setAttribute(CONSTANTS.ARIA_EXPANDED, 'true');
                });
            }
        }

        /**
         * Collapses or expands accordion
         * @param {Event} event Click or null
         * @param {HTMLElement} $button [optional] Button from the element that triggered the function. Only for deep link functionality.
         */
        var CollapsibleContainerBtnEventClick = function (event, $button) {
            event && event.preventDefault();

            // If triggered from deep link the event would come from the parent so we need
            // to set the var to the button for the code to work
            var $collapsibleContainerBtnClicked = event ? event.currentTarget : $button;

            if (
                !query.hasClass(
                    $collapsibleContainerBtnClicked,
                    CONSTANTS.COLLAPSIBLE_CONTAINERS_BTN_CLASS
                )
            ) {
                $collapsibleContainerBtnClicked = $collapsibleContainerBtnClicked.parentElement;
            }
            var $collapsibleContainerItem = $collapsibleContainerBtnClicked.parentElement;

            if (
                !query.hasClass(
                    $collapsibleContainerItem,
                    CONSTANTS.COLLAPSIBLE_CONTAINER_ITEM_ACTIVE_CLASS
                )
            ) {
                $collapsibleContainerBtnClicked.setAttribute(CONSTANTS.ARIA_EXPANDED, 'true');
                query.removeClass(
                    $collapsibleContainerItemsElement,
                    CONSTANTS.COLLAPSIBLE_CONTAINER_ITEM_ACTIVE_CLASS
                );
                query.addClass(
                    $collapsibleContainerItem,
                    CONSTANTS.COLLAPSIBLE_CONTAINER_ITEM_ACTIVE_CLASS
                );
            } else {
                $collapsibleContainerBtnClicked.setAttribute(CONSTANTS.ARIA_EXPANDED, 'false');
                query.removeClass(
                    $collapsibleContainerItem,
                    CONSTANTS.COLLAPSIBLE_CONTAINER_ITEM_ACTIVE_CLASS
                );
            }
        };

        var initializeData = function () {
            $collapsibleContainerBtnsElement = $collapsibleContainerElement.getElementsByClassName(
                CONSTANTS.COLLAPSIBLE_CONTAINER_BTN_CLASS
            );
            $collapsibleContainerItemsElement = $collapsibleContainerElement.getElementsByClassName(
                CONSTANTS.COLLAPSIBLE_CONTAINER_ITEM_CLASS
            );
        };

        var initializeEvents = function () {
            _.each($collapsibleContainerBtnsElement, function ($collapsibleContainerBtnElement) {
                coned.utils.addGeneralListeners(
                    $collapsibleContainerBtnElement,
                    CollapsibleContainerBtnEventClick
                );
            });

            // Listen to deep link event to open the accordion
            _.each($collapsibleContainerItemsElement, function ($collapsibleContainerItemElement) {
                $collapsibleContainerItemElement.addEventListener(CONSTANTS.DEEP_LINK_EVENT, function (event) {
                    var $button = event.target.getElementsByClassName(CONSTANTS.COLLAPSIBLE_CONTAINER_BTN_CLASS)[0];

                    CollapsibleContainerBtnEventClick(null, $button);
                });
            });
            $collapsibleContainerElement.addEventListener(coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL, handleSetCollapseAll)
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CollapsibleContainerComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CollapsibleContainerComponent;
})();
