// ==================== LIVE CHAT CTA COMPONENT =========================
/* global _ */
var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LiveChatCTA = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        LIVE_CHAT_WRAPPER_SELECTOR: 'js-live-chat-wrapper',
        LIVE_CHAT_AVAILABITY_MESSAGE_SELECTOR: 'js-live-chat-availability-message',
        LIVE_CHAT_NO_AVAILABLE_MESSAGE_SELECTOR: 'js-live-chat-no-available',
        LIVE_CHAT_ERROR_MESSAGE_SELECTOR: 'js-live-chat-error-message',
        LIVE_CHAT_INFOBOX_SELECTOR: 'js-informational-box',
        LIVE_CHAT_MOBILE_SELECTOR: 'js-live-chat-cta-m', 
        LIVE_CHAT_DESKTOP_SELECTOR: 'js-live-chat-cta-d',        
        LIVE_CHAT_INFOBOX: 'js-informational-box',
        LIVE_CHAT_TAGGING_EVENT:'live-chat-tagging',
        ACCOUNT_NUMBER_INPUT_SELECTOR: 'js-unauthenticated-login-account-number',
        INPUT_ERROR_CLASS: 'coned-input-message--error',
        INIT_EVENT: 'init',
        STOP_EVENT: 'stop',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LiveChatCTA = function ($liveChatCta) {
        /**
         * PRIVATE METHODS
         */
        var $liveChatAvailabilityMessage,
            $liveChatNoAvailableMessage,
            $liveChatInformationalBox,
            $liveChatErrorMessage,
            $accountNumberInputs,
            _initOnLoad,
            _hasMessage,
            _nofocusOnLoad,
            intervalId;

        /**
         * Initializes the Live Chat CTA (check if chat is available and show the button)
         */
        var initializeLiveChatCta = function () {
            if ($liveChatCta) {
                var serviceUrl = $liveChatCta.dataset.serviceUrl,
                    waitTime = $liveChatCta.dataset.waitTime,
                    maxCalls = $liveChatCta.dataset.maxCalls,
                    chatAvailable = $liveChatCta.dataset.chatAlwaysAvailable,
                    callCount = 1;

                //Clear interval every time the button is initialized
                clearInterval(intervalId);

                //Check if live chat is been set as always available in sitecore so we need to show the button without checking if all conditions are met.
                if(chatAvailable && chatAvailable === 'true') {
                    $liveChatCta.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $liveChatCta.disabled = false;
                    checkInformationalBox(false);

                } else {
                        //Call the service to check if live chat is available on page load
                    query.getData(serviceUrl, successLiveChatAvailable, errorLiveChatAvailable);

                    //Set interval to check if live chat is available and adjust the button to show or hide, the wait time is set in the data attribute
                    intervalId = setInterval(function () {
                        if (callCount < maxCalls) {
                            query.getData(serviceUrl, successLiveChatAvailable, errorLiveChatAvailable);
                            callCount++;
                        } else {
                            $liveChatCta.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);

                            checkInformationalBox(true);

                            clearInterval(intervalId);
                        }
                    }, waitTime);

                }
            }
        }

        /**
         * Check the success response form the live chat availability service call
         * @param {data} Response from service call to check if live chat is available 
         */
        var successLiveChatAvailable = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_AVAILABLE_LIVE_CHAT,
                    checkLiveChatAvailability,
                    function () {}
                );
            } else {
                checkLiveChatAvailability(data);
            }
        };

        /**
         * Check the error response form the live chat availability service call
         * */
        var errorLiveChatAvailable = function () {
         
            //Hide the live chat CTA if there is an error
            $liveChatCta.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);

            checkInformationalBox(true);
        }

        /*
        * Function to check if live chat is available
        */      
        var checkLiveChatAvailability = function (data) {
            if (data && data.chatAvailable) {
                $liveChatCta.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $liveChatAvailabilityMessage.innerHTML = $liveChatCta.dataset.availableChatMessage;

                $liveChatCta.disabled = false;
                checkInformationalBox(false);

            } else {
                $liveChatCta.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $liveChatAvailabilityMessage.innerHTML = '';
                
                checkInformationalBox(true);
            }
        }; 
        
        var stopLiveChatCtaValidation = function () { 
            clearInterval(intervalId);
            intervalId = null;

            $liveChatCta.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);

            //Check if the CTA has an additional message or it is just the CTA as in Contact Us page
            if(_hasMessage) {
                //Handle the error according to the one it is been used, as a paragraph or as an input error.
                //Currently because of design the error is been handle on account number input error
                //If the available message is undefined the input error should be updated
                if($liveChatNoAvailableMessage) {
                    $liveChatNoAvailableMessage.classList.add(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $liveChatErrorMessage.style.display = 'none';
                    $liveChatErrorMessage.parentElement.style.display = 'none';
                    $liveChatErrorMessage.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                
                $liveChatInformationalBox.classList.add(CONSTANTS.HIDDEN_CLASS);    
            }

        };

        var checkInformationalBox = function (displayError) {
            var setFocus = true;
            //If no focus on load has been set the cta or error should not have the focus.
            //This is needed on transfer and stop service page on load to allow the correct focus order for A11y
            if(_nofocusOnLoad && _nofocusOnLoad === 'true') {
                setFocus = false;
                _nofocusOnLoad = 'false';
            }
            //Check if the CTA has an additional message or it is just the CTA as in Contact Us page
            if(_hasMessage) {
                if(displayError) {
                    //Handle the error according to the one it is been used, as a paragraph or as an input error.
                    //Currently because of design the error is been handle on account number input error
                    //If the available message is undefined the input error should be updated
                    if($liveChatNoAvailableMessage) {
                        $liveChatNoAvailableMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        if(setFocus) {
                            $liveChatErrorMessage.parentElement.focus();
                        } else {
                            setFocus = true;
                        }

                    } else {
                        $liveChatErrorMessage.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $liveChatErrorMessage.parentElement.style.display = 'block';
                        $liveChatErrorMessage.style.display = 'block';
                        if(setFocus) {
                            $liveChatErrorMessage.focus();
                        } else {
                            setFocus = true;
                        }

                        //Account number input on un-auth stop should be red on error
                        _.each($accountNumberInputs, function (input) {
                            input.classList.add(CONSTANTS.INPUT_ERROR_CLASS);
                        });

                    } 
                    
                    $liveChatInformationalBox.classList.remove(CONSTANTS.HIDDEN_CLASS); 

                } else {
                    //Handle the error according to the one it is been used, as a paragraph or as an input error.
                    //Currently because of design the error is been handle on account number input error
                    //If the available message is undefined the input error should be updated
                    if($liveChatNoAvailableMessage) {                        
                        $liveChatNoAvailableMessage.classList.add(CONSTANTS.HIDDEN_CLASS); 
                    } else {
                        $liveChatErrorMessage.style.display = 'none';
                        $liveChatErrorMessage.parentElement.style.display = 'none';

                        //Account number input on un-auth stop should not have an error color
                        _.each($accountNumberInputs, function (input) {
                            input.classList.remove(CONSTANTS.INPUT_ERROR_CLASS);
                        });
                    }

                    $liveChatInformationalBox.classList.remove(CONSTANTS.HIDDEN_CLASS); 
                    
                    if(setFocus) {
                        $liveChatCta.focus(); 
                    } else {
                        setFocus = false;
                    }

                    //Trigger event for tagging to be added
                    coned.utils.triggerEvent($liveChatCta, CONSTANTS.LIVE_CHAT_TAGGING_EVENT);
                }
            }
        };

        var initializeData = function () {
            var $liveChatWrapper = query.hasClass($liveChatCta.parentElement.parentElement, 
                CONSTANTS.LIVE_CHAT_WRAPPER_SELECTOR) ? $liveChatCta.parentElement.parentElement : null;
            //For the contact us page the live chat wrapper is not present so we need to get the elements from the document
            $liveChatAvailabilityMessage = $liveChatWrapper ? $liveChatWrapper.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_AVAILABITY_MESSAGE_SELECTOR)[0] : 
                document.getElementsByClassName(CONSTANTS.LIVE_CHAT_AVAILABITY_MESSAGE_SELECTOR
            )[0];
            $liveChatNoAvailableMessage = $liveChatWrapper ? $liveChatWrapper.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_NO_AVAILABLE_MESSAGE_SELECTOR
            )[0]: null;
            $liveChatInformationalBox = $liveChatCta.parentElement.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_INFOBOX_SELECTOR
            )[0];
            //The error could be on a p tag or as the input error, this validation get the error that is using the current live chat implementation
            $liveChatErrorMessage =  $liveChatWrapper ?
                $liveChatWrapper.getElementsByClassName(CONSTANTS.LIVE_CHAT_ERROR_MESSAGE_SELECTOR)[0] ?
                $liveChatWrapper.getElementsByClassName(CONSTANTS.LIVE_CHAT_ERROR_MESSAGE_SELECTOR)[0]: 
                $liveChatWrapper.parentElement.getElementsByClassName(CONSTANTS.LIVE_CHAT_ERROR_MESSAGE_SELECTOR)[0]: null;
            $accountNumberInputs = document.getElementsByClassName(CONSTANTS.ACCOUNT_NUMBER_INPUT_SELECTOR);
            _initOnLoad = $liveChatCta.dataset.initOnLoad,
            _nofocusOnLoad = $liveChatCta.dataset.noFocusOnLoad,
            _hasMessage = $liveChatCta.dataset.hasMessage && $liveChatCta.dataset.hasMessage === 'true';
            intervalId = null;

            
        };

        var initializeEvents = function () {
            if(_initOnLoad && _initOnLoad === 'true') {
                initializeLiveChatCta();  
            } else {
                $liveChatCta.addEventListener(
                    CONSTANTS.INIT_EVENT, initializeLiveChatCta);
                $liveChatCta.addEventListener(
                    CONSTANTS.STOP_EVENT, stopLiveChatCtaValidation);
            }

            coned.utils.addGeneralListeners($liveChatCta, function () {
                clearInterval(intervalId);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LiveChatCTA.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LiveChatCTA;

})();
