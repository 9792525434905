// ==================== EDIT PROFILE COMPONENT =========================
/* global _ */
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.EditProfile = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        EDIT_FIELD: 'js-edit-profile-field',
        EDIT_FIELD_ACTIVE: 'edit-profile__field--active',
        EDIT_CTA: 'js-edit-profile-cta',
        EDIT_MAIN_CTA: 'js-edit-main-cta',
        EDIT_PASSWORD_CTA: 'js-edit-password-cta',
        EDIT_NOTIFICATION: 'coned-checkbox-switch',
        SAVE_CTA: 'js-save-profile-cta',
        SEND_CODE_CTA: 'js-send-code-profile-cta',
        CANCEL_CTA: 'js-cancel-profile-cta',
        STEP2_CANCEL_CTA: 'js-step2-cancel-profile-cta',
        CANCEL_SEND_CODE_CLASS: 'edit-profile__edit-link--cancel-send-code',
        FIELD_DESCRIPTION: 'js-field-description',
        FIELD_INPUT_CONTAINER: 'edit-profile__input',
        FIELD_LAST_NAME_INPUT: 'js-last-name',
        FIELD_INPUT: 'js-item-validate',
        PASSWORD_INPUT: 'js-password-input',
        NEW_PASSWORD_INPUT: 'js-new-password',
        CURRENT_PASSWORD_INPUT: 'js-current-password',
        SELECT_TYPE: 'js-edit-select-type',
        EDIT_PROFILE_FORM: '.js-edit-profile-form',
        EMAIL_LIST_ITEM: 'js-email-list-item',
        EDIT_EMAILS_CTAS: 'js-edit-emails-cta',
        HIDDEN_CLASS: 'hidden',
        FILLED_CLASS: 'coned-input--filled',
        EMPTY_FIELD_CLASS: 'edit-profile__description--empty',
        NOTIFICATION_MOBILE: 'js-notification-phone',
        DROPDOWN_OPTIONS: 'js-select-option',
        DROPDOWN_LABEL_SELECTOR: 'js-coned-select-label',
        ACTIVE_DROPDOWN: 'coned-select--active',
        ACTIVE_LABEL_DROPDOWN: 'coned-select__label--active',
        SECURITY_ANSWER_SELECTOR: 'js-security-answer',
        EDIT_PROFILE_SELECT: 'js-edit-profile-select',
        FORM_IGNORE_VALIDATION: 'js-validate-ignore',
        USERNAME_PHONE_GROUP: 'js-username-phone-group',
        PASSWORD_FIELD_GROUP: 'js-password-group',
        MFA_GROUP: 'js-mfa-group',
        LANGUAGE_GROUP: 'js-language-group',
        LANGUAGE_SELECT: 'js-language-dropdown-selector',
        EMAIL_INPUT: 'js-email-input',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        VERIFICATION_METHOD: 'js-method-container',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        NO_PHONE_ERROR: 'js-no-phone-error',
        MOBILE_PHONE_TEXT: 'js-mobile-phone-text',
        MOBILE_PHONE_INPUT: 'js-input-phone-selector',
        VERIFICATION_SELECT: 'js-step-verification-select',
        VERIFICATION_TEXT: 'js-step-verification-text',
        VERIFICATION_PHONE: 'js-phone-verification',
        VERIFICATION_PHONE_PARAGRAPH: 'js-phone-verification-paragraph',
        VERIFICATION_CODE_SECTION: 'js-send-code-section',
        VERIFICATION_TRY_AGAIN: 'js-send-code-try-again',
        VERIFICATION_CODE_INPUT: 'js-send-code-input',
        VERIFICATION_OPTION_QUESTION: 'question',
        SECURITY_QUESTION_CONTAINER: 'js-question-container',
        SELECT_CLASS_SELECTOR: 'js-coned-select',
        PASSWORD_VALIDATION_PARAGRAPH: 'register__validation-paragraph',
        PASSWORD_VALIDATION_ERROR: 'register__validation--error',
        EDIT_USERNAME: 'userName',
        EDIT_FIRST_NAME: 'firstName',
        EDIT_LAST_NAME: 'lastName',
        EDIT_MOBILE_PHONE: 'mfaMobilePhone',
        EDIT_PREFERRED_NAME: 'preferredName',
        EDIT_LANGUAGE: 'profileLanguage',
        EDIT_CONFIRM_PASSWORD: 'confirmPassword',
        EDIT_QUESTION: 'securityQuestion',
        EDIT_ANSWER: 'securityAnswer',
        EDIT_VERIFICATON_BY: 'verificationOption',
        EDIT_VERIFICATION_CODE: 'verificationCode',
        EDIT_PHONE_VERIFICATION_CODE: 'phoneVerificationCode',
        EDIT_SCID: 'ScId',
        EDIT_USER: 'userId',
        EDIT_USER_SCID: 'userScId',
        EDIT_PERSONAL_SCID: 'personalScId',
        EDIT_CURRENT_PASSWORD: 'profileCurrentPassword',
        EDIT_NEW_PASSWORD: 'profileNewPassword',
        EDIT_NOTIFICATIONS_SCID: 'notificationsScId',
        DOWNLOAD_ACCOUNTS_SCID: 'downloadAccountsScId',
        DOWNLOAD_ACCOUNTS_CLASS: 'js-download-accounts-list',
        DOWNLOAD_ACCOUNTS_SECTION_CLASS: 'js-download-accounts-section',
        DOWNLOAD_ACCOUNTS_POPUP_ERROR_CLASS: 'js-download-accounts-error-popup'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var EditProfile = function ($editProfile) {
        /**
         * PRIVATE METHODS
         */
        var $editFieldButtons,
            $editFields,
            $editDescriptions,
            $editInputsContainer,
            $serviceErrorMessages,
            $serviceErrors,
            $formLoading,
            $mobilePhoneText,
            $mobilePhoneInput,
            $fieldDescription,
            $actualField,
            $editButton,
            $editButtons,
            $saveButton,
            $saveButtons,
            $sendCodeButton,
            $sendCodeText,
            $sendCodeSection,
            $sendCodeTryAgain,
            $sendCodeInput,
            $cancelButton,
            $cancelButtons,
            $step2CancelButton,
            $fieldLastName,
            $fieldInputContainer,
            $fieldInput,
            $activeSelect,
            $noPhoneError,
            $verificationSelect,
            $notificationCheckbox,
            $languageGroup,
            $languageSelect,
            $securityQuestionSelect,
            $securityQuestionContainer,
            $newPasswordInput,
            $currentPassword,
            $verificationMethods,
            $passValidationParagraph,
            $accountsListExportLink,
            $accountsListExportErrorPopUp,
            _fieldInputValue,
            _validator;

        var setLanguageChannel = function () {
            var selectedLanguage = $languageSelect.options[$languageSelect.selectedIndex].value;

            $languageGroup.dataset.channel = selectedLanguage.toUpperCase();
        };

        //Hide on change of 2 step authentication
        var hideCancelButton = function () {
            $step2CancelButton.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        // Email list action handler
        var emailList = function () {
            var $emailItem = $editProfile.getElementsByClassName(CONSTANTS.EMAIL_LIST_ITEM),
                $viewAllEmails = $editProfile.getElementsByClassName(CONSTANTS.EDIT_EMAILS_CTAS);

            _.each($viewAllEmails, function ($ctaEmail) {
                coned.utils.addGeneralListeners($ctaEmail, editEmail);
            });

            // Show only 3 emails
            _.each($emailItem, function ($listItem, index) {
                if (index <= 2) {
                    $listItem.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            });

            function editEmail(event) {
                event.preventDefault();

                var $actualField = this.parentNode,
                    $editButton = $actualField.getElementsByClassName(
                        CONSTANTS.EDIT_EMAILS_CTAS
                    )[0],
                    $cancelButton = $actualField.getElementsByClassName(CONSTANTS.CANCEL_CTA)[0],
                    actionView = query.hasClass(this, CONSTANTS.SAVE_CTA);

                if (actionView) {
                    $editButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $cancelButton.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    _.each($emailItem, function ($listItem) {
                        $listItem.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    });
                } else {
                    $editButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $cancelButton.classList.add(CONSTANTS.HIDDEN_CLASS);

                    // Show only 3 emails
                    _.each($emailItem, function ($listItem, index) {
                        if (index >= 3) {
                            $listItem.classList.add(CONSTANTS.HIDDEN_CLASS);
                        }
                    });
                }
            }
        };

        var resetDropdown = function ($dropdown) {
            var $dropdownLabel = $dropdown.parentElement.getElementsByClassName(
                CONSTANTS.DROPDOWN_LABEL_SELECTOR
            )[0];

            $dropdownLabel.removeAttribute('style');
            $dropdown.removeAttribute('style');
            $dropdown.selectedIndex = 0;
            $dropdown.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);
        };

        // Close all fields on edit action
        var closeAllFields = function () {
            _.each($cancelButtons, function ($cancelButton) {
                $cancelButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($saveButtons, function ($saveButtons) {
                $saveButtons.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($editButtons, function ($editButton) {
                $editButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($editFields, function ($editField) {
                $editField.classList.remove(CONSTANTS.EDIT_FIELD_ACTIVE);
            });

            _.each($editDescriptions, function ($editDescriptions) {
                $editDescriptions.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($editInputsContainer, function ($editInputsContainer) {
                $editInputsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            // hide verification messages and dropdowns
            _.each($verificationMethods, function ($verificationMethod) {
                $verificationMethod.classList.add(CONSTANTS.HIDDEN_CLASS);
                $noPhoneError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            // MFA phone change related
            $sendCodeButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            $sendCodeText.classList.add(CONSTANTS.HIDDEN_CLASS);
            $sendCodeSection.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        // Edit field action
        var editField = function (event) {
            event.preventDefault();

            $actualField = this.parentNode;
            $fieldDescription = $actualField.getElementsByClassName(CONSTANTS.FIELD_DESCRIPTION)[0];
            $fieldLastName = $actualField.getElementsByClassName(
                CONSTANTS.FIELD_LAST_NAME_INPUT
            )[0];
            $editButton = $actualField.getElementsByClassName(CONSTANTS.EDIT_CTA)[0];
            $saveButton = $actualField.getElementsByClassName(CONSTANTS.SAVE_CTA)[0];
            $cancelButton = $actualField.getElementsByClassName(CONSTANTS.CANCEL_CTA)[0];
            $fieldInputContainer = $actualField.getElementsByClassName(
                CONSTANTS.FIELD_INPUT_CONTAINER
            )[0];
            $fieldInput = $actualField.getElementsByClassName(CONSTANTS.FIELD_INPUT)[0];
            _validator = $(CONSTANTS.EDIT_PROFILE_FORM).validate();

            var $validateItems = $actualField.getElementsByClassName(CONSTANTS.FIELD_INPUT),
                actionEdit = query.hasClass(this, CONSTANTS.CANCEL_CTA),
                actionSave = query.hasClass(this, CONSTANTS.SAVE_CTA),
                actionSendCode = query.hasClass(this, CONSTANTS.SEND_CODE_CTA),
                isSelectInput = query.hasClass($actualField, CONSTANTS.SELECT_TYPE),
                isPasswordField = query.hasClass($actualField, CONSTANTS.PASSWORD_FIELD_GROUP),
                isPhoneVerification = query.hasClass($actualField, CONSTANTS.VERIFICATION_PHONE),
                fieldValid,
                validationComplete,
                errorsField = [];

            // Get value from input or select menu
            if (isSelectInput) {
                $activeSelect = $actualField.getElementsByClassName(
                    CONSTANTS.EDIT_PROFILE_SELECT
                )[0];

                if ($activeSelect.selectedIndex >= 0) {
                    _fieldInputValue = $activeSelect.options[$activeSelect.selectedIndex].text;
                } else {
                    _fieldInputValue = '';
                }
            } else {
                if (!actionSave && !isPasswordField && !actionSendCode) {
                    // Set value from the data received or from a previously saved input
                    $fieldInput.value = $fieldDescription.dataset.value;
                    $fieldInput.classList.add(CONSTANTS.FILLED_CLASS);
                }

                _fieldInputValue = $fieldInput.value;
            }

            // Set last name input if avaialable
            if ($fieldLastName !== undefined && !actionSave) {
                $fieldLastName.value = $fieldDescription.dataset.lastName;
                $fieldLastName.classList.add(CONSTANTS.FILLED_CLASS);
            }

            if (actionEdit && !actionSave) {
                cancelActions(isSelectInput, isPhoneVerification);
            } else {
                //It allows to determine if it is opened from the edit button for move focus to cancel button.
                var isEditButtonOpen = query.hasClass(event.target, CONSTANTS.EDIT_MAIN_CTA);
                editActions(isSelectInput, isEditButtonOpen);
            }

            // Validate password criteria
            if (isPasswordField) {
                _.each($validateItems, function ($validateItem) {
                    fieldValid = _validator.element($validateItem);
                    validationComplete = $validateItem.dataset.valid;
                });

                _validator.resetForm();
            }

            // Check if MFA phone is saved
            if (!query.hasClass($noPhoneError, CONSTANTS.HIDDEN_CLASS)) {
                errorsField.push($noPhoneError);
            }

            // Save field edit if inputs are validated
            if (actionSave || actionSendCode) {
                _.each($validateItems, function ($validateItem) {
                    // Validate field if displayed
                    fieldValid = _validator.element($validateItem);

                    if (!fieldValid) {
                        errorsField.push($validateItem);
                    }
                });

                if (errorsField <= 0 && actionSendCode) {
                    // Verify that field is not the same as its currently saved value
                    if (!isSelectInput && $fieldInput.value === $fieldDescription.dataset.value)
                        return;

                    sendCode();
                    return;
                }

                if (errorsField <= 0 && !isPasswordField) {
                    // Validate lastName new value vs current value
                    var validLastName = false;

                    if ($fieldLastName !== undefined) {
                        if ($fieldLastName.value !== $fieldDescription.dataset.lastName) {
                            validLastName = true;
                        }
                    }

                    // Verify that field is not the same as its currently saved value
                    if (
                        !isSelectInput &&
                        $fieldInput.value === $fieldDescription.dataset.value &&
                        !validLastName
                    )
                        return;

                    saveField();
                } else if (isPasswordField && fieldValid && validationComplete === 'true') {
                    saveField();

                    for (var index = 0; index < $passValidationParagraph.length; index++) {
                        $passValidationParagraph[index].classList.add(
                            CONSTANTS.PASSWORD_VALIDATION_ERROR
                        );
                    }
                }
            }
        };

        var editActions = function (isSelectInput, isEditButtonOpen) {
            $editButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            $actualField.classList.add(CONSTANTS.EDIT_FIELD_ACTIVE);
            $fieldDescription.classList.add(CONSTANTS.HIDDEN_CLASS);
            $saveButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $cancelButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $fieldInputContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

            isEditButtonOpen && $cancelButton.focus();

            // Logic for the MFA phone change
            if (query.hasClass($actualField, CONSTANTS.VERIFICATION_PHONE)) {
                if (!query.hasClass($sendCodeSection, CONSTANTS.HIDDEN_CLASS)) {
                    $fieldInputContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $sendCodeSection.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $cancelButton.classList.add(CONSTANTS.CANCEL_SEND_CODE_CLASS);
                    $saveButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $sendCodeButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $sendCodeText.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            }

            if (isSelectInput) {
                // Assure phone input is set with the phone value
                $mobilePhoneInput.value = $mobilePhoneText.dataset.value;
            }
        };

        var cancelActions = function (isSelectInput, isPhoneVerification) {
            var $activeInputs = $fieldInputContainer.getElementsByClassName(CONSTANTS.FIELD_INPUT),
                $activeDropdowns = $fieldInputContainer.getElementsByClassName(
                    CONSTANTS.SELECT_CLASS_SELECTOR
                );

            $cancelButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            $editButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $editButton && $editButton.focus(); //Redirect focus to edit button
            $actualField.classList.remove(CONSTANTS.EDIT_FIELD_ACTIVE);
            $fieldDescription.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $saveButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            $fieldInputContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            _validator.resetForm();

            // MFA phone change related
            if (isPhoneVerification) {
                $cancelButton.classList.remove(CONSTANTS.CANCEL_SEND_CODE_CLASS);
                $sendCodeButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                $sendCodeText.classList.add(CONSTANTS.HIDDEN_CLASS);
                $sendCodeSection.classList.add(CONSTANTS.HIDDEN_CLASS);
                $sendCodeInput.classList.remove(CONSTANTS.FIELD_INPUT);
            }

            if (isSelectInput) {
                $noPhoneError.classList.add(CONSTANTS.HIDDEN_CLASS);

                // Hide verification messages and dropdowns
                _.each($verificationMethods, function ($verificationMethod) {
                    $verificationMethod.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                // Reset dropdowns
                _.each($activeDropdowns, function ($activeDropdown) {
                    resetDropdown($activeDropdown);
                });
            }

            // Clear input values
            _.each($activeInputs, function ($activeInput) {
                if (!query.hasClass($activeInput, CONSTANTS.SELECT_CLASS_SELECTOR)) {
                    $activeInput.value = '';
                }
            });

            hideErrors();
        };

        var sendCode = function () {
            var serviceUrl = $actualField.dataset.sendCodeService,
                resetCurrentMfa = $actualField.dataset.resetCurrentMfa
                    ? $actualField.dataset.resetCurrentMfa
                    : null,
                params;

            hideErrors();

            params = {
                ScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_SCID),
                UserId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_USER),
                MobilePhoneNumber: query.getFormInputValue(
                    $editProfile,
                    CONSTANTS.EDIT_MOBILE_PHONE
                ),
                StepVerification: $actualField.dataset.stepVerification,
                ResetCurrentMfaFactor: resetCurrentMfa
            };

            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                showCodeResponse,
                errorServiceCall,
                params,
                true,
                $formLoading
            );
        };

        var showCodeResponse = function (data) {
            // Set factor ID from service response
            $editProfile.dataset.factorId = data.FactorId;

            $cancelButton.classList.remove(CONSTANTS.CANCEL_SEND_CODE_CLASS);
            $saveButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $sendCodeButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            $fieldInputContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $sendCodeSection.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $sendCodeInput.classList.add(CONSTANTS.FIELD_INPUT);
            $sendCodeInput.classList.remove(CONSTANTS.FILLED_CLASS);
            $sendCodeInput.value = '';

            _validator.resetForm();
        };

        var hideCodeResponse = function (event) {
            event.preventDefault();

            $cancelButton.classList.add(CONSTANTS.CANCEL_SEND_CODE_CLASS);
            $saveButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            $sendCodeButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $fieldInputContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $sendCodeSection.classList.add(CONSTANTS.HIDDEN_CLASS);
            $sendCodeInput.classList.remove(CONSTANTS.FIELD_INPUT);

            _validator.resetForm();
        };

        var saveField = function () {
            var serviceUrl = $actualField.dataset.service,
                params;

            hideErrors();

            // User Fields
            if (query.hasClass($actualField, CONSTANTS.USERNAME_PHONE_GROUP)) {
                setUserInputs();

                params = {
                    ScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_SCID),
                    UserScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_USER_SCID),
                    PersonalScId: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_PERSONAL_SCID
                    ),
                    Login: query.getFormInputValue($editProfile, CONSTANTS.EDIT_USERNAME),
                    FirstName: query.getFormInputValue($editProfile, CONSTANTS.EDIT_FIRST_NAME),
                    LastName: query.getFormInputValue($editProfile, CONSTANTS.EDIT_LAST_NAME),
                    MobilePhone: query.getFormInputValue($editProfile, CONSTANTS.EDIT_MOBILE_PHONE),
                    DisplayName: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_PREFERRED_NAME
                    )
                };
                // Language
            } else if (query.hasClass($actualField, CONSTANTS.LANGUAGE_GROUP)) {
                params = {
                    ScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_SCID),
                    NotificationsScId: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_NOTIFICATIONS_SCID
                    ),
                    PersonalScId: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_PERSONAL_SCID
                    ),
                    PreferenceId: $languageGroup.dataset.preference,
                    ChannelName: $languageGroup.dataset.channel,
                    Value: query.getFormInputValue($editProfile, CONSTANTS.EDIT_LANGUAGE)
                };
                // MFA
            } else if (query.hasClass($actualField, CONSTANTS.MFA_GROUP)) {
                var selectedMethod =
                        $verificationSelect.options[$verificationSelect.selectedIndex].value,
                    factorIdValue = $editProfile.dataset.factorId
                        ? $editProfile.dataset.factorId
                        : '';

                params = {
                    ScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_SCID),
                    UserScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_USER_SCID),
                    FactorId: factorIdValue,
                    CurrentPassword: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_CONFIRM_PASSWORD
                    ),
                    MobilePhoneNumber: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_MOBILE_PHONE
                    ),
                    SecurityQuestion: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_QUESTION
                    ),
                    SecurityQuestionValue:
                        $securityQuestionSelect.options[$securityQuestionSelect.selectedIndex].text,
                    SecurityAnswer: query.getFormInputValue($editProfile, CONSTANTS.EDIT_ANSWER),
                    StepVerification: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_VERIFICATON_BY
                    ),
                    SecurityCode: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_VERIFICATION_CODE
                    )
                };

                // If security question option is not selected, clean related values
                if (selectedMethod != CONSTANTS.VERIFICATION_OPTION_QUESTION) {
                    params.SecurityQuestionValue = null;
                    params.SecurityQuestion = null;
                    params.SecurityQuestionText = null;
                    params.SecurityAnswer = null;
                }
                // Phone Verification
            } else if (query.hasClass($actualField, CONSTANTS.VERIFICATION_PHONE)) {
                factorIdValue = $editProfile.dataset.factorId ? $editProfile.dataset.factorId : '';

                setUserInputs();

                params = {
                    // Common
                    ScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_SCID),
                    UserScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_USER_SCID),
                    // Update User Profile
                    PersonalScId: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_PERSONAL_SCID
                    ),
                    Login: query.getFormInputValue($editProfile, CONSTANTS.EDIT_USERNAME),
                    FirstName: query.getFormInputValue($editProfile, CONSTANTS.EDIT_FIRST_NAME),
                    LastName: query.getFormInputValue($editProfile, CONSTANTS.EDIT_LAST_NAME),
                    MobilePhone: query.getFormInputValue($editProfile, CONSTANTS.EDIT_MOBILE_PHONE),
                    DisplayName: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_PREFERRED_NAME
                    ),
                    // Update User MFA
                    FactorId: factorIdValue,
                    CurrentPassword: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_CONFIRM_PASSWORD
                    ),
                    MobilePhoneNumber: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_MOBILE_PHONE
                    ),
                    SecurityQuestion: null,
                    SecurityQuestionValue: null,
                    SecurityAnswer: null,
                    StepVerification: $actualField.dataset.stepVerification,
                    SecurityCode: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_PHONE_VERIFICATION_CODE
                    )
                };
                // Password
            } else if (query.hasClass($actualField, CONSTANTS.PASSWORD_FIELD_GROUP)) {
                params = {
                    ScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_SCID),
                    UserScId: query.getFormInputValue($editProfile, CONSTANTS.EDIT_USER_SCID),
                    CurrentPassword: query.getFormInputValue(
                        $editProfile,
                        CONSTANTS.EDIT_CURRENT_PASSWORD
                    ),
                    NewPassword: query.getFormInputValue($editProfile, CONSTANTS.EDIT_NEW_PASSWORD)
                };
            }

            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successSaveField,
                errorServiceCall,
                params,
                true,
                $formLoading
            );
        };

        var setUserInputs = function () {
            var $userInputContainers = $editProfile.getElementsByClassName(
                CONSTANTS.USERNAME_PHONE_GROUP
            );

            for (var index = 0; index < $userInputContainers.length; index++) {
                var $userInputContainer = $userInputContainers[index],
                    $userInput = $userInputContainer.getElementsByClassName(
                        CONSTANTS.FIELD_INPUT
                    )[0],
                    $userLastNameInput = $userInputContainer.getElementsByClassName(
                        CONSTANTS.FIELD_LAST_NAME_INPUT
                    )[0],
                    $userDescription = $userInputContainer.getElementsByClassName(
                        CONSTANTS.FIELD_DESCRIPTION
                    )[0];

                if (
                    $userInput && $userInput.value === '' &&
                    !query.hasClass($userInputContainer, CONSTANTS.EDIT_FIELD_ACTIVE)
                ) {
                    $userInput.value = $userDescription.dataset.value;
                }

                if ($userLastNameInput !== undefined && $userLastNameInput.value === '') {
                    $userLastNameInput.value = $userDescription.dataset.lastName;
                }
            }
        };

        var successSaveField = function () {
            closeAllFields();

            if ($languageSelect && $languageSelect.value !== 'default') {
                var lang = $languageSelect.value,
                    langUrl = $languageGroup.dataset.urlStructure.replace('{{LANGUAGE}}', lang);
                window.location.href = langUrl;
                return false;
            }

            // Adding page reload, so the MFA options can be reloaded with a phone or MFA selection change
            if (
                query.hasClass($fieldDescription, CONSTANTS.MOBILE_PHONE_TEXT) ||
                query.hasClass($fieldDescription, CONSTANTS.VERIFICATION_TEXT)
            ) {
                window.location.reload();
            }

            if (_fieldInputValue !== '') {
                $fieldDescription.innerHTML = _fieldInputValue;
                $fieldDescription.classList.remove(CONSTANTS.EMPTY_FIELD_CLASS);
            } else if ($fieldDescription.dataset.placeholder !== undefined) {
                $fieldDescription.innerHTML = $fieldDescription.dataset.placeholder;
                $fieldDescription.classList.add(CONSTANTS.EMPTY_FIELD_CLASS);
            }

            // Data value is set even if no input was entered
            $fieldDescription.dataset.value = _fieldInputValue;

            if ($fieldLastName !== undefined) {
                $fieldDescription.innerHTML += ' ' + $fieldLastName.value;
                $fieldDescription.dataset.lastName = $fieldLastName.value;
            }

            if (query.hasClass($fieldInput, CONSTANTS.CURRENT_PASSWORD_INPUT)) {
                $currentPassword.value = '';
                $newPasswordInput.value = '';

                // Secure password field by always setting the same placeholder, not giving a hint of the password length
                $fieldDescription.innerHTML = '••••••';
            }

            _.each($verificationMethods, function ($verificationMethod) {
                $verificationMethod.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            cancelActions();
            resetDropdown($verificationSelect);
        };

        var errorServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.DEFAULT_SERVICE_ERROR, showError, showError, true);
            } else {
                showError(data);
            }
        };

        var showError = function (data) {
            var notificationField = query.hasClass($actualField, CONSTANTS.EDIT_NOTIFICATION);

            $actualField = notificationField ? $actualField.parentNode.parentNode : $actualField;

            if (notificationField) {
                $notificationCheckbox.checked = false;
            }

            var errorMsg = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE,
                $serviceError = $actualField.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0],
                $serviceErrorMessage = $actualField.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];

            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = errorMsg;
            $serviceError.focus();
        };

        var hideErrors = function () {
            _.each($serviceErrors, function ($serviceError) {
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorMessages, function ($serviceErrorMessage) {
                $serviceErrorMessage.innerHTML = '';
            });
        };

        /**
         * Call the export service
         */
        var exportAccountsList = function (event) {
            event.stopPropagation();
            event.preventDefault();

            if ($accountsListExportErrorPopUp) {
                $accountsListExportErrorPopUp.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            var $downloadAccountListSection = $editProfile.getElementsByClassName(CONSTANTS.DOWNLOAD_ACCOUNTS_SECTION_CLASS)[0],
                serviceUrl = $downloadAccountListSection.dataset.serviceUrl,
                params = {
                    ScId: query.getFormInputValue($editProfile, CONSTANTS.DOWNLOAD_ACCOUNTS_SCID)
                };

            query.getData(
                serviceUrl,
                successExportAccountsList,
                errorExportAccountsList,
                params,
                $formLoading
            );
        };

        /**
         * Success export service call handler
         */
        var successExportAccountsList = function (data) {
            if (coned.utils.isPatternLab()) {
                window.location.href =
                    'https://file-examples.com/wp-content/uploads/2017/02/file_example_XLS_10.xls';
            } else if (data && data.link && data.link !== '') {
                // Set file location
                window.location.href = data.link;
            } else {
                errorExportAccountsList();
            }
        };

        /**
         * Error export service call handler
         */
        var errorExportAccountsList = function () {
            if ($accountsListExportErrorPopUp) {
                $accountsListExportErrorPopUp.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            $accountsListExportErrorPopUp.focus();
        };

        var initializeData = function () {
            $editFieldButtons = $editProfile.getElementsByClassName(CONSTANTS.EDIT_CTA);
            $mobilePhoneText = $editProfile.getElementsByClassName(CONSTANTS.MOBILE_PHONE_TEXT)[0];
            $mobilePhoneInput = $editProfile.getElementsByClassName(
                CONSTANTS.MOBILE_PHONE_INPUT
            )[0];
            $serviceErrors = $editProfile.getElementsByClassName(CONSTANTS.SERVICE_ERROR);
            $serviceErrorMessages = $editProfile.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            );
            $securityQuestionContainer = $editProfile.getElementsByClassName(
                CONSTANTS.SECURITY_QUESTION_CONTAINER
            )[0];
            $securityQuestionSelect = $securityQuestionContainer && $securityQuestionContainer.getElementsByClassName(
                CONSTANTS.SELECT_CLASS_SELECTOR
            )[0];
            $formLoading = $editProfile.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $noPhoneError = $editProfile.getElementsByClassName(CONSTANTS.NO_PHONE_ERROR)[0];
            $verificationSelect = $editProfile.getElementsByClassName(
                CONSTANTS.VERIFICATION_SELECT
            )[0];
            $cancelButtons = $editProfile.getElementsByClassName(CONSTANTS.CANCEL_CTA);
            $step2CancelButton = $editProfile.getElementsByClassName(CONSTANTS.STEP2_CANCEL_CTA)[0];
            $editButtons = $editProfile.getElementsByClassName(CONSTANTS.EDIT_MAIN_CTA);
            $saveButtons = $editProfile.getElementsByClassName(CONSTANTS.SAVE_CTA);
            $sendCodeButton = $editProfile.getElementsByClassName(CONSTANTS.SEND_CODE_CTA)[0];
            $sendCodeText = $editProfile.getElementsByClassName(
                CONSTANTS.VERIFICATION_PHONE_PARAGRAPH
            )[0];
            $sendCodeSection = $editProfile.getElementsByClassName(
                CONSTANTS.VERIFICATION_CODE_SECTION
            )[0];
            $sendCodeTryAgain = $editProfile.getElementsByClassName(
                CONSTANTS.VERIFICATION_TRY_AGAIN
            )[0];
            $sendCodeInput = $editProfile.getElementsByClassName(
                CONSTANTS.VERIFICATION_CODE_INPUT
            )[0];
            $editFields = $editProfile.getElementsByClassName(CONSTANTS.EDIT_FIELD);
            $editDescriptions = $editProfile.getElementsByClassName(CONSTANTS.FIELD_DESCRIPTION);
            $editInputsContainer = $editProfile.getElementsByClassName(
                CONSTANTS.FIELD_INPUT_CONTAINER
            );
            $languageGroup = $editProfile.getElementsByClassName(CONSTANTS.LANGUAGE_GROUP)[0];
            $languageSelect = $editProfile.getElementsByClassName(CONSTANTS.LANGUAGE_SELECT)[0];
            $currentPassword = $editProfile.getElementsByClassName(
                CONSTANTS.CURRENT_PASSWORD_INPUT
            )[0];
            $newPasswordInput = $editProfile.getElementsByClassName(
                CONSTANTS.NEW_PASSWORD_INPUT
            )[0];
            $verificationMethods = $editProfile.getElementsByClassName(
                CONSTANTS.VERIFICATION_METHOD
            );
            $passValidationParagraph = $editProfile.getElementsByClassName(
                CONSTANTS.PASSWORD_VALIDATION_PARAGRAPH
            );
            $accountsListExportLink = $editProfile.getElementsByClassName(
                CONSTANTS.DOWNLOAD_ACCOUNTS_CLASS
            );
            $accountsListExportErrorPopUp = $editProfile.getElementsByClassName(CONSTANTS.DOWNLOAD_ACCOUNTS_POPUP_ERROR_CLASS)[0];
        };

        var initializeEvents = function () {
            // Edit profile field listener
            _.each($editFieldButtons, function ($editButton) {
                coned.utils.addGeneralListeners($editButton, editField);
            });

            // Language select
            if ($languageSelect) {
                $languageSelect.addEventListener('change', setLanguageChannel);
            }

            // Language select
            if ($verificationSelect) {
                $verificationSelect.addEventListener('change', hideCancelButton);
            }

            // Email list handler
            emailList();

            // Validate fields
            new coned.components.ValidateForm(
                CONSTANTS.EDIT_PROFILE_FORM,
                '',
                CONSTANTS.FORM_IGNORE_VALIDATION
            );

            // Send code try again
            coned.utils.addGeneralListeners($sendCodeTryAgain, hideCodeResponse);

            //Download Accounts List Link
            _.each($accountsListExportLink, function ($downloadtListLink) {
                coned.utils.addGeneralListeners($downloadtListLink, exportAccountsList);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    EditProfile.prototype.isLoaded = function () {
        return isLoaded;
    };

    return EditProfile;
})();
