// ==================== MODAL =========================

var coned = coned || {};
coned.components = coned.components || {};

/**
 * @returns the init function to start the module
 */
coned.components.Modal = (function () {
    var isLoaded = false;
    /**
     * @typedef StateModal
     * @property {boolean} open
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        CLASSES: {
            /**
             * @type {'hidden'}
             */
            HIDDEN: 'hidden',
            /**
             * @type {'va-chat'}
             */
            VIRTUAL_ASSISTANT: 'va-chat',
            /**
             * @type {'modal-footer--show'}
             */
            FOOTER_SHOW: 'modal-footer--show'
        },
        SELECTORS: {
            /**
             * @type {'js-select-miltiple-option'}
             */
            SELECT_MULTIPLE: 'js-select-miltiple-option',
            /**
             * @type {'js-modal-open'}
             */
            BUTTON_OPEN: 'js-modal-open',
            /**
             * @type {'js-modal-focus-open'}
             */
            BUTTON_FOCUS_OPEN: 'js-modal-focus-open',
            /**
             * @type {'js-modal-close'}
             */
            BUTTON_CLOSE: 'js-modal-close',
            /**
             * @type {'js-modal-focus-close'}
             */
            BUTTON_FOCUS_CLOSE: 'js-modal-focus-close',
            /**
             * @type {'js-modal-container'}
             */
            CONTAINER: 'js-modal-container',
            /**
             * @type {'js-modal-content'}
             */
            CONTENT: 'js-modal-content',
            /**
             * @type {'js-modal-footer'}
             */
            FOOTER: 'js-modal-footer'
        }
    }
    /**
     * @constructor
     * @param {HTMLElement} $modal
     * @returns {}
     */
    var Modal = function ($modal) {
        /**
         * PRIVATE VARIABLES
         */

        /**
         * @type {StateModal}
         */
        var _state,
            /**
             * @type {HTMLElement[]}
             */
            $btnCloseList,
            /**
             * @type {HTMLElement}
             */
            $container,
            /**
             * @type {HTMLElement}
             */
            $content,
            /**
             * @type {HTMLElement | null}
             */
            $footer,
            /**
             * @type {boolean}
             */
            hasClickContainerClose,
            /**
             * @type {HTMLElement[]}
             */
            $focusableElements,
            /**
             * @type {HTMLElement[]}
             */
            $btnOpenList,
            /**
             * @type {HTMLElement}
             */
            $vaChat,
            /**
             * @type {HTMLElement | undefined}
             */
            $btnFocusOpen,
            /**
             * @type {HTMLElement | undefined}
             */
            $btnFocusClose,
            /**
             * @type {boolean}
             */
            _isFixedFooter,
            /**
             * @type {MutationObserver}
             */
            _footerWatch,
            /**
             * @type {HTMLElement[]}
             */
            $footerFocusables;

        /**
         * PRIVATE METHODS
         */

        /**
         * Update html state
         */
        var updateOpenHtml = function () {
            $modal.setAttribute(
                coned.constants.ARIA.HIDDEN,
                _state.open ? coned.constants.FALSE : coned.constants.TRUE
            );
            if (_state.open) {
                setTimeout(function () {
                    document.body.classList.add(coned.constants.MODAL_OPEN);
                    if ($vaChat) {
                        $vaChat.classList.add(CONSTANTS.CLASSES.HIDDEN);
                    }
                }, 200);
                setTimeout(function () {
                    if (_state.open && $btnFocusClose && $btnFocusOpen) {
                        $btnFocusOpen.blur();
                        $btnFocusClose.focus();
                    }
                }, 400)

            } else {
                setTimeout(function () {
                    document.body.classList.remove(coned.constants.MODAL_OPEN);
                    if ($vaChat) {
                        $vaChat.classList.remove(CONSTANTS.CLASSES.HIDDEN);
                    }
                    if ($btnFocusOpen) {
                        $btnFocusOpen.focus();
                    }
                }, 200);
            }
        }
        /**
         * update changes in state
         * @param {StateModal} newState 
         */
        var setState = function (newState) {
            if (_state.open !== newState.open) {
                _state.open = newState.open;
                updateOpenHtml();
                coned.utils.triggerEvent(
                    $modal,
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    _state
                );
            }
        }
        /**
         * handle external change state
         * @param {CustomEvent<StateModal>} event 
         */
        var handleSetState = function (event) {
            if (event && typeof event.detail.open !== 'undefined') {
                setState({ open: event.detail.open });
            }
        }
        /**
         * handle btn close click
         */
        var handleCloseClick = function () {
            setState({ open: false });
        }
        /**
         * Handle container click
         * @param {MouseEvent} event 
         */
        var handleContainerClick = function (event) {
            if (!$content.contains(event.target) && _state.open) {
                setState({ open: false });
            }
        }
        /**
         * Handle btn open click
         * @param {MouseEvent} event
         */
        var handleOpenClick = function (event) {
            var $element = event.currentTarget;
            if ($element instanceof HTMLElement) {
                var isFocusable = coned.utils.isFocusableElement($element);
                if (isFocusable) {
                    setState({ open: true });
                }
            }
        }
        /**
         * Handle btn open keydown
         * @param {KeyboardEvent} event
         */
        var handleOpenKeyDown = function (event) {
            var $element = event.currentTarget,
                keyCode = event.code,
                isSpace = keyCode === coned.constants.KEYBOARD_CODE.SPACE,
                isEnter = keyCode === coned.constants.KEYBOARD_CODE.ENTER || keyCode === coned.constants.KEYBOARD_CODE.NUMPAD_ENTER;
            if ($element instanceof HTMLElement) {
                var isFocusable = coned.utils.isFocusableElement($element);
                if (isFocusable && (isSpace || isEnter)) {
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    setState({ open: true });
                }
            }
        }
        /**
         * Handle keyboard event
         * @param {KeyboardEvent} event 
         */
        var handleKeyboardEvent = function (event) {
            var keyCode = event.code,
                $element = event.target,
                isShiftPressed = event.shiftKey,
                isTab = keyCode === coned.constants.KEYBOARD_CODE.TAB,
                isSpace = keyCode === coned.constants.KEYBOARD_CODE.SPACE,
                isEnter = keyCode === coned.constants.KEYBOARD_CODE.ENTER || keyCode === coned.constants.KEYBOARD_CODE.NUMPAD_ENTER;

            if (_state.open) {
                event.preventDefault();
                if (isTab) {
                    if (isShiftPressed) {
                        coned.utils.handleNextPrevFocus(coned.constants.ORDER.PREV, $element, $focusableElements);
                    } else {
                        coned.utils.handleNextPrevFocus(coned.constants.ORDER.NEXT, $element, $focusableElements);
                    }
                }
                if (isSpace || isEnter) {
                    event.preventDefault();
                    if ($element instanceof HTMLElement &&
                        !$element.classList.contains(CONSTANTS.CLASSES.SELECT_MULTIPLE)
                    ) {
                        $element.click()
                    }
                }
            }
        }

        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            _state = {
                open: false
            };
            $vaChat = document.getElementsByClassName(CONSTANTS.CLASSES.VIRTUAL_ASSISTANT)[0];
            $container = $modal.getElementsByClassName(CONSTANTS.SELECTORS.CONTAINER)[0];
            hasClickContainerClose = $modal.dataset.clickContainerClose === coned.constants.TRUE;
            $btnOpenList = coned.utils.arrayFrom(
                $modal.parentElement.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON_OPEN)
            );
            $footer = $modal.getElementsByClassName(CONSTANTS.SELECTORS.FOOTER)[0];
            _isFixedFooter = $footer ? $footer.dataset.fixed === coned.constants.TRUE : false;
            $footerFocusables = $footer ? Array.from($footer.querySelectorAll(
                coned.constants.FOCUSABLE_ELEMENTS_DROPDOWN_MODAL_QUERY
            )) : [];
            $content = $modal.getElementsByClassName(CONSTANTS.SELECTORS.CONTENT)[0];
            $focusableElements = coned.utils.arrayFrom(
                $content.querySelectorAll(
                    coned.constants.FOCUSABLE_ELEMENTS_DROPDOWN_MODAL_QUERY
                )
            ).sort(coned.utils.arrayFocusSort);
            $btnCloseList = coned.utils.arrayFrom(
                $modal.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON_CLOSE)
            );
            $btnFocusOpen = $modal.parentElement.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON_FOCUS_OPEN)[0];
            $btnFocusClose = $modal.parentElement.getElementsByClassName(CONSTANTS.SELECTORS.BUTTON_FOCUS_CLOSE)[0];
            _footerWatch = new MutationObserver(handleFooterClassChange);
        }
        /**
         * 
         * @param {FocusEvent} event 
         */
        var handleFocusableFocusIn = function (event) {
            if (event.currentTarget instanceof HTMLElement) {
                event.currentTarget.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            }
        }
        /**
         * 
         * @param {MutationRecord} mutationsList 
         */
        var handleFooterClassChange = function (mutationsList) {
            mutationsList.forEach(function (mutation) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    //var $footerFocusables = 
                    if (!$footer.classList.contains(CONSTANTS.CLASSES.FOOTER_SHOW)) {
                        $focusableElements = $focusableElements.filter(function ($el) {
                            return !$footerFocusables.includes($el);
                        });
                    } else {
                        $focusableElements = $focusableElements.concat($footerFocusables);
                    }
                }
            });
        }
        /**
         * Initialize the events in the module
         */
        var initializeEvents = function () {
            $modal.addEventListener('keydown', handleKeyboardEvent);
            $modal.addEventListener(
                coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                handleSetState
            );
            $btnCloseList.forEach(function ($element) {
                $element.addEventListener('click', handleCloseClick);
            });
            $btnOpenList.forEach(function ($element) {
                $element.addEventListener('click', handleOpenClick);
                $element.addEventListener('keydown', handleOpenKeyDown);
            });
            if (hasClickContainerClose) {
                $container.addEventListener('click', handleContainerClick);
            }
            if ($focusableElements) {
                if (_isFixedFooter) {
                    $focusableElements = $focusableElements.filter(function ($el) {
                        return !$footerFocusables.includes($el);
                    })
                }
                $focusableElements.forEach(function ($el) {
                    $el.addEventListener('focusin', handleFocusableFocusIn);
                });
            }
            if ($footer && _isFixedFooter) {
                _footerWatch.observe($footer, { attributes: true, attributeFilter: ['class'] });
            }
        }
        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    Modal.prototype.isLoaded = function () {
        return isLoaded;
    };
    return Modal;
})();
