// ==================== CARD MODULE FORM COMPONENT =========================
/* global gsap */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CardModuleForm = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SELECT_ELEMENT: 'js-coned-select',
        HIDDEN_FIELD: 'coned-form__field-container--hidden',
        HIDDEABLE_CLASS_FIELDS: 'js-hiddeable-field',
        SELECT_CLASS: 'js-coned-select',
        SELECT_ACTIVE_CLASS: 'js-coned-select-active',
        LABEL_CLASS: 'js-coned-select-label'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var CardModuleForm = function ($CardModuleForm) {
        /**
         * PRIVATE METHODS
         */
        var $selectElement, $selectLabel;

        var changeOption = function () {
            var $elementsList, elementIndex, selectedField;

            $elementsList = $CardModuleForm.getElementsByClassName(
                CONSTANTS.HIDDEABLE_CLASS_FIELDS
            );

            for (elementIndex = 0; elementIndex < $elementsList.length; ++elementIndex) {
                query.addClass($elementsList[elementIndex], CONSTANTS.HIDDEN_FIELD);
            }

            selectedField = $selectElement.options[$selectElement.selectedIndex].value;
            elementIndex = $CardModuleForm.getElementsByClassName(selectedField)[0];
            elementIndex.value = '';
            query.removeClass(elementIndex.parentElement.parentElement, CONSTANTS.HIDDEN_FIELD);
        };

        var modifySelect = function () {
            var $select = $CardModuleForm.getElementsByClassName(CONSTANTS.SELECT_CLASS);

            $selectLabel.style.display = 'block';
            query.addClass($select, CONSTANTS.SELECT_ACTIVE_CLASS);

            gsap.to($selectLabel, {
                duration: 0.2,
                top: '10',
                opacity: '1'
            });
        };

        var initializeSelect = function () {
            $selectElement.selectedIndex = 1;
            changeOption();
            modifySelect();
        };

        var initializeData = function () {
            $selectElement = $CardModuleForm.getElementsByClassName(CONSTANTS.SELECT_ELEMENT)[0];
            $selectLabel = $CardModuleForm.getElementsByClassName(CONSTANTS.LABEL_CLASS)[0];
        };

        var initializeEvents = function () {
            $selectElement.addEventListener('change', changeOption);
            initializeSelect();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CardModuleForm.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CardModuleForm;
})();
