// ==================== SLIDER PANEL COMPONENT =========================
/* global Swiper */
var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SliderComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ARIA_CURRENT: 'aria-current',
        BUTTON_NEXT_CLASS: '.js-swiper-button-next',
        BUTTON_PREV_CLASS: '.js-swiper-button-prev',
        PAGINATION_CLASS: '.js-swiper-pagination',
        SLIDER_COMPONENT_CLASS: '.js-slider-component',
        BULLET_BUTTON_CLASS: 'js-bullet-button',
        BULLET_ACTIVE_CLASS: '.swiper-pagination-bullet-active',
        ACTIVE_SLIDE: 'swiper-slide-active',
        DUPLICATE_SLIDES_CLASS: 'swiper-slide-duplicate',
        SLIDER_IMAGE_CLASS: 'js-slide-image',
        SLIDES_SELECTOR: 'js-slide',
        SLIDE_INDEX_ATTR_SELECTOR: 'data-swiper-slide-index',
        POP_UP_SELECTOR: 'js-popup',
        SLIDER_COUNTER: 'js-slider-counter',
        SLIDER_COUNTER_CURRENT: 'js-slider-counter-current',
        SLIDER_COUNTER_TOTAL: 'js-slider-counter-total',
        POPUP_VISIBLE_EVENT: 'popup-visible',
        HIDDEN_CLASS: 'hidden',
        RESIZE_EVENT: 'resize',
        SLIDE_CHANGE_TRANSITION_END_EVENT: 'slideChangeTransitionEnd',
        DATA_BULLET_INDEX_ATTR: 'data-bullet-index',
        PX: 'px',
        ANIMATION_SPEED: 500
    };

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var SliderComponent = function ($slider) {
        /**
         * PRIVATE METHODS
         */

        var $currentPaginationBullet,
            $nextButton,
            $paginationBullets,
            $paginationContainer,
            $prevButton,
            $sliderContainer, 
            $totalSlides,
            $popupParent,
            $sliderCounter,
            $sliderCounterCurrent,
            $sliderCounterTotal,
            _gallery;


        /**
         * Updates the current pagination bullet that is being shown
         * on the screen. 
         * @param {Object} event 
         * @param {Object} newPaginationBullet 
         */
        var updateCurrentSelectedBullet = function (event, newCurrentButton) {
            $currentPaginationBullet && $currentPaginationBullet.removeAttribute(CONSTANTS.ARIA_CURRENT);
            $currentPaginationBullet = newCurrentButton || event.target;
            $currentPaginationBullet && $currentPaginationBullet.setAttribute(CONSTANTS.ARIA_CURRENT, true);
        };

        /**
        * Updates the counter when clicking on a bullet.
        * @param {Object} event 
        */
        var bulletUpdateCounter = function (event) {
            var currentBulletIndex = event.target.getAttribute(CONSTANTS.DATA_BULLET_INDEX_ATTR);

            $sliderCounterCurrent.innerHTML = currentBulletIndex;
        };

        /**
        * Updates the counter when clicking on the arrows.
        */
        var updateCounter = function () {
            var counterTotal = parseInt($sliderCounterTotal.innerHTML),
                totalSlides = $totalSlides.length,
                $activeSlide = $slider.getElementsByClassName(CONSTANTS.ACTIVE_SLIDE)[0];


            if (counterTotal !== totalSlides) {
                $sliderCounterTotal.innerHTML = $totalSlides.length;
            }

            $sliderCounterCurrent.innerHTML = parseInt($activeSlide.getAttribute(CONSTANTS.SLIDE_INDEX_ATTR_SELECTOR)) + 1;
        };
        
        /**
        * Handles next & previous clicks.
        * @param {Object} event 
        */
        var onNextPrevButtonClick = function (event) {
            var newCurrentButton = $paginationContainer.querySelector(CONSTANTS.BULLET_ACTIVE_CLASS);
            updateCurrentSelectedBullet(event, newCurrentButton);
        };

        /**
         * Creates the bullet buttons markup to be accessible.
         * @param {number} index 
         * @param {string} className 
         * @returns {string} Bullet buttons markup
         */
        var renderPaginationBullets = function (index, className) {
            className += ' ' + CONSTANTS.BULLET_BUTTON_CLASS;
            var attributes = 'aria-label = "View slide ' + (index + 1) + '"' + 'data-bullet-index ="' + (index + 1) + '"';

            if (index === 0) {
                attributes += ' aria-current = "true"';
            }

            return '<button class = "' + className + '"' + attributes + '></button>';
        };

        /**
        * Updates the arrows top position to be exactly in the middle of the image. 
        * Useful for cases when we have an image and another elment below it that slides.
        */
        var setArrowsPosition = function () {
            var $activeSlide = $slider.getElementsByClassName(CONSTANTS.ACTIVE_SLIDE)[0],
                $activeImage = $activeSlide.getElementsByClassName(CONSTANTS.SLIDER_IMAGE_CLASS)[0],
                activeImageHeight = $activeImage.offsetHeight,
                activeImageMarginTop = parseInt(window.getComputedStyle($activeImage).marginTop),
                buttonsHeight = $nextButton.offsetHeight,
                buttonsTop = ((activeImageHeight / 2) - (buttonsHeight / 2)) + activeImageMarginTop;
            
                $nextButton.style.top = buttonsTop + CONSTANTS.PX;
                $prevButton.style.top = buttonsTop + CONSTANTS.PX;
        }

        /**
        * Initializes the slider with or without controls depending on number of slides.
        */
        var initializeSlider = function () {
            $sliderContainer = $slider.querySelector(CONSTANTS.SLIDER_COMPONENT_CLASS);
            $totalSlides = $slider.querySelectorAll('.' + CONSTANTS.SLIDES_SELECTOR + ':not(.' + CONSTANTS.DUPLICATE_SLIDES_CLASS + ')');

            // When there is only one slide hide controls and prevent swipping/sliding behavior. 
            // Note: this will only work when .js-slide class is present, this class was not part of the original implementation.
            if ($totalSlides.length === 1) {
                _gallery = new Swiper($sliderContainer, {
                    watchOverflow: true,
                    loop: false,
                    followFinger: false
                });

                query.addClass($nextButton, CONSTANTS.HIDDEN_CLASS);
                query.addClass($prevButton, CONSTANTS.HIDDEN_CLASS);
                query.addClass($sliderCounter, CONSTANTS.HIDDEN_CLASS);
    
            } else {
                _gallery = new Swiper($sliderContainer, {
                    speed: CONSTANTS.ANIMATION_SPEED,
                    loop: true,
                    followFinger: false,
                    pagination: {
                        el: CONSTANTS.PAGINATION_CLASS,
                        clickable: true,
                        renderBullet: renderPaginationBullets
                    },
                    navigation: {
                        nextEl: CONSTANTS.BUTTON_NEXT_CLASS,
                        prevEl: CONSTANTS.BUTTON_PREV_CLASS
                    }
                });

                $sliderCounter && updateCounter();
            }

            _gallery.init();
        };

        /**
        * Add shared listeners to the bullets.
        */
        var addBulletsListeners = function () {
            for (var bulletIndex = 0; bulletIndex < $paginationBullets.length; bulletIndex++) {
                coned.utils.addGeneralListeners($paginationBullets[bulletIndex], updateCurrentSelectedBullet);
                $sliderCounter && coned.utils.addGeneralListeners($paginationBullets[bulletIndex], bulletUpdateCounter);
            }
        };

        /**
        * Add listeners to sliders that live in a popup.
        */
        var addPopupSliderListeners = function () {
            window.addEventListener(CONSTANTS.RESIZE_EVENT, setArrowsPosition);
            addBulletsListeners();

            // Update the slider counter on slide change on arrows click
            if ($sliderCounter) {
                _gallery.on(CONSTANTS.SLIDE_CHANGE_TRANSITION_END_EVENT, updateCounter);
            }
        };
      
        /**
        * Initialize shared bullets data.
        */
        var initializeBulletsData = function () {
            $paginationBullets = $paginationContainer.querySelectorAll('.' + CONSTANTS.BULLET_BUTTON_CLASS);
            $currentPaginationBullet = $paginationBullets[0];
        };

        /**
        * Check if slider lives in a popup
        */
        var preInitData = function () {
            $popupParent = query.selectParentElement($slider, CONSTANTS.POP_UP_SELECTOR);
        };

        var initializeData = function () {
            $nextButton = $slider.querySelector(CONSTANTS.BUTTON_NEXT_CLASS);
            $prevButton = $slider.querySelector(CONSTANTS.BUTTON_PREV_CLASS);
            $paginationContainer = $slider.querySelector(CONSTANTS.PAGINATION_CLASS);
            $sliderCounter = $slider.getElementsByClassName(CONSTANTS.SLIDER_COUNTER)[0];
            $sliderCounterCurrent = $slider.getElementsByClassName(CONSTANTS.SLIDER_COUNTER_CURRENT)[0];
            $sliderCounterTotal = $slider.getElementsByClassName(CONSTANTS.SLIDER_COUNTER_TOTAL)[0];

            if (!$popupParent) { 
                initializeBulletsData();
            }
        };

        var initializeEvents = function () {
            if (!$popupParent) {
                addBulletsListeners();
            } else {
                // If slider is in a popup, it needs to be initialized until it's visible
                // to make its size calculations, and so we need to set some other things after init
                $popupParent.addEventListener(CONSTANTS.POPUP_VISIBLE_EVENT, function () {
                    initializeSlider();
                    setArrowsPosition();
                    initializeBulletsData();
                    addPopupSliderListeners();
                });
            }

            coned.utils.addGeneralListeners($nextButton, onNextPrevButtonClick);
            coned.utils.addGeneralListeners($prevButton, onNextPrevButtonClick);
        };
        

        /**
         * Inits functionality in the module.
         * This inits a slick Slider for all appereances in the site.
         */
        var init = function () {
            preInitData();
            !$popupParent && initializeSlider();
            initializeData();
            initializeEvents();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */
    return SliderComponent;
})();
