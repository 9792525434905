// ==================== SELECTMULTIPLE =========================

var coned = coned || {};

coned.components = coned.components || {};
/**
 * @returns the init function to start the module
 */
coned.components.SelectMultiple = (function () {

    /**
     * @typedef {Object} SelectMuptipleState
     * @property {string} name 
     * @property {string[]} value
     * 
     */

    /**
     * @typedef {Object} OptionSelectedValue
     * @property {boolean} selected 
     * @property {string} value 
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        CLASSES: {
            /**
             * @type {'select-multiple__option--focus'}
             */
            OPTION_FOCUS: 'select-multiple__option--focus',
            /**
             * @type {'coned-checkbox--focus'}
             */
            OPTION_CHECKBOX_FOCUS: 'coned-checkbox--focus',
            /**
             * @type {'coned-checkbox--checked'}
             */
            CHECKBOX_CHECKED: 'coned-checkbox--checked'
        },
        SELECTORS: {
            /**
             * @type {'js-select-miltiple-option'}
             */
            OPTIONS: 'js-select-miltiple-option',
            /**
             * @type {'js-checkbox-selector'}
             */
            CHECKBOX: 'js-checkbox-selector'
        },
        ATTRIBUTES: {
            /**
             * @type {'checked'}
             */
            CHECKED: 'checked',
            /**
             * @type {'data-value'}
             */
            DATA_VALUE: 'data-value'
        }
    };
    var isLoaded = false;
    /**
     * Constructor
     * @param { HTMLDivElement } $selectMultiple
     * @returns {}
     */
    var SelectMultiple = function ($selectMultiple) {
        /**
         * PRIVATE VARIABLES
         */

        /**
         * @type {SelectMuptipleState}
         */
        var _state,
            /**
             * @type {HTMLElement[]}
             */
            $options;

        /**
         * PRIVATE METHODS
         */
        /**
         * Helper function to update html attributes of options
         * @param {HTMLElement} $option 
         * @param {boolean} isSelected
         */
        var setOptionSelected = function ($option, isSelected) {
            var $targetOptionCheckbox = $option.getElementsByClassName(CONSTANTS.SELECTORS.CHECKBOX)[0];
            // Ensures the selected element is an input element
            if ($targetOptionCheckbox instanceof HTMLInputElement) {
                var $parentLabel = $targetOptionCheckbox.parentElement;
                if ($parentLabel) {
                    if (isSelected) {
                        $parentLabel.classList.add(CONSTANTS.CLASSES.CHECKBOX_CHECKED);
                    }
                    else {
                        $parentLabel.classList.remove(CONSTANTS.CLASSES.CHECKBOX_CHECKED);
                    }

                }
                if (isSelected) {
                    $targetOptionCheckbox.setAttribute(CONSTANTS.ATTRIBUTES.CHECKED, isSelected);
                } else {
                    $targetOptionCheckbox.removeAttribute(CONSTANTS.ATTRIBUTES.CHECKED);
                }
            }
            $option.setAttribute(coned.constants.ARIA.CHECKED, isSelected);
        }
        /**
         * Function to make changes by state
         */
        var updateHtml = function () {
            $selectMultiple.dataset.value = JSON.stringify(_state.value);
            $options.forEach(function ($item) {
                var optionSelectedValue = getOptionSelectedValue($item),
                    isIncluded = _state.value.includes(optionSelectedValue.value);
                setOptionSelected($item, isIncluded);
            })
        }
        /**
         * Update module state and html
         * @param {string[]} newValue 
         */
        var setValueHTML = function (newValue) {
            _state.value = newValue;
            updateHtml();
            coned.utils.triggerEvent(
                $selectMultiple,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _state
            );
        }
        /**
         * modify module state and items html
         * @param {OptionSelectedValue} item 
         */
        var toggleItemStateValue = function (item) {
            var selectedItems = _state.value.concat(),
                isIncluded = selectedItems.includes(item.value);
            if (isIncluded && item.selected) {
                selectedItems = selectedItems.filter(function (el) {
                    return el !== item.value;
                })
            }
            if (!isIncluded && !item.selected) {
                selectedItems.push(item.value);
            }
            setValueHTML(selectedItems.concat());
        }
        /**
         * get selected and value from option
         * @param {HTMLElement} $element 
         * @returns 
         */
        var getOptionSelectedValue = function ($element) {
            var $checkbox = $element.getElementsByClassName(CONSTANTS.SELECTORS.CHECKBOX)[0],
                isSelected = $element.getAttribute(coned.constants.ARIA.CHECKED) === coned.constants.TRUE,
                optionValue = $element.getAttribute(CONSTANTS.ATTRIBUTES.DATA_VALUE),
                // Element type no have a .value property only we access if Element 
                // is an instance of HTMLInputElement
                checkboxValue = $checkbox instanceof HTMLInputElement ? $checkbox.value : '';

            return {
                value: optionValue ? optionValue : checkboxValue,
                selected: isSelected
            };
        }
        /**
         * handle option click
         * @param {MouseEvent} event 
         */
        var handleOptionClick = function (event) {
            event.preventDefault();
            // EventTarget is not HTMLElement here we ensure an casting as HTMLElement
            if (event.currentTarget instanceof HTMLElement) {
                var itemSelectedValue = getOptionSelectedValue(event.currentTarget);
                toggleItemStateValue(itemSelectedValue);
            }

        }
        /**
         * handle keyboard events on options
         * @param {KeyboardEvent} event 
         */
        var handleOptionKeyboard = function (event) {
            if (event.target) {
                /**
                 * @type {HTMLElement}
                 */
                var $optionElement = event.target || event.currentTarget;
                if (
                    event.code === coned.constants.KEYBOARD_CODE.ENTER ||
                    event.code === coned.constants.KEYBOARD_CODE.SPACE ||
                    event.code === coned.constants.KEYBOARD_CODE.NUMPAD_ENTER
                ) {
                    event.stopImmediatePropagation();
                    event.preventDefault();
                    $optionElement.click();
                } else if (
                    event.code === coned.constants.KEYBOARD_CODE.ESC
                ) {
                    $optionElement.blur();
                }
            }
        }
        /**
         * handle external set value
         * @param {CustomEvent<{value:string[]}>} event 
         */
        var handleSetValue = function (event) {
            setValueHTML(event.detail.value);
        }
        /**
         * Set custom focus to option
         * @param {HTMLElement} $element
         * @param {boolean} value 
         */
        var setFocusElement = function ($element, value) {
            var $checkbox = $element.getElementsByClassName(CONSTANTS.SELECTORS.CHECKBOX)[0];
            if (value) {                
                if ($checkbox) {
                    var $parentLabel = $checkbox.parentElement;
                    if ($parentLabel && !$parentLabel.classList.contains(CONSTANTS.CLASSES.OPTION_CHECKBOX_FOCUS)) {
                        $parentLabel.classList.add(CONSTANTS.CLASSES.OPTION_CHECKBOX_FOCUS);
                    }
                } else {
                    if (!$element.classList.contains(CONSTANTS.CLASSES.OPTION_FOCUS)) {
                        $element.classList.add(CONSTANTS.CLASSES.OPTION_FOCUS);
                    }
                }
            } else {
                coned.utils.arrayFrom(
                    $element.getElementsByClassName(CONSTANTS.CLASSES.OPTION_FOCUS)
                ).forEach(function ($el) {
                    $el.classList.remove(CONSTANTS.CLASSES.OPTION_FOCUS);
                });
                coned.utils.arrayFrom(
                    $element.getElementsByClassName(CONSTANTS.CLASSES.OPTION_CHECKBOX_FOCUS)
                ).forEach(function ($el) {
                    $el.classList.remove(CONSTANTS.CLASSES.OPTION_CHECKBOX_FOCUS);
                });
            }

        }
        /**
         * Handle focusIn event on options for set custom focus
         * @param {FocusEvent} event 
         */
        var handleFocus = function (event) {
            var $element = event.target || event.currentTarget;
            if ($element) {
                setFocusElement($element, true)
            }
        }
        /**
         * Handle focusOut event on Options for set custom focus
         * @param {FocusEvent} event
         */
        var handleFocusOut = function (event) {
            var $element = event.target || event.currentTarget;
            if ($element) {
                setFocusElement($element, false)
            }
        }

        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            var name = typeof $selectMultiple.dataset.name !== 'undefined'
                ? $selectMultiple.dataset.name
                : '';
            _state = {
                name: name,
                value: []
            }
            $options = coned.utils.arrayFrom(
                $selectMultiple.getElementsByClassName(CONSTANTS.SELECTORS.OPTIONS));
        }
        /**
         * Initialize the events in the module
         */
        var initializeEvents = function () {
            $options.forEach(function ($option) {
                $option.addEventListener('click', handleOptionClick);
                $option.addEventListener('keydown', handleOptionKeyboard);
                $option.addEventListener('focusin', handleFocus);
                $option.addEventListener('focusout', handleFocusOut);
            });
            $selectMultiple.addEventListener(
                coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                handleSetValue
            );
        }
        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            coned.utils.triggerEvent(
                $selectMultiple,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _state
            );
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    SelectMultiple.prototype.isLoaded = function () {
        return isLoaded;
    };
    return SelectMultiple;
})();