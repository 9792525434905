// ==================== LOADING ANIMATION COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoadingAnimation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SLICE_RIGHT_SELECTOR: 'js-slice-right-selector',
        SLICE_LEFT_SELECTOR: 'js-slice-left-selector',
        SLICE_RIGHT_WHITE_CLASS: 'loading-animation__slice-right--white',
        SLICE_LEFT_WHITE_CLASS: 'loading-animation__slice-left--white',
        ANIMATION_PLAY: 'js-animation-play'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LoadingAnimation = function ($loadingAnimation) {
        /**
         * PRIVATE METHODS
         */
        var $sliceRightSelector,
            $sliceLeftSelector,
            $pageContent,
            _progressBarSpeed = 20;

        var rotateSlice = function (element, degree) {
            // Set transform property
            var styles =
                '-webkit-transform rotate(' +
                degree +
                'deg);' +
                '-moz-transform rotate(' +
                degree +
                'deg);' +
                '-ms-transform: rotate(' +
                degree +
                'deg);' +
                '-o-transform: rotate(' +
                degree +
                'deg);' +
                'transform: rotate(' +
                degree +
                'deg);' +
                'zoom: 1;';

            element.setAttribute('style', styles);
        };

        var circleBarAnimation = function (actualProgress, outOf) {
            var firstHalfAngle = 180,
                secondHalfAngle = 0,
                drawAngle = (actualProgress / outOf) * 360;

            // calculate the angle to be displayed in each half
            if (drawAngle <= 180) {
                firstHalfAngle = drawAngle;
            } else {
                secondHalfAngle = drawAngle - 180;
            }

            // set the transition
            rotateSlice($sliceRightSelector, firstHalfAngle);
            rotateSlice($sliceLeftSelector, secondHalfAngle);
        };

        var moveBar = function (speed) {
            var progress = 0,
                id = setInterval(frame, speed);

            if (!$loadingAnimation.dataset.noPageDisabled || $loadingAnimation.dataset.noPageDisabled !== 'true') {
                $pageContent.classList.add(coned.constants.PAGE_DISABLED);
            }

            function frame() {
                if (progress == 100) {
                    if (!query.hasClass($loadingAnimation, CONSTANTS.ANIMATION_PLAY)) {
                        clearInterval(id);

                        // Progress bar reset
                        $sliceLeftSelector.removeAttribute('style');
                        $sliceRightSelector.removeAttribute('style');

                        if (!$loadingAnimation.dataset.noPageDisabled || $loadingAnimation.dataset.noPageDisabled !== 'true') {
                            $pageContent.classList.remove(coned.constants.PAGE_DISABLED);
                        }
                    } else {
                        progress = 0;

                        $sliceRightSelector.classList.toggle(CONSTANTS.SLICE_RIGHT_WHITE_CLASS);
                        $sliceLeftSelector.classList.toggle(CONSTANTS.SLICE_LEFT_WHITE_CLASS);
                    }
                } else {
                    progress++;
                    circleBarAnimation(progress, 100);
                }
            }
        };

        var initializeData = function () {
            $sliceRightSelector = $loadingAnimation.getElementsByClassName(CONSTANTS.SLICE_RIGHT_SELECTOR)[0];
            $sliceLeftSelector = $loadingAnimation.getElementsByClassName(CONSTANTS.SLICE_LEFT_SELECTOR)[0];
            $pageContent = document.getElementsByClassName(coned.constants.PAGE_CONTENT_CLASS)[0];
        };

        var initializeEvents = function () {
            if (query.hasClass($loadingAnimation, CONSTANTS.ANIMATION_PLAY)) {
                setTimeout(function () {
                    moveBar(_progressBarSpeed, true);
                }, 300);
            }

            $loadingAnimation.addEventListener('start-animation', function () {
                $loadingAnimation.classList.add(CONSTANTS.ANIMATION_PLAY);

                setTimeout(function () {
                    moveBar(_progressBarSpeed, true);
                }, 300);
            });

            $loadingAnimation.addEventListener('stop-animation', function () {
                $loadingAnimation.classList.remove(CONSTANTS.ANIMATION_PLAY);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoadingAnimation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LoadingAnimation;
})();
