// ==================== TABLE TABS NAVIGATION COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.TableTabsNavigation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        TAB_SELECTOR: 'js-tab-selector',
        TABLE_SELECTOR: 'js-table-selector',
        ACTIVE_TAB_CLASS: 'manage-cards__tabs-link--active',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var TableTabsNavigation = function ($TableTabsNavigation) {
        /**
         * PRIVATE METHODS
         */
        var $tabSelector, $tableSelector;

        var initializeData = function () {
            $tabSelector = $TableTabsNavigation.getElementsByClassName(CONSTANTS.TAB_SELECTOR);
            $tableSelector = $TableTabsNavigation.getElementsByClassName(CONSTANTS.TABLE_SELECTOR);
        };

        var showTable = function ($tab, index) {
            query.removeClass($tabSelector, CONSTANTS.ACTIVE_TAB_CLASS);
            query.addClass($tableSelector, CONSTANTS.HIDDEN_CLASS);
            $tab.classList.add(CONSTANTS.ACTIVE_TAB_CLASS);
            $tableSelector[index].classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        /**
         *  Controller event on keydown pressed.
         * @param {Event} event default event
         * @param {$tab} tab selected tab
         * @param {index} index index of selected tab
         */
        var handlerKeydownEventListener = function (event, $tab, index) {
            switch (event.keyCode) {
                case coned.constants.KEY_CODE.ENTER:
                case coned.constants.KEY_CODE.SPACE:
                    showTable($tab, index);
                    clearAriaAttributes($tab);
                    break;

                case coned.constants.KEY_CODE.HOME:
                    event.preventDefault();
                    $tabSelector[0].focus();
                    break;

                case coned.constants.KEY_CODE.END:
                    event.preventDefault();
                    $tabSelector[$tabSelector.length - 1].focus();
                    break;

                case coned.constants.KEY_CODE.RIGHT:
                case coned.constants.KEY_CODE.LEFT:
                    event.preventDefault();
                    moveUsingArrowKeys(event, $tab, index);
                    break;
            }
        };

        var moveUsingArrowKeys = function (event, $tab, index) {
            var key = event.keyCode,
                nexTab;

            if (key === coned.constants.KEY_CODE.RIGHT) {
                nexTab = getNextTab(index);
            } else {
                nexTab = getPrevTab(index);
            }

            nexTab.focus();
        };

        var getPrevTab = function (index) {
            return $tabSelector[index - 1] || $tabSelector[$tabSelector.length - 1];
        };

        var getNextTab = function (index) {
            return $tabSelector[index + 1] || $tabSelector[0];
        };

        var clearAriaAttributes = function (selectableTab) {
            _.each($tabSelector, function (tab) {
                tab.setAttribute('tabindex', '-1');
                tab.setAttribute('aria-selected', false);
            });

            selectableTab.setAttribute('tabindex', '0');
            selectableTab.setAttribute('aria-selected', true);
        };

        var initializeEvents = function () {
            _.each($tabSelector, function ($tab, index) {
                coned.utils.addGeneralListeners($tab, function (event) {
                    event.preventDefault();

                    showTable($tab, index);
                });

                //Event listener with keyboard
                $tab.addEventListener('keydown', function (event) {
                    handlerKeydownEventListener(event, $tab, index);
                });
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    TableTabsNavigation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return TableTabsNavigation;
})();
