// ==================== LOAD HERO COMPONENT =========================
/* global _ */
var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LinearStepper = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        STEPS_CONTENT_WRAPPER: 'js-steps-content',
        STEPS_CONTENT: 'js-step-content',
        LINEAR_STEPPER: 'js-linear-stepper',
        STEP: 'js-step',
        IS_CURRENT: 'is-current'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LinearStepper = function ($container) {
        /**
         * PRIVATE METHODS
         */

        var $stepsContentWrapper, $stepsContent, $linearStepper, $currentStep, _currentDataStep;

        var setCurrentStep = function () {
            //Get current step
            _.each($stepsContent, function ($stepContent) {
                if ($stepContent.dataset.step === _currentDataStep) {
                    $stepContent.classList.add(CONSTANTS.IS_CURRENT);
                }
            });
        };

        var initializeData = function () {
            $stepsContentWrapper = $container.getElementsByClassName(
                CONSTANTS.STEPS_CONTENT_WRAPPER
            )[0];
            $stepsContent = $stepsContentWrapper.getElementsByClassName(CONSTANTS.STEPS_CONTENT);
            $linearStepper = $container.getElementsByClassName(CONSTANTS.LINEAR_STEPPER)[0];
            $currentStep = $linearStepper.getElementsByClassName(CONSTANTS.IS_CURRENT)[0];
            _currentDataStep = $currentStep.dataset.step;
        };

        var initializeEvents = function () {
            setCurrentStep();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LinearStepper.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LinearStepper;
})();
