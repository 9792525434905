// ==================== ADDRESS LOOKUP COMPONENT =========================
/* global $ */
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AddressLookup = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ZIP_CODE_PARAM: '"zipcode":',
        SCID_PARAM: '"ScId":',
        ZIP_CODE_CLASS: 'js-autocomplete-zipcode',
        ZIP_CODE_ERROR_CLASS: 'js-address-autocomplete-zipcode-service-error',
        STREET_ADDRESS_CLASS: 'js-autocomplete-street-address',
        STREET_ADDRESS_SERVICE_ERROR: 'js-autocomplete-street-address-service-error',
        APARTMENT_UNIT_CLASS: 'js-autocomplete-apartment-unit',
        ADDRESS_SCID_NAME: 'AddressScId',
        ADDRESS_SELECTED_CLASS: 'js-address-autocomplete-selected',
        ADDRESS_SELECTED_DETAIL_CLASS: 'js-address-selected-detail',
        ADDRESS_SELECTED_MASKED_ACCOUNT_NUMBER: 'js-address-masked-account-number',
        SHOW_OLD_EXPERIENCE_CLASS: 'js-show-old-experience',
        ADDRESS_INPUT_FORM: 'js-address-input-form',
        ADDRESS_AUTOCOMPLETE_FORM: 'js-address-autocomplete-form',
        SEE_MORE_OPTIONS_CLASS: 'js-see-more-options',
        DEFAULT_OPTION_CLASS: 'js-default-option',
        DEFAULT_OPTION_LINK_CLASS: 'js-default-option-link',
        AUTOCOMPLETE_INPUT: 'js-autocomplete-input',
        AUTOCOMPLETE_LIST_INPUT: 'js-autocomplete-list-input',
        AUTOCOMPLETE_LIST_WRAPPER_CLASS: 'js-autocomplete-list-wrapper',
        ZIPCODE_URL_PARAMENTER: 'addressAutocompleteZipCode',
        DISABLED_CLASS: 'coned-field--disabled',
        VALID_CLASS: 'valid',
        FILLED_CLASS: 'coned-input--filled',
        HIDDEN_CLASS: 'hidden',
        VALIDATE_IGNORE: 'js-validate-ignore',
        ERROR_MESSAGE_CLASS: 'js-error-message',
        OLD_EXPERIENCE: 'input',
        AUTOCOMPLETE_ITEM: 'js-autocomplete-item',
        AUTOCOMPLETE_LIST_CLASS: 'js-autocomplete-list',
        ADDRESS_LOOKUP_BUTTON_CLASS: 'js-lookup-button',
        LI_TAG: 'li',
        UL_TAG: 'ul',
        FORM_LOADING: 'js-form-loading',
        LIVE_CHAT_AVAILABLE_SELECTOR: 'js-live-chat-available',
        LIVE_CHAT_BUTTON: 'js-live-chat-cta',
        STOP_CHAT: 'stop',
        RESET_INPUT_END_EVENT: 'resetInputEnd'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AddressLookup = function ($container) {
        /**
         * PRIVATE VARIABLES
         */
        var $zipCode,
            $zipCodeError,
            $streetAddress,
            $apartmentUnit,
            $addressSelected,
            $addressSelectedDetail,
            $addressAutocompleteForm,
            $addressInputForm,
            $addressLookupButton,
            $streetAddressListWrapper,
            $apartmentUnitListWrapper,
            $seeMoreOptions,
            $showOldExperience,
            $formLoading,
            $streetAddressServiceError,
            $liveChatAvailable,
            _apartmentUnitAutocomplete,
            _showSelectedAddress,
            _currentZipCode,
            _scId;

        var showOldExperience = function (event) {
            event.preventDefault();
            //Change the current experience to input (old experience)
            $container.dataset.currentExperience = CONSTANTS.OLD_EXPERIENCE;

            $addressAutocompleteForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $addressAutocompleteForm.classList.add(CONSTANTS.VALIDATE_IGNORE);
            $addressInputForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $addressInputForm.classList.remove(CONSTANTS.VALIDATE_IGNORE);

            manageAddressLookupButton(true);

            query.fireEvent($addressInputForm, 'show');
        };

        var validateZipField = function (event) {
            if (coned.utils.preventMovementError(event)) return;

            var zipCode = $($zipCode)[0].value,
                zipCodeStatus = zipCode.length == 5 ? $($zipCode).valid() : false,
                serviceUrl = $addressAutocompleteForm.dataset.verifyZipCodeServiceUrl,
                params;

            if (zipCodeStatus) {
                if (_currentZipCode !== $zipCode.value) {
                    _currentZipCode = $zipCode.value;

                    cleanUpStreetAndUnit();

                    params = {
                        ScId: _scId,
                        ZipCode: encodeURIComponent($zipCode.value)
                    };

                    // Service Call
                    query.getData(serviceUrl, successZipCode, failZipCode, params, $formLoading);
                }
            } else {
                cleanUpStreetAndUnit();
                _currentZipCode = $zipCode.value;
            }
        };

        var cleanUpStreetAndUnit = function () {
            //Reset Zipcode Error
            $zipCodeError.classList.add(CONSTANTS.HIDDEN_CLASS);

            //Reset Street Address
            $streetAddress.disabled = true;
            $streetAddress.parentElement.classList.add(CONSTANTS.DISABLED_CLASS);
            query.fireEvent($streetAddress.parentElement, 'resetInput');

            //Reset Apartment Unit
            $apartmentUnit.disabled = true;
            $apartmentUnit.parentElement.classList.add(CONSTANTS.DISABLED_CLASS);
            query.fireEvent($apartmentUnit.parentElement, 'resetInput');

            //Remove selected address
            cleanUpSelectedAddress();

            //If live chat is available clear interval
            if($liveChatAvailable) {   
                var $liveChatAvailableBtn = $liveChatAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_BUTTON)[0];
                coned.utils.triggerEvent($liveChatAvailableBtn, CONSTANTS.STOP_CHAT);
            }
        };

        var cleanUpUnit = function () {
            //Reset Apartment Unit
            $apartmentUnit.disabled = true;
            $apartmentUnit.parentElement.classList.add(CONSTANTS.DISABLED_CLASS);

            $apartmentUnit.dataset.unitsList = '';
            query.fireEvent($apartmentUnit.parentElement, 'resetInput');

            //Remove selected address
            cleanUpSelectedAddress();

            //If live chat is available clear interval
            if($liveChatAvailable) {   
                var $liveChatAvailableBtn = $liveChatAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_BUTTON)[0];
                coned.utils.triggerEvent($liveChatAvailableBtn, CONSTANTS.STOP_CHAT);
            }
        };

        var cleanUpSelectedAddress = function () {
            //Remove selected address
            $addressSelected.classList.add(CONSTANTS.HIDDEN_CLASS);
            $apartmentUnit.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $addressSelectedDetail.innerHTML = '';
            $addressSelectedDetail.dataset.addressLine1 = '';
            $addressSelectedDetail.dataset.addressLine2 = '';
            $addressSelectedDetail.dataset.customerAddress = '';
            $addressSelectedDetail.dataset.maid = '';
            $addressSelectedDetail.dataset.premiseNumber = '';

            //Disable find address button
            manageAddressLookupButton(true);

            //Hide more options message
            $seeMoreOptions.classList.add(CONSTANTS.HIDDEN_CLASS);

        };

        var successZipCode = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_VERIFY_ZIP_CODE,
                    enableStreetName,
                    function () {}
                );
            } else {
                enableStreetName(data);
            }
        };

        var failZipCode = function (data) {
            $zipCodeError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $zipCodeError.getElementsByClassName(
                CONSTANTS.ERROR_MESSAGE_CLASS
            )[0].innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;
            $zipCodeError.focus();
        };

        var enableStreetName = function (data) {
            if (data.isValid) {
                $streetAddress.disabled = false;
                $streetAddress.parentElement.classList.remove(CONSTANTS.DISABLED_CLASS);
                $streetAddress.parentElement.dataset.listServiceUrl =
                    $addressAutocompleteForm.dataset.getStreetsServiceUrl;
                $streetAddress.parentElement.dataset.params =
                    '{' +
                    CONSTANTS.SCID_PARAM +
                    '"' +
                    _scId +
                    '",' +
                    CONSTANTS.ZIP_CODE_PARAM +
                    '"' +
                    $zipCode.value +
                    '"' +
                    '}';
            } else if (data.errorMsg) {
                $zipCodeError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $zipCodeError.getElementsByClassName(
                    CONSTANTS.ERROR_MESSAGE_CLASS
                )[0].innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;
                query.fireEvent($zipCode, 'invalidZip');
                $zipCodeError.focus();
            }
        };

        var searchApartmentUnit = function (event) {
            if (coned.utils.preventMovementError(event)) return;

            var serviceUrl = $addressAutocompleteForm.dataset.getUnitsServiceUrl,
                params;

            params = {
                ScId: _scId,
                zipcode: encodeURIComponent($zipCode.value),
                street: encodeURIComponent($streetAddress.value)
            };

            query.fireEvent($apartmentUnit.parentElement, 'resetInput');

            // Service Call
            query.getData(serviceUrl, successStreetName, failStreetName, params, $formLoading);

            $apartmentUnit.disabled = false;
            $apartmentUnit.parentElement.classList.remove(CONSTANTS.DISABLED_CLASS);

            //If live chat is available clear interval
            if($liveChatAvailable) {   
                var $liveChatAvailableBtn = $liveChatAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_BUTTON)[0];
                coned.utils.triggerEvent($liveChatAvailableBtn, CONSTANTS.STOP_CHAT);
            }
        };

        var successStreetName = function (data) {
            if (coned.utils.isPatternLab()) {
                if ($zipCode.value === '10001') {
                    query.getData(
                        coned.plConstants.GET_ADDRESS_NO_UNITS,
                        checkUnits,
                        function () {}
                    );
                } else if ($zipCode.value === '10002') {
                    query.getData(
                        coned.plConstants.GET_ADDRESS_SINGLE_UNIT,
                        checkUnits,
                        function () {}
                    );
                } else {
                    query.getData(
                        coned.plConstants.GET_ADDRESS_UNIT_LIST,
                        checkUnits,
                        function () {}
                    );
                }
            } else {
                checkUnits(data);
            }
        };

        var failStreetName = function (data) {
            var $error = $streetAddressServiceError.getElementsByClassName(
                    CONSTANTS.ERROR_MESSAGE_CLASS
                )[0],
                errorMsg = $streetAddressServiceError.getElementsByClassName(
                    CONSTANTS.ERROR_MESSAGE_CLASS
                )[0].innerHTML;

            $error.innerHTML = data.errorMsg ? data.errorMsg : errorMsg;
            $streetAddressServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $streetAddressServiceError.focus();
        };

        var checkUnits = function (data) {
            var unitsList, accountNumber, addressLine1, addressLine2, addressInfo;

            // Trigger event to check the address if no units or single unit
            // Create input event
            var checkAddressEvent;
            // If not IE
            if (typeof Event === 'function') {
                checkAddressEvent = new Event('checkAddress', {
                    bubbles: true,
                    cancelable: true
                });
                // If IE
            } else {
                checkAddressEvent = document.createEvent('Event');
                checkAddressEvent.initEvent('checkAddress', true, true); // Flags: Bubbles, Cancelable
            }

            //Clean up the selected address
            $addressSelectedDetail.innerHTML = '';
            $addressSelected.classList.add(CONSTANTS.HIDDEN_CLASS);

            //Clean up aparment input
            $apartmentUnit.value = '';
            $apartmentUnit.dataset.unitsList = '';

            if (data.AddressInfoArray) {
                unitsList = Object.keys(data.AddressInfoArray);

                if (unitsList.length > 1) {
                    $apartmentUnit.parentElement.getElementsByClassName(
                        CONSTANTS.AUTOCOMPLETE_LIST_INPUT
                    )[0].value = unitsList.join(',');
                    $apartmentUnit.dataset.unitsList = JSON.stringify(data.AddressInfoArray);

                    $apartmentUnit.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    if (!_apartmentUnitAutocomplete) {
                        _apartmentUnitAutocomplete = new coned.components.AutocompleteInput(
                            $apartmentUnit.parentElement
                        );
                    } else {
                        query.fireEvent($apartmentUnit.parentElement, 'reloadList');
                    }
                } else if (unitsList.length === 1) {
                    accountNumber = document.createElement('span');
                    addressLine1 = document.createElement('span');
                    addressLine2 = document.createElement('span');

                    addressInfo = data.AddressInfoArray[unitsList[0]];

                    accountNumber.classList.add(CONSTANTS.ADDRESS_SELECTED_MASKED_ACCOUNT_NUMBER);
                    addressLine1.innerHTML = addressInfo.AddressLine1;
                    addressLine2.innerHTML = addressInfo.AddressLine2;
                    $addressSelectedDetail.dataset.addressLine1 = addressInfo.AddressLine1;
                    $addressSelectedDetail.dataset.addressLine2 = addressInfo.AddressLine2;
                    $addressSelectedDetail.dataset.customerAddress =
                        addressInfo.AddressLine1 + '<br>' + addressInfo.AddressLine2;
                    $addressSelectedDetail.dataset.maid = addressInfo.Maid;
                    $addressSelectedDetail.dataset.premiseNumber = addressInfo.ServiceAddress.PremiseNumber;
                    $addressSelectedDetail.dataset.serviceAddress = JSON.stringify(addressInfo.ServiceAddress);
                    $addressSelectedDetail.appendChild(accountNumber);
                    $addressSelectedDetail.appendChild(addressLine1);
                    $addressSelectedDetail.appendChild(addressLine2);

                    $apartmentUnit.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $apartmentUnit.dispatchEvent(checkAddressEvent);

                    if (_showSelectedAddress) {
                        $addressSelected.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }
                }
            }
            if (data.AddressInfo) {
                accountNumber = document.createElement('span');
                addressLine1 = document.createElement('span');
                addressLine2 = document.createElement('span');

                accountNumber.classList.add(CONSTANTS.ADDRESS_SELECTED_MASKED_ACCOUNT_NUMBER);
                addressLine1.innerHTML = data.AddressInfo.AddressLine1;
                addressLine2.innerHTML = data.AddressInfo.AddressLine2;
                $addressSelectedDetail.dataset.maid = data.AddressInfo.Maid;
                $addressSelectedDetail.dataset.premiseNumber = addressInfo.ServiceAddress.PremiseNumber;
                $addressSelectedDetail.dataset.addressLine1 = data.AddressInfo.AddressLine1;
                $addressSelectedDetail.dataset.addressLine2 = data.AddressInfo.AddressLine2;
                $addressSelectedDetail.dataset.customerAddress =
                    data.AddressInfo.AddressLine1 + '<br>' + data.AddressInfo.AddressLine2;
                $addressSelectedDetail.appendChild(accountNumber);
                $addressSelectedDetail.appendChild(addressLine1);
                $addressSelectedDetail.appendChild(addressLine2);

                $apartmentUnit.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $apartmentUnit.dispatchEvent(checkAddressEvent);

                if (_showSelectedAddress) {
                    $addressSelected.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            }

            query.fireEvent($streetAddress, 'selectedOptionEnd');
        };

        var bindSeeMoreOptionsEvent = function (event) {
            var $seeMoreOptionsListItem = query.selectParentElement(
                event.target.getElementsByClassName(CONSTANTS.DEFAULT_OPTION_LINK_CLASS)[0],
                CONSTANTS.AUTOCOMPLETE_ITEM
            );

            if (!$seeMoreOptionsListItem) {
                return;
            }

            coned.utils.addGeneralListeners($seeMoreOptionsListItem, function (event) {
                var $target = event.currentTarget,
                    $wrapperList,
                    $input;

                $wrapperList = query.selectParentElement(
                    $target,
                    CONSTANTS.AUTOCOMPLETE_LIST_WRAPPER_CLASS
                );
                $input = $wrapperList.parentElement.getElementsByClassName(
                    CONSTANTS.AUTOCOMPLETE_INPUT
                )[0];
                $input.setAttribute(CONSTANTS.ARIA_EXPANDED, 'false');
                $wrapperList.classList.add(CONSTANTS.HIDDEN_CLASS);

                if($input === $streetAddress) {
                    cleanUpUnit();
                }

                $input.value = '';

                $seeMoreOptions.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $seeMoreOptions.focus();
            });
        };

        var showSelectedAddress = function () {
            var addressLine1, addressLine2, accountNumber;

            //Clean up selected address
            $addressSelectedDetail.innerHTML = '';

            addressLine1 = document.createElement('span');
            addressLine2 = document.createElement('span');
            accountNumber = document.createElement('span');

            var addressInfo = JSON.parse($apartmentUnit.dataset.unitsList)[$apartmentUnit.value];

            accountNumber.classList.add(CONSTANTS.ADDRESS_SELECTED_MASKED_ACCOUNT_NUMBER);
            addressLine1.innerHTML = addressInfo.AddressLine1;
            addressLine2.innerHTML = addressInfo.AddressLine2;
            $addressSelectedDetail.dataset.addressLine1 = addressInfo.AddressLine1;
            $addressSelectedDetail.dataset.addressLine2 = addressInfo.AddressLine2;
            $addressSelectedDetail.dataset.customerAddress =
                addressInfo.AddressLine1 + '<br>' + addressInfo.AddressLine2;
            $addressSelectedDetail.dataset.maid = addressInfo.Maid;
            $addressSelectedDetail.dataset.premiseNumber = addressInfo.ServiceAddress.PremiseNumber;
            $addressSelectedDetail.appendChild(accountNumber);
            $addressSelectedDetail.appendChild(addressLine1);
            $addressSelectedDetail.appendChild(addressLine2);

            if (_showSelectedAddress) {
                $addressSelected.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            query.fireEvent($apartmentUnit, 'selectedOptionEnd');
        };

        /*
         * Change the status of the look up button depending on the parameter given
         * @param disabled boolean to indicate if the button should be disabled
         */
        var manageAddressLookupButton = function (disabled) {
            //Check if lookup button exists and disabled or not depending on the param
            if ($addressLookupButton) {
                disabled
                    ? ($addressLookupButton.disabled = true)
                    : ($addressLookupButton.disabled = false);
            }
        };

        /**
         * Initializes all elements needed in the module
         */
        var initializeData = function () {
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $zipCode = $container.getElementsByClassName(CONSTANTS.ZIP_CODE_CLASS)[0];
            $zipCodeError = $container.getElementsByClassName(CONSTANTS.ZIP_CODE_ERROR_CLASS)[0];
            $streetAddress = $container.getElementsByClassName(CONSTANTS.STREET_ADDRESS_CLASS)[0];
            $streetAddressServiceError = $container.getElementsByClassName(
                CONSTANTS.STREET_ADDRESS_SERVICE_ERROR
            )[0];
            $apartmentUnit = $container.getElementsByClassName(CONSTANTS.APARTMENT_UNIT_CLASS)[0];
            $streetAddressListWrapper = $streetAddress.parentElement.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_LIST_WRAPPER_CLASS
            )[0];
            $apartmentUnitListWrapper = $apartmentUnit.parentElement.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_LIST_WRAPPER_CLASS
            )[0];
            $showOldExperience = $container.getElementsByClassName(
                CONSTANTS.SHOW_OLD_EXPERIENCE_CLASS
            );
            $seeMoreOptions = $container.getElementsByClassName(
                CONSTANTS.SEE_MORE_OPTIONS_CLASS
            )[0];
            $addressInputForm = $container.getElementsByClassName(CONSTANTS.ADDRESS_INPUT_FORM)[0];
            $addressAutocompleteForm = $container.getElementsByClassName(
                CONSTANTS.ADDRESS_AUTOCOMPLETE_FORM
            )[0];
            $addressSelected = $container.getElementsByClassName(
                CONSTANTS.ADDRESS_SELECTED_CLASS
            )[0];
            $addressSelectedDetail = $addressSelected.getElementsByClassName(
                CONSTANTS.ADDRESS_SELECTED_DETAIL_CLASS
            )[0];
            $addressLookupButton = $container.getElementsByClassName(
                CONSTANTS.ADDRESS_LOOKUP_BUTTON_CLASS
            )[0];
            $liveChatAvailable = document.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_AVAILABLE_SELECTOR
            )[0];
            _scId = query.getFormInputValue($addressAutocompleteForm, CONSTANTS.ADDRESS_SCID_NAME);
            _currentZipCode = '';
            _showSelectedAddress = $container.dataset.showSelectedAddress
                ? $container.dataset.showSelectedAddress === 'true'
                    ? true
                    : false
                : true;
        };

        /**
         * Initializes all events needed in the module
         */
        var initializeEvents = function () {
            var urlZipCodeParam = coned.utils.getUrlParameterValue(
                CONSTANTS.ZIPCODE_URL_PARAMENTER
            );

            $zipCode.addEventListener('input', validateZipField);
            $zipCode.addEventListener('cleanUp', cleanUpStreetAndUnit);
            $streetAddress.addEventListener('selectedOption', searchApartmentUnit);
            $streetAddress.addEventListener('inputChange', cleanUpUnit);
            $streetAddress.addEventListener('input', function () {
                if (this.value === '') {
                    cleanUpUnit();
                }
            });
            $streetAddressListWrapper.addEventListener('change', bindSeeMoreOptionsEvent);
            $apartmentUnitListWrapper.addEventListener('change', bindSeeMoreOptionsEvent);
            $apartmentUnit.addEventListener('selectedOption', showSelectedAddress);
            $apartmentUnit.addEventListener('inputChange', cleanUpSelectedAddress);
            $apartmentUnit.addEventListener('input', function () {
                if (this.value === '') {
                    cleanUpSelectedAddress();
                }
            });
            _.each($showOldExperience, function ($showOldExperienceLink) {
                coned.utils.addGeneralListeners($showOldExperienceLink, showOldExperience);
            });
            
            // Disable lookup button on empty inputs to prevent invalid searches.
            $apartmentUnit.addEventListener(CONSTANTS.RESET_INPUT_END_EVENT, function() {
                manageAddressLookupButton(true);
            });
            $streetAddress.addEventListener(CONSTANTS.RESET_INPUT_END_EVENT, function() {
                manageAddressLookupButton(true);
            });

            // validate zip if autofilled
            if ($zipCode.value !== '' || urlZipCodeParam !== false) {
                $zipCode.value = urlZipCodeParam;

                $zipCode.classList.add(CONSTANTS.VALID_CLASS);
                $zipCode.classList.add(CONSTANTS.FILLED_CLASS);

                validateZipField();
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AddressLookup.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AddressLookup;
})();
