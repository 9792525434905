// ==================== FILTERS COMPONENT =========================
/* global gsap */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FiltersComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        DROPDOWN_CONTAINER_SELECTOR: 'js-dropdown-filter',
        DROPDOWN_BUTTON_SELECTOR: 'js-dropdown-button',
        DROPDOWN_BUTTON_TITLE_SELECTOR: 'js-dropdown-title',
        DROPDOWN_BUTTON_ICON_SELECTOR: 'js-icon-carrot',
        DROPDOWN_SORT_SELECTOR: 'js-dropdown-sort',
        DROPDOWN_LIST_SELECTOR: 'js-dropdown-list',
        DROPDOWN_ITEM_SELECTOR: 'js-dropdown-item',
        DROPDOWN_ITEM_LINK_SELECTOR: 'js-dropdown-item-link',
        DROPDOWN_ITEM_DEFAULT_SELECTOR: 'js-dropdown-item-default',
        DROPDOWN_BUTTON_ICON_OPEN_CLASS: 'dropdown-filters__title-icon--open',
        DROPDOWN_BUTTON_ACTIVE_CLASS: 'dropdown-filters__item-button--active',
        DROPDOWN_ITEM_ACTIVE_CLASS: 'dropdown-list-item__link--active',
        RESULTS_LIST_SELECTOR: 'js-results-list',
        RESULTS_LIST_FAQ_SELECTOR: 'js-results-list-faq',
        RESULTS_LIST_CARDS_SELECTOR: 'js-results-list-cards',
        RESULTS_ITEM_SELECTOR: 'js-result-item',
        RESULTS_CARD_SELECTOR: 'js-card-selector',
        RESULTS_CARD_ANIMATION_CLASS: 'card-tile__zoom--animation',
        RESULTS_ALL_VALUE: 'ALL',
        HEADER_WRAPPER_SELECTOR: 'js-header-wrapper',
        PAGINATION_SELECTOR: 'js-pagination-wrapper',
        PAGINATION_RESULTS_SELECTOR: 'js-pagination-results',
        PAGINATION_RESULTS_TOTAL_SELECTOR: 'js-total',
        PAGINATION_RESET_SELECTOR: 'js-pagination-reset',
        TAB_FILTER_SELECTOR: 'js-tab-filter',
        TAB_FILTER_ITEM_SELECTOR: 'js-tab-item-filter',
        TAB_FILTER_ACTIVE_CLASS: 'search-filters__by-nav-item--active',
        FILTERS_SELECTOR: 'js-filter',
        FILTERS_RESET_SELECTOR: 'js-filters-reset',
        FILTER_BACK: 'js-back',
        FILTER_BACK_ACTIVE: 'js-back-active',
        FILTER_TOP: 'js-filters-top',
        FILTER_CONTENT: 'js-filters-content',
        FILTER_CONTENT_ACTIVE: 'filters__content--active',
        FILTER_DROPDOWN_ITEM: 'js-filter-dropdown',
        FILTER_DROPDOWN_ITEM_OPEN: 'js-filter-open',
        FILTER_LISTS: 'js-list-item',
        FILTER_LISTS_SEARCH: 'js-list-item-search',
        FILTER_SEARCH_CONTAINER: 'js-item-search-container',
        FILTER_SEARCH_OPTIONS_CONTAINER: 'js-text-search-container',
        FILTER_SEARCH_OPTIONS_CLASS: 'filters__search-container--fixed',
        FILTER_MAIN_ACTIVE_CLASS: 'filters__item--active',
        FILTER_MAIN_ACTIVE_SELECTOR: 'js-filters-active',
        FILTER_SHOW_FILTERS: 'js-show-filters',
        FILTER_RESULTS_INFO: 'js-results-info',
        FILTER_ITEM_VISIBILITY: 'js-check-visibility',
        FILTER_ITEM_ACTIVE_CLASS: 'filters__list-item--active',
        FILTER_ITEM_HIDDEN_CLASS: 'filters__list-item--hidden',
        FILTER_MAIN_SUBTITLE: 'js-subcategory-label',
        FILTER_MAIN_SUBTITLE_ACTIVE: 'filters__sub-category--active',
        FILTERS_OPEN_MOBILE_CLASS: 'filters--open',
        FILTERS_RESULTS_WRAPPER: 'js-filter-results-wrapper',
        FILTERS_MOBILE_TITLE: 'js-mobile-title',
        FILTERS_RESULTS_EMPTY: 'js-filter-results-empty',
        FILTERS_TOTAL_RESULTS: 'js-filter-total-results',
        FILTERS_RESULT_NUMBERS: 'js-filter-result-numbers',
        FILTERS_RESET_BUTTON: 'js-filters-reset-button',
        FILTERS_DEPENDENCY_FILTER: 'faceted-search__dependency-filter',
        FILTER_LISTBOX: 'js-filter-listbox',

        FILTERS_RESET_CTA: 'js-filters-reset-cta',
        LOADING_CLASS: 'js-form-loading',
        HIDDEN_LOADING_CLASS: 'form-loading--hidden',
        TEXT_SEARCH_INPUT_SELECTOR: 'js-text-search-input',
        DISABLE_ATTRIBUTE: 'disabled',
        HIDDEN_CLASS: 'hidden',
        SELECTED_CLASS: 'selected',
        FILTERS_RESET_BUTTON_HIDDEN_CLASS: 'filters__clear-button--hidden',

        //A11Y
        ARIA_EXPANDED: 'aria-expanded',
        ARIA_SELECTED: 'aria-selected'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var FiltersComponent = function ($filtersComponent) {
        /**
         * PRIVATE METHODS
         */
        var $body,
            $filtersListboxes,
            $dropdownItems,
            $dropdownSort,
            $dropdownSortList,
            $header,
            $paginationModule,
            $paginationResults,
            $paginationTotal,
            $paginationReset,
            $tabFilter,
            $tabItems,
            $filters,
            $filtersResetButton,
            $filtersResetCta,
            $filtersItemsLists,
            $filtersItemsSearchLists,
            $filtersItemsVisibility,
            $filtersDropdown,
            $filtersBack,
            $filtersTop,
            $filtersContent,
            $filtersResultsInfo,
            $filtersResultsEmpty,
            $filtersResultsWrapper,
            $filtersMobileTitle,
            $filtersTotalResult,
            $filtersResultNumbers,
            $showFilter,
            $resultsContainer,
            $resultsList,
            $loader,
            _conditionalFiltering,
            _resultsList,
            _newResultsList,
            _resultsFilteredList,
            _newResultsFilteredList,
            _resultsTotal,
            _hideTotalNumber,
            _oldWidth;

        var backFilter = function () {
            if (query.hasClass($filtersBack, CONSTANTS.FILTER_BACK_ACTIVE)) {
                closeDropdowns();
                $filtersBack.classList.remove(CONSTANTS.FILTER_BACK_ACTIVE);
                $filtersMobileTitle.innerHTML = $filtersTop.dataset.defaultFilterName;
            } else {
                $filtersContent.classList.remove(CONSTANTS.FILTER_CONTENT_ACTIVE);
                $filtersResultsInfo.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersResultsWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersComponent.classList.remove(CONSTANTS.FILTERS_OPEN_MOBILE_CLASS);
                $filtersResultsWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersTop.classList.add(CONSTANTS.HIDDEN_CLASS);
                $filtersItemsVisibility.classList.add(CONSTANTS.HIDDEN_CLASS);
                $body.classList.remove(coned.constants.MOBILE_OVERLAY_OPEN);

                if (coned.utils.isMobile()) {
                    $showFilter.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
                    $showFilter.focus();
                }
            }
        };

        var filtersItemsVisibilityStatus = function (showItems) {
            if (showItems) {
                $filtersItemsVisibility.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $filtersItemsVisibility.classList.add(CONSTANTS.HIDDEN_CLASS);
                $filtersComponent.classList.remove(CONSTANTS.FILTERS_OPEN_MOBILE_CLASS);
            }
        };

        var showFilters = function () {

            $showFilter.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            $filtersContent.classList.add(CONSTANTS.FILTER_CONTENT_ACTIVE);
            $filtersResultsInfo.classList.add(CONSTANTS.HIDDEN_CLASS);
            $filtersResultsWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            $filtersComponent.classList.add(CONSTANTS.FILTERS_OPEN_MOBILE_CLASS);
            $filtersTop.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $body.classList.add(coned.constants.MOBILE_OVERLAY_OPEN);
            filtersItemsVisibilityStatus(true);
            $filtersBack.focus();

            window.scrollTo(0, 0);
        };

        var toggleDropdown = function (target, event) {
            event.preventDefault();

            if (!query.hasClass(target, CONSTANTS.FILTER_DROPDOWN_ITEM_OPEN)) {
                var dropdownCriteria = target.dataset.filterCriteria,
                    dropdownLeftMargin = target.offsetLeft,
                    dropdownTopMargin = target.offsetHeight,
                    dropdownWidth = target.offsetWidth,
                    $filterItemList,
                    $textSearchInputSelector;

                closeDropdowns();

                target.classList.add(CONSTANTS.FILTER_MAIN_ACTIVE_CLASS);
                target.classList.add(CONSTANTS.FILTER_MAIN_ACTIVE_SELECTOR);
                target.classList.toggle(CONSTANTS.FILTER_DROPDOWN_ITEM_OPEN);
                target.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
                
                coned.utils.isMobile() && Array.prototype.forEach.call($filtersDropdown, function ($filterDropdown) {
                    $filterDropdown.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                for (var listIndex = 0; listIndex < $filtersItemsLists.length; listIndex++) {
                    $filterItemList = $filtersItemsLists[listIndex];
                    $textSearchInputSelector = $filterItemList.getElementsByClassName(
                        CONSTANTS.TEXT_SEARCH_INPUT_SELECTOR
                    )[0];

                    if ($filterItemList.dataset.listValue !== dropdownCriteria) continue;

                    // Mobile related changes
                    $filtersMobileTitle.innerHTML = $filterItemList.dataset.listName;
                    $filtersBack.classList.add(CONSTANTS.FILTER_BACK_ACTIVE);

                    // Desktop list display
                    $filterItemList.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    // position the list according to parent element on desktop
                    if (!coned.utils.isMobile()) {
                        // check if first item is on edge of the screen
                        if (dropdownLeftMargin >= dropdownWidth) {
                            dropdownLeftMargin = dropdownLeftMargin - 60;
                        }

                        $filterItemList.style.position = 'absolute';
                        $filterItemList.style.top = dropdownTopMargin + 'px';
                        $filterItemList.style.left = dropdownLeftMargin + 'px';

                        gsap.from($filterItemList, {
                            duration: 0.6,
                            y: 15,
                            opacity: 0,
                            ease: "power1.inOut"
                        });
                    }

                    // Clear search text if dropdown has a search
                    $textSearchInputSelector && ($textSearchInputSelector.value = '');

                    coned.utils.focusFirstFormInputField($filterItemList);

                    break;
                }
            } else {
                closeDropdowns();
            }
        };

        var closeDropdowns = function () {
            Array.prototype.forEach.call($filtersDropdown, function ($filterDropdown) {
                $filterDropdown.classList.remove(CONSTANTS.FILTER_DROPDOWN_ITEM_OPEN);
                $filterDropdown.classList.remove(CONSTANTS.FILTER_MAIN_ACTIVE_CLASS);
                $filterDropdown.classList.remove(CONSTANTS.FILTER_MAIN_ACTIVE_SELECTOR);
                $filterDropdown.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
                coned.utils.isMobile() && $filterDropdown.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });

            Array.prototype.forEach.call($filtersItemsLists, function ($filterItemList) {
                $filterItemList.classList.add(CONSTANTS.HIDDEN_CLASS);
            });
        };

        var selectTabFilter = function (target, event) {
            event.preventDefault();

            changeTabSelection(target);
            filterResults();
        };

        var changeTabSelection = function ($selectedTab) {
            // If value is already selected, don't do anything
            if (query.hasClass($selectedTab, CONSTANTS.TAB_FILTER_ACTIVE_CLASS)) return;

            // Remove active class from tab items
            query.removeClass($tabItems, CONSTANTS.TAB_FILTER_ACTIVE_CLASS);

            // Set selected criteria and active class
            $tabFilter.dataset.value = $selectedTab.dataset.value;
            $tabFilter.dataset.name = $selectedTab.dataset.name;
            $selectedTab.classList.add(CONSTANTS.TAB_FILTER_ACTIVE_CLASS);
        };

        var selectDropdownItem = function ($element, event) {
            event.preventDefault();

            var dependencyParent = $element.parentNode.parentNode.parentNode.dataset.listValue,
                dependencyId = $element.parentNode.dataset.value,
                $allDependencySiblings = document.querySelectorAll(
                    '[data-dependency-parent="' + dependencyParent + '"]'
                );

            if ($allDependencySiblings) {
                Array.prototype.forEach.call($allDependencySiblings, function ($dependencySibling) {
                    var elementDependencyIds = $dependencySibling.dataset.dependencyId.split('|');
                    var dependencyHit = elementDependencyIds.includes(dependencyId);

                    if (dependencyHit) {
                        if (
                            $dependencySibling.classList.contains(
                                CONSTANTS.FILTERS_DEPENDENCY_FILTER
                            )
                        ) {
                            $dependencySibling.classList.remove(
                                CONSTANTS.FILTERS_DEPENDENCY_FILTER
                            );
                        }
                    } else {
                        if (
                            !$dependencySibling.classList.contains(
                                CONSTANTS.FILTERS_DEPENDENCY_FILTER
                            )
                        ) {
                            $dependencySibling.classList.add(CONSTANTS.FILTERS_DEPENDENCY_FILTER);
                        }
                    }
                });
            }

            if ($element.classList.contains(CONSTANTS.FILTER_ITEM_ACTIVE_CLASS)) return;
            $loader.classList.remove(CONSTANTS.HIDDEN_LOADING_CLASS);

            var $selectedOption = query.selectParentElement(
                $element,
                CONSTANTS.DROPDOWN_ITEM_SELECTOR
            );
            var $selectedDropdown = document.getElementsByClassName(
                CONSTANTS.FILTER_MAIN_ACTIVE_SELECTOR
            )[0];

            changeDropdownSelection($selectedDropdown, $selectedOption, true);

            $filtersComponent.classList.remove(CONSTANTS.FILTERS_OPEN_MOBILE_CLASS);
            $filtersResultsWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);

            if (query.hasClass($selectedDropdown, CONSTANTS.DROPDOWN_SORT_SELECTOR)) {
                sortResults($selectedOption.dataset.value);
            } else {
                filterResults();

                if (_conditionalFiltering) {
                    filterFilters();
                }
            }

            closeDropdowns();
            $selectedDropdown.focus();

            if (coned.utils.isMobile()) {
                $filtersContent.classList.remove(CONSTANTS.FILTER_CONTENT_ACTIVE);
                $filtersResultsInfo.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersTop.classList.add(CONSTANTS.HIDDEN_CLASS);
                $filtersItemsVisibility.classList.add(CONSTANTS.HIDDEN_CLASS);
                $body.classList.remove(coned.constants.MOBILE_OVERLAY_OPEN);
                $showFilter.focus();
            }

            $loader.classList.add(CONSTANTS.HIDDEN_LOADING_CLASS);
        };

        var changeDropdownSelection = function ($selectedDropdown, $selectedOption, changeUrl) {
            // If value is already selected, don't do anything
            if (query.hasClass($selectedOption, CONSTANTS.FILTER_ITEM_ACTIVE_CLASS)) return;

            var $selectedSubTitle = $selectedDropdown.getElementsByClassName(
                    CONSTANTS.FILTER_MAIN_SUBTITLE
                )[0],
                defaultOption = $selectedDropdown.dataset.defaultTitle,
                $selectedDropdownOptions = $selectedOption.parentNode.getElementsByClassName(
                    CONSTANTS.DROPDOWN_ITEM_SELECTOR
                ),
                defaultOptionSelected = false,
                $selectedItem = query.selectParentElement(
                    $selectedOption,
                    CONSTANTS.DROPDOWN_ITEM_SELECTOR
                );
                $selectedDropdown.classList.add(CONSTANTS.SELECTED_CLASS);

            // Change dropdown title and data to selected value
            $selectedSubTitle.innerHTML = $selectedOption.innerText;
            if (
                defaultOption &&
                $selectedOption &&
                $selectedOption.innerText &&
                defaultOption === $selectedOption.innerText.trim()
            ) {
                $selectedSubTitle.classList.remove(CONSTANTS.FILTER_MAIN_SUBTITLE_ACTIVE);
                defaultOptionSelected = true;
            } else {
                $selectedSubTitle.classList.add(CONSTANTS.FILTER_MAIN_SUBTITLE_ACTIVE);
                defaultOptionSelected = false;
            }

            $selectedDropdown.dataset.value = $selectedOption.dataset.value;
            $selectedDropdown.dataset.name = $selectedOption.dataset.name;

            Array.prototype.forEach.call(
                $selectedDropdownOptions,
                function ($selecteDropdownOption) {
                    $selecteDropdownOption.classList.remove(CONSTANTS.FILTER_ITEM_ACTIVE_CLASS);
                    $selectedOption.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $selecteDropdownOption.setAttribute(CONSTANTS.ARIA_SELECTED, false);
                }
            );

            $selectedOption.classList.add(CONSTANTS.FILTER_ITEM_ACTIVE_CLASS);
            $selectedOption.setAttribute(CONSTANTS.ARIA_SELECTED, true);

            if (changeUrl) {
                var params = coned.utils.getUrlParameters(),
                    criteria = $selectedDropdown.dataset.filterCriteria.trim(),
                    value = $selectedItem.dataset.value.trim();

                if (defaultOptionSelected) {
                    if (params[criteria]) {
                        params[criteria] = undefined;
                    }
                } else {
                    delete params[criteria];
                    params[criteria] = value;
                }
                coned.utils.setUrlParameters(params);
            }
        };

        var filterFilters = function () {
            // finds just selected filter
            var $searchItemsContainer,
                $textSearchContainer,
                $selectedDropdown = Array.prototype.find.call(
                    $filtersDropdown,
                    function ($dropdown) {
                        return $dropdown.classList.contains(CONSTANTS.SELECTED_CLASS);
                    }
                );

            if (
                $selectedDropdown.dataset.defaultTitle &&
                $selectedDropdown.dataset.value &&
                $selectedDropdown.dataset.defaultTitle.toLowerCase() ===
                    $selectedDropdown.dataset.value.toLowerCase()
            ) {
                // if default option was selected in filter then display all filters
                Array.prototype.forEach.call($dropdownItems, function ($item) {
                    $item.classList.remove(CONSTANTS.FILTER_ITEM_HIDDEN_CLASS);
                });

                Array.prototype.forEach.call($filtersDropdown, function ($item) {
                    var $subtitleLabel = $item.getElementsByClassName(
                            CONSTANTS.FILTER_MAIN_SUBTITLE
                        )[0],
                        defaultLabel = $item.dataset.defaultTitle;

                    $item.removeAttribute(CONSTANTS.DISABLE_ATTRIBUTE);
                    $subtitleLabel.innerHTML = defaultLabel;
                });

                Array.prototype.forEach.call($filtersItemsSearchLists, function ($dropdown) {
                    var $textSearchInputSelector = $dropdown.getElementsByClassName(
                        CONSTANTS.TEXT_SEARCH_INPUT_SELECTOR
                    )[0];
                    $textSearchContainer = $dropdown.getElementsByClassName(
                        CONSTANTS.FILTER_SEARCH_CONTAINER
                    )[0];
                    $textSearchContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $textSearchInputSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    $searchItemsContainer = $dropdown.getElementsByClassName(
                        CONSTANTS.FILTER_SEARCH_OPTIONS_CONTAINER
                    );
                    Array.prototype.forEach.call($searchItemsContainer, function ($search) {
                        $search.classList.add(CONSTANTS.FILTER_SEARCH_OPTIONS_CLASS);
                    });
                });

                $selectedDropdown.classList.remove(CONSTANTS.SELECTED_CLASS);
            } else {
                // if default option wasn't selected, then evaluate filters
                Array.prototype.forEach.call($filtersDropdown, function ($dropdown) {
                    if ($dropdown.classList.contains(CONSTANTS.SELECTED_CLASS)) {
                        $dropdown.classList.remove(CONSTANTS.SELECTED_CLASS);
                    } else {
                        checkFilterForResults($dropdown);
                    }
                });
            }
        };

        var checkFilterForResults = function ($filter) {
            var criteria = $filter.dataset.filterCriteria,
                $dropdown = Array.prototype.find.call($filtersItemsLists, function ($list) {
                    return $list.dataset.listValue === criteria;
                }),
                $dropdownOptions = $dropdown.getElementsByClassName(
                    CONSTANTS.DROPDOWN_ITEM_SELECTOR
                ),
                availableOptions,
                $subtitleLabel = $filter.getElementsByClassName(CONSTANTS.FILTER_MAIN_SUBTITLE)[0],
                defaultLabel = $filter.dataset.defaultTitle,
                noneLabel = $filter.dataset.noneTitle,
                minimumSearch,
                $textSearchContainer,
                $searchItemsContainer;

            // if there are results
            if (_newResultsFilteredList.length) {
                // Navigate through filter options and evaluate if there's a result with its criteria
                Array.prototype.forEach.call($dropdownOptions, function ($option) {
                    var found = _newResultsFilteredList.findIndex(function ($result) {
                        var attribute = $result.data[criteria];
                        return attribute.search($option.dataset.value) > -1;
                    });

                    // if there isn't a result with that filter criteria, then hide it
                    if (found === -1) {
                        $option.classList.add(CONSTANTS.FILTER_ITEM_HIDDEN_CLASS);
                    } else {
                        $option.classList.remove(CONSTANTS.FILTER_ITEM_HIDDEN_CLASS);
                    }
                });
            } else {
                // if there aren't results then hide all options
                Array.prototype.forEach.call($dropdownOptions, function ($option) {
                    $option.classList.add(CONSTANTS.FILTER_ITEM_HIDDEN_CLASS);
                });
            }

            // if there aren't any available options in the filter, disable it and add a none tag to it
            availableOptions = Array.prototype.filter.call($dropdownOptions, function ($option) {
                return !$option.classList.contains(CONSTANTS.FILTER_ITEM_HIDDEN_CLASS);
            });
            if (availableOptions.length) {
                $filter.removeAttribute(CONSTANTS.DISABLE_ATTRIBUTE);
                if ($subtitleLabel.innerHTML === noneLabel) {
                    $subtitleLabel.innerHTML = defaultLabel;
                }

                if ($dropdown.classList.contains(CONSTANTS.FILTER_LISTS_SEARCH)) {
                    // evaluate if search bar needs to be removed
                    $textSearchContainer = $dropdown.getElementsByClassName(
                        CONSTANTS.FILTER_SEARCH_CONTAINER
                    )[0];
                    $searchItemsContainer = $dropdown.getElementsByClassName(
                        CONSTANTS.FILTER_SEARCH_OPTIONS_CONTAINER
                    );
                    minimumSearch = $dropdown.dataset.searchMinimun;
                    var $textSearchInputSelector = $textSearchContainer.getElementsByClassName(
                        CONSTANTS.TEXT_SEARCH_INPUT_SELECTOR
                    )[0];

                    $textSearchInputSelector.classList.toggle(CONSTANTS.HIDDEN_CLASS);
                    if (availableOptions.length <= minimumSearch) {
                        // SHOW SEARCH BAR
                        $textSearchContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                        Array.prototype.forEach.call($searchItemsContainer, function ($search) {
                            $search.classList.remove(CONSTANTS.FILTER_SEARCH_OPTIONS_CLASS);
                        });
                    } else {
                        // HIDE SEARCH BAR
                        $textSearchContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        Array.prototype.forEach.call($searchItemsContainer, function ($search) {
                            $search.classList.add(CONSTANTS.FILTER_SEARCH_OPTIONS_CLASS);
                        });
                    }
                }
            } else {
                $filter.setAttribute(CONSTANTS.DISABLE_ATTRIBUTE, CONSTANTS.DISABLE_ATTRIBUTE);
                $subtitleLabel.innerHTML = noneLabel;
            }
        };

        var sortResults = function (sortValue) {
            // Sort the array by the value selected
            _resultsFilteredList = _resultsFilteredList.sort(function (a, b) {
                return (
                    parseFloat(b.getAttribute('data-' + sortValue)) -
                    parseFloat(a.getAttribute('data-' + sortValue))
                );
            });

            setResultsContainer();
        };

        var filterResults = function () {
            var noFiltersApplied = true,
                activeFilters,
                filtersList = [],
                filters = [];

            // Reset filtered list
            _newResultsFilteredList = _newResultsList.slice(0);

            // Apply all selected filters
            Array.prototype.forEach.call($filters, function ($filter) {
                if (
                    $filter.dataset.value != undefined &&
                    $filter.dataset.value != '' &&
                    $filter.dataset.value != CONSTANTS.RESULTS_ALL_VALUE
                ) {
                    noFiltersApplied = false;
                    filters.push($filter.dataset.name);
                    filters.push('|');
                    filtersList.push({
                        criteria: $filter.dataset.filterCriteria,
                        value: $filter.dataset.value
                    });
                }
            });

            if (filtersList.length) {
                applyFilter(filtersList);
            }

            filters.pop();
            activeFilters = filters.join('');

            // Analytics data building
            analyticsFilters(activeFilters, _resultsFilteredList.length);

            if (noFiltersApplied) {
                resetResultsList();
                hideResetButtons();
            } else {
                showResetButtons();
            }

            // Sort after filtering
            if ($dropdownSortList != undefined) {
                var $sortSelectedItem = $dropdownSortList.getElementsByClassName(
                    CONSTANTS.DROPDOWN_ITEM_ACTIVE_CLASS
                )[0];

                if ($sortSelectedItem != undefined) {
                    sortResults($sortSelectedItem.dataset.value);
                } else {
                    setResultsContainer();
                }
            } else {
                setResultsContainer();
            }
        };

        var applyFilter = function (filters) {
            // Filter results by the criteria > value selected

            if (filters.length) {
                // evaluates all elements to check if they contain all filters
                _newResultsFilteredList = _newResultsFilteredList.filter(function ($result) {
                    var filtersIndex, resultValue, resultValues, currentFilter;

                    // checks if element has all current filters
                    for (filtersIndex = 0; filtersIndex < filters.length; filtersIndex++) {
                        currentFilter = filters[filtersIndex];
                        resultValue = $result.data[currentFilter.criteria];

                        // if filter doesn't have criteria or its value is null or empty, then it isn't a candidate
                        if (resultValue) {
                            resultValues = resultValue.toUpperCase().split('|');

                            // if element doesn't contain criteria, then it isn't a candidate
                            if (!resultValues.includes(currentFilter.value.toUpperCase())) {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                    return true;
                });

                _resultsFilteredList = [];
                _newResultsFilteredList.forEach(function ($result) {
                    _resultsFilteredList.push(_resultsList[$result.index]);
                });
            }
        };

        var setResultsContainer = function () {
            var filteredList = '',
                filteredListArray = [],
                filteredListTotal = _resultsFilteredList.length;

            _resultsFilteredList.forEach(function (_result) {
                filteredListArray.push(_result.outerHTML);
            });
            filteredList = filteredListArray.join('');

            // Set results container to filtered results
            $resultsContainer.innerHTML = filteredList;

            if (filteredListTotal) {
                if (_hideTotalNumber) {
                    if (filteredListTotal === _resultsTotal) {
                        $filtersTotalResult.classList.add(CONSTANTS.HIDDEN_CLASS);
                    } else {
                        $filtersTotalResult.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }
                }

                $filtersResultNumbers.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersResultsEmpty.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                $filtersResultNumbers.classList.add(CONSTANTS.HIDDEN_CLASS);
                $filtersResultsEmpty.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            // Scroll to the top of the page for results viewing
            if (coned.utils.isMobile()) {
                $filtersBack.classList.remove(CONSTANTS.FILTER_BACK_ACTIVE);
                $filtersMobileTitle.innerHTML = $filtersTop.dataset.defaultFilterName;

                window.scrollTo(0, $filtersComponent.offsetTop - $header.offsetHeight);
            } else {
                coned.utils.scrollTo($filtersComponent.offsetTop - $header.offsetHeight, 500);
            }

            displayResults();
            paginationFiltering();
        };

        var analyticsFilters = function (filters, results) {
            dataLayer.push({
                event: 'hem',
                hem: filters,
                hemResults: results
            });
        };

        var displayResults = function () {
            if (query.hasClass($resultsContainer, CONSTANTS.RESULTS_LIST_CARDS_SELECTOR)) {
                // set up cards
                var $resultsFiltered = $resultsContainer.getElementsByClassName(
                    CONSTANTS.RESULTS_CARD_SELECTOR
                );

                coned.utils.initializeModules($resultsContainer);

                // Apply load animation
                Array.prototype.forEach.call($resultsFiltered, function ($resultFiltered) {
                    query.addClass($resultFiltered, CONSTANTS.RESULTS_CARD_ANIMATION_CLASS);
                });

                new coned.components.LoadCardTiles($resultsContainer);
            } else if (query.hasClass($resultsContainer, CONSTANTS.RESULTS_LIST_FAQ_SELECTOR)) {
                // set up faq
                new coned.components.CollapsibleContainerComponent($resultsContainer);
            }
        };

        var paginationFiltering = function () {
            var paginationDivider = $paginationModule.dataset.divider,
                paginationDividerTablet = $paginationModule.dataset.tabletdivider,
                displayTotal = paginationDivider * 1,
                showPageIndex,
                $resultToShow;

            if (paginationDividerTablet && coned.utils.isTablet()) {
                paginationDivider = paginationDividerTablet;
                displayTotal = paginationDivider * 1;
            }

            // Hide all results
            Array.prototype.forEach.call($paginationResults.children, function ($result) {
                query.addClass($result, $paginationResults.dataset.resultsHide);
            });

            // Show the corresponding page results
            for (
                showPageIndex = displayTotal - paginationDivider;
                showPageIndex < displayTotal && showPageIndex < $paginationResults.children.length;
                showPageIndex++
            ) {
                $resultToShow = $paginationResults.children[showPageIndex];

                query.removeClass($resultToShow, $paginationResults.dataset.resultsHide);
            }

            $paginationTotal.innerHTML = $paginationResults.children.length;
            $paginationReset.click();
        };

        var resetResultsList = function () {
            _resultsFilteredList = _resultsList;
        };

        var resetFiltersEvent = function (target, event) {
            event.preventDefault();
            resetFilters();
            coned.utils.setUrlParameters();
        };

        var resetFilters = function () {
            var $textSearchContainer, $searchItemsContainer;

            $loader.classList.remove(CONSTANTS.HIDDEN_LOADING_CLASS);

            Array.prototype.forEach.call($dropdownItems, function ($item) {
                $item.classList.remove(CONSTANTS.FILTER_ITEM_ACTIVE_CLASS);
            });

            Array.prototype.forEach.call($filters, function ($filter) {
                var $dropdownTitle = $filter.getElementsByClassName(
                        CONSTANTS.FILTER_MAIN_SUBTITLE
                    )[0],
                    listValue = $filter.dataset.filterCriteria,
                    $filterlist = $filtersContent.querySelectorAll(
                        '[data-list-value=' + listValue + ']'
                    )[0],
                    $defaultOption = $filterlist.getElementsByClassName(
                        CONSTANTS.DROPDOWN_ITEM_DEFAULT_SELECTOR
                    ),
                    $allDependencySiblings = document.querySelectorAll(
                        '[data-dependency-parent="' + listValue + '"]'
                    );

                if ($allDependencySiblings) {
                    Array.prototype.forEach.call(
                        $allDependencySiblings,
                        function ($dependencySibling) {
                            if (
                                !$dependencySibling.classList.contains(
                                    CONSTANTS.FILTERS_DEPENDENCY_FILTER
                                )
                            ) {
                                $dependencySibling.classList.add(
                                    CONSTANTS.FILTERS_DEPENDENCY_FILTER
                                );
                            }
                        }
                    );
                }

                if ($defaultOption && $defaultOption.length) {
                    $defaultOption = $defaultOption[0];
                    $dropdownTitle.innerText = $defaultOption.dataset.value;
                    $filter.dataset.value = $defaultOption.dataset.value;
                    $filter.dataset.name = '';
                    $defaultOption.classList.add(CONSTANTS.FILTER_ITEM_ACTIVE_CLASS);
                    $defaultOption.setAttribute(CONSTANTS.ARIA_SELECTED, true);
                } else {
                    // Reset dropdown title and data
                    $dropdownTitle.innerText = $filter.dataset.defaultTitle;
                    $filter.dataset.value = CONSTANTS.RESULTS_ALL_VALUE;
                    $filter.dataset.name = '';
                    $defaultOption.setAttribute(CONSTANTS.ARIA_SELECTED, false);
                }

                $dropdownTitle.classList.remove(CONSTANTS.FILTER_MAIN_SUBTITLE_ACTIVE);
            });

            Array.prototype.forEach.call($filtersItemsSearchLists, function ($dropdown) {
                $textSearchContainer = $dropdown.getElementsByClassName(
                    CONSTANTS.FILTER_SEARCH_CONTAINER
                )[0];
                var $textSearchInputSelector = $dropdown.getElementsByClassName(
                    CONSTANTS.TEXT_SEARCH_INPUT_SELECTOR
                )[0];
                $textSearchContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $textSearchInputSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $searchItemsContainer = $dropdown.getElementsByClassName(
                    CONSTANTS.FILTER_SEARCH_OPTIONS_CONTAINER
                );
                Array.prototype.forEach.call($searchItemsContainer, function ($search) {
                    $search.classList.add(CONSTANTS.FILTER_SEARCH_OPTIONS_CLASS);
                });
            });

            if (coned.utils.isMobile()) {
                Array.prototype.forEach.call($filtersItemsLists, function ($list) {
                    $list.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                $filtersBack.classList.remove(CONSTANTS.FILTER_BACK_ACTIVE);
                $filtersMobileTitle.innerHTML = $filtersTop.dataset.defaultFilterName;

                $filtersContent.classList.remove(CONSTANTS.FILTER_CONTENT_ACTIVE);
                $filtersResultsInfo.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersResultsWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersComponent.classList.remove(CONSTANTS.FILTERS_OPEN_MOBILE_CLASS);
                $filtersResultsWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersTop.classList.add(CONSTANTS.HIDDEN_CLASS);
                $filtersItemsVisibility.classList.add(CONSTANTS.HIDDEN_CLASS);
                $body.classList.remove(coned.constants.MOBILE_OVERLAY_OPEN);
                $showFilter.focus();
            } else {
                closeDropdowns();
            }

            Array.prototype.forEach.call($dropdownItems, function ($item) {
                $item.classList.remove(CONSTANTS.FILTER_ITEM_HIDDEN_CLASS);
            });

            Array.prototype.forEach.call($filtersDropdown, function ($item) {
                var $subtitleLabel = $item.getElementsByClassName(
                        CONSTANTS.FILTER_MAIN_SUBTITLE
                    )[0],
                    defaultLabel = $item.dataset.defaultTitle;

                $item.removeAttribute(CONSTANTS.DISABLE_ATTRIBUTE);
                $subtitleLabel.innerHTML = defaultLabel;
            });

            resetResultsList();
            setResultsContainer();
            hideResetButtons();

            $loader.classList.add(CONSTANTS.HIDDEN_LOADING_CLASS);
            $filtersDropdown[0].focus();
        };

        var hideResetButtons = function () {
            $filtersResetButton.classList.add(CONSTANTS.FILTERS_RESET_BUTTON_HIDDEN_CLASS);
            $filtersResetCta.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var showResetButtons = function () {
            $filtersResetButton.classList.remove(CONSTANTS.FILTERS_RESET_BUTTON_HIDDEN_CLASS);
            $filtersResetCta.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var setDeepLinking = function () {
            var urlParameters = coned.utils.getUrlParameters(),
                urlParameterCriteria,
                urlParameterValue;

            $loader.classList.remove(CONSTANTS.HIDDEN_LOADING_CLASS);

            for (var key in urlParameters) {
                // check if the property/key is defined in the object itself, not in parent
                if (urlParameters.hasOwnProperty(key) && urlParameters[key]) {
                    urlParameterCriteria = key;
                    urlParameterValue = urlParameters[key];

                    for (var filterIndex = 0; filterIndex < $filters.length; filterIndex++) {
                        // If urlParameter criteria matches a filter, trigger its click event

                        if ($filters[filterIndex].dataset.filterCriteria == urlParameterCriteria) {
                            // If filter represents a dropdown, its corresponding item needs to be found
                            if (
                                query.hasClass(
                                    $filters[filterIndex],
                                    CONSTANTS.FILTER_DROPDOWN_ITEM
                                )
                            ) {
                                var $selectedDropdownItems = $filtersItemsLists[
                                    filterIndex
                                ].getElementsByClassName(CONSTANTS.DROPDOWN_ITEM_SELECTOR);
                                $filters[filterIndex].classList.add(
                                    CONSTANTS.FILTER_MAIN_ACTIVE_SELECTOR
                                );

                                for (
                                    var dropdownsIndex = 0;
                                    dropdownsIndex < $selectedDropdownItems.length;
                                    dropdownsIndex++
                                ) {
                                    var $selectedDropdownItem =
                                        $selectedDropdownItems[dropdownsIndex];

                                    // If item value matches the parameter value, then the corresponding item has been found
                                    if ($selectedDropdownItem.dataset.value == urlParameterValue) {
                                        changeDropdownSelection($filters[filterIndex], $selectedDropdownItem, false);
                                        filterResults();

                                        if (_conditionalFiltering) {
                                            filterFilters();
                                        }
                                        break;
                                    }
                                }
                                $filters[filterIndex].classList.remove(
                                    CONSTANTS.FILTER_MAIN_ACTIVE_SELECTOR
                                );
                            }
                            // If filter represents a tab, look for the corresponding tab item
                            else if (
                                query.hasClass($filters[filterIndex], CONSTANTS.TAB_FILTER_SELECTOR)
                            ) {
                                for (var tabsIndex = 0; tabsIndex < $tabItems.length; tabsIndex++) {
                                    var $tabItem = $tabItems[tabsIndex];

                                    // If value matches, trigger its click event
                                    if ($tabItem.dataset.value == urlParameterValue) {
                                        changeTabSelection($tabItem);
                                        break;
                                    }
                                }
                            }

                            break;
                        }
                    }
                }
            }

            $loader.classList.add(CONSTANTS.HIDDEN_LOADING_CLASS);
        };

        var deviceValidation = function () {
            var newWidth = window.innerWidth;
            setResultsContainer();
            if (
                coned.constants.TABLET_MIN_SIZE <= _oldWidth &&
                coned.constants.TABLET_MIN_SIZE > newWidth
            ) {
                Array.prototype.forEach.call($filtersItemsLists, function ($list) {
                    $list.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $list.removeAttribute('style');
                });

                filtersItemsVisibilityStatus();
            } else if (
                coned.constants.TABLET_MIN_SIZE > _oldWidth &&
                coned.constants.TABLET_MIN_SIZE <= newWidth
            ) {
                $filtersContent.classList.remove(CONSTANTS.FILTER_CONTENT_ACTIVE);
                $filtersResultsInfo.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersResultsWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersComponent.classList.remove(CONSTANTS.FILTERS_OPEN_MOBILE_CLASS);
                $filtersResultsWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $filtersTop.classList.add(CONSTANTS.HIDDEN_CLASS);
                $filtersItemsVisibility.classList.add(CONSTANTS.HIDDEN_CLASS);
                $body.classList.remove(coned.constants.MOBILE_OVERLAY_OPEN);

                filtersItemsVisibilityStatus(true);
                closeDropdowns();
            }
            _oldWidth = newWidth;
        };

        /**
         * @param {Object} event event when a button is clicked
         * @param {HTMLCollection} $currentDropdownOptions list of options in the activated filters dropdown
         */
         var handleKeydownEventListener = function (event, $currentDropdownOptions) {
            switch (event.keyCode) {
                case coned.constants.KEY_CODE.ENTER:
                case coned.constants.KEY_CODE.SPACE:
                    event.preventDefault();
                    selectDropdownItem(event.target, event);
                    break;

                case coned.constants.KEY_CODE.HOME:
                    event.preventDefault();
                    var $firstItem = searchNextNotHiddenItem($currentDropdownOptions, $currentDropdownOptions[0]);
                    $firstItem.focus();
                    break;

                case coned.constants.KEY_CODE.END:
                    event.preventDefault();
                    var lastIndex = $currentDropdownOptions.length - 1,
                        $lastItem = searchPreviousNotHiddenItem($currentDropdownOptions, $currentDropdownOptions[lastIndex]);
                    $lastItem.focus();
                    break;

                case coned.constants.KEY_CODE.RIGHT:
                case coned.constants.KEY_CODE.LEFT:
                case coned.constants.KEY_CODE.UP:
                case coned.constants.KEY_CODE.DOWN:
                    event.preventDefault();
                    moveUsingArrowKeys($currentDropdownOptions, event.target, event.keyCode);
                    break;

                case coned.constants.KEY_CODE.TAB:
                    coned.utils.isDesktop() 
                    ? closeDropdowns()
                    : backFilter();
                    break;

                case coned.constants.KEY_CODE.ESC:
                    var $filterSelectedDropdown = $filtersComponent.getElementsByClassName(
                      CONSTANTS.FILTER_MAIN_ACTIVE_SELECTOR
                    )[0];

                    coned.utils.isDesktop() 
                    ? closeDropdowns()
                    : backFilter();

                    $filterSelectedDropdown.focus();
                    break;
            }
        };

        /**
         * @param {HTMLCollection} $currentDropdownOptions List of options in the activated dropdown
         * @param {HTMLElement} $currentOption Option selected by the user.
         * @param {Number} keyCode Key Pressed.
         */
        var moveUsingArrowKeys = function ($currentDropdownOptions, $currentOption, keyCode) {
            var $focusableOption;

            if (keyCode === coned.constants.KEY_CODE.RIGHT || keyCode === coned.constants.KEY_CODE.DOWN) {
                $focusableOption = searchNextNotHiddenItem($currentDropdownOptions, $currentOption.nextElementSibling);
            } else {
                $focusableOption = searchPreviousNotHiddenItem($currentDropdownOptions, $currentOption.previousElementSibling);
            }

            $focusableOption && $focusableOption.focus();
        };

        /**
         * @param {HTMLCollection} $currentDropdownOptions List of options in the activated dropdown
         * @param {HTMLElement} $item Item used to get its next sibling
         */
        var searchNextNotHiddenItem = function ($currentDropdownOptions, $item) {
            if (!$item) {
                $item = $currentDropdownOptions[0];
            }

            if (isHiddenItem($item)) {
                var $nextItem = $item.nextElementSibling;
                $item = searchNextNotHiddenItem($currentDropdownOptions, $nextItem);
            }

            return $item;
        };

        /**
         * @param {HTMLCollection} $currentDropdownOptions List of options in the activated dropdown
         * @param {HTMLElement} $item Item used to get its previous sibling
         */
        var searchPreviousNotHiddenItem = function ($currentDropdownOptions, $item) {
            if (!$item) {
                var lastIndex = $currentDropdownOptions.length - 1;
                $item = $currentDropdownOptions[lastIndex];
            }

            if (isHiddenItem($item)) {
                var $prevItem = $item.previousElementSibling;
                $item = searchPreviousNotHiddenItem($currentDropdownOptions, $prevItem);
            }

            return $item;
        };

        /**
         * @param {HTMLElement} $item Check if the provided item its hidden or not.
         */
        var isHiddenItem = function ($item) {
            return $item.classList.contains(CONSTANTS.HIDDEN_CLASS) 
                  || $item.classList.contains(CONSTANTS.FILTER_ITEM_HIDDEN_CLASS); 
        };

        var initializeData = function () {
            _conditionalFiltering =
                ($filtersComponent.dataset.conditionalFiltering &&
                    $filtersComponent.dataset.conditionalFiltering != 0) ||
                false;
            _hideTotalNumber =
                ($filtersComponent.dataset.hideTotalNumber &&
                    $filtersComponent.dataset.hideTotalNumber != 0) ||
                false;

            $body = document.getElementsByClassName(coned.constants.BODY_CLASS)[0];

            $filtersListboxes = $filtersComponent.getElementsByClassName(CONSTANTS.FILTER_LISTBOX);
            $filtersItemsLists = $filtersComponent.getElementsByClassName(CONSTANTS.FILTER_LISTS);
            $filtersItemsSearchLists = $filtersComponent.getElementsByClassName(
                CONSTANTS.FILTER_LISTS_SEARCH
            );
            $filtersItemsVisibility = $filtersComponent.getElementsByClassName(
                CONSTANTS.FILTER_ITEM_VISIBILITY
            )[0];
            $filtersDropdown = $filtersComponent.getElementsByClassName(
                CONSTANTS.FILTER_DROPDOWN_ITEM
            );
            $filtersBack = $filtersComponent.getElementsByClassName(CONSTANTS.FILTER_BACK)[0];
            $filtersContent = $filtersComponent.getElementsByClassName(CONSTANTS.FILTER_CONTENT)[0];
            $filtersTop = $filtersComponent.getElementsByClassName(CONSTANTS.FILTER_TOP)[0];
            $showFilter = $filtersComponent.getElementsByClassName(
                CONSTANTS.FILTER_SHOW_FILTERS
            )[0];
            $filtersResultsInfo = $filtersComponent.getElementsByClassName(
                CONSTANTS.FILTER_RESULTS_INFO
            )[0];
            $filtersResultsWrapper = document.getElementsByClassName(
                CONSTANTS.FILTERS_RESULTS_WRAPPER
            )[0];
            $filtersMobileTitle = $filtersComponent.getElementsByClassName(
                CONSTANTS.FILTERS_MOBILE_TITLE
            )[0];
            $filtersTotalResult = $filtersComponent.getElementsByClassName(
                CONSTANTS.FILTERS_TOTAL_RESULTS
            )[0];
            $filtersResultNumbers = $filtersComponent.getElementsByClassName(
                CONSTANTS.FILTERS_RESULT_NUMBERS
            )[0];

            $dropdownItems = $filtersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_ITEM_SELECTOR
            );
            $dropdownSort = $filtersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_SORT_SELECTOR
            )[0];

            if ($dropdownSort) {
                $dropdownSortList = $dropdownSort.getElementsByClassName(
                    CONSTANTS.DROPDOWN_LIST_SELECTOR
                )[0];
            }

            $header = document.getElementsByClassName(CONSTANTS.HEADER_WRAPPER_SELECTOR)[0];
            $paginationModule = document.getElementsByClassName(CONSTANTS.PAGINATION_SELECTOR)[0];
            $paginationResults = document.getElementsByClassName(
                CONSTANTS.PAGINATION_RESULTS_SELECTOR
            )[0];
            $paginationTotal = document.getElementsByClassName(
                CONSTANTS.PAGINATION_RESULTS_TOTAL_SELECTOR
            )[0];
            $paginationReset = document.getElementsByClassName(
                CONSTANTS.PAGINATION_RESET_SELECTOR
            )[0];

            $tabFilter = document.getElementsByClassName(CONSTANTS.TAB_FILTER_SELECTOR)[0];
            $tabItems = document.getElementsByClassName(CONSTANTS.TAB_FILTER_ITEM_SELECTOR);
            $filters = document.getElementsByClassName(CONSTANTS.FILTERS_SELECTOR);
            $filtersResultsEmpty = document.getElementsByClassName(
                CONSTANTS.FILTERS_RESULTS_EMPTY
            )[0];

            $filtersResetButton = document.getElementsByClassName(
                CONSTANTS.FILTERS_RESET_BUTTON
            )[0];
            $filtersResetCta = document.getElementsByClassName(CONSTANTS.FILTERS_RESET_CTA)[0];

            $loader = document.getElementsByClassName(CONSTANTS.LOADING_CLASS)[0];

            // Initialize results arrays
            $resultsContainer = document.getElementsByClassName(CONSTANTS.RESULTS_LIST_SELECTOR)[0];
            $resultsList = $resultsContainer.getElementsByClassName(
                CONSTANTS.RESULTS_ITEM_SELECTOR
            );

            _resultsTotal = $resultsList.length;
            _resultsList = new Array(_resultsTotal);
            _newResultsList = new Array(_resultsTotal);
            _resultsFilteredList = new Array(_resultsTotal);
            _newResultsFilteredList = new Array(_resultsTotal);

            for (var index = 0; index < $resultsList.length; index++) {
                _resultsList[index] = $resultsList[index].cloneNode(true);
                _resultsFilteredList[index] = $resultsList[index].cloneNode(true);

                var data = {};
                for (var key in _resultsList[index].dataset) {
                    data[coned.utils.toHyphenCase(key)] = _resultsList[index].dataset[key];
                }
                _newResultsList[index] = {
                    data: data,
                    index: index
                };
                _newResultsFilteredList[index] = {
                    data: data,
                    index: index
                };
            }
        };

        var initializeEvents = function () {
            for (var index = 0; index < $filtersListboxes.length; index++) {
                // When the listbox receives the focus, move it to the first control.
                $filtersListboxes[index].addEventListener('focus', function(event) {
                    var $firstOption = searchNextNotHiddenItem(event.target.children, event.target.firstElementChild);
                    $firstOption.focus();
                });

                // Listbox keyboard usage
                (function($listbox) {
                    $listbox.addEventListener('keydown', function (event) {
                        handleKeydownEventListener(event, $listbox.children);
                    });
                } ($filtersListboxes[index]));
            }

            // Close filters menu when the focus out of the widget in mobile.
            $filtersComponent.addEventListener('focusout', function(event) {
                if (coned.utils.isMobile() 
                    && $filtersComponent.classList.contains(CONSTANTS.FILTERS_OPEN_MOBILE_CLASS) 
                    && !$filtersComponent.contains(event.relatedTarget)) {
                    backFilter();
                }
            });

            // add event to dropdown items (filter||sort selection)
            if (coned.utils.isMobile()) {
                coned.utils.addParentListener(
                    $filtersContent,
                    ['click'],
                    CONSTANTS.DROPDOWN_ITEM_SELECTOR,
                    selectDropdownItem
                );
            } else {
                coned.utils.addParentListener(
                    $filtersContent,
                    coned.utils.generalEvents(),
                    CONSTANTS.DROPDOWN_ITEM_SELECTOR,
                    selectDropdownItem
                );
            }

            // add event to tab items
            coned.utils.addParentListener(
                $filtersComponent,
                coned.utils.generalEvents(),
                CONSTANTS.TAB_FILTER_ITEM_SELECTOR,
                selectTabFilter
            );
            // add event to reset filters
            coned.utils.addParentListener(
                $filtersComponent,
                coned.utils.generalEvents(),
                CONSTANTS.FILTERS_RESET_SELECTOR,
                resetFiltersEvent
            );
            // add event to toggle dropdowns
            coned.utils.addParentListener(
                $filtersComponent,
                coned.utils.generalEvents(),
                CONSTANTS.FILTER_DROPDOWN_ITEM,
                toggleDropdown
            );

            $filtersBack.addEventListener('click', backFilter);
            $showFilter.addEventListener('click', showFilters);

            // resize listener
            window.addEventListener('resize', deviceValidation);

            _oldWidth = window.innerWidth;

            if (coned.utils.isMobile()) {
                filtersItemsVisibilityStatus();
            } else {
                filtersItemsVisibilityStatus(true);
            }

            if (!_hideTotalNumber) {
                $filtersTotalResult.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            window.addEventListener('popstate', function () {
                resetFilters();
                setDeepLinking();
            });

            setDeepLinking();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FiltersComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FiltersComponent;
})();
