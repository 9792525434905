// ==================== AVAILABLE CONNECTIONS COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AvailableConnections = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        LOADING_CLASS: 'js-form-loading',
        AVAILABLE_CONNECTION_LINK_CLASS: 'js-available-connection-link',
        HIDDEN_CLASS: 'hidden',
        GREEN_BUTON_VENDOR_TAGGING: 'greenbutton.vendor.select',
        DATA_USE_CERTIFICATION_VERSION_ATTRIBUTE: 'data-use-certification-version',
        DATA_VENDOR_NAME_ATTRIBUTE: 'data-vendor-name',
        TRUE_STR: 'true'
    };

    var isLoaded = false; 

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AvailableConnections = function ($availableConnectionsContainer) {
        /**
         * PRIVATE METHODS
         */
        var $loadingContainer,
            $availableConections;

        var showLoading = function () {
            query.removeClass($loadingContainer, CONSTANTS.HIDDEN_CLASS);
        };

        /**
        * Create a tag when the user tap in a vendor
        */
        var addTaggingByVendor = function (event) {
            var _isThirdParty = event.currentTarget.getAttribute(CONSTANTS.DATA_USE_CERTIFICATION_VERSION_ATTRIBUTE) === CONSTANTS.TRUE_STR,
                vendorName = event.currentTarget.getAttribute(CONSTANTS.DATA_VENDOR_NAME_ATTRIBUTE);

            // Analytics data building
            dataLayer.push({
                event: CONSTANTS.GREEN_BUTON_VENDOR_TAGGING,
                vendor: vendorName,
                useCertificationVersion: _isThirdParty
            });
        }

        var initializeData = function () {
            $loadingContainer = document.getElementsByClassName(CONSTANTS.LOADING_CLASS);
            $availableConections = $availableConnectionsContainer.getElementsByClassName(CONSTANTS.AVAILABLE_CONNECTION_LINK_CLASS);
        };

        var initializeEvents = function () {
            coned.utils.addParentListener(
                $availableConnectionsContainer,
                coned.utils.generalEvents(),
                CONSTANTS.AVAILABLE_CONNECTION_LINK_CLASS,
                showLoading
            );
            
            Array.from($availableConections).forEach(function ($availableConection) {
                coned.utils.addGeneralListeners($availableConection, addTaggingByVendor);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AvailableConnections.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AvailableConnections;
})();
