// ==================== GOOGLE TRANSLATE COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.GoogleTranslateComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        GOOGLE_TRANSLATE_BTN_CLASS: 'js-google-translate-btn',
        GOOGLE_TRANSLATE_ELEMENT_CLASS: 'js-google-translate-element',
        GOOGLE_TRANSLATE_ELEMENT_OPEN_CLASS: 'google-translate__element--open',
        GOOGLE_TRANSLATE_SELECT_CLASS: '.goog-te-combo',
        GOOGLE_TRANSLATE_BAR_CLOSE_CLASS: '.goog-close-link',
        MODAL_SELECTOR_LINK_CLASS: 'modal-selector__link',
        MODAL_SELECTOR_LINK_ACTIVE_CLASS: 'modal-selector__link--active'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var GoogleTranslateComponent = function ($googleTranslateElement) {
        /**
         * PRIVATE METHODS
         */
        var $googleBtnElement,
            $googleElement,
            $googleSelectElement,
            $googleBarCloseElement,
            $modalSelectorLinks,
            _firstText;

        var googleBtnEventClick = function (event) {
            event.preventDefault();

            event.target.style.display = 'none';
            query.addClass($googleElement, CONSTANTS.GOOGLE_TRANSLATE_ELEMENT_OPEN_CLASS);
        };

        var googleBarHandling = function () {
            // Set active on modal selector link
            query.removeClass($modalSelectorLinks, CONSTANTS.MODAL_SELECTOR_LINK_ACTIVE_CLASS);
            query.addClass($googleBtnElement, CONSTANTS.MODAL_SELECTOR_LINK_ACTIVE_CLASS);
        };

        var googleBarClose = function () {
            query.removeClass($googleBtnElement, CONSTANTS.MODAL_SELECTOR_LINK_ACTIVE_CLASS);
        };

        var checkIfTranslated = function () {
            var check = function () {
                if (_firstText != $modalSelectorLinks[0].text) {
                    _firstText = $modalSelectorLinks[0].text;
                    googleBarHandling();
                    window.clearInterval(checkTranslation);
                }
            };

            var checkTranslation = window.setInterval(check, 2000);
        };

        var initializeData = function () {
            $googleBtnElement = $googleTranslateElement.getElementsByClassName(
                CONSTANTS.GOOGLE_TRANSLATE_BTN_CLASS
            )[0];
            $googleElement = $googleTranslateElement.getElementsByClassName(
                CONSTANTS.GOOGLE_TRANSLATE_ELEMENT_CLASS
            )[0];
            $googleSelectElement = $(CONSTANTS.GOOGLE_TRANSLATE_SELECT_CLASS);
            $googleBarCloseElement = $(CONSTANTS.GOOGLE_TRANSLATE_BAR_CLOSE_CLASS);
            $modalSelectorLinks = $googleTranslateElement.parentElement.parentElement.getElementsByClassName(
                CONSTANTS.MODAL_SELECTOR_LINK_CLASS
            );
            _firstText = $modalSelectorLinks[0].text;
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($googleBtnElement, googleBtnEventClick);

            // Set select change callback
            $('body').on('change', $googleSelectElement, googleBarHandling);

            // Set close bar event
            $('body').on('click', $googleBarCloseElement, googleBarClose);
            $('body').on('click', $googleBarCloseElement, googleBarClose);

            // Verification function to get the first instance of translation
            checkIfTranslated();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    GoogleTranslateComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return GoogleTranslateComponent;
})();
