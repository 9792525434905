// ==================== SR_LIVE =========================

// Create a namespace for the coned object if it doesn't already exist
var coned = coned || {};
coned.components = coned.components || {};

/**
 * @returns the init function to start the SrLive module
 */
coned.components.SrLive = (function () {
    var isLoaded = false; // Indicates if the module has been loaded
    /**
     * @typedef {Object} State
     * @property {Record<string, string>} [values]
     * @property {string} [valueKey]
     * @property {string[]} [formatValues]
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        VALUES: {
            /**
             * @type {' '}
             */
            SPACE: ' ',
            /**
             * @type {'&nbsp;'}
             */
            NBSP: '&nbsp;'
        }
    }

    /**
     * Constructor for the ProgramSavingsFilters module
     * @constructor
     * @param {HTMLElement} $container - The container element for the module
     * @returns {}
     */
    var SrLive = function ($container) {
        /**
         * PRIVATE VARIABLES
         */
        /**
         * @type {State}
         * Holds the private state of the module
         */
        var _state,
            cont = 1;

        /**
         * PRIVATE METHODS
         */
        
        /**
         * This function handles the setting of a state based on a custom event.
         * It formats and updates text content in a container using a utility function.
         * 
         * @param {CustomEvent<State>} event - The custom event object containing the state details.
         */
        var handleSetState = function (event) {
            // Extract the 'valueKey' and 'formatValues' properties from the event's 'detail' object.
            var valueKey = event.detail.valueKey,
                formatValues = event.detail.formatValues,

                // Retrieve the value from '_state.values' using 'valueKey', or an empty string if it doesn't exist.
                toFormat = _state.values && valueKey
                    ? _state.values[valueKey]
                        ? _state.values[valueKey]
                        : ""
                    : "";

            // If 'valueKey' exists and 'toFormat' is a non-empty string:
            if (valueKey && toFormat.length > 0) {
                cont ++;
                var spaces = Array.from({ length: cont }, function(){return CONSTANTS.VALUES.NBSP}).join(CONSTANTS.VALUES.SPACE);
                if(cont > 10){
                    cont = 0;
                }
                $container.innerHTML = spaces + (formatValues
                    ? coned.utils.formatString(toFormat, formatValues)
                    : toFormat) + spaces;
            }
        };

        /**
         * Initializes the data in the module
         */
        var initializeData = function () {
            _state = {
                values: $container.dataset.values ? coned.utils.buildRecordString($container.dataset.values) : {}
            }
        }

        /**
         * Initializes the events in the module
         */
        var initializeEvents = function () {
            $container.addEventListener(
                coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                handleSetState
            );
        }
        /**
         * Initializes functionality in the module
         */
        var init = function () {
            initializeData(); // Initialize data for the module
            initializeEvents(); // Initialize event listeners for the module
            isLoaded = true; // Mark the module as loaded
            coned.utils.triggerEvent($container,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _state
            );
        }
        init(); // Call the init function to start the module
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to check if the module is loaded
     * @returns {boolean} - True if the module is loaded, false otherwise
     */
    SrLive.prototype.isLoaded = function () {
        return isLoaded; // Return the loaded state of the module
    };
    return SrLive; // Return the SrLive constructor
})();