// ==================== FORM VALIDATION COMPONENT =========================
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FormValidationOptOutModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        MESSAGE_WRAPPER: 'js-form-messages',
        MESSAGE_SUCESS: 'js-success-message',
        MESSAGE_ERROR: 'js-error-message',
        CONTACT_COPY: 'js-contact-us-copy-block',
        CONTACT_SUCCESS_CLASS: 'contact-us-form__copy-block--success',
        CAPTCHA_ELEMENT: 'js-recaptcha',
        INPUT_ERROR_CLASS: 'coned-input-message--error',
        FIELD_ERROR_CLASS: 'coned-field-error-wrapper',
        CONED_INPUT: '.coned-input',
        CONED_INPUT_PHONE: '.coned-input-phone',
        CONED_TEXT_AREA: '.coned-textarea',
        CONED_INPUT_BIRTH_DATE: '.js-birth-date',
        CONED_INPUT_DATE_FORMAT: '.js-date-format',
        CONED_INPUT_WEEK_DAYS: '.js-week-days',
        CONED_INPUT_HOUR_DAY: '.js-hours-day',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        TEXT_AREA_FILLED_CLASS: 'coned-textarea--filled',
        GET_FILE_LIST: 'js-file-list-button',
        RESET_BUTTON: 'js-reset-button',
        FORM_SELECTOR: 'coned-form',
        DROPDOWN_LABEL: 'js-coned-select-label',
        INPUT_TEXT_SELECTOR: 'js-coned-input',
        TEXTAREA_SELECTOR: 'js-coned-textarea',
        FORM_LOADING_SELECTOR: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        FORM_LOADING_IMAGE: 'js-form-loading-image',
        HEADER_WRAPPER: 'js-header-wrapper',
        BORDER_ANIMATION_SELECTOR: 'js-border-bar-selector',
        BORDER_ANIMATION_CLASS: 'border-bar--animate',
        DATE_SELECTOR: 'js-date',
        RADIO_BUTTON_SELECTOR: 'js-coned-radio',
        CHECKBOX_SELECTOR: 'js-checkbox-selector',
        DROPDOWN_SELECTOR: 'js-coned-select',
        ACTIVE_DROPDOWN: 'coned-select--active',
        CHECKBOX_CHECKED_CLASS: 'coned-checkbox--checked',
        CURRENCY_INPUT_SELECTOR: 'js-currency-input',
        ZIPCODE_USA_INPUT_SELECTOR: 'js-zipcode-input',
        FORM_IGNORE_VALIDATION: '.js-validate-ignore',
        PHONE_EXTENSION_SELECTOR: 'js-validate-phone-extension',
        CONED_INPUT_FILLED: '.js-item-filled',
        CONED_INPUT_NUMBERS: '.js-number-input',
        NUMBER_FORMAT_SELECTOR: 'js-number-format',
        CONED_NUMBER_ACCOUNT: 'js-coned-account-number',
        ORU_NUMBER_ACCOUNT: 'js-oru-account-number',
        CONED_INPUT_SSN: '.js-input-ssn',
        CONED_ADDRESS_INPUT: 'js-address-input',
        CONED_EIN_TAX_INPUT: '.js-ein-tax-input',
        LIMITED_LENGTH_INPUT: 'js-limit-length-input',
        FORM_NAME: 'name',
        FORM_EMAIL: 'optOutEmail',
        FORM_HOW_IMPROVE: 'how-improve',
        FORM_COMMENT: 'optOutComments',
        FORM_ACCOUNT_NUMBER: 'optOutAccountNumber',
        CONED_RADIO_CLASS: 'coned-radio',
        CONED_RADIO_LABEL_CLASS: 'coned-radio__label-name',
        PARAMETER_NAME: 'name',
        PARAMETER_EMAIL: 'optOutEmail',
        PARAMETER_ACCOUNT: 'optOutAccountNumber',
        PARAMETER_COMMENT: 'optOutComments',
        PARAMETER_VERIFY: 'optOutVerify',
        REASON_OTHER: 'Other'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var FormValidationOptOutModule = function ($formValidation) {
        /**
         * PRIVATE METHODS
         */
        var $messageWrapper,
            $successMessage,
            $errorMessage,
            $contactCopy,
            $captchaElement,
            $resetButton,
            $inputsFilledSelector,
            $textareasSelector,
            $datesSelector,
            $checkboxesSelector,
            $dropdownLabels,
            $dropdownsSelector,
            $radioButtonsSelector,
            $currencyInputsSelector,
            $zipcodeUSAInputsSelector,
            $phoneExtensionInputs,
            $numbersFormatSelector,
            $conedNumberAccounts,
            $oruNumberAccounts,
            $conedAddressInputs,
            $limitedLengthInputs,
            $noTransactionalForms,
            $activeForm,
            $formLoading,
            $loadingImage,
            recaptcha,
            $headerWrapper,
            _targetUrl,
            _actualFormData,
            _formData,
            _hasCaptcha,
            _scrolledY;

        // include class to animate label pattern
        var labelPattern = function () {
            $(CONSTANTS.CONED_INPUT).bind('change keyup', function () {
                if ($(this).val() !== '') {
                    $(this).addClass(CONSTANTS.INPUT_FILLED_CLASS);
                } else {
                    $(this).removeClass(CONSTANTS.INPUT_FILLED_CLASS);
                }
            });

            $(CONSTANTS.CONED_TEXT_AREA).bind('change keyup', function () {
                if ($(this).val() !== '') {
                    $(this).addClass(CONSTANTS.TEXT_AREA_FILLED_CLASS);
                } else {
                    $(this).removeClass(CONSTANTS.TEXT_AREA_FILLED_CLASS);
                }
            });

            $(CONSTANTS.CONED_INPUT_PHONE).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
            });

            $(CONSTANTS.CONED_INPUT_BIRTH_DATE).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
                    .slice(1)
                    .join('/')
                    .replace(/\/*$/g, '');
            });

            $(CONSTANTS.CONED_INPUT_DATE_FORMAT).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
                    .slice(1)
                    .join('/')
                    .replace(/\/*$/g, '');
            });

            $(CONSTANTS.CONED_INPUT_WEEK_DAYS).keyup(function () {
                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,1})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
            });

            $(CONSTANTS.CONED_INPUT_HOUR_DAY).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,2})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
            });

            $(CONSTANTS.CONED_INPUT_FILLED).keyup(function () {
                $(this).valid();
            });

            $(CONSTANTS.CONED_INPUT_NUMBERS).keyup(function () {
                this.value = this.value.replace(/[^0-9]/g, '');
            });

            $(CONSTANTS.CONED_INPUT_SSN).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
            });

            $(CONSTANTS.CONED_EIN_TAX_INPUT).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,2})(\d{0,7})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
            });
        };

        // no transactional form submit action
        function submitAction() {
            $activeForm = $formValidation.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            _targetUrl = $activeForm.action;
            _actualFormData = $activeForm;
            _formData = new FormData(_actualFormData);

            // show loading message
            $formLoading.parentNode.style.position = 'relative';
            $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
            $loadingImage.scrollIntoView(false);
            _scrolledY = window.pageYOffset;

            if (_scrolledY) {
                window.scroll(0, _scrolledY + $headerWrapper.offsetHeight);
            }

            if (_hasCaptcha && recaptcha.getResponse() === '') {
                recaptcha.checkRecaptcha();
                $formLoading.parentNode.style.position = '';
                $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
                return false;
            } else {
                // formSubmit or captcha validation
                if (_hasCaptcha) {
                    recaptcha.checkRecaptcha();
                } else {
                    submitForm($activeForm, _formData, _targetUrl);
                }
            }
        }

        function recaptchaValidation() {
            submitForm($activeForm, _formData, _targetUrl);
        }

        /**
         * submit the form data.
         * @param  {HTMLElement} $activeForm form to be submited.
         * @param  {Object} _formData form data object.
         * @param  {String} _targetUrl service url.
         */
        function submitForm($activeForm, _formData, _targetUrl) {
            var dataString,
                comment,
                response,
                commentValue = '',
                name = query.getFormInputValue($activeForm, CONSTANTS.FORM_NAME),
                email = query.getFormInputValue($activeForm, CONSTANTS.FORM_EMAIL),
                how_improve = query.getFormInputText(
                    $activeForm,
                    CONSTANTS.FORM_HOW_IMPROVE,
                    CONSTANTS.CONED_RADIO_CLASS,
                    CONSTANTS.CONED_RADIO_LABEL_CLASS
                ),
                accountNumber = query.getFormInputValue($activeForm, CONSTANTS.FORM_ACCOUNT_NUMBER),
                reasonValue = query.getFormInputValue($activeForm, CONSTANTS.FORM_HOW_IMPROVE);

            if (reasonValue == CONSTANTS.REASON_OTHER) {
                how_improve = reasonValue;
                (comment = query.getFormInputValue($activeForm, CONSTANTS.FORM_COMMENT)),
                    (commentValue =
                        '&' + CONSTANTS.PARAMETER_COMMENT + '=' + encodeURIComponent(comment));
            }

            if (recaptcha) {
                response = _hasCaptcha ? recaptcha.getResponse() : null;
            }

            dataString =
                CONSTANTS.PARAMETER_EMAIL +
                '=' +
                email +
                '&' +
                CONSTANTS.FORM_HOW_IMPROVE +
                '=' +
                how_improve +
                '&' +
                CONSTANTS.PARAMETER_ACCOUNT +
                '=' +
                accountNumber +
                '&' +
                CONSTANTS.PARAMETER_NAME +
                '=' +
                encodeURIComponent(name) +
                commentValue;
            if (_hasCaptcha && response && response !== '') {
                dataString += '&' + CONSTANTS.PARAMETER_VERIFY + '=' + response;
            }
            _formData = dataString.split(' ').join('+');

            // Analytics data building
            dataLayer.push({
                event: 'ipp.optout.submit',
                optoutReason: reasonValue
            });

            query.postData(
                _targetUrl,
                function () {
                    $formLoading.parentNode.style.position = '';
                    $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
                    showFormMessage('success');
                    $activeForm.style.display = 'none';
                },
                function () {
                    // error messages
                    $formLoading.parentNode.style.position = '';
                    $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
                    showFormMessage();
                    $activeForm.style.display = 'none';
                },
                _formData,
                true,
                '',
                'POST'
            );
        }
        /**
         * Util service ==> POST/DELETE or PUT can be used to generate a request.
         * @param {String} url Url endpoint
         * @param {Function} successCallback Callback called when request is successful.
         * @param {Function} errorCallback Callback called when request failed.
         * @param {Object} params Data sent as a query.
         * @param {Boolean} json Flag to mark if the request is JSON.
         * @param {HTMLElement} $formLoading Form progress element.
         * @param {String} httpMethod Method to use for the request call to make.
         */

        query.httpDataMethod = function httpDataMethod(
            url,
            successCallback,
            errorCallback,
            params,
            json,
            $formLoading,
            httpMethod
        ) {
            var request = new XMLHttpRequest();

            request.open(httpMethod, url, true);

            // if (isForm && !sendFilesRequest) {
            if (json) {
                request.setRequestHeader(
                    'Content-type',
                    'application/x-www-form-urlencoded; Charset=ANSI'
                );
            }

            request.onload = function onload() {
                toggleLoading($formLoading);

                if (request.status >= 200 && request.status < 400) {
                    if (request.response) {
                        if (query.isJSON(request.response)) {
                            successCallback(JSON.parse(request.response));
                        } else {
                            successCallback(request.response);
                        }
                    } else {
                        successCallback();
                    }
                } else {
                    // Server was reached, but it returned an error
                    if (query.isJSON(request.response)) {
                        errorCallback(JSON.parse(request.response));
                    } else {
                        errorCallback(request.response);
                    }
                }
            };

            request.onerror = function () {
                toggleLoading($formLoading);

                var data = {
                    status: request.status,
                    errorMsg: request.response
                };

                errorCallback(data);
            };

            toggleLoading($formLoading, true);

            request.send(params);
        };

        /**
         * Method used for the loading show/hide on service calls.
         * @param {HTMLElement} $formLoading form progress element.
         * @param {boolean} status Boolean to show or hide the form loading.
         */
        function toggleLoading($formLoading, status) {
            if (!$formLoading) return;

            if (status) {
                $formLoading.classList.remove('form-loading--hidden');
            } else {
                $formLoading.classList.add('form-loading--hidden');
            }
        }

        // scroll to message position
        function scrollToMessage() {
            $messageWrapper.scrollIntoView(true);
            var scrolled = window.pageYOffset;

            if (scrolled) {
                window.scroll(0, scrolled - $headerWrapper.offsetHeight);
            }
        }

        // show form messages
        function showFormMessage(status) {
            var $statusMessage = status ? $successMessage : $errorMessage;

            $statusMessage.style.display = 'block';
            $messageWrapper.style.display = 'block';
            scrollToMessage();

            if (status) {
                $errorMessage.style.display = 'none';

                // Analytics data building
                dataLayer.push({
                    event: 'coned.form.success'
                });
            }

            if ($contactCopy) {
                query.addClass($contactCopy, CONSTANTS.CONTACT_SUCCESS_CLASS);
            }
        }

        var resetForm = function (event) {
            event.preventDefault();

            $($formValidation).find('form').validate().resetForm();

            for (var inputIndex = 0; inputIndex < $inputsFilledSelector.length; inputIndex++) {
                var $inputFilledSelector = $inputsFilledSelector[inputIndex];

                query.removeClass($inputFilledSelector, CONSTANTS.INPUT_FILLED_CLASS);
                $inputFilledSelector.value = '';
            }

            for (
                var textareaIndex = 0;
                textareaIndex < $textareasSelector.length;
                textareaIndex++
            ) {
                var $textareaSelector = $textareasSelector[textareaIndex];

                query.removeClass($textareaSelector, CONSTANTS.TEXT_AREA_FILLED_CLASS);
                $textareaSelector.value = '';
            }

            for (var labelIndex = 0; labelIndex < $dropdownLabels.length; labelIndex++) {
                var $dropdownLabel = $dropdownLabels[labelIndex];

                $dropdownLabel.removeAttribute('style');
            }

            for (
                var dropdownIndex = 0;
                dropdownIndex < $dropdownsSelector.length;
                dropdownIndex++
            ) {
                var $dropdownSelector = $dropdownsSelector[dropdownIndex];

                $dropdownSelector.removeAttribute('style');
                $dropdownSelector.selectedIndex = 0;
                $dropdownSelector.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);
            }

            for (var dateIndex = 0; dateIndex < $datesSelector.length; dateIndex++) {
                var $dateSelector = $datesSelector[dateIndex];

                query.removeClass($dateSelector, CONSTANTS.INPUT_FILLED_CLASS);
                $dateSelector.value = '';
            }

            for (var radioIndex = 0; radioIndex < $radioButtonsSelector.length; radioIndex++) {
                var $radioButtonSelector = $radioButtonsSelector[radioIndex];

                $radioButtonSelector.checked = false;
            }
        };

        var checkInputDefaultValues = function () {
            for (var inputIndex = 0; inputIndex < $inputsFilledSelector.length; inputIndex++) {
                var $inputFilledSelector = $inputsFilledSelector[inputIndex];

                if ($inputFilledSelector.value) {
                    $inputFilledSelector.classList.add(CONSTANTS.INPUT_FILLED_CLASS);
                }
            }

            for (
                var textareaIndex = 0;
                textareaIndex < $textareasSelector.length;
                textareaIndex++
            ) {
                var $textareaSelector = $textareasSelector[textareaIndex];

                if ($textareaSelector.value) {
                    $textareaSelector.classList.add(CONSTANTS.TEXT_AREA_FILLED_CLASS);
                }
            }

            for (var dateIndex = 0; dateIndex < $datesSelector.length; dateIndex++) {
                var $dateSelector = $datesSelector[dateIndex];

                if ($dateSelector.value) {
                    $dateSelector.classList.add(CONSTANTS.INPUT_FILLED_CLASS);
                }
            }
        };

        var limitInput = function (event, inputLimit) {
            if (event !== undefined && event.target.value.length >= inputLimit) {
                if (coned.utils.preventBehaviourError(event)) return;

                event.preventDefault();
            }
        };

        var borderAnimation = function (event) {
            var $border = event.target.parentElement.getElementsByClassName(
                CONSTANTS.BORDER_ANIMATION_SELECTOR
            )[0];

            if (query.hasClass($border, CONSTANTS.BORDER_ANIMATION_CLASS)) {
                query.removeClass($border, CONSTANTS.BORDER_ANIMATION_CLASS);
            } else {
                query.addClass($border, CONSTANTS.BORDER_ANIMATION_CLASS);
            }
        };

        var checkedAnimation = function (event) {
            var $target = event.target,
                $parentTarget = event.target.parentElement;

            if ($target.checked) {
                query.addClass($parentTarget, CONSTANTS.CHECKBOX_CHECKED_CLASS);
            } else {
                query.removeClass($parentTarget, CONSTANTS.CHECKBOX_CHECKED_CLASS);
            }
        };

        var currencyFormatNumber = function (number) {
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        };

        var currencyFormat = function (input) {
            var currencyInput = input.value.replace(/[^\d.-]/g, ''),
                decimals = '',
                trimDecimals = '',
                val;

            currencyInput = currencyInput.replace(/[-\s]+/g, '');
            currencyInput = currencyInput.split('.', 2);
            val = parseInt(currencyInput[0]) * 1;

            if (typeof currencyInput[1] != 'undefined') {
                if (currencyInput[1].length > 2) {
                    trimDecimals = currencyInput[1][0];

                    if (typeof currencyInput[1][1] != 'undefined') {
                        trimDecimals += currencyInput[1][1];
                    }
                } else {
                    trimDecimals = currencyInput[1];
                }

                decimals = '.' + trimDecimals;
            }

            if (currencyInput[0].length > 0) {
                input.value = '$' + currencyFormatNumber(val) + decimals;
            } else {
                input.value = '';
                query.removeClass(input, CONSTANTS.INPUT_FILLED_CLASS);
            }
        };

        var numberFormat = function (event) {
            if (coned.utils.preventBehaviourError(event)) return;

            var number = event.target.value;

            event.target.value = number.replace(/\D/g, '');
        };

        var addressFormat = function (event) {
            if (coned.utils.preventBehaviourError(event)) return;

            var address = event.target.value;

            event.target.value = address.replace(/[^\a-z\d\-\s]/gi, '');
        };

        var initializeData = function () {
            $messageWrapper = $formValidation.getElementsByClassName(CONSTANTS.MESSAGE_WRAPPER)[0];
            $successMessage = $formValidation.getElementsByClassName(CONSTANTS.MESSAGE_SUCESS)[0];
            $errorMessage = $formValidation.getElementsByClassName(CONSTANTS.MESSAGE_ERROR)[0];
            $contactCopy = $formValidation.getElementsByClassName(CONSTANTS.CONTACT_COPY)[0];
            $captchaElement = $formValidation.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT);
            $resetButton = $formValidation.getElementsByClassName(CONSTANTS.RESET_BUTTON)[0];
            $inputsFilledSelector = $formValidation.getElementsByClassName(
                CONSTANTS.INPUT_TEXT_SELECTOR
            );
            $textareasSelector = $formValidation.getElementsByClassName(
                CONSTANTS.TEXTAREA_SELECTOR
            );
            $checkboxesSelector = $formValidation.getElementsByClassName(
                CONSTANTS.CHECKBOX_SELECTOR
            );
            $dropdownLabels = $formValidation.getElementsByClassName(CONSTANTS.DROPDOWN_LABEL);
            $datesSelector = $formValidation.getElementsByClassName(CONSTANTS.DATE_SELECTOR);
            $radioButtonsSelector = $formValidation.getElementsByClassName(
                CONSTANTS.RADIO_BUTTON_SELECTOR
            );
            $dropdownsSelector = $formValidation.getElementsByClassName(
                CONSTANTS.DROPDOWN_SELECTOR
            );
            $currencyInputsSelector = $formValidation.getElementsByClassName(
                CONSTANTS.CURRENCY_INPUT_SELECTOR
            );
            $zipcodeUSAInputsSelector = $formValidation.getElementsByClassName(
                CONSTANTS.ZIPCODE_USA_INPUT_SELECTOR
            );
            $phoneExtensionInputs = $formValidation.getElementsByClassName(
                CONSTANTS.PHONE_EXTENSION_SELECTOR
            );
            $numbersFormatSelector = $formValidation.getElementsByClassName(
                CONSTANTS.NUMBER_FORMAT_SELECTOR
            );
            $conedNumberAccounts = $formValidation.getElementsByClassName(
                CONSTANTS.CONED_NUMBER_ACCOUNT
            );
            $oruNumberAccounts = $formValidation.getElementsByClassName(
                CONSTANTS.ORU_NUMBER_ACCOUNT
            );
            $conedAddressInputs = $formValidation.getElementsByClassName(
                CONSTANTS.CONED_ADDRESS_INPUT
            );
            $limitedLengthInputs = $formValidation.getElementsByClassName(
                CONSTANTS.LIMITED_LENGTH_INPUT
            );
            $noTransactionalForms = $formValidation.getElementsByClassName(CONSTANTS.FORM_SELECTOR);
            $formLoading = $formValidation.getElementsByClassName(
                CONSTANTS.FORM_LOADING_SELECTOR
            )[0];
            $loadingImage = document.getElementsByClassName(CONSTANTS.FORM_LOADING_IMAGE)[0];
            $headerWrapper = document.getElementsByClassName(CONSTANTS.HEADER_WRAPPER)[0];
            _hasCaptcha = $captchaElement.length;
        };

        var initializeEvents = function () {
            labelPattern();
            checkInputDefaultValues();

            if ($resetButton !== undefined) {
                coned.utils.addGeneralListeners($resetButton, resetForm);
            }

            if ($noTransactionalForms) {
                for (var formIndex = 0; formIndex < $noTransactionalForms.length; formIndex++) {
                    var $noTransactionalForm = $noTransactionalForms[formIndex];

                    new coned.components.ValidateForm($noTransactionalForm, submitAction);

                    $activeForm = $formValidation.getElementsByClassName(
                        CONSTANTS.FORM_SELECTOR
                    )[0];
                    recaptcha = new coned.components.Recaptcha(
                        $activeForm,
                        recaptchaValidation,
                        recaptchaValidation
                    );
                }
            }

            for (
                var inputsFilledIndex = 0;
                inputsFilledIndex < $inputsFilledSelector.length;
                inputsFilledIndex++
            ) {
                var $inputFilledSelector = $inputsFilledSelector[inputsFilledIndex];

                $inputFilledSelector.addEventListener('focus', borderAnimation);
                $inputFilledSelector.addEventListener('focusout', borderAnimation);
            }

            for (
                var textareaIndex = 0;
                textareaIndex < $textareasSelector.length;
                textareaIndex++
            ) {
                var $textareaSelector = $textareasSelector[textareaIndex];

                $textareaSelector.addEventListener('focus', borderAnimation);
                $textareaSelector.addEventListener('focusout', borderAnimation);
            }

            for (
                var checkboxIndex = 0;
                checkboxIndex < $checkboxesSelector.length;
                checkboxIndex++
            ) {
                var $checkboxSelector = $checkboxesSelector[checkboxIndex];

                $checkboxSelector.addEventListener('change', checkedAnimation);
            }

            for (var usZipIndex = 0; usZipIndex < $zipcodeUSAInputsSelector.length; usZipIndex++) {
                var $zipcodeUSAInputSelector = $zipcodeUSAInputsSelector[usZipIndex];

                $zipcodeUSAInputSelector.addEventListener('keyup', numberFormat);
                $zipcodeUSAInputSelector.addEventListener('keypress', function (event) {
                    limitInput(event, 5);
                });
            }

            for (
                var numbersIndex = 0;
                numbersIndex < $numbersFormatSelector.length;
                numbersIndex++
            ) {
                var $numberFormatSelector = $numbersFormatSelector[numbersIndex];

                $numberFormatSelector.addEventListener('keyup', numberFormat);
            }

            for (
                var currencyIndex = 0;
                currencyIndex < $currencyInputsSelector.length;
                currencyIndex++
            ) {
                var $currencyInputSelector = $currencyInputsSelector[currencyIndex];

                $currencyInputSelector.addEventListener('keyup', function (event) {
                    if (coned.utils.preventBehaviourError(event)) return;

                    currencyFormat($currencyInputSelector);
                });
            }

            for (
                var addressesIndex = 0;
                addressesIndex < $conedAddressInputs.length;
                addressesIndex++
            ) {
                var $conedAddressInput = $conedAddressInputs[addressesIndex];

                $conedAddressInput.addEventListener('keyup', addressFormat);
                $conedAddressInput.addEventListener('keypress', function (event) {
                    limitInput(event, 21);
                });
            }

            // Text limit key press events
            for (var phoneIndex = 0; phoneIndex < $phoneExtensionInputs.length; phoneIndex++) {
                var $phoneExtensionInput = $phoneExtensionInputs[phoneIndex];

                $phoneExtensionInput.addEventListener('keypress', function (event) {
                    limitInput(event, 4);
                });
            }

            for (
                var conedAccountIndex = 0;
                conedAccountIndex < $conedNumberAccounts.length;
                conedAccountIndex++
            ) {
                var $conedNumberAccount = $conedNumberAccounts[conedAccountIndex];

                $conedNumberAccount.addEventListener('keypress', function (event) {
                    limitInput(event, 15);
                });
            }

            for (
                var oruAccountIndex = 0;
                oruAccountIndex < $oruNumberAccounts.length;
                oruAccountIndex++
            ) {
                var $oruNumberAccount = $oruNumberAccounts[oruAccountIndex];

                $oruNumberAccount.addEventListener('keypress', function (event) {
                    limitInput(event, 10);
                });
            }

            for (
                var inputToLimitIndex = 0;
                inputToLimitIndex < $limitedLengthInputs.length;
                inputToLimitIndex++
            ) {
                var $limitedLengthInput = $limitedLengthInputs[inputToLimitIndex];

                $limitedLengthInput.addEventListener('keypress', function (event) {
                    var maxLength = event.target.dataset.ruleMaxlength;

                    limitInput(event, maxLength);
                });
            }
        };

        // GENERAL CUSTOM VALIDATION METHODS
        // Email validations
        $.validator.addMethod(
            'customEmail',
            function (value, element) {
                return (
                    this.optional(element) ||
                    /^\w+([-+.']\w+)*[+]*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
                );
            },
            'Error: Please enter a valid email address.'
        );

        $.validator.addMethod(
            'customEmailReduced',
            function (value, element) {
                return (
                    this.optional(element) ||
                    /^\w+([-.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
                );
            },
            'Error: Please enter a valid email address.'
        );

        // CAN zip code
        $.validator.addMethod(
            'canadaZipCode',
            function (value, element) {
                return (
                    this.optional(element) ||
                    /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i.test(value)
                );
            },
            'Error: The specified Canada ZIP Code is invalid.'
        );

        // US zip code
        $.validator.addMethod(
            'USAZipCode',
            function (value, element) {
                return this.optional(element) || /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(value);
            },
            'Error: Please enter a valid ZIP code.'
        );

        // SSN validation
        $.validator.addMethod(
            'ssnValidation',
            function (value) {
                return /^\d{3}-\d{2}-\d{4}$/.test(value);
            },
            'Error: Please enter a valid SSN number.'
        );

        // Payment amount limits
        $.validator.addMethod('paymentLimits', function (value, element) {
            var paymentMinAmount = element.dataset.paymentMin
                    ? parseFloat(element.dataset.paymentMin)
                    : 0,
                paymentMaxAmount = element.dataset.paymentMax
                    ? parseFloat(element.dataset.paymentMax)
                    : 0;

            value = parseFloat(value.replace(/[^\d.-]/g, ''));

            return (
                (paymentMinAmount <= 0 || value >= paymentMinAmount) &&
                (paymentMaxAmount <= 0 || value <= paymentMaxAmount)
            );
        });

        // Installment amount limit
        $.validator.addMethod(
            'installmentLimit',
            function (value, element) {
                return parseFloat(element.dataset.installmentAmount) / parseFloat(value) >= 5.0;
            },
            'Error: Installment amount has to be higher than $5 per month.'
        );

        // Letters and Spaces only
        $.validator.addMethod(
            'lettersSpacesOnly',
            function (value) {
                return /^[a-zA-Z ]*$/.test(value);
            },
            'Error: Please enter only letters.'
        );

        $.validator.addMethod(
            'addressInput',
            function (value, element) {
                return this.optional(element) || /^[a-z\d\-\s]+$/i.test(value);
            },
            'Error: Please enter only numbers, letters, spaces or dashes.'
        );

        // DATES CUSTOM VALIDATION METHODS
        // End date limit
        $.validator.addMethod(
            'endDate',
            function (value, element, params) {
                if (!/Invalid|NaN/.test(new Date(value))) {
                    return new Date(value) >= new Date($(params).val());
                }

                return (
                    (isNaN(value) && isNaN($(params).val())) ||
                    Number(value) > Number($(params).val())
                );
            },
            'Error: End date must be greater than start date.'
        );

        // Days range validation
        $.validator.addMethod(
            'limitDays',
            function (value, element, params) {
                var oneDay = 24 * 60 * 60 * 1000,
                    startDate = new Date($(params).val()),
                    endDate = new Date(value),
                    diffDays = Math.round(
                        Math.abs((startDate.getTime() - endDate.getTime()) / oneDay)
                    );

                if (diffDays < 30) {
                    return diffDays < 30;
                }
            },
            'Error: Date range cannot be greater than 30 days.'
        );

        // pay bill range validation
        $.validator.addMethod(
            'payLimitDays',
            function (value) {
                var startDate = new Date(),
                    payDate = new Date(value),
                    timeDiff = Math.abs(startDate.getTime() - payDate.getTime()),
                    diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

                if (diffDays <= 5) {
                    return diffDays <= 5;
                }
            },
            'Error: Date range cannot be greater than 5 days.'
        );

        // Beyond tomorrow
        $.validator.addMethod(
            'tomorrowLimit',
            function (value) {
                var today = new Date(),
                    dateLimit =
                        '' +
                        (today.getMonth() + 1) +
                        '/' +
                        (today.getDate() + 2) +
                        '/' +
                        today.getFullYear();

                if (value < dateLimit) {
                    return value;
                }
            },
            'Error: Real Time Prices are unavailable beyond tomorrow date'
        );

        // Future date validation
        $.validator.addMethod('futureDate', function (value, element) {
            var now = new Date(),
                myDate = new Date(value);

            return this.optional(element) || myDate > now;
        });

        // From today date validation
        $.validator.addMethod('todayDate', function (value, element) {
            var now = new Date(),
                today = now.setDate(now.getDate() - 1),
                myDate = new Date(value);

            myDate = myDate.setDate(myDate.getDate());

            return this.optional(element) || myDate > today;
        });

        // Past date validation
        $.validator.addMethod('pastDate', function (value, element) {
            var now = new Date(),
                today = now.setDate(now.getDate() - 1),
                myDate = new Date(value);

            myDate = myDate.setDate(myDate.getDate());

            return this.optional(element) || myDate < today;
        });

        // Transactional start date
        $.validator.addMethod(
            'transactionalStartDate',
            function (value, element, params) {
                if (!/Invalid|NaN/.test(new Date(value))) {
                    return new Date(value) <= new Date($(params).val());
                }

                return (
                    (isNaN(value) && isNaN($(params).val())) ||
                    Number(value) > Number($(params).val())
                );
            },
            'Error: The service start date must be before or the same day the service end date.'
        );

        // Days range validation
        $.validator.addMethod(
            'transferLimitDays',
            function (value, element, params) {
                var oneDay = 24 * 60 * 60 * 1000,
                    startDate = new Date($(params).val()),
                    endDate = new Date(value),
                    diffDays = Math.round(
                        Math.abs((startDate.getTime() - endDate.getTime()) / oneDay)
                    );

                return diffDays < 30;
            },
            'Error: Dates within 30 calendar days'
        );

        // Start service date format
        $.validator.addMethod(
            'dateFormat',
            function (value) {
                var date = Date.parse(value),
                    dateComponents = value.split('/'),
                    dateMonth = parseInt(dateComponents[0], 10),
                    dateDay = parseInt(dateComponents[1], 10),
                    dateYear = parseInt(dateComponents[2], 10),
                    validFormat,
                    validDate;

                if (isNaN(date)) {
                    return false;
                }

                if (dateComponents.length !== 3) {
                    return false;
                }

                // Validating date is correct in terms of days within the month of the selected year
                date = new Date(dateYear, dateMonth - 1, dateDay);
                validDate =
                    date.getFullYear() == dateYear &&
                    date.getMonth() + 1 == dateMonth &&
                    date.getDate() == dateDay;
                // Validating the format of the date
                validFormat = value.match(/^\d\d?\/\d\d?\/\d\d\d\d$/);

                return validDate && validFormat;
            },
            'Error: Please enter a valid date in the format mm/dd/yyyy'
        );

        // Due bill date limit
        $.validator.addMethod(
            'nextPaymentDue',
            function (value, element) {
                var extensionDays = element.dataset.extensionDays,
                    dueDay = element.dataset.paymentDay,
                    day = 24 * 60 * 60 * 1000,
                    startDate = dueDay !== '' ? new Date(dueDay) : new Date(),
                    endDate = new Date(value),
                    diffDays = Math.ceil((endDate.getTime() - startDate.getTime()) / day);

                return diffDays > 0 && diffDays <= extensionDays;
            },
            'Error: Date can’t be more than 10 days after bill is due'
        );

        // Services date limits validation
        $.validator.addMethod(
            'servicesLimitDates',
            function (value, element) {
                var minDate,
                    maxDate,
                    userValueDate = new Date(value).getTime();

                if (!element.dataset.minDateDays && !element.dataset.maxDateDays) {
                    (minDate = new Date().setHours(0, 0, 0, 0)), (maxDate = new Date());

                    maxDate.setDate(maxDate.getDate() + 5);
                    maxDate = maxDate.setHours(0, 0, 0, 0);
                } else {
                    (minDate = element.dataset.minDateDays
                        ? new Date(element.dataset.minDateDays).getTime()
                        : new Date().setHours(0, 0, 0, 0)),
                        (maxDate = element.dataset.maxDateDays
                            ? new Date(element.dataset.maxDateDays).getTime()
                            : new Date().setHours(0, 0, 0, 0));
                }

                return userValueDate >= minDate && userValueDate <= maxDate;
            },
            'Error: Dates range is invalid for this account'
        );

        // Letters and numbers only
        $.validator.addMethod(
            'lettersNumbersOnly',
            function (value, element) {
                return this.optional(element) || /^[0-9a-zA-Z]+$/i.test(value);
            },
            'Error: Please enter letters and numbers only.'
        );

        // Tax ID validation
        $.validator.addMethod(
            'taxId',
            function (value, element) {
                return this.optional(element) || /^[1-9]\d?-\d{7}$/i.test(value);
            },
            'Error: Please enter valid Tax ID number.'
        );

        // Birth date is 18 or over
        $.validator.addMethod(
            'over18',
            function (value) {
                var selectedDate = new Date(value),
                    birthDateDay = selectedDate.getDate(),
                    birthDateMonth = selectedDate.getMonth(),
                    birthDateYear = selectedDate.getFullYear(),
                    minimumDate = new Date(birthDateYear + 18, birthDateMonth, birthDateDay);

                return minimumDate <= new Date();
            },
            'Error: We’re sorry, you must be at least 18 years old to start service.'
        );

        // Birth date is 150 or under
        $.validator.addMethod(
            'under150',
            function (value) {
                var selectedDate = new Date(value),
                    birthDateDay = selectedDate.getDate(),
                    birthDateMonth = selectedDate.getMonth(),
                    birthDateYear = selectedDate.getFullYear(),
                    maximumDate = new Date(birthDateYear + 150, birthDateMonth, birthDateDay);

                return maximumDate >= new Date();
            },
            'Error: We’re sorry, you must be under a 150 years old to start service.'
        );

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FormValidationOptOutModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FormValidationOptOutModule;
})();
