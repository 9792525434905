// ==================== RESET PASSWORD COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ResetPassword = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_CLASS_SELECTOR: '.js-reset-form',
        SELECT_WIDTH_DROPDOWN: 'js-coned-select',
        EMAIL_SELECTOR_CONTAINER: 'js-email-selector',
        PHONE_SELECTOR_CONTAINER: 'js-phone-selector',
        SMS_MESSAGE_CONTIANER: 'js-message-selector',
        DISPLAY_CLASS: 'transactional--finish',
        SUBMIT_BUTTON_SELECTOR: 'js-transactional-submit-selector',
        INPUT_CLASS_SELECTOR: 'js-coned-input',
        FORM_IGNORE_VALIDATION: 'js-validation-ignore',
        FINISH_STEP_SELECTOR: 'js-finish-step-selector',
        FINISH_STEP_CLASS: 'transactional--finish',
        FINISH_MESSAGE_SELECTOR: 'js-sucess-receptor',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        FINISH_MESSAGE_CONTAINER: 'js-data-message',
        FIRST_STEP_FORM: 'js-first-step-form',
        CODE_VERIFICATION_FORM: 'js-code-verification-code',
        RESET_PASSWORD_FORM: 'js-reset-password',
        RESET_PASSWORD_SELECTOR: 'js-reset-password-selector',
        REVERT_RESET_SELECTOR: 'js-revert-reset-selector',
        SECURITY_QUESTION_CONTAINER: 'js-security-container',
        SECURITY_QUESTION: 'js-security-question',
        SECURITY_ANSWER: 'js-security-answer',
        RECAPTCHA_ELEMENT: 'js-recaptcha',
        RESET_ERROR: 'js-reset-error',
        RESET_ERROR_MESSAGE: 'js-error-message',
        RESET_DESCRIPTION: 'js-reset-description',
        HIDDEN_CLASS: 'hidden',
        SMS_OPTION: 'sms',
        EMAIL_OPTION: 'email',
        RESET_QUESTION_INPUT: 'resetSecurityQuestion',
        RESET_EMAIL_INPUT: 'resetEmail',
        RESET_VERIFICATION_CODE_INPUT: 'verificationCode',
        RESET_SITECORE_ID: 'ScId',
        RESET_STATE_TOKEN: 'StateToken',
        USER_NAME_SELECTOR: 'js-username-selector',
        FROM_URI_PARAMETER_NAME: 'fromURI'
    };

    var isLoaded = false;
    var smsActive = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ResetPassword = function ($resetForm) {
        /**
         * PRIVATE METHODS
         */
        var $selectWidthDropdown,
            $emailSelectorContainer,
            $smsMessageContainer,
            $submitButtonSelector,
            $emailInputSelector,
            $finishStepSelector,
            $finishMessageSelector,
            $submitAnimationButton,
            $finishMessageContainer,
            $firstStepForm,
            $codeVerificationForm,
            $resetError,
            $revertResetSelector,
            $resetDescription,
            $resetErrorMessage,
            $resetPasswordSelector,
            $stateTokenInput,
            $userNameSelector,
            recaptcha,
            _hasCaptcha,
            _successCallback,
            _data,
            _factorType;

        var revertReset = function(event) {
            event && event.preventDefault();

            var $verificationInput = $codeVerificationForm.getElementsByClassName(
                CONSTANTS.INPUT_CLASS_SELECTOR
            )[0];

            smsActive = false;
            $firstStepForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $codeVerificationForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $verificationInput.classList.add(CONSTANTS.FORM_IGNORE_VALIDATION);

            if (recaptcha && _hasCaptcha) {
                recaptcha.reset();
                _hasCaptcha.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var enableInput = function () {
            if ($selectWidthDropdown.value == 'sms') {
                query.removeClass($smsMessageContainer, CONSTANTS.HIDDEN_CLASS);
            } else {
                query.addClass($smsMessageContainer, CONSTANTS.HIDDEN_CLASS);
            }
            $submitButtonSelector.disabled = false;
        };

        var resetPasswordCall = function () {
            var params,
                callFunction,
                initiateResetService = $resetForm.dataset.initiateService,
                smsVerificationService = $resetForm.dataset.smsService,
                resetSelectedFactor = $selectWidthDropdown.value,
                fromUri = coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    : '';

            // rest with sms
            if (smsActive) {
                params = {
                    email: query.getFormInputValue($resetForm, CONSTANTS.RESET_EMAIL_INPUT),
                    PassCode: query.getFormInputValue(
                        $resetForm,
                        CONSTANTS.RESET_VERIFICATION_CODE_INPUT
                    ),
                    ScId: query.getFormInputValue(document, CONSTANTS.RESET_SITECORE_ID),
                    FactorType: _factorType,
                    StateToken: query.getFormInputValue(document, CONSTANTS.RESET_STATE_TOKEN),
                    FromUri: fromUri
                };

                callFunction = smsVerificationService;
            } else {
                params = {
                    email: query.getFormInputValue($resetForm, CONSTANTS.RESET_EMAIL_INPUT),
                    unlockWithFactor: resetSelectedFactor,
                    ScId: query.getFormInputValue(document, CONSTANTS.RESET_SITECORE_ID),
                    FromUri: fromUri
                };

                callFunction = initiateResetService;

                if (_hasCaptcha && recaptcha) {
                    params.Verify = recaptcha.getResponse();
                }
            }

            params = JSON.stringify(params);
            query.postData(callFunction, successEventCallback, errorEventCallback, params, true);
        };

        var successEventCallback = function (data) {
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var errorEventCallback = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
            
            if (recaptcha && _hasCaptcha) {
                recaptcha.reset();
            }
        };

        var resetValidate = function () {
            hideError();

            if (coned.utils.isPatternLab()) {
                var resetValidateService = coned.plConstants.INITIATE_FORGET_PASSWORD;

                query.getData(resetValidateService, initiateUnlockAccount, function () {});
            } else {
                initiateUnlockAccount(_data);
            }
        };

        var initiateUnlockAccount = function (data) {
            if (data && data.success) {
                $resetDescription.classList.add(CONSTANTS.HIDDEN_CLASS);
                smsActive = true;

                if ($selectWidthDropdown.value == CONSTANTS.EMAIL_OPTION) {
                    showFinishStep();
                } else {
                    var $verificationInput = $codeVerificationForm.getElementsByClassName(
                        CONSTANTS.INPUT_CLASS_SELECTOR
                    )[0];

                    $firstStepForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $codeVerificationForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $verificationInput.classList.remove(CONSTANTS.FORM_IGNORE_VALIDATION);
                    $stateTokenInput.value = data.stateToken;
                    _hasCaptcha.classList.add(CONSTANTS.HIDDEN_CLASS);
                    _factorType = data.factorType;
                }
            } else {
                $resetError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $resetErrorMessage.innerHTML = data.errorMsg;
            }
        };

        /**
         *  Check Recaptcha
         **/
        var checkRecaptcha = function () {
            if (smsActive) {
                resetPasswordSubmit();
            } else if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                recaptcha.checkRecaptcha();
            }
        };

        var validateVerificationCode = function () {
            hideError();

            if (coned.utils.isPatternLab()) {
                var getVerificationSerice = coned.plConstants.GET_SMS_VERIFICATION;

                query.getData(getVerificationSerice, verificationCode, function () {});
            } else {
                verificationCode(_data);
            }
        };

        var verificationCode = function (data) {
            if (data && data.stateToken) {
                $resetError.classList.add(CONSTANTS.HIDDEN_CLASS);
                $resetForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $codeVerificationForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $resetPasswordSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $stateTokenInput.value = data.stateToken;
                $userNameSelector.dataset.username = data.Username;
            } else {
                $codeVerificationForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $resetError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $resetErrorMessage.innerHTML = data.errorMsg;
            }
        };

        var errorCallback = function () {
            $resetError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $resetErrorMessage.innerHTML = _data.errorMsg;
            $resetError.focus();
        };

        var hideError = function () {
            $resetError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $resetErrorMessage.innerHTML = '';
        };

        var showFinishStep = function () {
            var selectMessage;

            if ($selectWidthDropdown.value == CONSTANTS.SMS_OPTION) {
                var typeSMSMessage = $finishMessageContainer.dataset.sms;

                selectMessage = typeSMSMessage;
                $finishMessageSelector.innerHTML = selectMessage;
            } else {
                var typeEmailMessage = $finishMessageContainer.dataset.email;

                selectMessage = typeEmailMessage;
                selectMessage = selectMessage.replace(
                    '@DATA',
                    '<b>' + $emailInputSelector.value + '</b>'
                );
            }

            $resetForm.style.display = 'none';
            $finishStepSelector.classList.remove(CONSTANTS.FINISH_STEP_CLASS);
            $finishMessageSelector.innerHTML = selectMessage;
        };

        var resetPasswordSubmit = function () {
            if (smsActive) {
                _successCallback = validateVerificationCode;
            } else {
                _successCallback = resetValidate;
            }

            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                resetPasswordCall,
                _successCallback,
                errorCallback,
                false,
                true
            );
        };

        var initializeData = function () {
            $selectWidthDropdown = $resetForm.getElementsByClassName(
                CONSTANTS.SELECT_WIDTH_DROPDOWN
            )[0];
            $emailSelectorContainer = $resetForm.getElementsByClassName(
                CONSTANTS.EMAIL_SELECTOR_CONTAINER
            )[0];
            $smsMessageContainer = $resetForm.getElementsByClassName(
                CONSTANTS.SMS_MESSAGE_CONTIANER
            )[0];
            $submitButtonSelector = $resetForm.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON_SELECTOR
            )[0];
            $emailInputSelector = $emailSelectorContainer.getElementsByClassName(
                CONSTANTS.INPUT_CLASS_SELECTOR
            )[0];
            $finishStepSelector = document.getElementsByClassName(
                CONSTANTS.FINISH_STEP_SELECTOR
            )[0];
            $finishMessageSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.FINISH_MESSAGE_SELECTOR
            )[0];
            $submitAnimationButton = $resetForm.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            $finishMessageContainer = $resetForm.getElementsByClassName(
                CONSTANTS.FINISH_MESSAGE_CONTAINER
            )[0];
            $firstStepForm = $resetForm.getElementsByClassName(CONSTANTS.FIRST_STEP_FORM)[0];
            $codeVerificationForm = $resetForm.getElementsByClassName(
                CONSTANTS.CODE_VERIFICATION_FORM
            )[0];
            $revertResetSelector = $resetForm.getElementsByClassName(
                CONSTANTS.REVERT_RESET_SELECTOR
            )[0];
            $resetError = $resetForm.getElementsByClassName(CONSTANTS.RESET_ERROR)[0];
            $resetErrorMessage = $resetForm.getElementsByClassName(
                CONSTANTS.RESET_ERROR_MESSAGE
            )[0];
            $resetDescription = $resetForm.getElementsByClassName(CONSTANTS.RESET_DESCRIPTION)[0];
            $resetPasswordSelector = document.getElementsByClassName(
                CONSTANTS.RESET_PASSWORD_SELECTOR
            )[0];
            $stateTokenInput = document.getElementsByName(CONSTANTS.RESET_STATE_TOKEN)[0];
            $userNameSelector = document.getElementsByClassName(CONSTANTS.USER_NAME_SELECTOR)[0];

            recaptcha = false;
            _hasCaptcha = $resetForm.getElementsByClassName(CONSTANTS.RECAPTCHA_ELEMENT)[0];
        };

        var initializeEvents = function () {
            $selectWidthDropdown.addEventListener('change', enableInput);

            coned.utils.addGeneralListeners($revertResetSelector, revertReset);

            // form validation
            recaptcha = new coned.components.Recaptcha(
                $resetForm,
                resetPasswordSubmit,
                resetPasswordSubmit
            );
            new coned.components.ValidateForm(
                CONSTANTS.FORM_CLASS_SELECTOR,
                checkRecaptcha,
                '.' + CONSTANTS.FORM_IGNORE_VALIDATION
            );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ResetPassword.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ResetPassword;
})();
