// ==================== MANAGE THIRD PARTY COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ManageThirdParty = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Popup Constants
        CONFIRMATION_POPUP: 'js-manage-third-party-confirmation-popup',
        CONFIRMATION_POPUP_SUBMIT: 'js-manage-third-party-confirmation-submit',
        SUCCESS_POPUP: 'js-manage-third-party-success-popup',
        ERROR_POPUP: 'js-manage-third-party-error-popup',
        POPUP_TITLE: 'js-popup-title',
        POPUP_DESCRIPTION: 'js-popup-description',
        POPUP_ENTITY: 'js-manage-third-party-popup-entity',
        POPUP_BUTTON: 'js-popup-button',
        POPUP_CLOSE: 'js-popup-close',

        // Access Constants
        MODULE_SELECTOR: 'js-manage-third-party',
        ACCESS_CONTENT: 'js-manage-third-party-content',
        ACCESS_ITEM: 'js-manage-third-party-table-item',
        CTA_CONTAINER: 'js-manage-third-party-cta-container',
        ITEM_DATE: 'js-manage-third-party-date',
        ALLOW_ACCESS_LINK: 'js-manage-allow-access',
        DENY_ACCESS_LINK: 'js-manage-deny-access',
        REMOVE_ACCESS_LINK: 'js-manage-remove-access',

        // Link Creation Constants
        LINK_TAG: 'a',
        LINK_CLASSES: ['manage-third-party__cta', 'js-manage-remove-access'],
        LINK_HREF: '#',

        // General Constants
        FORM_LOADING: 'js-form-loading',
        DIV_LOADING: 'js-div-loading',
        SITECORE_ID: 'ScId',
        VERIFICATION_TOKEN: 'Token',
        HIDDEN_CLASS: 'hidden',
        ALLOW_VALUE: 'allow',
        DENY_VALUE: 'deny',
        REMOVE_VALUE: 'remove',
        EDIT_BUTTON: 'js-edit-button',

        // Lazy Load
        LAZY_LOAD_CLASS: 'js-lazy-load',
        LAZY_LOAD_TAB_CLASS: 'js-lazy-load-manage-my-account',
        LAZY_LOAD_LOADED_CLASS: 'js-lazy-load-element-loaded',

        // Tagging Constants
        TAGGING_PREFIX: 'Pilot.Reg.3P.',
        TAGGING_ALLOW_ACCESS: 'Acct.Manage.Account.Allow',
        TAGGING_DENY_ACCESS: 'Acct.Manage.Account.Deny',
        TAGGING_REMOVE_ACCESS: 'Acct.Manage.Account.Remove',
        TAGGING_REMOVE_CONFIRM: 'Acct.Manage.Account.Remove.Confirm'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var ManageThirdParty = function ($manageThirdParty) {
        /**
         * PRIVATE VARIABLES
         */

        var // Popup Variables
            $confirmationPopup,
            $confirmationPopupSubmit,
            $successPopup,
            $successPopupTitle,
            $successPopupDescription,
            $successPopupButtons,
            $errorPopup,
            $errorPopupTitle,
            $errorPopupDescription,
            $closePopup,
            $successPopupClose,
            // Access Variables
            $accessItems,
            $accessContent,
            $allowAccessLink,
            $denyAccessLink,
            $removeAccessLink,
            // Lazy Load Variables
            $lazyLoadTab,
            _isLazyLoad,
            // General Variables
            $formLoading,
            $divLoading,
            $editButton,
            $selectedItem,
            _selectedButton,
            _selectedItem,
            _selectedNextItem,
            _buttonType;

        /**
         * Loads all module data from service.
         */
        var loadDataServiceCall = function () {
            if (isLoaded) return;

            var serviceUrl = $manageThirdParty.dataset.serviceUrl,
                params;

            if (!serviceUrl) {
                errorLoadDataServiceCall();
                return;
            }

            if ($divLoading.length) {
                $divLoading[0].classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            query.getData(serviceUrl, successLoadDataServiceCall, errorLoadDataServiceCall, params);
        };

        /**
         * If call succeeded, load markup into module.
         */
        var successLoadDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                setTimeout(function () {
                    query.getData(
                        coned.plConstants.GET_MANAGE_ACCESS,
                        setContentMarkup,
                        errorLoadDataServiceCall
                    );
                }, 5000);
            } else {
                setContentMarkup(data);
            }
        };

        /**
         * If call errored, hide module.
         */
        var errorLoadDataServiceCall = function () {
            isLoaded = true;

            $manageThirdParty.classList.add(CONSTANTS.HIDDEN_CLASS);

            if ($divLoading.length) {
                $divLoading[0].classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $manageThirdParty.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);
            }
        };

        /**
         * Set markup to the tab content.
         */
        var setContentMarkup = function (data) {
            if (data) {
                var parser = new DOMParser(),
                    HTMLObject,
                    $manageThirdPartyContent,
                    newManageThirdPartyAttrs;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $manageThirdPartyContent = HTMLObject.getElementsByClassName(
                    CONSTANTS.MODULE_SELECTOR
                );

                if ($manageThirdPartyContent.length !== 0) {
                    $manageThirdPartyContent = $manageThirdPartyContent[0];
                } else {
                    errorLoadDataServiceCall();
                    return;
                }

                $manageThirdParty.innerHTML = $manageThirdPartyContent.innerHTML;
                newManageThirdPartyAttrs = $manageThirdPartyContent.attributes;

                while ($manageThirdParty.attributes && $manageThirdParty.attributes.length > 0) {
                    $manageThirdParty.removeAttribute($manageThirdParty.attributes[0].name);
                }

                _.each(newManageThirdPartyAttrs, function (attribute) {
                    $manageThirdParty.setAttribute(attribute.nodeName, attribute.nodeValue);
                });

                // Initialize modules
                initializeData();
                initializeEvents();
                coned.utils.initializeModules($manageThirdParty);

                $manageThirdParty.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $divLoading = $manageThirdParty.getElementsByClassName(CONSTANTS.DIV_LOADING);
                if ($divLoading.length) {
                    $divLoading[0].classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                errorLoadDataServiceCall();
                return;
            }

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $manageThirdParty.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);
            }
        };

        /**
         * Handles the grant/deny access service call.
         */
        var accessSubmit = function (event) {
            if (event) {
                event.preventDefault();
            }

            var $target = event.target,
                value = $target.dataset.value;

            $selectedItem = query.selectParentElement($target, CONSTANTS.ACCESS_ITEM);

            _selectedButton = $target;
            _selectedItem = $selectedItem;
            _selectedNextItem = _selectedItem.nextElementSibling;

            if (value === CONSTANTS.REMOVE_VALUE) {
                var $popupEntity = $confirmationPopup.getElementsByClassName(
                    CONSTANTS.POPUP_ENTITY
                );

                _buttonType = CONSTANTS.REMOVE_VALUE;

                _.each($popupEntity, function ($entity) {
                    $entity.innerHTML = $selectedItem.dataset.entityName;
                });

                $confirmationPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $closePopup.focus();

                // Tagging
                dataLayer.push({
                    event: CONSTANTS.TAGGING_PREFIX + CONSTANTS.TAGGING_REMOVE_ACCESS
                });
            } else {
                var _allowAccess = value === CONSTANTS.ALLOW_VALUE ? true : false,
                    serviceUrl = $accessContent.dataset.accessServiceUrl,
                    params;

                $successPopup.dataset.access = value;

                params = {
                    ScId: query.getFormInputValue($manageThirdParty, CONSTANTS.SITECORE_ID),
                    VerificationToken: query.getFormInputValue(
                        $selectedItem,
                        CONSTANTS.VERIFICATION_TOKEN
                    ),
                    RequestId: $selectedItem.dataset.requestId,
                    AllowAccess: _allowAccess
                };

                params = JSON.stringify(params);
                query.postData(
                    serviceUrl,
                    successAccessSubmit,
                    errorAccessSubmit,
                    params,
                    true,
                    $formLoading
                );
            }
        };

        /**
         * Handles the grant/deny access success.
         */
        var successAccessSubmit = function () {
            if ($successPopup.dataset.access === CONSTANTS.ALLOW_VALUE) {
                var $newCta,
                    $ctaContainer = $selectedItem.getElementsByClassName(
                        CONSTANTS.CTA_CONTAINER
                    )[0],
                    $itemDate = $selectedItem.getElementsByClassName(CONSTANTS.ITEM_DATE)[0];

                _buttonType = CONSTANTS.ALLOW_VALUE;

                $successPopupTitle.innerHTML = $successPopup.dataset.allowTitle;
                $successPopupDescription.innerHTML = $successPopup.dataset.allowDescription;

                $itemDate.innerHTML = $manageThirdParty.dataset.acceptedCopy;

                $newCta = document.createElement(CONSTANTS.LINK_TAG);
                _.each(CONSTANTS.LINK_CLASSES, function (className) {
                    $newCta.classList.add(className);
                });
                $newCta.href = CONSTANTS.LINK_HREF;
                $newCta.dataset.value = CONSTANTS.REMOVE_VALUE;
                $newCta.innerHTML = $manageThirdParty.dataset.acceptedLinkCopy;

                $ctaContainer.innerHTML = '';
                $ctaContainer.appendChild($newCta);

                coned.utils.addGeneralListeners($newCta, accessSubmit);

                // Tagging
                dataLayer.push({
                    event: CONSTANTS.TAGGING_PREFIX + CONSTANTS.TAGGING_ALLOW_ACCESS
                });
            } else if ($successPopup.dataset.access === CONSTANTS.DENY_VALUE) {
                _buttonType = CONSTANTS.DENY_VALUE;

                $successPopupTitle.innerHTML = $successPopup.dataset.denyTitle;
                $successPopupDescription.innerHTML = $successPopup.dataset.denyDescription;

                $selectedItem.parentNode.removeChild($selectedItem);

                // Tagging
                dataLayer.push({
                    event: CONSTANTS.TAGGING_PREFIX + CONSTANTS.TAGGING_DENY_ACCESS
                });
            }

            var $popupEntity = $successPopup.getElementsByClassName(CONSTANTS.POPUP_ENTITY);

            _.each($popupEntity, function ($entity) {
                $entity.innerHTML = $selectedItem.dataset.entityName;
            });

            $selectedItem = null;

            $successPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $successPopupClose.focus();
        };

        /**
         * Handles the grant/deny access error.
         */
        var errorAccessSubmit = function (data) {
            if (data) {
                if (data.errorTitle) {
                    $errorPopupTitle.innerHTML = data.errorTitle;
                } else {
                    $errorPopupTitle.innerHTML = $errorPopup.dataset.defaultTitle;
                }

                if (data.errorMsg) {
                    $errorPopupDescription.innerHTML = data.errorMsg;
                } else {
                    $errorPopupDescription.innerHTML = $errorPopup.dataset.defaultDescription;
                }
            } else {
                $errorPopupTitle.innerHTML = $errorPopup.dataset.defaultTitle;
                $errorPopupDescription.innerHTML = $errorPopup.dataset.defaultDescription;
            }

            $errorPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Handles the remove access service call.
         */
        var removeAccessSubmit = function (event) {
            if (event) {
                event.preventDefault();
            }

            var entityId = $selectedItem.dataset.entityId,
                serviceUrl = $accessContent.dataset.removeServiceUrl,
                params;

            params = {
                ScId: query.getFormInputValue($manageThirdParty, CONSTANTS.SITECORE_ID),
                VerificationToken: query.getFormInputValue(
                    $selectedItem,
                    CONSTANTS.VERIFICATION_TOKEN
                ),
                RequestId: entityId
            };

            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successRevomeAccessSubmit,
                errorRevomeAccessSubmit,
                params,
                true,
                $formLoading
            );
        };

        /**
         * Handles the remove access success.
         */
        var successRevomeAccessSubmit = function () {
            $confirmationPopup.classList.add(CONSTANTS.HIDDEN_CLASS);

            $successPopupTitle.innerHTML = $successPopup.dataset.removeTitle;
            $successPopupDescription.innerHTML = $successPopup.dataset.removeDescription;

            var $popupEntity = $successPopup.getElementsByClassName(CONSTANTS.POPUP_ENTITY);

            _.each($popupEntity, function ($entity) {
                $entity.innerHTML = $selectedItem.dataset.entityName;
            });

            $selectedItem.parentNode.removeChild($selectedItem);
            $selectedItem = null;

            $successPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $successPopupClose.focus();

            // Tagging
            dataLayer.push({
                event: CONSTANTS.TAGGING_PREFIX + CONSTANTS.TAGGING_REMOVE_CONFIRM
            });
        };

        /**
         * Handles the remove access error.
         */
        var errorRevomeAccessSubmit = function (data) {
            $confirmationPopup.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (data) {
                if (data.errorTitle) {
                    $errorPopupTitle.innerHTML = data.errorTitle;
                } else {
                    $errorPopupTitle.innerHTML = $errorPopup.dataset.defaultTitle;
                }

                if (data.errorMsg) {
                    $errorPopupDescription.innerHTML = data.errorMsg;
                } else {
                    $errorPopupDescription.innerHTML = $errorPopup.dataset.defaultDescription;
                }
            } else {
                $errorPopupTitle.innerHTML = $errorPopup.dataset.defaultTitle;
                $errorPopupDescription.innerHTML = $errorPopup.dataset.defaultDescription;
            }

            $errorPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Return focus to the remove button that the popup was called from.
         */
        var returnFocus = function () {
            setTimeout(function () {
                _selectedButton.focus();
            }, 100);
        }

        /**
         * Depending on the case, puts the focus on the remove button or on the next element.
         */
        var focusElement = function () {
            setTimeout(function () {
                if (_buttonType === CONSTANTS.REMOVE_VALUE || _buttonType === CONSTANTS.DENY_VALUE){
                    if (_selectedNextItem) {
                        var focusItem = _selectedNextItem.querySelector(coned.constants.FORM_INPUTS_QUERY);
                        focusItem.focus();
                    } else {
                        $editButton.focus();
                    }
                } 

                if (_buttonType === CONSTANTS.ALLOW_VALUE) {
                    var focusRemove = _selectedItem.getElementsByClassName(CONSTANTS.REMOVE_ACCESS_LINK)[0];
                    focusRemove.focus();
                }
            }, 100);
        }

        /**
         * Close popup with keyboard: ESC key_code : 27
         * @param {*} event
         * @param {*} popup
        */
         var setFocus = function (event, popup) {
            if (event.keyCode == coned.constants.KEY_CODE.ESC) {
                //Check if a popup is visible on the screen.
                var confirmationPopup = query.hasClass(popup, CONSTANTS.CONFIRMATION_POPUP);
                if (confirmationPopup) {
                    returnFocus();
                } else {
                    focusElement();
                }
            }
        };

        /**
         * Checks if module should be lazy-loaded of not
         */
        var preInitialize = function () {
            _isLazyLoad = query.hasClass($manageThirdParty, CONSTANTS.LAZY_LOAD_CLASS);
            $divLoading = $manageThirdParty.getElementsByClassName(CONSTANTS.DIV_LOADING);

            // If module is lazy loaded, wait for event to trigger
            if (_isLazyLoad) {
                $lazyLoadTab = document.getElementsByClassName(CONSTANTS.LAZY_LOAD_TAB_CLASS)[0];

                $lazyLoadTab.addEventListener('lazy-load-start', loadDataServiceCall);
            } else {
                loadDataServiceCall();
            }
        };

        /**
         * Inits necessary variables in the module.
         */
        var initializeData = function () {
            // Popup
            $confirmationPopup = document.getElementsByClassName(CONSTANTS.CONFIRMATION_POPUP)[0];
            $confirmationPopupSubmit = $confirmationPopup.getElementsByClassName(
                CONSTANTS.CONFIRMATION_POPUP_SUBMIT
            )[0];
            $successPopup = document.getElementsByClassName(CONSTANTS.SUCCESS_POPUP)[0];
            $successPopupTitle = $successPopup.getElementsByClassName(CONSTANTS.POPUP_TITLE)[0];
            $successPopupDescription = $successPopup.getElementsByClassName(
                CONSTANTS.POPUP_DESCRIPTION
            )[0];
            $successPopupButtons = $successPopup.getElementsByClassName(CONSTANTS.POPUP_BUTTON);
            $errorPopup = document.getElementsByClassName(CONSTANTS.ERROR_POPUP)[0];
            $errorPopupTitle = $errorPopup.getElementsByClassName(CONSTANTS.POPUP_TITLE)[0];
            $errorPopupDescription = $errorPopup.getElementsByClassName(
                CONSTANTS.POPUP_DESCRIPTION
            )[0];
            $closePopup = $confirmationPopup.getElementsByClassName(CONSTANTS.POPUP_CLOSE)[0];
            $successPopupClose = $successPopup.getElementsByClassName(CONSTANTS.POPUP_CLOSE)[0];

            // Access
            $accessContent = $manageThirdParty.getElementsByClassName(CONSTANTS.ACCESS_CONTENT)[0];
            $accessItems = $manageThirdParty.getElementsByClassName(CONSTANTS.ACCESS_ITEM);
            $allowAccessLink = $manageThirdParty.getElementsByClassName(
                CONSTANTS.ALLOW_ACCESS_LINK
            );
            $denyAccessLink = $manageThirdParty.getElementsByClassName(CONSTANTS.DENY_ACCESS_LINK);
            $removeAccessLink = $manageThirdParty.getElementsByClassName(
                CONSTANTS.REMOVE_ACCESS_LINK
            );

            // General
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $editButton = document.getElementsByClassName(CONSTANTS.EDIT_BUTTON)[0];
        };

        /**
         * Inits necessary events in the module.
         */
        var initializeEvents = function () {
            if (!$accessItems.length) {
                $manageThirdParty.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            coned.utils.addGeneralListeners($confirmationPopupSubmit, removeAccessSubmit);

            if ($allowAccessLink.length) {
                _.each($allowAccessLink, function ($link) {
                    coned.utils.addGeneralListeners($link, accessSubmit);
                });
            }

            if ($denyAccessLink.length) {
                _.each($denyAccessLink, function ($link) {
                    coned.utils.addGeneralListeners($link, accessSubmit);
                });
            }

            if ($removeAccessLink.length) {
                _.each($removeAccessLink, function ($link) {
                    coned.utils.addGeneralListeners($link, accessSubmit);
                });
            }

            if ($successPopupButtons.length) {
                _.each($successPopupButtons, function ($button) {
                    coned.utils.addGeneralListeners($button, function () {
                        if (!$accessItems.length) {
                            $manageThirdParty.classList.add(CONSTANTS.HIDDEN_CLASS);
                        }
                    });
                });
            }

            $confirmationPopup.addEventListener('keydown', function (event) {
                setFocus(event, $confirmationPopup);
            });

            $successPopup.addEventListener('keydown', function (event) {
                setFocus(event, $successPopup);
            });
            
            coned.utils.addGeneralListeners($closePopup, returnFocus);

            coned.utils.addGeneralListeners($successPopupClose, focusElement);

        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            preInitialize();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ManageThirdParty.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ManageThirdParty;
})();
