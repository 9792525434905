// ==================== DASHBOARD CONCERN COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardPaymentAssistance = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ACCOUNT_MAID: 'account-maid',
        ENROLLED_FORM_SELECTOR: 'js-payment-assistance-enrolled',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        LINK_DESCRIPTION: 'js-link-description',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        ASSISTANCE_PROGRAM_ID: 'assistanceProgramsSection',
        DATA_ENROLLED: 'data-enrolled',
        ICON_STATUS_TEXT: 'icon-status-text',
        HIDDEN_CLASS: 'hidden',
        FORM_SELECTOR: 'js-payment-assistance-form',
       
        // Lazy Load
        LAZY_LOAD_CLASS: 'js-lazy-load',
        LAZY_LOAD_TAB_CLASS: 'js-lazy-load-manage-my-account',
        LAZY_LOAD_LOADED_CLASS: 'js-lazy-load-element-loaded'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardPaymentAssistance = function ($accordionElement) {
        /**
         * PRIVATE METHODS
         */
        var $linkAccordionSelector,
            $accordionSelector,
            $lazyLoadTab,
            $iconStatusTextSpan,
            _isLazyLoad;  

        var initializeData = function () {
            $linkAccordionSelector = $accordionElement.getElementsByClassName(
                CONSTANTS.LINK_ACCORDION_SELECTOR
            )[0];
            $accordionSelector = $accordionElement.getElementsByClassName(
                CONSTANTS.ACCORDION_SELECTOR
            )[0];
            $iconStatusTextSpan = $accordionElement.getElementsByClassName(
                CONSTANTS.ICON_STATUS_TEXT
            )[0];
        };

        var initializeEvents = function () {
            if (!query.hasClass($accordionElement, CONSTANTS.LAZY_LOAD_CLASS)) {
                $accordionElement.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_ENROLLED
                );
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.manage;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
            }
        };

        // Loads all module data from service
        var loadDataServiceCall = function () {
            if (isLoaded) return;

            var serviceUrl = $accordionElement.dataset.serviceUrl;

            if (!serviceUrl) {
                errorLoadDataServiceCall();
                return;
            }

            query.getData(serviceUrl, successLoadDataServiceCall, errorLoadDataServiceCall, '');
        };

        // If call succeeded, load markup into module
        var successLoadDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                switch ($accordionElement.dataset.scenario) {
                    case '0':
                        // SCENARIO #0: Payment Assistance enrolled form
                        query.getData(
                            coned.plConstants.GET_PAYMENT_ASSISTANCE_ENROLLED_SCENARIO_HTML,
                            setContentMarkup,
                            errorLoadDataServiceCall
                        );
                        break;
                }
            } else {
                setContentMarkup(data);
            }
        };

        // If call errored, hide module
        var errorLoadDataServiceCall = function () {
            $accordionElement.classList.add(CONSTANTS.HIDDEN_CLASS);

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $accordionElement.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($accordionElement, 'lazy-load-element-loaded');
            }
        };

        var setContentMarkup = function (data) {
            if (data) {
                var parser = new DOMParser(),
                    HTMLObject,
                    $paymentAssistanceFormContent,
                    newPaymentAssistanceAttributes;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $paymentAssistanceFormContent = HTMLObject.getElementsByClassName(CONSTANTS.FORM_SELECTOR);

                if (
                    $paymentAssistanceFormContent.length !== 0 &&
                    $paymentAssistanceFormContent[0].dataset.content !== 'false'
                ) {
                    $paymentAssistanceFormContent = $paymentAssistanceFormContent[0];
                } else {
                    errorLoadDataServiceCall();
                    return;
                }

                $accordionElement.innerHTML = $paymentAssistanceFormContent.innerHTML;
                newPaymentAssistanceAttributes = $paymentAssistanceFormContent.attributes;

                while ($accordionElement.attributes.length > 0) {
                    $accordionElement.removeAttribute($accordionElement.attributes[0].name);
                }

                _.each(newPaymentAssistanceAttributes, function (attribute) {
                    $accordionElement.setAttribute(attribute.nodeName, attribute.nodeValue);
                });

                // If data-module is present, initialize modules
                if ($accordionElement.dataset.module) {
                    initializeData();
                    initializeEvents();
                    coned.utils.initializeModules($accordionElement);
                }
            } else {
                errorLoadDataServiceCall();
                return;
            }

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $accordionElement.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($accordionElement, 'lazy-load-element-loaded');
            }

            coned.utils.triggerEvent(window, 'service-loaded');
        };

        // Checks if module should be lazy-loaded of not
        var preInitialize = function () {
            _isLazyLoad = query.hasClass($accordionElement, CONSTANTS.LAZY_LOAD_CLASS);

            // If module is lazy loaded, wait for event to trigger
            if (_isLazyLoad) {
                $lazyLoadTab = document.getElementsByClassName(CONSTANTS.LAZY_LOAD_TAB_CLASS)[0];

                $lazyLoadTab.addEventListener('lazy-load-start', loadDataServiceCall);
            } else {
                initializeData();
                initializeEvents();
                isLoaded = true;
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            preInitialize();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     DashboardPaymentAssistance.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardPaymentAssistance;
})();
