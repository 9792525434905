// ==================== CHECKBOX RECEIPTS COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CheckboxReceipt = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CHECKBOX_SELECTOR: 'js-checkbox-selector',
        CHECKBOX_CHECKED_CLASS: 'checkbox-receipt--disabled',
        CHECKBOX_INDICATOR: 'checkbox-receipt__indicator'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var CheckboxReceipt = function ($checkbox) {
        /**
         * PRIVATE METHODS
         */
        var $checkboxSelector;
        var $checkboxIndicator;

        var checkboxAnimation = function () {
            if ($checkboxSelector.checked) {
                query.removeClass($checkbox, CONSTANTS.CHECKBOX_CHECKED_CLASS);
            } else {
                query.addClass($checkbox, CONSTANTS.CHECKBOX_CHECKED_CLASS);
            }
        };

        var checkboxClicked = function (event) {
            event.preventDefault();

            $checkboxSelector.click();
        };

        var initializeData = function () {
            $checkboxSelector = $checkbox.getElementsByClassName(CONSTANTS.CHECKBOX_SELECTOR)[0];
            $checkboxIndicator = $checkbox.getElementsByClassName(CONSTANTS.CHECKBOX_INDICATOR)[0];
        };

        var initializeEvents = function () {
            $checkboxSelector.addEventListener('change', checkboxAnimation);
            coned.utils.addGeneralListeners($checkboxIndicator, checkboxClicked);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CheckboxReceipt.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CheckboxReceipt;
})();
