// ==================== FIND ACCOUNT BY ADDRESS COMPONENT =========================
/* global _ */
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FindAccountByAddress = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ZIP_CODE_SELECTOR: 'js-zipcode',
        ZIP_CODE_ERROR_SELECTOR: 'js-zipcode-service-error',
        STREET_NAME_ERROR_SELECTOR: 'js-street-name-service-error',
        STREET_NUMBER_ERROR_SELECTOR: 'js-street-number-service-error',
        SELECT_STREET_NAME: 'js-street-name',
        SELECT_STREET_NUMBER: 'js-street-number',
        SELECT_RESIDENCE_UNIT: 'js-residence-unit',
        SELECT_LABEL_SELECTOR: 'js-coned-select-label',
        ACTIVE_DROPDOWN: 'coned-select--active',
        FORM_ZIPCODE_NAME: 'zipCode',
        FORM_STREET_NAME_NAME: 'streetName',
        FORM_STREET_NUMBER_NAME: 'streetNumber',
        FORM_RESIDENCE_UNIT_NAME: 'residenceUnit',
        FORM_COMPANY_CODE_NAME: 'CompanyId',
        FORM_SCID_NAME: 'ScId',
        HIDDEN_CLASS: 'hidden',
        FORM_LOADING: 'js-form-loading',
        FORM_CURRENT_STEP: 'js-current-step',
        IS_FORM_BLOCKED: 'js-form-blocked',
        SERVICE_FORM: 'transactional__form',
        LOOKUP_BUTTON: 'js-lookup-button',
        DATE_PICKER_INPUT: 'js-start-date',
        ERROR_TEXT_SELECTOR: 'js-error-message'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var FindAccountByAddress = function ($findAccountByAddress) {
        /**
         * PRIVATE METHODS
         */
        var $zipCodeSelector,
            $selectStreetName,
            $selectStreetNumber,
            $selectResidendeUnit,
            $zipCodeServiceError,
            $streetNameServiceError,
            $streetNumberServiceError,
            $formLoading,
            $parentForm,
            $lookupButton,
            $datePickerInput,
            _currentZipCode,
            _typeOfForm;

        var formStatus = function (isBlocked) {
            var $currentStep = document.getElementsByClassName(CONSTANTS.FORM_CURRENT_STEP)[0];

            if (isBlocked) {
                $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
            } else {
                $currentStep.classList.remove(CONSTANTS.IS_FORM_BLOCKED);
            }
        };

        var validateZipField = function (event) {
            if (coned.utils.preventMovementError(event)) return;

            var $target = event.target,
                validator = $($parentForm).validate(),
                zipCodeLength = $target.value.length,
                zipCodeStatus = zipCodeLength == 5 ? validator.element($target) : false,
                serviceUrl = $findAccountByAddress.dataset.serviceUrl,
                searchFor = $findAccountByAddress.dataset.searchForStreetname;

            hideAddressLookUpErrors();

            if (_typeOfForm) {
                $lookupButton.disabled = true;
                formStatus(true);
            }

            if (zipCodeStatus) {
                if (_currentZipCode != $target.value) {
                    $target.disabled = true;
                    _currentZipCode = $target.value;
                    cleanDropdowns();
                    // Service Data
                    var params = {
                        ScId: query.getFormInputValue($parentForm, CONSTANTS.FORM_SCID_NAME),
                        SearchFor: searchFor,
                        CompanyCode: query.getFormInputValue(
                            $parentForm,
                            CONSTANTS.FORM_COMPANY_CODE_NAME
                        ),
                        ZipCode: query.getFormInputValue(
                            $findAccountByAddress,
                            CONSTANTS.FORM_ZIPCODE_NAME
                        )
                    };

                    // Service Call
                    query.getData(serviceUrl, successZipCode, failZipCode, params, $formLoading);

                    // Set the date picker dates limit
                    if (_typeOfForm) {
                        $($datePickerInput).datepicker('option', 'minDate', '1');
                        $($datePickerInput).datepicker('option', 'maxDate', '30d');
                    }
                }
            } else {
                _currentZipCode = $target.value;
                $selectStreetName.disabled = true;
                $selectStreetNumber.disabled = true;
                $selectResidendeUnit.disabled = true;
                cleanDropdowns();
            }
        };

        var successZipCode = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_STREET_NAMES,
                    enableStreetName,
                    showZipCodeError
                );
            } else {
                enableStreetName(data);
            }
        };

        var failZipCode = function (data) {
            $selectStreetName.disabled = true;
            $zipCodeSelector.disabled = false;
            showZipCodeError(data);
        };

        var showZipCodeError = function (data) {
            var $msgTextSelector = $zipCodeServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $zipCodeServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $zipCodeServiceError && $zipCodeServiceError.focus();
        };

        var enableStreetName = function (data) {
            var $option;

            cleanDropdown($selectStreetName);

            // Setting data values
            _.each(data.Addresses, function (address, index) {
                $option = document.createElement('option');
                $option.text = address.Direction + ' ' + address.Street + ' ' + address.Artery;
                $option.value = address.StreetNameKey;
                $option.dataset.borough = address.Borough;
                $selectStreetName.add($option, index + 1);
            });

            $zipCodeSelector.disabled = false;
            $selectStreetName.disabled = false;
            $selectStreetNumber.disabled = true;
            $selectResidendeUnit.disabled = true;
        };

        var validateStreetName = function (event) {
            var $target = event.target,
                $streetName = $findAccountByAddress.querySelector(
                    '[name=' + CONSTANTS.FORM_STREET_NAME_NAME + ']'
                ),
                streetSelected = $streetName.options[$streetName.selectedIndex],
                serviceUrl = $findAccountByAddress.dataset.serviceUrl,
                searchFor = $findAccountByAddress.dataset.searchForStreetnumber;

            $target.disabled = true;
            hideAddressLookUpErrors();

            // Service Data
            var params = {
                ScId: query.getFormInputValue($parentForm, CONSTANTS.FORM_SCID_NAME),
                SearchFor: searchFor,
                CompanyCode: query.getFormInputValue($parentForm, CONSTANTS.FORM_COMPANY_CODE_NAME),
                ZipCode: query.getFormInputValue(
                    $findAccountByAddress,
                    CONSTANTS.FORM_ZIPCODE_NAME
                ),
                StreetNameKey: streetSelected.value,
                Borough: streetSelected.dataset.borough
            };

            // Service Call
            query.getData(serviceUrl, successStreetName, failStreetName, params, $formLoading);
        };

        var successStreetName = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_STREET_NUMBERS,
                    enableStreetNumber,
                    showStreetNameError
                );
            } else {
                enableStreetNumber(data);
            }
        };

        var failStreetName = function (data) {
            $selectStreetNumber.disabled = true;
            $selectStreetName.disabled = false;
            showStreetNameError(data);
        };

        var showStreetNameError = function (data) {
            var $msgTextSelector = $streetNameServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $streetNameServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $streetNameServiceError && $streetNameServiceError.focus();
        };

        var enableStreetNumber = function (data) {
            var option;

            cleanDropdown($selectStreetNumber);
            cleanDropdown($selectResidendeUnit);

            // Disable submit button and steps nav as part of the street number setup
            if (_typeOfForm) {
                $lookupButton.disabled = true;
                formStatus(true);
            }

            // Setting data values
            _.each(data.Addresses, function (address, index) {
                option = document.createElement('option');
                option.text = address.StreetNumber;
                option.value = address.StreetNumber;
                $selectStreetNumber.add(option, index + 1);
            });

            $selectStreetName.disabled = false;
            $selectStreetNumber.disabled = false;
            $selectResidendeUnit.disabled = true;
        };

        var validateStreetNumber = function (event) {
            var $target = event.target,
                $streetName = $findAccountByAddress.querySelector(
                    '[name=' + CONSTANTS.FORM_STREET_NAME_NAME + ']'
                ),
                streetSelected = $streetName.options[$streetName.selectedIndex],
                serviceUrl = $findAccountByAddress.dataset.serviceUrl,
                searchFor = $findAccountByAddress.dataset.searchForUnit;

            $target.disabled = true;
            hideAddressLookUpErrors();

            // Service Data
            var params = {
                ScId: query.getFormInputValue($parentForm, CONSTANTS.FORM_SCID_NAME),
                SearchFor: searchFor,
                CompanyCode: query.getFormInputValue($parentForm, CONSTANTS.FORM_COMPANY_CODE_NAME),
                ZipCode: query.getFormInputValue(
                    $findAccountByAddress,
                    CONSTANTS.FORM_ZIPCODE_NAME
                ),
                StreetNameKey: streetSelected.value,
                Borough: streetSelected.dataset.borough,
                StreetNumber: query.getFormInputValue(
                    $findAccountByAddress,
                    CONSTANTS.FORM_STREET_NUMBER_NAME
                )
            };

            // Service Call
            query.getData(serviceUrl, successStreetNumber, failStreetNumber, params, $formLoading);
        };

        var successStreetNumber = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_RESIDENCE_UNITS,
                    enableResidenceUnit,
                    showStreetNumberError
                );
            } else {
                enableResidenceUnit(data);
            }
        };

        var failStreetNumber = function (data) {
            $selectResidendeUnit.disabled = true;
            $selectStreetNumber.disabled = false;
            showStreetNumberError(data);
        };

        var showStreetNumberError = function (data) {
            var $msgTextSelector = $streetNumberServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $streetNumberServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $streetNumberServiceError && $streetNumberServiceError.focus();
        };

        var enableResidenceUnit = function (data) {
            var option;

            cleanDropdown($selectResidendeUnit);

            // Disable submit button and steps nav as part of the residence unit setup
            if (_typeOfForm) {
                $lookupButton.disabled = true;
                formStatus(true);
            }

            // Setting data values
            _.each(data.Addresses, function (address, index) {
                option = document.createElement('option');
                option.text = address.UnitNo;
                option.value = address.AccountNumber;
                $selectResidendeUnit.add(option, index + 1);
            });

            $selectStreetNumber.disabled = false;
            $selectResidendeUnit.disabled = false;
        };

        var cleanDropdowns = function () {
            cleanDropdown($selectStreetName);
            cleanDropdown($selectStreetNumber);
            cleanDropdown($selectResidendeUnit);
        };

        var cleanDropdown = function ($dropdown) {
            var $dropdownLabel = $dropdown.parentElement.getElementsByClassName(
                CONSTANTS.SELECT_LABEL_SELECTOR
            )[0];

            $dropdownLabel.removeAttribute('style');
            $dropdown.removeAttribute('style');
            $dropdown.selectedIndex = 0;
            $dropdown.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);

            while ($dropdown.length > 1) {
                $dropdown.remove(1);
            }
        };

        var hideAddressLookUpErrors = function () {
            hideError($zipCodeServiceError);
            hideError($streetNameServiceError);
            hideError($streetNumberServiceError);
        };

        var hideError = function ($element) {
            $element.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var initializeData = function () {
            $zipCodeSelector = $findAccountByAddress.getElementsByClassName(
                CONSTANTS.ZIP_CODE_SELECTOR
            )[0];
            $selectStreetName = $findAccountByAddress.getElementsByClassName(
                CONSTANTS.SELECT_STREET_NAME
            )[0];
            $selectStreetNumber = $findAccountByAddress.getElementsByClassName(
                CONSTANTS.SELECT_STREET_NUMBER
            )[0];
            $selectResidendeUnit = $findAccountByAddress.getElementsByClassName(
                CONSTANTS.SELECT_RESIDENCE_UNIT
            )[0];
            $zipCodeServiceError = $findAccountByAddress.getElementsByClassName(
                CONSTANTS.ZIP_CODE_ERROR_SELECTOR
            )[0];
            $streetNameServiceError = $findAccountByAddress.getElementsByClassName(
                CONSTANTS.STREET_NAME_ERROR_SELECTOR
            )[0];
            $streetNumberServiceError = $findAccountByAddress.getElementsByClassName(
                CONSTANTS.STREET_NUMBER_ERROR_SELECTOR
            )[0];
            $streetNumberServiceError = $findAccountByAddress.getElementsByClassName(
                CONSTANTS.STREET_NUMBER_ERROR_SELECTOR
            )[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $parentForm = document.getElementsByClassName(
                $findAccountByAddress.dataset.parentForm
            )[0];
            $lookupButton = document.getElementsByClassName(CONSTANTS.LOOKUP_BUTTON)[0];
            $datePickerInput = document.getElementsByClassName(CONSTANTS.DATE_PICKER_INPUT)[0];
            _typeOfForm = query.hasClass($parentForm, CONSTANTS.SERVICE_FORM);
        };

        var initializeEvents = function () {
            // Address Services call
            $zipCodeSelector.addEventListener('keyup', validateZipField);
            $selectStreetName.addEventListener('change', validateStreetName);
            $selectStreetNumber.addEventListener('change', validateStreetNumber);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FindAccountByAddress.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FindAccountByAddress;
})();
