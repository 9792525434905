// ==================== SECURITY WORD CONFIRMATION COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SecurityWordConfirmation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORGOT_CONFIRM_ACCOUNT_FORM: '.js-forgot-confirm-account-form',
        CONFIRM_OWNERSHIP_FORM_WRAPPER: 'js-confirm-ownership-form-wrapper',
        CONFIRM_OWNERSHIP_FORM: '.js-confirm-ownership-form',
        CONFIRM_OWNERSHIP_SECURITY_WORD_CONTAINER: 'js-security-word-input-container',
        CONFIRM_OWNERSHIP_SECURITY_WORD_OPTIONS: 'js-security-word-option',
        CONFIRM_OWNERSHIP_SECURITY_WORD_OPTION_NO: 'js-security-word-option-no',
        CONFIRM_OWNERSHIP_SECURITY_WORD_INPUT: 'js-security-word-input',
        CONFIRM_OWNERSHIP_SUBMIT: 'js-transactional-submit-selector',
        IGNORE_CLASS: 'js-ignore-validation-class',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var SecurityWordConfirmation = function ($securityConfirmation) {
        /**
         * PRIVATE METHODS
         */
        var $confirmOwnershipSecurityOptions,
            $confirmOwnershipSecurityInput,
            $securityWordInputContainer,
            $securityWordConfirmationSubmit,
            $parentForm,
            _parentFormName;

        var securityWordConfirmation = function () {
            if (this.value === 'yes') {
                $securityWordConfirmationSubmit.disabled = true;
                $securityWordInputContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $confirmOwnershipSecurityInput.classList.remove(CONSTANTS.IGNORE_CLASS);
            } else {
                $securityWordConfirmationSubmit.disabled = false;
                $securityWordInputContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $confirmOwnershipSecurityInput.value = '';
                $confirmOwnershipSecurityInput.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                $confirmOwnershipSecurityInput.classList.add(CONSTANTS.IGNORE_CLASS);
            }
        };

        var securityWordInputValidation = function () {
            var isValid = $confirmOwnershipSecurityInput.value !== '';

            if (isValid) {
                $securityWordConfirmationSubmit.disabled = false;
            } else {
                $securityWordConfirmationSubmit.disabled = true;
            }
        };

        var initializeData = function () {
            _parentFormName = $securityConfirmation.dataset.parentForm;
            $parentForm = document.getElementsByClassName(_parentFormName)[0];
            $confirmOwnershipSecurityOptions = $securityConfirmation.getElementsByClassName(
                CONSTANTS.CONFIRM_OWNERSHIP_SECURITY_WORD_OPTIONS
            );
            $confirmOwnershipSecurityInput = $securityConfirmation.getElementsByClassName(
                CONSTANTS.CONFIRM_OWNERSHIP_SECURITY_WORD_INPUT
            )[0];
            $securityWordConfirmationSubmit = $parentForm.getElementsByClassName(
                CONSTANTS.CONFIRM_OWNERSHIP_SUBMIT
            )[0];
            $securityWordInputContainer = $securityConfirmation.getElementsByClassName(
                CONSTANTS.CONFIRM_OWNERSHIP_SECURITY_WORD_CONTAINER
            )[0];
        };

        var initializeEvents = function () {
            // security word options handler
            for (var index = 0; index < $confirmOwnershipSecurityOptions.length; index++) {
                coned.utils.addGeneralListeners(
                    $confirmOwnershipSecurityOptions[index],
                    securityWordConfirmation
                );
            }

            $confirmOwnershipSecurityInput.addEventListener('keyup', securityWordInputValidation);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SecurityWordConfirmation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SecurityWordConfirmation;
})();
