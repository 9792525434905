// ==================== CHECK OUTAGE STATUS COMPONENT =========================
/* global _ */
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CheckOutageStatus = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CHECK_OUTAGE_FORM: 'js-check-outage',
        VALIDATE_ITEM: 'js-item-validate',
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        LOOKUP_BUTTON: 'js-lookup-button',
        CONTINUE_BUTTON: 'js-continue-button',
        CHECK_BUTTON_TEXT: 'js-status-button-text',
        INPUT_FILLED: 'coned-input--filled',
        SELECT_FILLED: 'coned-select--active',
        FIND_ACCOUNT_CONTAINER: 'js-find-account-container',
        ACCOUNT_OPTION_SELECT: 'js-find-account',
        ACCOUNT_OPTION: 'js-account-options',
        ACCOUNT_OPTION_PHONE: 'phone',
        ACCOUNT_OPTION_ZIP_CODE: 'zipcode',
        SELECT_LABEL_SELECTOR: 'js-coned-select-label',
        ADDRESS_LOOKUP_FORM: 'js-address-lookup',
        AUTOCOMPLETE_ZIPCODE: 'js-autocomplete-zipcode',
        AUTOCOMPLETE_APARMENT_UNIT: 'js-autocomplete-apartment-unit',
        ADDRESS_SELECTED_DETAIL_CLASS: 'js-address-selected-detail',
        AUTOCOMPLETE_ADDRESS_SELECTED_CONTAINER: 'js-address-autocomplete-selected',
        ACTIVE_DROPDOWN: 'coned-select--active',
        RECAPTCHA: 'js-recaptcha-container',
        NOT_MY_ADDRESS_BUTTON: 'js-not-my-address',
        NOT_MY_ADDRESS_CONFIRMATION_BUTTON: 'js-not-my-address-confirmation',
        ACCOUNT_RESULTS: 'js-account-results',
        ACCOUNT_CONTAINER_SELECTOR: 'js-account-info',
        ACCOUNTS_CONTAINER_SELECTOR: 'js-accounts-info',
        ETR_SELECTOR: 'js-etr',
        ETR_TITLE_SELECTOR: 'js-etr-title',
        ACCOUNT_RECEPTOR: 'js-account-receptor',
        MULTIPLE_ACCOUNT_SELECT: 'js-multiple-account',
        ADDRESS_BOX_SELECTOR: 'js-address-selector',
        ADDRESS_BOX_ADDRESS_1: 'js-address-1',
        ADDRESS_BOX_ADDRESS_2: 'js-address-2',
        ADDRESS_CONFIRMATION_BOX_ADDRESS_1: 'js-address-confirmation-1',
        ADDRESS_CONFIRMATION_BOX_ADDRESS_2: 'js-address-confirmation-2',
        OUTAGE_STATUS: 'js-outage-status',
        OUTAGE_DESCRIPTION: 'js-outage-description',
        OUTAGE_REPORT: 'js-outage-report',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        FORM_SCID_NAME: 'ScId',
        FORM_UNAUTH_SCID_NAME: 'UnauthScId',
        FORM_FIND_ACCOUNT_VIA_NAME: 'findAccount',
        FORM_ACCOUNT_NUMBER_NAME: 'accountNumber',
        FORM_PHONE_NUMBER_NAME: 'phoneNumber',
        FORM_BOROUGH_NAME: 'borough',
        LOOKUP_ERROR_SELECTOR: 'js-lookup-service-error',
        STATUS_ERROR_SELECTOR: 'js-status-service-error',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        CAPTCHA_ELEMENT_CLASS: 'js-recaptcha',
        SUBMIT_ANIMATION_CONTAINER: 'js-submit-progress-animation',
        AMOUNT_LETTERS: 4,
        TITLE_SELECTOR: 'js-title',
        REQUIRED_FIELDS_MESSAGE_SELECTOR: 'js-required-fields-message',
        DESCRIPTION_MAIN_SELECTOR: 'js-description-main',
        CHECK_OUTAGE_NAV_SELECTOR: 'js-check-outage-nav',
        DESCRIPTION_SECONDARY_SELECTOR: 'js-description-secondary',
        OUTAGE_CONFIRMATION_SELECTOR:'js-outage-confirmation',
        STATUS_QUESTION_SELECTOR: 'js-status-question',
        PREDICTIVE_FLAG_RECEIVED: 'js-predictive-flag-received',
        PREDICTIVE_FLAG_INFORMATION: 'js-predictive-flag-information',
        OPTIONAL_TEXT_WRAPPER: 'js-optional-text-wrapper',
        RESTORATION_UPDATES_CTA: 'js-restoration-cta',
        OUTAGE_CONFIRMATION_HEADING_MARGIN_BOTTOM_CLASS: 'outage-confirmation-banner__heading--no-margin-bottom',
        OUTAGE_TILES_SELECTOR: 'js-outage-tiles',
        ADDRESS_DROPDOWN_BOX_TITLE_NON_AUTENTICATED: 'address-box__container-title--non-authenticated',
        COVID_INFORMATION_PARAGRAPH: 'js-covid-information-paragraph',
        CHECK_OUTAGE_CONTAINER: 'js-check-outage-container',
        TEXT_AREA_BUTTONS_CONTAINER: 'js-text-area-buttons-container',
        BUTTONS_CONTAINER_NO_MARGIN_TOP_CLASS: 'confirmation-screen__buttons-container--no-margin-top',
        SYSTEM_OUT_PARAMETER: 'system-out',

        // Tokens
        DATE_TOKEN: '@DATE',
        TIME_TOKEN: '@TIME'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var CheckOutageStatus = function ($checkOutage) {
        /**
         * PRIVATE METHODS
         */
        var $submitAnimationButton,
            $submitAnimationSelector,
            $submitAnimationContainer,
            $continueButton,
            $findAccountContainer,
            $findAccountSelect,
            $validItems,
            $notMyAddressButton,
            $notMyAddressConfirmationButton,
            $covidInformationParagraph,
            $checkOutageContainer,
            $textAreaContainer,
            $accountResults,
            $accountContainer,
            $accountsContainer,
            $etr,
            $etrTitle,
            $accountReceptor,
            $multipleAccountSelect,
            $addressBoxSelector,
            $addressBox,
            $outageStatus,
            $outageDescription,
            $outageReport,
            $lookupServiceError,
            $statusServiceError,
            $addressLookupForm,
            $autocompleteSelectedAddressContainer,
            $autocompleteZipCode,
            $autocompleteAparmentUnitInput,
            $addressSelectedDetail,
            $formLoading,
            $recaptcha,
            $captchaElement,
            $title,
            $requiredFieldsMessage,
            $descriptionMain,
            $checkOutageNav,
            $descriptionSecondary,
            $outageConfirmationSection,
            $statusQuestion,
            $predictiveFlagReceived,
            $predictiveFlagInformation,
            $restorationUpdatesCTA,
            $outageReceived,
            $outageTiles,
            $optionalTextWrapper,
            _selectedAccount,
            recaptcha,
            _userAuthenticated,
            _data,
            _hasCaptcha,
            _isSystemOut,
            _validForm;

        var cleanDropdown = function ($dropdown) {
            var $dropdownLabel = $dropdown.parentElement.getElementsByClassName(
                CONSTANTS.SELECT_LABEL_SELECTOR
            )[0];

            $dropdownLabel.removeAttribute('style');
            $dropdown.removeAttribute('style');
            $dropdown.selectedIndex = 0;
            $dropdown.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);

            while ($dropdown.length > 1) {
                $dropdown.remove(1);
            }
        };

        var findAccountOptions = function () {
            var selectAccountOption = this.options[$findAccountSelect.selectedIndex].value,
                $selectedOptions = $checkOutage.getElementsByClassName('js-' + selectAccountOption),
                $accountOptions = $checkOutage.getElementsByClassName(CONSTANTS.ACCOUNT_OPTION);

            $submitAnimationButton.disabled = false;

            //Hide lookup button if address lookup form display
            if (selectAccountOption === CONSTANTS.ACCOUNT_OPTION_ZIP_CODE ) {
                $submitAnimationContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $continueButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $submitAnimationContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } 

            resetOptionInputs();
            hideError($lookupServiceError);

            _.each($accountOptions, function ($option) {
                $option.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($selectedOptions, function ($option) {
                $option.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });
        };

        var resetOptionInputs = function () {
            _.each($validItems, function ($item) {
                $item.value = '';
                $item.classList.remove(CONSTANTS.INPUT_FILLED);

                // If item is a dropdown
                var $dropdownLabel = $item.parentElement.getElementsByClassName(
                    CONSTANTS.SELECT_LABEL_SELECTOR
                )[0];
                if ($dropdownLabel != undefined) {
                    $dropdownLabel.removeAttribute('style');
                    $item.removeAttribute('style');
                    $item.selectedIndex = 0;
                    $item.classList.remove(CONSTANTS.SELECT_FILLED);
                }
            });

            if ($autocompleteSelectedAddressContainer) {
                $autocompleteSelectedAddressContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var lookupAccount = function () {
            var selectAccountOption =
                    $findAccountSelect.options[$findAccountSelect.selectedIndex].value,
                lookUpStatus = $($checkOutage).valid();

            if (lookUpStatus) {
                  new coned.components.SubmitAnimation(
                    $submitAnimationSelector,
                    lookupAccountServiceCall,
                    successLookupAccountServiceCallback,
                    errorLookupAccountServiceCallback,
                    false,
                    false
                );

                if (selectAccountOption === CONSTANTS.ACCOUNT_OPTION_ZIP_CODE) {
                    $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
                }
            }
        };

        var lookupAccountServiceCall = function () {
            var serviceUrl = $checkOutage.dataset.lookupServiceUrl,
                altCheckOutage = $checkOutage.dataset.altCheckOutage,
                notFoundAccountMessage = $checkOutage.dataset.notFoundAccountMessage,
                accountNumber = query.getFormInputValue(
                    $checkOutage,
                    CONSTANTS.FORM_ACCOUNT_NUMBER_NAME
                ),
                maid = $addressSelectedDetail.dataset.maid,
                params;

            hideError($lookupServiceError);

            // Service Data
            params = {
                ScId: query.getFormInputValue($checkOutage, CONSTANTS.FORM_SCID_NAME),
                UnauthScId: query.getFormInputValue($checkOutage, CONSTANTS.FORM_UNAUTH_SCID_NAME),
                FindBy: query.getFormInputValue($checkOutage, CONSTANTS.FORM_FIND_ACCOUNT_VIA_NAME),
                PhoneNumber: query.getFormInputValue(
                    $checkOutage,
                    CONSTANTS.FORM_PHONE_NUMBER_NAME
                ),
                Borough: query.getFormInputValue($checkOutage, CONSTANTS.FORM_BOROUGH_NAME),
                AccountNumber: accountNumber,
                AccountMaid: maid
            };

            if (altCheckOutage) {
                params.NotFoundAccountMessage = notFoundAccountMessage;
            }

            // Service Call
            query.postData(
                serviceUrl,
                successLookupAccountCallback,
                errorLookupAccountCallback,
                JSON.stringify(params),
                true
            );
        };

        var successLookupAccountCallback = function (data) {
            
            $submitAnimationSelector.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            _validForm = true;
            
            if (coned.utils.isPatternLab()) {
                return;
            } else {
                _data = data;
            }

            //Focus the next element when recaptacha present
            _hasCaptcha && $continueButton.focus();
        };

        var errorLookupAccountCallback = function (data) {
            $submitAnimationSelector.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _validForm = false;

            if (coned.utils.isPatternLab()) {
                return;
            } else {
                _data = data;
            }
            
        };

        var successLookupAccountServiceCallback = function () {
            var findAccountSelectedOption =
                $findAccountSelect.options[$findAccountSelect.selectedIndex].value;

            if (coned.utils.isPatternLab()) {
                if (findAccountSelectedOption == CONSTANTS.ACCOUNT_OPTION_PHONE) {
                    query.getData(
                        coned.plConstants.GET_LOOKUP_ACCOUNTS,
                        setAccount,
                        errorLookupAccountServiceCallback
                    );
                } else {
                    if (findAccountSelectedOption !== CONSTANTS.ACCOUNT_OPTION_ZIP_CODE) {
                        query.getData(
                            coned.plConstants.GET_LOOKUP_ACCOUNT,
                            setAccount,
                            errorLookupAccountServiceCallback
                        );
                    }
                }
            } else {
                if (findAccountSelectedOption !== CONSTANTS.ACCOUNT_OPTION_ZIP_CODE) {
                    setAccount(_data);
                }
            }
            
            $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
        };

        /**
         * Show or hide predictive flag information elements
         * @param {String} funtionality Options are show or hide
         */
        var showHidePredictiveFlagInformationElements = function (functionality) {
            _.each ($predictiveFlagInformation, function ($predictiveFlagInformationElement) {
                if (functionality === 'show') {
                    query.removeClass($predictiveFlagInformationElement, CONSTANTS.HIDDEN_CLASS);
                } else if (functionality === 'hide') {
                    query.addClass($predictiveFlagInformationElement, CONSTANTS.HIDDEN_CLASS);
                }
            });
        };

        var setAccount = function (data) {
            $submitAnimationButton.classList.remove(CONSTANTS.LOOKUP_BUTTON);
            toggleButtonText(true);

            // Clean data containers
            cleanDropdown($multipleAccountSelect);
            //Clean up address box, first item needs to remain as template.
            for (var index = $addressBoxSelector.length; index > 1; index--) {
                $addressBoxSelector[index-1].remove();                
            }

            $addressBox = $addressBoxSelector[0];

            if (data.Accounts.length > 1) {
                $submitAnimationSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $notMyAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                setMultipleAccounts(data.Accounts);
            } else {
                $notMyAddressButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                setSingleAccount(data.Accounts[0]);
            }

            $findAccountContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $accountResults.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $recaptcha.classList.add(CONSTANTS.HIDDEN_CLASS);
            
            $outageStatus.classList.add(CONSTANTS.HIDDEN_CLASS);
            $outageReport.classList.add(CONSTANTS.HIDDEN_CLASS);
            
            $accountResults && $accountResults.focus();
        };

        var setSingleAccount = function (data) {
            var $address1 = $addressBox.getElementsByClassName(CONSTANTS.ADDRESS_BOX_ADDRESS_1)[0],
                $address2 = $addressBox.getElementsByClassName(CONSTANTS.ADDRESS_BOX_ADDRESS_2)[0];

            // Set account data
            $accountReceptor.dataset.maid = data.MaskedAccountNumber;
            $accountReceptor.innerHTML = data.AccountNumber;
            $accountsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $accountContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Set address data
            if (!_userAuthenticated) {
                var _lastDigitsAccountNumber =
                    '<b>' +
                    data.AccountNumber.substr(
                        data.AccountNumber.length -
                            parseInt($checkOutage.dataset.accountNumberLength)
                    ) +
                    '</b>';
                $address1.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_TITLE_NON_AUTENTICATED);
                $address1.innerHTML =
                    $checkOutage.dataset.accountNumberMessage + _lastDigitsAccountNumber;
                if (data.hasOwnProperty('ServiceAddress') && data.ServiceAddress !== null) {
                    var _houseNumber =
                        '<b>' +
                        data.ServiceAddress.StreetAddress.substr(
                            0,
                            parseInt($checkOutage.dataset.streetAddressLength)
                        ) +
                        '</b>';
                    var finalDetailAddress =
                        data.ServiceAddress.City + ', ' + data.ServiceAddress.State;
                    
                    $address2.innerHTML =
                        $checkOutage.dataset.streetAddressMessage +
                        _houseNumber +
                        '<br>' +
                        finalDetailAddress;
                } else {
                    $address2.innerHTML =
                        $checkOutage.dataset.customerNameMessage + '<b>' + data.CustomerName.substr(0,$checkOutage.dataset.customerNameLength) + '</b>';
                }
            } else {
                $address1.innerHTML = data.ServiceAddress.StreetAddress;

                if (data.ServiceAddress.UnitNumber && data.ServiceAddress.UnitNumber.trim() != '') {
                    $address1.innerHTML += ', ' + data.ServiceAddress.UnitNumber;
                }

                $address2.innerHTML = data.ServiceAddress.City;

                if (data.ServiceAddress.State) {
                    $address2.innerHTML += ', ' + data.ServiceAddress.State;
                }

                if (data.ServiceAddress.PostalCode) {
                    $address2.innerHTML += ' ' + data.ServiceAddress.PostalCode;
                }
            }

            $addressBox.dataset.maid = data.MaskedAccountNumber;
            $addressBox.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Show and hide appropriate sections
            $outageConfirmationSection.classList.add(CONSTANTS.HIDDEN_CLASS);
            $checkOutageContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var setMultipleAccounts = function (data) {
            _.each(data, function (dataRow, index) {
                var $accountOption = document.createElement('option'),
                    $addressContainer = $addressBox.cloneNode(true),
                    $address1 = $addressContainer.getElementsByClassName(
                        CONSTANTS.ADDRESS_BOX_ADDRESS_1
                    )[0],
                    $address2 = $addressContainer.getElementsByClassName(
                        CONSTANTS.ADDRESS_BOX_ADDRESS_2
                    )[0];

                // Set account data
                $accountOption.text = dataRow.AccountNumber;
                $accountOption.value = dataRow.MaskedAccountNumber;
                $multipleAccountSelect.add($accountOption, index + 1);

                // Set address data
                if (!_userAuthenticated) {
                    var _lastDigitsAccountNumber =
                        '<b>' +
                        dataRow.AccountNumber.substr(
                            dataRow.AccountNumber.length -
                                parseInt($checkOutage.dataset.accountNumberLength)
                        ) +
                        '</b>';
                    $address1.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_TITLE_NON_AUTENTICATED);
                    $address1.innerHTML = $checkOutage.dataset.accountNumberMessage + _lastDigitsAccountNumber;
                    if (dataRow.hasOwnProperty('ServiceAddress') && dataRow.ServiceAddress !== null){
                        var _houseNumber =
                            '<b>' +
                            dataRow.ServiceAddress.StreetAddress.substr(
                                0,
                                parseInt($checkOutage.dataset.streetAddressLength)
                            ) +
                            '</b>';
                        var finalDetailAddress =
                            dataRow.ServiceAddress.City + ', ' + dataRow.ServiceAddress.State;
                        
                        $address2.innerHTML =
                            $checkOutage.dataset.streetAddressMessage +
                            _houseNumber +
                            '<b>' +
                            finalDetailAddress +
                            '</b>';
                    } else {
                        $address2.innerHTML =
                            $checkOutage.dataset.customerNameMessage + '<b>' + dataRow.CustomerName.substr(0,$checkOutage.dataset.customerNameLength) + '</b>';
                    }
                } else {
                    $address1.innerHTML = dataRow.ServiceAddress.StreetAddress;

                    if (
                        dataRow.ServiceAddress.UnitNumber &&
                        dataRow.ServiceAddress.UnitNumber.trim() != ''
                    ) {
                        $address1.innerHTML += ', ' + dataRow.ServiceAddress.UnitNumber;
                    }

                    $address2.innerHTML = dataRow.ServiceAddress.City;

                    if (dataRow.ServiceAddress.State) {
                        $address2.innerHTML += ', ' + dataRow.ServiceAddress.State;
                    }

                    if (dataRow.ServiceAddress.PostalCode) {
                        $address2.innerHTML += ' ' + dataRow.ServiceAddress.PostalCode;
                    }
                }
                $addressContainer.dataset.maid = dataRow.MaskedAccountNumber;
                $addressBox.insertAdjacentElement('afterend', $addressContainer);

                // Show and hide appropriate sections
                $outageConfirmationSection.classList.add(CONSTANTS.HIDDEN_CLASS);
                $checkOutageContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });

            // Reset address box elements
            $addressBox.parentNode.removeChild($addressBox);
            $addressBoxSelector = $checkOutage.getElementsByClassName(
                CONSTANTS.ADDRESS_BOX_SELECTOR
            );
            query.addClass($addressBoxSelector, CONSTANTS.HIDDEN_CLASS);

            // Show accounts data
            $accountContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $accountsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var errorLookupAccountServiceCallback = function () {
            var $msgTextSelector = $lookupServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $lookupServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = _data.errorMsg
                ? _data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $lookupServiceError.focus();

            $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
        };

        var multipleAccountOptions = function () {
            $submitAnimationSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            
            $outageStatus.classList.add(CONSTANTS.HIDDEN_CLASS);
            $outageReport.classList.add(CONSTANTS.HIDDEN_CLASS);

            updateAddressBox();
        };

        var updateAddressBox = function () {
            var addressMaid,
                accountMaid = $multipleAccountSelect.value;

            query.addClass($addressBoxSelector, CONSTANTS.HIDDEN_CLASS);

            _.each($addressBoxSelector, function ($addressBox) {
                addressMaid = $addressBox.dataset.maid;

                if (accountMaid == addressMaid) {
                    $addressBox.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            });
        };

        var checkDifferentAccount = function (event) {
            event.preventDefault();

            var selectAccountOption =
                $findAccountSelect.options[$findAccountSelect.selectedIndex].value;

            // Content elements
            $title && $title.classList.add(CONSTANTS.OUTAGE_CONFIRMATION_HEADING_MARGIN_BOTTOM_CLASS);
            $requiredFieldsMessage && $requiredFieldsMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $descriptionMain && $descriptionMain.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $checkOutageNav && $checkOutageNav.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $notMyAddressButton && $notMyAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            $textAreaContainer && $textAreaContainer.classList.remove(CONSTANTS.BUTTONS_CONTAINER_NO_MARGIN_TOP_CLASS);
            $notMyAddressConfirmationButton && $notMyAddressConfirmationButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            $covidInformationParagraph && $covidInformationParagraph.classList.add(CONSTANTS.HIDDEN_CLASS);
            $outageTiles && $outageTiles.classList.add(CONSTANTS.HIDDEN_CLASS);
            $descriptionSecondary && $descriptionSecondary.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // UI elements
            $findAccountContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $submitAnimationSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $recaptcha.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $statusQuestion.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $accountResults.classList.add(CONSTANTS.HIDDEN_CLASS);
            $submitAnimationButton.classList.add(CONSTANTS.LOOKUP_BUTTON);

            // Show and hide appropriate sections
            $outageConfirmationSection.classList.add(CONSTANTS.HIDDEN_CLASS);
            $checkOutageContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

            if (
                selectAccountOption === CONSTANTS.ACCOUNT_OPTION_ZIP_CODE
            ) {
                query.fireEvent($autocompleteZipCode, 'cleanUp');
                $submitAnimationSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $continueButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            toggleButtonText();
            resetOptionInputs();
        };

        var checkDifferentAccountLogged = function (event) {
            event.preventDefault();

            // Content elements
            $title && $title.classList.add(CONSTANTS.OUTAGE_CONFIRMATION_HEADING_MARGIN_BOTTOM_CLASS);
            $requiredFieldsMessage && $requiredFieldsMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $descriptionMain && $descriptionMain.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $checkOutageNav && $checkOutageNav.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $textAreaContainer && $textAreaContainer.classList.remove(CONSTANTS.BUTTONS_CONTAINER_NO_MARGIN_TOP_CLASS);
            $notMyAddressConfirmationButton && $notMyAddressConfirmationButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            $covidInformationParagraph && $covidInformationParagraph.classList.add(CONSTANTS.HIDDEN_CLASS);
            $outageTiles && $outageTiles.classList.add(CONSTANTS.HIDDEN_CLASS);

            // UI elements
            $submitAnimationSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $statusQuestion.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Show and hide appropriate sections
            $outageConfirmationSection.classList.add(CONSTANTS.HIDDEN_CLASS);
            $checkOutageContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var toggleButtonText = function (replace) {
            var $checkButtonText = $checkOutage.getElementsByClassName(
                    CONSTANTS.CHECK_BUTTON_TEXT
                )[0],
                originalText = $submitAnimationButton.dataset.text,
                replaceText = $submitAnimationButton.dataset.replaceText;

            if (replace) {
                $checkButtonText.innerHTML = replaceText;
            } else {
                $checkButtonText.innerHTML = originalText;
            }
        };

        var statusServiceCall = function () {
            var serviceUrl = $checkOutage.dataset.statusServiceUrl,
                noOutageText = $checkOutage.dataset.noOutageText,
                outageStatusTextNoETRResult = $checkOutage.dataset.outageStatusTextNoEtrResult,
                outageStatusTextNoETR = $checkOutage.dataset.outageStatusTextNoEtr,
                outageStatusText = $checkOutage.dataset.outageStatusText,
                outageStatusTextExpiredETR = $checkOutage.dataset.outageStatusTextExpiredEtr,
                altCheckOutage = $checkOutage.dataset.altCheckOutage,
                maid;

            if ($multipleAccountSelect && $multipleAccountSelect.options.length > 1) {
                maid = $multipleAccountSelect.options[$multipleAccountSelect.selectedIndex].value;
            } else {
                maid = $accountReceptor.dataset.maid;
            }

            hideError($statusServiceError);

            // Service Data
            var response = _hasCaptcha ? recaptcha.getResponse() : null,
                params = {},
                verify;

            if (_hasCaptcha && response && response !== '') {
                verify = response;
            }

            //Service Call
            if (altCheckOutage) {
                params = {
                    ScId: query.getFormInputValue($checkOutage, CONSTANTS.FORM_SCID_NAME),
                    Maid: maid,
                    NoOutageText: noOutageText,
                    OutageStatusTextNoETRResult: outageStatusTextNoETRResult,
                    OutageStatusTextNoETR: outageStatusTextNoETR,
                    OutageStatusText: outageStatusText,
                    OutageStatusTextExpiredETR: outageStatusTextExpiredETR,
                    Verify: verify
                };

                query.postData(
                    serviceUrl,
                    successStatusCallback,
                    errorStatusCallback,
                    JSON.stringify(params),
                    true
                );
            } else {
                params = {
                    ScId: query.getFormInputValue($checkOutage, CONSTANTS.FORM_SCID_NAME),
                    Maid: maid,
                    Verify: verify
                };

                query.getData(serviceUrl, successStatusCallback, errorStatusCallback, params);
            }
        };

        var successStatusCallback = function (data) {
            $submitAnimationSelector.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var errorStatusCallback = function (data) {
            $submitAnimationSelector.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var successStatusServiceCallback = function () {
            if (coned.utils.isPatternLab()) {
                if ($multipleAccountSelect && $multipleAccountSelect.options.length > 1) {
                    query.getData(
                        coned.plConstants.GET_OUTAGE_STATUS_MULTIPLE,
                        setStatus,
                        errorStatusServiceCallback
                    );                    
                } else {                    
                    query.getData(
                        coned.plConstants.GET_OUTAGE_STATUS_SINGLE,
                        setStatus,
                        errorStatusServiceCallback
                    );
                }
            } else {                
                setStatus(_data);               
            }
        };

        var setStatus = function (data) {
            // Service
            var outageExists = data.OutageExists,
                outageEtr = outageExists && data.OutageETR;

            $outageReceived = outageExists && data.OutageDetails.IsPredictive,
            $submitAnimationSelector.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (!outageExists) {
                $outageReport.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $outageDescription.innerHTML = data.ErrorMessage;
                $outageStatus.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $outageStatus.focus();

                if ($multipleAccountSelect && $multipleAccountSelect.options.length > 1) {
                    $notMyAddressButton && $notMyAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                // Show and hide expected section
                $outageConfirmationSection.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $checkOutageContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                // Show and hide elements
                $title && $title.classList.remove(CONSTANTS.OUTAGE_CONFIRMATION_HEADING_MARGIN_BOTTOM_CLASS);
                $requiredFieldsMessage && $requiredFieldsMessage.classList.add(CONSTANTS.HIDDEN_CLASS);
                $descriptionMain && $descriptionMain.classList.add(CONSTANTS.HIDDEN_CLASS);
                $checkOutageNav && $checkOutageNav.classList.add(CONSTANTS.HIDDEN_CLASS);
                $descriptionSecondary && $descriptionSecondary.classList.add(CONSTANTS.HIDDEN_CLASS);
                $notMyAddressButton && $notMyAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                $textAreaContainer && $textAreaContainer.classList.add(CONSTANTS.BUTTONS_CONTAINER_NO_MARGIN_TOP_CLASS);
                $notMyAddressConfirmationButton && $notMyAddressConfirmationButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $covidInformationParagraph && $covidInformationParagraph.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $statusQuestion && $statusQuestion.classList.add(CONSTANTS.HIDDEN_CLASS);
                // Show tiles tips section
                $outageTiles && $outageTiles.classList.remove(CONSTANTS.HIDDEN_CLASS);

                // Show message
                $etrTitle.innerHTML = '<b>' + $checkOutage.dataset.etrTitle + '</b>';

                $etr.innerHTML =  outageEtr;
              
                $outageConfirmationSection && $outageConfirmationSection.focus();
            }

            setAccountResult();
        };

        var errorStatusServiceCallback = function () {
            var $msgTextSelector = $statusServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $statusServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = _data.errorMsg
                ? _data.errorMsg
                : coned.constants.ERROR_MESSAGE;

            $statusServiceError && $statusServiceError.focus();
        };

        var getSelectedAddress = function () {
            var addressMaid,
                $selectedAddress,
                accountMaid = $multipleAccountSelect.value;

            _.each($addressBoxSelector, function ($addressBox) {
                addressMaid = $addressBox.dataset.maid;

                if (accountMaid == addressMaid) {
                    $selectedAddress = $addressBox;
                }
            });

            return $selectedAddress;
        };

        var setAccountResult = function () {
            var $address1Confirmation = $checkOutage.getElementsByClassName(
                    CONSTANTS.ADDRESS_CONFIRMATION_BOX_ADDRESS_1
                )[0],
                $address2Confirmation = $checkOutage.getElementsByClassName(
                    CONSTANTS.ADDRESS_CONFIRMATION_BOX_ADDRESS_2
                )[0],
                _isMultipleAccount = $multipleAccountSelect && $multipleAccountSelect.options.length > 1,
                $addressBoxContainer = _isMultipleAccount ? getSelectedAddress() : $addressBox,
                $addresBox1 = $addressBoxContainer.getElementsByClassName(
                    CONSTANTS.ADDRESS_BOX_ADDRESS_1
                )[0],
                $addresBox2 = $addressBoxContainer.getElementsByClassName(
                    CONSTANTS.ADDRESS_BOX_ADDRESS_2
                )[0],
                _hasAddress = $addresBox2.textContent.includes($checkOutage.dataset.streetAddressMessage),
                streetAddressMessage = !_userAuthenticated && $checkOutage.dataset.streetAddressMessage,
                streetAddressMessageAuthenticated = _userAuthenticated && $checkOutage.dataset.streetAddressMessageAuthenticated,
                accountNumberMessageAuthenticated = _userAuthenticated && $checkOutage.dataset.accountNumberMessageAuthenticated,
                accountNumberAuthenticated = !_isMultipleAccount ?
                    $accountReceptor.textContent.trim() :
                    query.getDropdownInputTextByElement($multipleAccountSelect),
                streetAddressIndex = !_userAuthenticated && $checkOutage.dataset.streetAddressLength-1,
                customerNameIndex = !_userAuthenticated && $checkOutage.dataset.customerNameLength-1,
                customerNameMessage = !_userAuthenticated && $checkOutage.dataset.customerNameMessage,
                addresBox1Text = $addresBox1.innerHTML,
                addresBox2Text = _userAuthenticated ?
                    $addresBox2.innerHTML : _hasAddress ? 
                    $addresBox2.textContent.split(streetAddressMessage)[1]: $addresBox2.textContent.split(customerNameMessage)[1],

                addresBox2TextFormated = !_userAuthenticated && _hasAddress ?
                    coned.utils.insertStringAtIndex(addresBox2Text, '<br>', streetAddressIndex):
                    coned.utils.insertStringAtIndex(addresBox2Text, '<br>', customerNameIndex);
                $address1Confirmation.innerHTML = _userAuthenticated ?
                    accountNumberMessageAuthenticated + ' ' + '<b>' + accountNumberAuthenticated + '</b>' :
                    addresBox1Text;

                $address2Confirmation.innerHTML = _userAuthenticated ? 
                    streetAddressMessageAuthenticated + ' ' + addresBox1Text + ' ' + '<b>' + addresBox2Text + '</b>' :
                    _hasAddress ? 
                        streetAddressMessage + '<b>' + addresBox2TextFormated + '</b>' :
                        customerNameMessage + '<b>' + addresBox2TextFormated + '</b>' ;

            if ($outageReceived === true) {
                $optionalTextWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $predictiveFlagReceived && $predictiveFlagReceived.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $predictiveFlagInformation && showHidePredictiveFlagInformationElements('show');
                $restorationUpdatesCTA && $restorationUpdatesCTA.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $textAreaContainer && $textAreaContainer.classList.remove(CONSTANTS.BUTTONS_CONTAINER_NO_MARGIN_TOP_CLASS);
                $notMyAddressConfirmationButton && $notMyAddressConfirmationButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            }else {
                var showContainer = $optionalTextWrapper.dataset.showContainer === "true"? true : false; 
                if (showContainer) {
                    $optionalTextWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $optionalTextWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }
        };

        var hideError = function ($element) {
            $element.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var checkOutageOnSubmision = function (){
            var lookAccountButton = query.hasClass($submitAnimationButton, CONSTANTS.LOOKUP_BUTTON);

            if (lookAccountButton) {
                lookupAccount();
            }
            else {
                new coned.components.SubmitAnimation(
                    $submitAnimationSelector,
                    statusServiceCall,
                    successStatusServiceCallback,
                    errorStatusServiceCallback,
                    false,
                    true
                );
            }

        }

        var checkOutageSubmit = function () {

            if (_userAuthenticated || !_hasCaptcha) {
                checkOutageOnSubmision();
            } else {
                if (recaptcha && recaptcha.getResponse() === '') {
                    recaptcha.checkRecaptcha();
                } else if (recaptcha && recaptcha.getResponse() !== '') {
                    recaptcha.reset();
                    recaptcha.checkRecaptcha();
                }
            }
        };

        var selectFirstOptionByDefault = function () {
            if ($multipleAccountSelect) {
                $multipleAccountSelect.selectedIndex = 0;
            }
            if ($findAccountSelect) {
                $findAccountSelect.selectedIndex = 0;
            }
        };

        var initializeData = function () {
            _userAuthenticated = $checkOutage.dataset.userStatus;
            $accountReceptor = $checkOutage.getElementsByClassName(CONSTANTS.ACCOUNT_RECEPTOR)[0];
            $multipleAccountSelect = $checkOutage.getElementsByClassName(
                CONSTANTS.MULTIPLE_ACCOUNT_SELECT
            )[0];
            $addressBoxSelector = $checkOutage.getElementsByClassName(
                CONSTANTS.ADDRESS_BOX_SELECTOR
            );
            $submitAnimationButton = $checkOutage.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON
            )[0];
            $submitAnimationSelector = $submitAnimationButton.parentNode.parentNode;
            $statusServiceError = $checkOutage.getElementsByClassName(
                CONSTANTS.STATUS_ERROR_SELECTOR
            )[0];
            $title = $checkOutage.getElementsByClassName(
                CONSTANTS.TITLE_SELECTOR
            )[0];
            $requiredFieldsMessage = $checkOutage.getElementsByClassName(
                CONSTANTS.REQUIRED_FIELDS_MESSAGE_SELECTOR
            )[0];
            $descriptionMain = $checkOutage.getElementsByClassName(
                CONSTANTS.DESCRIPTION_MAIN_SELECTOR
            )[0];
            $checkOutageNav = $checkOutage.getElementsByClassName(
                CONSTANTS.CHECK_OUTAGE_NAV_SELECTOR
            )[0];
            $descriptionSecondary = $checkOutage.getElementsByClassName(
                CONSTANTS.DESCRIPTION_SECONDARY_SELECTOR
            )[0];
            $outageConfirmationSection = $checkOutage.getElementsByClassName(
                CONSTANTS.OUTAGE_CONFIRMATION_SELECTOR
            )[0];
            $statusQuestion = $checkOutage.getElementsByClassName(
                CONSTANTS.STATUS_QUESTION_SELECTOR
            )[0];
            $predictiveFlagReceived = $checkOutage.getElementsByClassName(
                CONSTANTS.PREDICTIVE_FLAG_RECEIVED
            )[0];
            $predictiveFlagInformation = $checkOutage.getElementsByClassName(
                CONSTANTS.PREDICTIVE_FLAG_INFORMATION
            );
            $restorationUpdatesCTA = $checkOutage.getElementsByClassName(
                CONSTANTS.RESTORATION_UPDATES_CTA
            )[0];
            $accountsContainer = $checkOutage.getElementsByClassName(
                CONSTANTS.ACCOUNTS_CONTAINER_SELECTOR
            )[0];
            $etr = $checkOutage.getElementsByClassName(
                CONSTANTS.ETR_SELECTOR
            )[0];
            $etrTitle = $checkOutage.getElementsByClassName(
                CONSTANTS.ETR_TITLE_SELECTOR
            )[0];
            $outageTiles = $checkOutage.getElementsByClassName(
                CONSTANTS.OUTAGE_TILES_SELECTOR
            )[0];
            $notMyAddressConfirmationButton = $checkOutage.getElementsByClassName(
                CONSTANTS.NOT_MY_ADDRESS_CONFIRMATION_BUTTON
            )[0];
            $covidInformationParagraph = $checkOutage.getElementsByClassName(
                CONSTANTS.COVID_INFORMATION_PARAGRAPH
            )[0];
            $checkOutageContainer = $checkOutage.getElementsByClassName(
                CONSTANTS.CHECK_OUTAGE_CONTAINER
            )[0];
            $textAreaContainer = $checkOutage.getElementsByClassName(
                CONSTANTS.TEXT_AREA_BUTTONS_CONTAINER
            )[0];
            $optionalTextWrapper = $checkOutage.getElementsByClassName(
                CONSTANTS.OPTIONAL_TEXT_WRAPPER
            )[0];
            $addressBox = $addressBoxSelector[0];
            $accountResults = $checkOutage.getElementsByClassName(CONSTANTS.ACCOUNT_RESULTS)[0];
            $captchaElement = document.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT_CLASS);
            _hasCaptcha = $captchaElement.length;
            _isSystemOut = $checkOutage.dataset.systemOut === 'true' ? true : false;

            // For patternlab, if the url has the "system-out=true" parameter, it shows the scenario in which the system is down
            if (coned.utils.isPatternLab()) {
                _isSystemOut = coned.utils.getUrlParameterValue(CONSTANTS.SYSTEM_OUT_PARAMETER) ? 
                    true : false;
            }

            if ($multipleAccountSelect) {
                _selectedAccount = $multipleAccountSelect.dataset.selectedAccount;
            }
            
            $outageStatus = $checkOutage.getElementsByClassName(
                CONSTANTS.OUTAGE_STATUS
            )[0];
            $outageReport = $checkOutage.getElementsByClassName(
                CONSTANTS.OUTAGE_REPORT
            )[0];
            $outageDescription = $checkOutage.getElementsByClassName(
                CONSTANTS.OUTAGE_DESCRIPTION
            )[0];

            if (!_userAuthenticated) {
                $findAccountSelect = $checkOutage.getElementsByClassName(
                    CONSTANTS.ACCOUNT_OPTION_SELECT
                )[0];
                $validItems = $checkOutage.getElementsByClassName(CONSTANTS.VALIDATE_ITEM);
                $findAccountContainer = $checkOutage.getElementsByClassName(
                    CONSTANTS.FIND_ACCOUNT_CONTAINER
                )[0];
                $accountContainer = $checkOutage.getElementsByClassName(
                    CONSTANTS.ACCOUNT_CONTAINER_SELECTOR
                )[0];
                $notMyAddressButton = $checkOutage.getElementsByClassName(
                    CONSTANTS.NOT_MY_ADDRESS_BUTTON
                )[0];
                $addressLookupForm = $checkOutage.getElementsByClassName(
                    CONSTANTS.ADDRESS_LOOKUP_FORM
                )[0];
                $addressSelectedDetail = $addressLookupForm.getElementsByClassName(
                    CONSTANTS.ADDRESS_SELECTED_DETAIL_CLASS
                )[0];
                $continueButton = $addressLookupForm.getElementsByClassName(
                    CONSTANTS.CONTINUE_BUTTON
                )[0];
                $autocompleteZipCode = $addressLookupForm.getElementsByClassName(
                    CONSTANTS.AUTOCOMPLETE_ZIPCODE
                )[0];
                $autocompleteSelectedAddressContainer = $addressLookupForm.getElementsByClassName(
                    CONSTANTS.AUTOCOMPLETE_ADDRESS_SELECTED_CONTAINER
                )[0];
                $autocompleteAparmentUnitInput = $addressLookupForm.getElementsByClassName(
                    CONSTANTS.AUTOCOMPLETE_APARMENT_UNIT
                )[0];
                $lookupServiceError = $checkOutage.getElementsByClassName(
                    CONSTANTS.LOOKUP_ERROR_SELECTOR
                )[0];
                $formLoading = $checkOutage.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
                $submitAnimationContainer = $checkOutage.getElementsByClassName(
                    CONSTANTS.SUBMIT_ANIMATION_CONTAINER
                )[0];
                $recaptcha = $checkOutage.getElementsByClassName(CONSTANTS.RECAPTCHA)[0];
                _validForm = true;
            }
        };

        var initializeEvents = function () {
            if ($multipleAccountSelect) {
                $multipleAccountSelect.addEventListener('change', multipleAccountOptions);

                if(_selectedAccount) {
                    _.each($multipleAccountSelect.options, function ($option) {
                        if ($option.value == _selectedAccount) {
                            $option.selected = 'selected';
                        }
                    });
                    query.fireEvent($multipleAccountSelect,'change');
                }else {
                    // Set the first option of the select by default
                    selectFirstOptionByDefault();
                }
            }

            if (!_userAuthenticated) {
                $findAccountSelect.addEventListener('change', findAccountOptions);
                coned.utils.addGeneralListeners($notMyAddressButton, checkDifferentAccount);
                coned.utils.addGeneralListeners($notMyAddressConfirmationButton, checkDifferentAccount);

                // Address Lookup
                if ($autocompleteAparmentUnitInput) {
                    $autocompleteAparmentUnitInput.addEventListener(
                        'selectedOptionEnd',
                        checkOutageSubmit
                    );
                    $autocompleteAparmentUnitInput.addEventListener(
                        'checkAddress',
                        checkOutageSubmit
                    );
                }

                if ($autocompleteZipCode) {
                    $autocompleteZipCode.addEventListener('invalidZip', function () {
                        _validForm = false;
                    });
                }

                if ($continueButton) {
                    coned.utils.addGeneralListeners($continueButton, function () {
                        var formStatus = $($checkOutage).valid();

                        if (formStatus && _validForm) {
                            if (coned.utils.isPatternLab()) {
                                query.getData(
                                    coned.plConstants.GET_LOOKUP_ACCOUNT,
                                    setAccount,
                                    errorLookupAccountServiceCallback
                                );
                            } else {
                                setAccount(_data);
                            }
                            $submitAnimationContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                            window.scrollTo(0, 0);
                        } else {
                            $($checkOutage).validate();
                        }
                    });
                }

                if (_isSystemOut) {
                    $findAccountSelect.value = "zipcode"; // Pre-select de zipcode option
                    query.fireEvent($findAccountSelect,'change');
                    $findAccountSelect.classList.add(CONSTANTS.HIDDEN_CLASS); // Hide the select
                }

            } else {
                coned.utils.addGeneralListeners($notMyAddressConfirmationButton, checkDifferentAccountLogged);
            }

            // submit handler
            new coned.components.ValidateForm('.' + CONSTANTS.CHECK_OUTAGE_FORM, checkOutageSubmit);

            recaptcha = new coned.components.Recaptcha($checkOutage, checkOutageOnSubmision, checkOutageOnSubmision);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CheckOutageStatus.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CheckOutageStatus;
})();