// ==================== ENROLL UNITS COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.EnrollUnitsModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HIDDEN_CLASS: 'hidden',
        CHANGE_EVENT: 'change',
        RESIZE_EVENT: 'resize',
        DISABLED_ATTRIBUTE: 'disabled',

        ARTICLE_HEADER_CLASS: 'js-article-header',
        SLIDER_PANEL_CLASS: 'js-slider-panel',

        UNIT_LIST_STEP_CLASS: 'js-step-unit-list',
        UNIT_LIST_CLEAR_CLASS: 'js-unit-list-cancel',
        UNIT_LIST_SELECT_ALL_CLASS: 'js-unit-list-select-all',
        UNIT_LIST_SUBMIT_CLASS: 'js-unit-list-submit',
        UNIT_LIST_SUBMIT_NUMBER_CLASS: 'js-unit-list-number',
        UNIT_LIST_UNITS_CLASS: 'js-enroll-unit-list',
        UNIT_LIST_CHEKBOX_CLASS: 'js-enroll-unit-checkbox',
        CONED_CHECKBOX_CHECK_CLASS: 'coned-checkbox--checked',

        CONFIRMATION_STEP_CLASS: 'js-step-confirmation',
        CONFIRMATION_SHOW_MORE_CLASS: 'js-enroll-confirmation-show-more',
        CONFIRMATION_TEXT_CLASS: 'js-enroll-confirmation-text',
        CONFIRMATION_TEXT_FULL_CLASS: 'enroll-units-confirmation__list-text--visible',
        CONFIRMATION_CONFIRM_CLASS: 'js-enroll-confirmation-button',
        CONFIRMATION_CANCEL_CLASS: 'js-enroll-cancel-button',
        CONFIRMATION_ADDRESS_CLASS: 'js-enroll-confirmation-address',
        CONFIRMATION_PARTY_CLASS: 'js-enroll-confirmation-party',
        CONFIRMATION_NUMBER_CLASS: 'js-enroll-confirmation-number',

        ANIMATION_STEP_CLASS: 'js-step-animation',
        ANIMATION_SUBMIT_MODULE: 'js-submit-progress-animation',
        ANIMATION_CURRENT_CLASS: 'js-enroll-animation-current',
        ANIMATION_TOTAL_CLASS: 'js-enroll-animation-total',
        ANIMATION_UNITS_TO_SEND: 10,

        STATUS_STEP_CLASS: 'js-step-status',
        STATUS_SUCCESS_CLASS: 'js-enroll-status-success',
        STATUS_SUCCESS_LIST_CLASS: 'js-enroll-status-success-list',
        STATUS_SUCCESS_NUMBER_CLASS: 'js-enroll-status-success-number',
        STATUS_FAILED_CLASS: 'js-enroll-status-failed',
        STATUS_FAILED_LIST_CLASS: 'js-enroll-status-failed-list',
        STATUS_FAILED_NUMBER_CLASS: 'js-enroll-status-failed-number',
        STATUS_ADDRESS_CLASS: 'js-enroll-status-address',
        STATUS_SUCCESS_MORE_CLASS: 'js-status-success-show-more',
        STATUS_FAILED_MORE_CLASS: 'js-status-failed-show-more',

        STATUS_LIST_COMPLETE: 'success-banner__span--visible'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var EnrollUnitsModule = function ($module) {
        /**
         * PRIVATE METHODS
         */
        //STEP 1
        var $unitListStep,
            $unitListClear,
            $unitListSelectAll,
            $unitListSubmit,
            $unitListSelectedNumber,
            $unitListUnits,
            $unitListCheckboxes,
            $articleHeader,
            $sliderPanel,
            //STEP 2
            $confirmationStep,
            $confirmationShowMore,
            $confirmationText,
            $confirmationButton,
            $confirmationCancel,
            $confirmationAddress,
            $confirmationParty,
            $confirmationNumber,
            //STEP 3
            _enrollUnitsCallService,
            $animationStep,
            $submitAnimation,
            $animationCurrentUnits,
            $animationTotalUnits,
            //STEP 4
            $statusStep,
            $statusSuccess,
            $statusSuccessNumber,
            $statusSuccessList,
            $statusSuccessMore,
            $statusFailed,
            $statusFailedNumber,
            $statusFailedList,
            $statusFailedMore,
            $statusAddress,
            // GENERAL
            _parentMaid,
            _resizeTimeout,
            _address,
            _party,
            _first,
            _unitList,
            _unitListToSend,
            _unitListCounter,
            _unitListPercentage,
            _unitListPercentageCounter,
            _successUnitList,
            _failedUnitList;

        //STEP 4
        var checkStatusEllipsis = function () {
            if ($statusFailedList) {
                if (coned.utils.isEllipsisActive($statusFailedList)) {
                    $statusFailedMore.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $statusFailedMore.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }

            if ($statusSuccessList) {
                if (coned.utils.isEllipsisActive($statusSuccessList)) {
                    $statusSuccessMore.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $statusSuccessMore.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }

            $statusStep.focus();
        };

        var attachStatusEvents = function () {
            coned.utils.addGeneralListeners($statusSuccessMore, function (event) {
                onClickShowMore(event, $statusSuccessMore, $statusSuccessList);
            });
            coned.utils.addGeneralListeners($statusFailedMore, function (event) {
                onClickShowMore(event, $statusFailedMore, $statusFailedList);
            });
        };

        var onClickShowMore = function (event, $button, $unitList) {
            event.preventDefault();

            $unitList.classList.add(CONSTANTS.STATUS_LIST_COMPLETE);
            $button.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        // STEP 3
        var postEnrollUnitsCall = function () {
            var params = {},
                unitIdListToSend,
                reminder;

            // take first 10 results
            if (_unitList.length) {
                if ($animationCurrentUnits) {
                    if (_first) {
                        _unitListCounter = Math.floor(
                            _unitList.length / CONSTANTS.ANIMATION_UNITS_TO_SEND
                        );
                        reminder = _unitList.length % CONSTANTS.ANIMATION_UNITS_TO_SEND;

                        if (reminder > 0) {
                            _unitListCounter++;
                        }

                        _unitListPercentage = Math.floor(100 / _unitListCounter);
                        _first = false;

                        if (_unitListPercentage !== 100) {
                            _unitListPercentageCounter += _unitListPercentage;
                            $animationCurrentUnits.innerHTML =
                                '(' + _unitListPercentageCounter + '%)';
                        }
                    } else {
                        _unitListPercentageCounter += _unitListPercentage;
                        $animationCurrentUnits.innerHTML = '(' + _unitListPercentageCounter + '%)';
                    }
                }

                _unitListToSend = _unitList.splice(0, CONSTANTS.ANIMATION_UNITS_TO_SEND);
                unitIdListToSend = _unitListToSend.map(function (unit) {
                    return unit.id;
                });

                params = {
                    ParentMaid: _parentMaid,
                    Children: unitIdListToSend
                };

                params = JSON.stringify(params);

                // and send request call.
                query.postData(
                    _enrollUnitsCallService,
                    successEnrollUnitsCall,
                    errorEnrollUnitsCall,
                    params,
                    true
                );
            } else {
                finishSubmitAnimation();
            }
        };

        var finishSubmitAnimation = function () {
            $submitAnimation.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        var successEnrollUnitsCall = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.POST_ENROLL_UNIT_LIST,
                    processEnrollUnitsCallData,
                    errorEnrollUnitsCall,
                    null
                );
            } else {
                processEnrollUnitsCallData(data);
            }
        };

        var processEnrollUnitsCallData = function (data) {
            // keep track of success and failed units.
            var successUnitsSent, failedUnitsSent;

            successUnitsSent = _unitListToSend.filter(function (unit) {
                return data.ProcessedUnits.indexOf(unit.id) > -1;
            });

            failedUnitsSent = _unitListToSend.filter(function (unit) {
                return data.NotProcessedUnits.indexOf(unit.id) > -1;
            });

            _successUnitList = _successUnitList.concat(successUnitsSent);
            _failedUnitList = _failedUnitList.concat(failedUnitsSent);

            if (coned.utils.isPatternLab()) {
                setTimeout(function () {
                    postEnrollUnitsCall();
                }, 2000);
            } else {
                postEnrollUnitsCall();
            }
        };

        var errorEnrollUnitsCall = function () {
            // all enroll units sent failed.
            _failedUnitList = _failedUnitList.concat(_unitListToSend);

            postEnrollUnitsCall();
        };

        var moveToStatusStep = function () {
            var successListArray, successListValues, failedListArray, failedListValues;

            // change address
            Array.prototype.forEach.call($statusAddress, function ($address) {
                $address.innerHTML = _address;
            });

            // check number of success units
            if (_successUnitList.length) {
                $statusSuccess.classList.remove(CONSTANTS.HIDDEN_CLASS);

                if ($statusSuccessNumber) {
                    $statusSuccessNumber.innerHTML = _successUnitList.length;
                }

                if ($statusSuccessList) {
                    successListArray = _successUnitList.map(function (element) {
                        return element.name;
                    });

                    successListValues = successListArray.join(', ');
                    $statusSuccessList.innerHTML = successListValues;
                }
            }

            // check number of failed units
            if (_failedUnitList.length) {
                $statusFailed.classList.remove(CONSTANTS.HIDDEN_CLASS);

                if ($statusFailedNumber) {
                    $statusFailedNumber.innerHTML = _failedUnitList.length;
                }

                if ($statusFailedList) {
                    failedListArray = _failedUnitList.map(function (element) {
                        return element.name;
                    });

                    failedListValues = failedListArray.join(', ');
                    $statusFailedList.innerHTML = failedListValues;
                }

                statusFailedAnalytics();
            } else {
                statusAllEnrolledAnalytics();
            }

            $animationStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            $statusStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
            checkStatusEllipsis();
            coned.utils.scrollTo(0, 0);
        };

        var statusAllEnrolledAnalytics = function () {
            // Analytics data building
            dataLayer.push({
                event: 'lofll',
                lofll: 'step2.6.1'
            });
        };

        var statusFailedAnalytics = function () {
            // Analytics data building
            dataLayer.push({
                event: 'lofll',
                lofll: 'step2.6.2'
            });
        };

        // STEP 2
        var attachConfirmationEvents = function () {
            if ($confirmationShowMore) {
                coned.utils.addGeneralListeners($confirmationShowMore, onClickConfirmationShowMore);
            }
            coned.utils.addGeneralListeners($confirmationButton, onClickConfirmationSubmit);
            coned.utils.addGeneralListeners($confirmationCancel, onClickConfirmationCancel);
        };

        var onClickConfirmationShowMore = function (event) {
            event.preventDefault();

            query.addClass($confirmationText, CONSTANTS.CONFIRMATION_TEXT_FULL_CLASS);
            query.addClass($confirmationShowMore, CONSTANTS.HIDDEN_CLASS);
        };

        var animationAnalytics = function () {
            // Analytics data building
            dataLayer.push({
                event: 'lofll',
                lofll: 'step2.5'
            });
        };

        var onClickConfirmationSubmit = function (event) {
            event.preventDefault();

            animationAnalytics();

            if ($animationTotalUnits) {
                $animationTotalUnits.innerHTML = _unitList.length;
            }

            query.removeClass($animationStep, CONSTANTS.HIDDEN_CLASS);
            query.addClass($confirmationStep, CONSTANTS.HIDDEN_CLASS);
            coned.utils.scrollTo(0, 0);
            startEnrollUnitsModuleService();
            $animationStep.focus();
        };

        var onClickConfirmationCancel = function (event) {
            event.preventDefault();

            $confirmationStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            if ($articleHeader) {
                $articleHeader.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
            if ($sliderPanel) {
                $sliderPanel.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
            $unitListStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
            coned.utils.scrollTo(0, 0);
        };

        var startEnrollUnitsModuleService = function () {
            new coned.components.SubmitAnimation(
                $submitAnimation,
                postEnrollUnitsCall,
                moveToStatusStep,
                moveToStatusStep,
                false,
                false
            );
        };

        // STEP 1
        var attachUnitListEvents = function () {
            coned.utils.addGeneralListeners($unitListSubmit, onSubmitUnitList);
            coned.utils.addGeneralListeners($unitListClear, onClickClearList);
            coned.utils.addParentListener(
                $unitListUnits,
                [CONSTANTS.CHANGE_EVENT],
                CONSTANTS.UNIT_LIST_CHEKBOX_CLASS,
                onCheckUnit
            );
            $unitListSelectAll.addEventListener(CONSTANTS.CHANGE_EVENT, onClickSelectAllUnits);
        };

        var onClickSelectAllUnits = function (event) {
            event.preventDefault();

            var $checkbox = event.target,
                $elegibleUnits;

            _unitList = [];
            if ($checkbox.checked) {
                $elegibleUnits = Array.prototype.filter.call($unitListCheckboxes, function ($unit) {
                    var disabled = $unit.getAttribute(CONSTANTS.DISABLED_ATTRIBUTE);

                    if (disabled && disabled === 'disabled') {
                        return false;
                    } else {
                        return true;
                    }
                });
                query.addClass(
                    $unitListSelectAll.parentElement,
                    CONSTANTS.CONED_CHECKBOX_CHECK_CLASS
                );
                Array.prototype.forEach.call($elegibleUnits, function ($checkbox) {
                    var data = {
                        id: $checkbox.dataset.unitId,
                        name: $checkbox.dataset.unitName
                    };
                    _unitList.push(data);
                    query.addClass($checkbox.parentElement, CONSTANTS.CONED_CHECKBOX_CHECK_CLASS);
                    $checkbox.checked = true;
                });
                $unitListSubmit.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
            } else {
                query.removeClass(
                    $unitListSelectAll.parentElement,
                    CONSTANTS.CONED_CHECKBOX_CHECK_CLASS
                );
                Array.prototype.forEach.call($unitListCheckboxes, function ($checkbox) {
                    query.removeClass(
                        $checkbox.parentElement,
                        CONSTANTS.CONED_CHECKBOX_CHECK_CLASS
                    );
                    $checkbox.checked = false;
                });
                $unitListSubmit.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, true);
            }
            changeUnitListNumber();
        };

        var onClickClearList = function (event) {
            event.preventDefault();

            _unitList = [];
            Array.prototype.forEach.call($unitListCheckboxes, function ($checkbox) {
                query.removeClass($checkbox.parentElement, CONSTANTS.CONED_CHECKBOX_CHECK_CLASS);
                $checkbox.checked = false;
            });

            if ($unitListSelectAll) {
                $unitListSelectAll.checked = false;
                query.removeClass(
                    $unitListSelectAll.parentElement,
                    CONSTANTS.CONED_CHECKBOX_CHECK_CLASS
                );
            }

            $unitListSubmit.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, true);
            changeUnitListNumber();
        };

        var onCheckUnit = function (target, event) {
            event.preventDefault();

            var checkbox = target,
                checkboxId = checkbox.dataset.unitId,
                position,
                data;

            if (checkbox.checked) {
                data = {
                    id: checkboxId,
                    name: checkbox.dataset.unitName
                };
                _unitList.push(data);
            } else {
                position = _unitList.findIndex(function (element) {
                    return element.id == checkboxId;
                });

                if (position > -1) {
                    _unitList.splice(position, 1);
                }

                if ($unitListSelectAll) {
                    $unitListSelectAll.checked = false;
                    query.removeClass(
                        $unitListSelectAll.parentElement,
                        CONSTANTS.CONED_CHECKBOX_CHECK_CLASS
                    );
                }
            }

            if (_unitList.length === 0) {
                $unitListSubmit.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, true);
            } else {
                $unitListSubmit.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
            }

            changeUnitListNumber();
        };

        var changeUnitListNumber = function () {
            if ($unitListSelectedNumber) {
                if (_unitList.length) {
                    $unitListSelectedNumber.innerHTML = '(' + _unitList.length + ')';
                } else {
                    $unitListSelectedNumber.innerHTML = '';
                }
            }
        };

        var fillConfirmationData = function () {
            var unitListNumbersList, unitListNumbersString;

            if ($confirmationText) {
                unitListNumbersList = _unitList.map(function (unit) {
                    return unit.name;
                });
                unitListNumbersString = unitListNumbersList.join(', ');

                $confirmationText.innerHTML = unitListNumbersString;
                query.removeClass($confirmationText, CONSTANTS.CONFIRMATION_TEXT_FULL_CLASS);
            }

            if ($confirmationAddress) {
                $confirmationAddress.innerHTML = _address;
            }
            if ($confirmationParty) {
                $confirmationParty.innerHTML = _party;
            }
            if ($confirmationNumber) {
                $confirmationNumber.innerHTML = '(' + _unitList.length + ')';
            }

            if ($confirmationShowMore) {
                query.removeClass($confirmationShowMore, CONSTANTS.HIDDEN_CLASS);
            }
        };

        var checkUnitListEllipsis = function () {
            if ($confirmationText) {
                if (coned.utils.isEllipsisActive($confirmationText)) {
                    $confirmationShowMore.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $confirmationShowMore.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }
        };

        var submitUnitAnalytics = function () {
            // Analytics data building
            dataLayer.push({
                event: 'lofll',
                lofll: _unitList.length
            });
        };

        var onSubmitUnitList = function (event) {
            event.preventDefault();

            if (_unitList.length) {
                // move to the confirmation step
                // fill confirmation step with data provided in step 1.
                submitUnitAnalytics();

                fillConfirmationData();
                $confirmationStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                if ($articleHeader) {
                    $articleHeader.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                if ($sliderPanel) {
                    $sliderPanel.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                $unitListStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                checkUnitListEllipsis();
                coned.utils.scrollTo(0, 0);
                $confirmationStep.focus();
            }
        };

        // GENERAL
        var initializeData = function () {
            // init variables
            _enrollUnitsCallService = $module.dataset.unitsService;
            _parentMaid = $module.dataset.parentMaid;
            _address = $module.dataset.address;
            _party = $module.dataset.party;

            (_first = true),
                (_unitList = []),
                (_unitListToSend = []),
                (_unitListCounter = 0),
                (_unitListPercentage = 0),
                (_unitListPercentageCounter = 0),
                (_successUnitList = []),
                (_failedUnitList = []);

            // init elements
            $articleHeader = document.getElementsByClassName(CONSTANTS.ARTICLE_HEADER_CLASS)[0];
            $sliderPanel = document.getElementsByClassName(CONSTANTS.SLIDER_PANEL_CLASS)[0];

            $unitListStep = $module.getElementsByClassName(CONSTANTS.UNIT_LIST_STEP_CLASS)[0];
            $unitListClear = $unitListStep.getElementsByClassName(
                CONSTANTS.UNIT_LIST_CLEAR_CLASS
            )[0];
            $unitListSelectAll = $unitListStep.getElementsByClassName(
                CONSTANTS.UNIT_LIST_SELECT_ALL_CLASS
            )[0];
            $unitListSubmit = $unitListStep.getElementsByClassName(
                CONSTANTS.UNIT_LIST_SUBMIT_CLASS
            )[0];
            $unitListSelectedNumber = $unitListStep.getElementsByClassName(
                CONSTANTS.UNIT_LIST_SUBMIT_NUMBER_CLASS
            )[0];
            $unitListUnits = $unitListStep.getElementsByClassName(
                CONSTANTS.UNIT_LIST_UNITS_CLASS
            )[0];
            $unitListCheckboxes = $unitListStep.getElementsByClassName(
                CONSTANTS.UNIT_LIST_CHEKBOX_CLASS
            );

            $confirmationStep = $module.getElementsByClassName(
                CONSTANTS.CONFIRMATION_STEP_CLASS
            )[0];
            $confirmationShowMore = $confirmationStep.getElementsByClassName(
                CONSTANTS.CONFIRMATION_SHOW_MORE_CLASS
            )[0];
            $confirmationButton = $confirmationStep.getElementsByClassName(
                CONSTANTS.CONFIRMATION_CONFIRM_CLASS
            )[0];
            $confirmationCancel = $confirmationStep.getElementsByClassName(
                CONSTANTS.CONFIRMATION_CANCEL_CLASS
            )[0];
            $confirmationText = $confirmationStep.getElementsByClassName(
                CONSTANTS.CONFIRMATION_TEXT_CLASS
            )[0];
            $confirmationAddress = $confirmationStep.getElementsByClassName(
                CONSTANTS.CONFIRMATION_ADDRESS_CLASS
            )[0];
            $confirmationParty = $confirmationStep.getElementsByClassName(
                CONSTANTS.CONFIRMATION_PARTY_CLASS
            )[0];
            $confirmationNumber = $confirmationStep.getElementsByClassName(
                CONSTANTS.CONFIRMATION_NUMBER_CLASS
            )[0];

            $animationStep = $module.getElementsByClassName(CONSTANTS.ANIMATION_STEP_CLASS)[0];
            $submitAnimation = $animationStep.getElementsByClassName(
                CONSTANTS.ANIMATION_SUBMIT_MODULE
            )[0];
            $animationCurrentUnits = $animationStep.getElementsByClassName(
                CONSTANTS.ANIMATION_CURRENT_CLASS
            )[0];
            $animationTotalUnits = $animationStep.getElementsByClassName(
                CONSTANTS.ANIMATION_TOTAL_CLASS
            )[0];

            $statusStep = $module.getElementsByClassName(CONSTANTS.STATUS_STEP_CLASS)[0];
            $statusSuccess = $statusStep.getElementsByClassName(CONSTANTS.STATUS_SUCCESS_CLASS)[0];
            $statusSuccessNumber = $statusStep.getElementsByClassName(
                CONSTANTS.STATUS_SUCCESS_NUMBER_CLASS
            )[0];
            $statusSuccessList = $statusStep.getElementsByClassName(
                CONSTANTS.STATUS_SUCCESS_LIST_CLASS
            )[0];
            $statusFailed = $statusStep.getElementsByClassName(CONSTANTS.STATUS_FAILED_CLASS)[0];
            $statusFailedNumber = $statusStep.getElementsByClassName(
                CONSTANTS.STATUS_FAILED_NUMBER_CLASS
            )[0];
            $statusFailedList = $statusStep.getElementsByClassName(
                CONSTANTS.STATUS_FAILED_LIST_CLASS
            )[0];
            $statusAddress = $statusStep.getElementsByClassName(CONSTANTS.STATUS_ADDRESS_CLASS);
            $statusSuccessMore = $statusStep.getElementsByClassName(
                CONSTANTS.STATUS_SUCCESS_MORE_CLASS
            )[0];
            $statusFailedMore = $statusStep.getElementsByClassName(
                CONSTANTS.STATUS_FAILED_MORE_CLASS
            )[0];
        };

        var initializeEvents = function () {
            // resize events
            window.addEventListener(CONSTANTS.RESIZE_EVENT, resizeThrottler, false);

            attachUnitListEvents();
            attachConfirmationEvents();
            attachStatusEvents();
        };

        var initializeAnalytics = function () {
            // Analytics data building
            dataLayer.push({
                event: 'lofll',
                lofll: 'step2.2'
            });
        };

        var resizeThrottler = function () {
            // ignore resize events as long as an actualResizeHandler execution is in the queue
            if (!_resizeTimeout) {
                _resizeTimeout = setTimeout(function () {
                    _resizeTimeout = null;
                    checkUnitListEllipsis();
                    checkStatusEllipsis();
                    // The functions will execute at a rate of 15fps
                }, 66);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            initializeAnalytics();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    EnrollUnitsModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return EnrollUnitsModule;
})();
