// ==================== DASHBOARD LIFE SAVING EQUIPMENT COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardLifeSavingEquipment = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        LSE_SECTION: 'js-life-saving-equipment',
        FORM: 'js-life-saving-form',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        MANAGE_SELECTOR: 'js-manage-selector',
        ENROLL_SELECTOR: 'js-enroll-selector',
        ENROLLMENT_STATUS_SELECTOR: 'js-life-saving-enrollment-status',
        REVIEW_SELECTOR: 'js-life-saving-review',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        LINK_DESCRIPTION: 'js-link-description',
        LINK_ACCORDION_CLOSE: 'js-accordion-close',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        ALERT_CLASS: 'manage-enroll__list--alert',
        ICON_STATUS_TEXT: 'icon-status-text',
        REVIEW_EQUIPMENT_SELECTOR: 'js-equipment-list-selector',
        REVIEW_CHECKBOX_EQUIPMENT: 'js-equipment-checkbox-selector',
        REVIEW_CHECKBOX_INDICATOR: 'js-checkbox-name-selector',
        REVIEW_OTHER_EQUIPMENT: 'js-other-equipment-selector',
        ENROLL_SUCCESS_SELECTOR: 'js-life-saving-success',
        EQUIPMENT_USED_HOURS: 'js-equipment-used-hours',
        DATA_ENROLLED: 'data-enrolled',
        HIDDEN_CLASS: 'hidden',
        FORM_SCID: 'scId',
        FORM_UPDATE_ACCOUNT_TYPE: 'LSE',
        FORM_TANK_RESPIRATOR: 'tankRespirator',
        FORM_CUIRASS_RESPIRATOR: 'cuirassRespirator',
        FORM_ROCKING_BED: 'rockingBed',
        FORM_ELECTRIC_OPERATED: 'electricalOperated',
        FORM_APNEA_MONITOR: 'apnaeMonitor',
        FORM_HEMODIALYSIS: 'hemodialysisEquipment',
        FORM_OTHER: 'optionOther',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        HEADER: 'js-header-wrapper',
        UPDATED_MESSAGE: 'js-updated-message',
        REAPPLY: 'js-reapply',
        FOCUS_PLACEHOLDER_CLASS: 'js-focus-placeholder',
        LSE_PARAMETER_NAME: 'lse',

        // Lazy Load
        LAZY_LOAD_CLASS: 'js-lazy-load',
        LAZY_LOAD_TAB_CLASS: 'js-lazy-load-manage-my-account',
        LAZY_LOAD_LOADED_CLASS: 'js-lazy-load-element-loaded'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardLifeSavingEquipment = function ($lifeSupportForm) {
        /**
         * PRIVATE METHODS
         */
        var $accordionSelector,
            $linkAccordionSelector,
            $reviewSelector,
            $manageSelector,
            $enrollSelector,
            $enrollmentStatus,
            $reviewEquipmentSelector,
            $reviewCheckboxEquipment,
            $reviewOtherEquipment,
            $enrollSuccessSelector,
            $header,
            $formLoading,
            $accountMaidInput,
            $serviceError,
            $serviceErrorMessage,
            $updateMessage,
            $iconStatusTextSpan,
            $lazyLoadTab,
            $reapplyButton,
            $focusPlaceholder,
            _isLazyLoad;

        var onSubmitForm = function () {
            var $reviewCheckboxLabelSelector,
                reviewCheckboxLabelValue = '',
                counter = 0,
                addComma = '';

            $enrollSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $reviewSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Set checkbox list
            _.each($reviewCheckboxEquipment, function ($checkbox) {
                $reviewCheckboxLabelSelector = $checkbox.parentNode.parentNode.getElementsByClassName(
                    CONSTANTS.REVIEW_CHECKBOX_INDICATOR
                )[0];
                if ($checkbox.checked) {
                    if (counter > 0) {
                        reviewCheckboxLabelValue += ', ';
                    }

                    reviewCheckboxLabelValue += $reviewCheckboxLabelSelector.innerHTML;
                    counter++;
                }
            });

            // Other Equipment
            if ($reviewOtherEquipment.value !== '') {
                if (counter > 0) {
                    addComma = ', ';
                }

                reviewCheckboxLabelValue += addComma + $reviewOtherEquipment.value;
            }

            // Update data
            $reviewEquipmentSelector.innerHTML = reviewCheckboxLabelValue;
            query.scrollToElement($lifeSupportForm, $header);
        };

        var sendReviewForm = function (event) {
            event.preventDefault();

            var serviceUrl = $lifeSupportForm.dataset.serviceUrl,
                params;

            hideError();

            // Service Data
            params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($lifeSupportForm, CONSTANTS.FORM_SCID),
                Tank: query.getFormInputValueById($lifeSupportForm, CONSTANTS.FORM_TANK_RESPIRATOR),
                Curass: query.getFormInputValueById(
                    $lifeSupportForm,
                    CONSTANTS.FORM_CUIRASS_RESPIRATOR
                ),
                RockingBed: query.getFormInputValueById(
                    $lifeSupportForm,
                    CONSTANTS.FORM_ROCKING_BED
                ),
                ElecOperated: query.getFormInputValueById(
                    $lifeSupportForm,
                    CONSTANTS.FORM_ELECTRIC_OPERATED
                ),
                Apnea: query.getFormInputValueById($lifeSupportForm, CONSTANTS.FORM_APNEA_MONITOR),
                Herneo: query.getFormInputValueById($lifeSupportForm, CONSTANTS.FORM_HEMODIALYSIS),
                Other: query.getFormInputValue($lifeSupportForm, CONSTANTS.FORM_OTHER),
                UsedSleepingHours: query.getFormInputValueById(
                    $lifeSupportForm,
                    CONSTANTS.FORM_USED_SLEEPING_HOURS
                ),
                SleepingHoursPerDay: query.getFormInputValueById(
                    $lifeSupportForm,
                    CONSTANTS.FORM_SLEEPING_HOURS_DAY
                )
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successLifeSupport,
                errorLifeSupport,
                params,
                true,
                $formLoading
            );
        };

        var hideError = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var successLifeSupport = function () {
            $reviewSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $enrollSuccessSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $lifeSupportForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
            $lifeSupportForm.classList.add(CONSTANTS.ALERT_CLASS);
            query.scrollToElement($lifeSupportForm, $header);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success',
                contentTitle: 'life-support'
            });

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($lifeSupportForm);

            $accordionSelector.dataset.openName = $accordionSelector.dataset.pending;
        };

        var errorLifeSupport = function (data) {
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var enrollSuccess = function (event) {
            event.preventDefault();

            $updateMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $accordionSelector.dataset.statement = 'true';
            $linkAccordionSelector.click();
            $focusPlaceholder.focus();
            query.scrollToElement($lifeSupportForm, $header);
            setStatement();
        };

        var setStatement = function () {
            var statementFlag = $accordionSelector.dataset.statement;

            if (coned.utils.isOru()) {
                if (statementFlag === 'false') {
                    $manageSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $enrollSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $manageSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $lifeSupportForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                    $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                        CONSTANTS.DATA_ENROLLED
                    );
                    $linkAccordionSelector.getElementsByClassName(
                        CONSTANTS.LINK_DESCRIPTION
                    )[0].innerHTML = $accordionSelector.dataset.manage;
                    $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
                }
            } else {
                $lifeSupportForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                $lifeSupportForm.classList.remove(CONSTANTS.ALERT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_ENROLLED
                );
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.manage;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
            }
        };

        var initializeData = function () {
            $accordionSelector = $lifeSupportForm.getElementsByClassName(
                CONSTANTS.ACCORDION_SELECTOR
            )[0];
            $manageSelector = $lifeSupportForm.getElementsByClassName(CONSTANTS.MANAGE_SELECTOR)[0];
            $enrollSelector = $lifeSupportForm.getElementsByClassName(CONSTANTS.ENROLL_SELECTOR)[0];
            $enrollmentStatus = $lifeSupportForm.getElementsByClassName(
                CONSTANTS.ENROLLMENT_STATUS_SELECTOR
            )[0];
            $linkAccordionSelector = $lifeSupportForm.getElementsByClassName(
                CONSTANTS.LINK_ACCORDION_SELECTOR
            )[0];
            $reviewSelector = $lifeSupportForm.getElementsByClassName(CONSTANTS.REVIEW_SELECTOR)[0];
            $reviewEquipmentSelector = $lifeSupportForm.getElementsByClassName(
                CONSTANTS.REVIEW_EQUIPMENT_SELECTOR
            )[0];
            $reviewCheckboxEquipment = $lifeSupportForm.getElementsByClassName(
                CONSTANTS.REVIEW_CHECKBOX_EQUIPMENT
            );
            $reviewOtherEquipment = $lifeSupportForm.getElementsByClassName(
                CONSTANTS.REVIEW_OTHER_EQUIPMENT
            )[0];
            $enrollSuccessSelector = $lifeSupportForm.getElementsByClassName(
                CONSTANTS.ENROLL_SUCCESS_SELECTOR
            )[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $serviceError = $lifeSupportForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceErrorMessage = $lifeSupportForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $updateMessage = $lifeSupportForm.getElementsByClassName(CONSTANTS.UPDATED_MESSAGE)[0];
            $iconStatusTextSpan = $lifeSupportForm.getElementsByClassName(
                CONSTANTS.ICON_STATUS_TEXT
            )[0];
            $reapplyButton = $lifeSupportForm.getElementsByClassName(CONSTANTS.REAPPLY)[0];
            $focusPlaceholder = document.getElementsByClassName(
                CONSTANTS.FOCUS_PLACEHOLDER_CLASS
            )[0];
        };

        // Loads all module data from service
        var loadDataServiceCall = function () {
            if (isLoaded) return;

            var serviceUrl = $lifeSupportForm.dataset.serviceUrl,
                params;

            if (!serviceUrl) {
                errorLoadDataServiceCall();
                return;
            }

            query.getData(serviceUrl, successLoadDataServiceCall, errorLoadDataServiceCall, params);
        };

        // If call succeeded, load markup into module
        var successLoadDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                var scenario = coned.utils.getUrlParameterValue(CONSTANTS.LSE_PARAMETER_NAME) ? 
                                coned.utils.getUrlParameterValue(CONSTANTS.LSE_PARAMETER_NAME) :
                                coned.constants.DEFAULT_SCENARIO;

                var scenarioHTML = coned.plConstants.GET_LSE_SCENARIO_HTML_URL + scenario + coned.constants.HTML_EXTENSION;
                    query.getData(
                        scenarioHTML,
                        setContentMarkup,
                        errorLoadDataServiceCall
                    );
                // SCENARIO #1: LSE enroll form
                // SCENARIO #2 : LSE Enrollment Status Documentation pending
                // SCENARIO #3: LSE Enrollment Status Documentation received
                // SCENARIO #4: LSE Enrollment Status request denied
                // SCENARIO #5: LSE Enrollment Status request approved
                // SCENARIO #6: LSE Enrollment Status service error
                // SCENARIO #7: LSE Enrolled
                // SCENARIO #8: LSE ORU
            } else {
                setContentMarkup(data);
            }
        };

        // If call errored, hide module
        var errorLoadDataServiceCall = function () {
            $lifeSupportForm.classList.add(CONSTANTS.HIDDEN_CLASS);

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $lifeSupportForm.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($lifeSupportForm, 'lazy-load-element-loaded');
            }
        };

        var setContentMarkup = function (data) {
            if (data) {
                var parser = new DOMParser(),
                    HTMLObject,
                    $lseFormContent,
                    newLifeSupportAttributes;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $lseFormContent = HTMLObject.getElementsByClassName(CONSTANTS.LSE_SECTION);

                if (
                    $lseFormContent.length !== 0 &&
                    $lseFormContent[0].dataset.content !== 'false'
                ) {
                    $lseFormContent = $lseFormContent[0];
                } else {
                    errorLoadDataServiceCall();
                    return;
                }

                $lifeSupportForm.innerHTML = $lseFormContent.innerHTML;
                newLifeSupportAttributes = $lseFormContent.attributes;

                while ($lifeSupportForm.attributes.length > 0) {
                    $lifeSupportForm.removeAttribute($lifeSupportForm.attributes[0].name);
                }

                _.each(newLifeSupportAttributes, function (attribute) {
                    $lifeSupportForm.setAttribute(attribute.nodeName, attribute.nodeValue);
                });

                // If data-module is present, initialize modules
                if ($lifeSupportForm.dataset.module) {
                    initializeData();
                    initializeEvents();
                    coned.utils.initializeModules($lifeSupportForm);
                }
            } else {
                errorLoadDataServiceCall();
                return;
            }

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $lifeSupportForm.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($lifeSupportForm, 'lazy-load-element-loaded');
            }

            coned.utils.triggerEvent(window, 'service-loaded');
        };

        var showForm = function (event) {
            event.preventDefault();

            $enrollSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $enrollmentStatus.classList.add(CONSTANTS.HIDDEN_CLASS);
            $lifeSupportForm.classList.add(CONSTANTS.UNENROLLMENT_CLASS);

            $focusPlaceholder.focus();
            query.scrollToElement($enrollSelector, $header);
            $enrollSelector.focus();

            $accordionSelector.dataset.openName = $accordionSelector.dataset.enroll;
        };

        // Checks if module should be lazy-loaded of not
        var preInitialize = function () {
            _isLazyLoad = query.hasClass($lifeSupportForm, CONSTANTS.LAZY_LOAD_CLASS);

            // If module is lazy loaded, wait for event to trigger
            if (_isLazyLoad) {
                $lazyLoadTab = document.getElementsByClassName(CONSTANTS.LAZY_LOAD_TAB_CLASS)[0];

                $lazyLoadTab.addEventListener('lazy-load-start', loadDataServiceCall);
            } else {
                initializeData();
                setStatement();
                initializeEvents();
                isLoaded = true;
            }
        };

        var initializeEvents = function () {
            new coned.components.ValidateForm('.' + CONSTANTS.FORM, onSubmitForm, '');

            if ($reviewSelector !== undefined) {
                $reviewSelector.addEventListener('submit', sendReviewForm);
                $enrollSuccessSelector.addEventListener('submit', enrollSuccess);
            }

            coned.utils.addGeneralListeners($reapplyButton, showForm);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            preInitialize();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardLifeSavingEquipment.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardLifeSavingEquipment;
})();
