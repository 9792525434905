// ==================== DASHBOARD PAYMENT AGREEMENT COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardPaymentAgreement = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_SIGNATURE_SELECTOR: 'js-payment-agreement-signature',
        PAYMENT_AGREEMENT_COMMERCIAL: 'js-payment-agreement-commercial',
        FORM_DPA_SBMT_BTN_SELECTOR: 'js-dpa-sbmt-btn',
        EMAIL_PARAGRAPH: 'js-email-paragraph',
        NO_EMAIL_PARAGRAPH: 'js-no-email-paragraph',
        FORM_SIGNATURE_SBMT_SELECTOR: 'manage-enroll__signature-submit',
        FORM_SIGNATURE_ERROR_SELECTOR: 'coned-field-error-wrapper',
        SIGNATURE_ERROR_COPY: 'js-signature-error__copy',
        FORM_SELECTOR: 'js-payment-agreement',
        SIGNATURE_READY_BODY: 'js-signature-body',
        SIGNATURE_COVID_READY_BODY: 'js-covid-signature-body',
        SIGNATURE_READY_ERROR: 'js-signature-error',
        DPA_SUCCESS_SELECTOR: 'js-signature-ready',
        DPA_EMAIL_TOKEN: 'js-email-token',
        ENROLL_FORM_SELECTOR: 'js-payment-agreement-enroll',
        CANCEL_ENROLL_BUTTON: 'js-cancel-enroll',
        MANAGE_FORM_SELECTOR: 'js-manage-form',
        CLOSE_SELECTOR: 'js-accordion-close',
        COVID_CLOSE_SELECTOR: 'js-accordion-close-covid',
        CLOSE_SELECTOR_ACCEPT: 'js-accordion-close-accept',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        LINK_DESCRIPTION: 'js-link-description',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        HIDDEN_CLASS: 'hidden',
        DISABLED_ATTR: 'disabled',
        FORM_SCID: 'scId',
        CHANGE: 'change',
        FORM_UPDATE_ACCOUNT_TYPE: 'PaymentAgreement',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        DPA_PAYMENT_AGREEMENT: 'js-payment-agreement-dpa',
        DPA_RADIO_CONFIRMATION_INPUT: 'mailMyConfirmation',
        DPA_SIGNATURE_EMAIL: 'signatureEmail',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        EDIT_PAYMENT_AGREEMENT_POPUP: 'js-edit-payment-agreement-popup',
        OPEN_EDIT_PAYMENT_AGREEMENT_POPUP_BUTTON: 'js-open-edit-payment-agreement-popup',
        EDIT_PAYMENT_AGREEMENT_FORM: 'js-edit-payment-agreement-form',
        SCHEDULE_PAYMENT_BUTTON: 'js-schedule-a-payment',
        MAKE_PAYMENT_BUTTON: 'js-make-a-payment',
        MANAGE_SCHEDULE_PAYMENT_BUTTON: 'js-schedule-a-payment-manage',
        MANAGE_ACCEPT_PAYMENT_BUTTON: 'js-accept-and-pay-manage',
        PAYMENT_CANCEL_BUTTON: 'js-accordion-close',
        PAYMENT_AGREEMENT_CHECK_TEXT: 'js-item-title-selector',
        ACCEPT_PAYMENT_AGREEMENT_BUTTON: 'js-accept-payment-agreement',
        PAYMENT_AGREEMENT_ACCEPTED_MESSAGE: 'js-payment-agreement-accepted-message',
        PAYMENT_TABLE_CONTAINER: 'js-payment-table',
        DOWN_PAYMENT_AMOUNT_SPAN: 'js-payment-agreement-down-payment',
        NUMBER_OF_INSTALLMENTS_SPAN: 'js-payment-agreement-number-installments',
        FINAL_PAYMENT_SPAN: 'js-payment-agreement-final-payment-span',
        AMOUNT_PER_INSTALLMENT_SPAN: 'js-payment-agreement-installment-amount-span',
        TOTAL_OUTSTANDING: 'js-payment-agreement-total-outstanding',
        PAY_MY_BILL_OTHER_AMOUNT_URL_PARAM: 'PaymentAmount',
        PAYMENT_AGREEMENT_EDITABLE: 'js-payment-agreement-editable',
        POPUP_INACTIVE_CLASS: 'payment-table__popup',
        PAYMENT_AGREEMENT_TABLE: 'js-payment-agreement-table-wrapper',
        POPUP_ACTIVE_CLASS: 'payment-table__popup--active',
        POPUP_CONTAINER: 'js-popup-container',
        MODIFIED_TERMS_ONLY: 'js-payment-agreement-modified-terms-only',
        DOWN_PAYMENT_DUE_DATE: 'js-payment-agreement-down-payment-due-date',
        DOWNPAYMENT_INPUT_CLASS: 'js-currency-input--downpayment',
        INSTALLMENTS_INPUT_CLASS: 'js-number-input--installments',
        INPUT_ERRORR_CLASS: 'coned-input-message--error',
        SAVE_EDIT_FORM_BUTTON: 'js-save-edit-payment-agreement',
        PAYMENT_AGREEMENT_SAO_SELECTOR: 'js-payment-agreement-sao',
        PAYMENT_AGREEMENT_SAO_MODIFIED_SELECTOR: 'js-payment-agreement-sao-modified-terms',
        PAYMENT_AGREEMENT_MODIFIED_SELECTOR: 'js-payment-agreement-modified-terms-only',
        PAYMENT_AGREEMENT_BNA_RENEGOTIATE_SELECTOR: 'js-payment-agreement-bna-renegotiate',
        BNA_RENEGOTIATE_PAYMENT_AGREEMENT_BANNER_SELECTOR: 'js-bna-renegotiate-payment-agreement-banner',
        BNA_RENEGOTIATE_OVERVIEW_BILL_CARD_VIEW_PLAN_SELECTOR: 'js-bna-renegotiate-overview-bill-card-view-plan',
        BNA_RENEGOTIATE_AGREEMENT_BUTTON: 'js-bna-renegotiate-agreement-btn',
        BNA_RENEGOTIATE_SECTION: 'js-bna-renegotiate',
        BNA_OPTIONS_SECTION: 'js-bna-options',
        SCENARIO_CLASS: 'js-scenario-page',
        ENABLE_EMAIL_SELECTOR: 'js-enable-email',
        DISABLE_EMAIL_SELECTOR: 'js-disable-email',
        DPA_EMAIL_INPUT_SELECTOR: 'js-dpa-input-email',
        DPA_EMAIL_FIELDS_TO_HIDE: 'js-dpa-email-to-hide',
        DPA_HIDDEN: 'js-dpa-hidden',
        ICON_STATUS_TEXT: 'icon-status-text',
        DATA_ENROLLED: 'data-enrolled',
        FILLED_CLASS: 'coned-input--filled',
        ARIA_HIDDEN_ATTR: 'aria-hidden',
        INACTIVE_CLASS: 'manage-enroll__list--inactive',
        ALERT_CLASS: 'manage-enroll__list--alert',
        RADIO_BUTTON_SELECTOR: 'js-coned-radio',
        RADIO_INDICATOR: 'coned-radio__indicator',
        RADIO_INDICATOR_FOCUS: 'coned-radio__indicator--focus',
        TERM_TYPE_SAO: 'SAO',
        TERM_TYPE_PGR: 'PGR',
        TERM_TYPE_COVID: 'COVID',
        BLUR_EVENT: 'blur',
        TABINDEX_ATTRIBUTE: 'tabindex',
        PAGR_PARAMETER_NAME: 'pagr',

        OVERPAYMENT: 'op=true',
        QUESTION_MARK: '?',
        AMPERSAND: '&',

        // COVID-19
        COVID_IDENTIFY_FORM: 'js-payment-agreement-covid-enroll',
        COVID_IDENTIFY_RADIO_BUTTONS: 'js-payment-agreement-covid-identify',
        COVID_IDENTIFY_RADIO_NAME: 'paymentAgreementCovidIdentify',
        COVID_IDENTIFY_ACCEPTED_VALUE: 'paymentAgreementCovidIdentifyAccept',
        COVID_IDENTIFY_REJECTED_VALUE: 'paymentAgreementCovidIdentifyReject',
        COVID_TERMS_RADIO_BUTTONS: 'js-payment-agreement-terms',
        COVID_TERMS_RADIO_NAME: 'paymentAgreementCovidTerms',
        COVID_TERMS_ACCEPTED_VALUE: 'paymentAgreementCovidTermsAccept',
        COVID_TERMS_REJECTED_VALUE: 'paymentAgreementCovidTermsReject',
        COVID_TERMS_ACCEPTED_TABLE: 'js-payment-agreement-terms-covid-table',
        COVID_TERMS_REJECTED_TABLE: 'js-payment-agreement-terms-standard-table',
        COVID_ACCEPTED_MESSAGE: 'js-payment-agreement-covid-accepted-message',
        COVID_HIDE_ELEMENTS: 'js-covid-hide',
        ACTIVE_DATE: 'js-active-date',
        COMMERCIAL: 'commercial',
        SHOW_COVID_TERMS: 'show-covid-terms',
        CONTINUE_BUTTON: 'js-covid-terms-continue',

        // Enrolled Progress bar
        PROGRESS_BAR: 'js-pagr-progress-bar',
        PROGRESS_BAR_SMALL_BAR: 'js-pagr-small-bg',
        PROGRESS_BAR_BIG_BAR: 'js-pagr-big-bg',
        PROGRESS_BAR_INSTALLMENTS_CHECKPOINT: 'js-pagr-installments-checkpoint',
        PROGRESS_BAR_DOWNPAYMENT_CHECKPOINT: 'js-pagr-downpayment-checkpoint',
        PROGRESS_BAR_FINAL_CHECKPOINT: 'js-pagr-final-checkpoint',
        PROGRESS_BAR_AMOUNT: 'js-pagr-bar-amount',
        AMOUNT_CLASS: 'manage-enroll__payment-agreement-progress-bar-amount',
        AMOUNT_GRAYED_SUFFIX: '--grayed',
        BAR_BIG_BAR_CLASS: 'manage-enroll__payment-agreement-progress-bar-big-bg',
        SMALL_BAR_CLASS: 'manage-enroll__payment-agreement-progress-bar-small-bg',
        SMALL_BAR_STARTED_SUFFIX: '--started',
        SMALL_BAR_FINISHED_SUFFIX: '--finished',
        PROGRESS_BAR_CHECKPOINT_CLASS: 'manage-enroll__payment-agreement-progress-bar-checkpoint',
        CHECKPOINT_INSTALLMENTS_SUFFIX: '--installments',
        CHECKPOINT_ACTIVE_SUFFIX: '--active',
        CHECKPOINT_ARROW_ICON: 'icon-arrow',
        CHECKPOINT_CHECK_ICON: 'icon-check-status',

        // Lazy Load
        LAZY_LOAD_CLASS: 'js-lazy-load',
        LAZY_LOAD_TAB_CLASS: 'js-lazy-load-manage-my-account',
        LAZY_LOAD_LOADED_CLASS: 'js-lazy-load-element-loaded',

        // Tagging
        CONTENT_TITLE_TAG: 'payment-agreement',
        START_PAYMENT_AGREEMENT_UNEDITABLE_TAG: 'start.payagmt.1',
        START_PAYMENT_AGREEMENT_EDITABLE_TAG: 'start.payagmt.2',
        PAYMENT_AGREEMENT_FORM_SUCCESS_TAG: 'coned.form.success',
        PAYMENT_AGREEMENT_FORM_MODIFIED_TERMS_TAG: 'coned.modified.terms.payagmt',
        PAYMENT_AGREEMENT_FORM_ERROR_TAG: 'coned.form.error',
        PAYMENT_AGREEMENT_FORM_CANCEL_TAG: 'coned.form.cancel',
        PAYMENT_AGREEMENT_EDIT_TAG: 'edit.payagmt',
        PAYMENT_AGREEMENT_SCHEDULE_TAG: 'schedule.payment',
        PAYMENT_AGREEMENT_MAKE_PAYMENT_TAG: 'make.payment.payagmt',

        //tagging classes DTM APPROACH
        DPA_SBMT_BTN_TAG_CLASS: 'tag-payment-agreement-signature-',

        //taggin props DTM APPROACH
        DPA_CONFIRMATION_TYPE_EMAIL: 'email',
        DPA_CONFIRMATION_TYPE_MAIL: 'mail',

        // scenario 1 tags
        SCENARIO_SAO_CANCEL_TAG: 'tag.sao.cancel',
        SCENARIO_SAO_ACCEPT_TAG: 'tag.sao.accept',
        SCENARIO_SAO_SCHEDULE_TAG: 'tag.sao.schedule',

        // scenario 1 commercial tags
        SCENARIO_COMMERCIAL_CANCEL_TAG: 'tag.commercial.cancel',
        SCENARIO_COMMERCIAL_ACCEPT_TAG: 'tag.commercial.accept',
        SCENARIO_COMMERCIAL_SCHEDULE_TAG: 'tag.commercial.schedule',

        // scenario 2 tags
        SCENARIO_SAO_MODIFIED_CANCEL_TAG: 'tag.sao.modify.cancel',
        SCENARIO_SAO_MODIFIED_ACCEPT_TAG: 'tag.sao-modify.accept',
        SCENARIO_SAO_MODIFIED_EDIT_TAG: 'tag.sao-modify.edit',
        SCENARIO_SAO_MODIFIED_SCHEDULE_TAG: 'tag.sao-modify.schedule',

        // scenario 3 tags
        SCENARIO_MODIFIED_CANCEL_TAG: 'tag.modify.cancel',
        SCENARIO_MODIFIED_ACCEPT_TAG: 'tag.modify.accept',
        SCENARIO_MODIFIED_EDIT_TAG: 'tag.modify.edit',
        SCENARIO_MODIFIED_SCHEDULE_TAG: 'tag.modify.schedule',
        SCENARIO_MODIFIED_COMMERCIAL_ACCEPT_TAG: 'tag.modify.commercial.accept'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardPaymentAgreement = function ($paymentAgreementForm) {
        /**
         * PRIVATE METHODS
         */
        var $lazyLoadTab,
            $dpaHidden,
            $dpaSubmtBtn,
            $noEmailParagraphSelector,
            $emailParagraphSelector,
            $dpaPaymentAgreement,
            $dpaSignatureReadyBody,
            $dpaSignatureCovidReadyBody,
            $dpaSignatureReadyError,
            $closeSelectors,
            $formSignatureSubmtSelector,
            $formSignatureSelector,
            $dpaEmailSelector,
            $dpaEmailFieldsToHide,
            $enableEmailSelector,
            $disableEmailSelector,
            $linkAccordionSelector,
            $accordionSelector,
            $enrollFormSelector,
            $formSignatureErrorSelector,
            $manageFormSelector,
            $serviceError,
            $serviceErrorMessage,
            $accountMaidInput,
            $formLoading,
            $editPaymentAgreementPopup,
            $openEditPaymentAgreementPopupButton,
            $schedulePaymentButton,
            $makePaymentButton,
            $manageSchedulePaymentButton,
            $manageAcceptAndPaymentButton,
            $totalOutstanding,
            $downPaymentAmountSpan,
            $numberOfInstallmentsSpan,
            $installmentsAmountSpan,
            $finalPaymentAmountSpan,
            $downPaymentDueDate,
            $paymentTableContainer,
            $acceptPaymentAgreementButton,
            $paymentCancelButton,
            $paymentAgreementCheckText,
            $downpaymentAmountInput,
            $installmentsInput,
            $saveEditFormButton,
            $dpaRadioConfirmationInput,
            $paymentAgreementTable,
            $dpaSignatureEmail,
            $dpaSuccessMessage,
            $dpaEmailToken,
            $paymentAgreementAcceptedMessage,
            $radioButtonsSelector,
            $iconStatusTextSpan,
            $covidIdentifyForm,
            $covidIdentifyRadioButtons,
            $covidTermsRadioButtons,
            $covidTermsAcceptedTable,
            $covidTermsRejectedTable,
            $covidAcceptedMessage,
            $covidHideElements,
            $continueButton,
            $bnaRenegotiateAgreementButton,
            $bnaRenegotiateSection,
            $bnaOptionsSection,
            $bnaRenegotiateBanner,
            $bnaRenegotiateOverviewBillCardViewPlanText,
            _hasCovidForm,
            _covidTerms,
            $progressBar,
            _progressBarPadding,
            _isCommercial,
            _openedPopupButton,
            _isScenarios,
            _editedPaymentAgreement,
            _validator,
            _isPaymentAgreementEditable,
            _isModifiedTermsOnly,
            _isAgreementCommercial,
            _isLazyLoad,
            _isPaymentAgreementSao,
            _hasUserHadAccount,
            _isPaymentAgreementSaoModified,
            _isPaymentAgreementModified,
            _isPaymentAgreementBnaRenegotiate,
            _isEventsLoaded = false;

        var getAmounts = function () {
            var data = {};
            var totalAmount = parseFloat($totalOutstanding.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var downPaymentAmount =
                parseFloat($downPaymentAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var installmentBase = totalAmount - downPaymentAmount;
            var finalAmount =
                parseFloat($finalPaymentAmountSpan[0].innerHTML.replace(/(\$|,)/g, '')) || 0;

            data = {
                DownPaymentAmount: downPaymentAmount,
                InstallmentBase: installmentBase,
                FinalAmount: finalAmount,
                TotalOutstanding: totalAmount
            };

            if ($numberOfInstallmentsSpan.length) {
                data.NumberOfInstallments = parseInt($numberOfInstallmentsSpan[0].innerHTML);
            }

            if ($installmentsAmountSpan.length) {
                data.AmountPerInstallment =
                    parseFloat($installmentsAmountSpan[0].innerHTML.replace(/(\$|,)/g, '')) || 0;
            }

            return data;
        };

        var setStatement = function () {
            var statementFlag = $accordionSelector.dataset.statement;

            if (statementFlag === 'false') {
                if ($manageFormSelector) {
                    $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else if (statementFlag === 'true') {
                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;

                if ($enrollFormSelector) {
                    $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                if ($manageFormSelector) {
                    $manageFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
                if ($paymentAgreementForm) {
                    $paymentAgreementForm.classList.remove(CONSTANTS.ALERT_CLASS);
                    $paymentAgreementForm.classList.remove(CONSTANTS.INACTIVE_CLASS);
                    $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                        CONSTANTS.DATA_ENROLLED
                    );
                }
                if ($linkAccordionSelector) {
                    $linkAccordionSelector.getElementsByClassName(
                        CONSTANTS.LINK_DESCRIPTION
                    )[0].innerHTML = $accordionSelector.dataset.manage;
                }
            }
        };

        var manageSubmitEvent = function (event) {
            event.preventDefault();

            if ($paymentAgreementForm.dataset.redirectUrl) {
                window.location.href = $paymentAgreementForm.dataset.redirectUrl;
            }
        };

        var redirectWithAmount = function (event) {
            var amount =
                    event.currentTarget.dataset.amount && event.currentTarget.dataset.amount !== ''
                        ? parseFloat(event.currentTarget.dataset.amount).toFixed(2)
                        : 0,
                redirectUrl = $paymentAgreementForm.dataset.queryRedirectUrl;

            if (amount) {
                redirectUrl = coned.utils.updateUrlParameter(
                    CONSTANTS.PAY_MY_BILL_OTHER_AMOUNT_URL_PARAM,
                    amount,
                    redirectUrl
                );
            }

            if (redirectUrl) {
                window.location.href = redirectUrl;
            }
        };

        var schedulePaymentClickEvent = function (event) {
            if (event) {
                event.preventDefault();
            }
            taggingSchedulePayment();
            redirectWithAmount(event);
        };

        var manageSchedulePaymentClickEvent = function (event) {
            event.preventDefault();

            if ($paymentAgreementForm.dataset.queryRedirectUrl) {
                //Check the url to see if there is already parameters
                if($paymentAgreementForm.dataset.queryRedirectUrl.indexOf("?") > -1) {
                    window.location.href = $paymentAgreementForm.dataset.queryRedirectUrl + CONSTANTS.AMPERSAND + CONSTANTS.OVERPAYMENT;
                } else {
                    window.location.href = $paymentAgreementForm.dataset.queryRedirectUrl + CONSTANTS.QUESTION_MARK + CONSTANTS.OVERPAYMENT;
                }
            }
        };

        var acceptPaymentAgreement = function (event) {
            event.preventDefault();

            var data = getAmounts(),
                serviceUrl = $paymentAgreementForm.dataset.updateTermsServiceUrl,
                // _isModifiedTermsOnly for residential and _isAgreementCommercial for commercial
                termTypeName = (_covidTerms ? CONSTANTS.TERM_TYPE_COVID :   
                    ( _isAgreementCommercial || _isModifiedTermsOnly ? CONSTANTS.TERM_TYPE_PGR : CONSTANTS.TERM_TYPE_SAO)),
                params;

            hideError();

            var downPaymentDateString = $downPaymentDueDate
                    ? $downPaymentDueDate.dataset.downPaymentDueDate
                    : '',
                totalAgreementAmount = data.TotalOutstanding,
                downPaymentAmount = data.DownPaymentAmount,
                downPaymentDate = new Date(downPaymentDateString),
                firstPaymentDueDate = new Date(downPaymentDateString),
                installmentAmount = data.AmountPerInstallment,
                installmentDueDate = new Date(
                    new Date(downPaymentDateString).setMonth(
                        new Date(downPaymentDateString).getMonth() + 1
                    )
                ),
                numberOfInstallments = data.NumberOfInstallments,
                finalPaymentAmount = data.FinalAmount,
                finalPaymentDate = new Date(
                    new Date(downPaymentDateString).setMonth(
                        new Date(downPaymentDateString).getMonth() + data.NumberOfInstallments
                    )
                );

            params = {
                Maid: $accountMaidInput.value,
                TermType: termTypeName,
                TotalAgreementAmount: totalAgreementAmount,
                DownPaymentAmount: downPaymentAmount,
                DownPaymentDate: downPaymentDate,
                FirstPaymentDueDate: firstPaymentDueDate,
                InstallmentAmount: installmentAmount,
                InstallmentDueDate: installmentDueDate,
                NumberOfInstallments: numberOfInstallments,
                FinalPaymentAmount: finalPaymentAmount,
                FinalPaymentDate: finalPaymentDate,
                HasEmail: _hasUserHadAccount
            };

            if (_isCommercial && _editedPaymentAgreement) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.SCENARIO_MODIFIED_COMMERCIAL_ACCEPT_TAG
                });
            } else if (_isPaymentAgreementSao) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.SCENARIO_SAO_ACCEPT_TAG
                });
            } else if (_isPaymentAgreementSaoModified) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.SCENARIO_SAO_MODIFIED_ACCEPT_TAG
                });
            } else if (_isPaymentAgreementModified) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.SCENARIO_MODIFIED_ACCEPT_TAG
                });
            } else if (_isCommercial) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.SCENARIO_COMMERCIAL_ACCEPT_TAG
                });
            }

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successPaymentAgreement,
                errorPaymentAgreement,
                params,
                true,
                $formLoading
            );
        };

        var hideError = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        // Loads all module data from service
        var loadDataServiceCall = function () {
            if (isLoaded) return;

            var serviceUrl = $paymentAgreementForm.dataset.eligibilityServiceUrl,
                params;

            if (!serviceUrl) {
                errorLoadDataServiceCall();
                return;
            }

            query.getData(serviceUrl, successLoadDataServiceCall, errorLoadDataServiceCall, params);
        };

        // If call succeeded, load markup into module
        var successLoadDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                var scenario = coned.utils.getUrlParameterValue(CONSTANTS.PAGR_PARAMETER_NAME) ? 
                                coned.utils.getUrlParameterValue(CONSTANTS.PAGR_PARAMETER_NAME) :
                                coned.constants.DEFAULT_SCENARIO;

                var scenarioHTML = coned.plConstants.GET_PAYMENT_AGREEMENT_SCENARIO_HTML_URL + scenario + coned.constants.HTML_EXTENSION;
                query.getData(
                    scenarioHTML,
                    setContentMarkup,
                    errorLoadDataServiceCall
                );

                /* List of scenarios
                // The new way to access this scenarios is with a parameter(pagr) from the My Account Page
                // Example of url: http://localhost:7788/patterns/04-pages-38-maui-manage-my-account-01-manage-my-account/04-pages-38-maui-manage-my-account-01-manage-my-account.html?pagr=c1
                // SCENARIO #0: Payment agreement when ineligible
                // SCENARIO #1 : SAO - No Modification Agreement > to schedule payment
                // SCENARIO #c1 for Commercial : SAO - No Modification Agreement > to schedule payment
                // SCENARIO #2: SAO + MODIFIED - Modifiable Agreement > to confirmation > to schedule payment
                // SCENARIO #3: MODIFIED ONLY - Modifiable Agreement (Optional) no immediate payment > to confirmation > to schedule payment
                // SCENARIO #4: Enrolled Customer - 
                // SCENARIO #5: No-Enrolled Customer signature is required, form will appear
                // SCENARIO #6: Enrolled Customer no signature email is required
                // SCENARIO #9: Enrolled Customer with BNA, 
                // SCENARIO #10: Enrolled Customer with pending downpayment
                // SCENARIO #11: Data error
                // SCENARIO #12: Installment Value of $0.00
                // SCENARIO #13: COVID-19 enroll
                // SCENARIO #14: COVID-19 enroll Commercial
                // SCENARIO #15: Enrolled Customer with BNA, renegotiate payment agreement
                // SCENARIO #c2 for Commercial : ORU
                // SCENARIO #16 - #28 ORU
                */

            } else {
                setContentMarkup(data);
            }
        };

        // If call errored, hide module
        var errorLoadDataServiceCall = function () {
            $paymentAgreementForm.classList.add(CONSTANTS.HIDDEN_CLASS);

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $paymentAgreementForm.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($paymentAgreementForm, 'lazy-load-element-loaded');
            }
        };

        // Set markup to the tab content
        var setContentMarkup = function (data) {
            if (data) {
                var parser = new DOMParser(),
                    HTMLObject,
                    $paymentAgreementContent,
                    newPaymentAgreementAttrs;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $paymentAgreementContent = HTMLObject.getElementsByClassName(
                    CONSTANTS.FORM_SELECTOR
                );

                if (
                    $paymentAgreementContent.length !== 0 &&
                    $paymentAgreementContent[0].dataset.content !== 'false'
                ) {
                    $paymentAgreementContent = $paymentAgreementContent[0];
                } else {
                    errorLoadDataServiceCall();
                    return;
                }

                $paymentAgreementForm.innerHTML = $paymentAgreementContent.innerHTML;
                newPaymentAgreementAttrs = $paymentAgreementContent.attributes;

                while ($paymentAgreementForm.attributes.length > 0) {
                    $paymentAgreementForm.removeAttribute($paymentAgreementForm.attributes[0].name);
                }

                _.each(newPaymentAgreementAttrs, function (attribute) {
                    $paymentAgreementForm.setAttribute(attribute.nodeName, attribute.nodeValue);
                });

                // If data-module is present, initialize modules
                if ($paymentAgreementContent.dataset.module) {
                    initializeData();
                    initializeEvents();
                    coned.utils.initializeModules($paymentAgreementForm);
                }

                if (_isScenarios) {
                    coned.utils.triggerEvent($paymentAgreementForm, 'scenarios-element-loaded');
                }
            } else {
                errorLoadDataServiceCall();
                return;
            }

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $paymentAgreementForm.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($paymentAgreementForm, 'lazy-load-element-loaded');
            }

            coned.utils.triggerEvent(window, 'service-loaded');
        };

        var changePaymentAgreementFlag = function () {
            _editedPaymentAgreement = true;
            _isModifiedTermsOnly = true;
        };

        var successPaymentAgreement = function (data) {
            // Analytics data building
            if (_editedPaymentAgreement) {
                taggingSuccessModifiedPaymentAgreement();
            } else {
                taggingSuccessPaymentAgreement();
            }

            if (_isCommercial) {
                var value = _covidTerms ? 'COVID 19 Terms' : 'Standard Terms';

                dataLayer.push({
                    'covid-terms-commercial' : value
                });

            } else {
                dataLayer.push({
                    'covid-terms': _covidTerms
                });
            }



            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($paymentAgreementForm);

            query.addClass($paymentTableContainer, CONSTANTS.HIDDEN_CLASS);
            query.addClass($acceptPaymentAgreementButton, CONSTANTS.HIDDEN_CLASS);
            _.each($schedulePaymentButton, function ($button) {
                $button.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });
            $paymentCancelButton.innerHTML = $paymentCancelButton.dataset.modifiedPayment;
            $paymentAgreementCheckText.innerHTML =
                $paymentAgreementCheckText.dataset.paymentAgreementConfirmationText;

            if (
                $formSignatureSelector &&
                (_isCommercial ||
                    _editedPaymentAgreement ||
                    _isModifiedTermsOnly ||
                    (_hasCovidForm && _covidTerms))
            ) {
                _.each($dpaHidden, function ($dpaHiddenItem) {
                    query.addClass($dpaHiddenItem, CONSTANTS.HIDDEN_CLASS);
                });

                if (data.hasOwnProperty('dpaProcessed') && data.dpaProcessed === false) {
                    showDPAError();
                } else {
                    if (!_hasUserHadAccount) {
                        $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                        $formSignatureSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $formSignatureSelector.focus();

                        if (
                            _hasCovidForm &&
                            _covidTerms &&
                            !_isPaymentAgreementSao
                        ) {
                            $dpaEmailToken = $dpaSignatureCovidReadyBody
                                ? $dpaSignatureCovidReadyBody.getElementsByClassName(
                                      CONSTANTS.DPA_EMAIL_TOKEN
                                  )[0]
                                : $dpaSignatureReadyBody.getElementsByClassName(
                                      CONSTANTS.DPA_EMAIL_TOKEN
                                  )[0];
                            $emailParagraphSelector = $dpaSignatureCovidReadyBody
                                ? $dpaSignatureCovidReadyBody.getElementsByClassName(
                                      CONSTANTS.EMAIL_PARAGRAPH
                                  )[0]
                                : $dpaSignatureReadyBody.getElementsByClassName(
                                      CONSTANTS.EMAIL_PARAGRAPH
                                  )[0];
                            $noEmailParagraphSelector = $dpaSignatureCovidReadyBody
                                ? $dpaSignatureCovidReadyBody.getElementsByClassName(
                                      CONSTANTS.NO_EMAIL_PARAGRAPH
                                  )[0]
                                : $dpaSignatureReadyBody.getElementsByClassName(
                                      CONSTANTS.NO_EMAIL_PARAGRAPH
                                  )[0];

                            if ($dpaSignatureCovidReadyBody) {
                                $dpaSignatureCovidReadyBody.classList.remove(
                                    CONSTANTS.HIDDEN_CLASS
                                );
                                $dpaSignatureReadyBody.classList.add(CONSTANTS.HIDDEN_CLASS);
                            } else {
                                $dpaSignatureReadyBody.classList.remove(CONSTANTS.HIDDEN_CLASS);
                            }
                        } else if (_hasCovidForm && !_covidTerms) {
                            $dpaEmailToken = $dpaSignatureReadyBody.getElementsByClassName(
                                CONSTANTS.DPA_EMAIL_TOKEN
                            )[0];
                            $emailParagraphSelector = $dpaSignatureReadyBody.getElementsByClassName(
                                CONSTANTS.EMAIL_PARAGRAPH
                            )[0];
                            $noEmailParagraphSelector = $dpaSignatureReadyBody.getElementsByClassName(
                                CONSTANTS.NO_EMAIL_PARAGRAPH
                            )[0];

                            $dpaSignatureCovidReadyBody &&
                                $dpaSignatureCovidReadyBody.classList.add(CONSTANTS.HIDDEN_CLASS);
                            $dpaSignatureReadyBody.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        }
                    } else {
                        if (
                            _hasCovidForm &&
                            _covidTerms &&
                            !_isPaymentAgreementSao
                        ) {
                            $dpaEmailToken = $dpaSignatureCovidReadyBody
                                ? $dpaSignatureCovidReadyBody.getElementsByClassName(
                                      CONSTANTS.DPA_EMAIL_TOKEN
                                  )[0]
                                : $dpaSignatureReadyBody.getElementsByClassName(
                                      CONSTANTS.DPA_EMAIL_TOKEN
                                  )[0];
                            $emailParagraphSelector = $dpaSignatureCovidReadyBody
                                ? $dpaSignatureCovidReadyBody.getElementsByClassName(
                                      CONSTANTS.EMAIL_PARAGRAPH
                                  )[0]
                                : $dpaSignatureReadyBody.getElementsByClassName(
                                      CONSTANTS.EMAIL_PARAGRAPH
                                  )[0];
                            $noEmailParagraphSelector = $dpaSignatureCovidReadyBody
                                ? $dpaSignatureCovidReadyBody.getElementsByClassName(
                                      CONSTANTS.NO_EMAIL_PARAGRAPH
                                  )[0]
                                : $dpaSignatureReadyBody.getElementsByClassName(
                                      CONSTANTS.NO_EMAIL_PARAGRAPH
                                  )[0];

                            if ($dpaSignatureCovidReadyBody) {
                                $dpaSignatureCovidReadyBody.classList.remove(
                                    CONSTANTS.HIDDEN_CLASS
                                );
                                $dpaSignatureReadyBody.classList.add(CONSTANTS.HIDDEN_CLASS);
                            } else {
                                $dpaSignatureReadyBody.classList.remove(CONSTANTS.HIDDEN_CLASS);
                            }

                            $emailParagraphSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);

                            var $dpaSchedulePaymentButton = $dpaSuccessMessage.getElementsByClassName(
                                    CONSTANTS.SCHEDULE_PAYMENT_BUTTON
                                )[0],
                                $dpaCloseSelector = $dpaSuccessMessage.getElementsByClassName(
                                    CONSTANTS.COVID_CLOSE_SELECTOR
                                )[0],
                                $closeSelector = $dpaSuccessMessage.getElementsByClassName(
                                    CONSTANTS.CLOSE_SELECTOR
                                )[0];

                            if ($dpaSchedulePaymentButton) {
                                $dpaSchedulePaymentButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                            }

                            if ($closeSelector) {
                                $closeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                            }

                            if ($dpaCloseSelector) {
                                $dpaCloseSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                                $dpaCloseSelector.innerHTML =
                                    $dpaCloseSelector.dataset.acceptedCovid;
                            }
                        } else if (_hasCovidForm && !_covidTerms) {
                            $dpaEmailToken = $dpaSignatureReadyBody.getElementsByClassName(
                                CONSTANTS.DPA_EMAIL_TOKEN
                            )[0];
                            $emailParagraphSelector = $dpaSignatureReadyBody.getElementsByClassName(
                                CONSTANTS.EMAIL_PARAGRAPH
                            )[0];
                            $noEmailParagraphSelector = $dpaSignatureReadyBody.getElementsByClassName(
                                CONSTANTS.NO_EMAIL_PARAGRAPH
                            )[0];

                            $dpaSignatureCovidReadyBody &&
                                $dpaSignatureCovidReadyBody.classList.add(CONSTANTS.HIDDEN_CLASS);
                            $dpaSignatureReadyBody.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        }

                        $dpaSuccessMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                        $dpaSuccessMessage.focus();

                        // Removes any error or warning classes
                        $paymentAgreementForm.classList.remove(CONSTANTS.ALERT_CLASS);
                        $paymentAgreementForm.classList.remove(CONSTANTS.INACTIVE_CLASS);
                        $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                            CONSTANTS.DATA_ENROLLED
                        );

                        $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
                    }
                }
            } else {
                if (_hasCovidForm && _covidTerms && !_isPaymentAgreementSao) {
                    $covidAcceptedMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $covidAcceptedMessage.focus();

                    _.each($schedulePaymentButton, function ($button) {
                        $button.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });

                    _.each($closeSelectors, function ($button) {
                        $button.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });

                    $acceptPaymentAgreementButton.type = 'button';
                    $acceptPaymentAgreementButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $acceptPaymentAgreementButton.classList.add(CONSTANTS.CLOSE_SELECTOR);
                    $acceptPaymentAgreementButton.innerHTML =
                        $acceptPaymentAgreementButton.dataset.acceptedCovid;
                } else {
                    $paymentAgreementAcceptedMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $enrollFormSelector.focus();
                }

                // Removes any error or warning classes
                $paymentAgreementForm.classList.remove(CONSTANTS.ALERT_CLASS);
                $paymentAgreementForm.classList.remove(CONSTANTS.INACTIVE_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_ENROLLED
                );

                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
            }

            // Hides BNA banner and BNA copy on blue box from overview tab
            if (_isPaymentAgreementBnaRenegotiate) {
                $bnaRenegotiateBanner && query.addClass($bnaRenegotiateBanner, CONSTANTS.HIDDEN_CLASS);
                $bnaRenegotiateOverviewBillCardViewPlanText && query.addClass($bnaRenegotiateOverviewBillCardViewPlanText, CONSTANTS.HIDDEN_CLASS);
            }
        };

        var errorPaymentAgreement = function (data) {
            if (data.hasOwnProperty('dpaProcessed') && data.dpaProcessed === false) {
                showDPAError();
            } else {
                $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : ($serviceErrorMessage.innerHTML !== '')
                ?  $serviceErrorMessage.innerText
                : coned.constants.ERROR_MESSAGE;

                $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $serviceError.focus();
                taggingErrorPaymentAgreement();
            }
        };

        var showDPAError = function () {
            $enrollFormSelector && $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $formSignatureSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $dpaSignatureReadyBody.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $dpaSignatureReadyError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $emailParagraphSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $noEmailParagraphSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            query.removeClass($dpaSuccessMessage, CONSTANTS.HIDDEN_CLASS);

            $dpaSuccessMessage.focus();

            if ($dpaSignatureCovidReadyBody && _hasCovidForm && !_isPaymentAgreementSao) {
                $dpaSignatureCovidReadyBody.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var openEditPaymentAgreementPopup = function (event) {
            event.preventDefault();

            _openedPopupButton = event.target;

            //Reset form
            $downpaymentAmountInput = $editPaymentAgreementPopup.getElementsByClassName(
                CONSTANTS.DOWNPAYMENT_INPUT_CLASS
            )[0];
            $installmentsInput = $editPaymentAgreementPopup.getElementsByClassName(
                CONSTANTS.INSTALLMENTS_INPUT_CLASS
            )[0];

            $downpaymentAmountInput.classList.remove(CONSTANTS.INPUT_ERRORR_CLASS);
            $installmentsInput.classList.remove(CONSTANTS.INPUT_ERRORR_CLASS);

            $downpaymentAmountInput.value = event.currentTarget.dataset.originalDownpayment;
            coned.utils.triggerEvent($downpaymentAmountInput, 'change');
            $installmentsInput.value = event.currentTarget.dataset.originalNumberinstallments;
            coned.utils.triggerEvent($installmentsInput, 'change');

            _validator = $(
                $editPaymentAgreementPopup.getElementsByClassName(
                    CONSTANTS.EDIT_PAYMENT_AGREEMENT_FORM
                )
            ).validate();
            _validator.resetForm();

            query.removeClass($editPaymentAgreementPopup, CONSTANTS.HIDDEN_CLASS);
            $editPaymentAgreementPopup.classList.remove(CONSTANTS.POPUP_INACTIVE_CLASS);
            $editPaymentAgreementPopup.classList.add(CONSTANTS.POPUP_ACTIVE_CLASS);

            var $popupContainer = $editPaymentAgreementPopup.getElementsByClassName(
                CONSTANTS.POPUP_CONTAINER
            )[0];

            if ($popupContainer) {
                $popupContainer.focus();
            } else {
                $editPaymentAgreementPopup.focus();
            }
        };

        // Handles the progress bar initialization
        var initializeProgressBar = function () {
            _.each($progressBar, function ($currentProgressBar, index) {
                var $progressBarSmallBar = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_SMALL_BAR
                    )[0],
                    $progressBarBigBar = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_BIG_BAR
                    )[0],
                    $progressBarInstallmentsCheck = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_INSTALLMENTS_CHECKPOINT
                    )[0],
                    $progressBarDownpaymentCheck = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_DOWNPAYMENT_CHECKPOINT
                    )[0],
                    $progressBarFinalCheck = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_FINAL_CHECKPOINT
                    )[0],
                    $progressBarAmounts = $currentProgressBar.parentElement.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_AMOUNT
                    ),
                    totalInstallments =
                        $currentProgressBar.dataset.totalInstallments &&
                        $currentProgressBar.dataset.totalInstallments !== ''
                            ? parseInt($currentProgressBar.dataset.totalInstallments)
                            : 0,
                    installmentsMade =
                        $currentProgressBar.dataset.installmentsMade &&
                        $currentProgressBar.dataset.installmentsMade !== ''
                            ? parseInt($currentProgressBar.dataset.installmentsMade)
                            : 0,
                    downpaymentCheck =
                        $currentProgressBar.dataset.downpaymentCheck &&
                        $currentProgressBar.dataset.downpaymentCheck === 'true'
                            ? true
                            : false,
                    finalCheck =
                        $currentProgressBar.dataset.finalCheck &&
                        $currentProgressBar.dataset.finalCheck === 'true'
                            ? true
                            : false,
                    percentage,
                    _desktopMediaQuery = '@media only screen and (min-width: 768px)',
                    _mobileMediaQuery = '@media only screen and (max-width: 767px)',
                    _desktopGradient = 'background: -moz-linear-gradient(middle, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -webkit-gradient(left middle, left right, color-stop(@PERCENTAGE%, #00882B), color-stop(@PERCENTAGE%, white));\
                                        background: -webkit-linear-gradient(middle, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -o-linear-gradient(middle, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -ms-linear-gradient(middle, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: linear-gradient(to right, #00882B @PERCENTAGE%, white @PERCENTAGE%);'.trim(),
                    _mobileGradient = 'background: -moz-linear-gradient(top, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -webkit-gradient(middle top, middle bottom, color-stop(@PERCENTAGE%, #00882B), color-stop(@PERCENTAGE%, white));\
                                        background: -webkit-linear-gradient(top, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -o-linear-gradient(top, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -ms-linear-gradient(top, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: linear-gradient(to bottom, #00882B @PERCENTAGE%, white @PERCENTAGE%);'.trim();

                // Set first checkpoint styles
                if (downpaymentCheck) {
                    $progressBarDownpaymentCheck.classList.add(
                        CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS + CONSTANTS.CHECKPOINT_ACTIVE_SUFFIX
                    );
                    $progressBarDownpaymentCheck.classList.add(CONSTANTS.CHECKPOINT_CHECK_ICON);

                    $progressBarAmounts[1].classList.remove(
                        CONSTANTS.AMOUNT_CLASS + CONSTANTS.AMOUNT_GRAYED_SUFFIX
                    );

                    $progressBarInstallmentsCheck.classList.add(CONSTANTS.CHECKPOINT_ARROW_ICON);
                    $progressBarInstallmentsCheck.classList.add(
                        CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS + CONSTANTS.CHECKPOINT_ACTIVE_SUFFIX
                    );

                    $progressBarSmallBar.classList.add(
                        CONSTANTS.SMALL_BAR_CLASS + CONSTANTS.SMALL_BAR_STARTED_SUFFIX
                    );
                }

                // Set installments bar & checkpoint styles
                if (installmentsMade === 0 && totalInstallments > 0) {
                    percentage = 0;
                } else if (installmentsMade > 0 && installmentsMade < totalInstallments) {
                    // First we must know if how much percentage would each step take.
                    var stepPercentage = 100 / totalInstallments;

                    // Now, if the step percentage is higher than the padding, calculate position of checkpoint normally by rule of thirds.
                    if (stepPercentage > _progressBarPadding) {
                        percentage = (100 * installmentsMade) / totalInstallments;
                        // Else, if the percentage is lower, apply the padding formula
                    } else {
                        // Calculate where to position the checkpoint and green part of bar should be position by rule of thirds
                        // '(_progressBarPadding * 2)' -> To remove padding on "both" sides of the percentage
                        // '(installmentsMade - 1)' -> Since all installments are > 0 and we're already adding the padding as the "first installment", we remove an extra installment
                        // '(totalInstallments - 2)' -> Same reason as above, but including the "last installment" as well
                        percentage =
                            _progressBarPadding +
                            ((100 - _progressBarPadding * 2) * (installmentsMade - 1)) /
                                (totalInstallments - 2);
                    }
                } else if (installmentsMade === totalInstallments) {
                    percentage = 100;

                    $progressBarAmounts[2].classList.remove(
                        CONSTANTS.AMOUNT_CLASS + CONSTANTS.AMOUNT_GRAYED_SUFFIX
                    );
                    $progressBarInstallmentsCheck.classList.add(CONSTANTS.CHECKPOINT_CHECK_ICON);
                    $progressBarInstallmentsCheck.classList.remove(CONSTANTS.CHECKPOINT_ARROW_ICON);
                }

                // Add index classes for elements
                $progressBarInstallmentsCheck.classList.add(
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS + '--' + index
                );
                $progressBarBigBar.classList.add(CONSTANTS.BAR_BIG_BAR_CLASS + '--' + index);

                // Style related variables
                var styles = document.createElement('style'),
                    $script = document.querySelector('script'); // First script tag

                // Desktop installment bar & checkpoint styles
                styles.textContent +=
                    _desktopMediaQuery +
                    '{ .' +
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS +
                    CONSTANTS.CHECKPOINT_INSTALLMENTS_SUFFIX +
                    '.' +
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS +
                    '--' +
                    index +
                    '{ left: ' +
                    percentage +
                    '% !important; }' +
                    '.' +
                    CONSTANTS.BAR_BIG_BAR_CLASS +
                    '.' +
                    CONSTANTS.BAR_BIG_BAR_CLASS +
                    '--' +
                    index +
                    '{ ' +
                    _desktopGradient.replace(/@PERCENTAGE/g, percentage) +
                    ' } }';

                // Mobile installment bar & checkpoint styles
                styles.textContent +=
                    _mobileMediaQuery +
                    '{ .' +
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS +
                    CONSTANTS.CHECKPOINT_INSTALLMENTS_SUFFIX +
                    '.' +
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS +
                    '--' +
                    index +
                    '{ top: ' +
                    percentage +
                    '% !important; }' +
                    '.' +
                    CONSTANTS.BAR_BIG_BAR_CLASS +
                    '.' +
                    CONSTANTS.BAR_BIG_BAR_CLASS +
                    '--' +
                    index +
                    '{ ' +
                    _mobileGradient.replace(/@PERCENTAGE/g, percentage) +
                    ' } }';

                $script.parentNode.insertBefore(styles, $script);

                // Set final checkpoint styles
                if (finalCheck) {
                    $progressBarFinalCheck.classList.add(
                        CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS + CONSTANTS.CHECKPOINT_ACTIVE_SUFFIX
                    );
                    $progressBarFinalCheck.classList.add(CONSTANTS.CHECKPOINT_CHECK_ICON);

                    $progressBarSmallBar.classList.remove(
                        CONSTANTS.SMALL_BAR_CLASS + CONSTANTS.SMALL_BAR_STARTED_SUFFIX
                    );
                    $progressBarSmallBar.classList.add(
                        CONSTANTS.SMALL_BAR_CLASS + CONSTANTS.SMALL_BAR_FINISHED_SUFFIX
                    );
                }
            });
        };

        var taggingStartPaymentAgreement = function () {
            var isAccordionOpened = !query.hasClass($linkAccordionSelector, 'active');

            if (isAccordionOpened) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: _isPaymentAgreementEditable
                        ? CONSTANTS.START_PAYMENT_AGREEMENT_EDITABLE_TAG
                        : CONSTANTS.START_PAYMENT_AGREEMENT_UNEDITABLE_TAG
                });
            }
        };

        var taggingEditPaymentAgreement = function () {
            if (_isCommercial) {
                dataLayer.push({
                    event: CONSTANTS.SCENARIO_MODIFIED_EDIT_TAG
                });
            } else if (_isPaymentAgreementSaoModified) {
                dataLayer.push({
                    event: CONSTANTS.SCENARIO_SAO_MODIFIED_EDIT_TAG
                });
            } else if (_isPaymentAgreementModified) {
                dataLayer.push({
                    event: CONSTANTS.SCENARIO_MODIFIED_EDIT_TAG
                });
            } else {
                dataLayer.push({
                    event: CONSTANTS.PAYMENT_AGREEMENT_EDIT_TAG
                });
            }
        };

        var taggingSuccessPaymentAgreement = function () {
            dataLayer.push({
                contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                event: CONSTANTS.PAYMENT_AGREEMENT_FORM_SUCCESS_TAG
            });
        };

        var taggingSuccessModifiedPaymentAgreement = function () {
            dataLayer.push({
                contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                event: CONSTANTS.PAYMENT_AGREEMENT_FORM_MODIFIED_TERMS_TAG
            });
        };

        var taggingErrorPaymentAgreement = function () {
            dataLayer.push({
                event: CONSTANTS.PAYMENT_AGREEMENT_FORM_ERROR_TAG
            });
        };

        var taggingSchedulePayment = function () {
            if (_isPaymentAgreementSao) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.SCENARIO_SAO_SCHEDULE_TAG
                });
            } else if (_isPaymentAgreementSaoModified) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.SCENARIO_SAO_MODIFIED_SCHEDULE_TAG
                });
            } else if (_isPaymentAgreementModified) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.SCENARIO_MODIFIED_SCHEDULE_TAG
                });
            } else if (_isCommercial) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.SCENARIO_COMMERCIAL_SCHEDULE_TAG
                });
            } else {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: CONSTANTS.PAYMENT_AGREEMENT_SCHEDULE_TAG
                });
            }
        };

        var taggingMakePayment = function () {
            dataLayer.push({
                contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                event: CONSTANTS.PAYMENT_AGREEMENT_MAKE_PAYMENT_TAG
            });
        };

        // Checks if module should be lazy-loaded of not
        var preInitialize = function () {
            _isLazyLoad = query.hasClass($paymentAgreementForm, CONSTANTS.LAZY_LOAD_CLASS);
            _isScenarios = query.hasClass($paymentAgreementForm, CONSTANTS.SCENARIO_CLASS);

            // If module is lazy loaded, wait for event to trigger
            if (_isLazyLoad) {
                $lazyLoadTab = document.getElementsByClassName(CONSTANTS.LAZY_LOAD_TAB_CLASS)[0];

                $lazyLoadTab.addEventListener('lazy-load-start', loadDataServiceCall);
            } else {
                initializeData();
                initializeEvents();
            }
        };

        var getEmailValue = function (radios) {
            for (var i = 0, length = radios.length; i < length; i++) {
                if (radios[i].checked) {
                    return radios[i].value;
                }
            }
        };

        var successDPA = function () {
            $formSignatureSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            query.removeClass($dpaSuccessMessage, CONSTANTS.HIDDEN_CLASS);
            $dpaSuccessMessage.focus();

            if ($enrollFormSelector) {
                $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            if (_hasCovidForm && _covidTerms && !(coned.utils.isOru() && _isPaymentAgreementSao)) {
                var $dpaSchedulePaymentButton = $dpaSuccessMessage.getElementsByClassName(
                        CONSTANTS.SCHEDULE_PAYMENT_BUTTON
                    )[0],
                    $dpaCloseSelector = $dpaSuccessMessage.getElementsByClassName(
                        CONSTANTS.COVID_CLOSE_SELECTOR
                    )[0],
                    $closeSelector = $dpaSuccessMessage.getElementsByClassName(
                        CONSTANTS.CLOSE_SELECTOR
                    )[0];

                if ($dpaSchedulePaymentButton) {
                    $dpaSchedulePaymentButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                if ($closeSelector) {
                    $closeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                if ($dpaCloseSelector) {
                    $dpaCloseSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $dpaCloseSelector.innerHTML = $dpaCloseSelector.dataset.acceptedCovid;
                }
            }

            if (getEmailValue($dpaRadioConfirmationInput) === 'email') {
                $dpaEmailToken.innerHTML = $dpaSignatureEmail.value;
                query.removeClass($emailParagraphSelector, CONSTANTS.HIDDEN_CLASS);
                query.addClass($noEmailParagraphSelector, CONSTANTS.HIDDEN_CLASS);
            } else {
                query.removeClass($noEmailParagraphSelector, CONSTANTS.HIDDEN_CLASS);
                query.addClass($emailParagraphSelector, CONSTANTS.HIDDEN_CLASS);
            }

            // Removes any error or warning classes
            $paymentAgreementForm.classList.remove(CONSTANTS.ALERT_CLASS);
            $paymentAgreementForm.classList.remove(CONSTANTS.INACTIVE_CLASS);
            $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                CONSTANTS.DATA_ENROLLED
            );

            $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
        };

        var errorDPA = function (data) {
            if (data.hasOwnProperty('dpaProcessed') && data.dpaProcessed === false) {
                showDPAError();
            }
        };

        var mailingChange = function () {
            var inputValue = $dpaEmailSelector.value;

            if (!inputValue || inputValue == '') {
                $dpaEmailSelector.classList.remove(CONSTANTS.FILLED_CLASS);
            } else {
                $dpaEmailSelector.classList.add(CONSTANTS.FILLED_CLASS);
            }
        };

        var onSubmitForm = function () {
            var serviceUrl = $paymentAgreementForm.dataset.dpaServiceUrl;
            var params = {
                Type: getEmailValue($dpaRadioConfirmationInput),
                Email: $dpaSignatureEmail.value || null,
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($paymentAgreementForm, CONSTANTS.FORM_SCID),
                UpdateAccountType: CONSTANTS.FORM_UPDATE_ACCOUNT_TYPE
            };

            query.postData(
                serviceUrl,
                successDPA,
                errorDPA,
                JSON.stringify(params),
                true,
                $formLoading
            );
        };

        /*
         * Handles the focus after the edit payment agreement popup close
         */
        var closeEditPaymentAgreementHandler = function (event) {
            if (_openedPopupButton && !(event.details && event.details.isModified)) {
                _openedPopupButton.focus();
            } else {
                $paymentAgreementTable.focus();
            }

            _openedPopupButton = null;
        };

        var enableSubmitSignatureBtn = function () {
            $formSignatureSubmtSelector.removeAttribute(CONSTANTS.DISABLED_ATTR);
        };

        var disableEmailInput = function () {
            var tagBasedClass = CONSTANTS.DPA_SBMT_BTN_TAG_CLASS;

            $dpaEmailSelector.setAttribute(CONSTANTS.DISABLED_ATTR, true);
            _.each($dpaEmailFieldsToHide, function ($item) {
                $item.setAttribute(CONSTANTS.ARIA_HIDDEN_ATTR, true);
            });

            query.removeClass($dpaEmailSelector, CONSTANTS.INPUT_ERRORR_CLASS);
            $dpaEmailSelector.value = '';
            $dpaEmailSelector.classList.remove(CONSTANTS.FILLED_CLASS);

            //Tag submit btn props mail
            $dpaSubmtBtn.classList.add(tagBasedClass + CONSTANTS.DPA_CONFIRMATION_TYPE_MAIL);
            $dpaSubmtBtn.classList.remove(tagBasedClass + CONSTANTS.DPA_CONFIRMATION_TYPE_EMAIL);

            $formSignatureErrorSelector = $formSignatureSelector.getElementsByClassName(
                CONSTANTS.FORM_SIGNATURE_ERROR_SELECTOR
            )[0];
            if ($formSignatureErrorSelector) {
                $formSignatureErrorSelector.parentNode.removeChild($formSignatureErrorSelector);
            }
            enableSubmitSignatureBtn();
        };

        var enableEmailInput = function () {
            var tagBasedClass = CONSTANTS.DPA_SBMT_BTN_TAG_CLASS;

            $dpaEmailSelector.removeAttribute(CONSTANTS.DISABLED_ATTR);
            _.each($dpaEmailFieldsToHide, function ($item) {
                $item.setAttribute(CONSTANTS.ARIA_HIDDEN_ATTR, false);
            });

            //Tag submit btn props email
            $dpaSubmtBtn.classList.add(tagBasedClass + CONSTANTS.DPA_CONFIRMATION_TYPE_EMAIL);
            $dpaSubmtBtn.classList.remove(tagBasedClass + CONSTANTS.DPA_CONFIRMATION_TYPE_MAIL);

            enableSubmitSignatureBtn();
        };

        /*
         * Handles form/flag changes on radio select
         */
        var covidTermsChange = function (event) {
            var $target = event.target;
            hideError();

            if ($target.value === CONSTANTS.COVID_TERMS_ACCEPTED_VALUE) {
                _covidTerms = true;

                $covidTermsAcceptedTable.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $covidTermsRejectedTable.classList.add(CONSTANTS.HIDDEN_CLASS);

                if ($downPaymentDueDate) {
                    $downPaymentDueDate.classList.remove(CONSTANTS.ACTIVE_DATE);
                }

                $paymentAgreementTable = $covidTermsAcceptedTable;
                $totalOutstanding = $covidTermsAcceptedTable.getElementsByClassName(
                    CONSTANTS.TOTAL_OUTSTANDING
                )[0];
                $downPaymentAmountSpan = $covidTermsAcceptedTable.getElementsByClassName(
                    CONSTANTS.DOWN_PAYMENT_AMOUNT_SPAN
                )[0];
                $finalPaymentAmountSpan = $covidTermsAcceptedTable.getElementsByClassName(
                    CONSTANTS.FINAL_PAYMENT_SPAN
                );
                $numberOfInstallmentsSpan = $covidTermsAcceptedTable.getElementsByClassName(
                    CONSTANTS.NUMBER_OF_INSTALLMENTS_SPAN
                );
                $installmentsAmountSpan = $covidTermsAcceptedTable.getElementsByClassName(
                    CONSTANTS.AMOUNT_PER_INSTALLMENT_SPAN
                );
                $downPaymentDueDate = $covidTermsAcceptedTable.getElementsByClassName(
                    CONSTANTS.DOWN_PAYMENT_DUE_DATE
                )[0];

                if ($downPaymentDueDate) {
                    $downPaymentDueDate.classList.add(CONSTANTS.ACTIVE_DATE);
                }

                _.each($schedulePaymentButton, function ($button) {
                    $button.dataset.amount = 0;
                });
            } else if ($target.value === CONSTANTS.COVID_TERMS_REJECTED_VALUE) {
                _covidTerms = false;

                $covidTermsAcceptedTable.classList.add(CONSTANTS.HIDDEN_CLASS);
                $covidTermsRejectedTable.classList.remove(CONSTANTS.HIDDEN_CLASS);

                if ($downPaymentDueDate) {
                    $downPaymentDueDate.classList.remove(CONSTANTS.ACTIVE_DATE);
                }

                $paymentAgreementTable = $covidTermsRejectedTable;
                $totalOutstanding = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.TOTAL_OUTSTANDING
                )[0];
                $downPaymentAmountSpan = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.DOWN_PAYMENT_AMOUNT_SPAN
                )[0];
                $finalPaymentAmountSpan = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.FINAL_PAYMENT_SPAN
                );
                $numberOfInstallmentsSpan = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.NUMBER_OF_INSTALLMENTS_SPAN
                );
                $installmentsAmountSpan = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.AMOUNT_PER_INSTALLMENT_SPAN
                );
                $downPaymentDueDate = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.DOWN_PAYMENT_DUE_DATE
                )[0];

                if ($downPaymentDueDate) {
                    $downPaymentDueDate.classList.add(CONSTANTS.ACTIVE_DATE);
                }

                _.each($schedulePaymentButton, function ($button) {
                    $button.dataset.amount =
                        parseFloat($downPaymentAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;
                });
            }

            if (_isCommercial) {
                var value = _covidTerms ? 'COVID 19 Terms' : 'Standard Terms';

                dataLayer.push({
                    'covid-terms-commercial' : value
                });

            } else {
                dataLayer.push({
                    'covid-terms': _covidTerms
                });
            }
        };

        /*
         * Submit COVID-19 identification form
         */
        var onCovidIdentifySubmit = function () {
            _covidTerms = false;
          
            _.each($covidIdentifyRadioButtons, function ($radio) {
                if ($radio.checked && $radio.value === CONSTANTS.COVID_IDENTIFY_ACCEPTED_VALUE) {
                    _covidTerms = true;
                }
            });
          
            if (_covidTerms) {
                var serviceUrl = $paymentAgreementForm.dataset.covidIdentifyServiceUrl,
                    params = {
                        Maid: $accountMaidInput.value,
                        ScId: query.getFormInputValue($paymentAgreementForm, CONSTANTS.FORM_SCID),
                        AttestationName: CONSTANTS.TERM_TYPE_COVID
                    };
          
                query.postData(
                    serviceUrl,
                    successCovidIdentifySubmit,
                    successCovidIdentifySubmit,
                    JSON.stringify(params),
                    true,
                    $formLoading
                );
            } else {
                successCovidIdentifySubmit();
            }
        };

        /*
         * Handle success on COVID-19 identification submit
         */
        var successCovidIdentifySubmit = function () {
            if (_isPaymentAgreementSao) {
                $covidIdentifyForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $enrollFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $enrollFormSelector.focus();

                return;
            }

            if (_covidTerms) {

                // Create input event
                var event;
                // If not IE
                if (typeof Event === 'function') {
                    event = new Event(CONSTANTS.CHANGE, {
                        bubbles: true,
                        cancelable: true
                    });
                    // If IE
                } else {
                    event = document.createEvent('Event');
                    event.initEvent(CONSTANTS.CHANGE, true, true);
                }

                $covidTermsRadioButtons[0].dispatchEvent(event);

                if ($formSignatureSelector) {
                    $dpaSignatureReadyBody.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $dpaSignatureCovidReadyBody.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                _.each($covidHideElements, function ($element) {
                    $element.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                _.each($covidTermsRadioButtons, function ($radio) {
                    $radio.disabled = true;
                });

                $covidTermsAcceptedTable.classList.add(CONSTANTS.HIDDEN_CLASS);
                $covidTermsRejectedTable.classList.remove(CONSTANTS.HIDDEN_CLASS);

                if ($downPaymentDueDate) {
                    $downPaymentDueDate.classList.remove(CONSTANTS.ACTIVE_DATE);
                }

                $paymentAgreementTable = $covidTermsRejectedTable;
                $totalOutstanding = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.TOTAL_OUTSTANDING
                )[0];
                $downPaymentAmountSpan = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.DOWN_PAYMENT_AMOUNT_SPAN
                )[0];
                $finalPaymentAmountSpan = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.FINAL_PAYMENT_SPAN
                );
                $numberOfInstallmentsSpan = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.NUMBER_OF_INSTALLMENTS_SPAN
                );
                $installmentsAmountSpan = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.AMOUNT_PER_INSTALLMENT_SPAN
                );
                $downPaymentDueDate = $covidTermsRejectedTable.getElementsByClassName(
                    CONSTANTS.DOWN_PAYMENT_DUE_DATE
                )[0];

                if ($downPaymentDueDate) {
                    $downPaymentDueDate.classList.add(CONSTANTS.ACTIVE_DATE);
                }

                _.each($schedulePaymentButton, function ($button) {
                    $button.dataset.amount =
                        parseFloat($downPaymentAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;
                });

                if ($formSignatureSelector) {
                    $dpaSignatureReadyBody.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $dpaSignatureCovidReadyBody.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }

            $covidIdentifyForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $enrollFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $enrollFormSelector.focus();
        };

        /**
         * Removes element's ability to be tabbed into
         */
        var removeTabIndex = function (event) {
            var $element = event.currentTarget;

            if ($element.hasAttribute(CONSTANTS.TABINDEX_ATTRIBUTE)) {
                $element.removeAttribute(CONSTANTS.TABINDEX_ATTRIBUTE);
                $bnaRenegotiateSection.removeEventListener(CONSTANTS.BLUR_EVENT, removeTabIndex);
            }
        };

        /**
         * Reveals BNA renegotiate agreement subsection and hides BNA options subsection
         */
        var showBnaRenegotiateAgreement = function (event) {
            event.preventDefault();
            query.addClass($bnaOptionsSection, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($bnaRenegotiateSection, CONSTANTS.HIDDEN_CLASS);
            // Allow revealed section to be focused for a11y
            $bnaRenegotiateSection.tabIndex = 0;
            $bnaRenegotiateSection.focus();

            $bnaRenegotiateSection.addEventListener(CONSTANTS.BLUR_EVENT, removeTabIndex);
        };

        var initializeData = function () {
            $closeSelectors = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.CLOSE_SELECTOR
            );
            $linkAccordionSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.LINK_ACCORDION_SELECTOR
            )[0];
            $accordionSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.ACCORDION_SELECTOR
            )[0];
            $enrollFormSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.ENROLL_FORM_SELECTOR
            )[0];
            $manageFormSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.MANAGE_FORM_SELECTOR
            )[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $serviceError = $enrollFormSelector
                ? $enrollFormSelector.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0]
                : $paymentAgreementForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceErrorMessage = $serviceError
                ? $serviceError.getElementsByClassName(CONSTANTS.SERVICE_ERROR_MESSAGE)[0]
                : null;
            _isPaymentAgreementEditable = query.hasClass(
                $paymentAgreementForm,
                CONSTANTS.PAYMENT_AGREEMENT_EDITABLE
            );
            $manageSchedulePaymentButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.MANAGE_SCHEDULE_PAYMENT_BUTTON
            )[0];
            $manageAcceptAndPaymentButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.MANAGE_ACCEPT_PAYMENT_BUTTON
            )[0];
            $editPaymentAgreementPopup = document.getElementsByClassName(
                CONSTANTS.EDIT_PAYMENT_AGREEMENT_POPUP
            )[0];
            $downPaymentAmountSpan = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.DOWN_PAYMENT_AMOUNT_SPAN
            )[0];
            $numberOfInstallmentsSpan = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.NUMBER_OF_INSTALLMENTS_SPAN
            );
            $schedulePaymentButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.SCHEDULE_PAYMENT_BUTTON
            );
            $makePaymentButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.MAKE_PAYMENT_BUTTON
            )[0];
            $paymentCancelButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.PAYMENT_CANCEL_BUTTON
            )[0];
            $acceptPaymentAgreementButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.ACCEPT_PAYMENT_AGREEMENT_BUTTON
            )[0];
            $paymentTableContainer = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.PAYMENT_TABLE_CONTAINER
            )[0];
            $paymentAgreementTable = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.PAYMENT_AGREEMENT_TABLE
            )[0];
            $totalOutstanding = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.TOTAL_OUTSTANDING
            )[0];
            $installmentsAmountSpan = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.AMOUNT_PER_INSTALLMENT_SPAN
            );
            $finalPaymentAmountSpan = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.FINAL_PAYMENT_SPAN
            );
            $downPaymentDueDate = $paymentAgreementTable
                ? $paymentAgreementTable.getElementsByClassName(CONSTANTS.DOWN_PAYMENT_DUE_DATE)[0]
                : null;
            $paymentAgreementAcceptedMessage = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.PAYMENT_AGREEMENT_ACCEPTED_MESSAGE
            )[0];
            $formSignatureSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.FORM_SIGNATURE_SELECTOR
            )[0];
            $paymentAgreementCheckText = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.PAYMENT_AGREEMENT_CHECK_TEXT
            )[0];
            $radioButtonsSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.RADIO_BUTTON_SELECTOR
            );
            $covidIdentifyForm = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.COVID_IDENTIFY_FORM
            )[0];
            $iconStatusTextSpan = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.ICON_STATUS_TEXT
            )[0];
            $bnaRenegotiateAgreementButton = document.getElementsByClassName(
                CONSTANTS.BNA_RENEGOTIATE_AGREEMENT_BUTTON
            )[0];

            $continueButton = $paymentAgreementForm.getElementsByClassName(CONSTANTS.CONTINUE_BUTTON)[0];

            _isCommercial = $paymentAgreementForm.dataset.isCommercial === 'true';

            _hasCovidForm = $covidIdentifyForm && $paymentAgreementForm.dataset.hasCovidForm === 'true';

            _isModifiedTermsOnly = query.hasClass(
                $paymentAgreementForm,
                CONSTANTS.MODIFIED_TERMS_ONLY
            );
            _isAgreementCommercial = query.hasClass(
                $paymentAgreementForm,
                CONSTANTS.PAYMENT_AGREEMENT_COMMERCIAL
            );
            _isPaymentAgreementSao = query.hasClass(
                $paymentAgreementForm,
                CONSTANTS.PAYMENT_AGREEMENT_SAO_SELECTOR
            );
            _isPaymentAgreementSaoModified = query.hasClass(
                $paymentAgreementForm,
                CONSTANTS.PAYMENT_AGREEMENT_SAO_MODIFIED_SELECTOR
            );
            _isPaymentAgreementModified = query.hasClass(
                $paymentAgreementForm,
                CONSTANTS.PAYMENT_AGREEMENT_MODIFIED_SELECTOR
            );
            _isPaymentAgreementBnaRenegotiate = query.hasClass(
                $paymentAgreementForm,
                CONSTANTS.PAYMENT_AGREEMENT_BNA_RENEGOTIATE_SELECTOR
            );

            if (_isPaymentAgreementBnaRenegotiate) {
                $bnaRenegotiateSection = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.BNA_RENEGOTIATE_SECTION
                )[0];
                $bnaOptionsSection = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.BNA_OPTIONS_SECTION
                )[0];
                $bnaRenegotiateBanner = document.getElementsByClassName(
                    CONSTANTS.BNA_RENEGOTIATE_PAYMENT_AGREEMENT_BANNER_SELECTOR
                )[0];
                $bnaRenegotiateOverviewBillCardViewPlanText = document.getElementsByClassName(
                    CONSTANTS.BNA_RENEGOTIATE_OVERVIEW_BILL_CARD_VIEW_PLAN_SELECTOR
                )[0];
            }

            if (_isPaymentAgreementEditable) {
                $saveEditFormButton = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.SAVE_EDIT_FORM_BUTTON
                )[0];
                $openEditPaymentAgreementPopupButton = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.OPEN_EDIT_PAYMENT_AGREEMENT_POPUP_BUTTON
                );
            }

            if (
                (_isPaymentAgreementEditable ||
                    _hasCovidForm ||
                    _isCommercial ||
                    coned.utils.isOru()) &&
                $formSignatureSelector
            ) {
                $dpaPaymentAgreement = document.getElementsByClassName(
                    CONSTANTS.DPA_PAYMENT_AGREEMENT
                )[0];
                $enableEmailSelector = $formSignatureSelector.getElementsByClassName(
                    CONSTANTS.ENABLE_EMAIL_SELECTOR
                )[0];
                $disableEmailSelector = $formSignatureSelector.getElementsByClassName(
                    CONSTANTS.DISABLE_EMAIL_SELECTOR
                )[0];
                $dpaEmailSelector = $formSignatureSelector.getElementsByClassName(
                    CONSTANTS.DPA_EMAIL_INPUT_SELECTOR
                )[0];
                $formSignatureSubmtSelector = $formSignatureSelector.getElementsByClassName(
                    CONSTANTS.FORM_SIGNATURE_SBMT_SELECTOR
                )[0];
                $dpaSuccessMessage = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.DPA_SUCCESS_SELECTOR
                )[0];
                $dpaRadioConfirmationInput = document.getElementsByName(
                    CONSTANTS.DPA_RADIO_CONFIRMATION_INPUT
                );
                $dpaSignatureEmail = document.getElementsByName(CONSTANTS.DPA_SIGNATURE_EMAIL)[0];
                $dpaEmailFieldsToHide = document.getElementsByClassName(
                    CONSTANTS.DPA_EMAIL_FIELDS_TO_HIDE
                );
                $dpaHidden = $dpaPaymentAgreement.getElementsByClassName(CONSTANTS.DPA_HIDDEN);
                $dpaEmailToken = $dpaSuccessMessage.getElementsByClassName(
                    CONSTANTS.DPA_EMAIL_TOKEN
                )[0];
                $emailParagraphSelector = $dpaPaymentAgreement.getElementsByClassName(
                    CONSTANTS.EMAIL_PARAGRAPH
                )[0];
                $noEmailParagraphSelector = $dpaPaymentAgreement.getElementsByClassName(
                    CONSTANTS.NO_EMAIL_PARAGRAPH
                )[0];
                $dpaSignatureReadyBody = $dpaPaymentAgreement.getElementsByClassName(
                    CONSTANTS.SIGNATURE_READY_BODY
                )[0];
                $dpaSignatureCovidReadyBody = $dpaPaymentAgreement.getElementsByClassName(
                    CONSTANTS.SIGNATURE_COVID_READY_BODY
                )[0];
                $dpaSignatureReadyError = $dpaPaymentAgreement.getElementsByClassName(
                    CONSTANTS.SIGNATURE_READY_ERROR
                )[0];
                $dpaSubmtBtn = $dpaPaymentAgreement.getElementsByClassName(
                    CONSTANTS.FORM_DPA_SBMT_BTN_SELECTOR
                )[0];
                _hasUserHadAccount =
                    $accordionSelector.dataset.userHadAccount === 'true' ? true : false;
            }

            _editedPaymentAgreement = false;

            // Enrolled Progress Bar
            $progressBar = $paymentAgreementForm.getElementsByClassName(CONSTANTS.PROGRESS_BAR);

            if ($progressBar) {
                _progressBarPadding = 12;
            }

            if ($covidIdentifyForm) {
                $covidIdentifyRadioButtons = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.COVID_IDENTIFY_RADIO_BUTTONS
                );
                $covidTermsRadioButtons = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.COVID_TERMS_RADIO_BUTTONS
                );
                $covidHideElements = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.COVID_HIDE_ELEMENTS
                );
                $covidTermsAcceptedTable = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.COVID_TERMS_ACCEPTED_TABLE
                )[0];
                $covidTermsRejectedTable = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.COVID_TERMS_REJECTED_TABLE
                )[0];
                $covidAcceptedMessage = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.COVID_ACCEPTED_MESSAGE
                )[0];
                _covidTerms = false;
            }
        };

        var initializeEvents = function () {

            if (!_isEventsLoaded && !coned.components.DashboardPaymentAgreement.prototype.isLoaded()) {

                setStatement();

                _.each($schedulePaymentButton, function ($button) {
                    coned.utils.addGeneralListeners($button, schedulePaymentClickEvent);
                });

                if (_isPaymentAgreementEditable) {
                    if ($enrollFormSelector) {
                        $enrollFormSelector.addEventListener('submit', acceptPaymentAgreement);
                    }

                    if ($saveEditFormButton) {
                        $saveEditFormButton.addEventListener('click', changePaymentAgreementFlag);
                    }

                    if ($editPaymentAgreementPopup) {
                        $editPaymentAgreementPopup.addEventListener(
                            'close-popup',
                            closeEditPaymentAgreementHandler
                        );

                        new coned.utils.addKeyEvent(
                            $editPaymentAgreementPopup,
                            coned.constants.KEY_CODE.ESC,
                            closeEditPaymentAgreementHandler
                        );

                        new coned.utils.addFocusTrap($editPaymentAgreementPopup);
                    }

                    if ($openEditPaymentAgreementPopupButton) {
                        _.each($openEditPaymentAgreementPopupButton, function ($element) {
                            coned.utils.addGeneralListeners($element, openEditPaymentAgreementPopup);
                        });
                    }

                    if (_isPaymentAgreementBnaRenegotiate) {
                        coned.utils.addGeneralListeners($bnaRenegotiateAgreementButton, showBnaRenegotiateAgreement);
                    }

                } else {
                    if ($enrollFormSelector) {
                        $enrollFormSelector.addEventListener('submit', acceptPaymentAgreement);
                    }
                }

                if (
                    $manageFormSelector &&
                    !_isPaymentAgreementSao &&
                    !_isPaymentAgreementSaoModified &&
                    !_isPaymentAgreementModified
                ) {
                    $manageFormSelector.addEventListener('submit', manageSubmitEvent);
                }

                if ($dpaPaymentAgreement) {
                    if ($disableEmailSelector) {
                        $disableEmailSelector.addEventListener('click', disableEmailInput);
                    }

                    if ($enableEmailSelector) {
                        $enableEmailSelector.addEventListener('click', enableEmailInput);
                    }

                    new coned.components.ValidateForm(
                        '.' + CONSTANTS.FORM_SIGNATURE_SELECTOR,
                        onSubmitForm,
                        ''
                    );
                    $dpaEmailSelector.addEventListener('change', mailingChange);

                    if (_hasUserHadAccount) {
                        query.removeClass($emailParagraphSelector, CONSTANTS.HIDDEN_CLASS);
                    }
                }

                if ($covidIdentifyForm) {

                    if ($covidIdentifyForm.dataset.accountType !== CONSTANTS.COMMERCIAL) {
                        new coned.components.ValidateForm(
                            '.' + CONSTANTS.COVID_IDENTIFY_FORM,
                            onCovidIdentifySubmit,
                            ''
                        );
                    }

                    _.each($covidIdentifyRadioButtons, function ($radio) {
                        $radio.addEventListener('change', function (event) {
                            var $radio = event.currentTarget;

                            dataLayer.push({
                                'covid-affected':
                                    $radio.checked &&
                                    $radio.value === CONSTANTS.COVID_IDENTIFY_ACCEPTED_VALUE
                            });
                        });
                    });

                    _.each($covidTermsRadioButtons, function ($radio) {
                        $radio.addEventListener('change', covidTermsChange);
                    });
                }

                coned.utils.addGeneralListeners(
                    $manageSchedulePaymentButton,
                    manageSchedulePaymentClickEvent
                );
                coned.utils.addGeneralListeners(
                    $manageAcceptAndPaymentButton,
                    manageSchedulePaymentClickEvent
                );

                _.each($closeSelectors, function ($closeSelector) {
                    coned.utils.addGeneralListeners($closeSelector, function () {
                        // If the button is only to accept, doesn't add cancel tagging.
                        if (!$closeSelector.classList.contains(CONSTANTS.CLOSE_SELECTOR_ACCEPT)) {
                            if (_isCommercial && _editedPaymentAgreement) {
                                dataLayer.push({
                                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                                    event: CONSTANTS.SCENARIO_MODIFIED_CANCEL_TAG
                                });
                            } else if (_isPaymentAgreementSao) {
                                dataLayer.push({
                                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                                    event: CONSTANTS.SCENARIO_SAO_CANCEL_TAG
                                });
                            } else if (_isPaymentAgreementSaoModified) {
                                dataLayer.push({
                                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                                    event: CONSTANTS.SCENARIO_SAO_MODIFIED_CANCEL_TAG
                                });
                            } else if (_isPaymentAgreementModified) {
                                dataLayer.push({
                                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                                    event: CONSTANTS.SCENARIO_MODIFIED_CANCEL_TAG
                                });
                            } else if (_isCommercial) {
                                dataLayer.push({
                                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                                    event: CONSTANTS.SCENARIO_COMMERCIAL_CANCEL_TAG
                                });
                            } else {
                                dataLayer.push({
                                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                                    event: CONSTANTS.PAYMENT_AGREEMENT_FORM_CANCEL_TAG
                                });
                            }
                        }
                    });
                });

                if ($progressBar) {
                    initializeProgressBar();
                }

                // Tagging Events
                coned.utils.addGeneralListeners($linkAccordionSelector, taggingStartPaymentAgreement);
                coned.utils.addGeneralListeners($manageSchedulePaymentButton, taggingSchedulePayment);
                coned.utils.addGeneralListeners($makePaymentButton, taggingMakePayment);

                if (_isPaymentAgreementEditable) {
                    _.each($openEditPaymentAgreementPopupButton, function ($element) {
                        coned.utils.addGeneralListeners($element, taggingEditPaymentAgreement);
                    });
                }

                for (
                    var radioButtonsIndex = 0;
                    radioButtonsIndex < $radioButtonsSelector.length;
                    radioButtonsIndex++
                ) {
                    $radioButtonsSelector[radioButtonsIndex].addEventListener('focusin', function () {
                        var actualElement = this.parentElement.getElementsByClassName(
                            CONSTANTS.RADIO_INDICATOR
                        )[0];
                        query.addClass(actualElement, CONSTANTS.RADIO_INDICATOR_FOCUS);
                    });

                    $radioButtonsSelector[radioButtonsIndex].addEventListener('focusout', function () {
                        var actualElement = this.parentElement.getElementsByClassName(
                            CONSTANTS.RADIO_INDICATOR
                        )[0];
                        query.removeClass(actualElement, CONSTANTS.RADIO_INDICATOR_FOCUS);
                    });
                }

                // listener for the continue buttons on the comercial form
                coned.utils.addGeneralListeners($continueButton, function() {

                    setTimeout(function () {
                        if ($paymentTableContainer.classList.contains(CONSTANTS.SHOW_COVID_TERMS)){
                            _covidTerms = $paymentTableContainer.dataset.covidTerms === 'true' ? true : false;
                            successCovidIdentifySubmit();
                        }
                    }, 300);
                })
            }

            _isEventsLoaded = true;
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            preInitialize();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardPaymentAgreement.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardPaymentAgreement;
})();
