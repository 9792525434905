// ==================== LOAD CARD TILES COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoadCardTiles = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CARD_CLASS: 'js-card-selector',
        CARD_CLASS_LINK_ELEMENT: '.card-tile__link-wrapper',
        CARD_CLASS_CTA_ELEMENT: '.card-tile__info-container--type-cta',
        CARD_CLASS_TITLE_ELEMENT: '.card-tile__title',
        CARD_CLASS_DESCRIPTION_ELEMENT: '.card-tile__description',
        CARD_CLASS_WRAPPER: 'card-tile__link-wrapper',
        CARD_CLASS_TILE_WRAPPER: 'card-tile-wrapper',
        CARD_CLASS_GRID_WRAPPER: 'card-tile-grid__link-wrapper',
        CARD_CLASS_FOCUS: 'card-tile--focus',
        CARD_CLASS_MODULE_WRAPPER: 'card-module__wrapper-link',
        CARD_CLASS_MODULE_WRAPPER_FOCUS: 'card-module__wrapper-link--focus',
        ANIMATION_CLASS: 'card-tile__zoom--animation',
        OVERVIEW_BILL_CARD_CONTAINER_ELEMENT: 'js-overview-bill-card-container',
        OVERVIEW_BILL_CARD_PRICE_ELEMENT: 'js-overview-bill-card-price',
        FONT_SIZE: 'font-size',
        SELECTOR_CE_LINE_CLAMP: '.js-ce-line-clamp'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LoadCardTiles = function ($cardTilesContainer) {
        /**
         * PRIVATE METHODS
         */
        var $cards, 
            $cardModules,
            $overviewBillCardContainer,
            $overviewBillCardPrice,
            overviewBillCardPriceFontSize;

        var initAnimation = function () {
            var timerAnimation = 200,
                itemTimer = 0;

            for (var index = 0; index < $cards.length; index++) {
                var $card = $cards[index];

                animationTimeout($card, itemTimer);

                //Updating timer
                itemTimer += timerAnimation;
            }
        };

        var initClamp = function () {
            for (var index = 0; index < $cards.length; index++) {
                var $card = $cards[index];
                findAndClampDescription($card);
            }
        };

        var findAndClampDescription = function ($card) {
            var $cardTitle = $card.querySelector(CONSTANTS.CARD_CLASS_TITLE_ELEMENT);
            var $cardDescription = $card.querySelector(CONSTANTS.CARD_CLASS_DESCRIPTION_ELEMENT);
            var $cardLinked = $card.querySelector(CONSTANTS.CARD_CLASS_LINK_ELEMENT);
            var $cardCTA = $card.querySelector(CONSTANTS.CARD_CLASS_CTA_ELEMENT);
            var $contentCeLineClamps = $card.querySelectorAll(CONSTANTS.SELECTOR_CE_LINE_CLAMP);

            // Test if link or cts is present
            if (($cardDescription && $cardLinked) || ($cardDescription && $cardCTA)) {
                // lineClamp Title
                coned.utils.lineClamp($cardTitle);
                // lineClamp Description
                coned.utils.lineClamp($cardDescription);
            }
            
            if($contentCeLineClamps.length != 0){
                // for in content has line clamp
                Array.from($contentCeLineClamps).forEach(function ($contentCeLineClamp) {
                    coned.utils.lineClamp($contentCeLineClamp);
                });
            }
        };

        var animationTimeout = function ($card, itemTimer) {
            setTimeout(function () {
                $card.classList.remove(CONSTANTS.ANIMATION_CLASS);
            }, itemTimer);
        };

        var initializeData = function () {
            $cards = $cardTilesContainer.getElementsByClassName(CONSTANTS.CARD_CLASS);
            $cardModules = $cardTilesContainer.getElementsByClassName(
                CONSTANTS.CARD_CLASS_MODULE_WRAPPER
            );
            $overviewBillCardContainer = $cardTilesContainer.getElementsByClassName(
                CONSTANTS.OVERVIEW_BILL_CARD_CONTAINER_ELEMENT
            )[0];
            $overviewBillCardPrice = $cardTilesContainer.getElementsByClassName(
                CONSTANTS.OVERVIEW_BILL_CARD_PRICE_ELEMENT
            )[0];

            if ($overviewBillCardPrice) {
                overviewBillCardPriceFontSize = parseInt(
                    window
                        .getComputedStyle($overviewBillCardPrice, null)
                        .getPropertyValue(CONSTANTS.FONT_SIZE)
                )
            }
        };

        var initializeEvents = function () {
            initAnimation();
            initClamp();

            // window.addEventListener('resize', initClamp);

            // Price font-size dynamic resizing for big amounts ie: 6+ digits with cents
            if ($overviewBillCardContainer && $overviewBillCardPrice) {
                // Update price font-size on load if needed
                coned.utils.updateFontToFitAncestor($overviewBillCardPrice, $overviewBillCardContainer, overviewBillCardPriceFontSize);

                // Resize event for price font-size
                coned.utils.updateFontResizeThrottler(
                    coned.utils.updateFontToFitAncestor,
                    $overviewBillCardPrice,
                    $overviewBillCardContainer
                );
            }

            // Adding the focus event on all the cards, when the focus is on the wrapper link
            var listWrapperElements = document.getElementsByClassName(CONSTANTS.CARD_CLASS_WRAPPER);

            listWrapperElements =
                listWrapperElements.length > 0
                    ? listWrapperElements
                    : document.getElementsByClassName(CONSTANTS.CARD_CLASS_GRID_WRAPPER);

            for (var wrapElement = 0; wrapElement < listWrapperElements.length; wrapElement++) {
                listWrapperElements[wrapElement].addEventListener('focusin', function (event) {
                    event.currentTarget.parentElement.parentElement.classList.add(
                        CONSTANTS.CARD_CLASS_FOCUS
                    );
                });

                listWrapperElements[wrapElement].addEventListener('focusout', function () {
                    var cardWrapperElement = document.getElementsByClassName(
                        CONSTANTS.CARD_CLASS_FOCUS
                    );

                    if (cardWrapperElement.length > 0) {
                        cardWrapperElement[0].classList.remove(CONSTANTS.CARD_CLASS_FOCUS);
                    }
                });
            }

            // Adding the focus event on all the cards, when the focus is on the tile wrapper
            var listTileWrapperElements = document.getElementsByClassName(
                CONSTANTS.CARD_CLASS_TILE_WRAPPER
            );

            for (
                var tileWrapElement = 0;
                tileWrapElement < listTileWrapperElements.length;
                tileWrapElement++
            ) {
                listTileWrapperElements[tileWrapElement].addEventListener(
                    'focus',
                    function (event) {
                        var cardWrapperElement = document.getElementsByClassName(
                            CONSTANTS.CARD_CLASS_FOCUS
                        );

                        if (cardWrapperElement.length > 0) {
                            cardWrapperElement[0].classList.remove(CONSTANTS.CARD_CLASS_FOCUS);
                        }
                        event.currentTarget.classList.add(CONSTANTS.CARD_CLASS_FOCUS);
                    }
                );
            }

            for (var index = 0; index < $cardModules.length; index++) {
                $cardModules[index].addEventListener('focusin', function () {
                    query.addClass(
                        event.target.parentElement,
                        CONSTANTS.CARD_CLASS_MODULE_WRAPPER_FOCUS
                    );
                });

                $cardModules[index].addEventListener('focusout', function () {
                    query.removeClass(
                        event.target.parentElement,
                        CONSTANTS.CARD_CLASS_MODULE_WRAPPER_FOCUS
                    );
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoadCardTiles.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LoadCardTiles;
})();
