// ==================== PAY MY BILL GUEST COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PayMyBillNoLogin = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        PAGE_HEADER: 'js-page-header',
        HERO_SECTION_SELECTOR: 'js-hero',
        SINGLE_PMB_MODULE_SELECTOR: 'js-single-pmb-module',
        FORM_SELECTOR: 'js-no-login-form',
        RADIO_BUTTON_SELECTOR: 'js-coned-radio',
        BILL_SELECTOR: 'js-bill-selector',
        ACCOUNT_NUMBER_INPUT: 'js-account-number-input',
        NOT_YOUR_ACCOUNT_SELECTOR: 'js-not-your-account',
        BILL_HEADER_SELECTOR: 'js-bill-header',
        ACCOUNT_ADDRESS_SELECTOR: 'js-account-address',
        ACCOUNT_NUMBER_SELECTOR: 'js-account-number-selector',
        SERVICE_TYPE_SELECTOR: 'js-service-fee',
        SUBMIT_ANIMATION_SELECTOR: 'js-submit-progress-animation',
        AMOUNT_INPUT_SELECTOR: 'js-currency-input',
        INPUT_DATE_SELECTOR: 'js-current-datepicker-input',
        BILL_LOOKUP_ERROR_SELECTOR: 'js-bill-lookup-error',
        TOTAL_AMOUNT: 'js-total-selector',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        FORM_SCID_NAME: 'ScId',
        FORM_ACCOUNT_NUMBER_NAME: 'accountNumber',
        FORM_PAYMENT_METHOD_NAME: 'paymentMethod',
        FORM_EMAIL_ADDRESS_NAME: 'emailAddress',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        DUE_BILL_CARD_CLASSES: 'pay-my-bill-card pay-my-bill-card--red',
        DUE_BILL_CARD_MESSAGE_CLASS: 'pay-my-bill-card__title',
        PAYMENT_METHOD_CONTAINER: 'js-payment-method-container',
        BANK_ACCOUNT_INPUT_RADIO: 'js-bank-account-radio',
        CREDIT_CARD_INPUT_RADIO: 'js-credit-card-radio',
        CREDIT_CARD_INFO_MESSAGE: 'js-credit-card-message',
        CREDIT_CARD_EMAIL_INPUT: 'js-credit-card-input',
        EMAIL_INPUT: 'js-email-address',
        CREDIT_CARD_MESSAGE_CONTAINER: 'js-credit-card-message-container',
        CREDIT_CARD_MESSAGE_SELECTOR: 'js-credit-card-thanks',
        ACCOUNT_ADDRESS: 'js-account-address',
        ACCOUNT_NUMBER: 'js-account-number',
        FORM_STEP_CLASS: 'js-step-form',
        FINISH_STEP_SELECTOR: 'js-step-finish',
        HIDDEN_CLASS: 'hidden',
        SELECTED_CLASS: 'selected',
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        CAPTCHA_ELEMENT: 'js-recaptcha',
        FILLED_INPUT: 'coned-input--filled',
        ACTIVE_SELECT: 'coned-select--active',
        ERROR_INPUT: 'coned-input-message--error',
        VALID_CLASS: 'valid',
        POPUP_DPP_ELEMENT: 'js-popup-dpp',
        POPUP_CONTENT_CLASS: 'popup__content',
        NEW_WINDOW_SPAN: 'js-new-window-span',
        MINIMUM_PAYMENT_ALERT: 'js-minimum-payment-alert',
        VISUALY_HIDDEN_CLASS: 'visually-hidden',
        DATA_INPUT_SHOULD_START_VALIDATE: 'data-input-should-start-validate',
        ACCOUNT_TOKEN: /@ACCOUNT/g,
        ADDRESS_TOKEN: /@ADDRESS/g,
        EMAIL_TOKEN: /@EMAIL/g,
        NUMBER_TOKEN: /@NUMBER/g,
        IMGSRC_TOKEN: /@IMGSRC/g,
        IMGALT_TOKEN: /@IMGALT/g,
        MIN_TOKEN: /@MIN/g,
        MAX_TOKEN: /@MAX/g,

        // Tagging
        TAG_START: 'guest.pay.start',
        TAG_SUBMIT_SUCCESS: 'guest.pay.submit',
        TAG_SUBMIT_ERROR: 'guest.pay.error',
        TAG_PAYMENT_METHOD: 'guest.pay.paymentMethod',
        TAG_THANK_YOU_PAGE: 'guest.pay.confirmation',
        TAG_PAY_BILL_CREDIT_CARD: 'pay.bill.creditcard',

        // MFA Address Selection
        SELECTED_ADDRESS_SUBTITLE: 'js-chosen-account-subtitle',
        MFA_GET_CODE: 'js-get-security-code',
        MFA_WRONG_ADDRESS: 'js-wrong-address',
        PMB_MAIN_FORM: 'js-pmb-guest-main-form',
        PMB_MFA_FORM: 'js-pmb-guest-mfa-form',
        MANUAL_ACCOUNT: 'js-manual-account',
        MFA_CHOOSE_ACCOUNT: 'js-mfa-choose-account',
        MFA_CHOOSE_ACCOUNT_CONTAINER: 'js-mfa-choose-account-container',
        MFA_ADDRESS_DROPDOWN: 'js-address-dropdown',
        MFA_STEP_1: 'js-mfa-get-account-step-1',
        MFA_STEP_2: 'js-mfa-get-account-step-2',
        MFA_CONTACT_TYPE: 'js-contact-type-mfa',
        MFA_CONTACT_TYPE_CONTENT: 'js-contact-type-content-mfa',
        MFA_EMAIL_INPUT: 'js-mfa-email',
        MFA_PHONE_INPUT: 'js-mfa-phone',
        MFA_SEND_CODE: 'js-send-code-button',
        MFA_PHONE_RADIOS: 'js-phone-contact-method',
        MFA_TEXT_DESCRIPTION: 'js-mfa-text-description',
        MFA_VOICE_DESCRIPTION: 'js-mfa-voice-description',
        MFA_EMAIL_DESCRIPTION: 'js-mfa-email-description',
        MFA_CODE_INPUT: 'js-mfa-code-input',
        MFA_SEND_AGAIN: 'js-send-code-again',
        MFA_SERVICE_ERROR: 'js-mfa-service-error',
        MFA_SUBMIT_CODE: 'js-submit-code-button',
        MFA_CONTACT_TYPE_DICT: {
            phone: 'js-phone-contact-mfa',
            email: 'js-email-contact-mfa'
        },
        MFA_CONTACT_TYPE_NAME: 'contactOption',
        MFA_PHONE_NUMBER_NAME: 'mfaPhoneNumber',
        MFA_EMAIL_INPUT_NAME: 'mfaEmail',
        MFA_PHONE_CONTACT_TYPE_NAME: 'phoneContactMethod',
        MFA_CODE_INPUT_NAME: 'verificationCode',
        MFA_ADDRESS_BOX_RADIO_NAME: 'addressBoxRadio',

        // Address Selection
        MULTIPLE_ADDRESS_CONTAINER: 'js-address-button-container',
        MULTIPLE_ADDRESS_BUTTON: 'js-address-button',
        SEVERAL_ADDRESS_CONTAINER: 'js-dropdown-list',
        SEVERAL_ADDRESS_BUTTON: 'js-dropdown-item-contain',

        // Address Creation
        SINGLE_ADDRESS_TEMPLATE:
            '<div class="address-box address-box--no-margin" data-account="@ACCOUNT"><div class="address-box__container address-dropdown__select"><div class="address-box__info"><p class="address-box__container-title">@ADDRESS</p></div></div></div>',
        MULTIPLE_ADDRESS_CONTAINER_TEMPLATE:
            '<div class="coned-form__addresses-container js-address-button-container"></div>',
        MULTIPLE_ADDRESS_TEMPLATE:
            '<div class="coned-form__address-box-button-wrapper">' +
            '<input id="@ACCOUNT" name="addressBoxRadio" class="visually-hidden coned-form__address-box-button js-address-button" type="radio" data-account="@ACCOUNT">' +
            '<label for="@ACCOUNT">' +
            '<img class="coned-form__address-box-button-image" src="@IMGSRC" alt="@IMGALT"/>' +
            '<p class="coned-form__address-box-button-address">@ADDRESS</p>' +
            '<span class="icon-check-status coned-form__address-box-button-check"></span>' +
            '</label>' +
            '</div>',

        SEVERAL_ADDRESS_CONTAINER_TEMPLATE:
            '<div class="address-dropdown__container address-dropdown__container--no-margin">' +
            '<div class="js-a11y-form js-address-dropdown address-dropdown address-dropdown--full-width js-module" data-module="AddressDropdown">' +
            '<div class="address-dropdown__button js-dropdown-button" tabindex="0" role="button" aria-haspopup="true" aria-describedby="pmbDropdownAddressIdDescripcion" aria-controls="pmbDropdownAddressIdControls">' +
            '<div class="address-box  address-box--no-margin js-dropdown-button-contain tag-transfer-service-address-dropdown" data-account="@ACCOUNT">' +
            '<div class="address-box__container address-dropdown__select">' +
            '<div class="address-box__info">' +
            '<p class="address-box__container-title">@ADDRESS</p>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '<span class="icon-carrot address-dropdown__icon js-dropdown-carrot"></span>' +
            '</div>' +
            '<span id="pmbDropdownAddressIdDescripcion" class="visually-hidden">Press the enter key to access the list. Inside of it, move using up and down arrows to review and enter to select.</span>' +
            '<ul id="pmbDropdownAddressIdControls" class="address-dropdown__list js-dropdown-list address-dropdown__list-item--hidden" role="listbox"></ul>' +
            '</div>' +
            '</div>',

        SEVERAL_ADDRESS_TEMPLATE:
            '<li class="address-dropdown__list-item js-dropdown-item " role="option" tabindex="-1" aria-selected="false">' +
            '<div class="address-box  address-box--item js-dropdown-item-contain" data-account="@ACCOUNT">' +
            '<div class="address-box__container">' +
            '<div class="address-box__info">' +
            '<p class="address-box__container-title">@ADDRESS</p>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '<span class="address-dropdown__icon address-dropdown__icon--check js-icon-check"></span>' +
            '</li>',

        SEVERAL_ADDRESS_SELECTED_TEMPLATE:
            '<li class="address-dropdown__list-item js-dropdown-item " role="option" tabindex="-1" aria-selected="true">' +
            '<div class="address-box  address-box--item js-dropdown-item-contain" data-account="@ACCOUNT">' +
            '<div class="address-box__container">' +
            '<div class="address-box__info">' +
            '<p class="address-box__container-title">@ADDRESS</p>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '<span class="address-dropdown__icon address-dropdown__icon--check js-icon-check icon-check-status"></span>' +
            '</li>',

        TONP_AMOUNT_MESSAGE: 'js-tonp-message'
    
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var PayMyBillNoLogin = function ($billLookup) {
        /**
         * PRIVATE METHODS
         */
        var $pageHeader,
            $heroSection,
            $billSelector,
            $accountNumberInput,
            $notYourAccountLink,
            $billHeader,
            $billAccountAddress,
            $billAccountNumber,
            $billServiceType,
            $billAmountInput,
            $billLookupServiceError,
            $submitAnimationSelector,
            $singlePmbModule,
            $totalAmountSelector,
            $inputDateSelector,
            $paymentMethodContainer,
            $bankAccountInputRadio,
            $creditCardInputRadio,
            $creditCardInfoMessage,
            $creditCardEmailInput,
            $creditCardMessageContainer,
            $creditCardMessageSelector,
            $accountAddress,
            $accountNumber,
            $finishStepSelector,
            $formStep,
            $minimumPaymentAlert,
            $form,
            $radioButtons,
            $submitButton,
            $captchaElement,
            $popupDPP,
            $popupDPPContent,
            $newWindowSpan,
            _hasCaptcha,
            recaptcha,
            _accountNumberInputHaveBeenFocus,
            _data,
            // MFA Elements
            $selectedAddressSubtitle,
            $getCodeButton,
            $wrongAddressButton,
            $mainForm,
            $manualAccountContainer,
            $mfaChooseAccount,
            $mfaChooseAccountContainer,
            $mfaForm,
            $mfaStep1,
            $mfaStep2,
            $mfaContactType,
            $mfaContactTypeContent,
            $mfaEmailInput,
            $mfaPhoneRadios,
            $mfaPhoneInput,
            $mfaSendCodeButton,
            $mfaSendCodeAnimationSelector,
            $mfaTextDescription,
            $mfaVoiceDescription,
            $mfaEmailDescription,
            $mfaCodeInput,
            $mfaSendAgainButton,
            $mfaSubmitCodeAnimationSelector,
            $mfaSubmitCode,
            _dataStep1,
            _dataStep2,
            _selectedAccount;

        var billLookup = function () {
            new coned.components.SubmitAnimation(
                $submitAnimationSelector,
                billLookupServiceCall,
                successBillLookupServiceCallback,
                errorBillLookupServiceCallback,
                false,
                true
            );
        };

        var billLookupServiceCall = function () {
            if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                $submitAnimationSelector.classList.add(
                    coned.constants.ERROR_SERVICE_RESPONSE_CLASS
                );
            } else {
                var serviceUrl = $billLookup.dataset.serviceUrl,
                    params;

                // Analytics data building
                dataLayer.push({
                    event: 'find.bill.click'
                });

                // Hide error
                $billLookupServiceError.classList.add(CONSTANTS.HIDDEN_CLASS);

                // Service data
                params = {
                    ScId: query.getFormInputValue(document, CONSTANTS.FORM_SCID_NAME),
                    PaymentMethod: query.getFormInputValue(
                        $billLookup,
                        CONSTANTS.FORM_PAYMENT_METHOD_NAME
                    ),
                    EmailAddress: encodeURIComponent(
                        query.getFormInputValue(
                            $billLookup,
                            CONSTANTS.FORM_EMAIL_ADDRESS_NAME
                        )
                    ),
                    IsMultipay: false
                };

                if (_selectedAccount) {
                    params.AccountNumber = _selectedAccount;
                } else {
                    params.AccountNumber = query.getFormInputValue(
                        $billLookup,
                        CONSTANTS.FORM_ACCOUNT_NUMBER_NAME
                    );
                }

                if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                    params.Verify = recaptcha.getResponse();
                }

                // Service call
                query.getData(serviceUrl, successCallback, errorCallback, params);
            }
        };

        var successCallback = function (data) {
            $submitAnimationSelector.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var errorCallback = function (data) {
            $submitAnimationSelector.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var successBillLookupServiceCallback = function () {
            if (_hasCaptcha && recaptcha) {
                recaptcha.reset();
            }

            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_BILL_NO_AUTH,
                    updateBill,
                    errorBillLookupServiceCallback
                );
            } else {
                updateBill(_data);
            }
        };

        var creditCardPayment = function (creditCardLink, address, accountNumber, accountType) {
            var creditCardMessage = $creditCardMessageContainer.innerHTML,
                serviceAddressContent = $accountAddress.innerHTML,
                accountNumberContent = $accountNumber.innerHTML;

            // Update link in credit card thanks message
            creditCardMessage = creditCardMessage.replace('{{LINKCARD}}', creditCardLink);
            $creditCardMessageContainer.innerHTML = creditCardMessage;
            // Update account info
            serviceAddressContent = serviceAddressContent.replace('{{ADDRESS}}', '<strong>' + address + '</strong>');
            $accountAddress.innerHTML = serviceAddressContent;
            accountNumberContent = accountNumberContent.replace('{{ACCOUNT}}', '<strong>' + accountNumber + '</strong>');
            $accountNumber.innerHTML = accountNumberContent;            

            dataLayer.push({
                event: CONSTANTS.TAG_PAY_BILL_CREDIT_CARD,
                creditAccountType: accountType
            });

            setTimeout(function () {
                window.open(creditCardLink);
            }, 3000);
        };

        var updateBill = function (data) {
            if ($creditCardInputRadio.checked) {
                // Hide header
                if ($pageHeader) {
                    $pageHeader.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                
                creditCardPayment(
                    data.CreditCardRedirectUrl, 
                    data.Address, 
                    data.AccountNumber,
                    data.AccountType
                );
                $billLookup.classList.add(CONSTANTS.HIDDEN_CLASS);
                $formStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                $finishStepSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $creditCardMessageSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $billSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                window.scrollTo(0, 0);

                $creditCardMessageSelector.focus();

                // Rebinds lost events with the creditCardPayment
                coned.components.singlePayMyBill.rebindEvents();

                // Analytics data building
                dataLayer.push({
                    event: CONSTANTS.TAG_THANK_YOU_PAGE,
                    contentTitle: $creditCardInputRadio.value
                });
            } else {
                // Fields updates
                $billAccountAddress.innerHTML = data.Address;
                $billAccountNumber.innerHTML = _selectedAccount
                    ? _selectedAccount
                    : $accountNumberInput.value;
                $billServiceType.dataset.type = data.AccountType;
                $totalAmountSelector.dataset.minAmount = data.PaymentLowerLimit;
                $billAmountInput.dataset.paymentMin = data.PaymentLowerLimit;
                $billAmountInput.dataset.paymentMax = data.PaymentUpperLimit;
                $singlePmbModule.dataset.accountNumber = _selectedAccount
                    ? _selectedAccount
                    : $accountNumberInput.value;
                $singlePmbModule.dataset.accountLastDigits = data.LastAccountDigits;
                $singlePmbModule.dataset.confirmationEmail = data.ConfirmationEmail;
                $singlePmbModule.dataset.address = data.Address;
                $singlePmbModule.dataset.maid = data.Maid;

                // Minimum Payment Amount Alert message update
                if ($minimumPaymentAlert) {
                    $minimumPaymentAlert.innerHTML = $minimumPaymentAlert.innerHTML.replace(
                        '@MIN',
                        parseFloat(data.PaymentLowerLimit).toFixed(2)
                    );
                    $minimumPaymentAlert.innerHTML = $minimumPaymentAlert.innerHTML.replace(
                        '@MAX',
                        parseFloat(data.PaymentUpperLimit).toFixed(2)
                    );
                }

                // Payment limits message update
                var paymentLimitsMsg = $billAmountInput.dataset.msgPaymentlimits;
                paymentLimitsMsg = paymentLimitsMsg.replace(
                    '@MIN',
                    parseFloat(data.PaymentLowerLimit).toFixed(2)
                );
                paymentLimitsMsg = paymentLimitsMsg.replace(
                    '@MAX',
                    parseFloat(data.PaymentUpperLimit).toFixed(2)
                );
                $billAmountInput.dataset.msgPaymentlimits = paymentLimitsMsg;
                if (data.FullAmountDue && data.FullAmountDueCents) {
                    var stringValue = data.FullAmountDue + data.FullAmountDueCents;
                    var value = parseFloat(stringValue.replaceAll(",", ""));

                    if (value < 0) {
                        $billAmountInput.value = 0;
                    } else {
                        $billAmountInput.value = data.FullAmountDue + data.FullAmountDueCents;
                    }
                    $billAmountInput.classList.add(CONSTANTS.INPUT_FILLED_CLASS);

                    // Format new input value to currency format
                    coned.utils.triggerEvent($billAmountInput, 'keyup');
                    // Reset input keyup validation for the user so it doesn't show error when tabbing to it
                    $billAmountInput.removeAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE);
                }

                // Set the date picker dates limit
                var newDate = new Date(),
                    day = newDate.getDate(),
                    month = newDate.getMonth(),
                    year = newDate.getFullYear(),
                    stringDate = ++month + '/' + day + '/' + year;

                $inputDateSelector.value = stringDate;
                $inputDateSelector.classList.add(CONSTANTS.FILLED_INPUT);

                setTimeout(function () {
                    var dates = data.LatestAllowedPostPaymentDate
                            ? coned.utils.datepickerDateFormat(
                                  null,
                                  data.LatestAllowedPostPaymentDate
                              )
                            : { minDate: '0', maxDate: '5d' },
                        todayPlusFive = new Date();

                    todayPlusFive.setDate(todayPlusFive.getDate() + 5);

                    $inputDateSelector.dataset.maxDateDays = data.LatestAllowedPostPaymentDate
                        ? data.LatestAllowedPostPaymentDate
                        : todayPlusFive.toLocaleString('en-us').split(',')[0];

                    $($inputDateSelector).datepicker('option', 'minDate', dates.minDate);
                    $($inputDateSelector).datepicker('option', 'maxDate', dates.maxDate);
                }, 1);

                // Overdue management
                if (data.OverDue) {
                    var $cardContainer = document.createElement('div'),
                        $cardMessage = document.createElement('h3');

                    // Assign classes and content
                    $cardContainer.classList = CONSTANTS.DUE_BILL_CARD_CLASSES;
                    $cardMessage.className = CONSTANTS.DUE_BILL_CARD_MESSAGE_CLASS;
                    $cardMessage.innerHTML = data.OverDueMessage;

                    // Append elements
                    $cardContainer.appendChild($cardMessage);
                    $billHeader.parentNode.insertBefore($cardContainer, $billHeader);
                }

                // 'page' switch
                $billLookup.classList.add(CONSTANTS.HIDDEN_CLASS);
                $billSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                // display popup if response exists
                if (data.ShowDPP) {
                    if ($popupDPP) {
                        if (data.DPPNotificationMessage) {
                            $popupDPPContent.innerText = data.DPPNotificationMessage;
                        }

                        if ($popupDPP.classList.contains(CONSTANTS.HIDDEN_CLASS)) {
                            $popupDPP.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        }
                    }
                }
            }

            // Analytics data building
            dataLayer.push({
                event: 'find.bill.success'
            });

            dataLayer.push({
                event: CONSTANTS.TAG_SUBMIT_SUCCESS,
                contentTitle: $form.paymentMethod.value
            });
        };

        var errorBillLookupServiceCallback = function () {
            if (_hasCaptcha && recaptcha) {
                recaptcha.reset();
            }

            if (_data) {
                var $msgTextSelector = $billLookupServiceError.getElementsByClassName(
                    CONSTANTS.ERROR_TEXT_SELECTOR
                )[0];
                var error =
                    _data && _data.errorMsg ? _data.errorMsg : coned.constants.ERROR_MESSAGE;

                $billLookupServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $msgTextSelector.innerHTML = error;
                $billLookupServiceError.focus();

                // Analytics data building
                dataLayer.push({
                    event: 'find.bill.error'
                });

                dataLayer.push({
                    event: CONSTANTS.TAG_SUBMIT_ERROR,
                    contentTitle: error
                });
            }
        };

        var notYourAccountEvent = function (event) {
            event.preventDefault();

            $accountNumberInput.value = '';
            query.removeClass($accountNumberInput, CONSTANTS.INPUT_FILLED_CLASS);
            $billLookup.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $billSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var paymentMethodChangeEvent = function () {
            if ($creditCardInputRadio.checked) {
                $newWindowSpan.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $newWindowSpan.classList.add(CONSTANTS.VISUALY_HIDDEN_CLASS);

                if ($creditCardInfoMessage) {
                    $creditCardInfoMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }

                if ($creditCardEmailInput) {
                    $creditCardEmailInput.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            } else if ($bankAccountInputRadio.checked) {
                $newWindowSpan.classList.add(CONSTANTS.HIDDEN_CLASS);
                $newWindowSpan.classList.remove(CONSTANTS.VISUALY_HIDDEN_CLASS);

                if ($creditCardInfoMessage) {
                    $creditCardInfoMessage.classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                if ($creditCardEmailInput) {
                    $creditCardEmailInput.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }
        };

        var enablePayBillButton = function () {
            $submitButton.disabled = !(
                $($form).validate().checkForm() &&
                (_selectedAccount || $accountNumberInput.value !== '')
            );
        };

        /**
         * Show MFA flow to users that don't know the account number
         */
        var showMfaFlow = function () {
            $mainForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $heroSection && $heroSection.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaForm.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $mfaForm.focus();
        };

        /**
         * Shows correct form depending on chosen conctact type
         */
        var handleContactType = function () {
            var $targetForm = $mfaForm.getElementsByClassName(
                    CONSTANTS.MFA_CONTACT_TYPE_DICT[$mfaContactType.value]
                )[0],
                $radios = $mfaForm.getElementsByClassName(CONSTANTS.RADIO_BUTTON_SELECTOR);

            _.each($mfaContactTypeContent, function ($item) {
                $item.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($radios, function ($radio) {
                $radio.checked = false;
            });

            $mfaPhoneInput.value = '';
            $mfaPhoneInput.classList.remove(CONSTANTS.VALID_CLASS);
            $mfaPhoneInput.classList.remove(CONSTANTS.FILLED_INPUT);
            $mfaPhoneInput.classList.remove(CONSTANTS.ERROR_INPUT);

            $mfaEmailInput.value = '';
            $mfaEmailInput.classList.remove(CONSTANTS.VALID_CLASS);
            $mfaEmailInput.classList.remove(CONSTANTS.FILLED_INPUT);
            $mfaEmailInput.classList.remove(CONSTANTS.ERROR_INPUT);

            $($form).validate().resetForm();
            $targetForm.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Tagging
            if ($mfaContactType.value === 'email') {
                dataLayer.push({
                    contactMethod: 'email'
                });
            }
        };

        /**
         * Send code again event handler
         */
        var sendCodeAgain = function () {
            var $errorContainer = $mfaStep2.getElementsByClassName(CONSTANTS.MFA_SERVICE_ERROR)[0],
                $errorMessage = $errorContainer.getElementsByClassName(
                    CONSTANTS.ERROR_TEXT_SELECTOR
                )[0];

            $errorMessage.innerHTML = coned.constants.ERROR_MESSAGE;
            $errorContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

            $mfaTextDescription.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaVoiceDescription.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaEmailDescription.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);

            $mfaCodeInput.value = '';
            $mfaCodeInput.classList.remove(CONSTANTS.VALID_CLASS);
            $mfaCodeInput.classList.remove(CONSTANTS.FILLED_INPUT);
            $mfaCodeInput.classList.remove(CONSTANTS.ERROR_INPUT);

            $mfaSubmitCode.disabled = true;

            $mfaStep2.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaStep1.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $mfaStep1.focus();
        };

        var sendCode = function () {
            new coned.components.SubmitAnimation(
                $mfaSendCodeAnimationSelector,
                sendCodeServiceCall,
                successSendCode,
                errorSendCode,
                false,
                true
            );
        };

        var successSendCodeCallback = function (data) {
            $mfaSendCodeAnimationSelector.classList.add(
                coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
            );
            _dataStep1 = [data, 200];
        };

        var errorSendCodeCallback = function (data, status) {
            $mfaSendCodeAnimationSelector.classList.add(
                coned.constants.ERROR_SERVICE_RESPONSE_CLASS
            );
            _dataStep1 = [data, status];
        };

        /**
         * Handle Send Code service call submit
         */
        var sendCodeServiceCall = function () {
            var serviceUrl = $mfaStep1.dataset.serviceUrl,
                params;

            $mfaEmailDescription.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaVoiceDescription.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaTextDescription.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);

            params = {
                ScId: query.getFormInputValue(document, CONSTANTS.FORM_SCID_NAME),
                ContactMethod: query.getFormInputValue($mfaStep1, CONSTANTS.MFA_CONTACT_TYPE_NAME),
                VerificationMethod: ''
            };

            if ($mfaContactType.value === 'phone') {
                params.VerificationValue = query.getFormInputValue(
                    $mfaStep1,
                    CONSTANTS.MFA_PHONE_NUMBER_NAME
                );
                params.VerificationMethod = query.getFormInputValue(
                    $mfaStep1,
                    CONSTANTS.MFA_PHONE_CONTACT_TYPE_NAME
                );
            } else {
                params.VerificationValue = query.getFormInputValue(
                    $mfaStep1,
                    CONSTANTS.MFA_EMAIL_INPUT_NAME
                );
            }

            if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                params.Verify = recaptcha.getResponse();
            }

            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successSendCodeCallback,
                errorSendCodeCallback,
                params,
                true
            );
        };

        /**
         * Handle Send Code service call success
         */
        var successSendCode = function () {
            var $errorContainer = $mfaStep1.getElementsByClassName(CONSTANTS.MFA_SERVICE_ERROR)[0];
            $errorContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

            if ($mfaContactType.value === 'email') {
                $mfaEmailDescription.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $mfaEmailDescription.innerHTML = $mfaEmailDescription.dataset.originalText.replace(
                    CONSTANTS.EMAIL_TOKEN,
                    $mfaEmailInput.value
                );
            } else if (
                $mfaContactType.value === 'phone' &&
                query.getFormInputValue($mfaStep1, CONSTANTS.MFA_PHONE_CONTACT_TYPE_NAME) ===
                    'voice'
            ) {
                $mfaVoiceDescription.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $mfaVoiceDescription.innerHTML = $mfaVoiceDescription.dataset.originalText.replace(
                    CONSTANTS.NUMBER_TOKEN,
                    $mfaPhoneInput.value
                );
            } else if (
                $mfaContactType.value === 'phone' &&
                query.getFormInputValue($mfaStep1, CONSTANTS.MFA_PHONE_CONTACT_TYPE_NAME) === 'text'
            ) {
                $mfaTextDescription.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $mfaTextDescription.innerHTML = $mfaTextDescription.dataset.originalText.replace(
                    CONSTANTS.NUMBER_TOKEN,
                    $mfaPhoneInput.value
                );
            }

            $mfaStep1.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaStep2.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $mfaStep2.focus();

            if (_hasCaptcha && recaptcha) {
                recaptcha.reset();
            }
        };

        /**
         * Handle Send Code service call error
         */
        var errorSendCode = function () {
            var data = _dataStep1[0],
                status = _dataStep1[1];

            if (!coned.utils.isPatternLab() && status >= 500) {
                successSendCode();
                return;
            }

            var $errorContainer = $mfaStep1.getElementsByClassName(CONSTANTS.MFA_SERVICE_ERROR)[0],
                $errorMessage = $errorContainer.getElementsByClassName(
                    CONSTANTS.ERROR_TEXT_SELECTOR
                )[0];

            $errorMessage.innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;
            $errorContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $errorContainer.focus();

            if (_hasCaptcha && recaptcha) {
                recaptcha.reset();
            }
        };

        var codeSubmit = function () {
            new coned.components.SubmitAnimation(
                $mfaSubmitCodeAnimationSelector,
                codeSubmitServiceCall,
                successSubmitCode,
                errorSubmitCode,
                false,
                true
            );
        };

        var successCodeSubmitCallback = function (data) {
            $mfaSubmitCodeAnimationSelector.classList.add(
                coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
            );
            _dataStep2 = data;
        };

        var errorCodeSubmitCallback = function (data) {
            $mfaSubmitCodeAnimationSelector.classList.add(
                coned.constants.ERROR_SERVICE_RESPONSE_CLASS
            );
            _dataStep2 = data;
        };

        /**
         * Handle Code Submit service call
         */
        var codeSubmitServiceCall = function () {
            var serviceUrl = $mfaStep2.dataset.serviceUrl,
                $errorContainer = $mfaStep2.getElementsByClassName(CONSTANTS.MFA_SERVICE_ERROR)[0],
                $errorMessage = $errorContainer.getElementsByClassName(
                    CONSTANTS.ERROR_TEXT_SELECTOR
                )[0],
                params;

            $errorMessage.innerHTML = coned.constants.ERROR_MESSAGE;
            $errorContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

            params = {
                ScId: query.getFormInputValue(document, CONSTANTS.FORM_SCID_NAME),
                VerificationMethod: query.getFormInputValue(
                    $mfaStep1,
                    CONSTANTS.MFA_CONTACT_TYPE_NAME
                ),
                AccessCode: query.getFormInputValue($mfaStep2, CONSTANTS.MFA_CODE_INPUT_NAME)
            };

            if ($mfaContactType.value === 'phone') {
                params.VerificationValue = query.getFormInputValue(
                    $mfaStep1,
                    CONSTANTS.MFA_PHONE_NUMBER_NAME
                );
            } else {
                params.VerificationValue = query.getFormInputValue(
                    $mfaStep1,
                    CONSTANTS.MFA_EMAIL_INPUT_NAME
                );

                var $creditCardEmail = $creditCardEmailInput.getElementsByClassName(
                    CONSTANTS.EMAIL_INPUT
                )[0];
                if ($creditCardEmail) {
                    $creditCardEmail.value = query.getFormInputValue(
                        $mfaStep1,
                        CONSTANTS.MFA_EMAIL_INPUT_NAME
                    );
                    $creditCardEmail.classList.add(CONSTANTS.FILLED_INPUT);
                    $creditCardEmail.classList.add(CONSTANTS.VALID_CLASS);
                }
            }

            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successCodeSubmitCallback,
                errorCodeSubmitCallback,
                params,
                true
            );
        };

        /**
         * Handle Submit Code service call success
         */
        var successSubmitCode = function () {
            var data = _dataStep2;

            if (!coned.utils.isPatternLab() && (!data || !data.Accounts || !data.Accounts.length)) {
                errorSubmitCode();
                return;
            }

            var $errorContainer = $mfaStep2.getElementsByClassName(CONSTANTS.MFA_SERVICE_ERROR)[0];
            $errorContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (coned.utils.isPatternLab()) {
                if (parseInt($mfaCodeInput.value) === 1) {
                    query.getData(
                        coned.plConstants.PMB_MFA_SINGLE,
                        parseAccounts,
                        function () {},
                        {}
                    );
                } else if (
                    2 <= parseInt($mfaCodeInput.value) &&
                    parseInt($mfaCodeInput.value) <= 6
                ) {
                    query.getData(
                        coned.plConstants.PMB_MFA_MULTIPLE,
                        parseAccounts,
                        function () {},
                        {}
                    );
                } else {
                    query.getData(
                        coned.plConstants.PMB_MFA_SEVERAL,
                        parseAccounts,
                        function () {},
                        {}
                    );
                }
            } else {
                parseAccounts(data);
            }
        };

        /**
         * Handle Submit Code service call error
         */
        var errorSubmitCode = function () {
            var data = _dataStep2;

            var $errorContainer = $mfaStep2.getElementsByClassName(CONSTANTS.MFA_SERVICE_ERROR)[0],
                $errorMessage = $errorContainer.getElementsByClassName(
                    CONSTANTS.ERROR_TEXT_SELECTOR
                )[0];

            $errorMessage.innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;
            $errorContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $errorContainer.focus();
        };

        /**
         * Parses & creates the account(s) boxes depending on the amount
         */
        var parseAccounts = function (data) {
            var accs = data.Accounts,
                parser = new DOMParser(),
                $accountsContainer;

            // Display Address Box if single Account
            if (accs.length === 1) {
                var acc = accs[0],
                    $accountElement,
                    stringToParse = CONSTANTS.SINGLE_ADDRESS_TEMPLATE.replace(
                        CONSTANTS.ADDRESS_TOKEN,
                        acc.Address
                    ).replace(CONSTANTS.ACCOUNT_TOKEN, acc.AccountNumber);

                _selectedAccount = acc.AccountNumber;
                $accountElement = parser
                    .parseFromString(stringToParse, 'text/html')
                    .getElementsByTagName('div')[0];
                $mfaChooseAccountContainer.appendChild($accountElement);

                $selectedAddressSubtitle.innerHTML = $selectedAddressSubtitle.dataset.singleAccount;

                // Display checkboxes if between 2-6 accounts
            } else if (2 <= accs.length && accs.length <= 6) {
                $accountsContainer = parser
                    .parseFromString(CONSTANTS.MULTIPLE_ADDRESS_CONTAINER_TEMPLATE, 'text/html')
                    .getElementsByTagName('div')[0];

                accs.forEach(function (acc, index) {
                    var $accountElement,
                        stringToParse = CONSTANTS.MULTIPLE_ADDRESS_TEMPLATE.replace(
                            CONSTANTS.ADDRESS_TOKEN,
                            acc.Address
                        )
                            .replace(CONSTANTS.IMGSRC_TOKEN, acc.ImageLink)
                            .replace(CONSTANTS.IMGALT_TOKEN, acc.ImageAlt)
                            .replace(CONSTANTS.ACCOUNT_TOKEN, acc.AccountNumber);

                    $accountElement = parser
                        .parseFromString(stringToParse, 'text/html')
                        .getElementsByTagName('div')[0];
                    $accountElement.addEventListener('change', accountBoxChange);

                    if (index === 0) {
                        var $inputElement = $accountElement.getElementsByClassName(
                            CONSTANTS.MULTIPLE_ADDRESS_BUTTON
                        )[0];

                        $inputElement.setAttribute('required', '');
                        $inputElement.setAttribute('aria-required', 'true');
                    }

                    $accountsContainer.appendChild($accountElement);

                    $selectedAddressSubtitle.innerHTML =
                        $selectedAddressSubtitle.dataset.multipleAccounts;
                });

                $mfaChooseAccountContainer.appendChild($accountsContainer);

                // Display Address Box Dropdown if 7+ accounts
            } else {
                var containerString = CONSTANTS.SEVERAL_ADDRESS_CONTAINER_TEMPLATE.replace(
                        CONSTANTS.ADDRESS_TOKEN,
                        accs[0].Address
                    ).replace(CONSTANTS.ACCOUNT_TOKEN, accs[0].AccountNumber),
                    $accountsDropdown,
                    $accountsWrapper;

                _selectedAccount = accs[0].AccountNumber;

                $accountsContainer = parser
                    .parseFromString(containerString, 'text/html')
                    .getElementsByTagName('div')[0];
                $accountsDropdown = $accountsContainer.getElementsByClassName(
                    CONSTANTS.MFA_ADDRESS_DROPDOWN
                )[0];
                $accountsWrapper = $accountsContainer.getElementsByClassName(
                    CONSTANTS.SEVERAL_ADDRESS_CONTAINER
                )[0];

                accs.forEach(function (acc, i) {
                    var $accountElement, stringToParse;

                    if (i === 0) {
                        stringToParse = CONSTANTS.SEVERAL_ADDRESS_SELECTED_TEMPLATE.replace(
                            CONSTANTS.ADDRESS_TOKEN,
                            acc.Address
                        ).replace(CONSTANTS.ACCOUNT_TOKEN, acc.AccountNumber);
                    } else {
                        stringToParse = CONSTANTS.SEVERAL_ADDRESS_TEMPLATE.replace(
                            CONSTANTS.ADDRESS_TOKEN,
                            acc.Address
                        ).replace(CONSTANTS.ACCOUNT_TOKEN, acc.AccountNumber);
                    }

                    $accountElement = parser
                        .parseFromString(stringToParse, 'text/html')
                        .getElementsByTagName('li')[0];
                    $accountsWrapper.appendChild($accountElement);
                });
                $accountsDropdown.addEventListener('change', accountBoxChange);
                $mfaChooseAccountContainer.appendChild($accountsContainer);
                coned.utils.initializeModules($mfaChooseAccountContainer);

                $selectedAddressSubtitle.innerHTML =
                    $selectedAddressSubtitle.dataset.multipleAccounts;
            }

            $manualAccountContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaChooseAccount.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $mfaStep2.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaStep1.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $mfaForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mainForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $mainForm.focus();

            $submitButton.disabled = !($($form).validate().checkForm() && _selectedAccount);
        };

        /**
         * Toggle _selectedAccount on account change
         */
        var accountBoxChange = function (event) {
            if (event.details && event.details.account) {
                _selectedAccount = event.details.account;
            } else {
                _selectedAccount = event.target.dataset.account;
            }
        };

        /**
         * Resets form to start from scratch when wrong address is input
         */
        var wrongAddressHandler = function () {
            var $radios = $mfaForm.getElementsByClassName(CONSTANTS.RADIO_BUTTON_SELECTOR),
                $contactTypeLabel = $mfaForm.querySelector(
                    'label[for="' + $mfaContactType.id + '"]'
                );

            _selectedAccount = null;

            var $creditCardEmail = $creditCardEmailInput.getElementsByClassName(
                CONSTANTS.EMAIL_INPUT
            )[0];
            if ($creditCardEmail) {
                $creditCardEmail.value = '';
                $creditCardEmail.classList.remove(CONSTANTS.FILLED_INPUT);
                $creditCardEmail.classList.remove(CONSTANTS.VALID_CLASS);
                $creditCardEmail.classList.remove(CONSTANTS.ERROR_INPUT);
            }

            $contactTypeLabel.style.cssText = '';
            $mfaContactType.classList.remove(CONSTANTS.FILLED_INPUT);
            $mfaContactType.classList.remove(CONSTANTS.ACTIVE_SELECT);
            $mfaContactType.classList.remove(CONSTANTS.ERROR_INPUT);
            $mfaContactType.classList.remove(CONSTANTS.VALID_CLASS);
            _.each($mfaContactType.children, function ($item, i) {
                if (i === 0) {
                    $item.disabled = false;
                    $item.selected = true;
                    $item.disabled = true;
                } else {
                    $item.selected = false;
                }
            });

            _.each($mfaContactTypeContent, function ($item) {
                $item.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($radios, function ($radio) {
                $radio.checked = false;
            });

            $mfaTextDescription.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaVoiceDescription.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaEmailDescription.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);

            $mfaPhoneInput.value = '';
            $mfaPhoneInput.classList.remove(CONSTANTS.VALID_CLASS);
            $mfaPhoneInput.classList.remove(CONSTANTS.FILLED_INPUT);
            $mfaPhoneInput.classList.remove(CONSTANTS.ERROR_INPUT);

            $mfaEmailInput.value = '';
            $mfaEmailInput.classList.remove(CONSTANTS.VALID_CLASS);
            $mfaEmailInput.classList.remove(CONSTANTS.FILLED_INPUT);
            $mfaEmailInput.classList.remove(CONSTANTS.ERROR_INPUT);

            $mfaCodeInput.value = '';
            $mfaCodeInput.classList.remove(CONSTANTS.VALID_CLASS);
            $mfaCodeInput.classList.remove(CONSTANTS.FILLED_INPUT);
            $mfaCodeInput.classList.remove(CONSTANTS.ERROR_INPUT);

            $accountNumberInput.value = '';
            $accountNumberInput.classList.remove(CONSTANTS.VALID_CLASS);
            $accountNumberInput.classList.remove(CONSTANTS.FILLED_INPUT);
            $accountNumberInput.classList.remove(CONSTANTS.ERROR_INPUT);

            $mfaSendCodeButton.disabled = true;
            $mfaSubmitCode.disabled = true;

            $mfaChooseAccountContainer.innerHTML = '';
            $($form).validate().resetForm();
            $submitButton.disabled = !$($form).validate().checkForm();

            $mfaChooseAccount.classList.add(CONSTANTS.HIDDEN_CLASS);
            $manualAccountContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $mainForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mfaForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $mfaStep1.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $mfaStep1.focus();
        };

        var taggingRadioButton = function (event) {
            dataLayer.push({
                event: CONSTANTS.TAG_PAYMENT_METHOD,
                contentTitle: event.target.value
            });
        };

        var taggingAccountNumberInput = function () {
            if (!_accountNumberInputHaveBeenFocus) {
                dataLayer.push({
                    event: CONSTANTS.TAG_START
                });
                _accountNumberInputHaveBeenFocus = true;
            }
        };

        var initializeData = function () {
            $pageHeader = document.getElementsByClassName(CONSTANTS.PAGE_HEADER)[0];
            $heroSection = $billLookup.getElementsByClassName(CONSTANTS.HERO_SECTION_SELECTOR)[0];
            $billSelector = document.getElementsByClassName(CONSTANTS.BILL_SELECTOR)[0];
            $accountNumberInput = $billLookup.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER_INPUT
            )[0];
            $notYourAccountLink = $billSelector.getElementsByClassName(
                CONSTANTS.NOT_YOUR_ACCOUNT_SELECTOR
            )[0];
            $billHeader = $billSelector.getElementsByClassName(CONSTANTS.BILL_HEADER_SELECTOR)[0];
            $billAccountAddress = $billSelector.getElementsByClassName(
                CONSTANTS.ACCOUNT_ADDRESS_SELECTOR
            )[0];
            $billAccountNumber = $billSelector.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER_SELECTOR
            )[0];
            $billServiceType = $billSelector.getElementsByClassName(
                CONSTANTS.SERVICE_TYPE_SELECTOR
            )[0];
            $billAmountInput = $billSelector.getElementsByClassName(
                CONSTANTS.AMOUNT_INPUT_SELECTOR
            )[0];
            $billLookupServiceError = $billLookup.getElementsByClassName(
                CONSTANTS.BILL_LOOKUP_ERROR_SELECTOR
            )[0];
            $singlePmbModule = $billSelector.getElementsByClassName(
                CONSTANTS.SINGLE_PMB_MODULE_SELECTOR
            )[0];
            $totalAmountSelector = $billSelector.getElementsByClassName(CONSTANTS.TOTAL_AMOUNT)[0];
            $paymentMethodContainer = $billLookup.getElementsByClassName(
                CONSTANTS.PAYMENT_METHOD_CONTAINER
            )[0];
            $bankAccountInputRadio = $paymentMethodContainer.getElementsByClassName(
                CONSTANTS.BANK_ACCOUNT_INPUT_RADIO
            )[0];
            $inputDateSelector = $billSelector.getElementsByClassName(
                CONSTANTS.INPUT_DATE_SELECTOR
            )[0];
            $creditCardInputRadio = $paymentMethodContainer.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_INPUT_RADIO
            )[0];
            $creditCardInfoMessage = $billLookup.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_INFO_MESSAGE
            )[0];
            $creditCardEmailInput = $billLookup.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_EMAIL_INPUT
            )[0];
            $formStep = $billSelector.getElementsByClassName(CONSTANTS.FORM_STEP_CLASS)[0];
            $form = $billLookup.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            $submitButton = $form.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $submitAnimationSelector = $form.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_SELECTOR
            )[0];
            $radioButtons = $form.getElementsByClassName(CONSTANTS.RADIO_BUTTON_SELECTOR);
            $finishStepSelector = $billSelector.getElementsByClassName(
                CONSTANTS.FINISH_STEP_SELECTOR
            )[0];
            $creditCardMessageSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_MESSAGE_SELECTOR
            )[0];
            $creditCardMessageContainer = $creditCardMessageSelector.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_MESSAGE_CONTAINER
            )[0];
            $accountAddress = $finishStepSelector.getElementsByClassName(
                CONSTANTS.ACCOUNT_ADDRESS
            )[0];
            $accountNumber = $finishStepSelector.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER
            )[0];
            $popupDPP = $billSelector.getElementsByClassName(CONSTANTS.POPUP_DPP_ELEMENT)[0];
            $minimumPaymentAlert = $billSelector.getElementsByClassName(
                CONSTANTS.MINIMUM_PAYMENT_ALERT
            )[0];
            $captchaElement = document.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT);
            $newWindowSpan = $form.getElementsByClassName(CONSTANTS.NEW_WINDOW_SPAN)[0];
            _hasCaptcha = $captchaElement.length > 0;
            _accountNumberInputHaveBeenFocus = false;

            if ($popupDPP) {
                $popupDPPContent = $popupDPP.getElementsByClassName(
                    CONSTANTS.POPUP_CONTENT_CLASS
                )[0];
            }

            // MFA Elements
            $mfaForm = $billLookup.getElementsByClassName(CONSTANTS.PMB_MFA_FORM)[0];
            if ($mfaForm) {
                $selectedAddressSubtitle = $billLookup.getElementsByClassName(
                    CONSTANTS.SELECTED_ADDRESS_SUBTITLE
                )[0];
                $getCodeButton = $billLookup.getElementsByClassName(CONSTANTS.MFA_GET_CODE)[0];
                $wrongAddressButton = $billLookup.getElementsByClassName(
                    CONSTANTS.MFA_WRONG_ADDRESS
                )[0];
                $mainForm = $billLookup.getElementsByClassName(CONSTANTS.PMB_MAIN_FORM)[0];
                $manualAccountContainer = $billLookup.getElementsByClassName(
                    CONSTANTS.MANUAL_ACCOUNT
                )[0];
                $mfaChooseAccount = $billLookup.getElementsByClassName(
                    CONSTANTS.MFA_CHOOSE_ACCOUNT
                )[0];
                $mfaChooseAccountContainer = $billLookup.getElementsByClassName(
                    CONSTANTS.MFA_CHOOSE_ACCOUNT_CONTAINER
                )[0];
                $mfaStep1 = $billLookup.getElementsByClassName(CONSTANTS.MFA_STEP_1)[0];
                $mfaStep2 = $billLookup.getElementsByClassName(CONSTANTS.MFA_STEP_2)[0];
                $mfaContactType = $billLookup.getElementsByClassName(CONSTANTS.MFA_CONTACT_TYPE)[0];
                $mfaContactTypeContent = $billLookup.getElementsByClassName(
                    CONSTANTS.MFA_CONTACT_TYPE_CONTENT
                );
                $mfaEmailInput = $billLookup.getElementsByClassName(CONSTANTS.MFA_EMAIL_INPUT)[0];
                $mfaPhoneInput = $billLookup.getElementsByClassName(CONSTANTS.MFA_PHONE_INPUT)[0];
                $mfaPhoneRadios = $billLookup.getElementsByClassName(CONSTANTS.MFA_PHONE_RADIOS);
                $mfaSendCodeButton = $billLookup.getElementsByClassName(CONSTANTS.MFA_SEND_CODE)[0];
                $mfaSendCodeAnimationSelector = $mfaStep1.getElementsByClassName(
                    CONSTANTS.SUBMIT_ANIMATION_SELECTOR
                )[0];
                $mfaTextDescription = $billLookup.getElementsByClassName(
                    CONSTANTS.MFA_TEXT_DESCRIPTION
                )[0];
                $mfaVoiceDescription = $billLookup.getElementsByClassName(
                    CONSTANTS.MFA_VOICE_DESCRIPTION
                )[0];
                $mfaEmailDescription = $billLookup.getElementsByClassName(
                    CONSTANTS.MFA_EMAIL_DESCRIPTION
                )[0];
                $mfaCodeInput = $billLookup.getElementsByClassName(CONSTANTS.MFA_CODE_INPUT)[0];
                $mfaSendAgainButton = $billLookup.getElementsByClassName(
                    CONSTANTS.MFA_SEND_AGAIN
                )[0];
                $mfaSubmitCode = $billLookup.getElementsByClassName(CONSTANTS.MFA_SUBMIT_CODE)[0];
                $mfaSubmitCodeAnimationSelector = $mfaStep2.getElementsByClassName(
                    CONSTANTS.SUBMIT_ANIMATION_SELECTOR
                )[0];
            }
        };

        var initializeEvents = function () {
            $submitButton.disabled = true;

            coned.utils.addGeneralListeners($notYourAccountLink, notYourAccountEvent);

            _.each($radioButtons, function ($radioButton) {
                coned.utils.addGeneralListeners($radioButton, taggingRadioButton);
            });

            coned.utils.addMultipleListeners($mainForm, ['change', 'keyup'], enablePayBillButton);

            $accountNumberInput.addEventListener('focus', taggingAccountNumberInput);

            $bankAccountInputRadio.addEventListener('change', paymentMethodChangeEvent);
            $creditCardInputRadio.addEventListener('change', paymentMethodChangeEvent);

            // MFA Events
            if ($mfaForm) {
                coned.utils.addGeneralListeners($getCodeButton, showMfaFlow);
                $mfaContactType.addEventListener('change', handleContactType);
                $mfaContactType.addEventListener('focus', function () {
                    $mfaContactType.classList.remove(CONSTANTS.ERROR_INPUT);
                });
                coned.utils.addMultipleListeners($mfaStep1, ['change', 'keyup'], function () {
                    $mfaSendCodeButton.disabled = !$($mfaStep1).validate().checkForm();
                });
                coned.utils.addMultipleListeners($mfaStep2, ['change', 'keyup'], function () {
                    $mfaSubmitCode.disabled = $mfaCodeInput.value === '';
                });
                coned.utils.addGeneralListeners($mfaSendAgainButton, sendCodeAgain);
                coned.utils.addGeneralListeners($wrongAddressButton, wrongAddressHandler);
                _.each($mfaPhoneRadios, function ($radio) {
                    $radio.addEventListener('change', function (event) {
                        if (event.target.checked) {
                            dataLayer.push({
                                contactMethod: 'phone-' + event.target.value
                            });
                        }
                    });
                });
            }

            // submit handler
            $mfaForm && new coned.components.ValidateForm('.' + CONSTANTS.MFA_STEP_2, codeSubmit);

            if (_hasCaptcha) {
                new coned.components.ValidateForm('.' + CONSTANTS.FORM_SELECTOR, checkRecaptcha, ':hidden', true);
                recaptcha = new coned.components.Recaptcha($billLookup, billLookup, billLookup);

                $mfaForm &&
                    new coned.components.ValidateForm('.' + CONSTANTS.MFA_STEP_1, checkRecaptcha, ':hidden', true);
            } else {
                new coned.components.ValidateForm('.' + CONSTANTS.FORM_SELECTOR, billLookup, ':hidden', true);
                $mfaForm && new coned.components.ValidateForm('.' + CONSTANTS.MFA_STEP_1, sendCode, ':hidden', true);
            }
        };

        var checkRecaptcha = function (form) {
            var callback = form === $form ? billLookup : sendCode;

            if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                recaptcha.checkRecaptcha(null, false, false, callback);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PayMyBillNoLogin.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PayMyBillNoLogin;
})();
