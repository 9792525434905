// ==================== LOAD CARD TILES COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoadCardTilesIppDashboard = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CARD_CLASS: 'js-card-selector',
        CARD_CLASS_LINK_ELEMENT: '.card-tile__link-wrapper',
        CARD_CLASS_CTA_ELEMENT: '.card-tile__info-container--type-cta',
        CARD_CLASS_WRAPPER: 'card-tile__link-wrapper',
        CARD_CLASS_FOCUS: 'card-tile--focus',
        CARD_CLASS_TITLE_ELEMENT: '.card-tile__title',
        CARD_CLASS_DESCRIPTION_ELEMENT: '.card-tile__description',
        ANIMATION_CLASS: 'card-tile__zoom--animation'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LoadCardTilesIppDashboard = function ($cardTilesContainer) {
        /**
         * PRIVATE METHODS
         */
        var $cards;

        var initAnimation = function () {
            var timerAnimation = 200,
                itemTimer = 0;

            for (var index = 0; index < $cards.length; index++) {
                var $card = $cards[index];

                animationTimeout($card, itemTimer);

                //Updating timer
                itemTimer += timerAnimation;
            }
        };

        var animationTimeout = function ($card, itemTimer) {
            setTimeout(function () {
                $card.classList.remove(CONSTANTS.ANIMATION_CLASS);
            }, itemTimer);
        };

        var initClamp = function () {
            for (var index = 0; index < $cards.length; index++) {
                var $card = $cards[index];
                findAndClampDescription($card);
            }

            window.addEventListener('resize', initClamp);
        };

        var findAndClampDescription = function ($card) {
            var $cardTitle = $card.querySelector(CONSTANTS.CARD_CLASS_TITLE_ELEMENT);
            var $cardDescription = $card.querySelector(CONSTANTS.CARD_CLASS_DESCRIPTION_ELEMENT);
            var $cardLinked = $card.querySelector(CONSTANTS.CARD_CLASS_LINK_ELEMENT);
            var $cardCTA = $card.querySelector(CONSTANTS.CARD_CLASS_CTA_ELEMENT);

            // Test if link or cts is present
            if (($cardDescription && $cardLinked) || ($cardDescription && $cardCTA)) {
                // lineClamp Title
                coned.utils.lineClamp($cardTitle);
                // lineClamp Description
                coned.utils.lineClamp($cardDescription);
            }
        };

        var initializeData = function () {
            $cards = $cardTilesContainer.getElementsByClassName(CONSTANTS.CARD_CLASS);

            //For dashboard load(has 2 sets of tips cards) check if the cards load tag is required or not
            if (
                !$cardTilesContainer.parentElement.dataset.triggerTag ||
                $cardTilesContainer.parentElement.dataset.triggerTag === 'true'
            ) {
                // Analytics data building
                dataLayer.push({
                    event: 'tipsView'
                });
            }
        };

        var initializeEvents = function () {
            initAnimation();
            initClamp();

            // window.addEventListener('resize', initClamp);
            
            // Adding the focus event on all the cards, when the focus is on the wrapper link
            var listWrapperElements = document.getElementsByClassName(CONSTANTS.CARD_CLASS_WRAPPER);

            listWrapperElements = listWrapperElements.length > 0 ? listWrapperElements : document.getElementsByClassName(CONSTANTS.CARD_CLASS_GRID_WRAPPER);

            for (var wrapElement = 0; wrapElement < listWrapperElements.length; wrapElement++) {
                listWrapperElements[wrapElement].addEventListener('focusin', function(event) {
                    event.currentTarget.parentElement.parentElement.classList.add(CONSTANTS.CARD_CLASS_FOCUS);
                });

                listWrapperElements[wrapElement].addEventListener('focusout', function() {
                    var cardWrapperElement = document.getElementsByClassName(CONSTANTS.CARD_CLASS_FOCUS);

                    if (cardWrapperElement.length > 0) {
                        cardWrapperElement[0].classList.remove(CONSTANTS.CARD_CLASS_FOCUS);
                    }
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoadCardTilesIppDashboard.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LoadCardTilesIppDashboard;
})();
