// ==================== MASKED FIELD COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.MaskedField = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        MASKED_FIELD_BTN_CLASS: 'js-masked-field-button',
        ARIA_LABEL: 'aria-label'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var MaskedField = function () {
        /**
         * PRIVATE METHODS
         */

        var $maskedFieldButtons;

        var toggleMaskedField = function (event) {
            event.preventDefault();

            var maskedFieldElementClassName = event.target.dataset.maskedFieldClassName;
            var maskedFieldElement = document.getElementsByClassName(
                maskedFieldElementClassName
            )[0];

            if (maskedFieldElement) {
                var inputType = maskedFieldElement.type;
                var buttonText, buttonAriaText;

                if (inputType == 'password') {
                    inputType = 'text';
                    buttonText = this.dataset.replaceText;
                    buttonAriaText = this.dataset.ariaReplaceText;
                } else {
                    inputType = 'password';
                    buttonText = this.dataset.text;
                    buttonAriaText = this.dataset.ariaText;
                }
                modifyPasswordElemAttributes(
                    event.target,
                    maskedFieldElement,
                    inputType,
                    buttonText,
                    buttonAriaText
                );
            }
        };

        var modifyPasswordElemAttributes = function (
            button,
            inputField,
            inputType,
            buttonText,
            buttonAriaText
        ) {
            var buttonSpan = button.firstElementChild;
            inputField.type = inputType;
            buttonSpan.innerHTML = buttonText;
            button.setAttribute(CONSTANTS.ARIA_LABEL, buttonAriaText);
        };

        var initializeData = function () {
            $maskedFieldButtons = document.getElementsByClassName(CONSTANTS.MASKED_FIELD_BTN_CLASS);
        };

        var initializeEvents = function () {
            _.each($maskedFieldButtons, function ($maskedFieldButton) {
                coned.utils.addGeneralListeners($maskedFieldButton, toggleMaskedField);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };
    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    MaskedField.prototype.isLoaded = function () {
        return isLoaded;
    };

    return MaskedField;
})();
