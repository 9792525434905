// ==================== PROGRESS IN PLAN BAR COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ProgressInPlanBar = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        PROGRESS_BAR_SELECTOR: 'js-progress-plan-bar',
        PROGRESS_ELEMENT_CLASS: 'overview-bill-analysis__progress-plan-element',
        PROGRESS_ELEMENT_BAR_CLASS: 'overview-bill-analysis__progress-plan-bar',
        PROGRESS_ELEMENT_BAR_PROGRESSED_MODIFIER:
            'overview-bill-analysis__progress-plan-bar--progressed',
        PROGRESS_ELEMENT_BAR_SELECTED_MODIFIER:
            'overview-bill-analysis__progress-plan-bar--selected',
        PROGRESS_ELEMENT_PARAGRAPH_CLASS: 'overview-bill-analysis__progress-plan-paragraph',
        PROGRESS_ELEMENT_SPAN_CURRENT_CLASS:
            'analysis-column__service-icon analysis-column__service-icon--check icon-check-status',
        LIST_ELEMENT: 'li',
        PARAGRAPH_ELEMENT: 'p',
        SPAN_ELEMENT: 'span',
        DIV_ELEMENT: 'div'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var ProgressInPlanBar = function ($progressBar) {
        /**
         * PRIVATE METHODS
         */
        var currentMonth,
            monthsCount;

        var loadProgressBar = function () {
            var listElement, barElement, paragraphElement, currentMonthSpan;

            // verify if the string is a number
            if (!isNaN(currentMonth)) {
                currentMonth = parseInt(currentMonth);
                for (var index = 1; index <= monthsCount; index++) {
                    // create list element and add class
                    listElement = document.createElement(CONSTANTS.LIST_ELEMENT);
                    listElement.classList.add(CONSTANTS.PROGRESS_ELEMENT_CLASS);
                    // create div element and add class
                    barElement = document.createElement(CONSTANTS.DIV_ELEMENT);
                    barElement.classList.add(CONSTANTS.PROGRESS_ELEMENT_BAR_CLASS);
                    // create paragraph element, add class and add number
                    paragraphElement = document.createElement(CONSTANTS.PARAGRAPH_ELEMENT);
                    paragraphElement.classList.add(CONSTANTS.PROGRESS_ELEMENT_PARAGRAPH_CLASS);
                    paragraphElement.innerHTML = index;

                    // if the actual element is less than the received month add modifier yo make green the bar
                    if (index < currentMonth) {
                        barElement.classList.add(
                            CONSTANTS.PROGRESS_ELEMENT_BAR_PROGRESSED_MODIFIER
                        );

                        // if the actual element is the same as the received month, add span element and add modifier for actual bar
                    } else if (currentMonth == index) {
                        currentMonthSpan = document.createElement(CONSTANTS.SPAN_ELEMENT);
                        currentMonthSpan.className = CONSTANTS.PROGRESS_ELEMENT_SPAN_CURRENT_CLASS;
                        barElement.classList.add(CONSTANTS.PROGRESS_ELEMENT_BAR_SELECTED_MODIFIER);
                        barElement.appendChild(currentMonthSpan);
                    }

                    // add to list element bar and text
                    listElement.appendChild(barElement);
                    listElement.appendChild(paragraphElement);

                    // add to progress bar list element
                    $progressBar.appendChild(listElement);
                }
            }
        };
        var initializeData = function () {
            currentMonth = $progressBar.dataset.currentMonth;
            monthsCount = $progressBar.dataset.monthsCount;
        };

        var initializeEvents = function () {
            loadProgressBar();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ProgressInPlanBar.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ProgressInPlanBar;
})();
