// ==================== PAY MY BILL SINGLE COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.singlePayMyBill = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        PAGE_HEADER: 'js-page-header',
        FORM_SELECTOR: 'js-single-pmb-form',
        PAYMENT_METHOD_SELECTOR: 'js-payment-method',
        AMOUNT_INPUT_SELECTOR: 'js-currency-input',
        FINISH_STEP_SELECTOR: 'js-step-finish',
        FINISH_STEP_FOCUS_SELECTOR: 'js-final-step-focus',
        PMB_ELEMENTS_SELECTOR: 'js-single-pmb',
        PAYMENT_REFERENCE_SELECTOR: 'js-payment-reference',
        ACCOUNT_EMAIL_CONTAINER_SELECTOR: 'js-account-email-container',
        OWE_SELECTOR: 'js-owe-selector',
        AMOUNT_TITLE_SELECTOR: 'js-amount-title',
        CREDIT_CARD_SELECTOR: 'js-credit-card-selector',
        CREDIT_CARD_PAYMENT_SELECTOR: 'js-credit-card-payment',
        ENERGY_SHARE_SELECTOR: 'js-energy-share-selector',
        THANKS_MESSAGE_SELECTOR: 'js-pay-my-bill-thanks',
        CREDIT_CARD_MESSAGE_SELECTOR: 'js-credit-card-thanks',
        PAYMENT_ERROR_MESSAGE_SELECTOR: 'js-pay-my-bill-error',
        PARAGRAPHS_ERROR_SELECTOR: 'js-error-paragraph',
        INPUT_DATE_SELECTOR: 'js-current-datepicker-input',
        MINUMUM_PAYMENT_ALERT: 'js-minimum-payment-alert',
        AMOUNT_SELECTOR: 'js-amount-selector',
        TOTAL_AMOUNT_SELECTOR: 'js-total-selector',
        ENERGY_AMOUNT_SELECTOR: 'js-energy-share',
        PAYMENT_AMOUNT_SELECTOR: 'js-payment-amount',
        SERVICE_FEE_SELECTOR: 'js-service-fee',
        SUBMIT_ANIMATION: 'js-submit-progress-animation',
        SELECT_DROPDOWN_LABEL: 'js-coned-select-label',
        ROW_ENERGY_SHARE: 'js-row-energy-share',
        ROW_SERVICE_FEE: 'js-row-service-fee',
        CREDIT_CARD_MESSAGE: 'js-credit-card-message',
        CREDIT_CARD_MESSAGE_CONTAINER: 'js-credit-card-message-container',
        ENERGY_SHARE_MODULE: 'js-energy-share-module',
        BILL_PAYMENT_ERROR: 'js-bill-payment-error',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        FORM_SCID_NAME: 'ScId',
        FORM_PAYMENT_METHOD_NAME: 'paymentMethod',
        FORM_ROUTING_ABA_NUMBER_NAME: 'routingAbaNumber',
        FORM_BANK_ACCOUNT_NUMBER_NAME: 'bankAccountNumber',
        FORM_DPP_ENROLL_NAME: 'dppEnroll',
        FORM_PAYMENT_DATE_NAME: 'paymentDate',
        FORM_PAYMENT_TYPE_NAME: 'paymentType',
        FORM_PAYMENT_AMOUNT_NAME: 'paymentAmount',
        FORM_ENERGY_SHARE_NAME: 'energyShare',
        DROPDOWN_ACTIVE: 'coned-select--active',
        ACTIVE_LABEL: 'coned-select__label--active',
        FILLED_INPUT: 'coned-input--filled',
        CHECKED_CLASS: 'coned-checkbox--checked',
        HIDDEN_CLASS: 'hidden',
        AUTH_USER: 'js-auth-user',
        CONFIRM_PAY_BILL_ERROR: 'js-pay-bill-confirm-error',
        BILL_LINK_CLASS: 'js-bill-link',
        FORM_LOADING: 'js-form-loading',
        BILL_HEADER_CLASS: 'js-bill-header',
        HEADER_TOTAL_AMOUNT_CLASS: 'js-total-amount',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        PAYMENT_AMOUNT_PARAMETER: 'PaymentAmount=',
        ACCOUNT_NUMBER_PARAMETER: 'AccountNumber=',
        ACCOUNT_ADDRESS: 'js-account-address',
        ACCOUNT_NUMBER: 'js-account-number',
        PAYMENT_DATE: 'js-payment-date',
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        ANIMATION_STEP_CLASS: 'js-step-animation',
        FORM_STEP_CLASS: 'js-step-form',
        HEADER_SELECTOR: 'js-header-wrapper',
        BILLING_TABLE_SELECTOR: 'js-billing-table',
        PAYMENT_AMOUNT_CHECKBOX: 'js-payment-amount-option',
        TOOLTIP_DATE_SELECTOR: 'js-tooltip-date',
        POPUP_CONFIRMATION_MESSAGE: 'js-popup-confirmation',
        POPUP_CONFIRMATION_BUTTON: 'js-popup-button-confirmation',
        BILL_AMOUNT_PAY: 'bill.amount.pay',
        OTHER_AMOUNT: 'ANOTHER_AMOUNT',
        BNA_AMOUNT: 'BNA_AMOUNT',
        IS_SUMMARY_BILLING_SELECTOR: 'js-is-summary-billing-program',
        IS_COMMERCIAL_SELECTOR: 'js-is-commercial',
        OTHER_VALUE: 'other',
        HALF_CONTAINER_MODIFIER: 'transactional__half-block',
        FULL_CONTAINER_MODIFIER: 'transactional__full',
        DATA_INPUT_SHOULD_START_VALIDATE: 'data-input-should-start-validate',
        PARTIAL_PAYMENT_WARNING_MSG: '.js-partial-payment-warning',

        NOTIFICATION_BANNER: 'js-pay-bill-notification-card',
        GUEST_PAY_LINK: "js-pay-guest-link",
        GUEST_PAY_DATA_MODULE: '[data-module="PayMyBillNoLogin"]',
        DEFAULT_VALIDATOR_HIDDEN_SELECTOR: ':hidden',

        PAYMENT_STATUS_MESSAGE: 'js-payment-status-message',
        LEGACY_METER_MESSAGE: 'js-legacy-meter-message',
        TABINDEX: 'tabindex',
        OVERPAYMENT_PARAGRAPH: 'js-overpayment-paragraph',
        FONT_SIZE: 'font-size',

        // Tagging
        TAG_CONFIRM_PAY_BILL_ERROR: 'gp.confirm.error',
        TAG_GUEST_PAY_CLICK: "scenario.guestpay.click",
        NO_ERROR_CODES_PROVIDED_MESSAGE: 'No error codes provided',
        TAG_GUEST_PAY_TILE_SCENARIO: 'Pay as Guest',
        TAG_PAY_BILL_CREDIT_CARD: 'pay.bill.creditcard',
        TAG_ACCOUNT_TYPE_COMMERCIAL: 'commercial',
        TAG_ACCOUNT_TYPE_RESIDENTIAL: 'residential'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var singlePayMyBill = function ($singleForm) {
        /**
         * PRIVATE METHODS
         */
        var $pageHeader,
            $billHeader,
            $headerTotalAmount,
            $paymentMethodSelector,
            $submitAnimationSelector,
            $finishStepSelector,
            $finishStepFocusSelector,
            $paymentReferenceSelector,
            $accountEmailContainerSelector,
            $amountTitleSelector,
            $oweSelector,
            $creditCardSelector,
            $creditCardPaymentSelector,
            $minimumPaymentAlert,
            $energyShareSelector,
            $totalAmountSelector,
            $energyAmountSelector,
            $paymentAmountSelector,
            $serviceFeeSelector,
            $inputDateSelector,
            $amountSelector,
            $thanksMessageSelector,
            $creditCardMessageSelector,
            $paymentErrorMessageSelector,
            $paragraphsErrorSelector,
            $amountInput,
            $amountValue,
            $rowEnergyShare,
            $rowServiceFee,
            $creditCardMessage,
            $creditCardMessageContainer,
            $energyShareModule,
            $billPaymentError,
            $billLinks,
            $currentBillLink,
            $serviceError,
            $formLoading,
            $accountAddress,
            $accountNumber,
            $paymentDate,
            $submitButton,
            $animationStep,
            $formStep,
            $header,
            $form,
            $billingTable,
            $newTab,
            $confirmPayBillError,
            $partialPaymentWarning,
            $paymentAmountCheckboxSelector,
            $popupConfirmation,
            $popupConfirmationButton,
            $notificationBanner,
            $guestPayLink,
            $paymentStatusMessage,
            $legacyMeterMessage,
            $overpaymentParagraph,
            index,
            _data,
            _isAuthUser,
            _isLessThanMinimum,
            _minimumAmount,
            _eventPaymentName,
            _isCommercial,
            _isSummaryBillingProgram,
            _isLegacyMeter, 
            _hasSubmitted;

        var paymentMethodChange = function (event) {
            var paymentValue = event.target.value,
                messageIndex;

            if (paymentValue == $singleForm.dataset.creditOption) {
                query.addClass($creditCardSelector, CONSTANTS.HIDDEN_CLASS);
                query.addClass($creditCardPaymentSelector, CONSTANTS.HIDDEN_CLASS);
                $rowServiceFee.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $energyShareModule.classList.add(CONSTANTS.HIDDEN_CLASS);

                for (messageIndex = 0; messageIndex < $creditCardMessage.length; messageIndex++) {
                    $creditCardMessage[messageIndex].classList.remove(CONSTANTS.HIDDEN_CLASS);
                }

                unCheckEnergyDonation();
                updateBillingTable();
            } else {
                query.removeClass($creditCardSelector, CONSTANTS.HIDDEN_CLASS);
                query.removeClass($creditCardPaymentSelector, CONSTANTS.HIDDEN_CLASS);
                $rowServiceFee.classList.add(CONSTANTS.HIDDEN_CLASS);
                $serviceFeeSelector.dataset.amount = 0;

                for (messageIndex = 0; messageIndex < $creditCardMessage.length; messageIndex++) {
                    $creditCardMessage[messageIndex].classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                // If last account was selected, only routing and account number should be hidden
                if (paymentValue == $singleForm.dataset.lastAccountOption) {
                    query.addClass($creditCardSelector, CONSTANTS.HIDDEN_CLASS);
                }

                // If user is authenticated, donation can be done
                if (_isAuthUser) {
                    $energyShareModule.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    checkEnergyDonation(updateBillingTable);
                } else {
                    updateBillingTable();
                }
            }
        };

        var paymentTypeChange = function () {
            // get current checkbox
            var checkedValue = null,
                paymentOptionValue = '';

          
            for (var i = 0; $paymentAmountCheckboxSelector[i]; ++i) {
                if ($paymentAmountCheckboxSelector[i].checked) {
                    checkedValue = $paymentAmountCheckboxSelector[i];
                    _eventPaymentName =
                        $paymentAmountCheckboxSelector[i].dataset.paymentOptionName;
                    break;
                }
            }
            paymentOptionValue = checkedValue.dataset.paymentOptionType;
            
            // OTHER_AMOUNT is the number defined for the checkboxes
            // OTHER_VALUE is the constant held for the dropdown
            if (
                paymentOptionValue == CONSTANTS.OTHER_AMOUNT ||
                paymentOptionValue == CONSTANTS.OTHER_VALUE
            ) {
                $amountInput.value = '';
                $paymentAmountSelector.innerHTML = '$0.00';
                $paymentAmountSelector.dataset.amount = 0;
                query.removeClass($amountInput, CONSTANTS.FILLED_INPUT);
                $amountInput.disabled = false;
                if ($amountInput.hasAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE)) {
                    $amountInput.removeAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE);
                }
                
                $amountInput.parentNode.parentNode.classList.remove(CONSTANTS.HIDDEN_CLASS);                
                
                $partialPaymentWarning && $partialPaymentWarning.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                
                $amountInput.value = currencyFormat(
                    parseFloat(checkedValue.dataset.paymentAmount.replace(/,/g, '')).toFixed(2)
                );
                $paymentAmountSelector.dataset.amount = checkedValue.dataset.paymentAmount;
                $paymentAmountSelector.innerHTML = currencyFormat(
                    parseFloat(checkedValue.dataset.paymentAmount.replace(/,/g, '')).toFixed(2)
                );
                
                query.addClass($amountInput, CONSTANTS.FILLED_INPUT);
                $amountInput.disabled = true;

                // Clean error message from input
                query.removeClass($amountInput, coned.constants.INPUT_ERROR_CLASS);
                var $amountInputValidationMsg = $amountInput.parentNode.nextSibling;
                if ($amountInputValidationMsg) {
                    $amountInputValidationMsg.remove();
                }
                
                $amountInput.parentNode.parentNode.classList.add(CONSTANTS.HIDDEN_CLASS);
                
                $partialPaymentWarning && $partialPaymentWarning.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            checkEnergyDonation(updateBillingTable);
        };

        var amountInputCheck = function (event) {
            var $input = event.target,
            inputValue = $input.value,
            inputMatch;

            // Parsing data
            inputValue = inputValue.replace(/[^\d.]/g, '');
            // Trim to 2 decimals for special case when user types a 3rd decimal, 
            // it is passed here before input validation deletes it from the UI,
            inputMatch = inputValue.match(/^-?\d+(?:\.\d{0,2})?/);
            inputValue = parseFloat(inputMatch ? inputMatch[0] : 0);
            
            $paymentAmountSelector.dataset.amount = inputValue;

            if ($input.value === '') {
                $paymentAmountSelector.innerHTML = '$0.00';
                $paymentAmountSelector.dataset.amount = 0;
            } else {
                $paymentAmountSelector.innerHTML = currencyFormat(
                    parseFloat($paymentAmountSelector.dataset.amount).toFixed(2)
                );
            }

            checkEnergyDonation(updateBillingTable);
        };

        var checkMinimumAmount = function () {
            if (
                !_isSummaryBillingProgram && (
                !coned.utils.isOru() ||
                !$totalAmountSelector.dataset.minAmount ||
                !$totalAmountSelector.dataset.amount)
            ) {
                if (
                    parseFloat($totalAmountSelector.dataset.amount) <
                    parseFloat($totalAmountSelector.dataset.minAmount)
                ) {
                    $minimumPaymentAlert.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $minimumPaymentAlert.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }

            enablePayBillButton();
        };

        var checkEnergyDonation = function (callback) {
            var paymentAmount = parseFloat($paymentAmountSelector.dataset.amount.replace(/,/g, '')),
                amountValue = parseFloat($amountValue.dataset.amount.replace(/,/g, ''));

            if (_isAuthUser) {
                if (paymentAmount == amountValue) {
                    $energyShareModule.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    unCheckEnergyDonation();
                }
            }

            callback();
        };

        var unCheckEnergyDonation = function () {
            $energyShareSelector.checked = false;
            $energyShareSelector.parentElement.classList.remove(CONSTANTS.CHECKED_CLASS);
            $energyShareModule.classList.add(CONSTANTS.HIDDEN_CLASS);
            $energyAmountSelector.dataset.amount = 0;
            $rowEnergyShare.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var donateChange = function () {
            if ($energyShareSelector.checked) {
                $rowEnergyShare.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $energyAmountSelector.dataset.amount = 1;
            } else {
                $rowEnergyShare.classList.add(CONSTANTS.HIDDEN_CLASS);
                $energyAmountSelector.dataset.amount = 0;
            }

            updateBillingTable();
        };

        var updateBillingTable = function () {
            var $amountRow,
                totalAmount = 0,
                actualAmount,
                dataAmount;

            for (index = 0; index < $amountSelector.length; index++) {
                $amountRow = $amountSelector[index];

                if (
                    $paymentMethodSelector.value == $singleForm.dataset.creditOption &&
                    $amountRow.dataset.type !== undefined
                ) {
                    if ($amountRow.dataset.type == 'commercial') {
                        actualAmount =
                            (parseFloat($amountRow.dataset.commercialValue.replace(/,/g, '')) /
                                100) *
                            parseFloat($paymentAmountSelector.dataset.amount.replace(/,/g, ''));
                    } else {
                        actualAmount = parseFloat(
                            $amountRow.dataset.residentialValue.replace(/,/g, '')
                        );
                    }

                    $amountRow.innerHTML = currencyFormat(actualAmount.toFixed(2) + '');
                } else {
                    actualAmount = parseFloat($amountRow.dataset.amount.replace(/,/g, ''));
                }

                totalAmount += actualAmount;
            }

            dataAmount = totalAmount;
            totalAmount = currencyFormat(totalAmount.toFixed(2) + '');

            $totalAmountSelector.innerHTML = totalAmount;
            $totalAmountSelector.dataset.amount = dataAmount;

            checkMinimumAmount();
        };

        var currencyFormatNumber = function (number) {
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        };

        var currencyFormat = function (num) {
            var $currencyInput = num.replace(/[^\d.-]/g, '');
            var decimals = '';

            $currencyInput = $currencyInput.split('.', 2);

            if (typeof $currencyInput[1] != 'undefined') {
                decimals = '.' + $currencyInput[1];
            }

            return '$' + currencyFormatNumber($currencyInput[0]) + decimals;
        };

        var setDefaults = function () {
            var newDate = new Date(),
                day = newDate.getDate(),
                month = newDate.getMonth(),
                year = newDate.getFullYear(),
                stringDate = ++month + '/' + day + '/' + year;

            $inputDateSelector.value = stringDate;
            $inputDateSelector.classList.add(CONSTANTS.FILLED_INPUT);
            $paymentAmountCheckboxSelector[0].checked = true;
            
            paymentTypeChange();
        };

        var changeToOtherAmount = function (paymentAmount) {
            // Payment type change
        
            for (var i = 0; $paymentAmountCheckboxSelector[i]; ++i) {
                if (
                    $paymentAmountCheckboxSelector[i].dataset.paymentOptionType ==
                    CONSTANTS.OTHER_AMOUNT
                ) {
                    $paymentAmountCheckboxSelector[i].checked = true;
                    break;
                }
            }
            
            paymentTypeChange();

            // Change other amount
            $amountInput.value = currencyFormat(parseFloat(paymentAmount).toFixed(2));
            $paymentAmountSelector.innerHTML = currencyFormat(parseFloat(paymentAmount).toFixed(2));
            $paymentAmountSelector.dataset.amount = paymentAmount;
            query.addClass($amountInput, CONSTANTS.FILLED_INPUT);

            checkEnergyDonation(updateBillingTable);
        };

        /**
         * Checks if a duplicate submit is being triggered.
         * @return {Boolean} Whether or not the form has been submitted.
         */
        var isDuplicateSubmit = function () {
            var _isDuplicateSubmit;

            if (_hasSubmitted) {
                _isDuplicateSubmit = true;
            } else {
                _isDuplicateSubmit = false;
                _hasSubmitted = true;
            }

            return _isDuplicateSubmit;
        };

        // for other scenarios, use the regular submit function
        // If scenario 4 and amount is less than the minimum, show info popup
        var prePayMyBillSingleSubmit = function () {
            var _isOtherAmount = false;
            // if is a commercial account, _isLessThanMinimum will always be false.
            if (!_isLessThanMinimum) {
                payMyBillSingleSubmit();
            } else {
                // get current checkbox and verify if is other amount option
                for (var i = 0; $paymentAmountCheckboxSelector[i]; ++i) {
                    if ($paymentAmountCheckboxSelector[i].checked) {
                        if (
                            $paymentAmountCheckboxSelector[i].dataset.paymentOptionType ==
                            CONSTANTS.OTHER_AMOUNT
                        ) {
                            _isOtherAmount = true;
                        }
                    }
                }
                // if is other amount option verify the current amount with the minimum
                if (_isOtherAmount) {
                    // Format amount
                    var amountValue = $amountInput.value.replace('$', '').replace(/,/g, '');
                    amountValue = parseFloat(amountValue);
                    var paymentAmount = _minimumAmount.replace('$', '').replace(/,/g, '');
                    paymentAmount = parseFloat(paymentAmount);
                    // if the current amount is less than the minimum display popup
                    // if not, call submit function
                    if (amountValue < paymentAmount) {
                        $popupConfirmation.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    } else {
                        payMyBillSingleSubmit();
                    }
                } else {
                    payMyBillSingleSubmit();
                }
            }
        };

        var payMyBillSingleSubmit = function () {
            var paymentType = query.getFormInputValue(
                    $singleForm,
                    CONSTANTS.FORM_PAYMENT_METHOD_NAME
                ),
                isPostDated =
                    new Date($inputDateSelector.value).getTime() > new Date().setHours(0, 0, 0, 0)
                        ? true
                        : false,
                tagObj = {
                    event: 'pay.bill.submit',
                    paymentMethod: paymentType,
                    PostPaymentSelected: isPostDated.toString()
                };

            if (isPostDated) {
                tagObj.PostPaymentDate = $inputDateSelector.value;
            }

            // Analytics data building
            dataLayer.push(tagObj);

            // add tagging for residential accounts only
            if (!_isCommercial) {
                dataLayer.push({
                    event: CONSTANTS.BILL_AMOUNT_PAY,
                    PaymentAmountOption: _eventPaymentName
                });
            }

            // Hide error on the start of the service call
            $billPaymentError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Credit card payment process
            if (paymentType == $singleForm.dataset.creditOption) {
                creditCardPayment();
                payBillResult();
            } else {
                // Display animation step
                $animationStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $formStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                window.scrollTo(0, 0);

                new coned.components.SubmitAnimation(
                    $submitAnimationSelector,
                    payBillServiceCall,
                    successPayBillServiceCallback,
                    errorPayBillServiceCallback
                );
            }

        };

        var payBillServiceCall = function () {
            var serviceUrl = $singleForm.dataset.serviceUrl,
                paymentType = query.getFormInputValue(
                    $singleForm,
                    CONSTANTS.FORM_PAYMENT_METHOD_NAME
                ),
                accounts = setAccounts(),
                lastAccountFlag = false,
                params;

            // Set flag correct if last account was selected
            if (paymentType == $singleForm.dataset.lastAccountOption) {
                lastAccountFlag = true;
            }

            // Service Data
            params = {
                ScId: query.getFormInputValue($singleForm, CONSTANTS.FORM_SCID_NAME),
                PaymentDate: query.getFormInputValue($singleForm, CONSTANTS.FORM_PAYMENT_DATE_NAME),
                PaymentType: query.getFormInputValue(
                    $singleForm,
                    CONSTANTS.FORM_PAYMENT_METHOD_NAME
                ),
                BankAccountNumber: query.getFormInputValue(
                    $singleForm,
                    CONSTANTS.FORM_BANK_ACCOUNT_NUMBER_NAME
                ),
                BankRoutingNumber: query.getFormInputValue(
                    $singleForm,
                    CONSTANTS.FORM_ROUTING_ABA_NUMBER_NAME
                ),
                AutopayFlag: query.getFormInputValue($singleForm, CONSTANTS.FORM_DPP_ENROLL_NAME),
                EnergyShareFlag: query.getFormInputValue(
                    $singleForm,
                    CONSTANTS.FORM_ENERGY_SHARE_NAME
                ),
                IsMultipay: false,
                UseAccountFromLastPayment: lastAccountFlag,
                Accounts: accounts
            };

            // Service Call
            params = JSON.stringify(params);
            // Only allow one post at a time.
            // Fix for ADO Bug 70805 where duplicate payments are apparently being triggered from the UI.
            if (!isDuplicateSubmit()) {
                query.postData(serviceUrl, successCallback, errorCallback, params, true);
            }
        };

        var setAccounts = function () {
            var account = [
                {
                    Maid: $singleForm.dataset.maid,
                    PaymentLowerLimit: currencyStringToFloat($amountInput.dataset.paymentMin),
                    PaymentUpperLimit: currencyStringToFloat($amountInput.dataset.paymentMax),
                    AccountNumber: $singleForm.dataset.accountNumber,
                    Address: $singleForm.dataset.address,
                    PaymentAmount: currencyStringToFloat($paymentAmountSelector.dataset.amount),
                    TotalAmount: currencyStringToFloat($totalAmountSelector.innerHTML)
                }
            ];

            return account;
        };

        var currencyStringToFloat = function (currencyString) {
            currencyString = currencyString.replace(/[^\d.-]/g, '');

            return parseFloat(currencyString);
        };

        var creditCardPayment = function () {
            var creditCardLink = $singleForm.dataset.creditPayment,
                creditCardMessage = $creditCardMessageContainer.innerHTML,
                serviceAddress = $accountAddress.innerHTML,
                accountNumber = $accountNumber.innerHTML;

            // Update link in credit card thanks message
            creditCardMessage = creditCardMessage.replace('{{LINKCARD}}', creditCardLink);
            $creditCardMessageContainer.innerHTML = creditCardMessage;
            // Update account info
            serviceAddress = serviceAddress.replace('{{ADDRESS}}', '<strong>' + $singleForm.dataset.address + '</strong>');
            $accountAddress.innerHTML = serviceAddress;
            accountNumber = accountNumber.replace('{{ACCOUNT}}', '<strong>' + $singleForm.dataset.accountNumber + '</strong>');
            $accountNumber.innerHTML = accountNumber;

            dataLayer.push({
                event: CONSTANTS.TAG_PAY_BILL_CREDIT_CARD,
                creditAccountType: 
                    _isCommercial ? 
                        CONSTANTS.TAG_ACCOUNT_TYPE_COMMERCIAL : 
                        CONSTANTS.TAG_ACCOUNT_TYPE_RESIDENTIAL
            });

            window.open(creditCardLink);
        };

        var successCallback = function (data) {
            $submitAnimationSelector.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var errorCallback = function (data) {
            $submitAnimationSelector.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;

            // Reset flag so UI is able to submit again
            _hasSubmitted = false;
        };

        var successPayBillServiceCallback = function () {
            if (coned.utils.isPatternLab()) {
                var _isGuestPay = document.querySelector(
                    CONSTANTS.GUEST_PAY_DATA_MODULE
                );

                if (_isGuestPay) {
                    query.getData(
                        coned.plConstants.POST_PAYMENT_SINGLE_GUEST_PAY,
                        payBillResult,
                        errorPayBillServiceCallback
                    );
                } else {
                    query.getData(
                        coned.plConstants.POST_PAYMENT_SINGLE,
                        payBillResult,
                        errorPayBillServiceCallback
                    );
                }
            } else {
                payBillResult(_data);
            }
        };

        var payBillResult = function (data) {
            var serviceAddress = $accountAddress.innerHTML,
                accountNumber = $accountNumber.innerHTML,
                paymentDate = $paymentDate.innerHTML,
                paymentDateFormatted,
                accountNumberDigits;

            // Hide header
            if ($pageHeader) {
                $pageHeader.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            // If credit card payment, just show the success page
            if ($paymentMethodSelector.value == $singleForm.dataset.creditOption) {
                $creditCardMessageSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $finishStepSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $formStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                window.scrollTo(0, 0);

                $creditCardMessageSelector.focus();
                
                return;
            } else {
                $animationStep.classList.add(CONSTANTS.HIDDEN_CLASS);

                if (data.RefNumbers[0].SuccessCode) {
                    /**This payment status can return different status and display the corresponding message
                     * PaymentStatus = 3 TONP
                     * PaymentStatus = 9 DPP
                     */
                    var paymentStatus = data.RefNumbers[0].SuccessCode.split('_')[1];
                    for (var message = 0; message < $paymentStatusMessage.length; message++) {
                        if($paymentStatusMessage[message].dataset.paymentStatus === paymentStatus) {
                            $paymentStatusMessage[message].classList.remove(CONSTANTS.HIDDEN_CLASS);
                        }
                    }
                }
                if (_isLegacyMeter && _isLegacyMeter === 'true') { 
                    for (var legacyMessage = 0; legacyMessage < $legacyMeterMessage.length; legacyMessage++) {
                        $legacyMeterMessage[legacyMessage].classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }
                }
            }

            // Analytics data building
            dataLayer.push(data.eCommerceJson);

            if (data.RefNumbers[0].Success) {
                var amountValue = $amountInput.value,
                    paymentAmount = $amountValue.dataset.amount,
                    titleAmount = $amountTitleSelector.innerHTML,
                    paymentReference = $paymentReferenceSelector.innerHTML,
                    accountEmail = $accountEmailContainerSelector.innerHTML;

                // Format amount
                amountValue = amountValue.replace('$', '').replace(/,/g, '');
                amountValue = parseFloat(amountValue);
                paymentAmount = paymentAmount.replace('$', '').replace(/,/g, '');
                paymentAmount = parseFloat(paymentAmount);

                // Show owe text
                if (amountValue < paymentAmount) {
                    var owe = (paymentAmount - amountValue).toFixed(2),
                        paymentAmountParam = '?' + CONSTANTS.PAYMENT_AMOUNT_PARAMETER + owe,
                        accountNumberParam =
                            '&' + CONSTANTS.ACCOUNT_NUMBER_PARAMETER + data.RefNumbers[0].Maid,
                        oweLink = paymentAmountParam + accountNumberParam,
                        messageContain = $oweSelector.innerHTML;

                    // Add owe amount
                    messageContain = messageContain.replace('{{VALUEOWE}}', '$' + owe);

                    // Add pay the rest link
                    messageContain = messageContain.replace('{{LINKOWE}}', oweLink);

                    $oweSelector.innerHTML = messageContain;
                    $oweSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }

                // Set dynamic values
                $totalAmountSelector.innerHTML = currencyFormat(
                    parseFloat(
                        $totalAmountSelector.innerHTML.replace('$', '').replace(/,/g, '')
                    ).toFixed(2)
                );
                titleAmount = titleAmount.replace('{{AMOUNT}}', $totalAmountSelector.innerHTML);
                $amountTitleSelector.innerHTML = titleAmount;
                paymentReference = paymentReference.replace(
                    '{{REFERENCE}}',
                    data.RefNumbers[0].RefNumber
                );
                $paymentReferenceSelector.innerHTML = paymentReference;
                accountEmail = accountEmail.replace(
                    '{{EMAIL}}',
                    $singleForm.dataset.confirmationEmail
                );
                $accountEmailContainerSelector.innerHTML = accountEmail;

                // Show payment success message
                $finishStepSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $thanksMessageSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $thanksMessageSelector.focus();

                // Analytics data building
                dataLayer.push({
                    event: 'coned.transaction.success'
                });
            } else {
                // Set error message
                $paragraphsErrorSelector.innerHTML = data.RefNumbers[0].errorMsg;

                if ($notificationBanner) {
                    $notificationBanner.classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                // Analytics data building
                var dataLayerObj = {
                    event: 'pay.bill.error'
                };

                if (
                    data.RefNumbers[0].PaymentErrors &&
                    data.RefNumbers[0].PaymentErrors.errorDetails
                ) {
                    var errorCodes = [];

                    _.each(data.RefNumbers[0].PaymentErrors.errorDetails, function (error) {
                        errorCodes.push(error.errorCode);
                    });

                    dataLayerObj.payBillErrors = errorCodes.join('|');
                } else {
                    dataLayerObj.payBillErrors = CONSTANTS.NO_ERROR_CODES_PROVIDED_MESSAGE;
                }

                if (data.RefNumbers[0].Maid !== null) {
                    dataLayerObj.maid = data.RefNumbers[0].Maid;
                }

                // Show payment error message
                $finishStepSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $paymentErrorMessageSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $paymentErrorMessageSelector.focus();

                dataLayer.push(dataLayerObj);
            }

            serviceAddress = serviceAddress.replace('{{ADDRESS}}', '<strong>' + data.RefNumbers[0].ServiceAddress + '</strong>');
            $accountAddress.innerHTML = serviceAddress;
            accountNumberDigits = $singleForm.dataset.accountLastDigits ? $singleForm.dataset.accountLastDigits : data.RefNumbers[0].AccountNumber;
            accountNumber = accountNumber.replace('{{ACCOUNT}}', '<strong>' + accountNumberDigits + '</strong>');
            $accountNumber.innerHTML = accountNumber;
            paymentDate = paymentDate.replace('{{PAYMENTDATE}}', data.RefNumbers[0].PaymentDate);
            paymentDateFormatted = coned.utils.dateFormatLongMonth(paymentDate);
            $paymentDate.innerHTML = paymentDateFormatted;

            window.scrollTo(0, 0);

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($singleForm);
        };

        var errorPayBillServiceCallback = function () {
            var $msgTextSelector = $billPaymentError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];
            if ($notificationBanner) {
                $notificationBanner.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
            
            $billPaymentError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = _data.errorMsg
                ? _data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $billPaymentError.focus();
            $animationStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            $formStep.classList.remove(CONSTANTS.HIDDEN_CLASS);

            query.scrollToElement($billingTable, $header);

            // Analytics data building
            dataLayer.push({
                event: 'pay.bill.error'
            });
        };

        var billLinkEvent = function (event) {
            event.preventDefault();

            var $billItem = query.selectParentElement(
                    event.target,
                    CONSTANTS.PMB_ELEMENTS_SELECTOR
                ),
                serviceUrl = $billItem.dataset.linkServiceUrl,
                params;

            // Set elements depending on the item clicked
            $currentBillLink = query.selectParentElement(event.target, CONSTANTS.BILL_LINK_CLASS);
            $serviceError = $billItem.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // If bill link is available, open the link in a new tab
            if ($currentBillLink.dataset.billLink) {
                window.open($currentBillLink.dataset.billLink, '_blank');

                return;
            }

            // Service data
            params = {
                ScId: $currentBillLink.dataset.scid,
                Maid: $currentBillLink.dataset.maid,
                BillDate: $currentBillLink.dataset.billDate,
                Type: $currentBillLink.dataset.type
            };

            // Before doing the service call, lets create the new tab where the link will be opened, so it won't be blocked by browser security
            $newTab = window.open();

            // Service call
            query.getData(serviceUrl, successBillService, errorBillService, params, $formLoading);
        };

        var successBillService = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.GET_BILL_LINK, redirectPage, errorBillService);
            } else if (data.Link && data.Link != '') {
                redirectPage(data);
            } else {
                errorBillService(data);
            }
        };

        var redirectPage = function (data) {
            // Set link for future click
            $currentBillLink.dataset.billLink = data.Link;

            // Set file location in the opened tab
            $newTab.location.href = data.Link;
        };

        var errorBillService = function (data) {
            var $serviceErrorMessage = $serviceError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];

            // Close new tab opened
            $newTab.close();

            // Display error message
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var enablePayBillButton = function () {
            var isFormValid = $($form).validate().checkForm();
            $submitButton.disabled =
                !isFormValid ||
                parseFloat($totalAmountSelector.dataset.amount) <
                    parseFloat($totalAmountSelector.dataset.minAmount);
        };

        var taggingConfirmPayBillError = function () {
            dataLayer.push({
                event: CONSTANTS.TAG_CONFIRM_PAY_BILL_ERROR
            });
        };
        
        var taggingGuestPayTileLink = function () {
            dataLayer.push({
                event: CONSTANTS.TAG_GUEST_PAY_CLICK,
                tileScenario: CONSTANTS.TAG_GUEST_PAY_TILE_SCENARIO
            });
        };

        var validateDate = function (event) {
            var validator = $('.' + CONSTANTS.FORM_SELECTOR).validate();
            validator.element(event.target);
        };

        singlePayMyBill.rebindEvents = function () {
            $confirmPayBillError = $singleForm.getElementsByClassName(
                CONSTANTS.CONFIRM_PAY_BILL_ERROR
            )[0];
            if (!_isAuthUser) {
                coned.utils.addGeneralListeners($confirmPayBillError, taggingConfirmPayBillError);
            }
        }; 

        var getFontSize = function () {
            var fontSize = parseInt(window.getComputedStyle($headerTotalAmount, null).getPropertyValue(CONSTANTS.FONT_SIZE));

            return fontSize;
        };

        var initializeData = function () {
            $pageHeader = document.getElementsByClassName(CONSTANTS.PAGE_HEADER)[0];
            $billHeader = $singleForm.getElementsByClassName(CONSTANTS.BILL_HEADER_CLASS)[0];
            $headerTotalAmount = $singleForm.getElementsByClassName(CONSTANTS.HEADER_TOTAL_AMOUNT_CLASS)[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER_SELECTOR)[0];
            $animationStep = $singleForm.getElementsByClassName(CONSTANTS.ANIMATION_STEP_CLASS)[0];
            $formStep = $singleForm.getElementsByClassName(CONSTANTS.FORM_STEP_CLASS)[0];
            $form = $singleForm.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            $finishStepSelector = $singleForm.getElementsByClassName(
                CONSTANTS.FINISH_STEP_SELECTOR
            )[0];
            $finishStepFocusSelector = $singleForm.getElementsByClassName(
                CONSTANTS.FINISH_STEP_FOCUS_SELECTOR
            );
            $amountValue = $singleForm.getElementsByClassName(CONSTANTS.AMOUNT_SELECTOR)[0];
            $amountSelector = $form.getElementsByClassName(CONSTANTS.AMOUNT_SELECTOR);
            $paymentMethodSelector = $singleForm.getElementsByClassName(
                CONSTANTS.PAYMENT_METHOD_SELECTOR
            )[0];
            $creditCardSelector = $singleForm.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_SELECTOR
            );
            $creditCardPaymentSelector = $singleForm.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_PAYMENT_SELECTOR
            );
            $energyShareSelector = $singleForm.getElementsByClassName(
                CONSTANTS.ENERGY_SHARE_SELECTOR
            )[0];
            $totalAmountSelector = $singleForm.getElementsByClassName(
                CONSTANTS.TOTAL_AMOUNT_SELECTOR
            )[0];
            $energyAmountSelector = $singleForm.getElementsByClassName(
                CONSTANTS.ENERGY_AMOUNT_SELECTOR
            )[0];
            $paymentAmountSelector = $singleForm.getElementsByClassName(
                CONSTANTS.PAYMENT_AMOUNT_SELECTOR
            )[0];
            $serviceFeeSelector = $singleForm.getElementsByClassName(
                CONSTANTS.SERVICE_FEE_SELECTOR
            )[0];
            $inputDateSelector = $singleForm.getElementsByClassName(
                CONSTANTS.INPUT_DATE_SELECTOR
            )[0];
            $amountInput = $singleForm.getElementsByClassName(CONSTANTS.AMOUNT_INPUT_SELECTOR)[0];
            $rowEnergyShare = $singleForm.getElementsByClassName(CONSTANTS.ROW_ENERGY_SHARE)[0];
            $rowServiceFee = $singleForm.getElementsByClassName(CONSTANTS.ROW_SERVICE_FEE)[0];
            $creditCardMessage = $singleForm.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_MESSAGE
            );
            $energyShareModule = $singleForm.getElementsByClassName(
                CONSTANTS.ENERGY_SHARE_MODULE
            )[0];
            $billPaymentError = $singleForm.getElementsByClassName(CONSTANTS.BILL_PAYMENT_ERROR)[0];
            $minimumPaymentAlert = $singleForm.getElementsByClassName(
                CONSTANTS.MINUMUM_PAYMENT_ALERT
            )[0];
            $submitAnimationSelector = $animationStep.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION
            )[0];
            $paymentReferenceSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.PAYMENT_REFERENCE_SELECTOR
            )[0];
            $accountEmailContainerSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.ACCOUNT_EMAIL_CONTAINER_SELECTOR
            )[0];
            $oweSelector = $finishStepSelector.getElementsByClassName(CONSTANTS.OWE_SELECTOR)[0];
            $amountTitleSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.AMOUNT_TITLE_SELECTOR
            )[0];
            $thanksMessageSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.THANKS_MESSAGE_SELECTOR
            )[0];
            $creditCardMessageSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_MESSAGE_SELECTOR
            )[0];
            $paymentErrorMessageSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.PAYMENT_ERROR_MESSAGE_SELECTOR
            )[0];
            $paragraphsErrorSelector = $finishStepSelector.getElementsByClassName(
                CONSTANTS.PARAGRAPHS_ERROR_SELECTOR
            )[0];
            $creditCardMessageContainer = $finishStepSelector.getElementsByClassName(
                CONSTANTS.CREDIT_CARD_MESSAGE_CONTAINER
            )[0];
            $billLinks = $singleForm.getElementsByClassName(CONSTANTS.BILL_LINK_CLASS);
            $formLoading = $singleForm.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $accountAddress = $finishStepSelector.getElementsByClassName(
                CONSTANTS.ACCOUNT_ADDRESS
            )[0];
            $accountNumber = $finishStepSelector.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER
            )[0];
            $paymentDate = $finishStepSelector.getElementsByClassName(
                CONSTANTS.PAYMENT_DATE
            )[0];
            $billingTable = $singleForm.getElementsByClassName(CONSTANTS.BILLING_TABLE_SELECTOR)[0];
            $submitButton = $singleForm.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            _isAuthUser = query.hasClass($singleForm, CONSTANTS.AUTH_USER);
            $confirmPayBillError = $singleForm.getElementsByClassName(
                CONSTANTS.CONFIRM_PAY_BILL_ERROR
            )[0];
            $paymentAmountCheckboxSelector = $singleForm.getElementsByClassName(
                CONSTANTS.PAYMENT_AMOUNT_CHECKBOX
            );
            $popupConfirmation = $singleForm.getElementsByClassName(
                CONSTANTS.POPUP_CONFIRMATION_MESSAGE
            )[0];
            $popupConfirmationButton = $singleForm.getElementsByClassName(
                CONSTANTS.POPUP_CONFIRMATION_BUTTON
            )[0];
            $notificationBanner = $singleForm.getElementsByClassName(
                CONSTANTS.NOTIFICATION_BANNER
            )[0];
            $guestPayLink = $singleForm.getElementsByClassName(
                CONSTANTS.GUEST_PAY_LINK
            )[0];
            $partialPaymentWarning = $singleForm.querySelector(CONSTANTS.PARTIAL_PAYMENT_WARNING_MSG);
            $paymentStatusMessage = $finishStepSelector.getElementsByClassName(CONSTANTS.PAYMENT_STATUS_MESSAGE);
            $legacyMeterMessage = $finishStepSelector.getElementsByClassName(CONSTANTS.LEGACY_METER_MESSAGE);

            $overpaymentParagraph = $singleForm.getElementsByClassName(CONSTANTS.OVERPAYMENT_PARAGRAPH)[0];
            _isLessThanMinimum = false;
            _minimumAmount = 0;
            _eventPaymentName = '';
            _isCommercial = false;
            _isSummaryBillingProgram = $singleForm.classList.contains(CONSTANTS.IS_SUMMARY_BILLING_SELECTOR);
            _isLegacyMeter = $singleForm.dataset.legacyMeter;
            _hasSubmitted = false;
        };

        var initializeEvents = function () {
            // form validation
            new coned.components.ValidateForm(
                '.' + CONSTANTS.FORM_SELECTOR,
                prePayMyBillSingleSubmit,
                CONSTANTS.DEFAULT_VALIDATOR_HIDDEN_SELECTOR,
                true
            );

            $submitButton.disabled = true;
            $paymentMethodSelector.addEventListener('change', paymentMethodChange);
            // Set default payment amount if available
            var paymentAmount = $singleForm.dataset.paymentAmount;

            // get flag to verify if account is commercial
            for (index = 0; index < $creditCardPaymentSelector.length; index++) {
                if (
                    $creditCardPaymentSelector[index].classList.contains(
                        CONSTANTS.IS_COMMERCIAL_SELECTOR
                    )
                ) {
                    _isCommercial = true;
                }
            }

            if (_isAuthUser) {
                $energyShareSelector.addEventListener('change', donateChange);
                setDefaults();
            }

            $amountInput.addEventListener('keyup', amountInputCheck);

            // set the date picker dates limit
            setTimeout(function () {
                var dates =
                    $inputDateSelector.dataset.minDateDays || $inputDateSelector.dataset.maxDateDays
                        ? coned.utils.datepickerDateFormat(
                              $inputDateSelector.dataset.minDateDays,
                              $inputDateSelector.dataset.maxDateDays
                          )
                        : { minDate: '0', maxDate: '5d' };

                $($inputDateSelector).datepicker('option', 'minDate', dates.minDate);
                $($inputDateSelector).datepicker('option', 'maxDate', dates.maxDate);
            }, 1);

            $inputDateSelector.addEventListener('change', validateDate);

            if (paymentAmount && paymentAmount !== '') {
                changeToOtherAmount(paymentAmount);
            }

            // Click on links
            for (index = 0; index < $billLinks.length; index++) {
                coned.utils.addGeneralListeners($billLinks[index], billLinkEvent);
            }

            
            for (index = 0; index < $paymentAmountCheckboxSelector.length; index++) {
                $paymentAmountCheckboxSelector[index].addEventListener(
                    'change',
                    paymentTypeChange
                );
                // if the current scenario is the minimumbalance scenario get the minimum amount and set the flag
                if (
                    $paymentAmountCheckboxSelector[index].dataset.paymentOptionType ==
                    CONSTANTS.BNA_AMOUNT
                ) {
                    _minimumAmount =
                        $paymentAmountCheckboxSelector[index].dataset.paymentAmount;
                    _isLessThanMinimum = true;
                }
            }
            

            // Enable/disable Pay Bill button event
            $($form).on('change', '.' + CONSTANTS.INPUT_DATE_SELECTOR, enablePayBillButton);
            coned.utils.addMultipleListeners($form, ['change', 'keyup'], enablePayBillButton);

            // if scenario 4, the popup button should have the submit function and hide date tooltip
            if (_isLessThanMinimum) {
                coned.utils.addGeneralListeners($popupConfirmationButton, payMyBillSingleSubmit);
            }

            // Tagging events
            if (!_isAuthUser) {
                coned.utils.addGeneralListeners($confirmPayBillError, taggingConfirmPayBillError);
            }

            if ($guestPayLink) {
                coned.utils.addGeneralListeners($guestPayLink, taggingGuestPayTileLink);
            }
            
            // Show the overpayment parapgraph
            var currentUrl = window.location.href,
                overpayment = currentUrl.match('op=true');

            if (overpayment) {
                $overpaymentParagraph.removeAttribute('hidden');
            }

            // Set tabindex on end screen for a11y
            _.each($finishStepFocusSelector, function ($finishStepFocus) {
                $finishStepFocus.setAttribute(CONSTANTS.TABINDEX, '-1');
            }); 
            
            // Bill amount font-size dynamic resizing for big amounts ie: 6+ digits with cents
            if ($billHeader && $headerTotalAmount) {
                // Resize font size in the current page when is started.
                coned.utils.updateFontToFitAncestor($headerTotalAmount, $billHeader, getFontSize());
                // Resize event for amount font-size
                coned.utils.updateFontResizeThrottler(
                    coned.utils.updateFontToFitAncestor,
                    $headerTotalAmount,
                    $billHeader
                );
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    singlePayMyBill.prototype.isLoaded = function () {
        return isLoaded;
    };

    return singlePayMyBill;
})();
