// ==================== CONED TABS COMPONENT =========================
/* global dataLayer */
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ConedTabs = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CONED_TAB_CONTAINER: 'js-coned-tabs',
        CONED_TAB: 'js-coned-tab',
        CONED_TAB_BUTTON: 'js-conedtab-tab',
        CONED_TAB_SECTION: 'js-conedtab-section',
        CONED_TAB_BUTTON_ACTIVE_CLASS: 'coned-tabs__tab-item--active',
        CONED_TAB_SECTION_ACTIVE_CLASS: 'coned-tabs__section--active',
        CONED_TAB_SECTION_INACTIVE_CLASS: 'coned-tabs__section--inactive',
        CONED_TAB_DROPDOWN_SELECTOR: 'js-coned-tabs-dropdown',
        CONED_TAB_DROPDOWN_BUTTON: 'js-tab-dropdown-selector',
        CONED_TAB_DROPDOWN_ITEMS: 'js-coned-tab-dropdown-item',
        CONED_TAB_DROPDOWN_CONTAINER_CLASS: 'coned-tabs__dropdown',
        CONED_TAB_DROPDOWN_CLASS: 'coned-tabs__tab--dropdown',
        CONED_TAB_ACTIVE: 'js-tab-active',
        CONED_TAB_CHANGE_CLASS: 'js-tab-cta-change',
        HIDDEN_CLASS: 'hidden',
        TAB_URL_PARAMETER_NAME: 'tab',
        TAB_URL_PARAMETER: 'tab=',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        ERROR_CONTAINER_CLASSES: 'transactional__error content-gutter',
        ERROR_ICON_CLASSES: 'transactional__error-icon icon-report-problem',
        TAB_LOADING_DATA_ATTRIBUTE: 'data-tab-loading="true"',
        HEADER_WRAPPER_CLASS: 'js-header-wrapper',
        CONED_TAB_PARENT_CONTAINER: 'js-coned-tabs-container',
        CONED_DROPDOWN_CONTAINER: 'js-coned-tabs-select',
        CONED_DROPDOWN_TOGGLE: 'js-coned-tabs-select-toggle',
        CONED_DROPDOWN_SELECTED: 'js-coned-tabs-select-selected',
        CONED_DROPDOWN_OPTIONS: 'js-coned-tabs-options',
        CONED_DROPDOWN_OPTION: 'js-coned-tabs-option',
        SCROLL_TO_ANIMATION_TIME: 300,
        ACTIVE_CLASS: 'active',
        PARENT_TAB_CLASS: 'js-parent-tab',
        TAB_DEPTH_LEVEL_ATTRIBUTE: 'data-tab-depth-level',
        LAZY_LOAD_CLASS: 'js-lazy-load',
        HEADER_LOADED_WRAPPER_CLASS: 'js-header-loaded-wrapper',
        ROLE: 'role',
        ROLE_LISTBOX: 'listbox',
        ROLE_OPTION: 'option',
        ROLE_TAB: 'tab',
        ROLE_TABPANEL: 'tabpanel',
        ROLE_TABLIST: 'tablist',
        ARIA_CONTROLS: 'aria-controls',
        ARIA_SELECTED: 'aria-selected',
        ARIA_EXPANDED: 'aria-expanded',
        TABINDEX: 'tabindex'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var ConedTabs = function ($conedTabs) {
        /**
         * PRIVATE METHODS
         */
        var $conedTabsContainer,
            $conedTabsParentContainer,
            $conedTabsButtons,
            $conedTabSections,
            $conedTabsDropdown,
            $conedTabsDropdownButton,
            $conedTabsDropdownItems,
            $conedTabActive,
            $conedTabList,
            $dropDownOptions,
            $dropDownButton,
            $sectionSelected,
            $formLoading,
            $selectedTab,
            $headerWrapperElement,
            $dropdownTabsContainer,
            $dropdownTabSelected,
            $dropdownTabOptions,
            _updateUrl,
            _totalWidthTabs,
            _conedTabLoadTag,
            _conedTabsInstance,
            _dynamicTabs,
            _isDeeplinked,
            _conedTabsButtonsDataValues = [],
            _isDesktop;

        var tabClickEvent = function (event) {
            var targetDropdown, $targetDropdown, $activeDropdown;

            event.preventDefault();
            $selectedTab = event.target;
            if (!query.hasClass($selectedTab, CONSTANTS.CONED_TAB_BUTTON)) {
                $selectedTab = $selectedTab.parentElement;
            }

            var sectionName = $selectedTab.dataset.value;
            var tabIndex = $selectedTab.dataset.tabIndex;

            if (_updateUrl) {
                updateTabUrl($selectedTab, sectionName, tabIndex);
            }

            $sectionSelected = document.getElementById(sectionName);

            initializeChildScript($sectionSelected);

            // Load section script
            if ($sectionSelected.hasAttribute('data-script-src')) {
                loadDropdownTabScript();
            }
            // Reset all tabs/sections active state
            for (var buttonsIndex = 0; buttonsIndex < $conedTabsButtons.length; buttonsIndex++) {
                query.removeClass(
                    $conedTabsButtons[buttonsIndex],
                    CONSTANTS.CONED_TAB_BUTTON_ACTIVE_CLASS
                );
                $conedTabsButtons[buttonsIndex].setAttribute(CONSTANTS.ARIA_SELECTED, false);
                $conedTabsButtons[buttonsIndex].setAttribute('tabindex', -1);
            }

            for (var sectionsIndex = 0; sectionsIndex < $conedTabSections.length; sectionsIndex++) {
                query.addClass($conedTabSections[sectionsIndex], 'coned-tabs__section--inactive');
                if (
                    query.hasClass(
                        $conedTabSections[sectionsIndex],
                        CONSTANTS.CONED_TAB_SECTION_ACTIVE_CLASS
                    )
                ) {
                    query.removeClass(
                        $conedTabSections[sectionsIndex],
                        CONSTANTS.CONED_TAB_SECTION_ACTIVE_CLASS
                    );
                    query.fireEvent($sectionSelected, 'tab-inactive');
                }
            }

            // Set active state to selected tab/secion
            query.addClass($selectedTab, CONSTANTS.CONED_TAB_BUTTON_ACTIVE_CLASS);
            $selectedTab.setAttribute(CONSTANTS.ARIA_SELECTED, true);
            $selectedTab.setAttribute(CONSTANTS.TABINDEX, 0);
            query.addClass($sectionSelected, CONSTANTS.CONED_TAB_SECTION_ACTIVE_CLASS);
            query.removeClass($sectionSelected, CONSTANTS.CONED_TAB_SECTION_INACTIVE_CLASS);
            query.fireEvent($sectionSelected, 'tab-active');

            if ($conedTabActive) {
                $conedTabActive.innerHTML = $selectedTab.innerHTML;
            }

            if (!_isDesktop) {
                query.addClass($conedTabsDropdown, CONSTANTS.HIDDEN_CLASS);
            }

            if (_dynamicTabs) {
                targetDropdown = $selectedTab.dataset.tabIndex;
                $targetDropdown = $dropdownTabOptions.querySelector(
                    '[data-tab-index="' + targetDropdown + '"]'
                );
                $activeDropdown = $dropdownTabOptions.getElementsByClassName(
                    CONSTANTS.ACTIVE_CLASS
                )[0];

                if ($targetDropdown && $dropdownTabSelected) {
                    $dropdownTabSelected.innerHTML = $targetDropdown.innerHTML;
                    $targetDropdown.classList.add(CONSTANTS.ACTIVE_CLASS);
                }

                if ($activeDropdown) {
                    $activeDropdown.classList.remove(CONSTANTS.ACTIVE_CLASS);
                }
            }

            // If tab has lazy-load class, trigger the start of the lazy load
            if (query.hasClass($selectedTab, CONSTANTS.LAZY_LOAD_CLASS, true) && !_isDeeplinked) {
                coned.utils.triggerEvent($selectedTab, 'lazy-load-start');
            }
            // Dispatching a resize events to prevent widgets break
            // because resizing issues on the opower implementation.
            window.dispatchEvent(new Event('resize'));
        };

        var initializeChildScript = function ($parent) {
            if (
                !$parent.hasAttribute('data-child-initialized') &&
                query.hasClass($parent, CONSTANTS.PARENT_TAB_CLASS)
            ) {
                var $childActiveItem = $parent.getElementsByClassName(
                    CONSTANTS.CONED_TAB_BUTTON_ACTIVE_CLASS
                )[0];
                $childActiveItem.setAttribute(CONSTANTS.ARIA_SELECTED, true);
                $childActiveItem.setAttribute(CONSTANTS.TABINDEX, 0);
                $childActiveItem.click();
                $parent.setAttribute('data-child-initialized', true);
            }
        };

        var loadDropdownTabScript = function () {
            if (!$sectionSelected.hasAttribute('data-initialized-script')) {
                var script = document.createElement('script');

                script.src = $sectionSelected.dataset.scriptSrc;
                $sectionSelected.appendChild(script);
                $sectionSelected.setAttribute('data-initialized-script', true);
            }
        };

        var dropdownToggleButttonClickEvent = function (event) {
            event.preventDefault();

            if (query.hasClass($conedTabsDropdown, CONSTANTS.HIDDEN_CLASS)) {
                query.removeClass($conedTabsDropdown, CONSTANTS.HIDDEN_CLASS);
                $conedTabsDropdownButton.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            } else {
                query.addClass($conedTabsDropdown, CONSTANTS.HIDDEN_CLASS);
                $conedTabsDropdownButton.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
            }
        };

        /**
         * Add the corresponding attributes and classes to the mobile drop-down menu
         */
        var setMobileTabs = function () {
            query.addClass($conedTabsDropdown, CONSTANTS.CONED_TAB_DROPDOWN_CONTAINER_CLASS);
            query.addClass($conedTabsDropdown, CONSTANTS.HIDDEN_CLASS);
            $conedTabsContainer &&
                $conedTabsContainer.setAttribute(CONSTANTS.ROLE, CONSTANTS.ROLE_LISTBOX);

            for (var index = 0; index < $conedTabsDropdownItems.length; index++) {
                query.addClass($conedTabsDropdownItems[index], CONSTANTS.CONED_TAB_DROPDOWN_CLASS);
                setDropdownAttributes($conedTabsDropdownItems[index]);
            }
        };

        /**
         * Add the corresponding attributes and classes to the desktop tablist menu
         * If there is a dropdown version of the tablist: remove the mobile dropdown classes.
         */
        var setDesktopTabs = function () {
            query.removeClass($conedTabsDropdown, CONSTANTS.CONED_TAB_DROPDOWN_CONTAINER_CLASS);
            query.removeClass($conedTabsDropdown, CONSTANTS.HIDDEN_CLASS);
            $conedTabsContainer &&
                $conedTabsContainer.setAttribute(CONSTANTS.ROLE, CONSTANTS.ROLE_TABLIST);

            _.each($conedTabsDropdownItems, function($conedTabsDropdownItem) {
                query.removeClass(
                  $conedTabsDropdownItem,
                  CONSTANTS.CONED_TAB_DROPDOWN_CLASS
                );
            });

            _.each($conedTabsButtons, function($conedTabsButton) {
                setTabAttributes($conedTabsButton);
            });
        };

        //Allow move position of tabs for adjust focus order
        var handleShowTabsOrderWindowResize = function () {
            _isDesktop = coned.utils.isDesktop();

            var parentElement = $conedTabsContainer.parentElement;

            if (_isDesktop) {
                if (parentElement.firstChild != $conedTabsContainer) {
                    parentElement.insertBefore($conedTabsContainer, parentElement.firstChild);
                }
            } else {
                if (parentElement.lastChild != $conedTabsContainer) {
                    parentElement.appendChild($conedTabsContainer);
                }
            }
        };

        /**
         * Set attributes and classes for the elements,
         * depending if its deskptop or mobile
         */
        var setTabOrListboxAttributes = function () {
            _isDesktop = coned.utils.isDesktop();

            if (_isDesktop) {
                setDesktopTabs();
            } else {
                setMobileTabs();
            }
            handleShowTabsOrderWindowResize();
        };

        var setTabDeepLinking = function () {
            if (window.location.search.includes(CONSTANTS.TAB_URL_PARAMETER_NAME)) {
                var tabDepthLevel = $conedTabsContainer.dataset.tabDepthLevel || '',
                    tabToLoadParamValue = coned.utils.getUrlParameterValue(
                        CONSTANTS.TAB_URL_PARAMETER_NAME + tabDepthLevel
                    ),
                    tabToLoad = (tabToLoadParamValue || '').split('-'),
                    queryString = '[data-value="' + tabToLoad[0] + '"]';

                if (tabToLoad[1]) {
                    queryString += '[data-tab-index="' + tabToLoad[1] + '"]';
                }

                var $tabSelected = $conedTabs.querySelector(queryString);

                if (
                    $tabSelected &&
                    _conedTabsButtonsDataValues.includes($tabSelected.dataset.value)
                ) {
                    // Check if tab has lazy-load modules
                    if (query.hasClass($tabSelected, CONSTANTS.LAZY_LOAD_CLASS, true)) {
                        // Assign flag so it doesn't fire the event twice
                        _isDeeplinked = true;

                        // Add listener to wait for all modules to be loaded
                        window.addEventListener('modules-loaded', function () {
                            // As soon as eveything is loaded, fire event and remove flag in case other tabs have lazy-load elements
                            _isDeeplinked = false;
                            coned.utils.triggerEvent($tabSelected, 'lazy-load-start');
                        });
                    }

                    $tabSelected.click();
                }
            }
        };

        var updateTabUrl = function ($selectedTab, sectionName, tabIndex) {
            var params = coned.utils.getUrlParameters();

            var tabDepth = $conedTabsContainer.dataset.tabDepthLevel || '';
            var paramName = CONSTANTS.TAB_URL_PARAMETER_NAME + tabDepth;
            //some tabs might contain same sectionName, so to make them unique it combines sectionName with tabIndex
            var tabSectionValue = tabIndex ? sectionName + '-' + tabIndex : sectionName;

            // Update parameters only when the clicked tab is not already there
            var paramKeys = _.keys(params);
            if (!paramKeys.includes(paramName) || params[paramName] !== tabSectionValue) {
                // Remove all other tab parameters if they are not children of the selected tab
                var paramsToRemove = _.filter(paramKeys, function (key) {
                    return (
                        key.includes(CONSTANTS.TAB_URL_PARAMETER_NAME) &&
                        key.replace(CONSTANTS.TAB_URL_PARAMETER_NAME, '') > tabDepth
                    );
                });

                params = _.omit(params, function (value, key) {
                    return paramsToRemove.includes(key);
                });

                params[paramName] = tabSectionValue;
                coned.utils.setUrlParameters(params);
            }
        };

        var clickOnChangeTab = function (target, event) {
            event.preventDefault();

            var element = target,
                $tabToOpen,
                scrollToPosition;

            if (element.dataset.value) {
                $tabToOpen = document.querySelector(
                    '.' +
                        CONSTANTS.CONED_TAB_BUTTON +
                        '[data-value="' +
                        element.dataset.value +
                        '"]'
                );

                if ($tabToOpen) {
                    scrollToPosition =
                        $conedTabsContainer.offsetTop - $headerWrapperElement.offsetHeight;
                    // Scroll to tabs container
                    coned.utils.scrollTo(scrollToPosition, CONSTANTS.SCROLL_TO_ANIMATION_TIME);
                    // Open the selected tab
                    $tabToOpen.click();
                }
            }
        };

        var calculateTotalWidth = function () {
            if (_totalWidthTabs == 0) {
                // events for anchor links
                Array.prototype.forEach.call($conedTabList, function ($conedTab) {
                    // Setting total width Button
                    _totalWidthTabs += $conedTab.offsetWidth;
                });
            }
        };

        var setDynamicTabs = function () {
            calculateTotalWidth();

            if ($conedTabsParentContainer.clientWidth > _totalWidthTabs) {
                query.removeClass($conedTabsContainer, CONSTANTS.HIDDEN_CLASS);
                query.addClass($dropdownTabsContainer, CONSTANTS.HIDDEN_CLASS);
            } else {
                query.addClass($conedTabsContainer, CONSTANTS.HIDDEN_CLASS);
                query.removeClass($dropdownTabsContainer, CONSTANTS.HIDDEN_CLASS);
            }
        };

        var clickOnDropdownTabOption = function ($target, event) {
            var tabToLoad, $tabSelected;
            event.preventDefault();

            if (!$target.classList.contains(CONSTANTS.ACTIVE_CLASS)) {
                tabToLoad = $target.dataset.tabIndex;
                $tabSelected = $conedTabs.querySelector('[data-tab-index="' + tabToLoad + '"]');

                if ($tabSelected) {
                    resetAriaAttributeDropdown($target);
                    $target.setAttribute(CONSTANTS.ARIA_SELECTED, true);
                    $tabSelected.click();
                }
                closeDropdownTab();
            }
        };

        var resetAriaAttributeDropdown = function (target) {
            var list = target.parentElement.children;
            _.each(list, function (item) {
                item.setAttribute(CONSTANTS.ARIA_SELECTED, false);
            });
        };

        var closeDropdownTab = function () {
            $dropdownTabOptions.classList.add(CONSTANTS.HIDDEN_CLASS);
            $dropDownButton.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
        };

        var openDropdownTab = function () {
            $dropdownTabOptions.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $dropDownButton.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
        };

        var clickOnDropdownTab = function ($target, event) {
            event.preventDefault();

            if (!$dropDownButton) {
                $dropDownButton = event.target;
            }

            if ($dropdownTabOptions.classList.contains(CONSTANTS.HIDDEN_CLASS)) {
                openDropdownTab();
            } else {
                closeDropdownTab();
            }
        };

        /**
         *
         * @param {*} event Default event with button was clicked
         * @param {*} buttonActived Open and close button for dropdown
         * @param {*} listElements list of tabs or options of dropdown
         */
        var handleKeydownEventListener = function (event, buttonActived, listElements) {
            event.stopPropagation();

            switch (event.keyCode) {
                case coned.constants.KEY_CODE.ENTER:
                case coned.constants.KEY_CODE.SPACE:
                    event.target.click();
                    break;

                case coned.constants.KEY_CODE.ESC:
                    if (!_isDesktop) {
                        buttonActived.click();
                        buttonActived.focus();
                    }
                    break;

                case coned.constants.KEY_CODE.HOME:
                    event.preventDefault();
                    switchTabs(listElements[0]);
                    break;

                case coned.constants.KEY_CODE.END:
                    event.preventDefault();
                    switchTabs(listElements[listElements.length - 1]);
                    break;

                case coned.constants.KEY_CODE.RIGHT:
                case coned.constants.KEY_CODE.LEFT:
                case coned.constants.KEY_CODE.UP:
                case coned.constants.KEY_CODE.DOWN:
                    event.preventDefault();
                    moveUsingArrowKeys(event, listElements);
                    break;
            }
        };

        var moveUsingArrowKeys = function (event, listElementNav) {
            var key = event.keyCode,
                listElement = Array.prototype.slice.call(listElementNav),
                index = listElement.indexOf(event.target),
                newTab;

            if (key === coned.constants.KEY_CODE.RIGHT || key === coned.constants.KEY_CODE.DOWN) {
                newTab = getNextTab(index, listElementNav);
            } else {
                newTab = getPrevTab(index, listElementNav);
            }

            switchTabs(newTab);
        };

        var switchTabs = function (selectableTab) {
            selectableTab.focus();
        };

        var getPrevTab = function (index, listElementNav) {
            return listElementNav[index - 1] || listElementNav[listElementNav.length - 1];
        };

        var getNextTab = function (index, listElementNav) {
            return listElementNav[index + 1] || listElementNav[0];
        };

        var setTabAttributes = function ($tabButton) {
            $tabButton.setAttribute(CONSTANTS.ROLE, CONSTANTS.ROLE_TAB);
            $tabButton.setAttribute(
                CONSTANTS.ARIA_CONTROLS,
                $tabButton.dataset.value
            );
        };

        var setDropdownAttributes = function ($optionButton) {
            $optionButton.children[0].setAttribute(CONSTANTS.ROLE, CONSTANTS.ROLE_OPTION);
            $optionButton.children[0].setAttribute(
                CONSTANTS.ARIA_CONTROLS,
                $optionButton.children[0].dataset.value
            );
        };

        var initializeData = function () {
            _dynamicTabs = $conedTabs.dataset.dynamicTabs;
            _updateUrl = $conedTabs.dataset.updateUrl;
            _isDesktop = coned.utils.isDesktop();

            $conedTabsContainer = $conedTabs.getElementsByClassName(
                CONSTANTS.CONED_TAB_CONTAINER
            )[0];
            $conedTabsButtons = $conedTabsContainer.getElementsByClassName(
                CONSTANTS.CONED_TAB_BUTTON
            );
            $conedTabsDropdown = $conedTabs.getElementsByClassName(
                CONSTANTS.CONED_TAB_DROPDOWN_SELECTOR
            )[0];
            $conedTabsDropdownButton = $conedTabs.getElementsByClassName(
                CONSTANTS.CONED_TAB_DROPDOWN_BUTTON
            )[0];
            $conedTabsDropdownItems = $conedTabs.getElementsByClassName(
                CONSTANTS.CONED_TAB_DROPDOWN_ITEMS
            );
            $conedTabActive = $conedTabs.getElementsByClassName(CONSTANTS.CONED_TAB_ACTIVE)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $headerWrapperElement = document.getElementsByClassName(
                CONSTANTS.HEADER_WRAPPER_CLASS
            )[0];
            $conedTabList = $conedTabs.getElementsByClassName(CONSTANTS.CONED_TAB);
            $sectionSelected = $conedTabs.getElementsByClassName(
                CONSTANTS.CONED_TAB_SECTION_ACTIVE_CLASS
            )[0];

            if (_dynamicTabs) {
                $conedTabsParentContainer = $conedTabs.getElementsByClassName(
                    CONSTANTS.CONED_TAB_PARENT_CONTAINER
                )[0];
                $dropdownTabsContainer = $conedTabs.getElementsByClassName(
                    CONSTANTS.CONED_DROPDOWN_CONTAINER
                )[0];
                $dropdownTabSelected = $conedTabs.getElementsByClassName(
                    CONSTANTS.CONED_DROPDOWN_SELECTED
                )[0];
                $dropdownTabOptions = $conedTabs.getElementsByClassName(
                    CONSTANTS.CONED_DROPDOWN_OPTIONS
                )[0];
            }

            _conedTabLoadTag = $conedTabs.dataset.loadTag;
            _totalWidthTabs = 0;
            _isDeeplinked = false;

            // Tabs instance variable delimits the scope of the sections the tabs interact with
            _conedTabsInstance = $conedTabs.dataset.instance;

            $conedTabSections = $conedTabs.getElementsByClassName(CONSTANTS.CONED_TAB_SECTION + '-' + _conedTabsInstance);
        };

        var respondToVisibility = function () {
            var options = {
                root: null,
                rootMargin: '0px'
            };

            if ('IntersectionObserver' in window) {
                var elementObserved = new IntersectionObserver(function (entries) {
                    entries.forEach(function (entry) {
                        if (entry.isIntersecting) {
                            var tabElement = entry.target;

                            setDynamicTabs();
                            elementObserved.unobserve(tabElement);
                        }
                    });
                }, options);

                elementObserved.observe($conedTabs);
            }
        };

        var initializeEvents = function () {
            // Add listeners and attributes to each tab button.
            for (var index = 0; index < $conedTabsButtons.length; index++) {
                coned.utils.addGeneralListeners($conedTabsButtons[index], tabClickEvent);
                _conedTabsButtonsDataValues.push($conedTabsButtons[index].dataset.value);
                $conedTabsButtons[index].addEventListener('keydown', function (event) {
                    handleKeydownEventListener(event, $conedTabsDropdownButton, $conedTabsButtons);
                });

                if (index === 0) {
                    $conedTabsButtons[index].setAttribute(CONSTANTS.TABINDEX, 0);
                    $conedTabsButtons[index].setAttribute(CONSTANTS.ARIA_SELECTED, true);
                } else {
                    $conedTabsButtons[index].setAttribute(CONSTANTS.TABINDEX, -1);
                    $conedTabsButtons[index].setAttribute(CONSTANTS.ARIA_SELECTED, false);
                }
            }

            _.each($conedTabSections, function($conedTabSection) {
                $conedTabSection.setAttribute(CONSTANTS.ROLE, CONSTANTS.ROLE_TABPANEL);
            });

            $conedTabsDropdownButton &&
                coned.utils.addGeneralListeners(
                    $conedTabsDropdownButton.parentElement,
                    dropdownToggleButttonClickEvent
                );

            //Start setting ARIA attributes and classes to the desktop or mobile tablist
            if ($conedTabsDropdown) {
                setTabOrListboxAttributes();
                window.addEventListener('resize', setTabOrListboxAttributes);
            } else {
                setDesktopTabs();
            }

            // Set tagging for tabs loading
            if (_conedTabLoadTag) {
                if (query.isJSON(_conedTabLoadTag)) {
                    // Analytics data building
                    dataLayer.push(JSON.parse(_conedTabLoadTag));
                } else {
                    dataLayer.push(_conedTabLoadTag);
                }
            }

            if (_dynamicTabs) {
                // attach events
                coned.utils.addParentListener(
                    $dropdownTabsContainer,
                    coned.utils.generalEvents(),
                    CONSTANTS.CONED_DROPDOWN_OPTION,
                    clickOnDropdownTabOption
                );
                coned.utils.addParentListener(
                    $dropdownTabsContainer,
                    coned.utils.generalEvents(),
                    CONSTANTS.CONED_DROPDOWN_TOGGLE,
                    clickOnDropdownTab
                );

                $dropDownOptions = $dropdownTabOptions.getElementsByClassName(
                    CONSTANTS.CONED_DROPDOWN_OPTION
                );

                _.each($dropDownOptions, function ($dropDownItem) {
                    $dropDownItem.addEventListener('keydown', function (event) {
                        handleKeydownEventListener(event, $dropDownButton, $dropDownOptions);
                    });
                });

                window.addEventListener('resize', function () {
                    setDynamicTabs();
                });
                respondToVisibility();
            }

            // Set deep linking for tabs
            setTabDeepLinking();

            // Attach change tab ctas in side tabs
            coned.utils.addParentListener(
                $conedTabs,
                coned.utils.generalEvents(),
                CONSTANTS.CONED_TAB_CHANGE_CLASS,
                clickOnChangeTab
            );

            // Load section script
            if ($sectionSelected) {
                if ($sectionSelected.hasAttribute('data-script-src')) {
                    loadDropdownTabScript();
                }
            }

            // Trying to solve dashboard issues, loading will be displayed until this point
            if($formLoading) {
                $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ConedTabs.prototype.isLoaded = function () {
        return isLoaded;
    };
    return ConedTabs;
})();
