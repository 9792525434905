// ==================== PAY MY BILL NOTIFICATION SOLICITATION COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PayBillNotification = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SCID: 'ScId',
        NOTIFICATION: 'js-notification',
        CHECKBOX_CLASS: 'js-checkbox-selector',
        NOT_NOW_BUTTON: 'js-notification-notNow',
        CONFIRM_BUTTON: 'js-notification-confirm', 
        BANNER: 'js-notification-banner',
        BANNER_TITLE: 'js-notification-banner-title',
        BANNER_DESCRIPTION: 'js-notification-banner-description',
        BANNER_BUTTON: 'js-notification-banner-button',
        BANNER_BUTTON_SPAN: 'js-notification-banner-button-text',
        EMAIL_TEXT: 'js-notification-email-text',
        HIDDEN: 'hidden',
        EMAIL: 'email',
        DISABLED: 'disabled',

        // tagging
        PAY_MY_BILL: 'Pay My Bill',
        CANCEL_BUTTON_ACTION: 'Notification Solicitation Cancel',
        CANCEL_BUTTON_LABEL: 'Step 1 Cancel',
        CONFIRM_BUTTON_ACTION: 'Notification Solicitation Enroll'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var PayBillNotification = function ($payBillNotification) {
        /**
         * PRIVATE METHODS
         */
        var $notification, 
            $notificationCheckBoxes,
            $notNowButton,
            $confirmButton,
            $notificationBanner,
            $bannerTitle,
            $bannerDescription,
            $bannerButton,
            $bannerButtonSpan,
            _channels;

        var enableButtons = function (event) {
            var disabledButtons = false;

            if (event.currentTarget.checked === true && $confirmButton.hasAttribute(CONSTANTS.DISABLED)) {
                $confirmButton.removeAttribute(CONSTANTS.DISABLED);
            }

            for (var index = 0; index < $notificationCheckBoxes.length; index++) {
                if ($notificationCheckBoxes[index].checked === false) {
                    disabledButtons = true;
                } else {
                    disabledButtons = false;
                    break;
                }
            }

            if (disabledButtons) { 
                $confirmButton.setAttribute(CONSTANTS.DISABLED, true);
            }

        };

        var sendNotification = function () {
            var serviceUrl = $notificationBanner.dataset.sendNotificationServiceUrl,
                params,
                channelsList = new Object(),
                emailText = $payBillNotification.getElementsByClassName(CONSTANTS.EMAIL_TEXT)[0];

            for (var index = 0; index < $notificationCheckBoxes.length; index++) {
                var key = $notificationCheckBoxes[index].name;

                channelsList[key] = $notificationCheckBoxes[index].checked;

                if ($notificationCheckBoxes[index].checked === true) {
                    if (_channels !== '') {
                        _channels += '/';
                    }
                    _channels += key;
                }
            }

            if (emailText && emailText.getAttribute(CONSTANTS.HIDDEN) === null) {
                channelsList[CONSTANTS.EMAIL] = true;
                _channels = CONSTANTS.EMAIL;
            }

            // Service Data
            params = {
                ScId: query.getFormInputValue(document, CONSTANTS.SCID),
                PreferenceId: $notificationBanner.dataset.preference,
                Channels: channelsList

            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(serviceUrl, showContinueBanner, showErrorBanner, params, true);
        }

        var showNotNowBanner = function () {
            dataLayer.push({
                event: CONSTANTS.PAY_MY_BILL,
                action: CONSTANTS.CANCEL_BUTTON_ACTION,
                label: CONSTANTS.CANCEL_BUTTON_LABEL
            });

            $bannerTitle.innerHTML = $notificationBanner.dataset.notNowTitle;
            $bannerDescription.innerHTML = $notificationBanner.dataset.notNowDescription;
            $bannerButtonSpan.innerHTML = $notificationBanner.dataset.notNowButton;
            $bannerButton.href = $notificationBanner.dataset.notNowUrl;
            $notificationBanner.removeAttribute(CONSTANTS.HIDDEN);
            $notification.setAttribute(CONSTANTS.HIDDEN, true);
        };

        var showContinueBanner = function () {
            dataLayer.push({
                event: CONSTANTS.PAY_MY_BILL,
                action: CONSTANTS.CONFIRM_BUTTON_ACTION,
                label: _channels
            });

            $bannerTitle.innerHTML = $notificationBanner.dataset.confirmTitle;
            $bannerDescription.innerHTML = $notificationBanner.dataset.confirmDescription;
            $bannerButtonSpan.innerHTML = $notificationBanner.dataset.confirmButton;
            $bannerButton.href = $notificationBanner.dataset.successUrl;
            $notificationBanner.removeAttribute(CONSTANTS.HIDDEN);
            $notification.setAttribute(CONSTANTS.HIDDEN, true);
        };

        var showErrorBanner = function () {
            $bannerTitle.innerHTML = $notificationBanner.dataset.errorTitle;
            $bannerDescription.innerHTML = $notificationBanner.dataset.errorDescription;
            $bannerButtonSpan.innerHTML = $notificationBanner.dataset.errorButton;
            $bannerButton.href = $notificationBanner.dataset.errorUrl;
            $notificationBanner.removeAttribute(CONSTANTS.HIDDEN);
            $notification.setAttribute(CONSTANTS.HIDDEN, true);
        };

        var initializeData = function () {
            $notification = $payBillNotification.getElementsByClassName(CONSTANTS.NOTIFICATION)[0];
            $notificationCheckBoxes = $payBillNotification.getElementsByClassName(CONSTANTS.CHECKBOX_CLASS);
            $notNowButton = $payBillNotification.getElementsByClassName(CONSTANTS.NOT_NOW_BUTTON)[0];
            $confirmButton = $payBillNotification.getElementsByClassName(CONSTANTS.CONFIRM_BUTTON)[0];
            $notificationBanner = $payBillNotification.getElementsByClassName(CONSTANTS.BANNER)[0];
            $bannerTitle = $payBillNotification.getElementsByClassName(CONSTANTS.BANNER_TITLE)[0];
            $bannerDescription = $payBillNotification.getElementsByClassName(CONSTANTS.BANNER_DESCRIPTION)[0];
            $bannerButton = $payBillNotification.getElementsByClassName(CONSTANTS.BANNER_BUTTON)[0];
            $bannerButtonSpan = $payBillNotification.getElementsByClassName(CONSTANTS.BANNER_BUTTON_SPAN)[0];
            _channels = '';

        };

        var initializeEvents = function () {
            for (var index = 0; index < $notificationCheckBoxes.length; index++) {
                coned.utils.addGeneralListeners($notificationCheckBoxes[index], function (){
                    enableButtons(event);
                });
            }

            $notNowButton.addEventListener('click', showNotNowBanner);
            $confirmButton.addEventListener('click', sendNotification);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PayBillNotification.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PayBillNotification;
})();
