// ==================== ADDRESS DROPDOWN COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AddressDropdown = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        DROPDOWN_BUTTON: 'js-dropdown-button',
        DROPDOWN_LIST: 'js-dropdown-list',
        DROPDOWN_ITEM: 'js-dropdown-item',
        DROPDOWN_BUTTON_CONTAIN: 'js-dropdown-button-contain',
        DROPDOWN_ITEM_CONTAIN: 'js-dropdown-item-contain',
        DROPDOWN_CARROT: 'js-dropdown-carrot',
        CHECKED_ICON_SELECTOR: 'js-icon-check',
        DISPLAY_NONE_CLASS: 'address-dropdown__list-item--hidden',
        CHECKED_ICON_CLASS: 'icon-check-status',
        CARROT_UP: 'address-dropdown__icon--up',
        DROPDOWN_REMOVE_BUTTON: 'address-dropdown__button--no-button',
        VALIDATED_ACCOUNT_ERROR: 'js-validated-account-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        ACCOUNT_TYPE_COMMERCIAL: 'C',
        HIDDEN_CLASS: 'hidden',

        // Report Outage Related
        REPORT_OUTAGE_FORM_CONTAINER: 'js-report-outage-form',
        REPORT_OUTAGE_FORM_LOADING: 'js-form-loading',
        REPORT_OUTAGE_SUBMIT_BUTTON: 'js-submit-outage',
        REPORT_OUTAGE_DROPDOWN_UPDATE_ACCOUNT: 'js-address-dropdown-update-account',

        // Energy Affordable Application 
        ENERGY_AFFORDABLE_FORM: 'js-energy-affordable-form',
        SUBMIT_BUTTON: 'js-submit-button',

        // Aria attributes
        ROLE: 'role',
        TABINDEX: 'tabindex',
        ARIA_EXPANDED: 'aria-expanded',
        ARIA_SELECTED: 'aria-selected'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AddressDropdown = function ($dropdown) {
        /**
         * PRIVATE METHODS
         */
        var $dropdownButton,
            $dropdownList,
            $dropdownItems,
            $dropdownContain,
            $icons,
            $dropdownCarrot,
            $firstDropdownItem,
            $firstCheckIcon,
            $itemSelected,
            // Report Outage Related
            $reportOutageValidateAccountError,
            $reportOutageSubmitButton,
            $reportOutageContainer,
            $reportOutageLoading,
            //Energy Affordable Application
            $energyAffordableForm,
            $validateAccountError,
            $submitButton,
            _account,
            _isScroll;

        /**
         * Show dropdown list styles.
         */
        var slideDropdown = function () {
            if (query.hasClass($dropdownList, CONSTANTS.DISPLAY_NONE_CLASS)) {
                openDropdown();
            } else {
                closeDropdown();
            }
        };

        /**
         * Open dropdown list using mouse or touch action.
         */
        var slideDropdownOnClicked = function (event) {
            event.preventDefault();

            slideDropdown();

            // For keyboard use. Focus the first item.
            $dropdownItems[0].focus();
        };

        /**
         * Open dropdown list using enter key.
         */
        var slideDropdownOnKeyPressed = function (event) {
            var keycode = event.keyCode;

            if (keycode == coned.constants.KEY_CODE.ENTER) {
                slideDropdown();

                // Get the current selected item and remove it in order to update to a new selection
                var $currentSelectedOption = $dropdownList.querySelectorAll(
                    '[aria-selected="true"]'
                )[0];

                if ($currentSelectedOption) {
                    $currentSelectedOption.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
                }

                //Identifier selected item for  moving focus
                if ($itemSelected) {
                    $itemSelected.parentElement.focus();
                    $itemSelected.parentElement.setAttribute(CONSTANTS.ARIA_SELECTED, 'true');
                } else {
                    $dropdownItems[0].focus();
                    $dropdownItems[0].setAttribute(CONSTANTS.ARIA_SELECTED, 'true');
                }
            }
        };

        /**
         * Select option in dropdown list
         */
        var selectItem = function () {
            if ($dropdownContain.dataset.getAccountEndpoint && $reportOutageContainer) {
                validatedSelectedAddress();
            } else {
                selectedItemChanges();
            }

            if ($dropdownContain.dataset.accountType && $energyAffordableForm) {
                validateCommercialAddress();
            }

            $dropdownButton.setAttribute(CONSTANTS.ARIA_EXPANDED, 'false');

            coned.utils.triggerEvent($itemSelected, 'checkEligibility');
        };

        /**
         * Select option in dropdown list using mouse or touch action
         */
        var checkClickOnItem = function (event) {

            if (_isScroll) {
                // User was trying to scroll, not click
                _isScroll = false;
                return;
            }

            event.preventDefault();

            //Item is selected on load through the list search
            if (event.target.classList.contains(CONSTANTS.DROPDOWN_ITEM)) {
                $itemSelected = event.target.getElementsByClassName(CONSTANTS.DROPDOWN_ITEM_CONTAIN)[0];
            } else {
                $itemSelected = query.selectParentElement(
                    event.target,
                    CONSTANTS.DROPDOWN_ITEM_CONTAIN
                );
            }
            selectItem();
        };

        /**
         * Handle keyboard action in dropdown list
         */
        var checkKeyPressOnItem = function (event) {
            var keycode = event.keyCode;

            if (keycode == coned.constants.KEY_CODE.ENTER) {
                $itemSelected = event.target.children[0];
                selectItem();
                $dropdownButton.focus();
            } else if (keycode == coned.constants.KEY_CODE.TAB) {
                closeDropdown();
            } else if (keycode == coned.constants.KEY_CODE.ESC) {
                closeDropdown();
                $dropdownButton.focus();
            } else if (keycode == coned.constants.KEY_CODE.UP) {
                focusPreviousItem(event);
            } else if (keycode == coned.constants.KEY_CODE.DOWN) {
                focusNextItem(event);
            }
        };

        var focusPreviousItem = function (event) {
            event.preventDefault();
            var previousElement = event.target.previousElementSibling;
            if (previousElement) {
                previousElement.focus();
            } else {
                $dropdownItems[$dropdownItems.length - 1].focus();
            }
        };

        var focusNextItem = function (event) {
            event.preventDefault();
            var nextElement = event.target.nextElementSibling;
            if (nextElement) {
                nextElement.focus();
            } else {
                $dropdownItems[0].focus();
            }
        };

        var selectedItemChanges = function () {
            var $icon = $itemSelected.parentElement.getElementsByClassName(
                    CONSTANTS.CHECKED_ICON_SELECTOR
                )[0],
                dataAccount = $itemSelected.dataset.account,
                dataLastDigitsAccountNumber = $itemSelected.dataset.lastDigitsAccountNumber,
                dataStreet = $itemSelected.dataset.street,
                dataCity = $itemSelected.dataset.city,
                dataState = $itemSelected.dataset.state,
                dataZipcode = $itemSelected.dataset.zipcode,
                dataCountry = $itemSelected.dataset.country,
                dataAddress2 = $itemSelected.dataset.address2,
                dataMdac = $itemSelected.dataset.mdac,
                dataUnit = $itemSelected.dataset.unit,
                dataPremise = $itemSelected.dataset.premise,
                dataCompany = $itemSelected.dataset.company,
                dataMaid = $itemSelected.dataset.accountMaid,
                dataAccountNumber = $itemSelected.dataset.accountNumber;

            // Update data values
            $dropdownContain.dataset.account = dataAccount ? dataAccount : '';
            $dropdownContain.dataset.lastDigitsAccountNumber = dataLastDigitsAccountNumber ? dataLastDigitsAccountNumber : '';
            $dropdownContain.dataset.street = dataStreet ? dataStreet : '';
            $dropdownContain.dataset.city = dataCity ? dataCity : '';
            $dropdownContain.dataset.state = dataState ? dataState : '';
            $dropdownContain.dataset.zipcode = dataZipcode ? dataZipcode : '';
            $dropdownContain.dataset.country = dataCountry ? dataCountry : '';
            $dropdownContain.dataset.address2 = dataAddress2 ? dataAddress2 : '';
            $dropdownContain.dataset.mdac = dataMdac ? dataMdac : '';
            $dropdownContain.dataset.accountMaid = dataMaid ? dataMaid : '';
            $dropdownContain.dataset.unit = dataUnit ? dataUnit : '';
            $dropdownContain.dataset.premise = dataPremise ? dataPremise : '';
            $dropdownContain.dataset.company = dataCompany ? dataCompany : '';
            $dropdownContain.dataset.accountNumber = dataAccountNumber ? dataAccountNumber : '';

            // Remove checked icon class
            _.each($icons, function ($icon) {
                query.removeClass($icon, CONSTANTS.CHECKED_ICON_CLASS);
            });

            query.addClass($icon, CONSTANTS.CHECKED_ICON_CLASS);

            query.fireEvent($dropdown, 'change', { account: dataAccount });

            $dropdownContain.innerHTML = $itemSelected.innerHTML;
            closeDropdown();

            // This address validation is currently only being done for ReportOutage,
            //   so if property exists, execute a reportOutage instance to check the mdac
            if (
                $itemSelected.dataset.validateAddress &&
                !coned.utils.isOru() &&
                $reportOutageContainer.dataset.moduleStarted !== 'true'
            ) {
                new coned.components.ReportOutage($reportOutageContainer);
            }
        };

        var closeDropdown = function () {
            query.addClass($dropdownList, CONSTANTS.DISPLAY_NONE_CLASS);
            query.removeClass($dropdownCarrot, CONSTANTS.CARROT_UP);
            $dropdownButton.setAttribute(CONSTANTS.ARIA_EXPANDED, 'false');
        };

        var openDropdown = function () {
            query.removeClass($dropdownList, CONSTANTS.DISPLAY_NONE_CLASS);
            query.addClass($dropdownCarrot, CONSTANTS.CARROT_UP);
            $dropdownButton.setAttribute(CONSTANTS.ARIA_EXPANDED, 'true');
        };

        var checkIconExists = function () {
            var iconExist = false;

            for (var iconsIndex = 0; iconsIndex < $icons.length; iconsIndex++) {
                if (query.hasClass($icons[iconsIndex], CONSTANTS.CHECKED_ICON_CLASS)) {
                    iconExist = true;
                    break;
                }
            }

            if (!iconExist) {
                $firstDropdownItem = $dropdown.getElementsByClassName(CONSTANTS.DROPDOWN_ITEM)[0];
                $firstCheckIcon = $firstDropdownItem.getElementsByClassName(
                    CONSTANTS.CHECKED_ICON_SELECTOR
                )[0];
                $firstCheckIcon.classList.add(CONSTANTS.CHECKED_ICON_CLASS);
            }
        };
        
        // Validated Commercial Address
        var validateCommercialAddress = function () {
            if($itemSelected.dataset.accountType === CONSTANTS.ACCOUNT_TYPE_COMMERCIAL) { 
                var $msgTextSelector = $validateAccountError.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];

                $msgTextSelector.innerHTML = $itemSelected.dataset.errorMsg;

                $validateAccountError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $validateAccountError.focus();
                $submitButton.disabled = true;
            } else {
                $validateAccountError.classList.add(CONSTANTS.HIDDEN_CLASS);
                $submitButton.disabled = false;
            }  
        };        

        // Report Outage Related
        var validatedSelectedAddress = function () {
            var getAccountService = $dropdownContain.dataset.getAccountEndpoint,
                filterValue = $dropdownContain.dataset.filterByCreateOutage ? true : false,
                params;

            $reportOutageValidateAccountError.classList.add(CONSTANTS.HIDDEN_CLASS);

            params = {
                ScId: $dropdownContain.dataset.scid,
                Maid: $itemSelected.dataset.accountMaid,
                FiltertByAllowCreateOutage: filterValue
            };

            params = JSON.stringify(params);

            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_ACCOUNT_REPORT_OUTAGE,
                    successCallback,
                    errorCallback,
                    params,
                    $reportOutageLoading
                );
            } else {
                query.postData(
                    getAccountService,
                    successCallback,
                    errorCallback,
                    params,
                    true,
                    $reportOutageLoading
                );
            }
        };

        var successCallback = function (data) {
            $reportOutageSubmitButton.disabled = false;
            $dropdownContain.dataset.hasError = 'false';

            $itemSelected.dataset.account = data.AccountNumber;
            $itemSelected.dataset.street = data.ServiceAddress.Street1;
            $itemSelected.dataset.city = data.ServiceAddress.City;
            $itemSelected.dataset.state = data.ServiceAddress.State;
            $itemSelected.dataset.zipcode = data.ServiceAddress.PostalCode;
            $itemSelected.dataset.country = data.ServiceAddress.Country;
            $itemSelected.dataset.address2 = data.ServiceAddress.UnitNumber;
            $itemSelected.dataset.mdac = data.MultiDwellingAccessCode
                ? data.MultiDwellingAccessCode
                : '';
            $itemSelected.dataset.validateAddress = false;

            selectedItemChanges();
        };

        var errorCallback = function (data) {
            var $msgTextSelector = $reportOutageValidateAccountError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];

            $reportOutageSubmitButton.disabled = true;
            $dropdownContain.dataset.hasError = 'true';
            $reportOutageValidateAccountError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML =
                data && data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;

            selectedItemChanges();
            $reportOutageValidateAccountError.focus();
        };

        var initializeData = function () {
            $dropdownButton = $dropdown.getElementsByClassName(CONSTANTS.DROPDOWN_BUTTON)[0];
            $dropdownList = $dropdown.getElementsByClassName(CONSTANTS.DROPDOWN_LIST)[0];
            $dropdownItems = $dropdown.getElementsByClassName(CONSTANTS.DROPDOWN_ITEM);
            $dropdownContain = $dropdown.getElementsByClassName(
                CONSTANTS.DROPDOWN_BUTTON_CONTAIN
            )[0];
            $dropdownCarrot = $dropdown.getElementsByClassName(CONSTANTS.DROPDOWN_CARROT)[0];
            $icons = $dropdown.getElementsByClassName(CONSTANTS.CHECKED_ICON_SELECTOR);

            // Report Outage Related
            $reportOutageValidateAccountError = $dropdown.getElementsByClassName(
                CONSTANTS.VALIDATED_ACCOUNT_ERROR
            )[0];
            $reportOutageSubmitButton = document.getElementsByClassName(
                CONSTANTS.REPORT_OUTAGE_SUBMIT_BUTTON
            )[0];
            $reportOutageContainer = document.getElementsByClassName(
                CONSTANTS.REPORT_OUTAGE_FORM_CONTAINER
            )[0];

            //Energy Affordable Form
            $energyAffordableForm = document.getElementsByClassName(
                CONSTANTS.ENERGY_AFFORDABLE_FORM
            )[0];
            $validateAccountError = $dropdown.getElementsByClassName(
                CONSTANTS.VALIDATED_ACCOUNT_ERROR
            )[0];
            $submitButton = document.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON
            )[0];

            if ($reportOutageContainer) {
                $reportOutageLoading = $reportOutageContainer.getElementsByClassName(
                    CONSTANTS.REPORT_OUTAGE_FORM_LOADING
                )[0];

                if($dropdown.classList.contains(CONSTANTS.REPORT_OUTAGE_DROPDOWN_UPDATE_ACCOUNT)) {
                    if(coned.utils.isOru()) {
                        _account =  $dropdownContain.dataset.accountMaid;
                    } else {
                        _account =  $dropdownContain.dataset.account;
                    }
                }
            }
        };

        var initializeEvents = function () {
            checkIconExists();

            if ($dropdownItems.length === 0) {
                if ($dropdownCarrot) {
                    $dropdownCarrot.classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                $dropdownButton.classList.add(CONSTANTS.DROPDOWN_REMOVE_BUTTON);
            } else {
                coned.utils.addGeneralListeners($dropdownButton, slideDropdownOnClicked);
                $dropdownButton.addEventListener('keydown', slideDropdownOnKeyPressed);

                _.each($dropdownItems, function ($dropdownItem) {
                    coned.utils.addGeneralListeners($dropdownItem, checkClickOnItem);
                    $dropdownItem.addEventListener('keydown', checkKeyPressOnItem);
                    $dropdownItem.addEventListener('accountInContext', checkClickOnItem);

                    // A11y attributes
                    $dropdownItem.setAttribute(CONSTANTS.ROLE, 'option');
                    $dropdownItem.setAttribute(CONSTANTS.TABINDEX, '-1');
                    $dropdownItem.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');

                    // Prevent scrolling to be confused as click
                    $dropdownItem.addEventListener('touchstart', function () {
                        _isScroll = false;
                    }, coned.supportsPassive ? { passive: true } : false);
                    $dropdownItem.addEventListener('touchmove', function () {
                        _isScroll = true;
                    }, coned.supportsPassive ? { passive: true } : false);
                });

                // Close dropdown on click or focus out of it.
                window.addEventListener('click', function(e) {
                    if (query.selectParentElement($dropdown, e.target.classList[0])) {
                        closeDropdown();
                    }
                });

                //If report outage validate the account in context address on load
                if ($reportOutageContainer) {
                    if($dropdown.classList.contains(CONSTANTS.REPORT_OUTAGE_DROPDOWN_UPDATE_ACCOUNT)) {
                        for (var i = 0; $dropdownItems[i]; i++) { 
                            var currentItemAccount = '';

                            if(coned.utils.isOru()) {
                                currentItemAccount = $dropdownItems[i].children[0].dataset.accountMaid;
                            } else { 
                                currentItemAccount = $dropdownItems[i].children[0].dataset.account;
                            }

                            if (currentItemAccount === _account) {
                                $itemSelected = $dropdownItems[i].children[0];
                                validatedSelectedAddress();
                                break;
                            }
                        }
                    }
                }
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AddressDropdown.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AddressDropdown;
})();
