// ==================== LOAD MORE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoadMore = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SHOW_MORE_CLASS: 'js-show-more',
        ITEM_CLASS: 'js-item',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @param  {HTMLElement} $loadMoreAncestorWrapper [optional] Ancestor wrapper for the $loadMore module. Previous sibling of the section you want to focus on upon clicking load more button when it gets hidden.
     * @return {}        Encapsulated modules with its function.
     */
    var LoadMore = function ($loadMore, $loadMoreAncestorWrapper) {
        /**
         * PRIVATE METHODS
         */
        var $showMore, $items, $focusLink, $showMoreClicked, _initialLoad, _loadMoreAmount;

        var showMoreClickEvent = function (event) {
            event.preventDefault();

            //Set the focus on the first new item showed in the screen
            $showMoreClicked = true;
            

            showMoreItems(_initialLoad, _initialLoad + _loadMoreAmount);

            // Set initial load as the addition of the loaded items + the load more amount
            _initialLoad = _initialLoad + _loadMoreAmount;

            // If requested index end exceeds the items length, the load more button is hidden
            if (_initialLoad >= $items.length) {
                query.addClass($showMore, CONSTANTS.HIDDEN_CLASS);
            }
        };

        var showMoreItems = function (indexStart, indexEnd) {
            var focusedElementExists = false;
            for (; indexStart < indexEnd && indexStart < $items.length; indexStart++) {
                query.removeClass($items[indexStart], CONSTANTS.HIDDEN_CLASS);

                // Handle focus when new elements become visible.
                if ($loadMoreAncestorWrapper) {
                    if (
                        !focusedElementExists &&
                        isLoaded
                    ) {
                        $focusLink = $items[indexStart].querySelector(coned.constants.FOCUSABLE_ELEMENTS_QUERY);
                        // Focus first element from the first new visible parent that has a focusable element
                        if ($focusLink) {
                            $focusLink.focus();
                            focusedElementExists = true;
                        // When show more button gets hidden and there are no more focusable elements,
                        // focus the next section's first focusable element.
                        } else if (indexStart === $items.length-1) {
                            coned.utils.focusFirstFocusableElementNextSibling($loadMoreAncestorWrapper);
                        }
                    }
                }
                if ($showMoreClicked) {
                    if (
                        !focusedElementExists &&
                        isLoaded
                    ) {
                        $focusLink = $items[indexStart].children[0];
                        // Focus first element from the first new visible parent that has a focusable element
                        if ($focusLink) {
                            $focusLink.focus();
                            coned.utils.triggerEvent($loadMore, 'show-more-grid-focus', $focusLink);
                            focusedElementExists = true;
                        // When show more button gets hidden and there are no more focusable elements,
                        // focus the next section's first focusable element.
                        } else if (indexStart === $items.length-1) {
                            var previousItem = $items[indexStart-1].children[0]
                            previousItem.focus();
                            coned.utils.triggerEvent($loadMore, 'show-more-grid-focus', previousItem);
                        }
                    }
                    $showMoreClicked = false;
                }
            }
        };

        var initializeData = function () {
            $showMore = $loadMore.getElementsByClassName(CONSTANTS.SHOW_MORE_CLASS)[0];
            $items = $loadMore.getElementsByClassName(CONSTANTS.ITEM_CLASS);
            _initialLoad = parseInt($loadMore.dataset.initialLoad);
            _loadMoreAmount = parseInt($loadMore.dataset.loadMore);
        };

        var initializeEvents = function () {
            // Render initial amount of items
            if ($items) {
                showMoreItems(0, _initialLoad);

                // If items amount exceeds the initial load, then display the show more button
                if ($showMore && _initialLoad < $items.length) {
                    query.removeClass($showMore, CONSTANTS.HIDDEN_CLASS);
                    coned.utils.addGeneralListeners($showMore, showMoreClickEvent);
                }
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };
        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoadMore.prototype.isLoaded = function () {
        return isLoaded;
    };
    return LoadMore;
})();
