// ==================== SUMMARY BILLING INFORMATION COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SummaryBillingInformation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        BILLING_GRID: '.js-data-grid',
        BILLING_GRID_REMOVE_BORDER: 'summary-billing-information__table-row--no-borders',
        BILL_LINK_CLASS: 'js-view-bill',
        BILL_ITEM_CLASS: 'js-item',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        FORM_LOADING: 'js-form-loading',
        SHOW_MORE_BUTTON: '.js-show-more'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var SummaryBillingInformation = function ($summaryBillingInformation) {
        /**
         * PRIVATE METHODS
         */
        var $currentBillLink, 
            $formLoading, 
            $newTab,
            $serviceErrorRowContainer,
            $showMoreButton,
            $viewBillLinks;

        var viewBillLinkService = function (event) {
            event.preventDefault();

            var $billItem = query.selectParentElement(event.target, CONSTANTS.BILL_ITEM_CLASS),
                serviceUrl,
                params,
                isCsv;

            // Set elements depending on the item clicked
            $currentBillLink = query.selectParentElement(event.target, CONSTANTS.BILL_LINK_CLASS);
            $serviceErrorRowContainer = $billItem.nextElementSibling;
            $serviceErrorRowContainer && $serviceErrorRowContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $billItem.classList.remove(CONSTANTS.BILLING_GRID_REMOVE_BORDER);
            isCsv = $currentBillLink.dataset.iscsv;
            serviceUrl = (isCsv) ? $summaryBillingInformation.dataset.documentCsvServiceUrl : $summaryBillingInformation.dataset.billServiceUrl

            // If bill link is available, open the link in a new tab
            if ($currentBillLink.dataset.billLink) {
                window.open($currentBillLink.href);

                return;
            }

            // Service data
            params = {
                Maid: $currentBillLink.dataset.accountMaid,
                MasterMaid: $currentBillLink.dataset.masterMaid,
                ScId: $summaryBillingInformation.dataset.billInfoScid
            };

            if (isCsv) {
                params.BillId = $currentBillLink.dataset.billId;
            } else {
                params.BillDate = $currentBillLink.dataset.billDate;
            }

            // Before doing the service call, lets create the new tab where the link will be opened, so it won't be blocked by browser security
            $newTab = window.open();

            // Service call
            query.getData(
                serviceUrl,
                function(data) { successBillLinkService(data, $billItem) },
                function(data) { errorBillLinkService(data, $billItem) },
                params,
                $formLoading
            );

            //Check if it's csv and safari cause it didn't support correctly the .close function
            if (isCsv && !coned.utils.isSafari()) {
                setTimeout(function () {
                    $newTab.close();
                }, 5000);
            }
        };

        var successBillLinkService = function (data, $billItem) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_LINK, 
                    redirectPage,
                    function(data) { errorBillLinkService(data, $billItem) }
                );
            } else if (data.Link && data.Link != '') {
                redirectPage(data);
            } else {
                errorBillLinkService(data, $billItem)
            }
        };

        var redirectPage = function (data) {
            // Set link and flag for future click
            $currentBillLink.dataset.billLink = 'true';
            $currentBillLink.href = data.Link;

            // Set file location in the opened tab
            $newTab.location.href = data.Link;
        };

        var errorBillLinkService = function (data, $billItem) {
            var $serviceError = $serviceErrorRowContainer.getElementsByClassName(
                  CONSTANTS.SERVICE_ERROR
                )[0],
                $serviceErrorMessage = $serviceError.getElementsByClassName(
                  CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];
          
            // Close new tab opened
            $newTab.close();

            // Display error message
            $billItem.classList.add(CONSTANTS.BILLING_GRID_REMOVE_BORDER);
            $serviceErrorRowContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.textContent = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var focusBackToTable = function () {
            var $billingInformationGrid = $summaryBillingInformation.querySelector(CONSTANTS.BILLING_GRID);
            $billingInformationGrid && $billingInformationGrid.focus();
        };

        var initializeData = function () {
            $viewBillLinks = $summaryBillingInformation.getElementsByClassName(
                CONSTANTS.BILL_LINK_CLASS
            );
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $showMoreButton = $summaryBillingInformation.querySelector(CONSTANTS.SHOW_MORE_BUTTON);
        };

        var initializeEvents = function () {
            if ($showMoreButton) {
                $showMoreButton.addEventListener('click', focusBackToTable);
            }

            for (var index = 0; index < $viewBillLinks.length; index++) {
                coned.utils.addGeneralListeners($viewBillLinks[index], viewBillLinkService);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SummaryBillingInformation.prototype.isLoaded = function () {
        return isLoaded;
    };
    return SummaryBillingInformation;
})();
