// ==================== NOTIFICATION ONBOARDING COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.NotificationOnboarding = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_IGNORE_VALIDATION: 'js-validate-ignore',
        HIDDEN_CLASS: 'hidden',
        EMPTY_STRING: '',
        NOTIFICATION_CHECK: 'js-notification-check',
        NOTIFICATION_CHECK_EMAIL: 'js-notification-check-email',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        EDIT_SCID: 'ScId',
        TURN_ON_EMAIL_NOTIFICATION_EVENT: 'turn-on-email-notification',
        TRUE_STRING: "true",
        TRUE_VALUE: true,
        FALSE_VALUE: false,

        // TOAST
        TOAST: 'js-toast',
        SHOW_TOAST_EVENT: 'show-toast-event',

        // Tagging
        GENERAL_NOTIFICATION_TAG: 'general.notification',
        ON_STRING: 'on',
        OFF_STRING: 'off',
        PERIOD_STRING: '.'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var NotificationOnboarding = function ($notificationOnboarding) {
        /**
         * PRIVATE METHODS
         */
        var $checksNotification,
            $formLoading,
            $actualField,
            $notificationCheckbox,
            $toast,
            $serviceError,
            $serviceErrorMessage,
            $checkboxSwitchEmail,
            _isStandalonePage;

        /**
         * Service call for notification update.
         * Can be triggered from a click event or by calling the method and passing the element and turnOn option.
         * @param {Event} event Click event that triggered the action, or null if passing the optional params
         * @param {HTMLElement} $checkboxSwitch [optional] Actual checkbox switch, or empty if an event triggers the function
         * @param {Boolean} turnOn [optional] To turn on/off the checkbox switch. To be added along with $checkboxSwitch param
         */
        var editNotification = function (event, $checkboxSwitch, turnOn) {
            var _isUserInteraction = event,
                serviceUrl,
                showMessage = _isUserInteraction ? 
                    true : 
                    false,
                params;                

            $actualField = _isUserInteraction ? 
                event.target.parentNode : 
                $checkboxSwitch.parentNode;
            
            serviceUrl = $actualField.dataset.service,
                    
            $notificationCheckbox = _isUserInteraction ? 
                event.target : 
                $checkboxSwitch;

            params = {
                ScId: query.getFormInputValue($notificationOnboarding, CONSTANTS.EDIT_SCID),
                PreferenceBillReminderId: $actualField.dataset.preferenceBillReminder,
                PreferenceOnlineTransConfirmId: $actualField.dataset.preferenceOnlineTransConfirm,
                PreferencePaymentProcessedId: $actualField.dataset.preferencePaymentProcessed,
                PreferencePaymentDueId: $actualField.dataset.preferencePaymentDue,
                ChannelName: $actualField.dataset.channel,
                Value: _isUserInteraction ? $notificationCheckbox.checked : turnOn
            };

            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                function () {
                    successEditNotification(showMessage, turnOn);
                    if (_isUserInteraction) {
                        successEditNotificationTagging($actualField, $notificationCheckbox);
                    }
                },
                function() {
                    errorServiceCall(showMessage);
                },
                params,
                true,
                $formLoading
            );
        };
        
        /**
         * Service success handler for notification update
         * @param {Boolean} showMessage To show the toast or not.
         * @param {Boolean} turnOn To turn on/off the checkbox switch.
         */
        var successEditNotification = function (showMessage, turnOn) {
            hideErrors();
            
            // For user-interaction-set notifications
            if (showMessage) {
                showToast();
            // For code-set notifications
            } else {
                // Manually set the checkbox switch state accordingly
                $notificationCheckbox.checked = turnOn;
            }
        };

        /**
         * Tagging events for toggling the switches
         * @param {HTMLElement} $checkboxWrapper Checkbox wrapper containing the data attributes.
         * @param {HTMLElement} $checkbox Checkbox switch that was toggled.
         */
        var successEditNotificationTagging = function ($checkboxWrapper, $checkbox) {
            var channel = 
                    CONSTANTS.PERIOD_STRING + 
                    $checkboxWrapper.dataset.channel.toLowerCase(),
                state = $checkbox.checked ? 
                    CONSTANTS.PERIOD_STRING + CONSTANTS.ON_STRING : 
                    CONSTANTS.PERIOD_STRING + CONSTANTS.OFF_STRING;

            dataLayer.push({
                event: CONSTANTS.GENERAL_NOTIFICATION_TAG + channel + state
            });
        };

        /**
         * Service error handler for notification update
         * @param {Boolean} showMessage True or false, to show the error or not.
         */
        var errorServiceCall = function (showMessage) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.DEFAULT_SERVICE_ERROR, 
                    showMessage ? showError : function () {}, 
                    showMessage ? showError : function () {}, 
                    true
                );
            } else {
                if (showMessage) {
                    showError();
                }
            }
        };

        /**
         * Shows service error message
         */
        var showError = function () {
            // Reverts the checked action
            $notificationCheckbox.checked = !$notificationCheckbox.checked;

            var errorMsg = 
                $actualField.dataset.serviceError ?
                    $actualField.dataset.serviceError :
                    coned.constants.ERROR_MESSAGE;

            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = errorMsg;
        };
        
        /**
         * Hides service error message
         */
        var hideErrors = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = '';
        };

        /**
         * Trigger event to show the toast.
         */
        var showToast = function () {
            coned.utils.triggerEvent($toast, CONSTANTS.SHOW_TOAST_EVENT);
        };        

        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            $checksNotification = $notificationOnboarding.getElementsByClassName(
                CONSTANTS.NOTIFICATION_CHECK
            );
            $formLoading = $notificationOnboarding.getElementsByClassName(
                CONSTANTS.FORM_LOADING
            )[0];
            $toast = $notificationOnboarding.getElementsByClassName(
                CONSTANTS.TOAST
            )[0];
            $serviceError = $notificationOnboarding.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR
            )[0],
            $serviceErrorMessage = $notificationOnboarding.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $checkboxSwitchEmail = $notificationOnboarding.getElementsByClassName(
                CONSTANTS.NOTIFICATION_CHECK_EMAIL
            )[0];
            _isStandalonePage = 
                $notificationOnboarding.dataset.standalone === CONSTANTS.TRUE_STRING ?
                    CONSTANTS.TRUE_VALUE :
                    CONSTANTS.FALSE_VALUE;
        };

        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            // Edit notifications
            if ($checksNotification.length > 0) {
                Array.from($checksNotification).forEach(function ($checkNotification) {
                    coned.utils.addGeneralListeners($checkNotification, editNotification);
                });
            }

            // If it's a standalone page, email notification needs to be turned on on load.
            if (_isStandalonePage) {
                $checkboxSwitchEmail && editNotification(null, $checkboxSwitchEmail, true);
            // If it's not a standalone page, then this module lives inside another module like AddAccount or MultipleAccounts, 
            // so we wait until that parent triggers the event to turn on email notification.
            } else {
                $notificationOnboarding.addEventListener(CONSTANTS.TURN_ON_EMAIL_NOTIFICATION_EVENT, function() {    
                    $checkboxSwitchEmail && editNotification(null, $checkboxSwitchEmail, true);
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    NotificationOnboarding.prototype.isLoaded = function () {
        return isLoaded;
    };

    return NotificationOnboarding;
})();
