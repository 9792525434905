// ==================== WAS THIS HELPFUL COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.WasThisHelpfulComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HELPFUL_BTN_CLASS: 'js-helpful-button',
        HELPFUL_BTN_ACTIVE_CLASS: 'was-this-helpful__button--active',
        ARIA_PRESSED: 'aria-pressed'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var WasThisHelpfulComponent = function ($wasThisHelpfulElement) {
        /**
         * PRIVATE METHODS
         */
        var $helpfulBtnsElement;

        var HelpfulBtnEventClick = function (event) {
            event.preventDefault();

            var $helpfulBtnClicked = event.target;
            var $helpfulBtnNotClicked =
                event.target == $helpfulBtnsElement[0]
                    ? $helpfulBtnsElement[1] //thumbs down
                    : $helpfulBtnsElement[0]; // thumbs up

            query.removeClass($helpfulBtnsElement, CONSTANTS.HELPFUL_BTN_ACTIVE_CLASS);
            query.addClass($helpfulBtnClicked, CONSTANTS.HELPFUL_BTN_ACTIVE_CLASS);

            $helpfulBtnClicked.setAttribute(CONSTANTS.ARIA_PRESSED, 'true');
            $helpfulBtnNotClicked.setAttribute(CONSTANTS.ARIA_PRESSED, 'false');
        };

        var initializeData = function () {
            $helpfulBtnsElement = $wasThisHelpfulElement.getElementsByClassName(
                CONSTANTS.HELPFUL_BTN_CLASS
            );
        };

        var initializeEvents = function () {
            _.each($helpfulBtnsElement, function ($helpfulBtnElement) {
                coned.utils.addGeneralListeners($helpfulBtnElement, HelpfulBtnEventClick);
            });
        };

        var initializeAriaAttributes = function () {
            _.each($helpfulBtnsElement, function ($helpfulBtnElement) {
                $helpfulBtnElement.setAttribute(CONSTANTS.ARIA_PRESSED, 'false');
            });
        };
        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            initializeAriaAttributes();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    WasThisHelpfulComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return WasThisHelpfulComponent;
})();
