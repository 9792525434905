// ==================== ACCOUNT DROPDOWN COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AccountDropdownLoggedIn = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        DROPDOWN_ITEM: 'js-dropdown-item',
        DROPDOWN_ITEM_CONTENT: 'js-account-content',
        DROPDOWN_CARROT: 'js-dropdown-carrot',
        DROPDOWN_CHECKED_ITEM: 'js-dropdown-check',
        DROPDOWN_LIST_HIDE_CLASS: 'account-dropdown__list--hidden',
        DROPDOWN_CHECK_HIDE_CLASS: 'account-dropdown__option-check--hidden',
        DROPDOWN_CARROT_UP_CLASS: 'account-dropdown__icon--up',
        DROPDOWN_LIST_SCROLLABLE: 'account-dropdown__list--scrollable',
        DROPDOWN_REMOVE_BUTTON: 'account-dropdown__button--no-pointer',
        DROPDOWN_CONTAINER: 'js-dropdown-container',
        ADD_ACCOUNT_CONTAINER: 'js-add-account-container',
        ADD_ACCOUNT_SCROLLABLE: 'account-dropdown__option--scrollable',
        ACCOUNT_URL_PARAMETER: 'account=',
        ACCOUNT_URL_PARAMETER_NAME: 'account',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        PAGE_LOADING: 'js-page-loading',
        PAGE_LOADING_HIDDEN: 'page-loading--hidden',
        HIDDEN_CLASS: 'hidden',
        FILTERS_TO_KEEP: ['tab1', 'ThirdPartyId','client_id','scope','response_type','state','redirect_uri'],
        ARIA_EXPANDED_ATTRIBUTE: 'aria-expanded',
        ARIA_SELECTED_ATTRIBUTE: 'aria-selected',
        TEXT_SEARCH_INPUT_SELECTOR: 'js-text-search-input',
        PRIMARY_NAV_ITEM_BOTTOM_SECTION: 'js-primary-nav-item-bottom-section',
        ACCOUNT_BUTTON_DESKTOP: 'js-account-button-desktop',
        ACCOUNT_BUTTON_MOBILE: 'js-account-button-mobile',
        MOBILE_ELEMENTS_HEIGHT: 365
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AccountDropdownLoggedIn = function ($accountDropdown) {
        /**
         * PRIVATE METHODS
         */
        var $dropdownItems,
            $dropdownContent,
            $dropdownContainer,
            $dropdownCarrot,
            $dropdownItemsCheck,
            $addAccountContainer,
            $accountButtonDesktop,
            $accountButtonMobile,
            $accountMaidInput,
            $formLoading,
            $pageLoading,
            $textSearchInputSelector,
            _accountsLimit;

        /**
         * Select option in dropdown list using mouse or touch action.
         */
        var selectDropdownOptionOnClick = function (event) {
            event.preventDefault();

            var $item = query.selectParentElement(event.target, CONSTANTS.DROPDOWN_ITEM_CONTENT),
                $itemContainer = $item.parentElement;

            updatePageOnUserSelection($item, $itemContainer);
        };

        /**
         * Listbox option listener on keydown action
         */
        var handleKeyDownPressedOnListboxOption = function (event) {
            event.preventDefault();
            var keycode = event.keyCode;
            var isShiftPressed = event.shiftKey;

            if (isShiftPressed && keycode === coned.constants.KEY_CODE.TAB) {
                // Shift Tab Combination
                var $firstDropdownItem = $textSearchInputSelector;
                $firstDropdownItem.focus();
            } else if (keycode == coned.constants.KEY_CODE.ENTER) {
                var $itemContainer = event.target,
                    $item = $itemContainer.getElementsByClassName(
                        CONSTANTS.DROPDOWN_ITEM_CONTENT
                    )[0];
                updatePageOnUserSelection($item, $itemContainer);
            } else if (keycode == coned.constants.KEY_CODE.DOWN) {
                setFocusToNextItem(event.target);
            } else if (keycode == coned.constants.KEY_CODE.UP) {
                setFocusToPreviousItem(event.target);
            } else if (keycode == coned.constants.KEY_CODE.TAB) {
                if (coned.utils.isMobile()) {
                    $accountButtonMobile.focus();
                } else {
                    $accountButtonDesktop.focus();
                }
            }
        };

        var isHiddenItem = function (item) {
            return item && item.classList.contains(CONSTANTS.HIDDEN_CLASS); 
        };
        
        var searchNextNotHiddenItem = function (item) {
            if ( item && isHiddenItem(item)) {
                var nextItem = item.nextElementSibling;
                return searchNextNotHiddenItem(nextItem);
            }

            return item;
        };
        
        /**
          * Focus the next item in the listbox
          */
        var setFocusToNextItem = function (currentListboxOption) {
            var nextListboxOption = searchNextNotHiddenItem(currentListboxOption.nextElementSibling);
            if (nextListboxOption) {
                nextListboxOption.focus();
            } else if (isHiddenItem($dropdownItems[0])) {
                setFocusToNextItem($dropdownItems[0]);
            } else {
                $dropdownItems[0].focus(); // Circular list.
            }
        };

        var searchPreviousNotHiddenItem = function (item) {
            if (!item) {
                var lastIndex = $dropdownItems.length - 1;
                item = $dropdownItems[lastIndex];
            }

            if (isHiddenItem(item)) {
                var previousItem = item.previousElementSibling;
                return searchPreviousNotHiddenItem(previousItem);
            }

            return item;
        };

        /**
         * Focus the prev item in the listbox
         */
        var setFocusToPreviousItem = function (currentListboxOption) {
            var prevListboxOption = searchPreviousNotHiddenItem(currentListboxOption.previousElementSibling);
            if (prevListboxOption && prevListboxOption.classList.contains(CONSTANTS.DROPDOWN_ITEM)) {
                prevListboxOption.focus();
            } else {
                var lastIndex = $dropdownItems.length - 1;
                $dropdownItems[lastIndex].focus(); // Circular list.
            }
        };

        var updatePageOnUserSelection = function ($item, $itemContainer) {
            var $icon = $itemContainer.getElementsByClassName(CONSTANTS.DROPDOWN_CHECKED_ITEM)[0];

            // Hide check from all options
            query.addClass($dropdownItemsCheck, CONSTANTS.DROPDOWN_CHECK_HIDE_CLASS);

            $dropdownContent.innerHTML = $item.innerHTML;
            $icon.classList.remove(CONSTANTS.DROPDOWN_CHECK_HIDE_CLASS);

            // Get the current selected item and remove it in order to update to a new selection
            var $currentSelectedOption = $dropdownContainer.querySelectorAll(
                '[aria-selected="true"]'
            )[0]; 
            if ($currentSelectedOption) {
                $currentSelectedOption.setAttribute(CONSTANTS.ARIA_SELECTED_ATTRIBUTE, false);
            }

            // Set aria-selected attribute to the new selected option.
            $itemContainer.setAttribute(CONSTANTS.ARIA_SELECTED_ATTRIBUTE, true);

            //Handle page redirection with user selection
            var updatedPath = getUrlWithParameters(CONSTANTS.FILTERS_TO_KEEP);
            updatedPath = addFilterToPath(
                $itemContainer.dataset.account,
                CONSTANTS.ACCOUNT_URL_PARAMETER_NAME,
                CONSTANTS.ACCOUNT_URL_PARAMETER,
                updatedPath
            );

            if($accountMaidInput) {
                $accountMaidInput.value = $itemContainer.dataset.account;
            }           

            query.fireEvent($accountDropdown, 'change', {
                account: $itemContainer.dataset.account
            });

            if (coned.utils.isPatternLab()) {
                var pathname = updatedPath.replace(window.location.origin, '');

                window.history.pushState({ page: updatedPath }, '', pathname);
            } else {
                $pageLoading.classList.remove(CONSTANTS.PAGE_LOADING_HIDDEN);
                window.location.href = updatedPath;
            }
        };

        var addFilterToPath = function (filterValue, filterParameterName, filterParameter, path) {
            if (path === undefined) {
                path = window.location.href;
            }
            var urlSeparator = path.indexOf('?') === -1 ? '?' : '&';

            if (filterValue !== '') {
                if (path.split['?'] > 1 && path.split['?'][1].includes(filterParameterName)) {
                    path = coned.utils.updateAnchorParameter(
                        path,
                        filterParameterName,
                        filterValue
                    );
                } else {
                    path += urlSeparator + filterParameter + filterValue;
                }
            } else {
                if (window.location.search.includes(filterParameterName)) {
                    path = coned.utils.deleteAnchorParameter(path, filterParameterName);
                }
            }

            return path;
        };

        var getUrlWithParameters = function (parametersToKeep) {
            var newUrl = window.location.href,
                queryString = newUrl.split('?')[1],
                parameters,
                parameterName;

            if (parametersToKeep === undefined) {
                parametersToKeep = [];
            }

            if (queryString) {
                parameters = queryString.split('&');

                _.each(parameters, function (parameter) {
                    parameterName = parameter.split('=')[0];
                    if (!parametersToKeep.includes(parameterName)) {
                        newUrl = coned.utils.deleteAnchorParameter(newUrl, parameterName);
                    }
                });
            }

            if (newUrl.substr(-1) === '?') {
                newUrl = newUrl.substring(0, newUrl.length - 1);
            }

            return newUrl;
        };

        var setAccountScroll = function () {
            var accountItemHeight = $dropdownItems[0].offsetHeight,
                accountContainerHeight;

            /* please notice that this hardcoded value of 53 was set for mobile,
                if the height of the item changes for mobile this value needs to be recalulated.
            */
            if (accountItemHeight === 0) {
                accountItemHeight = 53;
            }
            
            $dropdownContainer.classList.add(CONSTANTS.DROPDOWN_LIST_SCROLLABLE);
            $addAccountContainer && $addAccountContainer.classList.add(CONSTANTS.ADD_ACCOUNT_SCROLLABLE);
            
            if(coned.utils.isDesktop()) {
                accountContainerHeight = accountItemHeight * _accountsLimit - 18;
                $dropdownContainer.style.height = accountContainerHeight + 'px';
            } else {
                var mobileAccountContainerHegiht = window.innerHeight - CONSTANTS.MOBILE_ELEMENTS_HEIGHT;
                $dropdownContainer.style.height = mobileAccountContainerHegiht + 'px';
            }
        };

        var initializeData = function () {
            $dropdownItems = $accountDropdown.getElementsByClassName(CONSTANTS.DROPDOWN_ITEM);
            $dropdownContent = $accountDropdown.getElementsByClassName(
                CONSTANTS.DROPDOWN_ITEM_CONTENT
            )[0];
            $dropdownCarrot = $accountDropdown.getElementsByClassName(CONSTANTS.DROPDOWN_CARROT)[0];
            $dropdownItemsCheck = $accountDropdown.getElementsByClassName(
                CONSTANTS.DROPDOWN_CHECKED_ITEM
            );
            $dropdownContainer = $accountDropdown.getElementsByClassName(
                CONSTANTS.DROPDOWN_CONTAINER
            )[0];
            $addAccountContainer = document.getElementsByClassName(
                CONSTANTS.ADD_ACCOUNT_CONTAINER
            )[0];
            $accountButtonDesktop = document.getElementsByClassName(
                CONSTANTS.ACCOUNT_BUTTON_DESKTOP
            )[0];
            $accountButtonMobile = document.getElementsByClassName(
                CONSTANTS.ACCOUNT_BUTTON_MOBILE
            )[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $pageLoading = document.getElementsByClassName(CONSTANTS.PAGE_LOADING)[0];
            $textSearchInputSelector = $accountDropdown.getElementsByClassName(
                CONSTANTS.TEXT_SEARCH_INPUT_SELECTOR
            )[0];
            _accountsLimit = $accountDropdown.dataset.accountsLimit;
        };

        var initializeEvents = function () {
            if ($dropdownItems.length === 0) {
                if ($dropdownCarrot) {
                    $dropdownCarrot.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                for (var index = 0; index < $dropdownItems.length; index++) {
                    coned.utils.addGeneralListeners(
                        $dropdownItems[index],
                        selectDropdownOptionOnClick
                    );
                    $dropdownItems[index].addEventListener(
                        'keydown',
                        handleKeyDownPressedOnListboxOption
                    );
                }

                if ($dropdownItems.length > _accountsLimit) {
                    setAccountScroll();
                }
            }

            // Trying to solve dashboard issues, loading will be displayed until this point
            if($formLoading) {
                $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     AccountDropdownLoggedIn.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AccountDropdownLoggedIn;
})();
