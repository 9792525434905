// ==================== TRANSFER SERVICE COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.TransferService = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        TRANSFER_SERVICE_SELECTOR: 'js-transfer-service',
        TRANSFER_FORM: '.js-transfer-service-form',
        TRANSFER_FORM_CLASS: 'js-transfer-service-form',
        TRANSFER_SERVICE_START_SELECTOR: 'js-transfer-service-start',
        ADDRESS_BUTTON_RECEPTOR: 'js-dropdown-button-receptor',
        ADDRESS_LOOKUP_FORM: 'js-address-lookup',
        AUTOCOMPLETE_APARMENT_UNIT: 'js-autocomplete-apartment-unit',
        AUTOCOMPLETE_SELECTED_ADDRESS_DETAIL: 'js-address-selected-detail',
        CURRENT_ADDRESS_TITLE: 'js-current-address-title',
        MOVING_ADDRESS_1: 'js-moving-address1',
        MOVING_ADDRESS_2: 'js-moving-address2',
        ADDRESS_CONTAINER_CLASS: 'address-box__container',
        ADDRESS_CURRENT_DATE_CONTAIN: 'js-current-datapicker-contain',
        ADDRESS_NEXT_DATE_CONTAIN: 'js-next-datapicker-contain',
        ADDRESS_NEXT_DATE_CLASS: '.js-next-datapicker-contain',
        ADDRESS_CURRENT_DATE_RECEPTOR: 'js-current-datapicker-receptor',
        ADDRESS_NEXT_DATE_RECEPTOR: 'js-next-datapicker-receptor',
        EMAIL_RECEPTOR: 'js-email-receptor',
        PHONE_RECEPTOR: 'js-phone-receptor',
        PHONE_TYPE_SELECT: 'js-phone-type-select',
        PHONE_TITLE_RECEPTOR: 'js-phone-title-receptor',
        EDIT_ACCOUNT_INFORMATION: 'js-edit-account-information',
        NEXT_BUTTON: 'js-next-button',
        FINISH_BUTTON: 'js-finish-button',
        DATE_PICKER_INPUT_CLASS: '.js-start-date',
        ACCOUNT_PROFILE_SELECTOR: 'js-account-profile',
        STEPS_LIST: 'js-steps-list',
        STEP_FINISH: 'js-form-finish',
        STEP_FINISH_ACTIVE: 'form-progress-bar__finish--active',
        STEP_PROGRESS_BAR: 'js-form-progress-bar',
        FINISH_BAR_CLASS: 'form-progress-bar--finish',
        FINISH_STEP_SELECTOR: 'js-finish-message',
        ANIMATION_SUBMIT_MODULE: 'js-submit-progress-animation',
        ADDRESS_STEP_SELECTOR: 'js-address-step',
        VERIFY_DATE_STEP_SELECTOR: 'js-verify-date-step',
        ACCOUNT_PROFILE_STEP_SELECTOR: 'js-account-profile-step',
        DIRECT_PAYMENT_STEP_SELECTOR: 'js-step-direct-pay',
        LIFE_SUPPORT_STEP_SELECTOR: 'js-step-life-support',
        DISABILITY_STEP_SELECTOR: 'js-step-disability',
        REVIEW_STEP_SELECTOR: 'js-step-review',
        TRANSFER_DATE_ERROR_SELECTOR: 'js-transfer-date-service-error',
        TRANSFER_STOP_DATE_ERROR_SELECTOR: 'js-transfer-stop-date-service-error',
        TRANSFER_SERVICE_ERROR_SELECTOR: 'js-transfer-service-error',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        FORM_COMPANY_CODE_NAME: 'companyCode',
        FORM_ADDRESS_SCID_NAME: 'AddressScId',
        FORM_START_DATE_SCID_NAME: 'DateScId',
        FORM_ACCOUNT_PROFILE_SCID_NAME: 'AccountProfileScId',
        FORM_DIRECT_PAYMENT_SCID_NAME: 'DirectPaymentScId',
        FORM_LIFE_SUPPORT_SCID_NAME: 'LifeSupportScId',
        FORM_DISABILITY_SCID_NAME: 'DisabilityScId',
        FORM_ZIPCODE_NAME: 'transferServiceZipCode',
        FORM_STREET_NAME_NAME: 'streetName',
        FORM_STREET_NUMBER_NAME: 'streetNumber',
        FORM_RESIDENCE_UNIT_NAME: 'residenceUnit',
        FORM_END_DATE_NAME: 'transferServiceEndDate',
        FORM_START_DATE_NAME: 'transferServiceStartDate',
        FORM_EMAIL_NAME: 'emailAdress',
        FORM_PHONE_TYPE_NAME: 'phoneType',
        FORM_PHONE_NUMBER_NAME: 'phoneNumber',
        FORM_MAILING_ADDRESS_NAME: 'mailingAddress',
        FORM_MAILING_NAME_NAME: 'mailingName',
        FORM_MAILING_ADDRESS_ONE_NAME: 'mailingAddressOne',
        FORM_MAILING_CITY_NAME: 'mailingCity',
        FORM_MAILING_STATE_NAME: 'mailingState',
        FORM_MAILING_ZIPCODE_NAME: 'mailingZipCode',
        FORM_EBILL_ENROLL_NAME: 'ebillEnroll',
        FORM_DIRECT_PAYMENT_ENROLL_NAME: 'directPaymentEnroll',
        FORM_LIFE_SUPPORT_NAME: 'lifeSupportEnroll',
        FORM_DISABILITY_NAME: 'disabilityEnroll',
        FORM_DP_ACCOUNT_TYPE_NAME: 'accountType',
        FORM_DP_ROUTING_NUMBER_NAME: 'routingAbaNumber',
        FORM_DP_ACCOUNT_NUMBER_NAME: 'accountNumber',
        FORM_LS_TANK_RESPIRATOR_ID: 'tankRespirator',
        FORM_LS_CUIRASS_RESPIRATOR_ID: 'cuirassRespirator',
        FORM_LS_ROCKING_RESPIRATOR_ID: 'rockingRespirator',
        FORM_LS_ELECTRICAL_RESPIRATOR_ID: 'electricalRespirator',
        FORM_LS_APNEA_MONITOR_ID: 'apneaMonitor',
        FORM_LS_HEMODIALYSIS_ID: 'hemodialysis',
        FORM_LS_OTHER_NAME: 'lifeSupportOther',
        FORM_LS_DAYS_NAME: 'lifeSupportDays',
        FORM_LS_HOURS_NAME: 'lifeSupportHours',
        FORM_LS_EQUIPMENT_SLEEPING_NAME: 'equipmentSleeping',
        FORM_LS_HOURS_PER_DAY_NAME: 'lifeSupportHoursPerDay',
        FORM_DISABILITY_AGE_62_ID: 'age62',
        FORM_DISABILITY_BLIND_ID: 'blind',
        FORM_DISABILITY_PERMANENTLY_DISABLED_ID: 'permanentlyDisabled',
        FORM_DISABILITY_UNDER_AGE_18_ID: 'underAge18',
        FORM_DISABILITY_OTHER_NOTIFIED_NAME: 'otherNotified',
        FORM_DISABILITY_FIRST_NAME_NAME: 'disabilityFirstName',
        FORM_DISABILITY_LAST_NAME_NAME: 'disabilityLastName',
        FORM_DISABILITY_STREET_ADDRESS_NAME: 'disabilityStreetAddress',
        FORM_DISABILITY_CITY_NAME: 'disabilityCity',
        FORM_DISABILITY_STATE_NAME: 'disabilityState',
        FORM_DISABILITY_ZIPCODE_NAME: 'disabilityZipCode',
        FORM_DISABILITY_DAY_TIME_NUMBER_NAME: 'dayTimeNumber',
        FORM_DISABILITY_NIGHT_TIME_NUMBER_NAME: 'nightTimeNumber',
        HIDDEN_CLASS: 'hidden',
        INPUT_VALIDATE: 'js-item-validate',
        VALIDATE_IGNORE: 'js-validate-ignore',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        ACCOUNT_DROPDOWN_ITEMS: 'js-dropdown-item',
        LOOKUP_BUTTON: 'js-lookup-button',
        VALIDATE_BY_ADDRESS_ERROR: 'js-check-availability-current-service-error',
        VALIDATE_BY_RESIDENCE_UNIT_ERROR: 'js-check-availability-service-error',
        DROPDOWN_BUTTON: 'js-dropdown-button-contain',
        FORM_STEP: 'js-form-step',
        FORM_CURRENT_STEP: 'js-current-step',
        FORM_STEP_ACTIVE: 'form-progress-bar__item--active',
        DROPDOWN_LABEL: 'js-coned-select-label',
        ACTIVE_DROPDOWN: 'coned-select--active',
        IS_FORM_BLOCKED: 'js-form-blocked',
        DATE_PICKER_INPUT: 'js-current-datepicker-input',
        DATE_PICKER_NEXT_DATE: 'js-next-datepicker-input',
        DEFAULT_SUCCESS_MESSAGE: 'js-default-success-message',
        SUCCESS_BANNER_DATE: 'js-banner-date',
        SUCCESS_DATE_TOKEN: '{{DATE}}',
        SUCCESS_EMAIL_TOKEN: '{{EMAIL}}',
        EMAIL_INPUT_CLASS: 'js-transfer-email',
        PHONE_TYPE_SELECT_CLASS: 'js-phone-type-select',
        PHONE_INPUT_CLASS: 'js-transfer-phone',
        PROFILE_SUBMIT_BUTTON: 'js-transactional-extra-help',
        CONED_RADIO_SELECTOR: 'js-coned-radio',
        INPUT_EVENT: 'input',
        CHANGE_EVENT: 'change',
        FILLED_CLASS: 'coned-input--filled',
        DISABLED_ATTRIBUTE: 'disabled',
        ACCOUNT_TYPE_COMMERCIAL: 'C',
        ACCOUNT_TYPE_RESIDENTIAL: 'R',
        HEADER: 'js-header-wrapper',
        HAS_ELECTRICITY_COLD_AMI: 'js-has-electricity-cold-ami',
        TRANSFER_SERVICE_FINISH_MAIN: 'js-transfer-service-finish-main',
        TABINDEX: 'tabindex',

        // Live Chat
        LIVE_CHAT_STOP_AVAILABLE_SELECTOR: 'js-live-chat-stop-available',
        LIVE_CHAT_STOP_ERROR_MESSAGE: 'js-live-chat-stop-error-message',
        LIVE_CHAT_STOP_AVAILABLE_MESSAGE: 'js-live-chat-stop-available-message',
        LIVE_CHAT_STOP_BUTTON: 'js-live-chat-stop-cta',
        LIVE_CHAT_START_AVAILABLE_SELECTOR: 'js-live-chat-start-available',
        LIVE_CHAT_START_ERROR_MESSAGE: 'js-live-chat-start-error-message',
        LIVE_CHAT_START_AVAILABLE_MESSAGE: 'js-live-chat-start-available-message',
        LIVE_CHAT_START_BUTTON: 'js-live-chat-start-cta',
        INIT_CHAT:  'init',
        STOP_CHAT: 'stop',
        STOP_LIVE_CHAT_ON_PARAM: 'stopLiveChatOn',
        START_LIVE_CHAT_ON_PARAM: 'startLiveChatOn',
        TAGGING_LIVE_CHAT_STOP:'current.address',
        TAGGING_LIVE_CHAT_START:'new.address',
        TAGGING_LIVE_CHAT_DISPLAYS:'flow.address.error',
        TAGGING_LIVE_CHAT_EVENT:'live-chat-tagging',

        // Tagging
        TAGGING_PHONE_TYPE: 'enter.phone.type',
        TAGGING_ZIPCODE_ERROR: 'transferService.zipcode.error',
        TAGGING_ADDRESS_CHANGE: ''
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var TransferService = function ($transferService) {
        /**
         * PRIVATE METHODS
         */
        var $transferForm,
            $transferServiceForm,
            $transferServiceStart, 
            $transferServiceFinishMain,
            $addressCurrentButtonReceptor,
            $movingAddress1,
            $movingAddress2,
            $addressLookupForm,
            $autocompleteAparmentUnitInput,
            $autocompleteAddressSelectedDetail,
            $currentAddressTitle,
            $addressCurrentDateContain,
            $addressNextDateContain,
            $addressCurrentDateReceptor,
            $addressNextDateReceptor,
            $phoneReceptor,
            $editAccountInformation,
            $nextButtons,
            $stepsList,
            $finishStep,
            $progressBar,
            $finishStepSelector,
            $accountProfile,
            $emailReceptor,
            $addressStepSelector,
            $verifyDateStepSelector,
            $accountProfileStepSelector,
            $directPaymentStepSelector,
            $lifeSupportStepSelector,
            $disabilityStepSelector,
            $reviewStepSelector,
            $transferDateServiceError,
            $transferStopDateServiceError,
            $transferServiceError,
            $formLoading,
            $submitAnimationButton,
            $lookupButton,
            $dropdownButton,
            $accountDropDownItems,
            $checkAvailabilityError,
            $datePickerInput,
            $phoneTitleReceptor,
            $phoneTypeSelect,
            $successBannerDate,
            $profileSubmitButton,
            $email,
            $phone,
            $header,
            $datePickertNextDate,
            $currentAddressError,
            $hasElectricityColdAmi,
            $electricityColdAmiSuccessMessage,
            $liveChatStopErrorMessage,
            $liveChatStopAvailableMessage,
            $liveChatStopAvailable,
            $liveChatStartErrorMessage,
            $liveChatStartAvailableMessage,
            $liveChatStartAvailable,
            _liveChatAvailable,
            _liveChatStopTagging,
            _liveChatStartTagging,
            _stopLiveChatOn,
            _startLiveChatOn,
            _data,
            _account,
            _accountId,
            _checkAvailability,
            _isCurrentAddressValid,
            _isResidenceUnitValid,
            _activeSteps,
            _isStart,
            _premiseNumber,
            _isEletricityColdAmi,
            _startServiceMaid,
            _stopServiceMaid,
            _stopPremise;

        var formStatus = function (isBlocked) {
            var $currentStep = document.getElementsByClassName(CONSTANTS.FORM_CURRENT_STEP)[0];

            if (isBlocked) {
                $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
            } else {
                $currentStep.classList.remove(CONSTANTS.IS_FORM_BLOCKED);
            }
        };

        var checkAvailability = function (_account, isStartService) {
            var serviceUrl = $addressStepSelector.dataset.checkAvailabilityService,
                formSteps = document.getElementsByClassName(CONSTANTS.FORM_STEP),
                params;

            _isStart = isStartService;
            currentNewAddressStatus();

            // disable all steps on residence change
            _.each(formSteps, function (step) {
                if (
                    !query.hasClass(step, CONSTANTS.FORM_CURRENT_STEP) &&
                    query.hasClass(step, CONSTANTS.FORM_STEP_ACTIVE)
                ) {
                    step.classList.remove(CONSTANTS.FORM_STEP_ACTIVE);
                    _activeSteps.push(step);
                }
            });

            params = {
                ScId: query.getFormInputValue(
                    $addressStepSelector,
                    CONSTANTS.FORM_ADDRESS_SCID_NAME
                ),
                StartServiceMaid: _startServiceMaid,
                StopServiceMaid: _stopServiceMaid,
                PremiseNumber: _premiseNumber,
                IsStartService: _isStart,
                IsTransferService: true
            };

            // Adding testing scenarios for live chat option in Pattern Lab
            if (coned.utils.isPatternLab()) {

                if(_isStart && _startLiveChatOn && _startLiveChatOn === 'true') {
                    // Show live chat message for transfer start service section when startLiveChatOn=true query parameter is added
                    // Service Call
                    params = JSON.stringify(params);
                    query.postData(
                        serviceUrl,
                        failCheckAvailability,
                        failCheckAvailability,
                        params,
                        true,
                        $formLoading
                    );

                } else if(!_isStart && _stopLiveChatOn && _stopLiveChatOn === 'true') {
                    // Show live chat message for transfer stop service section when stopLiveChatOn=true query parameter is added
                    // Service Call
                    params = JSON.stringify(params);
                    query.postData(
                        serviceUrl,
                        failCheckAvailability,
                        failCheckAvailability,
                        params,
                        true,
                        $formLoading
                    );
                } else {
                    // Service Call
                    params = JSON.stringify(params);
                    query.postData(
                        serviceUrl,
                        checkAvailabilityValidation,
                        failCheckAvailability,
                        params,
                        true,
                        $formLoading
                    );
                }

            } else {
                // Service Call
                params = JSON.stringify(params);
                query.postData(
                    serviceUrl,
                    checkAvailabilityValidation,
                    failCheckAvailability,
                    params,
                    true,
                    $formLoading
                );
            }

            if (_isStart && !_isResidenceUnitValid) {
                query.scrollToElement($currentAddressTitle, $header);
                $currentAddressError.focus();
            }
        };

        var checkAvailabilityValidation = function (data) {
            if (coned.utils.isPatternLab()) {
                var tomorrow = new Date(),
                    dateNextMonth = new Date();

                // Set dates
                tomorrow.setDate(tomorrow.getDate() + 1);
                dateNextMonth.setUTCMonth(dateNextMonth.getUTCMonth() + 1);

                data = {
                    MinDate: tomorrow.toJSON().replace(/-/g, '/').slice(0, 10),
                    MaxDate: dateNextMonth.toJSON().replace(/-/g, '/').slice(0, 10)
                };

                data.ServiceAddress = {
                    City: 'NEW YORK',
                    State: 'NY',
                    UnitNumber: '11C'
                };

                data.AccountNumber = '123456789012345';

                if (_account === '700935004000055' || _account === '12345678901') {
                    data.AccountType = CONSTANTS.ACCOUNT_TYPE_COMMERCIAL;
                } else {
                    data.AccountType = CONSTANTS.ACCOUNT_TYPE_RESIDENTIAL;
                }

                if(_isEletricityColdAmi) { 
                    data.IsElectricityColdAmi = true;
                }
            }

            successCheckAvailability(data);
        };

        var validateByAddress = function (event) {
            if (event) {
                event.preventDefault();

                _account = event.currentTarget.children[0].dataset.account;
            } else {
                _account = $dropdownButton.dataset.account;
            }

            $checkAvailabilityError = $transferForm.getElementsByClassName(
                CONSTANTS.VALIDATE_BY_ADDRESS_ERROR
            )[0];
            _stopServiceMaid = _account;
            checkAvailability(_account, false);
        };

        var validateByResidenceUnit = function () {
            _account = $autocompleteAddressSelectedDetail.dataset.maid;
            _premiseNumber = $autocompleteAddressSelectedDetail.dataset.premiseNumber;
            $checkAvailabilityError = $transferForm.getElementsByClassName(
                CONSTANTS.VALIDATE_BY_RESIDENCE_UNIT_ERROR
            )[0];
            _startServiceMaid = _account;
            checkAvailability(_account, true);
        };

        var successCheckAvailability = function (data) {
            var isCommercial = data.AccountType === CONSTANTS.ACCOUNT_TYPE_COMMERCIAL,
                comercialErrorMsg = $addressStepSelector.dataset.comercialAccountErrorMsg,
                accountPremise = $dropdownButton.dataset.premise;

            if (_isStart) {
                // if the minDate is empty set the actual date as minDate
                changeMinStartDate(data.MinDate);
                _isCurrentAddressValid = !isCommercial;

                if (data.IsElectricityColdAmi) {
                    _isEletricityColdAmi = true;
                    $hasElectricityColdAmi = $transferService.getElementsByClassName(CONSTANTS.HAS_ELECTRICITY_COLD_AMI);
                    $electricityColdAmiSuccessMessage = $finishStepSelector.getElementsByClassName(CONSTANTS.HAS_ELECTRICITY_COLD_AMI);
    
                    _.each($hasElectricityColdAmi, function ($hasElectricityColdAmiItem) {
                        $hasElectricityColdAmiItem.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    });
    
                    _.each($electricityColdAmiSuccessMessage, function ($hasElectricityColdAmiItem) {
                        $hasElectricityColdAmiItem.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    });
    
                    //Tagging
                    dataLayer.push({
                        ElectricityAMI: true
                    });
    
                } else {
                    _isEletricityColdAmi = false;
                    $hasElectricityColdAmi = $transferService.getElementsByClassName(CONSTANTS.HAS_ELECTRICITY_COLD_AMI);
                    $electricityColdAmiSuccessMessage = $finishStepSelector.getElementsByClassName(CONSTANTS.HAS_ELECTRICITY_COLD_AMI);
    
                    _.each($hasElectricityColdAmi, function ($hasElectricityColdAmiItem) {
                        $hasElectricityColdAmiItem.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });
    
                    _.each($electricityColdAmiSuccessMessage, function ($hasElectricityColdAmiItem) {
                        $hasElectricityColdAmiItem.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });
    
                    //Tagging
                    dataLayer.push({
                        ElectricityAMI: false
                    });
                }
                
            } else {
                _isResidenceUnitValid = !isCommercial;
                _checkAvailability = true;
                setDatesLimitEndDate(data.MinDate, data.MaxDate);
            }

            if (isCommercial) {
                failCheckAvailability({ errorMsg: comercialErrorMsg });
                $transferServiceStart.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                $checkAvailabilityError.classList.add(CONSTANTS.HIDDEN_CLASS);

                //Stop live chat if the account is eligible
                if (_liveChatAvailable && _liveChatAvailable === 'true') {
                    if(_isStart) {
                        //Stop the start service live chat button
                        var $startLiveChatAvailableBtn = $liveChatStartAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_START_BUTTON)[0];
                        coned.utils.triggerEvent($startLiveChatAvailableBtn, CONSTANTS.STOP_CHAT);
                    } else {
                        //Stop the stop service live chat button
                        var $stopLiveChatAvailableBtn = $liveChatStopAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_STOP_BUTTON)[0];
                        coned.utils.triggerEvent($stopLiveChatAvailableBtn, CONSTANTS.STOP_CHAT);
                    }
                }
            }

            currentNewAddressStatus();

            if (data.AccountNumber && _isStart) {
                _accountId = data.AccountNumber;
            } 
            
            _premiseNumber = $autocompleteAddressSelectedDetail.dataset.premiseNumber;
            _stopPremise = accountPremise ? accountPremise : data.PremiseNumber;
            $dropdownButton.dataset.premise = _stopPremise

            // check if form already advance and set back class for active steps
            _.each(_activeSteps, function (step) {
                step.classList.add(CONSTANTS.FORM_STEP_ACTIVE);
            });

            //Show the start service selection if hidden
            if (!isCommercial) {
                $transferServiceStart.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }


        };

        var setDatesLimitEndDate = function (minDate, maxDate) {
            var minimunDate = new Date(minDate),
                maximunDate = new Date(maxDate);

            minimunDate.setDate(minimunDate.getDate());
            maximunDate.setDate(maximunDate.getDate());

            minimunDate = minimunDate.toJSON().replace(/-/g, '/').slice(0, 10);
            maximunDate = maximunDate.toJSON().replace(/-/g, '/').slice(0, 10);

            // set input validation rules
            $datePickerInput.dataset.minDateDays = minimunDate;
            $datePickerInput.dataset.maxDateDays = maximunDate;

            // set the date picker dates limit
            setTimeout(function () {
                $($datePickerInput).datepicker('option', 'minDate', new Date(minimunDate));
                $($datePickerInput).datepicker('option', 'maxDate', new Date(maximunDate));
            }, 1);
        };

        var changeMinStartDate = function (minDate) {
            if (!minDate) {
                var minimunDate = new Date();
                minimunDate.setDate(minimunDate.getDate());
                minimunDate = minimunDate.toJSON().replace(/-/g, '/').slice(0, 10);
                $datePickertNextDate.dataset.minDateDays = minimunDate;

                setTimeout(function () {
                    $($datePickertNextDate).datepicker('option', 'minDate', new Date(minimunDate));
                    $datePickertNextDate.nextSibling.disabled = true;
                }, 1);
            } else {
                minDate = minDate.replace(/-/g, '/').slice(0, 10);
                $datePickertNextDate.dataset.minDateDays = minDate;
                setTimeout(function () {
                    $($datePickertNextDate).datepicker('option', 'minDate', new Date(minDate));
                    $datePickertNextDate.nextSibling.disabled = true;
                }, 1);
            }
        };

        var changeMaxStartDate = function (maxDate) {
            var maximunDate = new Date(maxDate);

            maximunDate.setDate(maximunDate.getDate());

            maximunDate = maximunDate.toJSON().replace(/-/g, '/').slice(0, 10);

            // set input validation rules
            $datePickertNextDate.dataset.maxDateDays = maximunDate;

            // set the date picker dates limit
            setTimeout(function () {
                $($datePickertNextDate).datepicker('option', 'maxDate', new Date(maximunDate));
            }, 1);
        };

        var failCheckAvailability = function (data) {
            var $msgTextSelector = $checkAvailabilityError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            if (_liveChatAvailable && _liveChatAvailable === 'true') {
                if(_isStart) {
                    //Initialize the start service live chat button
                    var $startLiveChatAvailableBtn = $liveChatStartAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_START_BUTTON)[0];
                    coned.utils.triggerEvent($startLiveChatAvailableBtn, CONSTANTS.INIT_CHAT);

                    if (data && data.errorMsg && data.liveChatAvailableMessage) {
                        $liveChatStartErrorMessage.innerHTML = data.errorMsg;
                        $liveChatStartAvailableMessage.innerHTML = data.liveChatAvailableMessage;
                    }

                    //This event listen for the first time the chat appears to be triggered and add tagging
                    $startLiveChatAvailableBtn.addEventListener(CONSTANTS.TAGGING_LIVE_CHAT_EVENT, function(){
                        if(_liveChatStartTagging) {
                            dataLayer.push({
                                event: CONSTANTS.TAGGING_LIVE_CHAT_DISPLAYS,
                                'transfer.step.error': CONSTANTS.TAGGING_LIVE_CHAT_START
                            });
                            _liveChatStartTagging = false;
                        }
                    });
                } 
                else {
                    //Initialize the stop service live chat button
                    var $stopLiveChatAvailableBtn = $liveChatStopAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_STOP_BUTTON)[0];
                    coned.utils.triggerEvent($stopLiveChatAvailableBtn, CONSTANTS.INIT_CHAT);

                    if (data && data.errorMsg && data.liveChatAvailableMessage) {
                        $liveChatStopErrorMessage.innerHTML = data.errorMsg;
                        $liveChatStopAvailableMessage.innerHTML = data.liveChatAvailableMessage;
                    }                    

                    //This event listen for the first time the chat appears to be triggered and add tagging
                    $stopLiveChatAvailableBtn.addEventListener(CONSTANTS.TAGGING_LIVE_CHAT_EVENT, function(){
                        if(_liveChatStopTagging) {
                            dataLayer.push({
                                event: CONSTANTS.TAGGING_LIVE_CHAT_DISPLAYS,
                                'transfer.step.error': CONSTANTS.TAGGING_LIVE_CHAT_STOP
                            });
                            _liveChatStopTagging = false;
                        }
                    });
                }

            } else {            
                _checkAvailability = _isStart ? _checkAvailability : false;
                $msgTextSelector.innerHTML = data.errorMsg
                    ? data.errorMsg
                    : coned.constants.ERROR_MESSAGE;
                $checkAvailabilityError.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $checkAvailabilityError.focus();
            }

            if(!_isStart) { 
                //Hide the start service section when the account is not eligible
                $transferServiceStart.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
            
            $lookupButton.disabled = true;

        };

        var currentNewAddressStatus = function () {
            if (_isCurrentAddressValid && _isResidenceUnitValid && _checkAvailability) {
                $lookupButton.disabled = false;
                formStatus();
            } else {
                $lookupButton.disabled = true;
            }
        };

        var verifyStopDate = function (event) {
            var $target = event.target,
                targetValue = event.target.value,
                validator = $(CONSTANTS.TRANSFER_FORM).validate(),
                dateStatus = validator.element($target),
                serviceUrl = $verifyDateStepSelector.dataset.verifyDateServiceUrl;

            if (targetValue != '') {
                changeMaxStartDate(targetValue);
            }

            if (!dateStatus) return;

            $target.disabled = true;
            hideError($transferStopDateServiceError);

            // Service Data
            var params = {
                ScStartDateId: query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_START_DATE_SCID_NAME
                ),
                ScServiceAddressId: query.getFormInputValue(
                    $addressStepSelector,
                    CONSTANTS.FORM_ADDRESS_SCID_NAME
                ),
                StartServiceMaid: _startServiceMaid,
                StopServiceMaid: _stopServiceMaid,
                PremiseNumber: _premiseNumber,
                StopServiceDate: $target.value,
                StartServiceDate: ''
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successStopVerifyDate,
                failStopVerifyDate,
                params,
                true,
                $formLoading
            );
        };

        var successStopVerifyDate = function () {
            $datePickerInput.disabled = false;
            $datePickerInput.focus();
            formStatus();
        };

        var failStopVerifyDate = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.VERIFY_DATE_ERROR,
                    errorStopVerifyDate,
                    errorStopVerifyDate
                );
            } else {
                errorStopVerifyDate(data);
            }
        };

        var errorStopVerifyDate = function (data) {
            var $msgTextSelector = $transferStopDateServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $transferStopDateServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $datePickerInput.value = '';
            $datePickerInput.classList.remove(CONSTANTS.FILLED_CLASS);
            $datePickerInput.disabled = false;
            $datePickerInput.focus();
            formStatus(true);
        };

        var verifyDate = function (event) {
            var $target = event.target,
                validator = $(CONSTANTS.TRANSFER_FORM).validate(),
                dateStatus = validator.element($target),
                serviceUrl = $verifyDateStepSelector.dataset.verifyDateServiceUrl;

            if (!dateStatus) return;

            $target.disabled = true;
            hideError($transferDateServiceError);

            // Service Data
            var params = {
                ScStartDateId: query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_START_DATE_SCID_NAME
                ),
                ScServiceAddressId: query.getFormInputValue(
                    $addressStepSelector,
                    CONSTANTS.FORM_ADDRESS_SCID_NAME
                ),
                PremiseNumber: _premiseNumber,
                StartServiceMaid: _startServiceMaid,
                StartServiceDate: $target.value,
                StopServiceDate: query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_END_DATE_NAME
                )
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successVerifyDate,
                failVerifyDate,
                params,
                true,
                $formLoading
            );
        };

        var successVerifyDate = function () {
            $addressNextDateContain.disabled = false;
            $addressNextDateContain.focus();
            formStatus();
        };

        var failVerifyDate = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.VERIFY_DATE_ERROR,
                    errorVerifyDate,
                    errorVerifyDate
                );
            } else {
                errorVerifyDate(data);
            }
        };

        var errorVerifyDate = function (data) {
            var $msgTextSelector = $transferDateServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $transferDateServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $addressNextDateContain.value = '';
            $addressNextDateContain.classList.remove(CONSTANTS.FILLED_CLASS);
            $addressNextDateContain.disabled = false;
            $datePickerInput.focus();
            formStatus();
        };

        var hideError = function ($element) {
            $element.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var setReviewForm = function (event) {
            event.preventDefault();

            if (event.currentTarget.disabled) {
                return;
            }

            var $buttonClicked = query.selectParentElement(event.target, CONSTANTS.NEXT_BUTTON),
                formSteps = document.getElementsByClassName(CONSTANTS.FORM_STEP),
                $reviewStep = formSteps[formSteps.length - 1];

            coned.utils.addGeneralListeners($reviewStep, setReviewForm);

            if ($buttonClicked && query.hasClass($buttonClicked, CONSTANTS.FINISH_BUTTON)) {
                $submitAnimationButton = $reviewStepSelector.getElementsByClassName(
                    CONSTANTS.ANIMATION_SUBMIT_MODULE
                )[0];
                new coned.components.SubmitAnimation(
                    $submitAnimationButton,
                    transferServiceCall,
                    successTransferServiceCallback,
                    errorTransferServiceCallback,
                    false,
                    true
                );
            }

            var $actualAddress = $dropdownButton.getElementsByClassName(
                CONSTANTS.ADDRESS_CONTAINER_CLASS
            )[0];

            // Set Current Address
            _.each($addressCurrentButtonReceptor, function ($receptor) {
                var $actualReceptor = $receptor.getElementsByClassName(
                    CONSTANTS.ADDRESS_CONTAINER_CLASS
                )[0];
                $actualReceptor.innerHTML = $actualAddress.innerHTML;
            });

            // Set Moving Address
            _.each($movingAddress1, function ($address1) {
                $address1.innerHTML = $autocompleteAddressSelectedDetail.dataset.addressLine1;
            });

            _.each($movingAddress2, function ($address2) {
                $address2.innerHTML = $autocompleteAddressSelectedDetail.dataset.addressLine2;
            });

            // Set Current and next date
            var currentDateFormat = $.datepicker.formatDate(
                'MM dd, yy',
                new Date($addressCurrentDateContain.value)
            );
            var nextDateFormat = $.datepicker.formatDate(
                'MM dd, yy',
                new Date($addressNextDateContain.value)
            );

            $addressCurrentDateReceptor.innerHTML = currentDateFormat;
            $addressNextDateReceptor.innerHTML = nextDateFormat;

            // Set Email information
            $emailReceptor.innerHTML = query.getFormInputValue(
                $accountProfileStepSelector,
                CONSTANTS.FORM_EMAIL_NAME
            );

            // Set Phone information
            $phoneTitleReceptor.innerHTML =
                $phoneTypeSelect.options[$phoneTypeSelect.selectedIndex].text;
            $phoneReceptor.innerHTML = query.getFormInputValue(
                $accountProfileStepSelector,
                CONSTANTS.FORM_PHONE_NUMBER_NAME
            );
        };

        var transferServiceCall = function () {
            var serviceUrl = $transferForm.dataset.transferServiceUrl,
                lseFlag = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_LIFE_SUPPORT_NAME
                ),
                delegateFlag = query.getFormInputValue(
                    $disabilityStepSelector,
                    CONSTANTS.FORM_DISABILITY_OTHER_NOTIFIED_NAME
                ),
                disabilityFlag = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_DISABILITY_NAME
                ),
                StartService,
                accountMaid = $autocompleteAddressSelectedDetail.dataset.maid,
                params;

            hideError($transferServiceError);

            StartService = {
                AccountNumber: _accountId,
                Maid: accountMaid,
                StartDate: query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_START_DATE_NAME
                ),
                Email: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_EMAIL_NAME
                ),
                Phone: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_NUMBER_NAME
                ),
                PhoneType: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_TYPE_NAME
                ),
                EbillFlag: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_EBILL_ENROLL_NAME
                ),
                DPPflag: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_DIRECT_PAYMENT_ENROLL_NAME
                ),
                PremiseNumber: _premiseNumber,
                AutopayDetails: {
                    BankAccountType: query.getFormInputValue(
                        $directPaymentStepSelector,
                        CONSTANTS.FORM_DP_ACCOUNT_TYPE_NAME
                    ),
                    BankRoutingNumber: query.getFormInputValue(
                        $directPaymentStepSelector,
                        CONSTANTS.FORM_DP_ROUTING_NUMBER_NAME
                    ),
                    BankAccountNumber: query.getFormInputValue(
                        $directPaymentStepSelector,
                        CONSTANTS.FORM_DP_ACCOUNT_NUMBER_NAME
                    )
                }
            };

            if (disabilityFlag.toLowerCase() === 'yes') {
                StartService.ConcernFlag = true;
                StartService.Concern = setDisability(disabilityFlag, delegateFlag);
            } else {
                StartService.ConcernFlag = false;
            }

            if (lseFlag && lseFlag.toLowerCase() === 'yes') {
                StartService.LSEFlag = true;
                StartService.LSE = setLse(lseFlag);
            } else {
                StartService.LSEFlag = false;
            }

            // Service Data
            params = {
                ScStartDateId: query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_START_DATE_SCID_NAME
                ),
                ScServiceAddressId: query.getFormInputValue(
                    $addressStepSelector,
                    CONSTANTS.FORM_ADDRESS_SCID_NAME
                ),
                ScAccountProfileId: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_ACCOUNT_PROFILE_SCID_NAME
                ),
                ScDirectPaymentId: query.getFormInputValue(
                    $directPaymentStepSelector,
                    CONSTANTS.FORM_DIRECT_PAYMENT_SCID_NAME
                ),
                ScLifeSupportEquipmentId: query.getFormInputValue(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LIFE_SUPPORT_SCID_NAME
                ),
                ScDisabilityId: query.getFormInputValue(
                    $disabilityStepSelector,
                    CONSTANTS.FORM_DISABILITY_SCID_NAME
                ),
                ID: $dropdownButton.dataset.account,
                StopService: setStopAccountInformation(),
                StartService: StartService
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successTransferServiceCall,
                errorTransferServiceCall,
                params,
                true
            );
        };

        var successTransferServiceCall = function () {
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        var errorTransferServiceCall = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var setStopAccountInformation = function () {
            var stopAccountInformation = {
                StopDate: query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_END_DATE_NAME
                ),
                Phone: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_NUMBER_NAME
                ),
                PhoneType: query.getFormInputValue (
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_TYPE_NAME
                ),
                Maid: _stopServiceMaid,
                PremiseNumber: _stopPremise
            };

            return stopAccountInformation;
        };

        var setLse = function (lseFlag) {
            var lse;

            if (!lseFlag) return null;

            lse = {
                Tank: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_TANK_RESPIRATOR_ID
                ),
                Curass: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_CUIRASS_RESPIRATOR_ID
                ),
                RockingBed: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_ROCKING_RESPIRATOR_ID
                ),
                ElecOperated: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_ELECTRICAL_RESPIRATOR_ID
                ),
                Apnea: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_APNEA_MONITOR_ID
                ),
                Herneo: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_HEMODIALYSIS_ID
                ),
                Other: query.getFormInputValue(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_OTHER_NAME
                ),
                UsedSleepingHours: query.getFormInputValue(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_EQUIPMENT_SLEEPING_NAME
                ),
                SleepingHoursPerDay: query.getFormInputValue(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_HOURS_PER_DAY_NAME
                )
            };

            return lse;
        };

        var setDisability = function (disabilityFlag, delegateFlag) {
            var delegate = null,
                disability;

            if (!disabilityFlag) return null;

            if (delegateFlag) {
                delegate = {
                    Name: query.getFormInputValue(
                        $disabilityStepSelector,
                        CONSTANTS.FORM_DISABILITY_FIRST_NAME_NAME
                    ),
                    LastName: query.getFormInputValue(
                        $disabilityStepSelector,
                        CONSTANTS.FORM_DISABILITY_LAST_NAME_NAME
                    ),
                    Street: query.getFormInputValue(
                        $disabilityStepSelector,
                        CONSTANTS.FORM_DISABILITY_STREET_ADDRESS_NAME
                    ),
                    City: query.getFormInputValue(
                        $disabilityStepSelector,
                        CONSTANTS.FORM_DISABILITY_CITY_NAME
                    ),
                    State: query.getFormInputValue(
                        $disabilityStepSelector,
                        CONSTANTS.FORM_DISABILITY_STATE_NAME
                    ),
                    ZipCode: query.getFormInputValue(
                        $disabilityStepSelector,
                        CONSTANTS.FORM_DISABILITY_ZIPCODE_NAME
                    ),
                    DayPhone: query.getFormInputValue(
                        $disabilityStepSelector,
                        CONSTANTS.FORM_DISABILITY_DAY_TIME_NUMBER_NAME
                    ),
                    NightPhone: query.getFormInputValue(
                        $disabilityStepSelector,
                        CONSTANTS.FORM_DISABILITY_NIGHT_TIME_NUMBER_NAME
                    )
                };
            }

            disability = {
                OverAge62: query.getCheckboxInputValueById(
                    $disabilityStepSelector,
                    CONSTANTS.FORM_DISABILITY_AGE_62_ID
                ),
                IsBlind: query.getCheckboxInputValueById(
                    $disabilityStepSelector,
                    CONSTANTS.FORM_DISABILITY_BLIND_ID
                ),
                IsPermDisability: query.getCheckboxInputValueById(
                    $disabilityStepSelector,
                    CONSTANTS.FORM_DISABILITY_PERMANENTLY_DISABLED_ID
                ),
                Delegate: delegate
            };

            return disability;
        };

        var successTransferServiceCallback = function () {
            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success'
            });

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($transferService);

            // Updating banner text tokens
            var startDateValue = query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_START_DATE_NAME
                );

            var startDateFormat = $.datepicker.formatDate(
                        'MM dd, yy',
                        new Date(startDateValue)
                    );
    

            if($successBannerDate) {
                $successBannerDate.innerHTML = $successBannerDate.innerHTML.replace(
                    CONSTANTS.SUCCESS_DATE_TOKEN,
                    startDateFormat
                );
            }

            $stepsList.style.display = 'none';
            $transferServiceForm.style.display = 'none';

            $finishStep.classList.add(CONSTANTS.STEP_FINISH_ACTIVE);
            $progressBar.classList.add(CONSTANTS.FINISH_BAR_CLASS);
            query.removeClass($finishStepSelector, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($transferServiceFinishMain, CONSTANTS.HIDDEN_CLASS);
            $transferServiceFinishMain.focus();
        };

        var errorTransferServiceCallback = function () {
            var $msgTextSelector = $transferServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $transferServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $transferServiceError.focus();
            $msgTextSelector.innerHTML = _data.errorMsg
                ? _data.errorMsg
                : coned.constants.ERROR_MESSAGE;
        };

        var validateAccountProfileFields = function () {
            var email = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_EMAIL_NAME
                ),
                phoneType = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_TYPE_NAME
                ),
                phoneNumber = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_NUMBER_NAME
                ),
                lifeSupport = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_LIFE_SUPPORT_NAME
                ),
                disability = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_DISABILITY_NAME
                ),
                validEmail = email && email.trim() !== '',
                validPhoneType = phoneType && phoneType.trim() !== '',
                validPhoneNubmer = phoneNumber && phoneNumber.trim() !== '',
                validLifeSupport = lifeSupport && lifeSupport.trim() !== '',
                validDisability = disability && disability.trim() !== '';

            // take in count other inputs
            if (
                validEmail &&
                validPhoneType &&
                validPhoneNubmer &&
                validLifeSupport &&
                validDisability
            ) {
                $profileSubmitButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
            } else {
                $profileSubmitButton.setAttribute(
                    CONSTANTS.DISABLED_ATTRIBUTE,
                    CONSTANTS.DISABLED_ATTRIBUTE
                );
            }
        };

        var accountProfileValidation = function () {
            // Add event to email input
            $email.addEventListener(
                CONSTANTS.INPUT_EVENT,
                function () {
                    validateAccountProfileFields();
                },
                false
            );

            // phone type
            $phoneTypeSelect.addEventListener(
                CONSTANTS.CHANGE_EVENT,
                function (event) {
                    var $phoneSelector = event.currentTarget,
                        phoneValue = $phoneSelector.value;

                    validateAccountProfileFields();

                    // Tagging
                    dataLayer.push({
                        event: CONSTANTS.TAGGING_PHONE_TYPE,
                        phone: phoneValue
                    });
                },
                false
            );

            // Add event to phone input
            $phone.addEventListener(
                CONSTANTS.INPUT_EVENT,
                function () {
                    validateAccountProfileFields();
                },
                false
            );

            // radio options
            coned.utils.addParentListener(
                $accountProfileStepSelector,
                [CONSTANTS.CHANGE_EVENT],
                CONSTANTS.CONED_RADIO_SELECTOR,
                validateAccountProfileFields
            );
        };

        var handleKeyDownOnAccountItem = function (event) {
            var keycode = event.keyCode;

            if (keycode == coned.constants.KEY_CODE.ENTER) {
                validateByAddress(event);
            }
        };

        var initializeData = function () {
            $transferForm = document.getElementsByClassName(CONSTANTS.TRANSFER_SERVICE_SELECTOR)[0];
            $transferServiceForm = document.getElementsByClassName(
                CONSTANTS.TRANSFER_FORM_CLASS
            )[0];
            $transferServiceStart = document.getElementsByClassName(
                CONSTANTS.TRANSFER_SERVICE_START_SELECTOR
            )[0];
            $finishStepSelector = document.getElementsByClassName(
                CONSTANTS.FINISH_STEP_SELECTOR
            )[0];
            $lookupButton = document.getElementsByClassName(CONSTANTS.LOOKUP_BUTTON)[0];
            $successBannerDate = document.getElementsByClassName(
                CONSTANTS.SUCCESS_BANNER_DATE
            )[0];
            $nextButtons = $transferForm.getElementsByClassName(CONSTANTS.NEXT_BUTTON);
            $stepsList = document.getElementsByClassName(CONSTANTS.STEPS_LIST)[0];
            $finishStep = document.getElementsByClassName(CONSTANTS.STEP_FINISH)[0];
            $progressBar = document.getElementsByClassName(CONSTANTS.STEP_PROGRESS_BAR)[0];
            $accountProfile = document.getElementsByClassName(
                CONSTANTS.ACCOUNT_PROFILE_SELECTOR
            )[0];
            $addressCurrentButtonReceptor = $transferForm.getElementsByClassName(
                CONSTANTS.ADDRESS_BUTTON_RECEPTOR
            );
            $movingAddress1 = $transferForm.getElementsByClassName(CONSTANTS.MOVING_ADDRESS_1);
            $movingAddress2 = $transferForm.getElementsByClassName(CONSTANTS.MOVING_ADDRESS_2);
            $currentAddressTitle = $transferForm.getElementsByClassName(
                CONSTANTS.CURRENT_ADDRESS_TITLE
            )[0];
            $addressCurrentDateContain = $transferForm.getElementsByClassName(
                CONSTANTS.ADDRESS_CURRENT_DATE_CONTAIN
            )[0];
            $addressNextDateContain = $transferForm.getElementsByClassName(
                CONSTANTS.ADDRESS_NEXT_DATE_CONTAIN
            )[0];
            $addressCurrentDateReceptor = $transferForm.getElementsByClassName(
                CONSTANTS.ADDRESS_CURRENT_DATE_RECEPTOR
            )[0];
            $addressNextDateReceptor = $transferForm.getElementsByClassName(
                CONSTANTS.ADDRESS_NEXT_DATE_RECEPTOR
            )[0];
            $addressLookupForm = $transferForm.getElementsByClassName(
                CONSTANTS.ADDRESS_LOOKUP_FORM
            )[0];
            $autocompleteAparmentUnitInput = $transferForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_APARMENT_UNIT
            )[0];
            $autocompleteAddressSelectedDetail = $addressLookupForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_SELECTED_ADDRESS_DETAIL
            )[0];
            $phoneReceptor = $transferForm.getElementsByClassName(CONSTANTS.PHONE_RECEPTOR)[0];
            $phoneTitleReceptor = $transferForm.getElementsByClassName(
                CONSTANTS.PHONE_TITLE_RECEPTOR
            )[0];
            $editAccountInformation = $transferForm.getElementsByClassName(
                CONSTANTS.EDIT_ACCOUNT_INFORMATION
            );
            $emailReceptor = $transferForm.getElementsByClassName(CONSTANTS.EMAIL_RECEPTOR)[0];
            $addressStepSelector = $transferForm.getElementsByClassName(
                CONSTANTS.ADDRESS_STEP_SELECTOR
            )[0];
            $verifyDateStepSelector = $transferForm.getElementsByClassName(
                CONSTANTS.VERIFY_DATE_STEP_SELECTOR
            )[0];
            $accountProfileStepSelector = $transferForm.getElementsByClassName(
                CONSTANTS.ACCOUNT_PROFILE_STEP_SELECTOR
            )[0];
            $directPaymentStepSelector = $transferForm.getElementsByClassName(
                CONSTANTS.DIRECT_PAYMENT_STEP_SELECTOR
            )[0];
            $lifeSupportStepSelector = $transferForm.getElementsByClassName(
                CONSTANTS.LIFE_SUPPORT_STEP_SELECTOR
            )[0];
            $disabilityStepSelector = $transferForm.getElementsByClassName(
                CONSTANTS.DISABILITY_STEP_SELECTOR
            )[0];
            $reviewStepSelector = $transferForm.getElementsByClassName(
                CONSTANTS.REVIEW_STEP_SELECTOR
            )[0];
            $transferDateServiceError = $transferForm.getElementsByClassName(
                CONSTANTS.TRANSFER_DATE_ERROR_SELECTOR
            )[0];
            $transferStopDateServiceError = $transferForm.getElementsByClassName(
                CONSTANTS.TRANSFER_STOP_DATE_ERROR_SELECTOR
            )[0];
            $transferServiceError = $transferForm.getElementsByClassName(
                CONSTANTS.TRANSFER_SERVICE_ERROR_SELECTOR
            )[0];
            $formLoading = $transferForm.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $accountDropDownItems = $transferForm.getElementsByClassName(
                CONSTANTS.ACCOUNT_DROPDOWN_ITEMS
            );
            $dropdownButton = $transferForm.getElementsByClassName(CONSTANTS.DROPDOWN_BUTTON)[0];
            $datePickerInput = $transferForm.getElementsByClassName(CONSTANTS.DATE_PICKER_INPUT)[0];
            $datePickertNextDate = document.getElementsByClassName(
                CONSTANTS.DATE_PICKER_NEXT_DATE
            )[0];
            $phoneTypeSelect = $transferForm.getElementsByClassName(CONSTANTS.PHONE_TYPE_SELECT)[0];
            $checkAvailabilityError = $transferForm.getElementsByClassName(
                CONSTANTS.VALIDATE_BY_ADDRESS_ERROR
            )[0];
            $currentAddressError = $transferForm.getElementsByClassName(
                CONSTANTS.VALIDATE_BY_ADDRESS_ERROR
            )[0];
            $profileSubmitButton = $transferForm.getElementsByClassName(
                CONSTANTS.PROFILE_SUBMIT_BUTTON
            )[0];
            $email = $transferForm.getElementsByClassName(CONSTANTS.EMAIL_INPUT_CLASS)[0];
            $phone = $transferForm.getElementsByClassName(CONSTANTS.PHONE_INPUT_CLASS)[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];
            $transferServiceFinishMain = $transferService.getElementsByClassName(
                CONSTANTS.TRANSFER_SERVICE_FINISH_MAIN
            )[0];
            
            $liveChatStopAvailable = $transferForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_STOP_AVAILABLE_SELECTOR
            )[0];
            $liveChatStopErrorMessage = $transferForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_STOP_ERROR_MESSAGE
            )[0];
            $liveChatStopAvailableMessage = $transferForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_STOP_AVAILABLE_MESSAGE
            )[0];
            $liveChatStartAvailable = $transferForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_START_AVAILABLE_SELECTOR
            )[0];
            $liveChatStartErrorMessage = $transferForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_START_ERROR_MESSAGE
            )[0];
            $liveChatStartAvailableMessage = $transferForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_START_AVAILABLE_MESSAGE
            )[0];
            _liveChatAvailable = $transferForm.dataset.liveChatAvailable;
            _liveChatStopTagging = true,
            _liveChatStartTagging = true,
            _stopLiveChatOn = coned.utils.getUrlParameterValue(CONSTANTS.STOP_LIVE_CHAT_ON_PARAM);
            _startLiveChatOn = coned.utils.getUrlParameterValue(CONSTANTS.START_LIVE_CHAT_ON_PARAM);
            _activeSteps = [];            
            _isEletricityColdAmi = $transferService.dataset.isEletricityColdAmi;
        };

        var initializeEvents = function () {
            // Update information
            _.each($nextButtons, function ($nextButton) {
                coned.utils.addGeneralListeners($nextButton, setReviewForm);
            });

            // Edit Account Information listener
            _.each($editAccountInformation, function ($editAccountButton) {
                coned.utils.addGeneralListeners($editAccountButton, function (event) {
                    event.preventDefault();

                    window.scrollTo(0, 0);
                    $accountProfile.click();
                    $(CONSTANTS.TRANSFER_FORM) && $(CONSTANTS.TRANSFER_FORM).focus();
                });
            });

            setTimeout(function () {
                $($addressCurrentDateContain).datepicker('option', 'minDate', '1');
            }, 0);

            // Verify Stop Date Service call
            $($datePickerInput).on('change', verifyStopDate);

            // Verify Start Date Service call
            $(CONSTANTS.ADDRESS_NEXT_DATE_CLASS).on('change', verifyDate);
            // Validate Identity initial address
            validateByAddress();

            // Validate Identity call by address
            _.each($accountDropDownItems, function ($accountItem) {
                coned.utils.addGeneralListeners($accountItem, validateByAddress);
                $accountItem.addEventListener('keydown', handleKeyDownOnAccountItem);

                // Tagging
                // dataLayer.push({
                //     'event': CONSTANTS.TAGGING_ADDRESS_CHANGE,
                //     'account': $accountItem.dataset.account
                // });
            });

            // Update the dropdown to show the account in context selected
            for (var i = 0; $accountDropDownItems[i]; i++) {
                _account = $dropdownButton.dataset.account;

                if ($accountDropDownItems[i].children[0].dataset.account === _account) {
                    setTimeout(function () {
                        query.fireEvent($accountDropDownItems[i],'accountInContext'); 
                    }, 500);
                    break;
                }
            }

            if ($autocompleteAparmentUnitInput) {
                $autocompleteAparmentUnitInput.addEventListener(
                    'selectedOptionEnd',
                    validateByResidenceUnit
                );
                $autocompleteAparmentUnitInput.addEventListener(
                    'checkAddress',
                    validateByResidenceUnit
                );
            }

            // Set tabindex on end screen for a11y
            $transferServiceFinishMain.setAttribute(CONSTANTS.TABINDEX, '-1');

            // Transfer Service Steps Submit Calls
            new coned.components.ValidateForm(
                CONSTANTS.TRANSFER_FORM,
                function () {},
                CONSTANTS.VALIDATE_IGNORE
            );

            accountProfileValidation();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    TransferService.prototype.isLoaded = function () {
        return isLoaded;
    };

    return TransferService;
})();
