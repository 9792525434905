// ==================== SMART ENERGY COMPONENT =========================
/* global d3 */
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.IppChartsComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ARIA_CURRENT: 'aria-current',
        CONED_ENERGY_USAGE: 'energy-usage',
        CONED_CHART: 'coned-chart',
        CONED_CHART_HIDE_CLASS: 'coned-chart--hide',
        CONED_CHART_TAB_ACTIVE_CLASS: 'coned-tabs__tab-item--active',
        CONED_CHART_DEMAND_TAB_HIDDEN: 'coned-tabs--visibility-hidden',
        CONED_CHART_DATE: 'coned-chart__middle-text--date',
        CONED_CHART_MONTH_BUTTON: 'js-coned-chart-monthly',
        CONED_CHART_DATE_BUTTON: 'js-coned-chart-daily',
        CONED_CHART_BUTTON_DISABLED: 'coned-chart__buttons--square-blue-disabled',
        CONED_CHART_PEAK_CONTENT: 'coned-chart__peak-content',
        CONED_CHART_BUTTONS_HIDE: 'coned-chart__buttons--hide',
        CONED_CHART_ARROW_HIDE: 'coned-chart__arrow--hide',
        CONED_CHART_LEFT_ARROW: 'coned-chart__arrow--left',
        CONED_CHART_RIGHT_ARROW: 'coned-chart__arrow--right',
        CONED_CHART_ERROR: 'coned-chart__error-message',

        CONED_CHART_FORM_LOADING: 'js-form-loading',
        CONED_CHART_PEAK_OPTION: 'js-coned-radio-chart-peak',
        CONED_CHART_OFFPEAK_OPTION: 'js-coned-radio-chart-offpeak',
        CONED_CHART_NONSUMMER_OPTION: 'js-coned-radio-chart-nonsummer',
        CONED_CHART_DEMAND_BUTTON: 'js-demandTab',
        CONED_CHART_MOBILE_DEMAND: 'js-demandMobileTab',
        CONED_CHART_ERROR_MESSAGE: 'js-chart-module-error',
        CONED_CHART_NODATA_MESSAGE: 'js-chart-module-noData-error',
        CONED_CHART_MODULE: 'js-chart-module-content',
        CONED_CHART_CONTAINER: 'js-chart-container',
        CONED_CHART_NOTE: 'js-chart-note',

        CONED_CHART_SERVICE: 'data-chart-service',
        CONED_CHART_DATA_MAID: 'data-maid',
        CONED_CHART_DATA_RATE: 'data-rate',
        CONED_CHART_DATA_TIME: 'data-chart-time',
        CONED_CHART_DATA_TYPE: 'data-chart-type',
        CONED_CHART_DATE_TEXT: 'data-chart-date-text',
        CONED_CHART_DATA_FROM_DATE: 'data-from-date',
        CONED_CHART_DATA_TO_DATE: 'data-to-date',
        CONED_CHART_DATA_ZERO_HOVER: 'data-zero-value-hover',
        CONED_CHART_DATA_REGULAR_HOVER: 'data-regular-day-hover',
        CONED_CHART_DATA_SPECIAL_HOVER: 'data-chart-special-day',
        CONED_CHART_DETERMINANT: 'data-determinantname',
        CONED_CHART_BILLING_ERROR: 'data-billing-error',
        CONED_CHART_TOTAL_AVERAGE_DEMAND: 'data-total-average-legend',
        CONED_CHART_DATA_PERIOD: 'data-period',
        CONED_CHART_DATA_WEEK: 'data-week',
        CONED_CHART_DATA_SYMBOL: 'data-symbol',
        CONED_CHART_DATA_IS_SOLAR: 'data-is-solar', // data attribute that contais if it's a solar chart
        CONED_CHART_ARIA_HIDDEN: 'aria-hidden',

        CONED_CHART_TYPE: 'Type',
        CONED_CHART_FIXED_SUBSCRIPTION: 'fixedSubscriptionRate',
        CONED_CHART_TOTAL_AVERAGE_DEMAND_VALUE: 'totalAverageDemandValue',
        CONED_CHART_IPP_OPTION: 'IPP',
        CONED_CHART_STANDARD_OPTION: 'Standard',
        CONED_CHART_PEAK: 'Peak',
        CONED_CHART_OFFPEAK: 'OffPeak',
        CONED_CHART_NONSUMMER: 'NonSummer',
        CONED_CHART_TRANSITION: 'Transition',
        CONED_CHART_OFF_PEAK: 'Off-Peak',
        CONED_CHART_NONSUMMER_PEAK: 'Non-Summer',
        CONED_CHART_GROUPBY_MONTHLY: 'Monthly',
        CONED_CHART_GROUPBY_WEEKLY: 'Weekly',
        CONED_CHART_YEAR: 'Year',
        CONED_CHART_QUATER: 'Quarter',
        CONED_CHART_NO_DATA: 'None',
        CONED_CHART_RADIO_OPTION: 'peak',
        CONED_CHART_DEMAND: 'demand',
        CONED_CHART_USAGE: 'usage',
        CONED_CHART_BILLING: 'billing',
        CONED_CHART_MONTHLY: 'monthly',
        CONED_CHART_DAILY: 'daily',
        CONED_CHART_TRUE: 'true',
        CONED_HIDDEN_CLASS: 'hidden',
        CONED_DISABLED_ATTR: 'disabled',

        CONED_CHART_SUBSCRIPTION_COLOR: '#94000f',
        CONED_CHART_OVERAGE_COLOR: '#ffffff',
        CONED_CHART_DETERMINANT_COLOR: '#7a7a7a',
        CONED_CHART_BLUE_COLOR: '#069bd7',
        CONED_CHART_PEAK_COLOR: '#27aa5e',
        CONED_CHART_OFFPEAK_COLOR: '#19522c',
        CONED_CHART_NONSUMMER_COLOR: '#ffffff',
        CONED_CHART_WHITE_COLOR: '#fff',
        CONED_CHART_BLACK_COLOR: '#000000',

        CONED_DATA_ATTRIBUTES_JSON: '/_static/legendDataAttributes.json'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var IppChartsComponent = function ($ippChartsComponent) {
        /**
         *  PRIVATE METHODS
         */
        var $conedEnergyContent,
            $conedChartDateButton,
            $conedChartMonthButton,
            $conedChartPeakButtons,
            $conedChartDemandTab,
            $conedChartDemandMobileTab,
            $conedChartPeakOption,
            $conedChartOffPeakOption,
            $conedChartNonSummerOption,
            $conedChartDateText,
            $conedChartLastDate,
            $conedChartNextDate,
            $conedChartModule,
            $conedChartErrorMessage,
            $conedChartNoDataMessage,
            $conedChartMonthlyNoteText,
            $conedChartDailyNoteText,
            $conedChartNote,
            $conedChartContainer,
            $formLoading,
            $conedChartTableButton,
            $conedChartTable,
            _device,
            _isSolar,
            _monthlyIdsList,
            _dailyPeakIdsList,
            _dailyOffPeakIdsList,
            _dailyNonSummerIdsList,
            _monthlyPeriodsList,
            _allGeneralPeriods,
            _fromMonthlyPeriod,
            _toMonthlyPeriod,
            _fromDailyPeriod,
            _toDailyPeriod,
            _IPPPeriods,
            _morePeriods,
            _currentPeriodType,
            _type,
            _time,
            _peak,
            _addPeakClass,
            _chartId,
            _jsonFile,
            _isFirstbillingPeriodLoad,
            _actualChart,
            _dataMonthlyNames,
            _dataDailyNames,
            _legendTooltip,
            _dateText,
            _monthLine,
            _yearLineText,
            _allMonths,
            _allMonthsPeriodServiceCall,
            _allCompleteMonths,
            _generalDailyData,
            _lastChart,
            _chart,
            _yAxis,
            _allPeriods,
            _generalLoad,
            _indexBillingPeriod,
            _kilowatt_symbol,
            _dailyPeriodPosition,
            _actualDailyPos,
            _nextClick,
            _actualPeriod,
            _dailyDataPosition,
            _dailyPeakMobileData,
            _dailyOffPeakMobileData,
            _dailyNonSummerMobileData,
            _monthlyMobileData,
            _monthlyMobilePeriod,
            _monthlyMobilePosition,
            _dailyPeakMobileIds,
            _dailyOffPeakMobileIds,
            _dailyNonSummerMobileIds,
            _errorFlag,
            _monthlyErrorFlag,
            _lastPeriod,
            _rateType,
            _legendDataAttributes,
            _hasTotalAverageValue,
            _totalAverageValue,
            _allTotalAverageValue,
            _fixedSubscription,
            _fixedSubscriptionValues,
            _lastPeriodPosition,
            _demandMonthlyColors,
            _demandMonthlyColorsRate2,
            _monthlyColorsRateE,
            _dailyColorsRateE,
            _monthlyColorsRateF,
            _dailyColorsRateF;

        /**
         * Callback to get the Billing Periods
         */
        var getBillingPeriods = function () {
            var serviceUrl = $conedEnergyContent.dataset.getBillingPeriods,
                params,
                maid = $conedEnergyContent.getAttribute(CONSTANTS.CONED_CHART_DATA_MAID);

            _rateType = _rateType
                ? _rateType
                : $conedEnergyContent.getAttribute(CONSTANTS.CONED_CHART_DATA_RATE);
            query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            // Disable chart arrows to prevent issues while loading async content
            $conedChartLastDate.setAttribute(CONSTANTS.CONED_DISABLED_ATTR, true);
            $conedChartNextDate.setAttribute(CONSTANTS.CONED_DISABLED_ATTR, true);

            params = coned.chartComponents.getBillingPeriods(_rateType, _indexBillingPeriod, maid);

            // Service Call
            query.getData(serviceUrl, successGetBillingPeriods, errorGetBillingPeriods, params);
        };

        /**
         * Show error message when get billing periods service call fail
         */
        var errorGetBillingPeriods = function () {
            dataLayer.push({
                event: 'IPP.demand.error.message'
            });

            var count = 12;
            _indexBillingPeriod = _indexBillingPeriod - count;

            query.addClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);

            $conedChartDateText.innerHTML = $conedChartModule.getAttribute(
                CONSTANTS.CONED_CHART_BILLING_ERROR
            );

            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                _monthlyErrorFlag = true;
            } else {
                _errorFlag = true;
            }
        };

        /**
         * Call billingPeriods function when get billing periods service call success
         * @param {Object} data    Data returned by the service call
         */
        var successGetBillingPeriods = function (data) {
            if (coned.utils.isPatternLab()) {
                var billingPeriodsJson = document.querySelector('[data-patternlab-ipp-chart-rate2]')
                    ? coned.plConstants.GET_CHART_BILLING_PERIODS_RATE2
                    : document.querySelector('[data-patternlab-ipp-chart-rate5]')
                    ? coned.plConstants.GET_CHART_BILLING_PERIODS_RATE5
                    : document.querySelector('[data-patternlab-ipp-chart-rate6]')
                    ? coned.plConstants.GET_CHART_BILLING_PERIODS_RATE6
                    : coned.plConstants.GET_CHART_BILLING_PERIODS;
                query.getData(billingPeriodsJson, billingPeriods, errorGetBillingPeriods);
            } else {
                billingPeriods(data);
            }
        };

        /**
         * Create an array with all the periods and determinate the fromDate and toDate variables for the period
         * @param {Object} data    Data returned by the get-billing-periods service call
         */
        var billingPeriods = function (data) {
            _allGeneralPeriods = _allGeneralPeriods.concat(data.periods);
            _morePeriods = data.morePeriods;
            _currentPeriodType = data.currentPeriodType;
            _kilowatt_symbol = $conedChartDemandTab.getAttribute(CONSTANTS.CONED_CHART_DATA_SYMBOL);
            _isSolar = $conedChartDemandTab.getAttribute(CONSTANTS.CONED_CHART_DATA_IS_SOLAR);

            if (_morePeriods) {
                query.removeClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            }

            if (data && data.periods && data.periods.length > 0) {
                query.removeClass($conedChartDemandTab, CONSTANTS.CONED_CHART_DEMAND_TAB_HIDDEN);
                query.removeClass($conedChartDemandMobileTab, CONSTANTS.CONED_HIDDEN_CLASS);

                var periods = data.periods,
                    keys = Object.keys(periods),
                    ippPeriodsCount;

                _allPeriods = [];

                keys.forEach(function (key) {
                    _allPeriods.push(periods[key].billToDate);

                    if (periods[key].billProgram === CONSTANTS.CONED_CHART_IPP_OPTION) {
                        _IPPPeriods++;
                    }

                    if (
                        periods[key].billProgram === CONSTANTS.CONED_CHART_IPP_OPTION ||
                        periods[key].billProgram === CONSTANTS.CONED_CHART_STANDARD_OPTION
                    ) {
                        ippPeriodsCount = key;
                    }
                });

                _fromMonthlyPeriod = periods[ippPeriodsCount].billFromDate;
                _toMonthlyPeriod = periods[0].billToDate;

                getChartPeriod();
                
                // Enable chart arrows after async content has loaded
                $conedChartLastDate.removeAttribute(CONSTANTS.CONED_DISABLED_ATTR);
                $conedChartNextDate.removeAttribute(CONSTANTS.CONED_DISABLED_ATTR);
                // Since arrows get disabled while loading data, focus goes to body
                // so we re focus the prev chart arrow
                if (!_isFirstbillingPeriodLoad) {
                    if (document.activeElement === document.body) {
                        $conedChartLastDate.focus();
                    }
                } else {
                    _isFirstbillingPeriodLoad = false;
                }
            } else {
                query.addClass($conedChartDemandMobileTab, CONSTANTS.CONED_HIDDEN_CLASS);
                query.addClass($conedChartDemandTab, CONSTANTS.CONED_CHART_DEMAND_TAB_HIDDEN);
            }
        };

        /**
         * Call the monthly service
         * @param {Object} event   Event name
         */
        var getMonthlyData = function (event) {
            event.preventDefault();

            if (_monthlyIdsList.length === 0) {
                query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
                callMonthlyService();
            }
            
            coned.utils.removeMultipleListeners(
                $conedChartDemandTab, 
                coned.utils.generalEvents(),
                getMonthlyData
            )            
        };

        /**
         * Call the function to create the daily chart
         * @param {Object} event    Event name
         */
        var createDailyChart = function (event) {
            event.preventDefault();
            
            var disabled = $conedChartDateButton.classList.contains(CONSTANTS.CONED_CHART_BUTTON_DISABLED);
            coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);

            if (disabled) return;

            dataLayer.push({
                event: 'IPP.demand.toggle.view',
                ToggleLevel: 'Daily'
            });

            if (_currentPeriodType && _currentPeriodType !== CONSTANTS.CONED_CHART_NO_DATA) {
                showNewChart(_currentPeriodType);
            } else {
                showNewChart(CONSTANTS.CONED_CHART_PEAK);
            }

            addNoteText(CONSTANTS.CONED_CHART_DAILY);

            updatedCopyArrows($conedChartDateButton);
        };

        /**
         * Show monthly chart when monthly button is clicked
         */
        var showMonthlyChart = function () {
            dataLayer.push({
                event: 'IPP.demand.toggle.view',
                ToggleLevel: 'Monthly'
            });

            var disabled = $conedChartMonthButton.classList.contains(CONSTANTS.CONED_CHART_BUTTON_DISABLED);

            if (disabled) return;

            coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);

            if (_errorFlag) {
                var $actualChart = document.getElementById(_chartId);

                if ($actualChart) {
                    var chartDate = $actualChart.getAttribute(CONSTANTS.CONED_CHART_DATE_TEXT);

                    $conedChartDateText.innerHTML = chartDate;

                    query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);

                    _errorFlag = false;
                }
            }

            if (_monthlyErrorFlag && !_monthlyIdsList[0]) {
                query.addClass($conedChartPeakButtons, CONSTANTS.CONED_CHART_BUTTONS_HIDE);
                query.removeClass($conedChartDateButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                query.addClass($conedChartMonthButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                $conedChartMonthButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    true
                );
                $conedChartDateButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );

                callMonthlyService();
            } else {
                _time = CONSTANTS.CONED_CHART_MONTHLY;
                _actualDailyPos = 0;
                _dailyDataPosition = 0;
                _dailyPeriodPosition = 0;

                showChart(_monthlyIdsList[0]);
                if ($conedChartPeakOption) {
                    $conedChartPeakOption.setAttribute(CONSTANTS.CONED_DISABLED_ATTR, 'true');
                }

                allowArrows(_monthlyIdsList[0]);
            }
            addNoteText(CONSTANTS.CONED_CHART_MONTHLY);
            updatedCopyArrows($conedChartMonthButton);
        };

        /**
         * Get the period to call the respective service and generate the monthly date
         */
        var getChartPeriod = function () {
            var count = _allPeriods.length - 1,
                fromDate = _allPeriods[count],
                toDate = _allPeriods[0],
                $activeTab = $ippChartsComponent.getElementsByClassName(
                    CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS
                )[0];

            _dateText = coned.chartComponents.generateMonthlyDate(fromDate, toDate, _allMonthsPeriodServiceCall);

            if (
                !_generalLoad &&
                $activeTab.classList.contains(CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS)
            ) {
                $activeTab.click();
            }

            if (_generalLoad && _time === CONSTANTS.CONED_CHART_MONTHLY) {
                _dateText = coned.chartComponents.generateMonthlyDate(fromDate, toDate, _allMonths);
                callMonthlyService();
            } else {
                if (_generalLoad && _time === CONSTANTS.CONED_CHART_DAILY) {
                    var peakOption = peakRadioButtonOption(),
                        peakOptionList =
                            peakOption === CONSTANTS.CONED_CHART_PEAK
                                ? _dailyPeakIdsList
                                : _dailyOffPeakIdsList,
                        chartIndex = peakOptionList.indexOf(_actualChart) + 1,
                        position =
                            coned.utils.isMobile() || coned.utils.isTablet()
                                ? _dailyPeriodPosition
                                : chartIndex;

                    getDailyDemand(peakOption, position);
                }
            }
        };

        /**
         * Call the service to bring monthly information
         */
        var callMonthlyService = function () {
            if (_allGeneralPeriods) {
                var serviceUrl = $conedChartMonthButton.getAttribute(CONSTANTS.CONED_CHART_SERVICE),
                    params,
                    maid = $conedEnergyContent.getAttribute(CONSTANTS.CONED_CHART_DATA_MAID),
                    groupBy =
                        coned.utils.isMobile() || coned.utils.isTablet()
                            ? CONSTANTS.CONED_CHART_QUATER
                            : CONSTANTS.CONED_CHART_YEAR;

                _type = CONSTANTS.CONED_CHART_DEMAND;

                // Service Data
                params = coned.chartComponents.callMonthlyService(
                    maid,
                    _rateType,
                    _fromMonthlyPeriod,
                    _toMonthlyPeriod,
                    groupBy,
                    _isSolar
                );

                // Service Call
                query.getData(
                    serviceUrl,
                    successGetMonthlyDemand,
                    errorGetMonthlyDemand,
                    params,
                    $formLoading
                );
            }
        };

        /**
         * Show error message when server call fail for monthly data
         */
        var errorGetMonthlyDemand = function () {
            var count = _allPeriods.length - 1,
                fromDate = _allPeriods[count],
                toDate = _allPeriods[0];

            _monthlyErrorFlag = true;
            _lastPeriod = false;
            _dateText = coned.chartComponents.generateMonthlyDate(fromDate, toDate, _allMonths);
            $conedChartDateText.innerHTML = _dateText;

            dataLayer.push({
                event: 'IPP.demand.error.message'
            });

            query.removeClass($conedChartModule, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);

            if (_monthlyIdsList.length > 0) {
                query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            } else {
                query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            }
        };

        /**
         * List the monthtly id chart
         * @param {Object} data      Data returned by the get-monthly-data service call
         */
        var successGetMonthlyDemand = function (data) {
            var $activeTab = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS
            )[0];

            query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);

            _allPeriods = [];
            _lastPeriod = _morePeriods ? false : true;

            generateChartId($activeTab);
            _monthlyIdsList.push(_chartId);
            _monthlyErrorFlag = false;

            if (coned.utils.isPatternLab()) {
                query.getData(_jsonFile, generateChart, errorGetBillingPeriods);
            } else {
                generateChart(data);
            }
        };

        /**
         * Determines if the subscription rate is E or F
         */
        var isSubscriptionRate = function () {
            return _rateType === 'E' || _rateType === 'F';
        };

        /**
         * Define the radio button that is selected
         */
        var peakRadioButtonOption = function () {
            var peakRadioButtonOption = query.getFormInputValue(
                    $ippChartsComponent,
                    CONSTANTS.CONED_CHART_RADIO_OPTION
                ),
                peakOption = peakRadioButtonOption
                    ? peakRadioButtonOption
                    : CONSTANTS.CONED_CHART_PEAK;

            return peakOption;
        };

        /**
         * Create the chart id
         * @param {String} $activeTab     Name of the tab that is active (monthly or daily)
         */
        var generateChartId = function ($activeTab) {
            var device = '',
                date,
                rate,
                week = '';

            _time = $activeTab.getAttribute(CONSTANTS.CONED_CHART_DATA_TIME);
            _peak = '';

            if (_time === CONSTANTS.CONED_CHART_DAILY) {
                // Disable Daily button
                query.addClass($conedChartDateButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                query.removeClass($conedChartMonthButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                $conedChartMonthButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                $conedChartDateButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    true
                );

                _peak = query.getFormInputValue(
                    $ippChartsComponent,
                    CONSTANTS.CONED_CHART_RADIO_OPTION
                );
                _peak = _peak ? _peak : isSubscriptionRate() ? CONSTANTS.CONED_CHART_PEAK : '';

                _fromDailyPeriod = _allGeneralPeriods[_dailyPeriodPosition].billFromDate;
                _toDailyPeriod = _allGeneralPeriods[_dailyPeriodPosition].billToDate;
                date = coned.chartComponents
                    .generateDailyDate(_fromDailyPeriod, _toDailyPeriod, false, _allMonths)
                    .replace(/\s|[,]/g, '');

                if (_peak != '') {
                    query.removeClass($conedChartPeakButtons, CONSTANTS.CONED_CHART_BUTTONS_HIDE);
                }

                if (coned.utils.isMobile() || coned.utils.isTablet()) {
                    device = 'M';
                    week = _dailyDataPosition;
                }
            } else {
                query.addClass($conedChartMonthButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                query.removeClass($conedChartDateButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                $conedChartMonthButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    true
                );
                $conedChartDateButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                
                if (!_dateText) {
                    date = coned.chartComponents
                        .generateMonthlyDate(_fromMonthlyPeriod, _toMonthlyPeriod, _allMonths)
                        .replace(/\s|[,]/g, '');
                } else {
                    date = _dateText.replace(/\s|[,]/g, '');
                }
                device = coned.utils.isMobile() || coned.utils.isTablet() ? 'M' : '';
            }

            if (_type === undefined) {
                _type = $activeTab.getAttribute(CONSTANTS.CONED_CHART_DATA_TYPE);
            }

            if (coned.utils.isPatternLab()) {
                rate = document.querySelector('[data-patternlab-ipp-chart-rate2]')
                    ? 'B/'
                    : document.querySelector('[data-patternlab-ipp-chart-rate5]')
                    ? 'E/'
                    : document.querySelector('[data-patternlab-ipp-chart-rate6]')
                    ? 'F/'
                    : '';
            } else {
                rate = '';
            }

            _chartId = _type + date + _peak;
            _jsonFile = '/get-content/charts/' + rate + _chartId + device + '.json';
            _chartId += week;
        };

        /**
         * Show the corresponding chart or create a new one
         * @param {Object} data     Data returned by the service call
         */
        var generateChart = function (data) {
            // generate object for daily charts
            if (_time === CONSTANTS.CONED_CHART_DAILY) {
                var totalDays, lastDay, firstDay;

                if (coned.utils.isTablet() || coned.utils.isMobile()) {
                    _dailyDataPosition = _lastPeriodPosition
                        ? data.items.length - 1
                        : _dailyDataPosition;
                    _lastPeriodPosition = false;

                    var peakOption = peakRadioButtonOption(),
                        peakMobileOptionList =
                            peakOption === CONSTANTS.CONED_CHART_PEAK
                                ? _dailyPeakMobileData
                                : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                                ? _dailyOffPeakMobileData
                                : _dailyNonSummerMobileData,
                        peakOptionMobileList =
                            peakOption === CONSTANTS.CONED_CHART_PEAK
                                ? _dailyPeakMobileIds
                                : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                                ? _dailyOffPeakMobileIds
                                : _dailyNonSummerMobileIds,
                        peakchart =
                            peakOption === CONSTANTS.CONED_CHART_PEAK
                                ? document.getElementById(_dailyOffPeakIdsList[_actualDailyPos])
                                : document.getElementById(_dailyPeakIdsList[_actualDailyPos]),
                        peakOptionList =
                            peakOption === CONSTANTS.CONED_CHART_PEAK
                                ? _dailyPeakIdsList
                                : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                                ? _dailyOffPeakIdsList
                                : _dailyNonSummerIdsList,
                        $activeTab = $ippChartsComponent.getElementsByClassName(
                            CONSTANTS.CONED_CHART_DATE_BUTTON
                        )[0],
                        billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates;

                    if (!peakMobileOptionList[_dailyPeriodPosition]) {
                        if (peakOption === CONSTANTS.CONED_CHART_PEAK) {
                            _dailyPeakMobileData[_dailyPeriodPosition] = data;
                        } else if (peakOption === CONSTANTS.CONED_CHART_OFFPEAK) {
                            _dailyOffPeakMobileData[_dailyPeriodPosition] = data;
                        } else {
                            _dailyNonSummerMobileData[_dailyPeriodPosition] = data;
                        }
                    }

                    _dailyDataPosition =
                        _dailyDataPosition < 0
                            ? peakchart.getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK)
                            : _dailyDataPosition;
                    if (!data.items[_dailyDataPosition]) {
                        var chart = document.getElementById(_chartId);
                        _dailyDataPosition =
                            parseInt(chart.getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK)) + 1;
                    }

                    totalDays = data.items[_dailyDataPosition].periods.length - 1;
                    lastDay = data.items[_dailyDataPosition].periods[0].demandDate;
                    firstDay = data.items[_dailyDataPosition].periods[totalDays].demandDate;

                    _dateText = coned.chartComponents.generateDailyDate(
                        firstDay,
                        lastDay,
                        false,
                        _allMonths
                    );
                    generateChartId($activeTab);

                    if (
                        billRates &&
                        _.invert(peakOptionMobileList[_actualPeriod])[_chartId] === undefined
                    ) {
                        listMobileChartIds();
                    } else {
                        if (peakOptionList.indexOf(_chartId) < 0) {
                            listChartId();
                        }
                    }
                    data = data.items[_dailyDataPosition];
                } else {
                    totalDays = data.items[_dailyDataPosition].periods.length - 1;
                    lastDay = data.items[_dailyDataPosition].periods[0].demandDate;
                    firstDay = data.items[_dailyDataPosition].periods[totalDays].demandDate;

                    _dateText = coned.chartComponents.generateDailyDate(
                        firstDay,
                        lastDay,
                        false,
                        _allMonths
                    );
                    data = data.items[0];
                }
            } else {
                if (_monthlyMobileData[_monthlyMobilePeriod] === undefined) {
                    _monthlyMobileData[_monthlyMobilePeriod] = data.items;
                }

                if (coned.utils.isTablet() || coned.utils.isMobile()) {
                    var total =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition].periods
                                .length - 1,
                        fromDate =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                                .periods[total].endDate,
                        toDate =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                                .periods[0].endDate;

                    _dateText = coned.chartComponents.generateMonthlyDate(
                        fromDate,
                        toDate,
                        _allMonths
                    );

                    data = data.items ? data.items[_monthlyMobilePosition] : data;
                } else {
                    data = data.items[0];
                }
            }
            selectChartData(data);
        };

        /**
         * List the mobile ids in the corresponding list
         */
        var listMobileChartIds = function () {
            var chartId = _chartId,
                regExp = /[A-Za-z]*[0-9]*-[A-Za-z]*[0-9]*/g;
            chartId = chartId.match(regExp)[0];

            if (_dailyPeakMobileIds[_actualPeriod] === undefined) {
                _dailyPeakMobileIds[_actualPeriod] = {};
            }
            _dailyPeakMobileIds[_actualPeriod][_dailyDataPosition] =
                chartId + CONSTANTS.CONED_CHART_PEAK + _dailyDataPosition;

            if (_rateType !== 'E' && _rateType !== 'F') {
                if (_dailyOffPeakMobileIds[_actualPeriod] === undefined) {
                    _dailyOffPeakMobileIds[_actualPeriod] = {};
                }
                _dailyOffPeakMobileIds[_actualPeriod][_dailyDataPosition] =
                    chartId + CONSTANTS.CONED_CHART_OFFPEAK + _dailyDataPosition;
            }

            if (_rateType === 'B') {
                if (_dailyNonSummerMobileIds[_actualPeriod] === undefined) {
                    _dailyNonSummerMobileIds[_actualPeriod] = {};
                }
                _dailyNonSummerMobileIds[_actualPeriod][_dailyDataPosition] =
                    chartId + CONSTANTS.CONED_CHART_NONSUMMER + _dailyDataPosition;
            }
        };

        /**
         * List the desktop ids in the corresponding list
         */
        var listChartId = function () {
            var chartId = _chartId,
                regExp = /[A-Za-z]*[0-9]*-[A-Za-z]*[0-9]*/g,
                periodWeek;

            chartId = chartId.match(regExp)[0];
            periodWeek = coned.utils.isTablet() || coned.utils.isMobile() ? _dailyDataPosition : '';
            _dailyPeakIdsList.push(chartId + CONSTANTS.CONED_CHART_PEAK + periodWeek);
            _dailyOffPeakIdsList.push(chartId + CONSTANTS.CONED_CHART_OFFPEAK + periodWeek);
            _dailyNonSummerIdsList.push(chartId + CONSTANTS.CONED_CHART_NONSUMMER + periodWeek);
        };

        /**
         * Check corresponding radio button option and show/create daily chart
         * @param {String} type   Specified which radio button option is selected
         */
        var showNewChart = function (type) {
            var newChart = true,
                peakOption,
                peakOptionpeakList,
                peakOptionMobileIdsList,
                peakOptionpeakListLength;

            if (_monthlyErrorFlag) {
                query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            }

            if (!isSubscriptionRate()) {
                $conedChartPeakOption.removeAttribute(CONSTANTS.CONED_DISABLED_ATTR);

                if (type === CONSTANTS.CONED_CHART_PEAK) {
                    $conedChartPeakOption.checked = true;
                    $conedChartOffPeakOption.checked = false;
                } else if (type === CONSTANTS.CONED_CHART_OFFPEAK) {
                    $conedChartPeakOption.checked = false;
                    $conedChartOffPeakOption.checked = true;
                } else {
                    $conedChartPeakOption.checked = false;
                    $conedChartOffPeakOption.checked = false;
                    $conedChartNonSummerOption.checked = true;
                }
                (peakOption = peakRadioButtonOption()),
                    (peakOptionpeakList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakIdsList
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakIdsList
                            : _dailyNonSummerIdsList),
                    (peakOptionMobileIdsList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakMobileIds
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakMobileIds
                            : _dailyNonSummerMobileIds);
                peakOptionpeakList =
                    coned.utils.isMobile() || coned.utils.isTablet()
                        ? peakOptionMobileIdsList[0]
                        : peakOptionpeakList;
            } else {
                peakOptionpeakList = _dailyPeakIdsList;
            }

            peakOptionpeakListLength =
                coned.utils.isMobile() || coned.utils.isTablet()
                    ? peakOptionpeakList !== undefined
                        ? Object.keys(peakOptionpeakList).length
                        : 0
                    : peakOptionpeakList.length;

            if (peakOptionpeakListLength > 0) {
                _time = CONSTANTS.CONED_CHART_DAILY;
                newChart = false;
                showChart(peakOptionpeakList[0]);
            }

            if (newChart) {
                getDailyDemand(type, 0);
            }
        };

        /**
         * Calculate fromDate and toDate data for daily chart and call the service
         * @param {String} type    Specified which radio button is selected
         * @param {Int} position   Position of the actual period
         */
        var getDailyDemand = function (type, position) {
            var groupBy;
            _actualPeriod = position;

            if (!isSubscriptionRate()) {
                if (type === CONSTANTS.CONED_CHART_PEAK) {
                    _addPeakClass = CONSTANTS.CONED_CHART_PEAK;
                    $conedChartOffPeakOption.checked = false;
                } else if (type === CONSTANTS.CONED_CHART_OFFPEAK) {
                    _addPeakClass = CONSTANTS.CONED_CHART_OFFPEAK;
                    $conedChartPeakOption.checked = false;
                } else {
                    _addPeakClass = CONSTANTS.CONED_CHART_NONSUMMER;
                    $conedChartOffPeakOption.checked = false;
                    $conedChartPeakOption.checked = false;
                }
            }

            if (coned.utils.isMobile() || coned.utils.isTablet()) {
                groupBy = CONSTANTS.CONED_CHART_GROUPBY_WEEKLY;
            } else {
                groupBy = CONSTANTS.CONED_CHART_GROUPBY_MONTHLY;
            }

            var serviceUrl = $conedChartDateButton.getAttribute(CONSTANTS.CONED_CHART_SERVICE),
                params,
                billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates,
                maid = $conedEnergyContent.getAttribute(CONSTANTS.CONED_CHART_DATA_MAID),
                typeChart;

            _fromDailyPeriod = _allGeneralPeriods[position].billFromDate;
            _toDailyPeriod = _allGeneralPeriods[position].billToDate;

            if (_fixedSubscription) {
                if (_rateType === 'F') {
                    typeChart =
                        billRates.length > 1 ? CONSTANTS.CONED_CHART_TRANSITION : billRates[0];
                } else {
                    typeChart = billRates[0];
                }
            } else {
                typeChart =
                    billRates && billRates.indexOf(type) > -1
                        ? type
                        : CONSTANTS.CONED_CHART_NO_DATA;
            }

            // Service Data
            params = coned.chartComponents.getDailyChart(
                maid,
                _rateType,
                _fromDailyPeriod,
                _toDailyPeriod,
                groupBy,
                _isSolar
            );
            params[CONSTANTS.CONED_CHART_TYPE] = typeChart;

            if (isSubscriptionRate()) {
                params['FixedSubscriptionRate'] =
                    _allGeneralPeriods[_dailyPeriodPosition].billFixedSubscriptionRate;
            }

            // Service Call
            query.getData(
                serviceUrl,
                successGetDailyDemand,
                errorGetDailyDemand,
                params,
                $formLoading
            );
        };

        /**
         * Show error message when service call fail
         */
        var errorGetDailyDemand = function () {
            dataLayer.push({
                event: 'IPP.demand.error.message'
            });

            _errorFlag = true;
            _dateText = coned.chartComponents.generateDailyDate(
                _fromDailyPeriod,
                _toDailyPeriod,
                true,
                _allMonths
            );
            $conedChartDateText.innerHTML = _dateText;

            query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            query.addClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartDateButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
            query.removeClass($conedChartMonthButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
            query.removeClass($conedChartPeakButtons, CONSTANTS.CONED_CHART_BUTTONS_HIDE);

            $conedChartMonthButton.setAttribute(
                CONSTANTS.ARIA_CURRENT,
                false
            );
            $conedChartDateButton.setAttribute(
                CONSTANTS.ARIA_CURRENT,
                true
            );

            var peakOption = peakRadioButtonOption(),
                peakOptionList =
                    peakOption === CONSTANTS.CONED_CHART_PEAK
                        ? _dailyPeakIdsList
                        : _dailyOffPeakIdsList;

            if (_actualPeriod === '0') {
                query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            } else if (_actualDailyPos > 0 && _actualDailyPos - 1 < peakOptionList.length) {
                query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            }
        };

        /**
         * Add to daily list corresponding chart ids
         * @param {Object} data     Data returned by the get-daily service call
         */
        var successGetDailyDemand = function (data) {
            var $activeTab = $ippChartsComponent.getElementsByClassName(
                    CONSTANTS.CONED_CHART_DATE_BUTTON
                )[0],
                peakOption = peakRadioButtonOption(),
                peakOptionList =
                    peakOption === CONSTANTS.CONED_CHART_PEAK
                        ? _dailyPeakIdsList
                        : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                        ? _dailyOffPeakIdsList
                        : _dailyNonSummerIdsList;

            query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            if (coned.utils.isMobile() || coned.utils.isTablet()) {
                if (_nextClick) {
                    var peakchart = document.getElementById(peakOptionList[_actualDailyPos]),
                        fromPeriod = peakchart.getAttribute(CONSTANTS.CONED_CHART_DATA_FROM_DATE),
                        toPeriod = peakchart.getAttribute(CONSTANTS.CONED_CHART_DATA_TO_DATE);

                    _dateText = coned.chartComponents.generateDailyDate(
                        fromPeriod,
                        toPeriod,
                        false,
                        _allMonths
                    );
                    _nextClick = false;
                } else {
                    _dateText = coned.chartComponents.generateDailyDate(
                        _fromDailyPeriod,
                        _toDailyPeriod,
                        false,
                        _allMonths
                    ); // mobile
                }
            } else {
                _dateText = coned.chartComponents.generateDailyDate(
                    _fromDailyPeriod,
                    _toDailyPeriod,
                    true,
                    _allMonths
                ); // desktop
            }

            listMobileChartKeysIds();
            generateChartId($activeTab);

            if (peakOptionList[_actualDailyPos]) {
                _chartId = peakOptionList[_actualDailyPos];
                allowArrows(_chartId);
            }

            if (!coned.utils.isMobile() && !coned.utils.isTablet()) {
                if (peakOptionList.indexOf(_chartId) < 0) {
                    listChartId();
                }
            }

            if (coned.utils.isPatternLab()) {
                query.getData(_jsonFile, generateChart, errorGetBillingPeriods);
            } else {
                generateChart(data);
            }
        };

        /**
         * Generate the daily charts in mobile devices
         * @param {Object} data      Data returned by the service call
         */
        var selectChartData = function (data) {
            var chart = document.getElementById(_chartId),
                allMonthsData;

            if (_time === CONSTANTS.CONED_CHART_DAILY) {
                var subGeneralDailyData = (_generalDailyData[_chartId] = {});

                for (var index = 0; index < data.periods.length; index++) {
                    var day = data.periods[index].label;
                    subGeneralDailyData[day] = [
                        data.periods[index].measuredTime,
                        data.periods[index].specialDay
                    ];
                }
            } else {
                _monthlyMobilePosition++;
            }

            query.addClass($conedChartPeakButtons, CONSTANTS.CONED_CHART_BUTTONS_HIDE);

            allowArrows(_chartId);

            if (chart) {
                showChart(_chartId);
            } else {
                _yAxis = data.yAxis;
                _fixedSubscriptionValues = data.fixedSubscriptionYAxis;
                allMonthsData = data.periods;

                if (_fixedSubscriptionValues) {
                    for (var pos = 0; pos < _fixedSubscriptionValues.length; pos++) {
                        var elemPos = _yAxis.indexOf(_fixedSubscriptionValues[pos]);
                        if (elemPos > -1) {
                            _yAxis.splice(elemPos, 1);
                        }
                    }
                }

                // Hide current chart before the service call
                var $conedActualChart = document.getElementById(_actualChart);
                query.addClass($conedActualChart, CONSTANTS.CONED_CHART_HIDE_CLASS);

                getLegendText();
                getGeneralData(allMonthsData.reverse());
            }
        };

        /**
         * Display the corresponding arrows for the actual chart
         * @param {String} chartId      Id of the actual chart
         */
        var allowArrows = function (chartId) {
            if (_time === CONSTANTS.CONED_CHART_DAILY) {
                var peakOption = peakRadioButtonOption(),
                    peakOptionpeakList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakIdsList
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakIdsList
                            : _dailyNonSummerIdsList,
                    billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates,
                    peakOptionMobileList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakMobileData
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakMobileData
                            : _dailyNonSummerMobileData,
                    peakOptionMobileIdsList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakMobileIds
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakMobileIds
                            : _dailyNonSummerMobileIds,
                    position =
                        coned.utils.isMobile() || coned.utils.isTablet()
                            ? _dailyPeriodPosition
                            : peakOptionpeakList.indexOf(chartId),
                    lastWeek =
                        (coned.utils.isMobile() || coned.utils.isTablet()) &&
                        billRates &&
                        peakOptionMobileList[position] &&
                        peakOptionMobileList[position].items[_dailyDataPosition + 1]
                            ? false
                            : true;

                if (position < _IPPPeriods - 1 || !lastWeek) {
                    query.removeClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                } else {
                    if (coned.utils.isMobile() || coned.utils.isTablet()) {
                        var dailyMobileData,
                            moreWeeks,
                            lastPositionKey =
                                Object.keys(peakOptionMobileIdsList[_actualPeriod]).length - 1;

                        if (billRates) {
                            dailyMobileData =
                                peakOption === CONSTANTS.CONED_CHART_PEAK
                                    ? _dailyPeakMobileIds
                                    : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                                    ? _dailyOffPeakMobileIds
                                    : _dailyNonSummerMobileIds;
                            moreWeeks =
                                dailyMobileData[_actualPeriod][_dailyDataPosition + 1] ||
                                dailyMobileData[_actualPeriod + 1]
                                    ? true
                                    : false;
                        } else {
                            dailyMobileData =
                                peakOption === CONSTANTS.CONED_CHART_PEAK
                                    ? _dailyPeakMobileData
                                    : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                                    ? _dailyOffPeakMobileData
                                    : _dailyNonSummerMobileData;
                            moreWeeks = dailyMobileData[_dailyPeriodPosition]
                                ? dailyMobileData[_dailyPeriodPosition].items.length - 1 >
                                  _dailyDataPosition
                                : false;
                        }

                        if (
                            _morePeriods ||
                            moreWeeks ||
                            peakOptionMobileIdsList[_actualPeriod][lastPositionKey] !== chartId
                        ) {
                            //mobile
                            query.removeClass(
                                $conedChartLastDate,
                                CONSTANTS.CONED_CHART_ARROW_HIDE
                            );
                        } else {
                            query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                        }
                    } else {
                        if (
                            _morePeriods &&
                            peakOptionpeakList.indexOf(chartId) === _IPPPeriods - 1
                        ) {
                            query.removeClass(
                                $conedChartLastDate,
                                CONSTANTS.CONED_CHART_ARROW_HIDE
                            );
                        } else {
                            query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                        }
                    }
                }

                // right arrow
                if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                    if (
                        _actualPeriod > 0 ||
                        _.invert(peakOptionMobileIdsList[_actualPeriod])[_chartId] > 0
                    ) {
                        query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    } else {
                        query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    }
                } else {
                    if (peakOptionpeakList.indexOf(chartId) > 0) {
                        query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    } else {
                        query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    }
                }
            } else {
                if (coned.utils.isTablet() || coned.utils.isMobile()) {
                    if (
                        !_lastPeriod ||
                        _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition] ||
                        _monthlyIdsList.indexOf(chartId) < _monthlyIdsList.length - 1
                    ) {
                        query.removeClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    } else {
                        query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    }
                } else {
                    if (
                        !_lastPeriod ||
                        _monthlyIdsList.indexOf(chartId) < _monthlyIdsList.length - 1
                    ) {
                        query.removeClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                        focusChartArrow($conedChartNextDate, $conedChartLastDate);
                    } else {
                        query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                        focusChartArrow($conedChartLastDate, $conedChartNextDate);
                    }
                }

                // right arrow
                if (_monthlyIdsList.indexOf(chartId) > 0) {
                    query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    focusChartArrow($conedChartLastDate, $conedChartNextDate);
                } else {
                    query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    focusChartArrow($conedChartNextDate, $conedChartLastDate);
                }
            }
        };

        /**
         * Focus the corresponding chart arrow on arrow's visibility change for a11y
         * @param {HTMLElemenString} $arrowToCheck Arrow for which to check visibililty and if is the active element
         * @param {HTMLElemenString} $arrowToFocus Arrow to focus accordingly
         */
        var focusChartArrow = function ($arrowToCheck, $arrowToFocus) {
            if (query.hasClass($arrowToCheck, CONSTANTS.CONED_CHART_ARROW_HIDE) && document.activeElement === $arrowToCheck) {
                $arrowToFocus.focus();
            }
        };

        /**
         * Show the chart and enable the corresponding buttons
         * @param {Object} idName     Chart id to be shown
         */
        var showChart = function (idName) {
            _lastChart = _actualChart;
            _actualChart = idName;
            _chartId = _actualChart;

            var $conedActualChart = document.getElementById(_actualChart);
            var $conedLastChart = document.getElementById(_lastChart);

            query.removeClass($conedActualChart, CONSTANTS.CONED_CHART_HIDE_CLASS);

            if (_lastChart != _actualChart) {
                query.addClass($conedLastChart, CONSTANTS.CONED_CHART_HIDE_CLASS);
            }

            _dateText = $conedActualChart.getAttribute(CONSTANTS.CONED_CHART_DATE_TEXT);
            _actualPeriod = parseInt(
                $conedActualChart.getAttribute(CONSTANTS.CONED_CHART_DATA_PERIOD)
            );

            if (_time === CONSTANTS.CONED_CHART_DAILY) {
                query.addClass($conedChartDateButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                query.removeClass($conedChartMonthButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                $conedChartMonthButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                $conedChartDateButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    true
                );
                query.removeClass($conedChartPeakButtons, CONSTANTS.CONED_CHART_BUTTONS_HIDE);
            } else {
                query.addClass($conedChartMonthButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                query.removeClass($conedChartDateButton, CONSTANTS.CONED_CHART_BUTTON_DISABLED);
                $conedChartDateButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                $conedChartMonthButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    true
                );
                query.addClass($conedChartPeakButtons, CONSTANTS.CONED_CHART_BUTTONS_HIDE);
            }

            var isErrorChart = document
                .getElementById(idName)
                .classList.contains(CONSTANTS.CONED_CHART_ERROR);

            if (isErrorChart) {
                query.addClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNoDataMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            } else {
                query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.addClass($conedChartNoDataMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            }

            setChartDate();
            allowArrows(_actualChart);
            coned.chart.fixChartHeight(_chartId);
        };

        /**
         * Get Legent information
         */
        var getLegendText = function () {
            var peakRadioButtonOption = query.getFormInputValue(
                    $ippChartsComponent,
                    CONSTANTS.CONED_CHART_RADIO_OPTION
                ),
                peakOption = peakRadioButtonOption
                    ? peakRadioButtonOption
                    : CONSTANTS.CONED_CHART_DAILY,
                isSeasonal = _rateType === 'F' || _rateType === 'B',
                isMonthly = _time === CONSTANTS.CONED_CHART_MONTHLY,
                isDaily = _time === CONSTANTS.CONED_CHART_DAILY,
                subscriptionType = isSubscriptionRate()
                    ? 'subscriptionRate'
                    : 'nonSubscriptionRate',
                legendList = isMonthly ? _dataMonthlyNames : _dataDailyNames,
                timeButton = isMonthly ? $conedChartMonthButton : $conedChartDateButton,
                peakButton =
                    peakOption === CONSTANTS.CONED_CHART_PEAK
                        ? $conedChartPeakOption
                        : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                        ? $conedChartOffPeakOption
                        : peakOption === CONSTANTS.CONED_CHART_NONSUMMER
                        ? $conedChartNonSummerOption
                        : $conedChartDateButton,
                optionButton =
                    (isSubscriptionRate() && isDaily) || isMonthly ? timeButton : peakButton,
                dataAttributesLegend = _legendDataAttributes[_time][subscriptionType]['legend'],
                dataAttributesTooltip = _legendDataAttributes[_time][subscriptionType]['tooltip'],
                dataAttributes = Object.keys(dataAttributesLegend);

            for (var index = 0; index < dataAttributes.length; index++) {
                var dataLegend = dataAttributes[index],
                    rateType =
                        dataLegend.match('overageDeterminant') ||
                        dataLegend.match('billedOverage') ||
                        dataLegend.match('unbilledOverage')
                            ? _rateType
                            : '';
                legendList[dataLegend + rateType] =
                    optionButton.getAttribute(dataAttributesLegend[dataLegend]) +
                    ' (' +
                    _kilowatt_symbol +
                    ')';
                _legendTooltip[dataLegend + rateType] = optionButton.getAttribute(
                    dataAttributesTooltip[dataLegend]
                );
            }

            legendList.billingCycleTable = $conedChartTable.dataset.billingCycleLegend;
            legendList.dateTable = $conedChartTable.dataset.dateLegend;

            if (isSeasonal && isMonthly) {
                dataAttributesLegend = _legendDataAttributes[_time][_rateType]['legend'];
                dataAttributesTooltip = _legendDataAttributes[_time][_rateType]['tooltip'];
                dataAttributes = Object.keys(dataAttributesLegend)[0];

                legendList[dataAttributes] =
                    timeButton.getAttribute(dataAttributesLegend[dataAttributes]) +
                    ' (' +
                    _kilowatt_symbol +
                    ')';
                _legendTooltip[dataAttributes] = timeButton.getAttribute(
                    dataAttributesTooltip[dataAttributes]
                );
            }
        };

        /**
         * Get all the general information to create the chart
         * @param {Array} allMonthsData   Array with all the periods information
         */
        var getGeneralData = function (allMonthsData) {
            var types = Object.keys(allMonthsData[0]),
                dataKeys = Object.keys(allMonthsData[0]),
                colorsObj = {},
                lastPosition;

            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                _hasTotalAverageValue =
                    types.indexOf(CONSTANTS.CONED_CHART_TOTAL_AVERAGE_DEMAND_VALUE) > -1
                        ? true
                        : false;
                lastPosition = _hasTotalAverageValue ? types.length - 5 : types.length - 4;
                types.splice(0, 3);
                types.splice(lastPosition);
            } else {
                lastPosition = types.length;
                types = types.slice(4, lastPosition);
            }

            _fixedSubscription =
                types.indexOf(CONSTANTS.CONED_CHART_FIXED_SUBSCRIPTION) > 0 ? true : false;

            if (_type === CONSTANTS.CONED_CHART_DEMAND && _time === CONSTANTS.CONED_CHART_DAILY) {
                query.removeClass($conedChartPeakButtons, CONSTANTS.CONED_CHART_BUTTONS_HIDE);
            }

            if (_time === CONSTANTS.CONED_CHART_MONTHLY && _rateType === 'F') {
                types.splice(1, 0, types.pop());
            }

            var typesLength = types.length;

            if (_time === CONSTANTS.CONED_CHART_DAILY && _fixedSubscription) {
                types[typesLength - 1] = types[typesLength - 1] + _rateType;
                types[typesLength - 2] =
                    _rateType === 'F' ? types[typesLength - 2] + _rateType : types[typesLength - 2];
            }

            // set chart legend colors
            colorsObj = setChartColors(colorsObj, types);

            // create array with the chart data
            var monthsData = Object.keys(allMonthsData).map(function (e) {
                    return allMonthsData[e];
                }),
                startDate,
                endDate,
                initialDay,
                finalDay;
            _monthLine = '';
            _yearLineText = '';

            for (var month = 0; month < monthsData.length; month++) {
                if (monthsData[month].splitLabel != '') {
                    _monthLine = monthsData[month].label;
                    _yearLineText = monthsData[month].splitLabel;
                }

                startDate = monthsData[month].beginDate;
                endDate = monthsData[month].endDate;

                if (startDate && endDate) {
                    startDate = startDate.replace(/T[\s\S]*/g, 'T12:00:00');
                    endDate = endDate.replace(/T[\s\S]*/g, 'T12:00:00');
                    startDate = new Date(startDate);
                    endDate = new Date(endDate);
                    startDate.setDate(startDate.getDate() + 1);

                    initialDay =
                        _allMonths[startDate.getMonth()] +
                        ' ' +
                        startDate.getDate() +
                        ', ' +
                        startDate.getFullYear();
                    finalDay =
                        _allMonths[endDate.getMonth()] +
                        ' ' +
                        endDate.getDate() +
                        ', ' +
                        endDate.getFullYear();

                    _monthlyPeriodsList[monthsData[month].label] = initialDay + ' - ' + finalDay;
                }
            }

            setChartDate();
            createChart(colorsObj, dataKeys, lastPosition, monthsData, types);
        };

        /**
         * Select all the necessary data to create the chart the chart and call the corresponding function
         * @param {Object} colorsObj      Contains the color for each chart data
         * @param {Array} dataKeys        Array with the chart data series
         * @param {Int} lastPosition      Amount of chart data
         * @param {Array} monthsData      All chart data
         * @param {Array} types           Contains all chart data names
         *
         */
        var createChart = function (colorsObj, dataKeys, lastPosition, monthsData, types) {
            var chartData = [],
                columnsData = [];

            for (var index = 0; index < dataKeys.length; index++) {
                columnsData.push(dataKeys[index]);

                for (var x = 0; x < monthsData.length; x++) {
                    var chartKeys = monthsData[x],
                        keys = Object.keys(chartKeys);

                    keys.forEach(function (key) {
                        if (key === dataKeys[index]) {
                            columnsData.push(monthsData[x][key]);
                        }
                    });

                    var totalAverage = monthsData[x].totalAverageDemandValue;

                    if (totalAverage && totalAverage !== 0) {
                        var regexTruncateNumber = /[0-9]*(.[0-9])/g,
                            matchNumber = totalAverage.toString().match(regexTruncateNumber);
                        _totalAverageValue[x] = matchNumber ? matchNumber[0] : totalAverage + '.0';
                    }
                }

                chartData.push(columnsData);
                columnsData = [];
            }

            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                lastPosition = _hasTotalAverageValue ? chartData.length - 4 : chartData.length - 3;
                chartData.splice(0, 2);
                chartData.splice(lastPosition);
                createBarChart(_time, _type, types, chartData, colorsObj);
            } else {
                lastPosition = chartData.length;
                chartData = chartData.slice(3, lastPosition);

                var determinant = isSubscriptionRate() ? chartData[3] : chartData[2],
                    values = chartData[1],
                    peakType = isSubscriptionRate()
                        ? ''
                        : query.getFormInputValue(
                              $ippChartsComponent,
                              CONSTANTS.CONED_CHART_RADIO_OPTION
                          );
                determinant[0] = peakType + determinant[0];

                for (var i = 1; i < values.length; i++) {
                    determinant[i] = determinant[i] === false ? null : values[i];
                }

                if (_fixedSubscription) {
                    var overageDeterminant = chartData[4],
                        actualDemand = chartData[1];
                    overageDeterminant[0] = overageDeterminant[0] + _rateType;

                    for (var position = 1; position < actualDemand.length; position++) {
                        overageDeterminant[position] =
                            overageDeterminant[position] === false ? null : values[position];
                    }

                    if (_rateType === 'F') {
                        var billedOverage = chartData[5];
                        billedOverage[0] = billedOverage[0] + _rateType;

                        for (
                            var positionRate6 = 1;
                            positionRate6 < actualDemand.length;
                            positionRate6++
                        ) {
                            billedOverage[positionRate6] =
                                billedOverage[positionRate6] === false
                                    ? null
                                    : values[positionRate6];
                        }
                    }
                }
                createLineChart(_time, types, chartData, colorsObj);
            }

            return chartData;
        };

        /**
         * Create a new bar chart
         * @param {String} _time        Type monthly or daily
         * @param {String} _typeData    Type of chart (demand)
         * @param {String} types        Chart data names
         * @param {Array} chartData     All chart data
         * @param {Array} colorsObj     Contains the color for each chart data
         */
        var createBarChart = function (_time, _typeData, types, chartData, colorsObj) {
            var dataType,
                lineArray = coned.chart.yAxisLines(
                    _fixedSubscription,
                    _fixedSubscriptionValues,
                    _yAxis,
                    _kilowatt_symbol
                ),
                size,
                textLineChart = 'text-line-year',
                initialLine = _monthLine
                    ? [{ value: _monthLine, class: textLineChart, text: _yearLineText }]
                    : [],
                chart_left_padding = coned.utils.isMobile() || coned.utils.isTablet() ? 0.25 : 0.75,
                $actualChart,
                chartInfo,
                dataNames =
                    _time === CONSTANTS.CONED_CHART_MONTHLY ? _dataMonthlyNames : _dataDailyNames,
                peakOption = peakRadioButtonOption(),
                legendType =
                    peakOption === CONSTANTS.CONED_CHART_PEAK
                        ? 'actualDemand'
                        : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                        ? 'offPeakDemand'
                        : 'nonSummerDemand',
                truncateTotal = isSubscriptionRate(),
                totalHoverText = $conedChartMonthButton.getAttribute(
                    CONSTANTS.CONED_CHART_TOTAL_AVERAGE_DEMAND
                );

            _lastChart = _actualChart;
            _actualChart = _chartId;

            if (_rateType === 'E') {
                dataType = ['unbilledSubscriptionOverageValue', 'averageDemandValue'];
                size = coned.utils.isMobile() ? 22 : 24;
            } else if (_rateType === 'F') {
                dataType = [
                    'unbilledSubscriptionOverageValue',
                    'billedSubscriptionOverageValue',
                    'averageDemandValue'
                ];
                size = coned.utils.isMobile() ? 22 : 24;
            } else {
                dataType = [];
                size = coned.utils.isMobile() ? 22 : 17;
            }

            d3.select('.coned-chart__container').insert('div').attr('id', _chartId);
            $actualChart = document.getElementById(_chartId);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATE_TEXT, _dateText);

            if (isSubscriptionRate()) {
                _allTotalAverageValue[_chartId] = _totalAverageValue;
                _totalAverageValue = {};
            }

            chartInfo = {
                chartId: _chartId,
                chartLeftPadding: coned.utils.isMobile() || coned.utils.isTablet() ? 20 : 11,
                chartData: chartData,
                colorsObj: colorsObj,
                dataType: dataType,
                xAxisLeftPadding: chart_left_padding,
                yAxis: _yAxis,
                kilowattSymbol: _kilowatt_symbol,
                dollarSymbol: '',
                lineArray: lineArray,
                initialLine: initialLine,
                allTotalAverageValue: _allTotalAverageValue,
                dataMonthlyNames: _dataMonthlyNames,
                monthlyPeriodsList: _monthlyPeriodsList,
                size: size
            };

            _chart = coned.chart.createBarChart(
                CONSTANTS.CONED_CHART_IPP_OPTION,
                chartInfo,
                truncateTotal,
                totalHoverText,
                _monthlyIdsList.length,
                dataNames,
                _fixedSubscriptionValues
            );
            var regExp = _time === 'daily' ? /-([A-Z])*\w+/g : /-([0-9]{4})/g,
                maxValueLength = _yAxis[_yAxis.length - 1].length,
                minValueLength = _yAxis[0].length,
                amountDigits = maxValueLength > minValueLength ? maxValueLength : minValueLength,
                isMonthly = _time === CONSTANTS.CONED_CHART_MONTHLY;

            coned.chart.addLegend(
                types,
                _chart,
                _chartId,
                dataNames,
                _legendTooltip,
                legendType,
                peakOption,
                _rateType,
                undefined,
                isMonthly
            );
            coned.chart.fixChartHeight(_chartId);
            coned.chart.initializeTooltips(
                CONSTANTS.CONED_CHART_IPP_OPTION,
                _chartId,
                'coned-chart-tooltip'
            );
            coned.chart.deleteYearRect(_chartId);

            _chart.resize();

            coned.chart.createRect(_chartId, isSubscriptionRate(), amountDigits);
            coned.chart.fixYearLine(_chartId);
            coned.chart.resizeWindow(_chartId, _chart, regExp, isSubscriptionRate(), amountDigits);

            //Set focusable=false into svg for screen reader.
            var svgElement = $actualChart.querySelector('svg');
            svgElement && (svgElement.setAttribute('focusable', false), 
                svgElement.setAttribute('aria-hidden', true) );
        };

        /**
         * Create a new line chart
         * @param {String} _time        Type monthly or daily
         * @param {String} types        Chart data names
         * @param {Array} chartData     All chart data
         * @param {Array} colorsObj     Contains the color for each chart data
         */
        var createLineChart = function (_time, types, chartData, colorsObj) {
            _lastChart = _actualChart;
            _actualChart = _chartId;

            d3.select('.coned-chart__container').insert('div').attr('id', _chartId);

            var $actualChart = document.getElementById(_chartId),
                peakClass,
                linesArray = coned.chart.yAxisLines(
                    _fixedSubscription,
                    _fixedSubscriptionValues,
                    _yAxis,
                    _kilowatt_symbol
                ),
                maxValueLength = _yAxis[_yAxis.length - 1].length,
                minValueLength = _yAxis[0].length,
                amountDigits = maxValueLength > minValueLength ? maxValueLength : minValueLength;

            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATE_TEXT, _dateText);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATA_FROM_DATE, _fromDailyPeriod);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATA_TO_DATE, _toDailyPeriod);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATA_PERIOD, _dailyPeriodPosition);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATA_WEEK, _dailyDataPosition);

            peakClass =
                _addPeakClass === CONSTANTS.CONED_CHART_NONSUMMER
                    ? 'coned-line-nonSummerChart'
                    : _addPeakClass === CONSTANTS.CONED_CHART_OFFPEAK
                    ? 'coned-line-offPeakChart'
                    : 'coned-line-peakChart';

            var xAxisLeftPadding =
                    coned.utils.isMobile() || coned.utils.isTablet()
                        ? 0.4
                        : (xAxisLeftPadding = 1.5),
                chartLeftPadding = coned.utils.isMobile() || coned.utils.isTablet() ? 20 : 11,
                peakOption = peakRadioButtonOption(),
                chartInfo = {
                    chartId: _chartId,
                    chartLeftPadding: chartLeftPadding,
                    chartData: chartData,
                    colorsObj: colorsObj,
                    peakClass: peakClass,
                    generalDailyData: _generalDailyData,
                    allCompleteMonths: _allCompleteMonths,
                    xAxisLeftPadding: xAxisLeftPadding,
                    yAxis: _yAxis,
                    linesArray: linesArray,
                    kilowattSymbol: _kilowatt_symbol
                },
                zeroValueMessage,
                specialDayMessage,
                regularDayMessage;

            if (peakOption === CONSTANTS.CONED_CHART_OFFPEAK) {
                zeroValueMessage = $conedChartOffPeakOption.getAttribute(
                    CONSTANTS.CONED_CHART_DATA_ZERO_HOVER
                );
                regularDayMessage = $conedChartOffPeakOption.getAttribute(
                    CONSTANTS.CONED_CHART_DATA_REGULAR_HOVER
                );
                specialDayMessage = '';
            } else if (peakOption === CONSTANTS.CONED_CHART_PEAK) {
                if (_rateType === 'E' || _rateType === 'F') {
                    zeroValueMessage = $conedChartDateButton.getAttribute(
                        CONSTANTS.CONED_CHART_DATA_ZERO_HOVER
                    );
                    regularDayMessage = $conedChartDateButton.getAttribute(
                        CONSTANTS.CONED_CHART_DATA_REGULAR_HOVER
                    );
                    specialDayMessage =
                        _rateType === 'E'
                            ? ''
                            : $conedChartDateButton.getAttribute(
                                  CONSTANTS.CONED_CHART_DATA_SPECIAL_HOVER
                              );
                } else if (_rateType === 'A' || _rateType === 'C' || _rateType === '2') {
                    zeroValueMessage = $conedChartPeakOption.getAttribute(
                        CONSTANTS.CONED_CHART_DATA_SPECIAL_HOVER
                    );
                    regularDayMessage = $conedChartPeakOption.getAttribute(
                        CONSTANTS.CONED_CHART_DATA_REGULAR_HOVER
                    );
                    specialDayMessage = $conedChartPeakOption.getAttribute(
                        CONSTANTS.CONED_CHART_DATA_SPECIAL_HOVER
                    );
                } else {
                    zeroValueMessage = $conedChartPeakOption.getAttribute(
                        CONSTANTS.CONED_CHART_DATA_ZERO_HOVER
                    );
                    regularDayMessage = $conedChartPeakOption.getAttribute(
                        CONSTANTS.CONED_CHART_DATA_REGULAR_HOVER
                    );
                    specialDayMessage =
                        _rateType !== 'B'
                            ? $conedChartPeakOption.getAttribute(
                                  CONSTANTS.CONED_CHART_DATA_SPECIAL_HOVER
                              )
                            : '';
                }
            } else {
                zeroValueMessage = $conedChartNonSummerOption.getAttribute(
                    CONSTANTS.CONED_CHART_DATA_ZERO_HOVER
                );
                regularDayMessage = $conedChartNonSummerOption.getAttribute(
                    CONSTANTS.CONED_CHART_DATA_REGULAR_HOVER
                );
                specialDayMessage = '';
            }

            _chart = coned.chart.createLineChart(
                CONSTANTS.CONED_CHART_IPP_OPTION,
                chartInfo,
                zeroValueMessage,
                specialDayMessage,
                regularDayMessage,
                isSubscriptionRate(),
                undefined,
                _dataDailyNames,
                _fixedSubscriptionValues
            );

            if (chartData.length > 0) {
                var dataNames =
                        _time === CONSTANTS.CONED_CHART_MONTHLY
                            ? _dataMonthlyNames
                            : _dataDailyNames,
                    legendType =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? 'actualDemand'
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? 'offPeakDemand'
                            : 'nonSummerDemand',
                    isMonthly = _time === CONSTANTS.CONED_CHART_MONTHLY;

                coned.chart.addLegend(
                    types,
                    _chart,
                    _chartId,
                    dataNames,
                    _legendTooltip,
                    legendType,
                    peakOption,
                    _rateType,
                    undefined,
                    isMonthly
                );
                coned.chart.fixChartHeight(_chartId);
                coned.chart.initializeTooltips(
                    CONSTANTS.CONED_CHART_IPP_OPTION,
                    _chartId,
                    'coned-chart-tooltip'
                );
            }
            coned.chart.deleteYearRect(_chartId);
            coned.chart.resizeChart(_chartId, _chart, isSubscriptionRate(), amountDigits);
            var regExp = _time === 'daily' ? /-([A-Z])*\w+/g : /-([0-9]{4})/g;
            coned.chart.fixXaxisValues(_chartId, regExp);

            //Set focusable=false into svg for screen reader.
            var svgElement = $actualChart.querySelector('svg');
            svgElement && (svgElement.setAttribute('focusable', false), 
                svgElement.setAttribute('aria-hidden', true) );
        };

        /**
         * Set colors object for the chart legend
         * @param {Object} colorsObj    Contains the color for each chart data
         * @param {Array} types         Chart data names
         */
        var setChartColors = function (colorsObj, types) {
            var colors;

            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                if (_type === CONSTANTS.CONED_CHART_DEMAND) {
                    colors =
                        _rateType === 'B'
                            ? _demandMonthlyColorsRate2
                            : _rateType === 'E'
                            ? _monthlyColorsRateE
                            : _rateType === 'F'
                            ? _monthlyColorsRateF
                            : _demandMonthlyColors;
                }

                for (var index = 0; index < types.length; index++) {
                    colorsObj[types[index]] = colors[index];
                }
            } else {
                if (_type === CONSTANTS.CONED_CHART_USAGE) {
                    colors = [CONSTANTS.CONED_CHART_BLUE_COLOR];
                } else {
                    if (_rateType === 'E') {
                        colors = _dailyColorsRateE;
                    } else if (_rateType === 'F') {
                        colors = _dailyColorsRateF;
                    } else {
                        var peakOption = peakRadioButtonOption();

                        if (peakOption === CONSTANTS.CONED_CHART_PEAK) {
                            colors = [
                                CONSTANTS.CONED_CHART_PEAK_COLOR,
                                CONSTANTS.CONED_CHART_WHITE_COLOR
                            ];
                        } else if (peakOption === CONSTANTS.CONED_CHART_OFFPEAK) {
                            colors = [
                                CONSTANTS.CONED_CHART_OFFPEAK_COLOR,
                                CONSTANTS.CONED_CHART_WHITE_COLOR
                            ];
                        } else {
                            colors = [
                                CONSTANTS.CONED_CHART_NONSUMMER_COLOR,
                                CONSTANTS.CONED_CHART_WHITE_COLOR
                            ];
                        }
                    }
                }

                for (var indexDaily = 0; indexDaily < types.length; indexDaily++) {
                    colorsObj[types[indexDaily]] = colors[indexDaily];
                }
            }

            return colorsObj;
        };

        /**
         * Set date text on billyng cycle
         */
        var setChartDate = function () {
            query.removeClass($conedChartModule, CONSTANTS.CONED_HIDDEN_CLASS);
            $conedChartDateText.innerHTML = _dateText;
        };

        /**
         * Create daily peak - offpeak chart
         * @param {String} type     Radio button option
         */
        var createPeakChart = function (type) {
            var peak =
                    type === CONSTANTS.CONED_CHART_OFFPEAK
                        ? CONSTANTS.CONED_CHART_OFF_PEAK
                        : type === CONSTANTS.CONED_CHART_NONSUMMER
                        ? CONSTANTS.CONED_CHART_NONSUMMER_PEAK
                        : type,
                chart,
                chartId,
                position = _actualDailyPos,
                peakOption = peakRadioButtonOption(),
                dailyMobileData =
                    peakOption === CONSTANTS.CONED_CHART_PEAK
                        ? _dailyPeakMobileData
                        : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                        ? _dailyOffPeakMobileData
                        : _dailyNonSummerMobileData,
                periodExists = dailyMobileData[_dailyPeriodPosition] ? true : false,
                $activeTab = $ippChartsComponent.getElementsByClassName(
                    CONSTANTS.CONED_CHART_DATE_BUTTON
                )[0],
                billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates;

            dataLayer.push({
                event: 'IPP.demand.peak.offpeak.view',
                PeakLevel: peak
            });

            coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);

            if (_errorFlag) {
                query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);

                allowArrows(_chartId);
            }

            if (type === CONSTANTS.CONED_CHART_NONSUMMER) {
                _addPeakClass = CONSTANTS.CONED_CHART_NONSUMMER;
                $conedChartPeakOption.checked = false;
                $conedChartOffPeakOption.checked = false;
                if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                    if (_dailyNonSummerMobileIds[_actualPeriod]) {
                        chartId = _dailyNonSummerMobileIds[_actualPeriod][_dailyDataPosition];
                    }
                } else {
                    chartId = _dailyNonSummerIdsList[position];
                }
            } else if (type === CONSTANTS.CONED_CHART_OFFPEAK) {
                _addPeakClass = CONSTANTS.CONED_CHART_OFFPEAK;
                $conedChartPeakOption.checked = false;
                if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                    if (_dailyOffPeakMobileIds[_actualPeriod]) {
                        chartId = _dailyOffPeakMobileIds[_actualPeriod][_dailyDataPosition];
                    }
                } else {
                    chartId = _dailyOffPeakIdsList[position];
                }
            } else {
                _addPeakClass = CONSTANTS.CONED_CHART_PEAK;
                $conedChartOffPeakOption.checked = false;
                position = position === -1 ? 0 : position;
                if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                    if (_dailyPeakMobileIds[_actualPeriod]) {
                        chartId = _dailyPeakMobileIds[_actualPeriod][_dailyDataPosition];
                    }
                } else {
                    chartId = _dailyPeakIdsList[position];
                }
            }

            chart = document.getElementById(chartId);
            if (chart) {
                showChart(chartId);
            } else {
                if (periodExists) {
                    generateChartId($activeTab);
                    generateChart(dailyMobileData[_dailyPeriodPosition]);
                } else {
                    if (_allGeneralPeriods[_dailyPeriodPosition]) {
                        getDailyDemand(type, _dailyPeriodPosition);
                    } else {
                        getBillingPeriods();
                    }
                }
            }
            _errorFlag = false;
        };

        /**
         * Set all mobile chart ids in the corresponding list
         */
        var listMobileChartKeysIds = function () {
            _dailyPeakMobileIds[_actualPeriod.toString()] =
                _dailyPeakMobileIds[_actualPeriod.toString()] !== undefined
                    ? _dailyPeakMobileIds[_actualPeriod.toString()]
                    : {};
            _dailyOffPeakMobileIds[_actualPeriod.toString()] =
                _dailyOffPeakMobileIds[_actualPeriod.toString()] !== undefined
                    ? _dailyOffPeakMobileIds[_actualPeriod.toString()]
                    : {};
            _dailyNonSummerMobileIds[_actualPeriod.toString()] =
                _dailyNonSummerMobileIds[_actualPeriod.toString()] !== undefined
                    ? _dailyNonSummerMobileIds[_actualPeriod.toString()]
                    : {};
        };

        /**
         * Functionality for the backward chart arrow
         * @param {Object} event   Event name
         */
        var lastDateChart = function (event) {
            event.preventDefault();

            coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);

            dataLayer.push({
                event: 'IPP.demand.navigation.billing',
                label: 'Backward arrow'
            });

            var showChartId, chartIndex, chart, count, chartId, nextChartPeriod, $activeTab;

            _generalLoad = true;

            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                var total, fromDate, toDate;

                if (_monthlyIdsList.indexOf(_actualChart) === _monthlyIdsList.length - 1) {
                    count = 12;
                    if (
                        _monthlyMobileData[_monthlyMobilePeriod] &&
                        _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition] &&
                        (coned.utils.isMobile() || coned.utils.isTablet())
                    ) {
                        _monthlyMobilePeriod = _monthlyMobileData[_monthlyMobilePeriod][
                            _monthlyMobilePosition
                        ]
                            ? _monthlyMobilePeriod
                            : _monthlyMobilePeriod++;
                        _monthlyMobilePosition = _monthlyMobileData[_monthlyMobilePeriod][
                            _monthlyMobilePosition
                        ]
                            ? _monthlyMobilePosition
                            : 0;
                        $activeTab = $ippChartsComponent.getElementsByClassName(
                            CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS
                        )[0];

                        total =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition].periods
                                .length - 1;
                        fromDate =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                                .periods[total].beginDate;
                        toDate =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                                .periods[0].endDate;
                        _dateText = coned.chartComponents.generateMonthlyDate(
                            fromDate,
                            toDate,
                            _allMonths
                        );
                        generateChartId($activeTab);
                        _monthlyIdsList.push(_chartId);
                        generateChart(
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                        );
                    } else {
                        if (_allPeriods.length > 0) {
                            total = _allPeriods.length - 1;
                            fromDate = _allPeriods[total];
                            toDate = _allPeriods[0];

                            _dateText = coned.chartComponents.generateMonthlyDate(
                                fromDate,
                                toDate,
                                _allMonths
                            );
                            _monthlyMobilePeriod++;
                            _monthlyMobilePosition = 0;
                            callMonthlyService();
                        } else {
                            _indexBillingPeriod = _indexBillingPeriod + count;
                            _monthlyMobilePeriod++;
                            _monthlyMobilePosition = 0;
                            getBillingPeriods();
                        }
                    }
                } else {
                    chartIndex = _monthlyIdsList.indexOf(_actualChart);
                    showChartId = _monthlyIdsList[chartIndex + 1];
                    showChart(showChartId);
                }
            } else {
                var peakOption = peakRadioButtonOption(),
                    peakOptionList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakIdsList
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakIdsList
                            : _dailyNonSummerIdsList,
                    dailyMobileData =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakMobileData
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakMobileData
                            : _dailyNonSummerMobileData,
                    peakOptionMobileList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakMobileIds
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakMobileIds
                            : _dailyNonSummerMobileIds,
                    newPeriod,
                    callmorePeriods,
                    lastPeriod,
                    billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates;

                chartIndex =
                    billRates && (coned.utils.isMobile() || coned.utils.isTablet())
                        ? _.invert(peakOptionMobileList[_actualPeriod])[_actualChart]
                        : peakOptionList.indexOf(_actualChart);
                nextChartPeriod =
                    billRates && (coned.utils.isMobile() || coned.utils.isTablet())
                        ? (dailyMobileData[_actualPeriod] &&
                              dailyMobileData[_actualPeriod].items[parseInt(chartIndex) + 1] ===
                                  undefined) ||
                          dailyMobileData[_actualPeriod] === undefined
                            ? _actualPeriod + 1
                            : _actualPeriod
                        : _actualPeriod;
                chartIndex =
                    billRates && (coned.utils.isMobile() || coned.utils.isTablet())
                        ? (dailyMobileData[_actualPeriod] &&
                              dailyMobileData[_actualPeriod].items[parseInt(chartIndex) + 1] ===
                                  undefined) ||
                          dailyMobileData[_actualPeriod] === undefined
                            ? '-1'
                            : chartIndex
                        : chartIndex;
                chartId =
                    billRates && (coned.utils.isMobile() || coned.utils.isTablet())
                        ? peakOptionMobileList[nextChartPeriod]
                            ? peakOptionMobileList[nextChartPeriod][parseInt(chartIndex) + 1]
                            : undefined
                        : peakOptionList[chartIndex + 1];
                chart = document.getElementById(chartId);

                _actualDailyPos++;

                if (coned.utils.isMobile() || coned.utils.isTablet()) {
                    _dailyDataPosition++;
                    newPeriod =
                        dailyMobileData[_dailyPeriodPosition] === undefined ||
                        dailyMobileData[_dailyPeriodPosition].items.length === _dailyDataPosition
                            ? true
                            : false;
                    lastPeriod =
                        parseInt(_dailyPeriodPosition) === _allGeneralPeriods.length - 1
                            ? true
                            : false;
                    callmorePeriods = newPeriod && lastPeriod;
                } else {
                    callmorePeriods = peakOptionList.indexOf(_actualChart) === _IPPPeriods - 1;
                }

                if (_morePeriods && callmorePeriods) {
                    count = 12;
                    _indexBillingPeriod = _indexBillingPeriod + count;
                    _dailyPeriodPosition++;
                    _dailyDataPosition = 0;
                    getBillingPeriods();
                } else {
                    if (chart) {
                        _dailyPeriodPosition = parseInt(
                            document
                                .getElementById(chartId)
                                .getAttribute(CONSTANTS.CONED_CHART_DATA_PERIOD)
                        );
                        _dailyDataPosition = parseInt(
                            document
                                .getElementById(chartId)
                                .getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK)
                        );
                        showChart(chartId);
                    } else {
                        if (coned.utils.isMobile() || coned.utils.isTablet()) {
                            if (newPeriod) {
                                _dailyPeriodPosition++;
                                getDailyDemand(peakOption, _dailyPeriodPosition);
                                _dailyDataPosition = 0;
                            } else {
                                $activeTab = $ippChartsComponent.getElementsByClassName(
                                    CONSTANTS.CONED_CHART_DATE_BUTTON
                                )[0];
                                generateChartId($activeTab);
                                generateChart(dailyMobileData[_dailyPeriodPosition]);
                            }
                        } else {
                            // desktop
                            _dailyPeriodPosition++;
                            getDailyDemand(peakOption, chartIndex + 1);
                        }
                    }
                }
            }
        };

        /**
         * Functionality for the forward chart arrow
         * @param {Object} event   Event name
         */
        var nextDateChart = function (event) {
            var $actualChart,
                chartDate,
                nextChartId,
                peakOption = peakRadioButtonOption(),
                billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates;

            _actualDailyPos =
                _time === CONSTANTS.CONED_CHART_MONTHLY ? _actualDailyPos : _actualDailyPos - 1;
            event.preventDefault();
            coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);

            dataLayer.push({
                event: 'IPP.demand.navigation.billing',
                label: 'Forward arrow'
            });

            if (_errorFlag) {
                var peakList, chartExists;

                if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                    var dailyMobileData =
                            _chartId.indexOf(CONSTANTS.CONED_CHART_OFFPEAK) > -1
                                ? _dailyOffPeakMobileData
                                : _chartId.indexOf(CONSTANTS.CONED_CHART_PEAK) > -1
                                ? _dailyPeakMobileData
                                : _dailyNonSummerMobileData,
                        dailyMobileIds =
                            _chartId.indexOf(CONSTANTS.CONED_CHART_OFFPEAK) > -1
                                ? _dailyOffPeakMobileIds
                                : _chartId.indexOf(CONSTANTS.CONED_CHART_PEAK) > -1
                                ? _dailyPeakMobileIds
                                : _dailyNonSummerMobileIds; //_dailyNonSummerMobileData
                    peakList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakMobileIds
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakMobileIds
                            : _dailyNonSummerMobileIds;
                    nextChartId = dailyMobileData[_actualPeriod - 1]
                        ? dailyMobileIds[_actualPeriod - 1][
                              dailyMobileData[_actualPeriod - 1].items.length - 1
                          ]
                        : dailyMobileIds[_actualPeriod - 1][0];
                    chartExists = document.getElementById(nextChartId);

                    if (!chartExists) {
                        _actualPeriod--;
                        _dailyPeriodPosition--;
                        createPeakChart(peakOption);
                        return;
                    }
                } else {
                    peakList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakIdsList
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakIdsList
                            : _dailyNonSummerIdsList;
                    nextChartId = peakList[_actualDailyPos];
                    chartExists = document.getElementById(nextChartId) ? true : false;
                }

                if (!chartExists) {
                    // chart not exists
                    query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
                    _errorFlag = false;
                } else {
                    _chartId = nextChartId;

                    $actualChart = document.getElementById(_chartId);
                    chartDate = $actualChart.getAttribute(
                        CONSTANTS.CONED_CHART_DATE_TEXT,
                        _dateText
                    );
                    _dailyPeriodPosition = $actualChart.getAttribute(
                        CONSTANTS.CONED_CHART_DATA_PERIOD
                    );
                    _dailyDataPosition = $actualChart.getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK);

                    $conedChartDateText.innerHTML = chartDate;

                    showChart(_chartId);

                    query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);

                    _errorFlag = false;

                    return;
                }
            }

            if (_monthlyErrorFlag && _time === CONSTANTS.CONED_CHART_MONTHLY) {
                $actualChart = document.getElementById(_chartId);
                chartDate = $actualChart.getAttribute(CONSTANTS.CONED_CHART_DATE_TEXT, _dateText);

                query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);

                $conedChartDateText.innerHTML = chartDate;

                allowArrows(_chartId);
                _monthlyErrorFlag = false;

                return;
            }

            var nextChart, chart;
            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                nextChart = _monthlyIdsList.indexOf(_actualChart) - 1;
                showChart(_monthlyIdsList[nextChart]);
            } else {
                var lastPeakOption,
                    peakchart,
                    showChartId,
                    peakActualList,
                    peakChartElem,
                    offPeakChartElem,
                    nonSummerChartElem;
                _dailyDataPosition--;

                if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                    lastPeakOption =
                        _.invert(_dailyPeakMobileIds[_actualPeriod])[_actualChart] > -1
                            ? CONSTANTS.CONED_CHART_PEAK
                            : _.invert(_dailyOffPeakMobileIds[_actualPeriod])[_actualChart] > -1
                            ? CONSTANTS.CONED_CHART_OFFPEAK
                            : CONSTANTS.CONED_CHART_NONSUMMER;

                    var peakIdsList =
                        lastPeakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakMobileIds
                            : lastPeakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakMobileIds
                            : _dailyNonSummerMobileIds;

                    nextChart = _dailyDataPosition;
                    if (nextChart === -1) {
                        var dailyMobileDataList =
                                peakOption === CONSTANTS.CONED_CHART_PEAK
                                    ? _dailyPeakMobileData
                                    : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                                    ? _dailyOffPeakMobileData
                                    : _dailyNonSummerMobileData,
                            nextPeriod = peakIdsList[_actualPeriod - 1],
                            weekPosition = dailyMobileDataList[_actualPeriod - 1]
                                ? Object.keys(dailyMobileDataList[_actualPeriod - 1].items).length -
                                  1
                                : nextPeriod
                                ? nextPeriod[Object.keys(nextPeriod).length - 1]
                                : undefined;
                        nextChart = nextPeriod ? nextPeriod[weekPosition] : undefined;
                        _actualPeriod--;
                    } else {
                        nextChart = peakIdsList[_actualPeriod][nextChart];
                    }

                    _dailyPeriodPosition =
                        _dailyDataPosition < 0 ? _dailyPeriodPosition - 1 : _dailyPeriodPosition;
                    _dailyDataPosition =
                        _dailyDataPosition > -1
                            ? _dailyDataPosition
                            : peakIdsList[_actualPeriod] &&
                              Object.keys(peakIdsList[_actualPeriod]).length > 0
                            ? Object.keys(peakIdsList[_actualPeriod]).length - 1
                            : 0;
                    chart = document.getElementById(nextChart);
                    if (_dailyPeakMobileIds[_actualPeriod]) {
                        peakChartElem = _dailyPeakMobileIds[_actualPeriod]
                            ? document.getElementById(
                                  _dailyPeakMobileIds[_actualPeriod][_dailyDataPosition]
                              )
                            : null;
                        offPeakChartElem = _dailyOffPeakMobileIds[_actualPeriod]
                            ? document.getElementById(
                                  _dailyOffPeakMobileIds[_actualPeriod][_dailyDataPosition]
                              )
                            : null;
                        nonSummerChartElem = _dailyNonSummerMobileIds[_actualPeriod]
                            ? document.getElementById(
                                  _dailyNonSummerMobileIds[_actualPeriod][_dailyDataPosition]
                              )
                            : null;
                        peakchart = peakChartElem
                            ? peakChartElem
                            : offPeakChartElem
                            ? offPeakChartElem
                            : nonSummerChartElem;
                    }
                    showChartId = nextChart;
                } else {
                    lastPeakOption =
                        _dailyPeakIdsList.indexOf(_actualChart) > -1
                            ? CONSTANTS.CONED_CHART_PEAK
                            : _dailyOffPeakIdsList.indexOf(_actualChart) > -1
                            ? CONSTANTS.CONED_CHART_OFFPEAK
                            : CONSTANTS.CONED_CHART_NONSUMMER;
                    nextChart =
                        lastPeakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakIdsList.indexOf(_actualChart) - 1
                            : lastPeakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakIdsList.indexOf(_actualChart) - 1
                            : _dailyNonSummerIdsList.indexOf(_actualChart) - 1;
                    peakActualList =
                        peakOption === CONSTANTS.CONED_CHART_PEAK
                            ? _dailyPeakIdsList
                            : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                            ? _dailyOffPeakIdsList
                            : _dailyNonSummerIdsList;

                    chart = document.getElementById(peakActualList[nextChart]);
                    peakChartElem = document.getElementById(_dailyPeakIdsList[_actualDailyPos]);
                    offPeakChartElem = document.getElementById(
                        _dailyOffPeakIdsList[_actualDailyPos]
                    );
                    nonSummerChartElem = document.getElementById(
                        _dailyNonSummerIdsList[_actualDailyPos]
                    );
                    peakchart = peakChartElem
                        ? peakChartElem
                        : offPeakChartElem
                        ? offPeakChartElem
                        : nonSummerChartElem;
                    showChartId = peakActualList[nextChart];
                    _dailyPeriodPosition =
                        _dailyDataPosition < 0 ? _dailyPeriodPosition - 1 : _dailyPeriodPosition;
                }

                if (chart) {
                    _dailyPeriodPosition = parseInt(
                        document
                            .getElementById(showChartId)
                            .getAttribute(CONSTANTS.CONED_CHART_DATA_PERIOD)
                    );
                    _dailyDataPosition = parseInt(
                        document
                            .getElementById(showChartId)
                            .getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK)
                    );
                    showChart(showChartId);
                } else {
                    var newPeriodChart = true;

                    if (coned.utils.isMobile() || coned.utils.isTablet()) {
                        var dailyMobileAllData =
                                peakOption === CONSTANTS.CONED_CHART_PEAK
                                    ? _dailyPeakMobileData
                                    : peakOption === CONSTANTS.CONED_CHART_OFFPEAK
                                    ? _dailyOffPeakMobileData
                                    : _dailyNonSummerMobileData,
                            periodExists = dailyMobileAllData[_dailyPeriodPosition] ? true : false,
                            $activeTab = $ippChartsComponent.getElementsByClassName(
                                CONSTANTS.CONED_CHART_DATE_BUTTON
                            )[0];

                        if (periodExists) {
                            newPeriodChart = false;
                            generateChartId($activeTab);
                            generateChart(dailyMobileAllData[_dailyPeriodPosition]);
                        }
                    }

                    if (newPeriodChart) {
                        if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                            _lastPeriodPosition =
                                peakIdsList[_dailyPeriodPosition] === undefined ||
                                Object.keys(peakIdsList[_dailyPeriodPosition]).length > 0
                                    ? true
                                    : false;
                        } else {
                            _dailyDataPosition =
                                _dailyDataPosition < 0
                                    ? peakchart.getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK)
                                    : _dailyDataPosition;
                        }
                        getDailyDemand(peakOption, _dailyPeriodPosition);

                        _dateText = peakchart
                            ? peakchart.getAttribute(CONSTANTS.CONED_CHART_DATE_TEXT)
                            : _dateText;
                    }
                }
            }
        };

        /**
         * Change the Note text on rates E and F
         * @param {String} radioButtonOption    Determine if it's peak or off-peak
         */
        var addNoteText = function (radioButtonOption) {
            if (_rateType === 'E' || _rateType === 'F') {
                if (radioButtonOption === CONSTANTS.CONED_CHART_DAILY) {
                    query.addClass($conedChartMonthlyNoteText, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartDailyNoteText, CONSTANTS.CONED_HIDDEN_CLASS);
                } else {
                    query.addClass($conedChartDailyNoteText, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartMonthlyNoteText, CONSTANTS.CONED_HIDDEN_CLASS);
                }
            }
        };

        /**
         * Read the legend attributes json file for all charts
         */
        var getLegendDataAttributes = function () {
            var dataAttributesJson = coned.utils.isPatternLab()
                ? coned.plConstants.GET_CHART_LEGEND_DATA
                : window.location.origin + CONSTANTS.CONED_DATA_ATTRIBUTES_JSON;
            query.getData(
                dataAttributesJson,
                function (data) {
                    _legendDataAttributes = data;
                },
                function () {}
            );
        };

        /**
         * Updated aria-label of arrow buttons
         * @param {*} buttonSelected
         */
        var updatedCopyArrows = function (buttonSelected) {
            var typeView = coned.utils.isMobile() == true ? 'mobile' : 'desktop',
                stringAttributePrev = 'data-' + typeView + '-arrow-prev',
                stringAttributeNext = 'data-' + typeView + '-arrow-next',
                dataAttributeNext = buttonSelected.getAttribute(stringAttributeNext),
                dataAttributePrev = buttonSelected.getAttribute(stringAttributePrev);

            $conedChartLastDate.setAttribute('aria-label', dataAttributePrev);
            $conedChartNextDate.setAttribute('aria-label', dataAttributeNext);
        };

        /**
         * Set aria-label after reload
         */
        var setInitializeAttributes = function () {
            if ($conedChartMonthButton && query.hasClass($conedChartMonthButton, 
                                            CONSTANTS.CONED_CHART_BUTTON_DISABLED)) {
                updatedCopyArrows($conedChartMonthButton);
            } else if ($conedChartDateButton && query.hasClass($conedChartDateButton, 
                                                CONSTANTS.CONED_CHART_BUTTON_DISABLED)) {
                updatedCopyArrows($conedChartDateButton);
            }
        };

        /**
         * Initialize all the chart events
         */
        var initializeEvents = function () {
            coned.utils.addGeneralListeners($conedChartDemandTab, getMonthlyData);
            coned.utils.addGeneralListeners($conedChartDateButton, createDailyChart);
            coned.utils.addGeneralListeners($conedChartMonthButton, showMonthlyChart);
            coned.utils.addGeneralListeners($conedChartLastDate, lastDateChart);
            coned.utils.addGeneralListeners($conedChartNextDate, nextDateChart);
            coned.utils.addGeneralListeners($conedChartOffPeakOption, function () {
                createPeakChart('OffPeak');
            });
            coned.utils.addGeneralListeners($conedChartPeakOption, function () {
                createPeakChart('Peak');
            });
            coned.utils.addGeneralListeners($conedChartNonSummerOption, function () {
                createPeakChart('NonSummer');
            });
            setInitializeAttributes();
            window.addEventListener('resize', function () {
                var demandOption = document.getElementsByClassName(
                        CONSTANTS.CONED_CHART_DEMAND_BUTTON
                    )[0],
                    windowWidth = window.innerWidth,
                    actualSize =
                        windowWidth > coned.constants.TABLET_MAX_SIZE === true
                            ? 'desktop'
                            : 'mobile';

                if (
                    _chartId &&
                    demandOption.classList.contains(CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS)
                ) {
                    if (_device !== actualSize) {
                        // if the window change from desktop to mobile/tablet or vice versa
                        location.reload();
                    } else {
                        var regExp = _time === 'daily' ? /-([A-Z])*\w+/g : /-([0-9]{4})/g,
                            maxValueLength = _yAxis[_yAxis.length - 1].length,
                            minValueLength = _yAxis[0].length,
                            amountDigits =
                                maxValueLength > minValueLength ? maxValueLength : minValueLength;

                        coned.chart.resizeWindow(
                            _chartId,
                            _chart,
                            regExp,
                            isSubscriptionRate(),
                            amountDigits
                        );
                    }
                }
            });

            coned.utils.addGeneralListeners($conedChartTableButton, function (event) {
                event.preventDefault();
                var showTable = $conedChartTable.hasAttribute('hidden') ? 'true' : 'false';

                coned.chart.showTableInfo(this.parentElement, _chartId, showTable);
            });
        };

        /**
         * Initialize all the chart elements
         */
        var initializeData = function () {
            $conedEnergyContent = document.getElementsByClassName(CONSTANTS.CONED_ENERGY_USAGE)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.CONED_CHART_FORM_LOADING)[0];
            $conedChartModule = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_MODULE
            )[0];
            $conedChartContainer = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_CONTAINER
            )[0];
            $conedChartNote = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_NOTE
            )[0];
            $conedChartErrorMessage = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_ERROR_MESSAGE
            )[0];
            $conedChartNoDataMessage = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_NODATA_MESSAGE
            )[0];
            $conedChartDemandTab = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_DEMAND_BUTTON
            )[0];
            $conedChartDemandMobileTab = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_MOBILE_DEMAND
            )[0];
            $conedChartDateButton = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_DATE_BUTTON
            )[0];
            $conedChartMonthButton = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_MONTH_BUTTON
            )[0];
            $conedChartDateText = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_DATE
            )[0];
            $conedChartPeakButtons = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_PEAK_CONTENT
            )[0];
            $conedChartPeakOption = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_PEAK_OPTION
            )[0];
            $conedChartOffPeakOption = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_OFFPEAK_OPTION
            )[0];
            $conedChartNonSummerOption = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_NONSUMMER_OPTION
            )[0];
            $conedChartLastDate = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_LEFT_ARROW
            )[0];
            $conedChartNextDate = $ippChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_RIGHT_ARROW
            )[0];
            $conedChartDailyNoteText = $ippChartsComponent.getElementsByClassName(
                'coned-chart__note-paragraph--demandDaily'
            )[0];
            $conedChartMonthlyNoteText = $ippChartsComponent.getElementsByClassName(
                'coned-chart__note-paragraph--demandMonthly'
            )[0];
            $conedChartTableButton = $ippChartsComponent.getElementsByClassName(
                'js-coned-chart-table-button'
            )[0];
            $conedChartTable = $ippChartsComponent.getElementsByClassName(
                'js-chart-table-content'
            )[0];

            // This array is hardcoded as it is used for calling the months period as needed
            _allMonthsPeriodServiceCall = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ];

            // This array is content editable, and is used for displaying text on the widget,
            // and translating it to spanish via sitecore
            _allMonths = $conedChartTable.dataset.shortenedMonthsList.split(',');

            _allCompleteMonths = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ];

            _device =
                window.innerWidth > coned.constants.TABLET_MAX_SIZE === true ? 'desktop' : 'mobile';

            (_monthlyIdsList = []),
                (_dailyPeakIdsList = []),
                (_dailyOffPeakIdsList = []),
                (_dailyNonSummerIdsList = []),
                (_monthlyPeriodsList = {}),
                (_allGeneralPeriods = []),
                (_IPPPeriods = 0),
                (_dataMonthlyNames = {}),
                (_dataDailyNames = {}),
                (_legendTooltip = {}),
                (_generalDailyData = {}),
                (_generalLoad = false),
                (_indexBillingPeriod = 0),
                (_dailyPeriodPosition = 0),
                (_actualDailyPos = 0), // actual position in the daily array
                (_nextClick = false),
                (_dailyDataPosition = 0), // week on the period
                (_dailyPeakMobileData = {}),
                (_dailyOffPeakMobileData = {}),
                (_dailyNonSummerMobileData = {}),
                (_monthlyMobileData = []),
                (_monthlyMobilePeriod = 0),
                (_monthlyMobilePosition = 0),
                (_dailyPeakMobileIds = {}),
                (_dailyOffPeakMobileIds = {}),
                (_dailyNonSummerMobileIds = {}),
                (_errorFlag = false), // flag to determine if an error happens on Daily charts
                (_monthlyErrorFlag = false),
                (_lastPeriod = false),
                (_hasTotalAverageValue = false),
                (_totalAverageValue = {}),
                (_allTotalAverageValue = {}),
                (_lastPeriodPosition = false),
                (_demandMonthlyColors = [
                    CONSTANTS.CONED_CHART_PEAK_COLOR,
                    CONSTANTS.CONED_CHART_OFFPEAK_COLOR
                ]),
                (_demandMonthlyColorsRate2 = [
                    CONSTANTS.CONED_CHART_PEAK_COLOR,
                    CONSTANTS.CONED_CHART_OFFPEAK_COLOR,
                    CONSTANTS.CONED_CHART_NONSUMMER_COLOR
                ]),
                (_monthlyColorsRateE = [
                    CONSTANTS.CONED_CHART_PEAK_COLOR,
                    CONSTANTS.CONED_CHART_OVERAGE_COLOR,
                    CONSTANTS.CONED_CHART_SUBSCRIPTION_COLOR
                ]),
                (_dailyColorsRateE = [
                    CONSTANTS.CONED_CHART_PEAK_COLOR,
                    CONSTANTS.CONED_CHART_SUBSCRIPTION_COLOR,
                    CONSTANTS.CONED_CHART_DETERMINANT_COLOR,
                    CONSTANTS.CONED_CHART_OVERAGE_COLOR
                ]),
                (_monthlyColorsRateF = [
                    CONSTANTS.CONED_CHART_PEAK_COLOR,
                    CONSTANTS.CONED_CHART_SUBSCRIPTION_COLOR,
                    CONSTANTS.CONED_CHART_SUBSCRIPTION_COLOR,
                    CONSTANTS.CONED_CHART_OVERAGE_COLOR
                ]),
                (_dailyColorsRateF = [
                    CONSTANTS.CONED_CHART_PEAK_COLOR,
                    CONSTANTS.CONED_CHART_SUBSCRIPTION_COLOR,
                    CONSTANTS.CONED_CHART_DETERMINANT_COLOR,
                    CONSTANTS.CONED_CHART_SUBSCRIPTION_COLOR,
                    CONSTANTS.CONED_CHART_BLACK_COLOR
                ]);
            
            _isFirstbillingPeriodLoad = true;
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            getLegendDataAttributes();
            getBillingPeriods();
            isLoaded = true;
        };

        init();
    };

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    IppChartsComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return IppChartsComponent;
})();
