// ==================== AUTOCOMPLETE INPUT COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AutocompleteInput = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        AUTOCOMPLETE_INPUT: 'js-autocomplete-input',
        AUTOCOMPLETE_LIST_INPUT: 'js-autocomplete-list-input',
        AUTOCOMPLETE_ANNOUNCER: 'js-autocomplete-results-announcer',
        AUTOCOMPLETE_ITEM: 'js-autocomplete-item',
        AUTOCOMPLETE_ITEM_LIST: 'js-autocomplete-list',
        AUTOCOMPLETE_ITEM_LIST_WRAPPER: 'js-autocomplete-list-wrapper',
        AUTOCOMPLETE_ITEM_CLASS: 'coned-autocomplete__item',
        AUTOCOMPLETE_ITEM_HIGHLIGHT: 'coned-autocomplete__item--highlight',
        AUTOCOMPLETE_ITEM_UNHIGHLIGHT: 'coned-autocomplete__item--unhighlight',
        AUTOCOMPLETE_DEFAULT_OPTION_LINK: 'js-default-option-link',
        ITEM_HOVER_CLASS: 'coned-autocomplete__item--hover',
        ARIA_SELECTED: 'aria-selected',
        ARIA_POSINSET: 'aria-posinset',
        ARIA_EXPANDED: 'aria-expanded',
        ARIA_ACTIVEDESCENDANT: 'aria-activedescendant',
        ROLE_ATTRIBUTE: 'role',
        ID_ATTRIBUTE: 'id',
        AMOUNT_STRING_TEMPLATE: '@AMOUNT',
        SELECTION_STRING_TEMPLATE: '@SELECTION',
        OPTION_ROLE_ATTRIBUTE: 'option',
        HIDDEN_CLASS: 'hidden',
        ANNOUNCE_HOVER: 'hover',
        ANNOUNCE_RESULTS: 'results',
        ANNOUNCE_SELECTION: 'selection',
        FORM_LOADING: 'js-form-loading',
        VALID_CLASS: 'valid',
        RIGHT: 'right',
        ERROR_CLASS: 'js-error-message',
        ERROR_WRAPPER_CLASS: 'coned-field-error-wrapper',
        SUBSTRING: 'substr',
        MAIN_CONTENT_CLASS: 'js-page-content',
        START_SERVICE_FORM: 'js-start-service-form',
        TRANSFER_SERVICE_FORM: 'js-transfer-service-form',
        COMMA: ',',
        RESET_INPUT_END_EVENT: 'resetInputEnd'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AutocompleteInput = function ($container) {
        /**
         * PRIVATE VARIABLES
         */
        var $input,
            $listInput,
            $announcer,
            $itemList,
            $itemListWrapper,
            $errorWrapper,
            $formLoading,
            $defaultItem,
            $noResultsItem,
            $mainContent,
            $startServiceForm,
            $transferServiceForm,
            _serviceCall,
            _isScroll,
            _currentInput,
            _hideSuggestions,
            _currentIndex,
            _keyTrigger,
            _defaultOption,
            _defaultOptionFlag,
            _defaultOptionLink,
            _noResultsOption,
            _noResultsFlag,
            _filteredList,
            _filteringEnable,
            _filteringType,
            _list,
            _currentInputValue;

        /**
         * PRIVATE VARIABLES
         */

        /**
         * Loads list to manage to memory depending if it needs a service call or from a data-attribute
         */
        var loadAutocompleteContainer = function (event) {
            // If not present and module has a service url, call it to load the list.
            if ($container.dataset.listServiceUrl && $container.dataset.listServiceUrl !== '') {
                var minInputLength = $container.dataset.minInputLength,
                    waitInputTime = $container.dataset.waitInputTime,
                    listObj,
                    divider;
                _currentInputValue = $input.value;

                if ($input.value === '') {
                    $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                    query.fireEvent($container, 'resetInput');
                } else {
                    //Check if there is a min number of chars before a service call, timeout can also me set
                    if (minInputLength) {
                        if ($input.value.length > parseInt(minInputLength)) {
                            clearTimeout(_serviceCall);
                            _serviceCall = setTimeout(
                                serviceCall,
                                waitInputTime && parseInt(waitInputTime) > 0 ? waitInputTime : 0
                            );
                        }
                    } else {
                        serviceCall();
                    }
                }
            }
            // Load the list from the dataset list.
            else if ($listInput) {

                if($listInput.value !== '') {

                    if ($listInput.dataset.divider) {
                        divider = $listInput.dataset.divider;
                    } else {
                        divider = CONSTANTS.COMMA;
                    }
    
                    var list = $listInput.value.split(divider);

                    listObj = {
                        AutocompleteArray: list
                    };

                    loadAutocompleteList(listObj, event);
                     
                } else {

                    listObj = {
                        AutocompleteArray: []
                    };

                    loadAutocompleteList(listObj, event);
                    $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } 
        };

        var serviceCall = function () {
            var params = { input: encodeURIComponent($input.value) },
                extraParams = JSON.parse($container.dataset.params);

            // Trigger event to notice the input change
            query.fireEvent($input, 'inputChange');
            params = Object.assign(params, extraParams);
            query.getData(
                $container.dataset.listServiceUrl,
                successLoadDataServiceCall,
                errorLoadDataServiceCall,
                params,
                $formLoading
            );
        };

        /**
         * On successful service call handler
         * @param data {Object} Object containing the response of the service call
         */
        var successLoadDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                //Parameters to test Address Lookup feature
                if ($container.dataset.listServiceResponse === 'street') {
                    if (
                        $container.parentElement.parentElement.getElementsByClassName(
                            'js-autocomplete-zipcode'
                        )[0].value === '10003'
                    ) {
                        query.getData(
                            coned.plConstants.GET_STREET_ADDRESS_NO_RESULTS,
                            loadAutocompleteList,
                            function () {}
                        );
                    } else {
                        query.getData(
                            coned.plConstants.GET_STREET_ADDRESS_LIST,
                            loadAutocompleteList,
                            function () {}
                        );
                    }
                } else {
                    query.getData(
                        coned.plConstants.GET_EMAIL_DOMAIN_LIST,
                        loadAutocompleteList,
                        function () {}
                    );
                }
            } else {
                loadAutocompleteList(data);
            }
        };

        /**
         * On error service call handler
         * @param data {Object} Object containing the response of the service call
         */
        var errorLoadDataServiceCall = function (data) {
            //Show error
            var $error = $container.parentElement.getElementsByClassName(
                    $input.parentElement.dataset.errorClass
                )[0],
                $errorMsg = $error.getElementsByClassName(CONSTANTS.ERROR_CLASS);

            $errorMsg.innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;
            $error.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Reset input
            query.fireEvent($container, 'resetInput');
        };

        /**
         * Handles the autocomplete list and initializes filtering for suggestions
         * @param data {Object} Object containing the array of suggestions
         */
        var loadAutocompleteList = function (data, event) {
            _noResultsFlag = _.find($itemList.children, function ($element) {
                return (
                    _noResultsOption &&
                    _noResultsOption &&
                    $element.dataset.actualValue.includes(_noResultsOption)
                );
            });
            _defaultOptionFlag = _.find($itemList.children, function ($element) {
                return (
                    _defaultOption &&
                    _defaultOption !== '' &&
                    $element.dataset.actualValue.includes(_defaultOption)
                );
            });
            _list = data.AutocompleteArray;

            if ($input.value !== '' && _list.length > 0) {
                filterInput(null, $input.value);
            }

            //Load list on first load
            if ($input.value === '' && $container.dataset.listInitialLoad === 'true') {
                filterInput(null, $input.value);
                $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            if ($itemList.innerHTML === '' && _list.length < 1) {
                _currentInput = $input.value;
                query.fireEvent($container, 'resetInput');
                if (_noResultsOption && _noResultsOption !== '' && !_noResultsFlag) {
                    $input.value = _currentInput;
                    addNoResultsOption();

                    if (_defaultOption && _defaultOption !== '' && !_defaultOptionFlag) {
                        addDefaultOption();
                    }
                }
            }

            if (!event || event.type !== 'reloadList') {
                $input.addEventListener('keyup', filterInput);
                $input.addEventListener('keydown', onKeyDown);
                $input.addEventListener('focus', onInputFocus);
                $input.addEventListener('blur', onInputBlur);

                // Prevent scrolling to be confused as click
                $itemList.addEventListener('touchstart', function () {
                    _isScroll = false;
                }, coned.supportsPassive ? { passive: true } : false);
                $itemList.addEventListener('touchmove', function () {
                    _isScroll = true;
                }, coned.supportsPassive ? { passive: true } : false);

                //Add listeners only there are results
                if (_list && _list.length > 0) {
                    coned.utils.removeGeneralListeners($itemList, onItemClick);
                    coned.utils.addGeneralListeners($itemList, onItemClick);
                }
            }

            //Remove listener from default option.
            if ($defaultItem) {
                var $defaultLink;
                coned.utils.removeGeneralListeners($defaultItem, onItemClick);
                $defaultLink = $defaultItem.getElementsByClassName(
                    CONSTANTS.AUTOCOMPLETE_DEFAULT_OPTION_LINK
                )[0];
                if ($defaultLink) {
                    coned.utils.removeGeneralListeners($defaultLink, onItemClick);
                }
            }
        };

        /**
         *  Add the no result option if exists
         */
        var addNoResultsOption = function () {
            $noResultsItem = createAutocompleteItem(_noResultsOption);
            $noResultsItem.setAttribute('data-is-not-selectable', 'true');

            if ($noResultsItem) {
                $noResultsItem.removeEventListener('mouseover', onItemHoverIn);
                $noResultsItem.removeEventListener('mouseout', onItemHoverOut);
            }

            $itemList.appendChild($noResultsItem);
            $itemListWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        /**
         *  Add the default option
         */
        var addDefaultOption = function () {
            var $itemLink;
            $defaultItem = _defaultOption;
            if (_defaultOptionLink && _defaultOptionLink !== '') {
                $itemLink = document.createElement('button');

                $itemLink.classList.add(CONSTANTS.AUTOCOMPLETE_DEFAULT_OPTION_LINK);
                $itemLink.setAttribute('type', 'button');
                $itemLink.setAttribute('tabindex', '-1');
                $itemLink.innerText = _defaultOptionLink;
                $defaultItem = _defaultOption + ' ' + $itemLink.outerHTML;
            }

            $defaultItem = createAutocompleteItem($defaultItem);
            $defaultItem.setAttribute('data-is-non-input', 'true');
            $defaultItem.classList.add('js-autocomplete-default-option');

            // $defaultItem.removeEventListener('mouseover', onItemHoverIn);
            // $defaultItem.removeEventListener('mouseout', onItemHoverOut);

            $itemList.appendChild($defaultItem);
            $itemListWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Trigger event to bind the default option link
            query.fireEvent($itemListWrapper, 'change');
        };

        /**
         * Reads the results to the user in a more fluent way.
         * @param announceType {Boolean} Flag to either read the selected element or the amount of results.
         */
        var announceToUser = function (announceType) {
            announceType = announceType || false;

            var textToRead;

            if (announceType === CONSTANTS.ANNOUNCE_RESULTS) {
                textToRead = $announcer.dataset.announceResultsTemplate.replace(
                    CONSTANTS.AMOUNT_STRING_TEMPLATE,
                    $itemList.children.length
                );
            } else if (announceType === CONSTANTS.ANNOUNCE_HOVER) {
                if (_currentIndex >= 0 && _currentIndex < $itemList.children.length) {

                    if($defaultItem && $defaultItem.ariaSelected === 'true' && $container.dataset.isMarketRates) { 
                        textToRead = $announcer.dataset.announceDefaultOptionTemplate.replace(
                            CONSTANTS.SELECTION_STRING_TEMPLATE,
                            coned.utils.entitiesDecode(
                                $itemList.children[_currentIndex].dataset.actualValue
                            )
                        );
                    } else {
                        textToRead = coned.utils.entitiesDecode(
                            $itemList.children[_currentIndex].dataset.actualValue
                        );
                    }
                } else {
                    textToRead = $announcer.dataset.announceNoSelectionTemplate;
                }

            } else if (announceType === CONSTANTS.ANNOUNCE_SELECTION) {
                textToRead = $announcer.dataset.announceSelectionTemplate.replace(
                    CONSTANTS.SELECTION_STRING_TEMPLATE,
                    coned.utils.entitiesDecode(
                        $itemList.children[_currentIndex].dataset.actualValue
                    )
                );
                
            }

            $announcer.innerHTML = textToRead;
            setTimeout(function () {
                $announcer.innerHTML = '';
            }, 1000);
        };

        /**
         * On input blur handler
         */
        var onInputBlur = function () {
            setTimeout(function () {
                if (
                    $itemList.children.length &&
                    !query.hasClass($itemListWrapper, CONSTANTS.HIDDEN_CLASS)
                ) {
                    if (
                        document.activeElement === document.body ||
                        document.activeElement === $mainContent ||
                        document.activeElement === $itemListWrapper ||
                        //Start and transfer has the form itself as active element validation needed to 
                        document.activeElement === $startServiceForm ||
                        document.activeElement === $transferServiceForm ||
                        document.activeElement === $itemList
                    ) {
                        $input.focus();
                        document.body.addEventListener('focus', closeDropdown);
                        coned.utils.addGeneralListeners(document.body, closeDropdown);
                    } else {
                        $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                    }
                }
            }, 300);

            if (
                $itemList.children.length === 0 &&
                !query.hasClass($itemListWrapper, CONSTANTS.HIDDEN_CLASS)
            ) {
                $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var closeDropdown = function (event) {
            setTimeout(function () {
                if (event.target !== document.body) {
                    var $target = event.target,
                        $autocompleteContainerParent = query.selectParentElementByAttribute(
                            $target,
                            'module',
                            $container.dataset.module
                        );

                    if (
                        $target !== $container &&
                        !(
                            $autocompleteContainerParent &&
                            $autocompleteContainerParent === $container
                        )
                    ) {
                        // Adding a timeout so click event can trigger propperly
                        setTimeout(function () {
                            $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                        }, 300);

                        document.body.removeEventListener('focus', closeDropdown);
                        coned.utils.removeGeneralListeners(document.body, closeDropdown);
                    }
                }
            }, 300);
        };

        /**
         * On input focus handler
         */
        var onInputFocus = function () {
            if (
                $input.value === '' &&
                _list.length > 1 &&
                $container.dataset.showAllItems === 'true'
            ) {
                filterInput(null, '');
            }

            if (_filteredList.length > 0 || _defaultOption ) {
                $itemListWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        /**
         * On item click handler
         * @param event {Object} Object containing the event
         */
        var onItemClick = function (event) {
            if (_isScroll) {
                // User was trying to scroll, not click
                _isScroll = false;
                return;
            }

            var $target = query.hasClass(event.target, CONSTANTS.AUTOCOMPLETE_ITEM)
                ? event.target
                : query.selectParentElement(event.target, CONSTANTS.AUTOCOMPLETE_ITEM);

            if (!$target) {
                return;
            }

            if ($target.dataset && $target.dataset.isNonInput === 'true') {
                return;
            }

            if (
                query.hasClass($target, CONSTANTS.AUTOCOMPLETE_ITEM) ||
                query.hasClass($target.parentElement, CONSTANTS.AUTOCOMPLETE_ITEM)
            ) {
                _currentIndex = Array.from($itemList.children).indexOf($target);
                $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
                $input.value = coned.utils.entitiesDecode($target.dataset.actualValue);
                _currentInput = $input.value;

                announceToUser(CONSTANTS.ANNOUNCE_SELECTION);
                _currentIndex = -1;

                if (_filteringEnable) {
                    _filteredList = [];

                    // Create input event
                    var evt;
                    // If not IE
                    if (typeof Event === 'function') {
                        evt = new Event('input', {
                            bubbles: true,
                            cancelable: true
                        });
                        // If IE
                    } else {
                        evt = document.createEvent('Event');
                        evt.initEvent('input', true, true); // Flags: Bubbles, Cancelable
                    }

                    $input.dispatchEvent(evt);
                }

                query.fireEvent($input, 'selectedOption');
            }
        };

        /**
         * On item mouse enter handler
         * @param event {Object} Object containing the event
         */
        var onItemHoverIn = function (event) {
            var $target = event.currentTarget,
                $hoveredItems = $itemList.querySelectorAll('.' + CONSTANTS.ITEM_HOVER_CLASS);

            if ($hoveredItems.length) {
                _.each($hoveredItems, function ($item) {
                    $item.classList.remove(CONSTANTS.ITEM_HOVER_CLASS);
                    $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
                });
            }

            _currentIndex = Array.from($itemList.children).indexOf($target);

            if (!$target.dataset.isNonInput || $target.dataset.isNonInput !== 'true') {
                $input.value = coned.utils.entitiesDecode($target.dataset.actualValue);
                $input.selectionStart = _currentInput.length;
                $input.selectionEnd = $input.value.length;
                $input.scrollLeft = $input.scrollWidth;
            }

            $target.classList.add(CONSTANTS.ITEM_HOVER_CLASS);
            $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'true');
            $input.setAttribute(CONSTANTS.ARIA_ACTIVEDESCENDANT, $target.id);
            announceToUser(CONSTANTS.ANNOUNCE_HOVER);
        };

        /**
         * On item mouse exit handler
         */
        var onItemHoverOut = function (event) {
            var $target = event.currentTarget;

            _currentIndex = -1;
            $input.value = _currentInput;
            $target.classList.remove(CONSTANTS.ITEM_HOVER_CLASS);
            $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
            $input.removeAttribute($target.id);

            announceToUser(CONSTANTS.ANNOUNCE_HOVER);
        };

        /**
         * On input keydown event handler
         * @param event {Object} Object containing the event
         */
        var onKeyDown = function (event) {
            var keycode = event.keyCode,
                isSelectionKey =
                    keycode === coned.constants.KEY_CODE.ENTER || // enter
                    keycode === coned.constants.KEY_CODE.TAB;

            if (
                isSelectionKey &&
                !query.hasClass($itemListWrapper, CONSTANTS.HIDDEN_CLASS) &&
                _filteredList.length &&
                _currentIndex > -1
            ) {
                event.preventDefault();
            }

        };

        /**
         * Filters all the suggestions from what the user has typed
         * @param value {Object} Object containing the array of suggestions
         */
        var filterInput = function (event, value) {
            var valid = true;

            if (event) {
                var keycode = event.keyCode,
                    $target = $itemList.children[_currentIndex];

                event.preventDefault();
                event.stopImmediatePropagation();

                valid =
                    (keycode > 47 && keycode < 58) || // number keys
                    (keycode > 64 && keycode < 91) || // letter keys
                    (keycode > 95 && keycode < 112) || // numpad keys
                    (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
                    (keycode > 218 && keycode < 223); // [\]' (in order)

                if (
                    valid ||
                    keycode === coned.constants.KEY_CODE.BACKSPACE ||
                    keycode === coned.constants.KEY_CODE.DELETE
                ) {
                    var $selectedItems = $itemList.querySelectorAll('[aria-selected="true"]');
                    _.each($selectedItems, function ($item) {
                        $item.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
                    });
                    _currentInput = $input.value;
                    _currentIndex = -1;
                } else if (
                    ($input.value === '' ||
                        !_filteredList.length ||
                        query.hasClass($itemListWrapper, CONSTANTS.HIDDEN_CLASS)) &&
                    (keycode === coned.constants.KEY_CODE.TAB ||
                        keycode === coned.constants.KEY_CODE.SHIFT)
                ) {
                    return;
                }

                if (
                    $itemList.children.length &&
                    !query.hasClass($itemListWrapper, CONSTANTS.HIDDEN_CLASS)
                ) {
                    if (keycode === coned.constants.KEY_CODE.DOWN) {
                        if (_currentIndex < $itemList.children.length - 1) {
                            if (_currentIndex > -1) {
                                $target.classList.remove(CONSTANTS.ITEM_HOVER_CLASS);
                                $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
                            }

                            $target = $itemList.children[++_currentIndex];

                            if (
                                $target.dataset.isNotSelectable === 'true' &&
                                _currentIndex + 1 < $itemList.children.length
                            ) {
                                $target = $itemList.children[++_currentIndex];
                            }

                            if (!$target.dataset || $target.dataset.isNonInput !== 'true') {
                                $input.value = coned.utils.entitiesDecode(
                                    $target.dataset.actualValue
                                );
                                $input.selectionStart = _currentInput.length;
                                $input.selectionEnd = $input.value.length;
                                $input.scrollLeft = $input.scrollWidth;
                            }

                            $target.classList.add(CONSTANTS.ITEM_HOVER_CLASS);
                            $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'true');
                            $input.setAttribute(CONSTANTS.ARIA_ACTIVEDESCENDANT, $target.id);
                            announceToUser(CONSTANTS.ANNOUNCE_HOVER);

                            if (
                                $target.offsetTop + $target.offsetHeight >
                                $itemList.offsetHeight + $itemList.scrollTop
                            ) {
                                $itemList.scrollTop += $target.offsetHeight;
                            }
                        }

                        return;
                    } else if (keycode === coned.constants.KEY_CODE.UP) {
                        if (
                            _currentIndex > 0 &&
                            $itemList.children[0].dataset.isNotSelectable !== 'true'
                        ) {
                            $target.classList.remove(CONSTANTS.ITEM_HOVER_CLASS);
                            $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');

                            $target = $itemList.children[--_currentIndex];

                            if (!$target.dataset || $target.dataset.isNonInput !== 'true') {
                                $input.value = coned.utils.entitiesDecode(
                                    $target.dataset.actualValue
                                );
                                $input.selectionStart = _currentInput.length;
                                $input.selectionEnd = $input.value.length;
                                $input.scrollLeft = $input.scrollWidth;
                            }

                            $target.classList.add(CONSTANTS.ITEM_HOVER_CLASS);
                            $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'true');
                            $input.setAttribute(CONSTANTS.ARIA_ACTIVEDESCENDANT, $target.id);
                            announceToUser(CONSTANTS.ANNOUNCE_HOVER);

                            if ($target.offsetTop < $itemList.scrollTop) {
                                $itemList.scrollTop -= $target.offsetHeight;
                            }
                        } else if (
                            _currentIndex <= 0 ||
                            $itemList.children[0].dataset.isNotSelectable === 'true'
                        ) {
                            if (
                                _currentIndex == 0 ||
                                $itemList.children[0].dataset.isNotSelectable === 'true'
                            ) {
                                $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
                            }

                            $input.value = _currentInput;
                            _filteredList = [];
                            _currentIndex = -1;
                            $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                            $input.removeAttribute(CONSTANTS.ARIA_ACTIVEDESCENDANT);
                            announceToUser(CONSTANTS.ANNOUNCE_RESULTS);

                            $input.selectionStart = $input.value.length;
                            $input.selectionEnd = $input.value.length;
                        }

                        return;
                    } else if (
                        _currentIndex >= 0 &&
                        (keycode === coned.constants.KEY_CODE.ENTER ||
                            keycode === coned.constants.KEY_CODE.RIGHT)
                    ) {
                        $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');

                        if (!$target.dataset || $target.dataset.isNonInput !== 'true') {
                            $input.value = coned.utils.entitiesDecode($target.dataset.actualValue);
                            $input.selectionStart = $input.selectionEnd;
                            $input.scrollLeft = $input.scrollWidth;
                            _currentInputValue = $input.value;
                        } else {
                            query.fireEvent($target, 'click');

                            return;
                        }

                        announceToUser(CONSTANTS.ANNOUNCE_SELECTION);

                        _filteredList = [];
                        _currentIndex = -1;
                        $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                        $input.removeAttribute(CONSTANTS.ARIA_ACTIVEDESCENDANT);

                        if (_filteringEnable) {
                            // Create input event
                            var evt;
                            // If not IE
                            if (typeof Event === 'function') {
                                evt = new Event('input', {
                                    bubbles: true,
                                    cancelable: true
                                });
                                // If IE
                            } else {
                                evt = document.createEvent('Event');
                                evt.initEvent('input', true, true); // Flags: Bubbles, Cancelable
                            }

                            $input.dispatchEvent(evt);
                        }

                        query.fireEvent($input, 'selectedOption');

                        return;
                    } else if (keycode === coned.constants.KEY_CODE.ESC) {
                        if (_currentIndex >= 0 && _currentIndex < $itemList.children.length) {
                            $target.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
                        }

                        _filteredList = [];
                        _currentIndex = -1;
                        _hideSuggestions = true;
                        $input.value = _currentInput;
                        $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                        $input.removeAttribute(CONSTANTS.ARIA_ACTIVEDESCENDANT);

                        return;
                    } else if (_currentIndex == -1 && keycode === coned.constants.KEY_CODE.ENTER) {
                        return;
                    }
                }
            }

            if (_hideSuggestions) {
                if (
                    (event && event.keycode === coned.constants.KEY_CODE.DOWN) ||
                    (valid &&
                        ((_keyTrigger && $input.value[$input.value.length - 1] === _keyTrigger) ||
                            (!_keyTrigger && $input.value.length === 0)))
                ) {
                    _hideSuggestions = false;
                } else {
                    return;
                }
            }

            _filteredList = _list;
            value = value ? value : $input.value;
            $itemList.innerHTML = '';
            $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            $input.removeAttribute(CONSTANTS.ARIA_ACTIVEDESCENDANT);

            var inputValue;

            if (_keyTrigger) {
                if (value.toUpperCase().indexOf(_keyTrigger.toUpperCase()) !== -1) {
                    var splitValue = value.split(_keyTrigger);
                    // Removes the first instance of _keyTrigger so the list starts to filter after that
                    inputValue = splitValue.splice(1, splitValue.length - 1).join(_keyTrigger);
                } else {
                    // If it's not in the value, don't start to filter yet
                    _filteredList = [];
                    $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $input.removeAttribute(CONSTANTS.ARIA_ACTIVEDESCENDANT);

                    return;
                }
            } else {
                // There's no key to start filtering, filter with the whole value
                inputValue = value;
            }

            //Check if value changes to trigger input chsnge event
            if (_currentInputValue !== $input.value) {
                // Trigger event to notice the input change
                query.fireEvent($input, 'inputChange');

                _currentInputValue = $input.value;
            }

            if (inputValue && _filteringEnable) {
                // Filters values from list when there's a valid input value

                if (_filteringType === CONSTANTS.SUBSTRING) {
                    _filteredList = _.filter(_list, function (item) {
                        return (
                            item.toUpperCase().substr(0, inputValue.length) ===
                            inputValue.toUpperCase()
                        );
                    });
                } else {
                    _filteredList = _.filter(_list, function (item) {
                        return item.toUpperCase().includes(inputValue.toUpperCase());
                    });
                }

                if (_filteredList.length === 0) {
                    if (_noResultsOption && _noResultsOption !== '' && !_noResultsFlag) {
                        addNoResultsOption();
                    }

                    if (_defaultOption && _defaultOption !== '' && !_defaultOptionFlag) {
                        addDefaultOption();
                    }
                }
            }

            if (
                _filteredList.length &&
                !(_filteredList.length === 1 && _filteredList[0] === inputValue)
            ) {
                $listInput.value = _filteredList.join(',');
                createAutocompleteList();
                $itemListWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);

                if (_defaultOption && _defaultOption !== '' && !_defaultOptionFlag) {
                    addDefaultOption();
                }
            } else {
                if (_filteredList.length === 1 && !$container.dataset.isMarketRates) { 
                    query.fireEvent($input, 'selectedOption');
                    $itemListWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                
                } else if ($container.dataset.isMarketRates) {
                    $listInput.value = _filteredList.join(',');
                    createAutocompleteList();
                    $itemListWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    if ($itemList.innerHTML === '') {
                        if (_noResultsOption && _noResultsOption !== '' && !_noResultsFlag) {
                            addNoResultsOption();
                        }
                    }

                    if (_defaultOption && _defaultOption !== '' && !_defaultOptionFlag) {
                        addDefaultOption();
                    }
                }
            }

            announceToUser(CONSTANTS.ANNOUNCE_RESULTS);
        };

        var createAutocompleteList = function() {
            var fragment = document.createDocumentFragment(),
                $listItem;
            for (var index = 0; index < _filteredList.length; index++) {
                $listItem = createAutocompleteItem(_filteredList[index], fragment);
                fragment.appendChild($listItem);
            }

            $itemList.appendChild(fragment);
        };

        /**
         * Handles the creation of list elements
         * @param item {String} Suggestion to be added to the list
         */
        var createAutocompleteItem = function (item, list) {
            var $item = document.createElement('li'),
                $unhighlight = document.createElement('span'),
                $highlight = document.createElement('span'),
                itemIndex = list ? list.children.length : $itemList.children.length;

            $item.setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
            
            $item.setAttribute(CONSTANTS.ARIA_POSINSET, itemIndex + 1);
            $item.setAttribute(CONSTANTS.ROLE_ATTRIBUTE, CONSTANTS.OPTION_ROLE_ATTRIBUTE);
            $item.setAttribute(
                CONSTANTS.ID_ATTRIBUTE,
                CONSTANTS.AUTOCOMPLETE_ITEM_CLASS + '--' + itemIndex  
            );
            $item.classList.add(CONSTANTS.AUTOCOMPLETE_ITEM);
            $item.classList.add(CONSTANTS.AUTOCOMPLETE_ITEM_CLASS);
            $highlight.classList.add(CONSTANTS.AUTOCOMPLETE_ITEM_HIGHLIGHT);
            $unhighlight.classList.add(CONSTANTS.AUTOCOMPLETE_ITEM_UNHIGHLIGHT);

            if (
                _keyTrigger &&
                $input.value.toUpperCase().indexOf(_keyTrigger.toUpperCase()) !== -1
            ) {
                $highlight.innerHTML = _keyTrigger + item;
                $unhighlight.innerHTML = $input.value.split(_keyTrigger)[0];
            } else {
                $highlight.innerHTML = item;
                $unhighlight.innerHTML = '';
            }

            $item.setAttribute(
                'data-actual-value',
                decodeURIComponent($unhighlight.innerHTML) +
                    decodeURIComponent($highlight.innerHTML)
            );

            $item.appendChild($unhighlight);
            $item.appendChild($highlight);

            $item.addEventListener('mouseover', onItemHoverIn);
            $item.addEventListener('mouseout', onItemHoverOut);

            return $item;
        };

        /**
         * Reset Input value and list
         */
        var resetInput = function () {
            $input.value = '';
            $itemList.innerHTML = '';
            $errorWrapper = $input.parentElement.parentElement.getElementsByClassName(
                CONSTANTS.ERROR_WRAPPER_CLASS
            )[0];
            _filteredList = '';
            _list = [];

            $input.classList.remove(coned.constants.INPUT_FILLED_CLASS);
            $input.classList.remove(CONSTANTS.VALID_CLASS);
            $input.classList.remove(coned.constants.INPUT_ERROR_CLASS);

            if ($errorWrapper) {
                $errorWrapper.style['display'] = 'none';
            }

            query.fireEvent($input, CONSTANTS.RESET_INPUT_END_EVENT);
        };

        /**
         * Initializes all elements needed in the module
         */
        var initializeData = function () {
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $input = $container.getElementsByClassName(CONSTANTS.AUTOCOMPLETE_INPUT)[0];
            $listInput = $container.getElementsByClassName(CONSTANTS.AUTOCOMPLETE_LIST_INPUT)[0];
            $announcer = $container.getElementsByClassName(CONSTANTS.AUTOCOMPLETE_ANNOUNCER)[0];
            $itemList = $container.getElementsByClassName(CONSTANTS.AUTOCOMPLETE_ITEM_LIST)[0];
            $itemListWrapper = $container.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_ITEM_LIST_WRAPPER
            )[0];
            $mainContent = document.getElementsByClassName(CONSTANTS.MAIN_CONTENT_CLASS)[0];
            $startServiceForm = document.getElementsByClassName('js-start-service-form')[0];
            $transferServiceForm = document.getElementsByClassName('js-transfer-service-form')[0];
            _keyTrigger =
                $container.dataset.keyTrigger && $container.dataset.keyTrigger !== ''
                    ? $container.dataset.keyTrigger
                    : '';
            _defaultOption =
                $container.dataset.defaultOption && $container.dataset.defaultOption !== ''
                    ? $container.dataset.defaultOption
                    : '';
            _defaultOptionLink =
                $container.dataset.defaultOptionLink && $container.dataset.defaultOptionLink !== ''
                    ? $container.dataset.defaultOptionLink
                    : '';
            _noResultsOption =
                $container.dataset.noResultsOption && $container.dataset.noResultsOption !== ''
                    ? $container.dataset.noResultsOption
                    : '';
            _hideSuggestions = false;
            _filteringEnable =
                $container.dataset.filtering &&
                $container.dataset.filtering !== '' &&
                $container.dataset.filtering === 'false'
                    ? false
                    : true;
            _filteringType = $container.dataset._filteringType;
            _filteredList = [];
            _currentIndex = -1;
            _currentInput = '';
            _currentInputValue = '';
            _list = [];
        };

        /**
         * Initializes all events needed in the module
         */
        var initializeEvents = function () {
            if ($container.dataset.loadTrigger && $container.dataset.loadTrigger !== '') {
                $container.addEventListener(
                    $container.dataset.loadTrigger,
                    loadAutocompleteContainer
                );
            } else {
                loadAutocompleteContainer();
            }

            $container.addEventListener('resetInput', resetInput);
            $container.addEventListener('reloadList', loadAutocompleteContainer);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AutocompleteInput.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AutocompleteInput;
})();
