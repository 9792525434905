// ==================== LOAD HERO COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoadHero = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        JS_ITEM_SELECTOR: 'js-fadein-selector',
        JS_IMAGE_SELECTOR: 'js-fadein-image-selector',
        JS_EVENT_MODE_SELECTOR: 'js-event-mode',
        JS_GREETING_TITLE: 'js-greeting-title',
        JS_GREETING_SUBTITLE: 'js-greeting-subtitle',
        ANIMATION_CLASS: 'animated-hero--animation-item',
        ANIMATION_IMAGE_CLASS: 'animated-hero--animation-image',
        SCENARIO_DEFAULT: '1',
        SCENARIO_ARREARS: '2',
        SCENARIO_GENERIC: '3'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LoadHero = function ($heroElement) {
        /**
         * PRIVATE METHODS
         */
        var $fadeInElements,
            $fadeInImageElement,
            $greetingTitle,
            _heroScenario,
            _maintenanceMode,
            _eventMode,
            _mobileSwap = false,
            _desktopSwap = false;

        var eventModeChange = function () {
            // Two parents above since the first parent reprensents the bg container
            var $heroParent = $heroElement.parentElement.parentElement;

            // Event Mode Hero div should be the first div in Mobile
            if (coned.utils.isMobile()) {
                if (!_mobileSwap) {
                    $heroParent.insertBefore($heroElement.parentElement, $heroParent.firstChild);

                    // Change flags to only do the change once
                    _desktopSwap = false;
                    _mobileSwap = true;
                }
            }
            // and it should remain to the right in Desktop
            else {
                if (!_desktopSwap) {
                    $heroParent.insertBefore($heroElement.parentElement, $heroParent.lastChild);

                    // Change flags to only do the change once
                    _desktopSwap = true;
                    _mobileSwap = false;
                }
            }
        };

        var triggerTagging = function () {
            var scenario = '';
            //Scenario has a code on the html
            // 1: Default
            // 2: Arrear
            // 3: Generic
            switch (_heroScenario) {
                case CONSTANTS.SCENARIO_DEFAULT:
                    scenario = 'default';
                    break;
                case CONSTANTS.SCENARIO_ARREAR:
                    scenario = 'arrear';
                    break;
                case CONSTANTS.SCENARIO_GENERIC:
                    scenario = 'generic';
                    break;
            }

            // Analytics data building
            dataLayer.push({
                HomeScenario: scenario
            });
        };

        var updateScenario = function () {
            if (!_maintenanceMode) {
                var serviceUrl = $heroElement.dataset.serviceUrl,
                    params;

                // Service Data
                params = {
                    ScId: $heroElement.dataset.scid
                };

                // Service Call
                params = JSON.stringify(params);
                query.postData(
                    serviceUrl,
                    successUpdateScenario,
                    errorUpdateScenario,
                    params,
                    true
                );
            }
        };

        var successUpdateScenario = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_ACCOUNT_HOMEPAGE_SCENARIO,
                    setUpdateScenario,
                    errorUpdateScenario
                );
            } else {
                setUpdateScenario(data);
            }
        };

        var errorUpdateScenario = function () {
            //When error occur generic message will be send so no need to catch it here
        };

        var setUpdateScenario = function (data) {
            var subtitle = $heroElement.getElementsByClassName(CONSTANTS.JS_GREETING_SUBTITLE)[0];

            subtitle.innerHTML = data.Message;
            _heroScenario = data.Scenario;

            triggerTagging();
        };

        var initializeData = function () {
            $fadeInElements = $heroElement.getElementsByClassName(CONSTANTS.JS_ITEM_SELECTOR);
            $fadeInImageElement = document.getElementsByClassName(CONSTANTS.JS_IMAGE_SELECTOR);
            $greetingTitle = $heroElement.getElementsByClassName(CONSTANTS.JS_GREETING_TITLE)[0];
            _heroScenario = $heroElement.dataset.scenario;
            _maintenanceMode = $heroElement.dataset.maintenance === 'true' ? true : false;
        };

        var initializeEvents = function () {
            var timerAnimation = 300,
                itemTimer = 0;

            if ($fadeInImageElement && $fadeInImageElement.length) {
                setTimeout(function () {
                    query.removeClass($fadeInImageElement, CONSTANTS.ANIMATION_IMAGE_CLASS);
                }, itemTimer);
            }

            _.each($fadeInElements, function (itemHero) {
                setTimeout(function () {
                    query.removeClass(itemHero, CONSTANTS.ANIMATION_CLASS);
                }, itemTimer + timerAnimation);

                //Updating timer
                itemTimer += timerAnimation;
            });

            // resize Listener for Event Mode
            if (_eventMode) {
                window.addEventListener('resize', eventModeChange);

                // execute the event mode change on load
                eventModeChange();
            }

            if ($greetingTitle) {
                // lineClamp Title
                coned.utils.lineClamp($greetingTitle);
            }

            if (_heroScenario) {
                triggerTagging();
            }

            $heroElement.addEventListener('account-change', updateScenario);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoadHero.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LoadHero;
})();
