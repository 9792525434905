// ==================== UNAUTHENTICATED LOGIN COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.UnauthenticatedLogin = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Form Container Constants
        ACCOUNT_NUMBER_FORMS: 'js-unauthenticated-login-account-number-form',
        CREDENTIALS_FORMS: 'js-unauthenticated-login-credentials-form',
        ENTRY_RECOVERY_CODE_FORM: 'js-entry-recovery-code-form',
        ENTRY_RECOVERY_CODE_FORM_WRAPPER: 'js-entry-recovery-code-form-wrapper',
        MAIN_LOGIN_FORMS: 'js-unauthenticated-login-forms',
        SEND_RECOVERY_FORM: 'js-send-recovery-code-form',
        SEND_RECOVERY_FORM_WRAPPER: 'js-send-recovery-code-form-wrapper',
        VERIFY_MFA_FORM: 'js-unauthenticated-login-verify-mfa-form',
        VERIFY_MFA_WRAPPER: 'js-unauthenticated-login-verify-mfa-wrapper',

        //Form Name Constants
        ACCOUNT_NUMBER_INPUT_NAME: 'accountNumber',
        CREDENTIALS_EMAIL_NAME: 'credentialsEmail',
        CREDENTIALS_PASSWORD_NAME: 'credentialsPassword',
        CONFIRM_ACCOUNT_METHOD_RADIOS_NAME: 'unauthenticatedLoginConfirmType',
        VERIFICATION_CODE_NAME: 'verificationCode',
        VERIFY_MFA_CODE_INPUT_NAME: 'unauthenticatedLoginMFACode',

        // Input Constants
        ACCOUNT_SCID_INPUT: 'UnauthenticatedLoginAccountSCID',
        ACCOUNT_NUMBER_INPUT: 'js-unauthenticated-login-account-number',
        CONFIRM_ACCOUNT_METHOD_RADIOS: 'js-unauthenticated-login-confirm-type',
        CREDENTIALS_EMAIL_INPUT: 'js-unauthenticated-login-email-input',
        CREDENTIALS_PASSWORD_INPUT: 'js-unauthenticated-login-password-input',
        CREDENTIALS_EMAIL_NAME_MOBILE: 'credentialsEmailM',
        CREDENTIALS_EMAIL_NAME_DESKTOP: 'credentialsEmailD',
        MAID_INPUT: 'UnauthenticatedLoginMAID',
        VERIFICATION_CODE_INPUT: 'js-unathenticated-login-verification-code-input',
        VERIFY_MFA_CODE_INPUT: 'js-unauthenticated-login-device-code-input',

        // Submit Constants
        CONFIRM_ACCOUNT_SUBMIT: 'js-unauthenticated-login-confirm-account-submit',
        RECOVERY_FORM_SUBMIT: 'js-unauthenticated-login-recovery-form-submit',
        VERIFY_MFA_SUBMIT: 'js-unauthenticated-login-verify-mfa-submit',

        // Selector Constants
        ACCOUNT_INFO_CONTAINER: 'js-unathenticated-login-account-info-container',
        ACCOUNT_NUMBER_ERROR: 'js-account-number-service-error',
        ACCOUNT_NUMBER_ERROR_MESSAGE: 'js-account-number-error-message',
        LIVE_CHAT_AVAILABLE_SELECTOR: 'js-live-chat-available',
        LIVE_CHAT_ERROR_MESSAGE: 'js-live-chat-error-message',
        LIVE_CHAT_AVAILABLE_MESSAGE: 'js-live-chat-available-message',
        LIVE_CHAT_MOBILE_BUTTON: 'js-live-chat-cta-m',
        LIVE_CHAT_DESKTOP_BUTTON: 'js-live-chat-cta-d',
        CALL_CUSTOMER_SERVICE: 'js-unauthenticated-login-call-customer-service',
        CONFIRM_ACCOUNT_DIFFERENT_ACCOUNT: 'js-unathenticated-login-different-account',
        CREDENTIALS_SHOW_PASSWORD: 'js-credentials-show-password',
        CREDENTIALS_ERROR_MSG: 'js-credentials-error-msg',
        RADIO_INPUT_CLASS: 'js-coned-radio',
        RADIO_LABEL: 'coned-radio__label-name',
        RECOVERY_EMAIL_OPTION: 'js-email-option',
        RECOVERY_PHONE_OPTION: 'js-phone-option',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        TRANSACTIONAL_SUBMIT_BUTTON_SELECTOR: 'js-transactional-submit-selector',
        VERIFICATION_CODE_SEND_AGAIN: 'js-unathenticated-login-send-code-again',
        VERIFY_MFA_CODE_LABEL: 'js-unauthenticated-login-device-code-label',
        VERIFY_MFA_ERROR_MSG: 'js-unauthenticated-login-verify-mfa-error-msg',
        VERIFY_MFA_NEW_DEVICE_INTRO: 'js-unauthenticated-login-new-device-intro',

        // Other Constants
        UNAUTH_LOGIN_BUTTON: 'js-unauth-login-button',
        UNAUTH_LOGIN_MOBILE_BUTTON: 'js-unauth-login-button-m',
        EMAIL_MASK: '{{EMAIL}}',
        FROM_URI_PARAMETER_NAME: 'fromURI',
        HIDDEN_CLASS: 'hidden',
        FORM_IGNORE_VALIDATION: ':hidden',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        INPUT_ERROR_CLASS: 'coned-input-message--error',
        RELAY_STATE_PARAMETER_NAME: 'RelayState',
        RETURN_URL_PARAMETER_NAME: 'returnUrl',
        SITECORE_ID: 'stopServiceScId',
        TELEPHONE_MASK: '{{TELEPHONE}}',
        LIVE_CHAT_ON_PARAM: 'liveChatOn',
        INIT_CHAT:  'init',
        STOP_CHAT: 'stop',

        // Tagging Constants
        TAGGING_ACCOUNT_NUMBER: 'Account.Number',
        TAGGING_INTENT_START_FLOW: 'Intent.Start.Flow',
        TAGGING_ACCOUNT_NUMBER_ERROR: 'Account.Number.Error',
        TAGGING_CONFIRM_ACCOUNT_ERROR: 'Confirm.Account.Error',
        TAGGING_CONFIRM_ACCOUNT_TRY_AGAIN: 'Confirm.Account.Try.Again',
        TAGGING_CONFIRM_CHOOSE_DIFFERENT_ACCOUNT: 'Choose.Different.Account',
        TAGGING_LOG_IN_TO_TRANSFER: 'Log.In.To.Transfer',
        TAGGING_PREFIX: 'Unauthenticated.',
        TAGGING_SUBMIT_ERROR: 'Submit.Error',
        TAGGING_SUBMIT_SUCCESS: 'Submit.Success',
        TAGGING_SEND_CODE_SUCCESS: 'Send.Code',
        CAPTCHA_ELEMENT_CLASS: 'js-recaptcha',
        LOGIN_SUCCESS_TAG: 'Login.Success',
        TAGGING_LIVE_CHAT_DISPLAYS:'flow.address.error',
        TAGGING_LIVE_CHAT_EVENT:'live-chat-tagging'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var UnauthenticatedLogin = function ($unauthenticatedLogin) {
        // Form Container Variables
        var $accountNumberForm,
            $credentialForm,
            $entryRecoveryCodeFormWrapper,
            $mainLoginForms,
            $sendRecoveryForm,
            $sendRecoveryFormWrapper,
            $verifyMFAForm,
            $verifyMFAWrapper,
            // Input Variables
            $accountNumberInput,
            $confirmAccountRadios,
            $credentialsEmailInput,
            $credentialsPasswordInput,
            $maidInput,
            $verificationCodeInput,
            $verifyMFACodeInput,
            // Submit Variables
            $confirmAccountSubmit,
            $recoveryFormSubmit,
            $submitAnimationButton,
            $verifyMFASubmit,
            // Selector Variables
            $accountInfoContainer,
            $accountNumberError,
            $accountNumberErrorMessage,
            $liveChatErrorMessage,
            $liveChatAvailableMessage,
            $liveChatAvailable,
            $liveChatAvailableMobileBtn,
            $liveChatAvailableDesktopBtn,
            $callCustomerService,
            $confirmAccountDifferentAccount,
            $credentialsShowPassword,
            $credentialsErrorMsg,
            $recoveryEmailOption,
            $recoveryPhoneOption,
            $serviceError,
            $serviceErrorMessage,
            $verificationCodeSendAgain,
            $verifyMFACodeLabel,
            $verifyMFAErrorMsg,
            $verifyMFANewDeviceIntro,
            // Other Variables
            recaptchaDesktop,
            recaptchaMobile,
            recaptchaIndex,
            _data,
            _taggingPreffix,
            _liveChatOn,
            _isMobileVersion,
            $captchaElement,
            _hasCaptcha,
            _liveChatTagging;

        /**
        * Global login tagging event for all cases
        */
        var addGlobalLoginTagging = function () {
            dataLayer.push({
                event: CONSTANTS.LOGIN_SUCCESS_TAG
            });
        };

        /**
         * Shows error message
         */
        var displayErrorMessage = function ($errorMsg, errorMsg) {
            if ($errorMsg.length) {
                _.each($errorMsg, function (error) {
                    error.innerHTML = errorMsg ? errorMsg : coned.constants.ERROR_MESSAGE;
                    error.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    error.parentElement.style.display = 'block';
                });
            } else {
                $errorMsg.innerHTML = errorMsg ? errorMsg : coned.constants.ERROR_MESSAGE;
                $errorMsg.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $errorMsg.parentElement.style.display = 'block';
            }
        };

        /**
         * Hides error message
         */
        var hideErrorMessage = function ($errorMsg) {
            if ($errorMsg.length) {
                _.each($errorMsg, function (error) {
                    error.innerHTML = '';
                    error.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                    error.parentElement.style.display = 'none';
                });
            } else {
                $errorMsg.innerHTML = '';
                $errorMsg.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $errorMsg.parentElement.style.display = 'none';
            }
        };

        /**
         * Hides all error messages
         */
        var hideError = function () {
            _.each($serviceError, function (serviceError) {
                serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });
        };

        /**
         * Handles enabling/disabling the MFA verification submit button
         */
        var verifyMFAInputEvent = function (event) {
            $verifyMFASubmit.disabled = event.target.value === '';
        };

        /**
         * Handles enabling/disabling the entry code submit button
         */
        var verificationCodeInputEvent = function (event) {
            $recoveryFormSubmit.disabled = event.target.value === '';
        };

        /**
         * Toggles show/hide password.
         */
        var togglePassword = function (event) {
            event.preventDefault();

            var inputField = event.currentTarget.parentElement.getElementsByClassName(
                    CONSTANTS.CREDENTIALS_PASSWORD_INPUT
                )[0],
                inputType = inputField.type,
                button,
                buttonText;

            if (inputType == 'password') {
                _.each($credentialsPasswordInput, function (input) {
                    input.type = 'text';
                    buttonText = event.currentTarget.dataset.replaceText;
                    button = event.target;
                    button.innerHTML = buttonText;
                });
            } else {
                _.each($credentialsPasswordInput, function (input) {
                    input.type = 'password';
                    buttonText = event.currentTarget.dataset.text;
                    button = event.target;
                    button.innerHTML = buttonText;
                });
            }
        };

        /**
         * Shows error message
         */
        var enableSubmitButton = function ($targetForm) {
            if ($targetForm.length && $targetForm.length > 0) {
                _.each($targetForm, function (form) {
                    var submitButton = form.getElementsByClassName(
                        CONSTANTS.TRANSACTIONAL_SUBMIT_BUTTON_SELECTOR
                    )[0];

                    submitButton.disabled = !$(form).validate().checkForm();
                });
            } else {
                var submitButton = $targetForm.getElementsByClassName(
                    CONSTANTS.TRANSACTIONAL_SUBMIT_BUTTON_SELECTOR
                )[0];

                submitButton.disabled = !$($targetForm).validate().checkForm();
            }
        };

        /**
         * Manages the MFA verification.
         */
        var submitNewDeviceHandler = function () {
            var $formSelector = $verifyMFAWrapper,
                $errorMsg = $verifyMFAErrorMsg,
                params,
                returnUrl = coned.utils.getUrlParameterValue(CONSTANTS.RETURN_URL_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.RETURN_URL_PARAMETER_NAME)
                    : $credentialForm[0].dataset.redirectUrl
                    ? $credentialForm[0].dataset.redirectUrl
                    : '',
                fromUri = coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    : '',
                relayState = coned.utils.getUrlParameterValue(CONSTANTS.RELAY_STATE_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.RELAY_STATE_PARAMETER_NAME)
                    : '',
                _data;

            // Verify which value to send as the returnUrl
            if (returnUrl === '') {
                returnUrl = fromUri;
            }

            // Service Data
            params = {
                MFACode: query.getFormInputValue(
                    $formSelector,
                    CONSTANTS.VERIFY_MFA_CODE_INPUT_NAME
                ),
                ReturnUrl: returnUrl,
                FromURI: fromUri,
                OpenIdRelayState: relayState
            };
            params = JSON.stringify(params);

            hideErrorMessage($errorMsg);
            $submitAnimationButton = $formSelector.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];

            // Service Call
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                newDeviceCall,
                function () {},
                errorPostDeviceDataCallback,
                false,
                true
            );

            function newDeviceCall() {
                query.postData(
                    $verifyMFAForm.dataset.newDeviceUrl,
                    successDeviceCallback,
                    errorDeviceCallback,
                    params,
                    true
                );
            }

            function successPostDeviceDataCallback() {
                if (coned.utils.isPatternLab()) {
                    query.getData(
                        coned.plConstants.GET_UNAUTHENTICATED_LOGIN_MFA,
                        function (data) {
                            checkDeviceCode(data, $errorMsg);
                        },
                        function () {
                            displayErrorMessage($errorMsg, coned.constants.ERROR_MESSAGE);
                        }
                    );
                } else {
                    checkDeviceCode(_data, $errorMsg);
                }
            }

            function errorPostDeviceDataCallback() {
                displayErrorMessage($errorMsg, coned.constants.ERROR_MESSAGE);
            }

            function successDeviceCallback(data) {
                _data = data;
                successPostDeviceDataCallback();
            }

            function errorDeviceCallback() {
                $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            }
        };

        /**
         * Checks validity of the code.
         */
        var checkDeviceCode = function (data, $errorMsg) {
            if (data.code) {
                // Tagging Log in to transfer
                dataLayer.push({
                    event: _taggingPreffix + CONSTANTS.TAGGING_LOG_IN_TO_TRANSFER
                });

                authenticationSuccess(data);
            } else {
                // Stop animation manually, since now the animation is not stopped by the success scenario
                $submitAnimationButton.classList.add(
                    coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                );

                setTimeout(function () {
                    displayErrorMessage($errorMsg, data.codeErrorMsg);
                }, 1500);
            }
        };

        /**
         * Manages the Login validation.
         */
        var submitLoginHandler = function (event) {
            var $formSelector = query.hasClass(event, CONSTANTS.CREDENTIALS_FORMS)
                    ? event
                    : query.selectParentElement(event.target, CONSTANTS.CREDENTIALS_FORMS),
                $errorMsg = $credentialsErrorMsg,
                params,
                loginEmail = query
                    .getFormInputValue(
                        $formSelector,
                        CONSTANTS.CREDENTIALS_EMAIL_NAME + $formSelector.dataset.uiType
                    )
                    .trim(),
                returnUrl = coned.utils.getUrlParameterValue(CONSTANTS.RETURN_URL_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.RETURN_URL_PARAMETER_NAME)
                    : $credentialForm[0].dataset.redirectUrl
                    ? $credentialForm[0].dataset.redirectUrl
                    : '',
                fromUri = coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    : '',
                relayState = coned.utils.getUrlParameterValue(CONSTANTS.RELAY_STATE_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.RELAY_STATE_PARAMETER_NAME)
                    : '',
                _data;

            // Verify which value to send as the returnUrl
            if (returnUrl === '') {
                returnUrl = fromUri;
            }

            // Delete login cookie before request
            var req = new XMLHttpRequest();

            req.open('DELETE', $formSelector.dataset.logoutUrl);
            req.withCredentials = true;
            req.setRequestHeader('Accept', 'application/json');
            req.send(null);

            // Service Data
            params = {
                LoginEmail: loginEmail,
                LoginPassword: query.getFormInputValue(
                    $formSelector,
                    CONSTANTS.CREDENTIALS_PASSWORD_NAME + $formSelector.dataset.uiType
                ),
                LoginRememberMe: false,
                ReturnUrl: returnUrl,
                FromURI: fromUri,
                OpenIdRelayState: relayState
            };
            params = JSON.stringify(params);

            hideErrorMessage($errorMsg);
            $submitAnimationButton = $formSelector.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];

            // Service Call
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                loginCall,
                function () {},
                errorPostLoginDataCallback,
                false,
                true
            );

            function loginCall() {
                query.postData(
                    $formSelector.dataset.loginUrl,
                    successLoginCallback,
                    errorLoginCallback,
                    params,
                    true
                );
            }

            function successPostLoginDataCallback() {
                if (coned.utils.isPatternLab()) {
                    query.getData(
                        coned.plConstants.GET_LOGIN,
                        function (data) {
                            checkLogin($formSelector, data, $errorMsg);
                        },
                        function () {
                            displayErrorMessage($errorMsg, coned.constants.ERROR_MESSAGE);
                        }
                    );
                } else {
                    checkLogin($formSelector, _data, $errorMsg);
                }
            }

            function errorPostLoginDataCallback() {
                displayErrorMessage($errorMsg, coned.constants.ERROR_MESSAGE);
            }

            function successLoginCallback(data) {
                _data = data;
                successPostLoginDataCallback();
            }

            function errorLoginCallback() {
                $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            }
        };

        /**
         * Checks if user requires MFA verification.
         */
        var checkLogin = function (formSelector, data, $errorMsg) {
            var $newDeviceForm = $verifyMFAWrapper,
                $newDeviceIntroMsg = $verifyMFANewDeviceIntro,
                $newDeviceInput = $verifyMFACodeInput,
                $newDeviceInputLabel = $verifyMFACodeLabel;

            if (data.login) {
                // login was successful
                // Add Set MFA page redirect
                if (data.noMfa) {
                    authenticationSuccess(data);
                    return;
                }

                if (data.newDevice) {
                    // New device case scenario
                    window.scrollTo(0, 0);

                    // Stop animation manually, even though this won't be seen with the 'page' change
                    $submitAnimationButton.classList.add(
                        coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                    );

                    // Set innerTexts from service response
                    $newDeviceIntroMsg.innerText = data.newDeviceText;
                    $newDeviceInputLabel.innerText = data.newDeviceInputLabel;

                    // Switch input validation depending on the type of answer to enter
                    if (data.isNumeric) {
                        // If code is going to be added, number validation should be added
                        $newDeviceInput.classList.add(CONSTANTS.INPUT_NUMBER);
                        $newDeviceInput.type = 'tel';
                        $newDeviceInput.pattern = '[0-9]*';
                    } else {
                        // If not, it should be just text
                        $newDeviceInput.classList.remove(CONSTANTS.INPUT_NUMBER);
                        $newDeviceInput.type = 'text';
                        $newDeviceInput.patttern = '';
                    }

                    // Re-instance form validation module for it to change its text or numeric validation
                    new coned.components.FormValidationModule($newDeviceForm);

                    // Display new device verification form
                    $mainLoginForms.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $newDeviceForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    // Device is not new
                    // Tagging Log in to transfer
                    dataLayer.push({
                        event: _taggingPreffix + CONSTANTS.TAGGING_LOG_IN_TO_TRANSFER
                    });
                    addGlobalLoginTagging();

                    window.location.href = data.authRedirectUrl;
                }
            } else {
                // Login was unsucessful
                // Stop animation manually, since now the animation is not stopped by the success scenario
                $submitAnimationButton.classList.add(
                    coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                );

                setTimeout(function () {
                    displayErrorMessage($errorMsg, data.loginErrorMsg);
                }, 1500);
            }
        };

        /**
         * Validates the recaptcha with account number validation.
         */
        var checkAccountSubmitHandler = function (event) {
            var $targetForm = query.hasClass(event, CONSTANTS.ACCOUNT_NUMBER_FORMS)
                ? event
                : query.selectParentElement(event.target, CONSTANTS.ACCOUNT_NUMBER_FORMS);
            recaptchaIndex = $targetForm.dataset.uiType === 'D' ? 0 : 1;

            // Tagging Submit Account Number
            dataLayer.push({
                event: _taggingPreffix + CONSTANTS.TAGGING_ACCOUNT_NUMBER
            });

            dataLayer.push({
                event: _taggingPreffix + CONSTANTS.TAGGING_INTENT_START_FLOW
            });

            if (recaptchaIndex) {
                recaptchaMobile.checkRecaptcha($targetForm, true, recaptchaIndex);
                _isMobileVersion = true;
            } else {
                recaptchaDesktop.checkRecaptcha($targetForm, true, recaptchaIndex);
                _isMobileVersion = false;
            }
        };

        /**
         * Manages the Login with account number validation.
         */
        var checkAccountSubmitRecaptcha = function (event) {
            var $targetForm = query.hasClass(event, CONSTANTS.ACCOUNT_NUMBER_FORMS)
                ? event
                : query.selectParentElement(event.target, CONSTANTS.ACCOUNT_NUMBER_FORMS);

            query.addClass($accountNumberError, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($accountNumberInput, CONSTANTS.INPUT_ERROR_CLASS);

            $submitAnimationButton = $targetForm.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];

            //Adding live chat testing scenario
            if(coned.utils.isPatternLab() && _liveChatOn && _liveChatOn === 'true') {
                new coned.components.SubmitAnimation(
                    $submitAnimationButton,
                    checkAccountCall,
                    errorAccountServiceCallback,
                    errorAccountServiceCallback,
                    false,
                    true
                );
            }else {
                new coned.components.SubmitAnimation(
                    $submitAnimationButton,
                    checkAccountCall,
                    successCheckAccountServiceCallback,
                    errorAccountServiceCallback,
                    false,
                    true
                );
            }
        };

        /**
         * Post-animation call to service.
         */
        var checkAccountCall = function () {
            hideError();

            var params,
                accountNumberService = $accountNumberForm[0].dataset.checkAccountService;

            params = {
                ScId: query.getFormInputValue($unauthenticatedLogin, CONSTANTS.SITECORE_ID),
                AddAccountScId: query.getFormInputValue(
                    $unauthenticatedLogin,
                    CONSTANTS.ACCOUNT_SCID_INPUT
                ),
                AccountMaid: query.getFormInputValue(
                    $accountNumberForm[0],
                    CONSTANTS.ACCOUNT_NUMBER_INPUT_NAME + $accountNumberForm[0].dataset.uiType
                )
            };

            if (recaptchaIndex) {
                if (_hasCaptcha && recaptchaMobile && recaptchaMobile.getResponse() !== '') {
                    params.Verify = recaptchaMobile.getResponse();
                }
            } else {
                if (_hasCaptcha && recaptchaDesktop && recaptchaDesktop.getResponse() !== '') {
                    params.Verify = recaptchaDesktop.getResponse();
                }
            }

            params = JSON.stringify(params);
            query.postData(accountNumberService, successCallback, errorCallback, params, true);
        };

        /**
         * Generic success callback.
         */
        var successCallback = function (data) {
            _data = data;
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        /**
         * Generic error callback
         */
        var errorCallback = function (data) {
            _data = data;
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
        };

        /**
         * Generic service error callback.
         */
        var errorServiceCallback = function () {
            // Tagging Submit Error
            dataLayer.push({
                event: _taggingPreffix + CONSTANTS.TAGGING_SUBMIT_ERROR
            });

            _.each($serviceError, function (serviceError) {
                serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorMessage, function (serviceErrorMessage) {
                serviceErrorMessage.innerHTML = _data.errorMsg
                    ? _data.errorMsg
                    : coned.constants.ERROR_MESSAGE;
            });

            $serviceError[0].focus();
        };

        /**
         * Account number service error callback.
         */
        var errorAccountServiceCallback = function () {
            var liveChatAvailable = $unauthenticatedLogin.dataset.liveChatAvailable;
            
            if (liveChatAvailable && liveChatAvailable === 'true') {
                //Adding live chat testing scenario
                if(coned.utils.isPatternLab() && _liveChatOn && _liveChatOn === 'true') {
                    //Simulate response data
                    _data.liveChatAvailableMessage = "We can't stop service for this account. Get instant help - chat with a live agent now.";
                    _data.errorMsg = "Error: Please call us at 1-877-779-6943 to complete your service request.";
                }

                //Init both live chat buttons the order is important to have the correct focus for the user
                if(_isMobileVersion) {
                    coned.utils.triggerEvent($liveChatAvailableDesktopBtn, CONSTANTS.INIT_CHAT);

                    setTimeout(function () {
                        coned.utils.triggerEvent($liveChatAvailableMobileBtn, CONSTANTS.INIT_CHAT);
                    }, 500);                    
                } else {
                    coned.utils.triggerEvent($liveChatAvailableMobileBtn, CONSTANTS.INIT_CHAT);

                    setTimeout(function () {
                        coned.utils.triggerEvent($liveChatAvailableDesktopBtn, CONSTANTS.INIT_CHAT);
                    }, 500);                    
                }          

                if (_data && _data.errorMsg && _data.liveChatAvailableMessage) {
                    _.each($liveChatErrorMessage, function (message) {
                        message.innerHTML = _data.errorMsg;
                    });
                    _.each($liveChatAvailableMessage, function (message) {
                        message.innerHTML = _data.liveChatAvailableMessage;
                    });
                }

                _.each($accountNumberForm, function (form) {
                    var submitButton = form.getElementsByClassName(
                        CONSTANTS.TRANSACTIONAL_SUBMIT_BUTTON_SELECTOR
                    )[0];

                    submitButton.disabled = true;
                });

                //This event listen for the first time the chat appears to be triggered
                $liveChatAvailableMobileBtn.addEventListener(CONSTANTS.TAGGING_LIVE_CHAT_EVENT, function(){
                    if(_liveChatTagging) {
                        dataLayer.push({
                            event: CONSTANTS.TAGGING_LIVE_CHAT_DISPLAYS
                        });
                        _liveChatTagging = false;
                    }
                });

                //This event listen for the first time the chat appears to be triggered
                $liveChatAvailableDesktopBtn.addEventListener(CONSTANTS.TAGGING_LIVE_CHAT_EVENT, function(){
                    if(_liveChatTagging) {
                        dataLayer.push({
                            event: CONSTANTS.TAGGING_LIVE_CHAT_DISPLAYS
                        });
                        _liveChatTagging = false;
                    }
                });

            } else {
                // Shows the service error and overrides the jQuery validator error hide.
                query.removeClass($accountNumberError, CONSTANTS.HIDDEN_CLASS);
                _.each($accountNumberError, function (error) {
                    error.style.display = 'block';
                });
                _.each($accountNumberErrorMessage, function (error) {
                    error.style.display = 'block';
                    error.innerHTML = error.dataset.errorMessage;
                    error.focus();
                });
            }
            if (recaptchaIndex) {
                if (_hasCaptcha && recaptchaMobile && recaptchaMobile.getResponse() !== '') {
                    recaptchaMobile.reset();
                }
            } else {
                if (_hasCaptcha && recaptchaDesktop && recaptchaDesktop.getResponse() !== '') {
                    recaptchaDesktop.reset();
                }
            }

            // Tagging Submit Account Number Error
            dataLayer.push({
                event: _taggingPreffix + CONSTANTS.TAGGING_ACCOUNT_NUMBER
            });

            // Tagging Submit Account Number Error
            dataLayer.push({
                event: _taggingPreffix + CONSTANTS.TAGGING_ACCOUNT_NUMBER_ERROR
            });

            query.addClass($accountNumberInput, CONSTANTS.INPUT_ERROR_CLASS);
        };

        /**
         * Account number service success callback.
         */
        var successCheckAccountServiceCallback = function () {

            //If live chat is available and the service is available clear interval
            if($liveChatAvailable) {
                coned.utils.triggerEvent($liveChatAvailableMobileBtn, CONSTANTS.STOP_CHAT);
                coned.utils.triggerEvent($liveChatAvailableDesktopBtn, CONSTANTS.STOP_CHAT);
            }

            $mainLoginForms.classList.add(CONSTANTS.HIDDEN_CLASS);
            $sendRecoveryFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // TODO: Submit Success should only be tagged at Last Submission
            // dataLayer.push({
            //     'event' : _taggingPreffix + CONSTANTS.TAGGING_SUBMIT_SUCCESS
            // });

            // Set containers with correct account number
            _.each($accountInfoContainer, function ($infoContainer) {
                $infoContainer.innerHTML = $accountNumberInput[0].value;
            });

            // Set Account Maid
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.GET_ACCOUNT_MAID, setAccountMaid, function () {});
            } else {
                setAccountMaid(_data);
            }
        };

        var setAccountMaid = function (data) {
            var havePhone = data.PhoneNumber,
                haveEmail = data.Email;

            // Format email if user has one, hide it otherwise
            if (!haveEmail) {
                $recoveryEmailOption.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                var $emailRadio = $recoveryEmailOption.getElementsByClassName(
                        CONSTANTS.RADIO_INPUT_CLASS
                    )[0],
                    $emailLabel = $recoveryEmailOption.getElementsByClassName(
                        CONSTANTS.RADIO_LABEL
                    )[0],
                    userEmail = data.Email;
                    
                // Reset radio text before updating it
                $emailLabel.innerHTML = $recoveryEmailOption.dataset.placeholder;
                $emailLabel.innerHTML = $emailLabel.innerHTML.replace(
                    CONSTANTS.EMAIL_MASK,
                    userEmail
                );

                // Reset radio input
                $emailRadio.checked = false;
                $recoveryEmailOption.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            // Format phone if user has one, hide it otherwise
            if (!havePhone) {
                _.each($recoveryPhoneOption, function ($phoneOption) {
                    $phoneOption.classList.add(CONSTANTS.HIDDEN_CLASS);
                });
            } else {
                var maskedPhoneNumber = data.PhoneNumber;

                _.each($recoveryPhoneOption, function ($phoneOption) {
                    var $phoneRadio = $phoneOption.getElementsByClassName(
                            CONSTANTS.RADIO_INPUT_CLASS
                        )[0],
                        $phoneLabel = $phoneOption.getElementsByClassName(CONSTANTS.RADIO_LABEL)[0];

                    // Reset radio text before updating it
                    $phoneLabel.innerHTML = $phoneOption.dataset.placeholder;
                    $phoneLabel.innerHTML = $phoneLabel.innerHTML.replace(
                        CONSTANTS.TELEPHONE_MASK,
                        maskedPhoneNumber
                    );

                    // Reset radio input
                    $phoneRadio.checked = false;
                    $phoneOption.classList.remove(CONSTANTS.HIDDEN_CLASS);
                });
            }

            // Display call customer service if account has no phone or email
            if (!haveEmail && !havePhone) {
                $callCustomerService.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $callCustomerService.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            $maidInput.value = data.MAID;

            // Disable submit until option is selected
            $confirmAccountSubmit.disabled = true;

        };

        /**
         * Enable confirm account button on radio button press.
         */
        var confirmOwnershipEvent = function () {
            $confirmAccountSubmit.disabled = false;
        };

        /**
         * Send code again event handler.
         */
        var sendCodeAgainEvent = function (event) {
            event.preventDefault();

            hideError();

            $sendRecoveryFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $entryRecoveryCodeFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Tagging Send Again
            dataLayer.push({
                event: _taggingPreffix + CONSTANTS.TAGGING_CONFIRM_ACCOUNT_TRY_AGAIN
            });
        };

        /**
         * Try with different account number event handler.
         */
        var changeLogin = function (event) {
            event.preventDefault();

            hideError();

            $mainLoginForms.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $sendRecoveryFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            $entryRecoveryCodeFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (recaptchaIndex) {
                if (_hasCaptcha && recaptchaMobile && recaptchaMobile.getResponse() !== '') {
                    recaptchaMobile.reset();
                }
            } else {
                if (_hasCaptcha && recaptchaDesktop && recaptchaDesktop.getResponse() !== '') {
                    recaptchaDesktop.reset();
                }
            }

            // Tagging Change Login
            dataLayer.push({
                event: _taggingPreffix + CONSTANTS.TAGGING_CONFIRM_CHOOSE_DIFFERENT_ACCOUNT
            });
        };

        /**
         * Send recovery code submit handler.
         */
        var sendRecoveryCodeHandler = function () {
            $submitAnimationButton = $sendRecoveryForm.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                sendRecoveryCodeCall,
                successSendRecoveryCodeCallback,
                errorServiceCallback,
                false,
                true
            );
        };

        /**
         * Send recovery code service call.
         */
        var sendRecoveryCodeCall = function () {
            hideError();

            var params,
                sendRecoveryCodeService = $sendRecoveryFormWrapper.dataset.sendRecoveryCodeService,
                confirmType = query.getFormInputValue(
                    $sendRecoveryFormWrapper,
                    CONSTANTS.CONFIRM_ACCOUNT_METHOD_RADIOS_NAME
                );

            params = {
                ScId: query.getFormInputValue($unauthenticatedLogin, CONSTANTS.SITECORE_ID),
                AddAccountScId: query.getFormInputValue(
                    $unauthenticatedLogin,
                    CONSTANTS.ACCOUNT_SCID_INPUT
                ),
                ConfirmType: confirmType,
                MAID: query.getFormInputValue($unauthenticatedLogin, CONSTANTS.MAID_INPUT)
            };

            params = JSON.stringify(params);
            query.postData(sendRecoveryCodeService, successCallback, errorCallback, params, true);
        };

        /**
         * Send recovery code service call success.
         */
        var successSendRecoveryCodeCallback = function () {
            // Tagging Submit Success
            dataLayer.push({
                event: _taggingPreffix + CONSTANTS.TAGGING_SEND_CODE_SUCCESS
            });

            $sendRecoveryFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            $entryRecoveryCodeFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Entry recovery code submit handler.
         */
        var entryRecoveryCodeHandler = function () {
            $submitAnimationButton = $entryRecoveryCodeFormWrapper.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                entryRecoveryCodeCall,
                successEntryRecoveryCodeCallback,
                errorServiceCallback,
                false,
                true
            );
        };

        /**
         * Entry recovery code service call.
         */
        var entryRecoveryCodeCall = function () {
            hideError();

            var params,
                entryRecoveryCodeService =
                    $entryRecoveryCodeFormWrapper.dataset.entryRecoveryCodeService;

            params = {
                ScId: query.getFormInputValue($unauthenticatedLogin, CONSTANTS.SITECORE_ID),
                AddAccountScId: query.getFormInputValue(
                    $unauthenticatedLogin,
                    CONSTANTS.ACCOUNT_SCID_INPUT
                ),
                SecurityCode: query.getFormInputValue(
                    $entryRecoveryCodeFormWrapper,
                    CONSTANTS.VERIFICATION_CODE_NAME
                ),
                MAID: query.getFormInputValue($unauthenticatedLogin, CONSTANTS.MAID_INPUT)
            };

            params = JSON.stringify(params);
            query.postData(entryRecoveryCodeService, successCallback, errorCallback, params, true);
        };

        /**
         * Entry recovery code service call sucess.
         */
        var successEntryRecoveryCodeCallback = function () {
            // Tagging Submit Success
            // TODO: Should Not?
            // dataLayer.push({
            //     'event': _taggingPreffix + CONSTANTS.TAGGING_SUBMIT_SUCCESS
            // });

            // Set page redirect
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.UNAUTHENTICATED_REDIRECT,
                    authenticationSuccess,
                    function () {}
                );
            } else {
                authenticationSuccess(_data);
            }
        };

        /**
         * Authentication success handler.
         */
        var authenticationSuccess = function (data) {
            addGlobalLoginTagging();

            coned.accountData = data;
            coned.utils.triggerEvent($unauthenticatedLogin, 'account-available', data);

            if (data && (data.redirectUrl || data.authRedirectUrl)) {
                window.location.href = data.redirectUrl
                    ? data.redirectUrl
                    : data.authRedirectUrl
                    ? data.authRedirectUrl
                    : $credentialForm[0].dataset.redirectUrl;
            } else {
                $submitAnimationButton.classList.add(
                    coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                );
                coned.utils.triggerEvent($unauthenticatedLogin, 'login-success');
            }
        };

        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            $accountNumberForm = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER_FORMS
            );
            $credentialForm = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.CREDENTIALS_FORMS
            );
            $entryRecoveryCodeFormWrapper = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.ENTRY_RECOVERY_CODE_FORM_WRAPPER
            )[0];
            $mainLoginForms = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.MAIN_LOGIN_FORMS
            )[0];
            $sendRecoveryForm = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.SEND_RECOVERY_FORM
            )[0];
            $sendRecoveryFormWrapper = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.SEND_RECOVERY_FORM_WRAPPER
            )[0];
            $verifyMFAForm = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.VERIFY_MFA_FORM
            )[0];
            $verifyMFAWrapper = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.VERIFY_MFA_WRAPPER
            )[0];

            // Input Variables
            $accountNumberInput = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER_INPUT
            );
            $confirmAccountRadios = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.CONFIRM_ACCOUNT_METHOD_RADIOS
            );
            $credentialsEmailInput = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.CREDENTIALS_EMAIL_INPUT
            );
            $credentialsPasswordInput = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.CREDENTIALS_PASSWORD_INPUT
            );
            $maidInput = document.getElementsByName(CONSTANTS.MAID_INPUT)[0];
            $verificationCodeInput = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.VERIFICATION_CODE_INPUT
            )[0];
            $verifyMFACodeInput = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.VERIFY_MFA_CODE_INPUT
            )[0];

            // Submit Variables
            $confirmAccountSubmit = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.CONFIRM_ACCOUNT_SUBMIT
            )[0];
            $recoveryFormSubmit = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.RECOVERY_FORM_SUBMIT
            )[0];
            $verifyMFASubmit = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.VERIFY_MFA_SUBMIT
            )[0];

            // Selector Variables
            $accountInfoContainer = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.ACCOUNT_INFO_CONTAINER
            );
            $accountNumberError = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER_ERROR
            );
            $accountNumberErrorMessage = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER_ERROR_MESSAGE
            );
            $liveChatAvailable = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_AVAILABLE_SELECTOR
            )[0];
            $liveChatErrorMessage = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_ERROR_MESSAGE
            );
            $liveChatAvailableMessage = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_AVAILABLE_MESSAGE
            );
            $liveChatAvailableMobileBtn = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_MOBILE_BUTTON
            )[0];
            $liveChatAvailableDesktopBtn = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_DESKTOP_BUTTON
            )[0];
            $callCustomerService = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.CALL_CUSTOMER_SERVICE
            )[0];
            $confirmAccountDifferentAccount = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.CONFIRM_ACCOUNT_DIFFERENT_ACCOUNT
            );
            $credentialsShowPassword = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.CREDENTIALS_SHOW_PASSWORD
            );
            $credentialsErrorMsg = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.CREDENTIALS_ERROR_MSG
            );
            $recoveryEmailOption = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.RECOVERY_EMAIL_OPTION
            )[0];
            $recoveryPhoneOption = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.RECOVERY_PHONE_OPTION
            );
            $serviceError = $unauthenticatedLogin.getElementsByClassName(CONSTANTS.SERVICE_ERROR);
            $serviceErrorMessage = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            );
            $verificationCodeSendAgain = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.VERIFICATION_CODE_SEND_AGAIN
            )[0];
            $verifyMFACodeLabel = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.VERIFY_MFA_CODE_LABEL
            )[0];
            $verifyMFAErrorMsg = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.VERIFY_MFA_ERROR_MSG
            )[0];
            $verifyMFANewDeviceIntro = $unauthenticatedLogin.getElementsByClassName(
                CONSTANTS.VERIFY_MFA_NEW_DEVICE_INTRO
            )[0];
            $captchaElement = document.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT_CLASS);
            _liveChatOn = coned.utils.getUrlParameterValue(CONSTANTS.LIVE_CHAT_ON_PARAM);
            _liveChatTagging = true;
            _hasCaptcha = $captchaElement.length;
            _isMobileVersion = false;

            // Tagging Variables
            _taggingPreffix =
                CONSTANTS.TAGGING_PREFIX + $unauthenticatedLogin.dataset.taggingPreffix;
            _taggingPreffix = _taggingPreffix + (_taggingPreffix.substr(-1) === '.' ? '' : '.');
        };

        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            $verifyMFACodeInput.addEventListener('input', verifyMFAInputEvent);
            $verificationCodeInput.addEventListener('input', verificationCodeInputEvent);
            coned.utils.addGeneralListeners($verificationCodeSendAgain, sendCodeAgainEvent);

            _.each($confirmAccountDifferentAccount, function (link) {
                coned.utils.addGeneralListeners(link, changeLogin);
            });

            _.each($credentialsShowPassword, function (showPassword) {
                coned.utils.addGeneralListeners(showPassword, togglePassword);
            });

            _.each($confirmAccountRadios, function (radio) {
                coned.utils.addGeneralListeners(radio, confirmOwnershipEvent);
            });

            // Mirror each keydown on the desktop/mobile counterpart
            _.each($accountNumberInput, function (input) {
                input.addEventListener('input', function (event) {
                    query.addClass($accountNumberError, CONSTANTS.HIDDEN_CLASS);

                    _.each($accountNumberInput, function (accInput) {
                        if (accInput !== event.target) {
                            accInput.value = event.target.value;
                            $(accInput).valid();

                            if (event.target.value === '') {
                                accInput.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                            } else {
                                accInput.classList.add(CONSTANTS.INPUT_FILLED_CLASS);
                            }
                        }
                    });

                    enableSubmitButton($accountNumberForm);
                });
            });

            _.each($credentialsEmailInput, function (input) {
                input.addEventListener('input', function (event) {
                    _.each($credentialsEmailInput, function (credInput) {
                        if (credInput !== event.target) {
                            credInput.value = event.target.value;
                            $(credInput).valid();

                            if (event.target.value === '') {
                                credInput.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                            } else {
                                credInput.classList.add(CONSTANTS.INPUT_FILLED_CLASS);
                            }
                        }
                    });

                    enableSubmitButton($credentialForm);
                });
            });

            _.each($credentialsPasswordInput, function (input) {
                input.addEventListener('input', function (event) {
                    _.each($credentialsPasswordInput, function (credInput) {
                        if (credInput !== event.target) {
                            credInput.value = event.target.value;
                            $(credInput).valid();

                            if (event.target.value === '') {
                                credInput.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                            } else {
                                credInput.classList.add(CONSTANTS.INPUT_FILLED_CLASS);
                            }
                        }
                    });

                    enableSubmitButton($credentialForm);
                });
            });

            // Sets the validation methods for all forms
            new coned.components.ValidateForm(
                '.' + CONSTANTS.ACCOUNT_NUMBER_FORMS,
                checkAccountSubmitHandler,
                ''
            );
            new coned.components.ValidateForm(
                '.' + CONSTANTS.CREDENTIALS_FORMS,
                submitLoginHandler,
                '',
                true
            );
            new coned.components.ValidateForm(
                '.' + CONSTANTS.VERIFY_MFA_FORM,
                submitNewDeviceHandler,
                ''
            );
            new coned.components.ValidateForm(
                '.' + CONSTANTS.SEND_RECOVERY_FORM,
                sendRecoveryCodeHandler,
                ''
            );
            new coned.components.ValidateForm(
                '.' + CONSTANTS.ENTRY_RECOVERY_CODE_FORM,
                entryRecoveryCodeHandler,
                ''
            );

            var $accountNumberFormMobile = $accountNumberForm[1];
            var $accountNumberFormDesktop = $accountNumberForm[0];
            // Initialize Recaptcha
            recaptchaDesktop = new coned.components.Recaptcha(
                $accountNumberFormDesktop,
                checkAccountSubmitRecaptcha,
                checkAccountSubmitRecaptcha,
                true
            );
            recaptchaMobile = new coned.components.Recaptcha(
                $accountNumberFormMobile,
                checkAccountSubmitRecaptcha,
                checkAccountSubmitRecaptcha,
                true
            );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    UnauthenticatedLogin.prototype.isLoaded = function () {
        return isLoaded;
    };

    return UnauthenticatedLogin;
})();
