// ==================== EDIT PROFILE COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.EditPaymentAgreement = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HIDDEN_CLASS: 'hidden',

        // Popup elements
        SAVE_EDIT_FORM_BUTTON: 'js-save-edit-payment-agreement',
        DOWN_PAYMENT_AMOUNT_SPAN: 'js-down-payment-amount-text',
        NUMBER_OF_INSTALLMENTS_SPAN: 'js-number-of-installments-text',
        INSTALLMENTS_AMOUNT_SPAN: 'js-installments-amount-text',
        FINAL_PAYMENT_AMOUNT_SPAN: 'js-final-payment-amount-text',
        TOTAL_PAYMENT_AMOUNT_SPAN: 'js-total-payment-amount-text',
        CLOSE_EDIT_PAYMENT_AGREEMENT_BUTTON: 'js-close-edit-payment-agreement',
        EDIT_PAYMENT_AGREEMENT_POPUP: 'js-edit-payment-agreement-popup',
        POPUP_BUTTONS_MANAGE: 'js-popup-buttons-manage',
        POPUP_INACTIVE_CLASS: 'payment-table__popup',
        POPUP_ACTIVE_CLASS: 'payment-table__popup--active',
        DOWNPAYMENT_INPUT_CLASS: 'js-currency-input--downpayment',
        INSTALLMENTS_INPUT_CLASS: 'js-number-input--installments',
        INSTALLMENT_AMOUNT_INPUT_CLASS: 'js-number-input--installment-amount',
        INSTALLMENT_MAXIMUM_TOOLTIP_CLASS: 'js-number-input--installments-maximum-tooltip',
        INSTALLMENT_MINIMUM_TOOLTIP_CLASS: 'js-number-input--installments-minimum-tooltip',
        HIDDEN_INSTALLMENT_AMOUNT_MINIMUM_CLASS: 'js-number-input--installment-amount-minimum',
        HIDDEN_FINAL_INSTALLMENT_AMOUNT_MINIMUM_CLASS: 'js-number-input--final-installment-minimum',
        POPUP_PAYMENT_DETAILS_VALUE_CLASS: 'popup__payment-details--value',
        POPUP_PAYMENT_DETAILS_VALUE_ERROR_CLASS: 'popup__payment-details--value-error',
        INSTALLMENT_LOWER_RANGE_THREE: '3',
        INSTALLMENT_LOWER_RANGE_FOUR: '4',

        // Table elements in enrollment
        PAYMENT_AGREEMENT: 'js-payment-agreement',
        TABLE_DOWN_PAYMENT_SPAN: 'js-payment-agreement-down-payment-text',
        TABLE_DOWN_PAYMENT_AMOUNT: 'js-payment-agreement-down-payment',
        TABLE_INSTALLMENTS_FORMULA_SPAN: 'js-payment-agreement-installments-formula',
        TABLE_NUMBER_OF_INSTALLMENTS_SPAN: 'js-payment-agreement-number-installments',
        TABLE_TOTAL_INSTALLMENTS_AMOUNT: 'js-payment-agreement-total-installments',
        TABLE_FINAL_PAYMENT_SPAN: 'js-payment-agreement-final-payment-span',
        TABLE_FINAL_PAYMENT_AMOUNT: 'js-payment-agreement-final-payment-amount',
        TABLE_AMOUNT_PER_INSTALLMENT_SPAN: 'js-payment-agreement-installment-amount-span',
        TABLE_TOTAL_OUTSTANDING: 'js-payment-agreement-total-outstanding',
        TABLE_DOWN_PAYMENT_DUE_DATE: 'js-payment-agreement-down-payment-due-date',
        TABLE_INSTALLMENTS_DATE_RANGE_SPAN: 'js-payment-agreement-installments-date-range-span',
        TABLE_FINAL_PAYMENT_DATE_SPAN: 'js-payment-agreement-final-payment-date-span',
        PAYMENT_AGREEMENT_ACCEPTED_DOWN_PAYMENT_SPAN: 'js-payment-agreement-accepted-down-payment',
        NUMBER_OF_DECIMALS_IN_AMOUNT: 2,
        OPEN_EDIT_PAYMENT_AGREEMENT_POPUP_BUTTON: 'js-open-edit-payment-agreement-popup',
        PAYMENT_AGREEMENT_SAO_MODIFIED_SELECTOR: 'js-payment-agreement-sao-modified-terms',
        PAYMENT_AGREEMENT_MODIFIED_SELECTOR: 'js-payment-agreement-modified-terms-only',
        PAYMENT_AGREEMENT_NO_MODIFY: 'js-pagr-no-modify',
        VARIABLE_INDEX_ELEMENTS: 'js-variable-index',
        DATE_HIDE_ELEMENTS: 'js-date-hide',
        DATE_SHOW_ELEMENTS: 'js-date-show',
        SCHEDULE_PAYMENT_BUTTON: 'js-schedule-a-payment',
        ACTIVE_DATE: 'js-active-date',
        ATTRIBUTE_INITIAL_NUMBER_INSTALLMENTS: 'data-initial-Numberinstallments',

        // Tagging
        CLICK_SAVE_PAYMENT_AGREEMENT_TAG: 'click.save.payagmt',
        ERROR_SAVE_PAYMENT_AGREEMENT_TAG: 'error.save.payagmt',
        SUCCESS_SAVE_PAYMENT_AGREEMENT_TAG: 'success.save.payagmt',
        CANCEL_EDIT_PAYMENT_AGREEMENT_TAG: 'cancel.edit.payagmt',

        // scenario 2
        SCENARIO_SAO_MODIFIED_CANCEL_POPUP_TAG: 'tag.sao.modify.cancel.popup',
        SCENARIO_SAO_MODIFIED_SAVE_POPUP_TAG: 'tag.sao.modify.save',

        // scenario 3
        SCENARIO_MODIFIED_CANCEL_POPUP_TAG: 'tag.modify.cancel.popup',
        SCENARIO_MODIFIED_SAVE_POPUP_TAG: 'tag.modify.save'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var EditPaymentAgreement = function ($editPaymentAgreementForm) {
        // Popup elements
        var $popup,
            $popupCancelButtons,
            $downpaymentAmountInput,
            $installmentsInput,
            $hiddenInstallmentAmountInput,
            $hiddenFinalInstallmentAmountInput,
            $downPaymentAmountSpan,
            $numberOfInstallmentsSpan,
            $installmentsAmountSpan,
            $installmentsMaximumTooltipSpan,
            $installmentsMinimumTooltipSpan,
            $finalPaymentAmountSpan,
            $totalPaymentAmountSpan,
            $saveEditFormButton,
            _isPaymentAgreementSaoModified,
            _isPaymentAgreementModified;

        // Enrollment elements in table
        var $paymentAgreement,
            $tableDownPaymentSpan,
            $tableDownPaymentAmount,
            $tableInstallmentsFormulaSpan,
            $tableNumberOfInstallmentsSpan,
            $tableTotalInstallmentsAmount,
            $tableFinalPaymentSpan,
            $tableFinalPaymentAmount,
            $tableAmountPerInstallmentSpan,
            $paymentAgreementAcceptedDownPaymentSpan,
            $tableTotalOutstanding,
            $tableDownPaymentDueDate,
            $tableFinalPaymentDateSpan,
            $variableIndexElements,
            $dateHideElements,
            $dateShowElements,
            $tableInstallmentsDateRangeSpan,
            $schedulePaymentButton,
            $openEditPaymentAgreementPopupButton,
            _isCommercial;

        var getAmounts = function () {
            var totalOutstanding =
                $totalPaymentAmountSpan.dataset.totalPaymentAmount &&
                $totalPaymentAmountSpan.dataset.totalPaymentAmount !== ''
                    ? parseFloat(
                          $totalPaymentAmountSpan.dataset.totalPaymentAmount.replace(/(\$|,)/g, '')
                      ) || 0
                    : parseFloat($tableTotalOutstanding.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var totalAmount =
                parseFloat($totalPaymentAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var downPaymentAmount =
                parseFloat($downPaymentAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var installmentBase = totalAmount - downPaymentAmount;
            var numberOfInstallments = parseInt($numberOfInstallmentsSpan.innerHTML);
            var amountPerInstallment =
                parseFloat($installmentsAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var finalAmount =
                parseFloat($finalPaymentAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;

            return {
                DownPaymentAmount: downPaymentAmount,
                NumberOfInstallments: numberOfInstallments,
                AmountPerInstallment: amountPerInstallment,
                InstallmentBase: installmentBase,
                FinalAmount: finalAmount,
                TotalOutstanding: totalOutstanding
            };
        };

        var updatePaymentAgreementTable = function () {
            var data = getAmounts();

            // Update amounts
            _.each($tableDownPaymentSpan, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = coned.utils.formatToCurrencyText(
                    data.DownPaymentAmount,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            });
            _.each($schedulePaymentButton, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.dataset.amount = data.DownPaymentAmount;
            });
            _.each($tableDownPaymentAmount, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = coned.utils.formatToCurrencyText(
                    data.DownPaymentAmount,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            });
            _.each($tableInstallmentsFormulaSpan, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML =
                    coned.utils.formatToCurrencyText(
                        data.AmountPerInstallment,
                        CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                    ) +
                    ' x ' +
                    data.NumberOfInstallments;
            });
            _.each($tableNumberOfInstallmentsSpan, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = data.NumberOfInstallments;
            });
            _.each($tableTotalInstallmentsAmount, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = coned.utils.formatToCurrencyText(
                    data.AmountPerInstallment * data.NumberOfInstallments,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            });
            _.each($paymentAgreementAcceptedDownPaymentSpan, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = coned.utils.formatToCurrencyText(
                    data.DownPaymentAmount,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            });
            _.each($tableFinalPaymentAmount, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = coned.utils.formatToCurrencyText(
                    data.FinalAmount,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            });
            _.each($tableFinalPaymentSpan, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = coned.utils.formatToCurrencyText(
                    data.FinalAmount,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            });
            _.each($tableAmountPerInstallmentSpan, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = coned.utils.formatToCurrencyText(
                    data.AmountPerInstallment,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            });
            _.each($tableTotalOutstanding, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = coned.utils.formatToCurrencyText(
                    data.TotalOutstanding,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            });

            _.each($variableIndexElements, function ($element, index) {
                var $parent = $element.parentElement;

                $element.innerHTML = index + 1;

                while ($parent.tagName !== 'TR' && $parent !== null) {
                    $parent = $parent.parentElement;
                }

                if ($parent) {
                    $parent.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            });

            // Update dates
            var rootDate = new Date($downPaymentAmountSpan.dataset.downPaymentDueDate),
                locale = 'en-us';

            // Set first down payment date
            var firstDownPaymentDate = rootDate.toLocaleString(locale, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
            _.each($tableDownPaymentDueDate, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = firstDownPaymentDate;
                $element.dataset.downPaymentDueDate =
                    $downPaymentAmountSpan.dataset.downPaymentDueDate;
            });

            // Set initial month
            rootDate.setMonth(rootDate.getMonth() + 1);
            var paymentDueDateRange = rootDate.toLocaleString(locale, {
                month: 'short',
                year: '2-digit'
            });

            if (data.NumberOfInstallments > 1) {
                rootDate.setMonth(rootDate.getMonth() + data.NumberOfInstallments - 1);
                paymentDueDateRange +=
                    ' - ' + rootDate.toLocaleString(locale, { month: 'short', year: '2-digit' });
            }

            _.each($tableInstallmentsDateRangeSpan, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = paymentDueDateRange;
            });
            // Set final payment date
            var $tableDownPaymentDueDateCurrent = _.find(
                $tableDownPaymentDueDate,
                function ($element) {
                    return query.hasClass($element, CONSTANTS.ACTIVE_DATE);
                }
            );

            rootDate.setMonth(rootDate.getMonth() + 1);
            rootDate.setDate($tableDownPaymentDueDateCurrent.dataset.installmentPaymentDay);
            _.each($tableFinalPaymentDateSpan, function ($element) {
                if ($element.className.includes(CONSTANTS.PAYMENT_AGREEMENT_NO_MODIFY)) {
                    return;
                }

                $element.innerHTML = rootDate.toLocaleString(locale, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
                $element.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });

            if ($dateShowElements.length) {
                _.each($dateShowElements, function ($item) {
                    $item.classList.remove(CONSTANTS.HIDDEN_CLASS);
                });
            }

            if ($dateHideElements.length) {
                _.each($dateHideElements, function ($item) {
                    $item.classList.add(CONSTANTS.HIDDEN_CLASS);
                });
            }
        };

        var editPaymentAgreementSubmit = function () {
            var data = getAmounts();
            taggingClickSavePaymentAgreement();

            var downPaymentAmount = data.DownPaymentAmount,
                numberOfInstallments = data.NumberOfInstallments;

            // html attributes to edit payment agreement.
            _.each($openEditPaymentAgreementPopupButton, function ($element) {
                $element.dataset.originalNumberinstallments = numberOfInstallments;
                $element.dataset.originalDownpayment = coned.utils.formatToCurrencyText(
                    downPaymentAmount,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            });

            // Closes popup window.
            closeEditPaymentAgreement();

            // numberOfInstallments, installmentAmount, installmentsFormula and finalPayment are updated in updatePaymentAgreementTable function.
            updatePaymentAgreementTable();

            query.fireEvent($popup, 'close-popup', { isModified: true });
        };

        var closeEditPaymentAgreement = function () {
            $popup.classList.remove(CONSTANTS.POPUP_ACTIVE_CLASS);
            $popup.classList.add(CONSTANTS.POPUP_INACTIVE_CLASS);
        };

        var taggingClickSavePaymentAgreement = function () {
            if (_isCommercial) {
                dataLayer.push({
                    event: CONSTANTS.SCENARIO_MODIFIED_SAVE_POPUP_TAG
                });
            } else if (_isPaymentAgreementSaoModified) {
                dataLayer.push({
                    event: CONSTANTS.SCENARIO_SAO_MODIFIED_SAVE_POPUP_TAG
                });
            } else if (_isPaymentAgreementModified) {
                dataLayer.push({
                    event: CONSTANTS.SCENARIO_MODIFIED_SAVE_POPUP_TAG
                });
            } else {
                dataLayer.push({
                    event: CONSTANTS.CLICK_SAVE_PAYMENT_AGREEMENT_TAG
                });
            }
        };

        var taggingCancelEditPaymentAgreement = function () {
            if (_isCommercial) {
                dataLayer.push({
                    event: CONSTANTS.SCENARIO_MODIFIED_CANCEL_POPUP_TAG
                });
            } else if (_isPaymentAgreementSaoModified) {
                dataLayer.push({
                    event: CONSTANTS.SCENARIO_SAO_MODIFIED_CANCEL_POPUP_TAG
                });
            } else if (_isPaymentAgreementModified) {
                dataLayer.push({
                    event: CONSTANTS.SCENARIO_MODIFIED_CANCEL_POPUP_TAG
                });
            } else {
                dataLayer.push({
                    event: CONSTANTS.CANCEL_EDIT_PAYMENT_AGREEMENT_TAG
                });
            }
        };

        var initializeData = function () {
            // Popup Elements
            $popup = query.selectParentElement(
                $editPaymentAgreementForm,
                CONSTANTS.EDIT_PAYMENT_AGREEMENT_POPUP
            );
            $popupCancelButtons = $popup.getElementsByClassName(
                CONSTANTS.CLOSE_EDIT_PAYMENT_AGREEMENT_BUTTON
            );
            $downpaymentAmountInput = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.DOWNPAYMENT_INPUT_CLASS
            )[0];
            $installmentsInput = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.INSTALLMENTS_INPUT_CLASS
            )[0];
            $hiddenInstallmentAmountInput = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.HIDDEN_INSTALLMENT_AMOUNT_MINIMUM_CLASS
            )[0];
            $hiddenFinalInstallmentAmountInput = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.HIDDEN_FINAL_INSTALLMENT_AMOUNT_MINIMUM_CLASS
            )[0];
            $downPaymentAmountSpan = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.DOWN_PAYMENT_AMOUNT_SPAN
            )[0];
            $numberOfInstallmentsSpan = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.NUMBER_OF_INSTALLMENTS_SPAN
            )[0];
            $installmentsAmountSpan = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.INSTALLMENTS_AMOUNT_SPAN
            )[0];
            $installmentsMaximumTooltipSpan = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.INSTALLMENT_MAXIMUM_TOOLTIP_CLASS
            )[0];
            $installmentsMinimumTooltipSpan = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.INSTALLMENT_MINIMUM_TOOLTIP_CLASS
            )[0];
            $finalPaymentAmountSpan = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.FINAL_PAYMENT_AMOUNT_SPAN
            )[0];
            $totalPaymentAmountSpan = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.TOTAL_PAYMENT_AMOUNT_SPAN
            )[0];
            $saveEditFormButton = $editPaymentAgreementForm.getElementsByClassName(
                CONSTANTS.SAVE_EDIT_FORM_BUTTON
            )[0];

            // Enrollment Table Elements
            $paymentAgreement = $popup.parentElement;
            $tableDownPaymentSpan = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_DOWN_PAYMENT_SPAN
            );
            $tableDownPaymentAmount = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_DOWN_PAYMENT_AMOUNT
            );
            $tableInstallmentsFormulaSpan = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_INSTALLMENTS_FORMULA_SPAN
            );
            $tableNumberOfInstallmentsSpan = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_NUMBER_OF_INSTALLMENTS_SPAN
            );
            $tableFinalPaymentSpan = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_FINAL_PAYMENT_SPAN
            );
            $tableFinalPaymentAmount = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_FINAL_PAYMENT_AMOUNT
            );
            $tableTotalInstallmentsAmount = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_TOTAL_INSTALLMENTS_AMOUNT
            );
            $paymentAgreementAcceptedDownPaymentSpan = $paymentAgreement.getElementsByClassName(
                CONSTANTS.PAYMENT_AGREEMENT_ACCEPTED_DOWN_PAYMENT_SPAN
            );
            $tableAmountPerInstallmentSpan = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_AMOUNT_PER_INSTALLMENT_SPAN
            );
            $tableTotalOutstanding = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_TOTAL_OUTSTANDING
            );
            $tableDownPaymentDueDate = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_DOWN_PAYMENT_DUE_DATE
            );
            $tableInstallmentsDateRangeSpan = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_INSTALLMENTS_DATE_RANGE_SPAN
            );
            $tableFinalPaymentDateSpan = $paymentAgreement.getElementsByClassName(
                CONSTANTS.TABLE_FINAL_PAYMENT_DATE_SPAN
            );
            $schedulePaymentButton = $paymentAgreement.getElementsByClassName(
                CONSTANTS.SCHEDULE_PAYMENT_BUTTON
            );
            $openEditPaymentAgreementPopupButton = $paymentAgreement.getElementsByClassName(
                CONSTANTS.OPEN_EDIT_PAYMENT_AGREEMENT_POPUP_BUTTON
            );
            $variableIndexElements = $paymentAgreement.getElementsByClassName(
                CONSTANTS.VARIABLE_INDEX_ELEMENTS
            );
            $dateHideElements = $paymentAgreement.getElementsByClassName(
                CONSTANTS.DATE_HIDE_ELEMENTS
            );
            $dateShowElements = $paymentAgreement.getElementsByClassName(
                CONSTANTS.DATE_SHOW_ELEMENTS
            );
            _isPaymentAgreementSaoModified = query.hasClass(
                $paymentAgreement,
                CONSTANTS.PAYMENT_AGREEMENT_SAO_MODIFIED_SELECTOR
            );
            _isPaymentAgreementModified = query.hasClass(
                $paymentAgreement,
                CONSTANTS.PAYMENT_AGREEMENT_MODIFIED_SELECTOR
            );
            _isCommercial = $paymentAgreement.dataset.isCommercial === 'true';
        };

        var initializeEvents = function () {
            for (var i = 0; i < $popupCancelButtons.length; ++i) {
                if ($popupCancelButtons[i].tagName === 'BUTTON') {
                    coned.utils.addGeneralListeners(
                        $popupCancelButtons[i],
                        taggingCancelEditPaymentAgreement
                    );
                    coned.utils.addGeneralListeners($popupCancelButtons[i], function () {
                        closeEditPaymentAgreement();
                        query.fireEvent($popup, 'close-popup');
                    });
                }
            }

            if ($installmentsInput && $downpaymentAmountInput) {
                // Installemnt Change
                coned.utils.addMultipleListeners(
                    $installmentsInput,
                    ['change', 'keydown', 'focus', 'input'],
                    checkInstallments
                );

                // Down Payment Change
                coned.utils.addMultipleListeners(
                    $downpaymentAmountInput,
                    ['change', 'keyup', 'input'],
                    checkDownPayment
                );
            }

            // Enable/disable Pay Bill button event
            coned.utils.addMultipleListeners(
                $editPaymentAgreementForm,
                ['change', 'keyup'],
                enableAcceptAgreementTermsButton
            );

            setInstallmentMax();

            new coned.components.ValidateForm(
                $editPaymentAgreementForm,
                editPaymentAgreementSubmit,
                ''
            );
        };

        var checkInstallments = function () {
          formatNumber($installmentsInput);  
          if ($($editPaymentAgreementForm).valid()) {
                $numberOfInstallmentsSpan.innerHTML = $installmentsInput.value;
                $downPaymentAmountSpan.innerHTML = $downpaymentAmountInput.value;
                updateResultValues();
            }
        };

        var checkDownPayment = function () {
            currencyFormat($downpaymentAmountInput);
            setInstallmentMax();
            if ($($editPaymentAgreementForm).valid()) {
                $downPaymentAmountSpan.innerHTML = $downpaymentAmountInput.value;
                $numberOfInstallmentsSpan.innerHTML = $installmentsInput.value;
                updateResultValues();
            }
        };

        var updateResultValues = function () {
            var installmentValue = parseInt($installmentsInput.value, 10) + 1;
            var installmentAmountMinimum = parseFloat(
                $hiddenInstallmentAmountInput.dataset.paymentMin.replace(/(\$|,)/g, '')
            );
            var finalInstallmentAmountMinimum = parseFloat(
                $hiddenFinalInstallmentAmountInput.dataset.paymentMin.replace(/(\$|,)/g, '')
            );
            var totalOutstanding =
                $totalPaymentAmountSpan.dataset.totalPaymentAmount &&
                $totalPaymentAmountSpan.dataset.totalPaymentAmount !== ''
                    ? parseFloat(
                          $totalPaymentAmountSpan.dataset.totalPaymentAmount.replace(/(\$|,)/g, '')
                      ) || 0
                    : parseFloat($tableTotalOutstanding.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var downpaymentValue = parseFloat($downpaymentAmountInput.value.replace(/(\$|,)/g, ''));
            var minusDownPayment = totalOutstanding - downpaymentValue;
            var installmentLowerRangeCalc = $editPaymentAgreementForm.dataset.installmentLowerRangeCalc;
            var installmentMinimumLimit = $editPaymentAgreementForm.dataset.minInstallmentLimit;
            var installmentAmount;
            var totalAmount;
            var finalInstallment;

            // Base Values
            var installmentAmountBase = Math.ceil((minusDownPayment / installmentValue) * 100) / 100, // Round up with two decimals
                finalInstallmentBase = ((installmentAmountBase * installmentValue) - minusDownPayment) * -1;

            // FInal Installment amount
            installmentAmount = installmentAmountBase; // Round down with two decimals


            // Final Installment (round with 2 decimals)
            finalInstallment = ((installmentAmount + finalInstallmentBase) * 100) / 100;

            // Total Payment Amount
            totalAmount = downpaymentValue + ((installmentValue - 1) * installmentAmountBase ) + finalInstallment;

            // Picking Min from Input field in service
            // Can't be less than 5, Must be a whole number
            if (installmentAmount < installmentAmountMinimum) {
                // TODO:  Evaluate the possibility to present some user text feedback along this warning
                $installmentsAmountSpan.classList.remove(
                    CONSTANTS.POPUP_PAYMENT_DETAILS_VALUE_CLASS
                );
                $installmentsAmountSpan.classList.add(
                    CONSTANTS.POPUP_PAYMENT_DETAILS_VALUE_ERROR_CLASS
                );
                $hiddenInstallmentAmountInput.value = false;
            } else {
                $installmentsAmountSpan.classList.remove(
                    CONSTANTS.POPUP_PAYMENT_DETAILS_VALUE_ERROR_CLASS
                );
                $installmentsAmountSpan.classList.add(CONSTANTS.POPUP_PAYMENT_DETAILS_VALUE_CLASS);
                $hiddenInstallmentAmountInput.value = true;
            }

            // Picking Min from Input field in service
            // Can't be less than $1, or more than 0.99 more than the Installment amount.  Does not need to be a whole number.
            if (
                finalInstallment < finalInstallmentAmountMinimum &&
                finalInstallment > installmentAmount + 0.99
            ) {
                // TODO:  Evaluate the possibility to present some user text feedback along this warning
                $finalPaymentAmountSpan.classList.remove(
                    CONSTANTS.POPUP_PAYMENT_DETAILS_VALUE_CLASS
                );
                $finalPaymentAmountSpan.classList.add(
                    CONSTANTS.POPUP_PAYMENT_DETAILS_VALUE_ERROR_CLASS
                );
                $hiddenFinalInstallmentAmountInput.value = false;
            } else {
                $finalPaymentAmountSpan.classList.remove(
                    CONSTANTS.POPUP_PAYMENT_DETAILS_VALUE_ERROR_CLASS
                );
                $finalPaymentAmountSpan.classList.add(CONSTANTS.POPUP_PAYMENT_DETAILS_VALUE_CLASS);
                $hiddenFinalInstallmentAmountInput.value = true;
            }

            if (finalInstallmentAmountMinimum === parseInt(installmentLowerRangeCalc)){
                $installmentsInput.setAttribute('min', installmentLowerRangeCalc);
                $installmentsMinimumTooltipSpan.innerHTML = installmentLowerRangeCalc;
            } else if (finalInstallment < finalInstallmentAmountMinimum) {
                var installmentMinimumLimitIncreased = parseInt(installmentMinimumLimit);
                installmentMinimumLimitIncreased = installmentMinimumLimitIncreased++;
                $installmentsInput.setAttribute('min', installmentMinimumLimitIncreased.toString());
                $installmentsMinimumTooltipSpan.innerHTML = installmentMinimumLimitIncreased;
            } else {
                $installmentsInput.setAttribute('min', installmentMinimumLimit);
                $installmentsMinimumTooltipSpan.innerHTML = installmentMinimumLimit;
            }

            // Minimum Addressed
            if (installmentAmount && finalInstallment && totalAmount && downpaymentValue) {
                $installmentsAmountSpan.innerHTML = coned.utils.formatToCurrencyText(
                    installmentAmount,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
                $finalPaymentAmountSpan.innerHTML = coned.utils.formatToCurrencyText(
                    finalInstallment,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
                $totalPaymentAmountSpan.innerHTML = coned.utils.formatToCurrencyText(
                    totalAmount,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );

                // update result table with calculated values.
                $paymentAgreementAcceptedDownPaymentSpan.innerHTML = coned.utils.formatToCurrencyText(
                    downpaymentValue,
                    CONSTANTS.NUMBER_OF_DECIMALS_IN_AMOUNT
                );
            }

            enableAcceptAgreementTermsButton();
        };

        var setInstallmentMax = function () {
            if (!$openEditPaymentAgreementPopupButton[0].hasAttribute(CONSTANTS.ATTRIBUTE_INITIAL_NUMBER_INSTALLMENTS)) {
                $openEditPaymentAgreementPopupButton[0].dataset.initialNumberinstallments = $openEditPaymentAgreementPopupButton[0].dataset.originalNumberinstallments;
            }
        
            var totalOutstanding =
                $totalPaymentAmountSpan.dataset.totalPaymentAmount &&
                $totalPaymentAmountSpan.dataset.totalPaymentAmount !== ''
                    ? parseFloat(
                          $totalPaymentAmountSpan.dataset.totalPaymentAmount.replace(/(\$|,)/g, '')
                      ) || 0
                    : parseFloat($tableTotalOutstanding.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var downpaymentValue = parseFloat($downpaymentAmountInput.value.replace(/(\$|,)/g, ''));
            var minusDownPayment = totalOutstanding - downpaymentValue;
            var installmentMaximum;
            var installmentMaximumLimit = $editPaymentAgreementForm.dataset.maxInstallmentLimit;
            var installmentLowerRangeCalc = $editPaymentAgreementForm.dataset.installmentLowerRangeCalc;
            var minusDownPaymentRounded = coned.utils.roundDown(minusDownPayment / installmentLowerRangeCalc);

            installmentMaximum = minusDownPaymentRounded > installmentMaximumLimit ? installmentMaximumLimit : minusDownPaymentRounded;
            
            $installmentsInput.setAttribute('max', installmentMaximum);
            $installmentsInput.setAttribute('data-limit-max', installmentMaximum);
            $installmentsMaximumTooltipSpan.innerHTML = installmentMaximum;
        };

        var enableAcceptAgreementTermsButton = function () {
            var isFormValid =
                coned.components.ValidateForm.isFormValid(
                    $editPaymentAgreementForm,
                    '',
                    CONSTANTS.FORM_IGNORE_VALIDATION
                ) &&
                $hiddenFinalInstallmentAmountInput.value === 'true' &&
                $hiddenInstallmentAmountInput.value === 'true';
            $saveEditFormButton.disabled = !isFormValid;
        };

        var formatNumber = function (input) {
            var value = input.value.replace(/\D/g, '');
            input.value = value;
        };

        var currencyFormatNumber = function (number) {
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        };

        var currencyFormat = function (input) {
            var currencyInput = input.value.replace(/[^\d.-]/g, ''),
                decimals = '',
                trimDecimals = '',
                val;

            currencyInput = currencyInput.replace(/[-\s]+/g, '');
            currencyInput = currencyInput.split('.', 2);
            val = parseInt(currencyInput[0]) * 1;

            if (typeof currencyInput[1] != 'undefined') {
                if (currencyInput[1].length > 2) {
                    trimDecimals = currencyInput[1][0];

                    if (typeof currencyInput[1][1] != 'undefined') {
                        trimDecimals += currencyInput[1][1];
                    }
                } else {
                    trimDecimals = currencyInput[1];
                }

                decimals = '.' + trimDecimals;
            }

            if (currencyInput[0].length > 0) {
                input.value = '$' + currencyFormatNumber(val) + decimals;
            } else {
                input.value = '';
                query.removeClass(input, CONSTANTS.INPUT_FILLED_CLASS);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    EditPaymentAgreement.prototype.isLoaded = function () {
        return isLoaded;
    };

    return EditPaymentAgreement;
})();
