// ==================== EDIT ACCOUNT FORM COMPONENT ============================
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.EditAccountForm = (function () {
    /**
     * Constants used in the module.
     * @type {Object}s
     */
    var CONSTANTS = {
        ACTIVE_CONNECTIONS_CONTAINER:'js-active-connections',
        ARIA_EXPANDED: 'aria-expanded', 
        COLLAPSIBLE_CONTAINER_ITEM_ACTIVE_CLASS: 'collapsible-account-item__accordion',
        EDIT_BUTTON: 'js-edit-button',
        ACCOUNT_ITEM_TITLE_CLASS: 'collapsible-account-item__title',
        GRAY_COLOR_CLASS: 'collapsible-account-item__title--gray-color',
        SELECT_ARROW: 'js-coned-select-arrow',
        FORM_SELECT_MENU: 'js-form-select-menu',
        FORM_SELECT_MENU_EXTRA_CONTENT: 'js-form-select-menu-extra-content',
        CLOSE_BUTTON: 'js-close-button',
        REQUIRED_ATTRIBUTE: 'required',
        CHANGE_EVENT: 'change',
        CLOSE_LABEL: 'CLOSE',
        EDIT_LABEL: 'EDIT',
        KEYUP_EVENT: 'keyup',
        SHARE_OPTION_SELECT: 'js-select-share-option',
        TIMESPAN_SELECT_CLASS: 'js-form-date-select',
        DATE_PICKER_CONTAINER: 'js-form-date-container',
        DATE_PICKER_CLASS: 'js-form-date',
        DATE_PICKER_ACTIVE_CLASS: 'coned-input--filled',
        DATE_PICKER_ERROR_CLASS: 'coned-input-message--error',
        DATE_PICKER_ERROR_MESSAGE_CONTAINER: 'coned-field-error-wrapper',
        HIDDEN_CLASS: 'hidden',
        COLUMN_CLASS: 'collapsible-account-item__form-column',
        ROTATE_CLASS: 'collapsible-account-item__accordion-arrow--rotate',
        DISABLED_ATTRIBUTE: 'disabled',
        CANCEL_BUTTON: 'js-decline-submit',
        SAVE_BUTTON: 'js-authorize-submit',
        SUBMIT_BUTTON_CONTAINER_CLASS: 'js-form-button-container',
        SUBMIT_ANIMATION_CONTAINER_SELECTOR: 'js-submit-animation',
        SUBMIT_ANIMATION_SELECTOR: 'js-submit-progress-animation',
        FORM_SCID_NAME: 'ScId',
        FORM_CLASS_SELECTOR: 'js-register-form',
        FORM_CONTAINER_CLASS: 'js-form-container',
        FORM_SHARE_UNTIL_CLASS: 'js-share-until',
        FORM_SHARE_UNTIL_DEFAULT: 'Share until',
        FORM_SHARE_OPTION_TEXT_CLASS: 'js-share-option-text',
        FORM_SHARE_OPTION_ACTIVE_CLASS: 'coned-select--active',
        FORM_SHARE_DATE_NAME: 'sharedate',
        VISIBILITY_HIDDEN_CLASS: 'form__actions--visibility-hidden',
        ERROR_CLASS: 'js-service-error',
        ERROR_MESSAGE_CLASS: 'js-error-message',
        FORM_INPUT_TIMESPAN_NAME: 'timespanSelect',
        SELECT_VALUE_SHOW_DATE_PICKER: '2',
        SELECT_VALUE_STOP_SHARING_DATA: '3',
        BODY_SELECTOR: 'js-body',
        BUTTON_ACTIVE_CONNECTIONS_SELECTOR:'js-button-active-connections',

        // TOAST
        TOAST: 'js-toast',
        SHOW_TOAST_EVENT: 'show-toast-event'
    };

    var isLoaded = false;



    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var EditAccountForm = function ($account) {
        var $bodyContainer,
            $activeConnectionsContainer,
            $buttonActiveConnections,
            $itemActive,
            $editButton,
            $registerForm,
            $formContainer,
            $deauthorizeFormServiceError,
            $deauthorizeFormServiceErrorMessage,
            $submitAnimationContainer,
            $submitAnimation,
            _dummyFunction,
            _connectionRemoveUrl,
            _formId,
            $dateElement,
            $toast,
            $arrowSelect,
            $accountItemTitle,
            $formSelectMenuExtraContent, 
            $timespanSelect,
            $datePickerContainer,
            $datePicker,
            $cancelBtn,
            $saveButton,
            $authorizeSubmitButton,
            $declineSubmitButton,
            _datePickerDefault,
            $registerFormServiceError,
            _connectionPostUrl,
            $registerFormServiceErrorMessage,
            $shareOptionSelect,
            $selectedOptionText,
            _shareUntilOptionDefault,
            _selectDateValue,
            $editConnectionSuccess,
            $removeConnectionSuccess,
            $currentFocus;
        /**
         * PRIVATE METHODS
         */ 

        var setDatesLimit = function () {
            var tomorrow = new Date();

            tomorrow.setDate(tomorrow.getDate() + 1);

            // set input validation rules
            $datePicker.dataset.minDateDays = tomorrow;

            // set the date picker dates limit
            setTimeout(function () {
                $($datePicker).datepicker('option', 'minDate', new Date(tomorrow));
                $($datePicker).datepicker('option', 'onSelect', function () {
                    $(this).trigger('change');
                    onInputChange();
                });
            }, 1);
        };

        var showHideExtraTextStopSharing = function () {
            var selectedValue = $timespanSelect.value;
            
            if (selectedValue === CONSTANTS.SELECT_VALUE_STOP_SHARING_DATA) {
                $formSelectMenuExtraContent.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $formSelectMenuExtraContent.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        }

        var onChangeTimespanSelect = function (event) {
            event.preventDefault();
            
            var selectedValue = $timespanSelect.value;
        
            if (selectedValue === CONSTANTS.SELECT_VALUE_SHOW_DATE_PICKER) {
                
                $datePickerContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $datePicker.setAttribute(CONSTANTS.REQUIRED_ATTRIBUTE, ''); 
                btnSaveEnable(false);
                
                // Analytics data building
                dataLayer.push({
                    event: 'green.button',
                    gbc: 'step2.1.2'
                });
              
            } else { 
                $datePickerContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $datePicker.removeAttribute(CONSTANTS.REQUIRED_ATTRIBUTE); 
                btnSaveEnable(true);
            }
            showHideExtraTextStopSharing();
        };

        var onInputChange = function () {
            var disableButton = (
                $($registerForm).validate().checkForm() && $timespanSelect.selectedIndex > 0
            );
            btnSaveEnable(disableButton);
        };

        /**
         * Remove aria-expanded in accordion, it is set by default in other JS
         */
        var removeAriaInItemActive = function () {
            setTimeout(function () {
                $itemActive.removeAttribute(CONSTANTS.ARIA_EXPANDED);
            }, 10);
        }

        var actionAccordion = function (open) { 
            if (open) {
                $arrowSelect.classList.add(CONSTANTS.ROTATE_CLASS);  
                $accountItemTitle.classList.add(CONSTANTS.GRAY_COLOR_CLASS);
                $registerForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $registerForm.classList.add(CONSTANTS.COLUMN_CLASS); 
                $editButton.ariaExpanded = true;
                
            } else {
                $arrowSelect.classList.remove(CONSTANTS.ROTATE_CLASS);
                $accountItemTitle.classList.remove(CONSTANTS.GRAY_COLOR_CLASS);
                $registerForm.classList.add(CONSTANTS.HIDDEN_CLASS); 
                $editButton.ariaExpanded = false;
            }
            removeAriaInItemActive();
        }

        var editButtonClicked = function (event) {
            event.preventDefault();
            var openAccordion = !query.hasClass($arrowSelect, CONSTANTS.ROTATE_CLASS);
            actionAccordion(openAccordion); 
          
        };

        var cancelButtonClicked = function (event) {
            event.preventDefault();
            actionAccordion(false);
            $editButton.focus();
        };

        var submitAction = function () {
            var selectedValue = $timespanSelect.value;
            changeButtonVisibility();
            
            if (selectedValue === CONSTANTS.SELECT_VALUE_STOP_SHARING_DATA) {
                deAuthorizeAcceptClicked(); 
            } else {
                authorizeAcceptClicked();
            }
        };

        var authorizeAcceptClicked = function () {
            $registerFormServiceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            new coned.components.SubmitAnimation(
                $submitAnimation,
                startEditConnectionCall,
                finishEditConnectionCall,
                _dummyFunction,
                false,
                false
            );
        }

        var finishEditConnectionCall = function () {
            if ($editConnectionSuccess) { 
                changeButtonVisibility();
                actionAccordion(false);
                updateDate();
                showToast();
                editButtonFocus(true);
            }
        };
        /**
         * Focus on the last edit button pressed.
         */
        var editButtonFocus = function (setFocus) {
            if (setFocus) {
                $currentFocus = document.activeElement === $bodyContainer ? $editButton : document.activeElement;
            }
            // Wait the toast be hidden and set new focus
            var refreshIntervalId = setInterval(function() {
                var isHiddenToast = query.hasClass($toast,CONSTANTS.HIDDEN_CLASS);
                if (isHiddenToast) {
                    $currentFocus.focus();
                    clearInterval(refreshIntervalId);
                }
              }, 100);
        };

        var updateDate = function () {
            var selectedValue = $shareOptionSelect.options[$shareOptionSelect.selectedIndex].value;
            btnSaveEnable(false);
         
           if (selectedValue === CONSTANTS.SELECT_VALUE_SHOW_DATE_PICKER) {
                $dateElement.innerHTML = $datePicker.value;
                $selectedOptionText.innerHTML = CONSTANTS.FORM_SHARE_UNTIL_DEFAULT;
            } else { 
                $selectedOptionText.innerHTML =
                    $shareOptionSelect.options[$shareOptionSelect.selectedIndex].text;
                $dateElement.innerHTML = '';
            }
        };
        
        var startEditConnectionCall = function () {
            var params = {
                ScId: query.getFormInputValue(document, CONSTANTS.FORM_SCID_NAME),
                AuthorizationID: $account.dataset.authorizationId,
                AuthorizationStartDate: $account.dataset.authorizationStartDate,
                Date: coned.utils.localDateToUTC(
                    query.getFormInputValue($account, CONSTANTS.FORM_SHARE_DATE_NAME)
                ),
                ShareOption: $shareOptionSelect.value
            };

            params = JSON.stringify(params);
            query.putData(
                _connectionPostUrl,
                editConnectionSuccessCallback,
                editConnectionErrorCallback,
                params,
                true,
                null
            );
        }; 

        var editConnectionSuccessCallback = function () {
            $submitAnimation.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            $editConnectionSuccess = true;

            // Set default values
            _shareUntilOptionDefault = $shareOptionSelect.value;
            btnSaveEnable(false);

            if (_shareUntilOptionDefault === _selectDateValue) {
                $dateElement.dataset.hasDate = 'true';
                _datePickerDefault = $datePicker.value;
            } else {
                $dateElement.dataset.hasDate = 'false';
                _datePickerDefault = '';
            }
        };

        var editConnectionErrorCallback = function (data) {
            $submitAnimation.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            $registerFormServiceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $registerFormServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $registerFormServiceError.focus();
            $editConnectionSuccess = false;
        };
        
        var disableDatepickerPastDates = function () {
            var tomorrow = new Date();

            tomorrow.setDate(tomorrow.getDate() + 1);
            $datePicker.dataset.minDateDays = tomorrow;

            // set the date picker dates limit
            setTimeout(function () {
                $($datePicker).datepicker('option', 'minDate', new Date(tomorrow));
            }, 1);
        };

        var deAuthorizeAcceptClicked = function () {
            $deauthorizeFormServiceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            new coned.components.SubmitAnimation(
                $submitAnimation,
                startDeAuthorizationCall,
                finishDeAuthorizationCall,
                _dummyFunction,
                false,
                false
            );

            // Analytics data building
            dataLayer.push({
                event: 'green.button',
                gbc: 'step2.2'
            });
        };

        /*
        * Check if active connections has accounts, if is zero hide the active accounts container
        */
        var hideActiveConection = function () {
            var allAccounts = $activeConnectionsContainer.getElementsByClassName(CONSTANTS.FORM_CONTAINER_CLASS).length,
                accountsHidden = $activeConnectionsContainer.getElementsByClassName(CONSTANTS.FORM_CONTAINER_CLASS + " " + CONSTANTS.HIDDEN_CLASS).length,
                _isEmptyActive = (allAccounts - accountsHidden) === 0;
                  
            if (_isEmptyActive) {
                query.addClass($activeConnectionsContainer, CONSTANTS.HIDDEN_CLASS);
            }
        }

        /**
         * Find the next focus on the next active connection, when there are no connections in the current group.
         */
        var focusNextActiveConnection = function () {
            var $activeConnections = Array.from($activeConnectionsContainer.parentElement.children);
            for (var index = 0; index < $activeConnections.length; index++) {
                var $activeConnection = $activeConnections[index],
                    $newFocus = $activeConnection.getElementsByClassName(CONSTANTS.EDIT_BUTTON)[0];
                if (!$activeConnection.classList.contains(CONSTANTS.HIDDEN_CLASS)) {
                    focusNextItemInActiveConnection($newFocus,true);
                    return;
                }
            }
            // Focus in My Active Connections button, when there aren't active connections
            focusNextItemInActiveConnection($buttonActiveConnections,true);
        }

        /**
         * Find the next focus on the next item in active connection.
         */
        var focusNextItemInActiveConnection = function ($newFocus, forceNewFocus) {
            var $activeConnections = $activeConnectionsContainer.getElementsByClassName(CONSTANTS.FORM_CONTAINER_CLASS),
                indexActiveConnection = 0;
            // Find the next item in active connection
            for (var index = 0; index < $activeConnections.length; index++) {
                if ($formContainer === $activeConnections[index]) {
                    indexActiveConnection = index === 0 ? index + 1 : index;
                    break;
                }
            }
            // Save current item with focus
            if (document.activeElement === $bodyContainer && !forceNewFocus) {
                $currentFocus = $activeConnections[indexActiveConnection].getElementsByClassName(CONSTANTS.EDIT_BUTTON)[0];
            } else {
                $currentFocus = $newFocus;
            }
            editButtonFocus(false);
        }
        

        /**
         * Find the next one when a connection is deleted.
         */
        var newFocusOnHide = function () {
            var isHiddenActiveConnectionsContainer = query.hasClass($activeConnectionsContainer, CONSTANTS.HIDDEN_CLASS);
            if (isHiddenActiveConnectionsContainer) {
                focusNextActiveConnection();
            } else {
                focusNextItemInActiveConnection(document.activeElement,false);
            }
        };

        var finishDeAuthorizationCall = function () {
            if ($removeConnectionSuccess) {
                changeButtonVisibility();
                actionAccordion(false);
                updateDate();
                query.addClass($formContainer, CONSTANTS.HIDDEN_CLASS);
                hideActiveConection(); 
                showToast();
                newFocusOnHide();
            }
        };

        var changeButtonVisibility = function () {
            var isHiddenAuthorizeSubmitButton = query.hasClass( $authorizeSubmitButton,CONSTANTS.VISIBILITY_HIDDEN_CLASS);
            if (isHiddenAuthorizeSubmitButton) {
                // Hide submit animation
                query.addClass($submitAnimationContainer, CONSTANTS.HIDDEN_CLASS);
                // Make submit buttons visible
                query.removeClass($authorizeSubmitButton, CONSTANTS.VISIBILITY_HIDDEN_CLASS);
                query.removeClass($declineSubmitButton, CONSTANTS.VISIBILITY_HIDDEN_CLASS);
            } else {
                // Show submit animation
                query.removeClass($submitAnimationContainer, CONSTANTS.HIDDEN_CLASS);
                // Make submit buttons hidden.
                query.addClass($authorizeSubmitButton, CONSTANTS.VISIBILITY_HIDDEN_CLASS);
                query.addClass($declineSubmitButton, CONSTANTS.VISIBILITY_HIDDEN_CLASS);
            }
        }

        var startDeAuthorizationCall = function () {
            var params = {
                ScId: query.getFormInputValue(document, CONSTANTS.FORM_SCID_NAME),
                AuthorizationID: $account.dataset.authorizationId
            };

            params = JSON.stringify(params);
            query.deleteData(
                _connectionRemoveUrl,
                deAuthorizeSuccessCallback,
                deAuthorizeErrorCallback,
                params,
                true,
                null
            );
        };

        var deAuthorizeSuccessCallback = function () {
            $submitAnimation.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            $removeConnectionSuccess = true;
        };

        var deAuthorizeErrorCallback = function (data) {
            $deauthorizeFormServiceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $deauthorizeFormServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $submitAnimation.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            $removeConnectionSuccess = false;
        };

        var btnSaveEnable = function (enable) {
            if (enable) {
                $saveButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            } else {
                $saveButton.setAttribute(CONSTANTS.DISABLED_ATTRIBUTE, CONSTANTS.DISABLED_ATTRIBUTE);
            }
        };

        /*
         * Formatted date as MM/DD/YYYY
         */
        var getDateFormatted = function (date) {
            // get today date and add one day
            var endDate = new Date(date);
            return (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear();
        }

        var setDate = function () {
            if ($dateElement.dataset.hasDate === 'true') {
                setMinimumDateInPicker();

                // Get unix time on UTC from data-end-date attribute to convert it to local time
                var endDate =  $account.dataset.endDate * 1000,
                    endDateFormated = getDateFormatted(endDate);
               
                $dateElement.innerHTML = endDateFormated;
                _datePickerDefault = endDateFormated;
                //focus in the date selected
                $datePicker.value = endDateFormated;
            }
        };

        /**
         * Set tomorrow date as minimum date
         */
        var setMinimumDateInPicker = function () {
            // get today date and add one day
            var date = new Date(),
            tomorrow = date.setDate(date.getDate() + 1),
            endDateFormated = getDateFormatted(tomorrow);
            // set input validation rules
            $datePicker.dataset.minDateDays = endDateFormated;
        }

        var dateChanged = function () {
            if (_datePickerDefault === $datePicker.value) {
                btnSaveEnable(false);
            } else {
                btnSaveEnable(true);
            }
        };
        
        /**
         * Trigger event to show the toast.
         */

        var showToast = function () {
            coned.utils.triggerEvent($toast, CONSTANTS.SHOW_TOAST_EVENT);
        };
       

        var initializeData = function () {
            $bodyContainer = document.getElementsByClassName(CONSTANTS.BODY_SELECTOR)[0];
            $activeConnectionsContainer = query.selectParentElement($account,CONSTANTS.ACTIVE_CONNECTIONS_CONTAINER);
            $buttonActiveConnections = document.getElementsByClassName(CONSTANTS.BUTTON_ACTIVE_CONNECTIONS_SELECTOR)[0];
            $itemActive = $account.getElementsByClassName(CONSTANTS.COLLAPSIBLE_CONTAINER_ITEM_ACTIVE_CLASS)[0];
            $editButton = $account.getElementsByClassName(CONSTANTS.EDIT_BUTTON)[0];
            $arrowSelect = $account.getElementsByClassName(CONSTANTS.SELECT_ARROW)[0];
            $accountItemTitle = $account.getElementsByClassName(CONSTANTS.ACCOUNT_ITEM_TITLE_CLASS)[0];
            $formSelectMenuExtraContent = $account.getElementsByClassName(CONSTANTS.FORM_SELECT_MENU_EXTRA_CONTENT)[0];
            $timespanSelect = $account.getElementsByClassName(CONSTANTS.TIMESPAN_SELECT_CLASS)[0];
            $datePickerContainer = $account.getElementsByClassName(CONSTANTS.DATE_PICKER_CONTAINER)[0];
            $datePicker = $account.getElementsByClassName(CONSTANTS.DATE_PICKER_CLASS)[0];
            _datePickerDefault = $datePicker.value;
            $cancelBtn = $account.getElementsByClassName(CONSTANTS.CANCEL_BUTTON)[0];
            $saveButton =  $account.getElementsByClassName(
                CONSTANTS.SAVE_BUTTON
            )[0];
            
            $toast = document.getElementsByClassName(CONSTANTS.TOAST)[0]; 
            $registerForm = $account.getElementsByClassName(CONSTANTS.FORM_CLASS_SELECTOR)[0];
            $formContainer = $account.getElementsByClassName(CONSTANTS.FORM_CONTAINER_CLASS)[0];

            _formId = $registerForm.dataset.id;

            $deauthorizeFormServiceError = $registerForm.getElementsByClassName(
                CONSTANTS.ERROR_CLASS
            )[0];
            $deauthorizeFormServiceErrorMessage = $account.getElementsByClassName(
                CONSTANTS.ERROR_MESSAGE_CLASS
            )[0];
            $submitAnimationContainer = $account.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_CONTAINER_SELECTOR
            )[0];
            $submitAnimation = $submitAnimationContainer.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_SELECTOR
            )[0];

            $dateElement = $account.getElementsByClassName(CONSTANTS.FORM_SHARE_UNTIL_CLASS)[0];
            
            $authorizeSubmitButton = $account.getElementsByClassName(
                    CONSTANTS.SAVE_BUTTON
            )[0];
            $declineSubmitButton = $account.getElementsByClassName(
                CONSTANTS.CANCEL_BUTTON
            )[0];
            _connectionRemoveUrl = $account.dataset.removeConnection;

            _dummyFunction = function () {};

            $registerFormServiceError = $registerForm.getElementsByClassName(
                CONSTANTS.ERROR_CLASS
            )[0];
            $shareOptionSelect = $account.getElementsByClassName(CONSTANTS.SHARE_OPTION_SELECT)[0];
          
            _shareUntilOptionDefault =
                $shareOptionSelect.options[$shareOptionSelect.selectedIndex].value;

            _selectDateValue = $account.dataset.dateValue;
            
            $selectedOptionText = $account.getElementsByClassName(
                CONSTANTS.FORM_SHARE_OPTION_TEXT_CLASS
            )[0];
            
            $registerFormServiceErrorMessage = $registerForm.getElementsByClassName(
                CONSTANTS.ERROR_MESSAGE_CLASS
            )[0];
            
           _connectionPostUrl = $account.dataset.editConnection;

           $editConnectionSuccess = true,
           $removeConnectionSuccess = true;
        };

        var initializeEvents = function () {
            setDatesLimit();
            coned.utils.addGeneralListeners($editButton, editButtonClicked);

            coned.utils.addMultipleListeners(
                $timespanSelect,
                [CONSTANTS.CHANGE_EVENT],
                onChangeTimespanSelect
            );

            coned.utils.addMultipleListeners(
                $datePicker,
                [CONSTANTS.CHANGE_EVENT, CONSTANTS.KEYUP_EVENT],
                onInputChange
            );
            
            coned.utils.addGeneralListeners($cancelBtn, cancelButtonClicked); 
            btnSaveEnable(false);
            $datePicker.addEventListener(CONSTANTS.CHANGE_EVENT, dateChanged);

            new coned.components.ValidateForm(
                '.' + CONSTANTS.FORM_CLASS_SELECTOR + '[data-id="' + _formId + '"]',
                submitAction.bind(this)
            ); 
            disableDatepickerPastDates();

            setDate(); 
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true; 
        };

        init(); 
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    EditAccountForm.prototype.isLoaded = function () {
        return isLoaded;
    };

    return EditAccountForm;
})();