// ==================== CORRESPONDENCE COMPONENT =========================
/* global dataLayer */
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.Correspondence = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CORRESPONDENCE_TABLE_CLASS: 'js-correspondence-table',
        CORRESPONDENCE_ITEM_CLASS: 'js-item',
        CORRESPONDENCE_VIEW_LINK_CLASS: 'js-view-correspondence',
        FORM_LOADING: 'js-form-loading',
        DIV_LOADING_CLASS: 'js-div-loading',
        DIV_LOADING_HIDDEN_CLASS: 'form-loading--hidden',
        SITECORE_ID_NAME: 'billingUsageScId',
        ACCOUNT_MAID_NAME: 'accountMaid',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        ERROR_CONTAINER_CLASS: 'billing-module--sides-padding billing-module--top-bottom-padding transactional__error',
        ERROR_ICON_CLASSES: 'transactional__error-icon icon-report-problem',
        HIDDEN_CLASS: 'hidden',
        CORRESPONDENCE_TABLE_UL_SELECTOR: '.correspondence-body__table ul',
        SHOW_MORE_BTN: 'correspondence-body__show-more',
        INITIALOAD: 'initialLoad',
        PAGE_CONTENT_ANCESTOR_SELECTOR: 'js-page-content',
        DOWNLOAD_TAGGING:'document.download'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var Correspondence = function ($correspondence) {
        /**
         * PRIVATE METHODS
         */
        var $correspondenceTable,
            $correspondenceLinks,
            $currentCorrespondenceLink,
            $serviceError,
            $formLoading,
            $loadingDiv,
            $showMoreBtn,
            $newTab,
            $correspondenceAncestorWrapper;

        var correspondenceLinkEvent = function (event) {
            event.preventDefault();

            var $correspondenceItem = query.selectParentElement(
                    event.target,
                    CONSTANTS.CORRESPONDENCE_ITEM_CLASS
                ),
                serviceUrl = $correspondence.dataset.serviceUrl,
                params,
                documentType; 

            // Set elements depending on the item clicked
            $currentCorrespondenceLink = query.selectParentElement(
                event.target,
                CONSTANTS.CORRESPONDENCE_VIEW_LINK_CLASS
            );
            $serviceError = $correspondenceItem.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Tagging with an event and document type to all links
            documentType = $currentCorrespondenceLink.dataset.letterType;
            dataLayer.push({
                event : CONSTANTS.DOWNLOAD_TAGGING,
                documentType: documentType
            });

            // If correspondence link is available, open the link in a new tab
            if ($currentCorrespondenceLink.dataset.correspondenceLink === 'true') {
                window.open($currentCorrespondenceLink.href);

                return;
            }

            // Service data
            params = {
                ScId: query.getFormInputValue(document, CONSTANTS.SITECORE_ID_NAME),
                Maid: query.getFormInputValue(document, CONSTANTS.ACCOUNT_MAID_NAME),
                LetterDate: $currentCorrespondenceLink.dataset.letterDate,
                LetterType: $currentCorrespondenceLink.dataset.letterType,
                LetterCode: $currentCorrespondenceLink.dataset.letterCode,
                SequenceNumber: $currentCorrespondenceLink.dataset.sequenceNumber,
                DocumentId: $currentCorrespondenceLink.dataset.documentId
            };

            // Before doing the service call, lets create the new tab where the link will be opened, so it won't be blocked by browser security
            $newTab = window.open();

            // Service call
            query.getData(
                serviceUrl,
                successCorrespondenceService,
                errorCorrespondenceService,
                params,
                $formLoading
            );
        };

        var successCorrespondenceService = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_BILL_LINK,
                    redirectPage,
                    errorCorrespondenceService
                );
            } else if (data.Link && data.Link != '') {
                redirectPage(data);
            } else {
                errorCorrespondenceService(data);
            }
        };

        var redirectPage = function (data) {
            // Set link and flag for future click
            $currentCorrespondenceLink.dataset.correspondenceLink = 'true';
            $currentCorrespondenceLink.href = data.Link;

            // Set file location in the opened tab
            $newTab.location.href = data.Link;
        };

        var errorCorrespondenceService = function (data) {
            var $serviceErrorMessage = $serviceError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];

            // Close new tab opened
            $newTab.close();

            // Display error message
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var getCorrespondenceTable = function () {
            var serviceUrl = $correspondence.dataset.tableServiceUrl,
                params;

            // Service data
            params = {
                AccountMaid: query.getFormInputValue(document, CONSTANTS.ACCOUNT_MAID_NAME),
                ScId: query.getFormInputValue(document, CONSTANTS.SITECORE_ID_NAME)
            };

            // Service call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successGetCorrespondenceTable,
                errorGetCorrespondenceTable,
                params,
                true
            );
        };

        var successGetCorrespondenceTable = function (data) {
            if (coned.utils.isPatternLab()) {
                if ($correspondence.dataset.patternlabCorrespondenceTableBilling) {
                    query.getData(
                        coned.plConstants.GET_CORRESPONDENCE_TABLE_BILLING_HTML,
                        setCorrespondenceTable,
                        errorGetCorrespondenceTable
                    );
                } else {
                    query.getData(
                        coned.plConstants.GET_CORRESPONDENCE_TABLE_HTML,
                        setCorrespondenceTable,
                        errorGetCorrespondenceTable
                    );
                }
            } else {
                setCorrespondenceTable(data);
            }
        };

        var setCorrespondenceTable = function (data) {
            // Hide div loading and add billing and payment table received to the module container
            $loadingDiv.classList.add(CONSTANTS.DIV_LOADING_HIDDEN_CLASS);
            $correspondenceTable.innerHTML = data;

            // Once the div is loaded, now initialize all related functionality
            $correspondenceLinks = $correspondence.getElementsByClassName(
                CONSTANTS.CORRESPONDENCE_VIEW_LINK_CLASS
            );
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];

            new coned.components.LoadMore($correspondence, $correspondenceAncestorWrapper);
            initializeDataPostLoad();
            initializeEvents();
        };

        var errorGetCorrespondenceTable = function (data) {
            var $errorContainer = document.createElement('p'),
                $errorIcon = document.createElement('span'),
                $errorMessage = document.createElement('span');

            $errorContainer.className = CONSTANTS.ERROR_CONTAINER_CLASS;
            $errorIcon.classList = CONSTANTS.ERROR_ICON_CLASSES;
            $errorMessage.innerHTML = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;

            $errorContainer.appendChild($errorIcon);
            $errorContainer.appendChild($errorMessage);

            // Hide div loading and add error message to the module container
            $loadingDiv.classList.add(CONSTANTS.DIV_LOADING_HIDDEN_CLASS);
            $correspondence.appendChild($errorContainer);
        };

        var hideShowMoreOnLoad = function () {
            var initialLoad = $correspondence.dataset[CONSTANTS.INITIALOAD];
            var correspondenceLength = 0;

            if (
                $correspondence.querySelector(CONSTANTS.CORRESPONDENCE_TABLE_UL_SELECTOR) &&
                $correspondence.querySelector(CONSTANTS.CORRESPONDENCE_TABLE_UL_SELECTOR).children
            ) {
                correspondenceLength = $correspondence.querySelector(
                    CONSTANTS.CORRESPONDENCE_TABLE_UL_SELECTOR
                ).children.length;
            }

            if (initialLoad >= correspondenceLength) {
                $showMoreBtn && $showMoreBtn.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var initializeData = function () {
            $correspondenceTable = $correspondence.getElementsByClassName(
                CONSTANTS.CORRESPONDENCE_TABLE_CLASS
            )[0];
            $loadingDiv = $correspondence.getElementsByClassName(CONSTANTS.DIV_LOADING_CLASS)[0];
            $correspondenceAncestorWrapper = query.selectParentElement($correspondence, CONSTANTS.PAGE_CONTENT_ANCESTOR_SELECTOR);
        };

        var initializeDataPostLoad = function () {
            $showMoreBtn = $correspondence.getElementsByClassName(CONSTANTS.SHOW_MORE_BTN)[0];
        };

        var initializeEvents = function () {
            // Click on links
            _.each($correspondenceLinks, function ($correspondenceLink) {
                coned.utils.addGeneralListeners($correspondenceLink, correspondenceLinkEvent);
            });

            hideShowMoreOnLoad();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            getCorrespondenceTable();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    Correspondence.prototype.isLoaded = function () {
        return isLoaded;
    };

    return Correspondence;
})();
