// ==================== FILE A CLAIM FORM COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FileAClaimForm = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // General Selector Constants
        PAGE_HEADER: 'js-page-header',
        ATTACH_FILE_CHECKBOX: 'js-attach-file-checkbox',
        FILE_UPLOAD_INPUT: 'js-coned-inputfile',
        CURRENCY_INPUT: 'js-item-cost',
        AMOUNT_INPUTS: 'js-item-amount',
        TOTAL_ITEMS_COST: 'js-total-items-cost',
        TOTAL_ITEMS_AMOUNT: 'js-total-items-amount',
        ADD_NEW_ROW_BUTTON: 'js-add-new-row',
        SIGNATURE_DATE_INPUT: 'js-signature-date',
        TOTAL_LOSS_INPUT: 'js-total-loss',
        ITEMS_CONTAINER: 'js-items-container',
        ITEM_HEADER: 'js-item-header',
        ITEM_ROW: 'js-item-row',
        ITEM_GROUP_1: 'js-item-group-1',
        CONED_INPUT: '.coned-input',
        CONED_TEXT_AREA: '.coned-textarea',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        INPUT_FILLED_CLASS_SELECTOR: '.coned-input--filled',
        TEXT_AREA_FILLED_CLASS: 'coned-textarea--filled',
        MAX_ITEMS_MESSAGE: 'js-max-items-message',
        SUCCESS_MESSAGE: 'js-success-message',
        ERROR_MESSAGE: 'js-error-message',
        DOLLAR_SIGN: '$',
        RECAPTCHA_ELEMENT: 'js-recaptcha',
        MESSAGE_WRAPPER: 'js-form-messages',
        FORM_WRAPPER: 'js-form-wrapper',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        TRY_AGAIN: 'js-try-again',

        //Form data
        FORM_ID: 'facSpoilageFormId',
        CONTACT_NAME: 'facSpoilageName',
        CONTACT_ADDRESS: 'facSpoilageAddress',
        CONTACT_APT: 'facSpoilageApartmentNumber',
        CONTACT_CITY: 'facSpoilageCity',
        CONTACT_STATE: 'facSpoilageState',
        CONTACT_ZIP_CODE: 'facSpoilageZipcode',
        CONTACT_DAY_PHONE: 'facSpoilagePhone',
        CONTACT_EMAIL: 'facSpoilageEmail',
        TYPE_OF_BUSINESS: 'facSpoilageBusinessType',
        CONTACT_ACT_NUM: 'facSpoilageAccountNumber',
        OUTAGE_START_DATE: 'facSpoilageStartDate',
        OUTAGE_START_TIME: 'facSpoilageStartTime',
        OUTAGE_START_DAYTIME: 'facSpoilageStartDaytime',
        OUTAGE_END_DATE: 'facSpoilageEndDate',
        OUTAGE_END_TIME: 'facSpoilageEndTime',
        OUTAGE_END_DAYTIME: 'facSpoilageEndDayTime',
        TOTAL_LOST: 'facSpoilageTotalLoss',
        SIGNATURE: 'facSpoilageSignature',
        SIGNATURE_DATE: 'facSpoilageSignatureDate',
        SIGNATURE_POSITION: 'facSpoilageSignaturePosition',

        //Items
        ITEM_TYPE: 'facSpoilageItemType',
        ITEM_AMOUNT: 'facSpoilageItemAmount',
        ITEM_COST: 'facSpoilageItemCost',

        // Helper Class Constants
        HIDDEN_CLASS: 'hidden',
        CONED_INPUT_CLASS: 'coned-input',
        VALID_CLASS: 'valid',
        FILLED_CLASS: 'coned-input--filled',
        FORM_WRAPPER_NO_BACKGROUND_CLASS: 'coned-form-wrapper--no-background'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var FileAClaimForm = function ($facForm) {
        /**
         * PRIVATE METHODS
         */

        var // General Selector Variables
            $pageHeader,
            $attachFileCheckbox,
            $addNewRowButton,
            $itemsContainer,
            $itemRows,
            $maxItemsMessage,
            $successMessage,
            $errorMessage,
            $totalLossInput,
            $currencyInputs,
            $amountInputs,
            $totalItemsCost,
            $totalItemsAmount,
            $signatureDateInput,
            $fileInput,
            $formLoading,
            $formWrapper,
            $messageWrapper,
            $tryAgain,
            recaptcha,
            // Helper Variables
            _hasCaptcha,
            _isCommercial,
            _itemRowCounter;

        /**
         * Hides Manual Item Fields
         */
        var toggleManualItemsEvent = function () {
            if (!$itemsContainer.classList.contains(CONSTANTS.HIDDEN_CLASS)) {
                $itemsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $totalLossInput.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $fileInput.setAttribute('data-rule-fileRequired', 'true');
                $fileInput.setAttribute('aria-required', 'true');
            } else {
                $itemsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $totalLossInput.classList.add(CONSTANTS.HIDDEN_CLASS);
                $fileInput.setAttribute('data-rule-fileRequired', 'false');
                $fileInput.removeAttribute('aria-required');
            }
        };

        /**
         * Resets Item Fields
         */
        var resetItemsItemsEvent = function () {
            $itemsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $totalLossInput.classList.add(CONSTANTS.HIDDEN_CLASS);
            $fileInput.setAttribute('data-rule-fileRequired', 'false');
            $fileInput.setAttribute('aria-required', 'false');

            $totalItemsCost.innerHTML = coned.utils.formatToCurrencyText(0, 2);
            $totalItemsAmount.innerHTML = 0;
        };

        /**
         * Add amounts manually entered for items
         */
        var addItemsAmountEvent = function () {
            var total = 0;

            if ($currencyInputs && $totalItemsCost) {
                for (var i = 0; i < $currencyInputs.length; i++) {
                    if (
                        $currencyInputs[i] &&
                        $currencyInputs[i].value &&
                        $amountInputs[i] &&
                        $amountInputs[i].value
                    ) {
                        var currency = $currencyInputs[i].value.replace(/\,/g, '').substring(1);
                        total = total + parseFloat(currency, 10) * parseInt($amountInputs[i].value);
                    }
                }
                $totalItemsCost.innerHTML = coned.utils.formatToCurrencyText(total, 2);
            }
        };

        /**
         * Counts Items entered manually
         */
        var countItemsEvent = function () {
            var totalCount = 0;
            for (var j = 0; j < $itemRows.length; j++) {
                if (
                    $itemRows[j].querySelectorAll(CONSTANTS.INPUT_FILLED_CLASS_SELECTOR).length > 0
                ) {
                    totalCount = totalCount + 1;
                }
            }
            $totalItemsAmount.innerHTML = totalCount;
        };

        /**
         * Creates a new row of item fields
         */
        var addNewRowEvent = function () {
            if (
                _itemRowCounter >= parseInt($addNewRowButton.dataset.maxRows) ||
                _itemRowCounter >= $itemsContainer.children.length
            ) {
                return;
            }

            var $rowToAdd = $itemsContainer.children[_itemRowCounter++],
                $inputs = $rowToAdd.getElementsByClassName(CONSTANTS.CONED_INPUT_CLASS);

            _.each($inputs, function ($input, value) {
                $input.disabled = false;

                $input.addEventListener('keyup', countItemsEvent);
                value && $input.addEventListener('keyup', addItemsAmountEvent);
            });

            $rowToAdd.classList.remove(CONSTANTS.HIDDEN_CLASS);

            if (_itemRowCounter >= parseInt($addNewRowButton.dataset.maxRows)) {
                $addNewRowButton.disabled = true;

                if ($maxItemsMessage) {
                    $maxItemsMessage.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            }

            $inputs[0].focus();
        };

        /**
         * Check all item inputs to show them if they have cached values
         */
        var checkItemsOnLoad = function () {
            var hasValue = false;

            for (var i = $itemsContainer.children.length - 1; i > 0; i--) {
                if (hasValue) {
                    addNewRowEvent();
                } else {
                    hasValue =
                        _.filter(
                            $itemsContainer.children[i].querySelectorAll('input'),
                            function ($input) {
                                return $input.value !== '';
                            }
                        ).length > 0;

                    if (hasValue) {
                        addNewRowEvent();
                    }
                }
            }
        };

        /**
         *  Check Recaptcha
         **/
        var checkRecaptcha = function () {
            if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                recaptcha.checkRecaptcha();
            }
        };

        /**
        *  Updates page styles for Confirmation Screen
        **/
        var updatePageForConfirmationScreen = function () {
            query.addClass($formWrapper, CONSTANTS.FORM_WRAPPER_NO_BACKGROUND_CLASS);
            $pageHeader.style.display = 'none';
        }
        
        /**
        *  Resets page styles when Confirmation Screen hides
        **/
        var resetPageForTryAgain = function () {
            query.removeClass($formWrapper, CONSTANTS.FORM_WRAPPER_NO_BACKGROUND_CLASS);
            $pageHeader.style.display = 'block';
        }

        /**
         *  Handles submit form for spoliage
         **/
        var onSubmitForm = function () {
            if (!$($facForm).validate().valid()) return;

            var serviceUrl = $facForm.dataset.serviceUrl,
                formData = new FormData(),
                items = [],
                params;

            // Items
            for (var i = 1; i <= _itemRowCounter; i++) {
                if ($currencyInputs[i - 1].disabled || $currencyInputs[i - 1].value === '')
                    continue;
                var item = {
                    Type: query.getFormInputValue($facForm, CONSTANTS.ITEM_TYPE + i),
                    Quantity: query.getFormInputValue($facForm, CONSTANTS.ITEM_AMOUNT + i),
                    Cost: query
                        .getFormInputValue($facForm, CONSTANTS.ITEM_COST + i)
                        .replace('$', '')
                };
                items.push(item);
            }

            // Service Data
            params = {
                FormId: query.getFormInputValue($facForm, CONSTANTS.FORM_ID),
                Name: query.getFormInputValue($facForm, CONSTANTS.CONTACT_NAME),
                Address: query.getFormInputValue($facForm, CONSTANTS.CONTACT_ADDRESS),
                Apartment: query.getFormInputValue($facForm, CONSTANTS.CONTACT_APT),
                City: query.getFormInputValue($facForm, CONSTANTS.CONTACT_CITY),
                State: query.getFormInputValue($facForm, CONSTANTS.CONTACT_STATE),
                Zipcode: query.getFormInputValue($facForm, CONSTANTS.CONTACT_ZIP_CODE),
                DaytimePhone: query.getFormInputValue($facForm, CONSTANTS.CONTACT_DAY_PHONE),
                Email: query.getFormInputValue($facForm, CONSTANTS.CONTACT_EMAIL),
                Account: query.getFormInputValue($facForm, CONSTANTS.CONTACT_ACT_NUM),
                OutageStartDate: query.getFormInputValue($facForm, CONSTANTS.OUTAGE_START_DATE),
                OutageStartTime: query.getFormInputValue($facForm, CONSTANTS.OUTAGE_START_TIME),
                OutageStartPeriod: query.getFormInputValue(
                    $facForm,
                    CONSTANTS.OUTAGE_START_DAYTIME
                ),
                OutageEndDate: query.getFormInputValue($facForm, CONSTANTS.OUTAGE_END_DATE),
                OutageEndTime: query.getFormInputValue($facForm, CONSTANTS.OUTAGE_END_TIME),
                OutageEndPeriod: query.getFormInputValue($facForm, CONSTANTS.OUTAGE_END_DAYTIME),
                ItemsListAttached: $attachFileCheckbox.checked,
                Items: JSON.stringify(items),
                TotalAmountLoss: $attachFileCheckbox.checked
                    ? query
                        .getFormInputValue($facForm, CONSTANTS.TOTAL_LOST)
                        .replace(/\,/g, '')
                        .substring(1)
                    : $totalItemsCost.innerHTML.replace(/\,/g, '').substring(1),
                Signature: query.getFormInputValue($facForm, CONSTANTS.SIGNATURE),
                SignatureDate: query.getFormInputValue($facForm, CONSTANTS.SIGNATURE_DATE),
                IsCommercial: _isCommercial
            };

            // Add additional fields if it's commercial
            if (_isCommercial) {
                params.TypeOfBusiness = query.getFormInputValue(
                    $facForm,
                    CONSTANTS.TYPE_OF_BUSINESS
                );
                params.SignaturePosition = query.getFormInputValue(
                    $facForm,
                    CONSTANTS.SIGNATURE_POSITION
                );
            }

            if (_hasCaptcha && recaptcha) {
                params.Verify = recaptcha.getResponse();
            }

            // Serialize JSON into string to append to FormData
            params = JSON.stringify(params);
            formData.append('data', params);

            if ($fileInput && $fileInput.fileList.length) {
                _.each($fileInput.fileList, function ($file) {
                    formData.append('filesAttached', $file);
                });
            }

            // Service Call
            query.postData(
                serviceUrl,
                successSubmitForm,
                errorSubmitForm,
                formData,
                false,
                $formLoading
            );
        };

        /**
         * Handle Submit Code service call success
         */
        var successSubmitForm = function () {
            updatePageForConfirmationScreen();
            $facForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $messageWrapper.style.display = 'block';
            $successMessage.style.display = 'block';
            window.scrollTo(0, 0);
            $successMessage.focus();

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success'
            });
        };

        /**
         * Handle Submit Code service call error
         */
        var errorSubmitForm = function () {
            updatePageForConfirmationScreen();
            $facForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $messageWrapper.style.display = 'block';
            $errorMessage.style.display = 'block';
            window.scrollTo(0, 0);
            $errorMessage.focus();
        };

        /**
         * Inits data in the module.
         */
        var initializeData = function () {
            $pageHeader = document.getElementsByClassName(CONSTANTS.PAGE_HEADER)[0];
            $formWrapper = document.getElementsByClassName(CONSTANTS.FORM_WRAPPER)[0];
            $messageWrapper = document.getElementsByClassName(CONSTANTS.MESSAGE_WRAPPER)[0];
            $addNewRowButton = $facForm.getElementsByClassName(CONSTANTS.ADD_NEW_ROW_BUTTON)[0];
            $itemsContainer = $facForm.getElementsByClassName(CONSTANTS.ITEMS_CONTAINER)[0];
            $maxItemsMessage = $facForm.getElementsByClassName(CONSTANTS.MAX_ITEMS_MESSAGE)[0];
            $successMessage = $messageWrapper.getElementsByClassName(CONSTANTS.SUCCESS_MESSAGE)[0];
            $errorMessage = $messageWrapper.getElementsByClassName(CONSTANTS.ERROR_MESSAGE)[0];
            $totalLossInput = $facForm.getElementsByClassName(CONSTANTS.TOTAL_LOSS_INPUT)[0];
            $signatureDateInput = $facForm.getElementsByClassName(
                CONSTANTS.SIGNATURE_DATE_INPUT
            )[0];
            $currencyInputs = $facForm.getElementsByClassName(CONSTANTS.CURRENCY_INPUT);
            $amountInputs = $facForm.getElementsByClassName(CONSTANTS.AMOUNT_INPUTS);
            $itemRows = $facForm.getElementsByClassName(CONSTANTS.ITEM_ROW);
            $tryAgain = document.getElementsByClassName(CONSTANTS.TRY_AGAIN)[0];
            recaptcha = false;
            _hasCaptcha = $facForm.getElementsByClassName(CONSTANTS.RECAPTCHA_ELEMENT)[0];
            _isCommercial = $facForm.dataset.isCommercial === 'true';
            _itemRowCounter = 1;

            $attachFileCheckbox = $facForm.getElementsByClassName(
                CONSTANTS.ATTACH_FILE_CHECKBOX
            )[0];
            $totalItemsCost = $facForm
                .getElementsByClassName(CONSTANTS.TOTAL_ITEMS_COST)[0]
                .getElementsByTagName('p')[0];
            $totalItemsAmount = $facForm
                .getElementsByClassName(CONSTANTS.TOTAL_ITEMS_AMOUNT)[0]
                .getElementsByTagName('p')[0];
            $fileInput = $facForm.getElementsByClassName(CONSTANTS.FILE_UPLOAD_INPUT)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            
        };

        /**
         * Inits events in the module.
         */
        var initializeEvents = function () {
            new coned.components.ValidateForm($facForm, checkRecaptcha);
            recaptcha = new coned.components.Recaptcha($facForm, onSubmitForm, onSubmitForm);
        
            if ($attachFileCheckbox) {
                $attachFileCheckbox.addEventListener('change', toggleManualItemsEvent);
                $facForm.addEventListener('reset-form', resetItemsItemsEvent);
            }

            if ($signatureDateInput) {
                var today = new Date(),
                    dd = today.getDate(),
                    mm = today.getMonth() + 1,
                    yyyy = today.getFullYear();

                today = mm + '/' + dd + '/' + yyyy;
                $signatureDateInput.value = today;
                $signatureDateInput.disabled = true;
                $signatureDateInput.classList.add(CONSTANTS.FILLED_CLASS);
            }

            _.each(
                $facForm.getElementsByClassName(CONSTANTS.ITEM_GROUP_1),
                function ($input, value) {
                    $input.addEventListener('keyup', countItemsEvent);
                    value && $input.addEventListener('keyup', addItemsAmountEvent);
                }
            );

            coned.utils.addGeneralListeners($tryAgain, function () {
                $facForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $messageWrapper.style.display = 'none';
                $errorMessage.style.display = 'none';
                $facForm.scrollIntoView(true);

                resetPageForTryAgain();
                if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                    recaptcha.reset();
                }
            });
            

            if ($addNewRowButton) {
                coned.utils.addGeneralListeners($addNewRowButton, addNewRowEvent);
            }

            setTimeout(checkItemsOnLoad, 1100);

            setTimeout(function () {
                $($facForm)
                    .find(CONSTANTS.CONED_INPUT)
                    .each(function () {
                        if ($(this).val()) {
                            $(this).addClass(CONSTANTS.INPUT_FILLED_CLASS);
                            $(this).valid();
                        }
                    });

                $($facForm)
                    .find(CONSTANTS.CONED_TEXT_AREA)
                    .each(function () {
                        if ($(this).val()) {
                            $(this).addClass(CONSTANTS.TEXT_AREA_FILLED_CLASS);
                            $(this).valid();
                        }
                    });
            }, 1000);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FileAClaimForm.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FileAClaimForm;
})();
