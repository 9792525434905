// ==================== UTILS/UTILS =========================
/* global _ */
/* global $ */
/* global QSI */
/* global $clamp */
/* global isOru */
/* global dataLayer */

var query = {},
    coned = coned || {};
coned.utils = coned.utils || {};

/**
 * Looks for modules in the page and initialize them if they aren't ready.
 * @param {HTMLElement} $root [optional] Parent root to look for modules. If not given, uses the document.
 */
coned.utils.initializeModules = function initializeModules($root) {
    var $parentNode = $root ? $root : document,
        $modules = $parentNode.getElementsByClassName(coned.constants.MODULE_CLASS);

    for (var modulesIndex = 0; modulesIndex < $modules.length; modulesIndex++) {
        var $module = $modules[modulesIndex];

        if ($module.dataset.moduleStarted !== 'true') {
            var loadModule = $module.dataset.moduleLoad;

            if (loadModule !== 'false') {
                var moduleName = $module.dataset.module;

                $module.dataset.moduleStarted = 'true';

                if (moduleName) {
                    var newModule = new coned.components[moduleName]($module);

                    if (newModule.init) {
                        newModule.init();
                    }
                }
            }
        }
    }
};

/**
 * Checks if device has a touch screen.
 * @return {Boolean} True if device is touch, false if not.
 */
coned.utils.isTouch = function isTouch() {
    var pointerEnabled = !!navigator.pointerEnabled || navigator.msPointerEnabled;

    return 'ontouchstart' in window || (window.DocumentTouch && pointerEnabled);
};

/**
 * Checks if the provided item is hidden or not
 * @param {HTMLElement} $element DOM Element to check
 * @return {Boolean} True if the element is hidden, false if not.
 */
coned.utils.isElementHidden = function ($element) {
    return $element && !$element.offsetParent;
};

/**
 * Checks if device is mobile.
 * @return {Boolean} True if device is mobile, false if not.
 */
coned.utils.isMobile = function isMobile() {
    return window.innerWidth <= coned.constants.MOBILE_MAX_SIZE;
};

/**
 * Helper to detect if the device is an iOS device (iPhone or iPad).
 * @return {Boolean} True if the device is an iOS device, false if not.
 */
coned.utils.isIOS = function isIOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
};

/**
 * Helper to detect if the device is an ipad.
 * @return {Boolean} True if the device is an ipad, false if not.
 */
coned.utils.isiPad = function isiPad() {
    return navigator.userAgent.match(/iPad/i) !== null;
};

/**
 * Check if its tablet and the orientation is equal to portrait.
 * @return {Boolean} [orientationMatters] Boolean to set if the function needs to check the orientation.
 */
coned.utils.isTablet = function isTablet() {
    return (
        window.innerWidth >= coned.constants.TABLET_MIN_SIZE &&
        window.innerWidth <= coned.constants.TABLET_MAX_SIZE
    );
};

/**
 * Check if its a Desktop rendering.
 * @return {Boolean} True if the resolution represents a Desktop browser width.
 */
coned.utils.isLargeDesktop = function () {
    return window.innerWidth > coned.constants.TABLET_MAX_SIZE;
};

/**
 * Check if its a Desktop rendering.
 * @return {Boolean} True if the resolution represents a Desktop browser width.
 */
coned.utils.isDesktop = function () {
    return window.innerWidth > coned.constants.MOBILE_MAX_SIZE;
};

/**
 * Helper to detect if orientation is portrait.
 * @return {Boolean} True if orientation is portrait, false if orientation is landscape.
 */
coned.utils.isPortrait = function isPortrait() {
    return window.matchMedia('(orientation: portrait)').matches;
};

/**
 * Helper to detect if orientation is landscape.
 * @return {Boolean} True if orientation is landscape, false if orientation is portrait.
 */
coned.utils.isLandscape = function isLandscape() {
    return window.matchMedia('(orientation: landscape)').matches;
};

/**
 * Helper to detect if user is using Safari
 * @return {Boolean} True if browser is any version of Safari.
 */
coned.utils.isSafari = function isSafari() {
    var ua = navigator.userAgent.toLowerCase(),
        isSafari;

    try {
        isSafari = (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    } catch (err) {
        isSafari = false;
    }

    isSafari = (isSafari || ((ua.indexOf('safari') != -1) && (!(ua.indexOf('chrome') != -1) && (ua.indexOf('version/') != -1))));

    return isSafari
};

/**
 * Helper to detect if user is using IE
 * @return {Boolean} True if browser is any version of IE.
 */
coned.utils.isIE = function isIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!ua.match(/Trident.*rv\:11\./)) {
        return true;
    }

    return false;
};

/**
 * Check if browser is either a Windows Tablet or Edge.
 * @return {Boolean} True if the resolution represents a Desktop browser width
 */
coned.utils.isWinEdge = function () {
    return (
        navigator.platform.toLowerCase().indexOf('win') !== -1 &&
        (navigator.userAgent.toLowerCase().indexOf('touch') !== -1 ||
            navigator.userAgent.toLowerCase().indexOf('edge') !== -1)
    );
};

/**
 * Determines if page is within the Pattern Lab Framework.
 * @return {Boolean} True if page rendering is from PL, false if not.
 */
coned.utils.isPatternLab = function () {
    return document.getElementsByClassName(coned.plConstants.PATTERNLAB_CLASS).length > 0;
};

/**
 * Check if page rendering represents an ORU site page.
 * @return {Boolean} True if '--oru' is found as a modifier, false if not.
 */
coned.utils.isOru = function () {
    if (coned.utils.isPatternLab()) {
        return !(document.querySelector('[class*=-oru]') == null);
    }
    return isOru;
};

/**
 * Check if element is displayed in the DOM
 * @return {Boolean} True if element is visible, false if not.
 * @param {HTMLElement} $element Element to check.
 */
coned.utils.isDomVisible = function ($element) {
    return (
        $element.offsetWidth +
        $element.getBoundingClientRect().width +
        $element.offsetHeight +
        $element.getBoundingClientRect().height !==
        0
    );
};

/**
 * Check if the event is touch or click, depending on the device.
 * @return {String} Touchend or click, depending on the pointerEnabled response.
 */
coned.utils.eventType = function () {
    if (coned.utils.isTouch()) {
        return 'touchend';
    } else {
        return 'click';
    }
};

/**
 * Return an array with the events we generally want to set up for an element.
 * @return {Array} String array of events to use.
 */
coned.utils.generalEvents = function () {
    return ['touchend', 'click'];
};

/**
 * Trigger an element's event manually on js code without interacting with the HTML DOM
 * @template T
 * @param { HTMLElement } $element
 * @param { string } event
 * @param { T | null | undefined } detail
 */
coned.utils.triggerEvent = function ($element, event, detail) {
    var evt;

    if (detail && typeof CustomEvent === 'function') {
        evt = new CustomEvent(event, { detail: detail });
        $element.dispatchEvent(evt);
    } else {
        if ('createEvent' in document) {
            evt = document.createEvent('HTMLEvents');
            evt.initEvent(event, false, true);
            evt.detail = detail;
            $element.dispatchEvent(evt);
        } else {
            $element.fireEvent('on' + event);
        }
    }
};

/**
 * Add multiple event listeners to an element.
 * @param {HTMLElement} $element Element to attach event to.
 * @param {Array} events List of events to add to the element.
 * @param {Function} associatedFunction Event listener function to add to the element.
 */
coned.utils.addMultipleListeners = function ($element, events, associatedFunction) {
    for (var eventIndex = 0; eventIndex < events.length; eventIndex++) {
        $element.addEventListener(events[eventIndex], associatedFunction);
    }
};

/**
 * Add all general events listeners to an element.
 * @param {HTMLElement} $element Element to attach events to.
 * @param {Function} associatedFunction Event function to add.
 */
coned.utils.addGeneralListeners = function ($element, associatedFunction) {
    if (!$element) return;

    coned.utils.addMultipleListeners($element, coned.utils.generalEvents(), associatedFunction);
};

/**
 * Remove multiple event listeners to an element.
 * @param {HTMLElement} $element Element to remove event to.
 * @param {Array} events List of events to remove to the element.
 * @param {Function} associatedFunction Event listener function to remove to the element.
 */
coned.utils.removeMultipleListeners = function ($element, events, associatedFunction) {
    for (var eventIndex = 0; eventIndex < events.length; eventIndex++) {
        $element.removeEventListener(events[eventIndex], associatedFunction);
    }
};

/**
 * Remove all general events listeners to an element.
 * @param {HTMLElement} $element Element to remove events to.
 * @param {Function} associatedFunction Event function to remove.
 */
coned.utils.removeGeneralListeners = function ($element, associatedFunction) {
    if (!$element) return;

    coned.utils.removeMultipleListeners($element, coned.utils.generalEvents(), associatedFunction);
};

/**
 * Format float number to currency text
 * @param {number} amount
 */
coned.utils.formatToCurrencyText = function (amount, numberOfDecimals, currencySymbol) {
    if (!currencySymbol) currencySymbol = '$';
    if (!amount || !isFinite(amount) || isNaN(amount)) amount = 0;

    return currencySymbol + amount.toFixed(numberOfDecimals).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

/**
 * Add a listener to a parent element.
 * @param {HTMLElement} $element Element to attach events to.
 * @param {String} childrenClass Children class that triggers the event.
 * @param {Function} associatedFunction Event function to add.
 * @return {Function} associatedFunction($element, event).
 */
coned.utils.addParentListener = function ($element, events, childrenClass, associatedFunction) {
    if (!$element) return;

    coned.utils.addMultipleListeners($element, events, function (event) {
        var current = event.target,
            disabled;

        //Null is checked, because sometimes elements are removed from the DOM, but these elements still listen.
        while (current && current !== event.currentTarget) {
            disabled = current.getAttribute('disabled');

            if (current.classList.contains(childrenClass) && !disabled) {
                return associatedFunction(current, event);
            } else {
                current = current.parentElement;
            }
        }
    });
};

/**
 * Prevent backspace/delete, shift and arrows from being processed in form inputs when not needed.
 * @param {Event} event Action event related to the character input.
 * @return {Boolean} If the following functionality should be prevented or not.
 */
coned.utils.preventBehaviourError = function (event) {
    var keyCode;

    // Prevent delete/backspace/arrows/shift from not working
    if (!event) {
        event = window.event; // some browsers don't pass event, so get it from the window
    }

    if (event.keyCode) {
        keyCode = event.keyCode; // some browsers use event.keyCode
    } else if (event.which) {
        keyCode = event.which; // others use event.which
    }

    // delete = 8, backspace = 46, shift = 16, left arrow = 37, right arrow 39
    if (keyCode == 8 || keyCode == 16 || keyCode == 46 || keyCode == 37 || keyCode == 39) {
        return true;
    } else {
        return false;
    }
};

/**
 * Prevent arrows from being prevented in form inputs.
 * @param {Event} event Action event related to the character input.
 * @return {Boolean} If the following functionality should be prevented or not.
 */
coned.utils.preventMovementError = function (event) {
    var keyCode;

    // Prevent arrows + shift from not working
    if (!event) {
        event = window.event; // some browsers don't pass event, so get it from the window
    }

    if (event.keyCode) {
        keyCode = event.keyCode; // some browsers use event.keyCode
    } else if (event.which) {
        keyCode = event.which; // others use event.which
    }

    // shift = 16, left arrow = 37, right arrow 39
    if (keyCode == 16 || keyCode == 37 || keyCode == 39) {
        return true;
    } else {
        return false;
    }
};

/**
 * Get the url parameter value, depending on the parameter name.
 * @param {String} parameter Parameter name to look for.
 * @return {String} Parameter value from the parameter being searched for.
 */
coned.utils.getUrlParameterValue = function (parameter) {
    var query = window.location.search.substring(1),
        parameters = query.split('&');

    for (var index = 0; index < parameters.length; index++) {
        var pair = parameters[index].split('=');

        if (pair[0].toLowerCase() == parameter.toLowerCase()) {
            return pair[1];
        }
    }

    return false;
};

/**
 * Update the url with an updated parameter value. If parameter is not currently included, it'll add it.
 * @param {String} parameter Parameter name to update.
 * @param {String} updatedValue Update value used for the parameter value change.
 * @return {String} Url updated with the update requested.
 */
coned.utils.updateUrlParameter = function (parameter, updatedValue, url) {
    var query = url || window.location.href,
        urlNoQuery = query.split('?')[0],
        parameters = query.includes('?') ? query.split('?')[1].split('&') : [],
        parameterSet = parameter + '=' + updatedValue,
        exists = false;

    for (var index = 0; index < parameters.length; index++) {
        var pair = parameters[index].split('=');

        if (pair[0] == parameter) {
            parameters[index] = parameterSet;
            exists = true;
        }
    }

    // If parameter doesn't exist, add it
    if (!exists) {
        parameters.push(parameterSet);
    }

    return urlNoQuery + '?' + parameters.join('&');
};

/**
 * Update the provided url with an updated parameter value. If parameter is not currently included, it'll add it.
 * @param {String} url Url to update from.
 * @param {String} parameter Parameter name to update.
 * @param {String} updatedValue Update value used for the parameter to be updated.
 * @return {String} Url updated with the update requested.
 */
coned.utils.updateAnchorParameter = function (url, parameter, updatedValue) {
    // Create anchor to be able to use the location enabled functionality
    var location = document.createElement('a');

    location.href = url;

    var query = location.search.substring(1),
        parameters = query.split('&'),
        parameterSet = parameter + '=' + updatedValue,
        exists = false;

    for (var index = 0; index < parameters.length; index++) {
        var pair = parameters[index].split('=');

        if (pair[0] == parameter) {
            parameters[index] = parameterSet;
            exists = true;
        }
    }

    // If parameter doesn't exist, add it
    if (!exists) {
        parameters.push(parameterSet);
    }

    return location.href.replace(query, parameters.join('&'));
};

/**
 * Remove parameter from the provided url.
 * @param {String} url Url to delete from.
 * @param {String} parameter Parameter name to delete.
 * @return {String} Url without the parameter.
 */
coned.utils.deleteAnchorParameter = function (url, parameter) {
    // Create anchor to be able to use the location enabled functionality
    var location = document.createElement('a');

    location.href = url;

    var query = location.search.substring(1),
        parameters = query.split('&');

    for (var index = 0; index < parameters.length; index++) {
        var pair = parameters[index].split('=');

        if (pair[0] == parameter) {
            parameters.splice(index, 1);
        }
    }

    return location.href.replace(query, parameters.join('&'));
};

/**
 * Scrolls the page to the desired position on the required duration.
 * @param {Int} to Position to scroll to.
 * @param {Int} duration Duration of the scroll to do.
 * @param {Function} callback Callback function, once the scroll to the position is completed.
 */
coned.utils.scrollTo = function (to, duration, callback) {
    var scrollY = window.scrollY || window.pageYOffset;

    if (scrollY == to) return;

    var diff = to - scrollY,
        scrollStep = Math.PI / (duration / 10),
        count = 0,
        currPos,
        start = scrollY;

    var scrollInterval = setInterval(function () {
        if (scrollY === to) {
            clearInterval(scrollInterval);

            if (callback) callback();
        } else if (Math.abs(to - scrollY) <= 2) {
            window.scrollTo(0, to);

            clearInterval(scrollInterval);

            if (callback) callback();
        } else {
            count = count + 1;
            currPos = start + diff * (0.5 - 0.5 * Math.cos(count * scrollStep));

            window.scrollTo(0, currPos);
            scrollY = window.scrollY || window.pageYOffset;
        }
    }, 10);
};

/**
 * Format date recieved with the specific date format desired.
 * @param {String} unFormatDate This should comes in the form of: mm/dd/yyyy
 * @return {String} date in the following format: yyyy-mm-dd
 */
coned.utils.serviceDateFormat = function (unFormatDate) {
    var splitDate = unFormatDate.split('/');

    return splitDate[2] + '-' + splitDate[0] + '-' + splitDate[1];
};

/**
 * Format standard dates recieved with a datepicker pair of dates
 * @param {String} minDate This should come in the form of: 'yyyy/mm/dd'
 * @param {String} maxDate This should come in the form of: 'yyyy/mm/dd'
 * @return {Object} Object with the minDate and maxDate.
 */
coned.utils.datepickerDateFormat = function (minDate, maxDate) {
    minDate = minDate || 0;
    maxDate = maxDate || 0;

    var minimunDate = minDate != 0 && minDate != undefined ? new Date(minDate) : new Date(),
        maximunDate = maxDate != 0 && maxDate != undefined ? new Date(maxDate) : new Date();

    return { minDate: minimunDate, maxDate: maximunDate };
};

/**
 * Format standard dates received from a string
 * @param {String} inputDate This should come in the form of: 'yyyy/mm/dd'
 * @return {Date} formatedDate.
 */
coned.utils.dateFormat = function (inputDate) {
    if (inputDate && inputDate !== '') {
        inputDate = 0 || inputDate;
    } else {
        inputDate = 0;
    }

    var formatedDate = inputDate !== 0 ? new Date(inputDate) : new Date();

    return formatedDate;
};

/**
 * Format dates received to long month format
 * @param {Object String} inputDate Input date object or string.
 * @return {Date} formatedDate. ie: June 13, 2023.
 */
coned.utils.dateFormatLongMonth = function (inputDate) {
    var date = new Date(inputDate),
        options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        },
        formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
};

/**
 * Sets minimum and maximum dates for datepickers, disabling dates outside the range.
 * Dates are relative to today, so it adds or substracts # of days, months, years to today's date.
 * Set zeros if day, month or year should be same as current date.
 * Negative numbers are allowed to offset dates in the past.
 * ie: to limit date to 2 years in the past and 1 day ahead in the future,
 * set params as coned.utils.setDatepickerStartEndDateLimit($exampleDateInput, 0, 0, -2, 0, 1, 0)
 * @param {HTMLElement} $datepickerInput Input element from a datepicker.
 * @param {Number} minDateMonthOffset Number of months from minimum date we want to offset.
 * @param {Number} minDateDayOffset Number of days from minimum date we want to offset.
 * @param {Number} minDateYearOffset Number of years from minimum date we want to offset.
 * @param {Number} maxDateMonthOffset Number of months from maximum date we want to offset.
 * @param {Number} maxDateDayOffset Number of days from maximum date we want to offset.
 * @param {Number} maxDateYearOffset Number of years from maximum date we want to offset.
 */
coned.utils.setDatepickerStartEndDateLimit = function (
    $datepickerInput,
    minDateMonthOffset,
    minDateDayOffset,
    minDateYearOffset,
    maxDateMonthOffset,
    maxDateDayOffset,
    maxDateYearOffset
) {
    var todayMin = new Date(),
        todayMax = new Date(),
        minDate = new Date(
            todayMin.setFullYear(
                todayMin.getFullYear() + minDateYearOffset,
                todayMin.getMonth() + minDateMonthOffset,
                todayMin.getDate() + minDateDayOffset
            )
        ),
        maxDate = new Date(
            todayMax.setFullYear(
                todayMax.getFullYear() + maxDateYearOffset,
                todayMax.getMonth() + maxDateMonthOffset,
                todayMax.getDate() + maxDateDayOffset
            )
        );

    $datepickerInput.dataset.minDateDays = minDate.toString();
    $datepickerInput.dataset.maxDateDays = maxDate.toString();
};

/**
 * Fill input with the given date
 * @param {HTMLElement} $input Input element we want to fill with the date.
 * @param {Date} date Date object constructor.
 */
coned.utils.fillInputWithFormattedDate = function ($input, date) {
    var day = date,
        dd = day.getDate(),
        mm = day.getMonth() + 1,
        yyyy = day.getFullYear();

    day = mm + '/' + dd + '/' + yyyy;
    $input.value = day;
    $input.disabled = true;
    $input.classList.add(coned.constants.INPUT_FILLED_CLASS);
};

/**
 * Jack Moore's rounding solution http://www.jacklmoore.com/notes/rounding-in-javascript/
 * @param {Float} value This value equals to xx.xxxx
 * @param {Int} decimals It rounds to the amount of decimals specified in this parameter from 1-10.
 * @return {Float} number with the amount of decimals requested, rounding to the nearest tenth.
 */
coned.utils.round = function (value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

/**
 * Round up a number
 * Currently the ceil function doesn't work correctly with negative numbers, also handles -0 values
 * @param {Float} num the numbers that needs to roundUp
 */
coned.utils.roundUp = function (num) {
    var rounded = num >= 0 ? Math.ceil(num) : Math.floor(num);

    // Converts -0 to 0
    return rounded === -0 ? 0 : rounded;
};

/**
 * Round down a number
 * Currently the floor function doesn't work correctly with negative numbers, also handles -0 values
 * @param {Float} num the numbers that needs to roundDown
 */
coned.utils.roundDown = function (num) {
    var rounded = num >= 0 ? Math.floor(num) : Math.ceil(num);

    // Converts -0 to 0
    return rounded === -0 ? 0 : rounded;
};

/**
 * Checks if the element has an ellipsis
 * @param {Element} $element The element to evaluate
 * @return {Boolean} confirmation if the element has ellipsis
 */
coned.utils.isEllipsisActive = function ($element) {
    return $element.offsetWidth < $element.scrollWidth;
};

/**
 * Checks if the element has a line Clamp
 * Using
 * https://github.com/josephschmitt/Clamp.js/
 * installed in:
 * source\js\vendor\clamp.js
 * Element should have one of the following attributes for clamp to be set for a specific media query
 *  data-ce-line-clamp-small="{MaxNumberOfLines}"
 *  data-ce-line-clamp-medium="{MaxNumberOfLines}"
 *  data-ce-line-clamp-Large="{MaxNumberOfLines}"
 * @param {Element} $element The element to clamp to a maximum text lines.
 */
coned.utils.lineClamp = function ($element) {
    // Will set and apply
    var currentClampLimit;

    // mobile first
    if ($element.dataset && $element.dataset.ceLineClampSmall) {
        // data-ce-line-clamp-small || default no clamp
        currentClampLimit = $element.dataset.ceLineClampSmall;
    }

    if (coned.utils.isTablet() && $element.dataset && $element.dataset.ceLineClampMedium) {
        // data-ce-line-clamp-medium
        currentClampLimit = $element.dataset.ceLineClampMedium;
    }

    if (coned.utils.isLargeDesktop() && $element.dataset && $element.dataset.ceLineClampLarge) {
        // data-ce-line-clamp-large
        currentClampLimit = $element.dataset.ceLineClampLarge;
    }

    if (currentClampLimit) {
        $element.innerHTML = $element.innerHTML.trim();
        $element.innerHTML =
            $clamp($element, { clamp: currentClampLimit }).clamped || $element.innerHTML;
    }
};

/**
 * Convert local date to UTC Date
 * @param {String}  date input value to convert to UTC
 * @param {Boolean}  includeTime flag
 * @return {String} UTC date converted in String: MM/DD/YYYY HH:MM:SS
 */
coned.utils.localDateToUTC = function (inputDate, includeTime) {
    if (inputDate == '') return '';

    var localDate = new Date(inputDate),
        utcMonth = localDate.getUTCMonth(),
        utcDay = localDate.getUTCDate(),
        utcYear = localDate.getUTCFullYear(),
        utcHours = localDate.getUTCHours(),
        utcMinutes = localDate.getUTCMinutes(),
        utcSeconds = localDate.getUTCSeconds(),
        utcDate,
        utcTime = '';

    // The months start from 0 so we add + 1
    utcMonth = utcMonth + 1;

    if (includeTime || includeTime == undefined) {
        if (utcHours < 10) {
            // Add '0' at the beginning
            utcHours = '0' + utcHours;
        }

        if (utcMinutes < 10) {
            // Add '0' at the beginning
            utcMinutes = '0' + utcMinutes;
        }

        if (utcSeconds < 10) {
            // Add '0' at the beginning
            utcSeconds = '0' + utcSeconds;
        }

        utcTime = ' ' + utcHours + ':' + utcMinutes + ':' + utcSeconds;
    }

    utcDate = utcMonth + '/' + utcDay + '/' + utcYear + utcTime;

    return utcDate;
};

/**
 * get url query parameters as object
 * @return {Object} URL parameters in an object.
 */
coned.utils.getUrlParameters = function () {
    var queryString = window.location.search.substring(1),
        regexReplace = /\+/g, // For addition symbol with a space
        regexSearch = /([^&=]+)=?([^&]*)/g,
        regexMatch = regexSearch.exec(queryString),
        urlParameters = {},
        key,
        value;

    while (regexMatch != null) {
        key = coned.utils.stringDecode(regexMatch[1], regexReplace);
        value = coned.utils.stringDecode(regexMatch[2], regexReplace);
        regexMatch = regexSearch.exec(queryString);
        urlParameters[key] = value;
    }
    return urlParameters;
};

/**
 * Decodes a strings
 * @return {Object} decoded string
 */
coned.utils.stringDecode = function (string, replaceRegex) {
    return decodeURIComponent(string.replace(replaceRegex, ' '));
};

/**
 * Decodes any HTML Entities on a string
 * @param {String} string String to decode
 * @return {Object} Decoded string
 */
coned.utils.entitiesDecode = function (string) {
    var elem = document.createElement('textarea');

    elem.innerHTML = string;
    return elem.value;
};

/**
 * Checks and assigns correct classes to inputs if they are filled before js is able to validate
 * @param {HTMLElement} $form Form containing all elements to be checked
 */
coned.utils.checkInputsFilled = function ($form) {
    $($form)
        .find('.' + coned.constants.CONED_INPUT)
        .each(function () {
            if ($(this).val()) {
                $(this).addClass(coned.constants.INPUT_FILLED_CLASS);
                $(this).valid();
            }
        });

    $($form)
        .find('.' + coned.constants.CONED_TEXT_AREA)
        .each(function () {
            if ($(this).val()) {
                $(this).addClass(coned.constants.TEXT_AREA_FILLED_CLASS);
                $(this).valid();
            }
        });

    $($form)
        .find('.' + coned.constants.CONED_CHECKBOX)
        .each(function () {
            if ($(this).is(':checked')) {
                $(this).parent().addClass(coned.constants.CHECKBOX_INPUT_FILLED);
                $(this).valid();
            }
        });

    $($form)
        .find('.' + coned.constants.CONED_SELECT)
        .each(function () {
            if ($(this).val()) {
                $(this).addClass(coned.constants.SELECT_INPUT_FILLED);
                $(this).addClass(coned.constants.VALID_CLASS);

                $(this).siblings('label').css({
                    display: 'block',
                    top: '16',
                    opacity: '1'
                });

                $(this).valid();
            }
        });
};

/**
 * Fixes issue where file inputs erase past selection when file selector is opened
 * @param {HTMLElement} $form Form containing all data to be submitted
 * @return {FormData} Fixed form data with correct file array
 */
coned.utils.formDataFileInputFix = function ($form) {
    var formData = new FormData($form),
        $inputs = $form.querySelectorAll('input[type="file"]:not(:disabled):not([name=""])');

    // Creates all form data from scratch
    _.each($inputs, function ($input) {
        formData.delete($input.name);

        _.each($input.fileList, function ($file) {
            formData.append($input.name, $file);
        });
    });

    return formData;
};

/**
 * sets url query parameters from object
 * @param {Object} params Array with parameters
 */
coned.utils.setUrlParameters = function (params) {
    var searchParamsArray = [],
        searchParamsString,
        pathname;

    searchParamsArray.push(window.location.pathname);
    searchParamsArray.push('?');

    if (params) {
        for (var key in params) {
            // check if the property/key is defined in the object itself, not in parent
            if (params.hasOwnProperty(key) && params[key]) {
                searchParamsArray.push(key);
                searchParamsArray.push('=');
                searchParamsArray.push(params[key]);
                searchParamsArray.push('&');
            }
        }
    }

    searchParamsArray.pop();
    searchParamsString = searchParamsArray.join('');
    pathname = searchParamsString.replace(window.location.origin, '');
    window.history.pushState({ page: searchParamsString }, '', pathname);
};

/**
 * converts string camelCase
 * @param {string} string Array with parameters
 * @return {string} camelCase value of string
 */
coned.utils.toCamelCase = function (string) {
    return string.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
    });
};

/**
 * converts string to hyphen-case from camelCase
 * @param {string} string Array with parameters
 * @return {string} camelCase value of string
 */
coned.utils.toHyphenCase = function (string) {
    return string.replace(/([a-z][A-Z])/g, function (g) {
        return g[0] + '-' + g[1].toLowerCase();
    });
};

/**
 * Insert or replace a string at index position
 * @param {String} originalString Original string to be modified
 * @param {String} addedString String to be inserted
 * @param {Number} index Position where new string will be inserted, if replace is false it will be appended after index
 * @param {Boolean} replace Set it to true to replace the string at index
 * @return {String} New string with insertion or replacement
 */
coned.utils.insertStringAtIndex = function (originalString, addedString, index, replace) {
    replace = replace || false;

    if (!originalString || index > originalString.length - 1) return

    return originalString.replace(/./g, function (char, i) {
        if (i == index) {
            if (!replace) {
                return char + addedString;
            } else {
                return addedString;
            }
        }

        return char;
    });
};

/**
 * Trigger qualtrics survey appearance by adding class and re-loading the API
 * @param {Element} $container Element where the trigger class will be added
 */
coned.utils.qualtricsTriggering = function ($container) {
    if (typeof QSI !== 'undefined') {
        $container.classList.add(coned.constants.QUALTRICS_TRIGGER_CLASS);

        QSI.API.unload();
        QSI.API.load().done(QSI.API.run());
    }
};

coned.utils.changeListType = function ($countrySelector, $newType) {
    _.each($countrySelector, function (optionElement) {
        // create list element to replace it for current option element
        var listElement = document.createElement($newType);
        var index;

        // Copy the children from the option element and set it to the new li element
        while (optionElement.firstChild) {
            listElement.appendChild(optionElement.firstChild); // *Moves* the child
        }

        // Copy attributes from the option element and set them to the li element
        for (index = optionElement.attributes.length - 1; index >= 0; --index) {
            listElement.attributes.setNamedItem(optionElement.attributes[index].cloneNode());
        }

        // Replace the option element for the li element
        optionElement.parentNode.replaceChild(listElement, optionElement);
    });
};

/**
 * Processes data on threads to avoid bottling a single thread
 * @param {Array} data Data to be processed
 * @param {Function} handler Handles what to do with the data
 * @param {Function} callback Function to call after the data has been processed
 */
coned.utils.processLargeData = function (data, handler, callback) {
    var maxtime = 100, // chunk processing time
        delay = 20, // delay between processes
        queue =
            data && data.length
                ? Array.isArray(data)
                    ? data.concat()
                    : _.toArray(data).concat()
                : null; // clone original array

    if (!queue) {
        if (callback) callback();
        return;
    }

    setTimeout(function () {
        var endtime = +new Date() + maxtime;

        do {
            handler(queue.shift());
        } while (queue.length > 0 && endtime > +new Date());

        if (queue.length > 0) {
            setTimeout(arguments.callee, delay);
        } else {
            if (callback) callback();
        }
    }, delay);
};

/**
 * Create a key event for the given element
 * @param {element} element element to be handled
 */
coned.utils.addKeyEvent = function (element, keycode, callback, allowMobile, allowDesktop) {
    var keyCode = keycode,
        allowEventMobile = allowMobile !== undefined ? allowMobile : true,
        allowEventDesktop = allowDesktop !== undefined ? allowDesktop : true;

    function triggerEvent(e) {
        var isKeyPressed = e.keyCode === keyCode;

        if (!isKeyPressed) {
            return;
        } else {
            callback(e, element);
        }
    }

    function onkeyPresssed(e) {
        if (allowEventMobile && allowEventDesktop) {
            triggerEvent(e);
        } else if (!allowEventMobile && allowEventDesktop) {
            coned.utils.isDesktop() && triggerEvent(e);
        } else if (allowEventMobile && !allowEventDesktop) {
            coned.utils.isMobile() && triggerEvent(e);
        }
    }

    element.addEventListener('keydown', function (e) {
        onkeyPresssed(e);
    });
};

/**
 * Create a focus trap for the given element
 * @param {element} element element to be handled
 */
coned.utils.addFocusTrap = function (
    element,
    allowMobile,
    allowDesktop,
    forcedFirstElement,
    forcedLastElement
) {
    var focusableEls = element.querySelectorAll(coned.constants.FOCUSABLE_ELEMENTS_QUERY),
        addTrapMobile = allowMobile !== undefined ? allowMobile : true,
        addTrapDesktop = allowDesktop !== undefined ? allowDesktop : true;

    // If there is a forcedElement add it to the focusable Elements list.
    focusableEls = Array.prototype.slice.call(focusableEls);
    forcedFirstElement && focusableEls.unshift(forcedFirstElement);
    forcedLastElement && focusableEls.push(forcedLastElement);

    var firstFocusableEl = focusableEls[0],
        lastFocusableEl = focusableEls[focusableEls.length - 1];

    var trapFocus = function (e) {
        var isTabPressed = e.key === 'Tab' || e.keyCode === coned.constants.KEY_CODE.TAB,
            forwardCounter = 0,
            backwardsCounter = focusableEls.length - 1;

        if (isTabPressed && backwardsCounter === forwardCounter) {
            e.preventDefault();
            return;
        }

        // Resetting last focusable element
        lastFocusableEl = focusableEls[backwardsCounter--];

        // Look for the last enabled button in case of form type popups
        if (lastFocusableEl.disabled || !coned.utils.isDomVisible(lastFocusableEl)) {
            while (
                backwardsCounter >= 0 &&
                (lastFocusableEl.disabled || !coned.utils.isDomVisible(lastFocusableEl))
            ) {
                lastFocusableEl = focusableEls[backwardsCounter--];
            }
        }

        // Resetting first focusable element
        firstFocusableEl = focusableEls[forwardCounter++];

        // Look for the first enabled/visible button in case of form type popups, or different mobile/desktop layout
        if (firstFocusableEl.disabled || !coned.utils.isDomVisible(firstFocusableEl)) {
            while (
                forwardCounter >= 0 &&
                (firstFocusableEl.disabled || !coned.utils.isDomVisible(firstFocusableEl))
            ) {
                firstFocusableEl = focusableEls[forwardCounter++];
            }
        }

        if (!isTabPressed || backwardsCounter < 0 || forwardCounter >= focusableEls.length) {
            return;
        }

        if (e.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    };

    var onFocusSelected = function (e) {
        if (addTrapMobile && addTrapDesktop) {
            trapFocus(e);
        } else if (!addTrapMobile && addTrapDesktop) {
            coned.utils.isDesktop() && trapFocus(e);
        } else if (addTrapMobile && !addTrapDesktop) {
            coned.utils.isMobile() && trapFocus(e);
        }
    };

    element.addEventListener('keydown', onFocusSelected);

    if (forcedFirstElement) {
        firstFocusableEl.addEventListener('keydown', onFocusSelected);
    }

    return {
        onFocusSelected: onFocusSelected
    };
};
/**
 * Log out event
 */
coned.utils.logout = function (logoutUrl, redirectUrl) {
    var req = new XMLHttpRequest();

    req.open('DELETE', logoutUrl);
    req.withCredentials = true;
    req.setRequestHeader('Accept', 'application/json');
    req.send(null);

    window.location.href = redirectUrl;
};

/**
 * Set mouse user when the user clicks
 */
coned.utils.setInputUser = function () {
    var $body = document.querySelector('body');

    window.addEventListener('keydown', function () {
        if (query.hasClass($body, coned.constants.MOUSE_USER)) {
            query.removeClass($body, coned.constants.MOUSE_USER);
        }
    });
    window.addEventListener(
        'mousedown',
        function () {
            if (!query.hasClass($body, coned.constants.MOUSE_USER)) {
                query.addClass($body, coned.constants.MOUSE_USER);
            }

            var $focusCard = document.getElementsByClassName(coned.constants.CARD_FOCUS_CLASS);
            if ($focusCard.length > 0) {
                query.removeClass($focusCard, coned.constants.CARD_FOCUS_CLASS);
            }
        },
        true
    );
};

/**
 * Simulate a mouse event.
 * @public
 * @param {Element} elem  the element to simulate a click on
 * @param {EventElement} eventName name of mouse action
 */
coned.utils.simulateEvent = function (elem, eventName) {
    // Create our event (with options)
    var evt = new MouseEvent(eventName, {
        bubbles: true,
        cancelable: true,
        view: window
    });
    // If cancelled, don't dispatch our event
    !elem.dispatchEvent(evt);
};

/**
 * Focus the first input field of the given form.
 */
coned.utils.focusFirstFormInputField = function ($form, elementClass) {
    var $focusableElements = $form.querySelectorAll(coned.constants.FOCUSABLE_ELEMENTS_QUERY);

    for (var index = 0; index < $focusableElements.length; index++) {
        var $focusableElement = $focusableElements[index];

        if (!coned.utils.isElementHidden($focusableElement)) {
            if (elementClass) {
                if ($focusableElement.classList.contains(elementClass)) {
                    $focusableElement.focus();
                    break;
                }
            } else {
                $focusableElement.focus();
                break;
            }
        }
    }
};

/**
 * Focus the first focusable element from the next sibling.
 * If there is no next sibling or it has no focusable elements, focus last element from current section.
 * @param {HTMLElement} $currentSectionAncestor Ancestor element for the current section, previous sibling of the section you want to focus on.
 */
coned.utils.focusFirstFocusableElementNextSibling = function ($currentSectionAncestor) {
    var $nextSibling = $currentSectionAncestor && $currentSectionAncestor.nextElementSibling,
        $nextSiblingFocusableElements = $nextSibling && $nextSibling.querySelectorAll(coned.constants.FOCUSABLE_ELEMENTS_QUERY),
        $nextSiblingFocusableElementsVisible = $nextSiblingFocusableElements && _.filter($nextSiblingFocusableElements, function ($element) {
            return !query.checkElementOrParentDisplayNone($element);
        }),
        $nextSiblingFirstFocusableElement = $nextSiblingFocusableElementsVisible && $nextSiblingFocusableElementsVisible[0],
        $currentSectionFocusableElements = $currentSectionAncestor && $currentSectionAncestor.querySelectorAll(coned.constants.FOCUSABLE_ELEMENTS_QUERY),
        $currentSectionFocusableElementsVisible = $currentSectionFocusableElements && _.filter($currentSectionFocusableElements, function ($element) {
            return !query.checkElementOrParentDisplayNone($element)
        }),
        $currentSectionLastFocusableElement = $currentSectionFocusableElementsVisible && $currentSectionFocusableElementsVisible[$currentSectionFocusableElementsVisible.length - 1];

    if ($nextSibling && $nextSiblingFirstFocusableElement) {
        $nextSiblingFirstFocusableElement.focus();
    } else {
        $currentSectionLastFocusableElement && $currentSectionLastFocusableElement.focus();
    }
};

/**
 * Generate a random ID with next format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
 */
coned.utils.generateUUID = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

/**
 /* Triggered when element is nearly to be visible using IntersectionObserver
 */
coned.utils.initLazyLoadingIO = function (element, callbackFn) {
    var options = {
        root: null,
        rootMargin: '0px'
    };

    if ('IntersectionObserver' in window) {
        var lazyElementObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var lazyElement = entry.target;

                    if (callbackFn) {
                        callbackFn(lazyElement);
                    }

                    lazyElementObserver.unobserve(lazyElement);
                }
            });
        }, options);

        lazyElementObserver.observe(element);
    }
};

/*
* Read the url parameters and execute the action function
* @param {string} actionName
* @param {function} actionCallback
*/
coned.utils.doActionByURLParam = function (actionName, actionCallback) {
    var urlParams = coned.utils.getUrlParameters(),
        actionParam = urlParams[coned.constants.DEEP_LINK_URL_PARAM_ACTION];

    (actionParam === actionName) && actionCallback();
};

/**
 * Throttles the on scroll event for better performance so the callback is executed at a slower rate.
 * @param {Function} callback Callback function that will be executed after throttle.
 * @param {Number} delay Delay time for callback to be called. If omitted it defaults to 66 (15fps).
 */
coned.utils.onScrollThrottler = function (callback, delay) {
    var _resizeTimeout,
        _delay = delay ? delay : 66;

    window.addEventListener('scroll', function () {
        if (!_resizeTimeout) {
            _resizeTimeout = setTimeout(function () {
                _resizeTimeout = null;

                callback();
                // The functions will execute at a rate of (1000/delay)fps
            }, _delay);
        }
    });
};

/**
 * Throttles the on resize event for better performance so the callback is executed at a slower rate.
 * @param {Function} callback Callback function that will be executed after throttle.
 * @param {Number} delay Delay time for callback to be called. If omitted it defaults to 66 (15fps).
 */
coned.utils.onResizeThrottler = function (callback, delay) {
    var _resizeTimeout,
        _delay = delay ? delay : 66;

    window.addEventListener('resize', function () {
        if (!_resizeTimeout) {
            _resizeTimeout = setTimeout(function () {
                _resizeTimeout = null;

                callback();
                // The functions will execute at a rate of (1000/delay)fps
            }, _delay);
        }
    });
};

/**
 * Ignore resize events as long as an actual resize handler execution is in the queue.
 * Get CSS font size value of text element at current window size and call the callback function.
 * To be used with coned.utils.updateFontToFitAncestor as the callback.
 * @param {Function} callback Callback function that will be executed after throttle.
 * @param {HTMLElement} $textElement Element that contains text whose font size we want to adjust.
 * @param {HTMLElement} $ancestor Ancestor container element that should not be overflown.
 */
coned.utils.updateFontResizeThrottler = function (callback, $textElement, $ancestor) {
    var fontSize,
        _resizeTimeout;
    window.addEventListener('resize', function () {
        if (!_resizeTimeout) {
            _resizeTimeout = setTimeout(function () {
                _resizeTimeout = null;
                // Remove inline font-size so we can then get the actual CSS size at current breakpoint
                $textElement.style.fontSize = '';
                // CSS font-size at current breakpoint, this is important as it will
                // set the max font size relying on the CSS media query
                fontSize = parseInt(
                    window
                        .getComputedStyle($textElement, null)
                        .getPropertyValue('font-size')
                )
                callback($textElement, $ancestor, fontSize);
                // The functions will execute at a rate of 1fps
            }, 1000);
        }
    })
};

/**
 * Main functionality is to decrease font size of descendant element until it fits the ancestor's width (if it overflows).
 * Will increase font size if window is resized up, to revert decrement, but never more than the CSS set value.
 * Should be called as is on init, and also as a callback on coned.utils.updateFontResizeThrottler.
 * @param {HTMLElement} $textElement Element that contains text whose font size we want to adjust.
 * @param {HTMLElement} $ancestor Ancestor container element that should not be overflown.
 * @param {Number} fontSize CSS font-size at current breakpoint that will serve as a max limit.
 */
coned.utils.updateFontToFitAncestor = function ($textElement, $ancestor, fontSize) {
    if (
        !($textElement && $ancestor && fontSize) ||
        !coned.utils.isDomVisible($ancestor)
    ) {
        return
    }

    var fontSizeLimit = fontSize,
        currentFontSize = parseInt(
            window.getComputedStyle($textElement, null).getPropertyValue('font-size')
        ),
        textElementWidth = $textElement.offsetWidth,
        ancestorWidth = $ancestor.offsetWidth,
        ancestorsSidePadding;

    // Get the sum of side paddings from the element and all of its ancestors
    var getAllSidePadding = function ($element) {
        var sidePaddingTotal = 0,
            elementLeftPadding,
            elementRightPadding;

        while ($element != null) {
            elementLeftPadding = parseInt(
                window
                    .getComputedStyle($element, null)
                    .getPropertyValue('padding-left')
            );
            elementRightPadding = parseInt(
                window
                    .getComputedStyle($element, null)
                    .getPropertyValue('padding-right')
            );
            sidePaddingTotal += elementLeftPadding + elementRightPadding;

            $element = $element.parentElement;
        }

        return sidePaddingTotal;
    };

    ancestorsSidePadding = getAllSidePadding($textElement);

    // Decrease font size to fit on ancestor container
    if (textElementWidth > (ancestorWidth - ancestorsSidePadding)) {
        while (
            textElementWidth > (ancestorWidth - ancestorsSidePadding)
        ) {
            currentFontSize = parseInt(
                window
                    .getComputedStyle($textElement, null)
                    .getPropertyValue('font-size')
            ) - 1;
            $textElement.style.fontSize = currentFontSize + 'px';
            textElementWidth = $textElement.offsetWidth;
            ancestorWidth = $ancestor.offsetWidth;
        }

        $textElement.style.fontSize = (currentFontSize / 10) + 'rem';
        // Increase font size if smaller than current CSS font-size value
    } else if (
        (textElementWidth < (ancestorWidth - ancestorsSidePadding)) &&
        (fontSizeLimit > currentFontSize)
    ) {
        while (
            (textElementWidth < (ancestorWidth - ancestorsSidePadding)) &&
            (fontSizeLimit > currentFontSize)
        ) {
            currentFontSize = parseInt(
                window
                    .getComputedStyle($textElement, null)
                    .getPropertyValue('font-size')
            ) + 1;
            $textElement.style.fontSize = currentFontSize + 'px';
            textElementWidth = $textElement.offsetWidth;
            ancestorWidth = $ancestor.offsetWidth;
        }

        $textElement.style.fontSize = (currentFontSize / 10) + 'rem';
    }
};

/**
 * Add tagging to radio buttons.
 * @param {HTMLElement} $radioLabel Radio button group's label
 * @param {Array} radioButtonsArr Array containing all radio buttons from radio group.
 * @param {Array} radioButtonsArrValues [optional] Array containing custom values for all radio buttons from radio group. Array positions must map to the array radioButtonsArr.
 */
coned.utils.addRadioButtonsTagging = function ($radioLabel, radioButtonsArr, radioButtonsArrValues) {
    if (!($radioLabel && radioButtonsArr)) { return }

    var radioQuestion = $radioLabel.innerText,
        radioSelectedValue;

    for (var index = 0; index < radioButtonsArr.length; index++) {
        if (radioButtonsArr[index].checked) {
            if (radioButtonsArrValues.length) {
                radioSelectedValue = radioButtonsArrValues[index];
            } else {
                radioSelectedValue = radioButtonsArr[index].value;
            }
            break;
        }
    }

    // Analytics data building
    dataLayer.push({
        event: 'radio.button.selection',
        'radio.button.question': radioQuestion,
        'radio.button.selected': radioSelectedValue
    });
};

/**
 * Helper Function to toggle boolean attributes
 * Helper Function to toggle boolean attributes
 * @param { HTMLElement | null } $element
 * @param { string } attribute
 */
coned.utils.toggleAttributes = function ($element, attribute) {
    if ($element) {
        $element.setAttribute(attribute, $element.getAttribute(attribute) === coned.constants.TRUE
            ? coned.constants.FALSE : coned.constants.TRUE);
    }
}
/**
 * Helper function to check if component is overflow;
 * @param {HTMLElement} $element
 * @returns
 */
coned.utils.isOverflowingWidth = function ($element) {
    return $element.clientWidth < $element.scrollWidth;
}
/**
 * This function formats a string by replacing placeholders (e.g., {0}, {1}) with the corresponding values.
 * 
 * @param {string} format - The string containing placeholders to be replaced by values.
 * @param {string[]} values - An array of strings that will replace the placeholders in the format string.
 * @returns {string} - The formatted string with placeholders replaced by the provided values.
 */
coned.utils.formatString = function (format, values) {
    // Check if both 'format' and 'values' have content (non-empty)
    if (format.length > 0 && values.length > 0) {
        // Create a copy of the 'format' string to manipulate
        var formatted = "" + format;

        // Iterate over the 'values' array
        values.forEach(function (el, index) {
            // Create a placeholder string, like '{0}', '{1}', etc., based on the current index
            var formatKey = '{' + index + '}',

                // Check if the placeholder exists in the formatted string
                isInFormatted = formatted.includes(formatKey);

            // If the placeholder exists in the format string, replace it with the corresponding value
            if (isInFormatted) {
                formatted = formatted.replace(formatKey, el);
            }
        });

        // Return the fully formatted string with all placeholders replaced
        return formatted;
    }

    // If the 'format' or 'values' is empty, return format param
    return format;
}
/**
 * Helper Function to determine if an element is focusable or not
 * @param {HTMLElement} $element
 * @returns {boolean}
 */
coned.utils.isFocusableElement = function ($element) {
    var tabindex = $element.getAttribute(coned.constants.NOT_FOCUSABLE.TABINDEX),
        style = window.getComputedStyle($element),
        tagName = $element.tagName.toLowerCase(),
        role = $element.getAttribute('role');
    if (
        $element.classList.contains(coned.constants.NOT_FOCUSABLE.DISABLED) ||
        $element.classList.contains(coned.constants.NOT_FOCUSABLE.HIDDEN) ||
        $element.hasAttribute(coned.constants.NOT_FOCUSABLE.DISABLED) ||
        $element.getAttribute(coned.constants.ARIA.HIDDEN) === 'true'
    ) {
        return false;
    }
    if (tabindex !== null && parseInt(tabindex, 10) < 0) {
        return false;
    }
    if (style.display === coned.constants.NOT_FOCUSABLE.DISPLAY_NONE ||
        style.visibility === coned.constants.NOT_FOCUSABLE.HIDDEN) {
        return false;
    }
    // If the element has a role that requires tabindex=0 to be focusable, and it's not a naturally focusable element
    if (role !== null && coned.constants.NOT_FOCUSABLE.FOCUSABLE_ROLES.includes(role) &&
        !coned.constants.NOT_FOCUSABLE.FOCUSABLE_TAGS.includes(tagName)) {
        return tabindex === coned.constants.NOT_FOCUSABLE.TABINDEX_ENABLE; // Only focusable if tabindex="0"
    }
    return (
        coned.constants.NOT_FOCUSABLE.FOCUSABLE_TAGS.includes(tagName) ||
        (tabindex !== null && parseInt(tabindex, 10) >= 0)
    );
};
/**
 *
 * @param {"prev" | "next"} order
 * @param {HTMLElement} $target
 * @param {HTMLElement[]} $focusableList
 */
coned.utils.handleNextPrevFocus = function (order, $target, $focusableList) {
    var index = $focusableList.indexOf($target);
    if (index === -1) {
        if (order === coned.constants.ORDER.NEXT) {
            $focusableList[0].focus();
        }
        else {
            $focusableList[$focusableList.length - 1].focus();
        }
    } else {
        if (order === coned.constants.ORDER.NEXT) {
            if (index + 1 >= $focusableList.length) {
                $focusableList[0].focus();
            }
            else {
                $focusableList[index + 1].focus();
            }
        }
        else {
            if (index - 1 < 0) {
                $focusableList[$focusableList.length - 1].focus();
            } else {
                $focusableList[index - 1].focus();
            }
        }
    }
}
/**
 * This function calculates the next or previous focusable element based on the current focused element
 * and optionally allows looping when the focus reaches the beginning or end of the focusable elements.
 *
 * @param {Object} params - An object containing the parameters for the function.
 * @param {"prev" | "next"} params.order - The direction to move the focus ('prev' to move backward, 'next' to move forward).
 * @param {HTMLElement | null} [params.$container] - If provided, limits the search for focusable elements within a specific container.
 * @param {boolean} [params.canLoop] - Optional boolean indicating if focus should loop back to the first element when moving forward from the last, or to the last when moving backward from the first.
 * @param {HTMLElement} [params.target] - Optional target instead activeElement
 * @returns {HTMLElement | null} - The next or previous focusable element, or null if no focusable elements are found.
 */
coned.utils.getNextPrevFocus = function (params) {
    // Destructure parameters with default values, using 'var' to comply with the restriction.
    var order = params.order,
        $container = params.$container instanceof HTMLElement ? params.$container : undefined,  // Optional $container, default to undefined
        canLoop = typeof params.canLoop === 'boolean' ? params.canLoop : false,
        $target = params.target ? params.target : document.activeElement instanceof HTMLElement ? document.activeElement : null,
        $focusableElements = coned.utils.arrayFrom(
            $container ? $container.querySelectorAll(coned.constants.FOCUSABLE_ELEMENTS_DROPDOWN_MODAL_QUERY) :
                document.querySelectorAll(coned.constants.FOCUSABLE_ELEMENTS_DROPDOWN_MODAL_QUERY)
        ),
        currentIndex = $target ? $focusableElements.indexOf($target) : -1,
        isLastElement = currentIndex === $focusableElements.length - 1,
        isFirstElement = currentIndex === 0,
        hasMultipleElements = $focusableElements.length > 1;

    // Ensure the order is valid or have focusable elements
    if ((order !== 'next' && order !== 'prev') || $focusableElements.length === 0) {
        return null;
    }

    // Early exit for edge cases
    if (currentIndex < 0 || !hasMultipleElements) {
        return $focusableElements[0];  // Return first element if nothing is focused or only one element exists
    }

    // Handle looping logic for "prev" and "next" focus order
    if (order === 'prev') {
        return (isFirstElement && canLoop) ? $focusableElements[$focusableElements.length - 1] : $focusableElements[currentIndex - 1];
    }
    return (isLastElement && canLoop) ? $focusableElements[0] : $focusableElements[currentIndex + 1];
};

/**
 * Converts a collection (HTMLCollection or NodeList) to an array.
 *
 * @param {HTMLCollectionOf<HTMLElement> | NodeListOf<HTMLElement>} collection
 * @returns {HTMLElement[]}
 */
coned.utils.arrayFrom = function (collection) {
    return Array.prototype.slice.call(collection);
};

/**
 * Builds a record (object) where each key maps to a string value,
 * based on a semicolon-separated input string. Each key-value pair is separated by a colon.
 *
 * Example input: "key1:value1;key2:value2"
 * Example output: { "key1": "value1", "key2": "value2" }
 *
 * @param {string} recordString - The input string that contains key-value pairs, 
 * separated by semicolons (`;`), where each pair is in the form `key:value`.
 * @returns {Record<string, string>} - Returns an object where each key is mapped to a string value.
 */
coned.utils.buildRecordString = function (recordString) {
    // Split the input string by semicolons to get individual key-value pairs
    var keysValues = recordString.split(coned.constants.SEMICOLON);

    /**
     * Reduces the array of key-value strings into an object where each key maps to a string value.
     * 
     * @param {Record<string, string>} acc - The accumulator object being built in the reduce function.
     * @param {string} value - A string representing a key-value pair in the format `key:value`.
     * @returns {Record<string, string>} - The updated accumulator object after processing the current key-value pair.
     */
    var reduceFunction = function (acc, value) {
        // Split each key-value pair by a colon to separate the key from the value
        var keyValue = value.split(coned.constants.COLON);

        // Assign the value to the corresponding key in the accumulator object
        acc[keyValue[0]] = keyValue[1];
        return acc;
    }

    // Use the reduce function to transform the array of key-value pairs into the final object
    return keysValues.reduce(reduceFunction, {});
}

/**
 * Builds a record (object) where each key maps to an array of strings, 
 * based on a semicolon-separated input string. Each key-value pair is separated by a colon, 
 * and values are separated by commas.
 *
 * Example input: "key1:value1,value2;key2:value3,value4"
 * Example output: { "key1": ["value1", "value2"], "key2": ["value3", "value4"] }
 *
 * @param {string} recordString - The input string that contains key-value pairs, 
 * separated by semicolons (`;`) and with each key-value pair in the form `key:value1,value2,...`.
 * @returns {Record<string, string[]>} - Returns an object where each key maps to an array of strings.
 */
coned.utils.buildRecordStringArray = function (recordString) {
    // Split the input string by semicolons to get individual key-value pairs
    var keysValues = recordString.split(coned.constants.SEMICOLON);

    /**
     * Reduces the array of key-value strings into an object with arrays as values.
     * 
     * @param {Record<string, string[]>} acc - The accumulator object being built in the reduce function.
     * @param {string} value - A string representing a key-value pair in the format `key:value1,value2,...`.
     * @returns {Record<string, string[]>} - The updated accumulator object after processing the current key-value pair.
     */
    var reduceFunction = function (acc, value) {
        // Split each key-value pair by a colon to separate the key from the value string
        var keyValue = value.split(coned.constants.COLON);

        // Split the value string by commas and assign the resulting array to the key in the accumulator
        acc[keyValue[0]] = keyValue[1].split(coned.constants.COMMA);
        return acc;
    }

    // Use the reduce function to transform the array of key-value pairs into the final object
    return keysValues.reduce(reduceFunction, {});
}
/**
 * Checks if the `current` record is identical to the `same` record in both structure and values.
 *
 * This function compares two records (objects) to determine if they are exactly the same, meaning they have the same keys and corresponding values. If a property in either record is an object, the function will recursively compare the nested objects. It can be extended to handle arrays or other specific types as needed.
 *
 * @param {Record<string, any>} current - The current record to be compared. This is the object containing the data you want to verify.
 * @param {Record<string, any>} same - The record that serves as a reference to ensure `current` has the same structure and values.
 * @returns {boolean} - Returns `true` if the `current` record is identical to the `same` record in both structure and values; otherwise, returns `false`.
 */
coned.utils.isSameRecord = function (current, same) {
    var keysSame = Object.keys(same),
        keysCurrent = Object.keys(current),
        isSame = keysSame.length === keysCurrent.length;
    while (isSame && keysSame.length) {
        var key = keysSame.shift();
        if (typeof current[key] === 'object') {
            //TIP implement Array.isArray(current[key]) comparison if needed in a other modules
            //line below only is for objects with primitive types (boolean, number, string, ...)
            isSame = coned.utils.isSameRecord(current[key], same[key]);
        } else {
            isSame = current[key] === same[key];
        }

    }
    return isSame;
}

/**
 * Checks if the provided `current` record matches the structure and values of the `match` record.
 *
 * This function compares two records (objects) to determine if all the properties in the `match` record exist in the `current` record with the same values. If a property in `match` is an object, the function will recursively compare the nested objects. It can be extended to handle arrays or other specific types as needed.
 *
 * @param {Record<string, any>} current - The current record to be checked against. This is the object that contains the data you want to verify.
 * @param {Record<string, any>} match - The record containing the structure and values you want to match. This is the object that acts as a reference to ensure `current` contains the same key-value pairs.
 * @returns {boolean} - Returns `true` if the `current` record matches the `match` record; otherwise, returns `false`.
 */
coned.utils.isMatchedRecord = function (current, match) {
    var keysMatch = Object.keys(match),
        keysCurrent = Object.keys(current),
        isMatched = keysMatch.length <= keysCurrent.length;
    while (isMatched && keysMatch.length) {
        var key = keysMatch.shift();
        if (typeof current[key] === 'object') {
            var objMatch = match[key],
                objCurrent = current[key];
            if (Array.isArray(objMatch) && Array.isArray(objCurrent)) {
                var shiftMatch = objMatch.concat(),
                    checkCurrent = objCurrent.concat();
                isMatched = shiftMatch.length <= checkCurrent.length;
                while (isMatched && shiftMatch.length > 0) {
                    var shiftItem = shiftMatch.shift();
                    if (typeof shiftItem === 'string') {
                        isMatched = checkCurrent.includes(shiftItem);
                    }
                    // TIP implements when shiftItem is an Object
                }
            }
            //line below only is for objects with primitive types (boolean, number, string, ...)
            isMatched = coned.utils.isMatchedRecord(current[key], match[key]);
        } else {
            isMatched = current[key] === match[key];
        }
    }
    return isMatched;
}

/**
 * Checks if a record contains at least one value from the selected records.
 * It iterates through all the keys of the selected records and ensures that
 * at least one value in each key matches the values in the record.
 *
 * @param {Record<string, string[]>} itemRecords - An object representing the item's records, where the keys are record names, and the values are arrays of strings.
 * @param {Record<string, string[]>} selectedRecords - An object representing the selected records for filtering, with keys as record names and values as arrays of strings to match.
 * 
 * @returns {boolean} - Returns `true` if all the selected records have at least one matching value in the object, otherwise returns `false`.
 */
coned.utils.hasMatchingRecord = function (itemRecords, selectedRecords) {
    return Object.keys(selectedRecords).every(function (recordKey) {
        // Check if the object contains the current record
        if (itemRecords[recordKey]) {
            // Check if any of the item's record values match the selected values
            return itemRecords[recordKey].some(function (recordValue) {
                return selectedRecords[recordKey].indexOf(recordValue) !== -1;
            });
        }
        return false; // Return false if the record is not present
    });
}
/**
 * Utils sort an array HTMLElement by focus position
 * @param {HTMLElement} $a
 * @param {HTMLElement} $b
 * @returns
 */
coned.utils.arrayFocusSort = function ($a, $b) {
    return ($a.compareDocumentPosition($b) & Node.DOCUMENT_POSITION_PRECEDING) ? 1 : -1;
}

/**
 * set aria hidden attribute of element
 * @param {HTMLElement} $element
 * @param {boolean} value
 */
coned.utils.setAriaHidden = function ($element, value) {
    if ($element instanceof HTMLElement) {
        $element.setAttribute(
            coned.constants.ARIA.HIDDEN,
            value ? coned.constants.TRUE : coned.constants.FALSE
        );
    }
}

/**
 * set aria hidden attribute of element
 * @param {HTMLElement} $element 
 * @param {boolean} value 
 */
coned.utils.setAriaHidden = function ($element, value) {
    if ($element instanceof HTMLElement) {
        $element.setAttribute(
            coned.constants.ARIA.HIDDEN,
            value ? coned.constants.TRUE : coned.constants.FALSE
        );
    }
}

/**
 * Helper function to return Element or ParentElement by child Element
 * and selector Class
 * @param {HTMLElement | null} $element
 * @param {string} className
 * @returns {HTMLElement | null}
 */
coned.utils.getElementOrParentElementByClass = function ($element, className) {
    while ($element !== null && !$element.classList.contains(className)) {
        $element = $element.parentElement;
    }
    return $element;
};

/**
 * This modules has utils used across the coned components.
 * Its main purpose is to include functions related to element's handling.
 */
(function (query) {
    /**
     * This function calls the callback function when the document is ready.
     * @param {Function} callback Function called after the document is ready.
     */
    query.documentReady = function documentReady(callback, target) {
        target = target || document;

        // Mozilla, Opera and webkit nightlies currently support this event
        if (target.addEventListener) {
            // Use the handy event callback
            target.addEventListener(
                'DOMContentLoaded',
                function () {
                    target.removeEventListener('DOMContentLoaded', arguments.callee, false);
                    callback();
                },
                false
            );

            // If IE event model is used
        } else if (target.attachEvent) {
            // ensure firing before onload,
            // maybe late but safe also for iframes
            target.attachEvent('onreadystatechange', function () {
                if (target.readyState === 'complete') {
                    target.detachEvent('onreadystatechange', arguments.callee);
                    callback();
                }
            });
        }
    };

    /**
     * Insert element at index position
     * @param {HTMLElement} $parent Element to append child.
     * @param {HTMLElement} $element Child element
     * @param {index} index position where child will be inserted
     */
    query.insertChildAtIndex = function ($parent, $element, index) {
        if (index >= $parent.children.length) {
            $parent.appendChild($element);
        } else {
            $parent.insertBefore($element, $parent.children[index]);
        }
    };

    /**
     * Looks for all the classes inside an element that match a certain affix.
     * @param {HTMLElement} $element Element to get the classes from.
     * @param {String} affix Affix to look for.
     * @return {Array} Array of strings with the matched classes.
     */
    query.getClass = function ($element, affix) {
        return $element.className.match(
            new RegExp('([a-zA-Z0-9-_.]*' + affix + '[a-zA-Z0-9-_.]*)', 'g')
        );
    };

    /**
     * Checks if an element has a class.
     * @param {HTMLElement} $element Element to test if it has a class.
     * @param {String} className Class to check.
     * @param {Boolean} isSubstring Flag to match the class exactly or match className as part of a class.
     * @return {Boolean} True if element has class, false if not.
     */
    query.hasClass = function ($element, className, isSubstring) {
        isSubstring = isSubstring || false;

        if (!$element) return;

        if (!isSubstring) {
            if ($element.classList) {
                if ($element.classList.contains(className)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (
                    $element.className.match(
                        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi')
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            if ($element.classList) {
                for (var n = 0; n < $element.classList.length; n++) {
                    if ($element.classList[n].includes(className)) {
                        return true;
                    }
                }
                return false;
            } else {
                if ($element.className.includes(className)) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    /**
     * Checks if a list of elements, all/any have a class.
     * @param {HTMLElement} $elements Elements to test if they have a class.
     * @param {String} className Class to check.
     * @param {Boolean} any Flag to check if all should have it or any of them.
     * @return {Boolean} True if element has class, false if not.
     */
    query.listHasClass = function ($elements, className, any) {
        if (!$elements) return;

        any = any || false;

        for (var n = 0; n < $elements.length; n++) {
            if (!query.hasClass($elements[n], className) && !any) {
                return false;
            } else if (query.hasClass($elements[n], className) && any) {
                return true;
            }
        }
        if (!any) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Helper to add a class to an element. It wont add a class if already exists.
     * @param {HTMLElement} $element Element or array of elements to add class to.
     * @param {String} className Class to add to the element.
     */
    query.addClass = function addClass($element, className) {
        var addClassToElement = function ($element) {
            if (!$element) return;

            if ($element.classList) {
                if (!$element.classList.contains(className)) {
                    $element.classList.add(className);
                }
            } else {
                if (
                    !$element.className.match(
                        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi')
                    )
                ) {
                    $element.className = $element.className + ' ' + className;
                }
            }
        };

        if (!$element) return;

        var $elements, elementsIndex, elementIndex;

        if (Object.prototype.toString.call($element) === '[object Array]') {
            $elements = $element;

            for (elementsIndex = 0; elementsIndex < $elements.length; elementsIndex++) {
                $element = $elements[elementsIndex];

                if (!$element) return;

                if ($element.length) {
                    for (elementIndex = 0; elementIndex < $element.length; elementIndex++) {
                        addClassToElement($element[elementIndex]);
                    }
                } else {
                    addClassToElement($element);
                }
            }
        } else {
            if ($element.length) {
                for (elementIndex = 0; elementIndex < $element.length; elementIndex++) {
                    addClassToElement($element[elementIndex]);
                }
            } else {
                addClassToElement($element);
            }
        }
    };

    /**
     * Helper to remove a class from an element.
     * @param {HTMLElement} element Element or array of elements to remove class to.
     * @param {String} className Class to remove from the element.
     */
    query.removeClass = function removeClass($element, className) {
        var removeClassToElement = function ($element) {
            if ($element.classList) {
                $element.classList.remove(className);
            } else {
                $element.className = $element.className.replace(
                    new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
                    ' '
                );
            }
        };

        if (!$element) return;

        var $elements, elementsIndex, elementIndex;

        if (Object.prototype.toString.call($element) === '[object Array]') {
            $elements = $element;

            for (elementsIndex = 0; elementsIndex < $elements.length; elementsIndex++) {
                $element = $elements[elementsIndex];

                if (!$element) return;

                if ($element.length) {
                    for (elementIndex = 0; elementIndex < $element.length; elementIndex++) {
                        removeClassToElement($element[elementIndex]);
                    }
                } else {
                    removeClassToElement($element);
                }
            }
        } else {
            if ($element.length) {
                for (elementIndex = 0; elementIndex < $element.length; elementIndex++) {
                    removeClassToElement($element[elementIndex]);
                }
            } else {
                removeClassToElement($element);
            }
        }
    };

    /**
     * Helper to replace a class with a new one.
     * @param {HTMLElement} $element Element or array of elements to replace the class to.
     * @param {String} newClassName Class to add to the element.
     * @param {String} oldClassName Class to remove to the element.
     */
    query.replaceClass = function replaceClass($element, newClassName, oldClassName) {
        var replaceClassToElement = function replaceClass($element) {
            if (!$element) return;

            if ($element.classList) {
                // remove old class
                $element.classList.remove(oldClassName);

                // add new class
                if (!$element.classList.contains(newClassName)) {
                    $element.classList.add(newClassName);
                }
            } else {
                // remove old class
                $element.className = $element.className.replace(
                    new RegExp('(^|\\b)' + oldClassName.split(' ').join('|') + '(\\b|$)', 'gi'),
                    ' '
                );

                // add new class
                if (
                    !$element.className.match(
                        new RegExp('(^|\\b)' + newClassName.split(' ').join('|') + '(\\b|$)', 'gi')
                    )
                ) {
                    $element.className = $element.className + ' ' + newClassName;
                }
            }
        };

        if (!$element) return;

        var $elements, elementsIndex, elementIndex;

        if (Object.prototype.toString.call($element) === '[object Array]') {
            $elements = $element;

            for (elementsIndex = 0; elementsIndex < $elements.length; elementsIndex++) {
                $element = $elements[elementsIndex];

                if (!$element) return;

                if ($element.length) {
                    for (elementIndex = 0; elementIndex < $element.length; elementIndex++) {
                        replaceClassToElement($element[elementIndex]);
                    }
                } else {
                    replaceClassToElement($element);
                }
            }
        } else {
            if ($element.length) {
                for (elementIndex = 0; elementIndex < $element.length; elementIndex++) {
                    replaceClassToElement($element[elementIndex]);
                }
            } else {
                replaceClassToElement($element);
            }
        }
    };

    /**
     * Helper to fire an event on an element.
     * @param {HTMLElement} $element Element to trigger event.
     * @param {String} eventType Event type.
     * @param {Object} eventArgs Object with arguments to pass.
     */
    query.fireEvent = function ($element, eventType, eventArgs) {
        var event;

        if (document.createEventObject) {
            // dispatch for IE
            event = document.createEventObject();

            if (eventArgs) {
                event.details = eventArgs;
            }

            $element.fireEvent('on' + eventType, event);
        } else {
            // dispatch for firefox + others
            event = document.createEvent('HTMLEvents');

            event.initEvent(eventType, true, true); // event type, bubbling, cancelable

            if (eventArgs) {
                event.details = eventArgs;
            }

            $element.dispatchEvent(event);
        }
    };

    /**
     * Loops up until finding element's parent.
     * @param {HTMLElement} $element Element to whose parent you want to look for.
     * @param {String} className ClassName the parent should have.
     * @return {HTMLElement} Received element if parent class wasn't found, parent element (the one with the class) if it was.
     */
    query.selectParentElement = function ($element, className) {
        while ($element != null && !query.hasClass($element, className)) {
            $element = $element.parentElement;
        }

        return $element;
    };

    /**
     * Loops up until finding element's parent.
     * @param {HTMLElement} $element Element to whose parent you want to look for.
     * @param {String} tag Type of element the parent should have.
     * @return {HTMLElement} Received element if parent class wasn't found, parent element (the one with the class) if it was.
     */
    query.selectParentElementByTag = function ($element, tag) {
        while ($element != null && $element.tagName.toLowerCase() !== tag.toLowerCase()) {
            $element = $element.parentElement;
        }

        return $element;
    };

    /**
     * Loops up until finding element's parent.
     * @param {HTMLElement} $element Element to whose parent you want to look for.
     * @param {String} className ClassName the parent should have.
     * @return {HTMLElement} Received element if parent class wasn't found, parent element (the one with the class) if it was.
     */
    query.selectParentElementByAttribute = function ($element, key, value) {
        while ($element != null && $element.dataset[key] !== value) {
            $element = $element.parentElement;
        }

        return $element;
    };

    /**
     * Loops up until finding if the element or its ancestors has a display none.
     * @param {HTMLElement} $element Element you want to start checking if it has display none, going up all the way through the ancestors.
     * @return {boolean} True if element or any of its ancestors has a display none.
     */
    query.checkElementOrParentDisplayNone = function ($element) {
        var _hasDisplayNone = false;

        for (var index = 0; $element != null; index++) {
            if (window.getComputedStyle($element, null).display === 'none') {
                _hasDisplayNone = true;
                break;
            }
            $element = $element.parentElement;
        }

        return _hasDisplayNone;
    };

    /**
     * Determines wether an object exists in an array or not, and returns its index.
     * @param {Array} array Array to check for the value.
     * @param {Object} object Object to look for.
     * @return
     */
    query.indexOf = function (array, object) {
        for (var n = 0; n < array.length; n++) {
            if (array[n] === object) {
                return n;
            }
        }

        return -1;
    };

    /**
     * Determines wether an object exists in an array or not.
     * @param {Array} array Array to check for the value.
     * @param {Object} object Object to look for.
     * @return
     */
    query.contains = function (array, object) {
        var arrayLength = array.length;

        while (arrayLength--) {
            if (array[arrayLength] === object) {
                return true;
            }
        }

        return false;
    };

    /**
     * Method used for the loading show/hide on service calls.
     * @param {HTMLElement} $formLoading form progress element.
     * @param {boolean} status Boolean to show or hide the form loading.
     */
    function toggleLoading($formLoading, status) {
        if (!$formLoading) return;

        if (status) {
            $formLoading.classList.remove('form-loading--hidden');
        } else {
            $formLoading.classList.add('form-loading--hidden');
        }
    }

    /**
     * Makes ajax request to service URL.
     * @param {String} url Url endpoint
     * @param {Function} successCallback Callback called when request is successful.
     * @param {Function} errorCallback Callback called when request failed.
     * @param {Object} params Data sent as a query.
     * @param {HTMLElement} $formLoading Form progress element.
     */
    query.getData = function getData(url, successCallback, errorCallback, params, $formLoading) {
        var request = new XMLHttpRequest(),
            requestQueryArray = [],
            requestQuery = '',
            key;

        if (params) {
            requestQueryArray.push(url);
            requestQueryArray.push('?');

            // Iterates over data and adds query element in the url
            for (key in params) {
                if (key && params.hasOwnProperty(key)) {
                    requestQueryArray.push(key);
                    requestQueryArray.push('=');
                    requestQueryArray.push(params[key]);
                    requestQueryArray.push('&');
                }
            }
            // removes last '&'
            requestQueryArray.pop();
            requestQuery = requestQueryArray.join('');
            url = requestQuery;
        }

        request.open('GET', url, true);

        request.onload = function onload() {
            toggleLoading($formLoading);

            var data = '';

            if (request.status >= 200 && request.status < 400) {
                if (query.isJSON(request.responseText)) {
                    data = JSON.parse(request.responseText);
                } else {
                    data = request.responseText;
                }

                successCallback(data, request.status);
            } else {
                // Server was reached, but it returned an error
                if (query.isJSON(request.response)) {
                    errorCallback(JSON.parse(request.response), request.status);
                } else {
                    errorCallback(request.response, request.status);
                }
            }
        };

        request.onerror = function () {
            toggleLoading($formLoading);

            var data = {
                status: request.status,
                errorMsg: request.response
            };

            errorCallback(data, request.status);
        };

        toggleLoading($formLoading, true);

        request.send();
    };

    /**
     * Makes POST request to service URL.
     * @param {String} url Url endpoint
     * @param {Function} successCallback Callback called when request is successful.
     * @param {Function} errorCallback Callback called when request failed.
     * @param {Object} params Data sent as a query.
     * @param {Boolean} json Flag to mark if the request is JSON.
     * @param {HTMLElement} $formLoading Form progress element.
     */
    query.postData = function postData(
        url,
        successCallback,
        errorCallback,
        params,
        json,
        $formLoading
    ) {
        query.httpDataMethod(
            url,
            successCallback,
            errorCallback,
            params,
            json,
            $formLoading,
            'POST'
        );
    };

    /**
     * Makes DELETE request to service URL.
     * @param {String} url Url endpoint
     * @param {Function} successCallback Callback called when request is successful.
     * @param {Function} errorCallback Callback called when request failed.
     * @param {Object} params Data sent as a query.
     * @param {Boolean} json Flag to mark if the request is JSON.
     * @param {HTMLElement} $formLoading Form progress element.
     */
    query.deleteData = function postData(
        url,
        successCallback,
        errorCallback,
        params,
        json,
        $formLoading
    ) {
        query.httpDataMethod(
            url,
            successCallback,
            errorCallback,
            params,
            json,
            $formLoading,
            'DELETE'
        );
    };

    /**
     * Makes PUT request to service URL.
     * @param {String} url Url endpoint
     * @param {Function} successCallback Callback called when request is successful.
     * @param {Function} errorCallback Callback called when request failed.
     * @param {Object} params Data sent as a query.
     * @param {Boolean} json Flag to mark if the request is JSON.
     * @param {HTMLElement} $formLoading Form progress element.
     */
    query.putData = function postData(
        url,
        successCallback,
        errorCallback,
        params,
        json,
        $formLoading
    ) {
        query.httpDataMethod(
            url,
            successCallback,
            errorCallback,
            params,
            json,
            $formLoading,
            'PUT'
        );
    };

    /**
     * Util service ==> POST/DELETE or PUT can be used to generate a request.
     * @param {String} url Url endpoint
     * @param {Function} successCallback Callback called when request is successful.
     * @param {Function} errorCallback Callback called when request failed.
     * @param {Object} params Data sent as a query.
     * @param {Boolean} json Flag to mark if the request is JSON.
     * @param {HTMLElement} $formLoading Form progress element.
     * @param {String} httpMethod Method to use for the request call to make.
     */
    query.httpDataMethod = function httpDataMethod(
        url,
        successCallback,
        errorCallback,
        params,
        json,
        $formLoading,
        httpMethod
    ) {
        var request = new XMLHttpRequest();

        request.open(httpMethod, url, true);

        if (json) {
            request.setRequestHeader('Content-Type', 'application/json');
        }

        request.onload = function onload() {
            toggleLoading($formLoading);

            if (request.status >= 200 && request.status < 400) {
                if (request.response) {
                    if (query.isJSON(request.response)) {
                        successCallback(JSON.parse(request.response), request.status);
                    } else {
                        successCallback(request.response, request.status);
                    }
                } else {
                    successCallback();
                }
            } else {
                // Server was reached, but it returned an error
                if (query.isJSON(request.response)) {
                    errorCallback(JSON.parse(request.response), request.status);
                } else {
                    errorCallback(request.response, request.status);
                }
            }
        };

        request.onerror = function () {
            toggleLoading($formLoading);

            var data = {
                status: request.status,
                errorMsg: request.response
            };

            errorCallback(data, request.status);
        };

        toggleLoading($formLoading, true);

        request.send(params);
    };

    /**
     * Method that verifies if string is JSON.
     * @param {String} string String to see if its a JSON or not.
     */
    query.isJSON = function (string) {
        try {
            JSON.parse(string);
        } catch (e) {
            return false;
        }

        return true;
    };

    /**
     * Removes all elements within an element.
* @param {HTMLElement} $element Container element to be cleared.
     */
    query.clearElement = function ($element) {
        while ($element.firstChild) {
            $element.removeChild($element.firstChild);
        }
    };

    /**
     * Scroll page to specific element.
     * @param {HTMLElement} $element Element to scroll into.
     * @param {HTMLElement} $header Header element used to get the fixed header height.
     */
    query.scrollToElement = function ($element, $header) {
        $header = $header ? $header : document.getElementsByClassName('js-header-wrapper')[0];

        // For the dropdown tabs a class is added which gives issues to the scroll
        var $conedTabsContainer = document.getElementsByClassName(
            coned.constants.CONED_TABS_VISIBLE_CLASS
        )[0];

        // If element with the class is found, class must be removed to avoid issue
        // Assumption is that overflow hidden in a container + scroll, causes a page scroll block
        if ($conedTabsContainer !== undefined) {
            $conedTabsContainer.classList.remove(coned.constants.CONED_TABS_VISIBLE_CLASS);
        }

        $element.scrollIntoView(true);

        var scrolledY = window.pageYOffset,
            headerHeight = $header.offsetHeight * 2;

        if (scrolledY) {
            window.scroll(0, scrolledY - headerHeight);
        }

        // If element with the class was found, class must be re-added to allow the dropdown tabs to work
        if ($conedTabsContainer !== undefined) {
            $conedTabsContainer.classList.add(coned.constants.CONED_TABS_VISIBLE_CLASS);
        }
    };

    /**
     * Creates cookie.
     * @param {String} cookieName Cookie variable name to be set.
     * @param {String} cookieValue Cookie value to set the variable name.
     * @param {String} expiryDate Expiration date for the cookie to set.
     */
    query.setCookie = function (cookieName, cookieValue, expiryDate) {
        var path = ';path=/';
        var expires = '';

        if (expiryDate !== undefined) {
            var date = new Date(expiryDate);

            if (!isNaN(date)) {
                expires = ';expires=' + date.toUTCString();
            }
        }

        document.cookie = cookieName + '=' + cookieValue + expires + path;
    };

    /**
     * Reads cookie.
     * @param {String} cookieName Cookie variable name to read.
     */
    query.getCookie = function (cookieName) {
        var variableName = cookieName + '=',
            cookieArray = document.cookie.split(';');

        for (var cookieIndex = 0; cookieIndex < cookieArray.length; cookieIndex++) {
            var cookie = cookieArray[cookieIndex];

            // Remove any whitespace from the cookie string
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }

            // If variable is found, return its value
            if (cookie.indexOf(variableName) == 0) {
                return cookie.substring(variableName.length, cookie.length);
            }
        }

        return null;
    };

    /**
     * Erases cookie.
     * @param {String} cookieName Cookie variable name to delete.
     */
    query.deleteCookie = function (cookieName) {
        query.setCookie(cookieName, '', -1);
    };

    /**
     * Get form checked radio input value.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getRadioInputValue = function ($formModule, inputName) {
        var $radioGroup = $formModule.querySelectorAll('[name="' + inputName + '"]'),
            $radioElement,
            radioIndex;

        for (radioIndex = 0; radioIndex < $radioGroup.length; radioIndex++) {
            $radioElement = $radioGroup[radioIndex];

            if ($radioElement.checked) {
                return $radioElement.value;
            }
        }
    };

    /**
     * Get form checked checkbox input value.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getCheckboxInputValue = function ($formModule, inputName) {
        var $checkboxInput = $formModule.querySelector('[name="' + inputName + '"]');

        return $checkboxInput ? $checkboxInput.checked : null;
    };

    /**
     * Get form checked checkbox input value.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputId String of the name the input to read has.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getCheckboxInputValueById = function ($formModule, inputId) {
        var $checkboxInput = $formModule.querySelector('[id=' + inputId + ']');

        return $checkboxInput ? $checkboxInput.checked : null;
    };

    /**
     * Get form text and dropdown input value.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getInputValue = function ($formModule, inputName) {
        var $input = $formModule.querySelector('[name="' + inputName + '"]'),
            value = $input ? $input.value : null;

        if (value == 'default') {
            return '';
        } else {
            return value;
        }
    };

    // create a hash with the functions to be called.
    query.FORM_INPUT_VALUE_FUNCTIONS = {
        radio: query.getRadioInputValue,
        checkbox: query.getCheckboxInputValue,
        checkboxId: query.getCheckboxInputValueById,
        default: query.getInputValue
    };

    /**
     * Get form input values.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getFormInputValue = function ($formModule, inputName) {
        var inputType,
            $input = $formModule.querySelector('[name="' + inputName + '"]');

        if ($input) {
            inputType = $input.getAttribute('type');

            if (inputType && query.FORM_INPUT_VALUE_FUNCTIONS[inputType]) {
                return query.FORM_INPUT_VALUE_FUNCTIONS[inputType]($formModule, inputName);
            } else {
                return query.FORM_INPUT_VALUE_FUNCTIONS['default']($formModule, inputName);
            }
        }
    };

    /**
     * Get form input values.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputId String of the name the input to read has.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getFormInputValueById = function ($formModule, inputId) {
        var inputType,
            $input = $formModule.querySelector('#' + inputId);

        if ($input) {
            inputType = $input.getAttribute('type') + 'Id';

            if (inputType && query.FORM_INPUT_VALUE_FUNCTIONS[inputType]) {
                return query.FORM_INPUT_VALUE_FUNCTIONS[inputType]($formModule, inputId);
            } else {
                return query.FORM_INPUT_VALUE_FUNCTIONS['default']($formModule, inputId);
            }
        }
    };

    /**
     * set form text input values
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @param {String} inputValue String of the value to set the input with.
     */
    query.setFormTextInputValue = function (formModule, inputName, inputValue) {
        var $input = formModule.querySelector('[name="' + inputName + '"]');

        $input.value = inputValue;
    };

    /**
     * get form text input values
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to get the value.
     */
    query.getFormTextInputValue = function (formModule, inputName) {
        var $input = formModule.querySelector('[name="' + inputName + '"]');

        if ($input) {
            return $input.value.trim();
        }
    };

    /**
     * Get form checked radio input text.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @param {String} parentClass Class to use to get the input's parent element.
     * @param {String} textClass Class to look for the text instead of value of the selected option.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getRadioInputText = function ($formModule, inputName, parentClass, textClass) {
        var $radioGroup = $formModule.querySelectorAll('[name="' + inputName + '"]'),
            $radioElement,
            $radioParent,
            $radioText,
            radioIndex;

        for (radioIndex = 0; radioIndex < $radioGroup.length; radioIndex++) {
            $radioElement = $radioGroup[radioIndex];

            if ($radioElement.checked) {
                $radioParent = query.selectParentElement($radioElement, parentClass);
                $radioText = $radioParent.getElementsByClassName(textClass)[0];

                return $radioText ? $radioText.innerText : null;
            }
        }
    };

    /**
     * Get form checked checkbox input text.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @param {String} parentClass Class to use to get the input's parent element.
     * @param {String} textClass Class to use for the input text finding.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getCheckboxInputText = function ($formModule, inputName, parentClass, textClass) {
        var $checkboxInput = $formModule.querySelector('[name="' + inputName + '"]'),
            $checkboxParent,
            $checkboxText;

        $checkboxParent = query.selectParentElement($checkboxInput, parentClass);
        $checkboxText = $checkboxParent.getElementsByClassName(textClass)[0];

        return $checkboxText ? $checkboxText.innerText.trim() : null;
    };

    /**
     * Get form dropdown selected input text.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getDropdownInputText = function ($formModule, inputName) {
        var $dropdownInput = $formModule.querySelector('[name="' + inputName + '"]');

        return $dropdownInput.selectedIndex > 0
            ? $dropdownInput.options[$dropdownInput.selectedIndex].text
            : '';
    };

    /**
     * Get form dropdown selected input text by element.
     * @param {Object} $dropdownInput Dropdown that we want to get the value for.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getDropdownInputTextByElement = function ($dropdownInput) {
        return $dropdownInput.selectedIndex > 0
            ? $dropdownInput.options[$dropdownInput.selectedIndex].text
            : '';
    };

    /**
     * Get form dropdown selected value.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @return {String} Containing the input value, if input was found. If not, it returns null.
     */
    query.getDropdownSelectedValue = function ($formModule, inputName) {
        var $dropdownInput = $formModule.querySelector('[name="' + inputName + '"]');

        return $dropdownInput.selectedIndex > 0
            ? $dropdownInput.options[$dropdownInput.selectedIndex].value
            : '';
    };

    // create a hash with the functions to be called.
    query.FORM_INPUT_TEXT_FUNCTIONS = {
        radio: query.getRadioInputText,
        checkbox: query.getCheckboxInputText,
        default: query.getDropdownInputText
    };

    /**
     * Get form input texts.
     * @param {Object} formModule Form module that contains the input.
     * @param {String} inputName String of the name the input to read has.
     * @param {String} parentClass Class to use to get the input's parent element.
     * @param {String} textClass Class to use for the input text finding.
     * @return {String} Containing the input text, if input was found. If not, it returns null.
     */
    query.getFormInputText = function ($formModule, inputName, parentClass, textClass) {
        var inputType,
            $input = $formModule.querySelector('[name="' + inputName + '"]');

        if ($input) {
            inputType = $input.getAttribute('type');

            if (inputType && query.FORM_INPUT_TEXT_FUNCTIONS[inputType]) {
                return query.FORM_INPUT_TEXT_FUNCTIONS[inputType](
                    $formModule,
                    inputName,
                    parentClass,
                    textClass
                );
            } else {
                return query.FORM_INPUT_TEXT_FUNCTIONS['default']($formModule, inputName);
            }
        }
    };
})(query);
