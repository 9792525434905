// ==================== OVERVIEW BILL ANALYSIS COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.OverviewBillAnalysis = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        COMPARE_BUTTON: 'js-bill-compare',
        COMPARE_BUTTON_ACTIVE: 'overview-bill-analysis__nav-item--active',
        BILL_DISCLAIMER: 'js-bill-disclaimer',
        BILL_ANALYSIS_INFO: 'js-analysis-information',
        BILL_ANALYSIS_ACTIVE: 'overview-bill-analysis__information--active',
        TABS_CONTAINER: 'js-coned-tabs-bill-compare',
        HIDDEN_CLASS: 'hidden',
        ROLE: 'ROLE',
        ROLE_TAB: 'tab',
        ARIA_SELECTED: 'aria-selected',
        TABINDEX: 'tabindex'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var OverviewBillAnalysis = function ($billAnalysis) {
        /**
         * PRIVATE METHODS
         */
        var $selectBillButton, $billDisclaimer, $analysisInfoContainer;

        var compareBill = function (event) {
            event.preventDefault();

            var selectedBill = this.dataset.bill,
                hideDisclaimer =
                    this.dataset.hideDisclaimer && this.dataset.hideDisclaimer === 'true'
                        ? true
                        : false;

            if (hideDisclaimer && $billDisclaimer) {
                $billDisclaimer.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else if (!hideDisclaimer && $billDisclaimer) {
                $billDisclaimer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            for (var index = 0; index < $selectBillButton.length; index++) {
                $selectBillButton[index].classList.remove(CONSTANTS.COMPARE_BUTTON_ACTIVE);
            }

            for (index = 0; index < $analysisInfoContainer.length; index++) {
                var $activeBillInfo = $analysisInfoContainer[index],
                    billType = $activeBillInfo.dataset.billInformation;

                if (billType === selectedBill) {
                    $activeBillInfo.classList.add(CONSTANTS.BILL_ANALYSIS_ACTIVE);
                } else {
                    $activeBillInfo.classList.remove(CONSTANTS.BILL_ANALYSIS_ACTIVE);
                }
            }

            this.classList.add(CONSTANTS.COMPARE_BUTTON_ACTIVE);
        };

        /**
         *  Controller event with right and left key on tab menu
         * @param {Event} event default event
         */
        var handlerKeydownEventListener = function (event) {
            var key = event.keyCode;
            var listElement = Array.prototype.slice.call($selectBillButton),
                index = listElement.indexOf(event.target);

            var nextElement;

            switch (key) {
                case coned.constants.KEY_CODE.LEFT:
                    if ($selectBillButton[index - 1]) {
                        nextElement = $selectBillButton[index - 1];
                    } else {
                        nextElement = $selectBillButton[$selectBillButton.length - 1];
                    }
                    break;
                case coned.constants.KEY_CODE.RIGHT:
                    if ($selectBillButton[index + 1]) {
                        nextElement = $selectBillButton[index + 1];
                    } else {
                        nextElement = $selectBillButton[0];
                    }
                    break;
            }
            //Apply navegation with arrows key
            if (key == coned.constants.KEY_CODE.LEFT || key == coned.constants.KEY_CODE.RIGHT) {
                nextElement.focus();
            } else if (
                key == coned.constants.KEY_CODE.ENTER ||
                key == coned.constants.KEY_CODE.SPACE
            ) {
                //Remove aria-selected TRUE

                for (
                    var indexElement = 0;
                    indexElement < $selectBillButton.length;
                    indexElement++
                ) {
                    $selectBillButton[indexElement].setAttribute(CONSTANTS.TABINDEX, '-1');
                    $selectBillButton[indexElement].setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
                }
                //update ARIA-SELECTED and TABINDEX for selection tab
                event.target.setAttribute(CONSTANTS.TABINDEX, '0');
                event.target.setAttribute(CONSTANTS.ARIA_SELECTED, 'true');
                event.target.click();
            }
        };

        var initializeData = function () {
            $selectBillButton = $billAnalysis.getElementsByClassName(CONSTANTS.COMPARE_BUTTON);
            $billDisclaimer = $billAnalysis.getElementsByClassName(CONSTANTS.BILL_DISCLAIMER)[0];
            $analysisInfoContainer = $billAnalysis.getElementsByClassName(
                CONSTANTS.BILL_ANALYSIS_INFO
            );
        };

        var initializeEvents = function () {
            for (var index = 0; index < $selectBillButton.length; index++) {
                coned.utils.addGeneralListeners($selectBillButton[index], compareBill);
                $selectBillButton[index].parentElement.addEventListener(
                    'keydown',
                    handlerKeydownEventListener
                );

                //A11y attributes
                if (query.hasClass($selectBillButton[index], CONSTANTS.COMPARE_BUTTON_ACTIVE)) {
                    $selectBillButton[index].setAttribute(CONSTANTS.ARIA_SELECTED, 'true');
                    $selectBillButton[index].setAttribute(CONSTANTS.TABINDEX, '0');
                } else {
                    $selectBillButton[index].setAttribute(CONSTANTS.ARIA_SELECTED, 'false');
                    $selectBillButton[index].setAttribute(CONSTANTS.TABINDEX, '-1');
                }

                $selectBillButton[index].setAttribute(CONSTANTS.ROLE, CONSTANTS.ROLE_TAB);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    OverviewBillAnalysis.prototype.isLoaded = function () {
        return isLoaded;
    };

    return OverviewBillAnalysis;
})();
