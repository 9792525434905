// ==================== AUTHENTICATION COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.Authentication = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        AUTH_SELECT: 'js-coned-select',
        AUTH_OPTION: ' js-auth-option',
        FORM_ITEM_VALIDATE: 'js-item-validate',
        FORM_ITEM_IGNORE: 'js-validate-ignore',
        INPUT: 'js-coned-input',
        NOT_INPUT: 'js-no-input',
        INPUT_FILLED: 'coned-input--filled',
        INPUT_ACTIVE: 'js-identification-active',
        INPUT_ERROR_CLASS: 'coned-input-message--error',
        SSN_INPUT: 'js-social-number',
        SOCIAL_SECURITY_NUMBER_LABEL: 'social security number',
        DOB_INPUT: 'js-birth-date',
        FORM: 'js-start-service-form',
        START_SERVICE: 'js-start-service',
        START_DATE: 'js-start-date',
        START_DATE_STEP: 'js-start-date-step',
        VALIDATE_BUTTON: 'js-validate-identity-button',
        HIDDEN: 'hidden',
        VALID: 'valid',
        CHANGE_ADDRESS_EVENT: 'change-address',
        FORM_DATE_SCID: 'DateScId',
        FORM_FIRST_NAME_NAME: 'firstName',
        FORM_LAST_NAME_NAME: 'lastName',
        FORM_DOB_NAME: 'identityDateOfBirth',
        FORM_SSN_NAME: 'socialNumber',
        FORM_ZIPCODE_NAME: 'startServiceZipCode',
        FORM_DRIVER_LICENSE_NAME: 'driverLicense',
        FORM_DRIVER_LICENSE_ISSUED_STATE_NAME: 'driverLicenseIssuedState',
        FORM_ALIEN_ID_NAME: 'alienId',
        FORM_PUBLIC_ASSISTANCE_NAME: 'publicAsistanceId',
        FORM_OVER_62_RADIO: 'age62OverAlone',
        FORM_RADIO_NO_ANSWER: 'No',
        FORM_RADIO_STATE_NAME: 'state',
        FORM_RADIO_LOCATION_NAME: 'location',
        FORM_RADIO_PLACE_NAME: 'place',
        NO_IDENTIFICATION: 'js-no-identification',
        FORM_PASSPORT_NAME: 'startPassport',
        FORM_LOADING: 'js-form-loading',
        CONFIRM_IDENTITY_BUTTON: 'js-identity-button',
        VALIDATE_IDENTITY_ERROR: 'js-validate-identity-service-error',
        VALIDATE_IDENTITY_ERROR_MESSAGE: 'js-error-message',
        VALIDATE_IDENTITY_INPUT: 'js-valid-identity-input',
        IS_FORM_BLOCKED: 'js-form-blocked',
        CURRENT_STEP: 'js-current-step',
        AUTH_INPUT: 'js-auth-input',
        IS_ORU: 'js-oru-authentication',
        IS_COMMERCIAL: 'js-commercial-authentication',

        SERVICE_IDENTITY_STEP_SELECTOR: 'js-service-identity-step',
        SERVICE_DATE_STEP_SELECTOR: 'js-verify-date-step',
        START_DATE_FIELD_NAME: 'startServiceDate',
        TAX_ID_FIELD_NAME: 'taxId',
        AUTH_TYPE_COMMERCIAL_FIELD_NAME: 'autTypeCommercial',
        BUSINESS_NAME_FIELD_NAME: 'businessName',
        FIRST_NAME_FIELD_NAME: 'firstNameCommercial',
        LAST_NAME_FIELD_NAME: 'lastNameCommercial',
        SELECT_BUSINESS_VALUE: 'js-business-name',
        SELECT_FULLNAME_VALUE: 'js-full-name',
        CONED_FORM_FIELD_CONTAINER: 'coned-form__field-container',
        DROPDOWN_LABEL_SELECTOR: 'js-coned-select-label',
        ACTIVE_DROPDOWN_CLASS: 'coned-select--active',
        NO_ID_DEPOSIT: 'js-deposit',
        NO_ID_ZERO_DEPOSIT: 'js-zero-deposit',
        WATERFALL_QUESTIONNAIRE: '.js-waterfall-questions',
        WATERFALL_CONTINUE_BUTTON: '.js-waterfall-questions-submit',
        FORM_WATERFALL_RADIOGROUPS: '.js-waterfall-radiogroup',
        CONED_RADIO_SELECTOR: 'js-coned-radio',

        VALIDATE_IDENTITY_ERROR_TAG: 'error.validate.id',
        VALIDATE_IDENTITY_SUCCESS_TAG: 'validate.id.success',
        VALIDATE_IDENTITY_INPUT_NAME: 'autType'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var Authentication = function ($Authentication) {
        /**
         * PRIVATE METHODS
         */
        var $selectElements,
            $authOptions,
            $dobinput,
            $validateButton,
            $parentForm,
            $formLoading,
            $confirmIdentityButton,
            $identityServiceError,
            $identityServiceMessage,
            $currentStep,
            $startDate,
            $startDateStep,
            $startForm,
            $noIdZeroDeposit,
            $authInputs,
            $serviceIdentityStepSelector,
            $serviceDateStepSelector,
            $waterfallQuestionnaire,
            $waterfallSubmitButton,
            _addressData,
            _isNoID,
            _over62,
            _isOru,
            _waterfallQuestionQuantity;

        var resetFormInput = function ($input) {
            if (query.hasClass($input, CONSTANTS.AUTH_SELECT)) {
                var $dropdownLabel = $input.parentElement.getElementsByClassName(
                    CONSTANTS.DROPDOWN_LABEL_SELECTOR
                )[0];
                $dropdownLabel.removeAttribute('style');
                $input.removeAttribute('style');
                $input.selectedIndex = 0;
                $input.classList.remove(CONSTANTS.ACTIVE_DROPDOWN_CLASS);
            } else {
                $input.value = '';
            }
        };

        var showSelectedInput = function (event) {
            var authSelect = event ? event.target : 1,
                authOptionSelectedValue = authSelect[authSelect.selectedIndex].value,
                $authOptionFields = $Authentication.getElementsByClassName(authOptionSelectedValue),
                isCommercial = $Authentication.classList.contains(CONSTANTS.IS_COMMERCIAL);

            _isNoID = authOptionSelectedValue == CONSTANTS.NO_IDENTIFICATION;
            $currentStep = document.getElementsByClassName(CONSTANTS.CURRENT_STEP)[0];

            _.each($authOptions, function ($authOption) {
                var $optionInput = $authOption.querySelector(
                    '.' +
                        [CONSTANTS.INPUT, CONSTANTS.AUTH_SELECT, CONSTANTS.NO_IDENTIFICATION].join(
                            ', .'
                        )
                );

                if (!$authOption.classList.contains(CONSTANTS.NOT_INPUT)) {
                    $authOption.classList.add(CONSTANTS.HIDDEN);
                    resetFormInput($optionInput);
                    $optionInput.classList.add(CONSTANTS.FORM_ITEM_IGNORE);
                    $optionInput.classList.remove(CONSTANTS.INPUT_FILLED);
                    $optionInput.classList.remove(CONSTANTS.INPUT_ACTIVE);
                    $optionInput.classList.remove(CONSTANTS.INPUT_ERROR_CLASS);
                    $optionInput.classList.remove(CONSTANTS.FORM_ITEM_VALIDATE);
                }

                $authOption.style.display = 'none';
            });

            _.each($authOptionFields, function ($authOptionField) {
                var $parentLiElement = query.selectParentElement(
                    $authOptionField,
                    CONSTANTS.CONED_FORM_FIELD_CONTAINER
                );
                $parentLiElement.classList.remove(CONSTANTS.HIDDEN);
                $authOptionField.classList.remove(CONSTANTS.INPUT_ERROR_CLASS);

                if (!$parentLiElement.classList.contains(CONSTANTS.NOT_INPUT)) {
                    $authOptionField.classList.add(CONSTANTS.FORM_ITEM_VALIDATE);
                    $authOptionField.classList.remove(CONSTANTS.FORM_ITEM_IGNORE);
                    $authOptionField.classList.add(CONSTANTS.INPUT_ACTIVE);
                }

                $parentLiElement.style.display = 'block';
            });

            $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
            $confirmIdentityButton.disabled = true;
            $confirmIdentityButton.dataset.validIdentity = '';

            if ($validateButton && !_isNoID && !isCommercial) {
                $validateButton.classList.remove(CONSTANTS.HIDDEN);
                $validateButton.parentElement.classList.remove(CONSTANTS.HIDDEN);
            }

            if (_isNoID) {
                $validateButton.classList.add(CONSTANTS.HIDDEN);
                $validateButton.parentElement.classList.add(CONSTANTS.HIDDEN);

                checkOver62();
                if ($($Authentication).validate().checkForm()) {
                    $($parentForm).valid();
                }
            }
        };

        var enableNextStep = function () {
            if ($startDateStep.style.display === 'none' || !_isNoID) return;

            if ($($parentForm).validate().checkForm()) {
                $confirmIdentityButton.disabled = false;
                $identityServiceError.classList.add(CONSTANTS.HIDDEN);
                $currentStep.classList.remove(CONSTANTS.IS_FORM_BLOCKED);

                var zeroDeposit = $startForm.dataset.zeroDeposit === "true" ? true : false;

                if(_isOru && zeroDeposit && !_over62){
                    $confirmIdentityButton.disabled = true;
                    $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
                }

            } else {
                $confirmIdentityButton.disabled = true;
                $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
            }
            
        };

        var checkOver62 = function () {

            if (!_isNoID || !$dobinput.classList.contains(CONSTANTS.VALID)) {
                return;
            }
            
            var value = query.getFormInputValue($parentForm, CONSTANTS.FORM_DOB_NAME),
                deposit = $parentForm.getElementsByClassName(CONSTANTS.NO_IDENTIFICATION)[0],
                zeroDeposit = $startForm.dataset.zeroDeposit === "true" ? true : false,
                selectedDate = new Date(value),
                birthDateDay = selectedDate.getDate(),
                birthDateMonth = selectedDate.getMonth(),
                birthDateYear = selectedDate.getFullYear(),
                minimumDate = new Date(birthDateYear + 62, birthDateMonth, birthDateDay),
                over62Radios = $parentForm.querySelectorAll('[name="' + CONSTANTS.FORM_OVER_62_RADIO + '"]'),
                $noIdElement = query.selectParentElement(
                    document.getElementsByClassName(CONSTANTS.NO_IDENTIFICATION)[0],
                    CONSTANTS.CONED_FORM_FIELD_CONTAINER
                );

            if (minimumDate <= new Date()) {
                _over62 = true;
            } else {
                _over62 = false;
            }

            if (minimumDate <= new Date()) {
                deposit.classList.add(CONSTANTS.HIDDEN);
                $noIdElement.classList.remove(CONSTANTS.HIDDEN);
                $currentStep.classList.remove(CONSTANTS.IS_FORM_BLOCKED);

                if(_isOru) {
                    //Enable radios and uncheck the No answer
                    _.each(over62Radios, function ($radio) {
                        $radio.parentNode.parentNode.classList.remove('coned-form__field-disabled');
                        if ($radio.value === CONSTANTS.FORM_RADIO_NO_ANSWER) {
                            $radio.checked = false;
                        }
                    });

                    //If deposit isn't more than zero to show the correct message
                    $noIdZeroDeposit.classList.add(CONSTANTS.HIDDEN);
                }

            } else {
                if(_isOru) {
                    if(!zeroDeposit) {
                        deposit.classList.remove(CONSTANTS.HIDDEN);
                        $noIdElement.classList.remove(CONSTANTS.HIDDEN);
                        $noIdZeroDeposit.classList.add(CONSTANTS.HIDDEN);
                        
                        ///Disable radios and check the No answer
                        _.each(over62Radios, function ($radio) {
                            $radio.parentNode.parentNode.classList.add('coned-form__field-disabled');
                            if ($radio.value === CONSTANTS.FORM_RADIO_NO_ANSWER) {
                                $radio.checked = true;
                            }
                        });  

                    } else {
                        deposit.classList.add(CONSTANTS.HIDDEN);
                        $noIdElement.classList.add(CONSTANTS.HIDDEN);
                        $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);

                        //If deposit isn't more than zero to show the correct message
                        $noIdZeroDeposit.classList.remove(CONSTANTS.HIDDEN);
                    }
                } else {
                    deposit.classList.remove(CONSTANTS.HIDDEN);
                }
            }
        };

        var failWaterfallQuestionValidateIdentity = function() {
            $selectElements[0].value = CONSTANTS.NO_IDENTIFICATION;
            $selectElements[0].dispatchEvent(new Event('change'));
            $selectElements[0].disabled = true;
            $startDateStep.dispatchEvent(new Event('change'));
        };

        var waterfallQuestionsValidateIdentity = function () {
            var state = query.getFormInputValue($waterfallQuestionnaire, CONSTANTS.FORM_RADIO_STATE_NAME),
                location = query.getFormInputValue($waterfallQuestionnaire, CONSTANTS.FORM_RADIO_LOCATION_NAME),
                place = query.getFormInputValue($waterfallQuestionnaire, CONSTANTS.FORM_RADIO_PLACE_NAME),
                answers = [state, location, place],
                serviceUrl = $Authentication.dataset.validateIdentityService,
                params = {
                    answers: answers
                };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successValidateIdentity,
                failWaterfallQuestionValidateIdentity,
                params,
                true,
                $formLoading
            );
        };

        var hideWaterfallQuestions = function () {
            $waterfallQuestionnaire.classList.add(CONSTANTS.HIDDEN);
            $serviceDateStepSelector.parentElement.classList.remove(CONSTANTS.HIDDEN);
            $serviceIdentityStepSelector.parentElement.classList.remove(CONSTANTS.HIDDEN);
        };

        var displayWaterfallQuestions = function () {
            $waterfallQuestionnaire.classList.remove(CONSTANTS.HIDDEN);
            $serviceDateStepSelector.parentElement.classList.add(CONSTANTS.HIDDEN);
            $serviceIdentityStepSelector.parentElement.classList.add(CONSTANTS.HIDDEN);
            $parentForm.focus();
            window.scrollTo(0, 0);
        };

        var validateIdentity = function (event) {
            event.preventDefault();

            if (!$($parentForm).valid()) {
                coned.utils.focusFirstFormInputField($parentForm, CONSTANTS.INPUT_ERROR_CLASS);
                return;
            }

            var serviceUrl = $Authentication.dataset.validateIdentityService,
                params;

            if (_isOru) {
                params = {
                    ScId: query.getFormInputValue($parentForm, CONSTANTS.FORM_DATE_SCID),
                    FirstName: query.getFormInputValue($parentForm, CONSTANTS.FORM_FIRST_NAME_NAME),
                    LastName: query.getFormInputValue($parentForm, CONSTANTS.FORM_LAST_NAME_NAME),
                    DateOfBirth: query.getFormInputValue($parentForm, CONSTANTS.FORM_DOB_NAME),
                    SSN: query
                        .getFormInputValue($Authentication, CONSTANTS.FORM_SSN_NAME)
                        .replace(/-/g, ''),
                    ServiceAddress: _addressData,
                    Street: query.getFormInputValue($parentForm, CONSTANTS.FORM_DOB_NAME),
                    Zip: query.getFormInputValue($parentForm, CONSTANTS.FORM_DOB_NAME),
                    IsNoIdentification: _isNoID
                };
            } else {
                params = {
                    ScId: query.getFormInputValue($parentForm, CONSTANTS.FORM_DATE_SCID),
                    FirstName: query.getFormInputValue($parentForm, CONSTANTS.FORM_FIRST_NAME_NAME),
                    LastName: query.getFormInputValue($parentForm, CONSTANTS.FORM_LAST_NAME_NAME),
                    DateOfBirth: query.getFormInputValue($parentForm, CONSTANTS.FORM_DOB_NAME),
                    SSN: query
                        .getFormInputValue($Authentication, CONSTANTS.FORM_SSN_NAME)
                        .replace(/-/g, ''),
                    DriversLicense: query.getFormInputValue(
                        $Authentication,
                        CONSTANTS.FORM_DRIVER_LICENSE_NAME
                    ),
                    DriversLicenseIssuingState: query.getFormInputValue(
                        $Authentication,
                        CONSTANTS.FORM_DRIVER_LICENSE_ISSUED_STATE_NAME
                    ),
                    AlienID: query.getFormInputValue($Authentication, CONSTANTS.FORM_ALIEN_ID_NAME),
                    PublicAssistance: query.getFormInputValue(
                        $Authentication,
                        CONSTANTS.FORM_PUBLIC_ASSISTANCE_NAME
                    ),
                    PassportNumber: query.getFormInputValue(
                        $Authentication,
                        CONSTANTS.FORM_PASSPORT_NAME
                    ),
                    ServiceAddress: _addressData,
                    IsNoIdentification: _isNoID
                };
            }

            // Service Call
            var valueSSN = params.SSN;
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successValidateIdentity,
                function (data) { failValidateIdentity(data, valueSSN) },
                params,
                true,
                $formLoading
            );
        };

        var successValidateIdentity = function () {
            $validateButton.classList.add(CONSTANTS.HIDDEN);
            $validateButton.parentElement.classList.add(CONSTANTS.HIDDEN);
            taggingValidateIdentity(CONSTANTS.VALIDATE_IDENTITY_SUCCESS_TAG);
            $identityServiceError.classList.add(CONSTANTS.HIDDEN);
            $currentStep.classList.remove(CONSTANTS.IS_FORM_BLOCKED);
            $confirmIdentityButton.dataset.validIdentity = CONSTANTS.VALID;
            $confirmIdentityButton.disabled = false;
        };

        var failValidateIdentity = function (data, valueSSN) {
            if (data.waterfallStep && !_isOru && valueSSN) {
                displayWaterfallQuestions();
            } else {
                $confirmIdentityButton.dataset.validIdentity = '';
                $confirmIdentityButton.disabled = true;
                $identityServiceError.classList.remove(CONSTANTS.HIDDEN);
                $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
                $identityServiceMessage.textContent = data.errorMsg
                    ? data.errorMsg
                    : coned.constants.ERROR_MESSAGE;
                taggingValidateIdentity(CONSTANTS.VALIDATE_IDENTITY_ERROR_TAG);
                $identityServiceError.focus();
            }
        };

        var taggingValidateIdentity = function (eventValue) {
            dataLayer.push({
                event: eventValue,
                authenticationType: query
                    .getFormInputText($parentForm, CONSTANTS.VALIDATE_IDENTITY_INPUT_NAME)
                    .toLowerCase()
            });
        };

        var setAddressData = function (event) {
            _addressData = event.detail ? 
                {
                    City: event.detail.City,
                    State: event.detail.State,
                    PostalCode: event.detail.PostalCode,
                    PremiseNumber: event.detail.PremiseNumber,
                    StreetAddress: event.detail.StreetAddress,
                    UnitNumber: event.detail.UnitNumber
                }
                : null;
        };

        var reValidatedUser = function () {
            var isCommercial = $Authentication.classList.contains(CONSTANTS.IS_COMMERCIAL);

            $currentStep = document.getElementsByClassName(CONSTANTS.CURRENT_STEP)[0];
            $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
            $confirmIdentityButton.disabled = true;
            $confirmIdentityButton.dataset.validIdentity = '';
        
            if (
                $selectElements[0].options[$selectElements[0].selectedIndex].index > 0 &&
                $validateButton &&
                !_isNoID &&
                !isCommercial
            ) {
                $validateButton.classList.remove(CONSTANTS.HIDDEN);
                $validateButton.parentElement.classList.remove(CONSTANTS.HIDDEN);
            }
             
            
            if (isCommercial) {
                validateCommercialForm();
            }
        };

        var validateWaterfallQuestionsFields = function () {
            var $waterfallCheckedRadios = $waterfallQuestionnaire.querySelectorAll('input[type="radio"]:checked');
            $waterfallSubmitButton.disabled = !($waterfallCheckedRadios.length === _waterfallQuestionQuantity)
        };

        var validateCommercialForm = function () {
            var startServiceDate = query.getFormInputValue(
                    $serviceDateStepSelector,
                    CONSTANTS.START_DATE_FIELD_NAME
                ),
                taxId = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.TAX_ID_FIELD_NAME
                ),
                autType = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.AUTH_TYPE_COMMERCIAL_FIELD_NAME
                ),
                businessName = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.BUSINESS_NAME_FIELD_NAME
                ),
                firstName = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FIRST_NAME_FIELD_NAME
                ),
                lastName = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.LAST_NAME_FIELD_NAME
                ),
                validStartServiceDate = startServiceDate && startServiceDate !== '',
                validTaxId = taxId && taxId !== '',
                validBusinessName = businessName && businessName !== '',
                validFirstName = firstName && firstName !== '',
                validLastName = lastName && lastName !== '';

            if (autType && autType === CONSTANTS.SELECT_BUSINESS_VALUE) {
                if (validStartServiceDate && validTaxId && validBusinessName) {
                    $currentStep.classList.remove(CONSTANTS.IS_FORM_BLOCKED);
                    $confirmIdentityButton.disabled = false;
                    $confirmIdentityButton.dataset.validIdentity = CONSTANTS.VALID;
                }
            } else if (autType && autType === CONSTANTS.SELECT_FULLNAME_VALUE) {
                if (validStartServiceDate && validTaxId && validFirstName && validLastName) {
                    $currentStep.classList.remove(CONSTANTS.IS_FORM_BLOCKED);
                    $confirmIdentityButton.disabled = false;
                    $confirmIdentityButton.dataset.validIdentity = CONSTANTS.VALID;
                }
            }
        };

        var initializeData = function () {
            $serviceDateStepSelector = document.getElementsByClassName(
                CONSTANTS.SERVICE_DATE_STEP_SELECTOR
            )[0];
            $serviceIdentityStepSelector = document.getElementsByClassName(
                CONSTANTS.SERVICE_IDENTITY_STEP_SELECTOR
            )[0];
            $startDateStep = document.getElementsByClassName(CONSTANTS.START_DATE_STEP)[0];
            $authOptions = $Authentication.getElementsByClassName(CONSTANTS.AUTH_OPTION);

            //Dropdowns in $authOptions should not trigger the showSelectedInput event. The _.difference will exclude these dropdowns for this event
            var $authSelectOptions = _.reduce(
                [].slice.call($authOptions),
                function (result, $element) {
                    return [].slice
                        .call($element.getElementsByClassName(CONSTANTS.AUTH_SELECT))
                        .concat(result);
                },
                []
            );

            $selectElements = _.difference(
                $Authentication.getElementsByClassName(CONSTANTS.AUTH_SELECT),
                $authSelectOptions
            );

            $validateButton = $Authentication.getElementsByClassName(CONSTANTS.VALIDATE_BUTTON);
            if ($validateButton) {
                $validateButton = $validateButton[0];
            }

            $parentForm = document.getElementsByClassName(CONSTANTS.FORM)[0];
            $formLoading = $parentForm.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $confirmIdentityButton = $parentForm.getElementsByClassName(
                CONSTANTS.CONFIRM_IDENTITY_BUTTON
            );
            if ($confirmIdentityButton) {
                $confirmIdentityButton = $confirmIdentityButton[0];
            }

            // Date of Birth input
            $dobinput = $parentForm.getElementsByClassName(CONSTANTS.DOB_INPUT);
            if ($dobinput) {
                $dobinput = $dobinput[0];
            }

            //Start Service Complete Form
            $startForm = document.getElementsByClassName(CONSTANTS.START_SERVICE)[0];

            //Zero deposit Message
            $noIdZeroDeposit = document.getElementsByClassName(CONSTANTS.NO_ID_ZERO_DEPOSIT)[0];

            // Start Date input
            $startDate = $parentForm.getElementsByClassName(CONSTANTS.START_DATE);
            if ($startDate) {
                $startDate = $startDate[0];
            }

            $identityServiceError = $Authentication.getElementsByClassName(
                CONSTANTS.VALIDATE_IDENTITY_ERROR
            );
            if ($identityServiceError) {
                $identityServiceError = $identityServiceError[0];
            }

            $identityServiceMessage = $Authentication.getElementsByClassName(
                CONSTANTS.VALIDATE_IDENTITY_ERROR_MESSAGE
            );
            if ($identityServiceMessage) {
                $identityServiceMessage = $identityServiceMessage[0];
            }

            $authInputs = $Authentication.getElementsByClassName(CONSTANTS.AUTH_INPUT);
            $waterfallQuestionnaire = document.querySelector(CONSTANTS.WATERFALL_QUESTIONNAIRE);
            $waterfallSubmitButton = document.querySelector(CONSTANTS.WATERFALL_CONTINUE_BUTTON);
            _isOru = query.hasClass($Authentication, CONSTANTS.IS_ORU);
            _isNoID = false;
            _over62 = false;
            _addressData = null;
            _waterfallQuestionQuantity = $waterfallQuestionnaire && $waterfallQuestionnaire.querySelectorAll(CONSTANTS.FORM_WATERFALL_RADIOGROUPS).length;
        };

        var initializeEvents = function () {
            Array.prototype.forEach.call($selectElements, function ($selectElement) {
                $selectElement.addEventListener('change', showSelectedInput);
            });
           

            if ($dobinput) {
                $dobinput.addEventListener('keyup', checkOver62);
            }

            if ($validateButton) {
                coned.utils.addGeneralListeners($validateButton, validateIdentity);
            }

            // revalidated user on input change
            _.each($authInputs, function ($input) {
                $input.addEventListener('input', reValidatedUser);
                $input.addEventListener('keyup', reValidatedUser);
            });

            $startDateStep.addEventListener('change', enableNextStep);
            $startDateStep.addEventListener('keyup', enableNextStep);

            if ($confirmIdentityButton) {
                coned.utils.addGeneralListeners($confirmIdentityButton, function () {
                    if (!$confirmIdentityButton.disabled && _isNoID) {
                        taggingValidateIdentity(CONSTANTS.VALIDATE_IDENTITY_SUCCESS_TAG);
                    }
                });
            }

            $Authentication.addEventListener(CONSTANTS.CHANGE_ADDRESS_EVENT, setAddressData);
            
            $waterfallQuestionnaire && coned.utils.addParentListener(
                $waterfallQuestionnaire,
                ['change'],
                CONSTANTS.CONED_RADIO_SELECTOR,
                validateWaterfallQuestionsFields
            );

            if ($waterfallSubmitButton) {
                $waterfallSubmitButton.addEventListener('click', waterfallQuestionsValidateIdentity);
                $waterfallSubmitButton.Callback = hideWaterfallQuestions;
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    Authentication.prototype.isLoaded = function () {
        return isLoaded;
    };

    return Authentication;
})();
