// ==================== POWER YOUR WAY FORM COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PowerYourWayForm = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_SELECTOR: 'js-power-your-way-form',
        FORM_LOADING: 'js-form-loading',
        FORM_ESCO_LIST: 'js-power-your-way-form-esco-list',
        ESCO_CUSTOMER_TYPE: 'js-customer-type',
        ESCO_SERVICE_TYPE: 'js-service-type',
        ESCO_LOCATION: 'js-location',
        FILTER_CUSTOMER: 'customer=',
        FILTER_SERVICE: 'service=',
        FILTER_LOCATION: 'location=',
        LIST_CONTAINER: 'js-escos-container',
        CONED_INPUT: '.coned-input',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        POWER_YOUR_WAY_CUSTOMER: 'powerYourWayCustomer',
        POWER_YOUR_WAY_SERVICE: 'powerYourWayService',
        POWER_YOUR_WAY_LOCATION: 'powerYourWayLocation',
        DATA_INIT_RECAPTCHA_ATTR: 'data-init-recaptcha'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var PowerYourWayForm = function ($PowerYourWayForm) {
        /**
         * PRIVATE METHODS
         */
        var $form,
            $escoForm,
            $escoCustomerType,
            $escoServiceType,
            $escoLocation,
            $formValidationParent,
            $formLoading;

        // include class to animate label pattern
        var labelPattern = function () {
            $(CONSTANTS.CONED_INPUT).change(function () {
                if ($(this).val() !== '') {
                    $(this).addClass(CONSTANTS.INPUT_FILLED_CLASS);
                } else {
                    $(this).removeClass(CONSTANTS.INPUT_FILLED_CLASS);
                }
            });
        };

        var powerYourWaySubmit = function () {
            var customerTypeSelect = document.getElementsByName(
                    CONSTANTS.POWER_YOUR_WAY_CUSTOMER
                )[0],
                serviceTypeSelect = document.getElementsByName(CONSTANTS.POWER_YOUR_WAY_SERVICE)[0],
                locationSelect = document.getElementsByName(CONSTANTS.POWER_YOUR_WAY_LOCATION)[0],
                listContainer = document.getElementsByClassName(CONSTANTS.LIST_CONTAINER)[0],
                customerTypeSelected = customerTypeSelect.options[customerTypeSelect.selectedIndex],
                serviceTypeSelected = serviceTypeSelect.options[serviceTypeSelect.selectedIndex],
                locationSelected = locationSelect.options[locationSelect.selectedIndex],
                filterCustomer = CONSTANTS.FILTER_CUSTOMER + customerTypeSelected.value,
                filterService = CONSTANTS.FILTER_SERVICE + serviceTypeSelected.value,
                filterLocation = CONSTANTS.FILTER_LOCATION + locationSelected.value,
                formService = $PowerYourWayForm.dataset.service,
                urlSeparator = formService.indexOf('?') === -1 ? '?' : '&',
                formPath =
                    formService +
                    urlSeparator +
                    filterCustomer +
                    '&' +
                    filterService +
                    '&' +
                    filterLocation;

            // change form header description values.
            $escoCustomerType.innerHTML = customerTypeSelected.text;
            $escoServiceType.innerHTML = serviceTypeSelected.text;
            $escoLocation.innerHTML = locationSelected.text;

            if (coned.utils.isPatternLab()) {
                formPath = coned.plConstants.PATTERNLAB_POWER_YOUR_WAY_ESCO_LIST;
            }

            query.getData(
                formPath,
                function (data) {
                    // Set the escos results content with the data received
                    listContainer.innerHTML = data;
                    $formValidationParent.setAttribute(CONSTANTS.DATA_INIT_RECAPTCHA_ATTR, coned.constants.false);
                    new coned.components.FormValidationModule($formValidationParent);
                },
                function () {},
                '',
                $formLoading
            );

            $form.style.display = 'none';
            $escoForm.style.display = 'block';
            $escoForm.scrollIntoView();
        };

        var initializeData = function () {
            $escoForm = $PowerYourWayForm.getElementsByClassName(CONSTANTS.FORM_ESCO_LIST)[0];
            $escoCustomerType = $escoForm.getElementsByClassName(CONSTANTS.ESCO_CUSTOMER_TYPE)[0];
            $escoServiceType = $escoForm.getElementsByClassName(CONSTANTS.ESCO_SERVICE_TYPE)[0];
            $escoLocation = $escoForm.getElementsByClassName(CONSTANTS.ESCO_LOCATION)[0];
            $form = $PowerYourWayForm.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            $formValidationParent = $PowerYourWayForm.parentNode;
            $formLoading = $formValidationParent.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            labelPattern();
        };

        var initializeEvents = function () {
            // form validation
            new coned.components.ValidateForm('.' + CONSTANTS.FORM_SELECTOR, powerYourWaySubmit);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PowerYourWayForm.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PowerYourWayForm;
})();
