// ==================== REAL TIME PRICES COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.RealTimePrices = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        START_DATE_INPUT: 'js-start-date',
        END_DATE_INPUT: 'js-end-date',
        SERVICE_TYPE_SELECT: 'js-service-type',
        START_DATE_FILTER: 'startDate=',
        END_DATE_FILTER: 'endDate=',
        SERVICE_TYPE_FILTER: 'servCode=',
        REAL_TIME_FORM: '.js-real-time-prices-form',
        CONED_INPUT: 'coned-input',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        SELECT_LABEL: 'js-coned-select-label',
        INPUT_TEXT_SELECTOR: 'js-coned-input',
        RESET_BUTTON: 'js-reset-button'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var RealTimePrices = function ($realTimePricesForm) {
        /**
         * PRIVATE METHODS
         */
        var $startDate, $endDate, $serviceType, _serviceUrl;

        // include class to animate label pattern
        var labelPattern = function () {
            $(CONSTANTS.CONED_INPUT).bind('change keyup', function () {
                if ($(this).val() !== '') {
                    $(this).addClass(CONSTANTS.INPUT_FILLED_CLASS);
                } else {
                    $(this).removeClass(CONSTANTS.INPUT_FILLED_CLASS);
                }
            });
        };

        var realTimePricesSubmit = function () {
            var startDateValue = $startDate.value,
                endDateValue = $endDate.value,
                filterStarDate = CONSTANTS.START_DATE_FILTER + startDateValue,
                filterEndDate = CONSTANTS.END_DATE_FILTER + endDateValue,
                serviceTypeSelected = $serviceType.options[$serviceType.selectedIndex],
                serviceType = CONSTANTS.SERVICE_TYPE_FILTER + serviceTypeSelected.value,
                finalRedirect =
                    _serviceUrl + filterStarDate + '&' + filterEndDate + '&' + serviceType;

            window.location.href = finalRedirect;
        };

        var initializeData = function () {
            _serviceUrl = $realTimePricesForm.dataset.redirectUrl;
            $startDate = $realTimePricesForm.getElementsByClassName(CONSTANTS.START_DATE_INPUT)[0];
            $endDate = $realTimePricesForm.getElementsByClassName(CONSTANTS.END_DATE_INPUT)[0];
            $serviceType = $realTimePricesForm.getElementsByClassName(
                CONSTANTS.SERVICE_TYPE_SELECT
            )[0];
            labelPattern();
        };

        var initializeEvents = function () {
            // Revalidate the end date field
            $('.' + CONSTANTS.START_DATE_INPUT).on('change', function () {
                if ($endDate.value) {
                    $('.js-end-date').valid();
                }
            });

            // Revalidate the start date field
            $('.' + CONSTANTS.END_DATE_INPUT).on('change', function () {
                if ($startDate.value) {
                    $('.js-start-date').valid();
                }
            });

            // form validation
            new coned.components.ValidateForm(CONSTANTS.REAL_TIME_FORM, realTimePricesSubmit);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    RealTimePrices.prototype.isLoaded = function () {
        return isLoaded;
    };

    return RealTimePrices;
})();
