// ==================== SET MULTI-FACTOR AUTHENTICATION COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SetMultiFactorAuthentication = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SET_MFA_FORM: 'js-mfa-parent-form',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        SECURITY_QUESTION_SELECT: 'js-coned-select',
        SET_MFA_ACCESS_CODE: 'accessCode',
        SET_MFA_SITECORE_ID: 'ScId',
        SET_MFA_USER_ID: 'userId',
        SET_MFA_MOBILE_PHONE: 'mfaMobilePhone',
        SET_MFA_QUESTION: 'securityQuestion',
        SET_MFA_ANSWER: 'securityAnswer',
        SET_MFA_VERIFICATON_BY: 'verificationOption',
        SET_MFA_VERIFICATION_CODE: 'verificationCode',
        HIDDEN_CLASS: 'hidden',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FROM_URI_PARAMETER_NAME: 'fromURI',

        // Tagging
        TAGGING_PAGE_LOAD: 'Step05.Page.Load',
        TAGGING_CODE_SUBMIT: 'Step05.Submit',
        TAGGING_SUCCESS: 'Step06.Reg.Complete'     
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var SetMultiFactorAuthentication = function ($setMfaForm) {
        /**
         * PRIVATE METHODS
         */
        var $submitAnimationButton,
            $submitButton,
            $securityQuestionSelect,
            $serviceErrors,
            $serviceErrorMessages,
            _data;

        var setMfaSubmit = function () {
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                setMfaCall,
                successSetMfaServiceCallback,
                errorSetMfaServiceCallback,
                false,
                false
            );
        };

        var setMfaCall = function () {
            var serviceUrl = $setMfaForm.dataset.service,
                factorIdValue = $setMfaForm.dataset.factorId ? $setMfaForm.dataset.factorId : '',
                stepVerification = query.getFormInputValue(
                    $setMfaForm,
                    CONSTANTS.SET_MFA_VERIFICATON_BY
                ),
                params;

            hideErrors();

            // Service Data
            params = {
                FirstSet: true,
                AccessCode: query.getFormInputValue($setMfaForm, CONSTANTS.SET_MFA_ACCESS_CODE),
                ScId: query.getFormInputValue($setMfaForm, CONSTANTS.SET_MFA_SITECORE_ID),
                UserId: query.getFormInputValue($setMfaForm, CONSTANTS.SET_MFA_USER_ID),
                FactorId: factorIdValue,
                MobilePhoneNumber: query.getFormInputValue(
                    $setMfaForm,
                    CONSTANTS.SET_MFA_MOBILE_PHONE
                ),
                SecurityQuestion: query.getFormInputValue($setMfaForm, CONSTANTS.SET_MFA_QUESTION),
                SecurityQuestionValue:
                    $securityQuestionSelect.options[$securityQuestionSelect.selectedIndex].text,
                SecurityAnswer: query.getFormInputValue($setMfaForm, CONSTANTS.SET_MFA_ANSWER),
                StepVerification: stepVerification,
                SecurityCode: query.getFormInputValue(
                    $setMfaForm,
                    CONSTANTS.SET_MFA_VERIFICATION_CODE
                ),
                ReturnUrl: coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    ? coned.utils.getUrlParameterValue(CONSTANTS.FROM_URI_PARAMETER_NAME)
                    : '',
                ReturnUrlAutoAddAccounts: $setMfaForm.dataset.urlAutoAddAccounts
            };

            if (params.MobilePhoneNumber === null || params.MobilePhoneNumber === '') {
                params.StepVerification = null;
            } else {
                params.SecurityQuestion = null;
                params.SecurityAnswer = null;
                params.SecurityQuestionValue = null;
            }

            // Analytics data building
            dataLayer.push({
                mfa: stepVerification
            });

            if ($setMfaForm.dataset.tagging) {
                dataLayer.push({
                    event: $setMfaForm.dataset.tagging + CONSTANTS.TAGGING_CODE_SUBMIT
                });
            }

            // Disabled button to avoid double clicking while waiting for redirect
            $submitButton.disabled = true;

            params = JSON.stringify(params);

            if (coned.utils.isPatternLab()) {
                serviceUrl = coned.plConstants.GET_LOGIN_MFA;
                query.getData(serviceUrl, successSetMfaCallback, errorSetMfaCallback, params);
            } else {
                query.postData(
                    serviceUrl,
                    successSetMfaCallback,
                    errorSetMfaCallback,
                    params,
                    true
                );
            }
        };

        var successSetMfaServiceCallback = function () {
            if (_data.Status) {
                // Analytics data building
                dataLayer.push({
                    isLoggedIn: 'true'
                });

                if ($setMfaForm.dataset.tagging) {
                    dataLayer.push({
                        event: $setMfaForm.dataset.tagging + CONSTANTS.TAGGING_SUCCESS
                    });
                }
                
                window.location.href = _data.authRedirectUrl;
                return;
            } else {
                // Stop animation manually, since now the animation is not stopped by the success scenario
                $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);

                _data.errorMsg = _data.codeErrorMsg;
                showErrors(_data);
            }
        };

        var errorSetMfaServiceCallback = function () {
            // Disabled button to avoid double clicking while waiting for redirect
            $submitButton.disabled = false;

            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.DEFAULT_SERVICE_ERROR, showErrors, showErrors);
            } else {
                showErrors(_data);
            }
        };

        var hideErrors = function () {
            _.each($serviceErrors, function ($serviceError) {
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorMessages, function ($serviceErrorMessage) {
                $serviceErrorMessage.innerHTML = '';
            });
        };

        var successSetMfaCallback = function (data) {
            _data = data;
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        var errorSetMfaCallback = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var showErrors = function (data) {
            var errorMsg = data.errorMsg;

            _.each($serviceErrors, function ($serviceError) {
                $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $serviceError.focus();
            });

            _.each($serviceErrorMessages, function ($serviceErrorMessage) {
                $serviceErrorMessage.innerHTML = errorMsg;
            });
        };

        var initializeData = function () {
            $submitAnimationButton = $setMfaForm.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            $submitButton = $setMfaForm.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $securityQuestionSelect = $setMfaForm.getElementsByClassName(
                CONSTANTS.SECURITY_QUESTION_SELECT
            )[0];
            $serviceErrors = $setMfaForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR);
            $serviceErrorMessages = $setMfaForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            );
        };

        var initializeEvents = function () {
            if ($setMfaForm.dataset.tagging) {
                dataLayer.push({
                    event: $setMfaForm.dataset.tagging + CONSTANTS.TAGGING_PAGE_LOAD
                });
            }
            new coned.components.ValidateForm('.' + CONSTANTS.SET_MFA_FORM, setMfaSubmit);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SetMultiFactorAuthentication.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SetMultiFactorAuthentication;
})();
