// ==================== WAMI-FORM COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.WamiForm = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        PREFERENCE_CENTER_FORM: '.js-preference-center-form',
        FORM_IGNORE_VALIDATION: 'js-validate-ignore',
        HIDDEN_CLASS: 'hidden',
        NOTIFICATION_CHECK: 'js-notification-check',
        HDA_NOTIFICATION_CHECK: 'js-hda-notification',
        ACCORDION_SELECTOR_ACTIVE: 'js-accordion-selector active',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_CONTAINER: 'js-service-error-container',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        CHECKBOX_SWITCH_FOCUS: 'coned-checkbox-switch--focus',
        EDIT_SCID: 'ScId',
        EDIT_NOTIFICATIONS_SCID: 'notificationsScId',
        TAGGING_CHECKED_ON: '.on',
        TAGGING_CHECKED_OFF: '.off',
        WAMI_STRING: 'WAMI'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var WamiForm = function ($wamiForm) {
        /**
         * PRIVATE METHODS
         */
        var $checksNotification,
            $serviceErrorContainers,
            $serviceErrorMessages,
            $serviceErrors,
            $formLoading,
            $actualField,
            $notificationCheckbox,
            $hdaNotification,
            $closeButton;

        var successEditNotification = function () {
            hideErrors();
            checkboxTagging();
        };

        // Notification options handler
        var editNotification = function () {
            $actualField = this.parentNode;
            $notificationCheckbox = $actualField.getElementsByClassName(
                CONSTANTS.NOTIFICATION_CHECK
            )[0];

            var serviceUrl = $actualField.dataset.service,
                params;

            if ($hdaNotification === $notificationCheckbox) {
                $closeButton = $wamiForm.getElementsByClassName(
                    CONSTANTS.ACCORDION_SELECTOR_ACTIVE
                )[0];
                if ($closeButton) {
                    $closeButton.click();
                }
            }

            params = {
                ScId: query.getFormInputValue($wamiForm, CONSTANTS.EDIT_SCID),
                NotificationsScId: query.getFormInputValue(
                    $wamiForm,
                    CONSTANTS.EDIT_NOTIFICATIONS_SCID
                ),
                PreferenceId: $actualField.dataset.preference,
                ChannelName: $actualField.dataset.channel,
                IsIppSubscription: $actualField.dataset.isIppSubscription,
                Value: $notificationCheckbox.checked,
                Maid: $notificationCheckbox.dataset.maid,
                Email: $notificationCheckbox.dataset.email,
                Phone: $notificationCheckbox.dataset.phone
            };

            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successEditNotification,
                errorServiceCall,
                params,
                true,
                $formLoading
            );
        };

        var errorServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.DEFAULT_SERVICE_ERROR, showError, showError, true);
            } else {
                showError(data);
            }
        };

        var showError = function (data) {
            // Reverts the cheked acction
            $notificationCheckbox.checked = !$notificationCheckbox.checked;

            var errorMsg = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE,
                $serviceErrorContainer = $actualField.parentElement.parentElement.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_CONTAINER
                )[0],
                $serviceError = $serviceErrorContainer.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR
                )[0],
                $serviceErrorMessage = $serviceErrorContainer.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];

            $serviceErrorContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = errorMsg;
            $serviceError.focus();
        };

        var hideErrors = function () {
            _.each($serviceErrors, function ($serviceError) {
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorContainers, function ($serviceErrorContainer) {
                $serviceErrorContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorMessages, function ($serviceErrorMessage) {
                $serviceErrorMessage.innerHTML = '';
            });
        };

        var checkboxTagging = function () {
            if ($notificationCheckbox.dataset.tag) {
                var dataTag = JSON.parse($notificationCheckbox.dataset.tag);
                dataTag.event += $notificationCheckbox.checked
                    ? CONSTANTS.TAGGING_CHECKED_ON
                    : CONSTANTS.TAGGING_CHECKED_OFF;
                dataLayer.push(dataTag);
            }
        };

        var initializeData = function () {
            $checksNotification = $wamiForm.getElementsByClassName(CONSTANTS.NOTIFICATION_CHECK);
            $formLoading = $wamiForm.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $serviceErrors = $wamiForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR);
            $serviceErrorContainers = $wamiForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_CONTAINER
            );
            $serviceErrorMessages = $wamiForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            );
            $hdaNotification = $wamiForm.getElementsByClassName(
                CONSTANTS.HDA_NOTIFICATION_CHECK
            )[0];
        };

        var initializeEvents = function () {
            // Edit notifications
            if ($checksNotification.length > 0) {
                _.each($checksNotification, function ($editNotification) {
                    coned.utils.addGeneralListeners($editNotification, editNotification);

                    $editNotification.addEventListener('focusin', function (event) {
                        query.addClass(event.target.parentElement, CONSTANTS.CHECKBOX_SWITCH_FOCUS);
                    });

                    $editNotification.addEventListener('focusout', function (event) {
                        query.removeClass(
                            event.target.parentElement,
                            CONSTANTS.CHECKBOX_SWITCH_FOCUS
                        );
                    });
                });
            }

            // Validate fields
            new coned.components.ValidateForm(
                CONSTANTS.PREFERENCE_CENTER_FORM,
                '',
                CONSTANTS.FORM_IGNORE_VALIDATION
            );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    WamiForm.prototype.isLoaded = function () {
        return isLoaded;
    };

    return WamiForm;
})();
