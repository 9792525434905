// ==================== ZIPCODE STEP COMPONENT =========================
/* global $ */
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ServiceManagementZipcodeStep = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Selector Constants
        ZIP_CODE_SELECTOR: 'js-service-management-zipcode-input',
        CONTINUE_SELECTOR: 'js-service-management-zipcode-submit',
        ZIP_CODE_FORM: 'js-service-management-form',
        FORM_LOADING: 'js-form-loading',

        // General Constants
        RECAPTCHA: 'js-recaptcha',
        FORM_COMPANY_CODE_NAME: 'serviceManagementCompanyCode',
        FORM_ADDRESS_SCID_NAME: 'serviceManagementScId',
        FORM_ZIPCODE_NAME: 'serviceManagementZipCode',
        NEXT_STEP_LISTENER: 'trigger-next-step',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        VALID_CLASS: 'valid'
    };

    var isLoaded = false;

    /**
     * Constructor
     */
    var ServiceManagementZipcodeStep = function ($ServiceManagementZipcodeStep) {
        /**
         * PRIVATE METHODS
         */
        var $zipCodeInputSelector,
            $submitButtonSelector,
            $zipCodeForm,
            $formLoading,
            _hasRecaptcha,
            recaptcha;

        /**
         * Checks recaptcha on form submit.
         */
        var checkZipFieldSubmitRecaptcha = function () {
            $submitButtonSelector.disabled = true;

            if (_hasRecaptcha) {
                if (recaptcha && recaptcha.getResponse() === '') {
                    recaptcha.checkRecaptcha();
                } else {
                    recaptcha.reset();
                    recaptcha.checkRecaptcha();
                }
            } else {
                validateZipField();
            }
        };

        /**
         * Validates if the ZIP Code value is from the Con Edison area.
         */
        var validateZipField = function (event) {
            if (event) {
                event.preventDefault();
            }
            var serviceUrl = $zipCodeForm.dataset.zipcodeServiceUrl,
                searchFor = $zipCodeForm.dataset.searchForStreetname,
                params;

            //  Service Data
            params = {
                ScId: query.getFormInputValue(
                    $ServiceManagementZipcodeStep,
                    CONSTANTS.FORM_ADDRESS_SCID_NAME
                ),
                SearchFor: searchFor,
                CompanyCode: query.getFormInputValue(
                    $ServiceManagementZipcodeStep,
                    CONSTANTS.FORM_COMPANY_CODE_NAME
                ),
                ZipCode: query.getFormInputValue($zipCodeForm, CONSTANTS.FORM_ZIPCODE_NAME)
            };

            // Add recaptcha for server side validation
            if (_hasRecaptcha && recaptcha && recaptcha.getResponse() !== '') {
                params.Verify = recaptcha.getResponse();
            }

            //  Service Call
            if (coned.utils.isPatternLab()) {
                $formLoading.classList.remove('form-loading--hidden');

                // Success call (Has 3 second delay to simulate service call)
                setTimeout(function () {
                    $formLoading.classList.add('form-loading--hidden');
                    successValidateZipField();
                }, 2000);

                // Error Call With Response from Service
                // var data = {
                //     internalError: true
                // }

                // setTimeout(function() {
                //     $formLoading.classList.add('form-loading--hidden');
                //     errorValidateZipField(data);
                // }, 2000);

                // Error Call Without Service Response
                // setTimeout(function() {
                //     $formLoading.classList.add('form-loading--hidden');
                //     errorValidateZipField(null);
                // }, 2000);
            } else {
                query.getData(
                    serviceUrl,
                    successValidateZipField,
                    errorValidateZipField,
                    params,
                    $formLoading
                );
            }
        };

        /**
         * Handles success of ZIP value.
         */
        var successValidateZipField = function () {
            var changeHref = [],
                hrefQueryParameters = $zipCodeInputSelector.dataset.queryParameter.split(','),
                details = {
                    breadcrumbCopy: $submitButtonSelector.dataset.breadcrumbCopy,
                    nextStep: $submitButtonSelector.dataset.nextStep,
                    inputs: [
                        {
                            name: $zipCodeInputSelector.name,
                            value: $zipCodeInputSelector.value
                        }
                    ]
                };

            _.each(hrefQueryParameters, function (queryParameter) {
                changeHref.push({
                    name: queryParameter,
                    value: $zipCodeInputSelector.value
                });
            });

            if (changeHref.length) {
                details.changeHref = changeHref;
            }

            $submitButtonSelector.disabled = false;

            // Send event to service-management to render service response.
            query.fireEvent($submitButtonSelector, CONSTANTS.NEXT_STEP_LISTENER, details);
        };

        /**
         * Handles error of ZIP value.
         */
        var errorValidateZipField = function (data) {
            var details = {
                breadcrumbCopy: $submitButtonSelector.dataset.breadcrumbCopy,
                nextStep: $submitButtonSelector.dataset.errorStep,
                inputs: [
                    {
                        name: $zipCodeInputSelector.name,
                        value: $zipCodeInputSelector.value
                    }
                ]
            };

            if (data) {
                if (data.internalError) {
                    details.nextStep = $submitButtonSelector.dataset.internalErrorStep;
                    dataLayer.push({
                        event: 'transferService.internal.error'
                    });
                } else {
                    dataLayer.push({
                        event: 'transferService.notInArea.error'
                    });
                }
            } else {
                dataLayer.push({
                    event: 'transferService.notInArea.error'
                });
            }

            $submitButtonSelector.disabled = false;

            // Send event to service-management to render service response.
            query.fireEvent($submitButtonSelector, CONSTANTS.NEXT_STEP_LISTENER, details);
        };

        /**
         * Inits data in the module.
         */
        var initializeData = function () {
            $zipCodeForm = $ServiceManagementZipcodeStep.getElementsByClassName(
                CONSTANTS.ZIP_CODE_FORM
            )[0];
            $zipCodeInputSelector = $ServiceManagementZipcodeStep.getElementsByClassName(
                CONSTANTS.ZIP_CODE_SELECTOR
            )[0];
            $submitButtonSelector = $ServiceManagementZipcodeStep.getElementsByClassName(
                CONSTANTS.CONTINUE_SELECTOR
            )[0];
            $formLoading = $ServiceManagementZipcodeStep.getElementsByClassName(
                CONSTANTS.FORM_LOADING
            )[0];
            _hasRecaptcha = $ServiceManagementZipcodeStep.getElementsByClassName(
                CONSTANTS.RECAPTCHA
            ).length
                ? true
                : false;
        };

        /**
         * Inits events in the module.
         */
        var initializeEvents = function () {
            $zipCodeInputSelector.addEventListener('keyup', function (event) {
                if (!event) {
                    event = window.event;
                }

                event.preventDefault();

                var zipCodeLength = $zipCodeInputSelector.value.length;

                $submitButtonSelector.disabled = zipCodeLength == 5 ? !$($zipCodeForm).validate().checkForm() : true;

                // Submit form on enter
                if (event.keyCode == 13) {
                    $submitButtonSelector.click();
                }
            });

            // form validation
            new coned.components.ValidateForm(
                '.' + CONSTANTS.ZIP_CODE_FORM,
                checkZipFieldSubmitRecaptcha,
                '.' + CONSTANTS.VALIDATE_IGNORE
            );

            // Recaptcha Validate
            if (_hasRecaptcha) {
                recaptcha = new coned.components.Recaptcha(
                    $zipCodeForm,
                    validateZipField,
                    validateZipField
                );
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ServiceManagementZipcodeStep.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ServiceManagementZipcodeStep;
})();
