// ==================== POPUP COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PopupModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HIDDEN_CLASS: 'hidden',
        BUTTON_POPUP: 'js-popup-button',
        MAIN_CONTENT_ID: 'mainContent',
        CLOSE_BUTTON: 'js-popup-close',
        OPEN_BUTTON: 'js-popup-open',
        SHOW_POPUP: 'js-show-popup',
        HAS_SLIDER_CLASS: 'js-has-slider',
        POPUP_SHOW_SECONDARY_EVENT: 'popup-show-secondary',
        POPUP_VISIBLE_EVENT: 'popup-visible',
        UNAUTHENTICATED_LOGIN: 'js-unauthenticated-login',
        LOGIN_BUTTON: 'js-login-button',
        UNAUTH_LOGIN_BUTTON: 'js-unauth-login-button',
        LOGIN_SUBMIT_BUTTON: 'js-transactional-submit-selector',
        DATA_ERROR_BACK_FOCUS_CLASS: 'data-error-back-focus-class',
        DATA_ERROR_BACK_FOCUS_INDEX:'data-error-back-focus-index',

        // New Feature Messaging type popup exclusive
        NEW_FEATURE_MESSAGING: 'js-new-feature-messaging',
        COOKIE_NAME: 'newFeatureMessagingShouldShow',

        // New Feature Messaging type popup tagging
        FEATURE_MESSAGING_START_TAG: 'feature.messaging.start',
        FEATURE_MESSAGING_CLOSE_TAG: 'feature.messaging.close'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var PopupModule = function ($Popup) {
        /**
         * PRIVATE METHODS
         */
        var $popUpOpenButtons,
            $popUpCloseButtons,
            $showPopup,
            $focusableElements,
            $firstFocusableElement,
            errorPopup,
            $unauthenticatedLogin,
            $showPopupsPriorityQueue,
            newFeatureMessagingTimer;

        /**
        * Tagging for New Feature Messaging type popup
        * On popup open event and timer
        */
        var addNewFeatureMessagingTaggingStart = function ($popup) {
            var newFeatureMessagingElapsedSeconds = 0;

            dataLayer.push({
                event: CONSTANTS.FEATURE_MESSAGING_START_TAG
            });

            newFeatureMessagingTimer = setInterval(function() {
                ++newFeatureMessagingElapsedSeconds;
                // Save timer ID and seconds, as the timer starts and stops on different instances of
                // PopupModule when there's another auto-show popup other than New feature Messaging
                $popup.dataset.taggingOpenSeconds = newFeatureMessagingElapsedSeconds;
                $popup.dataset.taggingTimerId = newFeatureMessagingTimer;
            }, 1000);
        };

        /**
        * Tagging for New Feature Messaging type popup
        * On popup close events and clear timer
        */
        var addNewFeatureMessagingTaggingClose = function ($popup) {
            dataLayer.push({
                messageTime: $popup.dataset.taggingOpenSeconds
            });

            dataLayer.push({
                event: CONSTANTS.FEATURE_MESSAGING_CLOSE_TAG
            });

            clearInterval($popup.dataset.taggingTimerId);
        };

        var closePopup = function(popUp) {
            popUp.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Only for auto-show popups
            if (query.hasClass(popUp, CONSTANTS.SHOW_POPUP)) {
                popUp.dataset.alreadyClosed = true;
            }

            if(popUp && popUp.dataset.noBackFocus) {
                return;
            } else {
                //Check error message back focus
                if(errorPopup) {
                    var errorBackFocusClass = $Popup.dataset.errorBackFocus,
                        $errorBackFocusElements = document.getElementsByClassName(errorBackFocusClass),
                        errorBackFocusIndex = popUp.dataset.errorBackFocusIndex;

                    // When multiple buttons with the same class open a single popup we use index to determine the actual button
                    if (errorBackFocusIndex) {
                        popUp.BackFocus = $errorBackFocusElements[errorBackFocusIndex];
                        popUp.removeAttribute(CONSTANTS.DATA_ERROR_BACK_FOCUS_CLASS);
                        popUp.removeAttribute(CONSTANTS.DATA_ERROR_BACK_FOCUS_INDEX);
                    } else {
                        popUp.BackFocus = $errorBackFocusElements[0];
                    }
                }

                // A11y Handler back to focus
                if (popUp.BackFocus) {
                    //Check if no scroll on backfocus
                    var noScroll = $Popup.dataset.backFocusScroll === 'false' ? true : false;

                    if (noScroll) {
                        popUp.BackFocus.focus({preventScroll: true});
                    } else {
                        popUp.BackFocus.focus();
                    }

                } else {

                    // Check if auto-show popups with secondary priority are still pending to be shown after closing 
                    // the main popup and subsequents on the queue, trigger event to open the next queued
                    if (
                        $showPopupsPriorityQueue.length > 1 &&
                        pendingSecondaryPriorityPopup()
                    ) {
                        var $pendingSecondaryPriorityPopup = pendingSecondaryPriorityPopup();

                        coned.utils.triggerEvent($pendingSecondaryPriorityPopup, CONSTANTS.POPUP_SHOW_SECONDARY_EVENT);

                    // Check if from unauthenticated login
                    } else if (query.hasClass(popUp, CONSTANTS.UNAUTH_LOGIN_BUTTON)) {
                        popUp.classList.remove(CONSTANTS.UNAUTH_LOGIN_BUTTON);
                        $unauthenticatedLogin.getElementsByClassName(CONSTANTS.LOGIN_SUBMIT_BUTTON)[0].focus();

                    } else {
                        document.getElementById(CONSTANTS.MAIN_CONTENT_ID).focus();
                    }
                }
            }

            // New Feature Messaging type popup exclusive
            if (popUp.dataset.cookie) {
                setCookie(popUp);
            }

            if (query.hasClass(popUp, CONSTANTS.NEW_FEATURE_MESSAGING)) {
                addNewFeatureMessagingTaggingClose(popUp);
            }
        };

        /**
         * Check which is the next auto show popup still pending to be opened, if any
         * @return {HTMLElement} Next pending auto show popup or undefined if none found
         */
        var pendingSecondaryPriorityPopup = function () {
            var $pendingPopup;

            for (var index = 0; index < $showPopupsPriorityQueue.length; index++) {
                if (!$showPopupsPriorityQueue[index][0].dataset.alreadyClosed) {
                    $pendingPopup = $showPopupsPriorityQueue[index][0];

                    return $pendingPopup;
                }
            }

            return $pendingPopup;
        };

        /**
         * Opens the next priority popup on queue
         * @param {Event} event Event that triggered the function
         */        
        var openSecondaryPriorityPopup = function (event) {
            var $popup = event.target,
                $popupFocusableElements = $popup.querySelectorAll(
                    coned.constants.FOCUSABLE_ELEMENTS_QUERY
                ),
                $popupFirstFocusableElement = $popupFocusableElements[0],
                _isNewFeatureMessaging = query.hasClass(
                    $popup, CONSTANTS.NEW_FEATURE_MESSAGING
                ),
                $newFeatureMessagingPopup = _isNewFeatureMessaging && $popup,
                _hasSlider = query.hasClass(
                    $popup, CONSTANTS.HAS_SLIDER_CLASS
                );

            // Delay opening secondary popup so user is able to notice that a new popup has opened
            setTimeout(function () {
                $popup.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $popupFirstFocusableElement.focus();

                if (_hasSlider) {
                    coned.utils.triggerEvent($popup, CONSTANTS.POPUP_VISIBLE_EVENT);
                    _isNewFeatureMessaging && addNewFeatureMessagingTaggingStart($newFeatureMessagingPopup);
                }
            }, 500);
        };

        var popUpHandler = function (event) {
            event.preventDefault();

            var showPopup = query.hasClass(this, CONSTANTS.OPEN_BUTTON);

            if (showPopup) {
                $Popup.classList.remove(CONSTANTS.HIDDEN_CLASS);

                // A11y Handler back to focus
                event.currentTarget && ($Popup.BackFocus = event.currentTarget);

                var button_cancel = $Popup.getElementsByClassName(CONSTANTS.CLOSE_BUTTON);
                button_cancel[0].focus();
            } else {
                closePopup($Popup, event);
            }

        };

        /**
         * Sets a priority queue for the auto show poups based on their data-popup-priority attribute.
         * If no data-popup-priority if found, popup will be pushed to the end of the queue.
         * @return {Array} Containing auto-show popups and their priority in the form of [[$popupN, 1], [$popupM, 2]]
         */
        var setPopupsPriorityQueue = function () {
            var popupsOrder = [];

            // Order array by priority, if it has no priority it will be added last
            Array.from($showPopup).forEach(function ($popup) {
                var $popupPriority = [
                    $popup, 
                    $popup.dataset.popupPriority ?
                        $popup.dataset.popupPriority :
                        100
                ];

                if (popupsOrder.length === 0) {
                    popupsOrder.push($popupPriority);
                } else {
                    var insertIndex = 0;

                    for (var index = 0; index < popupsOrder.length; index++) {
                        if ($popupPriority[1] >= popupsOrder[index][1]) {
                            insertIndex = index+1;
                        }
        
                        if ($popupPriority[1] < popupsOrder[index][1]) {
                            insertIndex = index;
                            break;
                        }
                    }
        
                    popupsOrder.splice(insertIndex, 0, $popupPriority);
                }
            });

            return popupsOrder;
        };

        /**
         * Handles auto show popups on page load.
         * If there are more than one, queues them to open one after the other is closed.
         */
        var popUpVisible = function () {
            if ($showPopup.length > 0) {
                var _isOnePopupVisible = false;

                _.each($showPopupsPriorityQueue, function ($popupArr) {
                    var $popup = $popupArr[0],
                        _hasSlider = query.hasClass(
                            $popup, CONSTANTS.HAS_SLIDER_CLASS
                        ),
                        _isNewFeatureMessaging = query.hasClass(
                            $popup, CONSTANTS.NEW_FEATURE_MESSAGING
                        ),
                        _isVisible;

                    if (
                        !_isOnePopupVisible
                    ) {
                        $popup.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        _isOnePopupVisible = true;

                    // For secondary+ priority popups add custom event listener to be able to trigger 
                    // opening them after main popup is closed and so on (queued)
                    } else {
                        $popup.addEventListener(CONSTANTS.POPUP_SHOW_SECONDARY_EVENT, openSecondaryPriorityPopup);
                    }

                    _isVisible = !query.hasClass($popup, CONSTANTS.HIDDEN_CLASS);

                    if (_hasSlider && _isVisible) {
                        coned.utils.triggerEvent($popup, CONSTANTS.POPUP_VISIBLE_EVENT);
                        _isNewFeatureMessaging && addNewFeatureMessagingTaggingStart($popup);
                    }
                });

                // First focusable element is visible
                if (coned.utils.isDomVisible($firstFocusableElement)) {
                    $firstFocusableElement.focus();
                // Look for the first focusable element that is actually visible
                } else {
                    for (var index = 1; index < $focusableElements.length;  index++) {
                        if (coned.utils.isDomVisible($focusableElements[index])) {
                            $focusableElements[index].focus();
                            break;
                        }
                    }
                }
            }
        };

        /**
         * Close popup with keyboard: ESC key_code : 27
         * @param {*} event
         * @param {*} popup
         */
        var handlerPopUpKeyBoard = function (event, popup) {
            if (event.keyCode == coned.constants.KEY_CODE.ESC) {
                //Check if a popup is visible on the screen.
                var popUpOpen = !query.hasClass(popup, CONSTANTS.HIDDEN_CLASS);
                if (popUpOpen) {
                    closePopup(popup, event);
                }
            }
        };

        /**
         * Set the cookie so the BE can decide wether or not the next time the popup needs to be shown/added.
         * data-cookie value will change everytime there are new features to show.
         * @param {Element} $popUp
         */
        var setCookie = function ($popUp) {
            var _variableName = CONSTANTS.COOKIE_NAME,
                _currentCookieValue = query.getCookie(_variableName),
                _cookieValue = $popUp.dataset.cookie,
                expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

            if (
                !_currentCookieValue ||
                (_currentCookieValue && _currentCookieValue !== _cookieValue)
            ) {
                query.setCookie(_variableName, _cookieValue, expiryDate);
            }
        };

        var initializeData = function () {
            $popUpOpenButtons = document.querySelectorAll(
                '.' + CONSTANTS.BUTTON_POPUP + '.' + $Popup.dataset.openButton
            );
            $popUpCloseButtons = $Popup.getElementsByClassName(
                CONSTANTS.CLOSE_BUTTON
            );
            $showPopup = document.getElementsByClassName(
                CONSTANTS.SHOW_POPUP
            );
            $showPopupsPriorityQueue = setPopupsPriorityQueue();
            $focusableElements = $Popup.querySelectorAll(
                coned.constants.FOCUSABLE_ELEMENTS_QUERY
            );
            $firstFocusableElement = $focusableElements[0];
            errorPopup = $Popup.dataset.errorPopup;
            $unauthenticatedLogin = document.getElementsByClassName(
                CONSTANTS.UNAUTHENTICATED_LOGIN
            )[0];
        };

        var initializeEvents = function () {
            _.each($popUpOpenButtons, function ($button) {
                $button.addEventListener('click', popUpHandler);
            });

            _.each($popUpCloseButtons, function ($button) {
                $button.addEventListener('click', popUpHandler);
            });

            new coned.utils.addFocusTrap($Popup);

            // Pop ups that should be displayed when the page starts
            if ($Popup.classList.contains(CONSTANTS.SHOW_POPUP)) {

                setTimeout(popUpVisible, 500);

            }

            $Popup.addEventListener('keydown', function (event) {
                handlerPopUpKeyBoard(event, $Popup);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PopupModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PopupModule;
})();
