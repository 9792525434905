// ==================== ACCOUNT DETAILS ELEGIBLE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AccountDetailsElegible = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_SELECTOR: '.js-elegible-details-form',
        TEXT_INFORMATION: 'js-text-info',
        INPUT_INFORMATION: 'js-input-info',
        EDIT_BUTTON_SELECTOR: 'js-edit-button',
        HIDDEN_CLASS: 'hidden',
        OPEN_CONTAINER_CLASS: 'account-details__container--open'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AccountDetailsElegible = function ($accountForm) {
        /**
         * PRIVATE METHODS
         */
        var $editButtonSelector, $textInfo, $inputInfo;

        var closeEdit = function () {
            query.removeClass($textInfo, CONSTANTS.HIDDEN_CLASS);
            query.addClass($inputInfo, CONSTANTS.HIDDEN_CLASS);
            $editButtonSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $accountForm.classList.remove(CONSTANTS.OPEN_CONTAINER_CLASS);
        };

        var initializeData = function () {
            $textInfo = $accountForm.getElementsByClassName(CONSTANTS.TEXT_INFORMATION);
            $inputInfo = $accountForm.getElementsByClassName(CONSTANTS.INPUT_INFORMATION);
            $editButtonSelector = $accountForm.getElementsByClassName(
                CONSTANTS.EDIT_BUTTON_SELECTOR
            )[0];
        };

        var initializeEvents = function () {
            new coned.components.AccountDetails($accountForm);

            // form validation
            new coned.components.ValidateForm(CONSTANTS.FORM_SELECTOR, closeEdit);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AccountDetailsElegible.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AccountDetailsElegible;
})();
