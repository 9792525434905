// ==================== DASHBOARD THIRD PARTY COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardThirdPartyAccounts = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Tab Constants
        TAB_CONTENT: 'js-third-party-tab-content',

        // Table Constants
        ACCOUNTS_FORM: 'js-third-party-form',
        ACCOUNTS_CONTAINER: 'js-accounts-container',
        ACCOUNTS_NO_CONTENT: 'js-third-party-no-content',
        ACCOUNT_ITEM: 'js-third-party-checkbox-container',
        ACCOUNT_INPUT: 'js-third-party-checkbox',
        ACCOUNT_EXPAND: 'js-third-party-expand',
        ACCOUNT_EXPORT: 'js-third-party-export-button',
        ACCOUNT_LIST_EXPORT: 'js-third-party-account-list-export-button',

        //Page Loading Animation
        FORM_LOADING: 'js-form-loading',

        // Search Constants
        SEARCH_MODULE: 'js-text-search-module',
        SEARCH_INPUT: 'js-text-search-input',

        // Select All Constants
        SELECT_ALL_INPUT: 'js-third-party-select-all',
        TOTAL_AMOUNT: 'js-total-amount',

        // Form Actions Constants
        DESELECT_BUTTON: 'js-third-party-deselect',
        SUBMIT_BUTTON: 'js-third-party-submit',

        // Pagination Constants
        PAGINATION_MODULE: 'js-pagination-wrapper',
        PAGINATION_RESULTS: 'js-pagination-results',
        PAGINATION_TOTAL_CLASS: 'js-total',
        PAGINATION_RESET_SELECTOR: 'js-pagination-reset',
        PAGINATION_ITEM_HIDDEN: 'js-pagination-item-hidden',

        // Popup Constants
        POPUP_SUBMIT: 'js-third-party-submit',
        POPUP_REMOVE_ACCESS_BUTTON_CLASS: 'js-third-party-remove-access-btn',
        POPUP_LOADING_ANIMATION: 'js-loading-animation',
        POPUP_LOADING_ANIMATION_PLAY: 'js-animation-play',
        POPUP_CONFIRMATION_SUFFIX: '-confirmation-popup',
        POPUP_LOADING_SUFFIX: '-loading-popup',
        POPUP_SUCCESS_SUFFIX: '-success-popup',
        POPUP_ERROR_SUFFIX: '-error-popup',
        POPUP_ERROR_EXPORT_LIST_SUFFIX: '-export-list-error-popup',
        POPUP_ERROR_TITLE: 'js-popup-title',
        POPUP_ERROR_DESCRIPTION: 'js-popup-description',

        // Pending Access Element Creation Constants
        ACCOUNT_ROW_CLASSES: [
            'dashboard-third-party-accounts__table-row',
            'js-third-party-checkbox-container'
        ],
        ACCOUNT_ROW_WRAPPER_CLASS: 'dashboard-third-party-accounts__table-row-wrapper',
        ACCOUNT_ITEM_CLASSES: ['dashboard-third-party-accounts__table-item'],
        ACCOUNT_CHECKBOX_CONTAINER_CLASSES: [
            'dashboard-third-party-accounts__table-item',
            'dashboard-third-party-accounts__table-item--checkbox'
        ],
        ACCOUNT_LABEL_CONTAINER_CLASSES: ['coned-checkbox', 'coned-checkbox__label'],
        ACCOUNT_CHECKBOX_INPUT_CLASSES: [
            'coned-checkbox__input',
            'dashboard-third-party-accounts__checkbox',
            'js-third-party-checkbox',
            'js-checkbox-selector'
        ],
        ACCOUNT_CHECKBOX_INDICATOR_CLASS: 'coned-checkbox__indicator',
        ACCOUNT_CHECKBOX_ANIMATION_CLASS: 'coned-checkbox__animation-block',
        ACCOUNT_CHECKBOX_TYPE: 'checkbox',

        DIV_TAG: 'div',
        SPAN_TAG: 'span',
        LABEL_TAG: 'label',
        INPUT_TAG: 'input',

        // General Constants
        ACTIVE_ROW: 'active-row',
        REQUEST_AMOUNT: 'js-selected-amount',
        CHECKBOX_CONTAINER: 'js-checkbox-container',
        CHECKBOX_CHECKED_CLASS: 'coned-checkbox--checked',
        ITEM_IGNORE_SELECTOR: 'js-item-ignore',
        SITECORE_ID: 'ScId',
        HIDDEN_CLASS: 'hidden',
        ARIA_LABEL: 'aria-label',
        ARIA_EXPANDED: 'aria-expanded',
        POPUP_CLOSE: "js-popup-close",
        TRUE_VALUE: 'true'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardThirdPartyAccounts = function ($dashboardThirdPartyAccounts) {
        /**
         * PRIVATE VARIABLES
         */

        var // Table Variables
            $accountsForm,
            $accountsContainer,
            $accountsNoContent,
            $accountsNoContentHide,
            $accountInputs,
            $accountItems,
            $accountItemsShown,
            $accountItemsHidden,
            $accountSelectedItems,
            $accountsListExportButton,
            clickedExportButtonIndex,
            _accountsSelectedValues,
            //Page Loading
            $formLoading,
            // Search Variables
            $searchModule,
            $searchInput,
            // Select All Variables
            $selectAllInput,
            $selectTotalAmount,
            // Form Actions Variables
            $deselectButton,
            $submitButton,
            // Popup Variables
            $popupAmount,
            $confirmationPopup,
            $confirmationPopupSubmit,
            $loadingPopup,
            $loadingAnimation,
            $successPopup,
            $exportListErrorPopup,
            $errorPopup,
            $errorPopupTitle,
            $errorPopupDescription;

        /**
         * PRIVATE METHODS
         */

        /**
         * Handles the cancel request submit
         */
        var removeAccountSubmit = function () {
            if ($confirmationPopup) {
                _.each($popupAmount, function ($item) {
                    $item.innerHTML = $accountSelectedItems.length;
                });
            } else {
                removeAccountConfirmationSubmit();
            }
        };

        /**
         * Handles the cancel request submit
         */
        var removeAccountConfirmationSubmit = function () {
            var serviceUrl = $accountsForm.dataset.removeAccountsServiceUrl,
                $parentElement = query.selectParentElement(
                    $dashboardThirdPartyAccounts,
                    CONSTANTS.TAB_CONTENT
                ),
                params;

            if (_accountsSelectedValues.length >= 2 && $loadingPopup && $loadingAnimation) {
                query.fireEvent($loadingAnimation, 'start-animation');
                $loadingPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            if ($confirmationPopup) {
                $confirmationPopup.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            params = {
                ScId: query.getFormInputValue($parentElement, CONSTANTS.SITECORE_ID),
                AccountNumbers: _accountsSelectedValues.join(',')
            };

            params = JSON.stringify(params);
            query.postData(serviceUrl, successRemoveAccounts, errorRemoveAccounts, params, true);
        };

        /**
         * Deletes all the checkboxes from the list if the response succeeded
         */
        var successRemoveAccounts = function (data) {
            if (coned.utils.isPatternLab()) {
                data = {
                    AccountsCurrentCount: 969,
                    AccountsNewCount: 30,
                    HadReachedAccountsWarningPoint: false,
                    HadReachedAccountsLimit: false
                };
            }

            // Update counters
            if (data) {
                data.UpdateCounters = true;
            } else {
                data = { UpdateCounters: true };
            }
            query.fireEvent(document, 'update-counters', data);

            while ($accountSelectedItems.length > 0) {
                $accountsContainer.removeChild($accountSelectedItems.pop());
                _accountsSelectedValues.pop();
            }

            $accountInputs = $dashboardThirdPartyAccounts.querySelectorAll(
                '.' + CONSTANTS.ACCOUNT_INPUT + ':not([disabled])'
            );

            if (!$accountItems.length) {
                if ($accountsNoContent) {
                    $accountsNoContent.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $accountsForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $dashboardThirdPartyAccounts.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                if ($accountsNoContentHide && $accountsNoContentHide.length) {
                    _.each($accountsNoContentHide, function ($item) {
                        $item.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });
                }
            }

            if (!$accountInputs.length) {
                $selectAllInput.disabled = true;
                query
                    .selectParentElement($selectAllInput, CONSTANTS.CHECKBOX_CONTAINER)
                    .classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            if ($selectAllInput.checked) {
                $selectAllInput.checked = false;
                $selectAllInput.parentElement.classList.remove(CONSTANTS.CHECKBOX_CHECKED_CLASS);
            }

            if ($searchInput) {
                $searchInput.value = '';
            }

            if (
                $loadingPopup &&
                !$loadingPopup.classList.contains(CONSTANTS.HIDDEN_CLASS) &&
                $loadingAnimation
            ) {
                query.fireEvent($loadingAnimation, 'stop-animation');
                $loadingPopup.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            if ($successPopup) {
                $successPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);

                var successPopupCloseButton = $successPopup.getElementsByClassName(CONSTANTS.POPUP_CLOSE)[0];
                successPopupCloseButton && successPopupCloseButton.focus();
            }

            if ($selectTotalAmount) {
                $selectTotalAmount.innerHTML = $accountInputs.length;
            }

            $submitButton.disabled = true;

            resetPagination();
        };

        /**
         * If response failed, show an error and hide the module
         */
        var errorRemoveAccounts = function (data) {
            if ($loadingPopup && $loadingAnimation && $errorPopup) {
                $loadingAnimation.classList.remove(CONSTANTS.POPUP_LOADING_ANIMATION_PLAY);
                $loadingPopup.classList.add(CONSTANTS.HIDDEN_CLASS);
                $errorPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $errorPopup.dataset.errorBackFocus = CONSTANTS.POPUP_REMOVE_ACCESS_BUTTON_CLASS;

                $errorPopupTitle.innerHTML = $errorPopup.dataset.defaultTitle;
                $errorPopupDescription.innerHTML = $errorPopup.dataset.defaultDescription;

                if (data) {
                    if (data.errorTitle) {
                        $errorPopupTitle.innerHTML = data.errorTitle;
                    }

                    if (data.errorMsg) {
                        $errorPopupDescription.innerHTML = data.errorMsg;
                    }
                }

                var errorPopupCloseButton = $errorPopup.getElementsByClassName(CONSTANTS.POPUP_CLOSE)[0];
                errorPopupCloseButton && errorPopupCloseButton.focus();
            }
        };

        /**
         * Checks/Unchecks all shown accounts on select all.
         */
        var triggerSelectAll = function () {
            _.each($accountItemsShown, function ($item) {
                if (!$item.classList.contains(CONSTANTS.PAGINATION_ITEM_HIDDEN)) {
                    var $checkbox = $item.getElementsByClassName(CONSTANTS.ACCOUNT_INPUT)[0];

                    if ($checkbox.checked !== $selectAllInput.checked) {
                        $checkbox.click();
                    }
                }
            });

            if ($accountItemsHidden.length) {
                setTimeout(function () {
                    _.each($accountItemsHidden, function ($item) {
                        if (!$item.classList.contains(CONSTANTS.PAGINATION_ITEM_HIDDEN)) {
                            var $checkbox = $item.getElementsByClassName(
                                CONSTANTS.ACCOUNT_INPUT
                            )[0];

                            if ($checkbox.checked !== $selectAllInput.checked) {
                                $checkbox.click();
                            }
                        }
                    });
                }, 1);
            }
        };

        /**
         * Resets pending access items lists
         */
        var resetItems = function () {
            $accountItemsShown = $dashboardThirdPartyAccounts.querySelectorAll(
                '.' +
                CONSTANTS.ACCOUNT_ITEM +
                ':not(.' +
                CONSTANTS.HIDDEN_CLASS +
                '):not(.' +
                CONSTANTS.ITEM_IGNORE_SELECTOR +
                '):not(.' +
                CONSTANTS.PAGINATION_ITEM_HIDDEN +
                ')'
            );

            $accountItemsHidden = $dashboardThirdPartyAccounts.querySelectorAll(
                '.' +
                CONSTANTS.ACCOUNT_ITEM +
                '.' +
                CONSTANTS.HIDDEN_CLASS +
                ':not(.' +
                CONSTANTS.ITEM_IGNORE_SELECTOR +
                '):not(.' +
                CONSTANTS.PAGINATION_ITEM_HIDDEN +
                ')'
            );

            if ($selectTotalAmount) {
                $selectTotalAmount.innerHTML =
                    $accountItemsShown.length + $accountItemsHidden.length;
            }
        };

        /**
         * Resets the pagination for a container
         */
        var resetPagination = function () {
            var paginationDivider = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.PAGINATION_MODULE
            )[0].dataset.divider,
                $paginationResults = $dashboardThirdPartyAccounts.getElementsByClassName(
                    CONSTANTS.PAGINATION_RESULTS
                )[0],
                $paginationTotal = $dashboardThirdPartyAccounts.getElementsByClassName(
                    CONSTANTS.PAGINATION_TOTAL_CLASS
                )[0],
                $paginationReset = $dashboardThirdPartyAccounts.getElementsByClassName(
                    CONSTANTS.PAGINATION_RESET_SELECTOR
                )[0];

            // Hide all results
            Array.prototype.forEach.call($paginationResults.children, function ($result) {
                query.addClass($result, $paginationResults.dataset.resultsHide);
                query.removeClass($result, CONSTANTS.PAGINATION_ITEM_HIDDEN);
            });

            // Show the corresponding page results
            for (var resultsIndex = 0; resultsIndex < paginationDivider; resultsIndex++) {
                query.removeClass(
                    $paginationResults.children[resultsIndex],
                    $paginationResults.dataset.resultsHide
                );
            }

            $paginationTotal.innerHTML = $paginationResults.children.length;
            $paginationReset.click();

            resetItems();
        };

        /**
         * Triggers the animation for the checkbox
         * (Taken from coned.components.form-validation.js to avoid re-initializing everything when elements are added)
         */
        var checkboxChangeTrigger = function (event) {
            var $target = event.currentTarget,
                $parentTarget = $target.parentElement;

            if ($target.checked) {
                query.addClass($parentTarget, CONSTANTS.CHECKBOX_CHECKED_CLASS);

                _accountsSelectedValues.push($target.value);
                $accountSelectedItems.push(
                    query.selectParentElement($target, CONSTANTS.ACCOUNT_ITEM)
                );

                $submitButton.disabled = false;

                if (
                    $accountSelectedItems.length == $accountInputs.length &&
                    !$selectAllInput.checked
                ) {
                    $selectAllInput.checked = true;
                    $selectAllInput.parentElement.classList.add(CONSTANTS.CHECKBOX_CHECKED_CLASS);
                }
            } else {
                query.removeClass($parentTarget, CONSTANTS.CHECKBOX_CHECKED_CLASS);

                _accountsSelectedValues.splice(_accountsSelectedValues.indexOf($target.value), 1);
                $accountSelectedItems.splice(
                    $accountSelectedItems.indexOf(
                        query.selectParentElement($target, CONSTANTS.ACCOUNT_ITEM)
                    ),
                    1
                );

                if ($selectAllInput.checked) {
                    $selectAllInput.checked = false;
                    $selectAllInput.parentElement.classList.remove(
                        CONSTANTS.CHECKBOX_CHECKED_CLASS
                    );
                }

                if (!_accountsSelectedValues.length) {
                    $submitButton.disabled = true;
                }
            }
        };

        /**
         * Triggers the expand for the row
         */
        var expandEventTrigger = function (event) {
            var $arrowButton = event.currentTarget;
            var $target = query.selectParentElement($arrowButton, CONSTANTS.ACCOUNT_ITEM);

            if ($target.classList.contains(CONSTANTS.ACTIVE_ROW)) {
                $target.classList.remove(CONSTANTS.ACTIVE_ROW);
                $arrowButton.setAttribute(CONSTANTS.ARIA_LABEL, $arrowButton.dataset.openText);
                $arrowButton.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
            } else {
                $target.classList.add(CONSTANTS.ACTIVE_ROW);
                $arrowButton.setAttribute(CONSTANTS.ARIA_LABEL, $arrowButton.dataset.closeText);
                $arrowButton.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            }
        };

        /**
         * Call the export service
         */
        var exportDataServiceCall = function (event) {
            event.preventDefault();

            var $target = event.currentTarget,
                serviceUrl = $accountsForm.dataset.exportServiceUrl,
                $parentElement = query.selectParentElement(
                    $dashboardThirdPartyAccounts,
                    CONSTANTS.TAB_CONTENT
                ),
                params = {
                    ScId: query.getFormInputValue($parentElement, CONSTANTS.SITECORE_ID),
                    Maid: $target.dataset.maid
                };

            clickedExportButtonIndex = $target.dataset.buttonIndex;

            if ($loadingPopup && $loadingAnimation) {
                query.fireEvent($loadingAnimation, 'start-animation');
                $loadingPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            query.getData(
                serviceUrl,
                successExportDataServiceCall,
                errorExportDataServiceCall,
                params
            );
        };

        /**
         * Success export service call handler
         */
        var successExportDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                window.location.href =
                    'https://file-examples.com/wp-content/uploads/2017/02/file_example_XLS_10.xls';
            } else if (data && data.link && data.link !== '') {
                // Set file location
                window.location.href = data.link;
            } else {
                errorExportDataServiceCall();
            }

            if (
                $loadingPopup &&
                !$loadingPopup.classList.contains(CONSTANTS.HIDDEN_CLASS) &&
                $loadingAnimation
            ) {
                query.fireEvent($loadingAnimation, 'stop-animation');
                $loadingPopup.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        /**
         * Error export service call handler
         */
        var errorExportDataServiceCall = function (data) {
            if (
                $loadingPopup &&
                !$loadingPopup.classList.contains(CONSTANTS.HIDDEN_CLASS) &&
                $loadingAnimation
            ) {
                query.fireEvent($loadingAnimation, 'stop-animation');
                $loadingPopup.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            if ($errorPopup) {
                $errorPopup.dataset.errorBackFocus = CONSTANTS.ACCOUNT_EXPORT;
                $errorPopup.dataset.errorBackFocusIndex = clickedExportButtonIndex;
                $errorPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $errorPopupTitle.innerHTML = $errorPopup.dataset.defaultTitle;
                $errorPopupDescription.innerHTML = $errorPopup.dataset.defaultDescription;

                if (data) {
                    if (data.errorTitle) {
                        $errorPopupTitle.innerHTML = data.errorTitle;
                    }

                    if (data.errorMsg) {
                        $errorPopupDescription.innerHTML = data.errorMsg;
                    }
                }

                var $errorPopupCloseButton = $errorPopup.getElementsByClassName(CONSTANTS.POPUP_CLOSE)[0];
                $errorPopupCloseButton && $errorPopupCloseButton.focus();
            }
        };

        /**
         * Call the export service
         */
        var exportAccountsList = function (event) {
            event.stopPropagation();
            event.preventDefault();

            if ($exportListErrorPopup) {
                $exportListErrorPopup.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            var serviceUrl = $accountsForm.dataset.exportAccountListServiceUrl,
                $parentElement = query.selectParentElement(
                    $dashboardThirdPartyAccounts,
                    CONSTANTS.TAB_CONTENT
                ),
                params = {
                    ScId: query.getFormInputValue($parentElement, CONSTANTS.SITECORE_ID)
                };

            query.getData(
                serviceUrl,
                successExportAccountsList,
                errorExportAccountsList,
                params,
                $formLoading
            );
        };

        /**
         * Success export service call handler
         */
        var successExportAccountsList = function (data) {
            if (coned.utils.isPatternLab()) {
                window.location.href =
                    'https://file-examples.com/wp-content/uploads/2017/02/file_example_XLS_10.xls';
            } else if (data && data.link && data.link !== '') {
                // Set file location
                window.location.href = data.link;
            } else {
                errorExportAccountsList();
            }
        };

        /**
         * Error export service call handler
         */
        var errorExportAccountsList = function () {
            if ($exportListErrorPopup) {
                $exportListErrorPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        /**
         * Unchecks all accounts on Clear Selection.
         */
        var clearSelectionEvent = function () {
            if ($selectAllInput.checked) {
                $selectAllInput.checked = false;
                $selectAllInput.parentElement.classList.remove(CONSTANTS.CHECKBOX_CHECKED_CLASS);
            }

            _.each($accountItems, function ($item) {
                var $checkbox = $item.getElementsByClassName(CONSTANTS.ACCOUNT_INPUT)[0];

                if ($checkbox.checked) {
                    $checkbox.click();
                }
            });
        };

        /**
         * Creates new account on the table
         */
        var createAccount = function (event) {
            if (event && event.details && event.details.Accounts) {
                _.each(event.details.Accounts, function (data) {
                    var $item = _.chain($accountItems)
                        .map(function (item) {
                            return item.getElementsByClassName(CONSTANTS.ACCOUNT_INPUT)[0];
                        })
                        .findWhere({ id: event.details.AccountNumber })._wrapped;

                    if ($item) {
                        var $itemContainer = query.selectParentElement(
                            $item,
                            CONSTANTS.ACCOUNT_ITEM
                        );

                        $accountsContainer.removeChild($itemContainer);
                    }

                    var $accountItemDiv,
                        $accountWrapperDiv,
                        $accountCheckbox,
                        $accountInput,
                        $accountIndicator,
                        $accountAnimation,
                        $accountLabel;

                    // Create all elements of the checkbox
                    $accountItemDiv = document.createElement(CONSTANTS.DIV_TAG);
                    _.each(CONSTANTS.ACCOUNT_ROW_CLASSES, function (className) {
                        $accountItemDiv.classList.add(className);
                    });

                    if (data.RowClasses && data.RowClasses.length) {
                        _.each(data.RowClasses, function (className) {
                            $accountItemDiv.classList.add(className);
                        });
                    }

                    $accountWrapperDiv = document.createElement(CONSTANTS.DIV_TAG);
                    $accountWrapperDiv.classList.add(CONSTANTS.ACCOUNT_ROW_WRAPPER_CLASS);

                    if (data.Checkbox) {
                        $accountCheckbox = document.createElement(CONSTANTS.DIV_TAG);
                        _.each(CONSTANTS.ACCOUNT_CHECKBOX_CONTAINER_CLASSES, function (className) {
                            $accountCheckbox.classList.add(className);
                        });

                        if (
                            data.Checkbox.ContainerClasses &&
                            data.Checkbox.ContainerClasses.length
                        ) {
                            _.each(data.Checkbox.ContainerClasses, function (className) {
                                $accountCheckbox.classList.add(className);
                            });
                        }

                        $accountLabel = document.createElement(CONSTANTS.LABEL_TAG);
                        _.each(CONSTANTS.ACCOUNT_LABEL_CONTAINER_CLASSES, function (className) {
                            $accountLabel.classList.add(className);
                        });

                        if (data.Checkbox.LabelClasses && data.Checkbox.LabelClasses.length) {
                            _.each(data.Checkbox.LabelClasses, function (className) {
                                $accountLabel.classList.add(className);
                            });
                        }

                        $accountInput = document.createElement(CONSTANTS.INPUT_TAG);
                        $accountInput.type = CONSTANTS.ACCOUNT_CHECKBOX_TYPE;
                        $accountInput.name = data.Checkbox.InputName ? data.Checkbox.InputName : '';
                        $accountInput.id = data.Checkbox.InputValue ? data.Checkbox.InputValue : '';
                        $accountInput.value = data.Checkbox.InputValue
                            ? data.Checkbox.InputValue
                            : '';
                        $accountInput.disabled = true;
                        _.each(CONSTANTS.ACCOUNT_CHECKBOX_INPUT_CLASSES, function (className) {
                            $accountInput.classList.add(className);
                        });

                        if (data.Checkbox.InputClasses && data.Checkbox.InputClasses.length) {
                            _.each(data.Checkbox.InputClasses, function (className) {
                                $accountInput.classList.add(className);
                            });
                        }

                        $accountIndicator = document.createElement(CONSTANTS.DIV_TAG);
                        $accountIndicator.classList.add(CONSTANTS.ACCOUNT_CHECKBOX_INDICATOR_CLASS);

                        $accountAnimation = document.createElement(CONSTANTS.SPAN_TAG);
                        $accountAnimation.classList.add(CONSTANTS.ACCOUNT_CHECKBOX_ANIMATION_CLASS);

                        $accountLabel.appendChild($accountInput);
                        $accountLabel.appendChild($accountIndicator);
                        $accountLabel.appendChild($accountAnimation);

                        $accountCheckbox.appendChild($accountLabel);

                        $accountItemDiv.appendChild($accountCheckbox);
                    }

                    if (data.Items && data.Items.length) {
                        _.each(data.Items, function (item) {
                            var $accountCell = document.createElement(CONSTANTS.DIV_TAG);

                            _.each(CONSTANTS.ACCOUNT_ITEM_CLASSES, function (className) {
                                $accountCell.classList.add(className);
                            });

                            if (item.Classes && item.Classes.length) {
                                _.each(item.Classes, function (className) {
                                    $accountCell.classList.add(className);
                                });
                            }

                            if (item.Content) {
                                $accountCell.innerHTML = item.Content;
                            }

                            $accountWrapperDiv.appendChild($accountCell);
                        });
                    }

                    $accountItemDiv.appendChild($accountWrapperDiv);
                    $accountsContainer.insertBefore(
                        $accountItemDiv,
                        $accountsContainer.getElementsByClassName(CONSTANTS.ITEM_IGNORE_SELECTOR)[0]
                    );

                    $accountsForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $dashboardThirdPartyAccounts.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    if ($accountsNoContent) {
                        $accountsNoContent.classList.add(CONSTANTS.HIDDEN_CLASS);
                    }
                    if ($accountsNoContentHide && $accountsNoContentHide.length) {
                        _.each($accountsNoContentHide, function ($item) {
                            $item.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        });
                    }

                    $accountInputs = $dashboardThirdPartyAccounts.querySelectorAll(
                        '.' + CONSTANTS.ACCOUNT_INPUT + ':not([disabled])'
                    );

                    resetPagination();
                });
            }
        };

        var initializeData = function () {
            // Table
            $accountsForm = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.ACCOUNTS_FORM
            )[0];
            $accountsContainer = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.ACCOUNTS_CONTAINER
            )[0];
            $accountsNoContent = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.ACCOUNTS_NO_CONTENT
            )[0];
            $accountsListExportButton = document.getElementsByClassName(
                CONSTANTS.ACCOUNT_LIST_EXPORT
            );

            if ($dashboardThirdPartyAccounts.dataset.noContentSelector) {
                $accountsNoContentHide = document.getElementsByClassName(
                    $dashboardThirdPartyAccounts.dataset.noContentSelector
                );
            }

            $accountSelectedItems = [];
            _accountsSelectedValues = [];

            //Page Loading Animation
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];

            // Search
            $searchModule = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.SEARCH_MODULE
            )[0];
            $searchInput = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.SEARCH_INPUT
            )[0];

            // Select All
            $selectAllInput = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.SELECT_ALL_INPUT
            )[0];
            $selectTotalAmount = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.TOTAL_AMOUNT
            )[0];

            // Form Actions
            $deselectButton = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.DESELECT_BUTTON
            )[0];
            $submitButton = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON
            )[0];

            // Items
            $accountItems = $dashboardThirdPartyAccounts.getElementsByClassName(
                CONSTANTS.ACCOUNT_ITEM
            );
            $accountInputs = $dashboardThirdPartyAccounts.querySelectorAll(
                '.' + CONSTANTS.ACCOUNT_INPUT + ':not([disabled])'
            );

            // Popup
            if ($dashboardThirdPartyAccounts.dataset.popupSelector) {
                $confirmationPopup = document.getElementsByClassName(
                    $dashboardThirdPartyAccounts.dataset.popupSelector +
                    CONSTANTS.POPUP_CONFIRMATION_SUFFIX
                )[0];
                $popupAmount = $confirmationPopup.getElementsByClassName(CONSTANTS.REQUEST_AMOUNT);
                $confirmationPopupSubmit = $confirmationPopup.getElementsByClassName(
                    CONSTANTS.POPUP_SUBMIT
                )[0];

                $loadingPopup = document.getElementsByClassName(
                    $dashboardThirdPartyAccounts.dataset.popupSelector +
                    CONSTANTS.POPUP_LOADING_SUFFIX
                )[0];
                $loadingAnimation = $loadingPopup.getElementsByClassName(
                    CONSTANTS.POPUP_LOADING_ANIMATION
                )[0];

                $successPopup = document.getElementsByClassName(
                    $dashboardThirdPartyAccounts.dataset.popupSelector +
                    CONSTANTS.POPUP_SUCCESS_SUFFIX
                )[0];

                $errorPopup = document.getElementsByClassName(
                    $dashboardThirdPartyAccounts.dataset.popupSelector +
                    CONSTANTS.POPUP_ERROR_SUFFIX
                )[0];
                $errorPopupTitle = $errorPopup.getElementsByClassName(
                    CONSTANTS.POPUP_ERROR_TITLE
                )[0];
                $errorPopupDescription = $errorPopup.getElementsByClassName(
                    CONSTANTS.POPUP_ERROR_DESCRIPTION
                )[0];

                $exportListErrorPopup = document.getElementsByClassName(
                    $dashboardThirdPartyAccounts.dataset.popupSelector +
                    CONSTANTS.POPUP_ERROR_EXPORT_LIST_SUFFIX
                )[0];
            }
        };

        var initializeEvents = function () {
            $selectAllInput.addEventListener('change', triggerSelectAll);
            $accountsContainer.addEventListener('page-change', resetItems);

            if (
                $dashboardThirdPartyAccounts.dataset.addAccount &&
                $dashboardThirdPartyAccounts.dataset.addAccount === 'true'
            ) {
                $dashboardThirdPartyAccounts.addEventListener('add-account', createAccount);
            } else {
                _.each($accountsListExportButton, function ($exportListButton) {
                    coned.utils.addGeneralListeners($exportListButton, exportAccountsList);
                });
            }

            coned.utils.addGeneralListeners($deselectButton, function() {
                clearSelectionEvent();
                $selectAllInput.focus();
            });

            if ($confirmationPopup) {
                coned.utils.addGeneralListeners(
                    $confirmationPopupSubmit,
                    removeAccountConfirmationSubmit
                );
            }

            if ($searchModule) {
                $searchModule.addEventListener('filter', function () {
                    clearSelectionEvent();
                    resetItems();
                });
            }

            //The way the event is called has been changed.
            $submitButton.addEventListener('click', removeAccountSubmit);

            if ($searchInput) {
                $searchInput.addEventListener('input', function () {
                    if ($searchInput.value === '') {
                        _.each($accountsListExportButton, function ($exportListButton) {
                            $exportListButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        });
                    } else {
                        _.each($accountsListExportButton, function ($exportListButton) {
                            $exportListButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                        });
                    }
                });
            }
        };

        var initializeItems = function () {
            if ($accountsContainer.children.length === 0) {
                if ($accountsNoContent) {
                    $accountsForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $accountsNoContent.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $dashboardThirdPartyAccounts.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $dashboardThirdPartyAccounts.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                if ($accountsNoContentHide && $accountsNoContentHide.length) {
                    _.each($accountsNoContentHide, function ($item) {
                        $item.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });
                }
            } else {
                if ($selectTotalAmount) {
                    $selectTotalAmount.innerHTML = $accountInputs.length;
                }

                _.each($accountItems, function ($item, index) {
                    var $checkboxInput = $item.querySelectorAll(
                        '.' + CONSTANTS.ACCOUNT_INPUT + ':not([disabled])'
                    ),
                        $exportButton = $item.getElementsByClassName(CONSTANTS.ACCOUNT_EXPORT),
                        $expandButton = $item.getElementsByClassName(CONSTANTS.ACCOUNT_EXPAND);

                    if ($checkboxInput && $checkboxInput.length) {
                        $checkboxInput[0].addEventListener('change', checkboxChangeTrigger);
                    }

                    if ($exportButton && $exportButton.length) {
                        coned.utils.addGeneralListeners($exportButton[0], exportDataServiceCall);
                        $exportButton[0].dataset.buttonIndex = index;
                    }

                    if ($expandButton && $expandButton.length) {
                        coned.utils.addGeneralListeners($expandButton[0], expandEventTrigger);
                    }
                });

                resetPagination();

                $dashboardThirdPartyAccounts.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $accountsForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                if ($accountsNoContentHide && $accountsNoContentHide.length) {
                    _.each($accountsNoContentHide, function ($item) {
                        $item.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    });
                }
                if ($accountsNoContent) {
                    $accountsNoContent.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                if ($accountInputs.length) {
                    query
                        .selectParentElement($selectAllInput, CONSTANTS.CHECKBOX_CONTAINER)
                        .classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            initializeItems();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardThirdPartyAccounts.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardThirdPartyAccounts;
})();
