// ==================== SELECT BOX COMPONENT =========================
/* global gsap */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DropdownComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SELECT_CLASS: 'js-coned-select',
        LABEL_CLASS: 'js-coned-select-label',
        SELECT_ACTIVE_SELECTOR: 'js-coned-select-active',
        SELECT_ACTIVE_ClASS: 'coned-select--active',

        //Tagging
        SELECT_TAG: 'general.dropdown.selection'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var DropdownComponent = function ($dropDown) {
        /**
         * PRIVATE METHODS
         */
        var $selectElements, $selectLabel;

        var modifySelect = function () {
            var optionParent = $dropDown.getElementsByClassName(CONSTANTS.SELECT_CLASS),
                select = optionParent[0];

            $selectLabel.style.display = 'block';
            query.addClass(optionParent, CONSTANTS.SELECT_ACTIVE_SELECTOR);
            query.addClass(optionParent, CONSTANTS.SELECT_ACTIVE_ClASS);

            gsap.to($selectLabel, {
                duration: 0.2,
                top: '16',
                opacity: '1'
            });

            // Analytics data building
            var dropdownQuestion = $selectLabel.innerText,
                dropdownSelected = select.options[select.selectedIndex].text;

            dataLayer.push({
                event: CONSTANTS.SELECT_TAG,
                'dropdown.question': dropdownQuestion,
                'dropdown.selected': dropdownSelected
            });
        };

        var initializeData = function () {
            $selectElements = $dropDown.getElementsByClassName(CONSTANTS.SELECT_CLASS);
            $selectLabel = $dropDown.getElementsByClassName(CONSTANTS.LABEL_CLASS)[0];
        };

        var initializeEvents = function () {
            for (var index = 0; index < $selectElements.length; index++) {
                $selectElements[index].addEventListener('change', modifySelect);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DropdownComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DropdownComponent;
})();
