// ==================== DASHBOARD MEDICAL HARDSHIP COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardMedicalHardship = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM: '.js-medical-hardship-form',
        MHS_SECTION: 'js-medical-hardship',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        LINK_DESCRIPTION: 'js-link-description',
        FORM_SELECTOR: 'js-medical-hardship-form',
        ENROLL_SUCCESS_SELECTOR: 'js-medical-hardship-success-enroll',
        MANAGE_SELECTOR: 'js-medical-hardship-manage',
        ENROLLMENT_STATUS_SELECTOR: 'js-medical-hardship-enrollment-status',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        ALERT_CLASS: 'manage-enroll__list--alert',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        ICON_STATUS_TEXT: 'icon-status-text',
        DATA_ENROLLED: 'data-enrolled',
        ENROLLED: 'Enrolled',
        HIDDEN_CLASS: 'hidden',
        FORM_SCID: 'scId',
        FORM_EMAIL: 'medicalHardshipEmail',
        FORM_DESCRIPTION: 'medicalHardshipDescription',
        FORM_UPDATE_ACCOUNT_TYPE: 'MedicalHardship',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        HEADER: 'js-header-wrapper',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        UPDATED_MESSAGE: 'js-updated-message',
        REAPPLY: 'js-reapply',
        FOCUS_PLACEHOLDER_CLASS: 'js-focus-placeholder',
        MHS_PARAMETER_NAME: 'mhs',

        // Lazy Load
        LAZY_LOAD_CLASS: 'js-lazy-load',
        LAZY_LOAD_TAB_CLASS: 'js-lazy-load-manage-my-account',
        LAZY_LOAD_LOADED_CLASS: 'js-lazy-load-element-loaded'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardMedicalHardship = function ($medicalHardshipForm) {
        /**
         * PRIVATE METHODS
         */
        var $linkAccordionSelector,
            $formSelector,
            $manageSelector,
            $enrollmentStatus,
            $enrollSuccessSelector,
            $accordionSelector,
            $header,
            $formLoading,
            $accountMaidInput,
            $serviceError,
            $serviceErrorMessage,
            $updateMessage,
            $iconStatusTextSpan,
            $reapplyButton,
            $focusPlaceholder,
            //Lazy load
            $lazyLoadTab,
            _isLazyLoad;

        var onSubmitForm = function () {
            var serviceUrl = $medicalHardshipForm.dataset.serviceUrl,
                params;

            hideError();

            // Service Data
            params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($medicalHardshipForm, CONSTANTS.FORM_SCID),
                Email: query.getFormInputValue($medicalHardshipForm, CONSTANTS.FORM_EMAIL),
                DescribeEmergencyCondition: query.getFormInputValue(
                    $medicalHardshipForm,
                    CONSTANTS.FORM_DESCRIPTION
                )
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successMedicalHardship,
                errorMedicalHardship,
                params,
                true,
                $formLoading
            );
        };

        var hideError = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var successMedicalHardship = function () {
            $formSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $enrollSuccessSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            query.scrollToElement($medicalHardshipForm, $header);
            $medicalHardshipForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
            $medicalHardshipForm.classList.add(CONSTANTS.ALERT_CLASS);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            $focusPlaceholder.focus();
            query.scrollToElement($enrollSuccessSelector, $header);
            setTimeout(function () {
                $enrollSuccessSelector.focus();
            }, 500);

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success',
                contentTitle: 'medical-hardship'
            });

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($medicalHardshipForm);

            $accordionSelector.dataset.openName = $accordionSelector.dataset.pending;
        };

        var errorMedicalHardship = function (data) {
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var enrollDone = function (event) {
            event.preventDefault();

            $updateMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $accordionSelector.dataset.statement = 'true';
            $linkAccordionSelector.click();
            $focusPlaceholder.focus();
            query.scrollToElement($medicalHardshipForm, $header);
            $enrollSuccessSelector.focus();
            setStatement();
        };

        var setStatement = function () {
            var statementFlag = $accordionSelector.dataset.statement;

            if (coned.utils.isOru()) {
                if (statementFlag == 'false') {
                    $manageSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $manageSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $formSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $medicalHardshipForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                    $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                        CONSTANTS.DATA_ENROLLED
                    );
                    $linkAccordionSelector.getElementsByClassName(
                        CONSTANTS.LINK_DESCRIPTION
                    )[0].innerHTML = $accordionSelector.dataset.manage;
                    $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
                }
            } else {
                $medicalHardshipForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                $medicalHardshipForm.classList.remove(CONSTANTS.ALERT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_ENROLLED
                );
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.manage;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
            }
        };

        // Loads all module data from service
        var loadDataServiceCall = function () {
            if (isLoaded) return;

            var serviceUrl = $medicalHardshipForm.dataset.serviceUrl,
                params;

            if (!serviceUrl) {
                errorLoadDataServiceCall();
                return;
            }

            query.getData(serviceUrl, successLoadDataServiceCall, errorLoadDataServiceCall, params);
        };

        // If call succeeded, load markup into module
        var successLoadDataServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {

                var scenario = coned.utils.getUrlParameterValue(CONSTANTS.MHS_PARAMETER_NAME) ? 
                                coned.utils.getUrlParameterValue(CONSTANTS.MHS_PARAMETER_NAME) :
                                coned.constants.DEFAULT_SCENARIO;

                var scenarioHTML = coned.plConstants.GET_MHS_SCENARIO_HTML_URL + scenario + coned.constants.HTML_EXTENSION;
                    query.getData(
                        scenarioHTML,
                        setContentMarkup,
                        errorLoadDataServiceCall
                    );
                // SCENARIO #1: MHS enroll form
                // SCENARIO #2: MHS Enrollment Status Documentation pending
                // SCENARIO #3: MHS Enrollment Status Documentation received
                // SCENARIO #4: MHS Enrollment Status request denied
                // SCENARIO #5: MHS Enrollment Status request approved
                // SCENARIO #6: MHS Enrollment Status service error 
                // SCENARIO #7: MHS ORU
                
            } else {
                setContentMarkup(data);
            }
        };

        // If call errored, hide module
        var errorLoadDataServiceCall = function () {
            $medicalHardshipForm.classList.add(CONSTANTS.HIDDEN_CLASS);

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $medicalHardshipForm.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($medicalHardshipForm, 'lazy-load-element-loaded');
            }
        };

        var setContentMarkup = function (data) {
            if (data) {
                var parser = new DOMParser(),
                    HTMLObject,
                    $mhsFormContent,
                    newMedicalHardshipAttributes;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $mhsFormContent = HTMLObject.getElementsByClassName(CONSTANTS.MHS_SECTION);

                if (
                    $mhsFormContent.length !== 0 &&
                    $mhsFormContent[0].dataset.content !== 'false'
                ) {
                    $mhsFormContent = $mhsFormContent[0];
                } else {
                    errorLoadDataServiceCall();
                    return;
                }

                $medicalHardshipForm.innerHTML = $mhsFormContent.innerHTML;
                newMedicalHardshipAttributes = $mhsFormContent.attributes;

                while ($medicalHardshipForm.attributes.length > 0) {
                    $medicalHardshipForm.removeAttribute($medicalHardshipForm.attributes[0].name);
                }

                _.each(newMedicalHardshipAttributes, function (attribute) {
                    $medicalHardshipForm.setAttribute(attribute.nodeName, attribute.nodeValue);
                });

                // If data-module is present, initialize modules
                if ($medicalHardshipForm.dataset.module) {
                    initializeData();
                    initializeEvents();
                    coned.utils.initializeModules($medicalHardshipForm);
                }
            } else {
                errorLoadDataServiceCall();
                return;
            }

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $medicalHardshipForm.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($medicalHardshipForm, 'lazy-load-element-loaded');
            }

            coned.utils.triggerEvent(window, 'service-loaded');
        };

        var showForm = function (event) {
            event.preventDefault();

            $formSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $enrollmentStatus.classList.add(CONSTANTS.HIDDEN_CLASS);
            $medicalHardshipForm.classList.add(CONSTANTS.UNENROLLMENT_CLASS);

            $focusPlaceholder.focus();
            query.scrollToElement($formSelector, $header);
            setTimeout(function () {
                $formSelector.focus();
            }, 500);

            $accordionSelector.dataset.openName = $accordionSelector.dataset.enroll;
        };

        var initializeData = function () {
            $accordionSelector = $medicalHardshipForm.getElementsByClassName(
                CONSTANTS.ACCORDION_SELECTOR
            )[0];
            $linkAccordionSelector = $medicalHardshipForm.getElementsByClassName(
                CONSTANTS.LINK_ACCORDION_SELECTOR
            )[0];
            $formSelector = $medicalHardshipForm.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            $enrollSuccessSelector = $medicalHardshipForm.getElementsByClassName(
                CONSTANTS.ENROLL_SUCCESS_SELECTOR
            )[0];
            $enrollmentStatus = $medicalHardshipForm.getElementsByClassName(
                CONSTANTS.ENROLLMENT_STATUS_SELECTOR
            )[0];
            $manageSelector = $medicalHardshipForm.getElementsByClassName(
                CONSTANTS.MANAGE_SELECTOR
            )[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $serviceError = $medicalHardshipForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceErrorMessage = $medicalHardshipForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $updateMessage = $medicalHardshipForm.getElementsByClassName(
                CONSTANTS.UPDATED_MESSAGE
            )[0];
            $iconStatusTextSpan = $medicalHardshipForm.getElementsByClassName(
                CONSTANTS.ICON_STATUS_TEXT
            )[0];
            $reapplyButton = $medicalHardshipForm.getElementsByClassName(CONSTANTS.REAPPLY)[0];
            $focusPlaceholder = document.getElementsByClassName(
                CONSTANTS.FOCUS_PLACEHOLDER_CLASS
            )[0];
        };

        // Checks if module should be lazy-loaded of not
        var preInitialize = function () {
            _isLazyLoad = query.hasClass($medicalHardshipForm, CONSTANTS.LAZY_LOAD_CLASS);

            // If module is lazy loaded, wait for event to trigger
            if (_isLazyLoad) {
                $lazyLoadTab = document.getElementsByClassName(CONSTANTS.LAZY_LOAD_TAB_CLASS)[0];

                $lazyLoadTab.addEventListener('lazy-load-start', loadDataServiceCall);
            } else {
                initializeData();
                setStatement();
                initializeEvents();
                isLoaded = true;
            }
        };

        var initializeEvents = function () {
            new coned.components.ValidateForm(CONSTANTS.FORM, onSubmitForm, '');

            if ($enrollSuccessSelector !== undefined) {
                $enrollSuccessSelector.addEventListener('submit', enrollDone);
            }

            coned.utils.addGeneralListeners($reapplyButton, showForm);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            preInitialize();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardMedicalHardship.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardMedicalHardship;
})();
