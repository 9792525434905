// ==================== RESULT PROGRAM COMPONENT ============================
/* global _ */
/* global dataLayer */

var coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ResultProgram = (function () {
    /**
     * Constants used in the module.
     * @type {Object}s
     */
    var CONSTANTS = {
        BODY_CLASS: 'js-body',
        PROGRAM_SELECT: 'js-program',
        CLOSE_PROGRAM_SELECT: 'js-close-program',
        HIDDEN_CLASS: 'hidden',
        DATA_ATRIBUTE_LABEL: 'data-program-label',
        DATA_PROGRAM_ATRIBUTE: 'data-program',
        PROGRAM_ARROW:'program__tile-arrow',
        PROGRAM_DETAILS: 'program__details',
        ROTATE_CLASS: 'financial-advisor-result--rotate',
        KEY_PRESSED: '',
        OPEN_CARD_EVENT: 'FA.open.card',
        FINANCIAL_ADVISOR_TAGGING: 'FA.',
        BUTTON_PROGRAM_SELECTOR: 'js-button-program',
        ACCOUNT_TYPE_STRING:'accountType',
        STEPS_PARENT_SELECTOR: 'js-steps-parent'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ResultProgram = function ($resultProgram) {
        var $programs,
            $programsDetail, 
            $programArrows,
            $financialAdvisorParent;
             
        /**
         * PRIVATE METHODS
         */

        var initializeData = function () {
            $programs = $resultProgram.getElementsByClassName(CONSTANTS.PROGRAM_SELECT);
            $programsDetail = document.getElementsByClassName(CONSTANTS.PROGRAM_DETAILS);
            $programArrows = document.getElementsByClassName(CONSTANTS.PROGRAM_ARROW);
            $financialAdvisorParent = document.getElementsByClassName(CONSTANTS.STEPS_PARENT_SELECTOR)[0];
        };

        var initializeEvents = function () {
            Array.from($programs).forEach(initializeEventsProgram);
        }

        /**
         * Initialize open and close events in program details
         */
        var initializeEventsProgram = function ($program) {
            var targetContent = $program.getAttribute(CONSTANTS.DATA_ATRIBUTE_LABEL);
            var programDetails = $program.parentElement.querySelector(
                '[data-program="' + targetContent + '"]'
            );
            var programsContainer = $program.parentElement.parentElement;
            var arrowIcon = $program.getElementsByClassName(CONSTANTS.PROGRAM_ARROW)[0];
            var closeButton = programDetails.getElementsByClassName(CONSTANTS.CLOSE_PROGRAM_SELECT)[0];
            var $butonsProgramDetails = programDetails.getElementsByClassName(CONSTANTS.BUTTON_PROGRAM_SELECTOR);
            coned.utils.addGeneralListeners($program, function ($event) {
                displayProgram($event, programDetails, arrowIcon, closeButton);
            });
            coned.utils.addGeneralListeners(closeButton, function ($event) {
                hideProgramDetail($event, $program, programDetails, programsContainer);
            });
            _.each($butonsProgramDetails, function ($butonProgramDetails) {
                coned.utils.addGeneralListeners($butonProgramDetails, function ($event) {
                    buttonDetail($event, programDetails, $butonProgramDetails);
                });
            });
        }
        
        /**
         *  Close action, search the current container for programs that contain them.
         *  Hide program details if is open.
         *  
         */
        var hideProgramDetail = function ($event, $program, $programDetails) {
            $event.preventDefault();
            $programDetails.classList.add(CONSTANTS.HIDDEN_CLASS);
            closeArrowIcons();
            $program.focus();
        }

        /**
         *   Rotate all arrows when close the details program
         */
        var closeArrowIcons = function () {
            Array.from($programArrows).forEach(function ($program) {
                $program.classList.remove(CONSTANTS.ROTATE_CLASS);
            });
        }

        /**
         *   Close open dropdowns when pressing a program
         */
        var hiddenAllProgramsDetail = function () {
            Array.from($programsDetail).forEach(function ($detail) {
                if(!$detail.classList.contains(CONSTANTS.HIDDEN_CLASS)) {
                    $detail.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            });
            closeArrowIcons();
        }
        
        /**
         *   Function triggered to press a program
         */
        var displayProgram = function ($event, $programDetails, $arrowIcon, $closeButton) {
            var programNameFA = getProgramName($programDetails);
            $event.preventDefault();
            if (!$programDetails.classList.contains(CONSTANTS.HIDDEN_CLASS)) {
                $programDetails.classList.add(CONSTANTS.HIDDEN_CLASS);
                $arrowIcon.classList.remove(CONSTANTS.ROTATE_CLASS);
                return;
            }
            hiddenAllProgramsDetail();

            //hide display dropdown program and rotate arrow icon
            $programDetails.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $arrowIcon.classList.add(CONSTANTS.ROTATE_CLASS);
            //detect focus for key pressed
            if($event.pointerType == CONSTANTS.KEY_PRESSED){
                $closeButton.focus();
            }
            // Set tagging when program opens
            dataLayer.push({
                event: CONSTANTS.OPEN_CARD_EVENT,
                programName: programNameFA
            });
        }

        /**
         * Get Program Name by program details
         * @param {*} $programDetails 
         * @returns String
         */
        var getProgramName = function ($programDetails) {
            var programName = $programDetails.getAttribute(CONSTANTS.DATA_PROGRAM_ATRIBUTE),
            programNameFA = CONSTANTS.FINANCIAL_ADVISOR_TAGGING + programName.replaceAll(' ','_');
            return programNameFA;
        }

        /**
         * Set the data layer and redirect to the page on the button
         */
        var buttonDetail = function ($event, $programDetails, $butonProgramDetails) {
            $event.preventDefault();
            var programNameFA = getProgramName($programDetails),
                accountType = $financialAdvisorParent.dataset.accountType,
                url = new URL($butonProgramDetails.href);

            dataLayer.push({'event' : 'FA.cardCTA', 'programName' : programNameFA}); 
            localStorage.setItem(CONSTANTS.ACCOUNT_TYPE_STRING, accountType);
            location.href = url;
        }

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init(); 
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ResultProgram.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ResultProgram;

})();
