// ==================== DASHBOARD LIFE SAVING EQUIPMENT COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.NYISOCalculator = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */

     var CONSTANTS = {
        FORM: 'js-nyiso-calculator-form',
        CLEAR_BTN_CLASS: 'js-reset-button',
        NYISO_ZONE: 'js-nyiso-zone',
        NYISO_RESULT_TABLE_DESCRIPTION_CLASS: 'js-result-table-description',
        NYISO_RESULT_TABLE_CLASS: 'js-nyiso-result',
        NYISO_SERVICE_ERROR : 'js-nyiso-service-error',
        TABLE_ROW: 'js-data-table-row',
        TABLE_ROW_HOUR: 'js-nyiso-hour',
        TABLE_ROW_PRICE: 'js-nyiso-price',
        FORM_SITECORE_ID: 'scId',
        FORM_DATE: 'nyisoDate',
        FORM_ZONE: 'nyisoZone',
        FORM_LOADING: 'js-form-loading',
        ERROR_MESSAGE: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        RECAPTCHA_ELEMENT: 'js-recaptcha'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var NYISOCalculator = function ($nyisoCalculator) {

        var $nyisoCalculatorForm,
            $clearBtn,
            $nyisoZone,
            $nyisoResultTableDescription,
            $nyisoResultTable,
            $nyisoErrorMessage,
            $formLoading,
            recaptcha,
            _hasCaptcha;

        /**
         * PRIVATE METHODS
         */
         var resetCalculator = function () {
            $nyisoErrorMessage.classList.add(CONSTANTS.HIDDEN_CLASS);
            $nyisoResultTableDescription.classList.add(CONSTANTS.HIDDEN_CLASS);
            $nyisoResultTable.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (_hasCaptcha && recaptcha) {
                recaptcha.reset();
            }

            //Wait until the validator ends to position the focus
            setTimeout(function () {
                $nyisoZone.focus();
            }, 500);
        };

        var calculateRate = function() {
            var serviceUrl = $nyisoCalculator.dataset.serviceUrl,
                params,
                verify,
                formData;

            //Hide Error
            $nyisoErrorMessage.classList.add(CONSTANTS.HIDDEN_CLASS);

            //Hide Result
            $nyisoResultTable.classList.add(CONSTANTS.HIDDEN_CLASS);

            //Get verify for unauthenticated user
            if (_hasCaptcha && recaptcha) {
                verify = recaptcha.getResponse();
            }else {
                verify = '';
            }

            // Service Data
            params = {
                scId: query.getFormInputValue(document, CONSTANTS.FORM_SITECORE_ID),
                loadZone: query.getDropdownSelectedValue($nyisoCalculatorForm, CONSTANTS.FORM_ZONE),
                date: coned.utils.serviceDateFormat(query.getInputValue($nyisoCalculatorForm, CONSTANTS.FORM_DATE)),
                verify: verify
            };

            // Serialize JSON into string to append to FormData
            formData = JSON.stringify(params);

            // Service Call
            query.postData(
                serviceUrl,
                successCalculateRatesService,
                errorCalculateRatesService,
                formData,
                true,
                $formLoading
            );
            
        };


        var successCalculateRatesService = function (data) {
            if (coned.utils.isPatternLab()) {
                data = query.getData(coned.plConstants.GET_NYISO_CALCULATOR_CALCULATE, setResultTableValues, function () {});
            }else {
                setResultTableValues(data);
            }  
        };

        var setResultTableValues = function (data) {
            
            //Get table tbody
            var $tableContent = $nyisoResultTable.getElementsByTagName('tbody')[0],
                $resultRow = $nyisoCalculator.getElementsByClassName(CONSTANTS.TABLE_ROW)[0].cloneNode(true);

            $tableContent.innerHTML = '';
            $tableContent.appendChild($resultRow);

            //Load table values
            _.each(data.Prices, function(result) {
                // Copy the row element and its child nodes
                var $newRow = $resultRow.cloneNode(true);
                var hour = $newRow.getElementsByClassName(CONSTANTS.TABLE_ROW_HOUR)[0];
                hour.innerHTML = result.Hour;
                var price = $newRow.getElementsByClassName(CONSTANTS.TABLE_ROW_PRICE)[0];
                price.innerHTML = result.Price;
                $newRow.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $tableContent.appendChild($newRow);
            });
            
            $resultRow.classList.add(CONSTANTS.HIDDEN_CLASS);
            $nyisoResultTableDescription.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $nyisoResultTable.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $nyisoResultTable.focus();
        };

        var errorCalculateRatesService = function (data) {
            if (coned.utils.isPatternLab()) {
                data = query.getData(coned.plConstants.GET_NYISO_CALCULATOR_SERVICES_ERROR, setErrorMessage, function () {});
            }else {
                setErrorMessage(data);
            }            
        };

        var setErrorMessage = function(data) {
            var $msgText = $nyisoErrorMessage.getElementsByClassName(CONSTANTS.ERROR_MESSAGE)[0];
            $msgText.innerText = data.errorMsg && data.errorMsg !== '' ? data.errorMsg : $msgText.innerText;
            $nyisoErrorMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $nyisoErrorMessage.focus();
        };

        /**
         *  Check Recaptcha
         **/
         var checkRecaptcha = function () {
            $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
            if (_hasCaptcha && recaptcha) {
                if(recaptcha.getResponse() !== '') {
                    recaptcha.reset();
                }
                recaptcha.checkRecaptcha();
                
            }
        };

        var initializeData = function () {
            $nyisoCalculatorForm = $nyisoCalculator.getElementsByClassName(CONSTANTS.FORM)[0];
            $clearBtn = $nyisoCalculator.getElementsByClassName(CONSTANTS.CLEAR_BTN_CLASS)[0];
            $nyisoZone = $nyisoCalculatorForm.getElementsByClassName(CONSTANTS.NYISO_ZONE)[0];
            $nyisoResultTableDescription = $nyisoCalculator.getElementsByClassName(CONSTANTS.NYISO_RESULT_TABLE_DESCRIPTION_CLASS)[0];
            $nyisoResultTable = $nyisoCalculator.getElementsByClassName(CONSTANTS.NYISO_RESULT_TABLE_CLASS)[0];
            $nyisoErrorMessage = $nyisoCalculator.getElementsByClassName(CONSTANTS.NYISO_SERVICE_ERROR)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            recaptcha = false;
            _hasCaptcha = $nyisoCalculator.getElementsByClassName(CONSTANTS.RECAPTCHA_ELEMENT)[0];
        };

        var initializeEvents = function () {

            if (_hasCaptcha) {
                new coned.components.ValidateForm($nyisoCalculatorForm, checkRecaptcha);
                recaptcha = new coned.components.Recaptcha($nyisoCalculator, calculateRate, calculateRate);
            }else {
                new coned.components.ValidateForm($nyisoCalculatorForm, calculateRate);
            }
            
            coned.utils.addGeneralListeners($clearBtn, resetCalculator);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        }

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     NYISOCalculator.prototype.isLoaded = function () {
        return isLoaded;
    };

    return NYISOCalculator;
})();
