// ==================== ICONS NAVIGATION COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

coned.components.IconsNavigation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ITEM_CTA: 'icons-navigation__item-cta'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */

    var IconsNavigation = function () {
        /**
         * PRIVATE METHODS
         */
        function smoothScroll(target, duration) {
            target = document.getElementById(target);
            var targetPosition = target.getBoundingClientRect().top;
            var startPosition = window.pageYOffset;
            var distance = targetPosition - 90;
            var startTime = null;

            function animation(currentTime) {
                if (startTime === null) currentTime = startTime = currentTime;
                var timeElapsed = currentTime - startTime;
                var run = ease(timeElapsed, startPosition, distance, duration);
                window.scrollTo(0, run);
                if (timeElapsed < duration) requestAnimationFrame(animation);
            }

            function ease(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return (c / 2) * t * t + b;
                t--;
                return (-c / 2) * (t * (t - 2) - 1) + b;
            }
            requestAnimationFrame(animation);
        }

        function init() {
            var scrollElems = document.getElementsByClassName(CONSTANTS.ITEM_CTA);

            for (var i = 0; i < scrollElems.length; i++) {
                var elem = scrollElems[i];
                elem.addEventListener('click', function (e) {
                    var scrollElemId = e.currentTarget.href.split('#')[1];
                    smoothScroll(scrollElemId, 1000);
                    dataLayer.push({ event: e.currentTarget.dataset.navEvent });
                });
            }

            isLoaded = true;
        }

        init();
    };
    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    IconsNavigation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return IconsNavigation;
})();
