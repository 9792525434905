// ==================== REPORT OUTAGE ORU COMPONENT =========================
/* global _ */
/* global $ */
/* global gsap */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ReportOutageOru = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM: '.js-report-outage-form',
        HAVE_POWER_SELECT: 'js-have-power',
        BANNER_CONTAINER: 'js-banner-container',
        LOCATION_STEP: 'js-location',
        LOCATION_STEP_NO_ACCOUNTS: 'js-location-no-accounts',
        CONTACT_STEP: 'js-contact-details',
        FIND_ADDRESS_BUTTON: 'js-lookup-button',
        ACCOUNT_OPTION_ACTIVE: 'report-outage__account-option--active',
        ACCOUNT_OPTION: 'js-account-option',
        ACCOUNT_NUMBER_INPUT: 'js-number-input',
        ACCOUNT_NUMBER: 'js-oru-account-number',
        ADDRESS_RECORD_CONTAINER: 'js-address-record-container',
        ADDITIONAL_DETAILS_CONTAINER: 'js-additional-details',
        SEARCH_BY_ADDRESS_LINK: 'js-search-address-link',
        SUBMIT_CONTAINER: 'js-submit-progress-animation',
        SUBMIT_CONTAINER_ACTIVE: 'report-outage__animation--active',
        FIND_ACCOUNT_CONTAINER: 'js-find-account-container',
        FIND_ACCOUNT_BY_NUMBER: 'js-find-account-by-number',
        FIND_ACCOUNT_BY_ADDRESS: 'js-find-by-address',
        FIND_BY_ADDRESS_ACCOUNT_DETAIL: 'js-address-selected-detail',
        FIND_BY_ADDRESS_ACCOUNT_NUMBER: 'js-address-masked-account-number',
        FIND_BY_OPTION: 'js-find-by-option',
        FIND_BY_OPTION_ACTIVE: 'js-find-by-option-active',
        FIND_ACCOUNT_SELECT: 'js-find-account',
        OUTAGE_MY_ADDRESS_CHECK: 'js-outage-my-address',
        NOT_MY_ADDRESS_WRAPPER: 'js-not-my-address-wrapper',
        ADDRESS_LOOKUP_FORM: 'js-address-lookup',
        AUTOCOMPLETE_ZIPCODE: 'js-autocomplete-zipcode',
        AUTOCOMPLETE_APARMENT_UNIT: 'js-autocomplete-apartment-unit',
        AUTOCOMPLETE_SELECTED_ADDRESS: 'js-address-autocomplete-selected',
        ADDRESS_SELECTED_CLASS: 'js-address-autocomplete-selected',
        ADDRESS_SELECTED_DETAIL_CLASS: 'js-address-selected-detail',
        ADDRESS_SELECTED_MASKED_ACCOUNT_NUMBER: 'js-address-masked-account-number',
        FORM_SUBMIT_BUTTON: 'js-submit-outage',
        NOT_MY_ADDRESS_LINK: 'js-not-my-address-link',
        VALID_ITEM: 'js-item-validate',
        LOOKUP_RESULT: 'js-address-lookup-result',
        ISSUE_TYPE_CONTAINER: 'js-issue-type-container',
        ISSUE_TYPE_SELECTOR: 'js-issue-type',
        SHOCK_OPTIONS: 'js-shock-options',
        NO_PARTIAL_OPTIONS: 'js-no-partial-options',
        ADDRESS_SELECTED: 'js-dropdown-button',
        ADDRESS_TITLE: 'address-box__container-title',
        ADDRESS_SUBTITLE: 'address-box__container-subtitle',
        FULL_NAME: 'js-full-name',
        CONTACT_NUMBER: 'js-contact-number',
        OTHER_AFFECTED_RECEPTOR: 'js-other-affected-receptor',
        FULL_NAME_RECEPTOR: 'js-name-receptor',
        FINISH_REPORT_BANNER: 'js-finish-report',
        FINISH_REPORT_TITLE: 'js-success-banner-title',
        FINISH_REPORT_ACCOUNT: 'js-account-info',
        FINISH_REPORT_STREET_ADDRESS: 'js-street-address',
        FINISH_REPORT_CITY: 'js-city',
        FINISH_REPORT_ETR: 'js-etr',
        FINISH_REPORT_CONTACT_NUMBER: 'js-contact-info-number',
        FINISH_REPORT_BANNER_CONTAINER: 'js-finish-report-container',
        FINISH_REPORT_BANNER_FOCUS: 'js-finish-report-focus',
        FINISH_REPORT_DUPLICATE : 'js-duplicate-outage',
        SERVICE_ISSUE_FORMS: 'js-issues-forms',
        SERVICE_ISSUE_ACTIVE: 'service-issue-active',
        SUCCESS_INFORMATION: 'js-oru-success-information',
        DROPDOWN_SELECTOR: 'js-coned-select',
        DROPDOWN_LABEL_SELECTOR: 'js-coned-select-label',
        ACTIVE_DROPDOWN_CLASS: 'coned-select--active',
        ACTIVE_LABEL_DROPDOWN_CLASS: 'coned-select__label--active',
        ADITIONAL_PHONE_SELECTOR: 'js-additional-phone',
        ADITIONAL_EMAIL_SELECTOR: 'js-additional-email',
        INPUT_CLASS: 'js-coned-input',
        HIDDEN_CLASS: 'hidden',
        FORM_IGNORE_VALIDATION: 'js-validate-ignore',
        INPUT_DISABLED: 'coned-field--disabled',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        FORM_ACCOUNT_NUMBER: 'accountNumber',
        FIND_BY_ACCOUNT: 'account',
        FIND_BY_PHONE: 'phone',
        FIND_ADDRESS: 'zipcode',
        LOOKUP_SERVICE_ERROR: 'js-lookup-service-error',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        ACCOUNT_DATA: 'js-dropdown-button-contain',
        ACCOUNT_TITLE: 'address-box__container-title',
        ACCOUNT_SUB_TITLE: 'address-box__container-subtitle',
        FORM_SCID: 'ScId',
        FORM_HAVE_POWER: 'havePower',
        FORM_SERVICE_ISSUE: 'selectServiceIssue',
        FORM_PHONE_NUMBER: 'contactNumber',
        FORM_WIRE_LOCATION: 'wireLocation',
        FORM_WIRE_LOCATION_SHOCK: 'wireLocationShock',
        FORM_WIRE_CONDITION: 'wireCondition',
        FORM_WIRE_CONDITION_SHOCK: 'wireConditionShock',
        FORM_POLE_CONDITION: 'poleCondition',
        FORM_DESCRIPTION_OTHER_POLE: 'descriptionOtherPole',
        FORM_DESCRIPTION_OTHER_SHOCK: 'descriptionOtherShock',
        ADDRESS_DROPDOWN_MODULE: 'js-address-dropdown',
        ADDRESS_DROPDOWN_LIST: 'js-dropdown-list',
        ADDRESS_DROPDOWN_LIST_ITEM: 'js-dropdown-item',
        ADDRESS_DROPDOWN_LIST_ITEM_CLASS: 'address-dropdown__list-item',
        ADDRESS_DROPDOWN_ITEM_CONTAIN: 'js-dropdown-item-contain',
        ADDRESS_DROPDOWN_BOX: 'address-box',
        ADDRESS_DROPDOWN_BOX_ITEM: 'address-box--item',
        ADDRESS_DROPDOWN_BOX_CONTAINER: 'address-box__container',
        ADDRESS_DROPDOWN_BOX_INFO: 'address-box__info',
        ADDRESS_DROPDOWN_BOX_TITLE: 'address-box__container-title',
        ADDRESS_DROPDOWN_BOX_TITLE_NON_AUTENTICATED:
            'address-box__container-title--non-authenticated',
        ADDRESS_DROPDOWN_BOX_SUBTITLE: 'address-box__container-subtitle',
        ADDRESS_DROPDOWN_BOX_ICON: 'address-dropdown__icon',
        ADDRESS_DROPDOWN_BOX_ICON_CHECK_CLASS: 'address-dropdown__icon--check',
        ADDRESS_DROPDOWN_BOX_ICON_CHECK: 'js-icon-check',
        ADDRESS_DROPDOWN_BOX_CARROT: 'js-dropdown-carrot',
        TRANSACTIONAL_WRAPPER: 'transactional__wrapper',
        MARGIN_BOTTOM: 'transactional__wrapper--margin-bottom',
        NOTIFICATION_MESSAGE_PARAGRAPH: 'js-notification-message',
        NOTIFICATION_CONTAINER_CLASS: 'js-notification-container',
        CAPTCHA_ELEMENT_CLASS: 'js-recaptcha',
        ACCOUNT_LOOKUP_FORM: 'js-address-autocomplete-form',
        SYSTEM_OUT_PARAMETER: 'system-out',

        //Shock humand and shock animal scenario
        SELECTED_SERVICE_ISSUE: 'selectServiceIssue',
        SHOCK_HUMAN_OPTION: 'ShockHuman',
        SHOCK_ANIMAL_OPTION: 'ShockAnimal',
        SHOCK_MESSAGE: 'js-shock-message',
        ETR_MESSAGE: 'js-etr-message',

        // a11y attributes
        TABINDEX: 'tabindex'

    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ReportOutageOru = function ($reportOutageOru) {
        /**
         * PRIVATE METHODS
         */
        var $havePowerSelect,
            $bannerContainer,
            $locationStep,
            $findAccountSelect,
            $findaccountContainer,
            $findAccountByAddress,
            $findAccountByNumber,
            $findByAddressAccountDetail,
            $findAddressButtonContainer,
            $findAddressButton,
            $findByActiveOption,
            $searchByAddressLink,
            $addressLookupForm,
            $accountNumberInput,
            $autocompleteZipCode,
            $autocompleteAparmentUnitInput,
            $autocompleteSelectedAddress,
            $addressSelectedDetail,
            $addressSelected,
            $addressRecordContainer,
            $contactStep,
            $submitButton,
            $submitButtonContainer,
            $authenticatedMyAddressCheckbox,
            $notMyAddressWrapper,
            $notMyAddressLink,
            $additionalDetailsContainer,
            $issueTypeContainer,
            $issueTypeSelector,
            $shockOptions,
            $noPartialOptions,
            $lookupResult,
            $lookupError,
            $serviceError,
            $phoneNumber,
            $locationStepNoAccounts,
            $transactionalWrapper,
            $finishBannerContainer,
            $finishBannerFocus,
            $finishBanner,
            $finishTextETR,
            $finishTextAccountNumber,
            $finishTextStreetAddress,
            $finishTextCity,
            $finishTextContactNumber,
            $finishDuplicateReport,
            $finishShockMessage,
            $finishETRMessage,
            $captchaElement,
            $accountLookupForm,
            recaptcha,
            _lastDigitsAccountNumber,
            _houseNumber,
            finalDetailDirection,
            _havePowerOption,
            _havePowerNo,
            _havePowerPartial,
            _shockHumanOption,
            _shockAnimalOption,
            _data,
            _dataOutageService,
            _userAuthenticated,
            _hasCaptcha,
            _initialLoad,
            _isSystemOut;

        var fieldsMask = function ($element, height) {
            var $mask = $element.parentNode,
                elementHeight = height ? height : $element.offsetHeight;

            gsap.to($mask, {
                duration: 0.4,
                minHeight: elementHeight,
                onComplete: toggleMask
            });

            function toggleMask() {
                $mask.classList.toggle(CONSTANTS.MASK_ACTIVE);
            }
        };

        var userStatus = function () {
            // user status validation
            if (_userAuthenticated && !$locationStepNoAccounts) {
                $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $contactStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $submitButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $additionalDetailsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                // Analytics data building
                dataLayer.push({
                    event: 'coned.form.step02'
                });
            } else {
                $submitButtonContainer.classList.add(CONSTANTS.SUBMIT_CONTAINER_ACTIVE);
                $locationStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var scrollToStep = function ($element) {
            // scroll into active section
            $element.scrollIntoView(true);
            var scrolled = window.pageYOffset;

            if (scrolled) {
                window.scroll(0, scrolled - 100);
            }
        };

        var recaptchaValidation = function () {
            var formStatus = $($reportOutageOru).valid();

            if (formStatus) {
                new coned.components.SubmitAnimation(
                    $submitButtonContainer,
                    outageServiceCall,
                    successOutageService,
                    errorOutageService,
                    false,
                    true
                );
            }
        };

        var outageServiceCall = function () {
            var outageServiceUrl = $reportOutageOru.dataset.serviceUrl,
                $accountData = $lookupResult.getElementsByClassName(CONSTANTS.ACCOUNT_DATA)[0],
                response = _hasCaptcha ? recaptcha.getResponse() : null,
                maidAccountValue = null,
                outageParams,
                wireLocation,
                wireCondition,
                havePower,
                poleCondition,
                otherDescription;

            //If the user is authenticated we should check if they are reporting there own address or other.
            if (_userAuthenticated) {
                //If the user is reporting other address the service url changes to allow the report.
                if (!$authenticatedMyAddressCheckbox.checked) {
                    outageServiceUrl = $reportOutageOru.dataset.serviceUaUrl;                    
                }
            }

            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // set account maid value for auth user
            if (_userAuthenticated) {
                if($authenticatedMyAddressCheckbox.checked) {
                    maidAccountValue = $accountData.dataset.accountMaid;
                } else {
                    maidAccountValue = $findByAddressAccountDetail.dataset.maid;
                }
            } else {
                maidAccountValue =  $accountData.dataset.accountMaid;
            }

            // check if shock or no report is being submit and assign correct values
            if (query.hasClass($shockOptions, CONSTANTS.HIDDEN_CLASS)) {
                wireLocation = query.getFormInputValue(
                    $reportOutageOru,
                    CONSTANTS.FORM_WIRE_LOCATION
                );
                wireCondition = query.getFormInputValue(
                    $reportOutageOru,
                    CONSTANTS.FORM_WIRE_CONDITION
                );
                otherDescription = query.getFormInputValue(
                    $reportOutageOru,
                    CONSTANTS.FORM_DESCRIPTION_OTHER_POLE
                );
                havePower = query.getFormInputValue($reportOutageOru, CONSTANTS.FORM_HAVE_POWER);
                poleCondition = query.getFormInputValue(
                    $reportOutageOru,
                    CONSTANTS.FORM_POLE_CONDITION
                );
            } else {
                wireLocation = query.getFormInputValue(
                    $reportOutageOru,
                    CONSTANTS.FORM_WIRE_LOCATION_SHOCK
                );
                wireCondition = query.getFormInputValue(
                    $reportOutageOru,
                    CONSTANTS.FORM_WIRE_CONDITION_SHOCK
                );
                otherDescription = query.getFormInputValue(
                    $reportOutageOru,
                    CONSTANTS.FORM_DESCRIPTION_OTHER_SHOCK
                );
                havePower = query.getFormInputValue($reportOutageOru, CONSTANTS.FORM_SERVICE_ISSUE);
                poleCondition = '';
            }

            // service data
            outageParams = {
                ScId: query.getFormInputValue($reportOutageOru, CONSTANTS.FORM_SCID),
                Maid: maidAccountValue,
                HavePower: havePower,
                ContactName: '',
                PhoneNumber: query.getFormInputValue($reportOutageOru, CONSTANTS.FORM_PHONE_NUMBER),
                WireLocation: wireLocation,
                WireCondition: wireCondition,
                PoleCondition: poleCondition,
                DescriptionOther: otherDescription,
                Alorica: $reportOutageOru.dataset.alorica,
                CheckoutageScId: $reportOutageOru.dataset.checkoutageScid
            };

            if (_hasCaptcha && response && response !== '') {
                outageParams.Verify = response;
            }

            outageParams = JSON.stringify(outageParams);

            // service call
            if (coned.utils.isPatternLab() && _userAuthenticated) {
                query.getData(
                    coned.plConstants.GET_NOTIFICATION_MESSAGE,
                    successReportCallback,
                    errorReportCallback
                );
            } else {
                query.postData(
                    outageServiceUrl,
                    successReportCallback,
                    errorReportCallback,
                    outageParams,
                    true
                );
            }
        };

        var successOutageService = function () {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_REPORT_OUTAGE_CONFIRMATION_SUCCESS,
                    setStatus, 
                    errorReportCallback
                );
            } else {
                setStatus(_dataOutageService);
            }  
        };

        var setStatus = function () {
            var serviceIssue = query.getDropdownSelectedValue($reportOutageOru, CONSTANTS.SELECTED_SERVICE_ISSUE);
            $reportOutageOru.classList.add(CONSTANTS.HIDDEN_CLASS);
           
            var addressParent = $reportOutageOru.getElementsByClassName(
                    CONSTANTS.ADDRESS_SELECTED
                )[0],
                addressTitleValue = '',
                addressSubtitleValue = '';

            if (!_userAuthenticated) {
                var addressData = addressParent.getElementsByClassName(CONSTANTS.ACCOUNT_DATA)[0];
                _lastDigitsAccountNumber = 
                    addressData.dataset.lastDigitsAccountNumber;
                _houseNumber =
                    addressData.dataset.street.substr(
                        0,
                        parseInt($reportOutageOru.dataset.streetAddressLength)
                    );
            } else {
                //If the user is authenticated we should check if they are reporting there own address or other to 
                //load the correct information on the confirmation screen
                if ($authenticatedMyAddressCheckbox && $authenticatedMyAddressCheckbox.checked) {
                    addressTitleValue = addressParent.getElementsByClassName(
                        CONSTANTS.ADDRESS_TITLE
                    )[0].innerHTML,
                    addressSubtitleValue = addressParent.getElementsByClassName(
                        CONSTANTS.ADDRESS_SUBTITLE
                    )[0].innerHTML;
                } else {
                    //Get the address information from the address lookup
                    addressTitleValue = $findByAddressAccountDetail.dataset.addressLine1;
                    addressSubtitleValue = $findByAddressAccountDetail.dataset.addressLine2;
                }
            }

            var contactPhoneValue = $phoneNumber.value.trim();

            // set the values and show the message
            $finishBannerContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $finishBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success'
            });

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($reportOutageOru);

            // notification message
            if (serviceIssue === CONSTANTS.SHOCK_ANIMAL_OPTION || serviceIssue === CONSTANTS.SHOCK_HUMAN_OPTION) {
                $finishShockMessage.innerHTML = $finishShockMessage.innerHTML.replace('{{MESSAGE}}', _dataOutageService.ReportOutageETR);
                $finishShockMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
                
                _.each($finishETRMessage, function ($message) {
                    $message.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

            } else { 
                // Show ETR information         
                if (_dataOutageService) {
                    //If there is an ETR, show the ETR message
                    $finishTextETR.innerHTML = $finishTextETR.innerHTML.replace('{{ETR}}', _dataOutageService.ReportOutageETR);
                    
                    // Analytics confirmation ETR
                    dataLayer.push({
                        event: 'virtualPageView',
                        virtualPageUrl: '/en/report-outage/confirmation-etr'
                    });

                    _.each($finishETRMessage, function ($message) {
                        $message.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    });
    
                    $finishShockMessage.classList.add(CONSTANTS.HIDDEN_CLASS);

                    //Check if the outage is a duplicate
                    var duplicate = _dataOutageService.Duplicate === "true" ? true : false;
                    if(duplicate) {
                        $finishDuplicateReport.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }else {
                        $finishDuplicateReport.classList.add(CONSTANTS.HIDDEN_CLASS);
                    }
                    
                }
            }

            // finish address and account number
            if (!_userAuthenticated) {
                $finishTextAccountNumber.innerHTML = $finishTextAccountNumber.innerHTML.replace('{{ACCOUNT}}', '<b>' + _lastDigitsAccountNumber + '</b>'); 
                $finishTextStreetAddress.innerHTML = $finishTextStreetAddress.innerHTML.replace('{{ADDRESS}}', '<b>' + _houseNumber + '</b>');
            } else {
                $finishTextAccountNumber.classList.add(CONSTANTS.HIDDEN_CLASS);
                $finishTextCity.classList.add(CONSTANTS.HIDDEN_CLASS);
                $finishTextStreetAddress.innerHTML = $finishTextStreetAddress.innerHTML.replace('{{ADDRESS}}', '<b>' + addressTitleValue + ' ' + addressSubtitleValue + '</b>');
            }

            // phone
            if (contactPhoneValue) {
                $finishTextContactNumber.innerHTML = $finishTextContactNumber.innerHTML.replace('{{NUMBER}}', '<b>' + contactPhoneValue + '</b>');
            } else {
                $finishTextContactNumber.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            $finishBannerFocus.focus();
        };

        var errorOutageService = function () {
            var $msgTextSelector = $serviceError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];

            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML =
                _dataOutageService && _dataOutageService.errorMsg
                    ? _dataOutageService.errorMsg
                    : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
            $submitButtonContainer.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
        };

        var successReportCallback = function (dataOutageService) {
            if (coned.utils.isPatternLab()) {
                _dataOutageService = query.getData(
                    coned.plConstants.GET_REPORT_OUTAGE_CONFIRMATION_SUCCESS,
                    setDataOutage,
                    false
                );
            } else {
                _dataOutageService = dataOutageService;
            }

            $submitButtonContainer.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        var setDataOutage = function (data) {
            _dataOutageService = data;
        };

        var errorReportCallback = function (dataOutageService) {
            _dataOutageService = dataOutageService;
            $submitButtonContainer.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
        };

        var issueTypeChange = function () {
            var issueTypeOption = this.options[$issueTypeSelector.selectedIndex].value;

            $issueTypeSelector.classList.add(CONSTANTS.SERVICE_ISSUE_ACTIVE);
            if (issueTypeOption === _shockHumanOption || issueTypeOption === _shockAnimalOption) {
                $locationStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $additionalDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                if(_userAuthenticated) {
                    if (!$authenticatedMyAddressCheckbox.checked) {
                        $findAccountByAddress.classList.remove(CONSTANTS.HIDDEN_CLASS);                  
                    } else {
                        $lookupResult.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }
                }
                
                if (!query.hasClass($autocompleteSelectedAddress, CONSTANTS.HIDDEN_CLASS)) {
                    showHideContactAndAdditionalDetailsSections('show');
                }

                notMyAddress();
                userStatus();

                // Analytics data building
                dataLayer.push({
                    event: 'coned.form.step01'
                });
            } else {
                $locationStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                $additionalDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                window.open(issueTypeOption);
            }
        };

        /**
         * Check if the issue type dropdown already has a valid selection.
         * @return {Boolean} Whether the dropdown has a valid selection or not.
         */
        var isIssueTypeSelectorValid = function () {
            var issueTypeSelectorValid = query.getInputValue(
                    $reportOutageOru,
                    CONSTANTS.FORM_SERVICE_ISSUE
                );

            return issueTypeSelectorValid;
        };

        /**
         * Shows or hides the contact and additional details sections.
         * @param {String} action Accepts two values: 'show' or 'hide'.
         */
        var showHideContactAndAdditionalDetailsSections = function (action) {
            if (action === 'show') {
                query.removeClass($additionalDetailsContainer, CONSTANTS.HIDDEN_CLASS);
                query.removeClass($contactStep, CONSTANTS.HIDDEN_CLASS);
            } else if (action === 'hide') {
                query.addClass($additionalDetailsContainer, CONSTANTS.HIDDEN_CLASS);
                query.addClass($contactStep, CONSTANTS.HIDDEN_CLASS);
            }
        };

        var notMyAddress = function (event) {
            if (event !== undefined) {
                event.preventDefault();
            }

            var $findAccountOptions = $locationStep.getElementsByClassName(
                    CONSTANTS.ACCOUNT_OPTION
                );
            
            $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (!_userAuthenticated || $locationStepNoAccounts) {
                var $lookUpInputs = $findAccountByNumber.getElementsByClassName(
                    CONSTANTS.INPUT_CLASS
                );

                $findAccountSelect.disabled = false;
                resetDropdownLabel($findAccountSelect);

                // Based on the have power dropdown selections
                if (_havePowerOption === _havePowerNo || _havePowerOption === _havePowerPartial) {
                    // Reveal all the remaining sections.
                    if (
                        _isSystemOut && 
                        isFindAccountByAddressValidAndActive()
                    ) {
                        showHideContactAndAdditionalDetailsSections('show');
                    // Hide all the remaining sections.
                    } else {
                        showHideContactAndAdditionalDetailsSections('hide');
                    }
                } else {
                    // Reveal all the remaining sections.
                    if (
                        _isSystemOut && 
                        isIssueTypeSelectorValid() && 
                        isFindAccountByAddressValidAndActive()
                    ) {
                        showHideContactAndAdditionalDetailsSections('show');
                    // Hide all the remaining sections.
                    } else {
                        showHideContactAndAdditionalDetailsSections('hide');
                    }
                }

                $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAccountByNumber.style.display = 'flex';
                $findAccountByNumber.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                $addressRecordContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                _.each($lookUpInputs, function ($input) {
                    var $parentField = $input.parentNode;

                    $parentField.classList.remove(CONSTANTS.INPUT_DISABLED);
                    $input.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                    $input.disabled = false;
                });

                fieldsMask($addressRecordContainer);
                
            }

            if (event) {
                scrollToStep($locationStep);
            }

            if (!_userAuthenticated) {
                // hide record address container, adjust the mask fieldset mask
                $addressRecordContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
            
            $submitButtonContainer.classList.remove(CONSTANTS.SUBMIT_CONTAINER_ACTIVE);
            $findAddressButtonContainer.classList.add(CONSTANTS.SUBMIT_CONTAINER_ACTIVE);

            // clear and hide options
            _.each($findAccountOptions, function ($option) {
                $option.getElementsByClassName(CONSTANTS.INPUT_CLASS)[0].value = '';
                $option.classList.add(CONSTANTS.HIDDEN_CLASS);
                $option.classList.remove(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
            });

            resetAditionalOptions();
        };

        var resetAditionalOptions = function () {
            var $aditionalDropdowns = $additionalDetailsContainer.getElementsByClassName(
                CONSTANTS.DROPDOWN_SELECTOR
            );

            // reset dropdowns
            _.each($aditionalDropdowns, function ($additionalDropdown) {
                resetDropdownLabel($additionalDropdown);
            });

            // reset contact number
            $phoneNumber.value = '';
            $phoneNumber.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
        };

        var resetDropdownLabel = function ($element) {
            var $selectLabel = $element.parentNode.getElementsByClassName(
                CONSTANTS.DROPDOWN_LABEL_SELECTOR
            )[0];

            $selectLabel.style = '';
            $element.style = '';
            $element.selectedIndex = 0;
            $element.classList.remove(CONSTANTS.ACTIVE_DROPDOWN_CLASS);
        };

        var changeInputsState = function (disabled, noReset) {
            noReset = noReset || false;

            var $lookUpInputs = $findaccountContainer.getElementsByClassName(CONSTANTS.INPUT_CLASS),
                $lookUpDropDowns = $findaccountContainer.getElementsByClassName(
                    CONSTANTS.DROPDOWN_SELECTOR
                );

            if (disabled) {
                _.each($lookUpInputs, function ($input) {
                    $input.parentNode.classList.add(CONSTANTS.INPUT_DISABLED_CLASS);
                    $input.disabled = true;
                });

                _.each($lookUpDropDowns, function ($dropDown) {
                    $dropDown.disabled = true;
                });
            } else {
                _.each($lookUpDropDowns, function ($dropDown) {
                    $dropDown.disabled = false;
                    if (!noReset) {
                        resetDropdownLabel($dropDown);
                    }
                });

                _.each($lookUpInputs, function ($input) {
                    $input.parentNode.classList.remove(CONSTANTS.INPUT_DISABLED_CLASS);
                    $input.disabled = false;
                });
            }
        };

        var lookUpAddress = function (event) {
            event.preventDefault();

            $findByActiveOption = $reportOutageOru.getElementsByClassName(
                CONSTANTS.ACCOUNT_OPTION_ACTIVE
            )[0];

            $additionalDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);

            $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $findAddressButtonContainer.classList.remove(CONSTANTS.ERROR_SERVICE_RESPONSE_CLASS);
            $findAddressButtonContainer.classList.remove(CONSTANTS.SUCCESS_SERVICE_RESPONSE_CLASS);
            $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $autocompleteSelectedAddress.classList.add(CONSTANTS.HIDDEN_CLASS);

            var isFormValid = $($reportOutageOru).valid();

            if (isFormValid) {
                
                if(!_userAuthenticated) {
                    if (!$findByActiveOption) {
                        changeInputsState();
                    } else {
                        changeInputsState(true);
                    }
                }
                 
                new coned.components.SubmitAnimation(
                    $findAddressButtonContainer,
                    lookUpAccount,
                    successLookUpAccountService,
                    errorLookUpAccountService,
                    false
                );
            }
        };

        var lookUpAccount = function () {
            var lookAccountServiceUrl = $reportOutageOru.dataset.lookAccountService,
                notFoundAccountMessage = $reportOutageOru.dataset.notFoundAccountMessage,
                createOutageNotAllowedMessage = $reportOutageOru.dataset.createOutageNotAllowedMessage,
                accountNumber = query.getFormInputValue(
                    $reportOutageOru,
                    CONSTANTS.FORM_ACCOUNT_NUMBER
                ),
                filterValue = $reportOutageOru.dataset.filterByCreateOutage
                    ? $reportOutageOru.dataset.filterByCreateOutage
                    : false,
                maid = $addressSelectedDetail.dataset.maid,
                lookUpAccountParams,
                findBy,
                phoneNumber;

            if (!_userAuthenticated) {
                var $activeInput;

                if ($findByActiveOption) {
                    $activeInput = $findByActiveOption.getElementsByClassName(
                        CONSTANTS.INPUT_CLASS
                    )[0];
                    phoneNumber = $activeInput.value;

                    if (query.hasClass($activeInput, CONSTANTS.ACCOUNT_NUMBER)) {
                        phoneNumber = '';
                        findBy = CONSTANTS.FIND_BY_ACCOUNT;
                    } else {
                        findBy = CONSTANTS.FIND_BY_PHONE;
                    }
                }
            }

            //Fill the FindBy property for new experience
            if (
                (accountNumber === '' && $autocompleteZipCode && $autocompleteZipCode.value) ||
                _userAuthenticated
            ) {
                findBy = CONSTANTS.FIND_ADDRESS;
                phoneNumber = '';
            }

            lookUpAccountParams = {
                ScId: query.getFormInputValue($reportOutageOru, CONSTANTS.FORM_SCID),
                AccountNumber: accountNumber,
                AccountMaid: maid,
                PhoneNumber: phoneNumber,
                FindBy: findBy,
                FiltertByAllowCreateOutage: filterValue,
                Borough: query.getFormInputValue($reportOutageOru, CONSTANTS.FORM_BOROUGH),
                NotFoundAccountMessage: notFoundAccountMessage,
                CreateOutageNotAllowedMessage: createOutageNotAllowedMessage
            };

            if (coned.utils.isPatternLab()) {
                if (_userAuthenticated) {
                    query.getData(
                        coned.plConstants.GET_LOOKUP_ACCOUNT,
                        successLookupCallback,
                        function () {}
                    );
                } else if (
                    $findAccountSelect.options[$findAccountSelect.selectedIndex].value ==
                    CONSTANTS.FIND_BY_PHONE
                ) {
                    query.getData(
                        coned.plConstants.GET_LOOKUP_ACCOUNTS,
                        successLookupCallback,
                        function () {}
                    );
                } else {
                    query.getData(
                        coned.plConstants.GET_LOOKUP_ACCOUNT,
                        successLookupCallback,
                        function () {}
                    );
                }
            } else {
                lookUpAccountParams = JSON.stringify(lookUpAccountParams);

                query.postData(
                    lookAccountServiceUrl,
                    successLookupCallback,
                    errorLookupCallback,
                    lookUpAccountParams,
                    true
                );
            }
        };

        var successLookupCallback = function (data) {
            _data = data;
            $findAddressButtonContainer.classList.add(
                coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
            );
            
            $submitButton.disabled = false;
        };

        var errorLookupCallback = function (data) {
            _data = data;
            $findAddressButtonContainer.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);

            $submitButton.disabled = true;
        };

        var errorLookUpAccountService = function (accountLimitError) {
            var $msgTextSelector = $lookupError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];

            if (accountLimitError) {
                $msgTextSelector.innerHTML = $reportOutageOru.dataset.lookByPhoneError;
            } else {
                $msgTextSelector.innerHTML =
                    _data && _data.errorMsg ? _data.errorMsg : coned.constants.ERROR_MESSAGE;
            }

            $lookupError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $findAddressButtonContainer.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            $lookupError.focus();
            changeInputsState();
        };

        var successLookUpAccountService = function () {
            var isFindByPhone = query.hasClass($reportOutageOru, CONSTANTS.FIND_BY_PHONE);

            if (isFindByPhone && _data.Accounts.length > 1) {
                errorLookUpAccountService(true);

                return;
            }

            if (_data.Accounts.length) {
                $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $submitButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $contactStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $submitButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $submitButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                // Analytics data building
                dataLayer.push({
                    event: 'coned.form.step02'
                });

                if (!$findByActiveOption) {
                    if (!_userAuthenticated) {
                        $addressRecordContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                    }
                } else {
                    if (_userAuthenticated) {
                        $lookupResult.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        fieldsMask($addressRecordContainer);
                    } else {
                        $addressRecordContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        fieldsMask($addressRecordContainer);
                    }
                }

                $additionalDetailsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                
                if(_userAuthenticated) {
                    if($authenticatedMyAddressCheckbox.checked) {
                        //Update dropdown once you select an account
                        setAccount(_data);
                    } else {           
                        //Shows the address you enter on the option Find by Address
                        var selectedAddressAccount = $addressSelectedDetail.getElementsByClassName(
                            CONSTANTS.ADDRESS_SELECTED_MASKED_ACCOUNT_NUMBER
                        )[0];
                        //Set the account number from the lookup response
                        selectedAddressAccount.innerHTML = _data.Accounts[0].AccountNumber;
                        $findByAddressAccountDetail.dataset.account = _data.Accounts[0].AccountNumberRaw;
                        //Show the selected address after we got the account number
                        $addressSelected.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }
                } else {
                    setAccount(_data);
                }
    
                                       
            }
        };

        var setAccount = function (data) {
            var 
                $addressDropDownList = _userAuthenticated
                    ? $lookupResult.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_LIST)[0]
                    : $reportOutageOru.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_LIST)[0],
                $addressItem,
                $addressItemBox,
                $addressBoxContainer,
                $addressBoxInfo,
                $addressTitle,
                $addressSubTitle,
                $addressIcon,
                addressTitleString,
                addressSubTitleString;

            $addressDropDownList.innerHTML = '';

            _.each(data.Accounts, function (accountItem) {
                $addressItem = document.createElement('li');
                $addressItem.classList.add(CONSTANTS.ADDRESS_DROPDOWN_LIST_ITEM);
                $addressItem.classList.add(CONSTANTS.ADDRESS_DROPDOWN_LIST_ITEM_CLASS);

                $addressItemBox = document.createElement('div');
                $addressItemBox.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX);
                $addressItemBox.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_ITEM);
                $addressItemBox.classList.add(CONSTANTS.ADDRESS_DROPDOWN_ITEM_CONTAIN);
                $addressItemBox.dataset.account = accountItem.AccountNumberRaw;
                $addressItemBox.dataset.accountMaid = accountItem.MaskedAccountNumber
                    ? accountItem.MaskedAccountNumber
                    : '';
                if (!_userAuthenticated) {
                    $addressItemBox.dataset.lastDigitsAccountNumber = 
                        accountItem.AccountNumber.substr(
                            accountItem.AccountNumber.length -
                                parseInt($reportOutageOru.dataset.accountNumberLength)
                        );
                }

                if(accountItem.hasOwnProperty("ServiceAddress") && accountItem.ServiceAddress !== null){
                    $addressItemBox.dataset.street = accountItem.ServiceAddress.StreetAddress;
                    $addressItemBox.dataset.city = accountItem.ServiceAddress.City;
                    $addressItemBox.dataset.state =  accountItem.ServiceAddress.State;
                    $addressItemBox.dataset.zipcode = accountItem.ServiceAddress.PostalCode;
                    $addressItemBox.dataset.country = accountItem.ServiceAddress.Country;
                    $addressItemBox.dataset.address2 = accountItem.ServiceAddress.Division;
                }

                $addressItemBox.dataset.mdac = accountItem.MultiDwellingAccessCode;
                $addressBoxContainer = document.createElement('div');
                $addressBoxContainer.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_CONTAINER);
                $addressItemBox.appendChild($addressBoxContainer);

                $addressBoxInfo = document.createElement('div');
                $addressBoxInfo.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_INFO);
                $addressBoxContainer.appendChild($addressBoxInfo);

                $addressTitle = document.createElement('p');
                $addressTitle.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_TITLE);
                if (!_userAuthenticated) {
                    $addressTitle.classList.add(
                        CONSTANTS.ADDRESS_DROPDOWN_BOX_TITLE_NON_AUTENTICATED
                    );
                }
                $addressBoxInfo.appendChild($addressTitle);

                $addressSubTitle = document.createElement('p');
                $addressSubTitle.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_SUBTITLE);
                $addressBoxInfo.appendChild($addressSubTitle);

                if (!_userAuthenticated) {
                    _lastDigitsAccountNumber =
                        '<b>' +
                        accountItem.AccountNumber.substr(
                            accountItem.AccountNumber.length -
                                parseInt($reportOutageOru.dataset.accountNumberLength)
                        ) +
                        '</b>';
                    addressTitleString = $reportOutageOru.dataset.accountNumberMessage + _lastDigitsAccountNumber;
                    if(accountItem.hasOwnProperty("ServiceAddress") && accountItem.ServiceAddress !== null){
                        _houseNumber =
                            '<b>' +
                            accountItem.ServiceAddress.StreetAddress.substr(
                                0,
                                parseInt($reportOutageOru.dataset.streetAddressLength)
                            ) +
                            '</b>';
                        finalDetailDirection =
                            accountItem.ServiceAddress.City + ', ' + accountItem.ServiceAddress.State;
                        addressSubTitleString = $reportOutageOru.dataset.streetAddressMessage + _houseNumber + '<br>' + finalDetailDirection;
                    } else {
                        addressSubTitleString = $reportOutageOru.dataset.customerNameMessage +
                        accountItem.CustomerName.substr(0,$reportOutageOru.dataset.customerNameLength);
                    }
                } else {
                    addressTitleString = accountItem.ServiceAddress.StreetAddress;

                    if (
                        accountItem.ServiceAddress.UnitNumber &&
                        accountItem.ServiceAddress.UnitNumber.trim() != ''
                    ) {
                        addressTitleString += ', ' + accountItem.ServiceAddress.UnitNumber;
                    }

                    addressSubTitleString = accountItem.ServiceAddress.City;

                    if (accountItem.ServiceAddress.State) {
                        addressSubTitleString += ', ' + accountItem.ServiceAddress.State;
                    }

                    if (accountItem.ServiceAddress.PostalCode) {
                        addressSubTitleString += ' ' + accountItem.ServiceAddress.PostalCode;
                    }
                }

                $addressTitle.innerHTML = addressTitleString;
                $addressSubTitle.innerHTML = addressSubTitleString;

                $addressIcon = document.createElement('span');
                $addressIcon.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_ICON);
                $addressIcon.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_ICON_CHECK_CLASS);
                $addressIcon.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_ICON_CHECK);

                $addressItem.appendChild($addressItemBox);
                $addressItem.appendChild($addressIcon);
                $addressDropDownList.appendChild($addressItem);

                //If the user selects the find by address on Unauthenticathed version shows the address
                if (!$findByActiveOption) {
                    var selectedAddressAccount = $addressSelectedDetail.getElementsByClassName(
                        CONSTANTS.ADDRESS_SELECTED_MASKED_ACCOUNT_NUMBER
                    )[0];
                    selectedAddressAccount.innerHTML = accountItem.AccountNumber;
                    //Show the selected address after we got the account number
                    $addressSelected.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            });

            if(_userAuthenticated) {
                if($authenticatedMyAddressCheckbox.checked) {
                    setDropDownModule(_data);
                } 
            } else {
                setDropDownModule(_data);
            }

            
        };

        var setDropDownModule = function (data) {
            var $addressDropdownModule = _userAuthenticated
                    ? $lookupResult.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_MODULE)[0]
                    : $reportOutageOru.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_MODULE)[0],
                $addressDropdownModuleNew = $addressDropdownModule.cloneNode(true),
                $firstAddress = $addressDropdownModuleNew.getElementsByClassName(
                    CONSTANTS.ADDRESS_DROPDOWN_ITEM_CONTAIN
                )[0],
                $addressDropdownCarrot = $reportOutageOru.getElementsByClassName(
                    CONSTANTS.ADDRESS_DROPDOWN_BOX_CARROT
                ),
                index;

            $addressDropdownModule.parentNode.replaceChild(
                $addressDropdownModuleNew,
                $addressDropdownModule
            );
            new coned.components.AddressDropdown($addressDropdownModuleNew);
            $firstAddress.click();

            if (data.Accounts.length <= 1) {
                for (index = 0; index < $addressDropdownCarrot.length; index++) {
                    $addressDropdownCarrot[index].classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                for (index = 0; index < $addressDropdownCarrot.length; index++) {
                    $addressDropdownCarrot[index].classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            }
        };

        var findAccountByAddress = function (event) {
            event.preventDefault();

            var options = $locationStep.getElementsByClassName(CONSTANTS.ACCOUNT_OPTION);

            _.each(options, function ($option) {
                $option.classList.remove(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
                $option.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            $findAccountByNumber.classList.remove(CONSTANTS.FIND_BY_OPTION_ACTIVE);
            $findAccountByAddress.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
            $findAccountByAddress.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $findAddressButton.disabled = true;
            $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $findAccountByNumber.classList.add(CONSTANTS.HIDDEN_CLASS);
            $addressRecordContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            $additionalDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $accountNumberInput.value = '';
            scrollToStep($locationStep);
            fieldsMask($findAccountByAddress);
            fieldsMask($addressRecordContainer, 0);
            changeInputsState(true);

            $autocompleteZipCode.value = '';
            $autocompleteZipCode.disabled = false;
            $autocompleteZipCode.parentNode.classList.remove(CONSTANTS.INPUT_DISABLED_CLASS);
            $autocompleteZipCode.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
            query.fireEvent($autocompleteZipCode, 'cleanUp');

            //hide on new experience
            $findAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);

        };

        var findAccountOptions = function () {
            var accountOptionValue = this.options[$findAccountSelect.selectedIndex].value,
                accountOptionName = 'js-' + accountOptionValue + '-number',
                $selectedOption = $locationStep.getElementsByClassName(accountOptionName)[0],
                $options = $locationStep.getElementsByClassName(CONSTANTS.ACCOUNT_OPTION);

            _.each($options, function ($option) {
                $option.classList.remove(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
                $option.classList.add(CONSTANTS.HIDDEN_CLASS);
                $option.getElementsByClassName(CONSTANTS.INPUT_CLASS)[0].value = '';
            });

            $selectedOption.classList.add(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
            $selectedOption.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Check if all the account lookup section's inputs are valid, and if it has the active class.
         * @return {Boolean} Whether the section is valid an active or not.
         */
        var isFindAccountByAddressValidAndActive = function () {
            var $findByAccountAddressInputs = $accountLookupForm.getElementsByClassName(
                    CONSTANTS.INPUT_CLASS
                ),
                findByAccountAddressInputsVisibleArr = [],
                _isinputValidArr = [],
                _isSectionValid,
                _isSectionActive = $findAccountByAddress.classList.contains(
                    CONSTANTS.FIND_BY_OPTION_ACTIVE
                );

            Array.from($findByAccountAddressInputs).forEach(function ($input) {
                if (coned.utils.isDomVisible($input)) {
                    findByAccountAddressInputsVisibleArr.push($input);
                }
            });

            findByAccountAddressInputsVisibleArr.forEach(function ($input) {
                if (query.hasClass($input, 'valid')) {
                    _isinputValidArr.push(true);
                } else {
                    _isinputValidArr.push(false);                    
                }
            });

            _isSectionValid = !_isinputValidArr.includes(false);

            return _isSectionValid && _isSectionActive;
        };

        var havePowerChange = function () {
            _havePowerOption = this.options[$havePowerSelect.selectedIndex].value;

            // hide gas banner
            $bannerContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $additionalDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $issueTypeSelector.selectedIndex = 0;
            $issueTypeSelector.classList.remove(CONSTANTS.SERVICE_ISSUE_ACTIVE);
            resetDropdownLabel($issueTypeSelector);

            if (_havePowerOption === _havePowerNo || _havePowerOption === _havePowerPartial) {
                $locationStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $issueTypeContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $shockOptions.classList.add(CONSTANTS.HIDDEN_CLASS);
                $noPartialOptions.classList.remove(CONSTANTS.HIDDEN_CLASS);
                userStatus();

                if(!_userAuthenticated) {

                    if ($findAccountByAddress.classList.contains(CONSTANTS.FIND_BY_OPTION_ACTIVE)) {
                        changeInputsState(true);
                        $autocompleteZipCode.disabled = false;
                        $autocompleteZipCode.parentNode.classList.remove(CONSTANTS.INPUT_DISABLED_CLASS);
                        $findAccountByAddress.classList.remove(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                        $findAccountByAddress.classList.add(CONSTANTS.HIDDEN_CLASS);
                    } else {
                        $findAccountByAddress.classList.add(CONSTANTS.HIDDEN_CLASS);
                        fieldsMask($findAccountByAddress);
                    }
                }

            } else {
                $findAccountByAddress.classList.add(CONSTANTS.HIDDEN_CLASS);
                $transactionalWrapper.classList.add(CONSTANTS.MARGIN_BOTTOM);
                $locationStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                $issueTypeContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $shockOptions.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $noPartialOptions.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            if(!_userAuthenticated) {
                if (!_isSystemOut) {
                    $findAccountByNumber.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                    $findAccountByNumber.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    // Analytics data building
                    dataLayer.push({
                        event: 'coned.form.step01'
                    });
                } else {
                    $findAccountByAddress.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                    $findAccountByAddress.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $findAccountByNumber.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $findAccountByNumber.classList.remove(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                    $findAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $findAddressButton.disabled = true;
                }
            }

            if ($findAccountSelect) {
                resetDropdownLabel($findAccountSelect);
            }

            notMyAddress();
        };

        var reportOutageSubmit = function () {
            if (_userAuthenticated || !_hasCaptcha) {
                new coned.components.SubmitAnimation(
                    $submitButtonContainer,
                    outageServiceCall,
                    successOutageService,
                    errorOutageService,
                    false,
                    true
                );
            } else {
                if (recaptcha && recaptcha.getResponse() === '') {
                    recaptcha.checkRecaptcha();
                } else if (recaptcha && recaptcha.getResponse() !== '') {
                    recaptcha.reset();
                    recaptcha.checkRecaptcha();
                }
            }
        };

        var resetForm = function () {
            var validator = $($reportOutageOru).validate();

            validator.resetForm();
        };

        var authenticatedMyAddressChange = function () {
            if ($authenticatedMyAddressCheckbox.checked) {
                _.each($notMyAddressWrapper, function ($wrapper) {
                    $wrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                });

                query.fireEvent($autocompleteZipCode, 'cleanUp');

                if(_initialLoad) {
                    //Change the initialLoad to false to shut it down
                    _initialLoad = false;
                }
          
                $contactStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $additionalDetailsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $submitButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $findAccountByAddress.classList.add(CONSTANTS.HIDDEN_CLASS);
                $lookupResult.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAccountByAddress.classList.remove(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                $findAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

                var $selectedAddress = $lookupResult.getElementsByClassName(CONSTANTS.ACCOUNT_DATA)[0];

                if($selectedAddress.dataset.hasError === coned.constants.TRUE) {
                    $submitButton.disabled = true;
                } else {
                    $submitButton.disabled = false;
                }


            } else {
                _.each($notMyAddressWrapper, function ($wrapper) {
                    $wrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                $additionalDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $lookupResult.classList.add(CONSTANTS.HIDDEN_CLASS);
                $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAccountByAddress.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAccountByAddress.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
                $autocompleteZipCode.value = '';
                $autocompleteZipCode.disabled = false;
                $autocompleteZipCode.parentNode.classList.remove(
                    CONSTANTS.INPUT_DISABLED_CLASS
                );

               $findAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);
               $findAddressButton.disabled = true;
            }
        };

        var initializeData = function () {
            $havePowerSelect = $reportOutageOru.getElementsByClassName(
                CONSTANTS.HAVE_POWER_SELECT
            )[0];
            $bannerContainer = document.getElementsByClassName(CONSTANTS.BANNER_CONTAINER)[0];
            $locationStep = $reportOutageOru.getElementsByClassName(CONSTANTS.LOCATION_STEP)[0];
            $locationStepNoAccounts = $reportOutageOru.getElementsByClassName(
                CONSTANTS.LOCATION_STEP_NO_ACCOUNTS
            )[0];
            $findaccountContainer = $reportOutageOru.getElementsByClassName(
                CONSTANTS.FIND_ACCOUNT_CONTAINER
            )[0];
            $findAddressButton = document.getElementsByClassName(CONSTANTS.FIND_ADDRESS_BUTTON)[0];
            $findAddressButtonContainer = $findAddressButton.parentNode.parentNode;
            $findAccountSelect = $reportOutageOru.getElementsByClassName(
                CONSTANTS.FIND_ACCOUNT_SELECT
            )[0];
            $findAccountByNumber = $reportOutageOru.getElementsByClassName(
                CONSTANTS.FIND_ACCOUNT_BY_NUMBER
            )[0];
            $findAccountByAddress = $reportOutageOru.getElementsByClassName(
                CONSTANTS.FIND_ACCOUNT_BY_ADDRESS
            )[0];
            $findByAddressAccountDetail = $reportOutageOru.getElementsByClassName(
                CONSTANTS.FIND_BY_ADDRESS_ACCOUNT_DETAIL
            )[0];
            $notMyAddressWrapper = $reportOutageOru.getElementsByClassName(
                CONSTANTS.NOT_MY_ADDRESS_WRAPPER
            );
            $authenticatedMyAddressCheckbox = $reportOutageOru.getElementsByClassName(
                CONSTANTS.OUTAGE_MY_ADDRESS_CHECK
            )[0];
            $searchByAddressLink = $reportOutageOru.getElementsByClassName(
                CONSTANTS.SEARCH_BY_ADDRESS_LINK
            )[0];
            $addressLookupForm = $reportOutageOru.getElementsByClassName(
                CONSTANTS.ADDRESS_LOOKUP_FORM
            )[0];
            $autocompleteZipCode = $addressLookupForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_ZIPCODE
            )[0];
            $autocompleteSelectedAddress = $addressLookupForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_SELECTED_ADDRESS
            )[0];
            $autocompleteAparmentUnitInput = $addressLookupForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_APARMENT_UNIT
            )[0];
            $addressSelected = $addressLookupForm.getElementsByClassName(
                CONSTANTS.ADDRESS_SELECTED_CLASS
            )[0];
            $addressSelectedDetail = $addressLookupForm.getElementsByClassName(
                CONSTANTS.ADDRESS_SELECTED_DETAIL_CLASS
            )[0];
            $addressRecordContainer = $reportOutageOru.getElementsByClassName(
                CONSTANTS.ADDRESS_RECORD_CONTAINER
            )[0];
            $accountNumberInput = $reportOutageOru.getElementsByClassName(CONSTANTS.ACCOUNT_NUMBER_INPUT)[0];
            $contactStep = $reportOutageOru.getElementsByClassName(CONSTANTS.CONTACT_STEP)[0];
            $submitButton = document.getElementsByClassName(CONSTANTS.FORM_SUBMIT_BUTTON)[0];
            $submitButtonContainer = $submitButton.parentNode.parentNode;
            $notMyAddressLink = $reportOutageOru.getElementsByClassName(
                CONSTANTS.NOT_MY_ADDRESS_LINK
            )[0];
            $additionalDetailsContainer = $reportOutageOru.getElementsByClassName(
                CONSTANTS.ADDITIONAL_DETAILS_CONTAINER
            )[0];
            $issueTypeContainer = $reportOutageOru.getElementsByClassName(
                CONSTANTS.ISSUE_TYPE_CONTAINER
            )[0];
            $issueTypeSelector = $reportOutageOru.getElementsByClassName(
                CONSTANTS.ISSUE_TYPE_SELECTOR
            )[0];
            $shockOptions = $reportOutageOru.getElementsByClassName(CONSTANTS.SHOCK_OPTIONS)[0];
            $noPartialOptions = $reportOutageOru.getElementsByClassName(
                CONSTANTS.NO_PARTIAL_OPTIONS
            )[0];
            $serviceError = $reportOutageOru.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $lookupResult = $reportOutageOru.getElementsByClassName(CONSTANTS.LOOKUP_RESULT)[0];
            $lookupError = $reportOutageOru.getElementsByClassName(
                CONSTANTS.LOOKUP_SERVICE_ERROR
            )[0];
            $phoneNumber = $reportOutageOru.getElementsByClassName(CONSTANTS.CONTACT_NUMBER)[0];
            $transactionalWrapper = $reportOutageOru.getElementsByClassName(
                CONSTANTS.TRANSACTIONAL_WRAPPER
            )[0];
            $finishBannerContainer = document.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_BANNER_CONTAINER
            )[0];
            $finishBannerFocus = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_BANNER_FOCUS
            )[0];
            $finishBanner = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_BANNER
            )[0];
            $finishTextAccountNumber = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_ACCOUNT
            )[0];
            $finishTextStreetAddress = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_STREET_ADDRESS
            )[0];
            $finishTextCity = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_CITY
            )[0];
            $finishTextContactNumber = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_CONTACT_NUMBER
            )[0];
            $finishTextETR = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_ETR
            )[0];
            $finishDuplicateReport = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_DUPLICATE
            )[0];
            $finishShockMessage = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.SHOCK_MESSAGE
            )[0];
            $finishETRMessage = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.ETR_MESSAGE
            );
            $captchaElement = document.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT_CLASS);
            $accountLookupForm = $reportOutageOru.getElementsByClassName(
                CONSTANTS.ACCOUNT_LOOKUP_FORM
            )[0];

            _havePowerNo = $reportOutageOru.dataset.powerNo;
            _havePowerPartial = $reportOutageOru.dataset.powerPartial;
            _shockHumanOption = $reportOutageOru.dataset.shockOptionHuman;
            _shockAnimalOption = $reportOutageOru.dataset.shockOptionAnimal;
            _userAuthenticated = $reportOutageOru.dataset.userStatus;
            _hasCaptcha = $captchaElement.length;
            _initialLoad = true;
            // For patternlab, if the url has the "system-out=true" parameter, it shows the scenario in which the system is down
            _isSystemOut = 
                coned.utils.isPatternLab() ?
                    coned.utils.getUrlParameterValue(CONSTANTS.SYSTEM_OUT_PARAMETER) ? 
                        true : 
                        false :
                    $reportOutageOru.dataset.systemOut === 'true' ? 
                        true : 
                        false;
        };

        var initializeEvents = function () {
            // do you have power select
            $havePowerSelect.addEventListener('change', havePowerChange);

            // issue type selector
            $issueTypeSelector.addEventListener('change', issueTypeChange);

            // find address submit
            coned.utils.addGeneralListeners($findAddressButton, lookUpAddress);
            if ($autocompleteAparmentUnitInput) {
                $autocompleteAparmentUnitInput.addEventListener('selectedOptionEnd', lookUpAddress);
                $autocompleteAparmentUnitInput.addEventListener('checkAddress', lookUpAddress);

                $autocompleteAparmentUnitInput.addEventListener('inputChange', function () {
                    if (!$($autocompleteAparmentUnitInput).valid()) {
                        resetForm();
                        $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);

                        $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
                        $findAddressButtonContainer.classList.remove(
                            CONSTANTS.ERROR_SERVICE_RESPONSE_CLASS
                        );
                        $findAddressButtonContainer.classList.remove(
                            CONSTANTS.SUCCESS_SERVICE_RESPONSE_CLASS
                        );
                        $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }
                });

                $autocompleteAparmentUnitInput.parentElement.addEventListener(
                    'resetInput',
                    function () {
                        if (!query.hasClass($autocompleteSelectedAddress, CONSTANTS.HIDDEN_CLASS)) {
                            resetForm();
                        }
                        if(!_initialLoad) {
                            $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                        }

                        $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
                        $findAddressButtonContainer.classList.remove(
                            CONSTANTS.ERROR_SERVICE_RESPONSE_CLASS
                        );
                        $findAddressButtonContainer.classList.remove(
                            CONSTANTS.SUCCESS_SERVICE_RESPONSE_CLASS
                        );
                        $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }
                );
            }

            // find account options
            if ($findAccountSelect) {
                $findAccountSelect.addEventListener('change', findAccountOptions);
            }

            // not my address handler
            if ($notMyAddressLink) {
                coned.utils.addGeneralListeners($notMyAddressLink, notMyAddress);
            }

            // find account by address
            if ($searchByAddressLink) {
                coned.utils.addGeneralListeners($searchByAddressLink, findAccountByAddress);
            }

            if (_userAuthenticated) {
                $authenticatedMyAddressCheckbox.addEventListener(
                    'change',
                    authenticatedMyAddressChange
                );
            } 

            // Set tabindex on end screen for a11y
            $finishBannerFocus && $finishBannerFocus.setAttribute(CONSTANTS.TABINDEX, '-1');

            // form validation
            new coned.components.ValidateForm(CONSTANTS.FORM, reportOutageSubmit);

            recaptcha = new coned.components.Recaptcha(
                $reportOutageOru,
                recaptchaValidation,
                recaptchaValidation
            );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();

            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ReportOutageOru.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ReportOutageOru;
})();
