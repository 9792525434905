// ==================== FORM PROGRESS MODULE =========================
/* global _ */
/* global $ */
/* global query */
/* global TimelineMax */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FormProgress = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        STEP_CURRENT: 'js-current-step',
        STEP_ITEM: 'js-form-step',
        STEP_ITEM_CLASS: 'form-progress-bar__item',
        STEP_ACTIVE_CLASS: 'form-progress-bar__item--active',
        STEP_CURRENT_CLASS: 'form-progress-bar__item--current',
        STEP_VALID_CLASS: 'form-progress-bar__item--valid',
        STEP_NUMBER_CLASS: 'form-progress-bar__item-number',
        STEP_NAME_CLASS: 'form-progress-bar__item-name',
        STEP_DOT_CLASS: 'form-progress-bar__item-dot',
        STEP_DOT_SELECTOR: 'js-step-dot',
        STEPS_LIST: 'js-steps-list',
        STEPS_LIST_HIDE_TITLE: 'form-progress-bar__list--hide-title',
        FORM_STEPS: 'js-steps',
        FORM_ITEM_VALIDATE: 'js-item-validate',
        FORM_ITEM_IGNORE: 'js-validate-ignore',
        BUTTON_NEXT: 'js-next-button',
        FIELD_ERROR_MESSAGE: 'coned-input-message--error',
        FORM_STEPS_LIST: 'js-fieldset-list',
        ACTIVE_FORM: 'coned-form-wrapper',
        SELECT_ELEMENT: 'js-coned-select',
        DYNAMIC_FIELDS_ACTIVE: 'steps-ready',
        PROGRESS_BAR: 'js-progress',
        ANIMATION_SUBMIT_MODULE: '.js-submit-progress-animation:not(.hidden)',
        HELP_CHECKBOX_SELECTOR: 'js-extra-help-selector',
        HELP_LIST_ITEM_SELECTOR: 'js-extra-help-item-selector',
        HELP_BUTTON_SELECTOR: 'js-transactional-extra-help',
        HELP_INPUT_SELECTOR: 'js-checkbox-selector',
        CHECK_LIST_SELECTOR: 'js-check-list-selector',
        CHECK_LIST_ITEM_SELECTOR: 'js-check-item-selector',
        CHECK_LIST_ITEM_CLASS: 'check-list__item transactional__check-list-item',
        CHECK_LIST_TITLE_SELECTOR: 'js-item-title-selector',
        EDIT_STEP_SELECTOR: 'js-edit-step-selector',
        REVIEW_FIELDSET_SELECTOR: 'js-step-review',
        REMOVE_STEP: 'js-skip-step',
        ACCOUNT_PROFILE_STEP: 'js-account-profile',
        TRANSACTIONAL_FORM: '.transactional__form',
        EBILL_CHECK_BOX: 'js-ebill-checkbox',
        EBILL_CHECK_LIST: 'js-check-list-ebill',
        TOOLTIP_CONTAINER: 'js-ebill-tooltip',
        FORM_DOB_NAME: 'identityDateOfBirth',
        TOOLTIP_OPEN: 'js-tooltip-open',
        TOOLTIP_CONTENT: 'js-tooltip-content',
        TOOLTIP_LIST: 'coned-tooltip__list',
        IS_FORM_BLOCKED: 'js-form-blocked',
        HIDDEN_CLASS: 'hidden',
        NAVIGATION_RESET: 'js-form-navigation-reset',
        AUTHENTICATION_TYPE_SELECTOR: 'js-identification-type',
        NO_IDENTIFICATION: 'js-no-identification',
        DEPOSIT_PAYMENT_CONTINUE_BUTTON: 'js-payment-method-button',
        DEPOSIT_PAYMENT_SUBMIT: 'js-deposit-payment-submit',
        DEPOSIT_PAYMENT_STEP_SELECTOR: 'js-step-no-id-pay',
        DIRECT_PAYMENT_STEP_SELECTOR: 'js-step-direct-pay',
        DEPOSIT_ROUTING_ABA_SELECTOR: 'js-deposit-routing-aba-number',
        DEPOSIT_ACCOUNT_NUMBER_SELECTOR: 'js-deposit-account-number',
        DEPOSIT_BANK_CHECKING: 'checking',
        DEPOSIT_BANK_SAVINGS: 'savings',
        DIRECT_PAYMENT_ENROLL: 'js-direct-payment-enroll',
        FORM_DP_ACCOUNT_TYPE_NAME: 'accountType',
        FORM_DP_ROUTING_NUMBER_NAME: 'routingAbaNumber',
        FORM_DP_ACCOUNT_NUMBER_NAME: 'accountNumber',
        FILLED_CLASS: 'coned-input--filled',
        DPP_BANK_CHECKING: 'checkingAccount',
        DPP_BANK_SAVINGS: 'savingsAccount',
        STEP_SUCCESS_EVENT: 'step-success',
        STEP_ERROR_EVENT: 'step-error',
        DEPOSIT_PAYMENT_LIMIT_AGE: 62,
        START_SERVICE_SELECTOR: 'js-start-service',
        MAX_STEPS_DESKTOP_VIEW: 5,
        STEP_SMALL_VIEW: 'form-progress-bar__item--small',
        CURRENT_FORM_SELECTOR: '.js-current-form-step:visible',
        STEP_INFORMATION: 'js-step-information',
        PROGRESS_BAR_STEP_BUTTON: '.form-progress-bar__item-button',
        VISUALLY_HIDDEN: 'visually-hidden',
        PROGRESS_CLASS_BUTTON: 'form-progress-bar__item-button',
        STEPS_INFORMATION: 'js-steps-information',
        DISABLED: 'disabled',
        BUTTON_TAG: 'button'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var FormProgress = function ($formProgress) {
        /**
         * PRIVATE METHODS
         */
        var $steps,
            $stepsList,
            $progressBar,
            $nextButtons,
            $helpCheckboxSelector,
            $helpButtonSelectors,
            $checkListItemSelector,
            $checkListSelector,
            $reviewFieldsetSelector,
            $removeStepButtons,
            $accountProfileStep,
            $ebillCheckBox,
            $ebillCheckList,
            $navigationReset,
            $depositPaymentStepSelector,
            $directPaymentStepSelector,
            $depositRouting,
            $depositAccount,
            $depositPaymentContinueButton,
            $depositPaymentSubmit,
            $startServiceSelector,
            _depositWaived,
            _checkItemContain,
            _formSubmitted;

        var changeStep = function (currentStep, focusTransferService, event) {
            if (event !== undefined) {
                event.preventDefault();
            }

            var stepsNumber = $steps.length;

            // hide title if dynamic steps are more than 6
            if (stepsNumber >= 6) {
                $stepsList.classList.add(CONSTANTS.STEPS_LIST_HIDE_TITLE);
            } else {
                $stepsList.classList.remove(CONSTANTS.STEPS_LIST_HIDE_TITLE);
            }

            // Set small step view
            _.each($stepsList.children, function ($step) {
                if ($stepsList.childElementCount > CONSTANTS.MAX_STEPS_DESKTOP_VIEW) {
                    $step.classList.add(CONSTANTS.STEP_SMALL_VIEW);
                } else {
                    $step.classList.remove(CONSTANTS.STEP_SMALL_VIEW);
                }
            });

            var selectedStep = currentStep && currentStep.target ? this : currentStep,
                stepNumber = selectedStep.dataset.step,
                stepLeftMargin = +selectedStep.offsetLeft,
                stepWidth = selectedStep.offsetWidth,
                barWidth = stepLeftMargin + stepWidth,
                isActive = query.hasClass(selectedStep, CONSTANTS.STEP_ACTIVE_CLASS),
                $formSteps = document.getElementsByClassName(CONSTANTS.FORM_STEPS),
                stepDot = selectedStep.getElementsByClassName(CONSTANTS.STEP_DOT_SELECTOR)[0],
                timeLine = new TimelineMax({ repeat: 0 }),
                fieldList = document.getElementsByClassName(CONSTANTS.FORM_STEPS_LIST)[0],
                currentStepProgress = $formProgress.getElementsByClassName(
                    CONSTANTS.STEP_CURRENT
                )[0],
                currentStepForm = fieldList.querySelectorAll(
                    '[data-step-form="' + currentStepProgress.dataset.step + '"]'
                )[0],
                isFormBlocked = currentStepProgress.classList.contains(CONSTANTS.IS_FORM_BLOCKED),
                isHelpCheckbox =
                    currentStepProgress &&
                    currentStepProgress.classList.contains(CONSTANTS.HELP_LIST_ITEM_SELECTOR)
                        ? true
                        : false;

            // hide title if dynamic steps are more than 6
            if (stepsNumber > 6) {
                $stepsList.classList.add(CONSTANTS.STEPS_LIST_HIDE_TITLE);
            } else {
                $stepsList.classList.remove(CONSTANTS.STEPS_LIST_HIDE_TITLE);
            }

            // Set small step view
            _.each($stepsList.children, function ($step) {
                if ($stepsList.childElementCount > CONSTANTS.MAX_STEPS_DESKTOP_VIEW) {
                    $step.classList.add(CONSTANTS.STEP_SMALL_VIEW);
                } else {
                    $step.classList.remove(CONSTANTS.STEP_SMALL_VIEW);
                }
            });

            if (currentStep.target && !_formSubmitted && isHelpCheckbox) {
                $(CONSTANTS.CURRENT_FORM_SELECTOR).submit();
            }

            var formFields = currentStepForm.getElementsByClassName(CONSTANTS.FORM_ITEM_VALIDATE),
                errorFields = [];

            _.each(formFields, function (field) {
                if (query.hasClass(field, CONSTANTS.FIELD_ERROR_MESSAGE)) {
                    errorFields.push(field);
                }
            });

            if (isActive && errorFields <= 0 && !isFormBlocked) {
                $progressBar.style.width = barWidth - 50 + 'px';
                $progressBar.style.marginLeft = 0;

                var lastStep = document.getElementsByClassName(CONSTANTS.STEP_CURRENT)[0];

                // selected step class asignment
                _.each($steps, function (formStep) {
                    formStep.classList.remove(CONSTANTS.STEP_CURRENT);
                    formStep.classList.remove(CONSTANTS.STEP_CURRENT_CLASS);

                    if (formStep.dataset.step > stepNumber) {
                        var stepButton = formStep.querySelector(CONSTANTS.PROGRESS_BAR_STEP_BUTTON);
                        !formStep.classList.contains(CONSTANTS.STEP_ACTIVE_CLASS) &&
                            stepButton.setAttribute(CONSTANTS.DISABLED, true);
                        formStep.classList.add(CONSTANTS.STEP_VALID_CLASS);
                    } else if (stepNumber > formStep.dataset.step) {
                        formStep.classList.remove(CONSTANTS.STEP_VALID_CLASS);
                    }
                });

                // dot active animation
                timeLine.to(stepDot, 0.5, { opacity: 0 }).to(stepDot, 0.5, { opacity: 1 });

                // add current class to step
                setCurrentStep(selectedStep);

                //Update infomation of current step of list
                updateStepInformation(selectedStep.dataset.step, $steps.length);

                //Update text of step in progress bar
                updateAccessibilityState(lastStep, selectedStep);

                // show hide steps fieldsets
                _.each($formSteps, function (step) {
                    if (step.dataset.stepForm == stepNumber) {
                        // show current form fieldset
                        step.style.display = 'block';

                        removeIgnoreStatus(step);

                        // set next buttons
                        $nextButtons = step.getElementsByClassName(CONSTANTS.BUTTON_NEXT);

                        Array.prototype.forEach.call($nextButtons, function ($nextButton) {
                            $nextButton.dataset.nextStep = parseInt(stepNumber, 10) + 1;

                            //nextButton listener
                            coned.utils.addGeneralListeners($nextButton, nextStep);
                        });

                        var $formTransferService = $(CONSTANTS.TRANSACTIONAL_FORM);
                        if (
                            $formTransferService &&
                            $formTransferService[0].hasAttribute('tabindex') &&
                            focusTransferService !== false
                        ) {
                            $formTransferService.focus();
                        }
                    } else {
                        step.style.display = 'none';
                    }
                });

                _formSubmitted = false;
            }
        };

        /**
         * Updating infomation of current of the list example [Step 2 of 5]
         * @param {*} currentStep
         * @param {*} totalLenght
         */
        var updateStepInformation = function (currentStep, totalLenght) {
            var $stepInformation = $stepsList.parentElement.parentElement.getElementsByClassName(
                CONSTANTS.STEPS_INFORMATION
            )[0];
            var stepWord = $stepInformation.dataset.step;
            var ofWord = $stepInformation.dataset.of;

            var text =
                stepWord +
                ' ' +
                (parseInt(currentStep) + 1) +
                ' ' +
                ofWord +
                ' ' +
                (totalLenght + 1);

            $stepInformation.textContent = text;
        };

        var setCurrentStep = function (selectedStep) {
            selectedStep.classList.add(CONSTANTS.STEP_CURRENT);
            selectedStep.classList.add(CONSTANTS.STEP_CURRENT_CLASS);
            selectedStep.classList.add(CONSTANTS.STEP_ACTIVE_CLASS);
            selectedStep.classList.remove(CONSTANTS.STEP_VALID_CLASS);
            var selectedStepButton = selectedStep.querySelector(CONSTANTS.PROGRESS_BAR_STEP_BUTTON);
            selectedStepButton.removeAttribute(CONSTANTS.DISABLED);
        };

        var disableCurrentStep = function (currentStep) {
            var button = currentStep.getElementsByTagName(CONSTANTS.BUTTON_TAG)[0],
                spanText = currentStep.getElementsByClassName(CONSTANTS.STEP_INFORMATION)[0];

            currentStep.classList.remove(CONSTANTS.STEP_ACTIVE_CLASS);
            button.setAttribute(CONSTANTS.DISABLED, true);
            spanText.textContent = spanText.dataset.incompleted;
        };

        /**
         * Update status of non-visually span for accessibility
         * @param {*} lastSelectStep
         * @param {*} selectedStep
         */
        var updateAccessibilityState = function (lastSelectStep, selectedStep) {
            var spanSelectedStep = selectedStep.getElementsByClassName(
                CONSTANTS.STEP_INFORMATION
            )[0];
            var spanLastSelectStep = lastSelectStep.getElementsByClassName(
                CONSTANTS.STEP_INFORMATION
            )[0];

            if (lastSelectStep != selectedStep) {
                spanSelectedStep &&
                    (spanSelectedStep.textContent = spanSelectedStep.dataset.current);
                spanLastSelectStep &&
                    (spanLastSelectStep.textContent = spanLastSelectStep.dataset.completed);
            } else if (selectedStep == lastSelectStep.parentElement.lastElementChild) {
                spanLastSelectStep &&
                    (spanLastSelectStep.textContent = spanLastSelectStep.dataset.completed);
            } else {
                spanSelectedStep &&
                    (spanSelectedStep.textContent = spanSelectedStep.dataset.current);
            }
        };

        // remove ignore class used for validate step by step
        var removeIgnoreStatus = function (activeStep) {
            var ignoreFields = activeStep.getElementsByClassName(CONSTANTS.FORM_ITEM_VALIDATE);

            _.each(ignoreFields, function (field) {
                field.classList.remove(CONSTANTS.FORM_ITEM_IGNORE);
            });
        };

        var nextStep = function (event) {
            var blockNextStep = false;

            if (event !== undefined) {
                event.preventDefault();

                var $target = event.currentTarget;

                blockNextStep =
                    ($target.dataset.hasRedirect && $target.dataset.hasRedirect === 'true') ||
                    $target.disabled ||
                    !$($target).is(':visible')
                        ? true
                        : false;
            }

            if (!blockNextStep) {
                $(CONSTANTS.TRANSACTIONAL_FORM).submit();
                _formSubmitted = true;

                var actualStep = $formProgress.getElementsByClassName(CONSTANTS.STEP_CURRENT)[0],
                    actualStepNumber = parseInt(
                        $formProgress.getElementsByClassName(CONSTANTS.STEP_CURRENT)[0].dataset.step
                    ),
                    fieldList = document.getElementsByClassName(CONSTANTS.FORM_STEPS_LIST)[0],
                    actualStepForm = fieldList.querySelectorAll(
                        '[data-step-form="' + actualStepNumber + '"]'
                    )[0],
                    nextStep = actualStep.nextElementSibling,
                    actualAnimationButtons = actualStepForm.querySelectorAll(
                        CONSTANTS.ANIMATION_SUBMIT_MODULE
                    ),
                    $directPaymentEnroll = document.getElementsByClassName(
                        CONSTANTS.DIRECT_PAYMENT_ENROLL
                    )[0];

                setTimeout(function () {
                    var stepFields = actualStepForm.getElementsByClassName(
                            CONSTANTS.FORM_ITEM_VALIDATE
                        ),
                        errorFields = [],
                        $target = query.selectParentElement(event.target, CONSTANTS.BUTTON_NEXT);

                    _.each(stepFields, function (field) {
                        if (query.hasClass(field, CONSTANTS.FIELD_ERROR_MESSAGE)) {
                            errorFields.push(field);
                        }
                    });

                    if (errorFields.length > 0) {
                        coned.utils.triggerEvent($target, CONSTANTS.STEP_ERROR_EVENT);
                    } else if (errorFields.length <= 0 && nextStep) {
                        coned.utils.triggerEvent($target, CONSTANTS.STEP_SUCCESS_EVENT);
                        nextStep.classList.add(CONSTANTS.STEP_ACTIVE_CLASS);
                        Array.prototype.forEach.call(actualAnimationButtons, function ($button) {
                            new coned.components.SubmitAnimation(
                                $button,
                                nextStep,
                                function () {
                                    if ($startServiceSelector) {
                                        if (
                                            actualStepForm.classList.contains(
                                                CONSTANTS.DIRECT_PAYMENT_STEP_SELECTOR
                                            ) &&
                                            $directPaymentEnroll.checked &&
                                            $depositPaymentStepSelector.dataset.isCommercial ===
                                                'true'
                                        ) {
                                            updateBankChoices();
                                        }

                                        $target.Callback && $target.Callback();
                                    }
                                },
                                function () {},
                                true,
                                true
                            );
                            // Add success service response class to stop the submit animation
                            $button.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
                        });

                        // resetForm errors on step change
                        var formValidator = $(CONSTANTS.TRANSACTIONAL_FORM).validate({});
                        formValidator.resetForm();

                        $(CONSTANTS.TRANSACTIONAL_FORM) && $(CONSTANTS.TRANSACTIONAL_FORM).focus();

                        // Analytics data building
                        if (actualStepNumber <= 3) {
                            dataLayer.push({
                                event: 'coned.form.step0' + actualStepNumber
                            });
                        }
                    }
                }, 80);
            }
        };

        var updateBankChoices = function () {
            // checked radio
            var BankAccountType = query.getFormInputValue(
                $directPaymentStepSelector,
                CONSTANTS.FORM_DP_ACCOUNT_TYPE_NAME
            );
            var BankRoutingNumber = query.getFormInputValue(
                $directPaymentStepSelector,
                CONSTANTS.FORM_DP_ROUTING_NUMBER_NAME
            );
            var BankAccountNumber = query.getFormInputValue(
                $directPaymentStepSelector,
                CONSTANTS.FORM_DP_ACCOUNT_NUMBER_NAME
            );

            if (BankAccountType === CONSTANTS.DPP_BANK_CHECKING) {
                var $checkingRadio = $depositPaymentStepSelector.querySelector(
                    '[value*=' + CONSTANTS.DEPOSIT_BANK_CHECKING + ']'
                );
                $checkingRadio.checked = true;
            } else if (BankAccountType === CONSTANTS.DPP_BANK_SAVINGS) {
                var $savingsRadio = $depositPaymentStepSelector.querySelector(
                    '[value*=' + CONSTANTS.DEPOSIT_BANK_SAVINGS + ']'
                );
                $savingsRadio.checked = true;
            }

            $depositRouting.value = BankRoutingNumber;
            $depositAccount.value = BankAccountNumber;
            $depositRouting.classList.add(CONSTANTS.FILLED_CLASS);
            $depositAccount.classList.add(CONSTANTS.FILLED_CLASS);
            $depositRouting.classList.add('valid');
            $depositAccount.classList.add('valid');

            // For next screen, Enable Continue button
            $depositPaymentContinueButton.disabled = false;
            $depositPaymentContinueButton.click();
            $depositPaymentSubmit.disabled = false;
        };

        // dynamic steps creation
        var includeStep = function (number, stepTitle, $currentStep, className) {
            className = className || '';

            var $stepItem = document.createElement('li'),
                $stepNumber = document.createElement('span'),
                $stepName = document.createElement('span'),
                $stepDot = document.createElement('span'),
                $stepState = document.createElement('span'),
                $stepButton = document.createElement('button');
            var presteps = $($stepsList)
                .children()
                .filter(function () {
                    return this.dataset.preload;
                }).length;

            var currentStepSpan = $currentStep.getElementsByClassName(
                CONSTANTS.STEP_INFORMATION
            )[0];

            // create step item
            $stepItem.classList.add(CONSTANTS.STEP_ITEM_CLASS);
            $stepItem.classList.add(CONSTANTS.STEP_ITEM);
            $stepItem.setAttribute('data-step', number);

            if (className && className !== '') {
                className = className.split(' ');
                _.each(className, function (classNameItem) {
                    $stepItem.classList.add(classNameItem);
                });
            }

            $stepButton.classList.add(CONSTANTS.PROGRESS_CLASS_BUTTON);

            $stepState.classList.add(CONSTANTS.VISUALLY_HIDDEN);
            $stepState.classList.add(CONSTANTS.STEP_INFORMATION);

            currentStepSpan.dataset.current &&
                $stepState.setAttribute('data-current', currentStepSpan.dataset.current);
            currentStepSpan.dataset.completed &&
                $stepState.setAttribute('data-completed', currentStepSpan.dataset.completed);
            currentStepSpan.dataset.incompleted &&
                $stepState.setAttribute('data-incompleted', currentStepSpan.dataset.incompleted);

            currentStepSpan.dataset.incompleted &&
                ($stepState.innerHTML = currentStepSpan.dataset.incompleted);

            // account for preloaded steps
            number = parseInt(number) + presteps;

            // create step number
            $stepNumber.classList.add(CONSTANTS.STEP_NUMBER_CLASS);
            $stepNumber.innerHTML = number;

            // create step name
            $stepName.classList.add(CONSTANTS.STEP_NAME_CLASS);
            $stepName.innerHTML = stepTitle;

            // create step dot
            $stepDot.classList.add(CONSTANTS.STEP_DOT_CLASS);
            $stepDot.classList.add(CONSTANTS.STEP_DOT_SELECTOR);

            $stepButton.appendChild($stepState);
            $stepButton.appendChild($stepNumber);
            $stepButton.appendChild($stepName);
            $stepButton.appendChild($stepDot);

            $stepItem.appendChild($stepButton);

            $stepsList.insertBefore($stepItem, $currentStep.nextSibling);

            // add event listener to new step and adjust progress bar
            coned.utils.addGeneralListeners($stepItem, changeStep);
        };

        // dynamic remove steps
        var removeStep = function (event) {
            if (event !== undefined) {
                event.preventDefault();
            }

            var stepToRemove = this.dataset.step,
                currentStep = $formProgress.getElementsByClassName(CONSTANTS.STEP_CURRENT)[0];

            _.each($helpCheckboxSelector, function (checkbox) {
                var $radioInput, $checkboxInput;

                if (checkbox.dataset.selector == stepToRemove) {
                    $checkboxInput = checkbox.getElementsByClassName(
                        CONSTANTS.HELP_INPUT_SELECTOR
                    )[0];

                    if ($checkboxInput) {
                        $checkboxInput.click();
                        $checkboxInput.checked = false;
                    } else {
                        $radioInput = checkbox.querySelector('input:not(:checked)');
                        $radioInput.click();
                    }
                }
            });

            // resetForm errors on step change
            var formValidator = $(CONSTANTS.TRANSACTIONAL_FORM).validate({});
            formValidator.resetForm();
            _formSubmitted = true;

            $accountProfileStep.click();
            setHelpCheckbox(event);
            changeStep($accountProfileStep, true);
            
            // disable the current bar step 
            disableCurrentStep(currentStep);
        };

        var resetHelpCheckbox = function () {
            var counter = 3;

            while ($steps.length > 3) {
                var elementChild = $formProgress.getElementsByClassName(CONSTANTS.STEP_ITEM)[
                    counter
                ];

                if (elementChild) {
                    // remove events for unused element
                    elementChild.removeEventListener('click', changeStep);
                    elementChild.removeEventListener('touchend', changeStep);
                    $stepsList.removeChild(elementChild);
                }
            }
        };

        var setHelpCheckbox = function (event) {
            if (event !== undefined) {
                event.preventDefault();

                if (event.target.disabled || !$(event.target).is(':visible')) return;
            }

            var stepsTrigger = event.target,
                stepCounter = 4,
                includeStepArray = [],
                includeStepClassArray = [],
                includeDataArray = [],
                $currentStep = $formProgress.getElementsByClassName(CONSTANTS.STEP_CURRENT)[0],
                $directPaymentEnroll = document.getElementsByClassName(
                    CONSTANTS.DIRECT_PAYMENT_ENROLL
                )[0],
                $authenticationType = document.getElementsByClassName(
                    CONSTANTS.AUTHENTICATION_TYPE_SELECTOR
                )[0],
                _showDepositPaymentForm;

            //Set the flag to show the payment form
            if ($authenticationType && $directPaymentEnroll) {
                _showDepositPaymentForm =
                    $authenticationType.value === CONSTANTS.NO_IDENTIFICATION &&
                    !_depositWaived &&
                    !$directPaymentEnroll.checked &&
                    !checkOlderthan(CONSTANTS.DEPOSIT_PAYMENT_LIMIT_AGE)
                        ? true
                        : false;
            }

            if ($currentStep.dataset.step < 3) {
                $currentStep = $accountProfileStep;
            }

            // Cleaning review list
            $checkListSelector.innerHTML = '';
            resetHelpCheckbox();

            _.each($helpCheckboxSelector, function ($checkbox) {
                var $checkboxInput = $checkbox.getElementsByClassName(
                        CONSTANTS.HELP_INPUT_SELECTOR
                    )[0],
                    radioInput,
                    $radioSelected,
                    $checkboxTooltip = $checkbox.getElementsByClassName(
                        CONSTANTS.TOOLTIP_CONTENT
                    )[0],
                    $checkboxTooltipIcon = $checkbox.getElementsByClassName(
                        CONSTANTS.TOOLTIP_OPEN
                    )[0],
                    dataName = $checkbox.dataset.label,
                    classSelector = $checkbox.dataset.selector,
                    fieldStep = document.getElementsByClassName(classSelector)[0],
                    listItemClasses,
                    value;

                if (
                    $checkbox.classList.contains(CONSTANTS.AUTHENTICATION_TYPE_SELECTOR) &&
                    $checkbox.value == CONSTANTS.NO_IDENTIFICATION &&
                    !$directPaymentEnroll.checked
                ) {
                    value = false;
                    listItemClasses = $checkbox.dataset.breadcrumbTag;
                } else if ($checkboxInput) {
                    value = $checkboxInput.checked;

                    listItemClasses = $checkboxInput.dataset.breadcrumbTag;
                } else {
                    $radioSelected = $checkbox.querySelector('input:checked');

                    if ($radioSelected) {
                        radioInput = $radioSelected.value;
                    }

                    if (
                        radioInput &&
                        (radioInput.toLowerCase() == 'yes' || radioInput.toLowerCase() == 'true')
                    ) {
                        value = true;
                    } else {
                        value = false;
                    }

                    listItemClasses =
                        $checkbox.dataset.breadcrumbTag + ' ' + CONSTANTS.HELP_LIST_ITEM_SELECTOR;
                }

                if (value) {
                    // li creation
                    var $actualElement = document.createElement('li'),
                        $checkboxModule,
                        checkBoxText;

                    $actualElement.innerHTML = _checkItemContain;
                    $actualElement.className = CONSTANTS.CHECK_LIST_ITEM_CLASS;
                    $checkboxModule = $actualElement.getElementsByClassName(
                        CONSTANTS.TOOLTIP_LIST
                    )[0];

                    // validate if tooltip exists
                    if ($checkboxTooltipIcon) {
                        var toolTipText = $checkboxTooltip ? $checkboxTooltip.innerHTML : '';

                        checkBoxText = $actualElement.getElementsByClassName(
                            CONSTANTS.TOOLTIP_CONTENT
                        )[0];
                        checkBoxText.innerHTML = toolTipText;

                        // init the tool tip module
                        new coned.components.ToolTipComponent($checkboxModule);
                    } else {
                        $checkboxModule.classList.add(CONSTANTS.HIDDEN_CLASS);
                    }

                    // Set data step
                    fieldStep.dataset.stepForm = '' + stepCounter;

                    // Set data
                    var $actualTitle = $actualElement.getElementsByClassName(
                        CONSTANTS.CHECK_LIST_TITLE_SELECTOR
                    )[0];

                    $actualTitle.innerHTML = dataName;
                    $checkListSelector.appendChild($actualElement);

                    includeStepArray.push('' + stepCounter);
                    includeStepClassArray.push(listItemClasses);
                    includeDataArray.push(dataName);

                    stepCounter++;
                } else {
                    if (fieldStep) {
                        fieldStep.dataset.stepForm = '';
                    }
                }
            });

            if ($depositPaymentStepSelector) {
                if (
                    _showDepositPaymentForm ||
                    $depositPaymentStepSelector.dataset.isCommercial === 'true'
                ) {
                    // Set data step
                    $depositPaymentStepSelector.dataset.stepForm = '' + stepCounter;
                    $depositPaymentStepSelector.classList.remove(CONSTANTS.REMOVE_STEP);

                    includeStepArray.push('' + stepCounter);
                    includeStepClassArray.push($directPaymentEnroll.dataset.breadcrumbTag);
                    includeDataArray.push($depositPaymentStepSelector.dataset.label);

                    stepCounter++;
                }

                if (
                    ($directPaymentEnroll.checked || !_showDepositPaymentForm) &&
                        $depositPaymentStepSelector.dataset.isCommercial === 'false'  
                ) {
                    $depositPaymentStepSelector.dataset.stepForm = '';
                    $depositPaymentStepSelector.style.display = 'none';
                }
            }

            // Update Review step
            $reviewFieldsetSelector.dataset.stepForm = '' + stepCounter;
            includeStep('' + stepCounter, 'Review', $currentStep);

            // Set steps
            for (var index = includeStepArray.length - 1; index >= 0; index--) {
                includeStep(
                    includeStepArray[index],
                    includeDataArray[index],
                    $currentStep,
                    includeStepClassArray[index]
                );
            }

            // Set Edit Steps
            var $editLinks = document.getElementsByClassName(CONSTANTS.EDIT_STEP_SELECTOR);
            var stepIndex = 3;

            _.each($editLinks, function ($edit) {
                var $actualStepIndex = $formProgress.getElementsByClassName(CONSTANTS.STEP_ITEM)[
                    stepIndex
                ];

                coned.utils.addGeneralListeners($edit, function (event) {
                    event.preventDefault();

                    window.scrollTo(0, 0);
                    $actualStepIndex.click();
                });
                stepIndex++;
            });

            stepsTrigger.classList.add(CONSTANTS.DYNAMIC_FIELDS_ACTIVE);
        };

        var ebillEdit = function () {
            var $ebillTooltip = document.getElementsByClassName(CONSTANTS.TOOLTIP_CONTAINER)[0],
                $ebillTooltipIcon = $ebillTooltip.getElementsByClassName(CONSTANTS.TOOLTIP_OPEN)[0],
                $ebillReviewTooltip = $ebillCheckList.getElementsByClassName(
                    CONSTANTS.TOOLTIP_OPEN
                )[0];

            if (this.checked) {
                $ebillCheckList.style.display = 'block';
            } else {
                $ebillCheckList.style.display = 'none';
            }

            // validate if tooltip exists
            if ($ebillTooltipIcon && $ebillReviewTooltip) {
                $ebillReviewTooltip.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var checkOlderthan = function (limitAge) {
            var value = query.getFormInputValue(document, CONSTANTS.FORM_DOB_NAME);

            if (value) {
                var selectedDate = new Date(value),
                    birthDateDay = selectedDate.getDate(),
                    birthDateMonth = selectedDate.getMonth(),
                    birthDateYear = selectedDate.getFullYear(),
                    minimumDate = new Date(birthDateYear + limitAge, birthDateMonth, birthDateDay);

                return minimumDate <= new Date();
            } else {
                return false;
            }
        };

        var initializeData = function () {
            $steps = $formProgress.getElementsByClassName(CONSTANTS.STEP_ITEM);
            $progressBar = $formProgress.getElementsByClassName(CONSTANTS.PROGRESS_BAR)[0];
            $stepsList = $formProgress.getElementsByClassName(CONSTANTS.STEPS_LIST)[0];
            $helpCheckboxSelector = document.getElementsByClassName(
                CONSTANTS.HELP_CHECKBOX_SELECTOR
            );
            $helpButtonSelectors = document.getElementsByClassName(CONSTANTS.HELP_BUTTON_SELECTOR);
            $checkListItemSelector = document.getElementsByClassName(
                CONSTANTS.CHECK_LIST_ITEM_SELECTOR
            )[0];
            $checkListSelector = document.getElementsByClassName(CONSTANTS.CHECK_LIST_SELECTOR)[0];
            $reviewFieldsetSelector = document.getElementsByClassName(
                CONSTANTS.REVIEW_FIELDSET_SELECTOR
            )[0];
            $removeStepButtons = document.getElementsByClassName(CONSTANTS.REMOVE_STEP);
            $accountProfileStep = document.getElementsByClassName(
                CONSTANTS.ACCOUNT_PROFILE_STEP
            )[0];
            $ebillCheckBox = document.getElementsByClassName(CONSTANTS.EBILL_CHECK_BOX)[0];
            $ebillCheckList = document.getElementsByClassName(CONSTANTS.EBILL_CHECK_LIST)[0];
            $navigationReset = document.getElementsByClassName(CONSTANTS.NAVIGATION_RESET)[0];
            $depositPaymentStepSelector = document.getElementsByClassName(
                CONSTANTS.DEPOSIT_PAYMENT_STEP_SELECTOR
            )[0];
            $directPaymentStepSelector = document.getElementsByClassName(
                CONSTANTS.DIRECT_PAYMENT_STEP_SELECTOR
            )[0];
            $depositAccount = document.getElementsByClassName(
                CONSTANTS.DEPOSIT_ACCOUNT_NUMBER_SELECTOR
            )[0];
            $depositRouting = document.getElementsByClassName(
                CONSTANTS.DEPOSIT_ROUTING_ABA_SELECTOR
            )[0];
            $depositPaymentSubmit = document.getElementsByClassName(
                CONSTANTS.DEPOSIT_PAYMENT_SUBMIT
            )[0];
            $depositPaymentContinueButton = document.getElementsByClassName(
                CONSTANTS.DEPOSIT_PAYMENT_CONTINUE_BUTTON
            )[0];
            $startServiceSelector = document.getElementsByClassName(
                CONSTANTS.START_SERVICE_SELECTOR
            )[0];
            _checkItemContain = $checkListItemSelector.innerHTML;
            _depositWaived = false;
        };

        var initializeEvents = function () {
            // first step without a preload flag
            var firstStep = $formProgress.getElementsByClassName(CONSTANTS.STEP_ITEM)[0];

            // add event listener to steps items
            _.each($steps, function ($step) {
                coned.utils.addGeneralListeners($step, changeStep);
            });

            // add event listener for remove step
            _.each($removeStepButtons, function ($removeButton) {
                coned.utils.addGeneralListeners($removeButton, removeStep);
            });

            // resize listener for steps
            window.addEventListener('resize', function () {
                var currentStep = $formProgress.getElementsByClassName(CONSTANTS.STEP_CURRENT)[0];
                changeStep(currentStep, false);
            });

            // init first step
            changeStep(firstStep, true);

            $navigationReset && coned.utils.addGeneralListeners($navigationReset, setHelpCheckbox);

            // Ebill checkbox Listener
            $ebillCheckBox.addEventListener('change', ebillEdit);

            // Checkbox listeners
            Array.prototype.forEach.call($helpButtonSelectors, function ($button) {
                coned.utils.addGeneralListeners($button, setHelpCheckbox);
            });

            $formProgress.addEventListener('deposit-waived', function () {
                _depositWaived = true;
            });

            $formProgress.addEventListener('deposit-required', function () {
                _depositWaived = false;
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FormProgress.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FormProgress;
})();
