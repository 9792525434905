// ==================== HEADER COMPONENT =========================
/* global gsap */
/* global Sine */
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.HeaderComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HEADER_MENU_CLASS: 'js-action-menu',
        HEADER_MENU_ICON_CLASS: 'header__action-btn-icon',
        MODAL_SELECTOR: 'js-modal-selector',
        MODAL_SELECTOR_LIST: 'js-modal-selector-list',
        HEADER_MENU_ICON: 'icon-menu',
        HEADER_MENU_OPEN_ICON: 'icon-close',
        MODAL_ACTIVE_CLASS: 'header__secondary-link--active',
        MODAL_SELECTOR_WRAPPER_ACTIVE: 'modal-selector-wrapper--active',
        LOGGED_IN_MODAL_CLASS: 'js-modal-logged-in-wrapper',
        LOGGED_IN_MODAL_OPEN_CLASS: 'modal-logged-in-wrapper--open',
        OPEN_LOGGED_IN_MODAL: 'js-open-logged-in-modal',
        CLOSE_LOGGED_IN_MODAL: 'js-close-logged-in-modal',
        LOGGED_IN_MODAL_ITEM_CLASS: 'js-modal-logged-in-item',
        NAV_CLASS: 'header__primary',
        NAV_OPEN_CLASS: 'header__primary--open',
        NAV_PRIMARY_ITEM_HOVER_CLASS: 'hover',
        NAV_PRIMARY_ITEM_CLASS: 'js-primary-nav-item',
        NAV_PRIMARY_ITEM_LINK_CLASS: 'primary-nav-item__anchor',
        NAV_ITEM_CLASS: 'js-nav-item',
        NAV_ITEM_CLASS_SELECTOR: '.js-nav-item',
        NAV_ITEM_ICON_CLASS: 'primary-nav-item__icon',
        NAV_ITEM_ICON_OPEN_CLASS: 'primary-nav-item__icon--open',
        NAV_ITEM_LINKS_CLASS: 'primary-nav-item__links',
        NAV_ITEM_BOTTOM_SECTION : 'js-primary-nav-item-bottom-section',
        NAV_ITEM_LINKS_OPEN_CLASS: 'primary-nav-item__links--open',
        NAV_ITEM_NAME: 'js-primary-nav-name',
        NAV_ITEM_ADDRESS: 'js-primary-nav-address',
        NAV_BUTTON_ACCOUNT_SELECTOR: 'js-nav-button-account-selector',
        NAV_ITEM_PRIMARY_ACCOUNT_SELECTOR: 'js-primary-nav-item-account-selector',
        NAV_ITEM_DROPDOWN_CONTAINER: 'js-dropdown-container',
        NAV_ITEM_DROPDOWN_ITEM: 'js-account-dropdown-search-item-desktop',
        NAV_OPEN_MODAL: 'js-open-modal',
        NAV_MOBILE_OPEN_MODAL: 'js-mobile-modal-open',
        NAV_CLOSE_MODAL: 'js-close-modal',
        NAV_MOBILE_SECONDARY: 'js-nav-mobile-secondary',
        NAV_MOBILE_SECONDARY_INIT_PADDING: 40,
        NAV_MOBILE_PRIMARY_ITEM_FORWARD: 'js-primary-nav-mobile-item-forward',
        NAV_MOBILE_PRIMARY_ITEM_BACK: 'js-primary-nav-mobile-item-back',
        NAV_MOBILE_DRAWER_PRIMARY_WRAPPER: 'js-primary-nav-drawer-wrapper',
        NAV_MOBILE_DRAWER_SECONDARY_WRAPPER: 'js-secondary-nav-drawer-wrapper',
        NAV_MOBILE_DRAWER_PRIMARY_HIDE: 'primary-nav-drawer__primary-wrapper--hide',
        NAV_MOBILE_DRAWER_SECONDARY_SHOW: 'primary-nav-drawer__secondary-wrapper--show',
        NAV_MOBILE_BOTTOM_PADDING: '25',
        ALERTS_CONTAINER_CLASS: 'js-alerts-container',
        LOGOUT_LINK_CLASS: 'js-logout',
        ORU_NAV_CLASS: 'header--oru',
        ORANGE_COLOR: '#e77613',
        BLUE_COLOR: '#069bd7',
        ANIMATION_MENU_SELECTOR: 'js-animation-selector',
        ANIMATION_MENU_CLASS: 'header--nav-item-animation',
        DYNAMIC_LINK_CLASS: 'js-dynamic-link',
        ANCHOR_TAG_SELECTOR: 'a',
        HIDDEN_CLASS: 'hidden',
        VISUALLY_HIDDEN_CLASS: 'visually-hidden',
        BOTTOM_LINKS: 'js-bottom-links',
        VIRTUAL_ASSISTANT_CLASS: 'va-chat',
        ALERT_CLOSED_EVENT: 'alert-closed',
        SEARCH_DESCRIPTION: 'js-search-description',
        HEADER_BORDER_ANIMATION_TIME: 0.3,
        LANGUAGE_SWITCHER_CONTAINER_SELECTOR: "js-language-switcher-container",
        LANGUAGE_SWITCHER_SELECT_LANGUAGE_EVENT: "selectLanguage",
        // A11y
        ARIA_EXPANDED: 'aria-expanded',
        ARIA_MODAL: 'aria-modal',
        ROLE: 'role',
        DIALOG: 'dialog',
        EVENT_TYPE_MOUSEOENTER: 'mouseenter',
        EVENT_TYPE_MOUSELEAVE: 'mouseleave',
        SCREEN_MAX_HEIGHT_200_ZOOM: 690,
        SCREEN_MAX_HEIGHT_WITH_ALERT_200_ZOOM: 75,
        DROPDOWN_ITEM_HEIGHT: 90
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var HeaderComponent = function ($headerElement) {
        /**
         * PRIVATE METHODS
         */
        var $menuBtnElement,
            $modalSelectorList,
            $menuBtnIconElement,
            $modalSelector,
            $headerPrimaryElement,
            $primaryItemsElement,
            $itemsElement,
            $itemsIconsElement,
            $itemsLinksElement,
            $mobileNavItemForward,
            $mobileNavItemBack,
            $modalBtnOpen,
            $modalBtnClose,
            $modalWrapper,
            $modalMobileBtnOpen,
            $alertsContainer,
            $headerWrapper,
            $pageContent,
            $html,
            $body,
            $vaChat,
            $animationMenuSelector,
            $loggedInModalWrapper,
            $loggedInModalOpen,
            $loggedInModalClose,
            $loggedInModalItem,
            $logoutLinks,
            $navItemName,
            $navItemAddress,
            $navButtonAccountSelector,
            $dynamicLinks,
            $bottomLinks,
            $searchDescription,
            $languageSwitcherContainer,
            _hasLanguageSwitcher,
            _hasModalSelector,
            _currentPadding,
            _navBorderColor,
            _oldWidth,
            _hasAccountSelectorBtn,
            _isOpeningMainContent,
            _isClosingMainContent,
            index,
            eventTrap;

        /**
         * Animate mobile main nav's bottom items.
         * @param {HTMLElement} $item Element to add the animation class to.
         * @param {Number} animationTime Animation timeout delay.
         * @param {Boolean} isOpen Whether the menu is open or closed.
         */
        var animateMenuItems = function ($item, animationTime, isOpen) {
            if (isOpen) {
                setTimeout(function () {
                    $item.classList.remove(CONSTANTS.ANIMATION_MENU_CLASS);
                }, animationTime);
            } else {
                setTimeout(function () {
                    $item.classList.add(CONSTANTS.ANIMATION_MENU_CLASS);
                }, animationTime);
            }
        };

        /**
         * Reset any primary nav height that has been set dynamically
         */
        var resetPrimaryNavHeight = function () {
            $headerPrimaryElement.style.height = '';
        };

        /**
         * Close mobile main nav.
         */
        var closeMainContent = function () {
            var animationTime = 300,
                borderBottomWidth = '1px',
                borderBottomStyle = 'solid',
                borderBottomColor = '#636363';

            _isClosingMainContent = true;

            for (index = 0; index < $animationMenuSelector.length; index++) {
                animateMenuItems($animationMenuSelector[index], animationTime);
            }

            setTimeout(function () {
                $html.classList.remove(coned.constants.MOBILE_MODAL_OPEN);
                $body.classList.remove(coned.constants.MOBILE_MODAL_OPEN);
                $headerPrimaryElement.classList.remove(CONSTANTS.NAV_OPEN_CLASS);
                $menuBtnIconElement.classList.remove(CONSTANTS.HEADER_MENU_OPEN_ICON);
                $menuBtnIconElement.classList.add(CONSTANTS.HEADER_MENU_ICON);
                query.removeClass($primaryItemsElement, CONSTANTS.NAV_PRIMARY_ITEM_HOVER_CLASS);

                resetPrimaryNavHeight();

                if($vaChat) {
                    $vaChat.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }

                _isClosingMainContent = false;
            }, animationTime * 2);

            gsap.to($headerPrimaryElement, {
                duration: CONSTANTS.HEADER_BORDER_ANIMATION_TIME,
                borderBottom: borderBottomWidth + ' ' + borderBottomStyle + ' ' + borderBottomColor
            });

            // A11y Handler
            eventTrap &&
                $headerPrimaryElement.removeEventListener(
                    'keydown',
                    eventTrap.onFocusSelected,
                    false
                );
        };
        
        /**
         * Open mobile main nav.
         */
        var openMainContent = function () {
            var animationTime = 300,
                cascadeAnimation = 0,
                borderBottomWidth = '3px',
                borderBottomStyle = 'solid',
                headerAnimationTime = .05,
                viewportHeight;

            _isOpeningMainContent = true;

            $html.classList.add(coned.constants.MOBILE_MODAL_OPEN);
            $body.classList.add(coned.constants.MOBILE_MODAL_OPEN);

            // When adding the MOBILE_MODAL_OPEN class to $body, if the browser bottom bar is hidden on iOS, a resize is
            // triggered by the overflow hidden and the bar comes up, window.innerHeight seems not to be able to pick up the
            // new height updated as a consecuence of adding the class, unless using a timeout with at least 100 miliseconds.
            setTimeout(function () {
                viewportHeight = window.innerHeight - parseInt(borderBottomWidth);

                $headerPrimaryElement.classList.add(CONSTANTS.NAV_OPEN_CLASS);
                $menuBtnIconElement.classList.add(CONSTANTS.HEADER_MENU_OPEN_ICON);
                $menuBtnIconElement.classList.remove(CONSTANTS.HEADER_MENU_ICON);
    
                if ($vaChat) {
                    $vaChat.classList.add(CONSTANTS.HIDDEN_CLASS);
                }

                for (index = 0; index < $animationMenuSelector.length; index++) {
                    cascadeAnimation += animationTime;
                    animateMenuItems($animationMenuSelector[index], cascadeAnimation, true);
                }

                // Animating height with js instead of CSS as 100vh does not
                // work correctly when browser nav bars are visible on iOS.
                gsap.to($headerPrimaryElement, {
                    duration: headerAnimationTime,
                    ease: Sine.easeInOut,
                    height: viewportHeight
                });

                gsap.to($headerPrimaryElement, {
                    duration: CONSTANTS.HEADER_BORDER_ANIMATION_TIME,
                    borderBottom: borderBottomWidth + ' ' + borderBottomStyle + ' '+ _navBorderColor
                });

                //A11y Handler
                eventTrap = new coned.utils.addFocusTrap($headerPrimaryElement, true, false);
                
                _isOpeningMainContent = false;

                //Set the secondary nav to the bottom, wait for the modal to open in order to perform the calculations
                setTimeout(function () {
                    setSecondaryNav();
                }, 150);

            }, 100);

            
        };

        var menuBtnClickEvent = function (event) {
            event.preventDefault();

            // Prevent unexpected behaviors if activating buttons too fast.
            if (!_isOpeningMainContent && !_isClosingMainContent) {
                if (query.hasClass($headerPrimaryElement, CONSTANTS.NAV_OPEN_CLASS)) {
                    closeMainContent();
                } else {
                    openMainContent();
                }
            }
        };

        // A11y Handler
        var resetExpandedAria = function (elements) {
            for (index = 0; index < elements.length; index++) {
                elements[index].querySelector(CONSTANTS.NAV_ITEM_CLASS_SELECTOR) &&
                    elements[index]
                        .querySelector(CONSTANTS.NAV_ITEM_CLASS_SELECTOR)
                        .setAttribute(CONSTANTS.ARIA_EXPANDED, false);
            }
        };

        /// Primary Nav Click Functions
        var closeLinks = function () {
            query.removeClass($itemsIconsElement, CONSTANTS.NAV_ITEM_ICON_OPEN_CLASS);
            query.removeClass($itemsLinksElement, CONSTANTS.NAV_ITEM_LINKS_OPEN_CLASS);

            // A11y Handler
            resetExpandedAria($primaryItemsElement);
        };

        var closeItems = function (event) {
            if (query.selectParentElement(event.target, CONSTANTS.NAV_PRIMARY_ITEM_CLASS) !== null)
                return;

            if ($primaryItemsElement && $primaryItemsElement.length) {
                query.removeClass($primaryItemsElement, CONSTANTS.NAV_PRIMARY_ITEM_HOVER_CLASS);
                resetExpandedAria($primaryItemsElement);
            }

            if (_hasAccountSelectorBtn && isScreenLessThanMaxHeightDesktop()) {
                $vaChat.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var closeItemsByKeyboard = function (event) {
            var $parentElement = query.selectParentElement(
                event.target,
                CONSTANTS.NAV_PRIMARY_ITEM_CLASS
            );

            if ($primaryItemsElement && $primaryItemsElement.length) {
                query.removeClass($primaryItemsElement, CONSTANTS.NAV_PRIMARY_ITEM_HOVER_CLASS);
                resetExpandedAria($primaryItemsElement);
            }

            if (_hasAccountSelectorBtn && isScreenLessThanMaxHeightDesktop()) {
                showHideVaChat();
            } 

            closeLinks();
            $parentElement.querySelector(CONSTANTS.NAV_ITEM_CLASS_SELECTOR).focus();
        };

        var openLinks = function ($itemIconElement, $itemLinksElement, $itemElement) {
            closeLinks();

            query.addClass($itemIconElement, CONSTANTS.NAV_ITEM_ICON_OPEN_CLASS);
            query.addClass($itemLinksElement, CONSTANTS.NAV_ITEM_LINKS_OPEN_CLASS);
            $itemElement.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
        };

        var itemClickEvent = function (event) {
            event.preventDefault();

            var $itemElement = query.selectParentElement(event.target, CONSTANTS.NAV_ITEM_CLASS),
                $primaryItemElement = query.selectParentElement(
                    $itemElement,
                    CONSTANTS.NAV_PRIMARY_ITEM_CLASS
                ),
                $itemIconElement = $itemElement.getElementsByClassName(
                    CONSTANTS.NAV_ITEM_ICON_CLASS
                )[0],
                $itemLinksElement = $primaryItemElement.getElementsByClassName(
                    CONSTANTS.NAV_ITEM_LINKS_CLASS
                )[0];

            // A11y handler
            resetExpandedAria($primaryItemsElement);

            if (coned.utils.isMobile()) {
                if (query.hasClass($itemLinksElement, CONSTANTS.NAV_ITEM_LINKS_OPEN_CLASS)) {
                    closeLinks();
                } else {
                    openLinks($itemIconElement, $itemLinksElement, $itemElement);
                }
            } else {
                if (query.hasClass($primaryItemElement, CONSTANTS.NAV_PRIMARY_ITEM_HOVER_CLASS)) {
                    query.removeClass($primaryItemsElement, CONSTANTS.NAV_PRIMARY_ITEM_HOVER_CLASS);
                } else {
                    query.removeClass($primaryItemsElement, CONSTANTS.NAV_PRIMARY_ITEM_HOVER_CLASS);
                    query.addClass($primaryItemElement, CONSTANTS.NAV_PRIMARY_ITEM_HOVER_CLASS);
                    // A11y handler
                    $primaryItemElement
                        .querySelector(CONSTANTS.NAV_ITEM_CLASS_SELECTOR)
                        .setAttribute(CONSTANTS.ARIA_EXPANDED, true);
                }
            }
        };

        var mouseOutNavItem = function () {
            this.parentNode.classList.remove(CONSTANTS.NAV_PRIMARY_ITEM_HOVER_CLASS);
        };

        /**
         * Control modal selector state.
         */
        var controlModalState = function (event) {
            event.preventDefault();

            if ($modalWrapper.style.height != '0px' && $modalWrapper.style.height !== '') {
                closeModalBar();
            } else {
                openModalBar();
                event.target.focusBack = true;
            }
        };

        /**
         * Open modal selector.
         */
        var openModalBar = function () {
            var animationHeight;

            // In mobile if alerts are present, hide them to not affect current styling
            if (coned.utils.isMobile() && $alertsContainer !== undefined) {
                $alertsContainer.style.display = 'none';
            }

            // Text active state
            $modalBtnOpen.classList.add(CONSTANTS.MODAL_ACTIVE_CLASS);
            $modalWrapper.classList.add(CONSTANTS.MODAL_SELECTOR_WRAPPER_ACTIVE);
            $modalWrapper.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);

            if (coned.utils.isDesktop()) {
                animationHeight = {
                    duration: 0.3,
                    height: '125px'
                };
            } else {
                animationHeight = {
                    duration: 0.3,
                    height: $headerPrimaryElement.clientHeight - 3,
                    borderBottom: '3px solid ' + _navBorderColor
                };
            }

            if (coned.utils.isMobile()) {
                $body.classList.add(coned.constants.MOBILE_MODAL_OPEN);
            }

            gsap.to($modalWrapper,
                animationHeight
            );

            // A11y handlers
            $modalBtnOpen.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            setTimeout(function () {
                $modalSelectorList.children[0].querySelector(CONSTANTS.ANCHOR_TAG_SELECTOR).focus();
            }, 500);
        };

        /**
         * Close modal selector.
         */
        var closeModalBar = function (event) {
            if (event !== undefined) {
                event.preventDefault();
                $modalBtnOpen.focus();
            }

            // Text active state
            query.removeClass($modalBtnOpen, CONSTANTS.MODAL_ACTIVE_CLASS);
            query.removeClass($modalWrapper, CONSTANTS.MODAL_SELECTOR_WRAPPER_ACTIVE);
            query.addClass($modalWrapper.parentElement, CONSTANTS.HIDDEN_CLASS);

            gsap.to($modalWrapper, {
                duration: 0.3,
                height: '0',
                borderBottom: '0px',
                onComplete: showAlerts
            });

            // A11y handlers
            $modalBtnOpen.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
            setTimeout(function () {
                if ($modalBtnOpen && $modalBtnOpen.focusBack) {
                    $modalBtnOpen.focus();
                    $modalBtnOpen.focusBack = false;
                } else if ($modalMobileBtnOpen && $modalMobileBtnOpen.focusBack) {
                    $modalMobileBtnOpen.focus();
                    $modalMobileBtnOpen.focusBack = false;
                }
            }, 500);
        };

        var showAlerts = function () {
            // In mobile if alerts are present, show them again
            if (coned.utils.isMobile() && $alertsContainer !== undefined) {
                $alertsContainer.style.display = 'block';
            }
        };

        /**
         * Close mobile logged in account selector modal.
         */
        var closeModalLoggedIn = function () {
            var animationTime = 300,
                $item;

            for (index = 0; index < $loggedInModalItem.length; index++) {
                $item = $loggedInModalItem[index];
                gsap.to($item, {
                    duration: 0.3,
                    top: '25px',
                    opacity: '0'
                });
            }

            for (index = 0; index < $animationMenuSelector.length; index++) {
                animateMenuItems($animationMenuSelector[index], animationTime);
            }

            setTimeout(function () {
                $body.classList.remove(coned.constants.MOBILE_MODAL_OPEN);
                $loggedInModalWrapper.classList.remove(CONSTANTS.LOGGED_IN_MODAL_OPEN_CLASS);

                if ($vaChat) {
                    $vaChat.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            }, animationTime);

            gsap.to($loggedInModalWrapper, {
                duration: 0.3,
                borderBottom: '0'
            });

            // A11y Handler
            setTimeout(function () {
                $loggedInModalOpen.focus();
            }, 500);
        };

        /**
         * Open mobile logged in account selector modal.
         */
        var openModalLoggedIn = function () {
            var animationTime = 300,
                cascadeAnimation = 0,
                $item;

            $body.classList.add(coned.constants.MOBILE_MODAL_OPEN);
            $loggedInModalWrapper.classList.add(CONSTANTS.LOGGED_IN_MODAL_OPEN_CLASS);

            if ($vaChat) {
                $vaChat.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            gsap.to($loggedInModalWrapper, {
                duration: 0.3,
                borderBottom: '3px solid ' + _navBorderColor
            });

            for (index = 0; index < $animationMenuSelector.length; index++) {
                cascadeAnimation += animationTime;
                animateMenuItems($animationMenuSelector[index], cascadeAnimation, true);
            }

            for (index = 0; index < $loggedInModalItem.length; index++) {
                $item = $loggedInModalItem[index];

                gsap.to($item, {
                    duration: 0.6,
                    top: '0',
                    opacity: '1'
                });
            }

            // A11y Handler
            setTimeout(function () {
                $loggedInModalClose.focus();
            }, 500);
        };

        var menuBtnLoggedInClickEvent = function (event) {
            event.preventDefault();

            if (query.hasClass($loggedInModalWrapper, CONSTANTS.LOGGED_IN_MODAL_OPEN_CLASS)) {
                closeModalLoggedIn();
            } else {
                openModalLoggedIn();
            }
        };

        var logoutEvent = function (event) {
            event.preventDefault();

            coned.utils.logout(this.dataset.logoutUrl, this.href);
        };

        var getDynamicLinks = function () {
            var serviceUrl = $headerElement.dataset.dynamicLinksServiceUrl;

            query.getData(serviceUrl, successGetDynamicLinks, function () { });
        };

        var successGetDynamicLinks = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_HEADER_DYNAMIC_LINKS,
                    setDynamicLinks,
                    function () { }
                );
            } else {
                setDynamicLinks(data);
            }
        };

        var setDynamicLinks = function (data) {
            var $dynamicLink, dynamicLinkType;

            for (index = 0; index < $dynamicLinks.length; index++) {
                $dynamicLink = $dynamicLinks[index];
                dynamicLinkType = $dynamicLink.dataset.linkType;

                if (data[dynamicLinkType] && data[dynamicLinkType] != '') {
                    $dynamicLink.href = data[dynamicLinkType];
                }
            }
        };

        /**
         * Set max height for account selector dropdown container based on the space available.
         */
        var setAccountSelectorElementMaxHeight = function () {
            var headerHeight = parseFloat(window
                    .getComputedStyle($headerWrapper, null)
                    .getPropertyValue('height')
                ),
                $navButtonAccountSelectorParent = $navButtonAccountSelector.parentElement,
                $accountSelectorElement = $navButtonAccountSelectorParent.getElementsByClassName(
                    CONSTANTS.NAV_ITEM_PRIMARY_ACCOUNT_SELECTOR
                )[0],
                viewportHeight = window.innerHeight;

            $accountSelectorElement.style.maxHeight = (viewportHeight - headerHeight - 10) + 'px';
        };

        /**
         * Reset page padding to avoid overlapping with header when alerts are present.
         */
        var resetPagePadding = function () {
            var headerHeight = window
                .getComputedStyle($headerWrapper, null)
                .getPropertyValue('height');

            // Don't apply padding change if not needed
            if (headerHeight != _currentPadding) {
                gsap.to($pageContent, {
                    duration: 0.2,
                    paddingTop: headerHeight
                });

                _currentPadding = headerHeight;
            }
        };

        /**
         * Set modal role for mobile or desktop accordingly.
         * @param {Boolean} isMobile True if mobile, false if desktop.
         */
        var setModalRole = function (isMobile) {
            if (isMobile) {
                $modalSelector.setAttribute(CONSTANTS.ROLE, CONSTANTS.DIALOG);
                $modalSelector.setAttribute(CONSTANTS.ARIA_MODAL, true);
            } else {
                $modalSelector.removeAttribute(CONSTANTS.ROLE);
                $modalSelector.removeAttribute(CONSTANTS.ARIA_MODAL);
            }
        };

        /**
         * Handle window resize events for the header.
         * Resets dynamic heights and closes open modal navs to prevent issues and 
         * wrong header height calculation updates.
         */
        var headerResizing = function () {
            var newWidth = window.innerWidth,
                hasPrimaryNavDynamicHeightSet = $headerPrimaryElement.style.height !== '';

            if (_oldWidth === newWidth) {
                return;
            }

            if (_hasModalSelector) {
                setModalRole(coned.utils.isMobile());
            }
            // Close anything that may be opened to avoid display issues
            closeMainContent();
            _oldWidth = newWidth;

            if (_hasModalSelector) {
                closeModalBar();
            }

            if ($loggedInModalOpen !== undefined) {
                closeModalLoggedIn();
            }

            // Although closeMainContent() is called before and it resets the height, the header height 
            // will not be reset on time because it is done after a transition. So we need to reset it 
            // manually for resetPagePadding() to calculate the correct padding.
            if (hasPrimaryNavDynamicHeightSet) {
                resetPrimaryNavHeight();
            }

            if ($alertsContainer !== undefined) {
                resetPagePadding();
            }
        };

        /**
         * Handle keyboard events on menu button.
         * @param {Event} event
         */
        var handleKeyDownOnMenuButton = function (event) {
            var keyCode = event.keyCode;
            var shiftKey = event.shiftKey;

            // Close on shift + tab
            if (shiftKey && keyCode === coned.constants.KEY_CODE.TAB) {
                closeItemsByKeyboard(event);
            }
        };

        /**
         * Handle keyboard key up events on menu button.
         * @param {Event} event
         */
        var handleKeyUpOnMenuButton = function (event) {
            var keyCode = event.keyCode,
                spaceKeyCode = coned.constants.KEY_CODE.SPACE,
                enterKeyCode = coned.constants.KEY_CODE.ENTER,
                escapeKeyCode = coned.constants.KEY_CODE.ESC;

            if (_hasAccountSelectorBtn && isScreenLessThanMaxHeightDesktop()) {
                if (
                    keyCode === enterKeyCode ||
                    keyCode === escapeKeyCode
                ) {
                    showHideVaChat();
                }

                if (keyCode === spaceKeyCode) {
                    showHideVaChat(true);
                }
            }
        };

        /**
         * Close submenu using tab on the last menu option
         * @param {Event} event
         */
        var handleTabKeyOnLastMenuOption = function (event) {
            var keyCode = event.keyCode;
            var isShiftPressed = event.shiftKey;

            if (!isShiftPressed && keyCode === coned.constants.KEY_CODE.TAB) {
                closeItemsByKeyboard(event);
            }
        };

        /**
         * Calculate screen max height needed to show/hide the va chat on desktop, based on account selector dropdown height.
         * To be used for account selector dropdown container element.
         * a11y helper
         * @return {Number} Returns the max height needed to hide the va chat on desktop.
         */
        var getMaxScreenHeight = function () {
            var $navButtonAccountSelectorParent = $navButtonAccountSelector.parentElement,
                $accountDropDownContainer = $navButtonAccountSelectorParent.getElementsByClassName(
                    CONSTANTS.NAV_ITEM_DROPDOWN_CONTAINER
                )[0],
                $accountDropDownContainerChildren = $accountDropDownContainer && $accountDropDownContainer.getElementsByClassName(
                    CONSTANTS.NAV_ITEM_DROPDOWN_ITEM
                ),
                accountDropDownContainerChildrenQuantity = $accountDropDownContainerChildren && $accountDropDownContainerChildren.length,
                maxScreenHeight;

                if (
                    accountDropDownContainerChildrenQuantity === 0 ||
                    accountDropDownContainerChildrenQuantity === 1 ||
                    accountDropDownContainerChildrenQuantity === undefined
                ) {
                    maxScreenHeight = CONSTANTS.SCREEN_MAX_HEIGHT_200_ZOOM - (CONSTANTS.DROPDOWN_ITEM_HEIGHT * 2);
                } else if (accountDropDownContainerChildrenQuantity === 2) {
                    maxScreenHeight = CONSTANTS.SCREEN_MAX_HEIGHT_200_ZOOM - CONSTANTS.DROPDOWN_ITEM_HEIGHT;
                } else if (accountDropDownContainerChildrenQuantity >= 3) {
                    maxScreenHeight = CONSTANTS.SCREEN_MAX_HEIGHT_200_ZOOM;
                }

                if (
                    $alertsContainer !== undefined &&
                    $alertsContainer.offsetHeight > 0
                ) {
                    maxScreenHeight = maxScreenHeight + CONSTANTS.SCREEN_MAX_HEIGHT_WITH_ALERT_200_ZOOM;
                }

                return maxScreenHeight;
        };

        /**
         * Check if screen max height is met to show/hide the va chat for desktop.
         * To be used for account selector dropdown container element.
         * Also checks if va chat exists.
         * a11y helper
         * @return {Boolean} True if screen height is less than max height for desktop.
         */
        var isScreenLessThanMaxHeightDesktop = function () {
            var maxScreenHeight = getMaxScreenHeight();

            if (
                $vaChat && 
                window.innerWidth >= coned.constants.TABLET_MIN_SIZE && 
                window.innerHeight <= maxScreenHeight
            ) {
                return true;
            }
        };

        /**
         * Handles va chat show/hide functionality
         * If there is any nav dropdown displayed hide the va chat, else show it
         * a11y helper
         * @param {Boolean} invertShowHide True to invert show hide condition.
         */
        var showHideVaChat = function (invertShowHide) {
            _.each($itemsElement, function ($itemElement) {
                var $itemsLinkElement = $itemElement.parentElement.getElementsByClassName(CONSTANTS.NAV_ITEM_LINKS_CLASS)[0];

                if (invertShowHide) {
                    if (!query.checkElementOrParentDisplayNone($itemsLinkElement)) {
                        $vaChat.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    } else {
                        $vaChat.classList.add(CONSTANTS.HIDDEN_CLASS);
                    }
                } else {
                    if (query.checkElementOrParentDisplayNone($itemsLinkElement)) {
                        $vaChat.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    } else {
                        $vaChat.classList.add(CONSTANTS.HIDDEN_CLASS);
                    }
                }
            })
        };


        /**
         * Updating aria-expanded state according by hover state
         * Hide va chat if needed
         * @param {Event} event
         */
        var handleHoverStateNavItems = function (event) {
            var $currentButton = event.currentTarget,
                $navButtonAccountSelectorParent = _hasAccountSelectorBtn && $navButtonAccountSelector.parentElement;

            if (event.type == CONSTANTS.EVENT_TYPE_MOUSEOENTER) {
                //Focus button that contain aria-expanded attribute
                event.target.children[0].setAttribute(CONSTANTS.ARIA_EXPANDED, true);

                if (
                    _hasAccountSelectorBtn && 
                    isScreenLessThanMaxHeightDesktop() && 
                    $currentButton === $navButtonAccountSelectorParent
                ) {
                    showHideVaChat();
                }
            } else if (event.type == CONSTANTS.EVENT_TYPE_MOUSELEAVE) {
                //Focus button that contain aria-expanded attribute
                event.target.children[0].setAttribute(CONSTANTS.ARIA_EXPANDED, false);

                if (
                    _hasAccountSelectorBtn &&  
                    isScreenLessThanMaxHeightDesktop() && 
                    $currentButton === $navButtonAccountSelectorParent
                ) {
                    showHideVaChat();
                }
            }
        };

        /**
         * Mobile nav drawer show secondary nav
         */
        var showMobileSecondaryNav = function(event) {
            var secondaryNavId = event.currentTarget.dataset.secondaryNav;

            $headerElement.getElementsByClassName(CONSTANTS.NAV_MOBILE_DRAWER_PRIMARY_WRAPPER)[0].classList.add(CONSTANTS.NAV_MOBILE_DRAWER_PRIMARY_HIDE);
            var $secondaryNavContent = document.querySelector("[data-secondary-nav-id='"+secondaryNavId+"']");
            $secondaryNavContent.classList.add(CONSTANTS.NAV_MOBILE_DRAWER_SECONDARY_SHOW);
        }


        /**
         * Mobile nav drawer hide secondary nav
         */
         var hideMobileSecondaryNav = function(event) {
            var secondaryNavId = event.currentTarget.dataset.primaryNav;

            var $secondaryNavWrappers = $headerElement.getElementsByClassName(CONSTANTS.NAV_MOBILE_DRAWER_SECONDARY_WRAPPER);

            for(index=0; index < $secondaryNavWrappers.length; index++) {
                if($secondaryNavWrappers[index].dataset.secondaryNavId === secondaryNavId) {
                    $secondaryNavWrappers[index].classList.remove(CONSTANTS.NAV_MOBILE_DRAWER_SECONDARY_SHOW);
                    break;
                }
            }
            
            $headerElement.getElementsByClassName(CONSTANTS.NAV_MOBILE_DRAWER_PRIMARY_WRAPPER)[0].classList.remove(CONSTANTS.NAV_MOBILE_DRAWER_PRIMARY_HIDE);
            
        }

        /**
         * Set the secondary nav to the bottom of the device to match the design
         */
        var setSecondaryNav = function () {
            //Calculate the height of the primary nav and declare some necessary varibles
            var primaryNavHeight = $mobileNavItemForward.length * $mobileNavItemForward[0].offsetHeight,
                $secondaryNav = document.getElementsByClassName(CONSTANTS.NAV_MOBILE_SECONDARY)[0],
                secondaryNavHeight,
                secondaryPadding;

            //Reset padding for secondary nav to have the right calculations
            $secondaryNav.style.paddingTop = CONSTANTS.NAV_MOBILE_SECONDARY_INIT_PADDING + 'px';
            
            //Calculate secondary nav height
            secondaryNavHeight = $secondaryNav.offsetHeight;
            
            //Calculate the space between the primary and secondary nav to align the secondary nav on the bottom
            secondaryPadding = window.innerHeight - primaryNavHeight - secondaryNavHeight - CONSTANTS.NAV_MOBILE_BOTTOM_PADDING;
            
            //Set the padding for the mobile secondary nav to match the design and appear in the bottom
            if(secondaryPadding > 0) {
                $secondaryNav.style.paddingTop = secondaryPadding + 'px';
            }      
        }

        var initializeData = function () {
            $menuBtnElement = $headerElement.getElementsByClassName(CONSTANTS.HEADER_MENU_CLASS)[0];
            $menuBtnIconElement = $menuBtnElement.getElementsByClassName(
                CONSTANTS.HEADER_MENU_ICON_CLASS
            )[0];
            $headerPrimaryElement = $headerElement.getElementsByClassName(CONSTANTS.NAV_CLASS)[0];
            $primaryItemsElement = $headerElement.getElementsByClassName(
                CONSTANTS.NAV_PRIMARY_ITEM_CLASS
            );
            $itemsElement = $headerElement.getElementsByClassName(CONSTANTS.NAV_ITEM_CLASS);
            $itemsIconsElement = $headerElement.getElementsByClassName(
                CONSTANTS.NAV_ITEM_ICON_CLASS
            );
            $itemsLinksElement = $headerElement.getElementsByClassName(
                CONSTANTS.NAV_ITEM_LINKS_CLASS
            );
            $mobileNavItemForward = $headerElement.getElementsByClassName(
                CONSTANTS.NAV_MOBILE_PRIMARY_ITEM_FORWARD
            );
            $mobileNavItemBack = $headerElement.getElementsByClassName(
                CONSTANTS.NAV_MOBILE_PRIMARY_ITEM_BACK
            ); 
            $loggedInModalWrapper = $headerElement.getElementsByClassName(
                CONSTANTS.LOGGED_IN_MODAL_CLASS
            )[0];
            $loggedInModalOpen = $headerElement.getElementsByClassName(
                CONSTANTS.OPEN_LOGGED_IN_MODAL
            )[0];
            $loggedInModalClose = $headerElement.getElementsByClassName(
                CONSTANTS.CLOSE_LOGGED_IN_MODAL
            )[0];
            $loggedInModalItem = $headerElement.getElementsByClassName(
                CONSTANTS.LOGGED_IN_MODAL_ITEM_CLASS
            );
            $animationMenuSelector = $headerElement.getElementsByClassName(
                CONSTANTS.ANIMATION_MENU_SELECTOR
            );
            $logoutLinks = document.getElementsByClassName(CONSTANTS.LOGOUT_LINK_CLASS);
            $bottomLinks = document.getElementsByClassName(CONSTANTS.BOTTOM_LINKS)[0];
            $headerWrapper = document.getElementsByClassName(
                coned.constants.HEADER_WRAPPER_CLASS
            )[0];
            $pageContent = document.getElementsByClassName(coned.constants.PAGE_CONTENT_CLASS)[0];
            $body = document.getElementsByClassName(coned.constants.BODY_CLASS)[0];
            $html = document.getElementsByClassName(coned.constants.HTML_CLASS)[0];
            $vaChat = document.getElementsByClassName(CONSTANTS.VIRTUAL_ASSISTANT_CLASS)[0];
            $alertsContainer = document.getElementsByClassName(CONSTANTS.ALERTS_CONTAINER_CLASS)[0];
            $dynamicLinks = document.getElementsByClassName(CONSTANTS.DYNAMIC_LINK_CLASS);
            $modalSelector = $headerElement.getElementsByClassName(CONSTANTS.MODAL_SELECTOR)[0];
            $navItemName = $headerElement.getElementsByClassName(CONSTANTS.NAV_ITEM_NAME)[0];
            $navItemAddress = $headerElement.getElementsByClassName(CONSTANTS.NAV_ITEM_ADDRESS)[0];
            $navButtonAccountSelector = $headerElement.getElementsByClassName(CONSTANTS.NAV_BUTTON_ACCOUNT_SELECTOR)[0];
            $searchDescription = $headerElement.getElementsByClassName(
                CONSTANTS.SEARCH_DESCRIPTION
            );
            $languageSwitcherContainer = document.getElementsByClassName(CONSTANTS.LANGUAGE_SWITCHER_CONTAINER_SELECTOR);
            _hasAccountSelectorBtn = $navButtonAccountSelector;
            _currentPadding = 0;
            _navBorderColor = query.hasClass($headerElement, CONSTANTS.ORU_NAV_CLASS)
                ? CONSTANTS.ORANGE_COLOR
                : CONSTANTS.BLUE_COLOR;
            _isOpeningMainContent = false;
            _isClosingMainContent = false;

            // For translation we can only have one module at a time, either the 
            // language switcher (weglot) or the modal selector (google translate)
            _hasLanguageSwitcher = $languageSwitcherContainer;
            _hasModalSelector = $modalSelector;

            if (_hasModalSelector) {
                $modalBtnOpen = $headerElement.getElementsByClassName(CONSTANTS.NAV_OPEN_MODAL)[0];
                $modalMobileBtnOpen = $headerElement.getElementsByClassName(
                    CONSTANTS.NAV_MOBILE_OPEN_MODAL
                )[0];
                $modalBtnClose = $headerElement.getElementsByClassName(CONSTANTS.NAV_CLOSE_MODAL)[0];
                $modalWrapper = $headerElement.getElementsByClassName(
                    coned.constants.MODAL_SELECTOR_CLASS
                )[0];
                $modalSelectorList = $headerElement.getElementsByClassName(
                    CONSTANTS.MODAL_SELECTOR_LIST
                )[0];
            }
        };

        var initializeEvents = function () {
            // Menu Click Event
            coned.utils.addGeneralListeners($menuBtnElement, menuBtnClickEvent);
            // Language Select Event
            if(_hasLanguageSwitcher) {
                $headerElement.addEventListener(CONSTANTS.LANGUAGE_SWITCHER_SELECT_LANGUAGE_EVENT, closeMainContent);
            }
            

            // Nav Items Click Event
            for (index = 0; index < $itemsElement.length; index++) {
                coned.utils.addGeneralListeners($itemsElement[index], itemClickEvent);
                $itemsElement[index].addEventListener('mouseleave', mouseOutNavItem);
                $itemsElement[index].addEventListener('keydown', handleKeyDownOnMenuButton);
                //Target parent element for dropdown items have hover state
                $itemsElement[index].parentElement.addEventListener(
                    'mouseenter',
                    handleHoverStateNavItems
                );
                $itemsElement[index].parentElement.addEventListener(
                    'mouseleave',
                    handleHoverStateNavItems
                );
            }

            if (_hasAccountSelectorBtn) {
                $navButtonAccountSelector.addEventListener('keyup', handleKeyUpOnMenuButton);
            }

            // Menu should close on tab if tabbing out of them
            for (var indexMenu = 0; indexMenu < $itemsLinksElement.length; indexMenu++) {
                var lastMenuOption = $itemsLinksElement[indexMenu].lastElementChild;

                if (lastMenuOption && lastMenuOption.classList.contains(CONSTANTS.NAV_ITEM_BOTTOM_SECTION) && $bottomLinks) {
                    lastMenuOption = $bottomLinks.lastElementChild;
                } 

                lastMenuOption &&
                lastMenuOption.addEventListener('keydown', handleTabKeyOnLastMenuOption);
            }

            // Adding event for click outside of primary item
            coned.utils.addGeneralListeners(window, closeItems);

            // Logged In Modal Click Events
            if ($loggedInModalOpen !== undefined) {
                coned.utils.addGeneralListeners($loggedInModalOpen, menuBtnLoggedInClickEvent);
                coned.utils.addGeneralListeners($loggedInModalClose, menuBtnLoggedInClickEvent);
            }

            if (_hasModalSelector) {
                // Modal Bar Click Events
                if (
                    $modalBtnOpen !== undefined &&
                    $modalMobileBtnOpen !== undefined &&
                    $modalBtnClose !== undefined
                ) {
                    coned.utils.addGeneralListeners($modalBtnOpen, controlModalState);
                    coned.utils.addGeneralListeners($modalMobileBtnOpen, controlModalState);
                    coned.utils.addGeneralListeners($modalBtnClose, closeModalBar);
                }
            }

            // Manage possible page content padding addition due to the alerts added to the header
            if ($alertsContainer !== undefined) {
                resetPagePadding();

                if (_hasAccountSelectorBtn) {
                    setAccountSelectorElementMaxHeight();
                }
            }

            // Logout links event setup
            for (index = 0; index < $logoutLinks.length; index++) {
                coned.utils.addGeneralListeners($logoutLinks[index], logoutEvent);
            }

            // Dynamic links setup
            if ($dynamicLinks && $dynamicLinks.length) {
                getDynamicLinks();
            }

            // resize Listener
            window.addEventListener('resize', headerResizing);
            if ($alertsContainer !== undefined && _hasAccountSelectorBtn) {
                window.addEventListener('resize', setAccountSelectorElementMaxHeight);
                document.addEventListener(CONSTANTS.ALERT_CLOSED_EVENT, setAccountSelectorElementMaxHeight);
            }
            _oldWidth = window.innerWidth;

            // A11y handlers
            if (_hasModalSelector) {
                setModalRole();
            }

            for (index = 0; index < $primaryItemsElement.length; index++) {
                new coned.utils.addKeyEvent(
                    $primaryItemsElement[index],
                    coned.constants.KEY_CODE.ESC,
                    closeItemsByKeyboard,
                    false,
                    true
                );
            }

            // Voice Over implementation on Safari for aria-describedby works different than other browsers,
            // so we completely hide the element to avoid it being read twice.
            if ($searchDescription.length > 0 && coned.utils.isSafari()) {
                query.replaceClass($searchDescription, CONSTANTS.HIDDEN_CLASS, CONSTANTS.VISUALLY_HIDDEN_CLASS);
            }

            for (index = 0; index < $mobileNavItemForward.length; index++) {
                coned.utils.addGeneralListeners($mobileNavItemForward[index], showMobileSecondaryNav);
            }

            for (index = 0; index < $mobileNavItemBack.length; index++) {
                coned.utils.addGeneralListeners($mobileNavItemBack[index], hideMobileSecondaryNav);
            }

            new coned.utils.addKeyEvent(
                $headerElement,
                coned.constants.KEY_CODE.ESC,
                closeMainContent,
                true,
                false
            );

            if (_hasModalSelector) {
                $modalWrapper && new coned.utils.addFocusTrap(
                    $modalWrapper, true, false
                );

                $modalWrapper &&
                    new coned.utils.addKeyEvent(
                        $modalWrapper,
                        coned.constants.KEY_CODE.ESC,
                        closeModalBar
                    );
            }

            $loggedInModalWrapper &&
                new coned.utils.addKeyEvent(
                    $loggedInModalWrapper,
                    coned.constants.KEY_CODE.ESC,
                    closeModalLoggedIn
                );

            //Add clamp to the name and address on Global Nav
            if($navItemName) { 
                coned.utils.lineClamp($navItemName);
            }
            
            if($navItemAddress) { 
                coned.utils.lineClamp($navItemAddress);
            }

        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    HeaderComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return HeaderComponent;
})();
