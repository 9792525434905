// ==================== VIEW CURRENT BILL COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ViewCurrentBill = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        BILL_LINK_CLASS: 'js-bill-link',
        FORM_LOADING: 'js-form-loading',
        DIV_LOADING_CLASS: 'js-div-loading',
        SERVICE_ERROR: 'js-service-error-view-bill',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        CTA_CONTAINER_SELECTOR: 'js-overview-bill-card__cta-container',
        CTA_CONTAINER_THREE_ELEMENTS: 'overview-bill-card__cta-container--three-elements',
        CTA_SELECTOR: 'js-overview-bill-card__cta',
        OVERVIEW_BILL_CARD_SELECTOR: 'js-overview-bill-card-container',
        SUMMARY_BILLING_CARD_SELECTOR: 'js-summary-billing-card',
        EXTRA_MOBILE_PADDING_BOTTOM: 'overview-bill-card--padding-bottom-mobile-error'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var ViewCurrentBill = function ($viewBill) {
        /**
         * PRIVATE METHODS
         */
        var $billLinks,
            $currentBillLink,
            $serviceError,
            $formLoading,
            $newTab,
            $ctaContainer,
            $billCard,
            _ctaLength;

        var billLinkEvent = function (event) {
            event.preventDefault();

            var serviceUrl,
                params,
                isCsv;

            // Set elements depending on the item clicked
            $currentBillLink = query.selectParentElement(event.target, CONSTANTS.BILL_LINK_CLASS);
            $serviceError = $billCard.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            isCsv = $currentBillLink.dataset.iscsv;
            serviceUrl = isCsv ? $viewBill.dataset.documentCsvServiceUrl : $viewBill.dataset.billServiceUrl

            // If bill link is available, open the link in a new tab
            if ($currentBillLink.dataset.billLink == 'true') {
                window.open($currentBillLink.href);

                return;
            }

            // Service data
            params = {
                Maid: $viewBill.dataset.accountMaid,
                MasterMaid: $viewBill.dataset.masterMaid,
                ScId: $viewBill.dataset.scid
            };

            if (isCsv){
                params.BillId = $viewBill.dataset.billId;
            } else {
                params.BillDate = $viewBill.dataset.billDate;
                params.Type = $currentBillLink.dataset.type;
            }

            // Before doing the service call, lets create the new tab where the link will be opened, so it won't be blocked by browser security
            $newTab = window.open();

            // Service call
            query.getData(serviceUrl, successBillService, errorBillService, params, $formLoading);
            
            //Check if it's csv and safari cause it didn't support correctly the .close function
            if (isCsv && !coned.utils.isSafari()) {
                setTimeout(function () {
                    $newTab.close();
                }, 5000);
            }
        };

        var successBillService = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.GET_BILL_LINK, redirectPage, errorBillService);
            } else if (data.Link && data.Link != '') {
                redirectPage(data);
            } else {
                errorBillService(data);
            }
        };

        var redirectPage = function (data) {
            // Set link and flag for future click
            $currentBillLink.dataset.billLink = 'true';
            $currentBillLink.href = data.Link;

            // Set file location in the opened tab
            $newTab.location.href = data.Link;

            // verify if element contains modifier to remove it and hide error message
            if ($billCard.classList.contains(CONSTANTS.EXTRA_MOBILE_PADDING_BOTTOM)) {
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
                $billCard.classList.remove(CONSTANTS.EXTRA_MOBILE_PADDING_BOTTOM);
            }
        };

        var errorBillService = function (data) {
            var $serviceErrorMessage = $serviceError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];

            // Close new tab opened
            $newTab.close();

            // Display error message
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE_VIEW_BILL;

            // Add modifier to increase padding bottom to container
            if(!$billCard.classList.contains(CONSTANTS.SUMMARY_BILLING_CARD_SELECTOR)) {
                $billCard.classList.add(CONSTANTS.EXTRA_MOBILE_PADDING_BOTTOM);
            }
            

            $serviceError.focus();
        };

        var initializeData = function () {
            $billLinks = $viewBill.getElementsByClassName(CONSTANTS.BILL_LINK_CLASS);
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $billCard = document.getElementsByClassName(CONSTANTS.OVERVIEW_BILL_CARD_SELECTOR)[0] ? 
                        document.getElementsByClassName(CONSTANTS.OVERVIEW_BILL_CARD_SELECTOR)[0] : 
                        document.getElementsByClassName(CONSTANTS.SUMMARY_BILLING_CARD_SELECTOR)[0];
            $ctaContainer = $viewBill.getElementsByClassName(CONSTANTS.CTA_CONTAINER_SELECTOR)[0];
            var ctas = $viewBill.getElementsByClassName(CONSTANTS.CTA_SELECTOR);
            _ctaLength = ctas.length;
        };

        var initializeEvents = function () {
            // Add listener to the clicks
            _.each($billLinks, function ($billLink) {
                coned.utils.addGeneralListeners($billLink, billLinkEvent);
            });
            if (_ctaLength > 2) {
                $ctaContainer.classList.add(CONSTANTS.CTA_CONTAINER_THREE_ELEMENTS);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ViewCurrentBill.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ViewCurrentBill;
})();
