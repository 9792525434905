// ==================== SEARCH COMPONENT =========================
/* global gsap */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SearchBoxComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SEARCH_WRAPPER: 'search-box',
        PRIMARY_NAV_ITEM: 'js-primary-nav-item',
        FORM_WRAPPER: 'search-box__form-wrapper',
        LIST_WRAPPER: 'search-box__suggested-wrapper',
        SEARCH_BOX_ACTIVE_CLASS: 'search-box--active',
        SEARCH_BOX_INPUT: 'js-search-box-input',
        CLOSE_BUTTON: 'js-close-search',
        OPEN_NAV_BUTTON: 'js-nav-search',
        OPEN_ACTION_BUTTON: 'js-action-search',
        OPEN_SECONDARY_BUTTON: 'js-sec-search',
        NAV_CLOSE_LANGUAGE: 'js-language-close',
        HEADER_PRIMARY_MENU_OPEN: 'header__primary--open',
        ORU_NAV_CLASS: 'header--oru',
        ORANGE_COLOR: '#e77613',
        BLUE_COLOR: '#069bd7',
        HOVER_CLASS: 'hover',
        VIRTUAL_ASSISTANT_CLASS: 'va-chat',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var SearchBoxComponent = function ($searchBox) {
        /**
         * PRIVATE METHODS
         */
        var $closeBtnElement,
            $openNavBtnElement,
            $openActionBtnElement,
            $openSecondaryBtnElement,
            $languageBtnClose,
            $body,
            $vaChat,
            $itemList,
            $listWrapper,
            $searchBoxInput,
            $primaryNavItems,
            _navBorderColor;

        var closeSearchWrapper = function (event) {
            event.preventDefault();

            query.removeClass($searchBox, CONSTANTS.SEARCH_BOX_ACTIVE_CLASS);
            query.removeClass($body, coned.constants.MODAL_OPEN);
            query.removeClass($vaChat, CONSTANTS.HIDDEN_CLASS);

            gsap.to([$itemList, $closeBtnElement, $listWrapper], {
                duration: 0.3,
                opacity: 0,
                y: 0,
                onComplete: hideElements
            });

            $searchBoxInput.value = '';

            if (
                document.activeElement != document.body &&
                document.activeElement.classList.contains(CONSTANTS.SEARCH_BOX_INPUT)
            ) {
                document.activeElement.blur();
            }

            setTimeout(function () {
                if ($openSecondaryBtnElement && $openSecondaryBtnElement.focusBack) {
                    $openSecondaryBtnElement.focus();
                    $openSecondaryBtnElement.focusBack = false;
                } else if ($openNavBtnElement && $openNavBtnElement.focusBack) {
                    $openNavBtnElement.focus();
                    $openNavBtnElement.focusBack = false;
                } else if ($openNavBtnElement && $openActionBtnElement.focusBack) {
                    $openActionBtnElement.focus();
                    $openActionBtnElement.focusBack = false;
                }
            }, 500);
        };

        var openSearchWrapper = function (event) {

            event.target.focusBack = true;
            event.preventDefault();

            query.addClass($searchBox, CONSTANTS.SEARCH_BOX_ACTIVE_CLASS);
            query.addClass($body, coned.constants.MODAL_OPEN);
            query.addClass($vaChat, CONSTANTS.HIDDEN_CLASS);

            // to prevent display issues, close the language bar if it exists
            if ($languageBtnClose != undefined) {
                $languageBtnClose.click();
            }

            // set modal height based on the page height and the border to be added
            var modalHeight = window.innerHeight - 3; //the border height

            gsap.to($searchBox, {
                duration: 0.3,
                borderBottom: '3px solid ' + _navBorderColor,
                height: modalHeight,
                onComplete: showElements
            });

            // Nav Items Click Event
            for (var index = 0; index < $primaryNavItems.length; index++) {
                query.removeClass($primaryNavItems[index], CONSTANTS.HOVER_CLASS);
            }

            setTimeout(function () {
                $searchBoxInput.focus();
            }, 500);
        };

        var showElements = function () {
            gsap.to([$itemList, $closeBtnElement], {
                duration: 0.4,
                delay: 0,
                y: -40,
                opacity: 1
            });

            gsap.to($listWrapper, {
                duration: 0.2,
                delay: 0.3,
                y: -40,
                opacity: 1
            });

            $itemList.style.position = 'fixed';
            $closeBtnElement.style.position = 'fixed';
        };

        var hideElements = function () {
            gsap.to($searchBox, {
                duration: 0.1,
                borderBottom: '0',
                height: '0'
            });

            $itemList.style.position = 'absolute';
            $closeBtnElement.style.position = 'absolute';
        };

        var resizeModal = function () {
            if ($searchBox.style.height != '' && $searchBox.style.height != '0px') {
                var currentHeight = window.innerHeight - 3;

                gsap.to($searchBox, {
                    duration: 0.1,
                    height: currentHeight
                });
            }
        };

        var initializeData = function () {
            $body = document.getElementsByClassName(coned.constants.BODY_CLASS)[0];
            $vaChat = document.getElementsByClassName(CONSTANTS.VIRTUAL_ASSISTANT_CLASS)[0];
            $primaryNavItems = document.getElementsByClassName(CONSTANTS.PRIMARY_NAV_ITEM);
            $closeBtnElement = $searchBox.getElementsByClassName(CONSTANTS.CLOSE_BUTTON)[0];
            $languageBtnClose = document.getElementsByClassName(CONSTANTS.NAV_CLOSE_LANGUAGE)[0];
            $itemList = $searchBox.getElementsByClassName(CONSTANTS.FORM_WRAPPER)[0];
            $listWrapper = $searchBox.getElementsByClassName(CONSTANTS.LIST_WRAPPER)[0];
            $openNavBtnElement = document.getElementsByClassName(CONSTANTS.OPEN_NAV_BUTTON)[0];
            $openActionBtnElement = document.getElementsByClassName(
                CONSTANTS.OPEN_ACTION_BUTTON
            )[0];
            $openSecondaryBtnElement = document.getElementsByClassName(
                CONSTANTS.OPEN_SECONDARY_BUTTON
            )[0];
            $searchBoxInput = document.getElementsByClassName(CONSTANTS.SEARCH_BOX_INPUT)[0];
            _navBorderColor =
                document.getElementsByClassName(CONSTANTS.ORU_NAV_CLASS).length > 0
                    ? CONSTANTS.ORANGE_COLOR
                    : CONSTANTS.BLUE_COLOR;
        };

        var initializeEvents = function () {
            if ($closeBtnElement != null) {
                coned.utils.addGeneralListeners($closeBtnElement, closeSearchWrapper);
            }

            coned.utils.addGeneralListeners($openNavBtnElement, openSearchWrapper);
            coned.utils.addGeneralListeners($openActionBtnElement, openSearchWrapper);

            if ($openSecondaryBtnElement != null) {
                coned.utils.addGeneralListeners($openSecondaryBtnElement, openSearchWrapper);
            }

            new coned.utils.addKeyEvent(
                $searchBox,
                coned.constants.KEY_CODE.ESC,
                closeSearchWrapper
            );

            new coned.utils.addFocusTrap($searchBox);

            window.addEventListener('resize', resizeModal);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SearchBoxComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SearchBoxComponent;
})();
