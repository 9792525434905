// ==================== MAID TRACKING BANNER COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.MaidTrackingBanner = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FOOTER_SELECTOR: 'js-footer',
        FOOTER_MARGIN_BOTTOM: 'footer--add-margin-bottom',
        MAID_TRACKING_BANNER: 'js-maid-tracking-banner',
        MAID_TRACKING_BANNER_TERMS_CONDITIONS: 'js-terms-conditions',
        MAID_TRACKING_BANNER_PRIVACY_POLICY: 'js-privacy-policy',
        MAID_TRACKING_BANNER_CTA: 'js-maid-tracking-banner__cta',
        MAID_TRACKING_BANNER_ORU_CLASS: 'maid-tracking-banner--oru',
        MAID_TRACKING_BANNER_ORU_CTA_CLASS: 'maid-tracking-banner__cta--oru',
        HIDDEN_CLASS: 'hidden',
        MAID_TRACKING_BANNER_COOKIE_NAME: 'maidTrackingBanner',
        MAID_TRACKING_BANNER_COOKIE_STATIC_VALUE: 'isAlreadyClosed',
        RECAPTCHA_CLASS: 'js-recaptcha',
        RECAPTCHA_MODIFIER_CLASS: 'js-recaptcha--maid-active',
        VIRTUAL_ASSISTANT_CLASS: '.va-chat',
        VIRTUAL_ASSISTANT_CLASS_UP_DESKTOP: '72px',
        VIRTUAL_ASSISTANT_CLASS_UP_MOBILE: '120px',
        VIRTUAL_ASSISTANT_CLASS_DOWN: '-1px'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var MaidTrackingBanner = function ($maidTrackingBanner) {
        /**
         * PRIVATE METHODS
         */
        var $footerElement,
            $maidTrackingBannerTermsConditions,
            $maidTrackingBannerPrivacyPolicy,
            $maidTrackingBannerCta,
            $recaptcha,
            $vaChat,
            _cookieValue,
            _variableName;

        var vaChatReposition = function (vaChatEl, pos) {
            vaChatEl.style.bottom = pos; //set VA to specified position
        };

        var taggingAppearanceMaidTracking = function () {
            dataLayer.push({
                event: 'maidtrackingbanner.appearance'
            });
        };

        var taggingTermsConditions = function () {
            dataLayer.push({
                event: 'maid.terms.conditions'
            });
        };

        var taggingPrivacyPolicy = function () {
            dataLayer.push({
                event: 'maid.privacy.policy'
            });
        };

        var taggingCloseTrackingBanner = function () {
            dataLayer.push({
                event: 'maid.banner.x'
            });
        };

        var initializeData = function () {
            $footerElement = document.getElementsByClassName(CONSTANTS.FOOTER_SELECTOR);
            $maidTrackingBannerTermsConditions = $maidTrackingBanner.getElementsByClassName(
                CONSTANTS.MAID_TRACKING_BANNER_TERMS_CONDITIONS
            );
            $maidTrackingBannerPrivacyPolicy = $maidTrackingBanner.getElementsByClassName(
                CONSTANTS.MAID_TRACKING_BANNER_PRIVACY_POLICY
            );
            $maidTrackingBannerCta = $maidTrackingBanner.getElementsByClassName(
                CONSTANTS.MAID_TRACKING_BANNER_CTA
            );
            $recaptcha = document.getElementsByClassName(CONSTANTS.RECAPTCHA_CLASS);
            $vaChat = document.querySelector(CONSTANTS.VIRTUAL_ASSISTANT_CLASS);
            _cookieValue = CONSTANTS.MAID_TRACKING_BANNER_COOKIE_STATIC_VALUE;
            _variableName = CONSTANTS.MAID_TRACKING_BANNER_COOKIE_NAME + _cookieValue;
        };

        var closeTrackingBanner = function () {
            taggingCloseTrackingBanner();
            query.addClass($maidTrackingBanner, CONSTANTS.HIDDEN_CLASS);
            $footerElement[0].classList.remove(CONSTANTS.FOOTER_MARGIN_BOTTOM);

            // If the recaptcha exists, do a loop through an array of recaptchas
            // and remove the modifier that changes the position.
            if ($recaptcha) {
                for (var i = 0; i < $recaptcha.length; i++) {
                    $recaptcha[i].classList.remove(CONSTANTS.RECAPTCHA_MODIFIER_CLASS);
                }
            }
            var expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            query.setCookie(_variableName, _cookieValue, expiryDate);

            if ($vaChat) {
                //Check if VA is present and reset positioning when closing banner
                vaChatReposition($vaChat, CONSTANTS.VIRTUAL_ASSISTANT_CLASS_DOWN);
            }
        };

        var handleMobileChange = function (element) {
            if (!query.hasClass($maidTrackingBanner, CONSTANTS.HIDDEN_CLASS)) {
                //Banner is opened
                if (coned.utils.isMobile()) {
                    //screen resolution < 768
                    vaChatReposition(element, CONSTANTS.VIRTUAL_ASSISTANT_CLASS_UP_MOBILE);
                } else {
                    vaChatReposition(element, CONSTANTS.VIRTUAL_ASSISTANT_CLASS_UP_DESKTOP);
                }
            } else {
                //Banner is closed
                vaChatReposition(element, CONSTANTS.VIRTUAL_ASSISTANT_CLASS_DOWN);
            }
        };

        var initializeEvents = function () {
            // Check if the MAID Tracking banner was already hidden
            if (query.getCookie(_variableName) != null) {
                // Add class to hide banner
                query.addClass($maidTrackingBanner, CONSTANTS.HIDDEN_CLASS);
                if ($vaChat) {
                    vaChatReposition($vaChat, CONSTANTS.VIRTUAL_ASSISTANT_CLASS_DOWN); //check if VA is present and reset positioning
                }
            } else {
                taggingAppearanceMaidTracking();
                // Add extra margin to footer.
                query.addClass($footerElement, CONSTANTS.FOOTER_MARGIN_BOTTOM);
                $maidTrackingBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);
                // If the recaptcha exists, do a loop through an array of recaptchas
                // and add the modifier to change the position of each of them.
                if ($recaptcha) {
                    for (var i = 0; i < $recaptcha.length; i++) {
                        $recaptcha[i].classList.add(CONSTANTS.RECAPTCHA_MODIFIER_CLASS);
                    }
                }
            }

            // if the current page is ORU, add the style modifiers to banner.
            if (coned.utils.isOru()) {
                query.addClass($maidTrackingBanner, CONSTANTS.MAID_TRACKING_BANNER_ORU_CLASS);
                query.addClass(
                    $maidTrackingBannerCta,
                    CONSTANTS.MAID_TRACKING_BANNER_ORU_CTA_CLASS
                );
            }

            if ($maidTrackingBannerTermsConditions.length) {
                $maidTrackingBannerTermsConditions[0].addEventListener(
                    'click',
                    taggingTermsConditions
                );
            }

            if ($maidTrackingBannerPrivacyPolicy.length) {
                $maidTrackingBannerPrivacyPolicy[0].addEventListener('click', taggingPrivacyPolicy);
            }

            $maidTrackingBannerCta[0].addEventListener('click', closeTrackingBanner);

            if ($vaChat) {
                //check if VA is present on page at load time
                handleMobileChange($vaChat); //if present, recalculate VA chatbot position
                window.addEventListener('resize', function () {
                    //add event listener to detect changes in window size for VA chatbot reposition
                    handleMobileChange($vaChat);
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        setTimeout(init, 5000); //initialize banner with a delay of 5s
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    MaidTrackingBanner.prototype.isLoaded = function () {
        return isLoaded;
    };

    return MaidTrackingBanner;
})();
