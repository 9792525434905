// ==================== CONFIRMATION SCREEN COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ConfirmationScreen = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        TILES_SELECTOR: 'js-tile',
        TILE_TITLE_SELECTOR: 'js-tile-title',
        TILE_BUTTON_SELECTOR: 'js-tile-button',

        // Tagging
        TILE_CLICK_SUFIX: '.tile.click',
        TAG_BUTTON_1: 'tag-button-1',
        TAG_BUTTON_2: 'tag-button-2'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var ConfirmationScreen = function ($ConfirmationScreen) {
        /**
         * PRIVATE METHODS
         */
        var $tiles;

        /**
         * Add tagging to each tile on the page if it contains buttons. 
         * Adds to dataLayer the tile name, title and button clicked.
         */
        var tagTiles = function () {
            var $buttons,
                $title,
                titleText,
                tileButtonTag;

            _.each($tiles, function ($tile) {
                $buttons = $tile.getElementsByClassName(CONSTANTS.TILE_BUTTON_SELECTOR);

                if ($buttons) {
                    _.each($buttons, function ($button) {
                        coned.utils.addGeneralListeners($button, function () {
                            $title = $tile.getElementsByClassName(CONSTANTS.TILE_TITLE_SELECTOR)[0];
                            titleText = $title ? $title.textContent.trim() : '';
                            tileButtonTag =
                                query.hasClass($button, CONSTANTS.TAG_BUTTON_1) ?
                                    CONSTANTS.TAG_BUTTON_1 :
                                    CONSTANTS.TAG_BUTTON_2;
        
                            dataLayer.push({
                                event: $tile.dataset.tagPrefix + CONSTANTS.TILE_CLICK_SUFIX,
                                tileTitle: titleText,
                                tileButton: tileButtonTag
                            });
                        });
                    })
                }
            });
        };

        var initializeData = function () {
            $tiles = $ConfirmationScreen.getElementsByClassName(
                CONSTANTS.TILES_SELECTOR
            );
        };

        var initializeEvents = function () {
            $tiles && tagTiles();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ConfirmationScreen.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ConfirmationScreen;
})();
