// ==================== DATA CONNECTIONS FORM COMPONENT =========================
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DataConnectionsFormModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        DISABLED: 'disabled',
        REQUIRED: 'required',
        HIDDEN_CLASS: 'hidden',
        VALID_CLASS: 'valid',
        SUBMIT_EVENT: 'submit',
        CHANGE_EVENT: 'change',
        KEYUP_EVENT: 'keyup',
        ADDRESS_DROPDOWN_CLASS: 'js-address-dropdown',
        ADDRESS_DROPDOWN_SELECTED_CLASS: 'js-dropdown-button-contain',
        ADDRESS_DROPDOWN_SELECTED_CARROT_CLASS: 'js-dropdown-carrot',
        ADDRESS_DROPDOWN_LIST_CLASS: 'js-dropdown-list',
        ADDRESS_DROPDOWN_DISPLAY_NONE_CLASS: 'address-dropdown__list-item--hidden',
        ADDRESS_DROPDOWN_CARROT_UP_CLASS: 'address-dropdown__icon--up',
        THIRDPARTY_CLASS: 'js-form-thirdparty',
        TIMESPAN_SELECT_CLASS: 'js-form-date-select',
        DATE_PICKER_CLASS: 'js-form-date',
        DATE_PICKER_CONTAINER_CLASS: 'js-form-date-container',
        TERMS_CONDITIONS_CHECKBOX_CLASS: 'js-form-checkbox',
        SUBMIT_BUTTON_CLASS: 'js-form-button',
        SUBMIT_BUTTON_CONTAINER_CLASS: 'js-form-button-container',
        DATE_INVALID_ATTR: 'aria-invalid',
        FORM_ID: 'dataConnectionsForm',
        ERROR_CLASS: 'js-service-error',
        ERROR_MESSAGE_CLASS: 'js-error-message',
        FORM_INPUT_TIMESPAN_NAME: 'timespanSelect',
        FORM_INPUT_DATE_NAME: 'sharedate',
        FORM_INPUT_CHECKBOX_NAME: 'terms',

        // New experience
        NEW_EXPERIENCE_SELECTOR: 'js-new-experience',
        CATEGORIES_CHECKBOX: 'js-categories-checkbox',
        AUTHORIZE_SUBMIT_BUTTON_SELECTOR: 'js-authorize-submit',
        DECLINE_SUBMIT_BUTTON_SELECTOR: 'js-decline-submit',
        SUBMIT_ANIMATION_CONTAINER_SELECTOR: 'js-submit-animation',
        SUBMIT_ANIMATION_SELECTOR: 'js-submit-progress-animation',
        CATEGORIES_CHECKBOXES_CHECKED_SELECTOR: 'input[name="dataCategories"]:checked',
        FORM_INPUT_CLIENT_ID_NAME: 'clientId',
        FORM_INPUT_THIRD_PARTY_ID_NAME: 'thirdPartyId',
        FORM_INPUT_ORIGINAL_SCOPE_NAME: 'originalScope',
        FORM_INPUT_ORIGINAL_STATE_NAME: 'originalStateName',
        FORM_INPUT_RESPONSE_TYPE_NAME: 'originalResponseType',
        FORM_INPUT_ORIGIANL_REDIRECT_URI__NAME: 'originalRedirectURI',
        TIMEFRAME_DATE_PARAM_NAME: 'timeFrameDate',
        VISIBILITY_HIDDEN_CLASS: 'form__actions--visibility-hidden',
        CHECKBOX_CHECKED_CLASS: 'coned-checkbox--checked',
        DISABLED_ATTRIBUTE: 'disabled',
        TRUE_VALUE: true,
        SHOW_ACTION: 'show',
        HIDE_ACTION: 'hide',

        // Tagging
        LAND_GBC_TAGGING: 'gbc.land',  
        GREEN_BUTTON_TAGGING: 'green.button',
        DECLINE_BUTTON_TAGGING: 'gbc.decline',
        STEP_DATA_LAYER_THREE_TAGGING:'step2.1.3',
        STEP_DATA_LAYER_TWO_TAGGING:'step2.1.2',
        DATA_VENDOR_NAME_ATTRIBUTE: 'data-vendor-name',
        UNCHECKED: 'off',
        CHECKED: 'on',
        GBC: 'gbc.',
        DOT_STR: '.'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var DataConnectionsFormModule = function ($dataConnectionsModule) {
        /**
         * PRIVATE METHODS
         */
        var $form,
            $addressDropdown,
            $thirdparty,
            $timespanSelect,
            $datePicker,
            $datePickerContainer,
            $termsConditionsCheckbox,
            $submitButton,
            $submitButtonContainer,
            $serviceError,
            $serviceErrorMessage,
            $addressDropdownBtn,
            $addressDropdownCarrot,
            $addressDropdownList,
            _formPostUrl,
            _timespanDateSelectValue,

            // New experience
            vendorName,
            $categoriesCheckboxes,
            $authorizeSubmitButton,
            $declineSubmitButton,
            $clickedSubmitButton,
            $submitAnimationContainer,
            $submitAnimation,
            _formPostAuthorizeUrl,
            _formPostDeclineUrl,
            _isNewExperience;

        var setDatesLimit = function () {
            var tomorrow = new Date();

            tomorrow.setDate(tomorrow.getDate() + 1);

            // set input validation rules
            $datePicker.dataset.minDateDays = tomorrow;

            // set the date picker dates limit
            setTimeout(function () {
                $($datePicker).datepicker('option', 'minDate', new Date(tomorrow));
                $($datePicker).datepicker('option', 'onSelect', function () {
                    $(this).trigger('change');
                    onInputChange();
                });
            }, 1);
        };

        var onChangeTimespanSelect = function (event) {
            event.preventDefault();

            var selectedValue = query.getFormInputValue($form, CONSTANTS.FORM_INPUT_TIMESPAN_NAME);

            if (selectedValue === _timespanDateSelectValue) {
                $datePickerContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $datePicker.setAttribute(CONSTANTS.REQUIRED, '');
                // Analytics data building
                dataLayer.push({
                    event: CONSTANTS.GREEN_BUTTON_TAGGING,
                    gbc: CONSTANTS.STEP_DATA_LAYER_TWO_TAGGING
                });
            } else {
                $datePickerContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $datePicker.removeAttribute(CONSTANTS.REQUIRED);
            }

            onInputChange();
        };

        var onInputChange = function () {
            var disableButton = !(
                $($form).validate().checkForm() && $timespanSelect.selectedIndex > 0
            );

            if (_isNewExperience) {
               $authorizeSubmitButton.disabled = disableButton;
               $declineSubmitButton.disabled = disableButton;
            } else {
                $submitButton.disabled = disableButton;
            }
        };

        /**
         * Set preselected checkboxes state as checked.
         */
        var setCheckboxesAsChecked =  function () {
            Array.from($categoriesCheckboxes).forEach(function ($checkbox) {
                var $parent = $checkbox.parentElement;

                if (query.hasClass($parent, CONSTANTS.CHECKBOX_CHECKED_CLASS)) {
                    $checkbox.checked = CONSTANTS.TRUE_VALUE;
                }
            });
        };

        /**
         * Get the values from the selected categories checkboxes.
         * @return {Array} Values for checked checkboxes.
         */
        var getCategoriesCheckboxesValues =  function () {
            var $selectedCheckboxes = document.querySelectorAll(
                    CONSTANTS.CATEGORIES_CHECKBOXES_CHECKED_SELECTOR
                ),
                selectedCheckboxesValue = [];

            $selectedCheckboxes.forEach(function ($checkbox) {
                var isInputChecked = $checkbox.checked,
                    inputValue;

                if (isInputChecked) {
                    inputValue = $checkbox.value;

                    selectedCheckboxesValue.push(inputValue);
                }
            });

            return selectedCheckboxesValue;
        };

        /**
         * Detect and set which button was clicked.
         * @param {Event} event Event that triggered the function.
         */    
        var setClickedButton = function (event) {
            var $button = event.currentTarget;
             
            $clickedSubmitButton = $button;
        };

        /**
         * Get which button was clicked.
         * @return Clicked button that triggered the submit.
         */    
        var getClickedButton = function () {  
            return $clickedSubmitButton;
        };

        /**
         * Handles visibility to show a or hide the submits buttons.
         * @param {String} action String must be show or hide.
         */  
        var handleShowHideSubmitAnimation = function (action) {
            if (action === CONSTANTS.SHOW_ACTION) {
                // Show submit animation
                query.removeClass($submitAnimationContainer, CONSTANTS.HIDDEN_CLASS);
                // Set submit buttons visibility to hidden so the animation loader can be centered with CSS
                // on the container.
                query.addClass($authorizeSubmitButton, CONSTANTS.VISIBILITY_HIDDEN_CLASS);
                query.addClass($declineSubmitButton, CONSTANTS.VISIBILITY_HIDDEN_CLASS);
                // Disable submit buttons so there are no double triggers.
                $authorizeSubmitButton.disabled = CONSTANTS.TRUE_VALUE;
                $declineSubmitButton.disabled = CONSTANTS.TRUE_VALUE;
                
            } else if (action === CONSTANTS.HIDE_ACTION) {
                // Hide submit animation
                query.addClass($submitAnimationContainer, CONSTANTS.HIDDEN_CLASS);
                // Set submit buttons visibility to visible.
                query.removeClass($authorizeSubmitButton, CONSTANTS.VISIBILITY_HIDDEN_CLASS);
                query.removeClass($declineSubmitButton, CONSTANTS.VISIBILITY_HIDDEN_CLASS);
                // Enable submit buttons.
                $authorizeSubmitButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
                $declineSubmitButton.removeAttribute(CONSTANTS.DISABLED_ATTRIBUTE);
            }
        };

        /**
         * Handles sumbission for new experience, depending on which submit button triggered the event.
         */    
        var onFormSubmitNewExperience = function () {

            // Click event is triggered before submit event, hence we can get the clicked button here.
            var $submitButton = getClickedButton(),
                serviceCallback;
            
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Submit was triggered by authorize button
            if (
                query.hasClass($submitButton, CONSTANTS.AUTHORIZE_SUBMIT_BUTTON_SELECTOR)
            ) {
                serviceCallback = startFormCallAuthorization;
            
            // Submit was triggered by decline button
            } else if (
                query.hasClass($submitButton, CONSTANTS.DECLINE_SUBMIT_BUTTON_SELECTOR)
            ) {
                serviceCallback = startFormCallDecline;
            }

            handleShowHideSubmitAnimation(CONSTANTS.SHOW_ACTION);

            new coned.components.SubmitAnimation(
                $submitAnimation,
                serviceCallback,
                function () {},
                serviceErrorCallbackNewExperience
            );
        };

        var onFormSubmit = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            new coned.components.SubmitAnimation(
                $submitButtonContainer,
                startFormCall,
                function () {},
                formErrorCallback
            );
        };

        /**
         * New experience authorization service call.
         */ 
        var startFormCallAuthorization = function () {
            var clientID = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_CLIENT_ID_NAME
                ),
                thirdPartyId = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_THIRD_PARTY_ID_NAME
                ),
                originalScope = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_ORIGINAL_SCOPE_NAME
                ),
                originalState = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_ORIGINAL_STATE_NAME
                ),
                originalResponseType = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_RESPONSE_TYPE_NAME
                ),
                originalRedirectUri = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_ORIGIANL_REDIRECT_URI__NAME
                ),
                selectedScopes = getCategoriesCheckboxesValues(),
                authorizationOption = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_TIMESPAN_NAME
                ),
                $addressDropdownSelected = $addressDropdown.getElementsByClassName(
                    CONSTANTS.ADDRESS_DROPDOWN_SELECTED_CLASS
                )[0],
                params;

                params = {
                    clientID : clientID,
                    thirdPartyId: thirdPartyId,
                    originalScope: originalScope,
                    originalState: originalState,
                    originalResponseType: originalResponseType,
                    originalRedirectUri: originalRedirectUri,
                    selectedScopes: selectedScopes,
                    maid: $addressDropdownSelected.dataset.accountMaid,
                    accountNumber: $addressDropdownSelected.dataset.accountNumber,
                    selectedTimeFrame: authorizationOption
                };

            if (authorizationOption === _timespanDateSelectValue) {
                params[CONSTANTS.TIMEFRAME_DATE_PARAM_NAME] = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_DATE_NAME
                );
            }

            params = JSON.stringify(params);
            query.postData(
                _formPostAuthorizeUrl,
                formSuccessCallback,
                formErrorCallbackNewExperience,
                params,
                true,
                null
            );
        };

        /**
         * New experience decline service call.
         */ 
        var startFormCallDecline = function () {
            var thirdPartyId = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_THIRD_PARTY_ID_NAME
                ),
                originalState = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_ORIGINAL_STATE_NAME
                ),
                originalRedirectUri = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_ORIGIANL_REDIRECT_URI__NAME
                ),
                params;

                params = {
                    thirdPartyId: thirdPartyId,
                    originalState: originalState,
                    originalRedirectUri: originalRedirectUri
                };

            params = JSON.stringify(params);
            query.postData(
                _formPostDeclineUrl,
                formSuccessCallback,
                formErrorCallbackNewExperience,
                params,
                true,
                null
            );
        };

        /**
         * Old experience service call.
         */    
        var startFormCall = function () {
            var authorizationOption = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_TIMESPAN_NAME
                ),
                $addressDropdownSelected = $addressDropdown.getElementsByClassName(
                    CONSTANTS.ADDRESS_DROPDOWN_SELECTED_CLASS
                )[0],
                params = {
                    ScId: $dataConnectionsModule.dataset.scid,
                    Maid: $addressDropdownSelected.dataset.accountMaid,
                    AccountNumber: $addressDropdownSelected.dataset.accountNumber,
                    ThirdPartyId: $thirdparty.dataset.thirdpartyId,
                    AuthorizationOption: authorizationOption
                };

            if (authorizationOption === _timespanDateSelectValue) {
                params['RevokeDate'] = query.getFormInputValue(
                    $form,
                    CONSTANTS.FORM_INPUT_DATE_NAME
                );
            }

            params = JSON.stringify(params);
            query.postData(
                _formPostUrl,
                formSuccessCallback,
                formErrorCallback,
                params,
                true,
                null
            );
        };

        var formSuccessCallback = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.POST_DATA_CONNECTIONS_FORM,
                    redirectSite,
                    formErrorCallback
                );
            } else {
                redirectSite(data);
            }
        };

        var redirectSite = function (data) {
            var $submitButton = getClickedButton();

            // Analytics data building
            if (!_isNewExperience) {
                dataLayer.push({
                    event: CONSTANTS.GREEN_BUTTON_TAGGING,
                    gbc: $thirdparty.dataset.thirdpartyName
                });
                dataLayer.push({
                    event: CONSTANTS.GREEN_BUTTON_TAGGING,
                    gbc: CONSTANTS.STEP_DATA_LAYER_THREE_TAGGING
                });
            } else {
                // Set date layer when user press accept
                if (query.hasClass($submitButton, CONSTANTS.AUTHORIZE_SUBMIT_BUTTON_SELECTOR)) {
                    dataLayer.push({
                        event: CONSTANTS.GREEN_BUTTON_TAGGING,
                        gbc: CONSTANTS.STEP_DATA_LAYER_THREE_TAGGING,
                        vendor2: vendorName
                    });
                // Set data layer when user press decline
                } else if (query.hasClass($submitButton, CONSTANTS.DECLINE_SUBMIT_BUTTON_SELECTOR)) {
                    dataLayer.push({
                        event: CONSTANTS.DECLINE_BUTTON_TAGGING,
                        vendor2: vendorName
                    });
                }
            }
            window.location.href = data.RedirectUrl;
        };

        var formErrorCallback = function (data) {
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceError.focus();
            $submitButtonContainer.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        /**
         * Error callback for new experience that sets the appropriate class to stop the submit animation.
         */   
        var formErrorCallbackNewExperience = function () {
            $submitAnimation.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
        };

        /**
         * Service error callback for new experience that shows the error message.
         */   
        var serviceErrorCallbackNewExperience = function (data) {
            var $submitButton = getClickedButton();

            $serviceErrorMessage.innerHTML = 
                data && data.errorMsg
                    ? data.errorMsg
                    : coned.constants.ERROR_MESSAGE;
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            
            // Hide animation and show the submit buttons.
            handleShowHideSubmitAnimation(CONSTANTS.HIDE_ACTION);
            $submitButton.focus();
        };

        var pushAnalytics = function () {
            // Analytics data building
            dataLayer.push({
                event: 'green.button',
                gbc: 'step2.1.1'
            });
        };

        var addTaggingVendorName = function () {
            // Analytics data building
            dataLayer.push({
                event: CONSTANTS.LAND_GBC_TAGGING,
                vendor2: vendorName
            });
        };

        var closeDropdown = function () {
            query.addClass($addressDropdownList, CONSTANTS.ADDRESS_DROPDOWN_DISPLAY_NONE_CLASS);
            query.removeClass($addressDropdownCarrot, CONSTANTS.ADDRESS_DROPDOWN_CARROT_UP_CLASS);
        };

         /**
         * Create a tag when the user tap a checkbox from the first group displayed
         */
        var addTaggingCheckBox = function (event) {
            var optionSelected = event.target.value.toLowerCase(),
                _isChecked = event.target.checked ? CONSTANTS.CHECKED : CONSTANTS.UNCHECKED,
                eventName = CONSTANTS.GBC + optionSelected + CONSTANTS.DOT_STR + _isChecked;

            dataLayer.push({ event: eventName });
        }

        var initializeData = function () {
            _isNewExperience = query.hasClass(
                $dataConnectionsModule, 
                CONSTANTS.NEW_EXPERIENCE_SELECTOR
            );

            $form = document.getElementById(CONSTANTS.FORM_ID);
            $addressDropdown = $dataConnectionsModule.getElementsByClassName(
                CONSTANTS.ADDRESS_DROPDOWN_CLASS
            )[0];
            $addressDropdownBtn = $addressDropdown.getElementsByClassName(
                CONSTANTS.ADDRESS_DROPDOWN_SELECTED_CLASS
            )[0];
            $addressDropdownCarrot = $addressDropdown.getElementsByClassName(
                CONSTANTS.ADDRESS_DROPDOWN_SELECTED_CARROT_CLASS
            )[0];
            $addressDropdownList = $addressDropdown.getElementsByClassName(
                CONSTANTS.ADDRESS_DROPDOWN_LIST_CLASS
            )[0];
            $thirdparty = $dataConnectionsModule.getElementsByClassName(
                CONSTANTS.THIRDPARTY_CLASS
            )[0];
            $timespanSelect = $dataConnectionsModule.getElementsByClassName(
                CONSTANTS.TIMESPAN_SELECT_CLASS
            )[0];
            _timespanDateSelectValue = $timespanSelect.dataset.dateOption;

            $datePickerContainer = $dataConnectionsModule.getElementsByClassName(
                CONSTANTS.DATE_PICKER_CONTAINER_CLASS
            )[0];
            $datePicker = $datePickerContainer.getElementsByClassName(
                CONSTANTS.DATE_PICKER_CLASS
            )[0];
            $termsConditionsCheckbox = $dataConnectionsModule.getElementsByClassName(
                CONSTANTS.TERMS_CONDITIONS_CHECKBOX_CLASS
            )[0];
            $submitButton = $dataConnectionsModule.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON_CLASS
            )[0];
            $submitButtonContainer = $dataConnectionsModule.getElementsByClassName(
                CONSTANTS.SUBMIT_BUTTON_CONTAINER_CLASS
            )[0];
            $serviceError = $dataConnectionsModule.getElementsByClassName(CONSTANTS.ERROR_CLASS)[0];
            $serviceErrorMessage = $dataConnectionsModule.getElementsByClassName(
                CONSTANTS.ERROR_MESSAGE_CLASS
            )[0];

            if (_isNewExperience) {
                vendorName = $dataConnectionsModule.getAttribute(CONSTANTS.DATA_VENDOR_NAME_ATTRIBUTE);
                $categoriesCheckboxes = $dataConnectionsModule.getElementsByClassName(
                    CONSTANTS.CATEGORIES_CHECKBOX
                );
                $authorizeSubmitButton = $dataConnectionsModule.getElementsByClassName(
                    CONSTANTS.AUTHORIZE_SUBMIT_BUTTON_SELECTOR
                )[0];
                $declineSubmitButton = $dataConnectionsModule.getElementsByClassName(
                    CONSTANTS.DECLINE_SUBMIT_BUTTON_SELECTOR
                )[0];
                $submitAnimationContainer = $dataConnectionsModule.getElementsByClassName(
                    CONSTANTS.SUBMIT_ANIMATION_CONTAINER_SELECTOR
                )[0];
                $submitAnimation = $submitAnimationContainer.getElementsByClassName(
                    CONSTANTS.SUBMIT_ANIMATION_SELECTOR
                )[0];

                _formPostAuthorizeUrl = $dataConnectionsModule.dataset.connectionsFormAuthorizeService;
                _formPostDeclineUrl = $dataConnectionsModule.dataset.connectionsFormDeclineService;
            } else {
                _formPostUrl = $dataConnectionsModule.dataset.connectionsFormService;
            }
        };

        var initializeEvents = function () {
            setDatesLimit();

            coned.utils.addGeneralListeners($addressDropdownBtn, pushAnalytics);
            coned.utils.addGeneralListeners($addressDropdownCarrot, pushAnalytics);
            coned.utils.addGeneralListeners($termsConditionsCheckbox, onInputChange);
            coned.utils.addMultipleListeners(
                $datePicker,
                [CONSTANTS.CHANGE_EVENT, CONSTANTS.KEYUP_EVENT],
                onInputChange
            );
            coned.utils.addMultipleListeners(
                $timespanSelect,
                [CONSTANTS.CHANGE_EVENT],
                onChangeTimespanSelect
            );

            $timespanSelect.onfocus = closeDropdown;

            if (_isNewExperience) {
                new coned.components.ValidateForm('#' + CONSTANTS.FORM_ID, onFormSubmitNewExperience);
                
                // New experience has two submit buttons with different services for each.
                // Register which submit button was clicked.
                coned.utils.addGeneralListeners($authorizeSubmitButton, setClickedButton);
                coned.utils.addGeneralListeners($declineSubmitButton, setClickedButton);

                setCheckboxesAsChecked();

                Array.from($categoriesCheckboxes).forEach(function ($categoriesCheckbox) {
                    coned.utils.addGeneralListeners($categoriesCheckbox, onInputChange);
                    coned.utils.addGeneralListeners($categoriesCheckbox, addTaggingCheckBox);
                });
                addTaggingVendorName();
                
            } else {
                // Old experience has only one submit button and its specific service
                new coned.components.ValidateForm('#' + CONSTANTS.FORM_ID, onFormSubmit);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DataConnectionsFormModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DataConnectionsFormModule;
})();
