// ==================== LOADING PAGE ANIMATION COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LoadingPageAnimation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ANIMATION_SUBMIT_MODULE: 'js-submit-progress-animation'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var LoadingPageAnimation = function ($enrollUnitsAnimation) {
        /**
         * PRIVATE METHODS
         */
        var $submitAnimationButton,
            dummyFunction = function () {};

        var startLoadingPageAnimation = function () {
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                dummyFunction,
                dummyFunction,
                dummyFunction,
                false,
                true
            );
        };

        var initializeData = function () {
            $submitAnimationButton = $enrollUnitsAnimation.getElementsByClassName(
                CONSTANTS.ANIMATION_SUBMIT_MODULE
            )[0];
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            startLoadingPageAnimation();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LoadingPageAnimation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LoadingPageAnimation;
})();
