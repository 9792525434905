// ==================== SEARCH FILTERS COMPONENT =========================
/* global _ */
/* global gsap */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SearchFiltersComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        WRAPPER_BY_FILTER: 'js-search-filters-by',
        WRAPPER_TOPIC_FILTER: 'js-dropdown-topic',
        WRAPPER_TYPE_FILTER: 'js-dropdown-type',
        WRAPPER_FILTER_OPTIONS: 'js-search-filters-options',
        DROPDOWN_TITLE_LINK: 'js-dropdown-link',
        DROPDOWN_TITLE: 'js-dropdown-title',
        DROPDOWN_TITLE_TYPE_OPTION: 'dropdown-title__type-option',
        DROPDOWN_LIST: 'js-dropdown-list',
        FILTER_BUTTON: 'js-filter-button',
        OPTION_LINK: 'js-dropdown-list-item-link',
        OPTION_TITLE: 'js-dropdown-list-item-title',
        OPTION_ACTIVE_CLASS: 'dropdown-list-item-link--active',
        BUTTON_ACTIVE_CLASS: 'dropdown-title--active',
        FILTER_ACTIVE_CLASS: 'search-filters__by-nav-item--active',
        MOBILE_DROPDOWN_TITLE: 'dropdown-title--mobile',
        VISIBLE_MOBILE: 'visible-mobile',
        FILTER_URL_PARAMETER_NAME: 'filter',
        FILTER_URL_PARAMETER: 'filter=',
        TOPIC_URL_PARAMETER_NAME: 'topic',
        TOPIC_URL_PARAMETER: 'topic=',
        TYPE_URL_PARAMETER_NAME: 'type',
        TYPE_URL_PARAMETER: 'type=',
        PAGINATION_URL_PARAMETER_NAME: 'page',
        PAGINATION_CONTENT_CLASS: 'js-pagination-content',
        PAGINATION_RESET_CLASS: 'js-pagination-reset',
        RESULTS_ANIMATION_SELECTOR: 'js-results-selector',
        RESULTS_ANIMATION_CLASS: 'search-results__item--animation',
        CLEAR_FILTERS: 'js-search-clear-filters',

        ARIA_EXPANDED: 'aria-expanded'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var SearchFiltersComponent = function ($SearchFiltersComponent) {
        /**
         * PRIVATE METHODS
         */
        var $dropDownTitleLinks,
            $dropDownList,
            $filterButtons,
            $filterWrapper,
            $filterByWrapper,
            $filterTopicWrapper,
            $filterTypeWrapper,
            $optionLinks,
            $placeHolderTitle,
            $dropDownTitleTypeOption,
            $paginationContentElement,
            $paginationResetElement,
            $resultsAnimationSelector,
            $clearSearchFilters,
            _initialLoad;

        var getSelectedValueIndex = function ($filterElements, selectedValue) {
            var selectedValueIndex = -1;

            for (var filterIndex = 0; $filterElements.length > filterIndex; filterIndex++) {
                if ($filterElements[filterIndex].dataset.value == selectedValue) {
                    selectedValueIndex = filterIndex;
                    break;
                }
            }

            return selectedValueIndex;
        };

        var setFilter = function (parameterName, $filterElements) {
            if (window.location.search.includes(parameterName)) {
                var selectedValue = coned.utils.getUrlParameterValue(parameterName);
                var selectedValueIndex = getSelectedValueIndex($filterElements, selectedValue);

                if (selectedValueIndex > -1) {
                    $filterElements[selectedValueIndex].click();
                }
            }
        };

        var setFilters = function () {
            // Set filter by
            setFilter(CONSTANTS.FILTER_URL_PARAMETER_NAME, $filterButtons);

            // Set topic filter
            setFilter(
                CONSTANTS.TOPIC_URL_PARAMETER_NAME,
                $filterTopicWrapper.getElementsByClassName(CONSTANTS.OPTION_LINK)
            );

            // Set type filter
            setFilter(
                CONSTANTS.TYPE_URL_PARAMETER_NAME,
                $filterTypeWrapper.getElementsByClassName(CONSTANTS.OPTION_LINK)
            );

            _initialLoad = false;
        };

        var filterButtonsAnimation = function (event) {
            event.preventDefault();

            _.each($filterButtons, function (filterButtons) {
                filterButtons.classList.remove(CONSTANTS.FILTER_ACTIVE_CLASS);
            });

            this.classList.toggle(CONSTANTS.FILTER_ACTIVE_CLASS);

            makeSearch();
        };

        var dropDownAnimation = function (event) {
            event.preventDefault();

            var isActive = query.hasClass(this, CONSTANTS.BUTTON_ACTIVE_CLASS),
                isMobile = query.hasClass(this, CONSTANTS.MOBILE_DROPDOWN_TITLE),
                $selectedDropDown = this.parentNode,
                deviceType = isMobile ? $SearchFiltersComponent : $selectedDropDown,
                $selectedList = deviceType.getElementsByClassName(CONSTANTS.DROPDOWN_LIST);

            if (!isActive) {
                closeDropDown();

                // animate the list
                gsap.from($selectedList, {
                    duration: 0.2,
                    y: -20,
                    opacity: 0
                });

                // show all list element
                _.each($selectedList, function (listItem) {
                    listItem.style.display = 'block';
                });

                if (isMobile) {
                    _.each($dropDownTitleLinks, function (titleItem) {
                        titleItem.style.display = 'block';
                    });

                    $dropDownTitleTypeOption.length > 0 &&
                        query.addClass($dropDownTitleTypeOption, CONSTANTS.VISIBLE_MOBILE);
                }

                this.classList.toggle(CONSTANTS.BUTTON_ACTIVE_CLASS);

                _.each($dropDownTitleLinks, function (titleItem) {
                    query.hasClass(titleItem, CONSTANTS.BUTTON_ACTIVE_CLASS) &&
                        titleItem.setAttribute(CONSTANTS.ARIA_EXPANDED, 'true');
                });
            } else {
                closeDropDown();
            }
        };

        var closeDropDown = function () {
            _.each($dropDownList, function (dropDownList) {
                dropDownList.style.display = 'none';
                query.removeClass($dropDownTitleLinks, CONSTANTS.BUTTON_ACTIVE_CLASS);
            });

            _.each($dropDownTitleLinks, function (titleItem) {
                titleItem.setAttribute(CONSTANTS.ARIA_EXPANDED, 'false');
            });

            if (coned.utils.isMobile()) {
                _.each($placeHolderTitle, function (placeholderTitle) {
                    placeholderTitle.style.display = 'none';
                });

                $dropDownTitleTypeOption.length > 0 &&
                    query.removeClass($dropDownTitleTypeOption, CONSTANTS.VISIBLE_MOBILE);
            } else {
                _.each($placeHolderTitle, function (placeholderTitle) {
                    placeholderTitle.setAttribute('style', 'display: flex !important;');
                });
                $dropDownTitleTypeOption.length > 0 &&
                    query.addClass($dropDownTitleTypeOption, CONSTANTS.VISIBLE_MOBILE);
            }
        };

        var selectedValue = function (event) {
            event.preventDefault();

            var $optionTitle = this.getElementsByClassName(CONSTANTS.OPTION_TITLE)[0].innerHTML,
                $selectTitle = this.parentNode.parentNode.parentNode.getElementsByClassName(
                    CONSTANTS.DROPDOWN_TITLE
                )[0],
                $defaultTitle = $selectTitle.parentNode.dataset.defaultTitle,
                $optionLinkActive = this.parentNode.parentNode.parentNode.getElementsByClassName(
                    CONSTANTS.OPTION_LINK
                ),
                isOptionActive = query.hasClass(this, CONSTANTS.OPTION_ACTIVE_CLASS),
                isMobile = coned.utils.isMobile();

            _.each($optionLinkActive, function (optionLinkActive) {
                optionLinkActive.classList.remove(CONSTANTS.OPTION_ACTIVE_CLASS);
                optionLinkActive.setAttribute('aria-selected', 'false');
            });

            if (isMobile) {
                _.each($placeHolderTitle, function (placeholderTitle) {
                    placeholderTitle.style.display = 'none';
                });
            }

            if (isOptionActive) {
                $selectTitle.innerHTML = $defaultTitle;
            } else {
                $selectTitle.innerHTML = $optionTitle;
                this.classList.add(CONSTANTS.OPTION_ACTIVE_CLASS);
                this.setAttribute('aria-selected', 'true');
            }

            //If it is clicked, it means that the event occurs via the keyboard.
            //Then a redirect focus should be done
            if (isMobile && event.type == 'click') {
                var mobileButton = $SearchFiltersComponent.getElementsByClassName(
                    CONSTANTS.MOBILE_DROPDOWN_TITLE
                )[0];
                mobileButton.focus();
            } else {
                //Selected current open dropdown
                var dropDownActive = $selectTitle.parentNode;
                //redirected focus
                dropDownActive &&
                    query.hasClass(dropDownActive, CONSTANTS.BUTTON_ACTIVE_CLASS) &&
                    dropDownActive.focus();
            }
            closeDropDown();
            makeSearch();
        };

        var addFilterToPath = function (
            urlSeparator,
            path,
            filterValue,
            filterParameterName,
            filterParameter
        ) {
            if (filterValue != '') {
                if (window.location.search.includes(filterParameterName)) {
                    path = coned.utils.updateAnchorParameter(
                        path,
                        filterParameterName,
                        filterValue
                    );
                } else {
                    path += urlSeparator + filterParameter + filterValue;
                }
            } else {
                if (window.location.search.includes(filterParameterName)) {
                    path = coned.utils.deleteAnchorParameter(path, filterParameterName);
                }
            }

            return path;
        };

        var makeSearch = function () {
            if (_initialLoad) return;

            var $filterByItem = $filterByWrapper.getElementsByClassName(
                    CONSTANTS.FILTER_ACTIVE_CLASS
                ),
                $filterTopicItem = $filterTopicWrapper.getElementsByClassName(
                    CONSTANTS.OPTION_ACTIVE_CLASS
                ),
                $filterTypeItem = $filterTypeWrapper.getElementsByClassName(
                    CONSTANTS.OPTION_ACTIVE_CLASS
                ),
                filterByValue = $filterByItem.length ? $filterByItem[0].dataset.value : '',
                filterTopicValue = $filterTopicItem.length ? $filterTopicItem[0].dataset.value : '',
                filterTypeValue = $filterTypeItem.length ? $filterTypeItem[0].dataset.value : '',
                urlSeparator = window.location.href.indexOf('?') === -1 ? '?' : '&',
                path = window.location.href,
                servicePath = '';

            // Add filter by item to path
            path = addFilterToPath(
                urlSeparator,
                path,
                filterByValue,
                CONSTANTS.FILTER_URL_PARAMETER_NAME,
                CONSTANTS.FILTER_URL_PARAMETER
            );

            // Add filter by topic to path
            path = addFilterToPath(
                urlSeparator,
                path,
                filterTopicValue,
                CONSTANTS.TOPIC_URL_PARAMETER_NAME,
                CONSTANTS.TOPIC_URL_PARAMETER
            );

            // Add filter by type to path
            path = addFilterToPath(
                urlSeparator,
                path,
                filterTypeValue,
                CONSTANTS.TYPE_URL_PARAMETER_NAME,
                CONSTANTS.TYPE_URL_PARAMETER
            );

            // Remove pagination from the url
            path = coned.utils.deleteAnchorParameter(path, CONSTANTS.PAGINATION_URL_PARAMETER_NAME);

            var pathname = path.replace(window.location.origin, '');

            //window.history.pushState(state object, title, URL)
            window.history.pushState({ page: path }, '', pathname);

            // Set service path
            servicePath =
                $paginationContentElement.dataset.service +
                path.replace(window.location.origin + window.location.pathname, '');

            // For Pattern Lab, redirect to template file
            if (coned.utils.isPatternLab()) {
                if (
                    filterByValue != '' &&
                    filterByValue > 1 &&
                    filterTopicValue != '' &&
                    filterTypeValue != ''
                ) {
                    servicePath = coned.plConstants.PATTERNLAB_SEARCH_RESULTS_NONE_PATH;
                } else if (filterByValue != '' && filterByValue > 1 && filterTopicValue != '') {
                    servicePath = coned.plConstants.PATTERNLAB_SEARCH_RESULTS_ALL_PATH;
                } else if (filterTopicValue != '') {
                    servicePath = coned.plConstants.PATTERNLAB_SEARCH_RESULTS_TOPIC_PATH;
                } else if (filterTypeValue != '') {
                    servicePath = coned.plConstants.PATTERNLAB_SEARCH_RESULTS_TYPE_PATH;
                } else if (filterByValue != '') {
                    servicePath = coned.plConstants.PATTERNLAB_SEARCH_RESULTS_VIEWED_PATH;
                }
            }

            query.getData(
                servicePath,
                function (data) {
                    // Set the search results content with the data received
                    $paginationContentElement.innerHTML = data;

                    // Reset the pagination element so it represents the page change
                    $paginationResetElement.click();
                    window.scrollTo(0, 0);

                    animateItems();
                },
                function () {}
            );
        };

        var animateItems = function () {
            var animationTime = 100,
                cascadeAnimation = 0;

            _.each($resultsAnimationSelector, function ($ele) {
                cascadeAnimation += animationTime;
                setTimeout(function () {
                    query.removeClass($ele, CONSTANTS.RESULTS_ANIMATION_CLASS);
                }, cascadeAnimation);
            });
        };

        var clearSearchFilters = function (event) {
            event.preventDefault();

            Array.prototype.forEach.call($optionLinks, function ($item) {
                var $selectTitle = $item.parentNode.parentNode.parentNode.getElementsByClassName(
                        CONSTANTS.DROPDOWN_TITLE
                    )[0],
                    $defaultTitle = $selectTitle.parentNode.dataset.defaultTitle,
                    $optionLinkActive = $item.parentNode.parentNode.parentNode.getElementsByClassName(
                        CONSTANTS.OPTION_LINK
                    ),
                    isMobile = coned.utils.isMobile();

                _.each($optionLinkActive, function (optionLinkActive) {
                    optionLinkActive.classList.remove(CONSTANTS.OPTION_ACTIVE_CLASS);
                    $selectTitle.innerHTML = $defaultTitle;
                });

                if (isMobile) {
                    _.each($placeHolderTitle, function (placeholderTitle) {
                        placeholderTitle.style.display = 'none';
                    });
                }
            });

            makeSearch();
        };

        var initializeData = function () {
            ($dropDownTitleLinks = $SearchFiltersComponent.getElementsByClassName(
                CONSTANTS.DROPDOWN_TITLE_LINK
            )),
                ($dropDownList = $SearchFiltersComponent.getElementsByClassName(
                    CONSTANTS.DROPDOWN_LIST
                )),
                ($filterButtons = $SearchFiltersComponent.getElementsByClassName(
                    CONSTANTS.FILTER_BUTTON
                )),
                ($optionLinks = $SearchFiltersComponent.getElementsByClassName(
                    CONSTANTS.OPTION_LINK
                )),
                ($filterByWrapper = $SearchFiltersComponent.getElementsByClassName(
                    CONSTANTS.WRAPPER_BY_FILTER
                )[0]),
                ($filterTopicWrapper = $SearchFiltersComponent.getElementsByClassName(
                    CONSTANTS.WRAPPER_TOPIC_FILTER
                )[0]),
                ($filterTypeWrapper = $SearchFiltersComponent.getElementsByClassName(
                    CONSTANTS.WRAPPER_TYPE_FILTER
                )[0]),
                ($filterWrapper = $SearchFiltersComponent.getElementsByClassName(
                    CONSTANTS.WRAPPER_FILTER_OPTIONS
                )[0]),
                ($placeHolderTitle = $filterWrapper.getElementsByClassName(
                    CONSTANTS.DROPDOWN_TITLE_LINK
                ));
            $dropDownTitleTypeOption = $filterWrapper.getElementsByClassName(
                CONSTANTS.DROPDOWN_TITLE_TYPE_OPTION
            );
            $paginationContentElement = document.getElementsByClassName(
                CONSTANTS.PAGINATION_CONTENT_CLASS
            )[0];
            $paginationResetElement = document.getElementsByClassName(
                CONSTANTS.PAGINATION_RESET_CLASS
            )[0];
            $resultsAnimationSelector = $paginationContentElement.getElementsByClassName(
                CONSTANTS.RESULTS_ANIMATION_SELECTOR
            );
            $clearSearchFilters = $SearchFiltersComponent.getElementsByClassName(
                CONSTANTS.CLEAR_FILTERS
            )[0];
            _initialLoad = true;
        };

        var initializeEvents = function () {
            // add event listener to dropdown list
            _.each($dropDownTitleLinks, function ($dropDownTitleLink) {
                coned.utils.addGeneralListeners($dropDownTitleLink, dropDownAnimation);
            });

            // add event listener to filter buttons
            _.each($filterButtons, function ($filterButton) {
                coned.utils.addGeneralListeners($filterButton, filterButtonsAnimation);
            });

            // add event listener to optionLinks
            _.each($optionLinks, function ($optionLink) {
                coned.utils.addGeneralListeners($optionLink, selectedValue);
            });

            // resize listener
            window.addEventListener('resize', closeDropDown);

            // set initial filters selection
            setFilters();

            // Load animation
            animateItems();

            closeDropDown();

            // Add event listener to the clear search filters button
            coned.utils.addGeneralListeners($clearSearchFilters, clearSearchFilters);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SearchFiltersComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SearchFiltersComponent;
})();
