// ==================== PAGINATION_SIMPLE =========================

var coned = coned || {};
coned.components = coned.components || {};

/**
 * @returns the init function to start the module
 */
coned.components.PaginationSimple = (function () {
    var isLoaded = false;
    /**
     * @typedef {Object} PublicState
     * @property {boolean} [isSeted]
     * @property {number} [totalItems]
     * @property {number} [itemsPerPage]
     * @property {number} [currentPage]
     * @property {number} [totalPages]
     * @property {boolean} [canScroll]
     * @property {HTMLElement} [$srLive]
     */

    /**
     * @typedef {Object} PrivateState
     * @property {HTMLElement | null} [$prev]
     * @property {HTMLElement | null} [$next]
     * @property {HTMLElement[]} [$pages]
     * @property {HTMLElement} [$currentPage]
     * @property {HTMLElement} [$firstPage]
     * @property {HTMLElement} [$lastPage]
     * @property {HTMLElement} [$firstPageVisible]
     * @property {HTMLElement} [$lastPageVisible]
     * @property {string} [textFirst]
     * @property {string} [textLast]
     * @property {string} [textCurrent]
     * @property {string} [textPage]
     * @property {string} [textNext]
     * @property {string} [textPrevious]
     * @property {string} [textNavigation]
     * 
     */

    /**
     * @typedef {Object} ParamsListAnchorPage
     * @property {string[]} [classes]
     * @property {string} [label]
     * @property {string} [innerHTML]
     * @property {string} [page]
     */

    /**
     * @typedef {Object} ParamsPageHtml
     * @property {HTMLElement} $element
     * @property {boolean} [visible]
     * @property {boolean} [current]
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        CLASSES: {
            /**
            * @type {'pagination__cta--prev'}
            */
            PAGINATION_PREV: 'pagination__cta--prev',
            /**
            * @type {'pagination__cta--next'}
            */
            PAGINATION_NEXT: 'pagination__cta--next',
            /**
            * @type {'pagination__cta'}
            */
            PAGINATION_CTA: 'pagination__cta',
            /**
             * @type {'pagination__cta--active'}
             */
            PAGINATION_ACTIVE: 'pagination__cta--active'
        },
        SELECTORS: {
            /**
             * @type {'js-pagination-span-current'}
             */
            PAGINATION_SPAN_CURRENT: 'js-pagination-span-current'
        },
        DATA_TEXT: { // default Values if not provided in dataset
            /**
             * @type {'Current'}
             */
            CURRENT: 'Current',
            /**
             * @type {'First'}
             */
            FIRST: 'First',
            /**
             * @type {'Last'}
             */
            LAST: 'Last',
            /**
             * @type {'Page'}
             */
            PAGE: 'Page',
            /**
             * @type {'Next'}
             */
            NEXT: 'Next',
            /**
             * @type {'Previous'}
             */
            PREVIOUS: 'Previous',
            /**
             * @type {'Pagination'}
             */
            NAVIGATION: 'Pagination'
        },
        VALUES: {
            /**
             * @type {''}
             */
            EMPTY: '',
            /**
             * @type {' '}
             */
            SPACE: ' ',
            MAX_PAGES: 5,
            /**
             * @type {'prev-page'}
             */
            HREF_PREV: 'prev-page',
            /**
             * @type {'next-page'}
             */
            HREF_NEXT: 'next-page',
            /**
             * @type {'role'}
             */
            ROLE: 'role',
            /**
             * @type {'presentation'}
             */
            PRESENTATION: 'presentation',
            /**
             * @type {'<span class="icon-carrot"></span>'}
             */
            SPAN_CARROT: '<span class="icon-carrot"></span>',
            /**
             * @type {'<span class="visually-hidden visually-hidden--desktop">Page</span>'}
             */
            SPAN_PAGE: '<span class="visually-hidden visually-hidden--desktop">Page&nbsp;</span>',
            /**
             * @type {'a[data-page="{0}"]'}
             */
            SELECTOR_FORMAT: 'a[data-page="{0}"]',
            /**
             * @type {'change-page'}
             */
            CHANGE_FOCUS: 'change-focus'
        }
    }
    /**
     * @constructor
     * @param {HTMLElement} $container
     * @returns {}
     */
    var PaginationSimple = function ($container) {
        /**
         * PRIVATE VARIABLES
         */
        /**
         * @type {PublicState}
         */
        var _publicState,
            /**
             * @type {PrivateState}
             */
            _privateState;

        /**
         * PRIVATE METHODS
         */

        /**
         * Get page from or url or seted in externat state change (don't work inside iframe)
         * @param {number | undefined} currentPage 
         * @returns 
         */
        var getPageUrl = function (currentPage) {
            var urlParams = new URLSearchParams(window.location.search),
                pageFromUrl = urlParams.get('page') ? parseInt(urlParams.get('page')) : null,
                pageToUse = currentPage && typeof currentPage === 'number' ? currentPage : 1;

            if (pageFromUrl !== null && pageFromUrl !== pageToUse) {
                if (pageFromUrl < 1 || (typeof _publicState.totalPages === 'number' && pageFromUrl > _publicState.totalPages)) {
                    urlParams.delete('page');
                    window.history.replaceState(null, '', window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : ''));
                } else {
                    pageToUse = pageFromUrl;
                }
            }
            if (pageToUse < 1 || (typeof _publicState.totalPages === 'number' && pageToUse > _publicState.totalPages)) {
                pageToUse = 1;
                urlParams.delete('page');
                window.history.replaceState(null, '', window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : ''));
            } else if (pageToUse !== pageFromUrl) {
                urlParams.set('page', pageToUse);
                window.history.replaceState(null, '', window.location.pathname + '?' + urlParams.toString());
            }

            return pageToUse;
        }

        /**
         * Update ?page search param (don't work inside iframe)
         * @param {number} page 
         */
        var updatePageURL = function (page) {
            if (_publicState.isSeted) {
                var url = new URL(window.location.href);
                if (page === 1) {
                    url.searchParams.delete('page');
                } else {
                    url.searchParams.set('page', page);
                }
                history.pushState(null, '', url.toString());
            }
        };

        /**
         * Get cta with page passed in param
         * @param {number} page 
         */
        var getPageElement = function (page) {
            var selector = coned.utils.formatString(CONSTANTS.VALUES.SELECTOR_FORMAT, [page]),
                $page = $container.querySelector(selector);
            if ($page instanceof HTMLElement) {
                return $page
            }
            return null
        }
        /**
         * Update html of cta page
         * @param {ParamsPageHtml} params 
         */
        var setPageHTML = function (params) {
            if (params.$element) {
                var labelText = params.$element.getAttribute(coned.constants.ARIA.LABEL);
                labelText = labelText
                    ? labelText.replace(_privateState.textCurrent, CONSTANTS.VALUES.EMPTY)
                    : CONSTANTS.VALUES.EMPTY;
                params.$element.parentElement.setAttribute(
                    coned.constants.ARIA.HIDDEN,
                    params.visible ? coned.constants.FALSE : coned.constants.TRUE
                );
                if (params.current) {
                    params.$element.setAttribute(
                        coned.constants.ARIA.LABEL,
                        _privateState.textCurrent + CONSTANTS.VALUES.SPACE + labelText
                    );
                    params.$element.classList.add(
                        CONSTANTS.CLASSES.PAGINATION_ACTIVE
                    );
                } else {
                    params.$element.setAttribute(
                        coned.constants.ARIA.LABEL,
                        labelText
                    );
                }
            }
        }
        /**
         * Change page html and state
         * @param {number} page 
         * @param {boolean} [clickPrevNext]
         * @returns 
         */
        var changePage = function (page, clickPrevNext) {
            if (_publicState.totalPages === 0) {
                return;
            }
            var isFirstPage = page === 1,
                isLastPage = page === (typeof _publicState.totalPages !== 'undefined'
                    ? _publicState.totalPages : 0),
                isFirstPageVisible = _privateState.$firstPageVisible &&
                    page === Number(_privateState.$firstPageVisible.dataset.page),
                isLastPageVisible = _privateState.$lastPageVisible &&
                    page === Number(_privateState.$lastPageVisible.dataset.page),
                pageFormat = _privateState.textPage + ' ' + page,
                pageElement = getPageElement(page),
                $pageActive = $container.getElementsByClassName(CONSTANTS.CLASSES.PAGINATION_ACTIVE)[0];
            if ($pageActive) {
                var labelActive = $pageActive.getAttribute(coned.constants.ARIA.LABEL);
                $pageActive.classList.remove(CONSTANTS.CLASSES.PAGINATION_ACTIVE);
                $pageActive.setAttribute(coned.constants.ARIA.LABEL,
                    labelActive.replace(_privateState.textCurrent, CONSTANTS.VALUES.EMPTY));
            }
            if (_privateState.$firstPageVisible && _privateState.$lastPageVisible && _publicState.totalPages > CONSTANTS.VALUES.MAX_PAGES) {
                if (!isFirstPage && isFirstPageVisible) {
                    _privateState.$firstPageVisible = getPageElement(page - 1);
                    setPageHTML({
                        $element: _privateState.$firstPageVisible,
                        visible: true
                    });
                    setPageHTML({
                        $element: _privateState.$lastPageVisible
                    });
                    _privateState.$lastPageVisible = getPageElement(page + CONSTANTS.VALUES.MAX_PAGES - 2);
                }
                if (!isLastPage && isLastPageVisible) {
                    setPageHTML({
                        $element: _privateState.$firstPageVisible
                    });
                    _privateState.$firstPageVisible = getPageElement(page - CONSTANTS.VALUES.MAX_PAGES + 2);
                    _privateState.$lastPageVisible = getPageElement(page + 1);
                    setPageHTML({
                        $element: _privateState.$lastPageVisible,
                        visible: true
                    });
                }
            }
            _publicState.currentPage = page;
            _privateState.$currentPage = getPageElement(page);
            if (_privateState.$currentPage) {
                setPageHTML({
                    $element: _privateState.$currentPage,
                    visible: true,
                    current: true
                });
            }
            if (_privateState.$next) {
                setPageHTML({
                    $element: _privateState.$next,
                    visible: !isLastPage
                });
                if (isLastPage && _publicState.$srLive && clickPrevNext) {
                    if (pageElement) {
                        _privateState.$next.blur();
                        pageElement.focus();
                    }
                    coned.utils.triggerEvent(
                        _publicState.$srLive,
                        coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                        {
                            valueKey: CONSTANTS.VALUES.CHANGE_FOCUS,
                            formatValues: [_privateState.$next.getAttribute(coned.constants.ARIA.LABEL), pageFormat]
                        }
                    );
                }
            }
            if (_privateState.$prev) {
                setPageHTML({
                    $element: _privateState.$prev,
                    visible: !isFirstPage
                });
                if (isFirstPage && _publicState.$srLive && clickPrevNext) {
                    if (pageElement) {
                        _privateState.$prev.blur();
                        pageElement.focus();
                    }
                    coned.utils.triggerEvent(
                        _publicState.$srLive,
                        coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                        {
                            valueKey: CONSTANTS.VALUES.CHANGE_FOCUS,
                            formatValues: [_privateState.$prev.getAttribute(coned.constants.ARIA.LABEL), pageFormat]
                        }
                    );
                }
            }
            updatePageURL(page);
            coned.utils.triggerEvent(
                $container,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _publicState
            );
        }
        /**
         * Create a cta page element
         * @param {ParamsListAnchorPage} params 
         * @returns {HTMLElement}
         */
        var buildListAnchorPage = function (params) {
            var $listItem = document.createElement('li');
            var $cta = document.createElement('a');
            if (params.label) {
                $cta.setAttribute(
                    coned.constants.ARIA.LABEL,
                    params.label
                );
                if (params.label.includes(_privateState.textNext)) {
                    $cta.href = CONSTANTS.VALUES.HREF_NEXT
                }
                if (params.label.includes(_privateState.textPrevious)) {
                    $cta.href = CONSTANTS.VALUES.HREF_PREV
                }
            }
            if (params.classes) {
                params.classes.forEach(function (el) {
                    $cta.classList.add(el);
                });
            }
            if (params.innerHTML) {
                $cta.innerHTML = params.innerHTML;
            }
            if (params.page) {
                $cta.dataset.page = params.page
                $cta.href = params.page
            }
            $listItem.appendChild($cta);
            $listItem.setAttribute(
                coned.constants.ARIA.HIDDEN,
                coned.constants.TRUE
            );
            return $listItem;
        }
        /**
         * Create a nav of paginationm
         * @returns 
         */
        var buildNavBar = function () {
            var $navBar = document.createElement('nav'),
                $ul = document.createElement('ul'),
                $prev = buildListAnchorPage({
                    classes: [CONSTANTS.CLASSES.PAGINATION_CTA, CONSTANTS.CLASSES.PAGINATION_PREV],
                    innerHTML: CONSTANTS.VALUES.SPAN_CARROT,
                    label: _privateState.textPrevious + CONSTANTS.VALUES.SPACE + _privateState.textPage
                }),
                $next = buildListAnchorPage({
                    classes: [CONSTANTS.CLASSES.PAGINATION_CTA, CONSTANTS.CLASSES.PAGINATION_NEXT],
                    innerHTML: CONSTANTS.VALUES.SPAN_CARROT,
                    label: _privateState.textNext + CONSTANTS.VALUES.SPACE + _privateState.textPage
                });
            $ul.appendChild($prev);
            $ul.setAttribute(CONSTANTS.VALUES.ROLE, CONSTANTS.VALUES.PRESENTATION);
            for (var index = 1; index <= _publicState.totalPages; index++) {
                var $ctaPage = buildListAnchorPage({
                    classes: [CONSTANTS.CLASSES.PAGINATION_CTA],
                    innerHTML: CONSTANTS.VALUES.SPAN_PAGE + index,
                    page: CONSTANTS.VALUES.EMPTY + index,
                    label: index === 1
                        ? _privateState.textFirst + CONSTANTS.VALUES.SPACE + _privateState.textPage + CONSTANTS.VALUES.SPACE + index
                        : _privateState.textPage + CONSTANTS.VALUES.SPACE + index
                });
                if (index === _publicState.totalPages) {
                    $ctaPage = buildListAnchorPage({
                        classes: [CONSTANTS.CLASSES.PAGINATION_CTA],
                        innerHTML: CONSTANTS.VALUES.SPAN_PAGE + index,
                        page: CONSTANTS.VALUES.EMPTY + index,
                        label: _privateState.textLast + CONSTANTS.VALUES.SPACE + _privateState.textPage + CONSTANTS.VALUES.SPACE + index
                    });
                }
                $ul.appendChild($ctaPage)
            }
            $ul.appendChild($next);
            $navBar.setAttribute(
                coned.constants.ARIA.LABEL,
                _privateState.textNavigation
            );
            $navBar.appendChild($ul);
            return $navBar;
        }

        /**
         * Destroy pagination html
         */
        var destroyPagination = function () {
            _privateState = {
                textCurrent: $container.dataset.textCurrent
                    ? $container.dataset.textCurrent
                    : CONSTANTS.DATA_TEXT.CURRENT,
                textFirst: $container.dataset.textFirst
                    ? $container.dataset.textFirst
                    : CONSTANTS.DATA_TEXT.FIRST,
                textLast: $container.dataset.textLast
                    ? $container.dataset.textLast
                    : CONSTANTS.DATA_TEXT.LAST,
                textPage: $container.dataset.textPage
                    ? $container.dataset.textPage
                    : CONSTANTS.DATA_TEXT.PAGE,
                textNext: $container.dataset.textNext
                    ? $container.dataset.textNext
                    : CONSTANTS.DATA_TEXT.NEXT,
                textPrevious: $container.dataset.textPrevious
                    ? $container.dataset.textPrevious
                    : CONSTANTS.DATA_TEXT.PREVIOUS,
                textNavigation: $container.dataset.textNavigation
                    ? $container.dataset.textNavigation
                    : CONSTANTS.DATA_TEXT.NAVIGATION
            };
            $container.innerHTML = '';
            updatePageURL(1);
        }

        /**
         * Create all pagination elements and events
         */
        var buildPagination = function () {
            destroyPagination();
            if (_publicState.totalPages < 2) {
                return;
            }
            $container.appendChild(buildNavBar());
            _privateState.$pages = coned.utils.arrayFrom(
                $container.getElementsByClassName(
                    CONSTANTS.CLASSES.PAGINATION_CTA
                ));
            _privateState.$prev = $container.getElementsByClassName(
                CONSTANTS.CLASSES.PAGINATION_PREV
            )[0];
            _privateState.$next = $container.getElementsByClassName(
                CONSTANTS.CLASSES.PAGINATION_NEXT
            )[0];
            _privateState.$firstPageVisible = getPageElement(1);
            _privateState.$lastPageVisible = getPageElement(Math.min(CONSTANTS.VALUES.MAX_PAGES, _publicState.totalPages));
            _privateState.$firstPage = getPageElement(1);
            _privateState.$lastPage = getPageElement(_publicState.totalItems);
            if (_privateState.$firstPageVisible) {
                _privateState.$firstPageVisible.setAttribute(
                    coned.constants.ARIA.LABEL,
                    _privateState.textCurrent + CONSTANTS.VALUES.EMPTY +
                    _privateState.$firstPageVisible.getAttribute(coned.constants.ARIA.LABEL)
                );
                _privateState.$firstPage.classList.add(CONSTANTS.CLASSES.PAGINATION_ACTIVE);
            }
            initializePaginationEvents();
        }
        /**
         * modify html to show pages
         */
        var showInitialPages = function () {
            if (typeof _publicState.totalPages !== 'undefined' &&
                _publicState.totalPages > 1
            ) {
                var pagesToShow = Math.min(_publicState.totalPages, CONSTANTS.VALUES.MAX_PAGES);
                for (var i = 1; i <= pagesToShow; i++) {
                    setPageHTML({
                        $element: getPageElement(i),
                        visible: true
                    });
                }
                if (_publicState.currentPage) {
                    changePage(_publicState.currentPage);
                }
            } else {
                coned.utils.triggerEvent(
                    $container,
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    _publicState
                );
            }
        }


        /**
         * Change states from external modules
         * @param {PublicState} state 
         * @param {boolean} [isExternal]
         */
        var setPublicState = function (state, isExternal) {
            if ((typeof state.totalItems === 'number' && state.totalItems > 0) &&
                (typeof state.itemsPerPage === 'number' && state.itemsPerPage > 0)
            ) {
                _publicState.isSeted = state.isSeted ? state.isSeted : false;
                _publicState.totalItems = state.totalItems;
                _publicState.itemsPerPage = state.itemsPerPage;
                _publicState.totalPages = (state.totalItems && state.itemsPerPage)
                    ? Math.ceil(state.totalItems / state.itemsPerPage)
                    : undefined;
                _publicState.currentPage = isExternal ? state.currentPage : getPageUrl(state.currentPage);
            }
            else {
                _publicState.isSeted = state.isSeted ? state.isSeted : false;
                _publicState.totalItems = 0;
                _publicState.currentPage = 0;
                _publicState.totalPages = 0;
            }
            buildPagination();
            showInitialPages();
        }
        /**
         * handle prev cta click
         * @param {MouseEvent} event 
         */
        var handlePrevClick = function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
            _publicState.canScroll = true;
            changePage(_publicState.currentPage - 1, true);
        }
        /**
         *  Handle next cta click
         * @param {MouseEvent} event 
         */
        var handleNextClick = function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
            _publicState.canScroll = true;
            changePage(_publicState.currentPage + 1, true);
        }
        /**
         * Handle cta page click
         * @param {MouseEvent} event 
         */
        var handlePageClick = function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
            var $element = event.currentTarget
            if ($element instanceof HTMLElement && $element.dataset.page) {
                _publicState.canScroll = true;
                changePage(Number($element.dataset.page));
            }
        }

        /**
         * Handle custom event set state detail
         * @param {CustomEvent<PublicState>} event 
         */
        var handleSetState = function (event) {
            if (!_publicState.isSeted) {
                setPublicState(event.detail);
            } else {
                setPublicState(event.detail, true);
            }
        }

        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            _privateState = {
                textCurrent: $container.dataset.textCurrent
                    ? $container.dataset.textCurrent
                    : CONSTANTS.DATA_TEXT.CURRENT,
                textFirst: $container.dataset.textFirst
                    ? $container.dataset.textFirst
                    : CONSTANTS.DATA_TEXT.FIRST,
                textLast: $container.dataset.textLast
                    ? $container.dataset.textLast
                    : CONSTANTS.DATA_TEXT.LAST,
                textPage: $container.dataset.textPage
                    ? $container.dataset.textPage
                    : CONSTANTS.DATA_TEXT.PAGE,
                textNext: $container.dataset.textNext
                    ? $container.dataset.textNext
                    : CONSTANTS.DATA_TEXT.NEXT,
                textPrevious: $container.dataset.textPrevious
                    ? $container.dataset.textPrevious
                    : CONSTANTS.DATA_TEXT.PREVIOUS,
                textNavigation: $container.dataset.textNavigation
                    ? $container.dataset.textNavigation
                    : CONSTANTS.DATA_TEXT.NAVIGATION
            };
            _publicState = {
                isSeted: false,
                canScroll: false,
                itemsPerPage: $container.dataset.itemsPerPage ? parseInt($container.dataset.itemsPerPage) : 5
            };
        }

        /**
         * Initialize the events in the module
         */
        var initializeEvents = function () {
            $container.addEventListener(
                coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                handleSetState
            );
        }
        /**
         * Initialize the events after build new pagination
         */
        var initializePaginationEvents = function () {
            if (_privateState.$prev) {
                _privateState.$prev.addEventListener('click', handlePrevClick);
            }
            if (_privateState.$next) {
                _privateState.$next.addEventListener('click', handleNextClick);
            }
            if (Array.isArray(_privateState.$pages)) {
                _privateState.$pages.forEach(function ($el) {
                    $el.addEventListener('click', handlePageClick);
                });
            }
        }

        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
            coned.utils.triggerEvent(
                $container,
                coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                _publicState
            );
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    PaginationSimple.prototype.isLoaded = function () {
        return isLoaded;
    }
    return PaginationSimple;
})();
