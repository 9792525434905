// ==================== ALERTS COMPONENT =========================
/* global gsap */
/* global dataLayer */
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AlertModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ALERT_COOKIE_VARIABLE_NAME: 'alertId',
        ALERT_CLOSE_BUTTON: 'js-close-alert',
        ALERT_CLOSED_EVENT: 'alert-closed',
        ALERT_IN_PAGE_CUSTOMER_MESSAGING: 'js-in-page-customer-messaging',
        IN_PAGE_CUSTOMER_MESSAGING_DESCRIPTION: 'js-in-page-customer-messaging-description',
        IN_PAGE_CUSTOMER_MESSAGING_COOKIE_VARIABLE_NAME: 'inPageCustomerMessagingId',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AlertModule = function ($alertElement) {
        /**
         * PRIVATE METHODS
         */
        var $closeBtn, 
            $headerWrapper, 
            $pageContent, 
            $modalWrapper, 
            $inPageCustomerMessagingDescription,
            _cookieValue, 
            _variableName,
            _isInPageCustomerMessaging,
            _hasModalSelector;

        var tagInPageCustomerMessaging = function () {
            var $descriptionElements = $inPageCustomerMessagingDescription.children,
                message = '';
                
            _.each($descriptionElements, function ($descriptionElement){
                message += ' ' + $descriptionElement.textContent.trim();
            });

            dataLayer.push({
                customerMessagingText: message
            })
        };

        var closeClickEvent = function (event) {
            event.preventDefault();

            if ($alertElement.dataset.persistent == 'non-persistent') {
                var expiryDate = $alertElement.dataset.expiry;
                query.setCookie(_variableName, _cookieValue, expiryDate);
            }

            closeAlert();
            _isInPageCustomerMessaging && tagInPageCustomerMessaging();
        };

        var closeAlert = function () {
            gsap.to($alertElement, {
                duration: 0.2,
                opacity: '0',
                height: '0',
                onComplete: deleteAlert
            });
        };

        var showAlert = function () {
            query.removeClass($alertElement, CONSTANTS.HIDDEN_CLASS);
        };

        var deleteAlert = function () {
            $alertElement.remove();
            
            if (!_isInPageCustomerMessaging) {
                resetPagePadding();
                coned.utils.triggerEvent(document, CONSTANTS.ALERT_CLOSED_EVENT);
            }
        };

        var resetPagePadding = function () {
            var headerHeight = parseInt(
                    window.getComputedStyle($headerWrapper, null).getPropertyValue('height'),
                    10
                ),
                secondaryModalHeight ;

            if (_hasModalSelector) {
                secondaryModalHeight = parseInt(
                    window.getComputedStyle($modalWrapper, null).getPropertyValue('height'),
                    10
                );

                // Verify if a secondary nav modal is opened.
                // If so, don't take it into account for the padding setting
                if (secondaryModalHeight > 0) {
                    headerHeight -= secondaryModalHeight;
                }
            }

            gsap.to($pageContent, {
                duration: 0.2,
                paddingTop: headerHeight
            });
        };

        var initializeData = function () {
            $closeBtn = $alertElement.getElementsByClassName(CONSTANTS.ALERT_CLOSE_BUTTON)[0];
            $headerWrapper = document.getElementsByClassName(
                coned.constants.HEADER_WRAPPER_CLASS
            )[0];
            $pageContent = document.getElementsByClassName(coned.constants.PAGE_CONTENT_CLASS)[0];
            $modalWrapper = document.getElementsByClassName(
                coned.constants.MODAL_SELECTOR_CLASS
            )[0];
            $inPageCustomerMessagingDescription = $alertElement.getElementsByClassName(
                CONSTANTS.IN_PAGE_CUSTOMER_MESSAGING_DESCRIPTION
            )[0];
            _cookieValue = $alertElement.dataset.alertId;
            _isInPageCustomerMessaging = query.hasClass($alertElement, CONSTANTS.ALERT_IN_PAGE_CUSTOMER_MESSAGING);
            _variableName = _isInPageCustomerMessaging ?
                CONSTANTS.IN_PAGE_CUSTOMER_MESSAGING_COOKIE_VARIABLE_NAME  + _cookieValue :
                CONSTANTS.ALERT_COOKIE_VARIABLE_NAME + _cookieValue; // To assure cookie uniqueness
            _hasModalSelector = $modalWrapper;
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($closeBtn, closeClickEvent);

            if (_isInPageCustomerMessaging) {
                // If cookie doesn't exist for the alert, show it
                if (query.getCookie(_variableName) === null) {
                    showAlert();
                    tagInPageCustomerMessaging();
                }
            } else {
                // If cookie exists for the alert, close it
                // (will probably only occur in PL, since the alert won't be rendered if the BE checks the cookie)
                if (query.getCookie(_variableName) != null) {
                    closeAlert();
                }
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AlertModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AlertModule;
})();
