// ==================== SERVICE MANAGEMENT COMPONENT =========================
/* global _ */
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ServiceManagement = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Header
        HEADER: 'js-header-wrapper',

        // Step Constants
        STEP_CONTAINER: 'js-service-management-steps-container',
        STEP_SELECTOR: 'js-service-management-step',
        STEP_NEXT_BUTTON: 'js-step-next-button',
        STEP_FINAL_BUTTON: 'js-final-step-button',
        STEP_HAS_INPUT: 'js-step-has-input-button',
        STEP_PARAGRAPH: "js-service-management-step-paragraph",

        // Breadcrumb Constants
        BREADCRUMBS_CONTAINER: 'js-service-management-breadcrumbs-container',
        STEP_BREADCRUMB: 'js-service-management-breadcrumb',
        STEP_BREADCRUMB_TEXT: 'js-service-management-breadcrumb-text',
        BREADCRUMBS_EDIT_BUTTONS: 'service-management__breadcrumb-expand',

        // Progress Bar Constants
        DISABLED_PROGRESS_BAR_ITEM_CLASS: 'form-progress-bar__item-disabled',
        PROGRESS_BAR_STEP: 'js-get-started-breadcrumb',
        PROGRESS_BAR_SELECTOR: 'js-form-progress-bar',

        // General Constants
        INPUT_FILLED_CLASS: 'coned-input--filled',
        NEXT_STEP_LISTENER: 'trigger-next-step',
        INACTIVE_CLASS: 'inactive',
        HIDDEN_CLASS: 'hidden',
        VALID_CLASS: 'valid',

        // Tagging
        TAG_PREFIX: 'tag-',

        // Voice Reading / Accessibility
        BORDER_FOCUS_SIZE: 4,
        TAB_INDEX: 'tabindex',
        STEPS_LIST: 'js-steps-list',
        ARIA_LABEL_ATTRIBUTE: 'aria-label',
        USING_MOUSE_CLASS: 'service-management__using-mouse',

        ENTER_KEY: 13
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ServiceManagement = function ($serviceManagement) {
        /**
         * PRIVATE METHODS
         */
        var // Header
            $header,
            // Breadcrumb Variables
            $breadcrumbsContainer,
            $editButtonsBreadcrumbs,
            $breadcrumbs,
            // Step Variables
            $steps,
            $stepsContainer,
            $paragraphs,
            $currentStep,
            $stepNextButtons,
            // Progress Bar Variables
            $progressBar;

        /**
         * Handles the click of each of the next step buttons.
         */
        var handleNextStepButton = function (event) {
            var $target = event.currentTarget;

            if ($target.disabled || $target.wasDragged) {
                return false;
            }

            var $currentStepNextButtons = $currentStep.getElementsByClassName(
                    CONSTANTS.STEP_NEXT_BUTTON
                ),
                $currentStepBreadcrumb = $breadcrumbsContainer.querySelector(
                    '.' +
                        CONSTANTS.STEP_BREADCRUMB +
                        '[data-step-id="' +
                        $currentStep.dataset.stepId +
                        '"]'
                ),
                $currentStepBreadcrumbButton = $currentStepBreadcrumb.querySelector(
                    '.' + CONSTANTS.BREADCRUMBS_EDIT_BUTTONS
                ),
                $currentStepBreadcrumbText = $currentStepBreadcrumb.getElementsByClassName(
                    CONSTANTS.STEP_BREADCRUMB_TEXT
                )[0],
                $nextStep = $stepsContainer.querySelector(
                    '.' +
                        CONSTANTS.STEP_SELECTOR +
                        '[data-step-id="' +
                        $target.dataset.nextStep +
                        '"]'
                ),
                $nextStepInputs = $nextStep.querySelectorAll('input[type="text"]'),
                $nextStepNextButtons = $nextStep.getElementsByClassName(CONSTANTS.STEP_NEXT_BUTTON),
                $firstProgressBarItem = document.getElementsByClassName(
                    CONSTANTS.PROGRESS_BAR_STEP
                )[0];

            $currentStepBreadcrumbText.innerHTML = $target.dataset.breadcrumbCopy;
            $currentStepBreadcrumbButton.setAttribute(
                CONSTANTS.ARIA_LABEL_ATTRIBUTE,
                $target.dataset.breadcrumbAssistiveCopy
            );

            // If breadcrumb has variables that need to be added from inputs, loop all of them.
            if (query.hasClass($target, CONSTANTS.STEP_HAS_INPUT)) {
                var $inputs = $currentStep.querySelectorAll('input[type="text"]');

                _.each($inputs, function ($input) {
                    $currentStepBreadcrumbText.innerHTML = $currentStepBreadcrumbText.innerHTML
                        .split('@' + $input.name)
                        .join($input.value);
                    $currentStepBreadcrumbButton.setAttribute(
                        CONSTANTS.ARIA_LABEL_ATTRIBUTE,
                        $target.dataset.breadcrumbAssistiveCopy
                            .split('@' + $input.name)
                            .join($input.value)
                    );

                    $input.value = '';
                    $input.classList.remove(CONSTANTS.VALID_CLASS);
                    $input.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                });
            }

            // If next step has inputs, clean the form
            if ($nextStepInputs && $nextStepInputs.length) {
                var $nextStepForm = $nextStep.querySelector('form');
                _.each($nextStepInputs, function ($input) {
                    ($input.value = ''), $input.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                });

                $($nextStepForm).validate().resetForm();

                // Disable form buttons when inputs are present
                if ($nextStepNextButtons && $nextStepNextButtons.length) {
                    _.each($nextStepNextButtons, function ($button) {
                        $button.disabled = true;
                    });
                }
            }

            // Disable buttons to avoid double-clicking error
            if ($currentStepNextButtons && $currentStepNextButtons.length) {
                _.each($currentStepNextButtons, function ($button) {
                    $button.disabled = true;
                });
            }

            // Tagging
            if (query.hasClass($currentStepBreadcrumb, CONSTANTS.TAG_PREFIX, true)) {
                var tagClasses = query.getClass($currentStepBreadcrumb, CONSTANTS.TAG_PREFIX);

                _.each(tagClasses, function (tagClass) {
                    $currentStepBreadcrumb.className = $currentStepBreadcrumb.className.replace(
                        tagClass,
                        ''
                    );
                });
            }

            $currentStepBreadcrumb.classList.add($target.dataset.breadcrumbTag);

            // Switch Steps.
            $currentStep.classList.add(CONSTANTS.INACTIVE_CLASS);
            $nextStep.classList.remove(CONSTANTS.INACTIVE_CLASS);
            $currentStepBreadcrumb.classList.remove(CONSTANTS.INACTIVE_CLASS);

            // Enable next step buttons after a short delay
            if ($nextStepNextButtons && $nextStepNextButtons.length) {
                _.each($nextStepNextButtons, function ($button) {
                    if (!query.hasClass($button, CONSTANTS.STEP_HAS_INPUT)) {
                        setTimeout(function () {
                            $button.disabled = false;
                        }, 500);
                    }
                });
            }

            $currentStep = $nextStep;

            if ($currentStep != $steps[0]) {
                query.removeClass(
                    $firstProgressBarItem,
                    CONSTANTS.DISABLED_PROGRESS_BAR_ITEM_CLASS
                );
            }

            if (coned.utils.isMobile()) {
                coned.utils.scrollTo(
                    $stepsContainer.offsetTop +
                        $breadcrumbsContainer.offsetHeight -
                        $header.offsetHeight,
                    500
                );
            }
        };

        /**
         * Handles the event of the next step button with input
         */
        var handleNextStepInputButton = function (event) {
            var $target = event.currentTarget;

            if ($target.disabled || $target.wasDragged) {
                return false;
            }

            var $currentStepNextButtons = $currentStep.getElementsByClassName(
                    CONSTANTS.STEP_NEXT_BUTTON
                ),
                $currentStepBreadcrumb = $breadcrumbsContainer.querySelector(
                    '.' +
                        CONSTANTS.STEP_BREADCRUMB +
                        '[data-step-id="' +
                        $currentStep.dataset.stepId +
                        '"]'
                ),
                $currentStepBreadcrumbButton = $currentStepBreadcrumb.querySelector(
                    '.' + CONSTANTS.BREADCRUMBS_EDIT_BUTTONS
                ),
                $currentStepBreadcrumbText = $currentStepBreadcrumb.getElementsByClassName(
                    CONSTANTS.STEP_BREADCRUMB_TEXT
                )[0],
                $nextStep = $stepsContainer.querySelector(
                    '.' +
                        CONSTANTS.STEP_SELECTOR +
                        '[data-step-id="' +
                        event.details.nextStep +
                        '"]'
                ),
                $nextStepInputs = $nextStep.querySelectorAll('input[type="text"]'),
                $nextStepNextButtons = $nextStep.getElementsByClassName(CONSTANTS.STEP_NEXT_BUTTON),
                $buttonTarget = event.srcElement;

            $currentStepBreadcrumbText.innerHTML = event.details.breadcrumbCopy;
            $currentStepBreadcrumbButton.setAttribute(
                CONSTANTS.ARIA_LABEL_ATTRIBUTE,
                $buttonTarget.dataset.breadcrumbAssistiveCopy
            );

            // If breadcrumb has variables that need to be added from inputs, loop all of them.
            if (event.details.inputs) {
                _.each(event.details.inputs, function (input) {
                    $currentStepBreadcrumbText.innerHTML = $currentStepBreadcrumbText.innerHTML
                        .split('@' + input.name)
                        .join(input.value);
                    $currentStepBreadcrumbButton.setAttribute(
                        CONSTANTS.ARIA_LABEL_ATTRIBUTE,
                        $buttonTarget.dataset.breadcrumbAssistiveCopy
                            .split('@' + input.name)
                            .join(input.value)
                    );
                });
            }

            // If next step has inputs, clean the form
            if ($nextStepInputs && $nextStepInputs.length) {
                var $nextStepForm = $nextStep.querySelector('form');
                _.each($nextStepInputs, function ($input) {
                    ($input.value = ''), $input.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                });

                $($nextStepForm).validate().resetForm();
            }

            // If step needs to add query parameters to the final step's URL, loop all of them.
            if (event.details.changeHref) {
                var $stepIterator = $stepsContainer.querySelector(
                        '.' +
                            CONSTANTS.STEP_SELECTOR +
                            '[data-step-id="' +
                            event.details.nextStep +
                            '"]'
                    ),
                    $finalStepButton = $stepIterator.getElementsByClassName(
                        CONSTANTS.STEP_FINAL_BUTTON
                    );

                // Find the last step of the tree
                while (!$finalStepButton.length) {
                    var $currentNextStepButton = $stepIterator.getElementsByClassName(
                        CONSTANTS.STEP_NEXT_BUTTON
                    )[0];

                    $stepIterator = $stepsContainer.querySelector(
                        '.' +
                            CONSTANTS.STEP_SELECTOR +
                            '[data-step-id="' +
                            $currentNextStepButton.dataset.nextStep +
                            '"]'
                    );
                    $finalStepButton = $stepIterator.getElementsByClassName(
                        CONSTANTS.STEP_FINAL_BUTTON
                    );
                }

                // Add all of the parameters/values to the URL
                _.each(event.details.changeHref, function (param) {
                    $finalStepButton[0].href = coned.utils.updateUrlParameter(
                        param.name,
                        param.value,
                        $finalStepButton[0].href
                    );
                });
            }

            // Disable buttons to avoid double-clicking error
            if ($currentStepNextButtons && $currentStepNextButtons.length) {
                _.each($currentStepNextButtons, function ($button) {
                    $button.disabled = true;
                });
            }

            // Tagging
            if (query.hasClass($currentStepBreadcrumb, CONSTANTS.TAG_PREFIX, true)) {
                var tagClasses = query.getClass($currentStepBreadcrumb, CONSTANTS.TAG_PREFIX);

                _.each(tagClasses, function (tagClass) {
                    $currentStepBreadcrumb.className = $currentStepBreadcrumb.className.replace(
                        tagClass,
                        ''
                    );
                });
            }

            $currentStepBreadcrumb.classList.add(event.target.dataset.breadcrumbTag);

            // Switch Steps.
            $currentStep.classList.add(CONSTANTS.INACTIVE_CLASS);
            $nextStep.classList.remove(CONSTANTS.INACTIVE_CLASS);
            $currentStepBreadcrumb.classList.remove(CONSTANTS.INACTIVE_CLASS);

            // Enable next step buttons after a short delay
            if ($nextStepNextButtons && $nextStepNextButtons.length) {
                _.each($nextStepNextButtons, function ($button) {
                    if (!query.hasClass($button, CONSTANTS.STEP_HAS_INPUT)) {
                        setTimeout(function () {
                            $button.disabled = false;
                        }, 500);
                    }
                });
            }

            $currentStep = $nextStep;

            if (coned.utils.isMobile()) {
                coned.utils.scrollTo(
                    $stepsContainer.offsetTop +
                        $breadcrumbsContainer.offsetHeight -
                        $header.offsetHeight,
                    500
                );
            }
        };

        var initClamp = function () {
            for (var index = 0; index < $paragraphs.length; index++) {
                if ($paragraphs[index] != null) {
                    coned.utils.lineClamp($paragraphs[index]);  
                }
            }
        };

        /**
         * Handles the click of the breadcrumb
         */
        var handleBreadcrumbClick = function (event) {
            var $target = event.currentTarget,
                $targetContainer = $target.parentNode;

            if ($target.wasDragged) {
                return false;
            }

            var $currentStepNextButtons = $currentStep.getElementsByClassName(
                    CONSTANTS.STEP_NEXT_BUTTON
                ),
                $targetStep = $stepsContainer.querySelector(
                    '.' +
                        CONSTANTS.STEP_SELECTOR +
                        '[data-step-id="' +
                        $targetContainer.dataset.stepId +
                        '"]'
                ),
                $targetStepNextButtons = $targetStep.getElementsByClassName(
                    CONSTANTS.STEP_NEXT_BUTTON
                ),
                $firstProgressBarItem = document.getElementsByClassName(
                    CONSTANTS.PROGRESS_BAR_STEP
                )[0];

            // Hide all breadcrumbs in cascade.
            for (
                var n = query.indexOf($breadcrumbs, $targetContainer);
                n < $breadcrumbs.length;
                n++
            ) {
                $breadcrumbs[n].classList.add(CONSTANTS.INACTIVE_CLASS);
            }

            // Disable buttons to avoid double-clicking error
            if ($currentStepNextButtons && $currentStepNextButtons.length) {
                _.each($currentStepNextButtons, function ($button) {
                    $button.disabled = true;
                });
            }

            // Switch steps.
            $currentStep.classList.add(CONSTANTS.INACTIVE_CLASS);
            $targetStep.classList.remove(CONSTANTS.INACTIVE_CLASS);

            // Enable next step buttons after a short delay
            if ($targetStepNextButtons && $targetStepNextButtons.length) {
                _.each($targetStepNextButtons, function ($button) {
                    setTimeout(function () {
                        $button.disabled = false;
                    }, 500);
                });
            }

            $currentStep = $targetStep;

            if ($currentStep == $steps[0]) {
                query.addClass($firstProgressBarItem, CONSTANTS.DISABLED_PROGRESS_BAR_ITEM_CLASS);
            }
        };

        /**
         * Inits data in the module.
         */
        var initializeData = function () {
            // Header
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];

            // Breadcrumb
            $breadcrumbs = $serviceManagement.getElementsByClassName(CONSTANTS.STEP_BREADCRUMB);
            $editButtonsBreadcrumbs = $serviceManagement.querySelectorAll(
                '.' + CONSTANTS.BREADCRUMBS_EDIT_BUTTONS
            );
            $breadcrumbsContainer = $serviceManagement.getElementsByClassName(
                CONSTANTS.BREADCRUMBS_CONTAINER
            )[0];

            // Step
            $steps = $serviceManagement.getElementsByClassName(CONSTANTS.STEP_SELECTOR);
            $paragraphs = $serviceManagement.getElementsByClassName(CONSTANTS.STEP_PARAGRAPH);
            $stepsContainer = $serviceManagement.getElementsByClassName(
                CONSTANTS.STEP_CONTAINER
            )[0];
            $currentStep = $serviceManagement.getElementsByClassName(CONSTANTS.STEP_SELECTOR)[0];
            $stepNextButtons = $serviceManagement.getElementsByClassName(
                CONSTANTS.STEP_NEXT_BUTTON
            );

            // Progress Bar
            $progressBar = document.getElementsByClassName(CONSTANTS.PROGRESS_BAR_SELECTOR)[0];
        };

        /**
         * Inits animations in the module.
         */
        var initializeAnimations = function () {
            setTimeout(function () {
                _.each($stepNextButtons, function ($button) {
                    $button.disabled = false;
                });
            }, 300);
        };

        /**
         * Inits events in the module.
         */
        var initializeEvents = function () {
            initClamp();
            window.addEventListener('resize', initClamp);

            _.each($stepNextButtons, function ($button) {
                if (query.hasClass($button, CONSTANTS.STEP_HAS_INPUT)) {
                    $button.addEventListener(
                        CONSTANTS.NEXT_STEP_LISTENER,
                        handleNextStepInputButton
                    );
                } else {
                    coned.utils.addGeneralListeners($button, function (event) {
                        event.stopPropagation();
                        event.preventDefault();
                        handleNextStepButton(event);
                    });
                }
            });

            // Edit Button on click
            _.each($editButtonsBreadcrumbs, function ($editButton) {
                coned.utils.addGeneralListeners($editButton, handleBreadcrumbClick);
            });

            // Adding touchstart and touchmove events to buttons to avoid firing click on drag
            _.each($editButtonsBreadcrumbs, function ($button) {
                $button.addEventListener('touchstart', function (event) {
                    var $target = event.currentTarget;

                    $target.wasDragged = false;
                }, coned.supportsPassive ? { passive: true } : false);

                $button.addEventListener('touchmove', function (event) {
                    var $target = event.currentTarget;

                    $target.wasDragged = true;
                }, coned.supportsPassive ? { passive: true } : false);
            });

            _.each($stepNextButtons, function ($button) {
                $button.addEventListener('touchstart', function (event) {
                    var $target = event.currentTarget;

                    $target.wasDragged = false;
                }, coned.supportsPassive ? { passive: true } : false);

                $button.addEventListener('touchmove', function (event) {
                    var $target = event.currentTarget;

                    $target.wasDragged = true;
                }, coned.supportsPassive ? { passive: true } : false);
            });

            $serviceManagement.addEventListener('mousedown', function () {
                query.addClass($serviceManagement, CONSTANTS.USING_MOUSE_CLASS);
                query.addClass($progressBar, CONSTANTS.USING_MOUSE_CLASS);
            });

            $serviceManagement.addEventListener('keydown', function () {
                query.removeClass($serviceManagement, CONSTANTS.USING_MOUSE_CLASS);
                query.removeClass($progressBar, CONSTANTS.USING_MOUSE_CLASS);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeAnimations();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ServiceManagement.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ServiceManagement;
})();
