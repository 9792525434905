// ==================== SESSION TIMEOUT COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SessionTimeOut = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SESSION_TIME_OUT_ALERT: 'js-timeout-alert',
        SESSION_TIME_OUT_EXPIRED: 'js-timeout-expired',
        LOG_OUT: 'js-session-logout',
        STAY_IN: 'js-stay-logged-in',
        TIMER: 'js-timer',
        CLOSE_BUTTON: 'js-close-button',
        HIDDEN_CLASS: 'hidden',
        TWO_MINUTES: 120,
        SIXTY_SECONDS: '60000',
        THIRTEEN_MINUTES: '780000'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var SessionTimeOut = function ($sessionTimeOut) {
        /**
         * PRIVATE METHODS
         */
        var _serviceUrl,
            _sessionTimeOutInterval,
            _startSessionTimeOut,
            _setKeppAlive,
            $sessionTimeOutAlert,
            $sessionTimeOutExpired,
            $logOutButton,
            $stayInButton,
            $timer,
            $closeButton;

        /**
         * Starts the timeout timer (13 min) and call the get auth token service to get the remaining time
         * @param {Object} remainigSeconds   The initial data are 13 minutes / 780000 seconds
         */
        var startSessionTimeOut = function (remainigSeconds) {
            clearTimeout(_startSessionTimeOut);
            _startSessionTimeOut = setTimeout(function () {
                // Service Call
                query.getData(_serviceUrl, successGetAuthToken, function () {});
            }, remainigSeconds);
        };

        /**
         * Calls the function to starts the 2 min countdown
         * @param {Object} data
         */
        var successGetAuthToken = function (data) {
            if (coned.utils.isPatternLab()) {
                var getAuthTokenJson = coned.plConstants.GET_SESSION_TIMEOUT;

                query.getData(getAuthTokenJson, sessionTimeoutRemainSeconds, function () {});
            } else {
                sessionTimeoutRemainSeconds(data);
            }
        };

        /**
         * Checks if the remaining time from the server is equal to 0 to starts the countdown
         * @param {Object} data
         */
        var sessionTimeoutRemainSeconds = function (data) {
            var remainigSeconds = parseInt(data.RemainingSeconds);

            if (remainigSeconds <= CONSTANTS.TWO_MINUTES) {
                //show the session time pout pop up
                $sessionTimeOut.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $logOutButton.focus();

                //starts the two minutes timer
                startTimer(remainigSeconds, $timer);
            } else {
                remainigSeconds = (remainigSeconds - CONSTANTS.TWO_MINUTES) * 1000;
                startSessionTimeOut(remainigSeconds);
            }
        };

        /**
         * Closes client's session and shows expired message.
         */
        var closeSessionOnExpiredTime = function () {
            clearInterval(_sessionTimeOutInterval);
            showSessionTimeoutExpiredMessage();
        };

        /**
         * Receives the remaining seconds from the server and updates the client's timer.
         * @param {Object} data
         */
        var updateTimerAfterInactiveTab = function (data) {
            clearInterval(_sessionTimeOutInterval);
            sessionTimeoutRemainSeconds(data);
        };

        /**
         * Hides the session timeout popup and reset the time values to the default one
         */
        var hideSessionTimeOut = function () {
            clearInterval(_sessionTimeOutInterval);
            $sessionTimeOut.classList.add(CONSTANTS.HIDDEN_CLASS);
            $timer.textContent = '02:00';
        };

        /**
         * Calls the logout function
         */
        var logoutEvent = function () {
            event.stopPropagation();
            event.preventDefault();

            hideSessionTimeOut();

            // log out the user
            coned.utils.logout($logOutButton.dataset.logoutUrl, $logOutButton.href);
        };

        /**
         * Closes the session timeout popup
         */
        var closeSessionTimeoutPopup = function () {
            hideSessionTimeOut();
            location.reload();
        };

        /**
         * Restarts the remaining minutes to 13 and hide the popup
         */
        var stayLoggedInEvent = function () {
            query.postData(_setKeppAlive, successStayLoggedInEvent, errorStayLoggedInEvent);
        };

        /**
         * Hide the popup and start the internal timer again with 13 min
         */
        var successStayLoggedInEvent = function () {
            hideSessionTimeOut();

            // The time starts running again with the 13 seconds
            startSessionTimeOut(CONSTANTS.THIRTEEN_MINUTES); //10000);
        };

        /**
         * In case the server call fail, it would try it again after 60 secs
         */
        var errorStayLoggedInEvent = function () {
            setTimeout(function () {
                stayLoggedInEvent();
            }, CONSTANTS.SIXTY_SECONDS);
        };

        /**
         * Starts the 2 min popup countdowm
         * @param {int} duration
         * @param {int} timer
         */
        var startTimer = function (duration, timer) {
            var time = duration,
                minutesSeconds,
                minutes,
                seconds;

            minutesSeconds = convertToMinutesSeconds(time);
            minutes = minutesSeconds.minutes;
            seconds = minutesSeconds.seconds;
            timer.textContent = minutes + ':' + seconds;
            time--;

            //show the session time pout pop up
            $sessionTimeOut.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $logOutButton.focus();

            _sessionTimeOutInterval = setInterval(function () {
                minutesSeconds = convertToMinutesSeconds(time);
                minutes = minutesSeconds.minutes;
                seconds = minutesSeconds.seconds;
                timer.textContent = minutes + ':' + seconds;

                if (time-- < 0) {
                    //reset the timer data
                    time = duration;
                    clearInterval(_sessionTimeOutInterval);
                    showSessionTimeoutExpiredMessage();
                }
            }, 1000);
        };

        /**
         * Turn the miliseconds into minutes and seconds
         * @param {String} time
         */
        var convertToMinutesSeconds = function (time) {
            var minutes = parseInt(time / 60, 10),
                seconds = parseInt(time % 60, 10);

            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            return {
                minutes: minutes,
                seconds: seconds
            };
        };

        /**
         * Hides the session timeout alert message and show the expired message
         */
        var showSessionTimeoutExpiredMessage = function () {
            $sessionTimeOutAlert.classList.add(CONSTANTS.HIDDEN_CLASS);
            $sessionTimeOutExpired.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $closeButton.focus();
        };

        var initializeData = function () {
            _serviceUrl = $sessionTimeOut.dataset.getAuthToken;
            _setKeppAlive = $sessionTimeOut.dataset.setKeepAlive;
            $sessionTimeOutAlert = $sessionTimeOut.getElementsByClassName(
                CONSTANTS.SESSION_TIME_OUT_ALERT
            )[0];
            $sessionTimeOutExpired = $sessionTimeOut.getElementsByClassName(
                CONSTANTS.SESSION_TIME_OUT_EXPIRED
            )[0];
            $logOutButton = $sessionTimeOut.getElementsByClassName(CONSTANTS.LOG_OUT)[0];
            $stayInButton = $sessionTimeOut.getElementsByClassName(CONSTANTS.STAY_IN)[0];
            $timer = $sessionTimeOut.getElementsByClassName(CONSTANTS.TIMER)[0];
            $closeButton = $sessionTimeOut.getElementsByClassName(CONSTANTS.CLOSE_BUTTON)[0];
        };

        var initializeEvents = function () {
            $logOutButton.addEventListener('click', logoutEvent);
            $stayInButton.addEventListener('click', stayLoggedInEvent);
            $closeButton.addEventListener('click', closeSessionTimeoutPopup);

            new coned.utils.addFocusTrap($sessionTimeOutAlert);
            new coned.utils.addFocusTrap($sessionTimeOutExpired);

            // Update remaining seconds getting it from the service. It works when the user leaves the tab and returns.
            !coned.utils.isPatternLab() &&
                document.addEventListener('visibilitychange', function () {
                    !document.hidden &&
                        query.getData(
                            _serviceUrl,
                            updateTimerAfterInactiveTab,
                            closeSessionOnExpiredTime
                        );
                });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();

            var isAuthenticated = $sessionTimeOut.dataset.isAuthenticaded;

            if (isAuthenticated !== 'false') {
                startSessionTimeOut(CONSTANTS.THIRTEEN_MINUTES); //10000 ( for test - 10 sec)
            }
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SessionTimeOut.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SessionTimeOut;
})();
