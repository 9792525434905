// ==================== SERVICE ACCOUNT DETAILS COMPONENT =========================
/* global _ */
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ServiceAccountDetails = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        NAME_SELECTOR: 'js-name-selector',
        FORM_SELECTOR: 'js-service-account-details-form',
        EMAIL_SELECTOR: 'js-email-selector',
        PHONE_SELECTOR: 'js-phone-selector',
        ALTERNATIVE_PHONE_SELECTOR: 'js-alternative-phone-selector',
        SERVICE_ADDRESS_SELECTOR: 'js-service-address',
        SECUNDARY_ADDRESS_SELECTOR: 'js-secundary-address-oru',
        CITY_SELECTOR: 'js-city-selector',
        COUNTRY_SELECTOR: 'js-country-oru',
        STATE_SELECTOR: 'js-state-selector',
        ZIPCODE_SELECTOR: 'js-zipcode-selector',
        INPUT_NAME: 'js-input-name',
        INPUT_EMAIL: 'js-input-email',
        INPUT_PHONE: 'js-input-phone',
        INPUT_ALTERNATIVE_PHONE: 'js-input-alternative-phone',
        INPUT_SERVICE_ADDRESS: 'js-input-service-address',
        INPUT_SECUNDARY_ADDRESS: 'js-input-secundary-address',
        INPUT_CITY: 'js-input-city',
        INPUT_STATE: 'js-input-state',
        INPUT_STATE_OPTIONS: 'js-select-option',
        INPUT_ZIPCODE: 'js-input-zipcode',
        TEXT_INFORMATION: 'js-text-info',
        INPUT_INFORMATION: 'js-input-info',
        EDIT_BUTTON_SELECTOR: 'js-edit-button',
        USER_NAME: 'js-account-info-container',
        HIDDEN_CLASS: 'hidden',
        OPEN_CONTAINER_CLASS: 'account-details__container--open',
        MOBILE_EXTENSION: 'js-mobile-extension',
        ALTERNATIVE_EXTENSION: 'js-alternative-extension',
        MOBILE_PHONE_EXTENSION: 'js-mobile-phone-extension',
        ALTERNATIVE_PHONE_EXTENSION: 'js-alternative-phone-extension',
        SELECT_ACTIVE: 'coned-select--active',
        SELECT_MODIFIER: 'account-details__select',
        COUNTRY_SELECTOR_CANADA: 'js-country-canada',
        COUNTRY_SELECTOR_USA: 'js-country-usa',
        DROPDOWN_COUNTRY: 'js-input-country',
        OTHER_COUNTRY: 'js-other-country',
        IGNORE_VALIDATION_CLASS: 'js-ignore-validation-class',
        IGNORE_CLASS: 'js-validation-ignore',//
        USA_ZIPCODE: 'js-usa-zipcode',
        CANADA_ZIPCODE: 'js-canada-zipcode',
        FORM_SCID: 'scId',
        FORM_USER_EMAIL: 'accountUserEmail',
        FORM_MOBILE_NUMBER: 'mobilePhoneNumber',
        FORM_MOBILE_EXTENSION: 'mobilePhoneExtension',
        FORM_ALTERNATIVE_NUMBER: 'alternativePhoneNumber',
        FORM_ALTERNATIVE_NUMBER_EXTENSION: 'alternativePhoneExtension',
        FORM_GIVE_PERMISSION: 'givePermission',
        FORM_ACCOUNT_TYPE: 'ServiceDetails',//*
        FORM_DIRECT_PAYMENT_AGREE: 'directPaymentAgree',
        FORM_NAME: 'mailingAddressName',
        FORM_ADDRESS: 'serviceAddress',
        FORM_ADDRESS_TWO: 'secundaryAddress',
        FORM_STATE: 'state',
        FORM_USA_CANADA_CITY: 'cityAddress',
        FORM_OTHER_CITY: 'cityOtherAddress',
        FORM_USA_ZIPCODE: 'mailingZipCode',
        FORM_CANADA_ZIPCODE: 'mailingCanadaZipCode',
        FORM_COUNTRY: 'selectCountry',
        FORM_COUNTRY_NAME: 'countryName',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        HEADER: 'js-header-wrapper',
        SERVICE_OPTION_CONTAINER: 'js-mailing-address-container',
        SERVICE_CHECKBOX: 'js-service-mailing',
        DROPDOWN_LABEL_SELECTOR: 'js-coned-select-label',
        ACTIVE_DROPDOWN: 'coned-select--active',
        ACOUNT_DETAILS_ACTIVE_DROPDOWN: 'account-details__select--active',
        FILLED_CLASS: 'coned-input--filled',
        FIELD_DISABLED_CLASS: 'coned-field--disabled',
        INPUT_MAILING_SELECTOR:'js-input-mailing-address',
        SELECT_SELECTOR: 'js-coned-select',
        OTHER_CITY: 'js-other-city',
        USA_CANADA_CITY: 'js-usa-canada-city',
        OPTION_ELEMENT: 'option',
        LIST_TYPE_ELEMENT: 'li',
        WRAPPER_SELECTOR:"js-wrapper-selector",
        WRAPPER:"account-details__info-wrapper",
        MAILING_OPTION_CONTAINER: 'js-mailing-address-container',
        ACCOUNT_DETAILS_POPUP: 'js-account-details-popup-selector',
        CONTINUE_BUTTON_SELECTOR: 'js-popup-button-confirmation',
        CLOSE_BUTTON: 'js-close-button',
        SAVE_BUTTON: 'js-save-button'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ServiceAccountDetails = function ($accountForm) {
        /**
         * PRIVATE METHODS
         */
        var $nameSelector,
            $serviceAddressSelector,
            $secundaryAddressSelector,
            $countrySelector,
            $citySelector,
            $stateSelector,
            $zipCodeSelector,
            $inputName,
            $inputServiceAddress,
            $inputSecundaryAddress,
            $emailSelector,
            $phoneSelector,
            $alternativePhoneSelector,
            $editButtonSelector,
            $inputsCity,
            $inputState,
            $inputsZipCode,
            $inputEmail,
            $inputPhone,
            $inputAlternativePhone,
            $textInfo,
            $dropdownCountry,
            $inputCountryName,
            $inputInfo,
            $countrySelectorUsa,
            $countrySelectorCanada,
            $usaZipcode,
            $canadaZipcode,
            $mobileExtension,
            $alternativeExtension,
            $mobilePhoneExtension,
            $alternativePhoneExtension,
            $accountMaidInput,
            $formLoading,
            $header,
            $serviceError,
            $serviceErrorMessage,
            $serviceOptionContainer,
            $serviceCheckBox,
            $selectSelector,
            $inputMailingSelector,
            $serviceAccountDetailsForm,
            $inputOtherCity,
            $inputUsaCanadaCity,
            $accountDetailsPopup,
            $wrapperSelector,
            $mailingOptionContainer,
            $continueButton,
            $closeButton,
            $saveButton,
            _validator,
            _zipCodeType,
            _cityAddressType,
            _onEditMailingAddress,
            _currentIndexSelect;

        var mailingChange = function () {
            var $selectParent;

            _validator.resetForm();

            if ($serviceCheckBox && $serviceCheckBox.checked) {
                _.each($inputMailingSelector, function ($input) {
                    $input.value = $input.dataset.serviceValue;
                    $input.classList.add(CONSTANTS.FILLED_CLASS);
                    $input.parentNode.classList.add(CONSTANTS.FIELD_DISABLED_CLASS);
                    $input.disabled = true;
                });

                _.each($selectSelector, function ($select) {
                    var selectedOptionsLength = $select.options.length,
                        selectedOptionValue;

                    $selectParent = $select.parentNode;
                    $selectParent.classList.add(CONSTANTS.ACOUNT_DETAILS_ACTIVE_DROPDOWN);
                    $select.disabled = true;

                    // set the saved value on the dropdown option
                    for (var selectIndex = 0; selectIndex < selectedOptionsLength; selectIndex++) {
                        selectedOptionValue = $select.options[selectIndex].value;

                        if (selectedOptionValue === $select.dataset.serviceValue) {
                            $select.selectedIndex = $select.options[selectIndex].index;
                        }
                    }
                });
            } else {
                _.each($inputMailingSelector, function ($input) {
                    if(!$input.dataset.optional) {
                        $input.setAttribute('required', '');
                    }                    
                    $input.parentNode.classList.remove(CONSTANTS.FIELD_DISABLED_CLASS);
                    $input.disabled = false;
                });

                _.each($selectSelector, function ($select) {
                    var selectedOptionFound = false,
                        selectedOptionsLength = $select.options.length,
                        selectedOptionValue;

                    if(!$select.dataset.optional) {
                        $select.setAttribute('required', '');
                    }
                    
                    $select.disabled = false;

                    if ($select.dataset.savedValue == '') {
                        resetDropDown($select);
                    } else {
                        // set the saved value on the dropdown option, if found
                        for (
                            var selectIndex = 0;
                            selectIndex < selectedOptionsLength;
                            selectIndex++
                        ) {
                            selectedOptionValue = $select.options[selectIndex].value;

                            if (selectedOptionValue === $select.dataset.savedValue) {
                                $selectParent = $select.parentNode;
                                $selectParent.classList.add(
                                    CONSTANTS.ACOUNT_DETAILS_ACTIVE_DROPDOWN
                                );
                                $select.selectedIndex = $select.options[selectIndex].index;
                                $select.classList.add(CONSTANTS.SELECT_ACTIVE);

                                selectedOptionFound = true;
                            }
                        }

                        // If value is not found, reset dropdown to its unselected value state
                        if (!selectedOptionFound) {
                            resetDropDown($select);
                        }
                    }
                });
            }

            // ORU only
            if ($dropdownCountry !== undefined) {
                selectedCountry(false);
            }
        };

        var resetDropDown = function ($element) {
            var $selectLabel = $element.parentNode.getElementsByClassName(
                    CONSTANTS.DROPDOWN_LABEL_SELECTOR
                )[0],
                $selectParent = $element.parentNode;

            $selectLabel.style = '';
            $element.style = '';
            $element.selectedIndex = 0;
            $element.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);
            $selectParent.classList.remove(CONSTANTS.ACOUNT_DETAILS_ACTIVE_DROPDOWN);
        };

        var updateSelect = function ($selectSelector, $input) {
            var actualState = $selectSelector.innerHTML.trim();
            var $options = $input.getElementsByClassName(CONSTANTS.INPUT_STATE_OPTIONS);

            _.each($options, function ($option) {
                if ($option.innerHTML.trim() == actualState) {
                    $option.setAttribute('selected', '');
                }
            });

            $input.classList.add(CONSTANTS.SELECT_ACTIVE);
            $input.parentElement.classList.add(CONSTANTS.SELECT_MODIFIER);
        };

        var updateInformation = function () {
            $inputEmail.value = $emailSelector.innerHTML;
            $inputPhone.value = $phoneSelector.dataset.phone;
            $inputAlternativePhone.value = $alternativePhoneSelector.dataset.phone;
            
            // If extension exists
            if ($mobileExtension !== undefined && $mobileExtension.dataset.extension !== '') {
                $mobilePhoneExtension.value = $mobileExtension.innerHTML.replace(
                    $mobileExtension.dataset.copy,
                    ''
                );
            } else if ($mobileExtension !== undefined && $mobilePhoneExtension.value === '') {
                $mobilePhoneExtension.classList.remove(CONSTANTS.FILLED_CLASS);
            }

            if ($alternativeExtension !== undefined && $alternativeExtension.dataset.extension !== '') {
                $alternativePhoneExtension.value = $alternativeExtension.innerHTML.replace(
                    $alternativeExtension.dataset.copy,
                    ''
                );
            } else if ($mobileExtension !== undefined && $alternativePhoneExtension.value === '') {
                $alternativePhoneExtension.classList.remove(CONSTANTS.FILLED_CLASS);
            }
            //Mailing section
            $inputName.value = $nameSelector.textContent;
            $inputServiceAddress.value = $inputServiceAddress.dataset.savedValue;
            updateSelect($stateSelector, $inputState);
            _validator = $($accountForm).validate();

            // ORU only
            if (coned.utils.isOru()) {
                $inputSecundaryAddress.value = $secundaryAddressSelector.innerHTML;
                updateSelect($countrySelector, $dropdownCountry);
                selectedCountry(false);
            }

            if ($serviceCheckBox && $serviceCheckBox.checked) {
                $serviceAccountDetailsForm.dataset.serviceAddress = 'true';
            } else {
                $serviceAccountDetailsForm.dataset.serviceAddress = 'false';
            }

            _.each($inputsZipCode, function ($input) {
                $input.value = $zipCodeSelector.innerHTML.trim();
            });
            _.each($inputsCity, function ($input) {
                $input.value = $citySelector.innerHTML.trim();
            });
        };

        var setData = function () {
            $emailSelector.innerHTML = $inputEmail.value;
            $phoneSelector.innerHTML = $phoneSelector.dataset.phoneLabel + ' ' + $inputPhone.value;
            $phoneSelector.dataset.phone = $inputPhone.value;
            $alternativePhoneSelector.innerHTML =
                $alternativePhoneSelector.dataset.phoneLabel + ' ' + $inputAlternativePhone.value;
            $alternativePhoneSelector.dataset.phone = $inputAlternativePhone.value;

            // If extension exists
            if ($mobileExtension !== undefined && $mobilePhoneExtension.value !== '') {
                $mobileExtension.innerHTML =
                    $mobileExtension.dataset.copy + $mobilePhoneExtension.value;
                $mobileExtension.dataset.extension = $mobilePhoneExtension.value;
                $mobilePhoneExtension.classList.add(CONSTANTS.FILLED_CLASS);
            } else if ($mobileExtension !== undefined && $mobilePhoneExtension.value === '') {
                $mobileExtension.innerHTML = '';
                $mobileExtension.dataset.extension = '';
                $mobilePhoneExtension.classList.remove(CONSTANTS.FILLED_CLASS);
            }

            if ($alternativeExtension !== undefined && $alternativePhoneExtension.value !== '') {
                $alternativeExtension.innerHTML = $alternativeExtension.dataset.copy + $alternativePhoneExtension.value;
                $alternativeExtension.dataset.extension = $alternativePhoneExtension.value;
                $mobilePhoneExtension.classList.add(CONSTANTS.FILLED_CLASS);
            } else if ($mobileExtension !== undefined && $alternativePhoneExtension.value === '') {
                $alternativeExtension.innerHTML = '';
                $alternativeExtension.dataset.extension = '';
                $alternativePhoneExtension.classList.remove(CONSTANTS.FILLED_CLASS);
            }
            //Mailing section
            $nameSelector.textContent = $inputName.value;
            $serviceAddressSelector.innerHTML = $inputServiceAddress.value;
            $citySelector.innerHTML = $accountForm.querySelector(
                '[name=' + _cityAddressType + ']'
            ).value;
            $stateSelector.innerHTML = $inputState.options[$inputState.selectedIndex].text;
            $zipCodeSelector.innerHTML = $accountForm.querySelector(
                '[name=' + _zipCodeType + ']'
            ).value;

            // ORU only
            if (coned.utils.isOru()) {
                if ($dropdownCountry.value.toLowerCase() == 'other') {
                    $countrySelector.innerHTML = query.getFormInputValue(
                        $accountForm,
                        CONSTANTS.FORM_COUNTRY_NAME
                    );
                    $zipCodeSelector.innerHTML = '';
                } else {
                    $countrySelector.innerHTML =
                        $dropdownCountry[$dropdownCountry.selectedIndex].text;
                }

                if ($inputState.value.toLowerCase() == 'default') {
                    $stateSelector.innerHTML = '';
                } 

                $secundaryAddressSelector.innerHTML = $inputSecundaryAddress.value;
            }
        };

        var openEdit = function (event) {
            event.preventDefault();
            var focusableEls = $accountForm.querySelectorAll(
                    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), input[type="email"]:not([disabled]), input[type="tel"]:not([disabled])'
                ),
                firstFocusableEl = focusableEls[0];
            if ($mailingOptionContainer) {
                $mailingOptionContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
            query.addClass($textInfo, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($inputInfo, CONSTANTS.HIDDEN_CLASS);
            $editButtonSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $editButtonSelector.setAttribute(CONSTANTS.ARIA_EXPANDED, true);
            $wrapperSelector.classList.remove(CONSTANTS.WRAPPER);
            $accountForm.classList.add(CONSTANTS.OPEN_CONTAINER_CLASS);

            firstFocusableEl.focus();
        };

        var closeEdit = function () {
            query.removeClass($textInfo, CONSTANTS.HIDDEN_CLASS);
            query.addClass($inputInfo, CONSTANTS.HIDDEN_CLASS);
            $wrapperSelector.classList.add(CONSTANTS.WRAPPER);
            query.addClass($accountDetailsPopup, CONSTANTS.HIDDEN_CLASS);//
            $editButtonSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $editButtonSelector.setAttribute(CONSTANTS.ARIA_EXPANDED, false);
            $accountForm.classList.remove(CONSTANTS.OPEN_CONTAINER_CLASS);
            if ($mailingOptionContainer) {
                $mailingOptionContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
            $editButtonSelector.focus();
        };

        var openPopup = function (){ 
            $accountDetailsPopup.BackFocus = $saveButton;
            var focusableEls = $accountDetailsPopup.querySelectorAll(coned.constants.FOCUSABLE_ELEMENTS_POPUP),
            firstFocusableEl = focusableEls[0];          
            $accountDetailsPopup.classList.remove(CONSTANTS.HIDDEN_CLASS)
            firstFocusableEl.focus();
            
        };

        var openValidate = function (event) {
            event.preventDefault();

            selectedCountry(false, true);
        };

        var selectedCountry = function (reset, isOpenValidate) {
            var selectValue = $dropdownCountry.value.toLowerCase();

            if (
                $dropdownCountry.dataset.savedValue.toLowerCase() != 'usa' &&
                $dropdownCountry.dataset.savedValue.toLowerCase() != 'canada' &&
                isOpenValidate
            ) {
                selectValue = 'other';
                $dropdownCountry.selectedIndex = 3;
            }

            _validator.resetForm();
            $usaZipcode.classList.add(CONSTANTS.IGNORE_VALIDATION_CLASS);
            $usaZipcode.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            $canadaZipcode.classList.add(CONSTANTS.IGNORE_VALIDATION_CLASS);
            $canadaZipcode.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            $inputState.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $inputState.classList.remove(CONSTANTS.IGNORE_VALIDATION_CLASS);
            $inputCountryName.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
            $inputCountryName.classList.add(CONSTANTS.IGNORE_VALIDATION_CLASS);
            $inputUsaCanadaCity.classList.remove(CONSTANTS.IGNORE_VALIDATION_CLASS);
            $inputUsaCanadaCity.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $inputOtherCity.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (selectValue == 'usa') {
                // Show US States
                query.addClass($countrySelectorCanada, CONSTANTS.HIDDEN_CLASS);
                query.removeClass($countrySelectorUsa, CONSTANTS.HIDDEN_CLASS);

                // if the function is called with the purpose of editing the mailing address, make the element type change
                if (_onEditMailingAddress) {
                    // if the current country is USA, set all the other country elements (in this case CANADA) as a li type
                    // this is necesssary since the option element doesn't support the property display:none, li does.
                    coned.utils.changeListType($countrySelectorCanada, CONSTANTS.LIST_TYPE_ELEMENT);

                    // get the current index to set it in the select element at the end of the process
                    // the index sets the current option in the select html item.
                    _currentIndexSelect = $inputState.selectedIndex;

                    // Since the current country is USA, set the usa elements as a option element to display them.
                    coned.utils.changeListType($countrySelectorUsa, CONSTANTS.OPTION_ELEMENT);
                }

                // Show US Zipcode
                $usaZipcode.classList.remove(CONSTANTS.IGNORE_VALIDATION_CLASS);
                $usaZipcode.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                _zipCodeType = CONSTANTS.FORM_USA_ZIPCODE;
                // Set USA-CANADA input city
                _cityAddressType = CONSTANTS.FORM_USA_CANADA_CITY;
            } else if (selectValue == 'canada') {
                // Show CAN Provinces
                query.removeClass($countrySelectorCanada, CONSTANTS.HIDDEN_CLASS);
                query.addClass($countrySelectorUsa, CONSTANTS.HIDDEN_CLASS);

                // if the function is called with the purpose of editing the mailing address, make the element type change
                if (_onEditMailingAddress) {
                    // if the current country is Canada, set all the other country elements (in this case USA) as a li type
                    // this is necesssary since the option element doesn't support the property display:none, li does.
                    coned.utils.changeListType($countrySelectorUsa, CONSTANTS.LIST_TYPE_ELEMENT);

                    // get the current index to set it in the select element at the end of the process.
                    // the index sets the current option in the select html item.
                    _currentIndexSelect = $inputState.selectedIndex;

                    // Since the current country is Canada, set the Canada elements as a option element to display them.
                    coned.utils.changeListType($countrySelectorCanada, CONSTANTS.OPTION_ELEMENT);
                }

                // Show CAN Zipcode
                $canadaZipcode.classList.remove(CONSTANTS.IGNORE_VALIDATION_CLASS);
                $canadaZipcode.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                _zipCodeType = CONSTANTS.FORM_CANADA_ZIPCODE;
                // Set USA-CANADA input city
                _cityAddressType = CONSTANTS.FORM_USA_CANADA_CITY;
            } else if (selectValue == 'other') {
                $inputState.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $inputState.classList.add(CONSTANTS.IGNORE_VALIDATION_CLASS);
                $inputCountryName.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $inputCountryName.classList.remove(CONSTANTS.IGNORE_VALIDATION_CLASS);
                // Show other input city
                $inputUsaCanadaCity.classList.add(CONSTANTS.IGNORE_VALIDATION_CLASS);
                $inputUsaCanadaCity.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $inputOtherCity.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                _cityAddressType = CONSTANTS.FORM_OTHER_CITY;
            }

            // this function is called several times before editing, this process is only when editing the mailing address.
            if (_onEditMailingAddress) {
                // Set the saved index in the select element
                $inputState.selectedIndex = _currentIndexSelect;
            }

            if (reset) {
                var $stateLabel = $inputState.parentElement.getElementsByClassName(
                    CONSTANTS.DROPDOWN_LABEL_SELECTOR
                )[0];

                $stateLabel.removeAttribute('style');
                $inputState.removeAttribute('style');
                $inputState.selectedIndex = 0;
                $inputState.classList.remove(CONSTANTS.SELECT_ACTIVE);
                $inputState.parentElement.classList.remove(
                    CONSTANTS.ACOUNT_DETAILS_ACTIVE_DROPDOWN
                );

                $usaZipcode.value = '';
                $usaZipcode.classList.remove(CONSTANTS.FILLED_CLASS);
                $canadaZipcode.value = '';
                $canadaZipcode.classList.remove(CONSTANTS.FILLED_CLASS);
                $inputOtherCity.value = '';
                $inputOtherCity.classList.remove(CONSTANTS.FILLED_CLASS);
                $inputUsaCanadaCity.value = '';
                $inputUsaCanadaCity.classList.remove(CONSTANTS.FILLED_CLASS);
            }
        };

        var submitAccountDetails = function () {
            if ($serviceAccountDetailsForm.dataset.serviceAddress == 'true' && $serviceCheckBox.checked) {
                $serviceOptionContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                closeEdit();
                query.scrollToElement($accountForm, $header);
            } 
            var serviceUrl = $accountForm.dataset.serviceUrl,
            emailUpdated = $inputEmail.value != $emailSelector.innerHTML,
            alternatePhoneUpdated = $inputAlternativePhone.value != $alternativePhoneSelector.dataset.phone,
            primaryPhoneUpdated = $inputPhone.value != $phoneSelector.dataset.phone,
            params;
            // If extension exists
            if ($mobileExtension !== undefined) {
                if ($mobileExtension.innerHTML !== '') {
                    primaryPhoneUpdated =
                        $mobileExtension.dataset.copy + $mobilePhoneExtension.value !=
                        $mobileExtension.innerHTML;
                } else if ($mobilePhoneExtension.value !== '') {
                    primaryPhoneUpdated = true;
                }
            }

            if ($alternativeExtension !== undefined) {
                if ($alternativeExtension.innerHTML !== '') {
                    alternatePhoneUpdated =
                        $alternativeExtension.dataset.copy + $alternativePhoneExtension.value !=
                        $alternativeExtension.innerHTML;
                } else if ($alternativePhoneExtension.value !== '') {
                    alternatePhoneUpdated = true;
                }
            }

            hideError();
            // Service Data
            params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($accountForm, CONSTANTS.FORM_SCID),
                UpdateAccountType: CONSTANTS.FORM_ACCOUNT_TYPE,
                Email: query.getFormInputValue($accountForm, CONSTANTS.FORM_USER_EMAIL),
                EmailUpdated: emailUpdated,
                PrimaryPhone: query.getFormInputValue($accountForm, CONSTANTS.FORM_MOBILE_NUMBER),
                PrimaryPhoneExtension: query.getFormInputValue(
                    $accountForm,
                    CONSTANTS.FORM_MOBILE_EXTENSION
                ),
                AlternatePhoneUpdated: alternatePhoneUpdated,
                AlternativePhone: query.getFormInputValue($accountForm, CONSTANTS.FORM_ALTERNATIVE_NUMBER),
                AlternativePhoneExtension: query.getFormInputValue(
                    $accountForm,
                    CONSTANTS.FORM_ALTERNATIVE_NUMBER_EXTENSION
                ),
                PrimaryPhoneUpdated: primaryPhoneUpdated,
                MailingAddress: setMailingAddress()
            };
            params = JSON.stringify(params);
                query.postData(
                    serviceUrl,
                    successAccountDetails,
                    errorAccountDetails,
                    params,
                    true,
                    $formLoading
                );
        }

        var hideError = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var setMailingAddress = function () {
            var state, countryName, zipCodeValue;

            if ($dropdownCountry !== undefined && $dropdownCountry.value.toLowerCase() == 'other') {
                state = '';
                countryName = query.getFormInputValue($accountForm, CONSTANTS.FORM_COUNTRY_NAME);
                zipCodeValue = null;
            } else {
                state = query.getFormInputValue($accountForm, CONSTANTS.FORM_STATE);
                countryName = query.getFormInputValue($accountForm, CONSTANTS.FORM_COUNTRY);
                zipCodeValue = query.getFormInputValue($accountForm, _zipCodeType);
            }

            var mailingAddress = {
                Name: query.getFormInputValue($accountForm, CONSTANTS.FORM_NAME),
                Street1: query.getFormInputValue($accountForm, CONSTANTS.FORM_ADDRESS),
                Street2: query.getFormInputValue($accountForm, CONSTANTS.FORM_ADDRESS_TWO),
                Street3: null,
                City: query.getFormInputValue($accountForm, _cityAddressType),
                State: state,
                PostalCode: zipCodeValue,
                Country: countryName,
                AddressKey: null
            };

            if ($dropdownCountry !== undefined) {
                $dropdownCountry.dataset.savedValue = countryName;
            }

            return mailingAddress;
        };

        var successAccountDetails = function () {
            setData();
            closeEdit();
            updateInformation();

            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            query.scrollToElement($accountForm, $header);

            if ($serviceOptionContainer) {
                $serviceOptionContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                if ($canadaZipcode !== undefined) {
                    $canadaZipcode.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }
        };

        var errorAccountDetails = function (data) {
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var initializeData = function () {
            $nameSelector = $accountForm.getElementsByClassName(CONSTANTS.NAME_SELECTOR)[0];
            $serviceAddressSelector = $accountForm.getElementsByClassName(
                CONSTANTS.SERVICE_ADDRESS_SELECTOR
            )[0];
            $secundaryAddressSelector = $accountForm.getElementsByClassName(
                CONSTANTS.SECUNDARY_ADDRESS_SELECTOR
            )[0];
            $citySelector = $accountForm.getElementsByClassName(CONSTANTS.CITY_SELECTOR)[0];
            $stateSelector = $accountForm.getElementsByClassName(CONSTANTS.STATE_SELECTOR)[0];
            $zipCodeSelector = $accountForm.getElementsByClassName(CONSTANTS.ZIPCODE_SELECTOR)[0];
            $inputName = $accountForm.getElementsByClassName(CONSTANTS.INPUT_NAME)[0];
            $inputServiceAddress = $accountForm.getElementsByClassName(
                CONSTANTS.INPUT_SERVICE_ADDRESS
            )[0];
            $inputSecundaryAddress = $accountForm.getElementsByClassName(
                CONSTANTS.INPUT_SECUNDARY_ADDRESS
            )[0];
            $inputsCity = $accountForm.getElementsByClassName(CONSTANTS.INPUT_CITY);
            $inputOtherCity = $accountForm.getElementsByClassName(CONSTANTS.OTHER_CITY)[0];
            $inputUsaCanadaCity = $accountForm.getElementsByClassName(CONSTANTS.USA_CANADA_CITY)[0];
            $inputState = $accountForm.getElementsByClassName(CONSTANTS.INPUT_STATE)[0];
            $inputsZipCode = $accountForm.getElementsByClassName(CONSTANTS.INPUT_ZIPCODE);
            $textInfo = $accountForm.getElementsByClassName(CONSTANTS.TEXT_INFORMATION);
            $inputInfo = $accountForm.getElementsByClassName(CONSTANTS.INPUT_INFORMATION);
            $dropdownCountry = $accountForm.getElementsByClassName(CONSTANTS.DROPDOWN_COUNTRY)[0];
            $countrySelectorCanada = $accountForm.getElementsByClassName(
                CONSTANTS.COUNTRY_SELECTOR_CANADA
            );
            $countrySelectorUsa = $accountForm.getElementsByClassName(
                CONSTANTS.COUNTRY_SELECTOR_USA
            );
            $countrySelector = $accountForm.getElementsByClassName(CONSTANTS.COUNTRY_SELECTOR)[0];
            $inputCountryName = $accountForm.getElementsByClassName(CONSTANTS.OTHER_COUNTRY)[0];
            $usaZipcode = $accountForm.getElementsByClassName(CONSTANTS.USA_ZIPCODE)[0];
            $canadaZipcode = $accountForm.getElementsByClassName(CONSTANTS.CANADA_ZIPCODE)[0];
            $serviceError = $accountForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceErrorMessage = $accountForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $serviceOptionContainer = $accountForm.getElementsByClassName(
                CONSTANTS.SERVICE_OPTION_CONTAINER
            )[0];
            $serviceCheckBox = $accountForm.getElementsByClassName(CONSTANTS.SERVICE_CHECKBOX)[0];
            $inputMailingSelector = $accountForm.getElementsByClassName(CONSTANTS.INPUT_MAILING_SELECTOR);
            $selectSelector = $accountForm.getElementsByClassName(CONSTANTS.SELECT_SELECTOR);
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $serviceAccountDetailsForm = document.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            $mailingOptionContainer = $accountForm.getElementsByClassName(
                CONSTANTS.MAILING_OPTION_CONTAINER
            )[0];
            _zipCodeType = CONSTANTS.FORM_USA_ZIPCODE;
            _cityAddressType = CONSTANTS.FORM_USA_CANADA_CITY;
            _onEditMailingAddress = false;
        
            //Account contact information
            $emailSelector = $accountForm.getElementsByClassName(CONSTANTS.EMAIL_SELECTOR)[0];
            $phoneSelector = $accountForm.getElementsByClassName(CONSTANTS.PHONE_SELECTOR)[0];
            $alternativePhoneSelector = $accountForm.getElementsByClassName(
                CONSTANTS.ALTERNATIVE_PHONE_SELECTOR
            )[0];
            $inputEmail = $accountForm.getElementsByClassName(CONSTANTS.INPUT_EMAIL)[0];
            $inputPhone = $accountForm.getElementsByClassName(CONSTANTS.INPUT_PHONE)[0];
            $inputAlternativePhone = $accountForm.getElementsByClassName(CONSTANTS.INPUT_ALTERNATIVE_PHONE)[0];
            $mobileExtension = $accountForm.getElementsByClassName(CONSTANTS.MOBILE_EXTENSION)[0];
            $alternativeExtension = $accountForm.getElementsByClassName(CONSTANTS.ALTERNATIVE_EXTENSION)[0];
            $mobilePhoneExtension = $accountForm.getElementsByClassName(
                CONSTANTS.MOBILE_PHONE_EXTENSION
            )[0];
            $alternativePhoneExtension = $accountForm.getElementsByClassName(
                CONSTANTS.ALTERNATIVE_PHONE_EXTENSION
            )[0];
            
            //General use
            $editButtonSelector = $accountForm.getElementsByClassName(
                CONSTANTS.EDIT_BUTTON_SELECTOR
            )[0];
            $closeButton = $accountForm.getElementsByClassName(CONSTANTS.CLOSE_BUTTON)[0];
            $wrapperSelector = document.getElementsByClassName(CONSTANTS.WRAPPER_SELECTOR)[0];
            $accountDetailsPopup = document.getElementsByClassName(
                CONSTANTS.ACCOUNT_DETAILS_POPUP
            )[0];
            $continueButton = document.getElementsByClassName(
                CONSTANTS.CONTINUE_BUTTON_SELECTOR
            )[0];
            $saveButton = document.getElementsByClassName(
                CONSTANTS.SAVE_BUTTON
            )[0];
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($closeButton, closeEdit);
            coned.utils.addGeneralListeners($editButtonSelector, openEdit);
            //Validate the form and open the popup
            new coned.components.ValidateForm(
                $serviceAccountDetailsForm, 
                openPopup
            );
            updateInformation();   
            if ($dropdownCountry !== undefined) {
                $dropdownCountry.addEventListener('change', function () {
                    selectedCountry(true);
                });

                coned.utils.addGeneralListeners($editButtonSelector, openValidate);
            }

            if($serviceCheckBox) {
                $serviceCheckBox.addEventListener('change', mailingChange);
            }

            mailingChange();            
            // this flag is to make the element type change only when the mailing address will be edited
            _onEditMailingAddress = true;
            
            coned.utils.addGeneralListeners($continueButton, submitAccountDetails);
            new coned.components.AccountDetails($accountForm);
        };
        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ServiceAccountDetails.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ServiceAccountDetails;
})();
