// ==================== LANGUAGE SWITCHER COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {},
    weglot = window.Weglot || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LanguageSwitcher = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        //Selectors
        SWITCHER_CONTAINER_SELECTOR: 'js-lang-switcher-container',
        OPEN_CONTROLS_SELECTOR: 'js-open-lang-switcher',
        MODAL_OPENED_SELECTOR: 'js-lang-switcher-open',
        DESKTOP_SWITCHER_SELECTOR: 'js-lang-switcher-desktop',
        LANGUAGE_OPTIONS_CONTAINER_SELECTOR: 'js-language-switcher-options-container',
        LANGUAGE_OPTIONS_MOBILE_CONTAINER_SELECTOR: 'js-language-switcher-options-container-mobile',
        LANG_OPTION_ITEM_SELECTOR: 'js-lang-switcher-option-item',
        LANG_OPTION_ITEM_SELECTED_SELECTOR: 'js-lang-switcher-option-item-selected',
        SWITCHER_LABEL_SELECTOR: 'js-lang-switcher-label',
        SWITCHER_ACTION_ICON_SELECTOR: 'js-lang-switcher-action-icon',
        LANG_OPTION_ITEM_CHECK_SELECTOR: 'js-lang-option-check',
        HEADER_SELECTOR: 'js-header-select-language',
        LANGUAGE_SWITCHER_DESKTOP_CONTROL_SELECTOR: 'js-language-switcher-desktop-control',
        //Classes
        LANG_OPTION_ITEM_CLASS: 'language-switcher-option-item',
        LANG_OPTION_SELECTED_ITEM_CLASS: 'language-switcher-option-item--selected',
        LANG_OPTION_ITEM_LANGUAGE_CLASS: 'language-switcher-option-item--language',
        LANG_OPTION_ITEM_LANGUAGE_SELECTED_CLASS: 'language-switcher-option-item--language--selected',
        SWITCHER_ACTION_ICON_OPEN_CLASS: 'language-switcher-action-icon--open',
        SWITCHER_DESKTOP_CONTROL_OPEN_CLASS: 'language-switcher-desktop-control--open',
        LANG_OPTION_ITEM_CHECK_ICON_CLASS: 'language-switcher-option-item-check-icon',
        LANGUAGE_SWITCHER_SELECT_LANGUAGE_EVENT: 'selectLanguage',
        HREF_ATTRIBUTE: 'href',
        DATA_OPTION_LANG_ATTRIBUTE: 'data-lang',
        HIDDEN_CLASS: 'hidden',
        LOCAL_NAME_TOKEN: '{LOC}',
        ENG_NAME_TOKEN: '{ENG}',
        LANG_NAME_SEPARATOR: '|',
        LOCALIZED_LANG_SEPARATOR: ',',
        LANG_CODE_SEPARATOR: '-',
        LANG_URL_PARAM: 'language=',
        LANG_URL_PARAM_REGEX: /\&language=[A-Za-z]{2}/g,
        LANG_URL_PATH_REGEX: /^\/[A-Za-z]{2}/,
        LANG_OPTION_ITEM_CHECK_ICON_STATUS_CLASS: 'icon-check-status',
        ARIA_EXPANDED_ATTRIBUTE: 'aria-expanded',
        ARIA_ACTIVE_DESCENDANT_ATTRIBUTE: 'aria-activedescendant',
        MINUS_ONE_TAB_INDEX: '-1',
        ZERO_TAB_INDEX: '0',
        DESKTOP_DEVICE: 'Desktop',
        MOBILE_DEVICE: 'Mobile',
        //events
        KEYDOWN_EVENT: 'keydown'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @return {}        Encapsulated modules with its function.
     */
    var LanguageSwitcher = function () {
        /**
         * PRIVATE METHODS
         */
        var apiKey,
            localizedLanguages,
            currentSysLang,
            optionsDisplayFormat,
            optionsSortBy,
            linkSelectedClasses,
            itemSelectedClasses,
            availableLanguages,
            listboxDesktopState,
            $switcherContainer,
            $openSwitcherControls,
            $switcherLabels,
            $switcherActionIcon,
            $languageOptionsContainer,
            $languageOptionsMobileContainer,
            $languageSwitcherDesktopControl,
            $desktopSwitcherControl,
            $headerElement;
            
        var loadLanguageOptions = function () {
            try {
                if (!weglot || (weglot.options.languages === undefined)) {
                    throw new Error('Language options configuration cannot be found.');
                }

                // Includes the base language if missing
                if (!localizedLanguages.includes(weglot.options.language_from)){
                    localizedLanguages.push(weglot.options.language_from);
                }
    
                availableLanguages = weglot.options.languages
                    .map(function(lang) {
                        var langCode = lang.custom_code !== null ? lang.custom_code : lang.language_to;
                        var langCustomNameParts = getLanguageNameParts(langCode, lang.custom_name);            
                        return { 
                            code: langCode,
                            localName: langCustomNameParts.displayName,
                            englishName: langCustomNameParts.altName
                        };
                    });
                // Adds the original language at the beggining of the list
                var langCustomNameParts = getLanguageNameParts(weglot.options.language_from, weglot.options.language_from_custom_name);            
                availableLanguages.unshift({
                    code: weglot.options.language_from,
                    localName: langCustomNameParts.displayName,
                    englishName: langCustomNameParts.altName
                });
    
                // Sorts by field in case is requested
                // Valid Options: Empty or 'custom' for using configuration order. Otherwise, valid field names (code|localName|englishName)
                if (optionsSortBy !== undefined && optionsSortBy!== '' && optionsSortBy!== 'custom'){
                    availableLanguages.sort( function (a, b) {
                        var valueA = a[optionsSortBy] || '';
                        var valueB = b[optionsSortBy] || '';
                        if (valueA < valueB) return -1;
                        if (valueA > valueB) return 1;
                        return 0;
                    });
                }
                var selectedLang = getCurrentLanguageCode();
                // Create and append the options
                _.each(availableLanguages, function (lang) {
                    var selected = selectedLang === lang.code;
                    $languageOptionsMobileContainer.appendChild(createLanguageOption(lang, selected, $languageOptionsMobileContainer, CONSTANTS.MOBILE_DEVICE));
                    $languageOptionsContainer.appendChild(createLanguageOption(lang, selected, $languageOptionsContainer, CONSTANTS.DESKTOP_DEVICE));
                });
    
                setLanguageSwitcherLabels(selectedLang);

                // Focus listbox item state
                listboxDesktopState = {
                    currentIndex: 0,
                    $currentOption: $languageOptionsContainer.children[0]
                };

            } catch (error) {
                //No action to be taken
            }
        };
    
        var languageOptionClickHandler = function (event){
            event.preventDefault();
            var lang = event.currentTarget.dataset.lang;
            switchLanguage(lang);
        };

        var switchLanguage = function (lang) {
            try {
                var currentLangCode = getCurrentLanguageCode();

                if (lang !== '' && lang !== currentLangCode){    
                    // if the option is a localized language it 'resets' to the original lang on weglot, sets the new language otherwise
                    // In patterlab weglot is used to 'simulate' the proper translation like when using localized languages
                    if (localizedLanguages != undefined && localizedLanguages.includes(lang) && !coned.utils.isPatternLab())
                    {
                        if (weglot.getCurrentLang() !== weglot.options.language_from){
                            weglot.switchTo(weglot.options.language_from);
                        }
                        onLanguageSwitch(lang);
                    } else {
                        weglot.switchTo(lang);
                        coned.utils.triggerEvent($headerElement, CONSTANTS.LANGUAGE_SWITCHER_SELECT_LANGUAGE_EVENT);
                    }
                }
            } catch (error) {
                //No action to be taken
            }
            closeLanguageSwitcher();
        }

        var onLanguageSwitch = function (lang) {
            try {
                // When the new language is a localized language it must redirect to the page using the new language
                if (localizedLanguages != undefined && localizedLanguages.includes(lang)) {
                    redirectToLanguage(lang, true);
                } else {
                    // If the current system language code is different from the original language, it redirects to the original language url
                    var sysLang = getSystemLanguageCode();
                    if ((sysLang !== '' && sysLang !== weglot.options.language_from)
                        || window.location.search.includes(CONSTANTS.LANG_URL_PARAM)){ 
                        redirectToLanguage(weglot.options.language_from, false);
                    } else {
                        // If there is not need to redirect it just set the new language label on the switchers
                        setLanguageSwitcherLabels(lang);
                        setSwitcherSelectedLang(lang, $languageOptionsContainer);
                        setSwitcherSelectedLang(lang, $languageOptionsMobileContainer);
                    }
                }
            } catch (error) {
                //No action to be taken
            }
        };
        
        var languageSwitcherClickEvent = function (event) {
            event.preventDefault();
            if ($switcherContainer !== undefined) {
                if (query.hasClass($switcherContainer, CONSTANTS.MODAL_OPENED_SELECTOR)) {
                    closeLanguageSwitcher();
                } else {
                    openLanguageSwitcher();
                }
            }
        };

        var createLanguageOption = function (language, selected, ul, device){
            
            var optionItem = document.createElement('li');
            optionItem.classList.add(CONSTANTS.LANG_OPTION_ITEM_CLASS);
            optionItem.ariaSelected = selected;
            optionItem.role = 'option';
            optionItem.tabIndex = language.code == 'en' ? CONSTANTS.ZERO_TAB_INDEX : CONSTANTS.MINUS_ONE_TAB_INDEX;
            optionItem.id = language.englishName.concat(device);
            optionItem.addEventListener(CONSTANTS.KEYDOWN_EVENT, optionItemKeyboardHandler);

            var selectedIcon = document.createElement('span');
            selectedIcon.classList.add(CONSTANTS.LANG_OPTION_ITEM_CHECK_ICON_STATUS_CLASS);
            selectedIcon.classList.add(CONSTANTS.LANG_OPTION_ITEM_CHECK_SELECTOR);
            selectedIcon.classList.add(CONSTANTS.LANG_OPTION_ITEM_CHECK_ICON_CLASS);
            selectedIcon.classList.add(CONSTANTS.HIDDEN_CLASS);
            
            var languageOption = document.createElement('span');
            languageOption.setAttribute(CONSTANTS.DATA_OPTION_LANG_ATTRIBUTE, language.code);
            languageOption.classList.add(CONSTANTS.LANG_OPTION_ITEM_LANGUAGE_CLASS);
            languageOption.classList.add(CONSTANTS.LANG_OPTION_ITEM_SELECTOR);

            coned.utils.addGeneralListeners(languageOption, languageOptionClickHandler);
            
            var optionLabelText = optionsDisplayFormat !== '' 
                                    ? optionsDisplayFormat
                                        .replaceAll(CONSTANTS.LOCAL_NAME_TOKEN, language.localName)
                                        .replaceAll(CONSTANTS.ENG_NAME_TOKEN, language.englishName)
                                    : language.localName;
            languageOption.innerText = optionLabelText;
            
            if (selected){
                languageOption.classList.add(linkSelectedClasses);
                itemSelectedClasses.forEach(function (cls) {
                    optionItem.classList.add(cls); 
                });
                selectedIcon.classList.remove(CONSTANTS.HIDDEN_CLASS);
                ul.setAttribute(CONSTANTS.ARIA_ACTIVE_DESCENDANT_ATTRIBUTE, optionItem.id);
            }
            optionItem.appendChild(selectedIcon);
            optionItem.appendChild(languageOption);
            return optionItem;
        };

        var getLanguageNameParts = function (lang, customName){
            var languageName = weglot.getLanguageName(lang), // return value will have { displayName: localName, altName: englishName }
                nameParts = languageName.split(CONSTANTS.LANG_NAME_SEPARATOR); // When the language name in weglot platform has been configured to use main and alternate language names
            if (nameParts.length > 1){
                return { displayName: nameParts[0], altName: nameParts[1] };
            }
            return { displayName: languageName, altName: customName !== null ? customName : '' };
        };

        var setLanguageSwitcherLabels = function (lang) {
            if ($switcherLabels !== undefined) {
                var labelText = getLanguageNameParts(lang, '').displayName;
                _.each($switcherLabels, function (control) {
                    control.innerText = labelText;
                });
            }
        };

        var setSwitcherSelectedLang = function (lang, ul) {
            var languageOptionItems = ul.children;
            _.each(languageOptionItems, function (optionItem) {

                var languageOption = optionItem.getElementsByClassName(CONSTANTS.LANG_OPTION_ITEM_LANGUAGE_CLASS)[0],
                    checkIcon = optionItem.getElementsByClassName(CONSTANTS.LANG_OPTION_ITEM_CHECK_SELECTOR)[0],
                    selected = lang === languageOption.dataset.lang,
                    hasSelectedClasses = query.hasClass(optionItem, CONSTANTS.LANG_OPTION_ITEM_SELECTED_SELECTOR);
                
                optionItem.ariaSelected = selected;

                if (selected){
                    if (!hasSelectedClasses) {
                        languageOption.classList.add(linkSelectedClasses);
                        itemSelectedClasses.forEach(function (cls) {
                            optionItem.classList.add(cls);
                        });
                        
                    }
                    if (query.hasClass(checkIcon, CONSTANTS.HIDDEN_CLASS)) {
                        checkIcon.classList.remove(CONSTANTS.HIDDEN_CLASS);    
                    }
                    ul.setAttribute(CONSTANTS.ARIA_ACTIVE_DESCENDANT_ATTRIBUTE, optionItem.id);
                }
                else {
                    if (hasSelectedClasses) {
                        languageOption.classList.remove(linkSelectedClasses);
                        itemSelectedClasses.forEach(function (cls) {
                            optionItem.classList.remove(cls);
                        });    
                    }
                    if (!query.hasClass(checkIcon, CONSTANTS.HIDDEN_CLASS)) {
                        checkIcon.classList.add(CONSTANTS.HIDDEN_CLASS);    
                    }
                }
            });
        };

        var getCurrentLanguageCode = function () {
            var sysLang = getSystemLanguageCode();
            // Prioritizes language on the system
            if (sysLang !== '' && sysLang !== weglot.options.language_from){
                return sysLang;
            }
            // If the system language is the original language, returns the language set on weglot
            return weglot.getCurrentLang();
        };

        var getSystemLanguageCode = function () {
            if (currentSysLang != undefined && currentSysLang !== ''){
                var resultLang = availableLanguages.find(function (lang) {
                    // Codes that need to be mapped from configuration must use the same value that it's used for system lang (en,es) or using a '-' at the end (en-,es-) in case is a custom language
                    return (lang.code === currentSysLang) || (lang.code === (currentSysLang + CONSTANTS.LANG_CODE_SEPARATOR));
                });
                return (resultLang && resultLang !== undefined) ? resultLang.code : '';
            }
            return '';
        };

        var openLanguageSwitcher = function () {
            $switcherContainer.classList.add(CONSTANTS.MODAL_OPENED_SELECTOR);
            $switcherContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $switcherActionIcon.classList.add(CONSTANTS.SWITCHER_ACTION_ICON_OPEN_CLASS);
            $desktopSwitcherControl.parentElement.classList.add(CONSTANTS.SWITCHER_DESKTOP_CONTROL_OPEN_CLASS);
            $desktopSwitcherControl.setAttribute(CONSTANTS.ARIA_EXPANDED_ATTRIBUTE, 'true');
        };

        var closeLanguageSwitcher = function () {
            $switcherContainer.classList.remove(CONSTANTS.MODAL_OPENED_SELECTOR);
            $switcherContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

            $switcherActionIcon.classList.remove(CONSTANTS.SWITCHER_ACTION_ICON_OPEN_CLASS);
            $desktopSwitcherControl.parentElement.classList.remove(CONSTANTS.SWITCHER_DESKTOP_CONTROL_OPEN_CLASS);
            $desktopSwitcherControl.setAttribute(CONSTANTS.ARIA_EXPANDED_ATTRIBUTE, 'false');

        };
        
        var redirectToLanguage = function (lang, addLangParam) {
            // Removes chars after split value
            lang = lang.trim().split(CONSTANTS.LANG_CODE_SEPARATOR)[0];
            // New language path
            var langPath = !coned.utils.isPatternLab() ? ('/' + lang) : '',
                cleanPath = !coned.utils.isPatternLab() ? window.location.pathname.replace(CONSTANTS.LANG_URL_PATH_REGEX, '') : window.location.pathname,            
                langParam = addLangParam === true ? (CONSTANTS.LANG_URL_PARAM + lang) : '',
                params = window.location.search !== '' ? window.location.search.replaceAll('?', '&') : '';
            // Clean the current language params
            if (params.includes(CONSTANTS.LANG_URL_PARAM)){
                params = params.replace(CONSTANTS.LANG_URL_PARAM_REGEX, '');
            }
            // Builds new parameters
            params = langParam !== ''
                    ? '?' + langParam + params
                    // Replaces the first '&' for a '?'
                    : params.replace(/^\&/, '?');
            var redirectUrl = window.location.origin + langPath + cleanPath + params;
            window.location.href = redirectUrl;
        };

        /**
         * Handle keys inside listbox of languages.
         * @param {Event} event Event that triggered the function.
         */
        var listBoxKeyboardHandler = function (event) {
            event.stopPropagation();

            var currentPosition = listboxDesktopState.currentIndex,
                newPosition;

            switch (event.keyCode) {
                case coned.constants.KEY_CODE.UP:
                    event.preventDefault();
                    newPosition = currentPosition - 1;
                    moveListboxOption(newPosition, listboxDesktopState, $languageOptionsContainer);
                    break;

                case coned.constants.KEY_CODE.DOWN:
                    event.preventDefault();
                    newPosition = currentPosition + 1;
                    moveListboxOption(newPosition, listboxDesktopState, $languageOptionsContainer);
                    break;
                case coned.constants.KEY_CODE.ESC:
                    closeLanguageSwitcher();
                    $desktopSwitcherControl.focus();
                    break;
            }                     
        };

        /**
         * Move focus to the indicated position.
         * @param {Number} newPosition New listbox position to move.
         */
        var moveListboxOption = function (newPosition, listboxState, ul) {
            if (
                newPosition >= 0 && 
                newPosition < ul.children.length
            ) {
                var $previousOption = listboxState.$currentOption,
                    $currentOption = ul.children[newPosition];

                // Update global state
                listboxState.$currentOption = $currentOption;
                listboxState.currentIndex = newPosition;
                
                if ($previousOption) {
                    $previousOption.tabIndex = CONSTANTS.MINUS_ONE_TAB_INDEX;
                }
                
                if ($currentOption) {    
                    $currentOption.tabIndex = CONSTANTS.ZERO_TAB_INDEX;
                    $currentOption.focus();
                }
            }
        };
        /**
         * Handle keys inside switcher control.
         * @param {Event} event Event that triggered the function.
         */
        var switcherKeyboardHandler = function (event) {
            event.stopPropagation();

            switch (event.keyCode) {
                case coned.constants.KEY_CODE.SPACE :
                    event.preventDefault();
                    if (query.hasClass($switcherContainer, CONSTANTS.MODAL_OPENED_SELECTOR)) {
                        closeLanguageSwitcher();
                    } else {
                        openLanguageSwitcher();
                    }
                    break;
                case coned.constants.KEY_CODE.ESC:
                    closeLanguageSwitcher();
                    $desktopSwitcherControl.focus();
                    break;
            }
            
        }

        var optionItemKeyboardHandler = function (event) {
            var lang = event.currentTarget.children[1].dataset.lang,
                isValidKeyCode = event.keyCode === coned.constants.KEY_CODE.SPACE || event.keyCode === coned.constants.KEY_CODE.ENTER;
            if(!lang || !isValidKeyCode) return;
            event.stopPropagation();
            event.preventDefault();
            switchLanguage(lang);
            
        }

        coned.utils.onResizeThrottler(function () {
            if (coned.utils.isMobile() && query.hasClass($switcherContainer, CONSTANTS.MODAL_OPENED_SELECTOR)) {
                closeLanguageSwitcher();
            }
        });

        var initializeData = function () {
            $switcherContainer = document.getElementsByClassName(CONSTANTS.SWITCHER_CONTAINER_SELECTOR)[0]; 
            $languageOptionsContainer = document.getElementsByClassName(CONSTANTS.LANGUAGE_OPTIONS_CONTAINER_SELECTOR)[0];
            $languageOptionsMobileContainer = document.getElementsByClassName(CONSTANTS.LANGUAGE_OPTIONS_MOBILE_CONTAINER_SELECTOR)[0];
            $languageSwitcherDesktopControl = document.getElementsByClassName(CONSTANTS.LANGUAGE_SWITCHER_DESKTOP_CONTROL_SELECTOR)[0];
            $openSwitcherControls = document.getElementsByClassName(CONSTANTS.OPEN_CONTROLS_SELECTOR);
            $switcherLabels = document.getElementsByClassName(CONSTANTS.SWITCHER_LABEL_SELECTOR);
            $switcherActionIcon = document.getElementsByClassName(CONSTANTS.SWITCHER_ACTION_ICON_SELECTOR)[0];
            $desktopSwitcherControl = document.getElementsByClassName(CONSTANTS.DESKTOP_SWITCHER_SELECTOR)[0];
            $headerElement = document.getElementsByClassName(CONSTANTS.HEADER_SELECTOR)[0];

            apiKey = $switcherContainer.dataset.apiKey;
            currentSysLang = $switcherContainer.dataset.currentSysLang; //This value is always '' on patternlab and it's used for BE logic
            optionsDisplayFormat = $switcherContainer.dataset.optionDisplayFormat;
            optionsSortBy =  $switcherContainer.dataset.optionsSortBy;
            localizedLanguages = []; 
            var localizedLanguagesList = $switcherContainer.dataset.localizedLang;
            if (localizedLanguagesList !== undefined && localizedLanguagesList !== '') {
                localizedLanguages = localizedLanguagesList.split(CONSTANTS.LOCALIZED_LANG_SEPARATOR).filter(function (item) {
                    return item !== '';
                });
            }

            linkSelectedClasses = CONSTANTS.LANG_OPTION_ITEM_LANGUAGE_SELECTED_CLASS;          
            itemSelectedClasses = [CONSTANTS.LANG_OPTION_SELECTED_ITEM_CLASS, CONSTANTS.LANG_OPTION_ITEM_SELECTED_SELECTOR];

        };

        var initializeEvents = function () {
            // Add function for display of the language switcher
            if ($openSwitcherControls !== undefined) {
                _.each($openSwitcherControls, function (control) {
                    coned.utils.addGeneralListeners(control, languageSwitcherClickEvent);

                });
            }

            // Add load languages function when weglot is initialize
            weglot.on('initialized', loadLanguageOptions);

            // Add function to set new language when weglot changes the language
            weglot.on('languageChanged', function(newLang, prevLang) { 
                if (prevLang && prevLang != ''){
                    // Check if the language change was a reset for moving to a localized lang, in this case the OnLanguageSwitch is called manually
                    if (newLang !== weglot.options.language_from    // Reset is always done just to the base language
                        || coned.utils.isPatternLab()){             // Reset is never perform on PatternLab
                            onLanguageSwitch(newLang);
                    }
                }
            });

            $languageOptionsContainer.addEventListener(CONSTANTS.KEYDOWN_EVENT, listBoxKeyboardHandler);
            $languageSwitcherDesktopControl.addEventListener(CONSTANTS.KEYDOWN_EVENT, switcherKeyboardHandler);
        };

        var initializeProvider = function () {
            try {
                if (!weglot){
                    throw new Error('Weglot instance cannot be found.');
                }
                // Weglot initialization
                weglot.initialize({
                    api_key: apiKey,
                    hide_switcher: true // Always hide default switcher to use the custom
                });
            } catch (error) {
                //No action to be taken
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            initializeProvider();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    LanguageSwitcher.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LanguageSwitcher;
})();