// ==================== CARD LINKS COMPONENT =========================
/* global UsageGraph */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.UsageChart = (function () {
    var CONSTANTS = {
        CONED_ENERGY_USAGE: 'energy-usage',
        USAGE_TAB: 'js-usageTab',
        CONED_CHART_DATA_MAID: 'data-maid',
        CONED_HIDDEN_CLASS: 'hidden',
        USAGE_CONTENT: '.js-usage-chart'
    };

    var isLoaded = false;

    var UsageChart = function () {
        var $conedUsageTab;
        var $conedEnergyContent;
        var accountMaid;

        var showUsageTab = function () {
            UsageGraph.init(CONSTANTS.USAGE_CONTENT, accountMaid);
            coned.utils.removeMultipleListeners(
                $conedUsageTab, 
                coned.utils.generalEvents(),
                showUsageTab
            )
        };

        var initializeData = function () {
            $conedUsageTab = document.getElementsByClassName(CONSTANTS.USAGE_TAB)[0];
            $conedEnergyContent = document.getElementsByClassName(CONSTANTS.CONED_ENERGY_USAGE)[0];
            accountMaid = $conedEnergyContent.getAttribute(CONSTANTS.CONED_CHART_DATA_MAID);
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($conedUsageTab, showUsageTab);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    UsageChart.prototype.isLoaded = function () {
        return isLoaded;
    };

    return UsageChart;
})();
