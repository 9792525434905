// ==================== REMOVE ACCOUNT COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.RemoveAccount = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        REMOVE_ACCOUNT_LINK_SELECTOR: 'js-remove-account',
        REMOVE_ACCOUNT_ERROR_SELECTOR: 'js-remove-account-error',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        FORM_LOADING: 'js-form-loading',
        FORM_LOADING_HIDDEN_CLASS: 'form-loading--hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var RemoveAccount = function ($removeAccount) {
        /**
         * PRIVATE METHODS
         */
        var $removeAccountLink, $removeAccountError, $formLoading;

        var removeAccountEvent = function (event) {
            event.preventDefault();

            hideError($removeAccountError);

            var serviceUrl = $removeAccountLink.dataset.service,
                params = {
                    Maid: $removeAccountLink.dataset.maid,
                    ScId: $removeAccountLink.dataset.scid
                };

            params = JSON.stringify(params);
            query.deleteData(
                serviceUrl,
                successRemoveAccount,
                failRemoveAccount,
                params,
                true,
                $formLoading
            );
        };

        var successRemoveAccount = function () {
            $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN_CLASS);
            window.location.href = window.location.origin + window.location.pathname;
        };

        var failRemoveAccount = function (data) {
            if (coned.utils.isPatternLab()) {
                var removeAccountService = coned.plConstants.DELETE_STOP_SERVICE_ERROR;
                query.getData(removeAccountService, errorRemoveAccount, function () {});
            } else {
                errorRemoveAccount(data);
            }
        };

        var errorRemoveAccount = function (data) {
            var $msgTextSelector = $removeAccountError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $removeAccountError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $removeAccountError.focus();
        };

        var hideError = function ($element) {
            $element.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var initializeData = function () {
            $removeAccountLink = $removeAccount.getElementsByClassName(
                CONSTANTS.REMOVE_ACCOUNT_LINK_SELECTOR
            )[0];
            $removeAccountError = document.getElementsByClassName(
                CONSTANTS.REMOVE_ACCOUNT_ERROR_SELECTOR
            )[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($removeAccountLink, removeAccountEvent);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    RemoveAccount.prototype.isLoaded = function () {
        return isLoaded;
    };

    return RemoveAccount;
})();
