// ==================== TOAST COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ToastModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HIDDEN_CLASS: 'hidden',
        BUTTON_TOAST: 'js-toast-button',
        CLOSE_BUTTON: 'js-toast-close',
        SHOW_TOAST: 'toast-show',
        SHOW_TOAST_BOTTOM_12: 'toast-show-bottom-12',
        TOAST_EXPANDED: 'js-toast--expanded',
        TOAST_MESSAGE: 'js-toast-message',
        TOAST_REUSABLE: 'js-toast-reusable',
        TOAST_NOT_ADD_ROLE_ALERT: 'js-no-dynamically-add-role-alert',
        SHOW_TOAST_EVENT: 'show-toast-event',
        TOAST_DESCRIPTION_SELECTOR: 'js-toast-description',
        FIVE_SECONDS: 5000,
        // Should match the CSS keyframes fadeout animation duration
        FADEOUT_CSS_ANIMATION_TIME: 500,

        //A11Y
        ARIA_ROLE: 'role',
        ALERT: 'alert'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ToastModule = function ($Toast) {
        /**
         * PRIVATE METHODS
         */
        var $toastCloseButtons,
            $toastMessage,
            $toastDescription,
            _toastAutoShow,
            _toastReusable,
            _positionBottom12Px;

        var closeToast = function () {
            // Two different animation classes depdending on toast bottom postion
            if (_positionBottom12Px) {
                $Toast.classList.remove(CONSTANTS.SHOW_TOAST_BOTTOM_12);
            } else {
                $Toast.classList.remove(CONSTANTS.SHOW_TOAST);
            }

            if (_toastReusable) {
                // Reset toast
                $toastMessage.removeAttribute(CONSTANTS.ARIA_ROLE);
                $Toast.classList.remove(CONSTANTS.TOAST_EXPANDED);

                // Reset display to none after CSS animation ends so alert aria role works
                setTimeout(function () {
                    $Toast.classList.add(CONSTANTS.HIDDEN_CLASS);
                }, CONSTANTS.FADEOUT_CSS_ANIMATION_TIME);
            }
        };

        var showToast = function () {
            var hasRoleAlert = query.hasClass($Toast, CONSTANTS.TOAST_NOT_ADD_ROLE_ALERT);
            // Remove display none so alert aria role is read
            $Toast.classList.remove(CONSTANTS.HIDDEN_CLASS);

            if (_toastReusable) {
                if (_positionBottom12Px) {
                    $Toast.classList.add(CONSTANTS.SHOW_TOAST_BOTTOM_12);
                } else {
                    $Toast.classList.add(CONSTANTS.SHOW_TOAST);
                }
            }

            if (!$Toast.classList.contains(CONSTANTS.TOAST_EXPANDED)) {
                // For legacy toasts, but all will probably need to be updated to use the else in order to work correctly
                if (!hasRoleAlert) {
                    // Show toast
                    $toastMessage.setAttribute(CONSTANTS.ARIA_ROLE, CONSTANTS.ALERT);
                } else {
                    // Force a DOM update to ensure screen readers detect the change
                    var toastText = $toastDescription.innerHTML;
                    $toastDescription.innerHTML = '';

                    setTimeout(function () {
                        $toastDescription.innerHTML = toastText;
                    }, 200);
                }
                $Toast.classList.add(CONSTANTS.TOAST_EXPANDED);

                setTimeout(function () {
                    closeToast();
                }, CONSTANTS.FIVE_SECONDS);
            }
        };


        /**
         * Close toast with keyboard: ESC key_code : 27
         * @param {*} event
         * @param {*} toast
         */
        var handlerToastKeyBoard = function (event, toast) {
            if (event.keyCode == coned.constants.KEY_CODE.ESC) {
                //Check if a toast is visible on the screen.
                var toastOpen = !query.hasClass(toast, CONSTANTS.SHOW_TOAST);
                if (toastOpen) {
                    closeToast(event);
                }
            }
        };

        var initializeData = function () {
            $toastCloseButtons = $Toast.getElementsByClassName(
                CONSTANTS.CLOSE_BUTTON
            );
            $toastMessage = $Toast.getElementsByClassName(
                CONSTANTS.TOAST_MESSAGE
            )[0];
            $toastDescription = $Toast.getElementsByClassName(
                CONSTANTS.TOAST_DESCRIPTION_SELECTOR
            )[0];
            _toastAutoShow = $Toast.classList.contains(
                CONSTANTS.SHOW_TOAST
            );
            _toastReusable = $Toast.classList.contains(
                CONSTANTS.TOAST_REUSABLE
            );
            _positionBottom12Px =
                window
                    .getComputedStyle($Toast, null)
                    .getPropertyValue('bottom')
                    === '12px' ?
                    true :
                    false;
        };

        var initializeEvents = function () {
            _.each($toastCloseButtons, function ($button) {
                $button.addEventListener('click', closeToast);
            });

            new coned.utils.addFocusTrap($Toast);

            // A one time use, auto show toast
            if (_toastAutoShow) {
                showToast();
            }

            // Reusable toast
            if (_toastReusable) {
                $Toast.addEventListener(CONSTANTS.SHOW_TOAST_EVENT, function () {
                    showToast();
                });
            }

            // Keyboard
            $Toast.addEventListener('keydown', function (event) {
                handlerToastKeyBoard(event, $Toast);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ToastModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ToastModule;
})();