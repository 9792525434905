// ==================== NAVIGATION BUTTONS COMPONENT =========================
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.NavigationButtons = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        BACK_BUTTON: 'js-back-button',
        CONTINUE_BUTTON: 'js-continue-button',
        CURRENT_STEP: 'js-current-step',
        STEP_SELECTOR: 'js-step',
        FIRST_STEP_NAV_BUTTONS: 'navigation-buttons__content--first-step',
        NAVIGATION_BANNER_SELECTOR: 'js-navigation-banner',
        HIDDEN_CLASS: 'hidden',
        ACCOUNT_STEP_FULL_WIDTH_CLASS: 'navigation-buttons--full-width',
        STEPS_PARENT_SELECTOR: 'js-steps-parent',
        RESULT_OVERVIEW_SELECTOR: 'js-result-overview',
        DATA_ACCOUNT_TYPE_ATTRIBUTE: 'data-account-type',
        DATA_HIDE_QUESTIONNAIRE: 'data-hide-income-questionnaire',
        COMMERCIAL_STRING: 'C',
        DATA_LAYER_EVENT_STR: 'data-layer',
        FINANCIAL_ADVISOR_TAGGING: 'FA.',
        DATA_STATUS_TAGGING: 'data-status',
        LOAD_PROGRAM_DATA: 'load-data-programs',
        QUESTIONNAIRE_EVENT: 'questionnaire',
        FINANCIAL_ADVISOR_SELECTOR: 'js-financial-advisor',
        CONED_INPUT_NUMBERS: '.js-number-input',
        CONED_INPUT_SELECTOR: 'js-coned-input',
        RADIO_BUTTON_SELECTOR: 'js-coned-radio',
        RADIO_BUTTON_FOCUS: 'coned-radio__indicator--focus',
        INPUT_SELECTOR: 'coned-input',
        ERROR_MESSAGE_SELECTOR: 'js-error-message',
        HEATING_COOLING_SELECTOR: 'js-heating-and-cooling',
        EAP_SELECTOR: 'js-eap',
        RESULT_SELECTOR: 'js-programs-result',
        ERROR_SELECTOR: 'js-error-messaging-managed',
        NO_MATCHES_SELECTOR: 'js-not-matches-found',
        CONED_FORM_SELECTOR: 'coned-form',
        CONED_FIELD_ERROR_WRAPPER: 'coned-field-error-wrapper',
        POUND_KEY: '#',
        NONE_VALUE: 'none',
        HEATINGCOOLING_SELECTOR: 'heatingCooling',
        EAP_RADIO_NAME: 'eap',
        MAIN_PAGE_SELECTOR: 'js-page-content',
        RADIO_BUTTON_ERROR_MESSAGE_SELECTOR: "radio-button-error-message-js",
        TRUE_STRING: 'true',
        CLASS_STRING: 'class',
        ATTRIBUTES_STRING: 'attributes',
        RESULTS_STRING: 'Results',
        ERROR_STRING: 'Error',
        NO_MATCHES_STRING: 'Noresults',
        CONED_INPUT_FILLED: 'coned-input--filled',
        VALIDATED_CONTINUE_SELECTOR: 'js-validated-continue',
        KEYUP_EVENT: 'keyup',
        KEYDOWN_EVENT: 'keydown',
        ENTER_VALUE: 'Enter',
        CHANGE_EVENT: 'change',
        SECOND_STEP_INT: 2,
        YES_VALUE: 'yes',
        NO_VALUE: 'no',

        // Tagging
        CLICK_YES_HEATING_TAGGING: 'Click.Yes.heating',
        CLICK_NO_HEATING_TAGGING: 'Click.No.heating',
        CLICK_YES_EAP_TAGGING: 'Click.Yes.faeap',
        CLICK_NO_EAP_TAGGING: 'Click.No.faeap'
    };

    var isLoaded = false;

    /**
     * Constructor
     */
    var NavigationButtons = function ($navigationButtons) {
        /**
         * PRIVATE METHODS
         */
        var $backButton,
            $continueButton,
            $navigationContainer,
            $stepsParent,
            $currentStep,
            $eventQuestionnaire,
            $radioButtons,
            $eapRadioButtons,
            $allRadioButtons,
            $radioButtonErrorMessage,
            $financialAdvisor,
            $inputFields,
            _eapElementExists,
            _currentStep;

        /**
         *  Next page, find next page, hide current page and show next page
         */
        var nextStep = function (event) {
            event.preventDefault();
            if ($continueButton.disabled) {
                return;
            }
            // Check if the account is commercial, find the next step and check if it is the last step or commercial account.
            // Redirect to the result if it is the last step or commercial account.
            // Subtract one from the total, this element is the banner with the continue button.
            // Hide the navigation bar if the next page is an result overview.
            var currentStep = _currentStep + 1,
                isCommercial = $stepsParent.getAttribute(CONSTANTS.DATA_ACCOUNT_TYPE_ATTRIBUTE) === CONSTANTS.COMMERCIAL_STRING,
                $result = $stepsParent.getElementsByClassName(CONSTANTS.RESULT_OVERVIEW_SELECTOR)[0],
                $steps = $stepsParent.getElementsByClassName(CONSTANTS.STEP_SELECTOR),
                $nextStep = (isCommercial || currentStep > $steps.length) ?
                    $result : $steps[currentStep - 1],
                hideNavigationContainer = query.hasClass($nextStep, CONSTANTS.RESULT_OVERVIEW_SELECTOR),
                dataLayerStr = $currentStep.getAttribute(CONSTANTS.DATA_LAYER_EVENT_STR),
                hideQuestionnaire = $stepsParent.dataset.hideIncomeQuestionnaire;


            // Event tagging by page, when press continue
            setTaggings(dataLayerStr);

            _currentStep = currentStep;

            // Event tagging by radio button selection
            setTaggingsRadioSelection();

            if (hideQuestionnaire !== CONSTANTS.TRUE_STRING) {
                // Radio button component and trigger
                if (_eapElementExists) {
                    // If EAP exists, delay the event trigger until we are on the EAP step
                    if ($currentStep.classList.contains(CONSTANTS.EAP_SELECTOR) && !isCommercial) {
                        $eventQuestionnaire = new Event(CONSTANTS.QUESTIONNAIRE_EVENT);
                        $continueButton.dispatchEvent($eventQuestionnaire);
                    }
                    // If EAP does not exist, trigger the event on the Heating and Cooling step
                } else if ($currentStep.classList.contains(CONSTANTS.HEATING_COOLING_SELECTOR) && !isCommercial) {
                    $eventQuestionnaire = new Event(CONSTANTS.QUESTIONNAIRE_EVENT);
                    $continueButton.dispatchEvent($eventQuestionnaire);
                }
            }

            // The banner will hide whether redirect to the results page.
            if (hideNavigationContainer) {

                var dataStatus,
                    eventStatus,
                    eventLoadProgramsData = new Event(CONSTANTS.LOAD_PROGRAM_DATA);

                var observer = new MutationObserver(function (mutationsList, observer) {
                    mutationsList.forEach(function (mutation) {
                        if (mutation.type === CONSTANTS.ATTRIBUTES_STRING && mutation.attributeName === CONSTANTS.CLASS_STRING) {
                            if ($result.querySelector('.' + CONSTANTS.RESULT_SELECTOR + ':not(.' + CONSTANTS.HIDDEN_CLASS + ')')) {
                                dataStatus = CONSTANTS.RESULTS_STRING;
                            } else if ($result.querySelector('.' + CONSTANTS.ERROR_SELECTOR + ':not(.' + CONSTANTS.HIDDEN_CLASS + ')')) {
                                dataStatus = CONSTANTS.ERROR_STRING;
                            } else if ($result.querySelector('.' + CONSTANTS.NO_MATCHES_SELECTOR + ':not(.' + CONSTANTS.HIDDEN_CLASS + ')')) {
                                dataStatus = CONSTANTS.NO_MATCHES_STRING;
                            } else {
                                return;
                            }

                            $result.setAttribute('data-status', dataStatus);
                            eventStatus = CONSTANTS.FINANCIAL_ADVISOR_TAGGING + dataStatus;

                            //Set tagging when show result
                            dataLayer.push({
                                event: eventStatus
                            });

                            observer.disconnect();
                        }
                    });
                });

                // Configure the observer to watch for attribute changes in child nodes
                observer.observe($result, {
                    attributes: CONSTANTS.TRUE_STRING,
                    subtree: CONSTANTS.TRUE_STRING,
                    attributeFilter: [CONSTANTS.CLASS_STRING]
                });


                if (isCommercial) {
                    $stepsParent.dataset.successQuestionnaireCallback = CONSTANTS.TRUE_STRING;
                }
                query.addClass($navigationButtons, CONSTANTS.HIDDEN_CLASS);
                // Call event in result overview
                $continueButton.dispatchEvent(eventLoadProgramsData);

            } else {
                query.removeClass($currentStep, CONSTANTS.CURRENT_STEP);
                query.removeClass($backButton, CONSTANTS.HIDDEN_CLASS);
                query.removeClass($navigationContainer, CONSTANTS.FIRST_STEP_NAV_BUTTONS);
            }

            if (_currentStep === CONSTANTS.SECOND_STEP_INT) {
                query.removeClass($navigationButtons, CONSTANTS.ACCOUNT_STEP_FULL_WIDTH_CLASS);
            }
            $continueButton.disabled = ($nextStep.getElementsByClassName(CONSTANTS.VALIDATED_CONTINUE_SELECTOR).length === 0);

            // Hide current page and show next page
            query.addClass($currentStep, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($nextStep, CONSTANTS.HIDDEN_CLASS);
            query.addClass($nextStep, CONSTANTS.CURRENT_STEP);
            validateCurrentFields($nextStep);
            setNewFocus($nextStep);
            $currentStep = $nextStep;

        };

        /**
         *
         * @param {String} dataLayerStr
         * Event that splits the data layer String, converts it to json and sets all the tags
         */
        var setTaggings = function (dataLayerStr) {
            var taggins = {};
            dataLayerStr.split(',').forEach(function (taggingStr) {
                var taggingData = taggingStr.split(':'),
                    key = taggingData[0].trim(),
                    value = taggingData[1].trim();
                taggins[key] = value;
            });
            dataLayer.push(taggins);
        }

        /**
        * Sets analytics tagging based on radio button selection
        */
        var setTaggingsRadioSelection = function () {

            if ($currentStep.classList.contains(CONSTANTS.HEATING_COOLING_SELECTOR)) {
                var selectedRadio = document.querySelector("input[name=" + CONSTANTS.HEATINGCOOLING_SELECTOR + "]:checked");
                if (selectedRadio) {
                    var selectedValue = selectedRadio.id;

                    if (selectedValue === CONSTANTS.YES_VALUE) {
                        dataLayer.push({
                            event: CONSTANTS.CLICK_YES_HEATING_TAGGING
                        });
                    } else if (selectedValue === CONSTANTS.NO_VALUE) {
                        dataLayer.push({
                            event: CONSTANTS.CLICK_NO_HEATING_TAGGING
                        });
                    }
                }

            } else if ($currentStep.classList.contains(CONSTANTS.EAP_SELECTOR)) {
                var selectedEAP = document.querySelector("input[name=" + CONSTANTS.EAP_RADIO_NAME + "]:checked");
                if (selectedEAP) {
                    var selectedEAPValue = selectedEAP.id;

                    if (selectedEAPValue === CONSTANTS.YES_VALUE) {
                        dataLayer.push({ event: CONSTANTS.CLICK_YES_EAP_TAGGING });
                    } else if (selectedEAPValue === CONSTANTS.NO_VALUE) {
                        dataLayer.push({ event: CONSTANTS.CLICK_NO_EAP_TAGGING });
                    }
                }
            }
        };

        /**
         *  Returns the previous page when the button is enabled
         */
        var backStep = function (event) {
            event.preventDefault();
            _currentStep--;
            var $currentStepShowed = $stepsParent.getElementsByClassName(CONSTANTS.CURRENT_STEP)[0],
                $backStep = $stepsParent.querySelectorAll('[data-step="' + _currentStep + '"]')[0];
            $currentStepShowed.classList.add(CONSTANTS.HIDDEN_CLASS);
            $currentStepShowed.classList.remove(CONSTANTS.CURRENT_STEP);
            $backStep.classList.add(CONSTANTS.CURRENT_STEP);
            $backStep.classList.remove(CONSTANTS.HIDDEN_CLASS);


            // Hide button if the current page is one
            if (_currentStep === 1) {
                $backButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                $navigationContainer.classList.add(CONSTANTS.FIRST_STEP_NAV_BUTTONS);
                query.addClass($navigationButtons, CONSTANTS.ACCOUNT_STEP_FULL_WIDTH_CLASS);
            }
            $currentStep = $backStep;
            $continueButton.disabled = false;
        };

        /**
         *  Add listener and functions, which to check after typing in the inputs
         */
        var validateCurrentFields = function ($nextStep) {
            var classList = $nextStep.classList,
                $form = $nextStep.getElementsByClassName(CONSTANTS.CONED_FORM_SELECTOR)[0];

            // Radio button component - Heating and Cooling
            if (classList.contains(CONSTANTS.HEATING_COOLING_SELECTOR)) {

                Array.prototype.forEach.call($radioButtons, function ($radioButton) {
                    $radioButton.addEventListener(CONSTANTS.CHANGE_EVENT, function () {
                        validateContinueButton();
                    });
                });
                return;
            }

            // Radio button component - EAP
            if (classList.contains(CONSTANTS.EAP_SELECTOR) && _eapElementExists) {

                Array.prototype.forEach.call($eapRadioButtons, function ($radioButton) {
                    $radioButton.addEventListener(CONSTANTS.CHANGE_EVENT, function () {
                        validateContinueButton();
                    });
                });
                return;
            }

            if ($form) {
                var $inputs = $form.getElementsByClassName(CONSTANTS.CONED_INPUT_SELECTOR);

                Array.prototype.forEach.call($inputs, function ($input) {
                    $input.addEventListener(CONSTANTS.KEYUP_EVENT, validateContinueButton);
                });
                // Focus on the next input
                $form.getElementsByClassName(CONSTANTS.CONED_INPUT_SELECTOR)[0].focus();
            }
        }

        /**
         *  Find a new focus in the field of the next step
         */
        var setNewFocus = function ($nextStep) {
            var $focusElement = $nextStep.closest("." + CONSTANTS.FINANCIAL_ADVISOR_SELECTOR);

            if ($focusElement) {
                $focusElement.focus();
            }
        }

        /**
         *  Check if each step has the required field filled
         *  Validate if the fields are complete, to disable or enable the continue button.
         */
        var validateContinueButton = function () {
            var disabledButton = true,
                $form = $currentStep.getElementsByClassName(CONSTANTS.CONED_FORM_SELECTOR)[0],
                formId = $form === undefined ? undefined : CONSTANTS.POUND_KEY + $form.id,
                $errorMessage;

            // Check radio button - Heating and Cooling
            if (query.hasClass($currentStep, CONSTANTS.HEATING_COOLING_SELECTOR)) {
                var notValueChecked = !$currentStep.querySelector("input[name=" + CONSTANTS.HEATINGCOOLING_SELECTOR + "]:checked");
                if (notValueChecked) {
                    $errorMessage = $currentStep.getElementsByClassName(CONSTANTS.ERROR_MESSAGE_SELECTOR);
                    query.removeClass($errorMessage, CONSTANTS.HIDDEN_CLASS);
                }
                saveContinueButtonStatus($form, notValueChecked);
                $continueButton.disabled = false;
                return;
            }

            // Check radio button - EAP
            if (query.hasClass($currentStep, CONSTANTS.EAP_SELECTOR)) {
                notValueChecked = !$currentStep.querySelector("input[name=" + CONSTANTS.EAP_RADIO_NAME + "]:checked");
                if (notValueChecked) {
                    $errorMessage = $currentStep.getElementsByClassName(CONSTANTS.ERROR_MESSAGE_SELECTOR);
                    query.removeClass($errorMessage, CONSTANTS.HIDDEN_CLASS);
                }
                saveContinueButtonStatus($form, notValueChecked);
                $continueButton.disabled = false;
                return;
            }

            // Check the form with Coned's custom validation
            if (formId) {
                var errorsFound,
                    $inputs = Array.from($form.getElementsByClassName(CONSTANTS.INPUT_SELECTOR)),
                    inputsEmpty = $inputs.find(function ($input) {
                        return !query.hasClass($input, CONSTANTS.CONED_INPUT_FILLED)
                    }) !== undefined;

                if (!inputsEmpty) {
                    $(formId).valid();
                    $errorMessage = Array.from($currentStep.getElementsByClassName(CONSTANTS.CONED_FIELD_ERROR_WRAPPER));

                    errorsFound = $errorMessage.find(function ($error) {
                        return $error.style.display !== CONSTANTS.NONE_VALUE
                    }) !== undefined;

                    disabledButton = $errorMessage.length > 0 && errorsFound;
                }
                saveContinueButtonStatus($form, disabledButton);
            }
            $continueButton.disabled = disabledButton;
        }

        /**
         * Saves the state of the save button within the form, so as not to refresh with unnecessarily disabled.
         * Previously there was a delay when enabling or disabling, this function solves that.
         * @param {*} $form 
         * @param {*} fieldRequired 
         */
        var saveContinueButtonStatus = function ($form, fieldRequired) {
            if (fieldRequired) {
                query.removeClass($form, CONSTANTS.VALIDATED_CONTINUE_SELECTOR);
            } else {
                query.addClass($form, CONSTANTS.VALIDATED_CONTINUE_SELECTOR);
            }
        }

        var initializeData = function () {
            $backButton = $navigationButtons.getElementsByClassName(CONSTANTS.BACK_BUTTON)[0];
            $continueButton = $navigationButtons.getElementsByClassName(CONSTANTS.CONTINUE_BUTTON)[0];
            $navigationContainer = $navigationButtons.getElementsByClassName(CONSTANTS.NAVIGATION_BANNER_SELECTOR)[0];
            $stepsParent = document.getElementsByClassName(CONSTANTS.STEPS_PARENT_SELECTOR)[0];
            $currentStep = $stepsParent.getElementsByClassName(CONSTANTS.CURRENT_STEP)[0];
            $radioButtons = Array.from(document.querySelectorAll("input[name=" + CONSTANTS.HEATINGCOOLING_SELECTOR + "]"));
            $allRadioButtons = document.querySelectorAll("." + CONSTANTS.RADIO_BUTTON_SELECTOR);

            // If EAP exists
            _eapElementExists = document.querySelectorAll("input[name=" + CONSTANTS.EAP_RADIO_NAME + "]").length > 0;
            $eapRadioButtons = _eapElementExists ? Array.from(document.querySelectorAll("input[name=" + CONSTANTS.EAP_RADIO_NAME + "]")) : [];

            $radioButtonErrorMessage = document.getElementsByClassName(CONSTANTS.RADIO_BUTTON_ERROR_MESSAGE_SELECTOR)[0];
            $financialAdvisor = document.getElementsByClassName(CONSTANTS.FINANCIAL_ADVISOR_SELECTOR)[0];
            $inputFields = $financialAdvisor.getElementsByClassName(CONSTANTS.CONED_INPUT_SELECTOR);
            _currentStep = 1;
        };

        /**
         * Hidden error message in radio buttons
         */
        var hiddenErrorMessage = function () {
            query.addClass($radioButtonErrorMessage, CONSTANTS.HIDDEN_CLASS);
        }

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($continueButton, nextStep);
            coned.utils.addGeneralListeners($backButton, backStep);
            $(CONSTANTS.CONED_INPUT_NUMBERS).keyup(function () {
                this.value = this.value.replace(/[^0-9]/g, '');
            });

            // Add focusin and focusout event listeners for radio buttons
            $allRadioButtons.forEach(function (radio) {
                var indicator = radio.nextElementSibling;

                radio.addEventListener('focusin', function () {
                    indicator.classList.add(CONSTANTS.RADIO_BUTTON_FOCUS);
                });

                radio.addEventListener('focusout', function () {
                    indicator.classList.remove(CONSTANTS.RADIO_BUTTON_FOCUS);
                });
            });

            Array.prototype.forEach.call($radioButtons, function ($radioButton) {
                coned.utils.addGeneralListeners($radioButton, hiddenErrorMessage);
            });

            // If EAP exists
            if (_eapElementExists) {
                Array.prototype.forEach.call($eapRadioButtons, function ($eapRadioButton) {
                    coned.utils.addGeneralListeners($eapRadioButton, hiddenErrorMessage);
                });
            }

            Array.prototype.forEach.call($inputFields, function (inputField) {
                inputField.addEventListener(CONSTANTS.KEYDOWN_EVENT, function (event) {
                    if (event.key === CONSTANTS.ENTER_VALUE) {
                        event.preventDefault();
                        nextStep(event);
                    }
                });
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    NavigationButtons.prototype.isLoaded = function () {
        return isLoaded;
    };

    return NavigationButtons;
})();
