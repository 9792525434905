// ==================== DASHBOARD PAYMENT AGREEMENT COVID TERMS COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardPaymentAgreementCovidTerms = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        COMMERCIAL: 'commercial',
        HIDDEN_CLASS: 'hidden',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        FORM_LOADING: 'js-form-loading',
        PAYMENT_AGREEMENT_FORM: 'js-payment-agreement',
        SMALL_BUSINESS_IDENTIFY_RADIO: 'paymentAgreementSmallBusinessIdentify',
        SMALL_BUSINESS_TYPE_RADIO: 'paymentAgreementSmallBusinessType',
        SMALL_BUSINESS_FINANCIAL_IMPACT_RADIO: 'paymentAgreementFinancialImpact',
        SMALL_BUSINESS_QUESTION: 'js-covid-terms-small-business-question',
        SMALL_BUSINESS_TYPE_QUESTION: 'js-covid-terms-small-business-type-question',
        SMALL_BUSINESS_FINANCIAL_IMPACT_QUESTION: 'js-covid-terms-financial-impact-question',
        COVID_TERMS_DISCLAIMER: 'js-payment-agreement-covid-disclaimer',
        PAGR_TERMS_DISCLAIMER: 'js-payment-agreement-disclaimer',
        STANDARD_TERMS_DISCLAIMER: 'js-payment-agreement-standard-disclaimer',
        FINANCIAL_IMPACT_DISCLAIMER: 'js-payment-agreement-financial-impact-disclaimer',
        QUESTION_OPTION: 'js-question-option',
        YES_ANSWER: 'js-yes-answer',
        NO_ANSWER: 'js-no-answer',
        CONTINUE_BUTTON: 'js-covid-terms-continue',
        PAYMENT_TABLE: 'js-payment-table',
        FORM_SCID: 'scId',
        SHOW_COVID_TERMS: 'show-covid-terms',
        TERM_TYPE_COVID: 'COVID',

        //Tagging
        PAYMENT_AGREEMENT_SMALL_BUSINESS_QUESTION_TAG: 'covid.payagmt.q1',
        PAYMENT_AGREEMENT_SMALL_BUSINESS_TYPE_QUESTION_TAG: 'covid.payagmt.q2',
        PAYMENT_AGREEMENT_SMALL_BUSINESS_FINANCIAL_IMPACT_QUESTION_TAG: 'covid.payagmt.q3'

    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardPaymentAgreementCovidTerms = function ($paymentAgreementCovidForm) {
        /**
         * PRIVATE METHODS
         */
        var _isCommercial,
            _currentQuestion,
            _continueToTerms,
            _covidTerms,
            $accountMaidInput,
            $formLoading,
            $smallBusinessIdentifyQuestion,
            $smallBusinnesTypeQuestion,
            $smallBusinnesFinancialImpactQuestion,
            $financialImpactDisclaimer,
            $covidTermsDisclaimer,
            $pagrTermsDisclaimer,
            $standardTermsDisclaimer,
            $paymentAgreementForm,
            $paymentTable;

        var processQuestionAnswer = function () {
            var answer,
                $options,
                $yesAnswer,
                $noAnswer,
                valid;

            if (_continueToTerms) {
                showCovidTerms();
                return;
            }

            if (_currentQuestion === CONSTANTS.SMALL_BUSINESS_QUESTION) {
                answer = query.getRadioInputValue($paymentAgreementCovidForm, CONSTANTS.SMALL_BUSINESS_IDENTIFY_RADIO); 
                valid = $($paymentAgreementCovidForm.querySelectorAll('[name="' + CONSTANTS.SMALL_BUSINESS_IDENTIFY_RADIO + '"]')).valid(),
                $options = $smallBusinessIdentifyQuestion.getElementsByClassName(CONSTANTS.QUESTION_OPTION);

                if(valid) {
                    if(answer === 'YES') {
                        $yesAnswer = $smallBusinessIdentifyQuestion.getElementsByClassName(CONSTANTS.YES_ANSWER)[0];
                        $yesAnswer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $smallBusinnesTypeQuestion.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        _currentQuestion = CONSTANTS.SMALL_BUSINESS_TYPE_QUESTION;
                        $smallBusinnesTypeQuestion.focus();
                    }else {
                        $noAnswer = $smallBusinessIdentifyQuestion.getElementsByClassName(CONSTANTS.NO_ANSWER)[0];
                        $noAnswer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $pagrTermsDisclaimer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $pagrTermsDisclaimer.focus();
                        _continueToTerms = true;
                    }

                    //Tagging
                    dataLayer.push({
                        event: CONSTANTS.PAYMENT_AGREEMENT_SMALL_BUSINESS_QUESTION_TAG,
                        questionAnswer: answer
                    });
                }

            } else if (_currentQuestion === CONSTANTS.SMALL_BUSINESS_TYPE_QUESTION) {
                answer = query.getRadioInputValue($paymentAgreementCovidForm, CONSTANTS.SMALL_BUSINESS_TYPE_RADIO); 
                valid = $($paymentAgreementCovidForm.querySelectorAll('[name="' + CONSTANTS.SMALL_BUSINESS_TYPE_RADIO + '"]')).valid(),
                $options = $smallBusinnesTypeQuestion.getElementsByClassName(CONSTANTS.QUESTION_OPTION);

                if (valid) {
                    if (answer === 'NO') {
                        $noAnswer = $smallBusinnesTypeQuestion.getElementsByClassName(CONSTANTS.NO_ANSWER)[0];
                        $noAnswer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $smallBusinnesFinancialImpactQuestion.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        _currentQuestion = CONSTANTS.SMALL_BUSINESS_FINANCIAL_IMPACT_QUESTION;
                        $smallBusinnesFinancialImpactQuestion.focus();

                    } else {
                        $yesAnswer = $smallBusinnesTypeQuestion.getElementsByClassName(CONSTANTS.YES_ANSWER)[0];
                        $yesAnswer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $pagrTermsDisclaimer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $pagrTermsDisclaimer.focus();
                        _continueToTerms = true;
                    }  

                    //Tagging
                    dataLayer.push({
                        event: CONSTANTS.PAYMENT_AGREEMENT_SMALL_BUSINESS_TYPE_QUESTION_TAG,
                        questionAnswer: answer
                    });
                }             

            } else if (_currentQuestion === CONSTANTS.SMALL_BUSINESS_FINANCIAL_IMPACT_QUESTION) {
                answer = query.getRadioInputValue($paymentAgreementCovidForm, CONSTANTS.SMALL_BUSINESS_FINANCIAL_IMPACT_RADIO); 
                valid = $($paymentAgreementCovidForm.querySelectorAll('[name="' + CONSTANTS.SMALL_BUSINESS_FINANCIAL_IMPACT_RADIO + '"]')).valid(),
                $options = $smallBusinnesFinancialImpactQuestion.getElementsByClassName(CONSTANTS.QUESTION_OPTION);

                if (valid) {
                    if (answer === 'YES') {
                        $yesAnswer = $smallBusinnesFinancialImpactQuestion.getElementsByClassName(CONSTANTS.YES_ANSWER)[0];
                        $yesAnswer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                        sendCovidImpactedTerms();
                        _covidTerms = true;
                        $covidTermsDisclaimer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $covidTermsDisclaimer.focus();

                        //Tagging
                        dataLayer.push({
                           covidPagrElegible: true
                       });

                    } else {
                        $noAnswer = $smallBusinnesFinancialImpactQuestion.getElementsByClassName(CONSTANTS.NO_ANSWER)[0];
                        $noAnswer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                        _covidTerms = false;
                        $standardTermsDisclaimer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        $standardTermsDisclaimer.focus();

                        //Tagging
                        dataLayer.push({
                            covidPagrElegible: false
                        });
                    }

                    $financialImpactDisclaimer.classList.add(CONSTANTS.HIDDEN_CLASS);
                    _continueToTerms = true;

                    //Tagging
                    dataLayer.push({
                        event: CONSTANTS.PAYMENT_AGREEMENT_SMALL_BUSINESS_FINANCIAL_IMPACT_QUESTION_TAG,
                        questionAnswer: answer
                    });
                }
            }

            if (valid) {
                _.each($options, function ($option) {
                    $option.classList.add(CONSTANTS.HIDDEN_CLASS);
                });
            }

        };

        var sendCovidImpactedTerms = function () {
            var serviceUrl = $paymentAgreementCovidForm.dataset.covidIdentifyServiceUrl,
            params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($paymentAgreementForm, CONSTANTS.FORM_SCID),
                AttestationName: CONSTANTS.TERM_TYPE_COVID
            };

            query.postData(
                serviceUrl,
                function () {},
                function () {},
                JSON.stringify(params),
                true,
                $formLoading
            );
        };

        var showCovidTerms = function () {
            $paymentTable.classList.add(CONSTANTS.SHOW_COVID_TERMS);
            $paymentTable.dataset.covidTerms = _covidTerms;
        };


        var initializeData = function () {
            _isCommercial = $paymentAgreementCovidForm.dataset.accountType === CONSTANTS.COMMERCIAL ? true : false;
            _currentQuestion = CONSTANTS.SMALL_BUSINESS_QUESTION;
            _continueToTerms = false;
            _covidTerms = false;
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $smallBusinessIdentifyQuestion = $paymentAgreementCovidForm.getElementsByClassName(CONSTANTS.SMALL_BUSINESS_QUESTION)[0];
            $smallBusinnesTypeQuestion = $paymentAgreementCovidForm.getElementsByClassName(CONSTANTS.SMALL_BUSINESS_TYPE_QUESTION)[0];
            $smallBusinnesFinancialImpactQuestion = $paymentAgreementCovidForm.getElementsByClassName(CONSTANTS.SMALL_BUSINESS_FINANCIAL_IMPACT_QUESTION)[0];
            $covidTermsDisclaimer = $paymentAgreementCovidForm.getElementsByClassName(CONSTANTS.COVID_TERMS_DISCLAIMER)[0];
            $pagrTermsDisclaimer = $paymentAgreementCovidForm.getElementsByClassName(CONSTANTS.PAGR_TERMS_DISCLAIMER)[0];
            $standardTermsDisclaimer = $paymentAgreementCovidForm.getElementsByClassName(CONSTANTS.STANDARD_TERMS_DISCLAIMER)[0];
            $financialImpactDisclaimer = $paymentAgreementCovidForm.getElementsByClassName(CONSTANTS.FINANCIAL_IMPACT_DISCLAIMER)[0];
            $paymentAgreementForm = document.getElementsByClassName(CONSTANTS.PAYMENT_AGREEMENT_FORM)[0];
            $paymentTable = $paymentAgreementForm.getElementsByClassName(CONSTANTS.PAYMENT_TABLE)[0];
        };

        var initializeEvents = function () {
            if(_isCommercial) {
                $paymentAgreementCovidForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            new coned.components.ValidateForm('.js-payment-agreement-covid-enroll', processQuestionAnswer);
            
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData()
            initializeEvents();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     DashboardPaymentAgreementCovidTerms.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardPaymentAgreementCovidTerms;
})();
