// ==================== SMART ENERGY COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SmartEnergyOptOutMobile = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SMART_ENERGY_MODULE: 'js-smart-energy-contain',
        OPT_OUT_START_FORM: 'js-opt-out-start',
        OPT_OUT_REASON_FORM: 'js-opt-out-reason',
        OPT_OUT_FINALIZE_FORM: 'js-opt-out-finalize',
        OPT_OUT_SUCCESS_FORM: 'js-opt-out-success',
        OPT_OUT_ERROR_FORM: 'js-opt-out-error',
        OPT_OUT_LOADING_ERROR: 'js-form-loading',
        OPT_OUT_COMMENT_FIELD: 'js-opt-out-comment',
        OPT_OUT_REQUESTED_ERROR_FORM: 'js-opt-out-requested-error',
        MONEY_RADIO_OPTION: 'js-money-option',
        UNKNOWN_RADIO_OPTION: 'js-unknown-option',
        PEAK_RADIO_OPTION: 'js-peak-option',
        OTHER_RADIO_OPTION: 'js-other-option',
        COMMENT_OPT_OUT: 'js-opt-out-reason-comment',
        NEXT_OPT_OUT_BUTTON: 'js-opt-out-next',
        OTHER_OPTION_LABEL_TEXT: 'js-other-option-label',
        DATA_IS_EMAIL_REQUEST: 'data-is-email-request',
        DATA_MAID: 'data-maid',
        EMAIL_REQUEST: 'EmailRequest',
        HIDDEN_CLASS: 'hidden',
        REASON_FORM_0PTION: 'reason',
        MONEY_OPTION: 'money',
        HOURS_OPTION: 'peak',
        UNDERSTAND_OPTION: 'know',
        OTHER_OPTION: 'Other',
        TAGGING_MONEY: 'Save Money',
        TAGGING_HOURS: 'Hours',
        TAGGING_UNDERSTAND: 'Understand',
        OTHER_FORM_OPTION: 'Other',
        OTHER_REQUIRED_TEXT: 'Other*',
        COMMENT_TEXT_AREA: 'otherComment'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var SmartEnergyOptOutMobile = function ($smartEnergyMobile) {
        /**
         * PRIVATE METHODS
         */
        var $smartEnergyModule,
            $optOutStartForm,
            $optOutReasonForm,
            $optOutFinalizeForm,
            $optOutSuccessForm,
            $optOutErrorForm,
            $commentOptOut,
            $otherOption,
            $moneyOption,
            $unknownOption,
            $peakOption,
            $nextButton,
            $formLoading,
            $commentField,
            $alreadyRequestedErrorOptOutForm;

        var startOptOutForm = function () {
            $optOutStartForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $optOutReasonForm.classList.remove(CONSTANTS.HIDDEN_CLASS);

            dataLayer.push({
                event: 'ipp.authoptout.start.2'
            });
        };

        var reasonOptOutForm = function () {
            var reason,
                reasonValue = query.getFormInputValue(
                    $smartEnergyMobile,
                    CONSTANTS.REASON_FORM_0PTION
                );

            if (reasonValue.includes(CONSTANTS.MONEY_OPTION)) {
                reason = CONSTANTS.TAGGING_MONEY;
            } else if (reasonValue.includes(CONSTANTS.HOURS_OPTION)) {
                reason = CONSTANTS.TAGGING_HOURS;
            } else if (reasonValue.includes(CONSTANTS.UNDERSTAND_OPTION)) {
                reason = CONSTANTS.TAGGING_UNDERSTAND;
            } else {
                reason = CONSTANTS.OTHER_FORM_OPTION;
            }

            window.scrollTo(0, 0);

            $optOutReasonForm.classList.add(CONSTANTS.HIDDEN_CLASS);
            $optOutFinalizeForm.classList.remove(CONSTANTS.HIDDEN_CLASS);

            dataLayer.push({
                event: 'ipp.authoptout.reason.2',
                optoutReason: reason + '2'
            });
        };

        var finalizeOptOutForm = function () {
            submitEvent();
        };

        var submitEvent = function () {
            // event.preventDefault();
            var serviceUrl = $smartEnergyMobile.dataset.serviceUrl,
                maid,
                params,
                userName,
                userEmail,
                accountNumber,
                isEmailRequest,
                reasonValue = query.getFormInputValue(
                    $smartEnergyMobile,
                    CONSTANTS.REASON_FORM_0PTION
                ),
                comment = '';

            userName = $smartEnergyModule.dataset.userName;
            userEmail = $smartEnergyModule.dataset.userEmail;
            accountNumber = $smartEnergyModule.dataset.accountNumber;
            isEmailRequest = $smartEnergyMobile.getAttribute(CONSTANTS.DATA_IS_EMAIL_REQUEST);
            maid = $smartEnergyModule.getAttribute(CONSTANTS.DATA_MAID);

            if (reasonValue == CONSTANTS.OTHER_FORM_OPTION) {
                comment = query.getFormInputValue($smartEnergyMobile, CONSTANTS.COMMENT_TEXT_AREA);
            }

            // Service Data
            params = {
                AccountMaid: maid,
                Name: userName,
                Email: userEmail,
                Account: accountNumber,
                Reason: reasonValue,
                Comment: comment
            };

            if (isEmailRequest) {
                params[CONSTANTS.EMAIL_REQUEST] = isEmailRequest;
            }

            // Service Call
            params = JSON.stringify(params);
            query.postData(serviceUrl, successReason, errorReason, params, true, $formLoading);
        };

        var successReason = function (data) {
            var confMessage;
            if (coned.utils.isPatternLab() || (data != undefined && data.Success === true)) {
                $optOutFinalizeForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $optOutSuccessForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                confMessage = 'Success2';
            } else {
                $optOutFinalizeForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $optOutErrorForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                confMessage = 'Failure2';
            }
            dataLayer.push({
                event: 'ipp.authoptout.submit.2',
                confMessage: confMessage
            });
        };

        var errorReason = function (data) {
            if (data.errorMsg === 'AlreadyRequested') {
                $optOutFinalizeForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $alreadyRequestedErrorOptOutForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $optOutFinalizeForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $optOutErrorForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            dataLayer.push({
                event: 'ipp.authoptout.submit.2',
                confMessage: 'Failure2'
            });
        };

        var showCommentText = function () {
            $nextButton.disabled = true;
            $commentOptOut.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OTHER_OPTION_LABEL_TEXT
            )[0].innerText = CONSTANTS.OTHER_REQUIRED_TEXT;
        };

        var hideCommentText = function () {
            $commentField.value = '';
            $nextButton.disabled = false;
            $commentOptOut.classList.add(CONSTANTS.HIDDEN_CLASS);
            $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OTHER_OPTION_LABEL_TEXT
            )[0].innerText = CONSTANTS.OTHER_FORM_OPTION;
        };

        var ableNextButton = function () {
            var comment = query.getFormInputValue($smartEnergyMobile, CONSTANTS.COMMENT_TEXT_AREA);
            if (comment !== '') {
                $nextButton.disabled = false;
            } else {
                $nextButton.disabled = true;
            }
        };

        var initializeData = function () {
            $smartEnergyModule = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.SMART_ENERGY_MODULE
            )[0];
            $optOutStartForm = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OPT_OUT_START_FORM
            )[0];
            $optOutReasonForm = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OPT_OUT_REASON_FORM
            )[0];
            $optOutFinalizeForm = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OPT_OUT_FINALIZE_FORM
            )[0];
            $optOutSuccessForm = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OPT_OUT_SUCCESS_FORM
            )[0];
            $optOutErrorForm = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OPT_OUT_ERROR_FORM
            )[0];
            $alreadyRequestedErrorOptOutForm = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OPT_OUT_REQUESTED_ERROR_FORM
            )[0];
            $moneyOption = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.MONEY_RADIO_OPTION
            )[0];
            $unknownOption = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.UNKNOWN_RADIO_OPTION
            )[0];
            $peakOption = $smartEnergyMobile.getElementsByClassName(CONSTANTS.PEAK_RADIO_OPTION)[0];
            $otherOption = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OTHER_RADIO_OPTION
            )[0];
            $commentOptOut = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.COMMENT_OPT_OUT
            )[0];
            $nextButton = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.NEXT_OPT_OUT_BUTTON
            )[0];
            $commentField = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OPT_OUT_COMMENT_FIELD
            )[0];
            $formLoading = $smartEnergyMobile.getElementsByClassName(
                CONSTANTS.OPT_OUT_LOADING_ERROR
            )[0];
        };

        var initializeEvents = function () {
            new coned.components.ValidateForm(
                '.' + CONSTANTS.OPT_OUT_START_FORM,
                startOptOutForm,
                ''
            );
            new coned.components.ValidateForm(
                '.' + CONSTANTS.OPT_OUT_REASON_FORM,
                reasonOptOutForm,
                ''
            );
            new coned.components.ValidateForm(
                '.' + CONSTANTS.OPT_OUT_FINALIZE_FORM,
                finalizeOptOutForm,
                ''
            );

            coned.utils.addGeneralListeners($moneyOption, hideCommentText);
            coned.utils.addGeneralListeners($unknownOption, hideCommentText);
            coned.utils.addGeneralListeners($peakOption, hideCommentText);
            coned.utils.addGeneralListeners($otherOption, showCommentText);

            if ($commentOptOut != undefined) {
                $commentOptOut.addEventListener('keyup', ableNextButton);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SmartEnergyOptOutMobile.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SmartEnergyOptOutMobile;
})();
