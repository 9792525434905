// ==================== ADD ACCOUNT NOTIFICATION COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AddAccountNotification = (function () {
    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var AddAccountNotification = function () {
        var initializeEvents = function () {
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/add-account/pending-accounts'
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AddAccountNotification.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AddAccountNotification;
})();
