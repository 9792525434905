// ==================== REGISTER PROVIDER VALIDATION COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.RegisterProviderValidation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        FORM_CLASS_SELECTOR: '.js-register-form',
        SUCCESS_BANNER: 'js-finish-selector',
        ARTICLE_HEADER_CLASS: 'js-article-header',
        SUCCESS_BANNER_TITLE: 'js-success-email',
        HIDDEN_CLASS: 'hidden',
        PRE_REGISTER_SITECORE_ID: 'ScId',
        COMPANY_NAME: 'companyName',
        FIRST_NAME: 'firstName',
        LAST_NAME: 'lastName',
        PHONE_NUMBER: 'phoneNumber',
        PHONE_EXTENSION: 'extension',
        EMAIL_ADDRESS: 'emailAddress',
        ERROR_LABEL: 'js-error-label',
        ERROR_MESSAGE: 'js-error-message',
        CHECKBOX: 'js-checkbox',
        SITECORE_ID: 'ScId',
        COMPANY_NAME_INPUT: 'js-company-name-input',
        FIRST_NAME_INPUT: 'js-first-name-input',
        LAST_NAME_INPUT: 'js-last-name-input',
        PHONE_NUMBER_INPUT: 'js-phone-number-input',
        EMAIL_ADDRESS_INPUT: 'js-email-address-input'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var RegisterProviderValidation = function ($register) {
        /**
         * PRIVATE METHODS
         */
        var $submitAnimationButton,
            $submitButton,
            $successBanner,
            $articleHeader,
            $errorLabel,
            $errorMessage,
            $companyNameInput,
            $firstNameInput,
            $LastNameInput,
            $phoneNumberInput,
            $emailAddressInput,
            recaptcha,
            _data;

        var registerProviderCall = function () {
            hideError();

            var params,
                registerProviderUrl = $register.dataset.registerProviderService;

            params = {
                ScId: query.getFormInputValue($register, CONSTANTS.SITECORE_ID),
                CompanyName: query.getFormInputValue($register, CONSTANTS.COMPANY_NAME),
                FirstName: query.getFormInputValue($register, CONSTANTS.FIRST_NAME),
                LastName: query.getFormInputValue($register, CONSTANTS.LAST_NAME),
                PhoneNumber: query.getFormInputValue($register, CONSTANTS.PHONE_NUMBER),
                PhoneExtension: query.getFormInputValue($register, CONSTANTS.PHONE_EXTENSION),
                Email: query.getFormInputValue($register, CONSTANTS.EMAIL_ADDRESS)
            };

            params = JSON.stringify(params);
            query.postData(
                registerProviderUrl,
                successRegisterProviderCall,
                errorRegisterProviderCall,
                params,
                true
            );
        };

        var successRegisterProviderCall = function () {
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        var errorRegisterProviderCall = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var successRegisterProviderCallback = function () {
            query.removeClass($successBanner, CONSTANTS.HIDDEN_CLASS);
            $register.classList.add(CONSTANTS.HIDDEN_CLASS);
            query.addClass($articleHeader, CONSTANTS.HIDDEN_CLASS);

            // Analytics data building
            dataLayer.push({
                event: 'green.button',
                gbc: 'step1.5'
            });
        };

        var errorRegisterProviderCallback = function () {
            var errorMsg = _data.errorMsg ? _data.errorMsg : coned.constants.ERROR_MESSAGE;
            $errorLabel.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $errorMessage.innerHTML = errorMsg;
            $errorLabel.focus();
        };

        var hideError = function () {
            $errorLabel.classList.add(CONSTANTS.HIDDEN_CLASS);
            $errorMessage.innerHTML = '';
        };

        var registerProviderSubmit = function () {
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                registerProviderCall,
                successRegisterProviderCallback,
                errorRegisterProviderCallback,
                false,
                true
            );
        };

        var registerProviderRecaptchaValidation = function () {
            recaptcha.checkRecaptcha();
        };

        var enableButton = function () {
            if (
                $companyNameInput.value.length === 0 ||
                $firstNameInput.value.length === 0 ||
                $LastNameInput.value.length === 0 ||
                $phoneNumberInput.value.length === 0 ||
                $emailAddressInput.value.length === 0
            ) {
                $submitButton.disabled = true;
            } else {
                $submitButton.disabled = false;
            }
        };

        var initializeData = function () {
            $successBanner = document.getElementsByClassName(CONSTANTS.SUCCESS_BANNER)[0];
            $submitAnimationButton = $register.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            $articleHeader = document.getElementsByClassName(CONSTANTS.ARTICLE_HEADER_CLASS)[0];
            $errorLabel = $register.getElementsByClassName(CONSTANTS.ERROR_LABEL)[0];
            $errorMessage = $register.getElementsByClassName(CONSTANTS.ERROR_MESSAGE)[0];
            $submitButton = $register.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];

            // Inputs
            $companyNameInput = $register.getElementsByClassName(CONSTANTS.COMPANY_NAME_INPUT)[0];
            $firstNameInput = $register.getElementsByClassName(CONSTANTS.FIRST_NAME_INPUT)[0];
            $LastNameInput = $register.getElementsByClassName(CONSTANTS.LAST_NAME_INPUT)[0];
            $phoneNumberInput = $register.getElementsByClassName(CONSTANTS.PHONE_NUMBER_INPUT)[0];
            $emailAddressInput = $register.getElementsByClassName(CONSTANTS.EMAIL_ADDRESS_INPUT)[0];
        };

        var initializeEvents = function () {
            // form validation
            new coned.components.ValidateForm(
                CONSTANTS.FORM_CLASS_SELECTOR,
                registerProviderRecaptchaValidation
            );

            // Button enabling per input's value
            $companyNameInput.addEventListener('input', enableButton);
            $firstNameInput.addEventListener('input', enableButton);
            $LastNameInput.addEventListener('input', enableButton);
            $phoneNumberInput.addEventListener('input', enableButton);
            $firstNameInput.addEventListener('input', enableButton);
            $emailAddressInput.addEventListener('input', enableButton);

            $submitButton.disabled = true;

            recaptcha = new coned.components.Recaptcha(
                $register,
                registerProviderSubmit,
                registerProviderSubmit
            );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    RegisterProviderValidation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return RegisterProviderValidation;
})();
