// ==================== DASHBOARD THIRD PARTY COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardThirdParty = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Tab Content Constants
        TAB_CONTENT: 'js-third-party-tab-content',

        // Customer Accounts Constants
        CUSTOMER_ACCOUNTS_LAZY_LOAD: 'js-lazy-load-customer-accounts',
        CUSTOMER_ACCOUNTS_TAB_CONTENT: 'js-customer-accounts-tab-content',

        // Request Access Constants
        REQUEST_ACCESS_TAB: 'js-request-access-tab',
        REQUEST_ACCESS_TAB_CONTENT: 'js-request-access-tab-content',
        REQUEST_ACCESS_FORM: 'js-request-access-form',
        REQUEST_ACCESS_SUBFORM_CONTAINER: 'js-request-access-subform-container',
        REQUEST_ACCESS_CURRENT_ACCOUNTS_AMOUNT: 'js-current-accounts-amount',
        REQUEST_ACCESS_PENDING_REQUESTS_AMOUNT: 'js-pending-requests-amount',
        REQUEST_ACCESS_INPUT: 'js-request-access-account-numbers',
        REQUEST_ACCESS_INPUT_NAME: 'requestAccountNumberList',
        REQUEST_ACCESS_COUNTER: 'js-request-access-account-counter',
        REQUEST_ACCESS_SUBMIT_BUTTON: 'js-request-access-button',
        REQUEST_ACCESS_LINK: 'js-request-access-link',
        REQUEST_ACCESS_REGISTER_MESSAGES: 'js-request-access-register-message',

        // Request Access Response Constants
        REQUEST_ACCESS_RESPONSE: 'js-request-access-response',
        REQUEST_ACCESS_RESPONSE_SUCCESS: 'js-request-access-response-success',
        REQUEST_ACCESS_RESPONSE_SUCCESS_TITLE: 'js-request-access-response-success-title',
        REQUEST_ACCESS_RESPONSE_SUCCESS_DESCRIPTION:
            'js-request-access-response-success-description',
        REQUEST_ACCESS_RESPONSE_ERROR: 'js-request-access-response-error',
        REQUEST_ACCESS_RESPONSE_ERROR_TITLE: 'js-request-access-response-error-title',
        REQUEST_ACCESS_RESPONSE_ERROR_DESCRIPTION: 'js-request-access-response-error-description',
        REQUEST_ACCESS_RESPONSE_ERROR_ICON: 'js-request-access-response-error-icon',
        REQUEST_ACCESS_RESPONSE_ERROR_LIST: 'js-request-access-response-error-list',
        REQUEST_ACCESS_RESPONSE_ERROR_LIST_CONTAINER:
            'js-request-access-response-error-list-container',
        REQUEST_ACCESS_RESPONSE_ERROR_LIST_ELEMENT:
            'dashboard-third-party__response-error-list-element',
        REQUEST_ACCESS_RESPONSE_BUTTON: 'js-request-access-try-again',
        REQUEST_ACCESS_RESPONSE_REGISTER_BUTTON: 'js-request-access-register-profile',

        // Request Access Popup Constants
        REQUEST_ACCESS_POPUP_LOADING: 'js-request-access-loading-popup',
        REQUEST_ACCESS_POPUP_LOADING_TITLE: 'js-loading-popup-title',
        REQUEST_ACCESS_POPUP_LOADING_ANIMATION: 'js-loading-animation',
        REQUEST_ACCESS_POPUP_LOADING_ANIMATION_PLAY: 'js-animation-play',

        // Pending Access Constants
        REQUEST_ACCESS_LAZY_LOAD: 'js-lazy-load-request-access',
        PENDING_ACCESS_TABLE: 'js-pending-access',
        PENDING_ACCESS_CHECKBOX_INPUT: 'js-third-party-checkbox',
        PENDING_ACCESS_CHECKBOX_CONTAINER: 'js-third-party-checkbox-container',

        // Pending Access Element Creation Constants
        ACCOUNT_ITEM_ROW_CLASSES: ['js-item-ignore'],
        ACCOUNT_ITEM_LABEL_CONTAINER_CLASSES: ['invisible'],
        ACCOUNT_ITEM_LABEL_ACCOUNT_CLASS: [
            'dashboard-third-party-accounts__table-item--pending-account'
        ],
        ACCOUNT_ITEM_LABEL_REQUEST_CLASS: ['dashboard-third-party-accounts__table-item--request'],
        ACCOUNT_ITEM_INPUT_NAME: 'pendingAccountsCheckBox',

        // Tagging Constants
        TAGGING_GENERAL_PREFFIX: 'Pilot.Reg.3P.',
        TAGGING_REQUEST_ACCESS_CTA: 'Step07.Request.Access.CTA',

        // General Constants
        DIV_TAG: 'div',
        SPAN_TAG: 'span',
        BUTTON_TAG: 'button',

        DATE_TOKEN: '@DATE',
        AMOUNT_TOKEN: '@AMOUNT',
        PERCENTAGE_TOKEN: '@PERCENTAGE',

        BATCH_AMOUNT: 5,

        SITECORE_ID: 'ScId',
        TAB_URL_PARAMETER_NAME: 'tab',
        ACCOUNT_SUFFIX: '--account',
        DESCRIPTION_SUFFIX: '--description',
        COUNTER_ERROR_CLASS: 'dashboard-third-party__textarea-counter--error',
        RESPONSE_CONTAINER_BORDER_CLASS: 'dashboard-third-party__response-container--border',
        NEXT_FOCUS_QUERY: 'p:not(.hidden) [tabindex="0"], textarea:not(.hidden)',
        LOGGED_IN_CLASS: 'primary-nav-item--logged-in',
        FORM_LOADING: 'js-form-loading',
        TAB_CONTAINER: 'js-conedtab-section-dashboard-third-party',
        LAZY_LOAD_CLASS: 'js-lazy-load',
        LAZY_LOAD_LOADED_CLASS: 'js-lazy-load-element-loaded',
        FILLED_INPUT_CLASS: 'coned-input--filled',
        VALID_CLASS: 'valid',
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardThirdParty = function ($dashboardThirdParty) {
        /**
         * PRIVATE VARIABLES
         */

        var // Customer Accounts Variables
            $customerAccountsLazyLoad,
            $customerAccountsTabContent,
            // Request Access Variables
            $requestAccessLazyLoad,
            $requestAccessTab,
            $requestAccessTabContent,
            $requestAccessForm,
            $requestAccessSubformContainer,
            $requestAccessCurrentAccountsAmount,
            $requestAccessPendingRequestsAmount,
            $requestAccessTryAgain,
            $requestAccessRegister,
            $requestAccessInput,
            $requestAccessCounter,
            $requestAccessSubmitButton,
            $requestAccessResponse,
            $requestAccessResponseSuccess,
            $requestAccessResponseSuccessTitle,
            $requestAccessResponseSuccessDescription,
            $requestAccessResponseError,
            $requestAccessResponseErrorTitle,
            $requestAccessResponseErrorDescription,
            $requestAccessResponseErrorIcon,
            $requestAccessResponseErrorList,
            $requestAccessResponseErrorListContainer,
            $requestAccessRegisterMessages,
            _updatedCounters,
            _requestAccessScenario,
            _inputArray,
            _maxAccounts,
            _resetInput,
            _processedCounter,
            _totalAccountsAmount,
            _requestData,
            _responseData,
            // Request Access Popup Variable
            $requestAccessLoadingPopup,
            $requestAccessLoadingAnimation,
            $requestAccessLoadingTitle,
            // Pending Access Variables
            $pendingAccessTable,
            // General Variables
            $formLoading;

        /**
         * PRIVATE METHODS
         */

        /**
         * Handles the lazy load trigger
         */
        var lazyLoadCustomerAccountsTrigger = function () {
            var serviceUrl = $customerAccountsTabContent.dataset.loadAccountsServiceUrl;

            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_CUSTOMER_ACCOUNTS_HTML,
                    successLoadCustomerAccounts,
                    errorLoadCustomerAccounts,
                    true,
                    $formLoading
                );
            } else {
                query.getData(
                    serviceUrl,
                    successLoadCustomerAccounts,
                    errorLoadCustomerAccounts,
                    true,
                    $formLoading
                );
            }
        };

        /**
         * Handles the lazy load trigger
         */
        var lazyLoadRequestAccessTrigger = function () {
            var serviceUrl = $requestAccessTabContent.dataset.loadAccountsServiceUrl;

            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_REQUEST_ACCESS_HTML,
                    successLoadRequestAccess,
                    errorLoadRequestAccess,
                    true,
                    $formLoading
                );
            } else {
                query.getData(
                    serviceUrl,
                    successLoadRequestAccess,
                    errorLoadRequestAccess,
                    true,
                    $formLoading
                );
            }
        };

        /**
         * Set the accounts or hide the module if the response succeeded
         */
        var successLoadCustomerAccounts = function (data, status) {
            setTabContent(data, status, $customerAccountsTabContent, errorLoadCustomerAccounts);

            // Initialize elements
            coned.utils.initializeModules($customerAccountsTabContent);

            // Remove lazy-load listener since everything is loaded already
            $customerAccountsLazyLoad[0].removeEventListener(
                'lazy-load-start',
                lazyLoadCustomerAccountsTrigger
            );
            $customerAccountsTabContent.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);
            coned.utils.triggerEvent($customerAccountsTabContent, 'lazy-load-element-loaded');
        };

        /**
         * If response failed, show an error and hide the module
         */
        var errorLoadCustomerAccounts = function (data, status) {
            if (status === 302) {
                location.reload();
            }

            // Remove lazy-load listener since everything is loaded already
            $customerAccountsLazyLoad[0].removeEventListener(
                'lazy-load-start',
                lazyLoadCustomerAccountsTrigger
            );
            $customerAccountsTabContent.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);
            coned.utils.triggerEvent($customerAccountsTabContent, 'lazy-load-element-loaded');
        };

        /**
         * Set the accounts or hide the module if the response succeeded
         */
        var successLoadRequestAccess = function (data, status) {
            setTabContent(data, status, $requestAccessTabContent, errorLoadRequestAccess);

            // Initialize elements
            coned.utils.initializeModules($requestAccessTabContent);

            // Request Access
            $requestAccessForm = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_FORM
            )[0];
            $requestAccessSubformContainer = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_SUBFORM_CONTAINER
            )[0];
            $requestAccessInput = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_INPUT
            )[0];
            $requestAccessCounter = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_COUNTER
            )[0];
            $requestAccessSubmitButton = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_SUBMIT_BUTTON
            )[0];
            $requestAccessCurrentAccountsAmount = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_CURRENT_ACCOUNTS_AMOUNT
            )[0];
            $requestAccessPendingRequestsAmount = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_PENDING_REQUESTS_AMOUNT
            )[0];
            $requestAccessSubformContainer = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_SUBFORM_CONTAINER
            )[0];
            _maxAccounts =
                $requestAccessInput &&
                $requestAccessInput.dataset.maxAccounts &&
                $requestAccessInput.dataset.maxAccounts !== '' &&
                parseInt($requestAccessInput.dataset.maxAccounts) > 0
                    ? parseInt($requestAccessInput.dataset.maxAccounts)
                    : 50;

            // Request Access Response
            $requestAccessResponse = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE
            )[0];
            $requestAccessResponseSuccess = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_SUCCESS
            )[0];
            $requestAccessResponseSuccessTitle = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_SUCCESS_TITLE
            )[0];
            $requestAccessResponseSuccessDescription = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_SUCCESS_DESCRIPTION
            )[0];
            $requestAccessResponseError = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_ERROR
            )[0];
            $requestAccessResponseErrorTitle = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_ERROR_TITLE
            )[0];
            $requestAccessResponseErrorDescription = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_ERROR_DESCRIPTION
            )[0];
            $requestAccessResponseErrorIcon = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_ERROR_ICON
            )[0];
            $requestAccessResponseErrorList = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_ERROR_LIST
            )[0];
            $requestAccessResponseErrorListContainer = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_ERROR_LIST_CONTAINER
            )[0];
            $requestAccessTryAgain = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_BUTTON
            )[0];
            $requestAccessRegister = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_RESPONSE_REGISTER_BUTTON
            )[0];
            $requestAccessRegisterMessages = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_REGISTER_MESSAGES
            );

            // Request Access Popup
            $requestAccessLoadingPopup = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_POPUP_LOADING
            )[0];
            $requestAccessLoadingAnimation = $requestAccessLoadingPopup.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_POPUP_LOADING_ANIMATION
            )[0];
            $requestAccessLoadingTitle = $requestAccessLoadingPopup.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_POPUP_LOADING_TITLE
            )[0];

            // Pending Access
            $pendingAccessTable = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.PENDING_ACCESS_TABLE
            )[0];

            // Request Access Events
            if ($requestAccessInput) {
                $requestAccessInput.addEventListener('input', fieldInputHandler);
            }

            if ($requestAccessTryAgain) {
                coned.utils.addGeneralListeners($requestAccessTryAgain, tryAgainEventHandler);
            }

            document.addEventListener('update-counters', updateCounters);
            $requestAccessTabContent.addEventListener('tab-active', hideResponse);

            // Form validation
            if ($requestAccessSubmitButton) {
                new coned.components.ValidateForm(
                    '.' + CONSTANTS.REQUEST_ACCESS_FORM,
                    requestAccessSubmit
                );
            }

            // Remove lazy-load listener since everything is loaded already
            $requestAccessLazyLoad[0].removeEventListener(
                'lazy-load-start',
                lazyLoadRequestAccessTrigger
            );
            $requestAccessTabContent.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);
            coned.utils.triggerEvent($requestAccessTabContent, 'lazy-load-element-loaded');
        };

        /**
         * If response failed, show an error and hide the module
         */
        var errorLoadRequestAccess = function (data, status) {
            if (status === 302) {
                location.reload();
            }

            // Remove lazy-load listener since everything is loaded already
            $requestAccessLazyLoad[0].removeEventListener(
                'lazy-load-start',
                lazyLoadRequestAccessTrigger
            );
            $requestAccessTabContent.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);
            coned.utils.triggerEvent($requestAccessTabContent, 'lazy-load-element-loaded');
        };

        /**
         * Loads the markup into the page
         */
        var setTabContent = function (data, status, $tabContent, errorFunction) {
            if (data) {
                var parser = new DOMParser(),
                    HTMLObject,
                    newTabContentAttrs,
                    $newTabContent,
                    $requestAccessLinks;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $newTabContent = HTMLObject.getElementsByClassName(CONSTANTS.TAB_CONTAINER);

                if ($newTabContent.length !== 0) {
                    $newTabContent = $newTabContent[0];
                } else if (
                    HTMLObject.getElementsByClassName(CONSTANTS.LOGGED_IN_CLASS).length === 0
                ) {
                    location.reload();
                } else {
                    errorFunction(data, status);
                    return;
                }

                if ($newTabContent.innerHTML.trim() === '') {
                    errorFunction(data, status);
                    return;
                }

                $tabContent.innerHTML = $newTabContent.innerHTML;
                newTabContentAttrs = $newTabContent.attributes;

                while ($tabContent.attributes.length > 0) {
                    $tabContent.removeAttribute($tabContent.attributes[0].name);
                }

                _.each(newTabContentAttrs, function (attribute) {
                    $tabContent.setAttribute(attribute.nodeName, attribute.nodeValue);
                });

                $requestAccessLinks = $tabContent.getElementsByClassName(
                    CONSTANTS.REQUEST_ACCESS_LINK
                );

                _.each($requestAccessLinks, function ($link) {
                    coned.utils.addGeneralListeners($link, function (event) {
                        event.preventDefault();

                        dataLayer.push({
                            event:
                                CONSTANTS.TAGGING_GENERAL_PREFFIX +
                                CONSTANTS.TAGGING_REQUEST_ACCESS_CTA
                        });

                        $requestAccessTab.click();
                    });
                });
            } else {
                errorFunction(data, status);
                return;
            }
        };

        /**
         * Displays response to request access input submit
         * @param isErrorAmount {Boolean} Flag to know if error is from response or from amount of accounts
         */
        var displayRequestAccessResponse = function (isErrorAmount) {
            $requestAccessResponseSuccess.classList.add(CONSTANTS.HIDDEN_CLASS);
            $requestAccessResponseError.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (isErrorAmount) {
                _resetInput = false;

                $requestAccessResponseErrorTitle.innerHTML =
                    $requestAccessResponseErrorTitle.dataset.amountErrorTitle;
                $requestAccessResponseErrorDescription.innerHTML = $requestAccessResponseErrorDescription.dataset.amountErrorDescription.replace(
                    CONSTANTS.AMOUNT_TOKEN,
                    _maxAccounts
                );

                $requestAccessTryAgain.innerHTML = $requestAccessTryAgain.dataset.errorLabel;

                $requestAccessResponseErrorIcon.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $requestAccessResponseError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                var successList = _.where(_responseData.Accounts, { Success: true }),
                    errorList = $requestAccessResponseErrorListContainer.children;

                if (successList.length) {
                    $requestAccessResponseSuccessTitle.innerHTML =
                        successList.length === 1
                            ? $requestAccessResponseSuccessTitle.dataset.successTitleSingle
                            : $requestAccessResponseSuccessTitle.dataset.successTitleMultiple.replace(
                                  CONSTANTS.AMOUNT_TOKEN,
                                  successList.length
                              );
                    $requestAccessResponseSuccessDescription.innerHTML =
                        successList.length === 1
                            ? $requestAccessResponseSuccessDescription.dataset
                                  .successDescriptionSingle
                            : $requestAccessResponseSuccessDescription.dataset
                                  .successDescriptionMultiple;

                    $requestAccessResponseSuccess.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    $requestAccessTryAgain.innerHTML = $requestAccessTryAgain.dataset.successLabel;
                }

                if (errorList.length) {
                    $requestAccessResponseErrorTitle.innerHTML =
                        _responseData && _responseData.HadReachedAccountsLimit
                            ? $requestAccessResponseErrorTitle.dataset.maxAccountErrorTitle.replace(
                                  CONSTANTS.AMOUNT_TOKEN,
                                  $requestAccessResponseErrorListContainer.children.length
                              )
                            : errorList.length === 1
                            ? $requestAccessResponseErrorTitle.dataset.errorTitleSingle
                            : $requestAccessResponseErrorTitle.dataset.errorTitleMultiple.replace(
                                  CONSTANTS.AMOUNT_TOKEN,
                                  $requestAccessResponseErrorListContainer.children.length
                              );
                    $requestAccessResponseErrorDescription.innerHTML =
                        _responseData && _responseData.HadReachedAccountsLimit
                            ? $requestAccessResponseErrorDescription.dataset.maxAccountErrorDescription.replace(
                                  CONSTANTS.AMOUNT_TOKEN,
                                  $requestAccessResponseErrorListContainer.children.length
                              )
                            : errorList.length === 1
                            ? $requestAccessResponseErrorDescription.dataset.errorDescriptionSingle
                            : $requestAccessResponseErrorDescription.dataset.errorDescriptionMultiple.replace(
                                  CONSTANTS.AMOUNT_TOKEN,
                                  $requestAccessResponseErrorListContainer.children.length
                              );

                    $requestAccessResponseErrorIcon.classList.add(CONSTANTS.HIDDEN_CLASS);

                    $requestAccessResponseError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $requestAccessResponseErrorList.classList.remove(CONSTANTS.HIDDEN_CLASS);

                    $requestAccessTryAgain.innerHTML = $requestAccessTryAgain.dataset.errorLabel;
                }

                if (errorList.length && successList.length) {
                    $requestAccessResponseError.classList.add(
                        CONSTANTS.RESPONSE_CONTAINER_BORDER_CLASS
                    );
                } else {
                    $requestAccessResponseError.classList.remove(
                        CONSTANTS.RESPONSE_CONTAINER_BORDER_CLASS
                    );
                }

                if (_responseData && _responseData.HadReachedAccountsLimit) {
                    $requestAccessTryAgain.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $requestAccessRegister.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $requestAccessTryAgain.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $requestAccessRegister.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }
        };

        /**
         * Handles the request access submit
         */
        var requestAccessSubmit = function () {
            // Get all the values from the input and split it
            _inputArray = $requestAccessInput.value.split(/[,\s]/).filter(function (item) {
                return item !== '';
            });

            if (_inputArray.length > _maxAccounts) {
                displayRequestAccessResponse(true);

                // Show response
                $requestAccessResponseErrorList.classList.add(CONSTANTS.HIDDEN_CLASS);
                $requestAccessSubformContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $requestAccessResponse.classList.remove(CONSTANTS.HIDDEN_CLASS);

                // Focus Response
                $requestAccessResponse.focus();
            } else {
                // Reset variables
                _requestData = [];
                _responseData = null;
                _resetInput = true;
                _processedCounter = 0;
                _totalAccountsAmount = _inputArray.length;

                // Create error element list
                $requestAccessResponseErrorListContainer.innerHTML = '';

                // Create the request data for each value
                _.each(_inputArray, function (account) {
                    // Test if it's duplicated
                    var isDuplicated = _.find(_requestData, function (acctObj) {
                        return acctObj.AccountNumber === account;
                    });

                    _requestData.push({
                        AccountNumber: account,
                        IsDuplicated: isDuplicated ? true : false
                    });
                });

                // Hide register account messages
                _.each($requestAccessRegisterMessages, function ($element) {
                    $element.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                // Disable submit button
                $requestAccessSubmitButton.disabled = true;

                // Add total amount of accounts to the popup
                $requestAccessLoadingTitle.innerHTML =
                    _totalAccountsAmount === 1
                        ? $requestAccessForm.dataset.loadingTitleSingle
                        : $requestAccessForm.dataset.loadingTitleMultiple
                              .replace(CONSTANTS.AMOUNT_TOKEN, _totalAccountsAmount)
                              .replace(CONSTANTS.PERCENTAGE_TOKEN, '0');

                // Tagging event
                var tagEvent =
                    _totalAccountsAmount === 1
                        ? $requestAccessSubmitButton.dataset.tagSingle
                        : _totalAccountsAmount > 1
                        ? $requestAccessSubmitButton.dataset.tagMultiple
                        : '';

                if (tagEvent) {
                    dataLayer.push({
                        event: tagEvent
                    });
                }

                // Start animation and show popup
                query.fireEvent($requestAccessLoadingAnimation, 'start-animation');
                $requestAccessLoadingPopup.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $requestAccessLoadingPopup.focus();

                // Start processing accounts
                requestAccessServiceCall();
            }
        };

        /**
         * Handles the request access batched service call
         */
        var requestAccessServiceCall = function () {
            if (_requestData.length > 0) {
                var batchAmount = Math.min(CONSTANTS.BATCH_AMOUNT, _requestData.length),
                    accountsBatch = _requestData.splice(0, batchAmount), // Get new batch of N or less accounts
                    serviceUrl = $requestAccessForm.dataset.requestAccessServiceUrl,
                    params = {
                        ScId: query.getFormInputValue(
                            $requestAccessTabContent,
                            CONSTANTS.SITECORE_ID
                        ),
                        TotalAccountsToAdd: _inputArray.length,
                        Accounts: accountsBatch
                    };

                _processedCounter += batchAmount;

                params = JSON.stringify(params);
                if (coned.utils.isPatternLab() && _requestAccessScenario === '2') {
                    query.postData(
                        serviceUrl,
                        errorRequestAccess,
                        successRequestAccess,
                        params,
                        true
                    );
                } else {
                    query.postData(
                        serviceUrl,
                        successRequestAccess,
                        errorRequestAccess,
                        params,
                        true
                    );
                }
            } else {
                displayRequestAccessResponse();
                addNewPendingAccounts();

                // Update counters
                query.fireEvent(document, 'update-counters', _responseData);

                // Stop Animation Popup
                query.fireEvent($requestAccessLoadingAnimation, 'stop-animation');
                $requestAccessLoadingPopup.classList.add(CONSTANTS.HIDDEN_CLASS);

                // Show response
                $requestAccessSubformContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $requestAccessResponse.classList.remove(CONSTANTS.HIDDEN_CLASS);

                // Focus Response
                $requestAccessResponse.focus();

                // Tagging
                dataLayer.push({
                    event:
                        CONSTANTS.TAGGING_GENERAL_PREFFIX + CONSTANTS.TAGGING_REQUEST_ACCESS_SUBMIT
                });
            }
        };

        /**
         * Batch success request access handler
         */
        var successRequestAccess = function (data) {
            if (coned.utils.isPatternLab()) {
                // With errors
                setTimeout(function () {
                    query.getData(
                        coned.plConstants.GET_REQUEST_ACCESS_ERRORS,
                        processRequestAccessData,
                        errorRequestAccess,
                        null
                    );
                }, 500);

                // Full Success
                // setTimeout(function() { query.getData(coned.plConstants.GET_REQUEST_ACCESS_SUCCESS, processRequestAccessData, errorRequestAccess, null); }, 500);
            } else {
                processRequestAccessData(data);
            }
        };

        /**
         * Batch error request access handler
         */
        var errorRequestAccess = function (data) {
            if (coned.utils.isPatternLab() && _requestAccessScenario === '2') {
                data.errorTitle = 'You’ve Entered Too Many Accounts';
                data.errorMsg =
                    'To manage more than 1,000 accounts, please register a new profile.';
                data.registerProfile = 'true';
            }

            $requestAccessResponseSuccess.classList.add(CONSTANTS.HIDDEN_CLASS);
            $requestAccessResponseError.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $requestAccessResponseErrorTitle.innerHTML = data.errorTitle
                ? data.errorTitle
                : $requestAccessResponseErrorTitle.dataset.defaultTitle;
            $requestAccessResponseErrorDescription.innerHTML = data.errorMsg
                ? data.errorMsg
                : $requestAccessResponseErrorDescription.dataset.defaultMsg;

            $requestAccessResponseErrorIcon.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $requestAccessResponseErrorList.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (data.registerProfile && data.registerProfile === 'true') {
                _.each($requestAccessRegisterMessages, function ($element) {
                    $element.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                $requestAccessTryAgain.classList.add(CONSTANTS.HIDDEN_CLASS);
                $requestAccessRegister.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            // Stop Animation Popup
            query.fireEvent($requestAccessLoadingAnimation, 'stop-animation');
            $requestAccessLoadingPopup.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Show response
            $requestAccessSubformContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $requestAccessResponse.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $requestAccessResponseErrorDescription.focus();
        };

        /**
         * Handles the update of current/pending accounts counters
         * @param event {Object} Object with event that triggered the function
         */
        var updateCounters = function (event) {
            if (
                !event.details ||
                event.details.AccountsCurrentCount === undefined ||
                event.details.AccountsNewCount === undefined
            )
                return;

            var currentAccounts = event.details.AccountsCurrentCount,
                pendingAccounts = event.details.AccountsNewCount;

            // Updates counters' value
            $requestAccessCurrentAccountsAmount.innerHTML = currentAccounts;
            $requestAccessPendingRequestsAmount.innerHTML = pendingAccounts;

            if (_resetInput) {
                // Reset account counter to 0
                $requestAccessCounter.innerHTML = $requestAccessForm.dataset.multipleAccount.replace(
                    CONSTANTS.AMOUNT_TOKEN,
                    0
                );
                $requestAccessCounter.classList.remove(CONSTANTS.COUNTER_ERROR_CLASS);

                // Reset input
                $requestAccessInput.value = '';
                $requestAccessInput.classList.remove(CONSTANTS.FILLED_INPUT_CLASS);
                $requestAccessInput.classList.remove(CONSTANTS.VALID_CLASS);

                // Disable submit button
                $requestAccessSubmitButton.disabled = true;

                _resetInput = true;
            }

            // Check if by adding the accounts, the max accounts limit content needs to be shown
            if (event.details.HadReachedAccountsWarningPoint) {
                _.each($requestAccessRegisterMessages, function ($element) {
                    $element.classList.remove(CONSTANTS.HIDDEN_CLASS);
                });
            } else {
                _.each($requestAccessRegisterMessages, function ($element) {
                    $element.classList.add(CONSTANTS.HIDDEN_CLASS);
                });
            }

            if (event.details.HadReachedAccountsLimit) {
                $requestAccessSubformContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $requestAccessSubmitButton.disabled = true;
            } else {
                $requestAccessSubformContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $requestAccessSubmitButton.disabled = false;
            }

            _updatedCounters = event.details.UpdateCounters;
        };

        /**
         * Hides response section
         */
        var hideResponse = function () {
            $requestAccessResponse.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (!_updatedCounters) {
                if (_responseData && _responseData.HadReachedAccountsWarningPoint) {
                    // Check if by adding the accounts, the max accounts limit content needs to be shown
                    _.each($requestAccessRegisterMessages, function ($element) {
                        $element.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    });
                } else {
                    _.each($requestAccessRegisterMessages, function ($element) {
                        $element.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });
                }

                if (_responseData && _responseData.HadReachedAccountsLimit) {
                    $requestAccessSubformContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $requestAccessSubmitButton.disabled = true;
                } else {
                    $requestAccessSubformContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $requestAccessSubmitButton.disabled = false;
                }
            }

            _updatedCounters = false;
        };

        /**
         * Batch error request access handler
         * @param data {Object} Data to be processed
         */
        var processRequestAccessData = function (data) {
            // Display current percentual progress
            var percentage = Math.round((_processedCounter * 100) / _totalAccountsAmount);

            $requestAccessLoadingTitle.innerHTML =
                _totalAccountsAmount === 1
                    ? $requestAccessForm.dataset.loadingTitleSingle
                    : $requestAccessForm.dataset.loadingTitleMultiple
                          .replace(CONSTANTS.AMOUNT_TOKEN, _totalAccountsAmount)
                          .replace(CONSTANTS.PERCENTAGE_TOKEN, percentage);

            if (data && data.Accounts && data.Accounts.length > 0) {
                if (_responseData) {
                    _responseData.Accounts = _responseData.Accounts.concat(data.Accounts);
                    _responseData.AccountsCurrentCount = data.AccountsCurrentCount;
                    _responseData.AccountsNewCount = data.AccountsNewCount;
                    _responseData.HadReachedAccountsWarningPoint =
                        data.HadReachedAccountsWarningPoint;
                    _responseData.HadReachedAccountsLimit = data.HadReachedAccountsLimit;
                } else {
                    _responseData = data;
                }

                _.each(data.Accounts, function (accountObj) {
                    // If any accounts didn't get added, create the error element
                    if (!accountObj.Success) {
                        var account = accountObj.AccountNumber,
                            errorDescription = accountObj.ErrorMessage,
                            $containerElement = document.createElement(CONSTANTS.DIV_TAG),
                            $accountElement = document.createElement(CONSTANTS.SPAN_TAG),
                            $errorDescriptionElement = document.createElement(CONSTANTS.SPAN_TAG);

                        $accountElement.innerHTML = account;
                        $errorDescriptionElement.innerHTML = errorDescription;

                        $accountElement.classList.add(
                            CONSTANTS.REQUEST_ACCESS_RESPONSE_ERROR_LIST_ELEMENT +
                                CONSTANTS.ACCOUNT_SUFFIX
                        );
                        $errorDescriptionElement.classList.add(
                            CONSTANTS.REQUEST_ACCESS_RESPONSE_ERROR_LIST_ELEMENT +
                                CONSTANTS.DESCRIPTION_SUFFIX
                        );

                        $containerElement.classList.add(
                            CONSTANTS.REQUEST_ACCESS_RESPONSE_ERROR_LIST_ELEMENT
                        );
                        $containerElement.appendChild($accountElement);
                        $containerElement.appendChild($errorDescriptionElement);

                        $requestAccessResponseErrorListContainer.appendChild($containerElement);
                    }
                });
            }

            requestAccessServiceCall();
        };

        /**
         * Add the processed accounts to the list of pending access accounts
         */
        var addNewPendingAccounts = function () {
            var $pendingAccessCheckbox = $pendingAccessTable.getElementsByClassName(
                    CONSTANTS.PENDING_ACCESS_CHECKBOX_INPUT
                ),
                successList = _.where(_responseData.Accounts, { Success: true }),
                details = { Accounts: [] };

            _.each(successList, function (accountObj) {
                var $checkboxInput = _.find($pendingAccessCheckbox, function ($checkbox) {
                    return $checkbox.id === accountObj.AccountNumber;
                });

                if ($checkboxInput) {
                    var $checkboxContainer = query.selectParentElement(
                        $checkboxInput,
                        CONSTANTS.PENDING_ACCESS_CHECKBOX_CONTAINER
                    );

                    $checkboxContainer.parentNode.removeChild($checkboxContainer);
                }

                var currentDate = $requestAccessForm.dataset.requestedCopy.replace(
                        CONSTANTS.DATE_TOKEN,
                        accountObj.RequestedDate
                    ),
                    account = {
                        AccountNumber: accountObj.AccountNumber,
                        RowClasses: CONSTANTS.ACCOUNT_ITEM_ROW_CLASSES,
                        Checkbox: {
                            LabelClasses: CONSTANTS.ACCOUNT_ITEM_LABEL_CONTAINER_CLASSES,
                            InputName: CONSTANTS.ACCOUNT_ITEM_INPUT_NAME,
                            InputValue: accountObj.AccountNumber
                        },
                        Items: [
                            {
                                Classes: CONSTANTS.ACCOUNT_ITEM_LABEL_ACCOUNT_CLASS,
                                Content: accountObj.AccountNumber
                            },
                            {
                                Classes: CONSTANTS.ACCOUNT_ITEM_LABEL_REQUEST_CLASS,
                                Content: currentDate
                            }
                        ]
                    };

                details.Accounts.push(account);
            });

            query.fireEvent($pendingAccessTable, 'add-account', details);
        };

        /**
         * Handles try again click event
         */
        var tryAgainEventHandler = function (event) {
            if (event) {
                event.preventDefault();
            }

            // Reset response elements
            $requestAccessResponseErrorIcon.classList.add(CONSTANTS.HIDDEN_CLASS);
            $requestAccessResponseErrorList.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $requestAccessTryAgain.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $requestAccessRegister.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Show form again
            $requestAccessResponse.classList.add(CONSTANTS.HIDDEN_CLASS);

            if (!_responseData || !_responseData.HadReachedAccountsLimit) {
                $requestAccessSubformContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $requestAccessSubmitButton.disabled = false;
            }

            // Focus error message
            $requestAccessRegisterMessages[0].focus();
        };

        /**
         * Checks request access form to enable/disable submit button.
         */
        var fieldInputHandler = function () {
            // Replace some of the forbidden inputs
            $requestAccessInput.value = $requestAccessInput.value.replace(/([^\d,\s])/g, ''); // Remove anything but numbers, commas or spaces
            $requestAccessInput.value = $requestAccessInput.value.replace(/,{2,}/g, ','); // Remove multiple consecutive commas
            $requestAccessInput.value = $requestAccessInput.value.replace(/\s{2,}/g, '\n'); // Remove multiple consecutive spaces
            $requestAccessInput.value = $requestAccessInput.value.replace(/(,\s|\s,)/g, ','); // Remove multiple consecutive spaces
            $requestAccessInput.value = $requestAccessInput.value.replace(/^[\s,]/g, ''); // Disable starting input with spaces or commas

            $requestAccessSubmitButton.disabled = $requestAccessInput.value.length === 0;

            // Count the amount of accounts in the input
            var accountCount = $requestAccessInput.value.split(/[,\s]/).filter(function (item) {
                return item !== '';
            }).length;

            $requestAccessCounter.innerHTML =
                accountCount === 1
                    ? $requestAccessForm.dataset.oneAccount.replace(
                          CONSTANTS.AMOUNT_TOKEN,
                          accountCount
                      )
                    : $requestAccessForm.dataset.multipleAccount.replace(
                          CONSTANTS.AMOUNT_TOKEN,
                          accountCount
                      );

            // Add/Remove error class if count is higher/lower than the maximum amount of accounts
            if (accountCount > _maxAccounts) {
                $requestAccessCounter.classList.add(CONSTANTS.COUNTER_ERROR_CLASS);
            } else {
                $requestAccessCounter.classList.remove(CONSTANTS.COUNTER_ERROR_CLASS);
            }

            // Disable submit if more than max account numbers added
            $requestAccessSubmitButton.disabled = accountCount > _maxAccounts || accountCount === 0;
            $($requestAccessForm).validate().checkForm();
        };

        var initializeData = function () {
            // Customer Accounts
            $customerAccountsLazyLoad = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.CUSTOMER_ACCOUNTS_LAZY_LOAD
            );
            $customerAccountsTabContent = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.CUSTOMER_ACCOUNTS_TAB_CONTENT
            )[0];

            // Request Access
            $requestAccessTab = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_TAB
            )[0];
            $requestAccessTabContent = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_TAB_CONTENT
            )[0];
            $requestAccessLazyLoad = $dashboardThirdParty.getElementsByClassName(
                CONSTANTS.REQUEST_ACCESS_LAZY_LOAD
            );

            // General
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
        };

        var initializeEvents = function () {
            // Customer Accounts Events
            if ($customerAccountsLazyLoad && $customerAccountsLazyLoad.length) {
                if (window.location.search.includes(CONSTANTS.TAB_URL_PARAMETER_NAME)) {
                    $customerAccountsLazyLoad[0].addEventListener(
                        'lazy-load-start',
                        lazyLoadCustomerAccountsTrigger
                    );
                } else {
                    lazyLoadCustomerAccountsTrigger();
                }
            } else {
                lazyLoadCustomerAccountsTrigger();
            }

            // Request Access Events
            if ($requestAccessLazyLoad && $requestAccessLazyLoad.length) {
                $requestAccessLazyLoad[0].addEventListener(
                    'lazy-load-start',
                    lazyLoadRequestAccessTrigger
                );
            } else {
                lazyLoadRequestAccessTrigger();
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardThirdParty.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardThirdParty;
})();
