// ==================== PAGINATION_SIMPLE_ITEMS =========================

var coned = coned || {};
coned.components = coned.components || {};

/**
 * @returns the init function to start the module
 */
coned.components.PaginationSimpleItems = (function () {
    var isLoaded = false;

    /**
     * @typedef {Object} PaginationState
     * @property {boolean} [isSeted]
     * @property {number} [totalItems]
     * @property {number} [itemsPerPage]
     * @property {number} [currentPage]
     * @property {number} [totalPages]
     * @property {boolean} [canScroll]
     */

    /**
     * @typedef {Object} SortedItem
     * @property {number} order
     * @property {string} itemId
     */

    /**
     * @typedef {Object} PaginationItem
     * @property {string} id
     * @property {string} title
     * @property {Record<string, string[]} categories
     * @property {boolean} isFeatured
     * @property {HTMLElement} $element
     * @property {HTMLElement[]} [$collapsibles]
     */

    /**
     * @typedef {Object} PublicState
     * @property {boolean} isSeted
     * @property {PaginationItem[]} items
     * @property {PaginationItem[]} displayItems
     * @property {PaginationState} [pagination]
     * @property {SortedItem[][]} [sortedPages]
     */

    /**
     * @typedef {Object} PrivateState
     * @property {HTMLElement[]} $items
     * @property {HTMLElement} $no_results
     */

    /**
     * Constants used in the module.
     * @typedef {Object}
     */
    var CONSTANTS = {
        SELECTORS: {
            /**
             * @type {'js-header-wrapper'}
             */
            HEADER_WRAPPER: 'js-header-wrapper',
            /**
             * @type {'js-pagination-simple-item'}
             */
            ITEM: 'js-pagination-simple-item',
            /**
             * @type {'js-pagination-simple-no-results'}
             */
            NO_RESULTS: 'js-pagination-simple-no-results',
            /**
             * @type {'[data-module="CollapsibleContainerComponent"]'}
             */
            COLLAPSIBLE: '[data-module="CollapsibleContainerComponent"]'
        }
    }
    /**
     * @constructor
     * @param {HTMLElement} $container
     * @returns {}
     */
    var PaginationSimpleItems = function ($container) {
        /**
         * PRIVATE VARIABLES
         */
        /**
         * @type {PublicState}
         */
        var _publicState,
            /**
             * @type {PrivateState}
             */
            _privateState


        /**
         * PRIVATE METHODS
         */

        /**
         * Extract dataset from items
         */
        var getItems = function () {
            var items = [];
            if (Array.isArray(_privateState.$items)) {
                _privateState.$items.forEach(function ($el) {
                    var strCategories = $el.dataset.categories,
                        /**
                         * @type {Record<string, string[]>}
                         */
                        categories = {},
                        $collapsibles = Array.from($el.querySelectorAll(CONSTANTS.SELECTORS.COLLAPSIBLE));
                    if (strCategories && strCategories.length > 0) {
                        categories = coned.utils.buildRecordStringArray(
                            strCategories
                        );
                    }

                    items.push({
                        $element: $el,
                        $collapsibles: $collapsibles,
                        id: $el.dataset.id ? $el.dataset.id : '',
                        title: $el.getAttribute(coned.constants.ARIA.LABEL)
                            ? $el.getAttribute(coned.constants.ARIA.LABEL)
                            : '',
                        isFeatured: $el.dataset.featured
                            ? $el.dataset.featured === coned.constants.TRUE
                            : false,
                        categories: categories
                    })
                })
            }
            return items;
        }
        /**
         * set aria hidden attribute of element
         * @param {HTMLElement} $element 
         * @param {boolean} value 
         */
        var setAriaHidden = function ($element, value) {
            if ($element instanceof HTMLElement) {
                $element.setAttribute(
                    coned.constants.ARIA.HIDDEN,
                    value ? coned.constants.TRUE : coned.constants.FALSE
                );
            }
        }
        var scrollTopItems = function () {
            var $headerWrapper = document.getElementsByClassName(CONSTANTS.SELECTORS.HEADER_WRAPPER)[0],
                headerHeight = $headerWrapper instanceof HTMLElement ? $headerWrapper.offsetHeight : 0,
                elementTop = $container.getBoundingClientRect().top + window.scrollY,
                offset = 100;
            if (window.scrollY === 0 || headerHeight === 0) {
                return;
            }
            window.scrollTo({
                top: elementTop - headerHeight - offset,
                behavior: 'smooth'
            });

        }
        /**
         * Update HTML
         */
        var changeHTML = function () {
            if (_publicState.isSeted) {
                while (_publicState.displayItems.length > 0) {
                    var shiftItem = _publicState.displayItems.shift();
                    shiftItem.$collapsibles.forEach(function ($el) {
                        coned.utils.triggerEvent($el, coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL, { collapseAll: true });
                    })
                    setAriaHidden(shiftItem.$element, true);
                }

                if (_publicState.pagination &&
                    _publicState.sortedPages
                ) {
                    setAriaHidden(_privateState.$no_results, _publicState.pagination.currentPage !== 0);
                    if (_publicState.pagination.currentPage > 0) {
                        var sortedItems = _publicState.sortedPages[_publicState.pagination.currentPage - 1];

                        if (sortedItems && sortedItems.length > 0) {
                            for (var i = 0; i < sortedItems.length; i++) {

                                var sortedItem = sortedItems[i];
                                if (sortedItem) {
                                    var item = _publicState.items.find(function (paginationItem) {
                                        return paginationItem.id === sortedItem.itemId
                                    });
                                    if (item) {
                                        _publicState.displayItems.push(item);
                                        item.$element.removeAttribute(coned.constants.ARIA.HIDDEN);
                                        var $currentElement = $container.children[i];
                                        if ($currentElement !== item.$element) {
                                            $container.insertBefore(item.$element, $currentElement);
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    setAriaHidden(_privateState.$no_results, true);
                }
                if (typeof _publicState.pagination !== 'undefined' && 
                    typeof _publicState.pagination.canScroll !== 'undefined' && 
                    _publicState.pagination.canScroll
                ) {
                    scrollTopItems();
                }
            }
            if (!_publicState.isSeted) {
                coned.utils.triggerEvent(
                    $container,
                    coned.constants.CUSTOM_EVENTS.CHANGE_STATE_DETAIL,
                    _publicState
                );
            }

        }
        /**
         * Modify state
         * @param {PublicState} state 
         */
        var setState = function (state) {
            _publicState = state;
            changeHTML();
        }
        /**
         * Listen Custom Event
         * @param {CustomEvent<PublicState>} event
         */
        var handleStateChange = function (event) {
            setState(event.detail);
        }
        /**
         * Initialize the data in the module
         */
        var initializeData = function () {
            _privateState = {
                $items: coned.utils.arrayFrom($container.getElementsByClassName(
                    CONSTANTS.SELECTORS.ITEM
                )),
                $no_results: $container.getElementsByClassName(
                    CONSTANTS.SELECTORS.NO_RESULTS
                )[0]
            };
            _publicState = {
                isSeted: false,
                items: getItems(),
                displayItems: []           
            };
        }

        /**
         * Initialize the events in the module
         */
        var initializeEvents = function () {
            $container.addEventListener(
                coned.constants.CUSTOM_EVENTS.SET_STATE_DETAIL,
                handleStateChange
            );
        }

        /**
         * Inits functionality in the module
         */
        var init = function () {
            initializeData();
            initializeEvents();
            changeHTML();
            isLoaded = true;
        }
        init();
    }
    /**
     * PUBLIC METHODS
     */
    /**
     * Function to get is module Loaded
     * @returns {boolean}
     */
    PaginationSimpleItems.prototype.isLoaded = function () {
        return isLoaded;
    };
    return PaginationSimpleItems;
})();
