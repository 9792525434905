// ==================== FINANCIAL STATEMENT COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FinancialStatement = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Selector Classes
        CONED_INPUTS_SELECTOR: 'js-coned-input',
        CONED_FORM_FIELDSET_SELECTOR: 'js-coned-form__fieldset',
        INCOME_GOVERNMENT_BENEFITS_FIELDSET_SELECTOR: 'js-fieldset-income-government-benefits',
        EXPENSES_FIELDSET_SELECTOR: 'js-fieldset-expenses',
        TOTAL_CONTAINER_SELECTOR: 'js-total-container',
        SIGNATURE_DATE_INPUT_SELECTOR: 'js-signature-date',
        TOTAL_RESULT_CLASS: 'js-total-result',
        DESCRIBE_YOU_RADIO_BUTTONS_NAME: 'describeYouOption',
        OPTION_RESIDENTIAL_SELECTOR: 'js-option-residential',
        OPTION_COMMERCIAL_SELECTOR: 'js-option-commercial',
        CONTINUE_TO_FORM_BUTTON_SELECTOR: 'js-continue-to-form',
        FINANCIAL_STATEMENT_QUESTIONS_SELECTOR: 'js-financial-statement-questions',
        FINANCIAL_STATEMENT_MAIN_FORM_SELECTOR: 'js-financial-statement-main-form',
        INITIAL_FORM_FOCUS_SELECTOR: 'js-form-initial-focus',
        TOTAL_RESULT_HIDDEN_INPUTS_SELECTOR: 'js-total-result-hidden-input',
        MY_ACCOUNT_RESIDENTIAL_BUTTON_SELECTOR: 'js-my-account-residential',
        MY_ACCOUNT_COMMERCIAL_BUTTON_SELECTOR: 'js-my-account-commercial',
        FINANCIAL_STATEMENT_INITIAL_FORM_SELECTOR: 'js-financial-statement-initial-form',
        PAYMENT_ASSISTANCE_BUTTON_SELECTOR: 'js-payment-assistance',
        SUMBIT_BUTTON: 'form__actions-submit-button',
        SUCCESS_MESSAGE: 'js-success-message',
        SUCCESS_ALT_MESSAGE: 'js-success-alt-message',
        FORM_SUMBIT_SUCCESS_EVENT: 'form-submit-success',
        STATEMENT_QUESTIONS_SELECTOR: 'js-financial-statement-questions',

        // Helper Constants
        HIDDEN_CLASS: 'hidden',
        ARIA_HIDDEN: 'aria-hidden',
        FILLED_CLASS: 'coned-input--filled',
        STRONG_TAG: 'STRONG',
        BLUR_EVENT: 'blur',
        CHANGE_EVENT: 'change',
        RESIDENTIAL_CUSTOMER: 'residentialCustomer',
        COMMERCIAL_CUSTOMER: 'commercialCustomer',
        TRUE_VALUE: 'true',
        FALSE_VALUE: 'false',
        NONE_VALUE: 'none',
        BLOCK_VALUE: 'block',
        TABINDEX_ATTRIBUTE: 'tabindex',

        // Tagging
        LOGIN_ACCOUNT_TAG: 'tag.statementform.loginaccount',
        CONTINUE_TO_FORM_TAG: 'tag.statementform.continuetoform',
        GET_ASSISTANCE_TAG: 'tag.statementform.getassistance',
        FORM_NOT_ELIGIBLE: 'coned.form.noteligible',
        FSF_QUALIFY_TAG: 'fsf.qualify',

        // AccountType
        RESIDENTIAL_ACCOUNT: 'R',
        COMMERCIAL_ACCOUNT: 'C',
        ACCONT_TYPE_STRING: "accountType",
        
        // Step 1
        STEP_1_LOG_IN_MY_ACCOUNT: 'step.01.log.in.my.account',
        STEP_1_CONTINUE_TO_FORM: 'step.01.continue.to.form',
        STEP_1_GET_PAYMENT_ASSISTANCE: 'step.01.get.payment.assistance'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var FinancialStatement = function ($financialStatementForm) {
        /**
         * PRIVATE METHODS
         */
        var $describeYouRadioBtns,
            $optionResidential,
            $optionCommercial,
            $continueToFomButton,
            $financialStatementQuestions,
            $myAccountResidentialButton,
            $myAccountCommercialButton,
            $paymentAssistanceButton,
            $mainForm,
            $initialFormFocusSelector,
            $initialForm,
            $incomeGovernmentBenefitsFieldset,
            $incomeGovernmentBenefitsInputs,
            $expensesFieldset,
            $expensesInputs,
            $signatureDateInput,
            $submitButton,
            $successMessage,
            $successAltMessage,
            $statementQuestions;

        /**
         * Shows/hides the elements
         * @param {HTMLElement} $showElement Element to show.
         * @param {HTMLElement} $hideElement Element to hide.
         */
        var setElementsVisibility = function ($showElement, $hideElement) {
            query.addClass($hideElement, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($showElement, CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Checks the radio buttons selection to make its corresponding section visible
         */
        var checkRadioSelection = function () {
            var radioSelection = query.getRadioInputValue($financialStatementForm, CONSTANTS.DESCRIBE_YOU_RADIO_BUTTONS_NAME);

            if (radioSelection === CONSTANTS.RESIDENTIAL_CUSTOMER) {
                setElementsVisibility($optionResidential, $optionCommercial);
            } else if (radioSelection === CONSTANTS.COMMERCIAL_CUSTOMER) {
                setElementsVisibility($optionCommercial, $optionResidential);
            }
        };

        /**
         * Removes element's ability to be tabbed into
         */
        var removeTabIndex = function () {
            if ($initialFormFocusSelector.hasAttribute(CONSTANTS.TABINDEX_ATTRIBUTE)) {
                $initialFormFocusSelector.removeAttribute(CONSTANTS.TABINDEX_ATTRIBUTE);
            }
        };

        /**
         * Calculates the total amount from the inputs in the current input's section
         * @param {Event} event Blur event
         */
        var calculateSectionTotal = function (event) {
            var $input = event.target;
            var $parentFieldset = query.selectParentElement($input, CONSTANTS.CONED_FORM_FIELDSET_SELECTOR);
            var $fieldsetInputs = $parentFieldset.getElementsByClassName(CONSTANTS.CONED_INPUTS_SELECTOR);
            var $totalContainer = $parentFieldset.getElementsByClassName(CONSTANTS.TOTAL_CONTAINER_SELECTOR)[0];
            var $total = $totalContainer.getElementsByClassName(CONSTANTS.TOTAL_RESULT_CLASS)[0];
            var $totalHiddenInput = $parentFieldset.getElementsByClassName(CONSTANTS.TOTAL_RESULT_HIDDEN_INPUTS_SELECTOR)[0];
            var strongTag = document.createElement(CONSTANTS.STRONG_TAG);
            var total = 0;
            var emptyFields = 0;

            _.each($fieldsetInputs, function ($input) {
                if ($input.value === '' || $input.value === '$') {
                    emptyFields++;
                } else {
                    total += parseFloat($input.value.replace(/(\$|,)/g, ''));
                }
            });

            $total && $totalContainer.removeChild($total);
            $totalHiddenInput.value = '';
            if (emptyFields !== $fieldsetInputs.length) {
                strongTag.textContent = coned.utils.formatToCurrencyText(total, 2);
                strongTag.className = CONSTANTS.TOTAL_RESULT_CLASS;
                $totalContainer.appendChild(strongTag);
                $totalHiddenInput.value = strongTag.textContent;
            }
        };

        /**
         * Success submit form handler
         */
        var successSubmitForm = function (data) {
            // Not eligible scenario, where expenses are greater than income.
            if (data.details.showAltMessage) {
                $successMessage.style.display = CONSTANTS.NONE_VALUE;
                $successAltMessage.style.display = CONSTANTS.BLOCK_VALUE;
                $successAltMessage.focus();

                // Analytics data building
                dataLayer.push({
                    event: CONSTANTS.FORM_NOT_ELIGIBLE
                });
            // Eligible scenario, where income is greater than expenses.
            } else {
                // Analytics data building
                dataLayer.push({
                    event: CONSTANTS.FSF_QUALIFY_TAG
                });                
            }
        };

        var addTagging = function (event, buttonValue, accountType) {
            dataLayer.push({
                event: event,
                buttonValue: buttonValue,
                accountType: accountType
            });
        };

        var continueToForm = function () {
            setElementsVisibility($mainForm, $financialStatementQuestions);
            // Remove the hidden class in the initial form, after tapping the "Continue To Form" button
            query.removeClass($initialForm, CONSTANTS.HIDDEN_CLASS);
            // Enabled submit button, active the submit with enter key and tap in the button.
            $submitButton.disabled = false;     
            // Allow top element from revealed section to be focused for a11y
            $initialFormFocusSelector.tabIndex = 0;
            $initialFormFocusSelector.focus();
            // Tagging 
            addTagging(CONSTANTS.CONTINUE_TO_FORM_TAG, CONSTANTS.STEP_1_CONTINUE_TO_FORM, CONSTANTS.RESIDENTIAL_ACCOUNT);
        }

        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            $financialStatementQuestions = $financialStatementForm.getElementsByClassName(
                CONSTANTS.FINANCIAL_STATEMENT_QUESTIONS_SELECTOR
            )[0];
            $myAccountResidentialButton = $financialStatementQuestions.getElementsByClassName(
                CONSTANTS.MY_ACCOUNT_RESIDENTIAL_BUTTON_SELECTOR
            )[0];
            $myAccountCommercialButton = $financialStatementQuestions.getElementsByClassName(
                CONSTANTS.MY_ACCOUNT_COMMERCIAL_BUTTON_SELECTOR
            )[0];
            $paymentAssistanceButton = $financialStatementQuestions.getElementsByClassName(
                CONSTANTS.PAYMENT_ASSISTANCE_BUTTON_SELECTOR
            )[0];
            $mainForm = $financialStatementForm.getElementsByClassName(
                CONSTANTS.FINANCIAL_STATEMENT_MAIN_FORM_SELECTOR
            )[0];
            $initialFormFocusSelector = $financialStatementForm.getElementsByClassName(
                CONSTANTS.INITIAL_FORM_FOCUS_SELECTOR
            )[0];
            $describeYouRadioBtns = $financialStatementForm.querySelectorAll(
                '[name="' + CONSTANTS.DESCRIBE_YOU_RADIO_BUTTONS_NAME + '"]'
            );
            $optionResidential = $financialStatementForm.getElementsByClassName(
                CONSTANTS.OPTION_RESIDENTIAL_SELECTOR
            )[0];
            $optionCommercial = $financialStatementForm.getElementsByClassName(
                CONSTANTS.OPTION_COMMERCIAL_SELECTOR
            )[0];
            $continueToFomButton = $financialStatementForm.getElementsByClassName(
                CONSTANTS.CONTINUE_TO_FORM_BUTTON_SELECTOR
            )[0];
            $initialForm = $financialStatementForm.getElementsByClassName(
                CONSTANTS.FINANCIAL_STATEMENT_INITIAL_FORM_SELECTOR
            )[0];
            $incomeGovernmentBenefitsFieldset = $financialStatementForm.getElementsByClassName(
                CONSTANTS.INCOME_GOVERNMENT_BENEFITS_FIELDSET_SELECTOR
            )[0];
            $incomeGovernmentBenefitsInputs = $incomeGovernmentBenefitsFieldset.getElementsByClassName(
                CONSTANTS.CONED_INPUTS_SELECTOR
            );
            $expensesFieldset = $financialStatementForm.getElementsByClassName(
                CONSTANTS.EXPENSES_FIELDSET_SELECTOR
            )[0];
            $expensesInputs = $expensesFieldset.getElementsByClassName(
                CONSTANTS.CONED_INPUTS_SELECTOR
            );
            $signatureDateInput = $financialStatementForm.getElementsByClassName(
                CONSTANTS.SIGNATURE_DATE_INPUT_SELECTOR
            )[0];
            $submitButton = document.getElementsByClassName(
                CONSTANTS.SUMBIT_BUTTON
            )[0];
            $successMessage = document.getElementsByClassName(
                CONSTANTS.SUCCESS_MESSAGE
            )[0];
            $successAltMessage = document.getElementsByClassName(
                CONSTANTS.SUCCESS_ALT_MESSAGE
            )[0];

            $statementQuestions = document.getElementsByClassName(
                CONSTANTS.STATEMENT_QUESTIONS_SELECTOR
            )[0];
        };

        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            // Get item from local storage
            var accounType = localStorage.getItem(CONSTANTS.ACCONT_TYPE_STRING);
            _.each($describeYouRadioBtns, function ($radio) { 
                $radio.addEventListener(CONSTANTS.CHANGE_EVENT, checkRadioSelection);
            }); 
            // Submit button disabled, prevents the form from being submitted with enter while in the read button section.
            $submitButton.disabled = true;
            
            coned.utils.addGeneralListeners($continueToFomButton, continueToForm);

            $initialFormFocusSelector.addEventListener(CONSTANTS.BLUR_EVENT, removeTabIndex);

            _.each($incomeGovernmentBenefitsInputs, function ($input) {
                $input.addEventListener(CONSTANTS.BLUR_EVENT, calculateSectionTotal);
            });

            _.each($expensesInputs, function ($input) {
                $input.addEventListener(CONSTANTS.BLUR_EVENT, calculateSectionTotal);
            });

            if ($signatureDateInput) {
                var today = new Date();

                coned.utils.fillInputWithFormattedDate($signatureDateInput, today);
            }

            // Listen to form-validation events for custom functionality
            $financialStatementForm.addEventListener(CONSTANTS.FORM_SUMBIT_SUCCESS_EVENT, successSubmitForm);

            setTimeout(function () {
                coned.utils.checkInputsFilled($financialStatementForm);
            }, 1000);

            coned.utils.addGeneralListeners($myAccountResidentialButton, function () {
                addTagging(CONSTANTS.LOGIN_ACCOUNT_TAG, CONSTANTS.STEP_1_LOG_IN_MY_ACCOUNT, CONSTANTS.RESIDENTIAL_ACCOUNT);
            });

            coned.utils.addGeneralListeners($myAccountCommercialButton, function () {
                addTagging(CONSTANTS.LOGIN_ACCOUNT_TAG, CONSTANTS.STEP_1_LOG_IN_MY_ACCOUNT, CONSTANTS.COMMERCIAL_ACCOUNT);
            });

            coned.utils.addGeneralListeners($paymentAssistanceButton, function () {
                addTagging(CONSTANTS.GET_ASSISTANCE_TAG, CONSTANTS.STEP_1_GET_PAYMENT_ASSISTANCE, CONSTANTS.COMMERCIAL_ACCOUNT);
            });

            // Check if the Financial Advisor page was opened
            if (accounType === CONSTANTS.RESIDENTIAL_ACCOUNT) {
                setElementsVisibility($optionResidential, $optionCommercial);
                continueToForm();
            } else {
                query.removeClass($statementQuestions, CONSTANTS.HIDDEN_CLASS);
            }
            // Delete item from local storage after page load
            localStorage.removeItem(CONSTANTS.ACCONT_TYPE_STRING);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FinancialStatement.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FinancialStatement;
})();
