// ==================== SAFETY INSPECTION FORM COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SafetyInspectionModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CHECKBOX_HIDE_CLASS: 'js-checkbox-other',
        CHECKBOX_HIDE_FIELD: 'coned-form__field-container--hidden',
        CHECKBOX_HIDE_FIELD_SELECTOR: 'js-field-container-hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var SafetyInspectionModule = function ($safetyInspectionElement) {
        /**
         * PRIVATE METHODS
         */
        var $otherCheckBox,
            $hiddenField;

        var checkedEvent = function (event) {
            var isChecked = event.target.checked;

            if (isChecked) {
                query.removeClass($hiddenField, CONSTANTS.CHECKBOX_HIDE_FIELD);
            } else {
                query.addClass($hiddenField, CONSTANTS.CHECKBOX_HIDE_FIELD);
            }
        };

        var initializeData = function () {
            $otherCheckBox = $safetyInspectionElement.getElementsByClassName(
                CONSTANTS.CHECKBOX_HIDE_CLASS
            )[0];

            $hiddenField = $safetyInspectionElement.getElementsByClassName(
                CONSTANTS.CHECKBOX_HIDE_FIELD_SELECTOR
            )[0];
        };

        var initializeEvents = function () {
            //Adding event to tabs elements
            $otherCheckBox.addEventListener('change', checkedEvent);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SafetyInspectionModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SafetyInspectionModule;
})();
