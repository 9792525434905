// ==================== VIDEO MODULE COMPONENT =========================
/* global videojs */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.TrackedVideo = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        VIDEO_PARENT_CLASS: 'full-media-with-content__media-container',
        PLAY_PAUSE_BUTTON_CLASS: 'text-and-video-content__button',
        PLAY_PAUSE_ICON_CLASS: 'text-and-video-content__button_icon',
        CAPTION_SETTINGS_CLASS: 'vjs-caption-settings',
        LAZY_CLASS: 'js-lazy',
        PLAY_CLASS: 'js-play',
        PAUSE_CLASS: 'js-pause',
        PLAY_ICON: 'icon-play',
        PAUSE_ICON: 'icon-pause',
        ORANGE_MODIFIER_CLASS: '--orange',
        BLUE_MODIFIER_CLASS: '--blue',
        PLAY_ARIA_LABEL: 'btn-play-label',
        PAUSE_ARIA_LABEL: 'btn-pause-label',
        VIDEO: 'video',
        LOOP: 'loop',
        ARIA_POLITE: 'polite',
        VISUALLY_HIDDEN: 'visually-hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var TrackedVideo = function ($videoModule) {
        /**
         * PRIVATE METHODS
         */
        var initializeEvents = function () {
            var videoPlayer = videojs($videoModule).player(),
                videoParent = $videoModule.parentElement,
                showingFallbackImage = false,
                waitingData = false,
                maxWaitingTime = 10000,
                waitingTime,
                playAriaLabel = $videoModule.getAttribute(CONSTANTS.PLAY_ARIA_LABEL),
                pauseAriaLabel = $videoModule.getAttribute(CONSTANTS.PAUSE_ARIA_LABEL),
                video = $videoModule.getElementsByTagName(CONSTANTS.VIDEO)[0];

            var loadVideo = function (element) {
                var videoId = element.getAttribute('id');

                if (coned.utils.isDesktop()) {
                    videojs(videoId).play();

                    setTimeout(function () {
                        var videoPlayButton = $videoModule.getElementsByClassName(
                            CONSTANTS.PLAY_PAUSE_BUTTON_CLASS
                        )[0];

                        videojs(videoId).pause();
                        playPauseButton(videoPlayButton);
                    }, 5000);
                }

                element.classList.remove(CONSTANTS.LAZY_CLASS);
            };

            var playPauseButton = function (buttonElement, videoId) {
                // update the status to pause
                if (buttonElement.classList.contains(CONSTANTS.PLAY_CLASS)) {
                    buttonElement.classList.remove(CONSTANTS.PLAY_CLASS);
                    buttonElement.classList.add(CONSTANTS.PAUSE_CLASS);
                    buttonElement.firstChild.nextSibling.textContent =
                        'State video: ' +
                        playAriaLabel +
                        ', press for ' +
                        pauseAriaLabel +
                        ' video.';
                    buttonElement.firstChild.classList.remove(CONSTANTS.PLAY_ICON);
                    buttonElement.firstChild.classList.add(CONSTANTS.PAUSE_ICON);

                    if (videoId) {
                        videojs(videoId).play();
                        video.setAttribute(CONSTANTS.LOOP, '');
                    }
                } else {
                    //update the status to play
                    buttonElement.classList.remove(CONSTANTS.PAUSE_CLASS);
                    buttonElement.classList.add(CONSTANTS.PLAY_CLASS);
                    buttonElement.firstChild.nextSibling.textContent =
                        'State video: ' +
                        pauseAriaLabel +
                        ', press for ' +
                        playAriaLabel +
                        ' video.';
                    buttonElement.firstChild.classList.remove(CONSTANTS.PAUSE_ICON);
                    buttonElement.firstChild.classList.add(CONSTANTS.PLAY_ICON);

                    if (videoId) {
                        videojs(videoId).pause();
                    }
                }
            };

            var addPlayPauseButton = function () {
                var pausePlayButton = document.createElement('button'),
                    spanButtonIcon = document.createElement('span'),
                    spanButtonText = document.createElement('span'),
                    buttonIcon = coned.utils.isDesktop()
                        ? CONSTANTS.PAUSE_ICON
                        : CONSTANTS.PLAY_ICON,
                    buttonClass = coned.utils.isDesktop()
                        ? CONSTANTS.PAUSE_CLASS
                        : CONSTANTS.PLAY_CLASS,
                    buttonColor = coned.utils.isOru()
                        ? CONSTANTS.ORANGE_MODIFIER_CLASS
                        : CONSTANTS.BLUE_MODIFIER_CLASS;

                pausePlayButton.classList.add(CONSTANTS.PLAY_PAUSE_BUTTON_CLASS);
                pausePlayButton.classList.add(CONSTANTS.PLAY_PAUSE_BUTTON_CLASS + buttonColor);
                pausePlayButton.classList.add(buttonClass);

                pausePlayButton.setAttribute('aria-live', CONSTANTS.ARIA_POLITE);
                spanButtonIcon.classList.add(CONSTANTS.PLAY_PAUSE_ICON_CLASS);
                spanButtonIcon.classList.add(buttonIcon);
                spanButtonText.classList.add(CONSTANTS.VISUALLY_HIDDEN);
                spanButtonText.textContent =
                    'State video: ' + pauseAriaLabel + ', press for ' + playAriaLabel + ' video.';
                pausePlayButton.appendChild(spanButtonIcon);
                pausePlayButton.appendChild(spanButtonText);
                $videoModule.appendChild(pausePlayButton);
                pausePlayButton.addEventListener('click', function () {
                    playPauseButton(pausePlayButton, $videoModule);
                });
            };

            if ($videoModule.classList.contains(CONSTANTS.LAZY_CLASS)) {
                addPlayPauseButton();
                coned.utils.initLazyLoadingIO($videoModule, loadVideo);
            }

            function showFallbackImage() {
                $videoModule.style.display = 'none';
                videoParent.className = CONSTANTS.VIDEO_PARENT_CLASS;
                showingFallbackImage = true;
            }

            videoPlayer.ready(function () {
                var ariaLabel = $videoModule.getAttribute('video-label');

                $videoModule.removeAttribute('aria-label');
                $videoModule.removeAttribute('role');
                $videoModule
                    .getElementsByClassName(CONSTANTS.CAPTION_SETTINGS_CLASS)[0]
                    .removeAttribute('role');

                if (ariaLabel) {
                    $videoModule.setAttribute('aria-label', ariaLabel);
                }

                if (videoPlayer.error_) {
                    //INITIAL LOADING ERROR
                    if (!showingFallbackImage) {
                        showFallbackImage();
                    }
                }

                videoPlayer.on('play', function (e) {
                    if (e.target.dataset.tagValue) {
                        dataLayer.push({ event: e.target.dataset.tagValue });
                    }
                });

                videoPlayer.on('error', function () {
                    if (!showingFallbackImage) {
                        showFallbackImage();
                        waitingData = false;
                    }
                });

                if ($videoModule.dataset.bgVideo) {
                    videoPlayer.on('waiting', function () {
                        if (!waitingData) {
                            waitingData = true;
                            waitingTime = setTimeout(function () {
                                if (!showingFallbackImage && waitingData) {
                                    showFallbackImage();
                                    waitingData = false;
                                }
                            }, maxWaitingTime);
                        }
                    });

                    videoPlayer.on('playing', function () {
                        if (waitingData) {
                            clearTimeout(waitingTime);
                            waitingData = false;
                        } else {
                            if (showingFallbackImage) {
                                videoPlayer.pause().currentTime(0);
                            }
                        }
                    });
                }

                videoPlayer.on('ended', function () {
                    var videoPlayButton = $videoModule.getElementsByClassName(
                        CONSTANTS.PLAY_PAUSE_BUTTON_CLASS
                    )[0];

                    videoPlayer.pause().currentTime(0);
                    if (videoPlayButton) {
                        playPauseButton(videoPlayButton);
                    }
                });
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    TrackedVideo.prototype.isLoaded = function () {
        return isLoaded;
    };

    return TrackedVideo;
})();
