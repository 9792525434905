// ==================== START DATE COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.StartDate = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        DISABLED_MODAL: 'js-disabled-modal',
        DISABLED_MODAL_CLASS: 'disabled-modal',
        INPUT_DATE: '.js-current-datepicker-input',
        INPUT_NEXT_DATE: 'js-next-datepicker-input',
        SUBMIT_SELECTOR: 'js-start-day-selector'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var StartDate = function () {
        /**
         * PRIVATE METHODS
         */
        var $disabledModal, $inputNextDate, $submitSelector;

        var checkCurrentDate = function (event) {
            var targetValue = event.target.value;

            if (targetValue != '') {
                query.removeClass($disabledModal, CONSTANTS.DISABLED_MODAL_CLASS);
                $inputNextDate.disabled = false;
                $inputNextDate.nextSibling.disabled = false;
            } else {
                query.addClass($disabledModal, CONSTANTS.DISABLED_MODAL_CLASS);
                $inputNextDate.disabled = true;
                $submitSelector.disabled = true;
                $inputNextDate.nextSibling.disabled = true;
            }
        };

        var checkNextDate = function (event) {
            var targetValue = event.target.value;

            if (targetValue != '') {
                $submitSelector.disabled = false;
            } else {
                $submitSelector.disabled = true;
            }
        };

        var initializeData = function () {
            $disabledModal = document.getElementsByClassName(CONSTANTS.DISABLED_MODAL)[0];
            $inputNextDate = document.getElementsByClassName(CONSTANTS.INPUT_NEXT_DATE)[0];
            $submitSelector = document.getElementsByClassName(CONSTANTS.SUBMIT_SELECTOR)[0];
        };

        var initializeEvents = function () {
            $(CONSTANTS.INPUT_DATE).on('change', checkCurrentDate);
            $('.' + CONSTANTS.INPUT_NEXT_DATE).on('change', checkNextDate);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    StartDate.prototype.isLoaded = function () {
        return isLoaded;
    };

    return StartDate;
})();
