// ==================== SUBMIT ANIMATION COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SubmitAnimation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SLICE_RIGHT_SELECTOR: 'js-slice-right-selector',
        SLICE_LEFT_SELECTOR: 'js-slice-left-selector',
        SUBMIT_SELECTOR: 'js-transactional-submit-selector',
        SUBMIT_CLOSE_CLASS: 'submit-button--close',
        SUBMIT_CONTAINER_SELECTOR: 'js-submit-container',
        SUBMIT_CONTAINER_CLASS: 'submit-button__container--bg-container',
        SUBMIT_TOP_SELCTOR: 'js-top-selector',
        SUBMIT_TOP_CLASS: 'submit-button__top--show',
        SUBMIT_ERROR_CLASS: 'submit-button--error',
        SUBMIT_CONTAINER_REVERSED: 'submit-button__container--bg-container-reversed',
        SLICE_RIGHT_WHITE_CLASS: 'submit-button__slice-right--white',
        SLICE_LEFT_WHITE_CLASS: 'submit-button__slice-left--white',
        SPAN_TEXT_HIDDEN_CLASS: 'submit-button__text--hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var SubmitAnimation = function (
        $element,
        serviceCall,
        successServiceCallback,
        errorServiceCallback,
        clickEvent,
        scrollToTop,
        doNotStartAnimation
    ) {
        /**
         * PRIVATE METHODS
         */
        var $sliceRightSelector,
            $sliceLeftSelector,
            $submitSelector,
            $submitContainerSelector,
            $submitTopSelector,
            $pageContent,
            _progressBarSpeed = 20;

        var rotateSlice = function (element, degree) {
            // Set transform property
            var styles =
                '-webkit-transform rotate(' +
                degree +
                'deg);' +
                '-moz-transform rotate(' +
                degree +
                'deg);' +
                '-ms-transform: rotate(' +
                degree +
                'deg);' +
                '-o-transform: rotate(' +
                degree +
                'deg);' +
                'transform: rotate(' +
                degree +
                'deg);' +
                'zoom: 1;';

            element.setAttribute('style', styles);
        };

        var circleBarAnimation = function (actualProgress, outOf) {
            var firstHalfAngle = 180,
                secondHalfAngle = 0,
                drawAngle = (actualProgress / outOf) * 360;

            // calculate the angle to be displayed in each half
            if (drawAngle <= 180) {
                firstHalfAngle = drawAngle;
            } else {
                secondHalfAngle = drawAngle - 180;
            }

            // set the transition
            rotateSlice($sliceRightSelector, firstHalfAngle);
            rotateSlice($sliceLeftSelector, secondHalfAngle);
        };

        var moveBar = function (speed) {
            var progress = 0,
                id = setInterval(frame, speed),
                submitContent = $submitSelector.innerHTML;

            if (!clickEvent) {
                serviceCall();
            }

            $pageContent.classList.add(coned.constants.PAGE_DISABLED);

            function frame() {
                if (progress == 100) {
                    if (
                        query.hasClass($element, coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS) ||
                        query.hasClass($element, coned.constants.ERROR_SERVICE_RESPONSE_CLASS)
                    ) {
                        clearInterval(id);

                        // Button visible again
                        $submitSelector.style.display = 'flex';
                        query.removeClass($submitTopSelector, CONSTANTS.SUBMIT_TOP_CLASS);
                        query.removeClass(
                            $submitContainerSelector,
                            CONSTANTS.SUBMIT_CONTAINER_CLASS
                        );
                        query.removeClass(
                            $submitContainerSelector,
                            CONSTANTS.SUBMIT_CONTAINER_REVERSED
                        );
                        query.removeClass($submitSelector, CONSTANTS.SUBMIT_CLOSE_CLASS);
                        $submitSelector.innerHTML = '<span class="">.</span>';

                        // Progress bar reset
                        $sliceLeftSelector.removeAttribute('style');
                        $sliceRightSelector.removeAttribute('style');

                        setTimeout(function () {
                            if (clickEvent) {
                                serviceCall.click();
                            }

                            query.removeClass($submitSelector, CONSTANTS.SPAN_TEXT_HIDDEN_CLASS);
                            $submitSelector.innerHTML = submitContent;
                            $submitSelector.classList.remove(CONSTANTS.SUBMIT_ERROR_CLASS);

                            if (
                                query.hasClass(
                                    $element,
                                    coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                                )
                            ) {
                                successServiceCallback();
                                $pageContent.classList.remove(coned.constants.PAGE_DISABLED);
                                $element.classList.remove(
                                    coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                                );

                                if (scrollToTop) {
                                    window.scrollTo(0, 0);
                                }
                            } else {
                                errorServiceCallback();
                                $pageContent.classList.remove(coned.constants.PAGE_DISABLED);
                                $element.classList.remove(
                                    coned.constants.ERROR_SERVICE_RESPONSE_CLASS
                                );
                            }
                        }, 400);
                    } else {
                        progress = 0;
                        $submitContainerSelector.classList.toggle(
                            CONSTANTS.SUBMIT_CONTAINER_REVERSED
                        );
                        $sliceRightSelector.classList.toggle(CONSTANTS.SLICE_RIGHT_WHITE_CLASS);
                        $sliceLeftSelector.classList.toggle(CONSTANTS.SLICE_LEFT_WHITE_CLASS);
                    }
                } else {
                    progress++;
                    circleBarAnimation(progress, 100);
                }
            }
        };

        var submitAnimation = function ($submit) {
            query.addClass($submit, CONSTANTS.SUBMIT_CLOSE_CLASS);
            query.addClass($submit, CONSTANTS.SPAN_TEXT_HIDDEN_CLASS);
            query.addClass($submitContainerSelector, CONSTANTS.SUBMIT_CONTAINER_CLASS);
            query.addClass($submitTopSelector, CONSTANTS.SUBMIT_TOP_CLASS);
            $submitSelector.style.display = 'none';

            setTimeout(function () {
                moveBar(_progressBarSpeed, true);
            }, 300);
        };

        var initializeData = function () {
            $sliceRightSelector = $element.getElementsByClassName(
                CONSTANTS.SLICE_RIGHT_SELECTOR
            )[0];
            $sliceLeftSelector = $element.getElementsByClassName(CONSTANTS.SLICE_LEFT_SELECTOR)[0];
            $submitSelector = $element.getElementsByClassName(CONSTANTS.SUBMIT_SELECTOR)[0];
            $submitContainerSelector = $element.getElementsByClassName(
                CONSTANTS.SUBMIT_CONTAINER_SELECTOR
            )[0];
            $submitTopSelector = $element.getElementsByClassName(CONSTANTS.SUBMIT_TOP_SELCTOR)[0];
            $pageContent = document.getElementsByClassName(coned.constants.PAGE_CONTENT_CLASS)[0];
        };

        var initializeEvents = function () {
            if (!doNotStartAnimation) {
                submitAnimation($submitSelector);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SubmitAnimation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SubmitAnimation;
})();
