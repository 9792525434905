// ==================== WAMI COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.Wami = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SEE_ALL_ACCOUNTS_BUTTON: 'js-see-all-accounts-button',
        LIMITED_SINGLE_ACCOUNTS: 'js-limited-single-accounts',
        ALL_SINGLE_ACCOUNTS: 'js-all-single-accounts',
        MANAGE_ACCOUNT_BTNS: 'js-manage-account',
        CHECK_EMAIL: 'js-email',
        CHECK_PHONE: 'js-phone',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_CONTAINER: 'js-service-error-container',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        HEADER_WRAPPER_CLASS: 'js-header-wrapper',
        BACK_BUTTON: 'js-wami-back-button',
        ALL_ACCOUNTS_PARAM_VALUE: 'allAccounts',
        ACTION_PARAM: 'action',
        POPSTATE: 'popstate',
        PAGINATION_ANIMATION_TIME: 300,
        HIDDEN_CLASS: 'hidden'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var Wami = function ($wami) {
        /**
         * PRIVATE METHODS
         */
        var $seeAllAccountsBtn,
            $limitedSingleAccounts,
            $allSingleAccounts,
            $headerWrapperElement,
            $manageAccountBtns,
            $accountToUpdate,
            $actualManageBtn,
            $backButton,
            $serviceErrors,
            $serviceErrorContainers,
            $serviceErrorMessages;

        /**
         * Updates url with allAccounts param.
         * @param  {Event}  event Click event
         */
        var addAllAccountsParam = function (event) {
            event.preventDefault();

            var updatedUrl = 
                    coned.utils.updateUrlParameter(
                        CONSTANTS.ACTION_PARAM,
                        CONSTANTS.ALL_ACCOUNTS_PARAM_VALUE
                    );

            window.history.pushState(
                {},
                '',
                updatedUrl
            );
        };

        /**
         * Updates url by removing allAccounts param.
         * @param  {Event}  event Click event
         */
        var removeAllAccountsParam =  function (event) {
            event.preventDefault();

            var url = window.location.href,
                updatedUrl = 
                    coned.utils.deleteAnchorParameter(
                        url,
                        CONSTANTS.ACTION_PARAM
                    ),
                lastCharIndex = updatedUrl.length -1;

            if (updatedUrl.charAt(lastCharIndex) === '?') {
                updatedUrl = updatedUrl.slice(0, lastCharIndex);
            }

            window.history.pushState(
                {},
                '',
                updatedUrl
            );
        };

        /**
         * Updates view according to allAccounts param addition or removal.
         * @param  {Boolean}  scroll [optional] Only accepts false to prevent scroll, otherwise leave empty.
         */
        var paramChangeHandler = function (scroll) {
            if (scroll === undefined) {
                scroll = true;
            }
            var coordinates, 
                scrollToPosition, 
                $elementToHide, 
                $elementToShow,
                actionParamValue = 
                    coned.utils.getUrlParameterValue(
                        CONSTANTS.ACTION_PARAM
                    );

            $elementToHide =
                actionParamValue === CONSTANTS.ALL_ACCOUNTS_PARAM_VALUE
                    ? $limitedSingleAccounts
                    : $allSingleAccounts;
            $elementToShow =
                actionParamValue === CONSTANTS.ALL_ACCOUNTS_PARAM_VALUE
                    ? $allSingleAccounts
                    : $limitedSingleAccounts;

            $elementToHide.classList.add(CONSTANTS.HIDDEN_CLASS);
            $elementToShow.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Focus element for a11y only after initial load
            if (isLoaded) {
                if ($elementToShow === $allSingleAccounts) {
                    $backButton ? $backButton.focus() : $wami.focus();
                } else {
                    $wami.focus();
                }
            }

            if (scroll) {
                coordinates = $elementToShow.getBoundingClientRect();

                scrollToPosition =
                    coordinates.top + window.pageYOffset - $headerWrapperElement.offsetHeight;
                coned.utils.scrollTo(scrollToPosition, CONSTANTS.PAGINATION_ANIMATION_TIME);
            }
        };

        var loadCheckStatus = function (event) {
            var $targetEvent = event.target;
            if (!$targetEvent.classList.contains('active')) {
                var maid = $targetEvent.parentElement.dataset.accountMaid,
                    serviceUrl = $targetEvent.parentElement.dataset.service,
                    params = { maid: maid };

                $accountToUpdate = $targetEvent.parentElement.parentElement;
                $actualManageBtn = event.target;

                query.getData(serviceUrl, updateAccountChecksValues, errorServiceCall, params);
            }
        };

        var updateAccountChecksValues = function (data) {
            hideErrors();
            var checksToUpdate = $accountToUpdate.getElementsByTagName('input');

            _.each(checksToUpdate, function (checkToUpdate) {
                if (checkToUpdate.classList.contains(CONSTANTS.CHECK_EMAIL)) {
                    checkToUpdate.checked = data.email;
                } else if (checkToUpdate.classList.contains(CONSTANTS.CHECK_PHONE)) {
                    checkToUpdate.checked = data.sms;
                }
            });
        };

        var errorServiceCall = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.DEFAULT_SERVICE_ERROR, showError, showError, true);
            } else {
                showError(data);
            }
        };

        var showError = function (data) {
            // Reverts the clicked acction
            $actualManageBtn.click();

            var errorMsg = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE,
                $serviceErrorContainer = $accountToUpdate.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_CONTAINER
                )[0],
                $serviceError = $serviceErrorContainer.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR
                )[0],
                $serviceErrorMessage = $serviceErrorContainer.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];

            $serviceErrorContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = errorMsg;
            $serviceError.focus();
        };

        var hideErrors = function () {
            _.each($serviceErrors, function ($serviceError) {
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorContainers, function ($serviceErrorContainer) {
                $serviceErrorContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorMessages, function ($serviceErrorMessage) {
                $serviceErrorMessage.innerHTML = '';
            });
        };

        var initializeData = function () {
            $seeAllAccountsBtn = $wami.getElementsByClassName(CONSTANTS.SEE_ALL_ACCOUNTS_BUTTON)[0];
            $limitedSingleAccounts = $wami.getElementsByClassName(
                CONSTANTS.LIMITED_SINGLE_ACCOUNTS
            )[0];
            $allSingleAccounts = $wami.getElementsByClassName(CONSTANTS.ALL_SINGLE_ACCOUNTS)[0];

            $headerWrapperElement = document.getElementsByClassName(
                CONSTANTS.HEADER_WRAPPER_CLASS
            )[0];

            $manageAccountBtns = $wami.getElementsByClassName(CONSTANTS.MANAGE_ACCOUNT_BTNS);
            $backButton = $wami.getElementsByClassName(CONSTANTS.BACK_BUTTON)[0];
            $serviceErrors = $wami.getElementsByClassName(CONSTANTS.SERVICE_ERROR);
            $serviceErrorContainers = $wami.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_CONTAINER
            );
            $serviceErrorMessages = $wami.getElementsByClassName(CONSTANTS.SERVICE_ERROR_MESSAGE);
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($seeAllAccountsBtn, function (event) {
                addAllAccountsParam(event);
                paramChangeHandler();
            });

            coned.utils.addGeneralListeners($backButton, function (event) {
                removeAllAccountsParam(event);
                paramChangeHandler();
            });

            // Listen to the browser's back/forward button click to show the appropriate view.
            window.addEventListener(CONSTANTS.POPSTATE, function () {
                paramChangeHandler();
            });

            _.each($manageAccountBtns, function ($manageAccountBtn) {
                coned.utils.addGeneralListeners($manageAccountBtn, loadCheckStatus);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            
            // Depending on the action param value will show the appropriate view on load.
            coned.utils.doActionByURLParam(
                CONSTANTS.ALL_ACCOUNTS_PARAM_VALUE, 
                function () {
                    paramChangeHandler(false);
                }
            );
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    Wami.prototype.isLoaded = function () {
        return isLoaded;
    };

    return Wami;
})();
