// ==================== ALERTS COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.BillComparison = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CONED_BILL_DROPDOWN: 'js-bill-comparison-options',
        CONED_COMPARISON_AMOUNT: 'js-comparison-price',
        CONED_BILLED_AMOUNT: 'js-billed-amount',
        CONED_BILL_PREV_AMOUNT: 'data-prev-amount',
        CONED_BILL_PLAN_AMOUNT: 'data-plan-amount',
        CONED_BILL_DROPDOWN_YEAR: 'js-bill-comparison-options-year',
        CONED_COMPARISON_AMOUNT_YEAR: 'js-comparison-price-year',
        CONED_BILLED_AMOUNT_YEAR: 'js-billed-amount-year',
        CONED_BILL_PREV_AMOUNT_YEAR: 'data-prev-amount-year',
        CONED_BILL_PLAN_AMOUNT_YEAR: 'data-plan-amount-year',
        CONED_BILL_COMPARISON_TAG: 'data-bill-comparison-tag',
        CONED_AMOUNT_TITLE: 'js-price-title',
        CHANGE_EVENT: 'change',
        CONED_BILL_DROPDOWN_YEAR_TYPE: 'selectYear',
        CONED_BILL_YEAR: 'Year',
        CONED_BILL_MONTH: 'Month'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var BillComparison = function ($billComparison) {
        /**
         * PRIVATE METHODS
         */
        var $billComparisonDropdown,
            $billComparisonAmount,
            $billAmount,
            $billComparisonDropdownYear,
            $billComparisonAmountYear,
            $billAmountYear,
            $billAmountTitle;

        var getBillingPeriods = function () {
            var comparisonValueDecimal = $billComparisonDropdown[0].getAttribute(
                    CONSTANTS.CONED_BILL_PREV_AMOUNT
                ),
                billedAmountDecimal = $billComparisonDropdown[0].getAttribute(
                    CONSTANTS.CONED_BILL_PLAN_AMOUNT
                );

            selectBill(
                comparisonValueDecimal,
                billedAmountDecimal,
                $billComparisonAmount,
                $billAmount
            );

            if ($billComparisonDropdownYear) {
                var comparisonValueDecimalYear = $billComparisonDropdownYear[0].getAttribute(
                        CONSTANTS.CONED_BILL_PREV_AMOUNT_YEAR
                    ),
                    billedAmountDecimalYear = $billComparisonDropdownYear[0].getAttribute(
                        CONSTANTS.CONED_BILL_PLAN_AMOUNT_YEAR
                    ),
                    billingPeriodTitle = $billComparisonDropdownYear[0].innerHTML;

                $billAmountTitle.innerHTML = billingPeriodTitle.substring(
                    0,
                    billingPeriodTitle.indexOf('(')
                );

                selectBill(
                    comparisonValueDecimalYear,
                    billedAmountDecimalYear,
                    $billComparisonAmountYear,
                    $billAmountYear
                );
            }
        };

        /**
         * Show the corresponding amount, according with the period selected
         * @param {String} optionValue
         */
        var selectBill = function (
            comparisonValue,
            billedAmount,
            billComparisonAmountElement,
            billAmountElement
        ) {
            comparisonValue = parseFloat(comparisonValue.replace(/,/g, '')).toFixed(2);
            billedAmount = parseFloat(billedAmount.replace(/,/g, '')).toFixed(2);

            var comparisonValueDecimal = comparisonValue.substring(comparisonValue.indexOf('.')),
                billedAmountDecimal = billedAmount.substring(billedAmount.indexOf('.')),
                comparisonAmount = '${{comparisonValue}}<sup>{{comparisonValueDecimal}}</sup>',
                billAmount = '${{billedAmount}}<sup>{{billedAmountDecimal}}</sup>',
                locale = 'en-us';

            comparisonAmount = comparisonAmount.replace(
                '{{comparisonValue}}',
                Math.floor(comparisonValue).toLocaleString(locale)
            );
            comparisonAmount = comparisonAmount.replace(
                '{{comparisonValueDecimal}}',
                comparisonValueDecimal
            );
            billAmount = billAmount.replace(
                '{{billedAmount}}',
                Math.floor(billedAmount).toLocaleString(locale)
            );
            billAmount = billAmount.replace('{{billedAmountDecimal}}', billedAmountDecimal);

            billComparisonAmountElement.innerHTML = comparisonAmount;
            billAmountElement.innerHTML = billAmount;
        };

        /**
         * Call the function that show the corresponding amount, according with the period selected on dorpdown
         * @param {String} optionValue
         */
        var updateBillAmounts = function (event) {
            var eventTarget = event.target,
                optionValue = eventTarget.value,
                comparisonValueDecimal,
                billedAmountDecimal,
                billComparisonTag = $billComparison.getAttribute(
                    CONSTANTS.CONED_BILL_COMPARISON_TAG
                ),
                billComparisonType =
                    eventTarget.name === CONSTANTS.CONED_BILL_DROPDOWN_YEAR_TYPE
                        ? CONSTANTS.CONED_BILL_YEAR
                        : CONSTANTS.CONED_BILL_MONTH;

            dataLayer.push({
                event: billComparisonTag,
                periodType: billComparisonType
            });

            for (var pos = 0; pos < eventTarget.length; pos++) {
                if (optionValue == eventTarget[pos].value) {
                    if (eventTarget.name === CONSTANTS.CONED_BILL_DROPDOWN_YEAR_TYPE) {
                        comparisonValueDecimal = eventTarget[pos].getAttribute(
                            CONSTANTS.CONED_BILL_PREV_AMOUNT_YEAR
                        );
                        billedAmountDecimal = eventTarget[pos].getAttribute(
                            CONSTANTS.CONED_BILL_PLAN_AMOUNT_YEAR
                        );

                        $billAmountTitle.innerHTML = eventTarget[pos].textContent.substring(
                            0,
                            eventTarget[pos].textContent.indexOf('(')
                        );

                        selectBill(
                            comparisonValueDecimal,
                            billedAmountDecimal,
                            $billComparisonAmountYear,
                            $billAmountYear
                        );
                    } else {
                        comparisonValueDecimal = eventTarget[pos].getAttribute(
                            CONSTANTS.CONED_BILL_PREV_AMOUNT
                        );
                        billedAmountDecimal = eventTarget[pos].getAttribute(
                            CONSTANTS.CONED_BILL_PLAN_AMOUNT
                        );

                        selectBill(
                            comparisonValueDecimal,
                            billedAmountDecimal,
                            $billComparisonAmount,
                            $billAmount
                        );
                    }
                }
            }
        };

        var initializeData = function () {
            $billComparisonDropdown = $billComparison.getElementsByClassName(
                CONSTANTS.CONED_BILL_DROPDOWN
            )[0];
            $billComparisonAmount = $billComparison.getElementsByClassName(
                CONSTANTS.CONED_COMPARISON_AMOUNT
            )[0];
            $billAmount = $billComparison.getElementsByClassName(CONSTANTS.CONED_BILLED_AMOUNT)[0];

            $billComparisonDropdownYear = $billComparison.getElementsByClassName(
                CONSTANTS.CONED_BILL_DROPDOWN_YEAR
            )[0];
            $billComparisonAmountYear = $billComparison.getElementsByClassName(
                CONSTANTS.CONED_COMPARISON_AMOUNT_YEAR
            )[0];
            $billAmountYear = $billComparison.getElementsByClassName(
                CONSTANTS.CONED_BILLED_AMOUNT_YEAR
            )[0];
            $billAmountTitle = $billComparison.getElementsByClassName(
                CONSTANTS.CONED_AMOUNT_TITLE
            )[0];
        };

        var initializeEvents = function () {
            $billComparisonDropdown.addEventListener(CONSTANTS.CHANGE_EVENT, updateBillAmounts);

            if ($billComparisonDropdownYear) {
                $billComparisonDropdownYear.addEventListener(
                    CONSTANTS.CHANGE_EVENT,
                    updateBillAmounts
                );
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            getBillingPeriods();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    BillComparison.prototype.isLoaded = function () {
        return isLoaded;
    };

    return BillComparison;
})();
