// ==================== SMART ENERGY COMPONENT =========================
/* global d3 */
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ShrChartsComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ARIA_CURRENT: 'aria-current',
        CONED_ENERGY_USAGE: 'energy-usage',
        CONED_CHART: 'coned-chart',
        CONED_CHART_HIDE_CLASS: 'coned-chart--hide',
        CONED_CHART_TAB_ACTIVE_CLASS: 'coned-tabs__tab-item--active',
        CONED_CHART_DEMAND_TAB_HIDDEN: 'coned-tabs--visibility-hidden',
        CONED_CHART_DATE: 'coned-chart__middle-text--date',
        CONED_CHART_MONTH_BUTTON: 'js-coned-chart-monthly',
        CONED_CHART_DATE_BUTTON: 'js-coned-chart-daily',
        CONED_CHART_EVENT_BUTTON: 'js-coned-chart-event',
        CONED_CHART_BUTTON_DISABLED: 'coned-chart__buttons--square-blue-disabled',
        CONED_CHART_ORU_BUTTON_DISABLED: 'coned-chart__buttons--square-teal-disabled',
        CONED_CHART_BUTTONS_HIDE: 'coned-chart__buttons--hide',
        CONED_CHART_ARROW_HIDE: 'coned-chart__arrow--hide',
        CONED_CHART_LEFT_ARROW: 'coned-chart__arrow--left',
        CONED_CHART_RIGHT_ARROW: 'coned-chart__arrow--right',
        CONED_CHART_ERROR: 'coned-chart__error-message',
        CONED_CHART_DAILY_NOTE: 'coned-chart__note-paragraph--demandDaily',
        CONED_CHART_EVENT_NOTE: 'coned-chart__note-paragraph--demandEvent',
        CONED_CHART_HEADER: 'coned-chart__header-title',

        CONED_CHART_FORM_LOADING: 'js-form-loading',
        CONED_CHART_DEMAND_BUTTON: 'js-demandTab',
        CONED_CHART_MOBILE_DEMAND: 'js-demandMobileTab',
        CONED_CHART_ERROR_MESSAGE: 'js-chart-module-error',
        CONED_CHART_NODATA_MESSAGE: 'js-chart-module-noData-error',
        CONED_CHART_MODULE: 'js-chart-module-content',
        CONED_CHART_CONTAINER: 'js-chart-container',
        CONED_CHART_NOTE: 'js-chart-note',

        CONED_CHART_SERVICE: 'data-chart-service', // data attribute that contains the service url
        CONED_CHART_DATA_MAID: 'data-maid', // data attribute that contains the maid number
        CONED_CHART_DATA_RATE: 'data-rate', // data attribute that contains the account rate type
        CONED_CHART_DATA_TIME: 'data-chart-time', // data attribute that contains the type of the first chart (monthly - daily)
        CONED_CHART_DATE_TEXT: 'data-chart-date-text', // data attribute that contains the complete date of the chart
        CONED_CHART_DATA_FROM_DATE: 'data-from-date', // data attribute that contains the start date of the line chart
        CONED_CHART_DATA_TO_DATE: 'data-to-date', // data attribute that contains the final date of the line chart
        CONED_CHART_DATA_ZERO_HOVER: 'data-zero-value-hover', // data attribute that contains the hover text for zero values
        CONED_CHART_DATA_REGULAR_HOVER: 'data-regular-day-hover', // data attribute that contains the hover text for regular days
        CONED_CHART_DATA_NEGATIVE_HOVER: 'data-negative-value-hover', // data attribute that contains the hover text for negative values
        CONED_CHART_BILLING_ERROR: 'data-billing-error', // data attribute that contains the text for the date on the error chart message
        CONED_CHART_TOTAL_AVERAGE_DEMAND: 'data-total-average-legend', // data attribute that contains the text for the total hover value
        CONED_CHART_DATA_PERIOD: 'data-period', // data attribute that contains the period value of the line chart
        CONED_CHART_DATA_WEEK: 'data-week', // data attribute that contains the week value of the line chart
        CONED_CHART_DATA_SYMBOL: 'data-symbol', // data attribute that contains the chart symbol (for y-axis)
        CONED_CHART_DATA_IS_SOLAR: 'data-is-solar', // data attribute that contais if it's a solar chart

        CONED_CHART_FIXED_SUBSCRIPTION: 'FixedSubscriptionRate',
        CONED_CHART_TYPE: 'Type',
        CONED_CHART_TOTAL_AVERAGE_DEMAND_VALUE: 'totalAverageDemandValue',
        CONED_CHART_SHR_OPTION: 'SHR',
        CONED_CHART_STANDARD_OPTION: 'Standard',
        CONED_CHART_GROUPBY_MONTHLY: 'Monthly',
        CONED_CHART_GROUPBY_WEEKLY: 'Weekly',
        CONED_CHART_YEAR: 'Year',
        CONED_CHART_QUATER: 'Quarter',
        CONED_CHART_DEMAND: 'demand',
        CONED_CHART_MONTHLY: 'monthly',
        CONED_CHART_DAILY: 'daily',
        CONED_CHART_EVENT: 'event',
        CONED_CHART_TRUE: 'true',
        CONED_HIDDEN_CLASS: 'hidden',
        CONED_DISABLED_ATTR: 'disabled',

        CONED_CHART_DAILY_COLOR: '#27aa5e',
        CONED_CHART_EVENT_COLOR: '#94000f',

        CONED_DATA_ATTRIBUTES_JSON: '/_static/legendDataAttributes.json'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var ShrChartsComponent = function ($shrChartsComponent) {
        var $conedEnergyContent, // Energy content section
            $conedChartDailyButton, // Daily button
            $conedChartMonthButton, // Monthly button
            $conedChartEventButton, // Event button
            $conedChartDemandTab, // Demand tab
            $conedChartDemandMobileTab, // Mobile demand tab
            $conedChartDateText, // Section for the chart date
            $conedChartLastDate, // Left arrow button
            $conedChartNextDate, // Right arrow button
            $conedChartModule, // Entire chart module
            $conedChartErrorMessage, // Div error message
            $conedChartNoDataMessage, // No data text on the chart header
            $conedChartDailyNoteText, // Note section on daily charts
            $conedChartNote, // Note section on general charts
            $conedChartContainer, // Section for the chart svg
            $conedChartHeaderTitle,  // Header title section (under the date)
            $conedChartEventNoteText,  // Note section on event charts
            $formLoading,  // Loading form
            $conedChartTable, // Get the chart table
            $conedChartTableButton, // Show demand table button

            _device, // Save the device size to reload option
            _isSolar, // Determine if the chart is solar or not
            _monthlyIdsList, // List for the monthly charts id
            _dailyIdsList, // List for the daily charts id
            _eventIdsList, // List for the event charts id
            _monthlyPeriodsList, // List with the hover dates
            _allGeneralPeriods, // List of all periods
            _fromMonthlyPeriod, // Date when monthly period starts
            _toMonthlyPeriod, // Date when monthly period ends
            _fromDailyPeriod, // Date when daily period starts
            _toDailyPeriod, // Date when daily period ends
            _IPPPeriods, // Total of ipp periods
            _morePeriods, // Boolean
            _time, // Determines if it's daily/monthly/event
            _disabledButton, // corresponding disabled button class name for oru-coned accounts
            _chartId, // Id for the new chart
            _jsonFile, // Local json file name
            _isFirstbillingPeriodLoad, // Flag for fist load of billing period
            _actualChart, // Id for the actual chart
            _dataMonthlyNames, // Legend data names for monthly charts
            _dataDailyNames, // Legend data names for daily charts
            _dataEventNames, // Legend data names for event charts
            _legendTooltip, // Object with legend tooltip text
            _dateText, // Header date text
            _monthLine, // X axis where the year vertical line is going to be
            _yearLineText, // Value for the year label line
            _allMonths, // List of months
            _allMonthsPeriodServiceCall, // List of months used for the period service call
            _allCompleteMonths, // Complete month text
            _lastChart, // Determine if the last arrow were clicked
            _chart, // Actual draw chart
            _yAxis, // List of y-axis values
            _allPeriods, // All monthly periods
            _prevChart, // Previous arrow where clicked
            _nextChart, // Next arrow where clicked
            _indexBillingPeriod, // Actual Billing period
            _kilowatt_symbol, // kW
            _isEvent, // Event chart
            _errorFlag, // Error occured
            _monthlyErrorFlag, // Ocurred an error in monthly chart
            _actualPeriod, // index actual period
            _isLastPeriod, // Boolean
            _monthlyMobilePeriod, // Last monthly period asked
            _rateType, // Account rate type
            _lastPeriod, //
            _legendDataAttributes,
            _actualDailyPos,
            _dailyPeriodPosition,
            _chartDataPosition, //
            _monthlyMobilePosition, // Position of the actual monthly chart
            _generalDailyData, // Object with all the daily data according with the period
            _generalEventData, /// Object with all the event data
            _monthlyMobileData, // All monthly data
            _dailyMobileData, // All daily data
            _eventMobileData, // All event data
            _dailyMobileIds, // List of daily mobile charts id
            _eventMobileIds, // List of event mobile charts id
            _hasTotalAverageValue, // Boolean to determine if the total message is require
            _totalAverageValue, // Array with the total values
            _allTotalAverageValue, // All charts total values
            _isFixedSubscription, // Boolean to determine if has fixed subscriptions values
            _fixedSubscriptionValues, // Values for fixed subscription y-axis
            _monthlyColorsThermostat, // Array with monthly chart colors
            _dailyColorsThermostat, // Array with daily chart colors for thermostat A
            _dailyColorsThermostatB, // Array with daily chart colors for thermostat B
            _eventColorsThermostat, // Array with event chart colors for thermostat A
            _eventColorsThermostatB; // Array with event chart colors for thermostat B

        /**
         * Callback to get the Billing Periods
         */
        var getBillingPeriods = function () {
            var serviceUrl = $conedEnergyContent.dataset.getBillingPeriods,
                maid = $conedEnergyContent.getAttribute(CONSTANTS.CONED_CHART_DATA_MAID),
                params;

            _rateType = _rateType
                ? _rateType
                : $conedEnergyContent.getAttribute(CONSTANTS.CONED_CHART_DATA_RATE);
            query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            // Disable chart arrows to prevent issues while loading async content
            $conedChartLastDate.setAttribute(CONSTANTS.CONED_DISABLED_ATTR, true);
            $conedChartNextDate.setAttribute(CONSTANTS.CONED_DISABLED_ATTR, true);            

            params = coned.chartComponents.getBillingPeriods(_rateType, _indexBillingPeriod, maid);

            // Service Call
            query.getData(serviceUrl, successGetBillingPeriods, errorGetBillingPeriods, params);
        };

        /**
         * Show error message when get billing periods service call fail
         */
        var errorGetBillingPeriods = function () {
            dataLayer.push({
                event: 'SHR.demand.error.message'
            });

            var count = 12;
            _indexBillingPeriod = _indexBillingPeriod - count;

            query.addClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);

            $conedChartDateText.innerHTML = $conedChartModule.getAttribute(
                CONSTANTS.CONED_CHART_BILLING_ERROR
            );

            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                _monthlyErrorFlag = true;
            } else {
                _errorFlag = true;
            }
        };

        /**
         * Call billingPeriods function when get billing periods service call success
         * @param {Object} data    Data returned by the service call
         */
        var successGetBillingPeriods = function (data) {
            _isSolar = $conedChartDemandTab.getAttribute(CONSTANTS.CONED_CHART_DATA_IS_SOLAR);

            if (coned.utils.isPatternLab()) {
                var billingPeriodsJson =
                    _isSolar === 'true'
                        ? coned.plConstants.GET_CHART_BILLING_PERIODS_SOLAR
                        : document.querySelector('[data-patternlab-shr-chart-thermostat-B]') ||
                          document.querySelector('[data-oru-patternlab-shr-chart-thermostat-B]')
                        ? coned.plConstants.GET_CHART_BILLING_PERIODS_THERMOSTAT_B
                        : coned.plConstants.GET_CHART_BILLING_PERIODS_THERMOSTAT_A;

                query.getData(billingPeriodsJson, billingPeriods, errorGetBillingPeriods);
            } else {
                billingPeriods(data);
            }
        };

        /**
         * Create an array with all the periods and determinate the fromDate and toDate variables for the period
         * @param {Object} data    Data returned by the get-billing-periods service call
         */
        var billingPeriods = function (data) {
            _allGeneralPeriods = _allGeneralPeriods.concat(data.periods);
            _morePeriods = data.morePeriods;
            _kilowatt_symbol = $conedChartDemandTab.getAttribute(CONSTANTS.CONED_CHART_DATA_SYMBOL);

            if (_morePeriods) {
                query.removeClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            }

            if (data && data.periods && data.periods.length > 0) {
                query.removeClass($conedChartDemandTab, CONSTANTS.CONED_CHART_DEMAND_TAB_HIDDEN);
                query.removeClass($conedChartDemandMobileTab, CONSTANTS.CONED_HIDDEN_CLASS);

                var periods = data.periods,
                    keys = Object.keys(periods),
                    ippPeriodsCount;

                _allPeriods = [];

                keys.forEach(function (key) {
                    _allPeriods.push(periods[key].billToDate);

                    if (periods[key].billProgram === CONSTANTS.CONED_CHART_SHR_OPTION) {
                        _IPPPeriods++;
                    }

                    if (
                        periods[key].billProgram === CONSTANTS.CONED_CHART_SHR_OPTION ||
                        periods[key].billProgram === CONSTANTS.CONED_CHART_STANDARD_OPTION
                    ) {
                        ippPeriodsCount = key;
                    }
                });

                _fromMonthlyPeriod = periods[ippPeriodsCount].billFromDate;
                _toMonthlyPeriod = periods[0].billToDate;

                getChartPeriod();

                // Enable chart arrows after async content has loaded
                $conedChartLastDate.removeAttribute(CONSTANTS.CONED_DISABLED_ATTR);
                $conedChartNextDate.removeAttribute(CONSTANTS.CONED_DISABLED_ATTR);
                // Since arrows get disabled while loading data, focus goes to body
                // so we re focus the prev chart arrow
                if (!_isFirstbillingPeriodLoad) {
                    if (document.activeElement === document.body) {
                        $conedChartLastDate.focus();
                    }
                } else {
                    _isFirstbillingPeriodLoad = false;
                }                
            } else {
                query.addClass($conedChartDemandMobileTab, CONSTANTS.CONED_HIDDEN_CLASS);
                query.addClass($conedChartDemandTab, CONSTANTS.CONED_CHART_DEMAND_TAB_HIDDEN);
            }
        };

        /**
         * Call the monthly service
         * @param {Object} event   Event name
         */
        var getMonthlyData = function () {
            if (_monthlyIdsList.length === 0) {
                query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
                callMonthlyService();
            }
        
            coned.utils.removeMultipleListeners(
                $conedChartDemandTab, 
                coned.utils.generalEvents(),
                getMonthlyData
            )   
        };

        /**
         * Call the function to create the daily chart
         * @param {Object} event    Event name
         */
        var createDailyChart = function () {
            var disabled = $conedChartDailyButton.classList.contains(_disabledButton);

            if (disabled) return;

            if (_chartId) {
                coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);
            }

            _isEvent = false;
            _actualDailyPos = 0;
            _chartDataPosition = 0;
            _dailyPeriodPosition = 0;
            _time = CONSTANTS.CONED_CHART_DAILY;
            _dateText = undefined;
            _isFixedSubscription = _rateType === '5' ? true : false;

            dataLayer.push({
                event: 'SHR.demand.toggle.view',
                ToggleLevel: 'Daily'
            });

            showNewChart();
            updateHeader(CONSTANTS.CONED_CHART_DAILY);

            addNoteText(CONSTANTS.CONED_CHART_DAILY);
            updatedCopyArrows($conedChartDailyButton);

            coned.utils.removeMultipleListeners(
                $conedChartDemandTab, 
                coned.utils.generalEvents(),
                createDailyChart
            )       
        };
        /**
         * Call the function to create the daily chart
         * @param {Object} event    Event name
         */
        var createEventChart = function (event) {
            event.preventDefault();

            var disabled = $conedChartEventButton.classList.contains(_disabledButton);
            coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);

            if (disabled) return;

            _isEvent = true;
            _actualDailyPos = 0;
            _chartDataPosition = 0;
            _dailyPeriodPosition = 0;
            _time = CONSTANTS.CONED_CHART_EVENT;
            _dateText = undefined;

            dataLayer.push({
                event: 'SHR.demand.toggle.view',
                ToggleLevel: 'Event'
            });

            showNewChart();
            updateHeader(CONSTANTS.CONED_CHART_EVENT);
            addNoteText(CONSTANTS.CONED_CHART_EVENT);
            updatedCopyArrows($conedChartEventButton);
        };

        /**
         * Show monthly chart when monthly button is clicked
         */
        var showMonthlyChart = function () {
            dataLayer.push({
                event: 'SHR.demand.toggle.view',
                ToggleLevel: 'Monthly'
            });

            coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);
            var disabled = $conedChartMonthButton.classList.contains(_disabledButton);

            _isEvent = false;
            _dateText = undefined;

            if (disabled) return;

            if (_errorFlag) {
                var $actualChart = document.getElementById(_chartId);

                if ($actualChart) {
                    var chartDate = $actualChart.getAttribute(CONSTANTS.CONED_CHART_DATE_TEXT);

                    $conedChartDateText.innerHTML = chartDate;

                    query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);

                    _errorFlag = false;
                }
            }

            if (_monthlyErrorFlag && !_monthlyIdsList[0]) {
                query.removeClass($conedChartDailyButton, _disabledButton);
                query.removeClass($conedChartEventButton, _disabledButton);
                query.addClass($conedChartMonthButton, _disabledButton);
                $conedChartDailyButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                $conedChartEventButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                $conedChartMonthButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    true
                );
                
                callMonthlyService();
            } else {
                _time = CONSTANTS.CONED_CHART_MONTHLY;
                _actualDailyPos = 0;
                _chartDataPosition = 0;
                _dailyPeriodPosition = 0;

                showChart(_monthlyIdsList[0]);
                allowArrows(_monthlyIdsList[0]);
            }

            updateHeader(_time);
            addNoteText(CONSTANTS.CONED_CHART_MONTHLY);
            updatedCopyArrows($conedChartMonthButton);
        };

        /**
         * Get the period to call the respective service and generate the monthly date
         */
        var getChartPeriod = function () {
            var count = _allPeriods.length - 1,
                fromDate = _allPeriods[count],
                toDate = _allPeriods[0],
                $activeTab = $shrChartsComponent.getElementsByClassName(
                    CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS
                )[0];

            _dateText = coned.chartComponents.generateMonthlyDate(fromDate, toDate, _allMonthsPeriodServiceCall); //generateMonthlyDate(fromDate, toDate);

            if (
                !_prevChart &&
                $activeTab.classList.contains(CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS)
            ) {
                $activeTab.click();
            }

            if (_prevChart && _time === CONSTANTS.CONED_CHART_MONTHLY) {
                callMonthlyService();
            } else {
                if (_prevChart && _time === CONSTANTS.CONED_CHART_DAILY) {
                    var dailyEventList =
                            _time === CONSTANTS.CONED_CHART_DAILY ? _dailyIdsList : _eventIdsList,
                        chartIndex = dailyEventList.indexOf(_actualChart) + 1,
                        position =
                            coned.utils.isMobile() || coned.utils.isTablet()
                                ? _dailyPeriodPosition
                                : chartIndex;

                    getDailyEventChart(position);
                }
            }
        };

        /**
         * Call the service to bring monthly information
         */
        var callMonthlyService = function () {
            if (_allGeneralPeriods) {
                var serviceUrl = $conedChartMonthButton.getAttribute(CONSTANTS.CONED_CHART_SERVICE), //'https://reqres.in/api/users'
                    params,
                    maid = $conedEnergyContent.getAttribute(CONSTANTS.CONED_CHART_DATA_MAID),
                    groupBy =
                        coned.utils.isMobile() || coned.utils.isTablet()
                            ? CONSTANTS.CONED_CHART_QUATER
                            : CONSTANTS.CONED_CHART_YEAR;

                params = coned.chartComponents.callMonthlyService(
                    maid,
                    _rateType,
                    _fromMonthlyPeriod,
                    _toMonthlyPeriod,
                    groupBy,
                    _isSolar
                );

                // Service Call
                query.getData(
                    serviceUrl,
                    successGetMonthlyDemand,
                    errorGetMonthlyDemand,
                    params,
                    $formLoading
                );
            }
        };

        /**
         * Show error message when server call fail for monthly data
         */
        var errorGetMonthlyDemand = function () {
            var count = _allPeriods.length - 1,
                fromDate = _allPeriods[count],
                toDate = _allPeriods[0];

            _monthlyErrorFlag = true;
            _lastPeriod = false;
            _dateText = coned.chartComponents.generateMonthlyDate(fromDate, toDate, _allMonths); //generateMonthlyDate(fromDate, toDate);
            $conedChartDateText.innerHTML = _dateText;

            dataLayer.push({
                event: 'SHR.demand.error.message'
            });

            query.removeClass($conedChartModule, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);

            if (_monthlyIdsList.length > 0) {
                query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            } else {
                query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            }
        };

        /**
         * List the monthtly id chart
         * @param {Object} data      Data returned by the get-monthly-data service call
         */
        var successGetMonthlyDemand = function (data) {
            var $activeTab = $shrChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS
            )[0];

            query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);

            _lastPeriod = _morePeriods ? false : true;

            generateChartId($activeTab);
            _monthlyIdsList.push(_chartId);
            _monthlyErrorFlag = false;

            if (coned.utils.isPatternLab()) {
                query.getData(_jsonFile, generateChart, errorGetBillingPeriods);
            } else {
                generateChart(data);
            }

            _allPeriods = [];
            updateHeader('monthly');
        };

        /**
         * Change the header title according with the type of chart
         * @param typeChart    // Determine if it is a monthly, daily or event chart
         */
        var updateHeader = function (typeChart) {
            var typeButton =
                    typeChart === CONSTANTS.CONED_CHART_MONTHLY
                        ? $conedChartMonthButton
                        : typeChart === CONSTANTS.CONED_CHART_DAILY
                        ? $conedChartDailyButton
                        : $conedChartEventButton,
                headerText = typeButton.getAttribute('data-header-title'),
                headerTooltip = typeButton.getAttribute('data-header-title-tooltip'),
                headerLink = typeButton.getAttribute('data-header-title-link'),
                $graphic = document.getElementsByClassName('js-chart-module-content')[0],
                oruTooltip = coned.utils.isOru() ? ' coned-tooltip--oru ' : '',
                headerLinkColor = coned.utils.isOru()
                    ? 'coned-chart__header-link--orange'
                    : 'coned-chart__header-link--blue',
                graphicHeader;

            if (headerTooltip) {
                d3.select('.js-chart-middle-text')
                    .select('.coned-chart__header')
                    .select('.coned-tooltip')
                    .remove();

                d3.select('.js-chart-middle-text')
                    .select('.coned-chart__header')
                    .html(
                        '<div class="coned-tooltip ' +
                            oruTooltip +
                            ' js-module" data-module="ToolTipComponent">' +
                            '<p class="coned-chart__header-title" > </p>' +
                            '<button type="button" class="coned-tooltip__text coned-tooltip--open js-tooltip-open coned-chart__tooltip--icon" aria-label="Information" aria-expanded="false" onclick="dataLayer.push({\'event\': \'SHR.demand.top.tool.tip\'});"></button>' +
                            '<div class="tooltip__templates">' +
                            '<div class="coned-tooltip__wrapper js-coned-tooltip-wrapper">' +
                            '<button type="button" class="coned-tooltip--close js-tooltip-close" aria-label="close"></button>' +
                            '<div class="coned-tooltip__content">' +
                            '<p class="js-tooltip-content">' +
                            headerTooltip +
                            '&nbsp;' +
                            '<a class="coned-chart__header-link ' +
                            headerLinkColor +
                            ' tag-shr-learn-more" href="' +
                            headerLink +
                            '"> Learn More</a>' +
                            '</p></div></div></div></div>'
                    );
                setTimeout(function () {
                    graphicHeader = $graphic.getElementsByClassName('coned-chart__header')[0];

                    new coned.components.ToolTipComponent(graphicHeader);
                    updateHeaderTitle(headerText);
                }, 50);
            } else {
                graphicHeader = $graphic.getElementsByClassName('coned-chart__header')[0];
                graphicHeader
                    .getElementsByClassName('coned-tooltip__text')[0]
                    .classList.add('hidden');

                updateHeaderTitle(headerText);
            }
        };

        /**
         * * Change the header title according with the type of chart
         * @param {String} headerText    Text that is going to be in the header
         */
        var updateHeaderTitle = function (headerText) {
            $conedChartHeaderTitle = $shrChartsComponent.getElementsByClassName(
                CONSTANTS.CONED_CHART_HEADER
            )[0];
            $conedChartHeaderTitle.innerHTML = headerText;
        };

        /**
         * Create the chart id
         * @param {String} $activeTab     Name of the tab that is active (monthly or daily)
         */
        var generateChartId = function ($activeTab) {
            var device = '',
                date,
                rate,
                week = '',
                type = '',
                localFolder = _isSolar === 'true' ? 'Solar/' : _rateType === '4' ? 'T-A/' : 'T-B/';

            _time = $activeTab.getAttribute(CONSTANTS.CONED_CHART_DATA_TIME);

            if (_time === CONSTANTS.CONED_CHART_DAILY || _time === CONSTANTS.CONED_CHART_EVENT) {
                var tabSelected = _isEvent ? $conedChartEventButton : $conedChartDailyButton;
                type = _time;

                // Disable Daily button
                query.addClass(tabSelected, _disabledButton);
                query.removeClass($conedChartMonthButton, _disabledButton);

                if ($conedChartMonthButton) {
                    $conedChartMonthButton.setAttribute(
                        CONSTANTS.ARIA_CURRENT,
                        false
                    );
                }

                if (_time === CONSTANTS.CONED_CHART_DAILY) {
                    query.removeClass($conedChartEventButton, _disabledButton);
                    $conedChartDailyButton.setAttribute(
                        CONSTANTS.ARIA_CURRENT,
                        true
                    );
                    $conedChartEventButton.setAttribute(
                        CONSTANTS.ARIA_CURRENT,
                        false
                    );
                } else {
                    query.removeClass($conedChartDailyButton, _disabledButton);
                    $conedChartDailyButton.setAttribute(
                        CONSTANTS.ARIA_CURRENT,
                        false
                    );
                    $conedChartEventButton.setAttribute(
                        CONSTANTS.ARIA_CURRENT,
                        true
                    );
                }

                _fromDailyPeriod = _allGeneralPeriods[_dailyPeriodPosition].billFromDate;
                _toDailyPeriod = _allGeneralPeriods[_dailyPeriodPosition].billToDate;
                date = coned.chartComponents
                    .generateDailyDate(_fromDailyPeriod, _toDailyPeriod, false, _allMonths)
                    .replace(/\s|[,]/g, '');

                if (coned.utils.isMobile() || coned.utils.isTablet()) {
                    device = 'M';
                    week = _chartDataPosition;
                }
            } else {
                query.addClass($conedChartMonthButton, _disabledButton);
                query.removeClass($conedChartDailyButton, _disabledButton);
                $conedChartDailyButton.setAttribute(
                   CONSTANTS.ARIA_CURRENT,
                    false
                );

                if ($conedChartMonthButton) {
                    $conedChartMonthButton.setAttribute(
                        CONSTANTS.ARIA_CURRENT,
                        true
                    );
                }

                if (!_dateText) {
                    var count = _allPeriods.length - 1,
                        fromDate = _allPeriods[count],
                        toDate = _allPeriods[0];

                    _dateText = coned.chartComponents.generateMonthlyDate(
                        fromDate,
                        toDate,
                        _allMonths
                    );
                    date = _dateText.replace(/\s|[,]/g, '');
                } else {
                    date = _dateText.replace(/\s|[,]/g, '');
                }
                device = coned.utils.isMobile() || coned.utils.isTablet() ? 'M' : '';
            }

            rate = coned.utils.isPatternLab() ? localFolder : '';
            _chartId = CONSTANTS.CONED_CHART_DEMAND + date + type;
            _jsonFile = '/get-content/charts/' + rate + _chartId + device + '.json';
            _chartId += week;
        };

        /**
         * Show the corresponding chart or create a new one
         * @param {Object} data     Data returned by the service call
         */
        var generateChart = function (data) {
            // generate object for daily charts
            if (_time === CONSTANTS.CONED_CHART_DAILY || _time === CONSTANTS.CONED_CHART_EVENT) {
                var totalDays, lastDay, firstDay;

                if (coned.utils.isTablet() || coned.utils.isMobile()) {
                    _chartDataPosition = _isLastPeriod ? data.items.length - 1 : _chartDataPosition;
                    _isLastPeriod = false;

                    var dailyEventMobileData = _isEvent ? _eventMobileData : _dailyMobileData,
                        dailyEventMobileList = _isEvent ? _eventMobileIds : _dailyMobileIds,
                        dailyEventList =
                            _time === CONSTANTS.CONED_CHART_DAILY ? _dailyIdsList : _eventIdsList,
                        tabSelected = _isEvent
                            ? CONSTANTS.CONED_CHART_EVENT_BUTTON
                            : CONSTANTS.CONED_CHART_DATE_BUTTON,
                        $activeTab = $shrChartsComponent.getElementsByClassName(tabSelected)[0],
                        billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates;

                    if (!dailyEventMobileData[_dailyPeriodPosition]) {
                        if (_isEvent) {
                            _eventMobileData[_dailyPeriodPosition] = data;
                        } else {
                            _dailyMobileData[_dailyPeriodPosition] = data;
                        }
                    }

                    if (!data.items[_chartDataPosition]) {
                        var chart = document.getElementById(_chartId);
                        _chartDataPosition =
                            parseInt(chart.getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK)) + 1;
                    }

                    totalDays = data.items[_chartDataPosition].periods.length - 1;
                    lastDay = data.items[_chartDataPosition].periods[0].demandDate;
                    firstDay = data.items[_chartDataPosition].periods[totalDays].demandDate;

                    _dateText = coned.chartComponents.generateDailyDate(
                        firstDay,
                        lastDay,
                        false,
                        _allMonths
                    );
                    generateChartId($activeTab);

                    if (
                        billRates &&
                        _.invert(dailyEventMobileList[_actualPeriod])[_chartId] === undefined
                    ) {
                        listMobileChartIds();
                    } else {
                        if (dailyEventList.indexOf(_chartId) < 0) {
                            listChartId();
                        }
                    }
                    data = data.items[_chartDataPosition];
                } else {
                    totalDays = data.items[_chartDataPosition].periods.length - 1;
                    lastDay = data.items[_chartDataPosition].periods[0].demandDate;
                    firstDay = data.items[_chartDataPosition].periods[totalDays].demandDate;

                    _dateText = coned.chartComponents.generateDailyDate(
                        firstDay,
                        lastDay,
                        false,
                        _allMonths
                    );
                    data = data.items[0];
                }
            } else {
                if (_monthlyMobileData[_monthlyMobilePeriod] === undefined) {
                    _monthlyMobileData[_monthlyMobilePeriod] = data.items;
                }

                if (coned.utils.isTablet() || coned.utils.isMobile()) {
                    var total =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition].periods
                                .length - 1,
                        fromDate =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                                .periods[total].endDate,
                        toDate =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                                .periods[0].endDate;
                    _dateText = coned.chartComponents.generateMonthlyDate(
                        fromDate,
                        toDate,
                        _allMonths
                    ); //generateMonthlyDate(fromDate, toDate);

                    data = data.items ? data.items[_monthlyMobilePosition] : data;
                } else {
                    data = data.items[0];
                }
            }
            selectChartData(data);
        };

        /**
         * List the mobile ids in the corresponding list
         */
        var listMobileChartIds = function () {
            var chartId = _chartId,
                regExp = /[A-Za-z]*[0-9]*-[A-Za-z]*[0-9]*/g,
                listIds = _isEvent ? _eventMobileIds : _dailyMobileIds;
            chartId = chartId.match(regExp)[0];

            if (listIds[_actualPeriod] === undefined) {
                listIds[_actualPeriod] = {};
            }
            listIds[_actualPeriod][_chartDataPosition] = chartId + _time + _chartDataPosition;
        };

        /**
         * List the desktop ids in the corresponding list
         */
        var listChartId = function () {
            var chartId = _chartId,
                regExp = /[A-Za-z]*[0-9]*-[A-Za-z]*[0-9]*/g,
                periodWeek;

            chartId = chartId.match(regExp)[0];
            periodWeek = coned.utils.isTablet() || coned.utils.isMobile() ? _chartDataPosition : '';
            if (_isEvent) {
                _eventIdsList.push(chartId + CONSTANTS.CONED_CHART_EVENT + periodWeek);
            } else {
                _dailyIdsList.push(chartId + CONSTANTS.CONED_CHART_DAILY + periodWeek);
            }
        };

        /**
         * Check corresponding radio button option and show/create daily chart
         * @param {String} type   Specified which radio button option is selected
         */
        var showNewChart = function () {
            var newChart = true,
                dailyEventDesktopList,
                dailyEventMobileList,
                dailyEventList,
                dailyEventListLength;

            if (_monthlyErrorFlag) {
                query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            }

            dailyEventDesktopList = _isEvent === true ? _eventIdsList : _dailyIdsList;
            dailyEventMobileList = _isEvent === true ? _eventMobileIds : _dailyMobileIds;
            dailyEventList =
                coned.utils.isMobile() || coned.utils.isTablet()
                    ? dailyEventMobileList[0]
                    : dailyEventDesktopList;
            dailyEventListLength =
                coned.utils.isMobile() || coned.utils.isTablet()
                    ? dailyEventList !== undefined
                        ? Object.keys(dailyEventList).length
                        : 0
                    : dailyEventList.length;

            if (dailyEventListLength > 0) {
                newChart = false;
                showChart(dailyEventList[0]);
            }

            if (newChart) {
                getDailyEventChart(0);
            }
        };

        /**
         * Calculate fromDate and toDate data for daily chart and call the service
         * @param {String} type    Specified which radio button is selected
         * @param {Int} position   Position of the actual period
         */
        var getDailyEventChart = function (position) {
            var groupBy;
            _actualPeriod = position;

            if (coned.utils.isMobile() || coned.utils.isTablet()) {
                groupBy = CONSTANTS.CONED_CHART_GROUPBY_WEEKLY;
            } else {
                groupBy = CONSTANTS.CONED_CHART_GROUPBY_MONTHLY;
            }

            var currentButton =
                    _time === CONSTANTS.CONED_CHART_DAILY
                        ? $conedChartDailyButton
                        : $conedChartEventButton,
                serviceUrl = currentButton.getAttribute(CONSTANTS.CONED_CHART_SERVICE),
                params,
                billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates,
                typeChart = billRates ? billRates[0] : 'null',
                maid = $conedEnergyContent.getAttribute(CONSTANTS.CONED_CHART_DATA_MAID);

            _fromDailyPeriod = _allGeneralPeriods[position].billFromDate;
            _toDailyPeriod = _allGeneralPeriods[position].billToDate;

            if (typeChart === 'null') {
                errorGetServiceCall();
                return;
            }

            params = coned.chartComponents.getDailyChart(
                maid,
                _rateType,
                _fromDailyPeriod,
                _toDailyPeriod,
                groupBy,
                _isSolar
            );

            if (_isFixedSubscription) {
                params[CONSTANTS.CONED_CHART_FIXED_SUBSCRIPTION] =
                    _allGeneralPeriods[_dailyPeriodPosition].billFixedSubscriptionRate;
            }

            if (_time === CONSTANTS.CONED_CHART_DAILY) {
                params[CONSTANTS.CONED_CHART_TYPE] = typeChart;
            }

            // Service Call
            query.getData(
                serviceUrl,
                successGetServiceCall,
                errorGetServiceCall,
                params,
                $formLoading
            );
        };

        /**
         * Show error message when service call fail
         */
        var errorGetServiceCall = function () {
            dataLayer.push({
                event: 'SHR.demand.error.message'
            });

            var currentButton =
                _time === CONSTANTS.CONED_CHART_DAILY
                    ? $conedChartDailyButton
                    : $conedChartEventButton;
            _errorFlag = true;
            _dateText = coned.chartComponents.generateDailyDate(
                _fromDailyPeriod,
                _toDailyPeriod,
                true,
                _allMonths
            );
            $conedChartDateText.innerHTML = _dateText;

            query.removeClass($conedChartModule, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            query.addClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);

            // enable last button
            if ($conedChartMonthButton && $conedChartMonthButton.disabled) {
                query.removeClass($conedChartMonthButton, _disabledButton);
                $conedChartMonthButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
            } else if ($conedChartDailyButton.disabled) {
                query.removeClass($conedChartDailyButton, _disabledButton);
                $conedChartDailyButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
            } else if ($conedChartEventButton.disabled) {
                query.removeClass($conedChartEventButton, _disabledButton);
                $conedChartEventButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
            }

            // disable current button
            query.addClass(currentButton, _disabledButton);
            currentButton.setAttribute(
                CONSTANTS.ARIA_CURRENT,
                true
            );

            query.removeClass($conedChartModule, CONSTANTS.CONED_HIDDEN_CLASS);

            var dailyEventList =
                _time === CONSTANTS.CONED_CHART_DAILY ? _dailyIdsList : _eventIdsList;

            if (_actualPeriod === '0') {
                query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            } else if (_actualDailyPos > 0 && _actualDailyPos - 1 < dailyEventList.length) {
                query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
            }
        };

        /**
         * Add to daily list corresponding chart ids
         * @param {Object} data     Data returned by the get-daily service call
         */
        var successGetServiceCall = function (data) {
            var tabSelected = _isEvent
                    ? CONSTANTS.CONED_CHART_EVENT_BUTTON
                    : CONSTANTS.CONED_CHART_DATE_BUTTON,
                $activeTab = $shrChartsComponent.getElementsByClassName(tabSelected)[0],
                dailyEventList = _isEvent ? _eventIdsList : _dailyIdsList;

            query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
            query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            if (coned.utils.isMobile() || coned.utils.isTablet()) {
                if (_nextChart) {
                    var dailyEventChart = document.getElementById(dailyEventList[_actualDailyPos]),
                        fromPeriod = dailyEventChart.getAttribute(
                            CONSTANTS.CONED_CHART_DATA_FROM_DATE
                        ),
                        toPeriod = dailyEventChart.getAttribute(CONSTANTS.CONED_CHART_DATA_TO_DATE);

                    _dateText = coned.chartComponents.generateDailyDate(
                        fromPeriod,
                        toPeriod,
                        false,
                        _allMonths
                    );
                    _nextChart = false;
                } else {
                    _dateText = coned.chartComponents.generateDailyDate(
                        _fromDailyPeriod,
                        _toDailyPeriod,
                        false,
                        _allMonths
                    ); // mobile
                }
            } else {
                _dateText = coned.chartComponents.generateDailyDate(
                    _fromDailyPeriod,
                    _toDailyPeriod,
                    true,
                    _allMonths
                ); // desktop
            }

            if (coned.utils.isMobile() || coned.utils.isTablet()) {
                listMobileChartKeysIds();
            }

            generateChartId($activeTab);

            if (dailyEventList[_actualDailyPos]) {
                _chartId = dailyEventList[_actualDailyPos];
                allowArrows(_chartId);
            }

            if (!coned.utils.isMobile() && !coned.utils.isTablet()) {
                if (dailyEventList.indexOf(_chartId) < 0) {
                    listChartId();
                }
            }

            if (coned.utils.isPatternLab()) {
                query.getData(_jsonFile, generateChart, errorGetBillingPeriods);
            } else {
                generateChart(data);
            }
        };

        /**
         * Generate the daily charts in mobile devices
         * @param {Object} data      Data returned by the service call
         */
        var selectChartData = function (data) {
            var chart = document.getElementById(_chartId),
                index,
                day,
                allMonthsData;

            if (_time === CONSTANTS.CONED_CHART_DAILY) {
                var subGeneralDailyData = (_generalDailyData[_chartId] = {});

                for (index = 0; index < data.periods.length; index++) {
                    day = data.periods[index].label;
                    subGeneralDailyData[day] = [
                        data.periods[index].measuredTime,
                        data.periods[index].specialDay
                    ];
                }
            } else if (_time === CONSTANTS.CONED_CHART_EVENT) {
                var subGeneralEventData = (_generalEventData[_chartId] = {});

                for (index = 0; index < data.periods.length; index++) {
                    day = data.periods[index].label;
                    subGeneralEventData[day] = [data.periods[index].measuredTime];
                }
            } else {
                _monthlyMobilePosition++;
            }

            allowArrows(_chartId);

            if (chart) {
                showChart(_chartId);
            } else {
                _yAxis = data.yAxis;
                _fixedSubscriptionValues = data.fixedSubscriptionYAxis;
                allMonthsData = data.periods;

                if (_fixedSubscriptionValues) {
                    for (var pos = 0; pos < _fixedSubscriptionValues.length; pos++) {
                        var elemPos = _yAxis.indexOf(_fixedSubscriptionValues[pos]);
                        if (elemPos > -1) {
                            _yAxis.splice(elemPos, 1);
                        }
                    }
                }

                // Hide current chart before the service call
                var $conedActualChart = document.getElementById(_actualChart);
                query.addClass($conedActualChart, CONSTANTS.CONED_CHART_HIDE_CLASS);

                getLegendText();
                getGeneralData(allMonthsData.reverse());
            }
        };

        /**
         * Display the corresponding arrows for the actual chart
         * @param {String} chartId      Id of the actual chart
         */
        var allowArrows = function (chartId) {
            if (_time === CONSTANTS.CONED_CHART_DAILY || _isEvent) {
                var dailyEventList = _isEvent ? _eventIdsList : _dailyIdsList,
                    billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates,
                    dailyEventMobileList = _isEvent ? _eventMobileData : _dailyMobileData,
                    dailyEventMobileIdsList = _isEvent ? _eventMobileIds : _dailyMobileIds,
                    position =
                        coned.utils.isMobile() || coned.utils.isTablet()
                            ? _dailyPeriodPosition
                            : dailyEventList.indexOf(chartId),
                    lastWeek =
                        (coned.utils.isMobile() || coned.utils.isTablet()) &&
                        billRates &&
                        dailyEventMobileList[position] &&
                        dailyEventMobileList[position].items[_chartDataPosition + 1]
                            ? false
                            : true;

                if (position < _IPPPeriods - 1 || !lastWeek) {
                    query.removeClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                } else {
                    if (coned.utils.isMobile() || coned.utils.isTablet()) {
                        var dailyEventMobileData,
                            moreWeeks,
                            lastPositionKey =
                                Object.keys(dailyEventMobileIdsList[_actualPeriod]).length - 1;

                        if (billRates) {
                            dailyEventMobileData = _isEvent ? _eventMobileIds : _dailyMobileIds;
                            moreWeeks =
                                dailyEventMobileData[_actualPeriod][_chartDataPosition + 1] ||
                                dailyEventMobileData[_actualPeriod + 1]
                                    ? true
                                    : false;
                        } else {
                            dailyEventMobileData = _isEvent ? _eventMobileData : _dailyMobileData;
                            moreWeeks = dailyEventMobileData[_dailyPeriodPosition]
                                ? dailyEventMobileData[_dailyPeriodPosition].items.length - 1 >
                                  _chartDataPosition
                                : false;
                        }

                        if (
                            _morePeriods ||
                            moreWeeks ||
                            dailyEventMobileIdsList[_actualPeriod][lastPositionKey] !== chartId
                        ) {
                            //mobile
                            query.removeClass(
                                $conedChartLastDate,
                                CONSTANTS.CONED_CHART_ARROW_HIDE
                            );
                        } else {
                            query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                        }
                    } else {
                        if (_morePeriods && dailyEventList.indexOf(chartId) === _IPPPeriods - 1) {
                            query.removeClass(
                                $conedChartLastDate,
                                CONSTANTS.CONED_CHART_ARROW_HIDE
                            );
                        } else {
                            query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                        }
                    }
                }

                // right arrow
                if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                    if (
                        _actualPeriod > 0 ||
                        _.invert(dailyEventMobileIdsList[_actualPeriod])[_chartId] > 0
                    ) {
                        query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    } else {
                        query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    }
                } else {
                    if (dailyEventList.indexOf(chartId) > 0) {
                        query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    } else {
                        query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    }
                }
            } else {
                if (coned.utils.isTablet() || coned.utils.isMobile()) {
                    if (
                        !_lastPeriod ||
                        _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition] ||
                        _monthlyIdsList.indexOf(chartId) < _monthlyIdsList.length - 1
                    ) {
                        query.removeClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    } else {
                        query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    }
                } else {
                    if (
                        !_lastPeriod ||
                        _monthlyIdsList.indexOf(chartId) < _monthlyIdsList.length - 1
                    ) {
                        query.removeClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                        focusChartArrow($conedChartNextDate, $conedChartLastDate);
                    } else {
                        query.addClass($conedChartLastDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                        focusChartArrow($conedChartLastDate, $conedChartNextDate);
                    }
                }

                // right arrow
                if (_monthlyIdsList.indexOf(chartId) > 0) {
                    query.removeClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    focusChartArrow($conedChartLastDate, $conedChartNextDate);
                } else {
                    query.addClass($conedChartNextDate, CONSTANTS.CONED_CHART_ARROW_HIDE);
                    focusChartArrow($conedChartNextDate, $conedChartLastDate);
                }
            }
        };


        /**
         * Focus the corresponding chart arrow on arrow's visibility change for a11y
         * @param {HTMLElemenString} $arrowToCheck Arrow for which to check visibililty and if is the active element
         * @param {HTMLElemenString} $arrowToFocus Arrow to focus accordingly
         */
         var focusChartArrow = function ($arrowToCheck, $arrowToFocus) {
            if (query.hasClass($arrowToCheck, CONSTANTS.CONED_CHART_ARROW_HIDE) && document.activeElement === $arrowToCheck) {
                $arrowToFocus.focus();
            }
        };

        /**
         * Show the chart and enable the corresponding buttons
         * @param {Object} idName     Chart id to be shown
         */
        var showChart = function (idName) {
            _lastChart = _actualChart;
            _actualChart = idName;
            _chartId = _actualChart;

            var $conedActualChart = document.getElementById(_actualChart),
                $conedLastChart = document.getElementById(_lastChart);

            query.removeClass($conedActualChart, CONSTANTS.CONED_CHART_HIDE_CLASS);

            if (_lastChart != _actualChart) {
                query.addClass($conedLastChart, CONSTANTS.CONED_CHART_HIDE_CLASS);
            }

            _dateText = $conedActualChart.getAttribute(CONSTANTS.CONED_CHART_DATE_TEXT);
            _actualPeriod = parseInt(
                $conedActualChart.getAttribute(CONSTANTS.CONED_CHART_DATA_PERIOD)
            );

            if (_time === CONSTANTS.CONED_CHART_DAILY) {
                query.addClass($conedChartDailyButton, _disabledButton);
                query.removeClass($conedChartMonthButton, _disabledButton);
                query.removeClass($conedChartEventButton, _disabledButton);
                $conedChartEventButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                $conedChartDailyButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    true
                );

                if ($conedChartMonthButton) {
                    $conedChartMonthButton.setAttribute(
                        CONSTANTS.ARIA_CURRENT,
                        false
                    );
                }
            } else if (_time === CONSTANTS.CONED_CHART_EVENT) {
                query.addClass($conedChartEventButton, _disabledButton);
                query.removeClass($conedChartMonthButton, _disabledButton);
                query.removeClass($conedChartDailyButton, _disabledButton);
                $conedChartDailyButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                $conedChartEventButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    true
                );

                if ($conedChartMonthButton) {
                    $conedChartMonthButton.setAttribute(
                        CONSTANTS.ARIA_CURRENT,
                        false
                    );
                }
            } else {
                query.addClass($conedChartMonthButton, _disabledButton);
                query.removeClass($conedChartDailyButton, _disabledButton);
                query.removeClass($conedChartEventButton, _disabledButton);
                $conedChartDailyButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                $conedChartEventButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    false
                );
                $conedChartMonthButton.setAttribute(
                    CONSTANTS.ARIA_CURRENT,
                    true
                );
            }

            var isErrorChart = document
                .getElementById(idName)
                .classList.contains(CONSTANTS.CONED_CHART_ERROR);

            if (isErrorChart) {
                query.addClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNoDataMessage, CONSTANTS.CONED_HIDDEN_CLASS);
            } else {
                query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.addClass($conedChartNoDataMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
            }

            if (_errorFlag) {
                query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                _errorFlag = false;
            }

            setChartDate();
            allowArrows(_actualChart);
            coned.chart.fixChartHeight(_chartId);
        };

        /**
         * Get Legent information
         */
        var getLegendText = function () {
            var isMonthly = _time === CONSTANTS.CONED_CHART_MONTHLY,
                isDaily = _time === CONSTANTS.CONED_CHART_DAILY,
                subscriptionType = _rateType === '5' ? 'subscriptionRate' : 'nonSubscriptionRate',
                legendList = isMonthly
                    ? _dataMonthlyNames
                    : _isEvent
                    ? _dataEventNames
                    : _dataDailyNames,
                timeButton = isMonthly
                    ? $conedChartMonthButton
                    : isDaily
                    ? $conedChartDailyButton
                    : $conedChartEventButton,
                dataAttributesLegend = _legendDataAttributes[_time][subscriptionType]['legend'],
                dataAttributesTooltip = _legendDataAttributes[_time][subscriptionType]['tooltip'],
                dataAttributes = Object.keys(dataAttributesLegend);

            for (var index = 0; index < dataAttributes.length; index++) {
                var dataLegend = dataAttributes[index];

                legendList[dataLegend] =
                    timeButton.getAttribute(dataAttributesLegend[dataLegend]) +
                    ' (' +
                    _kilowatt_symbol +
                    ')';
                _legendTooltip[dataLegend] = timeButton.getAttribute(
                    dataAttributesTooltip[dataLegend]
                );
            }

            legendList.billingCycleTable = $conedChartTable.dataset.billingCycleLegend;
            legendList.dateTable = $conedChartTable.dataset.dateLegend;

            legendList[dataAttributes] =
                timeButton.getAttribute(dataAttributesLegend[dataAttributes]) +
                ' (' +
                _kilowatt_symbol +
                ')';
            _legendTooltip[dataAttributes] = timeButton.getAttribute(
                dataAttributesTooltip[dataAttributes]
            );
        };

        /**
         * Get all the general information to create the chart
         * @param {Array} allMonthsData   Array with all the periods information
         */
        var getGeneralData = function (allMonthsData) {
            var types = Object.keys(allMonthsData[0]),
                dataKeys = Object.keys(allMonthsData[0]),
                colorsObj = {},
                lastPosition;

            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                _hasTotalAverageValue =
                    types.indexOf(CONSTANTS.CONED_CHART_TOTAL_AVERAGE_DEMAND_VALUE) > -1
                        ? true
                        : false;
                lastPosition = _hasTotalAverageValue ? types.length - 5 : types.length - 4;
                types.splice(0, 3);
                types.splice(lastPosition);
            } else {
                lastPosition = types.length;
                types = types.slice(4, lastPosition);
            }

            // set chart legend colors
            colorsObj = setChartColors(colorsObj, types);

            // create array with the chart data
            var monthsData = Object.keys(allMonthsData).map(function (e) {
                    // meter esto en una condicion de monthly?
                    return allMonthsData[e];
                }),
                startDate,
                endDate,
                initialDay,
                finalDay;
            _monthLine = '';
            _yearLineText = '';

            for (var month = 0; month < monthsData.length; month++) {
                if (monthsData[month].splitLabel != '') {
                    _monthLine = monthsData[month].label;
                    _yearLineText = monthsData[month].splitLabel;
                }

                startDate = monthsData[month].beginDate;
                endDate = monthsData[month].endDate;

                if (startDate && endDate) {
                    startDate = startDate.replace(/T[\s\S]*/g, 'T12:00:00');
                    endDate = endDate.replace(/T[\s\S]*/g, 'T12:00:00');
                    startDate = new Date(startDate);
                    endDate = new Date(endDate);
                    startDate.setDate(startDate.getDate() + 1);

                    initialDay =
                        _allMonths[startDate.getMonth()] +
                        ' ' +
                        startDate.getDate() +
                        ', ' +
                        startDate.getFullYear();
                    finalDay =
                        _allMonths[endDate.getMonth()] +
                        ' ' +
                        endDate.getDate() +
                        ', ' +
                        endDate.getFullYear();

                    _monthlyPeriodsList[monthsData[month].label] = initialDay + ' - ' + finalDay;
                }
            }

            setChartDate();
            createChart(colorsObj, dataKeys, lastPosition, monthsData, types);
        };

        /**
         * Select all the necessary data to create the chart the chart and call the corresponding function
         * @param {Object} colorsObj      Contains the color for each chart data
         * @param {Array} dataKeys        Array with the chart data series
         * @param {Int} lastPosition      Amount of chart data
         * @param {Array} monthsData      All chart data
         * @param {Array} types           Contains all chart data names
         *
         */
        var createChart = function (colorsObj, dataKeys, lastPosition, monthsData, types) {
            var chartData = [],
                columnsData = [];

            for (var index = 0; index < dataKeys.length; index++) {
                columnsData.push(dataKeys[index]);

                for (var x = 0; x < monthsData.length; x++) {
                    var chartKeys = monthsData[x],
                        keys = Object.keys(chartKeys);

                    keys.forEach(function (key) {
                        if (key === dataKeys[index]) {
                            columnsData.push(monthsData[x][key]);
                        }
                    });

                    var totalAverage = monthsData[x].totalAverageDemandValue;

                    if (totalAverage && totalAverage !== 0) {
                        var regexTruncateNumber = /[0-9]*(.[0-9])/g,
                            matchNumber = totalAverage.toString().match(regexTruncateNumber);
                        _totalAverageValue[x] = matchNumber ? matchNumber[0] : totalAverage + '.0';
                    }
                }

                chartData.push(columnsData);
                columnsData = [];
            }

            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                lastPosition = _hasTotalAverageValue ? chartData.length - 4 : chartData.length - 3;
                chartData.splice(0, 2);
                chartData.splice(lastPosition);
                createBarChart(_time, types, chartData, colorsObj);
            } else {
                lastPosition = chartData.length;
                chartData = chartData.slice(3, lastPosition);
                createLineChart(_time, types, chartData, colorsObj);
            }

            return chartData;
        };

        /**
         * Create a new bar chart
         * @param {String} _time        Type monthly or daily
         * @param {String} types        Chart data names
         * @param {Array} chartData     All chart data
         * @param {Array} colorsObj     Contains the color for each chart data
         */
        var createBarChart = function (_time, types, chartData, colorsObj) {
            var dataType,
                lineArray = coned.chart.yAxisLines(
                    _isFixedSubscription,
                    _fixedSubscriptionValues,
                    _yAxis,
                    _kilowatt_symbol
                ),
                size,
                textLineChart = 'text-line-year',
                initialLine = _monthLine
                    ? [{ value: _monthLine, class: textLineChart, text: _yearLineText }]
                    : [],
                chart_left_padding = coned.utils.isMobile() || coned.utils.isTablet() ? 0.25 : 0.75,
                $actualChart,
                chartInfo,
                dataNames = _dataMonthlyNames,
                peakOption = 'Peak',
                legendType = 'actualDemand',
                truncateTotal = false,
                totalHoverText = $conedChartMonthButton
                    ? $conedChartMonthButton.getAttribute(
                          CONSTANTS.CONED_CHART_TOTAL_AVERAGE_DEMAND
                      )
                    : '';

            _lastChart = _actualChart;
            _actualChart = _chartId;

            dataType = [];
            size = coned.utils.isMobile() ? 22 : 24;

            d3.select('.coned-chart__container').insert('div').attr('id', _chartId);
            $actualChart = document.getElementById(_chartId);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATE_TEXT, _dateText);

            _allTotalAverageValue[_chartId] = _totalAverageValue;
            _totalAverageValue = {};

            chartInfo = {
                'chartId': _chartId,
                'chartLeftPadding': (coned.utils.isMobile() || coned.utils.isTablet()) ? 20 : 11,
                'chartData': chartData,
                'colorsObj': colorsObj,
                'dataType': dataType,
                'xAxisLeftPadding': chart_left_padding,
                'yAxis': _yAxis,
                'kilowattSymbol': _kilowatt_symbol,
                'dollarSymbol': '',
                'lineArray': lineArray,
                'initialLine': initialLine,
                'allTotalAverageValue': _allTotalAverageValue,
                'dataMonthlyNames': _dataMonthlyNames,
                'monthlyPeriodsList': _monthlyPeriodsList,
                'size': size
            }

            _chart = coned.chart.createBarChart(CONSTANTS.CONED_CHART_SHR_OPTION, chartInfo, truncateTotal, totalHoverText, undefined, dataNames, _fixedSubscriptionValues);

            var regExp = (_time === CONSTANTS.CONED_CHART_DAILY) ? /-([A-Z])*\w+/g : /-([0-9]{4})/g,
                maxValueLength = _yAxis[_yAxis.length-1].length,
                minValueLength = _yAxis[0].length,
                amountDigits = maxValueLength > minValueLength ? maxValueLength : minValueLength,
                isMonthly = _time === CONSTANTS.CONED_CHART_MONTHLY;

            coned.chart.addLegend(
                types,
                _chart,
                _chartId,
                dataNames,
                _legendTooltip,
                legendType,
                peakOption,
                _rateType,
                coned.utils.isOru(),
                isMonthly
            );
            coned.chart.fixChartHeight(_chartId);
            coned.chart.initializeTooltips(
                CONSTANTS.CONED_CHART_SHR_OPTION,
                _chartId,
                'coned-chart-tooltip'
            );
            coned.chart.deleteYearRect(_chartId);

            _chart.resize();

            coned.chart.createRect(_chartId, _isFixedSubscription, amountDigits);
            coned.chart.fixYearLine(_chartId);
            coned.chart.resizeWindow(_chartId, _chart, regExp, _isFixedSubscription, amountDigits);

            //Set focusable=false into svg for screen reader.
            var svgElement = $actualChart.querySelector("svg");
            svgElement && (svgElement.setAttribute("focusable",false), 
                    svgElement.setAttribute("aria-hidden",true));
        };

        /**
         * Create a new line chart
         * @param {String} _time        Type monthly or daily
         * @param {String} types        Chart data names
         * @param {Array} chartData     All chart data
         * @param {Array} colorsObj     Contains the color for each chart data
         */
        var createLineChart = function (_time, types, chartData, colorsObj) {
            _lastChart = _actualChart;
            _actualChart = _chartId;

            d3.select('.coned-chart__container').insert('div').attr('id', _chartId);

            var $actualChart = document.getElementById(_chartId),
                peakClass,
                linesArray = coned.chart.yAxisLines(
                    _isFixedSubscription,
                    _fixedSubscriptionValues,
                    _yAxis,
                    _kilowatt_symbol
                ),
                maxValueLength = _yAxis[_yAxis.length - 1].length,
                minValueLength = _yAxis[0].length,
                amountDigits = maxValueLength > minValueLength ? maxValueLength : minValueLength;

            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATE_TEXT, _dateText);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATA_FROM_DATE, _fromDailyPeriod);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATA_TO_DATE, _toDailyPeriod);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATA_PERIOD, _dailyPeriodPosition);
            $actualChart.setAttribute(CONSTANTS.CONED_CHART_DATA_WEEK, _chartDataPosition);

            peakClass = _isEvent ? 'coned-line-eventChart' : 'coned-line-peakChart';

            var xAxisLeftPadding =
                    coned.utils.isMobile() || coned.utils.isTablet()
                        ? 0.4
                        : (xAxisLeftPadding = 1.5),
                chartLeftPadding = coned.utils.isMobile() || coned.utils.isTablet() ? 20 : 11,
                peakOption = 'Peak',
                chartInfo = {
                    chartId: _chartId,
                    chartLeftPadding: chartLeftPadding,
                    chartData: chartData,
                    colorsObj: colorsObj,
                    peakClass: peakClass,
                    generalDailyData: _isEvent ? _generalEventData : _generalDailyData,
                    allCompleteMonths: _allCompleteMonths,
                    xAxisLeftPadding: xAxisLeftPadding,
                    yAxis: _yAxis,
                    linesArray: linesArray,
                    kilowattSymbol: _kilowatt_symbol
                },
                negativeValueMessage,
                zeroValueMessage,
                specialDayMessage,
                regularDayMessage, 
                legendNames = (_time === CONSTANTS.CONED_CHART_DAILY) ? _dataDailyNames : _dataEventNames;

            if (_time === CONSTANTS.CONED_CHART_DAILY) {
                zeroValueMessage = $conedChartDailyButton.getAttribute(
                    CONSTANTS.CONED_CHART_DATA_ZERO_HOVER
                );
                regularDayMessage = $conedChartDailyButton.getAttribute(
                    CONSTANTS.CONED_CHART_DATA_REGULAR_HOVER
                );
            } else {
                zeroValueMessage = $conedChartEventButton.getAttribute(
                    CONSTANTS.CONED_CHART_DATA_ZERO_HOVER
                );
                negativeValueMessage = $conedChartEventButton.getAttribute(
                    CONSTANTS.CONED_CHART_DATA_NEGATIVE_HOVER
                );
                regularDayMessage = $conedChartEventButton.getAttribute(
                    CONSTANTS.CONED_CHART_DATA_REGULAR_HOVER
                );
            }

                if (_time === CONSTANTS.CONED_CHART_DAILY) {
                    zeroValueMessage = $conedChartDailyButton.getAttribute(CONSTANTS.CONED_CHART_DATA_ZERO_HOVER);
                    regularDayMessage = $conedChartDailyButton.getAttribute(CONSTANTS.CONED_CHART_DATA_REGULAR_HOVER);
                } else {
                    zeroValueMessage = $conedChartEventButton.getAttribute(CONSTANTS.CONED_CHART_DATA_ZERO_HOVER);
                    negativeValueMessage = $conedChartEventButton.getAttribute(CONSTANTS.CONED_CHART_DATA_NEGATIVE_HOVER);
                    regularDayMessage = $conedChartEventButton.getAttribute(CONSTANTS.CONED_CHART_DATA_REGULAR_HOVER);
                }

            _chart = coned.chart.createLineChart(CONSTANTS.CONED_CHART_SHR_OPTION, chartInfo, zeroValueMessage, specialDayMessage, regularDayMessage, _isFixedSubscription, negativeValueMessage, legendNames, _fixedSubscriptionValues);

            if (chartData.length > 0) {
                var dataNames = legendNames,
                    legendType =
                        _time === CONSTANTS.CONED_CHART_DAILY
                            ? 'actualDemand'
                            : 'actualEventDemand',
                    isMonthly = _time === CONSTANTS.CONED_CHART_MONTHLY;

                coned.chart.addLegend(
                    types,
                    _chart,
                    _chartId,
                    dataNames,
                    _legendTooltip,
                    legendType,
                    peakOption,
                    _rateType,
                    coned.utils.isOru(),
                    isMonthly
                );
                coned.chart.fixChartHeight(_chartId);
                coned.chart.initializeTooltips(
                    CONSTANTS.CONED_CHART_SHR_OPTION,
                    _chartId,
                    'coned-chart-tooltip'
                );
            }
            coned.chart.deleteYearRect(_chartId);
            coned.chart.resizeChart(_chartId, _chart, _isFixedSubscription, amountDigits);
            var regExp =
                _time === CONSTANTS.CONED_CHART_DAILY || CONSTANTS.CONED_CHART_EVENT
                    ? /-([A-Z])*\w+/g
                    : /-([0-9]{4})/g;
            coned.chart.fixXaxisValues(_chartId, regExp);

            //Set focusable=false into svg for screen reader.
            var svgElement = $actualChart.querySelector("svg");
            svgElement && (svgElement.setAttribute("focusable",false), 
                    svgElement.setAttribute("aria-hidden",true));
        };

        /**
         * Set colors object for the chart legend
         * @param {Object} colorsObj    Contains the color for each chart data
         * @param {Array} types         Chart data names
         */
        var setChartColors = function (colorsObj, types) {
            var dailyColors = _rateType === '4' ? _dailyColorsThermostat : _dailyColorsThermostatB,
                eventColors = _rateType === '4' ? _eventColorsThermostat : _eventColorsThermostatB,
                colors =
                    _time === CONSTANTS.CONED_CHART_MONTHLY
                        ? _monthlyColorsThermostat
                        : _time === CONSTANTS.CONED_CHART_DAILY
                        ? dailyColors
                        : eventColors;

            for (var index = 0; index < types.length; index++) {
                colorsObj[types[index]] = colors[index];
            }

            return colorsObj;
        };

        /**
         * Set date text on billyng cycle
         */
        var setChartDate = function () {
            query.removeClass($conedChartModule, CONSTANTS.CONED_HIDDEN_CLASS);
            $conedChartDateText.innerHTML = _dateText;
        };

        /**
         * Set all mobile chart ids in the corresponding list
         */
        var listMobileChartKeysIds = function () {
            var listIds = _isEvent ? _eventMobileIds : _dailyMobileIds;
            listIds[_actualPeriod.toString()] =
                listIds[_actualPeriod.toString()] !== undefined
                    ? listIds[_actualPeriod.toString()]
                    : {};
        };

        /**
         * Functionality for the backward chart arrow
         * @param {Object} event   Event name
         */
        var lastDateChart = function (event) {
            event.preventDefault();

            coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);

            dataLayer.push({
                event: 'SHR.demand.navigation.billing',
                label: 'Backward arrow'
            });

            var showChartId, chartIndex, chart, count, chartId, nextChartPeriod, $activeTab;

            _prevChart = true;
            _errorFlag = false;

            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                var total, fromDate, toDate;

                if (_monthlyIdsList.indexOf(_actualChart) === _monthlyIdsList.length - 1) {
                    count = 12;
                    if (
                        _monthlyMobileData[_monthlyMobilePeriod] &&
                        _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition] &&
                        (coned.utils.isMobile() || coned.utils.isTablet())
                    ) {
                        _monthlyMobilePeriod = _monthlyMobileData[_monthlyMobilePeriod][
                            _monthlyMobilePosition
                        ]
                            ? _monthlyMobilePeriod
                            : _monthlyMobilePeriod++;
                        _monthlyMobilePosition = _monthlyMobileData[_monthlyMobilePeriod][
                            _monthlyMobilePosition
                        ]
                            ? _monthlyMobilePosition
                            : 0;
                        $activeTab = $shrChartsComponent.getElementsByClassName(
                            CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS
                        )[0];

                        total =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition].periods
                                .length - 1;
                        fromDate =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                                .periods[total].beginDate;
                        toDate =
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                                .periods[0].endDate;
                        _dateText = coned.chartComponents.generateMonthlyDate(
                            fromDate,
                            toDate,
                            _allMonths
                        ); //generateMonthlyDate(fromDate, toDate);
                        generateChartId($activeTab);
                        _monthlyIdsList.push(_chartId);
                        generateChart(
                            _monthlyMobileData[_monthlyMobilePeriod][_monthlyMobilePosition]
                        );
                    } else {
                        if (_allPeriods.length > 0) {
                            total = _allPeriods.length - 1;
                            fromDate = _allPeriods[total];
                            toDate = _allPeriods[0];

                            _dateText = coned.chartComponents.generateMonthlyDate(
                                fromDate,
                                toDate,
                                _allMonths
                            ); //generateMonthlyDate(fromDate, toDate);
                            _monthlyMobilePeriod++;
                            _monthlyMobilePosition = 0;
                            callMonthlyService();
                        } else {
                            _indexBillingPeriod = _indexBillingPeriod + count;
                            _monthlyMobilePeriod++;
                            _monthlyMobilePosition = 0;
                            getBillingPeriods();
                        }
                    }
                } else {
                    chartIndex = _monthlyIdsList.indexOf(_actualChart);
                    showChartId = _monthlyIdsList[chartIndex + 1];
                    showChart(showChartId);
                }
            } else {
                var dailyEventList = _isEvent ? _eventIdsList : _dailyIdsList,
                    dailyEventMobileData = _isEvent ? _eventMobileData : _dailyMobileData,
                    dailyEventMobileList = _isEvent ? _eventMobileIds : _dailyMobileIds,
                    newPeriod,
                    callmorePeriods,
                    lastPeriod,
                    billRates = _allGeneralPeriods[_dailyPeriodPosition].billRates;

                chartIndex =
                    billRates && (coned.utils.isMobile() || coned.utils.isTablet())
                        ? _.invert(dailyEventMobileList[_actualPeriod])[_actualChart]
                        : dailyEventList.indexOf(_actualChart);
                nextChartPeriod =
                    billRates && (coned.utils.isMobile() || coned.utils.isTablet())
                        ? (dailyEventMobileData[_actualPeriod] &&
                              dailyEventMobileData[_actualPeriod].items[
                                  parseInt(chartIndex) + 1
                              ] === undefined) ||
                          dailyEventMobileData[_actualPeriod] === undefined
                            ? _actualPeriod + 1
                            : _actualPeriod
                        : _actualPeriod;
                chartIndex =
                    billRates && (coned.utils.isMobile() || coned.utils.isTablet())
                        ? (dailyEventMobileData[_actualPeriod] &&
                              dailyEventMobileData[_actualPeriod].items[
                                  parseInt(chartIndex) + 1
                              ] === undefined) ||
                          dailyEventMobileData[_actualPeriod] === undefined
                            ? '-1'
                            : chartIndex
                        : chartIndex;
                chartId =
                    billRates && (coned.utils.isMobile() || coned.utils.isTablet())
                        ? dailyEventMobileList[nextChartPeriod]
                            ? dailyEventMobileList[nextChartPeriod][parseInt(chartIndex) + 1]
                            : undefined
                        : dailyEventList[chartIndex + 1];
                chart = document.getElementById(chartId);

                _actualDailyPos++;

                if (coned.utils.isMobile() || coned.utils.isTablet()) {
                    _chartDataPosition++;
                    newPeriod =
                        dailyEventMobileData[_dailyPeriodPosition] === undefined ||
                        dailyEventMobileData[_dailyPeriodPosition].items.length ===
                            _chartDataPosition
                            ? true
                            : false;
                    lastPeriod =
                        parseInt(_dailyPeriodPosition) === _allGeneralPeriods.length - 1
                            ? true
                            : false;
                    callmorePeriods = newPeriod && lastPeriod;
                } else {
                    callmorePeriods = dailyEventList.indexOf(_actualChart) === _IPPPeriods - 1;
                }

                if (_morePeriods && callmorePeriods) {
                    count = 12;
                    _indexBillingPeriod = _indexBillingPeriod + count;
                    _dailyPeriodPosition++;
                    _chartDataPosition = 0;
                    getBillingPeriods();
                } else {
                    if (chart) {
                        _dailyPeriodPosition = parseInt(
                            document
                                .getElementById(chartId)
                                .getAttribute(CONSTANTS.CONED_CHART_DATA_PERIOD)
                        );
                        _chartDataPosition = parseInt(
                            document
                                .getElementById(chartId)
                                .getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK)
                        );
                        showChart(chartId);
                    } else {
                        if (coned.utils.isMobile() || coned.utils.isTablet()) {
                            if (newPeriod) {
                                _dailyPeriodPosition++;
                                getDailyEventChart(_dailyPeriodPosition);
                                _chartDataPosition = 0;
                            } else {
                                var tabSelected = _isEvent
                                    ? CONSTANTS.CONED_CHART_EVENT_BUTTON
                                    : CONSTANTS.CONED_CHART_DATE_BUTTON;
                                $activeTab = $shrChartsComponent.getElementsByClassName(
                                    tabSelected
                                )[0];
                                generateChartId($activeTab);
                                generateChart(dailyEventMobileData[_dailyPeriodPosition]);
                            }
                        } else {
                            // desktop
                            _dailyPeriodPosition++;
                            getDailyEventChart(chartIndex + 1);
                        }
                    }
                }
            }
        };

        /**
         * Functionality for the forward chart arrow
         * @param {Object} event   Event name
         */
        var nextDateChart = function (event) {
            event.preventDefault();

            var $actualChart,
                chartDate,
                nextChartId,
                billRates = (_time === CONSTANTS.CONED_CHART_MONTHLY) ? undefined : _allGeneralPeriods[_dailyPeriodPosition].billRates;

            _actualDailyPos = (_time === CONSTANTS.CONED_CHART_MONTHLY) ? _actualDailyPos : _actualDailyPos - 1;
            coned.chart.showTableInfo($conedChartTable.parentElement, _chartId, false);

            dataLayer.push({
                event: 'SHR.demand.navigation.billing',
                label: 'Forward arrow'
            });

            if (_errorFlag) {
                var dailyEventMobileIds, chartExists;

                if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                    var dailyEventMobileData =
                        _chartId.indexOf(CONSTANTS.CONED_CHART_DAILY) > -1
                            ? _dailyMobileData
                            : _eventMobileData;
                    dailyEventMobileIds = _isEvent ? _eventMobileIds : _dailyMobileIds;
                    nextChartId = dailyEventMobileData[_actualPeriod - 1]
                        ? dailyEventMobileIds[_actualPeriod - 1][
                              dailyEventMobileData[_actualPeriod - 1].items.length - 1
                          ]
                        : dailyEventMobileIds[_actualPeriod - 1][0];
                    chartExists = document.getElementById(nextChartId);
                } else {
                    dailyEventMobileIds = _isEvent ? _eventIdsList : _dailyIdsList;
                    nextChartId = dailyEventMobileIds[_actualDailyPos];
                    chartExists = document.getElementById(nextChartId) ? true : false;
                }

                if (!chartExists) {
                    // chart not exists
                    query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);
                    _errorFlag = false;
                } else {
                    _chartId = nextChartId;

                    $actualChart = document.getElementById(_chartId);
                    chartDate = $actualChart.getAttribute(
                        CONSTANTS.CONED_CHART_DATE_TEXT,
                        _dateText
                    );
                    _dailyPeriodPosition = $actualChart.getAttribute(
                        CONSTANTS.CONED_CHART_DATA_PERIOD
                    );
                    _chartDataPosition = $actualChart.getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK);

                    $conedChartDateText.innerHTML = chartDate;

                    showChart(_chartId);

                    query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);

                    _errorFlag = false;

                    return;
                }
            }

            if (_monthlyErrorFlag && _time === CONSTANTS.CONED_CHART_MONTHLY) {
                $actualChart = document.getElementById(_chartId);
                chartDate = $actualChart.getAttribute(CONSTANTS.CONED_CHART_DATE_TEXT, _dateText);

                query.removeClass($conedChartContainer, CONSTANTS.CONED_HIDDEN_CLASS);
                query.addClass($conedChartErrorMessage, CONSTANTS.CONED_HIDDEN_CLASS);
                query.removeClass($conedChartNote, CONSTANTS.CONED_HIDDEN_CLASS);

                $conedChartDateText.innerHTML = chartDate;

                allowArrows(_chartId);
                _monthlyErrorFlag = false;

                return;
            }

            var nextChart, chart;
            if (_time === CONSTANTS.CONED_CHART_MONTHLY) {
                nextChart = _monthlyIdsList.indexOf(_actualChart) - 1;
                showChart(_monthlyIdsList[nextChart]);
            } else {
                var dailyEventChart, showChartId, dailyEventActualList, lastOption;
                _chartDataPosition--;

                if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                    var dailyEventIdsList = _isEvent ? _eventMobileIds : _dailyMobileIds;

                    nextChart = _chartDataPosition;
                    if (nextChart === -1) {
                        var dailyEventMobileDataList = _isEvent
                                ? _eventMobileData
                                : _dailyMobileData,
                            nextPeriod = dailyEventIdsList[_actualPeriod - 1],
                            weekPosition = dailyEventMobileDataList[_actualPeriod - 1]
                                ? Object.keys(dailyEventMobileDataList[_actualPeriod - 1].items)
                                      .length - 1
                                : nextPeriod
                                ? nextPeriod[Object.keys(nextPeriod).length - 1]
                                : undefined;
                        nextChart = nextPeriod ? nextPeriod[weekPosition] : undefined;
                        _actualPeriod--;
                    } else {
                        nextChart = dailyEventIdsList[_actualPeriod][nextChart];
                    }

                    _dailyPeriodPosition =
                        _chartDataPosition < 0 ? _dailyPeriodPosition - 1 : _dailyPeriodPosition;
                    _chartDataPosition =
                        _chartDataPosition > -1
                            ? _chartDataPosition
                            : dailyEventIdsList[_actualPeriod] &&
                              Object.keys(dailyEventIdsList[_actualPeriod]).length > 0
                            ? Object.keys(dailyEventIdsList[_actualPeriod]).length - 1
                            : 0;
                    chart = document.getElementById(nextChart);
                    if (_dailyMobileIds[_actualPeriod]) {
                        dailyEventChart = _dailyMobileIds[_actualPeriod]
                            ? document.getElementById(
                                  _dailyMobileIds[_actualPeriod][_chartDataPosition]
                              )
                            : null;
                    }
                    showChartId = nextChart;
                } else {
                    lastOption =
                        _dailyIdsList.indexOf(_actualChart) > -1
                            ? CONSTANTS.CONED_CHART_DAILY
                            : CONSTANTS.CONED_CHART_EVENT;
                    nextChart =
                        lastOption === CONSTANTS.CONED_CHART_EVENT
                            ? _eventIdsList.indexOf(_actualChart) - 1
                            : _dailyIdsList.indexOf(_actualChart) - 1;
                    dailyEventActualList = _isEvent ? _eventIdsList : _dailyIdsList;

                    chart = document.getElementById(dailyEventActualList[nextChart]);
                    dailyEventChart = document.getElementById(_dailyIdsList[_actualDailyPos]);
                    showChartId = dailyEventActualList[nextChart];
                    _dailyPeriodPosition =
                        _chartDataPosition < 0 ? _dailyPeriodPosition - 1 : _dailyPeriodPosition;
                }

                if (chart) {
                    _dailyPeriodPosition = parseInt(
                        document
                            .getElementById(showChartId)
                            .getAttribute(CONSTANTS.CONED_CHART_DATA_PERIOD)
                    );
                    _chartDataPosition = parseInt(
                        document
                            .getElementById(showChartId)
                            .getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK)
                    );
                    showChart(showChartId);
                } else {
                    var newPeriodChart = true;

                    if (coned.utils.isMobile() || coned.utils.isTablet()) {
                        var dailyMobileAllData = _isEvent ? _eventMobileData : _dailyMobileData,
                            periodExists = dailyMobileAllData[_dailyPeriodPosition] ? true : false,
                            tabSelected = _isEvent
                                ? CONSTANTS.CONED_CHART_EVENT_BUTTON
                                : CONSTANTS.CONED_CHART_DATE_BUTTON,
                            $activeTab = $shrChartsComponent.getElementsByClassName(tabSelected)[0];

                        if (periodExists) {
                            newPeriodChart = false;
                            generateChartId($activeTab);
                            generateChart(dailyMobileAllData[_dailyPeriodPosition]);
                        }
                    }

                    if (newPeriodChart) {
                        if (billRates && (coned.utils.isMobile() || coned.utils.isTablet())) {
                            _isLastPeriod =
                                dailyEventIdsList[_dailyPeriodPosition] === undefined ||
                                Object.keys(dailyEventIdsList[_dailyPeriodPosition]).length > 0
                                    ? true
                                    : false;
                        } else {
                            _chartDataPosition =
                                _chartDataPosition < 0
                                    ? dailyEventChart.getAttribute(CONSTANTS.CONED_CHART_DATA_WEEK)
                                    : _chartDataPosition;
                        }
                        getDailyEventChart(_dailyPeriodPosition);

                        _dateText = dailyEventChart
                            ? dailyEventChart.getAttribute(CONSTANTS.CONED_CHART_DATE_TEXT)
                            : _dateText;
                    }
                }
            }
        };

        /**
         * Change Note text according with the rate chart
         */
        var addNoteText = function () {
            if (_rateType === '5') {
                if (_isEvent) {
                    query.addClass($conedChartDailyNoteText, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartEventNoteText, CONSTANTS.CONED_HIDDEN_CLASS);
                } else {
                    query.addClass($conedChartEventNoteText, CONSTANTS.CONED_HIDDEN_CLASS);
                    query.removeClass($conedChartDailyNoteText, CONSTANTS.CONED_HIDDEN_CLASS);
                }
            }
        };

        /**
         * Read the legend attributes json file for all charts
         */
        var getLegendDataAttributes = function () {
            var dataAttributesJson = coned.utils.isPatternLab()
                ? coned.plConstants.GET_CHART_LEGEND_DATA
                : window.location.origin + CONSTANTS.CONED_DATA_ATTRIBUTES_JSON;
            query.getData(
                dataAttributesJson,
                function (data) {
                    _legendDataAttributes = data;
                },
                function () {}
            );
        };

        /**
         * Updated aria-label of arrow buttons
         * @param {*} buttonSelected
         */
        var updatedCopyArrows = function (buttonSelected){

            var typeView = (coned.utils.isMobile() == true) ? "mobile" : "desktop",
                stringAttributePrev = "data-"+typeView+"-arrow-prev",
                stringAttributeNext = "data-"+typeView+"-arrow-next",
                dataAttributeNext = buttonSelected.getAttribute(stringAttributeNext),
                dataAttributePrev = buttonSelected.getAttribute(stringAttributePrev);

            $conedChartLastDate.setAttribute("aria-label",dataAttributePrev);
            $conedChartNextDate.setAttribute("aria-label",dataAttributeNext);
        }

        /**
         * Set aria-label after reload
         */
        var setInitializeAttributes = function() {
            if ($conedChartMonthButton && query.hasClass($conedChartMonthButton, _disabledButton)) {
                updatedCopyArrows($conedChartMonthButton);
            } else if ($conedChartDailyButton && query.hasClass($conedChartDailyButton, _disabledButton)) {
                updatedCopyArrows($conedChartDailyButton);
            } else if ($conedChartEventButton && query.hasClass($conedChartEventButton, _disabledButton)) {
                updatedCopyArrows($conedChartEventButton);
            }
        };

        /**
         * Initialize all the chart events
         */
        var initializeEvents = function () {
            if ($conedChartMonthButton) {
                coned.utils.addGeneralListeners($conedChartDemandTab, getMonthlyData);
            } else {
                coned.utils.addGeneralListeners($conedChartDemandTab, createDailyChart);
            }
            coned.utils.addGeneralListeners($conedChartDailyButton, createDailyChart);
            coned.utils.addGeneralListeners($conedChartEventButton, createEventChart);
            coned.utils.addGeneralListeners($conedChartMonthButton, showMonthlyChart);
            coned.utils.addGeneralListeners($conedChartLastDate, lastDateChart);
            coned.utils.addGeneralListeners($conedChartNextDate, nextDateChart);
            setInitializeAttributes();
            window.addEventListener('resize', function() {
                var demandOption = document.getElementsByClassName(CONSTANTS.CONED_CHART_DEMAND_BUTTON)[0],
                    windowWidth = window.innerWidth,
                    actualSize =
                        windowWidth > coned.constants.TABLET_MAX_SIZE === true
                            ? 'desktop'
                            : 'mobile';

                if (
                    _chartId &&
                    demandOption.classList.contains(CONSTANTS.CONED_CHART_TAB_ACTIVE_CLASS)
                ) {
                    if (_device !== actualSize) {
                        // if the window change from desktop to mobile/tablet or vice versa
                        location.reload();
                    } else {
                        var regExp =
                                _time === CONSTANTS.CONED_CHART_DAILY ||
                                _time === CONSTANTS.CONED_CHART_EVENT
                                    ? /-([A-Z])*\w+/g
                                    : /-([0-9]{4})/g,
                            maxValueLength = _yAxis[_yAxis.length - 1].length,
                            minValueLength = _yAxis[0].length,
                            amountDigits =
                                maxValueLength > minValueLength ? maxValueLength : minValueLength;

                        coned.chart.resizeWindow(
                            _chartId,
                            _chart,
                            regExp,
                            _isFixedSubscription,
                            amountDigits
                        );
                    }
                }
            });
            coned.utils.addGeneralListeners($conedChartTableButton, function(event) {
                event.preventDefault();
                var showTable = $conedChartTable.hasAttribute('hidden') ? 'true' : 'false';

                coned.chart.showTableInfo(this.parentElement, _chartId, showTable);
            });
        };

        /**
         * Initialize all the chart elements
         */
        var initializeData = function () {
            $conedEnergyContent = document.getElementsByClassName(CONSTANTS.CONED_ENERGY_USAGE)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.CONED_CHART_FORM_LOADING)[0];
            $conedChartModule = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_MODULE)[0];
            $conedChartContainer = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_CONTAINER)[0];
            $conedChartNote = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_NOTE)[0];
            $conedChartErrorMessage = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_ERROR_MESSAGE)[0];
            $conedChartNoDataMessage = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_NODATA_MESSAGE)[0];
            $conedChartDemandTab = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_DEMAND_BUTTON)[0];
            $conedChartDemandMobileTab = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_MOBILE_DEMAND)[0];
            $conedChartDailyButton = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_DATE_BUTTON)[0];
            $conedChartMonthButton = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_MONTH_BUTTON)[0];
            $conedChartEventButton = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_EVENT_BUTTON)[0];
            $conedChartDateText = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_DATE)[0];
            $conedChartLastDate = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_LEFT_ARROW)[0];
            $conedChartNextDate = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_RIGHT_ARROW)[0];
            $conedChartDailyNoteText = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_DAILY_NOTE)[0];
            $conedChartEventNoteText = $shrChartsComponent.getElementsByClassName(CONSTANTS.CONED_CHART_EVENT_NOTE)[0];
            $conedChartTableButton = $shrChartsComponent.getElementsByClassName('js-coned-chart-table-button')[0];
            $conedChartTable = $shrChartsComponent.getElementsByClassName('js-chart-table-content')[0];

            // This array is hardcoded as it is used for calling the months period as needed
            _allMonthsPeriodServiceCall = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            
            // This array is content editable, and is used for displaying text on the widget,
            // and translating it to spanish via sitecore
            _allMonths = $conedChartTable.dataset.shortenedMonthsList.split(',');
            
            _allCompleteMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

            _device = ((window.innerWidth > coned.constants.TABLET_MAX_SIZE) === true) ? 'desktop' : 'mobile';

            _monthlyIdsList = [],
            _dailyIdsList = [],
            _eventIdsList = [],
            _monthlyPeriodsList = {},
            _allGeneralPeriods = [],
            _IPPPeriods = 0,
            _dataMonthlyNames = {},
            _dataDailyNames = {},
            _dataEventNames = {},
            _legendTooltip = {},
            _generalDailyData = {},
            _generalEventData = {},
            _prevChart = false,
            _indexBillingPeriod = 0,
            _nextChart = false,
            _dailyMobileData = {},
            _eventMobileData = {},
            _monthlyMobileData = [],
            _monthlyMobilePeriod = 0,
            _monthlyMobilePosition = 0,
            _dailyMobileIds = {},
            _eventMobileIds = {},
            _errorFlag = false,   // flag to determine if an error happens on Daily charts
            _monthlyErrorFlag = false,
            _lastPeriod = false,
            _hasTotalAverageValue = false,
            _totalAverageValue = {},
            _allTotalAverageValue = {},
            _isLastPeriod = false,
            _isEvent = false,
            _disabledButton = (coned.utils.isOru()) ? CONSTANTS.CONED_CHART_ORU_BUTTON_DISABLED : CONSTANTS.CONED_CHART_BUTTON_DISABLED;

            _monthlyColorsThermostat = [CONSTANTS.CONED_CHART_DAILY_COLOR],
            _dailyColorsThermostat = [CONSTANTS.CONED_CHART_DAILY_COLOR],
            _dailyColorsThermostatB = [CONSTANTS.CONED_CHART_DAILY_COLOR, CONSTANTS.CONED_CHART_EVENT_COLOR],
            _eventColorsThermostat = [CONSTANTS.CONED_CHART_EVENT_COLOR],
            _eventColorsThermostatB = [CONSTANTS.CONED_CHART_EVENT_COLOR, CONSTANTS.CONED_CHART_EVENT_COLOR];

            _isFirstbillingPeriodLoad = true;
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            getLegendDataAttributes();
            getBillingPeriods();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ShrChartsComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ShrChartsComponent;
})();
