// ==================== START SERVICE COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.StartService = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM: 'coned-form',
        PAGE_LOADING: 'js-page-loading',
        PAGE_LOADING_HIDDEN: 'page-loading--hidden',
        START_SERVICE: 'js-start-service',
        START_FORM: '.js-start-service-form',
        START_FORM_CLASS: 'js-start-service-form',
        ADDRESS_LOOKUP_FORM: 'js-address-lookup',
        AUTOCOMPLETE_ZIPCODE: 'js-autocomplete-zipcode',
        AUTOCOMPLETE_ADDRESS: 'js-autocomplete-street-address',
        AUTOCOMPLETE_APARMENT_UNIT: 'js-autocomplete-apartment-unit',
        AUTOCOMPLETE_SELECTED_ADDRESS_DETAIL: 'js-address-selected-detail',
        NEXT_BUTTON: 'js-next-button',
        NEXT_MAIN_BUTTON: 'js-next-main-button',
        FINISH_BUTTON: 'js-finish-button',
        LOOKUP_BUTTON: 'js-lookup-button',
        DATE_PICKER_INPUT: 'js-start-date',
        DATE_PICKER_INPUT_CLASS: '.js-start-date',
        START_DATE_RECEPTOR: 'js-start-date-receptor',
        ADDRESS_RECEPTOR: 'js-address-receptor',
        NAME: 'js-first-name',
        LAST_NAME: 'js-last-name',
        NAME_COMMERCIAL: 'js-first-name-commercial',
        LAST_NAME_COMMERCIAL: 'js-last-name-commercial',
        NAME_RECEPTOR: 'js-first-name-receptor',
        LAST_NAME_RECEPTOR: 'js-last-name-receptor',
        DATE_OF_BIRTH: 'js-birth-date',
        DATE_OF_BIRTH_RECEPTOR: 'js-date-birth-receptor',
        PROFILE_SUBMIT_BUTTON: 'js-transactional-extra-help',
        IDENTIFICATION: 'js-identification-active',
        IDENTIFICATION_RECEPTOR: 'js-identification-receptor',
        IDENTIFICATION_TITLE_RECEPTOR: 'js-identification-title-receptor',
        IDENTIFICATION_SELECT: 'js-identification-type',
        NO_IDENTIFICATION: 'js-no-identification',
        IDENTITY_BUTTON: 'js-identity-button',
        PHONE_TITLE_RECEPTOR: 'js-phone-title-receptor',
        EMAIL: 'js-start-email',
        EMAIL_RECEPTOR: 'js-email-receptor',
        PHONE: 'js-start-phone',
        PHONE_RECEPTOR: 'js-phone-receptor',
        PHONE_TYPE_SELECT: 'js-phone-type-select',
        LIFE_SUPPORT_SELECT: 'js-start-service-life-support',
        DISABLILTY_SELECT: 'js-start-service-disability',
        SUCCESS_EMAIL_RECEPTOR: 'js-sucess-email-receptor',
        SUCCESS_DATE_RECEPTOR: 'js-success-date-receptor',
        ANIMATION_SUBMIT_MODULE: 'js-submit-progress-animation',
        EDIT_ACCOUNT_INFORMATION: 'js-edit-account-information',
        ACCOUNT_PROFILE_SELECTOR: 'js-account-profile',
        FORM_IGNORE_VALIDATION: '.js-validate-ignore',
        STEPS_LIST: 'js-steps-list',
        STEP_FINISH: 'js-form-finish',
        STEP_FINISH_ACTIVE: 'form-progress-bar__finish--active',
        STEP_PROGRESS_BAR: 'js-form-progress-bar',
        FINISH_BAR_CLASS: 'form-progress-bar--finish',
        FINISH_STEP_SELECTOR: 'js-finish-message',
        MAILING_ZIPCODE: 'js-mailing-zipcode',
        MAILING_CANADA_ZIPCODE: 'js-mailing-canada-zipcode',
        MAILING_STATE: 'js-mailing-state',
        MAILING_STATE_CANADA: 'js-mailing-state-canada',
        MAILING_TOWN: 'js-mailing-town',
        MAILING_STREET: 'js-mailing-street',
        MAILING_NAME: 'js-mailing-name',
        MAILING_OPTIONS_CONTAINER: 'js-mailing-options',
        MAILING_CHECKBOX: 'js-start-service-mailing',
        MAILING_INPUT_OPTIONS: 'js-mailing-input',
        MAILING_OPTIONS_CLASS: 'transactional__mailing-options',
        MAILING_RECEPTOR: 'js-mailing-receptor',
        VALIDATE_IGNORE: 'js-validate-ignore',
        INPUT_ERROR_MESSAGE: 'coned-input-message--error',
        COUNTRY_DROPDOWN: 'js-mailing-country',
        OTHER_COUNTRY: 'js-other-country',
        INPUT_VALIDATE: 'js-item-validate',
        ACTIVE_DROPDOWN: 'coned-select--active',
        SOCIAL_SECURITY_NUMBER: 'Social Security Number',
        DROPDOWN_LABEL: 'js-coned-select-label',
        DROPDOWN_ACTIVE_CLASS: 'js-coned-select-active',
        USA_ZIPCODE_SELECTOR: 'js-usa-zipcode-selector',
        TAX_ID_NUMBER: 'Tax ID Number',
        CANADA_ZIPCODE_SELECTOR: 'js-canada-zipcode-selector',
        DISABILITY_AGE_62_ALONE_RECEPTOR: 'js-age62OverAlone-receptor',
        DISABILITY_MEMBERS_62_OVER_18_UNDER_RECEPTOR: 'js-members62Over18Under-receptor',
        DISABILITY_DISABLED_BLIND_RECEPTOR: 'js-disabledOrBlind-receptor',
        NO_DEPOSIT_ALERT: 'js-no-deposit-alert',
        DISABILITY_RADIOS: 'js-disability-radio',
        CITY_SELECTOR: 'js-city-selector',
        ADDRESS_STEP_SELECTOR: 'js-address-step',
        VERIFY_DATE_STEP_SELECTOR: 'js-verify-date-step',
        SERVICE_IDENTITY_STEP_SELECTOR: 'js-service-identity-step',
        ACCOUNT_PROFILE_STEP_SELECTOR: 'js-account-profile-step',
        DIRECT_PAYMENT_STEP_SELECTOR: 'js-step-direct-pay',
        DEPOSIT_PAYMENT_STEP_SELECTOR: 'js-step-no-id-pay',
        LIFE_SUPPORT_STEP_SELECTOR: 'js-step-life-support',
        LIFE_SUPPORT_RADIOS_SELECTOR: 'js-radio-lse-needed',
        LIFE_SUPPORT_RADIO_YES_SELECTOR: 'js-radio-lse-needed-yes',
        LIFE_SUPPORT_RADIO_ERROR_SELECTOR: 'js-lse-error',
        DISABILITY_STEP_SELECTOR: 'js-step-disability',
        REVIEW_STEP_SELECTOR: 'js-step-review',
        ACCOUNT_NUMBER_ERROR_SELECTOR: 'js-account-number-service-error',
        START_DATE_ERROR_SELECTOR: 'js-start-date-service-error',
        CHECK_AVAILABILITY_ERROR: 'js-check-availability-service-error',
        START_SERVICE_ERROR_SELECTOR: 'js-start-service-error',
        LIVE_CHAT_AVAILABLE_SELECTOR: 'js-live-chat-available',
        LIVE_CHAT_ERROR_MESSAGE: 'js-live-chat-error-message',
        LIVE_CHAT_AVAILABLE_MESSAGE: 'js-live-chat-available-message',
        LIVE_CHAT_BUTTON: 'js-live-chat-cta',
        INIT_CHAT:  'init',
        STOP_CHAT: 'stop',
        LIVE_CHAT_ON_PARAM: 'liveChatOn',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        FORM_COMPANY_CODE_NAME: 'companyCode',
        FORM_ADDRESS_SCID_NAME: 'AddressScId',
        FORM_START_DATE_SCID_NAME: 'DateScId',
        FORM_ROP_INDICATOR: 'residency',
        FORM_ACCOUNT_PROFILE_SCID_NAME: 'AccountProfileScId',
        FORM_DIRECT_PAYMENT_SCID_NAME: 'DirectPaymentScId',
        FORM_LIFE_SUPPORT_SCID_NAME: 'LifeSupportScId',
        FORM_DISABILITY_SCID_NAME: 'DisabilityScId',
        FORM_ZIPCODE_NAME: 'startServiceZipCode',
        FORM_STREET_NAME_NAME: 'streetName',
        FORM_STREET_NUMBER_NAME: 'streetNumber',
        FORM_RESIDENCE_UNIT_NAME: 'residenceUnit',
        FORM_START_DATE_NAME: 'startServiceDate',
        FORM_FIRST_NAME_NAME: 'firstName',
        FORM_LAST_NAME_NAME: 'lastName',
        FORM_FIRST_NAME_COMMERCIAL: 'firstNameCommercial',
        FORM_LAST_NAME_COMMERCIAL: 'lastNameCommercial',
        FORM_DOB_NAME: 'identityDateOfBirth',
        FORM_SSN_NAME: 'socialNumber',
        FORM_DRIVER_LICENSE_NAME: 'driverLicense',
        FORM_DRIVER_LICENSE_ISSUED_STATE_NAME: 'driverLicenseIssuedState',
        FORM_ALIEN_ID_NAME: 'alienId',
        FORM_PUBLIC_ASSISTANCE_NAME: 'publicAsistanceId',
        FORM_PASSPORT_NAME: 'startPassport',
        FORM_EMAIL_NAME: 'emailAdress',
        FORM_PHONE_TYPE_NAME: 'phoneType',
        FORM_PHONE_NUMBER_NAME: 'phoneNumber',
        FORM_MAILING_ADDRESS_NAME: 'mailingAddress',
        FORM_MAILING_NAME_NAME: 'mailingName',
        FORM_SECURTY_WORD: 'js-start-security-word',
        FORM_MAILING_STREET_NAME: 'mailingStreet',
        FORM_MAILING_ADDRESS_ONE_NAME: 'mailingAddressOne',
        FORM_MAILING_ADDRESS_TWO_NAME: 'mailingAddressTwo',
        FORM_MAILING_COUNTRY_NAME: 'mailingCountry',
        FORM_MAILING_OTHER_COUNTRY_NAME_NAME: 'otherMailingCountryName',
        FORM_MAILING_CITY_NAME: 'mailingCity',
        FORM_MAILING_TOWN_NAME: 'mailingTown',
        FORM_MAILING_STATE_NAME_USA: 'mailingState',
        FORM_MAILING_STATE_NAME_CANADA: 'mailingStateCanada',
        FORM_MAILING_ZIPCODE_NAME: 'mailingZipCode',
        FORM_MAILING_CANADA_ZIPCODE_NAME: 'mailingCanadaZipCode',
        FORM_SECURITY_WORD_NAME: 'securityWord',
        FORM_EBILL_ENROLL_NAME: 'ebillEnroll',
        FORM_DIRECT_PAYMENT_ENROLL_NAME: 'directPaymentEnroll',
        FORM_LIFE_SUPPORT_NAME: 'lifeSupportEnroll',
        FORM_DISABILITY_NAME: 'disabilityEnroll',
        FORM_DP_ACCOUNT_TYPE_NAME: 'accountType',
        FORM_DP_ROUTING_NUMBER_NAME: 'routingAbaNumber',
        FORM_DP_ACCOUNT_NUMBER_NAME: 'accountNumber',
        FORM_NID_PAYMENT_METHOD_NAME: 'paymentMethod',
        FORM_NID_ROUTING_NUMBER_NAME: 'depositRoutingAbaNumber',
        FORM_NID_ACCOUNT_NUMBER_NAME: 'depositAccountNumber',
        FORM_LS_TANK_RESPIRATOR_ID: 'tankRespirator',
        FORM_LS_CUIRASS_RESPIRATOR_ID: 'cuirassRespirator',
        FORM_LS_ROCKING_RESPIRATOR_ID: 'rockingRespirator',
        FORM_LS_ELECTRICAL_RESPIRATOR_ID: 'electricalRespirator',
        FORM_LS_APNEA_MONITOR_ID: 'apneaMonitor',
        FORM_LS_HEMODIALYSIS_ID: 'hemodialysis',
        FORM_LS_OTHER_NAME: 'lifeSupportOther',
        FORM_DISABILITY_AGE_62_ID: 'age62',
        FORM_DISABILITY_BLIND_ID: 'blind',
        FORM_DISABILITY_PERMANENTLY_DISABLED_ID: 'permanentlyDisabled',
        FORM_DISABILITY_UNDER_AGE_18_ID: 'underAge18',
        FORM_DISABILITY_OTHER_NOTIFIED_NAME: 'otherNotified',
        FORM_DISABILITY_FIRST_NAME_NAME: 'disabilityFirstName',
        FORM_DISABILITY_LAST_NAME_NAME: 'disabilityLastName',
        FORM_DISABILITY_STREET_ADDRESS_NAME: 'disabilityStreetAddress',
        FORM_DISABILITY_CITY_NAME: 'disabilityCity',
        FORM_DISABILITY_STATE_NAME: 'disabilityState',
        FORM_DISABILITY_ZIPCODE_NAME: 'disabilityZipCode',
        FORM_DISABILITY_DAY_TIME_NUMBER_NAME: 'dayTimeNumber',
        FORM_DISABILITY_NIGHT_TIME_NUMBER_NAME: 'nightTimeNumber',
        FORM_ORU_DISABILITY_AGE_62_ALONE_NAME: 'age62OverAlone',
        FORM_ORU_DISABILITY_MEMBERS_62_OVER_18_UNDER: 'members62Over18Under',
        FORM_ORU_DISABILITY_DISABLED_BLIND: 'disabledOrBlind',
        HIDDEN_CLASS: 'hidden',
        HIDDEN_FORM_LOADING: 'form-loading--hidden',
        FORM_LOADING: 'js-form-loading',
        FORM_STEP: 'js-form-step',
        FORM_CURRENT_STEP: 'js-current-form-step',
        NAV_CURRENT_STEP: 'js-current-step',
        FORM_STEP_ACTIVE: 'form-progress-bar__item--active',
        IS_FORM_BLOCKED: 'js-form-blocked',
        CONED_RADIO_SELECTOR: 'js-coned-radio',
        CONED_RADIO_CLASS: 'coned-radio',
        CONED_RADIO_LABEL_CLASS: 'coned-radio__label-name',
        INPUT_FULL: 'transactional__half--full',
        INPUT_EVENT: 'input',
        CHANGE_EVENT: 'change',
        DISABLED_ATTRIBUTE: 'disabled',
        COMMERCIAL_ACCOUNT_SELECTOR: 'js-commercial-account',
        FORM_BUSINESS_NAME: 'businessName',
        FORM_BOPA_AWKNOLEDGEMENT_NAME: 'bopaAcknowledgement',
        BUSINESS_NAME_RECEPTOR: 'js-business-name-receptor',
        BUSINESS_NAME: 'js-business-name',
        FULL_NAME: 'js-full-name',
        BOPA_CHECKLIST_INFO: 'js-check-list-bopa-info',
        COMMERCIAL_DISABLED: 'js-commercial-disabled',
        COMMERCIAL_NO_REQUIRED: 'js-commercial-no-required',
        COMMERCIAL_NO_VALIDATE: 'js-commercial-no-validate',
        COMMERCIAL_VALIDATE: 'js-commercial-validate',
        COMMERCIAL_HIDDEN: 'js-commercial-hidden',
        COMMERCIAL_VISIBLE: 'js-commercial-visible',
        COMMERCIAL_VISIBLE_HIDDEN: 'js-commercial-visible-hidden',
        COMMERCIAL_HIDDEN_VISIBLE: 'js-commercial-hidden-visible',
        FORM_ITEM_IGNORE: 'js-validate-ignore',
        FORM_ITEM_VALIDATE: 'js-item-validate',
        TAX_INPUT_VALUE: 'js-ein-tax-input',
        TAX_INPUT_NAME: 'taxId',
        INPUT_ACTIVE_VALUE: 'js-identification-active',
        AUTHENTICATION_SELECTOR: 'js-authentication',
        NO_INPUT: 'js-no-input',
        IS_COMMERCIAL: 'js-commercial-authentication',
        ACCOUNT_TYPE_RESIDENTIAL: 'R',
        ACCOUNT_TYPE_COMMERCIAL: 'C',
        SKIP_STEP_CLASS: 'js-skip-step',
        HELP_INPUT_SELECTOR: 'js-checkbox-selector',
        HELP_CHECKBOX_SELECTOR: 'js-extra-help-selector',
        SELECT_AUTH: 'js-select-auth',
        SELECT_AUTH_COMMERCIAL: 'js-select-auth-commercial',
        COMMERCIAL_INITIAL_AMMOUNT: 'js-commercial-initial-ammount',
        COMMERCIAL_INITIAL_TIME: 'js-commercial-initial-time',
        BOPA_RADIO_NOT_SURE: 'NOT SURE',
        FILLED_CLASS: 'coned-input--filled',
        IDENTIFICATION_STRING: 'Identification',
        RADIO_YES_OPTION: 'Yes',
        RADIO_NO_OPTION: 'No',
        DIRECT_PAYMENT: 'js-direct-payment-enroll',
        DEPOSIT_HIDDEN: 'js-has-deposit',
        DEPOSIT_BANK_HIDDEN: 'js-has-deposit-bank',
        DEPOSIT_MAIL_HIDDEN: 'js-has-deposit-mail',
        DEPOSIT_AMOUNT: 'js-deposit-amount',
        CAPTCHA_ELEMENT: 'js-recaptcha',
        DEPOSIT_PAYMENT_RADIOS: 'js-payment-method-radio',
        DEPOSIT_PAYMENT_CONTINUE_BUTTON: 'js-payment-method-button',
        DEPOSIT_ACCOUNT_TYPE_LABEL: 'js-deposit-account-type-label',
        DEPOSIT_PAYMENT_METHOD_CONTENT: 'js-payment-method-content',
        DEPOSIT_ROUTING_ABA_SELECTOR: 'js-deposit-routing-aba-number',
        DEPOSIT_ACCOUNT_NUMBER_SELECTOR: 'js-deposit-account-number',
        DEPOSIT_MAIL_BILL_CONTENT: 'js-bill-mail-content',
        DEPOSIT_BANK_CONTENT: 'js-bank-account-content',
        DEPOSIT_BANK_CHECKING: 'checking',
        DEPOSIT_BANK_SAVINGS: 'savings',
        DEPOSIT_RECEIVE_IN_MAIL: 'Bill',
        DEPOSIT_MAIL_BILL: 'bill',
        DEPOSIT_TOTAL_SELECTOR: 'js-total-selector',
        DEPOSIT_PAYMENT_SUBMIT: 'js-deposit-payment-submit',
        DPP_BANK_CHECKING: 'checkingAccount',
        DPP_BANK_SAVINGS: 'savingsAccount',
        CANADA_SELECT_VALUE: 'canada',
        USA_SELECT_VALUE: 'usa',
        CANADA_DROPDOWN_CONTAINER: 'js-canada-state-selector',
        USA_DROPDOWN_CONTAINER: 'js-usa-state-selector',
        ITEM_CHECK_FORM: 'js-item-check-form',
        ABLE_TO_BACKDATE_SELECTOR: 'js-inactive-can-backdate-description',
        MUST_BACKDATE_SELECTOR: 'js-inactive-must-backdate-description',
        RED_PARAGRAPH_MODIFIER: 'transactional__paragraph--red',
        BIRTH_DATE: '.js-birth-date',
        ABLE_TO_BACKDATE: 'AB',
        MUST_BACKDATE: 'MB',
        SSN_LABEL: 'SSN',
        VALID: 'valid',
        COMMERCIAL_TAX_ID: 'CommercialTaxId',
        CHANGE_ADDRESS_EVENT: 'change-address',
        ADDRESS_AVERAGE_PARAGRAPH: 'js-paragraph-address-average',
        AVERAGE_ELEMENT_SELECTOR: 'js-paragraph-average',
        RADIO_AGE_62_OVER_ALONE: 'js-age-62-over-alone',
        START_SERVICE_FINISH_MAIN: 'js-start-service-finish-main',
        TABINDEX: 'tabindex',
        DROPDOWN_DICTIONARY: {
            'js-social-number': 'SSN',
            'js-drivers-license': 'DriverLicense',
            'js-alien-id': 'AlienID',
            'js-public-assistance-id': 'PublicAssistanceID',
            'js-passport': 'Passport',
            'js-no-identification': 'NoID'
        },

        // Tagging
        STEP_SUCCESS_EVENT: 'step-success',
        STEP_ERROR_EVENT: 'step-error',
        STEP_3D_SUCCESS_TAG: 'start.step3d.success',
        STEP_3D_ERROR_TAG: 'start.step3d.error',
        ACCOUNT_NUMBER_TAG: 'enter.bank.acct',
        ROUTING_ABA_TAG: 'enter.routing',
        DEPOSIT_CHECK_TAG: 'Check',
        NO_ERROR_CODES: 'start.no.error.code',
        TAGGING_PHONE_TYPE: 'enter.phone.type',
        TAGGING_INACTIVE: 'Inactive',
        TAGGING_ACTIVE: 'Active',
        TAGGING_CONED_FORM_SUCCESS: 'coned.form.success',
        TAGGING_CCG_FORM: 'startserv.submit.ccg-form',
        HAS_ELECTRICITY_COLD_AMI: 'js-has-electricity-cold-ami',
        DEFAULT_SUCCESS_MESSAGE: 'js-default-success-message',
        START_DATE_PLACEHOLDER: '@DATE',
        ZERO_AMOUNT: '0',

        // A11Y
        ARIA_DISABLED: 'aria-disabled'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var StartService = function ($startService) {
        /**
         * PRIVATE METHODS
         */
        var $startForm,
            $startServiceForm,
            $startServiceFinishMain,        
            $addressLookupForm,
            $autocompleteZipcodeInput,
            $autocompleteAddressInput,
            $autocompleteAparmentUnitInput,
            $autocompleteAddressSelectedDetail,
            $datePickerInput,
            $startDateReceptor,
            $startAddressReceptor,
            $name,
            $nameReceptor,
            $lastName,
            $lastNameReceptors,
            $birthDate,
            $birthDateReceptor,
            $identificationReceptors,
            $identificationTitleReceptors,
            $email,
            $emailReceptor,
            $phone,
            $phoneReceptor,
            $successEmailReceptor,
            $successDateReceptor,
            $nextButtons,
            $stepsList,
            $finishStep,
            $progressBar,
            $finishStepSelector,
            $accountProfile,
            $mailingInputState,
            $mailingInputStateCanada,
            $mailingInputTown,
            $mailingInputStreet,
            $mailingInputZipCode,
            $mailingInputCanadaZipCode,
            $mailingCheckbox,
            $mailingOptionsContainer,
            $mailingOptions,
            $mailingReceptor,
            $editAccountInformation,
            $countryDropdown,
            $otherCountryName,
            $USAZipcodeSelector,
            $canadaZipcodeSelector,
            $citySelector,
            $disabilityAge62AloneReceptor,
            $disabilityMembers62Over18UnderReceptor,
            $disabilityDisabledBlindReceptor,
            $disabilityRadios,
            $noDepositAlert,
            $addressStepSelector,
            $verifyDateStepSelector,
            $serviceIdentityStepSelector,
            $accountProfileStepSelector,
            $directPaymentStepSelector,
            $depositPaymentStepSelector,
            $lifeSupportStepSelector,
            $lifeSupportRadiosSelector,
            $lifeSupportRadioYesSelector,
            $lifeSupportRadioErrorSelector,
            $reviewStepSelector,
            $accountNumberServiceError,
            $startDateServiceError,
            $startServiceError,
            $checkAvailabilityError,
            $liveChatErrorMessage,
            $liveChatAvailableMessage,
            $liveChatAvailable,
            $formLoading,
            $submitAnimationButton,
            $lookupButton,
            $phoneTitleReceptor,
            $phoneTypeSelect,
            $profileSubmitButtons,
            $business,
            $businessReceptor,
            $bopaChecklistInfo,
            $taxInput,
            $authenticationSelector,
            $nameCommercial,
            $lastNameCommercial,
            $formSteps,
            $nextMainButtons,
            $helpCheckboxSelector,
            $selectAuth,
            $selectAuthCommercial,
            $commercialInitialAmount,
            $commercialInitialTime,
            $identificationSelect,
            $depositRouting,
            $depositAccount,
            $depositPaymentContinueButton,
            $depositPaymentRadios,
            $confirmIdentityButton,
            $depositPaymentSubmit,
            $USADropdownContainer,
            $CanadaDropdownContainer,
            $captchaElement,
            $itemCheckForm,
            $ableToBackdate,
            $mustBackdate,
            $addressAverageParagraph,
            $paragraphAverage,
            $mailingAddressTwoInput,
            $hasElectricityColdAmi,
            $electricityColdAmiSuccessMessage,
            $radioAge62OverAlone,
            $pageLoading,
            recaptcha,
            iframe,
            containerCaptcha,
            observerCaptcha,
            _accountMaidCheckRecaptcha,
            _accountNumberCheckRecaptcha,
            _activeSteps,
            _maid,
            _data,
            _isComercial,
            _isVerifyActive,
            _previousIsComercial,
            _mailingCheckboxOff,
            _lookupRedirect,
            _companyCode,
            _unit,
            _premiseNumber,
            _hasCaptcha,
            _accountType,
            _averageAmount,
            _isInactiveBackdate,
            _isEletricityColdAmi,
            _depositAmount,
            _edb,
            _edbDepositWaived,
            _unitNumber,
            _addressSelected,
            _unitList,
            _addressStepCompleted,
            _liveChatOn;

        var formStatus = function (isBlocked) {
            var $currentStep = document.getElementsByClassName(CONSTANTS.NAV_CURRENT_STEP)[0];

            if (isBlocked) {
                $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
            } else {
                $currentStep.classList.remove(CONSTANTS.IS_FORM_BLOCKED);
            }
        };

        var createObserverRecaptcha = function () {
            if (_hasCaptcha) {
                iframe = document.querySelector(
                    'iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]'
                );
                containerCaptcha = iframe.parentNode.parentNode;

                // The MutationObserver interface provides the ability to watch for changes being made to the DOM tree.
                observerCaptcha = new MutationObserver(function () {
                    // if the recaptcha box is closed by clicking outside it, add to formLoading animation to hide.
                    if (containerCaptcha && containerCaptcha.style.visibility === 'hidden') {
                        if (!$formLoading.classList.contains(CONSTANTS.HIDDEN_FORM_LOADING)) {
                            $formLoading.classList.add(CONSTANTS.HIDDEN_FORM_LOADING);
                        }
                    }
                });

                observerCaptcha.observe(containerCaptcha, {
                    attributes: true,
                    attributeFilter: ['style']
                });
            }
        };

        var checkAvailability = function (accountMaid, accountNumber) {
            _unitNumber = $autocompleteAparmentUnitInput ? $autocompleteAparmentUnitInput.value : undefined;
            _unitList = $autocompleteAparmentUnitInput ? $autocompleteAparmentUnitInput.dataset.unitsList : undefined;
            _addressSelected = _unitList ? JSON.parse(_unitList)[_unitNumber].ServiceAddress : JSON.parse($autocompleteAddressSelectedDetail.dataset.serviceAddress);

            var serviceUrl = $addressStepSelector.dataset.checkAvailabilityService,
                params,
                cityName = _addressSelected.City,
                stateName = _addressSelected.State,
                postalCodeNumber = _addressSelected.PostalCode,
                premiseNumber = _addressSelected.PremiseNumber,
                streetNumber = _addressSelected.StreetAddress;

            if (_accountMaidCheckRecaptcha) {
                accountMaid = _accountMaidCheckRecaptcha;
                _accountMaidCheckRecaptcha = '';
            }

            if (_accountNumberCheckRecaptcha) {
                accountNumber = _accountNumberCheckRecaptcha;
                _accountNumberCheckRecaptcha = '';
            }

            // Hide animation of formLoading.
            if (!$formLoading.classList.contains(CONSTANTS.HIDDEN_FORM_LOADING)) {
                $formLoading.classList.add(CONSTANTS.HIDDEN_FORM_LOADING);
            }

            $lookupButton.disabled = true;
            hideAddressLookUpErrors();

            if (!(accountMaid && typeof accountMaid === 'string')) {
                //Get Selected Maid
                _maid = $autocompleteAddressSelectedDetail.dataset.maid;
            } 

            // Disable all steps on residence change
            _.each($formSteps, function ($step) {
                if (
                    !query.hasClass($step, CONSTANTS.NAV_CURRENT_STEP) &&
                    query.hasClass($step, CONSTANTS.FORM_STEP_ACTIVE)
                ) {
                    $step.classList.remove(CONSTANTS.FORM_STEP_ACTIVE);
                    _activeSteps.push($step);
                }
            });

            // Service Data
            params = {
                ScId: query.getFormInputValue(
                    $addressStepSelector,
                    CONSTANTS.FORM_ADDRESS_SCID_NAME
                ),
                AccountMaid: _maid,
                AccountNumber: accountNumber,
                IsStartService: true,
                IsTransferService: false,
                ServiceAddress: {
                    City: cityName,
                    State: stateName,
                    PostalCode: postalCodeNumber,
                    PremiseNumber: premiseNumber,
                    StreetAddress: streetNumber,
                    UnitNumber: _unitNumber
                }
            };

            if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                params.Verify = recaptcha.getResponse();
            }

            params = JSON.stringify(params);
            
            //Adding live chat testing scenario
            if(coned.utils.isPatternLab() && _liveChatOn && _liveChatOn === 'true') {
                // Service Call
                query.postData(
                    serviceUrl,
                    failCheckAvailability,
                    failCheckAvailability,
                    params,
                    true,
                    $formLoading
                );
            } else {
                // Service Call
                query.postData(
                    serviceUrl,
                    checkAvailabilityValidation,
                    failCheckAvailability,
                    params,
                    true,
                    $formLoading
                );
            }
        };

        var checkAvailabilityValidation = function (data) {
            if (coned.utils.isPatternLab()) {
                var tomorrow = new Date(),
                    dateNextMonth = new Date(),
                    unit = $autocompleteAparmentUnitInput.value,
                    selectedElement = $autocompleteAparmentUnitInput.value;

                // Set dates
                if (!coned.utils.isOru()) {
                    if (selectedElement == 'PD' || selectedElement == '10C') {
                        // we'll use PD as scenario 1 (Able to backdate)
                        tomorrow.setDate(tomorrow.getDate() - 60);
                        dateNextMonth.setUTCMonth(dateNextMonth.getUTCMonth() + 6);
                        data.InactiveBackDate = CONSTANTS.ABLE_TO_BACKDATE;
                    } else if (selectedElement == 'FD' || selectedElement == '11B') {
                        // we'll use FD as scenario 2 (MUST backdate)
                        tomorrow.setDate(tomorrow.getDate() - 60);
                        dateNextMonth.setDate(dateNextMonth.getDate() - 1);
                        data.InactiveBackDate = CONSTANTS.MUST_BACKDATE;
                    } else {
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        dateNextMonth.setUTCMonth(dateNextMonth.getUTCMonth() + 6);
                        data.InactiveBackDate = '';
                    }
                } else {
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    dateNextMonth.setUTCMonth(dateNextMonth.getUTCMonth() + 6);
                    data.InactiveBackDate = '';
                }

                data.MinDate = tomorrow.toJSON().replace(/-/g, '/').slice(0, 10);
                data.MaxDate = dateNextMonth.toJSON().replace(/-/g, '/').slice(0, 10);

                data.maid = 12345678910;
                data.AccountNumber =
                    'MF+TooI1iJE/j6+lImO5Xgwe/qMm1anl0/e9XW65CmjFn6Fg+DZY2vmZq2BV99o2WGHYyS2W0HuNwLbJGg+bp4tETtowjHhZzm/HhrULXBy1kD2JJ0t9WKKl7otA26X6';

                data.ServiceAddress = {
                    City: 'FLORAL PARK ',
                    Country: '',
                    Division: 'Queens',
                    PostalCode: '11001',
                    State: 'NY',
                    StreetAddress: '86- 22 260 STREET',
                    UnitNumber: 'PD'
                };

                if (!coned.utils.isOru()) {
                    if (unit === '005100006014108' || unit === 'HPH') {
                        data.RedirectTo =
                            '/patterns/04-pages-09-transactional-services-00-start-service-ccg/04-pages-09-transactional-services-00-start-service-ccg.html';
                    } else if (unit === '005100006013107' || unit === 'CPH') {
                        data.AccountType = CONSTANTS.ACCOUNT_TYPE_COMMERCIAL;
                        data.InitialDepositAmount = '14,256.25';
                        data.InitialDepositTime = 12;
                    } else {
                        data.AccountType = CONSTANTS.ACCOUNT_TYPE_RESIDENTIAL;
                        data.InitialDepositAmount = '1,692.63';
                        data.InitialDepositTime = 12;
                        data.AverageBillAmount = '80.00';
                    }
                } else {
                    if (unit === '700935004000055') {
                        data.AverageBillAmount = '80.00';
                    }
                }

                if(coned.utils.isOru()) {
                    data.InitialDepositAmount = '1,692.63';
                    data.InitialDepositTime = 12;
                }

                if(_isEletricityColdAmi) { 
                    data.IsElectricityColdAmi = true;
                }
            }

            successCheckAvailability(data);
        };

        var successCheckAvailability = function (data) {                  
            _averageAmount = data.AverageBillAmount;
            _accountType = data.AccountType;
            _lookupRedirect = data.RedirectTo && data.RedirectTo !== '' ? data.RedirectTo : '';
            var $defaultSuccessMessage = $finishStepSelector.getElementsByClassName(CONSTANTS.DEFAULT_SUCCESS_MESSAGE)[0];

            $lookupButton.dataset.hasRedirect =
                data.RedirectTo && data.RedirectTo !== '' ? 'true' : 'false';

            //If live chat is available clear interval
            if($liveChatAvailable) {
                var $liveChatAvailableBtn = $liveChatAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_BUTTON)[0];
                coned.utils.triggerEvent($liveChatAvailableBtn, CONSTANTS.STOP_CHAT);
            }

            if (data.ServiceAddress) {
                coned.utils.triggerEvent(
                    $authenticationSelector,
                    CONSTANTS.CHANGE_ADDRESS_EVENT,
                    data.ServiceAddress
                );
            }
            
            if(data.IsElectricityColdAmi) {
                _isEletricityColdAmi = true;
                $hasElectricityColdAmi = $startServiceForm.getElementsByClassName(CONSTANTS.HAS_ELECTRICITY_COLD_AMI);
                $electricityColdAmiSuccessMessage = $finishStepSelector.getElementsByClassName(CONSTANTS.HAS_ELECTRICITY_COLD_AMI);

                _.each($hasElectricityColdAmi, function ($hasElectricityColdAmiItem) {
                    $hasElectricityColdAmiItem.classList.remove(CONSTANTS.HIDDEN_CLASS);
                });

                _.each($electricityColdAmiSuccessMessage, function ($hasElectricityColdAmiItem) {
                    $hasElectricityColdAmiItem.classList.remove(CONSTANTS.HIDDEN_CLASS);
                });

                $defaultSuccessMessage.classList.add(CONSTANTS.HIDDEN_CLASS);

                //Tagging
                dataLayer.push({
                    ElectricityAMI: true
                });

            }else {
                _isEletricityColdAmi = false;
                $hasElectricityColdAmi = $startServiceForm.getElementsByClassName(CONSTANTS.HAS_ELECTRICITY_COLD_AMI);
                $electricityColdAmiSuccessMessage = $finishStepSelector.getElementsByClassName(CONSTANTS.HAS_ELECTRICITY_COLD_AMI);

                _.each($hasElectricityColdAmi, function ($hasElectricityColdAmiItem) {
                    $hasElectricityColdAmiItem.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                _.each($electricityColdAmiSuccessMessage, function ($hasElectricityColdAmiItem) {
                    $hasElectricityColdAmiItem.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                $defaultSuccessMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);

                //Tagging
                dataLayer.push({
                    ElectricityAMI: false
                });
            }

            
            if (data.AccountType === CONSTANTS.ACCOUNT_TYPE_COMMERCIAL) {
                _isComercial = true;

                $depositPaymentStepSelector.dataset.isCommercial = 'true';

                if ($commercialInitialAmount) {
                    $commercialInitialAmount.innerHTML = '$' + data.InitialDepositAmount;
                }

                if ($commercialInitialTime) {
                    $commercialInitialTime.innerHTML = data.InitialDepositTime;
                }
            } else {
                $depositPaymentStepSelector.dataset.isCommercial = 'false';
                _isComercial = false;
            }
            

            //Set the deposit amount
            var depositAmountFields = document.getElementsByClassName(CONSTANTS.DEPOSIT_AMOUNT),
                depositTotal = document.getElementsByClassName(
                    CONSTANTS.DEPOSIT_TOTAL_SELECTOR
                )[0];

            _.each(depositAmountFields, function (field) {
                field.innerHTML = '$' + data.InitialDepositAmount;
            });

            if(depositTotal) {
                depositTotal.innerHTML = '$' + data.InitialDepositAmount;
            }            

            _depositAmount = data.InitialDepositAmount;

            if(_depositAmount === CONSTANTS.ZERO_AMOUNT && !checkOver62()) {
                $startForm.dataset.zeroDeposit = true;
            }else {
                $startForm.dataset.zeroDeposit = false;
            }

            if (_averageAmount) {
                if ($addressAverageParagraph) {
                    $addressAverageParagraph.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    if ($paragraphAverage) {
                        $paragraphAverage.innerHTML = '$' + _averageAmount;
                    }
                }
            }

            if (_previousIsComercial === undefined || _previousIsComercial === null) {
                _previousIsComercial = _isComercial;
            }

            _unit = data.ServiceAddress.UnitNumber;
              
            if (_addressSelected.PremiseNumber) {
                _premiseNumber = _addressSelected.PremiseNumber;
            }

            if(data.CompanyCode) {
                _companyCode = data.CompanyCode;
            }

            // Enable submit button and steps nav once the availability is verified
            $lookupButton.disabled = false;
            formStatus();
            setDatesLimit(data.MinDate, data.MaxDate);

            // Only if the status is inactive should display inactive backdate message
            if ($ableToBackdate && $mustBackdate) {
                _isInactiveBackdate =
                    data.InactiveBackDate === CONSTANTS.ABLE_TO_BACKDATE ||
                    data.InactiveBackDate === CONSTANTS.MUST_BACKDATE;

                if (!_isComercial && data.InactiveBackDate === CONSTANTS.ABLE_TO_BACKDATE) {
                    $ableToBackdate.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $mustBackdate.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                } else if (!_isComercial && data.InactiveBackDate === CONSTANTS.MUST_BACKDATE) {
                    $ableToBackdate.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $mustBackdate.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $ableToBackdate.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $mustBackdate.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }

            // Hide/show components for Commercial Account
            if (!coned.utils.isOru()) {
                if (_isComercial) {
                    displayCommercialFields();
                } else {
                    hideCommercialFields();
                    $confirmIdentityButton.disabled = true;
                }

                if (_previousIsComercial !== _isComercial) {
                    resetFormType();
                }
            }

            // Check if form already advanced and set back class for active steps
            _.each(_activeSteps, function ($step) {
                $step.classList.add(CONSTANTS.FORM_STEP_ACTIVE);
            });

            _previousIsComercial = _isComercial;

            //Focus the next element when recaptacha present
            _hasCaptcha && $lookupButton.focus();
        };

        var failCheckAvailability = function (data) {
            var $msgTextSelector = $checkAvailabilityError.getElementsByClassName(
                    CONSTANTS.ERROR_TEXT_SELECTOR
                )[0],
                liveChatAvailable = $addressStepSelector.dataset.liveChatAvailable,
                dataLayerObj = {
                    event: 'startserv.address.error'
                };

            $lookupButton.disabled = true;
            
            if (liveChatAvailable && liveChatAvailable === 'true') {
                var $liveChatAvailableBtn = $liveChatAvailable.getElementsByClassName(CONSTANTS.LIVE_CHAT_BUTTON)[0];
                coned.utils.triggerEvent($liveChatAvailableBtn, CONSTANTS.INIT_CHAT);

                if (data && data.errorMsg && data.liveChatAvailableMessage) {
                    $liveChatErrorMessage.innerHTML = data.errorMsg;
                    $liveChatAvailableMessage.innerHTML = data.liveChatAvailableMessage;
                }

            } else {
                $msgTextSelector.innerHTML = data.errorMsg
                    ? data.errorMsg
                    : coned.constants.ERROR_MESSAGE;
                $checkAvailabilityError.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $checkAvailabilityError.focus();
            }

            formStatus(true);

            if (data.errorCode.toString() === coned.constants.BAD_REQUEST) {
                if (data.errorDetails && data.errorDetails.errorDetails) {
                    var errorCodes = [];

                    _.each(data.errorDetails.errorDetails, function (error) {
                        errorCodes.push(error.errorCode);
                    });

                    dataLayerObj.startservAddError = errorCodes.join('|');
                } else {
                    dataLayerObj.startservAddError = CONSTANTS.NO_ERROR_CODES;
                }

                if (data.maid !== null) {
                    _maid = data.maid;
                    dataLayerObj.maid = data.maid;
                }

                dataLayer.push(dataLayerObj);
            }
        };

        var resetFormType = function () {
            Array.prototype.forEach.call($nextMainButtons, function ($nextButton) {
                $nextButton.disabled = true;
            });

            resetHelpCheckboxes();
            disableFormSteps();
        };

        var resetHelpCheckboxes = function () {
            Array.prototype.forEach.call($helpCheckboxSelector, function ($checkbox) {
                var $checkboxInput = $checkbox.getElementsByClassName(
                        CONSTANTS.HELP_INPUT_SELECTOR
                    )[0],
                    radioInput,
                    $radioInput,
                    $radioSelected;

                if ($checkboxInput) {
                    if ($checkboxInput.checked) {
                        $checkboxInput.click();
                        $checkboxInput.checked = false;
                    }
                } else {
                    $radioSelected = $checkbox.querySelector('input:checked');

                    if ($radioSelected) {
                        radioInput = $radioSelected.value;
                    }

                    if (
                        radioInput &&
                        (radioInput.toLowerCase() == 'yes' || radioInput.toLowerCase() == 'true')
                    ) {
                        $radioInput = $checkbox.querySelector('input:not(:checked)');
                        $radioInput.click();
                    }
                }
            });
        };

        var disableFormSteps = function () {
            Array.prototype.forEach.call($formSteps, function ($formStep, index) {
                if (index !== 0) {
                    $formStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
                }
            });
            $formSteps[0].classList.remove(CONSTANTS.IS_FORM_BLOCKED);
        };

        var displayCommercialFields = function () {
            var $commercialNoRequired = $startForm.getElementsByClassName(
                    CONSTANTS.COMMERCIAL_NO_REQUIRED
                ),
                $commercialNoValidate = $startForm.getElementsByClassName(
                    CONSTANTS.COMMERCIAL_NO_VALIDATE
                ),
                $commercialDisabled = $startForm.getElementsByClassName(
                    CONSTANTS.COMMERCIAL_DISABLED
                ),
                $commercialValidate = $startForm.getElementsByClassName(
                    CONSTANTS.COMMERCIAL_VALIDATE
                ),
                $commercialHidden = document.getElementsByClassName(CONSTANTS.COMMERCIAL_HIDDEN),
                $commercialVisible = document.getElementsByClassName(CONSTANTS.COMMERCIAL_VISIBLE);

            Array.prototype.forEach.call($commercialNoRequired, function ($input) {
                $input.required = false;
            });

            Array.prototype.forEach.call($commercialNoValidate, function ($field) {
                $field.classList.add(CONSTANTS.FORM_ITEM_IGNORE);
                $field.classList.remove(CONSTANTS.FORM_ITEM_VALIDATE);
            });

            Array.prototype.forEach.call($commercialValidate, function ($field) {
                $field.classList.remove(CONSTANTS.FORM_ITEM_IGNORE);
                $field.classList.add(CONSTANTS.FORM_ITEM_VALIDATE);
            });

            Array.prototype.forEach.call($commercialDisabled, function ($field) {
                $field.disabled = true;
            });

            Array.prototype.forEach.call($commercialHidden, function ($container) {
                $container.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            Array.prototype.forEach.call($commercialVisible, function ($container) {
                if (!$container.classList.contains(CONSTANTS.COMMERCIAL_VISIBLE_HIDDEN)) {
                    $container.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            });

            if ($taxInput) {
                $taxInput.classList.add(CONSTANTS.INPUT_ACTIVE_VALUE);
            }

            $authenticationSelector.classList.add(CONSTANTS.IS_COMMERCIAL);
            clearDropdown($selectAuthCommercial);
        };

        var hideCommercialFields = function () {
            var $commercialNoRequired = $startForm.getElementsByClassName(
                    CONSTANTS.COMMERCIAL_NO_REQUIRED
                ),
                $commercialNoValidate = $startForm.getElementsByClassName(
                    CONSTANTS.COMMERCIAL_NO_VALIDATE
                ),
                $commercialValidate = $startForm.getElementsByClassName(
                    CONSTANTS.COMMERCIAL_VALIDATE
                ),
                $commercialDisabled = $startForm.getElementsByClassName(
                    CONSTANTS.COMMERCIAL_DISABLED
                ),
                $commercialHidden = document.getElementsByClassName(CONSTANTS.COMMERCIAL_HIDDEN),
                $commercialVisible = document.getElementsByClassName(CONSTANTS.COMMERCIAL_VISIBLE);

            Array.prototype.forEach.call($commercialNoRequired, function ($input) {
                $input.required = true;
            });

            Array.prototype.forEach.call($commercialDisabled, function ($field) {
                $field.disabled = false;
            });

            Array.prototype.forEach.call($commercialNoValidate, function ($field) {
                $field.classList.remove(CONSTANTS.FORM_ITEM_IGNORE);
                $field.classList.add(CONSTANTS.FORM_ITEM_VALIDATE);
            });

            Array.prototype.forEach.call($commercialValidate, function ($field) {
                $field.classList.add(CONSTANTS.FORM_ITEM_IGNORE);
                $field.classList.remove(CONSTANTS.FORM_ITEM_VALIDATE);
            });

            Array.prototype.forEach.call($commercialHidden, function ($container) {
                if (!$container.classList.contains(CONSTANTS.COMMERCIAL_HIDDEN_VISIBLE)) {
                    $container.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            });

            Array.prototype.forEach.call($commercialVisible, function ($container) {
                $container.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            if ($taxInput) {
                $taxInput.classList.remove(CONSTANTS.INPUT_ACTIVE_VALUE);
            }

            $authenticationSelector.classList.remove(CONSTANTS.IS_COMMERCIAL);
            
            clearDropdown($selectAuth);
        };

        var setDatesLimit = function (minDate, maxDate) {
            var dates = coned.utils.datepickerDateFormat(minDate, maxDate);

            // Set input validation rules
            $datePickerInput.dataset.minDateDays = dates.minDate
                .toJSON()
                .replace(/-/g, '/')
                .slice(0, 10);
            $datePickerInput.dataset.maxDateDays = dates.maxDate
                .toJSON()
                .replace(/-/g, '/')
                .slice(0, 10);

            // Set the date picker dates limit
            setTimeout(function () {
                $($datePickerInput).datepicker('option', 'minDate', new Date(dates.minDate));
                $($datePickerInput).datepicker('option', 'maxDate', new Date(dates.maxDate));
            }, 1);
        };

        var clearDropdown = function ($dropdown) {
            var $dropdownLabel = $dropdown.parentElement.getElementsByClassName(
                CONSTANTS.DROPDOWN_LABEL
            )[0];

            $dropdownLabel.removeAttribute('style');
            $dropdown.removeAttribute('style');
            $dropdown.selectedIndex = 0;
            $dropdown.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);
        };

        var hideAddressLookUpErrors = function () {
            hideError($checkAvailabilityError);

            $accountNumberServiceError && hideError($accountNumberServiceError);
        };

        var verifyDate = function (target) {
            var $target = target,
                validator = $(CONSTANTS.START_FORM).validate(),
                dateStatus = validator.element($target),
                serviceUrl = $verifyDateStepSelector.dataset.verifyDateServiceUrl,
                params;

            if (!dateStatus || _isVerifyActive) return;

            _isVerifyActive = true;
            $datePickerInput.classList.add(CONSTANTS.FILLED_CLASS);

            $target.disabled = true;
            hideError($startDateServiceError);

            // Service Data
            params = {
                Maid: _maid,
                ScServiceAddressId: query.getFormInputValue(
                    $addressStepSelector,
                    CONSTANTS.FORM_ADDRESS_SCID_NAME
                ),
                ScStartDateId: query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_START_DATE_SCID_NAME
                ),
                StartServiceDate: $target.value,
                UnitNumber: _unit,
                PremiseNumber: _premiseNumber
            };

            // Add ORU related properties
            if (coned.utils.isOru()) {
                //Adjustments for the new experience on ORU
                params.CompanyCode = _companyCode;
            }
            
            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successVerifyDate,
                failVerifyDate,
                params,
                true,
                $formLoading
            );
        };

        var successVerifyDate = function () {
            _isVerifyActive = false;
            $datePickerInput.disabled = false;
            $datePickerInput.focus();
            formStatus();

            enableNextStep();
        };

        var failVerifyDate = function (data) {
            _isVerifyActive = false;
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.VERIFY_DATE_ERROR,
                    errorVerifyDate,
                    errorVerifyDate
                );
            } else {
                errorVerifyDate(data);
            }
        };

        var errorVerifyDate = function (data) {
            var $msgTextSelector = $startDateServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $startDateServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $datePickerInput.classList.remove(CONSTANTS.FILLED_CLASS);
            $datePickerInput.value = '';
            $datePickerInput.disabled = false;
            $datePickerInput.focus();
            formStatus(true);
        };

        var hideError = function ($element) {
            if ($element) {
                $element.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var mailingChange = function () {
            if (this.checked) {
                $mailingOptionsContainer.classList.add(CONSTANTS.MAILING_OPTIONS_CLASS);

                _.each($mailingOptions, function (field) {
                    field.classList.add(CONSTANTS.VALIDATE_IGNORE);
                    field.classList.remove(CONSTANTS.INPUT_ERROR_MESSAGE);
                    field.classList.remove(CONSTANTS.INPUT_VALIDATE);
                });
            } else {
                $mailingOptionsContainer.classList.remove(CONSTANTS.MAILING_OPTIONS_CLASS);

                _.each($mailingOptions, function (field) {
                    field.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                    field.classList.add(CONSTANTS.INPUT_VALIDATE);
                });
            }
        };

        var countrySelectionEvent = function () {
            var parentSelectElement = $mailingInputState.parentElement.parentElement.parentElement,
                label = parentSelectElement.getElementsByClassName(CONSTANTS.DROPDOWN_LABEL)[0],
                country = $countryDropdown.value.toLowerCase();

            parentSelectElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
            label.setAttribute('style', '');
            $canadaZipcodeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $USAZipcodeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);

            setEnabledOptions(country);

            if (country === CONSTANTS.USA_SELECT_VALUE) {
                $USAZipcodeSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $mailingInputZipCode.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputZipCode.classList.add(CONSTANTS.INPUT_VALIDATE);
                $mailingInputCanadaZipCode.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputCanadaZipCode.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $citySelector.classList.remove(CONSTANTS.INPUT_FULL);
                toggleOtherCountry(false);
            } else if (country === CONSTANTS.CANADA_SELECT_VALUE) {
                $canadaZipcodeSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $mailingInputCanadaZipCode.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputCanadaZipCode.classList.add(CONSTANTS.INPUT_VALIDATE);
                $mailingInputZipCode.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputZipCode.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $citySelector.classList.remove(CONSTANTS.INPUT_FULL);
                toggleOtherCountry(false);
            } else {
                parentSelectElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $USAZipcodeSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $mailingInputZipCode.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputZipCode.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $mailingInputCanadaZipCode.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputCanadaZipCode.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $citySelector.classList.add(CONSTANTS.INPUT_FULL);
                toggleOtherCountry(true);
            }
        };

        var toggleOtherCountry = function (toggleFlag) {
            if (toggleFlag) {
                $otherCountryName.parentNode.parentNode.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $otherCountryName.classList.remove(CONSTANTS.INPUT_ERROR_MESSAGE);
                $otherCountryName.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $otherCountryName.classList.add(CONSTANTS.INPUT_VALIDATE);
                $USAZipcodeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $canadaZipcodeSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                $otherCountryName.parentNode.parentNode.classList.add(CONSTANTS.HIDDEN_CLASS);
                $otherCountryName.classList.add(CONSTANTS.INPUT_ERROR_MESSAGE);
                $otherCountryName.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $otherCountryName.classList.remove(CONSTANTS.INPUT_VALIDATE);
            }
        };

        var setEnabledOptions = function (country) {
            $mailingInputState.disabled = true;
            $mailingInputState.selectedIndex = 0;
            $mailingInputState.setAttribute('style', '');
            $USADropdownContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mailingInputState.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);
            $mailingInputState.classList.remove(CONSTANTS.DROPDOWN_ACTIVE_CLASS);

            $mailingInputStateCanada.disabled = true;
            $mailingInputStateCanada.selectedIndex = 0;
            $mailingInputStateCanada.setAttribute('style', '');
            $CanadaDropdownContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $mailingInputStateCanada.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);
            $mailingInputStateCanada.classList.remove(CONSTANTS.DROPDOWN_ACTIVE_CLASS);

            if (country === CONSTANTS.USA_SELECT_VALUE) {
                $USADropdownContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $mailingInputState.disabled = false;
                $mailingInputState.classList.add(CONSTANTS.ACTIVE_DROPDOWN);
                $mailingInputState.classList.add(CONSTANTS.INPUT_VALIDATE);
                $mailingInputState.classList.add(CONSTANTS.DROPDOWN_ACTIVE_CLASS);
                $mailingInputState.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputState.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $mailingInputStateCanada.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $mailingInputStateCanada.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputStateCanada.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else if (country === CONSTANTS.CANADA_SELECT_VALUE) {
                $CanadaDropdownContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $mailingInputStateCanada.disabled = false;
                $mailingInputStateCanada.classList.add(CONSTANTS.ACTIVE_DROPDOWN);
                $mailingInputStateCanada.classList.add(CONSTANTS.INPUT_VALIDATE);
                $mailingInputStateCanada.classList.add(CONSTANTS.DROPDOWN_ACTIVE_CLASS);
                $mailingInputStateCanada.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputStateCanada.classList.remove(CONSTANTS.HIDDEN_CLASS);

                $mailingInputState.classList.remove(CONSTANTS.INPUT_VALIDATE);
                $mailingInputState.classList.add(CONSTANTS.VALIDATE_IGNORE);
                $mailingInputState.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var lifeSupportRadioChange = function () {
            var $currentStep = query.selectParentElement(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_CURRENT_STEP
                ),
                $submitAnimationButton = $currentStep.getElementsByClassName(
                    CONSTANTS.ANIMATION_SUBMIT_MODULE
                )[0],
                $navSteps = $progressBar.getElementsByClassName(CONSTANTS.FORM_STEP);

            if ($lifeSupportRadioYesSelector.checked) {
                $lifeSupportRadioErrorSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $verifyDateStepSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $serviceIdentityStepSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $submitAnimationButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                query.addClass($navSteps, CONSTANTS.IS_FORM_BLOCKED);
                $lifeSupportRadioErrorSelector.focus()
            } else {
                $lifeSupportRadioErrorSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $verifyDateStepSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $serviceIdentityStepSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $submitAnimationButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                query.removeClass($navSteps, CONSTANTS.IS_FORM_BLOCKED);
            }
        };

        var submitAnimationButton = function () {
            $submitAnimationButton = $reviewStepSelector.getElementsByClassName(
                CONSTANTS.ANIMATION_SUBMIT_MODULE
            )[0];
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                startServiceCall,
                successStartServiceCallback,
                errorStartServiceCallback,
                false,
                true
            );
        };

        var setReviewForm = function (event) {
            event.preventDefault();

            if (event.currentTarget.disabled) {
                return;
            }

            // Verify Date Service call
            addStartDatePickerHandlers();

            var $buttonClicked = query.selectParentElement(event.target, CONSTANTS.NEXT_BUTTON),
                formSteps = document.getElementsByClassName(CONSTANTS.FORM_STEP),
                $reviewStep = formSteps[formSteps.length - 1],
                isFinishButtonClicked = $buttonClicked && query.hasClass($buttonClicked, CONSTANTS.FINISH_BUTTON),
                isReviewStepSelectorVisible = coned.utils.isDomVisible($reviewStepSelector),
                bopaText;

            coned.utils.addGeneralListeners($reviewStep, setReviewForm);

            // Bug fix - Check review step section visibility to prevent unintended submits due to pressing the enter key 
            // on previous steps when focusing on an element that does not handle preventDefault for the enter key.
            if (isFinishButtonClicked && isReviewStepSelectorVisible) {
                submitAnimationButton();
            }

            // Set Address
            var customerAddress = $autocompleteAddressSelectedDetail.dataset.customerAddress;

            // Set start date and date of birth
            var startDateFormat, startDateOfBirth;

            if ($datePickerInput) {
                startDateFormat = $.datepicker.formatDate(
                    'MM dd, yy',
                    new Date($datePickerInput.value)
                );
            }

            if ($birthDate) {
                startDateOfBirth = $.datepicker.formatDate('MM dd, yy', new Date($birthDate.value));
            }

            // Set Name and Last Name
            var name, lastName;

            if (_isComercial) {
                name = $nameCommercial.value;
                lastName = $lastNameCommercial.value;
            } else {
                name = $name.value;
                lastName = $lastName.value;
            }

            // Set identification value and title
            var $identification = $startForm.getElementsByClassName(CONSTANTS.IDENTIFICATION)[0],
                identification = '',
                identificationTitle = '';

            if (!$identification) {
                $identification = $identificationSelect;
            }

            var depositFields = document.getElementsByClassName(CONSTANTS.DEPOSIT_HIDDEN);

            if (_isComercial || $identification.value === CONSTANTS.NO_IDENTIFICATION) {
                if (_isComercial) {
                    _.each(depositFields, function (field) {
                        if (!query.hasClass(field, CONSTANTS.COMMERCIAL_HIDDEN)) {
                            field.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        } else {
                            field.classList.add(CONSTANTS.HIDDEN_CLASS);
                        }
                    });
                } else if (
                    !$startForm.getElementsByClassName(CONSTANTS.DIRECT_PAYMENT)[0].checked &&
                    !checkOver62()
                ) {
                    _.each(depositFields, function (field) {
                        field.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    });

                    identification =
                        $identification.options[$identification.selectedIndex].innerHTML;
                    identificationTitle = CONSTANTS.IDENTIFICATION_STRING;
                } else {
                    _.each(depositFields, function (field) {
                        field.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });

                    identification =
                        $identification.options[$identification.selectedIndex].innerHTML;
                    identificationTitle = CONSTANTS.IDENTIFICATION_STRING;
                }
            } else {
                _.each(depositFields, function (field) {
                    field.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                identification = $identification.value;
                identificationTitle = $identification.nextElementSibling.innerHTML;

                if (identificationTitle === CONSTANTS.SOCIAL_SECURITY_NUMBER) {
                    identification = $identification.value.replace(/\d(?=.{4})/g, '*');
                } else if (identificationTitle === CONSTANTS.TAX_ID_NUMBER) {
                    identification = $identification.value.replace(/\d(?=.{4})/g, '*');
                }
            }

            // Set Email and Phone
            var email = $email.value,
                phone = $phone.value,
                businessName,
                phoneTitle = $phoneTypeSelect.options[$phoneTypeSelect.selectedIndex].text;

            if ($business) {
                businessName = $business.value;
            }

            // Set Address
            if ($mailingCheckbox.checked) {
                $mailingReceptor.innerHTML = customerAddress;
            } else {
                var mailingText,
                    mailingZipCode,
                    mailingState =
                        $mailingInputState.options[$mailingInputState.selectedIndex].text;

                if (coned.utils.isOru()) {
                    var countrySelected = query.getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_COUNTRY_NAME
                        ),
                        zipCodeName =
                            countrySelected.toLowerCase() === CONSTANTS.CANADA_SELECT_VALUE
                                ? CONSTANTS.FORM_MAILING_CANADA_ZIPCODE_NAME
                                : CONSTANTS.FORM_MAILING_ZIPCODE_NAME,
                        mailingCountry =
                            $countryDropdown.options[$countryDropdown.selectedIndex].text,
                        mailingAddress1 = query.getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_ADDRESS_ONE_NAME
                        ),
                        mailingAddress2 = query.getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_ADDRESS_TWO_NAME
                        ),
                        mailingCity = query.getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_CITY_NAME
                        );

                    mailingZipCode = query.getFormInputValue(
                        $accountProfileStepSelector,
                        zipCodeName
                    );
                    mailingState =
                        countrySelected.toLowerCase() !== CONSTANTS.CANADA_SELECT_VALUE
                            ? $mailingInputState.options[$mailingInputState.selectedIndex].text
                            : $mailingInputStateCanada.options[
                                  $mailingInputStateCanada.selectedIndex
                              ].text;

                    if (mailingCountry != 'Other') {
                        mailingText =
                            mailingAddress1 +
                            ' ' +
                            mailingAddress2 +
                            '<br>' +
                            mailingCity +
                            ', ' +
                            mailingState +
                            ' ' +
                            mailingZipCode;
                    } else {
                        mailingText =
                            mailingAddress1 +
                            ' ' +
                            mailingAddress2 +
                            '<br>' +
                            mailingCity +
                            ', ' +
                            query.getFormInputValue(
                                $accountProfileStepSelector,
                                CONSTANTS.FORM_MAILING_OTHER_COUNTRY_NAME_NAME
                            );
                    }
                } else {
                    var mailingTown = $mailingInputTown.value,
                        mailingStreet = $mailingInputStreet.value;

                    mailingZipCode = $mailingInputZipCode.value;
                    mailingText =
                        mailingStreet +
                        '<br>' +
                        mailingTown +
                        ', ' +
                        mailingState +
                        ' ' +
                        mailingZipCode;
                }

                $mailingReceptor.innerHTML = mailingText;
            }

            if (_isComercial) {
                if (businessName) {
                    $nameReceptor.parentElement.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);

                    Array.prototype.forEach.call($lastNameReceptors, function ($receptor) {
                        $receptor.parentElement.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });

                    if ($businessReceptor) {
                        $businessReceptor.parentElement.parentElement.classList.remove(
                            CONSTANTS.HIDDEN_CLASS
                        );
                    }
                } else {
                    $nameReceptor.parentElement.parentElement.classList.remove(
                        CONSTANTS.HIDDEN_CLASS
                    );

                    Array.prototype.forEach.call($lastNameReceptors, function ($receptor) {
                        $receptor.parentElement.parentElement.classList.remove(
                            CONSTANTS.HIDDEN_CLASS
                        );
                    });

                    if ($businessReceptor) {
                        $businessReceptor.parentElement.parentElement.classList.add(
                            CONSTANTS.HIDDEN_CLASS
                        );
                    }
                }
            }

            $startDateReceptor.innerHTML = startDateFormat;
            $startAddressReceptor.innerHTML = customerAddress;
            $nameReceptor.innerHTML = name;
            Array.prototype.forEach.call($lastNameReceptors, function ($receptor) {
                $receptor.innerHTML = lastName;
            });

            if ($businessReceptor) {
                $businessReceptor.innerHTML = businessName;
            }

            if ($birthDateReceptor) {
                $birthDateReceptor.innerHTML = startDateOfBirth;
            }

            Array.prototype.forEach.call($identificationReceptors, function ($receptor) {
                $receptor.innerHTML = identification;
            });

            Array.prototype.forEach.call($identificationTitleReceptors, function ($receptor) {
                $receptor.innerHTML = identificationTitle;
            });

            $emailReceptor.innerHTML = email;
            $phoneReceptor.innerHTML = phone;
            $phoneTitleReceptor.innerHTML = phoneTitle;

            if ($successEmailReceptor) {
                $successEmailReceptor.innerHTML = email;
            }

            if ($successDateReceptor) {
                $successDateReceptor.innerHTML = startDateFormat;
            }

            if ($bopaChecklistInfo) {
                bopaText = query.getFormInputText(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_BOPA_AWKNOLEDGEMENT_NAME,
                    CONSTANTS.CONED_RADIO_CLASS,
                    CONSTANTS.CONED_RADIO_LABEL_CLASS
                );

                if (bopaText && bopaText !== '') {
                    $bopaChecklistInfo.innerHTML = bopaText;
                }
            }

            // Disability fields for ORU
            if (coned.utils.isOru()) {
                var disabilityAge62Alone = query.getFormInputValue(
                        $startForm,
                        CONSTANTS.FORM_ORU_DISABILITY_AGE_62_ALONE_NAME
                    ),
                    disabilityMembers62Over18Under = query.getFormInputValue(
                        $startForm,
                        CONSTANTS.FORM_ORU_DISABILITY_MEMBERS_62_OVER_18_UNDER
                    ),
                    disabilityDisabledBlind = query.getFormInputValue(
                        $startForm,
                        CONSTANTS.FORM_ORU_DISABILITY_DISABLED_BLIND
                    );

                $disabilityAge62AloneReceptor.innerHTML = disabilityAge62Alone;
                $disabilityMembers62Over18UnderReceptor.innerHTML = disabilityMembers62Over18Under;
                $disabilityDisabledBlindReceptor.innerHTML = disabilityDisabledBlind;

                _.each($radioAge62OverAlone, function($radio) {
                    $radio.disabled = false; 
                    $radio.setAttribute(CONSTANTS.ARIA_DISABLED, false);
                });

                if(disabilityAge62Alone === CONSTANTS.RADIO_YES_OPTION || 
                    disabilityMembers62Over18Under === CONSTANTS.RADIO_YES_OPTION ||
                    disabilityDisabledBlind === CONSTANTS.RADIO_YES_OPTION) {

                    _.each(depositFields, function (field) {
                        field.classList.add(CONSTANTS.HIDDEN_CLASS);
                    });
                } 
                
                if(disabilityAge62Alone === CONSTANTS.RADIO_NO_OPTION) {
                    _.each($radioAge62OverAlone, function($radio) {
                        $radio.disabled = true; 
                        $radio.setAttribute(CONSTANTS.ARIA_DISABLED, true);
                    });
                }
            }
        };

        var checkDisability = function(event) {
            var depositFields = document.getElementsByClassName(CONSTANTS.DEPOSIT_HIDDEN),
                identification = $identificationSelect.options[$identificationSelect.selectedIndex].value;
               
            // Check if No deposit
            if (identification === CONSTANTS.NO_IDENTIFICATION && !checkOver62()) {
                var radioValue = query.getFormInputValue(
                    $startForm,
                    event.target.name
                );
                _edbDepositWaived = false;

                if(radioValue === CONSTANTS.RADIO_YES_OPTION) { 
                    _edb[event.target.name] = true;
                }else {
                    _edb[event.target.name] = false;
                }

                var keys = Object.keys(_edb);
                for (var i = 0; i < keys.length; i++) {
                    if (_edb[keys[i]]) {          
                        _edbDepositWaived = true;
                    }
                }

                if(_edbDepositWaived) {
                    $noDepositAlert.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    _.each(depositFields, function (field) {
                        field.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    });
                    //Inform the form progress that the deposit should be waived
                    coned.utils.triggerEvent($progressBar,'deposit-waived');
                }else {
                    $noDepositAlert.classList.add(CONSTANTS.HIDDEN_CLASS);
                    _.each(depositFields, function (field) {
                        field.classList.add(CONSTANTS.HIDDEN_CLASS);
                        //Inform the form progress that the deposit should be required
                        coned.utils.triggerEvent($progressBar,'deposit-required');
                    });
                }
            }            
            
        };

        var startServiceCall = function () {
            var serviceUrl = $startForm.dataset.startServiceUrl,
                mailingAddressFlag = !query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_ADDRESS_NAME
                ),
                ebillFlag = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_EBILL_ENROLL_NAME
                ),
                lseFlag = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_LIFE_SUPPORT_NAME
                ),
                delegateFlag = query.getFormInputValue(
                    $startForm,
                    CONSTANTS.FORM_DISABILITY_OTHER_NOTIFIED_NAME
                ),
                disabilityFlag = coned.utils.isOru()
                    ? true
                    : query.getFormInputValue(
                          $accountProfileStepSelector,
                          CONSTANTS.FORM_DISABILITY_NAME
                      ),
                dppFlag = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_DIRECT_PAYMENT_ENROLL_NAME
                ),
                params,
                rpoIndicator = query.getFormInputValue($startForm, CONSTANTS.FORM_ROP_INDICATOR),
                bopaAcknowledgement = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_BOPA_AWKNOLEDGEMENT_NAME
                ),
                SSNo = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_SSN_NAME
                ),
                TaxId = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.TAX_INPUT_NAME
                ),
                identification =
                    $identificationSelect.options[$identificationSelect.selectedIndex].value;

            hideError($startServiceError);

            // Service Data
            params = {
                ScStartDateId: query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_START_DATE_SCID_NAME
                ),
                ScServiceAddressId: query.getFormInputValue(
                    $addressStepSelector,
                    CONSTANTS.FORM_ADDRESS_SCID_NAME
                ),
                ScAccountProfileId: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_ACCOUNT_PROFILE_SCID_NAME
                ),
                ScDirectPaymentId: query.getFormInputValue(
                    $directPaymentStepSelector,
                    CONSTANTS.FORM_DIRECT_PAYMENT_SCID_NAME
                ),
                ScLifeSupportEquipmentId: query.getFormInputValue(
                    $startForm,
                    CONSTANTS.FORM_LIFE_SUPPORT_SCID_NAME
                ),
                ScDisabilityId: query.getFormInputValue(
                    $startForm,
                    CONSTANTS.FORM_DISABILITY_SCID_NAME
                ),
                StartDate: query.getFormInputValue(
                    $verifyDateStepSelector,
                    CONSTANTS.FORM_START_DATE_NAME
                ),
                Email: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_EMAIL_NAME
                ),
                Phone: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_NUMBER_NAME
                ),
                PhoneType: query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_TYPE_NAME
                ),
                Maid: _maid,
                IdentificationType: (TaxId ? CONSTANTS.COMMERCIAL_TAX_ID : CONSTANTS.SSN_LABEL),
                MailingAddress: setMailingAddress(mailingAddressFlag),
                SecurityWord: '',
                EbillFlag: ebillFlag,
                DPPFlag: dppFlag,
                PremiseNumber: _premiseNumber
            };

            // Analytics data building - ebill
            if (ebillFlag) {
                dataLayer.push({
                    event: 'startservice.ebill'
                });
            }

           
            params.AccountType = _accountType;
            params.IdentificationType =
                CONSTANTS.DROPDOWN_DICTIONARY[
                    $identificationSelect.options[$identificationSelect.selectedIndex].value
                ];

            // Add tagging for NoID Deposit Payment
            if (
                _isComercial ||
                (identification === CONSTANTS.NO_IDENTIFICATION && !dppFlag && !checkOver62())
            ) {
                var value = query.getFormInputValue(
                        $depositPaymentStepSelector,
                        CONSTANTS.FORM_NID_PAYMENT_METHOD_NAME
                    ),
                    proccessedDepositAmount = $depositPaymentStepSelector
                        .getElementsByClassName(CONSTANTS.DEPOSIT_AMOUNT)[0]
                        .innerHTML.split('$')[1]
                        .replace(/,/g, '');

                if (
                    value === CONSTANTS.DEPOSIT_BANK_CHECKING ||
                    value === CONSTANTS.DEPOSIT_BANK_SAVINGS
                ) {
                    params.RequiredDepositFlag = true;
                    params.DepositPayment = {};

                    params.DepositPayment.PaymentType = CONSTANTS.DEPOSIT_CHECK_TAG;
                    params.DepositPayment.BankAccountType = query.getFormInputValue(
                        $depositPaymentStepSelector,
                        CONSTANTS.FORM_NID_PAYMENT_METHOD_NAME
                    );
                    params.DepositPayment.BankRoutingNumber = query.getFormInputValue(
                        $depositPaymentStepSelector,
                        CONSTANTS.FORM_NID_ROUTING_NUMBER_NAME
                    );
                    params.DepositPayment.BankAccountNumber = query.getFormInputValue(
                        $depositPaymentStepSelector,
                        CONSTANTS.FORM_NID_ACCOUNT_NUMBER_NAME
                    );
                    params.DepositPayment.DepositAmount = parseFloat(proccessedDepositAmount);

                    //Send tagging
                    dataLayer.push({
                        deposit: 'true'
                    });
                } else if (value === CONSTANTS.DEPOSIT_RECEIVE_IN_MAIL.toLowerCase()) {
                    params.RequiredDepositFlag = true;
                    params.DepositPayment = {};

                    params.DepositPayment.PaymentType = CONSTANTS.DEPOSIT_RECEIVE_IN_MAIL;
                    params.DepositPayment.DepositAmount = parseFloat(proccessedDepositAmount);
                    
                    //Send tagging
                    dataLayer.push({
                    deposit: 'true'
                });
                }
            }
            

            if (dppFlag) {
                params.AutopayDetails = {
                    BankAccountType: query.getFormInputValue(
                        $directPaymentStepSelector,
                        CONSTANTS.FORM_DP_ACCOUNT_TYPE_NAME
                    ),
                    BankRoutingNumber: query.getFormInputValue(
                        $directPaymentStepSelector,
                        CONSTANTS.FORM_DP_ROUTING_NUMBER_NAME
                    ),
                    BankAccountNumber: query.getFormInputValue(
                        $directPaymentStepSelector,
                        CONSTANTS.FORM_DP_ACCOUNT_NUMBER_NAME
                    )
                }

                // Analytics data building - dpp
                dataLayer.push({
                    event: 'startservice.dpp'
                });
            }

            if (!_isComercial) {
                params.DateOfBirth = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_DOB_NAME
                );
                params.SSNNo = SSNo ? SSNo.replace(/-/g, '') : '';
                params.DriverLicenseNo = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_DRIVER_LICENSE_NAME
                );
                params.DriverLicenseState = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_DRIVER_LICENSE_ISSUED_STATE_NAME
                );
                params.ResidentAlienId = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_ALIEN_ID_NAME
                );
                params.PublicAssistanceId = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_PUBLIC_ASSISTANCE_NAME
                );
                params.Passport = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_PASSPORT_NAME
                );
                params.CustomerFirstName = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_FIRST_NAME_NAME
                ).trim();
                params.CustomerLastName = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_LAST_NAME_NAME
                ).trim();

                if (rpoIndicator && rpoIndicator !== '') {
                    params.RPOIndicator = rpoIndicator;
                }

                if (coned.utils.isOru()) {
                    //Check if there is one or more disability options selected to set the Concern Flag to true
                    var disabilityOptions = setDisability(disabilityFlag, delegateFlag);

                    if(Object.values(disabilityOptions).includes(true)) {
                        params.ConcernFlag = true;
                    } else {
                        params.ConcernFlag = false;
                    }
                    params.ElderlyBlindAndDisabled = disabilityOptions;

                } else if (disabilityFlag.toLowerCase() === 'yes') {
                    params.ConcernFlag = true;
                    params.Concern = setDisability(disabilityFlag, delegateFlag);

                    // Analytics data building - special services
                    dataLayer.push({
                        event: 'startservice.specialservices'
                    });
                } else {
                    params.ConcernFlag = false;
                }

                if (lseFlag && lseFlag.toLowerCase() === 'yes') {
                    params.LSEFlag = true;
                    params.LSE = setLse(lseFlag);

                    // Analytics data building - life support
                    dataLayer.push({
                        event: 'startservice.lifesupport'
                    });
                } else {
                    params.LSEFlag = false;
                }
            } else {
                // Commercial
                params.CommercialTaxId = TaxId.replace(/-/g, '');
                params.CustomerFirstName = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_FIRST_NAME_COMMERCIAL
                ).trim();
                params.CustomerLastName = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_LAST_NAME_COMMERCIAL
                ).trim();
                params.BusinessName = query.getFormInputValue(
                    $serviceIdentityStepSelector,
                    CONSTANTS.FORM_BUSINESS_NAME
                ).trim();
                params.IdentificationType = (TaxId ? CONSTANTS.COMMERCIAL_TAX_ID : CONSTANTS.SSN_LABEL);

                if (bopaAcknowledgement && bopaAcknowledgement !== CONSTANTS.BOPA_RADIO_NOT_SURE) {
                    params.BOPAAcknowledgement = bopaAcknowledgement;
                }
            }

            // Service Call
            params = JSON.stringify(params);

            query.postData(serviceUrl, successStartCall, errorStartCall, params, true);
        };

        var successStartCall = function () {
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        var errorStartCall = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var setMailingAddress = function (mailingAddressFlag) {
            var mailingAddress;

            if (!mailingAddressFlag) return null;

            if (coned.utils.isOru()) {
                var countrySelected = query.getFormInputValue(
                        $accountProfileStepSelector,
                        CONSTANTS.FORM_MAILING_COUNTRY_NAME
                    ),
                    zipCodeName =
                        countrySelected.toLowerCase() == 'canada'
                            ? CONSTANTS.FORM_MAILING_CANADA_ZIPCODE_NAME
                            : CONSTANTS.FORM_MAILING_ZIPCODE_NAME,
                    state =
                        countrySelected.toLowerCase() == 'other'
                            ? ''
                            : countrySelected.toLowerCase() == 'usa'
                            ? query.getFormInputValue(
                                  $accountProfileStepSelector,
                                  CONSTANTS.FORM_MAILING_STATE_NAME_USA
                              )
                            : query.getFormInputValue(
                                  $accountProfileStepSelector,
                                  CONSTANTS.FORM_MAILING_STATE_NAME_CANADA
                              );

                mailingAddress = {
                    Street1: query
                        .getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_ADDRESS_ONE_NAME
                        )
                        .replace(/\t/g, ' '),
                    Street2: query
                        .getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_ADDRESS_TWO_NAME
                        )
                        .replace(/\t/g, ' '),
                    City: query
                        .getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_CITY_NAME
                        )
                        .replace(/\t/g, ' '),
                    State: state,
                    PostalCode: query
                        .getFormInputValue($accountProfileStepSelector, zipCodeName)
                        .toUpperCase(),
                    Country: countrySelected,
                    OtherCountryName: query.getFormInputValue(
                        $accountProfileStepSelector,
                        CONSTANTS.FORM_MAILING_OTHER_COUNTRY_NAME_NAME
                    )
                };
            } else {
                mailingAddress = {
                    Name: query
                        .getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_NAME_NAME
                        )
                        .replace(/\t/g, ' '),
                    Street1: query
                        .getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_STREET_NAME
                        )
                        .replace(/\t/g, ' '),
                    City: query
                        .getFormInputValue(
                            $accountProfileStepSelector,
                            CONSTANTS.FORM_MAILING_TOWN_NAME
                        )
                        .replace(/\t/g, ' '),
                    State: query.getFormInputValue(
                        $accountProfileStepSelector,
                        CONSTANTS.FORM_MAILING_STATE_NAME_USA
                    ),
                    PostalCode: query.getFormInputValue(
                        $accountProfileStepSelector,
                        CONSTANTS.FORM_MAILING_ZIPCODE_NAME
                    )
                };
            }

            return mailingAddress;
        };

        // Handles the first Continue button click
        var changePaymentMethod = function (event) {
            var paymentContents = document.getElementsByClassName(
                    CONSTANTS.DEPOSIT_PAYMENT_METHOD_CONTENT
                ),
                targetValue = event.currentTarget.value;

            // Hide content
            _.each(paymentContents, function (content) {
                content.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            if (targetValue === CONSTANTS.DEPOSIT_MAIL_BILL) {
                // Change content if Mail Bill is selected
                switchPaymentMethodContent(targetValue);

                // Disable & show Continue buttons
                $depositPaymentSubmit.disabled = false;
                $depositPaymentContinueButton.disabled = true;
                
                $depositPaymentSubmit.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $depositPaymentContinueButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                // Disable & show Continue buttons
                $depositPaymentSubmit.disabled = true;
                $depositPaymentContinueButton.disabled = false;

                $depositPaymentSubmit.parentElement.classList.add(CONSTANTS.HIDDEN_CLASS);
                $depositPaymentContinueButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        // Changes the content depending on the value
        var switchPaymentMethodContent = function (value) {
            var paymentContents = document.getElementsByClassName(
                    CONSTANTS.DEPOSIT_PAYMENT_METHOD_CONTENT
                ),
                $mailMessages = document.getElementsByClassName(CONSTANTS.DEPOSIT_MAIL_HIDDEN),
                $bankMessages = document.getElementsByClassName(CONSTANTS.DEPOSIT_BANK_HIDDEN);

            // Remove all content
            _.each(paymentContents, function (content) {
                content.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            // Make inputs not required for validation
            $depositAccount.required = false;
            $depositRouting.required = false;
            $depositAccount.classList.add(CONSTANTS.VALIDATE_IGNORE);
            $depositRouting.classList.add(CONSTANTS.VALIDATE_IGNORE);
            $depositAccount.classList.remove(CONSTANTS.FORM_ITEM_VALIDATE);
            $depositRouting.classList.remove(CONSTANTS.FORM_ITEM_VALIDATE);

            // Display content and activate inputs depending on value
            if (
                value == CONSTANTS.DEPOSIT_BANK_CHECKING ||
                value == CONSTANTS.DEPOSIT_BANK_SAVINGS
            ) {
                var bankAccountContent = document.getElementsByClassName(
                        CONSTANTS.DEPOSIT_BANK_CONTENT
                    )[0],
                    accountTypeLabel = document.getElementsByClassName(
                        CONSTANTS.DEPOSIT_ACCOUNT_TYPE_LABEL
                    );

                query.addClass($mailMessages, CONSTANTS.HIDDEN_CLASS);
                query.removeClass($bankMessages, CONSTANTS.HIDDEN_CLASS);
                bankAccountContent.classList.remove(CONSTANTS.HIDDEN_CLASS);
                _.each(accountTypeLabel, function (label) {
                    label.innerHTML = value;
                });

                $depositAccount.required = true;
                $depositRouting.required = true;
                $depositAccount.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $depositRouting.classList.remove(CONSTANTS.VALIDATE_IGNORE);
                $depositAccount.classList.add(CONSTANTS.FORM_ITEM_VALIDATE);
                $depositRouting.classList.add(CONSTANTS.FORM_ITEM_VALIDATE);
            } else {
                var mailBillContent = document.getElementsByClassName(
                    CONSTANTS.DEPOSIT_MAIL_BILL_CONTENT
                )[0];

                query.removeClass($mailMessages, CONSTANTS.HIDDEN_CLASS);
                query.addClass($bankMessages, CONSTANTS.HIDDEN_CLASS);

                mailBillContent.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            depositPaymentValidation();
        };

        var clickDepositPaymentContinue = function () {
            $depositPaymentSubmit.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $depositPaymentContinueButton.classList.add(CONSTANTS.HIDDEN_CLASS);
            switchPaymentMethodContent(
                query.getFormInputValue(
                    $depositPaymentStepSelector,
                    CONSTANTS.FORM_NID_PAYMENT_METHOD_NAME
                )
            );
        };

        var checkOver62 = function () {
            var value = query.getFormInputValue($startForm, CONSTANTS.FORM_DOB_NAME),
                selectedDate = new Date(value),
                birthDateDay = selectedDate.getDate(),
                birthDateMonth = selectedDate.getMonth(),
                birthDateYear = selectedDate.getFullYear(),
                minimumDate = new Date(birthDateYear + 62, birthDateMonth, birthDateDay);

            return minimumDate <= new Date();
        };

        var setLse = function (lseFlag) {
            var lse;

            if (!lseFlag) return null;

            lse = {
                Tank: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_TANK_RESPIRATOR_ID
                ),
                Curass: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_CUIRASS_RESPIRATOR_ID
                ),
                RockingBed: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_ROCKING_RESPIRATOR_ID
                ),
                ElecOperated: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_ELECTRICAL_RESPIRATOR_ID
                ),
                Apnea: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_APNEA_MONITOR_ID
                ),
                Herneo: query.getCheckboxInputValueById(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_HEMODIALYSIS_ID
                ),
                Other: query.getFormInputValue(
                    $lifeSupportStepSelector,
                    CONSTANTS.FORM_LS_OTHER_NAME
                )
            };

            return lse;
        };

        var setDisability = function (disabilityFlag, delegateFlag) {
            if (!disabilityFlag) return null;

            var disability;

            if (coned.utils.isOru()) {
                disability = {
                    OverAge62Alone:
                        query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_ORU_DISABILITY_AGE_62_ALONE_NAME
                        ) === CONSTANTS.RADIO_YES_OPTION
                            ? true
                            : false,
                    MembersOver62Under18:
                        query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_ORU_DISABILITY_MEMBERS_62_OVER_18_UNDER
                        ) === CONSTANTS.RADIO_YES_OPTION
                            ? true
                            : false,
                    DisabledOrBlind:
                        query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_ORU_DISABILITY_DISABLED_BLIND
                        ) === CONSTANTS.RADIO_YES_OPTION
                            ? true
                            : false
                };
            } else {
                var delegate = null;

                if (delegateFlag) {
                    delegate = {
                        Name: query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_DISABILITY_FIRST_NAME_NAME
                        ),
                        LastName: query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_DISABILITY_LAST_NAME_NAME
                        ),
                        Street: query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_DISABILITY_STREET_ADDRESS_NAME
                        ),
                        City: query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_DISABILITY_CITY_NAME
                        ),
                        State: query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_DISABILITY_STATE_NAME
                        ),
                        ZipCode: query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_DISABILITY_ZIPCODE_NAME
                        ),
                        DayPhone: query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_DISABILITY_DAY_TIME_NUMBER_NAME
                        ),
                        NightPhone: query.getFormInputValue(
                            $startForm,
                            CONSTANTS.FORM_DISABILITY_NIGHT_TIME_NUMBER_NAME
                        )
                    };
                }

                disability = {
                    OverAge62: query.getCheckboxInputValueById(
                        $startForm,
                        CONSTANTS.FORM_DISABILITY_AGE_62_ID
                    ),
                    IsBlind: query.getCheckboxInputValueById(
                        $startForm,
                        CONSTANTS.FORM_DISABILITY_BLIND_ID
                    ),
                    IsPermDisability: query.getCheckboxInputValueById(
                        $startForm,
                        CONSTANTS.FORM_DISABILITY_PERMANENTLY_DISABLED_ID
                    ),
                    Delegate: delegate
                };
            }

            return disability;
        };

        var successStartServiceCallback = function () {
            var selectedDate = new Date($datePickerInput.value),
                dataLayerObj = { event: CONSTANTS.TAGGING_CONED_FORM_SUCCESS };

            if(_isEletricityColdAmi) {
                var startDateFormat = $.datepicker.formatDate('MM dd, yy', new Date($datePickerInput.value));
                _.each($electricityColdAmiSuccessMessage, function ($hasElectricityColdAmiItem) {
                    $hasElectricityColdAmiItem.innerHTML = $hasElectricityColdAmiItem.innerHTML.replace(CONSTANTS.START_DATE_PLACEHOLDER, startDateFormat);
                });
            }

            $stepsList.style.display = 'none';
            $startServiceForm.style.display = 'none';
            
            $finishStep.classList.add(CONSTANTS.STEP_FINISH_ACTIVE);
            $progressBar.classList.add(CONSTANTS.FINISH_BAR_CLASS);
            query.removeClass($finishStepSelector, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($startServiceFinishMain, CONSTANTS.HIDDEN_CLASS);
            $startServiceFinishMain.focus();

            // Analytics data building - success
            if (($mustBackdate || $ableToBackdate) && !_isComercial) {
                if (selectedDate > new Date()) {
                    dataLayerObj.startServiceFuturedate = $.datepicker.formatDate(
                        'MM dd, yy',
                        selectedDate
                    );
                    dataLayerObj.startServiceFuturedateComplete = 'True';
                    dataLayerObj.UserAccountStatus = _isInactiveBackdate
                        ? CONSTANTS.TAGGING_INACTIVE
                        : CONSTANTS.TAGGING_ACTIVE;

                    if (_maid) {
                        dataLayerObj.maid = _maid;
                    }
                } else if (selectedDate < new Date()) {
                    dataLayerObj.startServiceBackdate = $.datepicker.formatDate(
                        'MM dd, yy',
                        selectedDate
                    );
                    dataLayerObj.startServiceBackdateComplete = 'True';
                    dataLayerObj.UserAccountStatus = _isInactiveBackdate
                        ? CONSTANTS.TAGGING_INACTIVE
                        : CONSTANTS.TAGGING_ACTIVE;

                    if (_maid) {
                        dataLayerObj.maid = _maid;
                    }
                }
            }

            dataLayer.push(dataLayerObj);

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($startService);
        };

        var errorStartServiceCallback = function () {
            if (coned.utils.isPatternLab()) {
                var startService = coned.plConstants.DELETE_STOP_SERVICE_ERROR;
                query.getData(startService, errorStartService, function () {});
            } else {
                errorStartService(_data);
            }
        };

        var errorStartService = function (data) {
            var $msgTextSelector = $startServiceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $startServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $startServiceError.focus();
        };

        var validateAccountProfileFields = function () {
            var email = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_EMAIL_NAME
                ),
                phoneType = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_TYPE_NAME
                ),
                phoneNumber = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_PHONE_NUMBER_NAME
                ),
                lifeSupport = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_LIFE_SUPPORT_NAME
                ),
                disability = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_DISABILITY_NAME
                ),
                validEmail = email && email.trim() !== '',
                validPhoneType = phoneType && phoneType.trim() !== '',
                validPhoneNumber = phoneNumber && phoneNumber.trim() !== '',
                validLifeSupport = lifeSupport && lifeSupport.trim() !== '',
                validDisability = disability && disability.trim() !== '',
                mailingName = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_NAME_NAME
                ),
                mailingStreet = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_STREET_NAME
                ),
                mailingTown = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_TOWN_NAME
                ),
                mailingState = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_STATE_NAME_USA
                ),
                mailingZipCode = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_ZIPCODE_NAME
                ),
                validMailingName = mailingName && mailingName.trim() !== '',
                validMailingStreet = mailingStreet && mailingStreet.trim() !== '',
                validMailingTown = mailingTown && mailingTown.trim() !== '',
                validMailingState = mailingState && mailingState.trim() !== '',
                validMailingZipCode = mailingZipCode && mailingZipCode.trim() !== '',
                // ORU
                mailingAddressOne = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_ADDRESS_ONE_NAME
                ),
                mailingAddressTwo = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_ADDRESS_TWO_NAME
                ),
                mailingCountry = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_COUNTRY_NAME
                ),
                mailingCity = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_CITY_NAME
                ),
                mailingCanadaState = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_STATE_NAME_CANADA
                ),
                mailingCanadaZipCode = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_MAILING_CANADA_ZIPCODE_NAME
                ),
                validMailingAddressOne = mailingAddressOne && mailingAddressOne.trim() !== '',
                validMailingAddressTwo = true,
                validMailingCountry = mailingCountry && mailingCountry.trim() !== '',
                validMailingCity = mailingCity && mailingCity.trim() !== '',
                validMailingCanadaState = mailingCanadaState && mailingCanadaState.trim() !== '',
                validMailingCanadaZipCode =
                    mailingCanadaZipCode && mailingCanadaZipCode.trim() !== '',
                age62OverAlone = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_ORU_DISABILITY_AGE_62_ALONE_NAME
                ),
                members62Over18Under = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_ORU_DISABILITY_MEMBERS_62_OVER_18_UNDER
                ),
                disabledOrBlind = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_ORU_DISABILITY_DISABLED_BLIND
                ),
                bopaAcknowledgement = query.getFormInputValue(
                    $accountProfileStepSelector,
                    CONSTANTS.FORM_BOPA_AWKNOLEDGEMENT_NAME
                ),
                validAge62OverAlone = age62OverAlone && age62OverAlone.trim() !== '',
                validMembers62Over18Under =
                    members62Over18Under && members62Over18Under.trim() !== '',
                validDisabledOrBlind = disabledOrBlind && disabledOrBlind.trim() !== '',
                validBopaAcknowledgement = bopaAcknowledgement && bopaAcknowledgement.trim() !== '',
                validMailingZip = false,
                isMailingStateValid = false;

            $mailingAddressTwoInput = $accountProfileStepSelector.querySelector(
                '[name="' + CONSTANTS.FORM_MAILING_ADDRESS_TWO_NAME + '"]'
            );

            if ($mailingAddressTwoInput && $mailingAddressTwoInput.required) {
                validMailingAddressTwo = mailingAddressTwo && mailingAddressTwo.trim() !== '';
            } else {
                validMailingAddressTwo = true;
            }

            if (_mailingCheckboxOff) {
                if (coned.utils.isOru()) {
                    if (
                        validEmail &&
                        validPhoneType &&
                        validPhoneNumber &&
                        validAge62OverAlone &&
                        validMembers62Over18Under &&
                        validDisabledOrBlind
                    ) {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = false;
                            }
                        );

                        formStatus();
                    } else {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = true;
                            }
                        );
                        formStatus(true);
                    }
                } else if (_isComercial) {
                    if (
                        validEmail &&
                        validPhoneType &&
                        validPhoneNumber &&
                        validBopaAcknowledgement
                    ) {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = false;
                            }
                        );

                        formStatus();
                    } else {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = true;
                            }
                        );
                        formStatus(true);
                    }
                } else {
                    if (
                        validEmail &&
                        validPhoneType &&
                        validPhoneNumber &&
                        validLifeSupport &&
                        validDisability
                    ) {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = false;
                            }
                        );

                        formStatus();
                    } else {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = true;
                            }
                        );
                        formStatus(true);
                    }
                }
            } else {
                // take in count other inputs
                if (coned.utils.isOru()) {
                    if (mailingCountry.toLowerCase() == 'canada') {
                        validMailingZip = validMailingCanadaZipCode;
                        isMailingStateValid = validMailingCanadaState;
                    } else if (mailingCountry.toLowerCase() == 'usa') {
                        validMailingZip = validMailingZipCode;
                        isMailingStateValid = validMailingState;
                    } else {
                        validMailingZip = true;
                        isMailingStateValid = true;
                    }

                    if (
                        validEmail &&
                        validPhoneType &&
                        validPhoneNumber &&
                        validAge62OverAlone &&
                        validMembers62Over18Under &&
                        validDisabledOrBlind &&
                        validMailingAddressOne &&
                        validMailingAddressTwo &&
                        validMailingCountry &&
                        validMailingCity &&
                        isMailingStateValid &&
                        validMailingZip
                    ) {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = false;
                            }
                        );
                        formStatus();
                    } else {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = true;
                            }
                        );
                        formStatus(true);
                    }
                } else if (_isComercial) {
                    if (
                        validEmail &&
                        validPhoneType &&
                        validPhoneNumber &&
                        validBopaAcknowledgement &&
                        validMailingName &&
                        validMailingStreet &&
                        validMailingTown &&
                        validMailingState &&
                        validMailingZipCode
                    ) {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = false;
                            }
                        );
                        formStatus();
                    } else {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = true;
                            }
                        );
                        formStatus(true);
                    }
                } else {
                    if (
                        validEmail &&
                        validPhoneType &&
                        validPhoneNumber &&
                        validLifeSupport &&
                        validDisability &&
                        validMailingName &&
                        validMailingStreet &&
                        validMailingTown &&
                        validMailingState &&
                        validMailingZipCode
                    ) {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = false;
                            }
                        );
                        formStatus();
                    } else {
                        Array.prototype.forEach.call(
                            $profileSubmitButtons,
                            function ($profileSubmitButton) {
                                $profileSubmitButton.disabled = true;
                            }
                        );
                        formStatus(true);
                    }
                }
            }
        };

        var accountProfileValidation = function () {
            // Add event to email input
            $email.addEventListener(
                CONSTANTS.INPUT_EVENT,
                function () {
                    validateAccountProfileFields();
                },
                false
            );

            // phone type
            $phoneTypeSelect.addEventListener(
                CONSTANTS.CHANGE_EVENT,
                function (event) {
                    var $phoneSelector = event.currentTarget,
                        phoneValue = $phoneSelector.value;

                    validateAccountProfileFields();

                    // Tagging
                    dataLayer.push({
                        event: CONSTANTS.TAGGING_PHONE_TYPE,
                        phone: phoneValue
                    });
                },
                false
            );

            // Add event to phone input
            $phone.addEventListener(
                CONSTANTS.INPUT_EVENT,
                function () {
                    validateAccountProfileFields();
                },
                false
            );

            $mailingCheckbox.addEventListener(
                CONSTANTS.CHANGE_EVENT,
                function (event) {
                    var $checkbox = event.target;
                    _mailingCheckboxOff = $checkbox.checked;
                    validateAccountProfileFields();
                },
                false
            );

            // radio options
            coned.utils.addParentListener(
                $accountProfileStepSelector,
                [CONSTANTS.CHANGE_EVENT],
                CONSTANTS.CONED_RADIO_SELECTOR,
                validateAccountProfileFields
            );
            // mailing options
            coned.utils.addParentListener(
                $accountProfileStepSelector,
                [CONSTANTS.INPUT_EVENT],
                CONSTANTS.MAILING_INPUT_OPTIONS,
                validateAccountProfileFields
            );
        };

        // Handles the Next Step Continue button activation
        var depositPaymentValidation = function () {
            var selectedRadio = query.getFormInputValue(
                $depositPaymentStepSelector,
                CONSTANTS.FORM_NID_PAYMENT_METHOD_NAME
            );

            if (selectedRadio === CONSTANTS.DEPOSIT_MAIL_BILL) {
                $depositPaymentSubmit.disabled = false;
            } else {
                var validator = $(CONSTANTS.START_FORM).validate(),
                    isDepositRoutingValid = validator.check($depositRouting),
                    isDepositAccountValid = validator.check($depositAccount);

                if (isDepositAccountValid && isDepositRoutingValid) {
                    $depositPaymentSubmit.disabled = false;
                }
            }
        };

        // Tagging
        var taggingPaymentMethod = function (event) {
            var data = {};

            if (event.type === CONSTANTS.STEP_ERROR_EVENT) {
                data.event = CONSTANTS.STEP_3D_ERROR_TAG;
            } else if (event.type === CONSTANTS.STEP_SUCCESS_EVENT) {
                var selectedValue = query.getFormInputValue(
                    $depositPaymentStepSelector,
                    CONSTANTS.FORM_NID_PAYMENT_METHOD_NAME
                );

                data.event = CONSTANTS.STEP_3D_SUCCESS_TAG;
                data.paymentMethod =
                    selectedValue == CONSTANTS.DEPOSIT_BANK_CHECKING ||
                    selectedValue == CONSTANTS.DEPOSIT_BANK_SAVINGS
                        ? CONSTANTS.DEPOSIT_CHECK_TAG
                        : '';
            }

            dataLayer.push(data);
        };

        var taggingAccountNumber = function () {
            if ($($depositAccount).valid()) {
                dataLayer.push({
                    event: CONSTANTS.ACCOUNT_NUMBER_TAG
                });
            }
        };

        var taggingRoutingAba = function () {
            if ($($depositRouting).valid()) {
                dataLayer.push({
                    event: CONSTANTS.ROUTING_ABA_TAG
                });
            }
        };

        var addStartDatePickerHandlers = function () {
            var $pickerElement = $(CONSTANTS.DATE_PICKER_INPUT_CLASS);
            $pickerElement.on('change', function () {
                verifyDate(this);
            });
        };

        var enableNextStep = function () {
            var $currentStep = document.getElementsByClassName(CONSTANTS.NAV_CURRENT_STEP)[0],
                identification = '';
            
            if (_isComercial) {
                identification =
                    $selectAuthCommercial.options[$selectAuthCommercial.selectedIndex].value;
            } else {
                identification =
                    $identificationSelect.options[$identificationSelect.selectedIndex].value;
            }

            if (
                $($startForm).validate().checkForm() &&
                ($confirmIdentityButton.dataset.validIdentity === CONSTANTS.VALID ||
                    identification === CONSTANTS.NO_IDENTIFICATION ||
                    identification === CONSTANTS.BUSINESS_NAME ||
                    identification === CONSTANTS.FULL_NAME)
            ) {
                $confirmIdentityButton.disabled = false;
                $currentStep.classList.remove(CONSTANTS.IS_FORM_BLOCKED);

                var zeroDeposit = $startForm.dataset.zeroDeposit === "true" ? true : false;

                if(coned.utils.isOru() && zeroDeposit && !checkOver62()) {
                    $confirmIdentityButton.disabled = true;
                    $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
                }

            } else {
                $confirmIdentityButton.disabled = true;
                $currentStep.classList.add(CONSTANTS.IS_FORM_BLOCKED);
            }
        };

        var checkRecaptcha = function () {
            //Disable find address button
            $lookupButton.disabled = true;

            // Remove class to formLoading to display animation
            if ($formLoading.classList.contains(CONSTANTS.HIDDEN_FORM_LOADING)) {
                $formLoading.classList.remove(CONSTANTS.HIDDEN_FORM_LOADING);
            }
            if (_hasCaptcha && recaptcha && recaptcha.getResponse() === '') {
                recaptcha.checkRecaptcha();
            } else if (_hasCaptcha && recaptcha && recaptcha.getResponse() !== '') {
                // Reset the verify code and the iframe
                recaptcha.reset();

                // Interval to check if recaptcha iframe exists
                var checkExist = setInterval(function () {
                    iframe = document.querySelector(
                        'iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]'
                    );
                    if (iframe) {
                        createObserverRecaptcha();
                        clearInterval(checkExist);
                    }
                }, 100);
                recaptcha.checkRecaptcha();
            }
        };

        var addressChangeReload = function () {
            $pageLoading.classList.remove(CONSTANTS.PAGE_LOADING_HIDDEN);
            setTimeout(function () {
                location.reload();
            }, 1500);
           
        }

        var initializeData = function () {
            $startForm = document.getElementsByClassName(CONSTANTS.START_SERVICE)[0];
            $startServiceForm = document.getElementsByClassName(
                CONSTANTS.START_FORM_CLASS
            )[0];
            $addressLookupForm = $startForm.getElementsByClassName(
                CONSTANTS.ADDRESS_LOOKUP_FORM
            )[0];
            $autocompleteZipcodeInput = $startForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_ZIPCODE
            )[0];
            $autocompleteAddressInput = $startForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_ADDRESS
            )[0];
            $autocompleteAparmentUnitInput = $startForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_APARMENT_UNIT
            )[0];
            $autocompleteAddressSelectedDetail = $addressLookupForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_SELECTED_ADDRESS_DETAIL
            )[0];
            $nextButtons = $startForm.getElementsByClassName(CONSTANTS.NEXT_BUTTON);
            $nextMainButtons = $startForm.getElementsByClassName(CONSTANTS.NEXT_MAIN_BUTTON);
            $stepsList = document.getElementsByClassName(CONSTANTS.STEPS_LIST)[0];
            $finishStep = document.getElementsByClassName(CONSTANTS.STEP_FINISH)[0];
            $progressBar = document.getElementsByClassName(CONSTANTS.STEP_PROGRESS_BAR)[0];
            $finishStepSelector = document.getElementsByClassName(
                CONSTANTS.FINISH_STEP_SELECTOR
            )[0];
            $datePickerInput = $startForm.getElementsByClassName(CONSTANTS.DATE_PICKER_INPUT)[0];
            $startDateReceptor = $startForm.getElementsByClassName(
                CONSTANTS.START_DATE_RECEPTOR
            )[0];
            $startAddressReceptor = $startForm.getElementsByClassName(
                CONSTANTS.ADDRESS_RECEPTOR
            )[0];
            $name = $startForm.getElementsByClassName(CONSTANTS.NAME)[0];
            $lastName = $startForm.getElementsByClassName(CONSTANTS.LAST_NAME)[0];
            $nameCommercial = $startForm.getElementsByClassName(CONSTANTS.NAME_COMMERCIAL)[0];
            $lastNameCommercial = $startForm.getElementsByClassName(
                CONSTANTS.LAST_NAME_COMMERCIAL
            )[0];
            $business = $startForm.getElementsByClassName(CONSTANTS.BUSINESS_NAME)[0];
            $businessReceptor = $startForm.getElementsByClassName(
                CONSTANTS.BUSINESS_NAME_RECEPTOR
            )[0];
            $nameReceptor = $startForm.getElementsByClassName(CONSTANTS.NAME_RECEPTOR)[0];
            $lastNameReceptors = $startForm.getElementsByClassName(CONSTANTS.LAST_NAME_RECEPTOR);
            $birthDate = $startForm.getElementsByClassName(CONSTANTS.DATE_OF_BIRTH)[0];
            $birthDateReceptor = $startForm.getElementsByClassName(
                CONSTANTS.DATE_OF_BIRTH_RECEPTOR
            )[0];
            $identificationReceptors = $startForm.getElementsByClassName(
                CONSTANTS.IDENTIFICATION_RECEPTOR
            );
            $identificationTitleReceptors = $startForm.getElementsByClassName(
                CONSTANTS.IDENTIFICATION_TITLE_RECEPTOR
            );
            $phoneTitleReceptor = $startForm.getElementsByClassName(
                CONSTANTS.PHONE_TITLE_RECEPTOR
            )[0];
            $email = $startForm.getElementsByClassName(CONSTANTS.EMAIL)[0];
            $emailReceptor = $startForm.getElementsByClassName(CONSTANTS.EMAIL_RECEPTOR)[0];
            $phone = $startForm.getElementsByClassName(CONSTANTS.PHONE)[0];
            $phoneReceptor = $startForm.getElementsByClassName(CONSTANTS.PHONE_RECEPTOR)[0];
            $successEmailReceptor = document.getElementsByClassName(
                CONSTANTS.SUCCESS_EMAIL_RECEPTOR
            )[0];
            $successDateReceptor = document.getElementsByClassName(
                CONSTANTS.SUCCESS_DATE_RECEPTOR
            )[0];
            $editAccountInformation = $startForm.getElementsByClassName(
                CONSTANTS.EDIT_ACCOUNT_INFORMATION
            );
            $accountProfile = document.getElementsByClassName(
                CONSTANTS.ACCOUNT_PROFILE_SELECTOR
            )[0];
            $mailingCheckbox = $startForm.getElementsByClassName(CONSTANTS.MAILING_CHECKBOX)[0];
            $mailingOptionsContainer = $startForm.getElementsByClassName(
                CONSTANTS.MAILING_OPTIONS_CONTAINER
            )[0];
            $mailingOptions = $startForm.getElementsByClassName(CONSTANTS.MAILING_INPUT_OPTIONS);
            $mailingReceptor = $startForm.getElementsByClassName(CONSTANTS.MAILING_RECEPTOR)[0];
            $mailingInputState = $startForm.getElementsByClassName(CONSTANTS.MAILING_STATE)[0];
            $mailingInputStateCanada = $startForm.getElementsByClassName(
                CONSTANTS.MAILING_STATE_CANADA
            )[0];
            $mailingInputTown = $startForm.getElementsByClassName(CONSTANTS.MAILING_TOWN)[0];
            $mailingInputStreet = $startForm.getElementsByClassName(CONSTANTS.MAILING_STREET)[0];
            $mailingInputZipCode = $startForm.getElementsByClassName(CONSTANTS.MAILING_ZIPCODE)[0];
            $mailingInputCanadaZipCode = $startForm.getElementsByClassName(
                CONSTANTS.MAILING_CANADA_ZIPCODE
            )[0];
            $countryDropdown = $startForm.getElementsByClassName(CONSTANTS.COUNTRY_DROPDOWN)[0];
            $otherCountryName = $startForm.getElementsByClassName(CONSTANTS.OTHER_COUNTRY)[0];
            $USAZipcodeSelector = $startForm.getElementsByClassName(
                CONSTANTS.USA_ZIPCODE_SELECTOR
            )[0];
            $canadaZipcodeSelector = $startForm.getElementsByClassName(
                CONSTANTS.CANADA_ZIPCODE_SELECTOR
            )[0];
            $disabilityAge62AloneReceptor = $startForm.getElementsByClassName(
                CONSTANTS.DISABILITY_AGE_62_ALONE_RECEPTOR
            )[0];
            $disabilityMembers62Over18UnderReceptor = $startForm.getElementsByClassName(
                CONSTANTS.DISABILITY_MEMBERS_62_OVER_18_UNDER_RECEPTOR
            )[0];
            $disabilityDisabledBlindReceptor = $startForm.getElementsByClassName(
                CONSTANTS.DISABILITY_DISABLED_BLIND_RECEPTOR
            )[0];
            $noDepositAlert = $startForm.getElementsByClassName(
                CONSTANTS.NO_DEPOSIT_ALERT
            )[0];
            $disabilityRadios = $startForm.getElementsByClassName(
                CONSTANTS.DISABILITY_RADIOS
            );
            $addressStepSelector = $startForm.getElementsByClassName(
                CONSTANTS.ADDRESS_STEP_SELECTOR
            )[0];
            $verifyDateStepSelector = $startForm.getElementsByClassName(
                CONSTANTS.VERIFY_DATE_STEP_SELECTOR
            )[0];
            $serviceIdentityStepSelector = $startForm.getElementsByClassName(
                CONSTANTS.SERVICE_IDENTITY_STEP_SELECTOR
            )[0];
            $accountProfileStepSelector = $startForm.getElementsByClassName(
                CONSTANTS.ACCOUNT_PROFILE_STEP_SELECTOR
            )[0];
            $directPaymentStepSelector = $startForm.getElementsByClassName(
                CONSTANTS.DIRECT_PAYMENT_STEP_SELECTOR
            )[0];
            $depositPaymentStepSelector = $startForm.getElementsByClassName(
                CONSTANTS.DEPOSIT_PAYMENT_STEP_SELECTOR
            )[0];
            $lifeSupportStepSelector = $startForm.getElementsByClassName(
                CONSTANTS.LIFE_SUPPORT_STEP_SELECTOR
            )[0];
            $lifeSupportRadiosSelector = $startForm.getElementsByClassName(
                CONSTANTS.LIFE_SUPPORT_RADIOS_SELECTOR
            );
            $lifeSupportRadioYesSelector = $startForm.getElementsByClassName(
                CONSTANTS.LIFE_SUPPORT_RADIO_YES_SELECTOR
            )[0];
            $lifeSupportRadioErrorSelector = $startForm.getElementsByClassName(
                CONSTANTS.LIFE_SUPPORT_RADIO_ERROR_SELECTOR
            )[0];
            $reviewStepSelector = $startForm.getElementsByClassName(
                CONSTANTS.REVIEW_STEP_SELECTOR
            )[0];
            $accountNumberServiceError = $startForm.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER_ERROR_SELECTOR
            )[0];
            $checkAvailabilityError = document.getElementsByClassName(
                CONSTANTS.CHECK_AVAILABILITY_ERROR
            )[0];
            $startServiceError = $startForm.getElementsByClassName(
                CONSTANTS.START_SERVICE_ERROR_SELECTOR
            )[0];
            $startDateServiceError = $startForm.getElementsByClassName(
                CONSTANTS.START_DATE_ERROR_SELECTOR
            )[0];
            $liveChatAvailable = $startForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_AVAILABLE_SELECTOR
            )[0];
            $liveChatErrorMessage = $startForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_ERROR_MESSAGE
            )[0];
            $liveChatAvailableMessage = $startForm.getElementsByClassName(
                CONSTANTS.LIVE_CHAT_AVAILABLE_MESSAGE
            )[0];
            $formLoading = $startForm.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $lookupButton = document.getElementsByClassName(CONSTANTS.LOOKUP_BUTTON)[0];
            $phoneTypeSelect = $startForm.getElementsByClassName(CONSTANTS.PHONE_TYPE_SELECT)[0];
            $citySelector = $startForm.getElementsByClassName(CONSTANTS.CITY_SELECTOR)[0];
            $profileSubmitButtons = $startForm.getElementsByClassName(
                CONSTANTS.PROFILE_SUBMIT_BUTTON
            );
            $bopaChecklistInfo = $startForm.getElementsByClassName(
                CONSTANTS.BOPA_CHECKLIST_INFO
            )[0];
            $taxInput = $startForm.getElementsByClassName(CONSTANTS.TAX_INPUT_VALUE)[0];
            $authenticationSelector = $startForm.getElementsByClassName(
                CONSTANTS.AUTHENTICATION_SELECTOR
            )[0];
            $formSteps = document.getElementsByClassName(CONSTANTS.FORM_STEP);
            $helpCheckboxSelector = document.getElementsByClassName(
                CONSTANTS.HELP_CHECKBOX_SELECTOR
            );
            $selectAuth = $startForm.getElementsByClassName(CONSTANTS.SELECT_AUTH)[0];
            $selectAuthCommercial = $startForm.getElementsByClassName(
                CONSTANTS.SELECT_AUTH_COMMERCIAL
            )[0];
            $commercialInitialAmount = $startForm.getElementsByClassName(
                CONSTANTS.COMMERCIAL_INITIAL_AMMOUNT
            )[0];
            $commercialInitialTime = $startForm.getElementsByClassName(
                CONSTANTS.COMMERCIAL_INITIAL_TIME
            )[0];
            $identificationSelect = document.getElementsByClassName(
                CONSTANTS.IDENTIFICATION_SELECT
            )[0];
            $captchaElement = $startForm.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT);
            $depositPaymentRadios = $startForm.getElementsByClassName(
                CONSTANTS.DEPOSIT_PAYMENT_RADIOS
            );
            $depositPaymentSubmit = document.getElementsByClassName(
                CONSTANTS.DEPOSIT_PAYMENT_SUBMIT
            )[0];
            $depositPaymentContinueButton = document.getElementsByClassName(
                CONSTANTS.DEPOSIT_PAYMENT_CONTINUE_BUTTON
            )[0];
            $depositAccount = document.getElementsByClassName(
                CONSTANTS.DEPOSIT_ACCOUNT_NUMBER_SELECTOR
            )[0];
            $depositRouting = document.getElementsByClassName(
                CONSTANTS.DEPOSIT_ROUTING_ABA_SELECTOR
            )[0];
            $USADropdownContainer = $startForm.getElementsByClassName(
                CONSTANTS.USA_DROPDOWN_CONTAINER
            )[0];
            $CanadaDropdownContainer = $startForm.getElementsByClassName(
                CONSTANTS.CANADA_DROPDOWN_CONTAINER
            )[0];
            $confirmIdentityButton = $startForm.getElementsByClassName(
                CONSTANTS.IDENTITY_BUTTON
            )[0];
            $itemCheckForm = $startForm.getElementsByClassName(CONSTANTS.ITEM_CHECK_FORM);
            $ableToBackdate = $startForm.getElementsByClassName(
                CONSTANTS.ABLE_TO_BACKDATE_SELECTOR
            )[0];
            $mustBackdate = $startForm.getElementsByClassName(CONSTANTS.MUST_BACKDATE_SELECTOR)[0];
            $addressAverageParagraph = $startForm.getElementsByClassName(
                CONSTANTS.ADDRESS_AVERAGE_PARAGRAPH
            )[0];
            $paragraphAverage = $startForm.getElementsByClassName(
                CONSTANTS.AVERAGE_ELEMENT_SELECTOR
            )[0];
            $radioAge62OverAlone = $startForm.getElementsByClassName(CONSTANTS.RADIO_AGE_62_OVER_ALONE);
            $startServiceFinishMain = $startService.getElementsByClassName(
                CONSTANTS.START_SERVICE_FINISH_MAIN
            )[0];
            $pageLoading = document.getElementsByClassName(CONSTANTS.PAGE_LOADING)[0];
            _liveChatOn = coned.utils.getUrlParameterValue(CONSTANTS.LIVE_CHAT_ON_PARAM);
            _hasCaptcha = $captchaElement.length;
            _mailingCheckboxOff = true;
            _isInactiveBackdate = false;
            _activeSteps = [];
            _isVerifyActive = false;
            _isEletricityColdAmi = $startService.dataset.isEletricityColdAmi;
            _edb = [];
            _edbDepositWaived = false;
            _addressStepCompleted = false;

        };

        var initializeEvents = function () {
            // Validate Account availability
            if (_hasCaptcha) {
                if ($autocompleteAparmentUnitInput) {
                    $autocompleteAparmentUnitInput.addEventListener(
                        'selectedOptionEnd',
                        function () { 
                            if(_addressStepCompleted) {
                                addressChangeReload();
                            } else {
                                checkRecaptcha();
                            }
                        }
                    );
                    $autocompleteAparmentUnitInput.addEventListener('checkAddress', checkRecaptcha);
                    $autocompleteAparmentUnitInput.addEventListener(
                        'checkAddress', 
                        function () { 
                            if(_addressStepCompleted) {
                                addressChangeReload();
                            } else {
                                createObserverRecaptcha();
                            }
                        }
                    );
                }
            } else {                
                if ($autocompleteAparmentUnitInput) {
                    $autocompleteAparmentUnitInput.addEventListener(
                        'selectedOptionEnd',
                        function () { 
                            if(_addressStepCompleted) {
                                addressChangeReload();
                            } else {
                                checkAvailability();
                            }
                        }
                    );
                    $autocompleteAparmentUnitInput.addEventListener(
                        'checkAddress',
                        function () { 
                            if(_addressStepCompleted) {
                                addressChangeReload();
                            } else {
                                checkAvailability();
                            }
                        }
                    );
                }
            }

            // Reload page on address change
            $autocompleteZipcodeInput.addEventListener(
                'input',
                function () { 
                    if(_addressStepCompleted) {
                        addressChangeReload();
                    }
                }
            ); 

            // Reload page on address change
            $autocompleteAddressInput.addEventListener(
                'input',
                function () { 
                    if(_addressStepCompleted) {
                        addressChangeReload();
                    }
                }
            ); 

            // Reload page on address change
            $autocompleteAddressInput.addEventListener(
                'selectedOption',
                function () { 
                    if(_addressStepCompleted) {
                        addressChangeReload();
                    }
                }
            ); 

            // Mailing checkbox
            $mailingCheckbox.addEventListener('change', mailingChange);

            // Check if address should be redirected
            coned.utils.addGeneralListeners($lookupButton, function () {
                if (_lookupRedirect) {
                    dataLayer.push({
                        event: CONSTANTS.TAGGING_CCG_FORM
                    });

                    window.location.href = _lookupRedirect;
                }

                //Once an address is selected and the user move to the next step the _addressStepCompleted flag turns to true
                //Any change on the address information after this will make the page reload to avoid issues on other form sections
                if(!_addressStepCompleted) {
                    _addressStepCompleted = true;
                }
            });

            // Update information
            _.each($nextButtons, function ($nextButton) {
                coned.utils.addGeneralListeners($nextButton, setReviewForm);
            });
            

            // Edit Account Information listener
            _.each($editAccountInformation, function ($editAccountButton) {
                coned.utils.addGeneralListeners($editAccountButton, function (event) {
                    event.preventDefault();

                    window.scrollTo(0, 0);
                    $accountProfile.click();

                    $(CONSTANTS.START_FORM) && $(CONSTANTS.START_FORM).focus();
                });
            });

            // Add country selection change event
            if ($countryDropdown) {
                $countryDropdown.addEventListener('change', countrySelectionEvent);
            }

            // Add LSE radio change event
            if ($lifeSupportRadiosSelector.length > 0) {
                for (var index = 0; index < $lifeSupportRadiosSelector.length; index++) {
                    var $lifeSupportRadioSelector = $lifeSupportRadiosSelector[index];

                    $lifeSupportRadioSelector.addEventListener('change', lifeSupportRadioChange);
                }
            }

            // Disability Radios
            _.each($disabilityRadios, function ($disabilityRadio) {
                coned.utils.addGeneralListeners($disabilityRadio, checkDisability);
            });

            // Verify Date Service call
            addStartDatePickerHandlers();
            
            if ($depositPaymentRadios.length) {
                _.each($depositPaymentRadios, function (radio) {
                    radio.addEventListener('change', changePaymentMethod);
                });
            }

            $depositPaymentContinueButton.addEventListener(
                'click',
                clickDepositPaymentContinue
            );
            coned.utils.addMultipleListeners(
                $depositAccount,
                ['keyup', 'change'],
                depositPaymentValidation
            );
            coned.utils.addMultipleListeners(
                $depositRouting,
                ['keyup', 'change'],
                depositPaymentValidation
            );

            // Tagging
            if ($depositPaymentSubmit) {
                coned.utils.addMultipleListeners(
                    $depositPaymentSubmit,
                    [CONSTANTS.STEP_SUCCESS_EVENT, CONSTANTS.STEP_ERROR_EVENT],
                    taggingPaymentMethod
                );
            }

            $depositAccount.addEventListener('keyup', taggingAccountNumber);
            $depositRouting.addEventListener('keyup', taggingRoutingAba);

            if ($itemCheckForm) {
                // add listener to checkbox to verify if should enable next step
                _.each($itemCheckForm, function (radio) {
                    radio.addEventListener('change', enableNextStep);
                });
            }
            
            
            if ($birthDate) {
                $birthDate.addEventListener('focusout', function() {
                    $(CONSTANTS.BIRTH_DATE).valid();
                });

                // Check on birth date for No ID if the label should show
                $birthDate.addEventListener('keyup', showNoIDDepositMessage);
            }

            // Set tabindex on end screen for a11y
            $startServiceFinishMain.setAttribute(CONSTANTS.TABINDEX, '-1');

            // Start Service Steps Submit Calls
            new coned.components.ValidateForm(CONSTANTS.START_FORM, function () {});

            if (_hasCaptcha) {
                recaptcha = new coned.components.Recaptcha(
                    document.querySelector(CONSTANTS.START_FORM),
                    checkAvailability,
                    checkAvailability
                );
            }

            accountProfileValidation();
        };

        //Check if No Id authentication change on birth date and update the message
        var showNoIDDepositMessage = function () {
            var identification = $identificationSelect.options[$identificationSelect.selectedIndex].value,
                birthDate = $(CONSTANTS.BIRTH_DATE)[0].value,
                validDate = (birthDate !== "" && birthDate.length == 10) ? $(CONSTANTS.BIRTH_DATE).valid() : false,
                validDepositAmount = (coned.utils.isOru() && _depositAmount === CONSTANTS.ZERO_AMOUNT) ? false : true;

            // Add tagging for NoID Deposit Payment
            if (validDate && identification === CONSTANTS.NO_IDENTIFICATION) {
                var $noIdentificationMessage = $startForm.getElementsByClassName(
                        CONSTANTS.NO_IDENTIFICATION
                    )[0],
                    messageContainer = query.selectParentElement(
                        $noIdentificationMessage,
                        CONSTANTS.NO_INPUT
                    );

                if (checkOver62()) {
                    messageContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                } else {
                    messageContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            }

            if(validDepositAmount) {
                return;
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    StartService.prototype.isLoaded = function () {
        return isLoaded;
    };

    return StartService;
})();
