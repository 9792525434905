// ==================== LEAD GEN FORM COMPONENT =========================
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.LeadGenForm = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        LEAD_GEN_FORM: 'coned-lead-gen-form',
        FORM_SELECT: 'js-coned-select',
        FORM_SELECTOR: 'js-lead-gen-form',
        FORM_LOADING: 'js-form-loading',
        FORM_LEAD_LIST: 'js-lead-gen-form-company-list',
        LEAD_CUSTOMER_TYPE: 'js-customer-type',
        LEAD_SERVICE_TYPE: 'js-service-type',
        LEAD_LOCATION: 'js-location',
        CUSTOMER_PARAGRAPH: 'js-customer-paragraph',
        SERVICE_PARAGRAPH: 'js-service-paragraph',
        LOCATION_PARAGRAPH: 'js-location-paragraph',
        FILTER_CUSTOMER: 'customer=',
        FILTER_SERVICE: 'service=',
        FILTER_LOCATION: 'location=',
        LIST_CONTAINER: 'js-company-container',
        LEAD_GEN_CUSTOMER: 'customer',
        LEAD_GEN_SERVICE: 'service',
        LEAD_GEN_LOCATION: 'location',
        LEAD_GEN_SELECT_CUSTOMER: 'js-select-customer',
        LEAD_GEN_SELECT_SERVICE_TYPE: 'js-select-service',
        LEAD_GEN_SELECT_LOCATION: 'js-select-location',
        LEAD_GEN_TABLE_ROW: 'js-table-row',
        LEAD_GEN_SELECT_MENU_SERVICE: 'js-select-menu-service',
        LEAD_GEN_SELECT_MENU_LOCATION: 'js-select-menu-location',
        FALSE: 'false',

        // RESET FORM
        RESET_BUTTON: 'js-reset-form-button',
        INPUT_TEXT_SELECTOR: 'js-coned-input',
        BORDER_ANIMATION_SELECTOR: 'js-border-bar-selector',
        BORDER_ANIMATION_CLASS: 'border-bar--animate',
        INPUT_FILLED_CLASS: 'coned-input--filled',

        // SUBMIT BUTTON
        SUBMIT_BUTTON: 'js-submit-form-button',

        // FAQ BUTTON
        FAQ_BUTTON: 'js-faq-form-button',

        // CHECKBOX
        LEAD_GEN_COMPANY_CHECKBOX: 'js-lead-gen-checkbox',
        LEAD_GEN_COMPANY_LINK: 'js-lead-gen-link',

        // TOAST
        TOAST: 'js-toast',
        SHOW_TOAST: 'toast-show',
        TOAST_EXPANDED: 'js-toast--expanded',

        // STYLES
        LEAD_GEN_FORM_TOP: 'lead-gen-form__top',
        HIDDEN_CLASS: 'hidden',

        //TAGGING
        LEAD_GEN_STEP_1: 'DR Lead Gen Step 1',
        LEAD_GEN_STEP_2: 'DR Lead Gen Step 2',
        LEAD_GEN_STEP_3: 'DR Lead Gen Step 3',
        LEAD_GEN_RESET_STEP: 'DR Lead Gen Reset',
        LEAD_GEN_SUBMIT_STEP: 'DR Lead Gen Submit',
        LEAD_GEN_FAQ_STEP: 'DR Lead Gen FAQs',
        LEAD_GEN_CHECKBOX: 'DR Lead Search Result Checkbox',
        LEAD_GEN_HIPERLINK: 'DR Lead Search Result Hyperlink'
        
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var LeadGenForm = function ($LeadGenForm) {
        /**
         * PRIVATE METHODS
         */
        var $form,
            $leadForm,
            $leadCustomerType,
            $leadServiceType,
            $leadLocation,
            $customerTypeSelect,
            $serviceTypeSelect,
            $locationSelect,
            $completeTable,
            $optionsTableList,
            $filters,
            $customerSelected,
            $serviceSelected,
            $locationSelected,
            $customerParagraph,
            $serviceParagraph,
            $locationParagraph,
            $resetButton,
            $submitButton,
            $faqButton,
            $inputsFilledSelector,
            $toast,
            $selectMenuService,
            $selectMenuLocation,
            $customerDropdown,
            $serviceTypeDropdown,
            $locationDropdown,
            $tableCheckbox,
            $tableLinks,
            _resultsTotal,
            _resultsFilteredList, 
            _resultsList, 
            _newResultsFilteredList;

        /**
         * Performs the first part of the filter to separate the data in the table by criteria and value.
         */
        var filterSelection = function () {
            // Create a list of objects with the data attr of each div in the table
            for (var index = 0; index < $optionsTableList.length; index++) {
                _resultsList[index] = $optionsTableList[index].cloneNode(true);
                _resultsFilteredList[index] = $optionsTableList[index].cloneNode(true);

                var data = {};
                for (var key in _resultsList[index].dataset) {
                    data[coned.utils.toHyphenCase(key)] = _resultsList[index].dataset[key];
                }
                _newResultsFilteredList[index] = {
                    data: data,
                    index: index
                };
            }

            // array with dropdowns option types
            var filtersList = [];

            Array.prototype.forEach.call($filters, function ($filter) {
                if ($filter.value != undefined && $filter.value != '' && $filter.value != 'default') {
                    filtersList.push({
                        criteria: $filter.dataset.filterCriteria,
                        value: $filter.value
                    });
                }
            });

            if (filtersList.length) {
                applyFilter(filtersList);  
            }

            companyTagging();
            showNext();
        };
        
        /**
         * Returns the table with its corresponding data according to the filter.
         * @param {array} filters
         */
        var applyFilter = function (filters) {
            // Filter results by the criteria > value selected

            if (filters.length) {
                // evaluates all elements to check if they contain all filters
                _newResultsFilteredList = _newResultsFilteredList.filter(function ($result) {
                    var filtersIndex, resultValue, resultValues, currentFilter;

                    // checks if element has all current filters
                    for (filtersIndex = 0; filtersIndex < filters.length; filtersIndex++) {
                        currentFilter = filters[filtersIndex];
                        resultValue = $result.data[currentFilter.criteria];

                        // if filter doesn't have criteria or its value is null or empty, then it isn't a candidate
                        if (resultValue) {
                            resultValues = resultValue.toUpperCase().split('|');

                            // if element doesn't contain criteria, then it isn't a candidate
                            if (!resultValues.includes(currentFilter.value.toUpperCase())) {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                    return true;
                });

                _resultsFilteredList = [];
                _newResultsFilteredList.forEach(function ($result) {
                    _resultsFilteredList.push(_resultsList[$result.index]);
                });

                $optionsTableList = _resultsList;
            }

            // Update html with the filtered table
            var filteredList = '',
                filteredListArray = [];

            _resultsFilteredList.forEach(function (_result) {
                filteredListArray.push(_result.outerHTML);
            });

            filteredList = filteredListArray.join('');

            $completeTable.innerHTML = filteredList;
            
        };

        /**
         * Show the next corresponding part of the form that is hidden.
         */
        var showNext = function () {
            $customerSelected = $customerTypeSelect.options[$customerTypeSelect.selectedIndex].text,
            $serviceSelected = $serviceTypeSelect.options[$serviceTypeSelect.selectedIndex].text, 
            $locationSelected = $locationSelect.options[$locationSelect.selectedIndex].text

            if ($customerSelected != 'Customer Type') {
                $selectMenuService.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            if ($serviceSelected != 'Service Type') {
                $selectMenuLocation.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            if ($locationSelected != 'Location') {
                var leadGenForm = document.getElementsByClassName(CONSTANTS.LEAD_GEN_FORM)[0];

                if (coned.utils.isMobile()) {
                    showToast();
                }

                updateDescription();
                $form.classList.add(CONSTANTS.LEAD_GEN_FORM_TOP);
                $leadForm.classList.remove(CONSTANTS.HIDDEN_CLASS);

                new coned.components['FormValidationModule'](leadGenForm);
            }
        }

        /**
         * Displays and updates the dynamic paragraph information according to the selections made in the selects.
         */
        var updateDescription = function () {
            $customerSelected = $customerTypeSelect.options[$customerTypeSelect.selectedIndex].text,
            $serviceSelected = $serviceTypeSelect.options[$serviceTypeSelect.selectedIndex].text, 
            $locationSelected = $locationSelect.options[$locationSelect.selectedIndex].text

            if ($customerSelected != 'Customer Type') {
                $leadCustomerType.innerHTML = $customerSelected;
                $customerParagraph.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
            if ($serviceSelected != 'Service Type') {
                $leadServiceType.innerHTML = $serviceSelected;
                $serviceParagraph.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
            if ($locationSelected != 'Location') {
                $leadLocation.innerHTML = $locationSelected;
                $locationParagraph.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        /**
         * Add the information from the table on the first load.
         */

        var addTable = function () {
            var listContainer = document.getElementsByClassName(CONSTANTS.LIST_CONTAINER)[0],
                formPath;
            
            if (coned.utils.isPatternLab()) {
                formPath = coned.plConstants.PATTERNLAB_LEAD_GEN_COMPANY_LIST;
            }
            
            query.getData(
                formPath,
                function (data) {
                    // Set the leads results content with the data received
                    listContainer.innerHTML = data;
                }
            );
            
        };

        /**
         * Show the toast for a time of 5s.
         */
        var showToast = function () {
            if (!$toast.classList.contains(CONSTANTS.TOAST_EXPANDED)) {
                // Show toast
                $toast.classList.add(CONSTANTS.SHOW_TOAST);
                new coned.components.ToastModule($toast);
            }
        };

        /**
         * Reset the fields of the second form.
         * @param {Event} event
         */
        var resetForm = function (event) {
            event.preventDefault();
            
            $($LeadGenForm).find('form').validate().resetForm();

            var $borderBar;

            for (var inputIndex = 0; inputIndex < $inputsFilledSelector.length; inputIndex++) {
                var $inputFilledSelector = $inputsFilledSelector[inputIndex];

                if ($inputFilledSelector.disabled) continue;

                $borderBar = $inputFilledSelector.parentElement.getElementsByClassName(
                    CONSTANTS.BORDER_ANIMATION_SELECTOR
                )[0];

                query.removeClass($inputFilledSelector, CONSTANTS.INPUT_FILLED_CLASS);
                query.removeClass($inputFilledSelector, CONSTANTS.INPUT_VALID_CLASS);
                $borderBar && query.removeClass($borderBar, CONSTANTS.BORDER_ANIMATION_CLASS);
                $inputFilledSelector.value = '';
            }

            dataLayer.push({
                event: CONSTANTS.LEAD_GEN_RESET_STEP,
                customerType: $customerSelected
            });
        };

        var companyTagging = function () {
            $tableCheckbox = $LeadGenForm.getElementsByClassName(CONSTANTS.LEAD_GEN_COMPANY_CHECKBOX);
            $tableLinks = $LeadGenForm.getElementsByClassName(CONSTANTS.LEAD_GEN_COMPANY_LINK);

            Array.prototype.forEach.call($tableCheckbox, function ($option) {
                coned.utils.addGeneralListeners($option, function(event) {
                    checkboxTagging(event)
                });
            });

            Array.prototype.forEach.call($tableLinks, function ($link) {
                coned.utils.addGeneralListeners($link, function(event) {
                    linkTagging(event)
                });
            });
        }

        var checkboxTagging = function (event) {
            var currentCheckboxName = event.currentTarget.name,
                currentCheckbox = $LeadGenForm.querySelectorAll('[data-name="'+ currentCheckboxName + '"]')[0],
                currentCheckboxCompanyName = currentCheckbox.dataset.company;

                dataLayer.push({
                    event: CONSTANTS.LEAD_GEN_CHECKBOX,
                    customerType: $customerSelected,
                    clickedCompany:  currentCheckboxCompanyName
                });
        };

        var linkTagging = function (event) {
            var currentAnchorName = event.currentTarget.dataset.name, 
                currentCheckbox = $LeadGenForm.querySelectorAll('[data-name="'+ currentAnchorName + '"]')[0],
                currentCheckboxCompanyName = currentCheckbox.dataset.company;

            
                dataLayer.push({
                    event: CONSTANTS.LEAD_GEN_HIPERLINK,
                    customerType: $customerSelected,
                    clickedCompany:  currentCheckboxCompanyName
                });
        };

        var initializeData = function () {
            $leadForm = $LeadGenForm.getElementsByClassName(CONSTANTS.FORM_LEAD_LIST)[0];
            $leadCustomerType = $leadForm.getElementsByClassName(CONSTANTS.LEAD_CUSTOMER_TYPE)[0];
            $leadServiceType = $leadForm.getElementsByClassName(CONSTANTS.LEAD_SERVICE_TYPE)[0];
            $leadLocation = $leadForm.getElementsByClassName(CONSTANTS.LEAD_LOCATION)[0];
            $customerParagraph = $LeadGenForm.getElementsByClassName(CONSTANTS.CUSTOMER_PARAGRAPH)[0];
            $serviceParagraph = $LeadGenForm.getElementsByClassName(CONSTANTS.SERVICE_PARAGRAPH)[0];
            $locationParagraph = $LeadGenForm.getElementsByClassName(CONSTANTS.LOCATION_PARAGRAPH)[0];
            $form = $LeadGenForm.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            $customerTypeSelect = document.getElementsByName(CONSTANTS.LEAD_GEN_CUSTOMER)[0];
            $serviceTypeSelect = document.getElementsByName(CONSTANTS.LEAD_GEN_SERVICE)[0];
            $locationSelect = document.getElementsByName(CONSTANTS.LEAD_GEN_LOCATION)[0];
            $customerDropdown = $LeadGenForm.getElementsByClassName(CONSTANTS.LEAD_GEN_SELECT_CUSTOMER)[0];
            $serviceTypeDropdown = $LeadGenForm.getElementsByClassName(CONSTANTS.LEAD_GEN_SELECT_SERVICE_TYPE)[0];
            $locationDropdown = $LeadGenForm.getElementsByClassName(CONSTANTS.LEAD_GEN_SELECT_LOCATION)[0];
            $customerSelected = $customerTypeSelect.options[$customerTypeSelect.selectedIndex];
            $serviceSelected = $serviceTypeSelect.options[$serviceTypeSelect.selectedIndex]; 
            $locationSelected = $locationSelect.options[$locationSelect.selectedIndex];
            $filters = $LeadGenForm.getElementsByClassName(CONSTANTS.FORM_SELECT);
            $selectMenuService = $LeadGenForm.getElementsByClassName(CONSTANTS.LEAD_GEN_SELECT_MENU_SERVICE)[0];
            $selectMenuLocation = $LeadGenForm.getElementsByClassName(CONSTANTS.LEAD_GEN_SELECT_MENU_LOCATION)[0];
            $toast = $LeadGenForm.getElementsByClassName(CONSTANTS.TOAST)[0];
            $resetButton = $LeadGenForm.getElementsByClassName(CONSTANTS.RESET_BUTTON)[0];
            $inputsFilledSelector = $LeadGenForm.getElementsByClassName(CONSTANTS.INPUT_TEXT_SELECTOR);
            $submitButton = $LeadGenForm.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $faqButton = $LeadGenForm.getElementsByClassName(CONSTANTS.FAQ_BUTTON)[0];
            $completeTable = $LeadGenForm.getElementsByClassName(CONSTANTS.LIST_CONTAINER)[0];
            $optionsTableList = $completeTable.getElementsByClassName(CONSTANTS.LEAD_GEN_TABLE_ROW);

            _resultsTotal = $optionsTableList.length;
            _resultsList = new Array(_resultsTotal);
            _resultsFilteredList = new Array(_resultsTotal);
            _newResultsFilteredList= new Array(_resultsTotal);
        };

        var initializeEvents = function () {
            var dropDownOptions  = $LeadGenForm.getElementsByClassName(CONSTANTS.FORM_SELECT);
            for (var index = 0; index < dropDownOptions.length; index++) {
                dropDownOptions[index].addEventListener('change', filterSelection);
            }

            if ($resetButton !== undefined) {
                coned.utils.addGeneralListeners($resetButton, resetForm);
            }

            if (coned.utils.isPatternLab()) {
                addTable();
            }

            $customerDropdown.addEventListener('change', function() { 
                dataLayer.push({
                    event: CONSTANTS.LEAD_GEN_STEP_1,
                    customerType: $customerSelected
                });
            });
            
            $serviceTypeDropdown.addEventListener('change', function() { 
                dataLayer.push({
                    event: CONSTANTS.LEAD_GEN_STEP_2,
                    serviceType: $serviceSelected
                });
            });

            $locationDropdown.addEventListener('change', function() { 
                dataLayer.push({
                    event: CONSTANTS.LEAD_GEN_STEP_3,
                    location: $locationSelected
                });
            });

            //tagging for the submit button
            $submitButton.addEventListener('click', function() { 
                dataLayer.push({
                    event: CONSTANTS.LEAD_GEN_SUBMIT_STEP,
                    customerType: $customerSelected
                });
            });

            //tagging for the FAQ button
            $faqButton && $faqButton.addEventListener('click', function() { 
                dataLayer.push({
                    event: CONSTANTS.LEAD_GEN_FAQ_STEP, 
                    customerType: $customerSelected
                });
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
     LeadGenForm.prototype.isLoaded = function () {
        return isLoaded;
    };

    return LeadGenForm;
})();
