// ==================== ENERGY USAGE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.EnergyUsage = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ENERGY_USAGE_LINK: 'js-energy-usage-link',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        ENERGY_USAGE_MAID: 'accountMaid',
        HIDDEN_CLASS: 'hidden',
        FORM_LOADING: 'js-form-loading'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var EnergyUsage = function ($energyUsage) {
        /**
         * PRIVATE METHODS
         */
        var $energyLinks,
            $currentEnergyLink,
            $serviceError,
            $serviceErrorMessage,
            $formLoading,
            $newTab,
            _redirectUrl;

        var energyUsageService = function (event) {
            event.preventDefault();

            var serviceUrl = $energyUsage.dataset.serviceUrl,
                params;

            // Set element and value depending on the link clicked
            $currentEnergyLink = event.target;
            _redirectUrl = $currentEnergyLink.dataset.redirectUrl;

            // Hide error
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // If card link is available, open the link in a new tab
            if ($currentEnergyLink.dataset.energyLink === 'true') {
                window.open($currentEnergyLink.href);

                return;
            }

            // Service data
            params = {
                maid: query.getFormInputValue(document, CONSTANTS.ENERGY_USAGE_MAID),
                scId: $energyUsage.dataset.scId
            };

            // Before doing the service call, lets create the new tab where the link will be opened, so it won't be blocked by browser security
            $newTab = window.open();

            // Service call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successEnergyUsageService,
                errorEnergyUsageService,
                params,
                true,
                $formLoading
            );
        };

        var successEnergyUsageService = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.GET_LINK, redirectPage, errorEnergyUsageService);
            } else {
                redirectPage(data);
            }
        };

        var redirectPage = function (data) {
            // Set link and flag for future click
            $currentEnergyLink.dataset.energyLink = 'true';
            $currentEnergyLink.href = _redirectUrl ? _redirectUrl : data.redirectUrl;

            // Set url location in the opened tab
            $newTab.location.href = $currentEnergyLink.href;
        };

        var errorEnergyUsageService = function (data) {
            // Close new tab opened
            $newTab.close();

            // Display error message
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var initializeData = function () {
            $energyLinks = $energyUsage.getElementsByClassName(CONSTANTS.ENERGY_USAGE_LINK);
            $serviceError = $energyUsage.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceErrorMessage = $energyUsage.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
        };

        var initializeEvents = function () {
            for (var index = 0; index < $energyLinks.length; index++) {
                coned.utils.addGeneralListeners($energyLinks[index], energyUsageService);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    EnergyUsage.prototype.isLoaded = function () {
        return isLoaded;
    };
    return EnergyUsage;
})();
