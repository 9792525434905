// ==================== DASHBOARD PAPERLESS EBILL COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardPaperlessEbill = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM: '.js-paperless-ebill-form',
        MANAGE_FORM: '.js-paperless-manage-form',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        ENROLL_FORM_SELECTOR: 'js-paperless-ebill-form',
        MANAGE_FORM_SELECTOR: 'js-paperless-manage-form',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        ICON_STATUS_TEXT: 'icon-status-text',
        ENROLL_EMAIL_CONTAINER: 'js-enroll-email-selector',
        ENROLL_INPUT_EMAIL: 'js-enroll-input-email',
        MANAGE_INPUT_EMAIL: 'js-manage-input-email',
        UNENROLLMENT_BUTTON: 'js-unenroll-button',
        MANAGE_EBILL_MESSAGE: 'js-ebill-email',
        LINK_DESCRIPTION: 'js-link-description',
        UNROLL_SUCCESS_SELECTOR: 'js-paperless-unroll-success',
        UNROLL_SUCCESS_BUTTON: 'js-unenroll-button-confirm',
        DATA_ENROLLED: 'data-enrolled',
        DATA_NOT_ENROLLED: 'data-not-enrolled',
        HIDDEN_CLASS: 'hidden',
        FORM_SCID: 'scId',
        FORM_EMAIL: 'paperlessEnrollEmail',
        FORM_MANAGE_EMAIL: 'paperlessManageEmail',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        HEADER: 'js-header-wrapper',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        UPDATED_MESSAGE: 'js-updated-message'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardPaperlessEbill = function ($ebillForm) {
        /**
         * PRIVATE METHODS
         */
        var $linkAccordionSelector,
            $manageFormSelector,
            $enrollFormSelector,
            $enrollEmailContainer,
            $accordionSelector,
            $manageEbillMessage,
            $unenrollButton,
            $unrollSuccessSelector,
            $unrollSuccessButton,
            $header,
            $formLoading,
            $accountMaidInput,
            $serviceError,
            $serviceErrorMessage,
            $updateMessage,
            $emailInput,
            $iconStatusTextSpan,
            params,
            _isUnenroll,
            _userEmail;

        var setStatement = function () {
            var statementFlag = $accordionSelector.dataset.statement;

            if (statementFlag == 'false') {
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.enroll;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.enroll;
                $ebillForm.classList.add(CONSTANTS.UNENROLLMENT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_NOT_ENROLLED
                );
            } else {
                $enrollFormSelector && $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $manageFormSelector && $manageFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $ebillForm && $ebillForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_ENROLLED
                );
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.manage;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
            }
        };

        var hideError = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var successEbill = function () {
            if (_isUnenroll) {
                $updateMessage && $updateMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $unrollSuccessSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $enrollFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $linkAccordionSelector.click();
            } else {
                var manageEmailMessage = $manageEbillMessage.dataset.message;

                manageEmailMessage = manageEmailMessage.replace('@EMAIL', _userEmail);
                $enrollEmailContainer.innerHTML = manageEmailMessage;
                $emailInput.dataset.currentEmail = _userEmail.toLowerCase();
                $accordionSelector.dataset.statement = 'true';
                $linkAccordionSelector.click();
            }

            setStatement();
            query.scrollToElement($ebillForm, $header);
            hideError()
            $updateMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success',
                contentTitle: 'paperless-ebill'
            });

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($ebillForm);
        };

        var errorEbill = function (data) {
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.textContent = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var submitEnrolling = function (isNewEnroll) {
            var serviceUrl = $ebillForm.dataset.serviceEnrollUrl.toLowerCase();
            _userEmail = isNewEnroll
            ? query.getFormInputValue($ebillForm, CONSTANTS.FORM_EMAIL)
            : query.getFormInputValue($ebillForm, CONSTANTS.FORM_MANAGE_EMAIL);
            _isUnenroll = false;

            params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($ebillForm, CONSTANTS.FORM_SCID),
                Email: _userEmail.toLowerCase()
            };

            hideError();

            params = JSON.stringify(params);
            query.postData(
              serviceUrl,
              successEbill,
              errorEbill,
              params,
              true,
              $formLoading
            );
        };

        var unEnrollEvent = function (event) {
            event.preventDefault();

            $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $unrollSuccessSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $accordionSelector.dataset.statement = 'false';
            query.scrollToElement($ebillForm, $header);
        };

        var submitUnenrolling = function (event) {
            event.preventDefault();
            var serviceUrl = $ebillForm.dataset.serviceUnenrollUrl.toLowerCase();
            _isUnenroll = true;

            params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($ebillForm, CONSTANTS.FORM_SCID)
            };

            params = JSON.stringify(params);
            query.deleteData(
              serviceUrl,
              successEbill,
              errorEbill,
              params,
              true,
              $formLoading
            );
        };

        var initializeData = function () {
            $linkAccordionSelector = $ebillForm.getElementsByClassName(
                CONSTANTS.LINK_ACCORDION_SELECTOR
            )[0];
            $accordionSelector = $ebillForm.getElementsByClassName(CONSTANTS.ACCORDION_SELECTOR)[0];
            $manageFormSelector = $ebillForm.getElementsByClassName(
                CONSTANTS.MANAGE_FORM_SELECTOR
            )[0];
            $enrollFormSelector = $ebillForm.getElementsByClassName(
                CONSTANTS.ENROLL_FORM_SELECTOR
            )[0];
            $enrollEmailContainer = $ebillForm.getElementsByClassName(
                CONSTANTS.ENROLL_EMAIL_CONTAINER
            )[0];
            $manageEbillMessage = $ebillForm.getElementsByClassName(
                CONSTANTS.MANAGE_EBILL_MESSAGE
            )[0];
            $unenrollButton = $ebillForm.getElementsByClassName(CONSTANTS.UNENROLLMENT_BUTTON)[0];
            $unrollSuccessSelector = $ebillForm.getElementsByClassName(
                CONSTANTS.UNROLL_SUCCESS_SELECTOR
            )[0];
            $unrollSuccessButton = $ebillForm.getElementsByClassName(
                CONSTANTS.UNROLL_SUCCESS_BUTTON
            )[0];
            $header = document.getElementsByClassName(CONSTANTS.HEADER)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $serviceError = $ebillForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceErrorMessage = $ebillForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $updateMessage = $ebillForm.getElementsByClassName(CONSTANTS.UPDATED_MESSAGE)[0];
            $emailInput = $ebillForm.getElementsByClassName(CONSTANTS.ENROLL_INPUT_EMAIL)[0];
            $iconStatusTextSpan = $ebillForm.getElementsByClassName(CONSTANTS.ICON_STATUS_TEXT)[0];
        };

        var initializeEvents = function () {
            setStatement();

            new coned.components.ValidateForm(
              CONSTANTS.FORM,
              function() {
                submitEnrolling(true)
              },
              ''
            );
            new coned.components.ValidateForm(
              CONSTANTS.MANAGE_FORM,
              function() {
                submitEnrolling(false)
              },
              ''
            );

            coned.utils.addGeneralListeners($unenrollButton, unEnrollEvent);
            coned.utils.addGeneralListeners($unrollSuccessButton, submitUnenrolling);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardPaperlessEbill.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardPaperlessEbill;
})();
