// ==================== DASHBOARD LEVEL PLAN COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardLevelPlan = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_SELECTOR: 'js-level-plan',
        ENROLL_FORM: 'js-level-plan-form',
        ENROLL_BUTTON_SELECTOR: 'js-enroll-button',
        ENROLL_SELECTOR_GENERIC: 'js-unenroll-',
        PAY_NOW_ENROLL_BUTTON_SELECTOR: 'js-pay-now-enroll-button',
        ENROLL_CONFIRM_SELECTOR: 'js-level-plan-enroll-message',
        MANAGE_FORM_SELECTOR: 'js-level-plan-manage',
        SCENARIO_CLASS: 'js-scenario-page',
        BUDGET_BILLING_CLASS: 'js-budget-billing',
        MANAGE_FORM_CONFIRM_SELECTOR: 'js-level-plan-manage-confirm',
        MANAGE_COMMUNICATION_SELECTOR: 'js-level-plan-communication',
        UNAVAILABLE_FORM_SELECTOR: 'js-level-plan-unavailable',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        LINK_DESCRIPTION: 'js-link-description',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        ALERT_CLASS: 'manage-enroll__list--alert',
        ICON_STATUS_TEXT: 'icon-status-text',
        ENROLLED: 'Enrolled',
        DATA_ENROLLED: 'data-enrolled',
        DATA_WARNING: 'data-warning',
        WARNING: 'Warning',
        HIDDEN_CLASS: 'hidden',
        FORM_SCID: 'scId',
        FORM_UPDATE_ACCOUNT_TYPE: 'LPP',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        FORM_UNENROLL_POSITIVE_SELECTOR: 'js-unenroll-positive',
        FORM_UNENROLL_NEGATIVE_SELECTOR: 'js-unenroll-negative',
        FORM_UNENROLL_ZERO_SELECTOR: 'js-unenroll-zero',
        POSITIVE: 'positive',
        NEGATIVE: 'negative',
        ZERO: 'zero',
        LPP_PARAMETER_NAME: 'lpp',
        BBP_PARAMETER_NAME: 'bbp',

        // Lazy Load
        LAZY_LOAD_CLASS: 'js-lazy-load',
        LAZY_LOAD_TAB_CLASS: 'js-lazy-load-manage-my-account',
        LAZY_LOAD_LOADED_CLASS: 'js-lazy-load-element-loaded',

        // Tagging
        TAGGING_UNENROLL_CONFIRM: 'event.unenroll.confirm'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardLevelPlan = function ($levelPlanForm) {
        /**
         * PRIVATE METHODS
         */
        var $enrollFormSelector,
            $enrollButtonsSelector,
            $enrollConfirmSelector,
            $manageFormSelector,
            $manageFormConfirmSelector,
            $manageCommunicationSelector,
            $unavailableFormSelector,
            $linkAccordionSelector,
            $accordionSelector,
            $serviceError,
            $serviceErrorMessage,
            $unEnrollServiceError,
            $unEnrollServiceErrorMessage,
            $accountMaidInput,
            $formLoading,
            $lazyLoadTab,
            $iconStatusTextSpan,
            _balanceStatus,
            _isRedirect = false,
            _isLazyLoad,
            _isScenarios,
            _isBudgetBilling,
            _serviceUrlUnenroll;

        // Loads all module data from service
        var loadDataServiceCall = function () {
            if (isLoaded) return;

            var serviceUrl = $levelPlanForm.dataset.loadServiceUrl,
                params;

            if (!serviceUrl) {
                initializeData();
                initializeEvents();
                initializeGlobalVariables();
                initUnEnroll();
                return;
            }

            query.getData(serviceUrl, successLoadDataServiceCall, errorLoadDataServiceCall, params);
        };

        // If call succeeded, load markup into module
        var successLoadDataServiceCall = function (data) {
            
            if (coned.utils.isPatternLab()) {
                if(!_isBudgetBilling) {
                    var lppScenario = coned.utils.getUrlParameterValue(CONSTANTS.LPP_PARAMETER_NAME) ? 
                                    coned.utils.getUrlParameterValue(CONSTANTS.LPP_PARAMETER_NAME) :
                                    coned.constants.DEFAULT_SCENARIO;

                    var lppScenarioHTML = coned.plConstants.GET_LPP_SCENARIO_HTML_URL + lppScenario + coned.constants.HTML_EXTENSION;
                        query.getData(
                            lppScenarioHTML,
                            setContentMarkup,
                            errorLoadDataServiceCall
                        );
                    
                    // LPP SCENARIOS
                    // The new way to access this scenarios is with a parameter(lpp) from the My Account Page
                    // Example of url: http://localhost:7788/patterns/04-pages-38-maui-manage-my-account-01-manage-my-account/04-pages-38-maui-manage-my-account-01-manage-my-account.html?lpp=3
                    // SCENARIO #1 : LPP not enrolled - No remaining balance - No solicitation
                    // SCENARIO #2 : LPP not enrolled - Remaining balance - No solicitation
                    // SCENARIO #3 : LPP not eligible
                    // SCENARIO #4: LPP enrolled zero
                    // SCENARIO #5: LPP enrolled negative
                    // SCENARIO #6: LPP enrolled positive
                    // SCENARIO #7: LPP not available
                    // SCENARIO #8: LPP not enrolled - Solicitation
                } else {
                    var bbpScenario = coned.utils.getUrlParameterValue(CONSTANTS.BBP_PARAMETER_NAME) ? 
                                    coned.utils.getUrlParameterValue(CONSTANTS.BBP_PARAMETER_NAME) :
                                    coned.constants.DEFAULT_SCENARIO;

                    var bbpScenarioHTML = coned.plConstants.GET_BUDGET_BILLING_SCENARIO_HTML_URL + bbpScenario + coned.constants.HTML_EXTENSION;
                        query.getData(
                            bbpScenarioHTML,
                            setContentMarkup,
                            errorLoadDataServiceCall
                        );
                    
                    // BUGET BILLING PLAN SCNEARIOS
                    // The new way to access this scenarios is with a parameter(bbp) from the My Account Page
                    // Example of url: http://localhost:7788/patterns/04-pages-38-maui-manage-my-account-01-manage-my-account/04-pages-38-maui-manage-my-account-01-manage-my-account.html?bbp=3
                    // SCENARIO #1 : Budget Billing not enrolled - Solicitation
                    // SCENARIO #2 : Budget Billing not enrolled - No Remaining balance  - No solicitation
                    // SCENARIO #3 : Budget Billing not enrolled - Remaining balance  - No solicitation
                    // SCENARIO #4 : Budget Billing not eligible
                    // SCENARIO #5 : Budget Billing not eligible due to not have enough account history
                    // SCENARIO #6 : Budget Billing not available
                }
            } else {
                setContentMarkup(data);
            }
        };

        // If call errored, hide module
        var errorLoadDataServiceCall = function () {
            $levelPlanForm.classList.add(CONSTANTS.HIDDEN_CLASS);

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $levelPlanForm.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($levelPlanForm, 'lazy-load-element-loaded');
            }
        };

        // Set markup to the tab content
        var setContentMarkup = function (data) {
            if (data) {
                var parser = new DOMParser(),
                    HTMLObject,
                    $levelPlanContent,
                    newLevelPlanAttrs;

                HTMLObject = parser.parseFromString(data, 'text/html');
                $levelPlanContent = HTMLObject.getElementsByClassName(CONSTANTS.FORM_SELECTOR);

                if (
                    $levelPlanContent.length !== 0 &&
                    $levelPlanContent[0].dataset.content !== 'false'
                ) {
                    $levelPlanContent = $levelPlanContent[0];
                } else {
                    errorLoadDataServiceCall();
                    return;
                }

                $levelPlanForm.innerHTML = $levelPlanContent.innerHTML;
                newLevelPlanAttrs = $levelPlanContent.attributes;

                while ($levelPlanForm.attributes.length > 0) {
                    $levelPlanForm.removeAttribute($levelPlanForm.attributes[0].name);
                }

                _.each(newLevelPlanAttrs, function (attribute) {
                    $levelPlanForm.setAttribute(attribute.nodeName, attribute.nodeValue);
                });

                // If data-module is present, initialize modules
                if ($levelPlanContent.dataset.module) {
                    initializeData();
                    initializeEvents();
                    initializeGlobalVariables();

                    if ($manageFormConfirmSelector) {
                        initUnEnroll();
                    }

                    coned.utils.initializeModules($levelPlanForm);
                }

                if (_isScenarios) {
                    coned.utils.triggerEvent($levelPlanForm, 'scenarios-element-loaded');
                }
            } else {
                errorLoadDataServiceCall();
                return;
            }

            isLoaded = true;

            if (_isLazyLoad) {
                // Remove lazy-load listener since everything is loaded already
                $lazyLoadTab.removeEventListener('lazy-load-start', loadDataServiceCall);

                // Adds class so accordion knows this module is already loaded
                $levelPlanForm.classList.add(CONSTANTS.LAZY_LOAD_LOADED_CLASS);

                // Trigger loaded event
                coned.utils.triggerEvent($levelPlanForm, 'lazy-load-element-loaded');
            }
            coned.utils.triggerEvent(window, 'service-loaded');
        };

        var setStatement = function () {
            var statementFlag = $accordionSelector.dataset.statement;

            if (statementFlag == 'false') {
                if($manageFormSelector) { 
                    $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            } else if (statementFlag == 'true') {
                $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $manageFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $levelPlanForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_ENROLLED
                );
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.manage;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
            } else {
                $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $unavailableFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $levelPlanForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
                $iconStatusTextSpan.innerText = $iconStatusTextSpan.getAttribute(
                    CONSTANTS.DATA_WARNING
                );
                $levelPlanForm.classList.add(CONSTANTS.ALERT_CLASS);
            }
        };

        var confirmEnrollEvent = function (event) {
            event.preventDefault();

            if($enrollConfirmSelector) { 
                $enrollConfirmSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
            
            if (!coned.utils.isOru()) {
                $accordionSelector.dataset.statement = 'true';
            }
            $linkAccordionSelector.click();
            setStatement();

            if (_isRedirect) {
                window.location.href = $levelPlanForm.dataset.redirectUrl;
            }
        };
        
        var enrollEvent = function (event) {
            event.preventDefault();

            var serviceUrl = $levelPlanForm.dataset.serviceUrl,
                params;

            _isRedirect = query.hasClass(
                event.currentTarget,
                CONSTANTS.PAY_NOW_ENROLL_BUTTON_SELECTOR
            );

            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            // Service Data
            params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($levelPlanForm, CONSTANTS.FORM_SCID)
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successLevelPlan,
                errorLevelPlan,
                params,
                true,
                $formLoading
            );
        };

        var successLevelPlan = function () {
            // Analytics data building
            var planType = coned.utils.isOru() ? 'budget-payment-plan' : 'level-payment-plan';
            
            dataLayer.push({
                event: 'coned.form.success',
                contentTitle: planType
            });

            $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $enrollConfirmSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $levelPlanForm.classList.remove(CONSTANTS.UNENROLLMENT_CLASS);
            $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;

            $enrollConfirmSelector.focus();

            if (coned.utils.isOru()) {
                // Qualtrics survey triggering
                coned.utils.qualtricsTriggering($levelPlanForm);
            }

        };

        var displayUnEnroll = function ($selectors) {
            _.each($selectors, function ($item) {
                $item.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });
        };

        var unEnrollFormSubmit = function (event) {
            event.preventDefault();
            $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $manageFormConfirmSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $manageFormConfirmSelector.focus();
        };

        var displayUnEnrollCommunication = function () {
            $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $manageFormConfirmSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $manageCommunicationSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);

            $manageCommunicationSelector.focus();
        };

        var successUnEnroll = function () {
            displayUnEnrollCommunication();

            // Tagging
            dataLayer.push({
                event: CONSTANTS.TAGGING_UNENROLL_CONFIRM,
                'unenroll-remaining-balance':
                    $accordionSelector.dataset.unenrollRemainingBalance || '0'
            });
        };

        var errorUnenrollPlan = function (data) {
            $unEnrollServiceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $unEnrollServiceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;

            $unEnrollServiceError.focus();
        };

        var errorLevelPlan = function (data) {
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;

            $serviceError.focus();
        };

        var unEnrollConfirmFormSubmit = function (event) {
            event.preventDefault();

            var params = {
                Maid: $accountMaidInput.value,
                ScId: query.getFormInputValue($levelPlanForm, CONSTANTS.FORM_SCID)
            };

            query.deleteData(
                _serviceUrlUnenroll,
                successUnEnroll,
                errorUnenrollPlan,
                JSON.stringify(params),
                true,
                $formLoading
            );
        };

        var initUnEnroll = function () {
            var $unEnrollSelectors;

            switch (_balanceStatus) {
                case CONSTANTS.POSITIVE:
                    $unEnrollSelectors = CONSTANTS.ENROLL_SELECTOR_GENERIC + CONSTANTS.POSITIVE;
                    break;
                case CONSTANTS.NEGATIVE:
                    $unEnrollSelectors = CONSTANTS.ENROLL_SELECTOR_GENERIC + CONSTANTS.NEGATIVE;
                    break;
                case CONSTANTS.ZERO:
                    $unEnrollSelectors = CONSTANTS.ENROLL_SELECTOR_GENERIC + CONSTANTS.ZERO;
                    break;
                default:
                    $unEnrollSelectors = CONSTANTS.ENROLL_SELECTOR_GENERIC + CONSTANTS.ZERO;
            }

            displayUnEnroll($accordionSelector.getElementsByClassName($unEnrollSelectors));
        };

        // Checks if module should be lazy-loaded of not
        var preInitialize = function () {
            _isLazyLoad = query.hasClass($levelPlanForm, CONSTANTS.LAZY_LOAD_CLASS);
            _isScenarios = query.hasClass($levelPlanForm, CONSTANTS.SCENARIO_CLASS);
            _isBudgetBilling = query.hasClass($levelPlanForm, CONSTANTS.BUDGET_BILLING_CLASS);

            // If module is lazy loaded, wait for event to trigger
            if (_isLazyLoad) {
                $lazyLoadTab = document.getElementsByClassName(CONSTANTS.LAZY_LOAD_TAB_CLASS)[0];

                $lazyLoadTab.addEventListener('lazy-load-start', loadDataServiceCall);
            } else {
                loadDataServiceCall();
            }
        };

        var initializeData = function () {
            $linkAccordionSelector = $levelPlanForm.getElementsByClassName(
                CONSTANTS.LINK_ACCORDION_SELECTOR
            )[0];
            $accordionSelector = $levelPlanForm.getElementsByClassName(
                CONSTANTS.ACCORDION_SELECTOR
            )[0];
            $enrollFormSelector = $levelPlanForm.getElementsByClassName(CONSTANTS.ENROLL_FORM)[0];
            $enrollButtonsSelector = $levelPlanForm.getElementsByClassName(
                CONSTANTS.ENROLL_BUTTON_SELECTOR
            );
            $enrollConfirmSelector = $levelPlanForm.getElementsByClassName(
                CONSTANTS.ENROLL_CONFIRM_SELECTOR
            )[0];
            $manageFormSelector = $levelPlanForm.getElementsByClassName(
                CONSTANTS.MANAGE_FORM_SELECTOR
            )[0];
            $manageFormConfirmSelector = $levelPlanForm.getElementsByClassName(
                CONSTANTS.MANAGE_FORM_CONFIRM_SELECTOR
            )[0];
            $manageCommunicationSelector = $levelPlanForm.getElementsByClassName(
                CONSTANTS.MANAGE_COMMUNICATION_SELECTOR
            )[0];
            $unavailableFormSelector = $levelPlanForm.getElementsByClassName(
                CONSTANTS.UNAVAILABLE_FORM_SELECTOR
            )[0];
            $serviceError = $levelPlanForm.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $serviceErrorMessage = $levelPlanForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $iconStatusTextSpan = $levelPlanForm.getElementsByClassName(
                CONSTANTS.ICON_STATUS_TEXT
            )[0];

            if ($manageFormConfirmSelector) {
                $unEnrollServiceError = $manageFormConfirmSelector.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR
                )[0];
                $unEnrollServiceErrorMessage = $manageFormConfirmSelector.getElementsByClassName(
                    CONSTANTS.SERVICE_ERROR_MESSAGE
                )[0];
            }
        };

        var initializeGlobalVariables = function () {
            _balanceStatus = $accordionSelector.dataset && $accordionSelector.dataset.levelPayment;
            _serviceUrlUnenroll =
                $accordionSelector.dataset && $accordionSelector.dataset.unenrollServiceUrl;
        };

        var initializeEvents = function () {
            setStatement();

            _.each($enrollButtonsSelector, function ($enrollButton) {
                coned.utils.addGeneralListeners($enrollButton, enrollEvent);
            });

            if ($enrollConfirmSelector) {
                $enrollConfirmSelector.addEventListener('submit', confirmEnrollEvent);
            }

            if ($manageFormSelector) {
                $manageFormSelector.addEventListener('submit', unEnrollFormSubmit);
            }

            if ($manageFormConfirmSelector) {
                $manageFormConfirmSelector.addEventListener('submit', unEnrollConfirmFormSubmit);
            }

            // Tagging
            if ($accordionSelector.dataset.levelPaymentScenario) {
                dataLayer.push({
                    'level-payment-scenario': $accordionSelector.dataset.levelPaymentScenario
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            preInitialize();
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardLevelPlan.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardLevelPlan;
})();
