// ==================== ADD ACCOUNT COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.AddAccount = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ADD_ACCOUNT_FORM: '.js-add-account-form',
        ADD_ACCOUNT_FORM_WRAPPER: 'js-add-account-form-wrapper',
        CONFIRM_OWNERSHIP_FORM: '.js-confirm-ownership-form',
        CONFIRM_OWNERSHIP_SECURITY_WORD_OPTIONS: 'js-security-word-option',
        CONFIRM_OWNERSHIP_SUBMIT: 'js-transactional-submit-selector',
        SEND_RECOVERY_CODE_FORM_WRAPPER: 'js-send-recovery-code-form-wrapper',
        SEND_RECOVERY_CODE_FORM: '.js-send-recovery-code-form',
        ENTRY_RECOVERY_CODE_FORM_WRAPPER: 'js-entry-recovery-code-form-wrapper',
        ENTRY_RECOVERY_CODE_FORM: '.js-entry-recovery-code-form',
        ENTRY_SECURITY_WORD_FORM_WRAPPER: 'js-entry-security-word-form-wrapper',
        ENTRY_SECURITY_WORD_FORM: '.js-entry-security-word-form',
        FORGOT_ACCOUNT_NUMBER_LOOKUP_FORM_WRAPPER: 'js-forgot-account-number-lookup-form-wrapper',
        FORGOT_ACCOUNT_NUMBER_LOOKUP_FORM: '.js-forgot-account-number-lookup-form',
        FORGOT_SELECT_ACCOUNT_FORM_WRAPPER: 'js-forgot-select-account-form-wrapper',
        FORGOT_SELECT_ACCOUNT_FORM: '.js-forgot-select-account-form',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        ACCOUNT_NUMBER_CONTAIN: 'js-account-info-contain',
        ACCOUNT_NUMBER_CONTAINER: 'js-account-info-container',
        FORGOT_ACCOUNT_NUMBER_LINK: 'js-forgot-account-number',
        NOT_MY_ACCOUNT_LINK: 'js-not-my-account',
        SELECT_LOOKUP_WITH: 'js-select-account-lookup-width',
        EMAIL_LOOKUP_SELECTOR: 'js-lookup-email-selector',
        PHONE_LOOKUP_SELECTOR: 'js-lookup-phone-selector',
        IGNORE_CLASS: 'js-ignore-validation-class',
        SEND_CODE_AGAIN_LINK: 'js-send-code-again',
        LOOKUP_ACCOUNT_NUMBER_LINK: 'js-lookup-account-number',
        INPUT_CLASS: 'js-coned-input',
        RADIO_INPUT_CLASS: 'js-coned-radio',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        HIDDEN_CLASS: 'hidden',
        REMEMBER_PHONE_OPTION: 'js-phone-option',
        REMEMBER_EMAIL_OPTION: 'js-email-option',
        RADIO_LABEL: 'coned-radio__label-name',
        ADD_ACCOUNT_SITECORE_ID: 'ScId',
        ADD_ACCOUNT_USER_ID: 'UserId',
        ADD_ACCOUNT_ACCOUNT_NUMBER: 'accountNumber',
        ADD_ACCOUNT_CONFIRM_TYPE: 'confirmType',
        ADD_ACCOUNT_VERIFICATION_CODE: 'verificationCode',
        ADD_ACCOUNT_LOOKUP_WITH: 'lookupWith',
        ADD_ACCOUNT_EMAIL_ADDRESS: 'emailAddress',
        ADD_ACCOUNT_PHONE_NUMBER: 'phoneNumber',
        ADD_ACCOUNT_LAST_PAYMENT: 'lastPaymentAmount',
        ADD_ACCOUNT_ERROR: 'js-service-error',
        ADD_ACCOUNT_ERROR_MESSAGE: 'js-error-message',
        MAID_INPUT: 'AddAccountMAID',
        ADD_ACCOUNT: 'AddAccount',
        CUSTOMER_SERVICE: 'js-call-customer-service',
        REGISTER_PROFILE_BUTTON: 'js-register-new-profile',
        NOTIFICATION_ONBOARDING_SELECTOR: 'js-notification-onboarding',
        TURN_ON_EMAIL_NOTIFICATION_EVENT: 'turn-on-email-notification',
        EMAIL_PLACEHOLDER: '{{EMAIL}}',
        TELEPHONE_PLACEHOLDER: '{{TELEPHONE}}',

        // PL only
        NOTIFICATION_ONBOARDING_PARAM: 'notification-onboarding'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var AddAccount = function ($accountForm) {
        /**
         * PRIVATE METHODS
         */
        var $addAccountFormWrapper,
            $confirmOwnershipSecurityOptions,
            $sendRecoveryCodeFormWrapper,
            $entryRecoveryCodeFormWrapper,
            $forgotAccountNumberLookupFormWrapper,
            $forgotSelectAccountFormWrapper,
            $accountNumberContain,
            $accountNumberContainer,
            $notMyAccountLink,
            $selectLookupWith,
            $emailLookupSelector,
            $phoneLookupSelector,
            $forgotAccountNumberLink,
            $lookupAccountNumberLink,
            $sendCodeAgainLink,
            $addAccountError,
            $addAccountErrorMessage,
            $maidInput,
            $submitAnimationButton,
            $phoneOptions,
            $emailOption,
            $callCustomerService,
            $confirmOwnershipSubmitButton,
            $registerProfileButton,
            $notificationOnboardingStep,
            _hasNotificationOnboardingStep,
            _isNYPA,
            _isMaster,
            _data;

        var addAccountFocus = function () {
            $accountForm.focus();
        };

        var addAccountHandler = function () {
            $submitAnimationButton = $addAccountFormWrapper.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                addAccountCall,
                successAddAccountServiceCallback,
                errorServiceCallback,
                false,
                true
            );
        };

        var addAccountCall = function () {
            hideError();

            var params,
                addAccountService = $addAccountFormWrapper.dataset.addAccountService;

            params = {
                ScId: query.getFormInputValue($accountForm, CONSTANTS.ADD_ACCOUNT_SITECORE_ID),
                AddAccountScId: query.getFormInputValue($accountForm, CONSTANTS.ADD_ACCOUNT),
                AccountNumber: query.getFormInputValue(
                    $addAccountFormWrapper,
                    CONSTANTS.ADD_ACCOUNT_ACCOUNT_NUMBER
                )
            };

            params = JSON.stringify(params);
            query.postData(addAccountService, successCallback, errorCallback, params, true);
        };

        var successCallback = function (data) {
            _data = data;

            $submitAnimationButton.classList.add(
                coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
            );
        };

        /**
         * Sets the conditions to either show the notification onboarding step or redirect
         * @param {Object} data Object containing the response from the service call.
         */
        var successFinalStepCallback = function (data) {
            _data = data;

            if (_hasNotificationOnboardingStep && !_isNYPA && !_isMaster) {
                $submitAnimationButton.classList.add(
                    coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                );
                coned.utils.triggerEvent($notificationOnboardingStep, CONSTANTS.TURN_ON_EMAIL_NOTIFICATION_EVENT);
            } else {
                successEntryRecoveryCodeCallback();
            }
        };

        var errorCallback = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;

            if (coned.utils.isPatternLab()) {
                _data.errorMsg =
                    'To manage more than 750 accounts, please <a class="transactional__error-link" href="http://localhost:7788/patterns/04-pages-10-transactional-register-00-pre-register/04-pages-10-transactional-register-00-pre-register.html">register a new profile.</a>';
            }
        };

        var successAddAccountServiceCallback = function () {
            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step02'
            });

            $addAccountFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            $sendRecoveryCodeFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            addAccountFocus();

            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step05'
            });

            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/add-account/confirm-account-ownership'
            });

            _.each($accountNumberContainer, function ($infoContainer) {
                $infoContainer.innerHTML = $accountNumberContain.value;
            });

            // Set Account Maid, NYPA and Master flags
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_ACCOUNT_MAID, 
                    function (data) {
                        setAccountMaid(data);
                        setNypaFlag(data);
                        setMasterFlag(data);
                    }, 
                    function () {}
                );
            } else {
                setAccountMaid(_data);
                setNypaFlag(_data);
                setMasterFlag(_data);
            }
        };

        var setEmailRadioLabel = function ($emailOption, userEmail) {
            var $emailRadio = $emailOption.getElementsByClassName(CONSTANTS.RADIO_INPUT_CLASS)[0],
                $emailLabel = $emailOption.getElementsByClassName(CONSTANTS.RADIO_LABEL)[0];
      
            // Reset radio text before updating it
            $emailLabel.textContent = $emailOption.dataset.placeholder;
            $emailLabel.textContent = $emailOption.dataset.placeholder.replace(
              CONSTANTS.EMAIL_PLACEHOLDER, 
              userEmail
            );

            // Reset radio input
            $emailRadio.checked = false;
            $emailOption.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var setPhoneRadioLabel = function ($phoneOption, userPhoneNumber) {
            var $phoneRadio = $phoneOption.getElementsByClassName(CONSTANTS.RADIO_INPUT_CLASS)[0],
                $phoneLabel = $phoneOption.getElementsByClassName(CONSTANTS.RADIO_LABEL)[0];

              // Reset radio text before updating it
              $phoneLabel.textContent = $phoneOption.dataset.placeholder;
              $phoneLabel.textContent = $phoneOption.dataset.placeholder.replace(
                CONSTANTS.TELEPHONE_PLACEHOLDER,
                userPhoneNumber
              );

              // Reset radio input
              $phoneRadio.checked = false;
              $phoneOption.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var setAccountMaid = function (data) {
            var userPhoneNumber = data.PhoneNumber,
                userEmail = data.Email;

            if (!userEmail) {
                $emailOption.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                setEmailRadioLabel($emailOption, userEmail);
            }

            if (!userPhoneNumber) {
                _.each($phoneOptions, function ($phoneOption) {
                    $phoneOption.classList.add(CONSTANTS.HIDDEN_CLASS);
                });
            } else {
                _.each($phoneOptions, function ($phoneOption) {
                    setPhoneRadioLabel($phoneOption, userPhoneNumber);
                });
            }

            if (!userEmail && !userPhoneNumber) {
                $callCustomerService.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $callCustomerService.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            $maidInput.value = data.MAID;

            // Disable submit until option is selected
            $confirmOwnershipSubmitButton.disabled = true;
        };

        /**
         * Set NYPA account flag
         * @param {Object} data Object containing the response from the service call.
         */
        var setNypaFlag = function (data) {
            _isNYPA = data.IsNYPA;
        };

        /**
         * Set Master account flag
         * @param {Object} data Object containing the response from the service call.
         */
        var setMasterFlag = function (data) {
            _isMaster = data.IsMasterAccount;
        };

        var confirmOwnershipEvent = function () {
            $confirmOwnershipSubmitButton.disabled = false;
        };

        var sendRecoveryCodeHandler = function () {
            $submitAnimationButton = $sendRecoveryCodeFormWrapper.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                sendRecoveryCodeCall,
                successSendRecoveryCodeCallback,
                errorServiceCallback,
                false,
                true
            );
        };

        var sendRecoveryCodeCall = function () {
            hideError();

            var params,
                sendRecoveryCodeService =
                    $sendRecoveryCodeFormWrapper.dataset.sendRecoveryCodeService,
                confirmType = query.getFormInputValue(
                    $sendRecoveryCodeFormWrapper,
                    CONSTANTS.ADD_ACCOUNT_CONFIRM_TYPE
                );

            params = {
                ScId: query.getFormInputValue($accountForm, CONSTANTS.ADD_ACCOUNT_SITECORE_ID),
                AddAccountScId: query.getFormInputValue($accountForm, CONSTANTS.ADD_ACCOUNT),
                ConfirmType: confirmType,
                MAID: query.getFormInputValue($accountForm, CONSTANTS.MAID_INPUT)
            };

            // Analytics data building
            dataLayer.push({
                'confirmation.method': confirmType
            });

            params = JSON.stringify(params);
            query.postData(sendRecoveryCodeService, successCallback, errorCallback, params, true);
        };

        var successSendRecoveryCodeCallback = function () {
            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step06'
            });

            $sendRecoveryCodeFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            $entryRecoveryCodeFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            addAccountFocus();

            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step07'
            });

            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/add-account/enter-confirmation-code'
            });
        };

        var entryRecoveryCodeHandler = function () {
            var successEntryCodeCallback = 
                _hasNotificationOnboardingStep ? 
                    showNotificationOnboardingStep :
                    function () {};

            $submitAnimationButton = $entryRecoveryCodeFormWrapper.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                entryRecoveryCodeCall,
                successEntryCodeCallback,
                errorServiceCallback,
                false,
                true
            );
        };

        var entryRecoveryCodeCall = function () {
            hideError();

            var params,
                entryRecoveryCodeService =
                    $entryRecoveryCodeFormWrapper.dataset.entryRecoveryCodeService;

            params = {
                ScId: query.getFormInputValue($accountForm, CONSTANTS.ADD_ACCOUNT_SITECORE_ID),
                UserId: query.getFormInputValue($accountForm, CONSTANTS.ADD_ACCOUNT_USER_ID),
                AddAccountScId: query.getFormInputValue($accountForm, CONSTANTS.ADD_ACCOUNT),
                SecurityCode: query.getFormInputValue(
                    $entryRecoveryCodeFormWrapper,
                    CONSTANTS.ADD_ACCOUNT_VERIFICATION_CODE
                ),
                MAID: query.getFormInputValue($accountForm, CONSTANTS.MAID_INPUT)
            };

            params = JSON.stringify(params);
            query.postData(entryRecoveryCodeService, successFinalStepCallback, errorCallback, params, true);
        };

        var successEntryRecoveryCodeCallback = function () {
            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step08'
            });

            // Set page redirect
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.ADD_ACCOUNT_REDIRECT,
                    dashboardRedirect,
                    function () {}
                );
            } else {
                dashboardRedirect(_data);
            }
        };

        var dashboardRedirect = function (data) {
            // Analytics data building
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/manually-add-account/dashboard-view'
            });

            // dashboard redirect
            window.location.href = data.redirectUrl;
        };

        /**
         * Shows the notification onboarding step
         * This section will only exist for users adding their first account
         */
        var showNotificationOnboardingStep = function () {
            query.addClass($entryRecoveryCodeFormWrapper, CONSTANTS.HIDDEN_CLASS);
            query.removeClass($notificationOnboardingStep, CONSTANTS.HIDDEN_CLASS);
            $notificationOnboardingStep.focus();
        };

        var forgotAccountNumberLookupHandler = function () {
            $submitAnimationButton = $forgotAccountNumberLookupFormWrapper.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                forgotAccountNumberLookupCall,
                successForgotAccountNumberLookupCallback,
                errorServiceCallback,
                false,
                true
            );
        };

        var forgotAccountNumberLookupCall = function () {
            hideError();

            var params,
                forgotAccountNumberLookupService =
                    $forgotAccountNumberLookupFormWrapper.dataset.forgotAccountNumberLookupService,
                value = query.getFormInputValue(
                    $forgotAccountNumberLookupFormWrapper,
                    CONSTANTS.ADD_ACCOUNT_LAST_PAYMENT
                ),
                lastPaymentValue = parseFloat(value.replace(/[^0-9-.]/g, ''));

            params = {
                ScId: query.getFormInputValue($accountForm, CONSTANTS.ADD_ACCOUNT_SITECORE_ID),
                AddAccountScId: query.getFormInputValue($accountForm, CONSTANTS.ADD_ACCOUNT),
                LookupWith: query.getFormInputValue(
                    $forgotAccountNumberLookupFormWrapper,
                    CONSTANTS.ADD_ACCOUNT_LOOKUP_WITH
                ),
                Email: query.getFormInputValue(
                    $forgotAccountNumberLookupFormWrapper,
                    CONSTANTS.ADD_ACCOUNT_EMAIL_ADDRESS
                ),
                Phone: query.getFormInputValue(
                    $forgotAccountNumberLookupFormWrapper,
                    CONSTANTS.ADD_ACCOUNT_PHONE_NUMBER
                ),
                LastPayment: lastPaymentValue
            };

            params = JSON.stringify(params);
            query.postData(
                forgotAccountNumberLookupService,
                successCallback,
                errorCallback,
                params,
                true
            );
        };

        var successForgotAccountNumberLookupCallback = function () {
            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step12a'
            });

            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_ACCOUNT_NUMBER,
                    forgotAccountNumberSetValues,
                    function () {}
                );
                query.getData(
                    coned.plConstants.GET_ACCOUNT_MAID, 
                    function (data) {
                        setAccountMaid(data);
                        setNypaFlag(data);
                        setMasterFlag(data);
                    }, 
                    function () {}
                );
            } else {
                forgotAccountNumberSetValues(_data);
                setAccountMaid(_data);
                setNypaFlag(_data);
                setMasterFlag(_data);
            }
        };

        var forgotAccountNumberSetValues = function (data) {
            hideError();

            var accountNumber = data.AccountNumber,
                accountVisible = accountNumber.substr(-5),
                maskedAccount = '**********' + accountVisible;

            _.each($accountNumberContainer, function ($infoContainer) {
                $infoContainer.innerHTML = maskedAccount;
            });

            $maidInput.value = data.MAID;
            $forgotAccountNumberLookupFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            $forgotSelectAccountFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            addAccountFocus();

            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step11b'
            });

            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/add-account/forgot-account-number/account-matched'
            });
        };

        var forgotSelectAccountHandler = function () {
            $submitAnimationButton = $forgotSelectAccountFormWrapper.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                forgotSelectAccountCall,
                function () {},
                function () {}
            );
        };

        var forgotSelectAccountCall = function () {
            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step12b'
            });

            hideError();
            successCallback();

            $forgotSelectAccountFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            $sendRecoveryCodeFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            addAccountFocus();
        };

        var errorServiceCallback = function () {
            _.each($addAccountError, function ($serviceError) {
                $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $serviceError.focus();
            });

            _.each($addAccountErrorMessage, function ($serviceErrorMessage) {
                $serviceErrorMessage.innerHTML = _data.errorMsg
                    ? _data.errorMsg
                    : coned.constants.ERROR_MESSAGE;
            });
        };

        var hideError = function () {
            _.each($addAccountError, function (serviceError) {
                serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });
        };

        var returnToHome = function (event) {
            event.preventDefault();

            hideError();

            $addAccountFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $sendRecoveryCodeFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            $entryRecoveryCodeFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            addAccountFocus();

            // security word options reset
            for (var index = 0; index < $confirmOwnershipSecurityOptions.length; index++) {
                $confirmOwnershipSecurityOptions[index].checked = false;
            }
        };

        var selectLookupWithEvent = function () {
            var selectValue = $selectLookupWith.value;
            var $emailInputSelector = $emailLookupSelector.getElementsByClassName(
                CONSTANTS.INPUT_CLASS
            )[0];
            var $phoneInputSelector = $phoneLookupSelector.getElementsByClassName(
                CONSTANTS.INPUT_CLASS
            )[0];

            if (selectValue == 'phone') {
                $phoneLookupSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $emailLookupSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $emailInputSelector.classList.add(CONSTANTS.IGNORE_CLASS);
                $phoneInputSelector.classList.remove(CONSTANTS.IGNORE_CLASS);
            } else {
                $phoneLookupSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $emailLookupSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $emailInputSelector.classList.remove(CONSTANTS.IGNORE_CLASS);
                $phoneInputSelector.classList.add(CONSTANTS.IGNORE_CLASS);
            }
        };

        var registerProfileRedirect = function (event) {
            event.preventDefault();

            if ($registerProfileButton.dataset.link && $registerProfileButton.dataset.link !== '') {
                window.location.href = $registerProfileButton.dataset.link;
            }
        };

        var forgotAccountNumberLinkEvent = function (event) {
            event.preventDefault();

            hideError();

            $forgotAccountNumberLookupFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $addAccountFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            addAccountFocus();

            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step02opt'
            });

            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step11a'
            });

            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/add-account/forgot-account-number'
            });
        };

        var sendCodeAgainEvent = function (event) {
            event.preventDefault();

            hideError();

            $sendRecoveryCodeFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $entryRecoveryCodeFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            addAccountFocus();

            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step08opt1'
            });
        };

        var lookupAccountNumberEvent = function (event) {
            event.preventDefault();

            hideError();

            $forgotAccountNumberLookupFormWrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $forgotSelectAccountFormWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
            addAccountFocus();
        };

        var initializeData = function () {
            $addAccountFormWrapper = $accountForm.getElementsByClassName(
                CONSTANTS.ADD_ACCOUNT_FORM_WRAPPER
            )[0];
            $confirmOwnershipSecurityOptions = $accountForm.getElementsByClassName(
                CONSTANTS.CONFIRM_OWNERSHIP_SECURITY_WORD_OPTIONS
            );
            $sendRecoveryCodeFormWrapper = $accountForm.getElementsByClassName(
                CONSTANTS.SEND_RECOVERY_CODE_FORM_WRAPPER
            )[0];
            $entryRecoveryCodeFormWrapper = $accountForm.getElementsByClassName(
                CONSTANTS.ENTRY_RECOVERY_CODE_FORM_WRAPPER
            )[0];
            $forgotAccountNumberLookupFormWrapper = $accountForm.getElementsByClassName(
                CONSTANTS.FORGOT_ACCOUNT_NUMBER_LOOKUP_FORM_WRAPPER
            )[0];
            $forgotSelectAccountFormWrapper = $accountForm.getElementsByClassName(
                CONSTANTS.FORGOT_SELECT_ACCOUNT_FORM_WRAPPER
            )[0];
            $accountNumberContain = $accountForm.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER_CONTAIN
            )[0];
            $accountNumberContainer = $accountForm.getElementsByClassName(
                CONSTANTS.ACCOUNT_NUMBER_CONTAINER
            );
            $notMyAccountLink = $accountForm.getElementsByClassName(CONSTANTS.NOT_MY_ACCOUNT_LINK);
            $selectLookupWith = $accountForm.getElementsByClassName(
                CONSTANTS.SELECT_LOOKUP_WITH
            )[0];
            $emailLookupSelector = $accountForm.getElementsByClassName(
                CONSTANTS.EMAIL_LOOKUP_SELECTOR
            )[0];
            $phoneLookupSelector = $accountForm.getElementsByClassName(
                CONSTANTS.PHONE_LOOKUP_SELECTOR
            )[0];
            $forgotAccountNumberLink = $accountForm.getElementsByClassName(
                CONSTANTS.FORGOT_ACCOUNT_NUMBER_LINK
            )[0];
            $sendCodeAgainLink = $accountForm.getElementsByClassName(
                CONSTANTS.SEND_CODE_AGAIN_LINK
            )[0];
            $lookupAccountNumberLink = $accountForm.getElementsByClassName(
                CONSTANTS.LOOKUP_ACCOUNT_NUMBER_LINK
            )[0];
            $addAccountError = $accountForm.getElementsByClassName(CONSTANTS.ADD_ACCOUNT_ERROR);
            $addAccountErrorMessage = $accountForm.getElementsByClassName(
                CONSTANTS.ADD_ACCOUNT_ERROR_MESSAGE
            );
            $maidInput = document.getElementsByName(CONSTANTS.MAID_INPUT)[0];
            $phoneOptions = $sendRecoveryCodeFormWrapper.getElementsByClassName(
                CONSTANTS.REMEMBER_PHONE_OPTION
            );
            $emailOption = $sendRecoveryCodeFormWrapper.getElementsByClassName(
                CONSTANTS.REMEMBER_EMAIL_OPTION
            )[0];
            $callCustomerService = $sendRecoveryCodeFormWrapper.getElementsByClassName(
                CONSTANTS.CUSTOMER_SERVICE
            )[0];
            $confirmOwnershipSubmitButton = $sendRecoveryCodeFormWrapper.getElementsByClassName(
                CONSTANTS.CONFIRM_OWNERSHIP_SUBMIT
            )[0];
            $registerProfileButton = $accountForm.getElementsByClassName(
                CONSTANTS.REGISTER_PROFILE_BUTTON
            )[0];
            $notificationOnboardingStep = $accountForm.getElementsByClassName(
                CONSTANTS.NOTIFICATION_ONBOARDING_SELECTOR
            )[0];
            // For patternlab, if the url has the "notification-onboarding=true" parameter, it shows the scenario in
            // which notification-onboarding markup is present (BE will only add it if the profile has no accounts)
            _hasNotificationOnboardingStep = 
                coned.utils.isPatternLab() ?
                    coned.utils.getUrlParameterValue(CONSTANTS.NOTIFICATION_ONBOARDING_PARAM) ?
                        true : 
                        false :
                    $notificationOnboardingStep ? 
                        true : 
                        false;                            
        };

        var initializeEvents = function () {
            new coned.components.ValidateForm(CONSTANTS.ADD_ACCOUNT_FORM, addAccountHandler, '');
            new coned.components.ValidateForm(
                CONSTANTS.SEND_RECOVERY_CODE_FORM,
                sendRecoveryCodeHandler,
                ''
            );
            new coned.components.ValidateForm(
                CONSTANTS.ENTRY_RECOVERY_CODE_FORM,
                entryRecoveryCodeHandler,
                ''
            );
            new coned.components.ValidateForm(
                CONSTANTS.FORGOT_ACCOUNT_NUMBER_LOOKUP_FORM,
                forgotAccountNumberLookupHandler,
                '.' + CONSTANTS.IGNORE_CLASS
            );
            new coned.components.ValidateForm(
                CONSTANTS.FORGOT_SELECT_ACCOUNT_FORM,
                forgotSelectAccountHandler,
                ''
            );

            // confirm ownership options handler
            coned.utils.addGeneralListeners($emailOption, confirmOwnershipEvent);
            for (var index = 0; index < $phoneOptions.length; index++) {
                coned.utils.addGeneralListeners($phoneOptions[index], confirmOwnershipEvent);
            }

            _.each($notMyAccountLink, function ($link) {
                coned.utils.addGeneralListeners($link, returnToHome);
            });

            coned.utils.addGeneralListeners($registerProfileButton, registerProfileRedirect);
            coned.utils.addGeneralListeners($lookupAccountNumberLink, lookupAccountNumberEvent);
            coned.utils.addGeneralListeners($sendCodeAgainLink, sendCodeAgainEvent);
            coned.utils.addGeneralListeners($forgotAccountNumberLink, forgotAccountNumberLinkEvent);
            $selectLookupWith.addEventListener('change', selectLookupWithEvent);

            // Analytics data building
            dataLayer.push({
                event: 'add.account.process',
                'add.account': 'step01'
            });

            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/add-account/lookup-account-info'
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    AddAccount.prototype.isLoaded = function () {
        return isLoaded;
    };

    return AddAccount;
})();
