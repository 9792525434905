// ====================  LOAD REAL TIME DATA OPOWER COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.RealTimeDataOpower = (function () {
    var isLoaded = false;

    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        REAL_TIME_TAB_CLASS: 'js-real-time-tab',
        BILLING_USAGE_SELECTOR: 'js-billing-usage',
        COMPARISONS_ANALYSIS_SELECTOR: 'js-comparisons-analysis',
        DATA_BROWSER_CONTAINER_BILLING: 'js-data-browser-container-billing',
        DATA_BROWSER_CONTAINER_USAGE: 'js-data-browser-container-usage',
        SIMILAR_HOMES_CONTAINER: 'js-similar-homes-container',
        DATA_BROWSER_WIDGET_BILLING_TAG: '<opower-widget-data-browser opower-instance="billing"></opower-widget-data-browser>',
        DATA_BROWSER_WIDGET_USAGE_TAG: '<opower-widget-data-browser opower-instance="usage"></opower-widget-data-browser>',
        DATA_BROWSER_WIDGET_SIMILAR_HOMES_TAG: '<opower-widget-data-browser opower-instance="similar-homes"></opower-widget-data-browser>',
        LOAD_ENERGY_INSIGHTS: 'js-load-energy-insights',
        LOAD_ENERGY_INSIGHTS_ERROR: 'js-load-energy-insights-error',
        NEIGHBORS_CHART_TYPE: 'neighbors',
        COST_CHART_TYPE: 'cost',
        USAGE_CHART_TYPE: 'usage',
        CAPTURE_GROUP_$1: '$1',
        TAB_NAV_PARAM:  'ou-data-browser',
        TAB1_VALUE: 'sectionBillingUsage-2',
        TAB1_KEY: 'tab1',
        TAB3_KEY: 'tab3'
    };

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var RealTimeDataOpower = function ($container) {
        /**
         * PRIVATE METHODS
         */
        var $dataBrowserContainerUsage,
            $similarHomesContainer,
            $billingUsageTab,
            $comparisonsAnalysisTab,
            $loadEnergyInsightsModule,
            _isLoadEnergyInsightsModuleError;

        var cleanOUNavigationFromUrl = function() {
            var params = coned.utils.getUrlParameters();
            var paramName = CONSTANTS.TAB_NAV_PARAM;

            var paramKeys = _.keys(params);
            if (paramKeys.includes(paramName)) {
                //Remove the OU nav param {ou-data-browser}
                var paramsToRemove = _.filter(paramKeys, function (key) {
                    return (
                        key.includes(CONSTANTS.TAB_NAV_PARAM)
                    );
                });
                params = _.omit(params, function (value, key) {
                    return paramsToRemove.includes(key);
                });
                coned.utils.setUrlParameters(params);
            }
        }

        var validateInitialTabNavigation = function() {
            var params = new URLSearchParams(window.location.search);
            // Validates if tab2 param doesn't exist,
            // so we don't need the ou widgets' params
            if (!params.has(CONSTANTS.TAB2_KEY)) {
                cleanOUNavigationFromUrl();
            }
        }

        var setRealTimeDataWidget = function ($target) {
            /*
                chartPattern regex overview:
                =====================
                (?:\&|\?) Checks if the parameter is the first one or a subsequent
                (\w+) Captures the chart type. A string of 1 or more characters.
            */
            // 1. Finds oPower widget
            var chartType = $target.dataset.realTimeTab,
                chartTypeList = ['usage', 'neighbors'],
                defaultChartType = chartTypeList[0],
                // 2. Validate the chartType parameter
                targetChartType = chartTypeList.includes(chartType) ? chartType : defaultChartType;


            // 5. Get level 1 tabs
            $billingUsageTab = $container.getElementsByClassName(
                CONSTANTS.BILLING_USAGE_SELECTOR
            )[0];

            $comparisonsAnalysisTab = $container.getElementsByClassName(
                CONSTANTS.COMPARISONS_ANALYSIS_SELECTOR
            )[0];

            // 6. Get data-browser widget instances containers
            $dataBrowserContainerUsage = $container.getElementsByClassName(
                CONSTANTS.DATA_BROWSER_CONTAINER_USAGE
            )[0];

            $similarHomesContainer = $container.getElementsByClassName(
                CONSTANTS.SIMILAR_HOMES_CONTAINER
            )[0];

            // 7. Check for errors while loading the Energy Insights Module
            _isLoadEnergyInsightsModuleError = query.hasClass($loadEnergyInsightsModule, CONSTANTS.LOAD_ENERGY_INSIGHTS_ERROR);

            // 8. Reinstantiate widgets to pick up the route according to the chartType in the updated URL
            if (!_isLoadEnergyInsightsModuleError) {
                if (targetChartType === CONSTANTS.USAGE_CHART_TYPE) {
                    if ($dataBrowserContainerUsage && !$dataBrowserContainerUsage.innerHTML) {
                        $dataBrowserContainerUsage.innerHTML = CONSTANTS.DATA_BROWSER_WIDGET_USAGE_TAG;
                    }
                    if ($billingUsageTab && $billingUsageTab.dataset.realTimeTab) {
                        $billingUsageTab.dataset.realTimeTab = targetChartType;
                    }
                }

                if (targetChartType === CONSTANTS.NEIGHBORS_CHART_TYPE) {
                    if ($similarHomesContainer && !$similarHomesContainer.innerHTML) {
                        $similarHomesContainer.innerHTML = CONSTANTS.DATA_BROWSER_WIDGET_SIMILAR_HOMES_TAG;
                    }
                    if ($comparisonsAnalysisTab && $comparisonsAnalysisTab.dataset.realTimeTab) {
                        $comparisonsAnalysisTab.dataset.realTimeTab = targetChartType;
                    }
                }
            }
        };

        var initializeData = function () {
            $loadEnergyInsightsModule = document.getElementsByClassName(
                CONSTANTS.LOAD_ENERGY_INSIGHTS
            )[0];

            _isLoadEnergyInsightsModuleError = false;
        };

        var initializeEvents = function () {
            validateInitialTabNavigation();
            coned.utils.addParentListener(
                $container,
                coned.utils.generalEvents(),
                CONSTANTS.REAL_TIME_TAB_CLASS,
                setRealTimeDataWidget
            );
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    RealTimeDataOpower.prototype.isLoaded = function () {
        return isLoaded;
    };

    return RealTimeDataOpower;
})();
