// ==================== STREET LIGHT TABLE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.StreetLightTableComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ITEM: 'js-item',
        ITEM_OPEN: 'street-lights__table-item--open',
        ITEM_ARROW: 'js-open-arrow' 
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var StreetLightTableComponent = function ($StreetLightTable) {
        /**
         * PRIVATE METHODS
         */
        var $item, $arrowItem;
        
        var billItemEvent = function (event) {
            event.preventDefault();

            var $arrowParentItem = this.parentNode;
            var $arrowButton = event.target;

            if (query.hasClass($arrowParentItem, CONSTANTS.ITEM_OPEN)) {
                $arrowParentItem.classList.remove(CONSTANTS.ITEM_OPEN);
                $arrowButton.setAttribute('aria-expanded','false');
            } else {
                query.removeClass($item, CONSTANTS.ITEM_OPEN);
                $arrowParentItem.classList.add(CONSTANTS.ITEM_OPEN);
                $arrowButton.setAttribute('aria-expanded','true');
            }
        };

        var initializeData = function () {
            $item = $StreetLightTable.getElementsByClassName(CONSTANTS.ITEM);
            $arrowItem = $StreetLightTable.getElementsByClassName(CONSTANTS.ITEM_ARROW);
        };

        var initializeEvents = function () {
            // Expand/collapse row
            for (var index = 0; index < $arrowItem.length; index++) {
                coned.utils.addGeneralListeners($arrowItem[index], billItemEvent);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    StreetLightTableComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return StreetLightTableComponent;
})();
