// ==================== STOP SERVICE NO AUTH COMPONENT =========================
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.StopServiceNoAuth = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        // Selector Constants
        AUTHENTICATION_CONTAINER_SELECTOR: 'js-authentication-container',
        DROPDOWN_BUTTON: 'js-dropdown-button-contain',
        ADDRESS_BOX: 'js-address-box',
        ADDRESS_BOX_TITLE: 'js-address-box-title',
        ADDRESS_BOX_SUBTITLE: 'js-address-box-subtitle',
        STOP_SERVICE_SELECTOR: 'js-stop-service',
        STOP_SERVICE_CONTAINER_SELECTOR: 'js-stop-service-container',
        UNAUTHENTICATED_LOGIN_SELECTOR: 'js-unauthenticated-login',
        UNAUTHENTICATED_STOP_SERVICE: 'js-unauthenticated-stop-service',

        // Other Constants
        HIDDEN_CLASS: 'hidden',
        DATA_MODULE: 'data-module',
        STOP_SERVICE_MODULE: 'StopService',
        UNAUTHENTICATED_ACCOUNT_MAID: 'UnauthenticatedLoginMAID',

        // Tagging Constants
        DATA_TAGGING: 'data-tagging-preffix',
        TAGGING_CONFIRM_ACCOUNT: 'Confirm.Account',
        TAGGING_PREFFIX: 'Unauthenticated.'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var StopServiceNoAuth = function ($stopServiceNoAuth) {
        /**
         * PRIVATE METHODS
         */
        var $authenticationContainer,
            $dropdownButton,
            $addressBox,
            $addressBoxTitle,
            $addressBoxSubtitle,
            $stopService,
            $stopServiceContainer,
            $unauthenticatedLogin;

        /**
         * Handles the login success.
         */
        var loginSuccessEvent = function () {
            if ($dropdownButton) {
                $dropdownButton.dataset.accountMaid = query.getFormInputValue(
                    $unauthenticatedLogin,
                    CONSTANTS.UNAUTHENTICATED_ACCOUNT_MAID
                );
            }

            if ($addressBox) {
                $addressBox.dataset.accountMaid = query.getFormInputValue(
                    $unauthenticatedLogin,
                    CONSTANTS.UNAUTHENTICATED_ACCOUNT_MAID
                );
            }

            if (
                $unauthenticatedLogin.dataset.taggingPreffix &&
                $unauthenticatedLogin.dataset.taggingPreffix !== ''
            ) {
                $stopService.setAttribute(
                    CONSTANTS.DATA_TAGGING,
                    CONSTANTS.TAGGING_PREFFIX + $unauthenticatedLogin.dataset.taggingPreffix
                );

                // Tagging Confirm Unauthorized Account
                dataLayer.push({
                    event:
                        CONSTANTS.TAGGING_PREFFIX +
                        $unauthenticatedLogin.dataset.taggingPreffix +
                        CONSTANTS.TAGGING_CONFIRM_ACCOUNT
                });
            }

            $stopService.classList.add(CONSTANTS.UNAUTHENTICATED_STOP_SERVICE);
            $stopService.setAttribute(CONSTANTS.DATA_MODULE, CONSTANTS.STOP_SERVICE_MODULE);
            new coned.components.StopService($stopService);

            $stopServiceContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $authenticationContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        /**
         * Handles the account availability success.
         */
        var accountAvailableEvent = function (event) {
            var data = event.detail,
                titleLabel =
                    data.FinalBillAddress.Address2 && data.FinalBillAddress.Address2 !== ''
                        ? data.FinalBillAddress.StreetOrAddress &&
                          data.FinalBillAddress.StreetOrAddress !== ''
                            ? data.FinalBillAddress.StreetOrAddress +
                              ', ' +
                              data.FinalBillAddress.Address2
                            : data.FinalBillAddress.Address2
                        : data.FinalBillAddress.StreetOrAddress,
                subtitleLabel =
                    data.FinalBillAddress.City +
                    ', ' +
                    data.FinalBillAddress.StateOrProvince +
                    ', ' +
                    data.FinalBillAddress.ZipCode;

            titleLabel +=
                data.FinalBillAddress.UnitNumber && data.FinalBillAddress.UnitNumber !== ''
                    ? ', ' + data.FinalBillAddress.UnitNumber
                    : '';

            $addressBoxTitle.innerHTML = titleLabel;
            $addressBoxSubtitle.innerHTML = subtitleLabel;

            if (data.FinalBillAddress.UnitNumber) {
                $addressBox.dataset.unit = data.FinalBillAddress.UnitNumber;
            }
        };

        /**
         * Inits data in the module.
         */
        var initializeData = function () {
            $authenticationContainer = $stopServiceNoAuth.getElementsByClassName(
                CONSTANTS.AUTHENTICATION_CONTAINER_SELECTOR
            )[0];
            $dropdownButton = $stopServiceNoAuth.getElementsByClassName(
                CONSTANTS.DROPDOWN_BUTTON
            )[0];
            $addressBox = $stopServiceNoAuth.getElementsByClassName(CONSTANTS.ADDRESS_BOX)[0];
            $addressBoxTitle = $stopServiceNoAuth.getElementsByClassName(
                CONSTANTS.ADDRESS_BOX_TITLE
            )[0];
            $addressBoxSubtitle = $stopServiceNoAuth.getElementsByClassName(
                CONSTANTS.ADDRESS_BOX_SUBTITLE
            )[0];
            $stopServiceContainer = $stopServiceNoAuth.getElementsByClassName(
                CONSTANTS.STOP_SERVICE_CONTAINER_SELECTOR
            )[0];
            $stopService = $stopServiceNoAuth.getElementsByClassName(
                CONSTANTS.STOP_SERVICE_SELECTOR
            )[0];
            $unauthenticatedLogin = $stopServiceNoAuth.getElementsByClassName(
                CONSTANTS.UNAUTHENTICATED_LOGIN_SELECTOR
            )[0];
        };

        /**
         * Inits events in the module.
         */
        var initializeEvents = function () {
            $unauthenticatedLogin.addEventListener('login-success', loginSuccessEvent);
            $unauthenticatedLogin.addEventListener('account-available', accountAvailableEvent);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    StopServiceNoAuth.prototype.isLoaded = function () {
        return isLoaded;
    };

    return StopServiceNoAuth;
})();
