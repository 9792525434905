// ==================== DASHBOARD PAYMENT AGREEMENT COMPONENT =========================
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DashboardPaymentAgreementOru = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ENROLL_FORM_SELECTOR: 'js-payment-agreement-enroll',
        ORU_ENROLL_CONFIRMATION: 'js-oru-enroll-confirmation',
        CANCEL_ENROLL_BUTTON: 'js-cancel-enroll',
        MANAGE_FORM_SELECTOR: 'js-manage-form',
        CLOSE_SELECTOR: 'js-accordion-close',
        LINK_ACCORDION_SELECTOR: 'js-accordion-selector',
        LINK_DESCRIPTION: 'js-link-description',
        ACCORDION_SELECTOR: 'js-accordion-contain',
        UNENROLLMENT_CLASS: 'manage-enroll__list--inactive',
        ALERT_CLASS: 'manage-enroll__list--alert',
        ICON_STATUS_TEXT: 'icon-status-text',
        ENROLLED: 'Enrolled',
        HIDDEN_CLASS: 'hidden',
        FORM_SCID: 'scId',
        FORM_UPDATE_ACCOUNT_TYPE: 'PaymentAgreement',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        FORM_LOADING: 'js-form-loading',
        EDIT_PAYMENT_AGREEMENT_POPUP: 'js-edit-payment-agreement-popup',
        OPEN_EDIT_PAYMENT_AGREEMENT_POPUP_BUTTON: 'js-open-edit-payment-agreement-popup',
        SCHEDULE_PAYMENT_BUTTON: 'js-schedule-a-payment',
        MAKE_PAYMENT_BUTTON: 'js-make-a-payment',
        MANAGE_SCHEDULE_PAYMENT_BUTTON: 'js-schedule-a-payment-manage',
        PAYMENT_CANCEL_BUTTON: 'js-accordion-close',
        PAYMENT_AGREEMENT_CHECK_TEXT: 'js-item-title-selector',
        ACCEPT_PAYMENT_AGREEMENT_BUTTON: 'js-accept-payment-agreement',
        PAYMENT_AGREEMENT_ACCEPTED_MESSAGE: 'js-payment-agreement-accepted-message',
        PAYMENT_TABLE_CONTAINER: 'js-payment-table',
        DOWN_PAYMENT_AMOUNT_SPAN: 'js-payment-agreement-down-payment',
        NUMBER_OF_INSTALLMENTS_SPAN: 'js-payment-agreement-number-installments',
        FINAL_PAYMENT_SPAN: 'js-payment-agreement-final-payment-span',
        AMOUNT_PER_INSTALLMENT_SPAN: 'js-payment-agreement-installment-amount-span',
        TOTAL_OUTSTANDING: 'js-payment-agreement-total-outstanding',
        PAY_MY_BILL_OTHER_AMOUNT_URL_PARAM: 'PaymentAmount',
        PAYMENT_AGREEMENT_EDITABLE: 'js-payment-agreement-editable',
        POPUP_INACTIVE_CLASS: 'payment-table__popup',
        POPUP_ACTIVE_CLASS: 'payment-table__popup--active',
        MODIFIED_TERMS_ONLY: 'js-payment-agreement-modified-terms-only',
        DOWN_PAYMENT_DUE_DATE: 'js-payment-agreement-down-payment-due-date',
        RADIO_BUTTON_SELECTOR: 'js-coned-radio',
        RADIO_INDICATOR: 'coned-radio__indicator',
        RADIO_INDICATOR_FOCUS: 'coned-radio__indicator--focus',
        TERM_TYPE_SAO: 'SAO',
        TERM_TYPE_PGR: 'PGR',

        // Enrolled Progress bar
        PROGRESS_BAR: 'js-pagr-progress-bar',
        PROGRESS_BAR_SMALL_BAR: 'js-pagr-small-bg',
        PROGRESS_BAR_BIG_BAR: 'js-pagr-big-bg',
        PROGRESS_BAR_INSTALLMENTS_CHECKPOINT: 'js-pagr-installments-checkpoint',
        PROGRESS_BAR_DOWNPAYMENT_CHECKPOINT: 'js-pagr-downpayment-checkpoint',
        PROGRESS_BAR_FINAL_CHECKPOINT: 'js-pagr-final-checkpoint',
        PROGRESS_BAR_AMOUNT: 'js-pagr-bar-amount',
        AMOUNT_CLASS: 'manage-enroll__payment-agreement-progress-bar-amount',
        AMOUNT_GRAYED_SUFFIX: '--grayed',
        BAR_BIG_BAR_CLASS: 'manage-enroll__payment-agreement-progress-bar-big-bg',
        SMALL_BAR_CLASS: 'manage-enroll__payment-agreement-progress-bar-small-bg',
        SMALL_BAR_STARTED_SUFFIX: '--started',
        SMALL_BAR_FINISHED_SUFFIX: '--finished',
        PROGRESS_BAR_CHECKPOINT_CLASS: 'manage-enroll__payment-agreement-progress-bar-checkpoint',
        CHECKPOINT_INSTALLMENTS_SUFFIX: '--installments',
        CHECKPOINT_ACTIVE_SUFFIX: '--active',
        CHECKPOINT_ARROW_ICON: 'icon-arrow',
        CHECKPOINT_CHECK_ICON: 'icon-check-status',

        // Tagging
        CONTENT_TITLE_TAG: 'payment-agreement',
        START_PAYMENT_AGREEMENT_UNEDITABLE_TAG: 'start.payagmt.1',
        START_PAYMENT_AGREEMENT_EDITABLE_TAG: 'start.payagmt.2',
        PAYMENT_AGREEMENT_FORM_SUCCESS_TAG: 'coned.form.success',
        PAYMENT_AGREEMENT_FORM_ERROR_TAG: 'coned.form.error',
        PAYMENT_AGREEMENT_FORM_CANCEL_TAG: 'coned.form.cancel',
        PAYMENT_AGREEMENT_EDIT_TAG: 'edit.payagmt',
        PAYMENT_AGREEMENT_SCHEDULE_TAG: 'schedule.payment',
        PAYMENT_AGREEMENT_MAKE_PAYMENT_TAG: 'make.payment'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DashboardPaymentAgreementOru = function ($paymentAgreementForm) {
        /**
         * PRIVATE METHODS
         */
        var $closeSelectors,
            $linkAccordionSelector,
            $accordionSelector,
            $enrollFormSelector,
            $oruEnrollConfirmation,
            $cancelEnrollButton,
            $manageFormSelector,
            $serviceError,
            $serviceErrorMessage,
            $accountMaidInput,
            $formLoading,
            $editPaymentAgreementPopup,
            $openEditPaymentAgreementPopupButton,
            $schedulePaymentButton,
            $makePaymentButton,
            $manageSchedulePaymentButton,
            $totalOutstanding,
            $downPaymentAmountSpan,
            $numberOfInstallmentsSpan,
            $installmentsAmountSpan,
            $finalPaymentAmountSpan,
            $downPaymentDueDate,
            $paymentTableContainer,
            $acceptPaymentAgreementButton,
            $paymentCancelButton,
            $paymentAgreementAcceptedMessage,
            $paymentAgreementCheckText,
            $radioButtonsSelector,
            _isPaymentAgreementEditable,
            _isModifiedTermsOnly,
            $progressBar,
            _progressBarPadding;

        var getAmounts = function () {
            var data = {};
            var totalAmount = parseFloat($totalOutstanding.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var downPaymentAmount =
                parseFloat($downPaymentAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;
            var installmentBase = totalAmount - downPaymentAmount;
            var finalAmount =
                parseFloat($finalPaymentAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;

            data = {
                DownPaymentAmount: downPaymentAmount,
                InstallmentBase: installmentBase,
                FinalAmount: finalAmount,
                TotalOutstanding: totalAmount
            };

            if ($numberOfInstallmentsSpan) {
                data.NumberOfInstallments = parseInt($numberOfInstallmentsSpan.innerHTML);
            }

            if ($installmentsAmountSpan) {
                data.AmountPerInstallment =
                    parseFloat($installmentsAmountSpan.innerHTML.replace(/(\$|,)/g, '')) || 0;
            }

            return data;
        };

        var setStatement = function () {
            var statementFlag = $accordionSelector.dataset.statement;

            if (statementFlag == 'false') {
                $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $manageFormSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $paymentAgreementForm.classList.remove(CONSTANTS.ALERT_CLASS);
                $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.ICON_STATUS_TEXT
                )[0].innerText = CONSTANTS.ENROLLED;
                $linkAccordionSelector.getElementsByClassName(
                    CONSTANTS.LINK_DESCRIPTION
                )[0].innerHTML = $accordionSelector.dataset.manage;
                $accordionSelector.dataset.openName = $accordionSelector.dataset.manage;
            }
        };

        var manageSubmitEvent = function (event) {
            event.preventDefault();

            window.location.href = $paymentAgreementForm.dataset.redirectUrl;
        };

        var confirmEnroll = function (event) {
            event.preventDefault();

            window.location.href = $paymentAgreementForm.dataset.queryRedirectUrl;
        };

        var cancelEnrollEvent = function (event) {
            event.preventDefault();

            $linkAccordionSelector.click();
        };

        var redirectWithDownPayment = function () {
            var downPayment = parseFloat(
                    $downPaymentAmountSpan.innerHTML.replace(/(\$|,)/g, '')
                ).toFixed(2),
                redirectUrl = $paymentAgreementForm.dataset.queryRedirectUrl;

            redirectUrl = coned.utils.updateUrlParameter(
                CONSTANTS.PAY_MY_BILL_OTHER_AMOUNT_URL_PARAM,
                downPayment,
                redirectUrl
            );

            window.location.href = redirectUrl;
        };

        var schedulePaymentClickEvent = function (event) {
            if (event) {
                event.preventDefault();
            }

            redirectWithDownPayment();
        };

        var manageSchedulePaymentClickEvent = function (event) {
            event.preventDefault();

            window.location.href = $paymentAgreementForm.dataset.queryRedirectUrl;
        };

        var acceptPaymentAgreement = function (event) {
            event.preventDefault();

            var data = getAmounts(),
                serviceUrl = $paymentAgreementForm.dataset.updateTermsServiceUrl,
                termTypeName = (_isModifiedTermsOnly ? CONSTANTS.TERM_TYPE_PGR : CONSTANTS.TERM_TYPE_SAO),
                params;

            hideError();

            var rootDate = new Date($downPaymentDueDate.dataset.downPaymentDueDate),
                totalAgreementAmount = data.TotalOutstanding,
                downPaymentAmount = data.DownPaymentAmount,
                downPaymentDate = rootDate,
                firstPaymentDueDate = rootDate,
                installmentAmount = data.AmountPerInstallment,
                installmentDueDate = new Date(rootDate.setMonth(rootDate.getMonth() + 1)),
                numberOfInstallments = data.NumberOfInstallments,
                finalPaymentAmount = data.FinalAmount,
                finalPaymentDate = new Date(
                    rootDate.setMonth(rootDate.getMonth() + data.NumberOfInstallments)
                );

            params = {
                Maid: $accountMaidInput.value,
                TermType: termTypeName,
                TotalAgreementAmount: totalAgreementAmount,
                DownPaymentAmount: downPaymentAmount,
                DownPaymentDate: downPaymentDate,
                FirstPaymentDueDate: firstPaymentDueDate,
                InstallmentAmount: installmentAmount,
                InstallmentDueDate: installmentDueDate,
                NumberOfInstallments: numberOfInstallments,
                FinalPaymentAmount: finalPaymentAmount,
                FinalPaymentDate: finalPaymentDate
            };

            // Service Call
            params = JSON.stringify(params);
            query.postData(
                serviceUrl,
                successPaymentAgreement,
                errorPaymentAgreement,
                params,
                true,
                $formLoading
            );
        };

        var hideError = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var successPaymentAgreement = function () {
            // Analytics data building
            taggingSuccessPaymentAgreement();

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($paymentAgreementForm);

            if (coned.utils.isOru()) {
                $accordionSelector.dataset.statement = 'true';
                setStatement();

                $oruEnrollConfirmation.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $enrollFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
                $manageFormSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else if (_isModifiedTermsOnly) {
                query.addClass($paymentTableContainer, CONSTANTS.HIDDEN_CLASS);
                query.addClass($acceptPaymentAgreementButton, CONSTANTS.HIDDEN_CLASS);
                $schedulePaymentButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $paymentCancelButton.innerHTML = $paymentCancelButton.dataset.originalText;
                $paymentAgreementAcceptedMessage.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $paymentAgreementCheckText.innerHTML =
                    $paymentAgreementCheckText.dataset.paymentAgreementConfirmationText;
            } else {
                redirectWithDownPayment();
            }
        };

        var errorPaymentAgreement = function (data) {
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceErrorMessage.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;

            taggingErrorPaymentAgreement();
            $serviceError.focus();
        };

        var openEditPaymentAgreementPopup = function (event) {
            event.preventDefault();

            query.removeClass($editPaymentAgreementPopup, CONSTANTS.HIDDEN_CLASS);
            $editPaymentAgreementPopup.classList.remove(CONSTANTS.POPUP_INACTIVE_CLASS);
            $editPaymentAgreementPopup.classList.add(CONSTANTS.POPUP_ACTIVE_CLASS);
        };

        var taggingStartPaymentAgreement = function () {
            var isAccordionOpened = !query.hasClass($linkAccordionSelector, 'active');

            if (isAccordionOpened) {
                dataLayer.push({
                    contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                    event: _isPaymentAgreementEditable
                        ? CONSTANTS.START_PAYMENT_AGREEMENT_EDITABLE_TAG
                        : CONSTANTS.START_PAYMENT_AGREEMENT_UNEDITABLE_TAG
                });
            }
        };

        var taggingEditPaymentAgreement = function () {
            dataLayer.push({
                event: CONSTANTS.PAYMENT_AGREEMENT_EDIT_TAG
            });
        };

        var taggingSuccessPaymentAgreement = function () {
            dataLayer.push({
                contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                event: CONSTANTS.PAYMENT_AGREEMENT_FORM_SUCCESS_TAG
            });
        };

        var taggingErrorPaymentAgreement = function () {
            dataLayer.push({
                event: CONSTANTS.PAYMENT_AGREEMENT_FORM_ERROR_TAG
            });
        };

        var taggingSchedulePayment = function () {
            dataLayer.push({
                contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                event: CONSTANTS.PAYMENT_AGREEMENT_SCHEDULE_TAG
            });
        };

        var taggingMakePayment = function () {
            dataLayer.push({
                contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                event: CONSTANTS.PAYMENT_AGREEMENT_MAKE_PAYMENT_TAG
            });
        };

        // Handles the progress bar initialization
        var initializeProgressBar = function () {
            _.each($progressBar, function ($currentProgressBar, index) {
                var $progressBarSmallBar = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_SMALL_BAR
                    )[0],
                    $progressBarBigBar = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_BIG_BAR
                    )[0],
                    $progressBarInstallmentsCheck = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_INSTALLMENTS_CHECKPOINT
                    )[0],
                    $progressBarDownpaymentCheck = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_DOWNPAYMENT_CHECKPOINT
                    )[0],
                    $progressBarFinalCheck = $currentProgressBar.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_FINAL_CHECKPOINT
                    )[0],
                    $progressBarAmounts = $currentProgressBar.parentElement.getElementsByClassName(
                        CONSTANTS.PROGRESS_BAR_AMOUNT
                    ),
                    totalInstallments =
                        $currentProgressBar.dataset.totalInstallments &&
                        $currentProgressBar.dataset.totalInstallments !== ''
                            ? parseInt($currentProgressBar.dataset.totalInstallments)
                            : 0,
                    installmentsMade =
                        $currentProgressBar.dataset.installmentsMade &&
                        $currentProgressBar.dataset.installmentsMade !== ''
                            ? parseInt($currentProgressBar.dataset.installmentsMade)
                            : 0,
                    downpaymentCheck =
                        $currentProgressBar.dataset.downpaymentCheck &&
                        $currentProgressBar.dataset.downpaymentCheck === 'true'
                            ? true
                            : false,
                    finalCheck =
                        $currentProgressBar.dataset.finalCheck &&
                        $currentProgressBar.dataset.finalCheck === 'true'
                            ? true
                            : false,
                    percentage,
                    _desktopMediaQuery = '@media only screen and (min-width: 768px)',
                    _mobileMediaQuery = '@media only screen and (max-width: 767px)',
                    _desktopGradient = 'background: -moz-linear-gradient(middle, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -webkit-gradient(left middle, left right, color-stop(@PERCENTAGE%, #00882B), color-stop(@PERCENTAGE%, white));\
                                        background: -webkit-linear-gradient(middle, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -o-linear-gradient(middle, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -ms-linear-gradient(middle, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: linear-gradient(to right, #00882B @PERCENTAGE%, white @PERCENTAGE%);'.trim(),
                    _mobileGradient = 'background: -moz-linear-gradient(top, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -webkit-gradient(middle top, middle bottom, color-stop(@PERCENTAGE%, #00882B), color-stop(@PERCENTAGE%, white));\
                                        background: -webkit-linear-gradient(top, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -o-linear-gradient(top, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: -ms-linear-gradient(top, #00882B @PERCENTAGE%, white @PERCENTAGE%);\
                                        background: linear-gradient(to bottom, #00882B @PERCENTAGE%, white @PERCENTAGE%);'.trim();

                // Set first checkpoint styles
                if (downpaymentCheck) {
                    $progressBarDownpaymentCheck.classList.add(
                        CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS + CONSTANTS.CHECKPOINT_ACTIVE_SUFFIX
                    );
                    $progressBarDownpaymentCheck.classList.add(CONSTANTS.CHECKPOINT_CHECK_ICON);

                    $progressBarAmounts[1].classList.remove(
                        CONSTANTS.AMOUNT_CLASS + CONSTANTS.AMOUNT_GRAYED_SUFFIX
                    );

                    $progressBarInstallmentsCheck.classList.add(CONSTANTS.CHECKPOINT_ARROW_ICON);
                    $progressBarInstallmentsCheck.classList.add(
                        CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS + CONSTANTS.CHECKPOINT_ACTIVE_SUFFIX
                    );

                    $progressBarSmallBar.classList.add(
                        CONSTANTS.SMALL_BAR_CLASS + CONSTANTS.SMALL_BAR_STARTED_SUFFIX
                    );
                }

                // Set installments bar & checkpoint styles
                if (installmentsMade === 0 && totalInstallments > 0) {
                    percentage = 0;
                } else if (installmentsMade > 0 && installmentsMade < totalInstallments) {
                    // First we must know if how much percentage would each step take.
                    var stepPercentage = 100 / totalInstallments;

                    // Now, if the step percentage is higher than the padding, calculate position of checkpoint normally by rule of thirds.
                    if (stepPercentage > _progressBarPadding) {
                        percentage = (100 * installmentsMade) / totalInstallments;
                        // Else, if the percentage is lower, apply the padding formula
                    } else {
                        // Calculate where to position the checkpoint and green part of bar should be position by rule of thirds
                        // '(_progressBarPadding * 2)' -> To remove padding on "both" sides of the percentage
                        // '(installmentsMade - 1)' -> Since all installments are > 0 and we're already adding the padding as the "first installment", we remove an extra installment
                        // '(totalInstallments - 2)' -> Same reason as above, but including the "last installment" as well
                        percentage =
                            _progressBarPadding +
                            ((100 - _progressBarPadding * 2) * (installmentsMade - 1)) /
                                (totalInstallments - 2);
                    }
                } else if (installmentsMade === totalInstallments) {
                    percentage = 100;

                    $progressBarAmounts[2].classList.remove(
                        CONSTANTS.AMOUNT_CLASS + CONSTANTS.AMOUNT_GRAYED_SUFFIX
                    );
                    $progressBarInstallmentsCheck.classList.add(CONSTANTS.CHECKPOINT_CHECK_ICON);
                    $progressBarInstallmentsCheck.classList.remove(CONSTANTS.CHECKPOINT_ARROW_ICON);
                }

                // Add index classes for elements
                $progressBarInstallmentsCheck.classList.add(
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS + '--' + index
                );
                $progressBarBigBar.classList.add(CONSTANTS.BAR_BIG_BAR_CLASS + '--' + index);

                // Style related variables
                var styles = document.createElement('style'),
                    $script = document.querySelector('script'); // First script tag

                // Desktop installment bar & checkpoint styles
                styles.textContent +=
                    _desktopMediaQuery +
                    '{ .' +
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS +
                    CONSTANTS.CHECKPOINT_INSTALLMENTS_SUFFIX +
                    '.' +
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS +
                    '--' +
                    index +
                    '{ left: ' +
                    percentage +
                    '% !important; }' +
                    '.' +
                    CONSTANTS.BAR_BIG_BAR_CLASS +
                    '.' +
                    CONSTANTS.BAR_BIG_BAR_CLASS +
                    '--' +
                    index +
                    '{ ' +
                    _desktopGradient.replace(/@PERCENTAGE/g, percentage) +
                    ' } }';

                // Mobile installment bar & checkpoint styles
                styles.textContent +=
                    _mobileMediaQuery +
                    '{ .' +
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS +
                    CONSTANTS.CHECKPOINT_INSTALLMENTS_SUFFIX +
                    '.' +
                    CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS +
                    '--' +
                    index +
                    '{ top: ' +
                    percentage +
                    '% !important; }' +
                    '.' +
                    CONSTANTS.BAR_BIG_BAR_CLASS +
                    '.' +
                    CONSTANTS.BAR_BIG_BAR_CLASS +
                    '--' +
                    index +
                    '{ ' +
                    _mobileGradient.replace(/@PERCENTAGE/g, percentage) +
                    ' } }';

                $script.parentNode.insertBefore(styles, $script);

                // Set final checkpoint styles
                if (finalCheck) {
                    $progressBarFinalCheck.classList.add(
                        CONSTANTS.PROGRESS_BAR_CHECKPOINT_CLASS + CONSTANTS.CHECKPOINT_ACTIVE_SUFFIX
                    );
                    $progressBarFinalCheck.classList.add(CONSTANTS.CHECKPOINT_CHECK_ICON);

                    $progressBarSmallBar.classList.remove(
                        CONSTANTS.SMALL_BAR_CLASS + CONSTANTS.SMALL_BAR_STARTED_SUFFIX
                    );
                    $progressBarSmallBar.classList.add(
                        CONSTANTS.SMALL_BAR_CLASS + CONSTANTS.SMALL_BAR_FINISHED_SUFFIX
                    );
                }
            });
        };

        var initializeData = function () {
            $closeSelectors = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.CLOSE_SELECTOR
            );
            $linkAccordionSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.LINK_ACCORDION_SELECTOR
            )[0];
            $accordionSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.ACCORDION_SELECTOR
            )[0];
            $enrollFormSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.ENROLL_FORM_SELECTOR
            )[0];
            $oruEnrollConfirmation = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.ORU_ENROLL_CONFIRMATION
            )[0];
            $manageFormSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.MANAGE_FORM_SELECTOR
            )[0];
            $cancelEnrollButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.CANCEL_ENROLL_BUTTON
            )[0];
            $serviceError = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR
            )[0];
            $serviceErrorMessage = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            _isPaymentAgreementEditable = query.hasClass(
                $paymentAgreementForm,
                CONSTANTS.PAYMENT_AGREEMENT_EDITABLE
            );
            $manageSchedulePaymentButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.MANAGE_SCHEDULE_PAYMENT_BUTTON
            )[0];
            $editPaymentAgreementPopup = document.getElementsByClassName(
                CONSTANTS.EDIT_PAYMENT_AGREEMENT_POPUP
            )[0];
            $downPaymentAmountSpan = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.DOWN_PAYMENT_AMOUNT_SPAN
            )[0];
            $numberOfInstallmentsSpan = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.NUMBER_OF_INSTALLMENTS_SPAN
            )[0];
            $schedulePaymentButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.SCHEDULE_PAYMENT_BUTTON
            )[0];
            $makePaymentButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.MAKE_PAYMENT_BUTTON
            )[0];
            $paymentCancelButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.PAYMENT_CANCEL_BUTTON
            )[0];
            $acceptPaymentAgreementButton = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.ACCEPT_PAYMENT_AGREEMENT_BUTTON
            )[0];
            $paymentAgreementAcceptedMessage = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.PAYMENT_AGREEMENT_ACCEPTED_MESSAGE
            )[0];
            $paymentTableContainer = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.PAYMENT_TABLE_CONTAINER
            )[0];
            $totalOutstanding = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.TOTAL_OUTSTANDING
            )[0];
            $installmentsAmountSpan = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.AMOUNT_PER_INSTALLMENT_SPAN
            )[0];
            $finalPaymentAmountSpan = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.FINAL_PAYMENT_SPAN
            )[0];
            $downPaymentDueDate = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.DOWN_PAYMENT_DUE_DATE
            )[0];
            $paymentAgreementCheckText = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.PAYMENT_AGREEMENT_CHECK_TEXT
            )[0];
            $radioButtonsSelector = $paymentAgreementForm.getElementsByClassName(
                CONSTANTS.RADIO_BUTTON_SELECTOR
            );
            _isModifiedTermsOnly = query.hasClass(
                $paymentAgreementForm,
                CONSTANTS.MODIFIED_TERMS_ONLY
            );

            if (_isPaymentAgreementEditable) {
                $openEditPaymentAgreementPopupButton = $paymentAgreementForm.getElementsByClassName(
                    CONSTANTS.OPEN_EDIT_PAYMENT_AGREEMENT_POPUP_BUTTON
                );
            }

            // Enrolled Progress Bar
            $progressBar = $paymentAgreementForm.getElementsByClassName(CONSTANTS.PROGRESS_BAR);

            if ($progressBar) {
                _progressBarPadding = 12;
            }
        };

        var initializeEvents = function () {
            setStatement();

            coned.utils.addGeneralListeners($schedulePaymentButton, schedulePaymentClickEvent);

            if (_isPaymentAgreementEditable) {
                $enrollFormSelector.addEventListener('submit', acceptPaymentAgreement);

                _.each($openEditPaymentAgreementPopupButton, function ($element) {
                    coned.utils.addGeneralListeners($element, openEditPaymentAgreementPopup);
                });
            } else {
                $enrollFormSelector.addEventListener('submit', acceptPaymentAgreement);
            }
            $manageFormSelector.addEventListener('submit', manageSubmitEvent);
            coned.utils.addGeneralListeners(
                $manageSchedulePaymentButton,
                manageSchedulePaymentClickEvent
            );

            if ($progressBar) {
                initializeProgressBar();
            }

            $oruEnrollConfirmation.addEventListener('submit', confirmEnroll);
            coned.utils.addGeneralListeners($cancelEnrollButton, cancelEnrollEvent);

            _.each($closeSelectors, function ($closeSelector) {
                coned.utils.addGeneralListeners($closeSelector, function () {
                    dataLayer.push({
                        contentTitle: CONSTANTS.CONTENT_TITLE_TAG,
                        event: CONSTANTS.PAYMENT_AGREEMENT_FORM_CANCEL_TAG
                    });
                });
            });

            // Tagging Events
            coned.utils.addGeneralListeners($linkAccordionSelector, taggingStartPaymentAgreement);
            coned.utils.addGeneralListeners($manageSchedulePaymentButton, taggingSchedulePayment);
            coned.utils.addGeneralListeners($makePaymentButton, taggingMakePayment);
            if (_isPaymentAgreementEditable) {
                coned.utils.addGeneralListeners($schedulePaymentButton, taggingSchedulePayment);

                _.each($openEditPaymentAgreementPopupButton, function ($element) {
                    coned.utils.addGeneralListeners($element, taggingEditPaymentAgreement);
                });
            }

            for (
                var radioButtonsIndex = 0;
                radioButtonsIndex < $radioButtonsSelector.length;
                radioButtonsIndex++
            ) {
                $radioButtonsSelector[radioButtonsIndex].addEventListener('focusin', function () {
                    var actualElement = this.parentElement.getElementsByClassName(
                        CONSTANTS.RADIO_INDICATOR
                    )[0];
                    query.addClass(actualElement, CONSTANTS.RADIO_INDICATOR_FOCUS);
                });

                $radioButtonsSelector[radioButtonsIndex].addEventListener('focusout', function () {
                    var actualElement = this.parentElement.getElementsByClassName(
                        CONSTANTS.RADIO_INDICATOR
                    )[0];
                    query.removeClass(actualElement, CONSTANTS.RADIO_INDICATOR_FOCUS);
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DashboardPaymentAgreementOru.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DashboardPaymentAgreementOru;
})();
