// ==================== REPORT OUTAGE COMPONENT =========================
/* global _ */
/* global $ */
/* global gsap */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ReportOutage = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM: '.js-report-outage-form',
        HAVE_POWER_SELECT: 'js-have-power',
        TREE_WIRE_PROBLEM_CONTAINER: 'js-tree-wire-problem-container',
        DAMAGED_WIRE_RADIO_LABEL: 'js-damaged-wire-radio-label',
        DAMAGED_WIRE_YES_RADIO: 'js-damaged-wire-yes',
        DAMAGED_WIRE_NO_RADIO: 'js-damaged-wire-no',
        FIND_ACCOUNT_SELECT: 'js-find-account',
        FIND_BY_ACCOUNT: 'account',
        FIND_BY_PHONE: 'phone',
        FIND_ADDRESS: 'zipcode',
        LOCATION_STEP: 'js-location',
        CONTACT_STEP: 'js-contact-details',
        STEP_ACTIVE: 'report-outage__step--active',
        ACCOUNT_OPTION_ACTIVE: 'report-outage__account-option--active',
        ACCOUNT_OPTION: 'js-account-option',
        ACCOUNT_NUMBER: 'js-coned-account-number',
        BANNER_CONTAINER: 'js-banner-container',
        SUBMIT_CONTAINER: 'js-submit-progress-animation',
        SUBMIT_CONTAINER_ACTIVE: 'report-outage__animation--active',
        SEARCH_BY_ADDRESS_LINK: 'js-search-address-link',
        FIND_ACCOUNT_CONTAINER: 'js-find-account-container',
        FIND_ACCOUNT_BY_NUMBER: 'js-find-account-by-number',
        FIND_ACCOUNT_BY_ADDRESS: 'js-find-by-address',
        ADDRESS_LOOKUP_FORM: 'js-address-lookup',
        AUTOCOMPLETE_ZIPCODE: 'js-autocomplete-zipcode',
        AUTOCOMPLETE_APARMENT_UNIT: 'js-autocomplete-apartment-unit',
        AUTOCOMPLETE_SELECTED_ADDRESS: 'js-address-autocomplete-selected',
        ADDRESS_SELECTED_CLASS: 'js-address-autocomplete-selected',
        ADDRESS_SELECTED_DETAIL_CLASS: 'js-address-selected-detail',
        ADDRESS_SELECTED_MASKED_ACCOUNT_NUMBER: 'js-address-masked-account-number',
        ACCOUNT_NUMBER_MASKED_PART: '***********',
        DIM_FLICKER_RADIO_LABEL: 'js-dim-flicker-radio-label',
        DIM_FLICKER_YES_RADIO: 'js-dim-flicker-yes',
        DIM_FLICKER_NO_RADIO: 'js-dim-flicker-no',
        FLICKER_LIGHTS_HIDDEN: 'js-flicker-lights-hidden',
        FIND_ADDRESS_BUTTON: 'js-lookup-button',
        ADDRESS_RECORD_CONTAINER: 'js-address-record-container',
        OTHER_DETAILS_CONTAINER: 'js-outage-other-details-container',
        NOT_MY_ADDRESS_LINK: 'js-not-my-address-link',
        FIND_BY_OPTION: 'js-find-by-option',
        FIND_BY_OPTION_ACTIVE: 'js-find-by-option-active',
        ACCOUNT_NUMBER_INPUT: 'js-find-number-input',
        MASK_ACTIVE: 'report-outage__mask--active',
        VALID_ITEM: 'js-item-validate',
        FORM_SUBMIT_BUTTON: 'js-submit-outage',
        ADDRESS_SELECTED: 'js-dropdown-button',
        ADRESS_TITLE: 'address-box__container-title',
        ADRESS_SUBTITLE: 'address-box__container-subtitle',
        FULL_NAME: 'js-full-name',
        FULL_NAME_HAS_POWER: 'js-full-name-has-power',
        CONTACT_NUMBER: 'js-contact-number',
        CONTACT_NUMBER_HAS_POWER: 'js-contact-number-has-power',
        NO_PARTIAL_POWER_FORM: 'js-no-partial-power-form',
        HAS_POWER_FORM: 'js-has-power-form',
        FINISH_REPORT_STREET_ADDRESS: 'js-street-address',
        FINISH_REPORT_ACCOUNT: 'js-account-info',
        FINISH_REPORT_CITY: 'js-city',
        FINISH_REPORT_CONTACT_NAME: 'js-contact-info-name',
        FINISH_REPORT_CONTACT_NUMBER: 'js-contact-info-number',
        FINISH_REPORT_BANNER_CONTAINER: 'js-finish-report-container',
        FINISH_REPORT_BANNER: 'js-finish-report',
        FINISH_REPORT_BANNER_HAS_POWER: 'js-finish-report-has-power',
        FINISH_REPORT_BANNER_FOCUS: 'js-finish-report-focus',
        FINISH_REPORT_ETR: 'js-etr',
        FINISH_REPORT_DUPLICATE : 'js-duplicate-outage',
        OUTAGE_MY_ADDRESS_CHECK: 'js-outage-my-address',
        NOT_MY_ADDRESS_WRAPPER: 'js-not-my-address-wrapper',
        PHONE_CALL_RADIO_BUTTON: 'js-phone-call',
        PHONE_TEXT_RADIO_BUTTON: 'js-phone-text',
        LOOKUP_RESULT: 'js-address-lookup-result',
        PARTIAL_OPTIONS_CONTAINER: 'js-partial-options',
        OTHER_AFFECTED_DESCRIPTION: 'js-other-affected-description',
        OTHER_AFFECTED_CONTAINER: 'js-others-affected-container',
        OTHER_AFFECTED_ALERT_CONTAINER: 'js-affected-alert-container',
        OTHER_AFFECTED_ALERT: 'js-affected-alert',
        OTHER_AFFECTED_ACTIVE: 'report-outage__affected-container--active',
        OTHER_ISSUES_OPTIONS: 'js-other-issue-options',
        OTHER_ISSUES_SELECT: 'js-other-issue',
        OTHER_ISSUES_BANNER: 'js-banner-other-issue',
        OTHER_AFFECTED_SELECT: 'js-other-affected',
        WIRE_DOWN_SELECT: 'js-wire-down-select',
        TREE_BRANCH_SELECT: 'js-tree-branch-down',
        ISSUE_DETAILS_CONTAINER: 'js-issue-details-container',
        DROPDOWN_LABEL_SELECTOR: 'js-coned-select-label',
        DROPDOWN_CLASS: 'js-coned-select',
        ACTIVE_DROPDOWN_CLASS: 'coned-select--active',
        ACTIVE_LABEL_DROPDOWN_CLASS: 'coned-select__label--active',
        INPUT_CLASS: 'js-coned-input',
        HIDDEN_CLASS: 'hidden',
        INPUT_DISABLED_CLASS: 'coned-field--disabled',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        BOROUGH_DROPDOWN_CONTAINER: 'js-borough-container',
        BOROUGH_DROPDOWN_SELECT: 'js-borough-select',
        FORM_CLASS: 'js-report-outage-form',
        FORM_ACCOUNT_NUMBER: 'accountNumber',
        FORM_CONTACT_NAME: 'fullName',
        FORM_CONTACT_NAME_HAS_POWER: 'fullNameHasPower',
        FORM_PHONE_NUMBER: 'contactNumber',
        FORM_PHONE_NUMBER_HAS_POWER: 'contactNumberHasPower',
        FORM_WIRE_LOCATION: 'wireLocation',
        FORM_WIRE_CONDITION: 'wireTreeCondition',
        FORM_SPARKING_FIRE: 'sparkingFire',
        FORM_TREE_LOCATION: 'treeLocation',
        FORM_HAVE_POWER: 'havePower',
        FORM_OTHERS_AFFECTED: 'otherAffected',
        FORM_HAVE_POWER_SERVICE_ISSUE: 'otherIssue',
        FORM_BOROUGH: 'borough',
        FORM_FIND_BY: 'findAccount',
        FORM_SCID: 'ScId',
        FORM_OTHER_STREET_NUMBER: 'OtherLocationStreetNumber',
        FORM_OTHER_STREET: 'OtherLocationStreet',
        FORM_OTHER_BOROUGH_COUNTY: 'OtherLocationBoroughCounty',
        LOOKUP_SERVICE_ERROR: 'js-lookup-service-error',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        ADDRESS_DROPDOWN_MODULE: 'js-address-dropdown',
        ADDRESS_DROPDOWN_LIST: 'js-dropdown-list',
        ADDRESS_DROPDOWN_LIST_ITEM: 'js-dropdown-item',
        ADDRESS_DROPDOWN_LIST_ITEM_CLASS: 'address-dropdown__list-item',
        ADDRESS_DROPDOWN_ITEM_CONTAIN: 'js-dropdown-item-contain',
        ADDRESS_DROPDOWN_BOX: 'address-box',
        ADDRESS_DROPDOWN_BOX_ITEM: 'address-box--item',
        ADDRESS_DROPDOWN_BOX_CONTAINER: 'address-box__container',
        ADDRESS_DROPDOWN_BOX_INFO: 'address-box__info',
        ADDRESS_DROPDOWN_BOX_TITLE: 'address-box__container-title',
        ADDRESS_DROPDOWN_BOX_TITLE_NON_AUTENTICATED:
            'address-box__container-title--non-authenticated',
        ADDRESS_DROPDOWN_BOX_SUBTITLE: 'address-box__container-subtitle',
        ADDRESS_DROPDOWN_BOX_ICON: 'address-dropdown__icon',
        ADDRESS_DROPDOWN_BOX_ICON_CHECK_CLASS: 'address-dropdown__icon--check',
        ADDRESS_DROPDOWN_BOX_ICON_CHECK: 'js-icon-check',
        ADDRESS_DROPDOWN_BOX_CARROT: 'js-dropdown-carrot',
        ACCOUNT_DATA: 'js-dropdown-button-contain',
        WIRE_DOWN_OPTIONS: 'js-wire-down-options-container',
        WIRE_LOCATION_OTHER_CONTAINER: 'js-wire-location-other-container',
        HAVE_DIRECTIONS: 'js-no-directions',
        ADDRESS_DESCRIPTION: 'js-my-address-description',
        REQUIRED_FIELD: 'required',
        VALID_CLASS: 'valid',
        TRANSACTIONAL_WRAPPER: 'transactional__wrapper',
        MARGIN_BOTTOM: 'transactional__wrapper--margin-bottom',
        NOTIFICATION_MESSAGE_PARAGRAPH: 'js-notification-message',
        NOTIFICATION_CONTAINER_CLASS: 'js-notification-container',
        CELLULAR_LABEL: 'js-cellular-label',
        LANDLINE_LABEL: 'js-landline-label',
        CELLULAR_PHONE_TYPE: 'Cellular',
        CAPTCHA_ELEMENT_CLASS: 'js-recaptcha',
        UNKNOWN_VALUE: 'Unknown',
        PARAGRAPH_TAG: 'p',
        AFFECTED_ALERT_TITLE_CLASS: 'report-outage__affected-alert-title',
        AFFECTED_ALERT_DESCRIPTION_CLASS: 'report-outage__affected-alert-description',
        INPUT_MESSAGE_ERROR: 'coned-input-message--error',
        SYSTEM_OUT_PARAMETER: 'system-out',
        DATA_INPUT_SHOULD_START_VALIDATE: 'data-input-should-start-validate',
        VALIDATE_SHOW_CONTACT_STEP_SELECTOR: 'js-validate-show-contact-step',
        FORM_IGNORE_VALIDATION: ':hidden',
        SHOW_ACTION: 'show',
        HIDE_ACTION: 'hide',
        EMPTY_STRING: '',
        TRUE_VALUE: true,

        // A11y attributes
        TABINDEX: 'tabindex',
        ARIA_REQUIRED: 'aria-required',
        ARIA_INVALID: 'aria-invalid',

        // Tagging
        YES_VALUE: 'YES',
        NO_VALUE: 'NO'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ReportOutage = function ($reportOutage) {
        /**
         * PRIVATE METHODS
         */
        var $havePowerSelect,
            $treeWireProblemContainer,
            $damagedWireRadioLabel,
            $damagedWireYesRadio,
            $damagedWireNoRadio,
            $damagedWireRadios,
            $findaccountContainer,
            $findAccountSelect,
            $dimFlickerRadioLabel,
            $dimFlickerYesRadio,
            $dimFlickerNoRadio,
            $dimFlickerRadios,
            $flickerLightsHidden,
            $locationStep,
            $contactStep,
            $searchByAddressLink,
            $addressLookupForm,
            $autocompleteZipCode,
            $autocompleteAparmentUnitInput,
            $autocompleteSelectedAddress,
            $addressSelectedDetail,
            $addressSelected,
            $finishBannerContainer,
            $finishBannerFocus,
            $finishTextAccountNumber,
            $finishTextStreetAddress,
            $finishTextCity,
            $finishOtherAffected,
            $finishTextContactName,
            $finishTextContactNumber,
            $finishTextETR,
            $finishDuplicateReport,
            $bannerContainer,
            $submitContainer,
            $findAddressButton,
            $addressRecordContainer,
            $otherDetailsContainer,
            $notMyAddressLink,
            $othersAffectedContainer,
            $otherAffectedSelect,
            $otherAffectedAlertContainer,
            $findAccountByAddress,
            $findAccountByNumber,
            $submitButton,
            $findAddressButtonContainer,
            $submitButtonContainer,
            $notMyAddressWrapper,
            $authenticatedMyAddressCheckbox,
            $accountNumberInput,
            $lookupResult,
            $otherIssuesSelect,
            $otherIssuesBanner,
            $otherIssuesContainer,
            $partialOptionsContainer,
            $treeBranchSelect,
            $wireDownSelect,
            $issueDetailContainer,
            $boroughDropdownContainer,
            $boroughDropdownSelect,
            $lookupError,
            $serviceError,
            $wireLocationOtherContainer,
            $wireDownOptions,
            $accountData,
            $addressDescription,
            $transactionalWrapper,
            $contactNumber,
            $phoneCallRadio,
            $phoneTextRadio,
            $noPartialPowerForm,
            $hasPowerForm,
            $contactNumberHasPower,
            $cellularLabel,
            $landlineLabel,
            $captchaElement,
            $otherAffectedAlert,
            $findByActiveOption,
            $body,
            $validateShowContactStepElements,
            _othersDisabled,
            _hasAddress,
            _lastDigitsAccountNumber,
            _houseNumber,
            finalDetailDirection,
            recaptcha,
            _hasPower,
            _noDirections,
            _wiresDown,
            _treeOrBranchOnWire,
            _userAuthenticated,
            _mdacValue,
            _data,
            _dataOutageService,
            _hasCaptcha,
            _initialLoad;

        var fieldsMask = function ($element, height) {
            var $mask = $element.parentNode,
                elementHeight = height ? height : $element.offsetHeight;

            gsap.to($mask, 0.4, {
                minHeight: elementHeight,
                onComplete: toggleMask
            });

            function toggleMask() {
                $mask.classList.toggle(CONSTANTS.MASK_ACTIVE);
            }
        };

        var adjustMasks = function () {
            fieldsMask($addressRecordContainer);
            fieldsMask($findAccountByAddress);
            fieldsMask($treeWireProblemContainer);
        };

        var userStatus = function () {
            // user status validation and no directions on account
            if (_userAuthenticated && !_noDirections) {
                validateShowHideContactStep();
            } else {
                $submitContainer.classList.add(CONSTANTS.SUBMIT_CONTAINER_ACTIVE);
                $locationStep.classList.add(CONSTANTS.STEP_ACTIVE);
            }

            checkForDirections();
        };

        var scrollToStep = function ($element) {
            // scroll into active section
            $element.scrollIntoView(true);
            var scrolled = window.pageYOffset;

            if (scrolled) {
                window.scroll(0, scrolled - 100);
            }
        };

        var recaptchaValidation = function () {
            var formStatus = $($reportOutage).valid();

            if (formStatus) {
                new coned.components.SubmitAnimation(
                    $submitButtonContainer,
                    outageServiceCall,
                    successOutageService,
                    errorOutageService,
                    false,
                    true
                );
            }
        };

        var lookUpAddress = function (event) {
            event.preventDefault();

            $findByActiveOption = $reportOutage.getElementsByClassName(
                CONSTANTS.ACCOUNT_OPTION_ACTIVE
            )[0];

            $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);

            $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $findAddressButtonContainer.classList.remove(CONSTANTS.ERROR_SERVICE_RESPONSE_CLASS);
            $findAddressButtonContainer.classList.remove(CONSTANTS.SUCCESS_SERVICE_RESPONSE_CLASS);
            $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $autocompleteSelectedAddress.classList.add(CONSTANTS.HIDDEN_CLASS);

            if ($otherDetailsContainer) {
                $otherDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                fieldsMask($otherDetailsContainer, 0);
            }

            //Hide other affected issues
            if ($othersAffectedContainer) {
                $othersAffectedContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                fieldsMask($othersAffectedContainer, 0);
                resetDropdownLabel($otherAffectedSelect);
                destroyAlertBody();
                $otherAffectedAlertContainer.classList.remove(CONSTANTS.OTHER_AFFECTED_ACTIVE);
            }

            var isFormValid = $($reportOutage).valid();

            if (isFormValid) {
                if (!$findByActiveOption) {
                    changeInputsState();
                } else {
                    changeInputsState(true);
                }

                new coned.components.SubmitAnimation(
                    $findAddressButtonContainer,
                    lookUpAccount,
                    successLookUpAccountService,
                    errorLookUpAccountService,
                    false
                );
            } else {
                coned.utils.focusFirstFormInputField($reportOutage, CONSTANTS.INPUT_MESSAGE_ERROR);
            }
        };

        var lookUpAccount = function () {
            var lookAccountServiceUrl = $reportOutage.dataset.lookAccountService,
                notFoundAccountMessage = $reportOutage.dataset.notFoundAccountMessage,
                createOutageNotAllowedMessage = $reportOutage.dataset.createOutageNotAllowedMessage,
                accountNumber = query.getFormInputValue(
                    $reportOutage,
                    CONSTANTS.FORM_ACCOUNT_NUMBER
                ),
                filterValue = $reportOutage.dataset.filterByCreateOutage
                    ? $reportOutage.dataset.filterByCreateOutage
                    : false,
                maid = $addressSelectedDetail.dataset.maid,
                lookUpAccountParams,
                findBy,
                phoneNumber;

            if (!_userAuthenticated) {
                var $activeInput;

                if ($findByActiveOption) {
                    $activeInput = $findByActiveOption.getElementsByClassName(
                        CONSTANTS.INPUT_CLASS
                    )[0];
                    phoneNumber = $activeInput.value;

                    if (query.hasClass($activeInput, CONSTANTS.ACCOUNT_NUMBER)) {
                        phoneNumber = '';
                        findBy = CONSTANTS.FIND_BY_ACCOUNT;
                    } else {
                        findBy = CONSTANTS.FIND_BY_PHONE;
                    }
                }
            }

            //Fill the FindBy property for new experience
            if (
                (accountNumber === '' && $autocompleteZipCode && $autocompleteZipCode.value) ||
                _userAuthenticated
            ) {
                findBy = CONSTANTS.FIND_ADDRESS;
                phoneNumber = '';
            }

            lookUpAccountParams = {
                ScId: query.getFormInputValue($reportOutage, CONSTANTS.FORM_SCID),
                AccountNumber: accountNumber,
                AccountMaid: maid,
                PhoneNumber: phoneNumber,
                FindBy: findBy,
                FiltertByAllowCreateOutage: filterValue,
                Borough: query.getFormInputValue($reportOutage, CONSTANTS.FORM_BOROUGH),
                NotFoundAccountMessage: notFoundAccountMessage,
                CreateOutageNotAllowedMessage: createOutageNotAllowedMessage
            };

            if (coned.utils.isPatternLab()) {
                if (_userAuthenticated) {
                    query.getData(
                        coned.plConstants.GET_LOOKUP_ACCOUNT,
                        successLookupCallback,
                        function () {}
                    );
                } else if (
                    $findAccountSelect.options[$findAccountSelect.selectedIndex].value ==
                    CONSTANTS.FIND_BY_PHONE
                ) {
                    query.getData(
                        coned.plConstants.GET_LOOKUP_ACCOUNTS,
                        successLookupCallback,
                        function () {}
                    );
                } else {
                    query.getData(
                        coned.plConstants.GET_LOOKUP_ACCOUNT,
                        successLookupCallback,
                        function () {}
                    );
                }
            } else {
                lookUpAccountParams = JSON.stringify(lookUpAccountParams);

                query.postData(
                    lookAccountServiceUrl,
                    successLookupCallback,
                    errorLookupCallback,
                    lookUpAccountParams,
                    true
                );
            }
        };

        var successLookUpAccountService = function () {
            var otherDetailContainer = false;
            $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $findAddressButtonContainer.classList.add(
                coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
            );

            // Show, hide, active elements and disable dropdowns
            $findAddressButtonContainer.classList.remove(CONSTANTS.SUBMIT_CONTAINER_ACTIVE);
            $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $issueDetailContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $wireDownSelect.disabled = false;
            $otherAffectedSelect.disabled = false;
            $submitButton.disabled = false;

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.step02'
            });

            if (!$findByActiveOption) {
                if ($otherDetailsContainer) {
                    $otherDetailsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    fieldsMask($otherDetailsContainer);
                    otherDetailContainer = true;
                }
            } else {
                $addressRecordContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                fieldsMask($addressRecordContainer);
                if ($otherDetailsContainer) {
                    $otherDetailsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    fieldsMask($otherDetailsContainer);
                }
            }

            _hasAddress = true;
            setAccount(_data);

            if (!query.hasClass($body, coned.constants.MOUSE_USER)) {
                var focusElement;
                //If the user selected the power lines down on Yes, focus should go to  the wires down select
                if ($damagedWireYesRadio.checked) {
                    focusElement = $wireDownSelect;
                } else {
                    //If the user selected the power lines down on No, focus should go to the others affected select or the address record container 
                    focusElement =  otherDetailContainer
                        ? $otherDetailsContainer.getElementsByClassName(
                            CONSTANTS.OTHER_AFFECTED_SELECT
                        )[0]
                        : $addressRecordContainer.getElementsByClassName(CONSTANTS.ADDRESS_SELECTED)[0];
                }

                //For unauthenticated users, if the user wires down options the focus should go to that select
                if(!_userAuthenticated) {

                    if (!$findByActiveOption) {                        
                        var otherIssueSelectedOption = $otherIssuesSelect.options[$otherIssuesSelect.selectedIndex].value;

                        if(otherIssueSelectedOption === _wiresDown) {
                            focusElement = $wireDownSelect;
                        } else if (otherIssueSelectedOption === _treeOrBranchOnWire) {
                            focusElement = $treeBranchSelect;
                        }
                    } else {
                        focusElement = $addressRecordContainer.getElementsByClassName(CONSTANTS.ADDRESS_SELECTED)[0];
                    }
                }

                focusElement.focus();
            }
        };

        var errorLookUpAccountService = function () {
            var $msgTextSelector = $lookupError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $lookupError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML =
                _data && _data.errorMsg ? _data.errorMsg : coned.constants.ERROR_MESSAGE;
            $findAddressButtonContainer.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            $lookupError.focus();
            $submitButton.disabled = true;
            changeInputsState(false, true);
        };

        var successLookupCallback = function (data) {
            _data = data;
            $findAddressButtonContainer.classList.add(
                coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
            );
        };

        var errorLookupCallback = function (data) {
            _data = data;
            $findAddressButtonContainer.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
        };

        var reportOutageSubmit = function () {
            // Prevent submit on enter if contact step is hidden as submit button lives there
            if (!query.hasClass($contactStep, CONSTANTS.HIDDEN_CLASS)) {
                if (_userAuthenticated || !_hasCaptcha) {
                    new coned.components.SubmitAnimation(
                        $submitButtonContainer,
                        outageServiceCall,
                        successOutageService,
                        errorOutageService,
                        false,
                        true
                    );
                } else {
                    if (recaptcha && recaptcha.getResponse() === '') {
                        recaptcha.checkRecaptcha();
                    } else if (recaptcha && recaptcha.getResponse() !== '') {
                        recaptcha.reset();
                        recaptcha.checkRecaptcha();
                    }
                }
            }
        };

        var outageServiceCall = function () {
            var outageServiceUrl = $reportOutage.dataset.serviceUrl,
                response = _hasCaptcha ? recaptcha.getResponse() : null,
                outageParams,
                mdacRuleValue = parseInt($reportOutage.dataset.multiDwellingCode, 10);
            
            //If the user is authenticated we should check if they are reporting there own address or other.
            if (_userAuthenticated) {
                //If the user is reporting other address the service url changes to allow the report.
                if (!$authenticatedMyAddressCheckbox.checked) {
                    outageServiceUrl = $reportOutage.dataset.serviceUaUrl;                    
                }
            }

            // service data
            outageParams = {
                ScId: query.getFormInputValue($reportOutage, CONSTANTS.FORM_SCID),
                Maid: $accountData.dataset.accountMaid,
                ContactName: _hasPower
                    ? query.getFormInputValue($reportOutage, CONSTANTS.FORM_CONTACT_NAME_HAS_POWER)
                    : query.getFormInputValue($reportOutage, CONSTANTS.FORM_CONTACT_NAME),
                PhoneNumber: _hasPower
                    ? query.getFormInputValue($reportOutage, CONSTANTS.FORM_PHONE_NUMBER_HAS_POWER)
                    : query.getFormInputValue($reportOutage, CONSTANTS.FORM_PHONE_NUMBER),
                ReceiveSMSAlerts: false,
                WireLocation: query.getFormInputValue($reportOutage, CONSTANTS.FORM_WIRE_LOCATION),
                WireCondition: query.getFormInputValue(
                    $reportOutage,
                    CONSTANTS.FORM_WIRE_CONDITION
                ),
                SparkingOrFire: query.getFormInputValue(
                    $reportOutage,
                    CONSTANTS.FORM_SPARKING_FIRE
                ),
                PoleCondition: '',
                DescriptionOther: '',
                Channel:
                    $phoneTextRadio && $phoneTextRadio
                        ? $phoneTextRadio.checked
                            ? $phoneTextRadio.value
                            : $phoneCallRadio.value
                        : '',
                TreeLocation: query.getFormInputValue($reportOutage, CONSTANTS.FORM_TREE_LOCATION),
                OtherLocationStreetNumber: query.getFormInputValue(
                    $reportOutage,
                    CONSTANTS.FORM_OTHER_STREET_NUMBER
                ),
                OtherLocationStreet: query.getFormInputValue(
                    $reportOutage,
                    CONSTANTS.FORM_OTHER_STREET
                ),
                OtherLocationBoroughCounty: query.getFormInputValue(
                    $reportOutage,
                    CONSTANTS.FORM_OTHER_BOROUGH_COUNTY
                ),
                HavePower: query.getFormInputValue($reportOutage, CONSTANTS.FORM_HAVE_POWER),
                DueToDownedOrDamagedWires: $damagedWireYesRadio.checked
                    ? true
                    : false,
                OthersAffected: query.getFormInputValue(
                    $reportOutage,
                    CONSTANTS.FORM_OTHERS_AFFECTED
                )
                    ? query.getFormInputValue($reportOutage, CONSTANTS.FORM_OTHERS_AFFECTED)
                    : _mdacValue > mdacRuleValue
                    ? ''
                    : CONSTANTS.UNKNOWN_VALUE,
                DimFlicker: $dimFlickerYesRadio.checked
                    ? true
                    : false,
                HavePowerServiceIssue: query.getFormInputValue(
                    $reportOutage,
                    CONSTANTS.FORM_HAVE_POWER_SERVICE_ISSUE
                ),
                Comment: '',
                PhoneExtension: '',
                MedicalEmergencyFlag: true,
                Alorica: $reportOutage.dataset.alorica,
                FromAccountList: $authenticatedMyAddressCheckbox
                    ? $authenticatedMyAddressCheckbox.checked
                    : false,
                CheckoutageScId: $reportOutage.dataset.checkoutageScid
            };

            if (_hasCaptcha && response && response !== '') {
                outageParams.Verify = response;
            }

            outageParams = JSON.stringify(outageParams);

            // service call
            if (coned.utils.isPatternLab() && _userAuthenticated) {
                query.getData(
                    coned.plConstants.GET_NOTIFICATION_MESSAGE,
                    successReportCallback,
                    errorReportCallback
                );
            } else {
                query.postData(
                    outageServiceUrl,
                    successReportCallback,
                    errorReportCallback,
                    outageParams,
                    true
                );
            }
        };

        var successOutageService = function () {
            $reportOutage.classList.add(CONSTANTS.HIDDEN_CLASS);

            var addressParent;

            if ($authenticatedMyAddressCheckbox && !$authenticatedMyAddressCheckbox.checked) {
                addressParent = $addressRecordContainer.getElementsByClassName(
                    CONSTANTS.ADDRESS_SELECTED
                )[0];
            } else {
                addressParent = $reportOutage.getElementsByClassName(CONSTANTS.ADDRESS_SELECTED)[0];
            }

            var addressTitleValue = addressParent.getElementsByClassName(CONSTANTS.ADRESS_TITLE)[0]
                    .innerHTML,
                addressSubtitleValue = addressParent.getElementsByClassName(
                    CONSTANTS.ADRESS_SUBTITLE
                )[0].innerHTML,
                affectedValue =
                    $otherAffectedSelect.options[$otherAffectedSelect.selectedIndex].text,
                affectedOption =
                    $otherAffectedSelect.options[$otherAffectedSelect.selectedIndex].value,
                fullNameValue = _hasPower
                    ? $reportOutage.getElementsByClassName(CONSTANTS.FULL_NAME_HAS_POWER)[0].value
                    : $reportOutage.getElementsByClassName(CONSTANTS.FULL_NAME)[0].value,
                contactPhoneValue = _hasPower
                    ? $contactNumberHasPower.value.trim()
                    : $contactNumber.value.trim(),
                $finishBanner = _hasPower
                    ? document.getElementsByClassName(CONSTANTS.FINISH_REPORT_BANNER_HAS_POWER)[0]
                    : document.getElementsByClassName(CONSTANTS.FINISH_REPORT_BANNER)[0];

            if (!_userAuthenticated) {
                var addressData = addressParent.getElementsByClassName(CONSTANTS.ACCOUNT_DATA)[0];
                _lastDigitsAccountNumber = 
                    addressData.dataset.lastDigitsAccountNumber;
                _houseNumber =
                    addressData.dataset.street.substr(
                        0,
                        parseInt($reportOutage.dataset.streetAddressLength)
                    );
            }

            // set the values and show the message
            $finishBannerContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $finishBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.success'
            });

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($reportOutage);

            // finish address and account number
            if (!_userAuthenticated) {
                $finishTextAccountNumber.innerHTML = $finishTextAccountNumber.innerHTML.replace('{{ACCOUNT}}', '<b>' + _lastDigitsAccountNumber + '</b>'); 
                $finishTextStreetAddress.innerHTML = $finishTextStreetAddress.innerHTML.replace('{{ADDRESS}}', '<b>' + _houseNumber + '</b>');

            } else {
                $finishTextAccountNumber.classList.add(CONSTANTS.HIDDEN_CLASS);
                $finishTextCity.classList.add(CONSTANTS.HIDDEN_CLASS);
                $finishTextStreetAddress.innerHTML = $finishTextStreetAddress.innerHTML.replace('{{ADDRESS}}', '<b>' + addressTitleValue + ' ' + addressSubtitleValue + '</b>');
            }

            // others affected
            if (affectedOption == 'default') {
                $finishOtherAffected.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                $finishOtherAffected.innerHTML = $finishOtherAffected.innerHTML.replace('{{OTHERS}}', '<b>' +  affectedValue + '</b>');
            }

            // full name
            $finishTextContactName.innerHTML = $finishTextContactName.innerHTML.replace('{{NAME}}', '<b>' + fullNameValue + '</b>');

            // phone
            $finishTextContactNumber.innerHTML = $finishTextContactNumber.innerHTML.replace('{{NUMBER}}', '<b>' + contactPhoneValue + '</b>');

            // notification message
            if (_dataOutageService) {
                //If there is an ETR, show the ETR message
                $finishTextETR.innerHTML = $finishTextETR.innerHTML.replace('{{ETR}}', _dataOutageService.ReportOutageETR);
                
                // Analytics confirmation ETR
                dataLayer.push({
                    event: 'virtualPageView',
                    virtualPageUrl: '/en/report-outage/confirmation-etr'
                });

                //Check if the outage is a duplicate
                var duplicate = _dataOutageService.Duplicate === "true" ? true : false;
                if(duplicate) {
                    $finishDuplicateReport.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }else {
                    $finishDuplicateReport.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
                
            }

            $finishBannerFocus.focus();
        };

        var errorOutageService = function () {
            var $msgTextSelector = $serviceError.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            )[0];
            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML =
                _dataOutageService && _dataOutageService.errorMsg
                    ? _dataOutageService.errorMsg
                    : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
            $submitButtonContainer.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
        };

        var successReportCallback = function (dataOutageService) {
            if (coned.utils.isPatternLab()) {
                _dataOutageService = query.getData(
                    coned.plConstants.GET_REPORT_OUTAGE_CONFIRMATION_SUCCESS,
                    setDataOutage,
                    false
                );
            } else {
                _dataOutageService = dataOutageService;
            }

            $submitButtonContainer.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
        };

        var setDataOutage = function (data) {
            _dataOutageService = data;
        };

        var errorReportCallback = function (dataOutageService) {
            _dataOutageService = dataOutageService;
            $submitButtonContainer.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
        };

        var authenticatedMyAddressChange = function () {
            if ($authenticatedMyAddressCheckbox.checked) {
                _.each($notMyAddressWrapper, function ($wrapper) {
                    $wrapper.classList.remove(CONSTANTS.HIDDEN_CLASS);
                });

                query.fireEvent($autocompleteZipCode, 'cleanUp');

                if(!_initialLoad) {
                    $contactStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    _initialLoad = false;
                }
                
                $submitButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $findAccountByAddress.classList.add(CONSTANTS.HIDDEN_CLASS);
                $lookupResult.classList.add(CONSTANTS.HIDDEN_CLASS);
                $issueDetailContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $findAccountByAddress.classList.remove(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

                changeInputsState();
                mdacValidation();
                othersAffected();

                if ($damagedWireYesRadio.checked) {
                    $wireDownOptions.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }
            } else {
                _.each($notMyAddressWrapper, function ($wrapper) {
                    $wrapper.classList.add(CONSTANTS.HIDDEN_CLASS);
                });

                $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);
                $issueDetailContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAccountByAddress.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $findAccountByAddress.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                $wireDownOptions.classList.add(CONSTANTS.HIDDEN_CLASS);
                $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
                $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
                changeInputsState(true);
                $autocompleteZipCode.value = '';
                $autocompleteZipCode.disabled = false;
                $autocompleteZipCode.parentNode.classList.remove(
                    CONSTANTS.INPUT_DISABLED_CLASS
                );
                $findAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                if ($othersAffectedContainer) {
                    $othersAffectedContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                }
            }
        };

        var generateAlertBody = function () {
            var titleMessage = $otherAffectedAlert.dataset.alertTitle;
            var titleType = $otherAffectedAlert.dataset.alertTitleType;
            var titleNodeElement = document.createElement(titleType);
            titleNodeElement.classList.add(CONSTANTS.AFFECTED_ALERT_TITLE_CLASS);
            titleNodeElement.innerText = titleMessage;

            var descriptionMessage = $otherAffectedAlert.dataset.alertDescription;
            var descriptionType = CONSTANTS.PARAGRAPH_TAG;
            var descriptionNodeElement = document.createElement(descriptionType);
            descriptionNodeElement.classList.add(CONSTANTS.AFFECTED_ALERT_DESCRIPTION_CLASS);
            descriptionNodeElement.innerText = descriptionMessage;

            $otherAffectedAlert.appendChild(titleNodeElement);
            $otherAffectedAlert.appendChild(descriptionNodeElement);
        };

        var destroyAlertBody = function () {
            $otherAffectedAlert.innerText = '';
        };

        /**
         * Handles others affected dropdown selection. 
         * Depending on mdac values, and other options, shows or hides contact step and alert.
         * Sets global _othersDisabled flag.
         */
        var othersAffected = function () {
            var otherAffectedOption =
                    $otherAffectedSelect.options[$otherAffectedSelect.selectedIndex].value,
                othersIssuesOption =
                    $otherIssuesSelect.options[$otherIssuesSelect.selectedIndex].value,
                mdacAccountValue = parseInt($accountData.dataset.mdac, 10),
                mdacRuleValue = parseInt($reportOutage.dataset.multiDwellingCode, 10),
                othersAffectedNo = $reportOutage.dataset.othersAffectedNo,
                generateAlertBodyTimeout;

            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            if (
                mdacAccountValue > mdacRuleValue &&
                otherAffectedOption === othersAffectedNo &&
                !(othersIssuesOption == _wiresDown || othersIssuesOption == _treeOrBranchOnWire)
            ) {
                if (
                    !$otherAffectedAlertContainer.classList.contains(
                        CONSTANTS.OTHER_AFFECTED_ACTIVE
                    )
                ) {
                    $otherAffectedAlertContainer.classList.add(CONSTANTS.OTHER_AFFECTED_ACTIVE);
                    
                    // Set global flag to true to restrict both hiding the alert and showing contact step
                    _othersDisabled = true;
                    // Hide contact step
                    showHideContactStep(CONSTANTS.HIDE_ACTION);
                    //A11y Handlers
                    generateAlertBodyTimeout = setTimeout(function () {
                        generateAlertBody();
                    }, 500);
                }
            } else {
                destroyAlertBody();
                $otherAffectedAlertContainer.classList.remove(CONSTANTS.OTHER_AFFECTED_ACTIVE);

                // Set flag before show hide contact step validation
                _othersDisabled = false;
                // Only show contact step if conditions are met, else hide it
                validateShowHideContactStep();
            }

            if ($damagedWireYesRadio.checked) {
                // Prevent alert being generated after we destroy it
                if (generateAlertBodyTimeout) {
                    clearTimeout(generateAlertBodyTimeout);
                }

                destroyAlertBody();
                $otherAffectedAlertContainer.classList.remove(CONSTANTS.OTHER_AFFECTED_ACTIVE);
                
                // Set flag before show hide contact step validation
                _othersDisabled = false;
                // Only show contact step if conditions are met, else hide it
                validateShowHideContactStep();
            }
        };

        var changeInputsState = function (disabled, noReset) {
            noReset = noReset || false;

            var $lookUpInputs = $findaccountContainer.getElementsByClassName(CONSTANTS.INPUT_CLASS),
                $lookUpDropDowns = $findaccountContainer.getElementsByClassName(
                    CONSTANTS.DROPDOWN_CLASS
                );

            if (disabled) {
                _.each($lookUpInputs, function ($input) {
                    $input.parentNode.classList.add(CONSTANTS.INPUT_DISABLED_CLASS);
                    $input.disabled = true;
                });

                _.each($lookUpDropDowns, function ($dropDown) {
                    $dropDown.disabled = true;
                });
            } else {
                _.each($lookUpDropDowns, function ($dropDown) {
                    $dropDown.disabled = false;
                    if (!noReset) {
                        resetDropdownLabel($dropDown);
                    }
                });

                _.each($lookUpInputs, function ($input) {
                    $input.parentNode.classList.remove(CONSTANTS.INPUT_DISABLED_CLASS);
                    $input.disabled = false;
                });
            }
        };

        var setAccount = function (_data) {
            var $addressDropDownList = _userAuthenticated
                    ? $lookupResult.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_LIST)[0]
                    : $reportOutage.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_LIST)[0],
                $addressItem,
                $addressItemBox,
                $addressBoxContainer,
                $addressBoxInfo,
                $addressTitle,
                $addressSubTitle,
                $addressIcon,
                addressTitleString,
                addressSubTitleString;

            $addressDropDownList.innerHTML = '';
            _.each(_data.Accounts, function (accountItem) {
                $addressItem = document.createElement('li');
                $addressItem.classList.add(CONSTANTS.ADDRESS_DROPDOWN_LIST_ITEM);
                $addressItem.classList.add(CONSTANTS.ADDRESS_DROPDOWN_LIST_ITEM_CLASS);

                $addressItemBox = document.createElement('div');
                $addressItemBox.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX);
                $addressItemBox.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_ITEM);
                $addressItemBox.classList.add(CONSTANTS.ADDRESS_DROPDOWN_ITEM_CONTAIN);
                $addressItemBox.dataset.account = accountItem.AccountNumberRaw;
                $addressItemBox.dataset.accountMaid = accountItem.MaskedAccountNumber
                    ? accountItem.MaskedAccountNumber
                    : '';
                if (!_userAuthenticated) {
                    $addressItemBox.dataset.lastDigitsAccountNumber = 
                        accountItem.AccountNumber.substr(
                            accountItem.AccountNumber.length -
                                parseInt($reportOutage.dataset.accountNumberLength)
                        );
                }

                if(accountItem.hasOwnProperty("ServiceAddress") && accountItem.ServiceAddress !== null){
                    $addressItemBox.dataset.street = accountItem.ServiceAddress.StreetAddress;
                    $addressItemBox.dataset.city = accountItem.ServiceAddress.City;
                    $addressItemBox.dataset.state =  accountItem.ServiceAddress.State;
                    $addressItemBox.dataset.zipcode = accountItem.ServiceAddress.PostalCode;
                    $addressItemBox.dataset.country = accountItem.ServiceAddress.Country;
                    $addressItemBox.dataset.address2 = accountItem.ServiceAddress.Division;
                }
                $addressItemBox.dataset.mdac = accountItem.MultiDwellingAccessCode;
                $addressItemBox.dataset.accountType = accountItem.AccountType;

                $addressBoxContainer = document.createElement('div');
                $addressBoxContainer.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_CONTAINER);
                $addressItemBox.appendChild($addressBoxContainer);

                $addressBoxInfo = document.createElement('div');
                $addressBoxInfo.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_INFO);
                $addressBoxContainer.appendChild($addressBoxInfo);

                $addressTitle = document.createElement('p');
                $addressTitle.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_TITLE);
                if (!_userAuthenticated) {
                    $addressTitle.classList.add(
                        CONSTANTS.ADDRESS_DROPDOWN_BOX_TITLE_NON_AUTENTICATED
                    );
                }
                $addressBoxInfo.appendChild($addressTitle);

                $addressSubTitle = document.createElement('p');
                $addressSubTitle.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_SUBTITLE);
                $addressBoxInfo.appendChild($addressSubTitle);

                if (!_userAuthenticated) {
                    _lastDigitsAccountNumber =
                        accountItem.AccountNumber.substr(
                            accountItem.AccountNumber.length -
                                parseInt($reportOutage.dataset.accountNumberLength)
                        );
                    addressTitleString = $reportOutage.dataset.accountNumberMessage + _lastDigitsAccountNumber;
                    if(accountItem.hasOwnProperty('ServiceAddress') && accountItem.ServiceAddress !== null){
                        _houseNumber =
                            accountItem.ServiceAddress.StreetAddress.substr(
                                0,
                                parseInt($reportOutage.dataset.streetAddressLength)
                            );
                        finalDetailDirection =
                            accountItem.ServiceAddress.City + ', ' + accountItem.ServiceAddress.State;
                        addressSubTitleString = $reportOutage.dataset.streetAddressMessage + _houseNumber + '<br>' + finalDetailDirection;
                    } else {
                        addressSubTitleString = $reportOutage.dataset.customerNameMessage + 
                        accountItem.CustomerName.substr(0,$reportOutage.dataset.customerNameLength);
                    }
                } else {
                    addressTitleString = accountItem.ServiceAddress.StreetAddress;

                    if (
                        accountItem.ServiceAddress.UnitNumber &&
                        accountItem.ServiceAddress.UnitNumber.trim() != ''
                    ) {
                        addressTitleString += ', ' + accountItem.ServiceAddress.UnitNumber;
                    }

                    addressSubTitleString = accountItem.ServiceAddress.City;

                    if (accountItem.ServiceAddress.State) {
                        addressSubTitleString += ', ' + accountItem.ServiceAddress.State;
                    }

                    if (accountItem.ServiceAddress.PostalCode) {
                        addressSubTitleString += ' ' + accountItem.ServiceAddress.PostalCode;
                    }
                }

                if (!$findByActiveOption) {
                    var selectedAddressAccount = $addressSelectedDetail.getElementsByClassName(
                        CONSTANTS.ADDRESS_SELECTED_MASKED_ACCOUNT_NUMBER
                    )[0];
                    selectedAddressAccount.innerHTML = accountItem.AccountNumber;
                    //Show the selected address after we got the account number
                    $addressSelected.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }

                $addressTitle.innerHTML = addressTitleString;
                $addressSubTitle.innerHTML = addressSubTitleString;

                $addressIcon = document.createElement('span');
                $addressIcon.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_ICON);
                $addressIcon.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_ICON_CHECK_CLASS);
                $addressIcon.classList.add(CONSTANTS.ADDRESS_DROPDOWN_BOX_ICON_CHECK);

                $addressItem.appendChild($addressItemBox);
                $addressItem.appendChild($addressIcon);
                $addressDropDownList.appendChild($addressItem);
            });

            setDropDownModule(_data);
        };

        var setDropDownModule = function (_data) {
            var $addressDropdownModule = _userAuthenticated
                    ? $lookupResult.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_MODULE)[0]
                    : $reportOutage.getElementsByClassName(CONSTANTS.ADDRESS_DROPDOWN_MODULE)[0],
                $addressDropdownModuleNew = $addressDropdownModule.cloneNode(true),
                $firstAddress = $addressDropdownModuleNew.getElementsByClassName(
                    CONSTANTS.ADDRESS_DROPDOWN_ITEM_CONTAIN
                )[0],
                $addressDropdownCarrot = $reportOutage.getElementsByClassName(
                    CONSTANTS.ADDRESS_DROPDOWN_BOX_CARROT
                ),
                $items,
                index;

            $addressDropdownModule.parentNode.replaceChild(
                $addressDropdownModuleNew,
                $addressDropdownModule
            );
            new coned.components.AddressDropdown($addressDropdownModuleNew);
            $items = $addressDropdownModuleNew.getElementsByClassName(
                CONSTANTS.ADDRESS_DROPDOWN_LIST_ITEM
            );

            for (index = 0; index < $items.length; index++) {
                coned.utils.addGeneralListeners($items[index], mdacValidation);
                // To validate if contact step should show, depending on the conditions of the selected account
                coned.utils.addGeneralListeners($items[index], othersAffected);
            }

            $firstAddress.click();
            mdacValidation();

            if (!_userAuthenticated) {
                if (_data.Accounts.length <= 1) {
                    for (index = 0; index < $addressDropdownCarrot.length; index++) {
                        $addressDropdownCarrot[index].classList.add(CONSTANTS.HIDDEN_CLASS);
                    }
                } else {
                    for (index = 0; index < $addressDropdownCarrot.length; index++) {
                        $addressDropdownCarrot[index].classList.remove(CONSTANTS.HIDDEN_CLASS);
                    }
                }
            }
        };

        /**
         * Validates mdac along with other options and sets others affected dropdown properties accordingly.
         */
        var mdacValidation = function () {
            var $othersAffectedDescription = document.getElementsByClassName(
                    CONSTANTS.OTHER_AFFECTED_DESCRIPTION
                )[0],
                havePowerOption = $havePowerSelect.options[$havePowerSelect.selectedIndex].value,
                havePowerNo = $reportOutage.dataset.havePowerNo,
                powerOptionNo = havePowerOption === havePowerNo,
                otherAffectedRequiredText = $otherAffectedSelect.dataset.otherAffectedRequiredText,
                otherAffectedOptionalText = $otherAffectedSelect.dataset.otherAffectedOptionalText,
                mdacRuleValue = parseInt($reportOutage.dataset.multiDwellingCode, 10);

            if ($authenticatedMyAddressCheckbox && !$authenticatedMyAddressCheckbox.checked) {
                $accountData = $addressRecordContainer.getElementsByClassName(
                    CONSTANTS.ACCOUNT_DATA
                )[0];
            } else {
                $accountData = $reportOutage.getElementsByClassName(CONSTANTS.ACCOUNT_DATA)[0];
            }

            _mdacValue =
                $accountData && $accountData.dataset && $accountData.dataset.mdac
                    ? $accountData.dataset.mdac
                    : null;

            if (
                _mdacValue > mdacRuleValue &&
                ((powerOptionNo && $damagedWireNoRadio.checked) || !powerOptionNo)
            ) {
                $otherAffectedSelect.setAttribute(CONSTANTS.REQUIRED_FIELD, '');
                $otherAffectedSelect.options[0].text = otherAffectedRequiredText;
                //change information in spam element FOR REQUIRED
                $otherAffectedSelect.setAttribute(CONSTANTS.ARIA_REQUIRED, 'true');
                $otherAffectedSelect.parentElement.children[0].innerText = otherAffectedRequiredText;
            } else {
                $otherAffectedSelect.removeAttribute(CONSTANTS.REQUIRED_FIELD);
                $otherAffectedSelect.options[0].text = otherAffectedOptionalText;
                //change information in spam element for OPTIONAL
                $otherAffectedSelect.parentElement.children[0].innerText = otherAffectedOptionalText;
                $otherAffectedSelect.removeAttribute(CONSTANTS.ARIA_REQUIRED, 'false');
                $($reportOutage).validate().element($otherAffectedSelect);
            }

            $issueDetailContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $othersAffectedDescription.classList.remove(CONSTANTS.HIDDEN_CLASS);

            if ((_userAuthenticated && !_noDirections) || _mdacValue) {
                $othersAffectedContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);

                //Check if wires down is selected to show the wire down select
                if ($damagedWireYesRadio.checked || $otherIssuesSelect.options[$otherIssuesSelect.selectedIndex].value === _wiresDown) {
                    resetDropdownLabel($wireDownSelect);
                    $wireDownSelect.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $wireDownOptions.classList.remove(CONSTANTS.HIDDEN_CLASS);
                }

            } else {
                $othersAffectedContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        /**
         * Resets the location step lookup
         * @param {Event} event Default event.
         */
        var notMyAddress = function (event) {
            if (event !== undefined) {
                event.preventDefault();
            }

            var options = $locationStep.getElementsByClassName(CONSTANTS.ACCOUNT_OPTION),
                firstOption = $locationStep.getElementsByClassName(CONSTANTS.ACCOUNT_OPTION)[0],
                $addressSelect = $findAccountByAddress.getElementsByClassName(
                    CONSTANTS.DROPDOWN_CLASS
                );

            if ($findAccountByNumber) {
                var lookUpInputs = $findAccountByNumber.getElementsByClassName(
                    CONSTANTS.INPUT_CLASS
                );

                $findAccountSelect.disabled = false;
                $findAccountSelect.selectedIndex = 1;

                _.each(lookUpInputs, function (input) {
                    input.parentNode.classList.remove(CONSTANTS.INPUT_DISABLED_CLASS);
                    input.disabled = false;
                });
            }

            // hide record address container, adjust the mask fieldset mask
            $addressRecordContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            $findAccountByAddress.classList.add(CONSTANTS.HIDDEN_CLASS);
            $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            if ($otherDetailsContainer) {
                $otherDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            // clear and hide options
            _.each(options, function ($option) {
                $option.classList.add(CONSTANTS.HIDDEN_CLASS);
                $option.classList.remove(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
                $option.getElementsByClassName(CONSTANTS.ACCOUNT_NUMBER_INPUT)[0].value = '';
            });

            if (!_userAuthenticated) {
                $findAccountByNumber.style.display = 'flex';
                $findAccountByNumber.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                $findAccountByNumber.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $boroughDropdownContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                resetDropdownLabel($boroughDropdownSelect);
                $boroughDropdownSelect.disabled = true;
            }

            fieldsMask($addressRecordContainer);
            fieldsMask($findAccountByAddress);
            if (event) scrollToStep($locationStep);
            $findAccountByAddress.classList.remove(CONSTANTS.FIND_BY_OPTION_ACTIVE);
            $submitButtonContainer.classList.remove(CONSTANTS.SUBMIT_CONTAINER_ACTIVE);
            $findAddressButtonContainer.classList.add(CONSTANTS.SUBMIT_CONTAINER_ACTIVE);

            mdacValidation();
            resetDropdownLabel($otherAffectedSelect);

            if (!_userAuthenticated) {
                firstOption.classList.add(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
            }

            // find by address dropdown reset
            _.each($addressSelect, function ($addressDropdown) {
                resetDropdownLabel($addressDropdown);
                $addressDropdown.disabled = true;
            });

            changeInputsState();
        };

        var findAccountByAddress = function (event) {
            event.preventDefault();

            var options = $locationStep.getElementsByClassName(CONSTANTS.ACCOUNT_OPTION);

            _.each(options, function ($option) {
                $option.classList.remove(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
                $option.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            $findAccountByNumber.classList.remove(CONSTANTS.FIND_BY_OPTION_ACTIVE);
            $findAccountByAddress.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
            $findAccountByAddress.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $findAddressButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $findAccountByNumber.classList.add(CONSTANTS.HIDDEN_CLASS);
            $addressRecordContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            $boroughDropdownContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $accountNumberInput.value = '';
            scrollToStep($locationStep);
            fieldsMask($findAccountByAddress);
            fieldsMask($addressRecordContainer, 0);
            changeInputsState(true);

            $autocompleteZipCode.value = '';
            $autocompleteZipCode.disabled = false;
            $autocompleteZipCode.parentNode.classList.remove(CONSTANTS.INPUT_DISABLED_CLASS);
            $autocompleteZipCode.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
            query.fireEvent($autocompleteZipCode, 'cleanUp');

            //hide on new experience
            $findAddressButton.classList.add(CONSTANTS.HIDDEN_CLASS);

            if ($otherDetailsContainer) {
                $otherDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var findAccountOptions = function () {
            var accountOptionValue = this.options[$findAccountSelect.selectedIndex].value,
                accountOptionName = 'js-' + accountOptionValue + '-number',
                selectedOption = $locationStep.getElementsByClassName(accountOptionName)[0],
                options = $locationStep.getElementsByClassName(CONSTANTS.ACCOUNT_OPTION),
                validator = $($reportOutage).validate();

            _.each(options, function ($option) {
                $option.classList.remove(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
                $option.classList.add(CONSTANTS.HIDDEN_CLASS);
            });
            $boroughDropdownSelect.disabled = false;
            validator.resetForm();
            selectedOption.classList.add(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
            selectedOption.getElementsByClassName(CONSTANTS.INPUT_DISABLED_CLASS)[0]
                ? selectedOption
                      .getElementsByClassName(CONSTANTS.INPUT_DISABLED_CLASS)[0]
                      .classList.remove(CONSTANTS.INPUT_DISABLED_CLASS)
                : '';
            selectedOption.getElementsByClassName(CONSTANTS.INPUT_CLASS)[0].disabled = false;
            selectedOption.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $findAddressButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $findAccountByAddress.classList.add(CONSTANTS.HIDDEN_CLASS);
            fieldsMask($findAccountByAddress);
            $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);

            if ($othersAffectedContainer) {
                $othersAffectedContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                fieldsMask($othersAffectedContainer);
            }
        };

        var resetDropdownLabel = function ($element) {
            var $selectLabel = $element.parentNode.getElementsByClassName(
                CONSTANTS.DROPDOWN_LABEL_SELECTOR
            )[0];

            $selectLabel.style = '';
            $element.style = '';
            $element.selectedIndex = 0;
            $element.classList.remove(CONSTANTS.ACTIVE_DROPDOWN_CLASS);
        };

        var resetForm = function () {
            var validator = $($reportOutage).validate();

            validator.resetForm();
        };

        /**
         * Handles wire other location dropdown selection.
         * Depending on options shows or hides elements accordingly.
         * Resets wire location input and wire location borough select.
         */
        var wireOtherLocationOptions = function () {
            var wireLocationValue = this.options[this.selectedIndex].value,
                wireDownAtAnotherLocation = $reportOutage.dataset.wireLocationOther,
                treeDownAtAnotherLocation = $reportOutage.dataset.treeLocationOther,
                $wireLocationInputOptions = $wireLocationOtherContainer.getElementsByClassName(
                    CONSTANTS.INPUT_CLASS
                ),
                $wireLocationBoroughSelect = $wireLocationOtherContainer.getElementsByClassName(
                    CONSTANTS.DROPDOWN_CLASS
                )[0];

            for (var index = 0; index < $wireLocationInputOptions.length; index++) {
                $wireLocationInputOptions[index].value = '';
                $wireLocationInputOptions[index].classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                $wireLocationInputOptions[index].removeAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE);
            }

            resetDropdownLabel($wireLocationBoroughSelect);

            if (
                wireLocationValue === wireDownAtAnotherLocation ||
                wireLocationValue === treeDownAtAnotherLocation
            ) {
                $wireLocationOtherContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $wireLocationOtherContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
            
            validateShowHideContactStep();
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            fieldsMask($addressRecordContainer);
        };

        /**
         * Handles damaged wire radios buttons selection.
         * Resets elements as needed.
         * Shows or hides wire down dropdown related elements.
         * Validates mdac and others affected.
         */
        var damagedWire = function () {
            resetDropdownLabel($wireDownSelect);
            resetDropdownLabel($otherAffectedSelect);
            $otherAffectedAlertContainer.classList.remove(CONSTANTS.OTHER_AFFECTED_ACTIVE);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);

            if ($damagedWireYesRadio.checked) {
                $wireDownSelect.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $wireDownOptions.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $wireDownSelect.classList.add(CONSTANTS.HIDDEN_CLASS);
                $wireLocationOtherContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $wireDownOptions.classList.add(CONSTANTS.HIDDEN_CLASS);
            }

            mdacValidation();
            othersAffected();

            // Tagging
            coned.utils.addRadioButtonsTagging(
                $damagedWireRadioLabel, 
                $damagedWireRadios, 
                [
                    CONSTANTS.YES_VALUE, 
                    CONSTANTS.NO_VALUE
                ]
            );
        };

        /**
         * Handles other issue dropdown selection.
         * Resets wire and tree-branches dropdowns. 
         * Shows or hides location and contact steps.
         * Validates mdac and others affected.
         */
        var otherIssueChange = function () {
            var otherIssueOption = this.options[$otherIssuesSelect.selectedIndex].value,
                ManholeProblems = $reportOutage.dataset.issueManholeProblems,
                OtherElectricalIssue = $reportOutage.dataset.issueOtherElectrical,
                hasTagging = true;

            resetDropdownLabel($wireDownSelect);
            resetDropdownLabel($treeBranchSelect);
            $wireDownSelect.classList.add(CONSTANTS.HIDDEN_CLASS);
            $wireDownOptions.classList.add(CONSTANTS.HIDDEN_CLASS);
            $wireLocationOtherContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            mdacValidation();
            othersAffected();

            if (otherIssueOption === ManholeProblems || otherIssueOption === OtherElectricalIssue) {
                $otherIssuesBanner.classList.remove(CONSTANTS.HIDDEN_CLASS);
                showHideLocationStep(CONSTANTS.HIDE_ACTION);
                showHideContactStep(CONSTANTS.HIDE_ACTION);
                $treeBranchSelect.classList.add(CONSTANTS.HIDDEN_CLASS);
                hasTagging = false;
            } else if (otherIssueOption === _wiresDown) {
                $wireDownSelect.classList.remove(CONSTANTS.HIDDEN_CLASS);
                showHideLocationStep(CONSTANTS.SHOW_ACTION);
                $treeBranchSelect.classList.add(CONSTANTS.HIDDEN_CLASS);
                $otherIssuesBanner.classList.add(CONSTANTS.HIDDEN_CLASS);
                $wireDownOptions.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $wireDownSelect.classList.remove(CONSTANTS.HIDDEN_CLASS);
                validateShowHideContactStep();
                userStatus();
            } else if (otherIssueOption === _treeOrBranchOnWire) {
                $treeBranchSelect.classList.remove(CONSTANTS.HIDDEN_CLASS);
                showHideLocationStep(CONSTANTS.SHOW_ACTION);
                $otherIssuesBanner.classList.add(CONSTANTS.HIDDEN_CLASS);
                validateShowHideContactStep();
                userStatus();
            } else {
                showHideLocationStep(CONSTANTS.SHOW_ACTION);
                $otherIssuesBanner.classList.add(CONSTANTS.HIDDEN_CLASS);
                $treeBranchSelect.classList.add(CONSTANTS.HIDDEN_CLASS);
                userStatus();
            }

            if (_hasAddress) {
                validateShowHideContactStep();
            }

            if (hasTagging) {
                addTagging();
            }

            if (_userAuthenticated && !_noDirections) {
                $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var addTagging = function () {
            // Analytics data building
            dataLayer.push({
                event: 'coned.form.step01'
            });

            if (_userAuthenticated) {
                dataLayer.push({
                    event: 'coned.form.step02'
                });
            }
        };

        var checkContactNumber = function () {
            var valid = false;

            if ($($contactNumber).valid()) {
                valid = true;
            } else {
                valid = false;
                $contactNumber.classList.remove(CONSTANTS.INPUT_MESSAGE_ERROR);
                $contactNumber.parentElement.nextElementSibling.style.display = 'none';
            }

            if (
                $contactNumber.value.trim() &&
                valid &&
                $contactNumber.dataset.prevVal !== $contactNumber.value
            ) {
                var checkContactNumberService = $reportOutage.dataset.checkContactNumberService,
                    params = {
                        ScId: query.getFormInputValue($reportOutage, CONSTANTS.FORM_SCID),
                        PhoneNumber: query.getFormInputValue(
                            $reportOutage,
                            CONSTANTS.FORM_PHONE_NUMBER
                        )
                    };

                $phoneCallRadio.checked = $phoneTextRadio.checked = false;

                params = JSON.stringify(params);

                // service call
                if (coned.utils.isPatternLab()) {
                    query.getData(
                        coned.plConstants.GET_CHECK_CONTACT_NUMBER,
                        successCheckContactNumber,
                        noMatchContactNumber
                    );
                } else {
                    query.postData(
                        checkContactNumberService,
                        successCheckContactNumber,
                        noMatchContactNumber,
                        params,
                        true
                    );
                }
            } else {
                if (!valid) {
                    $phoneCallRadio.checked = $phoneTextRadio.checked = false;
                    $phoneTextRadio.parentElement.parentElement.classList.remove(
                        CONSTANTS.HIDDEN_CLASS
                    );
                }
            }

            $contactNumber.dataset.prevVal = $contactNumber.value;
        };

        var successCheckContactNumber = function (data) {
            if (data && !data.error && data.phoneType) {
                var isMobile = data.phoneType === CONSTANTS.CELLULAR_PHONE_TYPE;

                if (!isMobile) {
                    $phoneCallRadio.checked = true;
                    $phoneTextRadio.parentElement.parentElement.classList.add(
                        CONSTANTS.HIDDEN_CLASS
                    );

                    $cellularLabel.length
                        ? $cellularLabel[0].classList.add(CONSTANTS.HIDDEN_CLASS)
                        : '';
                    $landlineLabel.length
                        ? $landlineLabel[0].classList.remove(CONSTANTS.HIDDEN_CLASS)
                        : '';
                } else {
                    $phoneCallRadio.checked = $phoneTextRadio.checked = false;
                    $phoneTextRadio.parentElement.parentElement.classList.remove(
                        CONSTANTS.HIDDEN_CLASS
                    );

                    $cellularLabel.length
                        ? $cellularLabel[0].classList.remove(CONSTANTS.HIDDEN_CLASS)
                        : '';
                    $landlineLabel.length
                        ? $landlineLabel[0].classList.add(CONSTANTS.HIDDEN_CLASS)
                        : '';
                }
            }
        };

        var noMatchContactNumber = function () {
            $phoneCallRadio.checked = true;
            $phoneTextRadio.parentElement.parentElement.classList.add(
                CONSTANTS.HIDDEN_CLASS
            );

            $cellularLabel.length
                ? $cellularLabel[0].classList.add(CONSTANTS.HIDDEN_CLASS)
                : '';
            $landlineLabel.length
                ? $landlineLabel[0].classList.remove(CONSTANTS.HIDDEN_CLASS)
                : '';
        };

        /**
         * Handles have power dropdown selection. 
         * Depending on options shows or hides and sets elements accordingly.
         * Validates mdac.
         */
        var havePowerChange = function () {
            var $findAccountOptions,
                havePowerOption = this.options[$havePowerSelect.selectedIndex].value,
                havePowerNo = $reportOutage.dataset.havePowerNo,
                havePowerPartial = $reportOutage.dataset.havePowerPartial,
                powerOptionNo = havePowerOption === havePowerNo,
                powerOptionPartial = havePowerOption === havePowerPartial,
                hasTagging = true,
                isSystemOut = $reportOutage.dataset.systemOut === 'true' ? true : false;

            // For patternlab, if the url has the "system-out=true" parameter, it shows the scenario in which the system is down
            if (coned.utils.isPatternLab()) {
                isSystemOut = coned.utils.getUrlParameterValue(CONSTANTS.SYSTEM_OUT_PARAMETER) ? 
                    true : false;
            }
            // Hide gas banner
            $bannerContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            // Hide upcoming sections
            showHideLocationStep(CONSTANTS.HIDE_ACTION);   
            showHideContactStep(CONSTANTS.HIDE_ACTION);

            userStatus();
            $otherIssuesSelect.selectedIndex = 0;
            $treeBranchSelect.classList.add(CONSTANTS.HIDDEN_CLASS);
            $wireDownSelect.classList.add(CONSTANTS.HIDDEN_CLASS);
            $wireDownOptions.classList.add(CONSTANTS.HIDDEN_CLASS);
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            resetDropdownLabel($otherIssuesSelect);

            if (!_userAuthenticated & !_hasAddress) {
                $findAccountSelect.selectedIndex = 0;
                $findAccountOptions = $locationStep.getElementsByClassName(
                    CONSTANTS.ACCOUNT_OPTION
                );

                $boroughDropdownContainer.classList.add(CONSTANTS.HIDDEN_CLASS);

                if (!isSystemOut) {
                    $findAccountByNumber.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                    $findAccountByNumber.classList.remove(CONSTANTS.HIDDEN_CLASS);
                } else {
                    $findAccountByAddress.classList.add(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                    $findAccountByAddress.classList.remove(CONSTANTS.HIDDEN_CLASS);
                    $findAccountByNumber.classList.remove(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                }

                if ($findAccountByAddress.classList.contains(CONSTANTS.FIND_BY_OPTION_ACTIVE)) {
                    changeInputsState(true);
                    $autocompleteZipCode.disabled = false;
                    $autocompleteZipCode.parentNode.classList.remove(CONSTANTS.INPUT_DISABLED_CLASS);
                } else {
                    $findAccountByAddress.classList.add(CONSTANTS.HIDDEN_CLASS);
                    $findAccountByAddress.classList.remove(CONSTANTS.FIND_BY_OPTION_ACTIVE);
                    fieldsMask($findAccountByAddress);
                }

                resetDropdownLabel($findAccountSelect);
                resetDropdownLabel($boroughDropdownSelect);

                $boroughDropdownSelect.disabled = true;
                $findAccountSelect.disabled = false;

                if ($accountData) {
                    $accountData.dataset.mdac = '';
                }

                _.each($findAccountOptions, function ($option) {
                    var $input = $option.querySelector('input');

                    $input.value = '';
                    $input.classList.remove(CONSTANTS.VALID_CLASS);
                    $input.classList.remove(CONSTANTS.INPUT_FILLED_CLASS);
                    $option.classList.remove(CONSTANTS.ACCOUNT_OPTION_ACTIVE);
                    $option.classList.add(CONSTANTS.HIDDEN_CLASS);
                });
            }

            // Power issue selection
            if (powerOptionNo) {
                resetRadioButtons($damagedWireRadios);
                $treeWireProblemContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $partialOptionsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $otherIssuesContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $otherIssuesBanner.classList.add(CONSTANTS.HIDDEN_CLASS);
                $noPartialPowerForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $hasPowerForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                _hasPower = false;

            } else if (powerOptionPartial) {
                resetRadioButtons($dimFlickerRadios);
                $treeWireProblemContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                fieldsMask($treeWireProblemContainer, '0');
                $partialOptionsContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $otherIssuesContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $otherIssuesBanner.classList.add(CONSTANTS.HIDDEN_CLASS);
                $noPartialPowerForm.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $hasPowerForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                _hasPower = false;
            } else {
                $transactionalWrapper.classList.add(CONSTANTS.MARGIN_BOTTOM);
                $treeWireProblemContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $partialOptionsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $otherIssuesContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $noPartialPowerForm.classList.add(CONSTANTS.HIDDEN_CLASS);
                $hasPowerForm.classList.remove(CONSTANTS.HIDDEN_CLASS);

                // Only remove the alert if allowed by the flag
                if (!_othersDisabled) {
                    $otherAffectedAlertContainer.classList.remove(CONSTANTS.OTHER_AFFECTED_ACTIVE);
                    destroyAlertBody();
                }

                resetRadioButtons($damagedWireRadios);
                hasTagging = false;
                _hasPower = true;
            }

            if (hasTagging) {
                addTagging();
            }

            if (
                (_userAuthenticated && !_noDirections) ||
                _hasAddress ||
                !query.hasClass($addressRecordContainer, CONSTANTS.HIDDEN_CLASS)
            ) {
                $findAddressButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            } else {
                $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            fieldsMask($treeWireProblemContainer);
            mdacValidation();
        };

        var displayboroughtDropdown = function () {
            if ($findAccountSelect.selectedIndex > 1) {
                $boroughDropdownContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                $boroughDropdownContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var checkForDirections = function () {
            if (_noDirections && !($accountData && $accountData.dataset.account)) {
                $addressDescription.classList.add(CONSTANTS.HIDDEN_CLASS);
                $authenticatedMyAddressCheckbox.checked = true;
                $authenticatedMyAddressCheckbox.click();
                $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }
        };

        /**
         * Toggles all of the flicker and lights fields on the page.
         */
        var toggleFlickerLightsFields = function () {
            if ($dimFlickerYesRadio.checked) {
                _.each($flickerLightsHidden, function ($content) {
                    $content.classList.add(CONSTANTS.HIDDEN_CLASS);
                });
            } else {
                _.each($flickerLightsHidden, function ($content) {
                    $content.classList.remove(CONSTANTS.HIDDEN_CLASS);
                });
            }

            // Tagging
            coned.utils.addRadioButtonsTagging(
                $dimFlickerRadioLabel, 
                $dimFlickerRadios, 
                [
                    CONSTANTS.YES_VALUE, 
                    CONSTANTS.NO_VALUE
                ]
            );
        };

        /**
         * Resets radio buttons to initial state.
         * @param {Array} $radioButtons Array of radio buttons.
         */
        var resetRadioButtons = function ($radioButtons) {
            _.each($radioButtons, function ($radioButton) {
                $radioButton.checked = false;
                query.removeClass($radioButton, CONSTANTS.VALID_CLASS);
                $radioButton.removeAttribute(CONSTANTS.ARIA_INVALID);
                $radioButton.removeAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE);
            });
        };

        /**
         * Validate if contact step should show or hide
         */
        var validateShowHideContactStep = function () {
            showHideContactStep(CONSTANTS.HIDE_ACTION);

            var _isFormValid = coned.components.ValidateForm.isFormValid(
                CONSTANTS.FORM,
                CONSTANTS.EMPTY_STRING,
                CONSTANTS.FORM_IGNORE_VALIDATION,
                CONSTANTS.TRUE_VALUE
            ),
            _isLocationStepVisible = !query.hasClass($locationStep, CONSTANTS.HIDDEN_CLASS);

            
            // Only show contact step if form is valid at that point, previous step is visible, and allowed by the flag
            if (_isFormValid && _isLocationStepVisible && !_othersDisabled) {
                showHideContactStep(CONSTANTS.SHOW_ACTION);
            }
        };

        /**
         * Shows or hides the location step based on the parameter
         * @param {String} action String must be show or hide.
         */
        var showHideLocationStep = function (action) {
            if (action === CONSTANTS.SHOW_ACTION) {
                $locationStep.classList.add(CONSTANTS.STEP_ACTIVE);
                $locationStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else if (action === CONSTANTS.HIDE_ACTION) {
                $locationStep.classList.remove(CONSTANTS.STEP_ACTIVE);
                $locationStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        /**
         * Shows or hides the contact step based on the parameter
         * @param {String} action String must be show or hide.
         */
        var showHideContactStep =  function (action) {
            if (action === CONSTANTS.SHOW_ACTION) {
                $submitButtonContainer.classList.add(CONSTANTS.SUBMIT_CONTAINER_ACTIVE);
                $submitButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $submitButton.classList.remove(CONSTANTS.HIDDEN_CLASS);
                $contactStep.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else if (action === CONSTANTS.HIDE_ACTION) {
                $submitButtonContainer.classList.remove(CONSTANTS.SUBMIT_CONTAINER_ACTIVE);
                $submitButtonContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                $submitButton.classList.add(CONSTANTS.HIDDEN_CLASS);
                $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);
            }
        };

        var initializeData = function () {
            $havePowerSelect = $reportOutage.getElementsByClassName(CONSTANTS.HAVE_POWER_SELECT)[0];
            $treeWireProblemContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.TREE_WIRE_PROBLEM_CONTAINER
            )[0];
            $damagedWireRadioLabel = $reportOutage.getElementsByClassName(
                CONSTANTS.DAMAGED_WIRE_RADIO_LABEL
            )[0];
            $damagedWireYesRadio = $reportOutage.getElementsByClassName(
                CONSTANTS.DAMAGED_WIRE_YES_RADIO
            )[0];
            $damagedWireNoRadio = $reportOutage.getElementsByClassName(
                CONSTANTS.DAMAGED_WIRE_NO_RADIO
            )[0];
            $damagedWireRadios = [$damagedWireYesRadio, $damagedWireNoRadio];
            $findAccountSelect = $reportOutage.getElementsByClassName(
                CONSTANTS.FIND_ACCOUNT_SELECT
            )[0];
            $locationStep = $reportOutage.getElementsByClassName(CONSTANTS.LOCATION_STEP)[0];
            $contactStep = $reportOutage.getElementsByClassName(CONSTANTS.CONTACT_STEP)[0];
            $searchByAddressLink = $reportOutage.getElementsByClassName(
                CONSTANTS.SEARCH_BY_ADDRESS_LINK
            )[0];
            $addressLookupForm = $reportOutage.getElementsByClassName(
                CONSTANTS.ADDRESS_LOOKUP_FORM
            )[0];
            $autocompleteZipCode = $addressLookupForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_ZIPCODE
            )[0];
            $autocompleteSelectedAddress = $addressLookupForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_SELECTED_ADDRESS
            )[0];
            $autocompleteAparmentUnitInput = $addressLookupForm.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_APARMENT_UNIT
            )[0];
            $addressSelected = $addressLookupForm.getElementsByClassName(
                CONSTANTS.ADDRESS_SELECTED_CLASS
            )[0];
            $addressSelectedDetail = $addressLookupForm.getElementsByClassName(
                CONSTANTS.ADDRESS_SELECTED_DETAIL_CLASS
            )[0];
            $addressRecordContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.ADDRESS_RECORD_CONTAINER
            )[0];
            $otherDetailsContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.OTHER_DETAILS_CONTAINER
            )[0];
            $notMyAddressLink = $reportOutage.getElementsByClassName(
                CONSTANTS.NOT_MY_ADDRESS_LINK
            )[0];
            $bannerContainer = document.getElementsByClassName(CONSTANTS.BANNER_CONTAINER)[0];
            $submitContainer = document.getElementsByClassName(CONSTANTS.SUBMIT_CONTAINER)[0];
            $findAccountByNumber = $reportOutage.getElementsByClassName(
                CONSTANTS.FIND_ACCOUNT_BY_NUMBER
            )[0];
            $findAccountByAddress = $reportOutage.getElementsByClassName(
                CONSTANTS.FIND_ACCOUNT_BY_ADDRESS
            )[0];
            $findAddressButton = document.getElementsByClassName(CONSTANTS.FIND_ADDRESS_BUTTON)[0];
            $findAddressButtonContainer = $findAddressButton.parentNode.parentNode;
            $submitButton = document.getElementsByClassName(CONSTANTS.FORM_SUBMIT_BUTTON)[0];
            $submitButtonContainer = $submitButton.parentNode.parentNode;
            $othersAffectedContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.OTHER_AFFECTED_CONTAINER
            )[0];
            $otherAffectedSelect = $reportOutage.getElementsByClassName(
                CONSTANTS.OTHER_AFFECTED_SELECT
            )[0];
            $otherAffectedAlertContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.OTHER_AFFECTED_ALERT_CONTAINER
            )[0];
            $otherAffectedAlert = $reportOutage.getElementsByClassName(
                CONSTANTS.OTHER_AFFECTED_ALERT
            )[0];
            $notMyAddressWrapper = $reportOutage.getElementsByClassName(
                CONSTANTS.NOT_MY_ADDRESS_WRAPPER
            );
            $authenticatedMyAddressCheckbox = $reportOutage.getElementsByClassName(
                CONSTANTS.OUTAGE_MY_ADDRESS_CHECK
            )[0];
            $lookupResult = $reportOutage.getElementsByClassName(CONSTANTS.LOOKUP_RESULT)[0];
            $partialOptionsContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.PARTIAL_OPTIONS_CONTAINER
            )[0];
            $otherIssuesContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.OTHER_ISSUES_OPTIONS
            )[0];
            $otherIssuesSelect = $reportOutage.getElementsByClassName(
                CONSTANTS.OTHER_ISSUES_SELECT
            )[0];
            $otherIssuesBanner = $reportOutage.getElementsByClassName(
                CONSTANTS.OTHER_ISSUES_BANNER
            )[0];
            $wireDownSelect = $reportOutage.getElementsByClassName(CONSTANTS.WIRE_DOWN_SELECT)[0];
            $treeBranchSelect = $reportOutage.getElementsByClassName(
                CONSTANTS.TREE_BRANCH_SELECT
            )[0];
            $issueDetailContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.ISSUE_DETAILS_CONTAINER
            )[0];
            $boroughDropdownContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.BOROUGH_DROPDOWN_CONTAINER
            )[0];
            $boroughDropdownSelect = $reportOutage.getElementsByClassName(
                CONSTANTS.BOROUGH_DROPDOWN_SELECT
            )[0];
            $lookupError = $reportOutage.getElementsByClassName(CONSTANTS.LOOKUP_SERVICE_ERROR)[0];
            $serviceError = $reportOutage.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $wireLocationOtherContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.WIRE_LOCATION_OTHER_CONTAINER
            )[0];
            $wireDownOptions = $reportOutage.getElementsByClassName(CONSTANTS.WIRE_DOWN_OPTIONS)[0];
            $findaccountContainer = $reportOutage.getElementsByClassName(
                CONSTANTS.FIND_ACCOUNT_CONTAINER
            )[0];
            $dimFlickerRadioLabel = $reportOutage.getElementsByClassName(
                CONSTANTS.DIM_FLICKER_RADIO_LABEL
            )[0];
            $dimFlickerYesRadio = $reportOutage.getElementsByClassName(
                CONSTANTS.DIM_FLICKER_YES_RADIO
            )[0];
            $dimFlickerNoRadio = $reportOutage.getElementsByClassName(
                CONSTANTS.DIM_FLICKER_NO_RADIO
            )[0];
            $dimFlickerRadios = [$dimFlickerYesRadio, $dimFlickerNoRadio];
            $flickerLightsHidden = $reportOutage.getElementsByClassName(
                CONSTANTS.FLICKER_LIGHTS_HIDDEN
            );
            $accountNumberInput = $reportOutage.getElementsByClassName(CONSTANTS.ACCOUNT_NUMBER)[0];
            $addressDescription = $reportOutage.getElementsByClassName(
                CONSTANTS.ADDRESS_DESCRIPTION
            )[0];
            $transactionalWrapper = $reportOutage.getElementsByClassName(
                CONSTANTS.TRANSACTIONAL_WRAPPER
            )[0];
            $phoneCallRadio = $reportOutage.getElementsByClassName(
                CONSTANTS.PHONE_CALL_RADIO_BUTTON
            )[0];
            $phoneTextRadio = $reportOutage.getElementsByClassName(
                CONSTANTS.PHONE_TEXT_RADIO_BUTTON
            )[0];
            $contactNumber = $reportOutage.getElementsByClassName(CONSTANTS.CONTACT_NUMBER)[0];
            $contactNumberHasPower = $reportOutage.getElementsByClassName(
                CONSTANTS.CONTACT_NUMBER_HAS_POWER
            )[0];
            $noPartialPowerForm = $reportOutage.getElementsByClassName(
                CONSTANTS.NO_PARTIAL_POWER_FORM
            )[0];
            $finishBannerContainer = document.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_BANNER_CONTAINER
            )[0];
            $finishBannerFocus = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_BANNER_FOCUS
            )[0];
            $finishTextAccountNumber = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_ACCOUNT
            )[0];
            $finishTextStreetAddress = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_STREET_ADDRESS
            )[0];
            $finishTextCity = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_CITY
            )[0];
            $finishOtherAffected = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.OTHER_AFFECTED_DESCRIPTION
            )[0];
            $finishTextContactName = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_CONTACT_NAME
            )[0];
            $finishTextContactNumber = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_CONTACT_NUMBER
            )[0];
            $finishTextETR = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_ETR
            )[0];
            $finishDuplicateReport = $finishBannerContainer.getElementsByClassName(
                CONSTANTS.FINISH_REPORT_DUPLICATE
            )[0];
            $hasPowerForm = $reportOutage.getElementsByClassName(CONSTANTS.HAS_POWER_FORM)[0];
            $cellularLabel = $reportOutage.getElementsByClassName(CONSTANTS.CELLULAR_LABEL);
            $landlineLabel = $reportOutage.getElementsByClassName(CONSTANTS.LANDLINE_LABEL);
            $captchaElement = document.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT_CLASS);
            $body = document.getElementsByClassName(coned.constants.BODY_CLASS)[0];
            $validateShowContactStepElements = $reportOutage.getElementsByClassName(
                CONSTANTS.VALIDATE_SHOW_CONTACT_STEP_SELECTOR
            );
            _hasCaptcha = $captchaElement.length;
            _othersDisabled = false;
            _hasAddress = false;
            _noDirections = query.hasClass($reportOutage, CONSTANTS.HAVE_DIRECTIONS);
            _wiresDown = $reportOutage.dataset.issueWiresDown;
            _treeOrBranchOnWire = $reportOutage.dataset.issueTreeBranchOnWire;
            _userAuthenticated = $reportOutage.dataset.userStatus;
            _initialLoad = true;
        };

        var initializeEvents = function () {
            var $authenticatedMyAddressDropdown = $reportOutage.getElementsByClassName(
                CONSTANTS.ADDRESS_DROPDOWN_MODULE
            )[0];

            // find account by phone or account number select
            if ($findAccountSelect) {
                $findAccountSelect.addEventListener('change', displayboroughtDropdown);
            }

            // Dim flicker radio buttons listener, shows location and/or contact step if needed
            _.each ($dimFlickerRadios, function ($dimFlickerRadio) {
                $dimFlickerRadio.addEventListener('change', function () {
                    toggleFlickerLightsFields();
    
                    if (query.hasClass($locationStep, CONSTANTS.HIDDEN_CLASS)) {
                        showHideLocationStep(CONSTANTS.SHOW_ACTION);
                    }
    
                    validateShowHideContactStep();
                });
            });

            // Validate phone number to enable/disasble text messaging
            $contactNumber.addEventListener('input', checkContactNumber);

            // do you have power select
            $havePowerSelect.addEventListener('change', havePowerChange);

            // Outage downed wire radio buttons listener, shows location and/or contact step if needed
            _.each ($damagedWireRadios, function ($damagedWireRadio) {
                $damagedWireRadio.addEventListener('change', function () {
                    damagedWire();
    
                    if (query.hasClass($locationStep, CONSTANTS.HIDDEN_CLASS)) {
                        showHideLocationStep(CONSTANTS.SHOW_ACTION);
                    }
    
                    validateShowHideContactStep();
                });
            });

            // are others affected
            $otherAffectedSelect.addEventListener('change', othersAffected);

            // other issues select options
            $otherIssuesSelect.addEventListener('change', otherIssueChange);

            // wire and tree other location
            $wireDownSelect.addEventListener('change', wireOtherLocationOptions);
            $treeBranchSelect.addEventListener('change', wireOtherLocationOptions);

            // Form elements that require a simple validation that does not depend on mdac others affected
            // options, etc, to show or hide contact step
            _.each($validateShowContactStepElements, function ($validateShowContactStepElement) {
                $validateShowContactStepElement.addEventListener('input', validateShowHideContactStep);
            });

            // find address submit
            coned.utils.addGeneralListeners($findAddressButton, lookUpAddress);
            if ($autocompleteAparmentUnitInput) {
                $autocompleteAparmentUnitInput.addEventListener('selectedOptionEnd', lookUpAddress);
                $autocompleteAparmentUnitInput.addEventListener('checkAddress', lookUpAddress);

                $autocompleteAparmentUnitInput.addEventListener('inputChange', function () {
                    if (!$($autocompleteAparmentUnitInput).valid()) {
                        resetForm();
                        $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);

                        $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
                        $findAddressButtonContainer.classList.remove(
                            CONSTANTS.ERROR_SERVICE_RESPONSE_CLASS
                        );
                        $findAddressButtonContainer.classList.remove(
                            CONSTANTS.SUCCESS_SERVICE_RESPONSE_CLASS
                        );
                        $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        if ($otherDetailsContainer) {
                            $otherDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                            fieldsMask($otherDetailsContainer, 0);
                        }

                        //Hide other affected issues
                        if ($othersAffectedContainer) {
                            $othersAffectedContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                            fieldsMask($othersAffectedContainer, 0);
                            resetDropdownLabel($otherAffectedSelect);
                            destroyAlertBody();
                            $otherAffectedAlertContainer.classList.remove(
                                CONSTANTS.OTHER_AFFECTED_ACTIVE
                            );
                        }
                    }
                });

                $autocompleteAparmentUnitInput.parentElement.addEventListener(
                    'resetInput',
                    function () {
                        if (!query.hasClass($autocompleteSelectedAddress, CONSTANTS.HIDDEN_CLASS)) {
                            resetForm();
                        }
                        $contactStep.classList.add(CONSTANTS.HIDDEN_CLASS);

                        $lookupError.classList.add(CONSTANTS.HIDDEN_CLASS);
                        $findAddressButtonContainer.classList.remove(
                            CONSTANTS.ERROR_SERVICE_RESPONSE_CLASS
                        );
                        $findAddressButtonContainer.classList.remove(
                            CONSTANTS.SUCCESS_SERVICE_RESPONSE_CLASS
                        );
                        $findAddressButtonContainer.classList.remove(CONSTANTS.HIDDEN_CLASS);
                        if ($otherDetailsContainer) {
                            $otherDetailsContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                            fieldsMask($otherDetailsContainer, 0);
                        }

                        //Hide other affected issues
                        if ($othersAffectedContainer) {
                            $othersAffectedContainer.classList.add(CONSTANTS.HIDDEN_CLASS);
                            fieldsMask($othersAffectedContainer, 0);
                            resetDropdownLabel($otherAffectedSelect);
                            destroyAlertBody();
                            $otherAffectedAlertContainer.classList.remove(
                                CONSTANTS.OTHER_AFFECTED_ACTIVE
                            );
                        }
                    }
                );
            }

            // form validation
            new coned.components.ValidateForm(CONSTANTS.FORM, reportOutageSubmit, CONSTANTS.FORM_IGNORE_VALIDATION, true);

            recaptcha = new coned.components.Recaptcha(
                $reportOutage,
                recaptchaValidation,
                recaptchaValidation
            );

            // validate if user is authenticated
            if (_userAuthenticated) {
                $authenticatedMyAddressCheckbox.addEventListener(
                    'change',
                    authenticatedMyAddressChange
                );
                $authenticatedMyAddressDropdown &&
                    $authenticatedMyAddressDropdown.addEventListener(
                        'change',
                        authenticatedMyAddressChange
                    );
                $accountData = $reportOutage.getElementsByClassName(CONSTANTS.ACCOUNT_DATA)[0];
                mdacValidation();
            } else {
                // find account select
                $findAccountSelect.addEventListener('change', findAccountOptions);

                // not my address handler
                coned.utils.addGeneralListeners($notMyAddressLink, notMyAddress);

                // find account by address
                if ($searchByAddressLink) {
                    coned.utils.addGeneralListeners($searchByAddressLink, findAccountByAddress);
                }
            }

            // Set tabindex on end screen for a11y
            $finishBannerFocus && $finishBannerFocus.setAttribute(CONSTANTS.TABINDEX, '-1');

            window.addEventListener('resize', adjustMasks);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();

            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ReportOutage.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ReportOutage;
})();
