// ==================== RECAPTCHA COMPONENT =========================
/* global grecaptcha */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.Recaptcha = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CAPTCHA_ELEMENT_CLASS: 'js-recaptcha',
        GOOGLE_RECAPTCHA_API_EXPLICIT_URL:
            'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoadCallback&render=explicit',
        GOOGLE_RECAPTCHA_READY_EVENT: 'onRecaptchaLoaded',
        SUBMIT_BUTTON: 'js-submit-button',
        FORM_ACTIONS: 'form__actions',
        RECAPTCHA_LOADING_ERROR_WRAPPER: 'recaptcha-error-wrapper',
        RECAPTCHA_LOADING_ERROR: 'recaptcha__loading--error'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var Recaptcha = function ($form, callback, dataCallback, sendFormBack, recaptchaIndex) {
        /**
         * PRIVATE METHODS
         */
        var $captchaElement, $captchaForm, $submitButton;

        var checkRecaptcha = function ($newForm, newSendFormBack, newRecaptchaIndex, newCallback) {
            callback = newCallback ? newCallback : callback;
            dataCallback = newCallback ? newCallback : dataCallback;
            $form = $newForm || $form;
            sendFormBack = newSendFormBack || sendFormBack || false;
            var index =
                newRecaptchaIndex || newRecaptchaIndex === 0
                    ? newRecaptchaIndex
                    : recaptchaIndex || recaptchaIndex === 0
                    ? recaptchaIndex
                    : false;

            var encodedResponse = index || index === 0 ? getResponse(index) : getResponse();

            if (encodedResponse != '') {
                var formDataRecaptcha = new FormData(),
                    verifyUrl = $captchaElement.getAttribute('data-verify-url');

                if (coned.utils.isPatternLab()) {
                    verifyUrl = coned.plConstants.CAPTCHA_FAKE_RESPONSE;
                }
                query.postData(
                    verifyUrl,
                    successCheckRecaptcha,
                    function () {},
                    formDataRecaptcha,
                    false
                );
            } else {
                // invokes captcha challenge if response request fails.

                grecaptcha.execute();
            }
        };

        var getResponse = function (index) {
            if (!grecaptcha) return null;

            if (index === 0 || index === 1) {
                return grecaptcha.getResponse(index);
            } else if (recaptchaIndex === 0 || recaptchaIndex === 1) {
                return grecaptcha.getResponse(recaptchaIndex);
            } else {
                return grecaptcha.getResponse();
            }
        };

        var reset = function () {
            if (!grecaptcha) return null;

            grecaptcha.reset();
        };

        var renderRecaptcha = function () {
            if (dataCallback) {
                // grecaptcha.ready will run the function as soon as the reCaptcha library has loaded.
                grecaptcha.ready(function () {
                    grecaptcha.render($captchaElement, {
                        'site-key': $captchaElement.getAttribute('data-sitekey'),
                        'verify-url': $captchaElement.getAttribute('data-verify-url'),
                        size: 'invisible',
                        badge: 'bottomleft',
                        callback: function () {
                            if (sendFormBack) {
                                dataCallback($captchaForm);
                            } else {
                                dataCallback();
                            }
                        }
                    });
                });
            }
        };

        var successCheckRecaptcha = function (response) {
            if (response) {
                if (sendFormBack) {
                    callback($form);
                } else {
                    callback();
                }
            } else {
                grecaptcha.execute();
                return false;
            }
        };

        var initializeData = function () {
            $captchaForm = $form;
            $captchaElement = $captchaForm.querySelector('.' + CONSTANTS.CAPTCHA_ELEMENT_CLASS);
            $submitButton = $form.querySelectorAll('button[type=submit]')[0];
        };

        var initializeEvents = function () {
            try {
                if ($captchaElement && grecaptcha) {
                    renderRecaptcha();
                }
            } catch (error) {
                setIntervalRecaptchaLoop(false);
            }
        };

        /**
         * Loop if the recaptcha failed.
         * @param {Boolean} recaptchaStatus boolean to check if the recaptcha has loaded successfully or unsuccessfully at each loop.
         */
        var setIntervalRecaptchaLoop = function (recaptchaStatus) {
            var counter = 0,
                repetitions = 5,
                intervalLoop = setInterval(function () {
                    try {
                        if ($captchaElement && grecaptcha) {
                            renderRecaptcha();
                            recaptchaStatus = true;
                        }
                    } catch (error) {
                        coned.utils.addGeneralListeners($submitButton, preventSubmitFormEvent);
                    } finally {
                        if (recaptchaStatus) {
                            coned.utils.removeGeneralListeners(
                                $submitButton,
                                preventSubmitFormEvent
                            );
                            clearInterval(intervalLoop);
                        } else if (++counter === repetitions) {
                            clearInterval(intervalLoop);
                            createLoadingErrorElement();
                        }
                    }
                }, 3000);
        };

        /**
         * Create the error element only if the loop didn't work out.
         */
        var createLoadingErrorElement = function () {
            var captchaLoadingErrorWrapper = document.createElement('div'),
                captchaLoadingError = document.createElement('span'),
                submitButtonParent = query.selectParentElement(
                    $submitButton,
                    CONSTANTS.FORM_ACTIONS
                );

            captchaLoadingErrorWrapper.classList.add(CONSTANTS.RECAPTCHA_LOADING_ERROR_WRAPPER);
            captchaLoadingError.classList.add(CONSTANTS.RECAPTCHA_LOADING_ERROR);

            captchaLoadingError.innerHTML = coned.constants.ERROR_MESSAGE;

            captchaLoadingErrorWrapper.appendChild(captchaLoadingError);

            if (coned.utils.isMobile()) {
                query.insertChildAtIndex(submitButtonParent, captchaLoadingErrorWrapper, 2);
            } else {
                query.insertChildAtIndex(submitButtonParent, captchaLoadingErrorWrapper, 0);
            }
        };

        /**
         * Prevent user from clicking submit button while loop is taking place.
         */
        var preventSubmitFormEvent = function (event) {
            event.preventDefault();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };
        init();

        Recaptcha.prototype.checkRecaptcha = checkRecaptcha;
        Recaptcha.prototype.getResponse = getResponse;
        Recaptcha.prototype.reset = reset;
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    Recaptcha.prototype.isLoaded = function () {
        return isLoaded;
    };

    return Recaptcha;
})();
