// ==================== DEEP LINK COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.DeepLink = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        DEEP_LINK_BUTTON_CLASS: 'js-coned-deep-link',
        DEEP_LINK_BUTTONS_ATTRIBUTE: 'data-deep-link-target',
        DEEP_LINK_TARGET_ID: 'data-deep-link-id',
        ACTIVATE_TAB_ON_DEEP_LINK_ID: 'data-activate-tab-on-deep-link-id',
        HEADER_WRAPPER_CLASS: 'js-header-wrapper',
        DEEP_LINK_SCROLLED: 'js-deep-link-scrolled',
        HEADER_LOADED_WRAPPER_CLASS: 'js-header-loaded-wrapper',

        TAB_URL_PARAMETER_NAME: 'tab',
        TAB_CONTAINER: 'js-conedtab-section-dashboard',
        DATA_VALUE: 'data-value',
        TAB_INDEX: 'data-tab-index'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var DeepLink = function ($element) {
        var $deepLinkButtons = [],
            $headerWrapperElement,
            containerDeepLinkElement,
            observerDeepLinkElement,
            multipleGlobalLazyLoadElements,
            multipleGlobalLazyLoadEventsTriggered,
            _avoidDoubleTrigger,
            _isDeepLinkClickEvent;

        var updateDeepLinkUrl = function ($deepLinkButton) {
            var params = coned.utils.getUrlParameters();
            var deepLinkTargetId = $deepLinkButton.dataset.deepLinkTarget;
            var deepLinkAction = $deepLinkButton.dataset.deepLinkAction;

            if (deepLinkTargetId) {
                params[coned.constants.DEEP_LINK_URL_PARAM_NAME] = deepLinkTargetId;
                
                if (deepLinkAction) {
                  params[coned.constants.DEEP_LINK_URL_PARAM_ACTION] = deepLinkAction;
                }

                coned.utils.setUrlParameters(params);
            }
        };

        var getAbsoluteOffsetTopOfElement = function ($element) {
            var top = 0;

            do {
                top += $element.offsetTop || 0;
                $element = $element.offsetParent;
            } while ($element);

            return top;
        };

        var scrollToSection = function (deepLinkTargetId, isClick, event) {
            if (_avoidDoubleTrigger) return;

            _avoidDoubleTrigger = true;

            var deepLinkTargetTab,
                tabParams,
                $tabActive,
                $sectionActive,
                $button = event && event.currentTarget ? event.currentTarget : null,
                tabToShow =
                    $button && $button.dataset.deepLinkTab && $button.dataset.deepLinkTabType
                        ? {
                              TabId: $button.dataset.deepLinkTab,
                              TabType: $button.dataset.deepLinkTabType
                          }
                        : null,
                $tabToActivate = document.querySelector(
                    '[' + CONSTANTS.ACTIVATE_TAB_ON_DEEP_LINK_ID + '="' + deepLinkTargetId + '"]'
                );

            if (tabToShow) {
                var params = coned.utils.getUrlParameters();

                var paramName = tabToShow.TabType;
                //some tabs might contain same sectionName, so to make them unique it combines sectionName with tabIndex
                var tabSectionValue = tabToShow.TabId;

                // Update parameters only when the clicked tab is not already there
                var paramKeys = _.keys(params);
                if (!paramKeys.includes(paramName) || params[paramName] !== tabSectionValue) {
                    // Remove all other tab parameters if they are not children of the selected tab
                    var paramsToRemove = _.filter(paramKeys, function (key) {
                        return (
                            key.includes(CONSTANTS.TAB_URL_PARAMETER_NAME) &&
                            key.replace(CONSTANTS.TAB_URL_PARAMETER_NAME, '') >
                                paramName[paramName.length - 1]
                        );
                    });

                    params = _.omit(params, function (value, key) {
                        return paramsToRemove.includes(key);
                    });

                    params[paramName] = tabSectionValue;
                    coned.utils.setUrlParameters(params);
                }

                var sectionParams = tabSectionValue.split('-'),
                    $tab = document.querySelector(
                        '[' +
                            CONSTANTS.DATA_VALUE +
                            '="' +
                            sectionParams[0] +
                            '"][' +
                            CONSTANTS.TAB_INDEX +
                            '="' +
                            sectionParams[1] +
                            '"]'
                    );

                $tab.click();
            }

            // Var assignment needs to happen after $tab.click() has happend on the previous
            // if statement to get consistency, otherwise when triggering deep link buttons 
            // for a second time the wrong tab section could be selected
            deepLinkTargetTab = coned.utils.getUrlParameterValue(
                coned.constants.DEEP_LINK_URL_PARAM_TAB
            );
            tabParams = deepLinkTargetTab && deepLinkTargetTab.split('-');
            $tabActive = deepLinkTargetTab && document.getElementById(tabParams[0]);

            if ($tabActive) {
                $sectionActive = $tabActive.querySelector(
                    '[' + CONSTANTS.DEEP_LINK_TARGET_ID + '="' + deepLinkTargetId + '"]'
                );
            } else {
                $sectionActive = document.querySelector(
                    '[' + CONSTANTS.DEEP_LINK_TARGET_ID + '="' + deepLinkTargetId + '"]'
                );
            }

            if ($sectionActive && getComputedStyle($sectionActive).visibility === 'visible') {
                
                // Timer to wait until page finish loading
                setTimeout(function() {
                    var offset =
                      getAbsoluteOffsetTopOfElement($sectionActive) -
                      $headerWrapperElement.offsetHeight;
                    scrollAndFocusSection($sectionActive, offset);
                }, 1000);

                /*RCLM 121428: Added functionality so that the deep link also activates a tab
                marked with an attribute defined in ACTIVATE_TAB_ON_DEEP_LINK_ID 
                with value equal to deepLinkTargetId*/
                if ($tabToActivate) {
                    if(!$tabToActivate.dataset.activateTabOnDeepLinkOnlyOnClick) {
                        $tabToActivate.click();
                    // Don't trigger the tab on page load, only do it on click
                    } else if ($tabToActivate.dataset.activateTabOnDeepLinkOnlyOnClick && _isDeepLinkClickEvent) {
                        $tabToActivate.click();
                        _isDeepLinkClickEvent = false;
                    }
                }
            } else {
                _avoidDoubleTrigger = false;
            }
        };

        var scrollAndFocusSection = function($sectionActive, offset) {
            coned.utils.scrollTo(offset, 1000, function () {
                window.removeEventListener('service-loaded', scrollToParamDeepLink);
                query.fireEvent($sectionActive, 'deeplink-event');

                if (!$element.classList.contains(CONSTANTS.DEEP_LINK_SCROLLED)) {
                    $element.classList.add(CONSTANTS.DEEP_LINK_SCROLLED);
                }

                _avoidDoubleTrigger = false;
            });

            $sectionActive.setAttribute('tabindex', '-1');
            $sectionActive.focus();
        };

        var deepLinkClickEvent = function (event) {
            event.preventDefault();

            var deepLinkItem = event.currentTarget,
                deepLinkTargetId = deepLinkItem.dataset.deepLinkTarget;

            if (!deepLinkItem || !deepLinkTargetId) return;

            _isDeepLinkClickEvent = true;

            updateDeepLinkUrl(deepLinkItem);

            scrollToSection(deepLinkTargetId, true, event);
        };

        var scrollToParamDeepLink = function () {
            if (window.location.search.includes(coned.constants.DEEP_LINK_URL_PARAM_NAME)) {
                var deepLinkTargetId = coned.utils.getUrlParameterValue(
                        coned.constants.DEEP_LINK_URL_PARAM_NAME
                    ),
                    deepLinkTargetTab = coned.utils.getUrlParameterValue(
                        coned.constants.DEEP_LINK_URL_PARAM_TAB
                    ),
                    tabParams = deepLinkTargetTab && deepLinkTargetTab.split('-'),
                    $tabActive = deepLinkTargetTab && document.getElementById(tabParams[0]),
                    $sectionActive;

                if ($tabActive) {
                    $sectionActive = $tabActive.querySelector(
                        '[' + CONSTANTS.DEEP_LINK_TARGET_ID + '="' + deepLinkTargetId + '"]'
                    );
                } else {
                    $sectionActive = document.querySelector(
                        '[' + CONSTANTS.DEEP_LINK_TARGET_ID + '="' + deepLinkTargetId + '"]'
                    );
                }

                if ($sectionActive && !_avoidDoubleTrigger) {
                    containerDeepLinkElement = $sectionActive.parentNode;
                    if (getComputedStyle($sectionActive).visibility === 'visible') {
                        scrollToSection(deepLinkTargetId, false);
                    } else {
                        // The MutationObserver interface provides the ability to watch for changes being made to the DOM tree.
                        observerDeepLinkElement = new MutationObserver(function () {
                            if (getComputedStyle($sectionActive).visibility === 'visible') {
                                scrollToSection(deepLinkTargetId, false);
                            }
                        });
                        observerDeepLinkElement.observe(containerDeepLinkElement, {
                            attributes: true,
                            attributeFilter: ['style']
                        });
                    }
                }
            }
        };

        // Wait until all of the lazy load elements on the page have loaded to trigger the scroll
        var multipleGlobalLazyLoadEventsHandler = function () {
            multipleGlobalLazyLoadEventsTriggered++;
            if (multipleGlobalLazyLoadElements.length === multipleGlobalLazyLoadEventsTriggered) {
                scrollToParamDeepLink();
            }
        };

        var initializeLazyLoadHandler = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
            if (multipleGlobalLazyLoadElements.length) {
                // When multiple modules lazy load on a page, each should have the coned.constants.MULTIPLE_GLOBAL_LAZY_LOAD_ELEMENT class
                // and trigger the coned.constants.MULTIPLE_GLOBAL_LAZY_LOAD_EVENT_DONE event when done
                window.addEventListener('multiple-global-lazy-load-event-done', multipleGlobalLazyLoadEventsHandler);
            } else {
                window.addEventListener('service-loaded', scrollToParamDeepLink);
            }
            window.addEventListener('service-loaded', initializeLazyLoadedData);
        };

        var initializeLazyLoadedData = function () {
            _.each($deepLinkButtons, function ($deepLinkButton) {
                coned.utils.removeGeneralListeners($deepLinkButton, deepLinkClickEvent);
            });

            $deepLinkButtons = $element.getElementsByClassName(CONSTANTS.DEEP_LINK_BUTTON_CLASS);

            _.each($deepLinkButtons, function ($deepLinkButton) {
                coned.utils.addGeneralListeners($deepLinkButton, deepLinkClickEvent);
            });
        };

        var initializeData = function () {
            $headerWrapperElement = document.getElementsByClassName(
                CONSTANTS.HEADER_WRAPPER_CLASS
            )[0];
            $deepLinkButtons = $element.getElementsByClassName(CONSTANTS.DEEP_LINK_BUTTON_CLASS);
            _avoidDoubleTrigger = false;
            multipleGlobalLazyLoadElements = document.getElementsByClassName(coned.constants.MULTIPLE_GLOBAL_LAZY_LOAD_ELEMENT);
            multipleGlobalLazyLoadEventsTriggered = 0;
        };

        var initializeEvents = function () {
            _.each($deepLinkButtons, function ($deepLinkButton) {
                coned.utils.addGeneralListeners($deepLinkButton, deepLinkClickEvent);
            });
            if (!multipleGlobalLazyLoadElements.length) {
                scrollToParamDeepLink();
            }
            coned.utils.triggerEvent(window, 'header-ready');
        };

        var init = function () {
            if ($element.dataset.lazyLoaded && $element.dataset.lazyLoaded === 'true') {
                initializeData();
                initializeEvents();
                isLoaded = true;
            } else {
                if (coned.utils.isPatternLab()) {
                    initializeLazyLoadHandler();
                } else {
                    window.addEventListener('load', initializeLazyLoadHandler);
                }
            }
        };

        init();
    };

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    DeepLink.prototype.isLoaded = function () {
        return isLoaded;
    };

    return DeepLink;
})();
