// ==================== ORU POWER SWICTH FORM =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PowerSwitch = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        HIDDEN: 'hidden',
        RESIDENTIAL_RADIO: 'js-account-residential',
        COMMERCIAL_RADIO: 'js-account-commercial',
        RESIDENTIAL_NAME_INPUT_CONTAINER: 'js-residential-name-input',
        COMMERCIAL_NAME_INPUT_CONTAINER: 'js-commercial-name-input',
        RESIDENTIAL_NAME_INPUT_NAME: 'nameBillResidential',
        COMMERCIAL_NAME_INPUT_NAME: 'nameBillBusiness',
        EMPTY_STRING: ''
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var PowerSwitch = function ($powerSwitchForm) {
        /**
         * PRIVATE METHODS
         */
        var $residentialRadio,
            $commercialRadio,
            $residentialName,
            $commercialName;

        var residentialUserSelected = function () {
            //Clean input before showing it
            $residentialName.classList.remove(CONSTANTS.HIDDEN);
            query.setFormTextInputValue($powerSwitchForm, CONSTANTS.RESIDENTIAL_NAME_INPUT_NAME, CONSTANTS.EMPTY_STRING),
            $commercialName.classList.add(CONSTANTS.HIDDEN);
        };

        var commercialUserSelected = function () {
            $residentialName.classList.add(CONSTANTS.HIDDEN);
            query.setFormTextInputValue($powerSwitchForm, CONSTANTS.COMMERCIAL_NAME_INPUT_NAME, CONSTANTS.EMPTY_STRING);
            $commercialName.classList.remove(CONSTANTS.HIDDEN);
        };

        var initializeData = function () {
            $residentialRadio = $powerSwitchForm.getElementsByClassName(CONSTANTS.RESIDENTIAL_RADIO)[0];
            $commercialRadio = $powerSwitchForm.getElementsByClassName(CONSTANTS.COMMERCIAL_RADIO)[0];
            $residentialName = $powerSwitchForm.getElementsByClassName(CONSTANTS.RESIDENTIAL_NAME_INPUT_CONTAINER)[0];
            $commercialName = $powerSwitchForm.getElementsByClassName(CONSTANTS.COMMERCIAL_NAME_INPUT_CONTAINER)[0];
        }

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($residentialRadio, residentialUserSelected);
            coned.utils.addGeneralListeners($commercialRadio, commercialUserSelected);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        }

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PowerSwitch.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PowerSwitch;
})();
