// ==================== MARKET RATES COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.MarketRates = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        MARKET_RATES_FORM: '.js-market-rates',
        STANDARD_TOTAL: 'js-standard-total',
        MARKET_SUPPLY_TOTAL: 'js-market-supply-total',
        MARKET_SUPPLY_ON_PEAK: 'js-market-supply-on-peak',
        MARKET_SUPPLY_OFF_PEAK: 'js-market-supply-off-peak',
        SUPPLY_ADJUSTMENTS_TOTAL: 'js-supply-adjustments-total',
        SUPPLY_ADJUSTMENTS_ON_PEAK: 'js-supply-adjustments-on-peak',
        SUPPLY_ADJUSTMENTS_OFF_PEAK: 'js-supply-adjustments-off-peak',
        SUPPLY_CHARGE_TOTAL: 'js-supply-charge-total',
        SUPPLY_CHARGE_ON_PEAK: 'js-supply-charge-on-peak',
        SUPPLY_CHARGE_OFF_PEAK: 'js-supply-charge-off-peak',
        PRICE_TO_COMPARE: 'js-price-to-compare',
        FORM_SITECORE_ID_NAME: 'scId',
        FORM_LOADING: 'js-form-loading',
        SERVICE_ERROR: 'js-service-error',
        ERROR_TEXT_SELECTOR: 'js-error-message',
        HIDDEN_CLASS: 'hidden',
        SELECT_BILLING_PERIOD: 'js-billing-period',
        INPUT_FILLED: 'coned-input--filled',
        ACCOUNT_MAID_INPUT: 'accountMaid',
        MARKET_RATES_TABLE: 'js-market-rates-table',
        CANCEL_BUTTON: 'js-close-button',
        DEFAULT_OPTION_LINK_CLASS: 'js-default-option-link',
        AUTOCOMPLETE_LIST_WRAPPER_CLASS: 'js-autocomplete-list-wrapper',
        AUTOCOMPLETE_ITEM: 'js-autocomplete-item'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var MarketRates = function ($marketRates) {
        /**
         * PRIVATE METHODS
         */
        var $marketSupplyTotal,
            $marketSupplyOnPeak,
            $marketSupplyOffPeak,
            $supplyAdjustmentsTotal,
            $supplyAdjustmentsOnPeak,
            $supplyAdjustmentsOffPeak,
            $supplyChargeTotal,
            $supplyChargeOnPeak,
            $supplyChargeOffPeak,
            $priceToCompare,
            $formLoading,
            $serviceError,
            $selectBillingPeriod,
            $selectBillingPeriodWrapper,
            $accountMaidInput,
            $marketRatesTable,
            $cancelButton;

        var selectBillingPeriod = function () {
            $selectBillingPeriod.classList.add(CONSTANTS.INPUT_FILLED);
        };

        var calculateRates = function () {
            var serviceUrl = $marketRates.dataset.serviceUrl,
                billingPeriodDates = $selectBillingPeriod.dataset.billingPeriods,
                selectedDates,
                params;

            hideError();
            billingPeriodDates = JSON.parse(billingPeriodDates);
            selectedDates = $selectBillingPeriod.value.replaceAll(/\s|-|,/g,'');
            billingPeriodDates = billingPeriodDates[selectedDates] && billingPeriodDates[selectedDates].split('|');

            if(billingPeriodDates) {

                // Service Data
                params = {
                    Maid: $accountMaidInput.value,
                    ScId: query.getFormInputValue($marketRates, CONSTANTS.FORM_SITECORE_ID_NAME),
                    FromDate: billingPeriodDates && billingPeriodDates[0],
                    ToDate: billingPeriodDates && billingPeriodDates[1]
                };

                // Service Call
                params = JSON.stringify(params);
                query.postData(
                    serviceUrl,
                    successCalculateRatesService,
                    errorCalculateRatesService,               
                    params,
                    true,
                    $formLoading
                );
            } else {
                var data = [];
                $selectBillingPeriod.value = '';
                $selectBillingPeriod.classList.remove(coned.constants.INPUT_FILLED_CLASS);
                $selectBillingPeriod.classList.remove(CONSTANTS.VALID_CLASS);
                $selectBillingPeriod.classList.remove(coned.constants.INPUT_ERROR_CLASS);
                data.errorMsg = $selectBillingPeriod.dataset.invalidBillingPeriod;
                errorCalculateRatesService(data);
            }
        };

        var hideError = function () {
            $serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
        };

        var successCalculateRatesService = function (data) {
            if (coned.utils.isPatternLab()) {
                query.getData(
                    coned.plConstants.GET_MARKET_RATES,
                    setRates,
                    errorCalculateRatesService
                );
            } else {
                setRates(data);
            }
        };

        var setRates = function (data) {
            if (coned.utils.isOru()) {
                // If value can be converted to a number, round it to two decimals
                var priceToCompare = !Number.isNaN(parseFloat(data.KwhPriceRA))
                    ? coned.utils.round(parseFloat(data.KwhPriceRA), 2)
                    : data.KwhPriceRA;

                $priceToCompare.innerHTML = priceToCompare;
                $priceToCompare.parentElement.classList.remove(CONSTANTS.HIDDEN_CLASS);
            } else {
                // If value can be converted to a number, round it to two decimals
                var marketSupplyTotal = !Number.isNaN(parseFloat(data.MarketSupplyTotal))
                        ? coned.utils.round(parseFloat(data.MarketSupplyTotal), 2)
                        : data.MarketSupplyTotal,
                    marketSupplyOnPeak = !Number.isNaN(parseFloat(data.MarketSupplyOnPeak))
                        ? coned.utils.round(parseFloat(data.MarketSupplyOnPeak), 2)
                        : data.MarketSupplyOnPeak,
                    marketSupplyOffPeak = !Number.isNaN(parseFloat(data.MarketSupplyOffPeak))
                        ? coned.utils.round(parseFloat(data.MarketSupplyOffPeak), 2)
                        : data.MarketSupplyOffPeak,
                    supplyAdjustmentsTotal = !Number.isNaN(parseFloat(data.SupplyAdjustmentsTotal))
                        ? coned.utils.round(parseFloat(data.SupplyAdjustmentsTotal), 2)
                        : data.SupplyAdjustmentsTotal,
                    supplyAdjustmentsOnPeak = !Number.isNaN(parseFloat(data.SupplyAdjustmentsOnPeak))
                        ? coned.utils.round(parseFloat(data.SupplyAdjustmentsOnPeak), 2)
                        : data.SupplyAdjustmentsOnPeak,
                    supplyAdjustmentsOffPeak = !Number.isNaN(parseFloat(data.SupplyAdjustmentsOffPeak))
                        ? coned.utils.round(parseFloat(data.SupplyAdjustmentsOffPeak), 2)
                        : data.SupplyAdjustmentsOffPeak,
                    supplyChargeTotal = !Number.isNaN(parseFloat(data.SupplyChargeTotal))
                        ? coned.utils.round(parseFloat(data.SupplyChargeTotal), 2)
                        : data.SupplyChargeTotal,
                    supplyChargeOnPeak = !Number.isNaN(parseFloat(data.SupplyChargeOnPeak))
                        ? coned.utils.round(parseFloat(data.SupplyChargeOnPeak), 2)
                        : data.SupplyChargeOnPeak,
                    supplyChargeOffPeak = !Number.isNaN(parseFloat(data.SupplyChargeOffPeak))
                        ? coned.utils.round(parseFloat(data.SupplyChargeOffPeak), 2)
                        : data.SupplyChargeOffPeak;

                $marketSupplyTotal.innerHTML = marketSupplyTotal;
                $marketSupplyOnPeak.innerHTML = marketSupplyOnPeak;
                $marketSupplyOffPeak.innerHTML = marketSupplyOffPeak;

                $supplyAdjustmentsTotal.innerHTML = supplyAdjustmentsTotal;
                $supplyAdjustmentsOnPeak.innerHTML = supplyAdjustmentsOnPeak;
                $supplyAdjustmentsOffPeak.innerHTML = supplyAdjustmentsOffPeak;

                $supplyChargeTotal.innerHTML = supplyChargeTotal;
                $supplyChargeOnPeak.innerHTML = supplyChargeOnPeak;
                $supplyChargeOffPeak.innerHTML = supplyChargeOffPeak;
                $marketRatesTable.classList.remove(CONSTANTS.HIDDEN_CLASS);
            }

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($marketRates);
        };

        var errorCalculateRatesService = function (data) {
            var $msgTextSelector = $serviceError.getElementsByClassName(
                CONSTANTS.ERROR_TEXT_SELECTOR
            )[0];

            $serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $msgTextSelector.innerHTML = data.errorMsg
                ? data.errorMsg
                : coned.constants.ERROR_MESSAGE;
            $serviceError.focus();
        };

        var bindViewMoreInformation = function (event) {
            var $viewMoreInformationListItem = query.selectParentElement(
                event.target.getElementsByClassName(CONSTANTS.DEFAULT_OPTION_LINK_CLASS)[0],
                CONSTANTS.AUTOCOMPLETE_ITEM
            );

            if (!$viewMoreInformationListItem) {
                return;
            }

            coned.utils.addGeneralListeners($viewMoreInformationListItem, function () {
                $selectBillingPeriod.setAttribute(CONSTANTS.ARIA_EXPANDED, 'false');
                $selectBillingPeriodWrapper.classList.add(CONSTANTS.HIDDEN_CLASS);

                var redirectLink = $selectBillingPeriod.parentElement.dataset.defaultOptionLinkUrl;
                window.location.href = redirectLink;
            });
        };

        var initializeData = function () {
            $marketSupplyTotal = document.getElementsByClassName(CONSTANTS.MARKET_SUPPLY_TOTAL)[0];
            $marketSupplyOnPeak = document.getElementsByClassName(
                CONSTANTS.MARKET_SUPPLY_ON_PEAK
            )[0];
            $marketSupplyOffPeak = document.getElementsByClassName(
                CONSTANTS.MARKET_SUPPLY_OFF_PEAK
            )[0];
            $supplyAdjustmentsTotal = document.getElementsByClassName(CONSTANTS.SUPPLY_ADJUSTMENTS_TOTAL)[0];
            $supplyAdjustmentsOnPeak = document.getElementsByClassName(CONSTANTS.SUPPLY_ADJUSTMENTS_ON_PEAK)[0];
            $supplyAdjustmentsOffPeak = document.getElementsByClassName(CONSTANTS.SUPPLY_ADJUSTMENTS_OFF_PEAK)[0];
            $supplyChargeTotal = document.getElementsByClassName(CONSTANTS.SUPPLY_CHARGE_TOTAL)[0];
            $supplyChargeOnPeak = document.getElementsByClassName(
                CONSTANTS.SUPPLY_CHARGE_ON_PEAK
            )[0];
            $supplyChargeOffPeak = document.getElementsByClassName(
                CONSTANTS.SUPPLY_CHARGE_OFF_PEAK
            )[0];
            $priceToCompare = document.getElementsByClassName(CONSTANTS.PRICE_TO_COMPARE)[0];
            $formLoading = document.getElementsByClassName(CONSTANTS.FORM_LOADING)[0];
            $serviceError = $marketRates.getElementsByClassName(CONSTANTS.SERVICE_ERROR)[0];
            $selectBillingPeriod = $marketRates.getElementsByClassName(
                CONSTANTS.SELECT_BILLING_PERIOD
            )[0];
            $accountMaidInput = document.getElementsByName(CONSTANTS.ACCOUNT_MAID_INPUT)[0];
            $marketRatesTable = document.getElementsByClassName(CONSTANTS.MARKET_RATES_TABLE)[0];
            $cancelButton = $marketRates.getElementsByClassName(CONSTANTS.CANCEL_BUTTON)[0];
            $selectBillingPeriodWrapper = $selectBillingPeriod.parentElement.getElementsByClassName(
                CONSTANTS.AUTOCOMPLETE_LIST_WRAPPER_CLASS
            )[0];
        };

        var initializeEvents = function () {
        
            $selectBillingPeriod.addEventListener('selectedOption', selectBillingPeriod);

            $selectBillingPeriodWrapper.addEventListener('change', bindViewMoreInformation);
        
            new coned.components.ValidateForm(CONSTANTS.MARKET_RATES_FORM, calculateRates);

            coned.utils.addGeneralListeners($cancelButton, hideError);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    MarketRates.prototype.isLoaded = function () {
        return isLoaded;
    };

    return MarketRates;
})();
