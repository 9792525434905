// ==================== PASSWORD PROFILE COMPONENT =========================

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.PasswordProfile = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SHOW_PASSWORD_BUTTON: 'js-show-password',
        PASSWORD_INPUT: 'js-password-input',
        MESSAGE_CHARACTERS: 'js-register-characters',
        MESSAGE_UPPERCASE: 'js-register-uppercase',
        MESSAGE_NUMBER: 'js-register-number',
        MESSAGE_PASSWORD: 'js-register-password',
        INPUT_CLASS_SELECTOR: 'js-coned-input',
        ERROR_CLASS: 'register__validation--error',
        ERROR_MESSAGE_CLASS: 'coned-input-message--error',
        HIDDEN_CLASS: 'hidden',
        USERNAME_SELECTOR: 'js-username-selector',
        IGNORE_CLASS: 'js-validate-ignore',
        CHECK_ICON: 'icon-check-status',
        CLOSE_ICON: 'icon-close'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var PasswordProfile = function ($password) {
        /**
         * PRIVATE METHODS
         */
        var $showPasswordButton,
            $passwordInput,
            $messageCharacters,
            $messageUppercase,
            $messageNumber,
            $messagePassword,
            $usernameSelector,
            _dataUsername;

        var initializeData = function () {
            $showPasswordButton = $password.getElementsByClassName(
                CONSTANTS.SHOW_PASSWORD_BUTTON
            )[0];
            $passwordInput = $password.getElementsByClassName(CONSTANTS.PASSWORD_INPUT)[0];
            $messageCharacters = $password.getElementsByClassName(CONSTANTS.MESSAGE_CHARACTERS)[0];
            $messageUppercase = $password.getElementsByClassName(CONSTANTS.MESSAGE_UPPERCASE)[0];
            $messageNumber = $password.getElementsByClassName(CONSTANTS.MESSAGE_NUMBER)[0];
            $messagePassword = $password.getElementsByClassName(CONSTANTS.MESSAGE_PASSWORD)[0];
            $usernameSelector = document.getElementsByClassName(CONSTANTS.USERNAME_SELECTOR)[0];
            _dataUsername = $usernameSelector.innerHTML;
        };

        var showPassword = function (event) {
            event.preventDefault();

            var inputType = $passwordInput.type;

            if (inputType == 'password') {
                $passwordInput.type = 'text';
                event.target.innerHTML = this.dataset.replaceText;
            } else {
                $passwordInput.type = 'password';
                event.target.innerHTML = this.dataset.text;
            }
        };

        var testValue = function ($text_error, testCase, value) {
            var testExpression,
                testExpressionValue,
                testMinMax = /^.{8,30}$/,
                testUppercaseLowercase = /(?=.*[a-z])(?=.*[A-Z])/,
                testNumber = /[0-9\s]+/;

            // Case test
            switch (testCase) {
                case 'minMax':
                    testExpression = testMinMax;
                    break;
                case 'uppercase':
                    testExpression = testUppercaseLowercase;
                    break;
                case 'number':
                    testExpression = testNumber;
                    break;
                case 'password':
                    testExpressionValue = testUsername(value, _dataUsername);
                    break;
            }

            if (testCase != 'password') {
                testExpressionValue = testExpression.test(value);
            }

            // Show message
            if (testExpressionValue) {
                query.removeClass($text_error, CONSTANTS.ERROR_CLASS);
                query.removeClass($text_error.children[0], CONSTANTS.CLOSE_ICON);
                query.addClass($text_error.children[0], CONSTANTS.CHECK_ICON);
            } else {
                query.addClass($text_error, CONSTANTS.ERROR_CLASS);
                query.removeClass($text_error.children[0], CONSTANTS.CHECK_ICON);
                query.addClass($text_error.children[0], CONSTANTS.CLOSE_ICON);
            }

            return testExpressionValue;
        };

        var testUsername = function (password, username) {
            var lengthString = username.length,
                existOnPassword = true,
                subStringPiece,
                indexOfString;

            for (var i = 0; i + 3 <= lengthString; i++) {
                subStringPiece = username.substring(i, i + 3);
                indexOfString = password.indexOf(subStringPiece);

                if (indexOfString > 0) {
                    existOnPassword = false;
                }
            }

            return existOnPassword;
        };

        var passwordValidation = function () {
            var inputValue = $passwordInput.value;

            // Flags
            // min 8 - max 30
            var minMax = testValue($messageCharacters, 'minMax', inputValue);

            // Uppercase letter
            var uppercase = testValue($messageUppercase, 'uppercase', inputValue);

            // Number
            var number = testValue($messageNumber, 'number', inputValue);

            // Exist Username on Password
            var userOnPassword = testValue($messagePassword, 'password', inputValue);

            if (minMax && uppercase && number && userOnPassword) {
                $passwordInput.dataset.valid = true;
            } else {
                $passwordInput.dataset.valid = false;
            }
        };

        var initializeEvents = function () {
            coned.utils.addGeneralListeners($showPasswordButton, showPassword);

            if ($passwordInput != undefined) {
                $passwordInput.addEventListener('keyup', passwordValidation);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    PasswordProfile.prototype.isLoaded = function () {
        return isLoaded;
    };

    return PasswordProfile;
})();
