// ==================== SET NEW PASSWORD COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.SetNewPassword = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        FORM_CLASS_SELECTOR: '.js-set-password-form',
        PASSWORD_INPUT: 'js-password-input',
        CONFIRM_PASSWORD_INPUT: 'js-confirm-password-input',
        SHOW_PASSWORD_BUTTON: 'js-show-password',
        INPUT_CLASS: 'js-coned-input',
        MESSAGE_CHARACTERS: 'js-register-characters',
        MESSAGE_UPPERCASE: 'js-register-uppercase',
        MESSAGE_NUMBER: 'js-register-number',
        MESSAGE_PASSWORD: 'js-register-password',
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        USERNAME_SELECTOR: 'js-username-selector',
        ERROR_CLASS: 'register__validation--error',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        FORM_CONTAINER_SELECTOR: 'js-form-selector',
        FINISH_STEP_SELECTOR: 'js-finish-selector',
        LOGIN_URL_SELECTOR: 'js-login-url-selector',
        HIDDEN_CLASS: 'hidden',
        SET_PASSWORD_INPUT_NAME: 'newPassword',
        PASSWORD_ERROR: 'js-password-error',
        PASSWORD_ERROR_MESSAGE: 'js-error-message',
        SET_PASSWORD_SITECORE_ID: 'ScId',
        STATE_TOKEN: 'StateToken',
        LOGIN_URL: 'loginPageUrl',
        FROM_URI_PARAMETER: 'fromURI=',
        CHECK_ICON: 'icon-check-status',
        CLOSE_ICON: 'icon-close'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var SetNewPassword = function ($register) {
        /**
         * PRIVATE METHODS
         */
        var $passwordInput,
            $confirmPasswordInput,
            $showPasswordButtons,
            $messageCharacters,
            $messageUppercase,
            $messageNumber,
            $messagePassword,
            $submitButton,
            $submitAnimationButton,
            $formContainerSelector,
            $usernameSelector,
            $finishStepSelector,
            $passwordError,
            $passwordErrorMessage,
            _dataUsername,
            _data;

        var showPassword = function (event) {
            event.preventDefault();

            var $selectedInput = this.parentNode.getElementsByClassName(CONSTANTS.INPUT_CLASS)[0],
                inputType = $selectedInput.type;

            if (inputType == 'password') {
                $selectedInput.type = 'text';
                event.target.innerHTML = this.dataset.replaceText;
            } else {
                $selectedInput.type = 'password';
                event.target.innerHTML = this.dataset.text;
            }
        };

        var testValue = function ($text_error, testCase, value) {
            var testExpression,
                testExpressionValue,
                testMinMax = /^.{8,30}$/,
                testUppercaseLowercase = /(?=.*[a-z])(?=.*[A-Z])/,
                testNumber = /[0-9\s]+/;

            // Case test
            switch (testCase) {
                case 'minMax':
                    testExpression = testMinMax;
                    break;
                case 'uppercase':
                    testExpression = testUppercaseLowercase;
                    break;
                case 'number':
                    testExpression = testNumber;
                    break;
                case 'password':
                    testExpressionValue = testUsername(value, _dataUsername);
                    break;
            }

            if (testCase != 'password') {
                testExpressionValue = testExpression.test(value);
            }

            // Show message
            if (testExpressionValue) {
                query.removeClass($text_error, CONSTANTS.ERROR_CLASS);
                query.removeClass($text_error.children[0], CONSTANTS.CLOSE_ICON);
                query.addClass($text_error.children[0], CONSTANTS.CHECK_ICON);
            } else {
                query.addClass($text_error, CONSTANTS.ERROR_CLASS);
                query.removeClass($text_error.children[0], CONSTANTS.CHECK_ICON);
                query.addClass($text_error.children[0], CONSTANTS.CLOSE_ICON);
            }

            return testExpressionValue;
        };

        var testUsername = function (password, username) {
            var lengthString = username.length,
                existOnPassword = true,
                subStringPiece,
                indexOfString;

            for (var i = 0; i + 3 <= lengthString; i++) {
                subStringPiece = username.substring(i, i + 3);
                indexOfString = password.indexOf(subStringPiece);

                if (indexOfString > 0) {
                    existOnPassword = false;
                }
            }

            return existOnPassword;
        };

        var passwordValidation = function () {
            var inputValue = $passwordInput.value;

            // Flags
            // min 8 - max 30
            var minMax = testValue($messageCharacters, 'minMax', inputValue);

            // Uppercase letter
            var uppercase = testValue($messageUppercase, 'uppercase', inputValue);

            // Number
            var number = testValue($messageNumber, 'number', inputValue);

            // Exist Username on Password
            var userOnPassword = testValue($messagePassword, 'password', inputValue);

            if (minMax && uppercase && number && userOnPassword) {
                $passwordInput.classList.add(CONSTANTS.VALID_CONFIRMATION);
            } else {
                $passwordInput.classList.remove(CONSTANTS.VALID_CONFIRMATION);
            }

            checkFormStatus();
        };

        var checkFormStatus = function () {
            var isPasswordValid = query.hasClass($passwordInput, CONSTANTS.VALID_CONFIRMATION),
                validator = $($register).validate();

            if (isPasswordValid && validator.element($confirmPasswordInput)) {
                $submitButton.disabled = false;
            } else {
                $submitButton.disabled = true;
            }
        };

        var setNewPasswordCall = function () {
            var params,
                setNewPasswordService = $register.dataset.setPasswordService;

            params = {
                NewPassword: query.getFormInputValue($register, CONSTANTS.SET_PASSWORD_INPUT_NAME),
                ScId: query.getFormInputValue(document, CONSTANTS.SET_PASSWORD_SITECORE_ID),
                stateToken: query.getFormInputValue(document, CONSTANTS.STATE_TOKEN)
            };

            params = JSON.stringify(params);
            query.postData(
                setNewPasswordService,
                successSetNewPasswordCallback,
                errorSetNewPasswordCallback,
                params,
                true
            );
        };

        var successSetNewPasswordCallback = function (data) {
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            _data = data;

            if (_data && CONSTANTS.LOGIN_URL in _data && _data[CONSTANTS.LOGIN_URL]) {
                setTimeout(function () {
                    window.location.href = _data[CONSTANTS.LOGIN_URL];
                }, 5000);
            }
        };

        var errorSetNewPasswordCallback = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var validatedSetPassword = function () {
            hideError();

            if (coned.utils.isPatternLab()) {
                var resetValidateService = coned.plConstants.SET_NEW_PASSWORD;

                query.getData(resetValidateService, setPassword, function () {});
            } else {
                setPassword();
            }
        };

        var setPassword = function () {
            var $loginUrl = document.getElementsByClassName(CONSTANTS.LOGIN_URL_SELECTOR)[0];
            if (_data && CONSTANTS.LOGIN_URL in _data && _data[CONSTANTS.LOGIN_URL]) {
                var newLink =
                    $loginUrl.getAttribute('href') +
                    '?' +
                    CONSTANTS.FROM_URI_PARAMETER +
                    encodeURIComponent(_data[CONSTANTS.LOGIN_URL]);
                $loginUrl.setAttribute('href', newLink);
            }
            $formContainerSelector.classList.add(CONSTANTS.HIDDEN_CLASS);
            $finishStepSelector.classList.remove(CONSTANTS.HIDDEN_CLASS);
        };

        var errorServiceCallback = function () {
            $passwordError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            $passwordErrorMessage.innerHTML = _data.errorMsg;
            $passwordError.focus();
        };

        var hideError = function () {
            $passwordError.classList.add(CONSTANTS.HIDDEN_CLASS);
            $passwordErrorMessage.innerHTML = '';
        };

        var setNewPasswordSubmit = function () {
            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                setNewPasswordCall,
                validatedSetPassword,
                errorServiceCallback,
                false,
                true
            );
        };

        var initializeData = function () {
            $passwordInput = $register.getElementsByClassName(CONSTANTS.PASSWORD_INPUT)[0];
            $confirmPasswordInput = $register.getElementsByClassName(
                CONSTANTS.CONFIRM_PASSWORD_INPUT
            )[0];
            $showPasswordButtons = $register.getElementsByClassName(CONSTANTS.SHOW_PASSWORD_BUTTON);
            $messageCharacters = $register.getElementsByClassName(CONSTANTS.MESSAGE_CHARACTERS)[0];
            $messageUppercase = $register.getElementsByClassName(CONSTANTS.MESSAGE_UPPERCASE)[0];
            $messageNumber = $register.getElementsByClassName(CONSTANTS.MESSAGE_NUMBER)[0];
            $messagePassword = $register.getElementsByClassName(CONSTANTS.MESSAGE_PASSWORD)[0];
            $submitButton = $register.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            $usernameSelector = $register.getElementsByClassName(CONSTANTS.USERNAME_SELECTOR)[0];
            $submitAnimationButton = $register.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            $formContainerSelector = document.getElementsByClassName(
                CONSTANTS.FORM_CONTAINER_SELECTOR
            )[0];
            $finishStepSelector = document.getElementsByClassName(
                CONSTANTS.FINISH_STEP_SELECTOR
            )[0];
            $passwordError = $register.getElementsByClassName(CONSTANTS.PASSWORD_ERROR)[0];
            $passwordErrorMessage = $register.getElementsByClassName(
                CONSTANTS.PASSWORD_ERROR_MESSAGE
            )[0];
            _dataUsername = $usernameSelector.dataset.username;
        };

        var initializeEvents = function () {
            $passwordInput.addEventListener('keyup', passwordValidation);
            $confirmPasswordInput.addEventListener('keyup', checkFormStatus);

            for (var index = 0; index < $showPasswordButtons.length; index++) {
                coned.utils.addGeneralListeners($showPasswordButtons[index], showPassword);
            }

            // form validation
            new coned.components.ValidateForm(CONSTANTS.FORM_CLASS_SELECTOR, setNewPasswordSubmit);
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    SetNewPassword.prototype.isLoaded = function () {
        return isLoaded;
    };

    return SetNewPassword;
})();
