// ==================== UTILS/MOXIE =========================
/* global dataLayer */
/* global _ */

var coned = coned || {};
coned.moxie = coned.moxie || {};

// List/dictionary with all the events that will be listened.
coned.moxie.eventList = {
    'GoMoxie:widget:chat:suggestedArticles': 'suggested',
    'GoMoxie:widget:chat:articleViewed': 'viewedchat',
    'GoMoxie:widget:kb:articleViewed': 'viewedkb',
    'GoMoxie:widget:chat:deflected': 'deflected',
    'GoMoxie:widget:chat:escalatedToChat': 'chat',
    'GoMoxie:widget:kb:portalSearched': 'search',
    'GoMoxie:proactiveOffer': 'displayed'
};

// Dynamic handler for all moxie events.
coned.moxie.eventHandler = function eventHandler(event) {
    var data = {
            event: 'moxiekbot.widget.' + coned.moxie.eventList[event.type]
        },
        articlesList = [],
        detail = event.detail;

    // Checks if response has content
    if (detail) {
        // Adds all article titles in case the response contains an articleList
        if (detail.articlesList) {
            _.each(detail.articlesList, function (article) {
                articlesList.push(article.articleTitle.trim());
            });
            data.articleTitle = articlesList.join('|');
        }

        // Adds searchText in case the response contains it
        if (detail.searchText) {
            data.searchText = detail.searchText;
        }

        // Adds articleTitle in case the response contains it
        if (detail.articleTitle) {
            data.articleTitle = detail.articleTitle;
        }

        //When proactive offer trigger needs to check the status value before pushes data to dataLayer
        if (detail.status) {
            if (detail.status === 'displayed') {
                data.widgetName = detail.rule.name;
                dataLayer.push(data);
            }
        } else {
            // Pushes data into dataLayer
            dataLayer.push(data);
        }
    }
};

// Initialize events
coned.moxie.initEvents = function initEvents() {
    for (var moxieEvent in coned.moxie.eventList) {
        window.addEventListener(moxieEvent, coned.moxie.eventHandler);
    }
};
