// ==================== IMAGE MODAL COMPONENT =========================
/* global _ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ImageModalModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        MAIN_CONTENT_ID: 'mainContent',
        HIDDEN_CLASS: 'hidden',
        CLOSE_BUTTON: 'js-image-modal-close',
        EXPAND_BUTTON: 'js-image-modal-expand',
        OPEN_BUTTON: 'js-image-modal-open',
        ARIA_EXPANDED_ATTRIBUTE: 'aria-expanded'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var ImageModalModule = function ($ImageModal) {
        /**
         * PRIVATE METHODS
         */
        var $imageModalOpenButtons,
            $imageModalCloseButtons;

        
        var closeImageModal = function(imageModal) {
            imageModal.classList.add(CONSTANTS.HIDDEN_CLASS);

            // A11y Handlers
            _.each($imageModalOpenButtons, function ($button) {
                $button.setAttribute(CONSTANTS.ARIA_EXPANDED_ATTRIBUTE, false);
            });

            if(imageModal && imageModal.dataset.noBackFocus) {
                return;
            } else {
                // A11y Handler back to focus
                if (imageModal.BackFocus) {
                    imageModal.BackFocus.focus({preventScroll: true});
                } else {
                    document.getElementById(CONSTANTS.MAIN_CONTENT_ID).focus();
                }
            }
        };

        var imageModalHandler = function (event) {
            event.preventDefault();

            var show = query.hasClass(this, CONSTANTS.OPEN_BUTTON);

            if (show) {
                $ImageModal.classList.remove(CONSTANTS.HIDDEN_CLASS);

                // A11y Handlers
                _.each($imageModalOpenButtons, function ($button) {
                    $button.setAttribute(CONSTANTS.ARIA_EXPANDED_ATTRIBUTE, true);
                });

                event.currentTarget && ($ImageModal.BackFocus = event.currentTarget);

                var button_close = $ImageModal.getElementsByClassName(CONSTANTS.CLOSE_BUTTON);
                button_close[0].focus();
            } else {
                closeImageModal($ImageModal, event);
            }

        };

        /**
         * Close modal with keyboard: ESC key_code : 27
         * @param {*} event
         * @param {*} popup
         */
        var handlerImageModalKeyBoard = function (event, modal) {
            if (event.keyCode == coned.constants.KEY_CODE.ESC) {
                //Check if a modal is visible on the screen.
                var modalOpen = !query.hasClass(modal, CONSTANTS.HIDDEN_CLASS);
                if (modalOpen) {
                    closeImageModal(modal, event);
                }
            }
        };

        var initializeData = function () {
            $imageModalOpenButtons = document.querySelectorAll(
                '.' + CONSTANTS.EXPAND_BUTTON + '.' + $ImageModal.dataset.openButton
            );
            $imageModalCloseButtons = $ImageModal.getElementsByClassName(CONSTANTS.CLOSE_BUTTON);
        };

        var initializeEvents = function () {
            _.each($imageModalOpenButtons, function ($button) {
                $button.addEventListener('click', imageModalHandler);
            });

            _.each($imageModalCloseButtons, function ($button) {
                $button.addEventListener('click', imageModalHandler);
            });

            new coned.utils.addFocusTrap($ImageModal);

            $ImageModal.addEventListener('keydown', function (event) {
                handlerImageModalKeyBoard(event, $ImageModal);
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ImageModalModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ImageModalModule;
})();
