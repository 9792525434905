// ==================== TOOLTIP COMPONENT =========================
/* global $ */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.ToolTipComponent = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        ARIA_EXPANDED: 'aria-expanded',
        ROLE: 'role',
        DIALOG_ROLE: 'dialog',
        REGION_ROLE: 'region',
        ARIA_LABEL: 'aria-label',
        ARIA_LABELLEDBY: 'aria-labelledby',
        TOOLTIP_DUMMY_FOCUS: 'js-tooltip-focus',
        TOOLTIP_OPEN_SELECTOR: 'js-tooltip-open',
        TOOLTIP_CLOSE_SELECTOR: 'js-tooltip-close',
        TOOLTIP_WRAPPER_SELECTOR: 'js-coned-tooltip-wrapper',
        TOOLTIP_CONTENT_CLASS: 'coned-tooltip__content',
        TOOLTIP_TEMPLATES_CLASS: 'tooltip__templates',
        TOOLTIPSTER_CLASS: 'tooltipster-sidetip',
        TOOLTIPSTER_CLASS_ORU: 'tooltipster-sidetip--oru',
        TOOLTIP_CLASS_ORU: 'coned-tooltip--oru',
        TOOLTIP_CLASS_OPEN: 'tooltip-opened',
        MOUSE_USER_CLASS: 'mouse-user',
        BODY_CLASS: 'js-body'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var ToolTipComponent = function ($toolTipComponent) {
        /**
         * PRIVATE METHODS
         */
        var $tooltipOpenButton,
            $toolTipContainer,
            $toolTipContainerWrapper,
            $tooltipCloseButton,
            $tooltipFocus,
            $content,
            $contentCl;

        var initializeData = function () {
            $tooltipOpenButton = $toolTipComponent.getElementsByClassName(
                CONSTANTS.TOOLTIP_OPEN_SELECTOR
            )[0];
        };

        var initializeEvents = function () {
            $($toolTipComponent)
                .find('.' + CONSTANTS.TOOLTIP_OPEN_SELECTOR)
                .tooltipster({
                    content: $($toolTipComponent).find('.' + CONSTANTS.TOOLTIP_WRAPPER_SELECTOR),
                    trigger: 'custom',
                    onlyOne: true,
                    side: 'top',
                    width: '100%',
                    minWidth: '100%',
                    zIndex: '9998', // 1 Less than the header
                    interactive: true,
                    functionBefore: onTooltipInit,
                    functionReady: onTooltipOpen,
                    triggerOpen: {
                        click: true,
                        touchstart: true
                    },
                    triggerClose: {
                        click: true,
                        touchstart: true
                    }
                });

            var $closeButton = $toolTipComponent.getElementsByClassName(
                CONSTANTS.TOOLTIP_CLOSE_SELECTOR
            )[0];

            setA11YValues();

            if ($closeButton) {
                $closeButton.focusBack = true;
                $closeButton.addEventListener('click', closeTooltip);
            }
        };

        var setA11YValues = function () {
            var toolTipWrapper = $toolTipComponent.getElementsByClassName(
                CONSTANTS.TOOLTIP_WRAPPER_SELECTOR
            )[0];

            var tooltipContent = $toolTipComponent.getElementsByClassName(
                CONSTANTS.TOOLTIP_CONTENT_CLASS
            )[0];

            var tooltipCloseButton = $toolTipComponent.getElementsByClassName(
                CONSTANTS.TOOLTIP_CLOSE_SELECTOR
            )[0];

            var generatedID;
            do {
                generatedID = coned.utils.generateUUID();
            } while (document.getElementById(generatedID));

            toolTipWrapper && toolTipWrapper.setAttribute(CONSTANTS.ROLE, CONSTANTS.DIALOG_ROLE);
            toolTipWrapper && toolTipWrapper.setAttribute(CONSTANTS.ARIA_LABELLEDBY, generatedID);
            tooltipContent && tooltipContent.setAttribute('id', generatedID);

            //The aria-label is added if it doesn't exist.
            if (tooltipCloseButton && !tooltipCloseButton.hasAttribute(CONSTANTS.ARIA_LABEL)) {
                tooltipCloseButton.setAttribute(CONSTANTS.ARIA_LABEL, 'Close');
                tooltipCloseButton.setAttribute('type', 'button');
            }
        };

        /**
         * Handles tooltip open bindings and accessibility
         */
        var onTooltipClose = function (event) {
            var $body = document.getElementsByClassName(CONSTANTS.BODY_CLASS)[0];
            $('.' + CONSTANTS.TOOLTIP_DUMMY_FOCUS).remove();

            if (!$body.classList.contains(CONSTANTS.MOUSE_USER_CLASS) || event.target.focusBack) {
                $tooltipOpenButton && $tooltipOpenButton.focus();
            }
        };

        /**
         * Handles tooltip before is displayed
         */
        var onTooltipInit = function () {
            /**
             * Handles duplicate content to avoid removing the tooltip template
             */
            $content = $toolTipComponent.getElementsByClassName(
                CONSTANTS.TOOLTIP_TEMPLATES_CLASS
            )[0];
            $contentCl = $content && $content.cloneNode(true);
        };

        /**
         * Handles tooltip open bindings and accessibility
         */
        var onTooltipOpen = function (event) {
            $toolTipContainer = document.getElementById(event.__namespace);

            new coned.utils.addFocusTrap($toolTipContainer);

            $toolTipContainerWrapper = $toolTipContainer.getElementsByClassName(
                CONSTANTS.TOOLTIP_WRAPPER_SELECTOR
            )[0];
            $tooltipCloseButton = $toolTipContainer.getElementsByClassName(
                CONSTANTS.TOOLTIP_CLOSE_SELECTOR
            )[0];
            $tooltipFocus = document.createElement('div');

            $tooltipOpenButton.setAttribute(CONSTANTS.ARIA_EXPANDED, 'true');
            $toolTipContainer.addEventListener('keydown', handleKeyPressed);
            $toolTipContainerWrapper.setAttribute(CONSTANTS.ROLE, CONSTANTS.DIALOG_ROLE);

            $tooltipCloseButton.focus();
            $toolTipContainer.classList.add(CONSTANTS.TOOLTIP_CLASS_OPEN);
            $tooltipFocus.classList.add(CONSTANTS.TOOLTIP_DUMMY_FOCUS);
            $tooltipFocus.setAttribute('tabindex', '0');
            $($tooltipFocus).appendTo('body');
            if ($contentCl) {
                var $toolTipContent = $toolTipComponent.getElementsByClassName(
                    CONSTANTS.TOOLTIP_TEMPLATES_CLASS
                );
                if (
                    !$toolTipContent[0].getElementsByClassName(
                        CONSTANTS.TOOLTIP_WRAPPER_SELECTOR
                    )[0]
                ) {
                    $toolTipContent && $toolTipContent[0].appendChild($contentCl.children[0]);
                }
            }

            if (coned.utils.isOru()) {
                query.addClass($toolTipContainer, CONSTANTS.TOOLTIPSTER_CLASS_ORU);
                query.addClass($toolTipContainer, CONSTANTS.TOOLTIP_CLASS_ORU);
            }

            // When the user focus out the tooltip, close it.
            $toolTipContainer.addEventListener('focusout', function (event) {
                if (!$toolTipContainer.contains(event.relatedTarget)) {
                    closeTooltip(event);
                }
            });
        };

        /*
         * Handle a key pressed when the tooltip is expanded.
         */
        var handleKeyPressed = function (event) {
            var keycode = event && event.keyCode ? event.keyCode : null;

            if (keycode == coned.constants.KEY_CODE.ESC) {
                closeTooltip(event);
            }
        };

        var closeTooltip = function (event) {
            if ($toolTipContainer.classList.contains(CONSTANTS.TOOLTIP_CLASS_OPEN)) {
                $toolTipContainer.classList.remove(CONSTANTS.TOOLTIP_CLASS_OPEN);

                $toolTipContainer.removeEventListener('blur', closeTooltip);
                $tooltipOpenButton.setAttribute(CONSTANTS.ARIA_EXPANDED, 'false');
                $($toolTipComponent)
                    .find('.' + CONSTANTS.TOOLTIP_OPEN_SELECTOR)
                    .tooltipster('hide');

                onTooltipClose(event);
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    ToolTipComponent.prototype.isLoaded = function () {
        return isLoaded;
    };

    return ToolTipComponent;
})();
