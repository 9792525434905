// ==================== FORM VALIDATION COMPONENT =========================
/* global $ */
/* global _ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.FormValidationModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        PAGE_HEADER: 'js-page-header',
        MESSAGE_WRAPPER: 'js-form-messages',
        MESSAGE_SUCESS: 'js-success-message',
        MESSAGE_SUCESS_FOCUS: 'js-success-message-focus',
        MESSAGE_SUCCESS_ALT: 'js-success-alt-message',
        MESSAGE_SUCCESS_ALT_FOCUS: 'js-success-alt-message-focus',
        MESSAGE_ERROR: 'js-error-message',
        MESSAGE_ERROR_FOCUS: 'js-error-message-focus',
        CONTACT_COPY: 'js-contact-us-copy-block',
        CONTACT_SUCCESS_CLASS: 'contact-us-form__copy-block--success',
        CAPTCHA_ELEMENT: 'js-recaptcha',
        INPUT_ERROR_CLASS: 'coned-input-message--error',
        FIELD_ERROR_CLASS: 'coned-field-error-wrapper',
        CONED_INPUT: '.coned-input',
        CONED_INPUT_PHONE: '.coned-input-phone',
        CONED_TEXT_AREA: '.coned-textarea',
        CONED_FILE_INPUT: '.coned-inputfile',
        CONED_SUBMIT_BUTTON: '.js-submit-button',
        CONED_INPUT_BIRTH_DATE: '.js-birth-date',
        CONED_INPUT_DATE_FORMAT: '.js-date-format',
        CONED_INPUT_WEEK_DAYS: '.js-week-days',
        CONED_INPUT_HOUR_DAY: '.js-hours-day',
        INPUT_FILLED_CLASS: 'coned-input--filled',
        TEXT_AREA_FILLED_CLASS: 'coned-textarea--filled',
        INPUT_VALID_CLASS: 'valid',
        GET_FILE_LIST: 'js-file-list-button',
        RESET_BUTTON: 'js-reset-button',
        TRY_AGAIN_BUTTON: 'js-try-again-button',
        FORM_SELECTOR: 'coned-form',
        DROPDOWN_LABEL: 'js-coned-select-label',
        INPUT_TEXT_SELECTOR: 'js-coned-input',
        TEXTAREA_SELECTOR: 'js-coned-textarea',
        FORM_LOADING_SELECTOR: 'js-form-loading',
        FORM_LOADING_HIDDEN: 'form-loading--hidden',
        FORM_LOADING_IMAGE: 'js-form-loading-image',
        HEADER_WRAPPER: 'js-header-wrapper',
        BORDER_ANIMATION_SELECTOR: 'js-border-bar-selector',
        BORDER_ANIMATION_CLASS: 'border-bar--animate',
        DATE_SELECTOR: 'js-date',
        RADIO_BUTTON_SELECTOR: 'js-coned-radio',
        CHECKBOX_SELECTOR: 'js-checkbox-selector',
        CHECKBOX_SWITCH_FOCUS_SELECTOR: 'js-checkbox-switch-focus',
        DROPDOWN_SELECTOR: 'js-coned-select',
        ACTIVE_DROPDOWN: 'coned-select--active',
        CHECKBOX_CHECKED_CLASS: 'coned-checkbox--checked',
        CHECKBOX_AUTO_CLASS: 'coned-checkbox--auto',
        CURRENCY_INPUT_SELECTOR: 'js-currency-input',
        CURRENCY_INPUT_OPTIONAL_SELECTOR: 'js-currency-input-optional',
        TIME_INPUT_SELECTOR: 'js-daytime-format',
        ZIPCODE_USA_INPUT_SELECTOR: 'js-zipcode-input',
        ZIPCODE_USA_SELECTOR: 'js-zipcode-input-selector',
        NO_SPACES_SELECTOR: 'js-input-no-spaces',
        FORM_IGNORE_VALIDATION: '.js-validate-ignore',
        PHONE_EXTENSION_SELECTOR: 'js-validate-phone-extension',
        CONED_INPUT_FILLED: '.js-item-filled',
        CONED_INPUT_NUMBERS: '.js-number-input',
        NUMBER_FORMAT_SELECTOR: 'js-number-format',
        CONED_NUMBER_ACCOUNT: 'js-coned-account-number',
        ORU_NUMBER_ACCOUNT: 'js-oru-account-number',
        ORU_ADDRESS_INPUT: 'js-oru-address-input',
        CONED_INPUT_SSN: '.js-input-ssn',
        CONED_ADDRESS_INPUT: 'js-address-input',
        CONED_EIN_TAX_INPUT: '.js-ein-tax-input',
        LIMITED_LENGTH_INPUT: 'js-limit-length-input',
        TOTAL_AMOUNT_RESULT_SELECTOR: 'js-total-result',
        TOTAL_RESULT_HIDDEN_INPUTS_SELECTOR: 'js-total-result-hidden-input',
        CHECKBOX_EQUAL_HIERARCHY: 'js-checkbox-equal-hierarchy-selector',
        VALIDATE_IN_BETWEEN_LENGTH_SELECTOR: 'js-validate-no-in-between-length',
        VALIDATE_START_MIN_LENGTH_SELECTOR: 'js-validate-start-min-length',
        VALIDATE_START_KEY_UP_SELECTOR: 'js-validate-start-key-up',
        VALIDATE_START_MIN_EMAIL_SELECTOR: 'js-validate-start-min-email',
        VALIDATE_START_BLUR_SELECTOR: 'js-validate-start-blur',
        VALIDATE_TAB_SELECTOR: 'js-validate-tab',
        VALIDATE_START_CHANGE_SELECTOR: 'js-validate-start-change',
        VALIDATE_START_TWELVE_DIGITS_SELECTOR: 'js-validate-start-twelve-digits',
        DATA_INPUT_SHOULD_START_VALIDATE: 'data-input-should-start-validate',
        VALIDATE_CHANGE_FILLED_SELECTOR: 'js-validate-change-filled',
        KEYUP_EVENT: 'keyup',
        CHANGE_EVENT: 'change',
        TOP_ERROR_MESSAGE: 'js-error-top-message',
        BLUR_EVENT: 'blur',
        SSN_LENGTH: 9,

        //A11Y Attributes
        ACCESSIBILITY_FORM: 'js-a11y-form',
        ACCESSIBILITY_FORM_ERROR: 'js-a11y-form--error',
        TABINDEX: 'tabindex',

        //Class to modified focus in the checkbox
        FOCUS_LABEL_CHECKBOX: 'coned-checkbox--focus',
        CHECKBOX_SWITCH_FOCUS: 'coned-checkbox-switch--focus',

        //Style modifiers
        FORM_WRAPPER_NO_BACKGROUND_CLASS: 'coned-form-wrapper--no-background'
    };

    var isLoaded = false,
        timerSSN;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var FormValidationModule = function ($formValidation) {
        /**
         * PRIVATE METHODS
         */
        var $pageHeader,
            $messageWrapper,
            $successMessage,
            $successAltMessage,
            $errorMessage,
            $contactCopy,
            $captchaElement,
            $resetButton,
            $inputsFilledSelector,
            $inputsValidateNoInBetweenLengthSelector,
            $inputsValidateStartMinLengthSelector,
            $inputsValidateStartKeyupSelector,
            $inputsValidateStartMinEmailSelector,
            $inputsValidateStartBlurSelector,
            $selectsValidateTabSelector,
            $selectsValidateStartChangeSelector,
            $datePickersValidateChangeFilledSelector,
            $inputsValidateStartTwelveDigitsSelector,
            $textareasSelector,
            $datesSelector,
            $checkboxesSelector,
            $checkboxesSwitchFocusSelector,            
            $dropdownLabels,
            $dropdownsSelector,
            $radioButtonsSelector,
            $currencyInputsSelector,
            $timeInputsSelector,
            $zipcodeUSAInputsSelector,
            $zipcodeUSASelector,
            $noSpacesInputSelector,
            $phoneExtensionInputs,
            $numbersFormatSelector,
            $conedNumberAccounts,
            $oruNumberAccounts,
            $conedAddressInputs,
            $limitedLengthInputs,
            $totalResults,
            $totalResultHiddenInputs,
            $noTransactionalForms,
            $activeForm,
            $formLoading,
            $loadingImage,
            $headerWrapper,
            $tryAgainButton,
            $a11yFormSelector,
            $inlineErrorMessage,
            recaptcha,
            _targetUrl,
            _actualFormData,
            _formData,
            _hasCaptcha,
            _scrolledY,
            _response;

        // include class to animate label pattern
        var labelPattern = function () {
            $(CONSTANTS.CONED_INPUT).bind('input change keyup', function () {
                if ($(this).val() !== '') {
                    $(this).addClass(CONSTANTS.INPUT_FILLED_CLASS);
                } else {
                    $(this).removeClass(CONSTANTS.INPUT_FILLED_CLASS);
                }
            });

            $(CONSTANTS.CONED_TEXT_AREA).bind('change keyup', function () {
                if ($(this).val() !== '') {
                    $(this).addClass(CONSTANTS.TEXT_AREA_FILLED_CLASS);
                } else {
                    $(this).removeClass(CONSTANTS.TEXT_AREA_FILLED_CLASS);
                }
            });

            $(CONSTANTS.CONED_INPUT_PHONE).bind('input keyup', function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
            });

            $($formValidation)
                .find(CONSTANTS.CONED_FILE_INPUT)
                .bind('inputFileInvalid inputFileValid', function (event) {
                    $($formValidation).find(CONSTANTS.CONED_SUBMIT_BUTTON)[0].disabled =
                        event.type === 'inputFileInvalid';
                });

            $(CONSTANTS.CONED_INPUT_BIRTH_DATE).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
                    .slice(1)
                    .join('/')
                    .replace(/\/*$/g, '');
            });

            $(CONSTANTS.CONED_INPUT_DATE_FORMAT).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
                    .slice(1)
                    .join('/')
                    .replace(/\/*$/g, '');
            });

            $(CONSTANTS.CONED_INPUT_WEEK_DAYS).keyup(function () {
                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,1})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
            });

            $(CONSTANTS.CONED_INPUT_HOUR_DAY).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,2})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
            });

            $(CONSTANTS.CONED_INPUT_FILLED).keyup(function () {
                $(this).valid();
            });

            $(CONSTANTS.CONED_INPUT_NUMBERS).keyup(function () {
                this.value = this.value.replace(/[^0-9]/g, '');
            });

            $(CONSTANTS.CONED_INPUT).keyup(function () {
                // trims whitespace at the begining
                this.value = this.value.replace(/^\s+/g, '');
            });

            $(CONSTANTS.CONED_INPUT_SSN).keyup(function (event) {
                // Avoid the processing of backspace/delete, shift and arrows as an alphanumeric character that requires formatting.
                if (coned.utils.preventBehaviourError(event)) return;

                var waitTime = 1000,
                    inputElement = this;

                clearTimeout(timerSSN);

                timerSSN = setTimeout(function () {
                    var inputValueLength = inputElement.value.length;
                    inputElement.value = inputElement.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
                    //Validate again
                    if(inputValueLength > CONSTANTS.SSN_LENGTH)
                        $(inputElement).valid();
                }, waitTime);

            });

            $(CONSTANTS.CONED_EIN_TAX_INPUT).keyup(function (event) {
                if (coned.utils.preventBehaviourError(event)) return;

                this.value = this.value
                    .match(/\d*/g)
                    .join('')
                    .match(/(\d{0,2})(\d{0,7})/)
                    .slice(1)
                    .join('-')
                    .replace(/-*$/g, '');
            });
        };

        // no transactional form submit action
        function submitAction() {
            $activeForm = $formValidation.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0];
            _targetUrl = $activeForm.action;
            _actualFormData = $activeForm;
            _formData = _actualFormData.querySelectorAll('input[type="file"]').length
                ? coned.utils.formDataFileInputFix(_actualFormData)
                : new FormData(_actualFormData);
            if (_hasCaptcha) {
                _response = recaptcha.getResponse();
            }

            if (_hasCaptcha && _response === '') {
                recaptcha.checkRecaptcha();
                return false;
            } else {
                // formSubmit or captcha validation
                if (_hasCaptcha) {
                    recaptcha.checkRecaptcha();
                } else {
                    submitForm($activeForm, _formData, _targetUrl);
                }
            }
        }

        // hide loading message
        function hideFromLoading() {
            $formLoading.parentNode.style.position = '';
            $formLoading.classList.add(CONSTANTS.FORM_LOADING_HIDDEN);
        }

        // show loading message
        function showFromLoading() {
            $formLoading.parentNode.style.position = 'relative';
            $formLoading.classList.remove(CONSTANTS.FORM_LOADING_HIDDEN);
            $loadingImage.scrollIntoView(false);
            _scrolledY = window.pageYOffset;

            if (_scrolledY) {
                if($headerWrapper) {
                    window.scroll(0, _scrolledY + $headerWrapper.offsetHeight);
                } else {
                    window.scroll(0,0);                    
                }
            }
        }

        function recaptchaValidation() {
            submitForm($activeForm, _formData, _targetUrl);
        }

        /**
         * submit the form data.
         * @param  {HTMLElement} $activeForm form to be submited.
         * @param  {Object} _formData form data object.
         * @param  {String} _targetUrl service url.
         */
        function submitForm($activeForm, _formData, _targetUrl) {
            showFromLoading();
            if (recaptcha) {
                _response = _hasCaptcha ? recaptcha.getResponse() : null;
            }
            if (_hasCaptcha && _response && _response !== '') {
                _formData.append('Verify', _response);
            }

            query.postData(
                _targetUrl,
                function (data) {
                    hideFromLoading();
                    showFormMessage('success');
                    updatePageForConfirmationScreen();
                    // This fires twice (on showFormMessage() it fires too)
                    query.fireEvent($activeForm, 'submit-success');
                    // This only fires once
                    query.fireEvent($activeForm, 'form-submit-success', data);
                },
                function () {
                    // error message

                    if ($errorMessage) {
                        hideFromLoading();
                        showFormMessage();
                        updatePageForConfirmationScreen();
                        // This fires twice (on showFormMessage() it fires too)
                        query.fireEvent($activeForm, 'submit-error');
                        // This only fires once. Use it to trigger custom functionalities on forms as needed
                        query.fireEvent($activeForm, 'form-submit-error');
                    } else {
                        hideFromLoading();
                        showTopErrorFormMessage();
                        // This fires twice (on showFormMessage() it fires too)
                        query.fireEvent($activeForm, 'submit-error');
                        // This only fires once. Use it to trigger custom functionalities on forms as needed
                        query.fireEvent($activeForm, 'form-submit-error');
                    }
                },
                _formData,
                false,
                ''
            );
        }

        var updatePageForConfirmationScreen = function () {
            $activeForm.style.display = 'none';
            query.addClass($formValidation, CONSTANTS.FORM_WRAPPER_NO_BACKGROUND_CLASS);

            if ($pageHeader) {
                $pageHeader.style.display = 'none';
            }
            window.scrollTo(0, 0);
        }

        var resetPageForTryAgain = function () {
            if (query.hasClass($formValidation, CONSTANTS.FORM_WRAPPER_NO_BACKGROUND_CLASS)) {
                query.removeClass($formValidation, CONSTANTS.FORM_WRAPPER_NO_BACKGROUND_CLASS);
            }

            if ($pageHeader) {
                $pageHeader.style.display = 'block';
            }
        }

        var showTopErrorFormMessage =  function() {

            $inlineErrorMessage.classList.remove('hidden');
            
            $inlineErrorMessage.scrollIntoView(true);
            var scrolled = window.pageYOffset;

            if (scrolled) {
                window.scroll(0, scrolled - $headerWrapper.offsetHeight);
            }

            query.fireEvent($activeForm, 'submit-error');

        }

        /**
         *  Handles submit focus
         **/
        var submitFocusHandler = function (event) {
            switch (event.type) {
                case 'submit-success':
                    if ($successMessage && query.hasClass($successMessage, CONSTANTS.MESSAGE_SUCESS_FOCUS)) {
                        $successMessage.focus();
                    }
                    break;
                case 'submit-error':
                    if ($errorMessage && query.hasClass($errorMessage, CONSTANTS.MESSAGE_ERROR_FOCUS)) {
                        $errorMessage.focus();
                    } else {
                        $inlineErrorMessage.focus(); 
                    }
                    break;
            }
        };

        // show form messages
        function showFormMessage(status) {
            var $statusMessage = status ? $successMessage : $errorMessage;

            $statusMessage.style.display = 'block';
            $messageWrapper.style.display = 'block';

            if (status) {
                $errorMessage && ($errorMessage.style.display = 'none');
                query.fireEvent($activeForm, 'submit-success');

                // Analytics data building
                dataLayer.push({
                    event: 'coned.form.success'
                });
            } else {
                query.fireEvent($activeForm, 'submit-error');

                // Analytics data building
                dataLayer.push({
                    event: 'coned.form.fail.submission'
                });
                
            }

            if ($contactCopy) {
                query.addClass($contactCopy, CONSTANTS.CONTACT_SUCCESS_CLASS);
            }
        }

        var tryAgain = function () {
            if (recaptcha && _hasCaptcha) {
                recaptcha.reset();
            }

            resetPageForTryAgain();
            $successMessage.style.display = 'none';
            $errorMessage.style.display = 'none';
            $messageWrapper.style.display = 'none';
            $activeForm.style.display = 'block';
            $activeForm.scrollIntoView(true);
            
            // Use it to trigger custom functionalities on forms as needed
            query.fireEvent($activeForm, 'form-try-again');

            // Analytics data building
            dataLayer.push({
                event: 'coned.form.fail.try.again'
            });
        };

        var resetForm = function (event) {
            event.preventDefault();

            $($formValidation).find('form').validate().resetForm();

            var $borderBar;

            for (var inputIndex = 0; inputIndex < $inputsFilledSelector.length; inputIndex++) {
                var $inputFilledSelector = $inputsFilledSelector[inputIndex];

                if ($inputFilledSelector.disabled) continue;

                $borderBar = $inputFilledSelector.parentElement.getElementsByClassName(
                    CONSTANTS.BORDER_ANIMATION_SELECTOR
                )[0];

                query.removeClass($inputFilledSelector, CONSTANTS.INPUT_FILLED_CLASS);
                query.removeClass($inputFilledSelector, CONSTANTS.INPUT_VALID_CLASS);
                $borderBar && query.removeClass($borderBar, CONSTANTS.BORDER_ANIMATION_CLASS);
                $inputFilledSelector.value = '';
            }

            for (
                var textareaIndex = 0;
                textareaIndex < $textareasSelector.length;
                textareaIndex++
            ) {
                var $textareaSelector = $textareasSelector[textareaIndex];

                if ($textareaSelector.disabled) continue;

                $borderBar = $textareaSelector.parentElement.getElementsByClassName(
                    CONSTANTS.BORDER_ANIMATION_SELECTOR
                )[0];

                query.removeClass($textareaSelector, CONSTANTS.TEXT_AREA_FILLED_CLASS);
                query.removeClass($textareaSelector, CONSTANTS.INPUT_VALID_CLASS);
                $borderBar && query.removeClass($borderBar, CONSTANTS.BORDER_ANIMATION_CLASS);
                $textareaSelector.value = '';
            }

            for (var labelIndex = 0; labelIndex < $dropdownLabels.length; labelIndex++) {
                var $dropdownLabel = $dropdownLabels[labelIndex];

                $dropdownLabel.removeAttribute('style');
            }

            for (
                var dropdownIndex = 0;
                dropdownIndex < $dropdownsSelector.length;
                dropdownIndex++
            ) {
                var $dropdownSelector = $dropdownsSelector[dropdownIndex];

                if ($dropdownSelector.disabled) continue;

                $dropdownSelector.removeAttribute('style');
                $dropdownSelector.selectedIndex = 0;
                $dropdownSelector.classList.remove(CONSTANTS.ACTIVE_DROPDOWN);
            }

            for (var dateIndex = 0; dateIndex < $datesSelector.length; dateIndex++) {
                var $dateSelector = $datesSelector[dateIndex];

                if ($dateSelector.disabled) continue;

                $borderBar = $dateSelector.parentElement.getElementsByClassName(
                    CONSTANTS.BORDER_ANIMATION_SELECTOR
                )[0];

                query.removeClass($dateSelector, CONSTANTS.INPUT_FILLED_CLASS);
                query.removeClass($inputFilledSelector, CONSTANTS.INPUT_VALID_CLASS);
                $borderBar && query.removeClass($borderBar, CONSTANTS.BORDER_ANIMATION_CLASS);
                $dateSelector.value = '';
            }

            for (var radioIndex = 0; radioIndex < $radioButtonsSelector.length; radioIndex++) {
                var $radioButtonSelector = $radioButtonsSelector[radioIndex];

                if ($radioButtonSelector.disabled) continue;

                $radioButtonSelector.checked = false;
            }

            for (var checkIndex = 0; checkIndex < $checkboxesSelector.length; checkIndex++) {
                var $checkboxSelector = $checkboxesSelector[checkIndex];

                if ($checkboxSelector.disabled) continue;

                $checkboxSelector.checked = false;
                query.removeClass(
                    $checkboxSelector.parentElement,
                    CONSTANTS.CHECKBOX_CHECKED_CLASS
                );
            }

            for (var totalResultIndex = 0; totalResultIndex < $totalResults.length; totalResultIndex++) {
                var $totalResult = $totalResults[totalResultIndex];

                $totalResult.textContent = '';
            }

            for (var totalResultHiddenInputsIndex = 0; totalResultHiddenInputsIndex < $totalResultHiddenInputs.length; totalResultHiddenInputsIndex++) {
                var $totalResultHiddenInput = $totalResultHiddenInputs[totalResultHiddenInputsIndex];

                $totalResultHiddenInput.value = '';
            }

            var $activeForm = $formValidation.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0]
                ? $formValidation.getElementsByClassName(CONSTANTS.FORM_SELECTOR)[0]
                : $formValidation.getElementsByTagName('form')[0];
            coned.utils.focusFirstFormInputField($activeForm);
            query.fireEvent($activeForm, 'reset-form');
        };

        var checkInputDefaultValues = function () {
            for (var inputIndex = 0; inputIndex < $inputsFilledSelector.length; inputIndex++) {
                var $inputFilledSelector = $inputsFilledSelector[inputIndex];
                if ($inputFilledSelector.value) {
                    $inputFilledSelector.classList.add(CONSTANTS.INPUT_FILLED_CLASS);
                }
            }
            for (
                var textareaIndex = 0;
                textareaIndex < $textareasSelector.length;
                textareaIndex++
            ) {
                var $textareaSelector = $textareasSelector[textareaIndex];
                if ($textareaSelector.value) {
                    $textareaSelector.classList.add(CONSTANTS.TEXT_AREA_FILLED_CLASS);
                }
            }
            for (var dateIndex = 0; dateIndex < $datesSelector.length; dateIndex++) {
                var $dateSelector = $datesSelector[dateIndex];
                if ($dateSelector.value) {
                    $dateSelector.classList.add(CONSTANTS.INPUT_FILLED_CLASS);
                }
            }
        };

        var datePickerValidationOnChangeFilled = function (event) {
            var $input = event.target;

            if ($input.value !== '') {
                $($input).valid();
            }
        };

        var initInputValidationOnKeyUp = function (event) {
            var $input = event.target;
            var key = event.keyCode;
            var inputShouldStartValidate = !$input.hasAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE);

            // Prevent initial tab to element to trigger validation
            if (key == coned.constants.KEY_CODE.TAB && inputShouldStartValidate) {
                $input.dataset.inputShouldStartValidate = true;
            } else {
                if (inputShouldStartValidate) {
                    $input.dataset.inputShouldStartValidate = true;
                }
                $($input).valid();
            }
        };

        var initElementValidationOnEvent = function (event) {
            var $input = event.target;
            var inputShouldStartValidate = !$input.hasAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE);

            // Add attribute to be able to mix and match with other custom validations that use the attribute
            if (inputShouldStartValidate) {
                $input.dataset.inputShouldStartValidate = true;
            } 
            $($input).valid();
        };

        var initInputValidationOnMinEmail = function (event) {
            var $input = event.target;
            var inputValue = $input.value;
            var inputShouldStartValidate = !$input.hasAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE);

            // Start using the validator's email validation when reaching the minimum possible email format ie a@a.a
            if (/.+@.+\..+/g.test(inputValue) && inputShouldStartValidate) {
                $input.dataset.inputShouldStartValidate = true;
                $($input).valid();
            } else if (!inputShouldStartValidate) {
                $($input).valid();
            }
        };

        var selectValidationOnTabKey = function (event) {
            var key = event.keyCode;

            if (key == coned.constants.KEY_CODE.TAB) {
                $(this).valid();
            }
        };

        var initInputValidationOnMinLength = function (event) {
            var $input = event.target;
            var val = $input.value;
            var valLength = val.length;
            var minLength = $input.dataset.ruleMinlength;
            var inputShouldStartValidate = !$input.hasAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE);
            
            if (valLength >= minLength ) {
                $($input).valid();
            } else if (!inputShouldStartValidate) {
                $($input).valid();
            }
        };

        var initInputValidationInBetweenLength = function (event) {
            var $input = event.target,
                val = $input.value,
                valLength = val.length,
                actualMaxValue = parseInt($input.dataset.ruleMaxlength),
                firstMaxLength = parseInt($input.dataset.firstMax),
                secondMaxLength = parseInt($input.dataset.secondMax),
                updateLenghts = false,
                actualLength;

            if (valLength <= firstMaxLength && actualMaxValue != firstMaxLength) {
                actualLength = $input.dataset.firstMax;
                updateLenghts = true;
            } else if (valLength > firstMaxLength && actualMaxValue != secondMaxLength) {
                actualLength = $input.dataset.secondMax;
                updateLenghts = true;
            }

            if (updateLenghts === true) {
                $input.dataset.ruleMinlength = actualLength;
                $input.dataset.ruleMaxlength = actualLength;

                $($input).rules ("add", {
                    maxlength: actualLength,
                    minlength: actualLength
                });
            }

            limitInput(event, parseInt(actualLength));
            $($input).valid();
        };        

        var initInputValidationOnTwelveDigits = function (event) {
            var $input = event.target;
            var inputLength = $input.value.length;
            var inputShouldStartValidate = !$input.hasAttribute(CONSTANTS.DATA_INPUT_SHOULD_START_VALIDATE);

            if (inputShouldStartValidate && inputLength === 12) {
                $input.dataset.inputShouldStartValidate = true;
                $($input).valid();
            } else if (!inputShouldStartValidate) {
                $($input).valid();
            }
        };

        var limitInput = function (event, inputLimit) {
            if (event !== undefined && event.target.value.length >= inputLimit) {
                if (coned.utils.preventBehaviourError(event)) return;
                event.preventDefault();
            }
        };
        
        /**
         * Main goal is to remove non number characters and then trim to max length in that specific order.
         * On paste event:
         * 1. Remove any non number characters.
         * 2. Replace content appropriately.
         * 3. Replicate all of the default/previous paste functionality.
         * 4. Only then trim the content of the input to the max length.
         * 5. Set input value to the updated content.
         * 6. Set cursor position.
         * @param {Event} event Paste event triggered.
         * @param {Number} inputLimit Max length limit that the input has.
         */
        var limitInputNumbersOnPaste = function (event, inputLimit) {
            event.preventDefault();
            var $input = event.target,
                currentValue = $input.value,
                pastedValue = event.clipboardData.getData('text'),
                finalValue,
                limit = inputLimit,
                remainingLimit = limit - currentValue.length,
                selectionStart = $input.selectionStart,
                selectionEnd = $input.selectionEnd,
                selectionLength = selectionEnd - selectionStart,
                selectionExists = selectionEnd !== selectionStart;

            pastedValue = pastedValue
                .replace(/[^0-9]/g, '');

            // When there is a selection
            // Replace the selection with the pasted value
            if (selectionExists) {
                // Input is full at max limit
                if (currentValue.length === limit) {
                    pastedValue = pastedValue.substring(
                        0,
                        selectionLength
                    );
                
                // Input is filled but not at max limit
                } else {
                    pastedValue = pastedValue.substring(
                        0,
                        remainingLimit + selectionLength
                    );
                }

                currentValue = currentValue.splice(
                    selectionStart, 
                    selectionLength, 
                    pastedValue
                );

                finalValue = currentValue
                    .substring(
                        0, limit
                    );

            // There is no selection and the input has content
            } else if (currentValue.length !== 0) {
                pastedValue = pastedValue.substring(
                    0,
                    remainingLimit
                );

                currentValue = currentValue.splice(
                        selectionStart, 
                        0, 
                        pastedValue
                    );

                finalValue = currentValue
                    .substring(
                        0, limit
                    );
                        

            // Input is empty
            } else {
                finalValue = pastedValue
                    .substring(
                        0, limit
                    );
            }

            $input.value = finalValue;

            // Set cursor right at the end of the pasted value
            if (selectionExists) {
                if (pastedValue.length > selectionLength) {
                    $input.setSelectionRange(
                        selectionStart + pastedValue.length, 
                        selectionStart + pastedValue.length
                    );
                } else {
                    $input.setSelectionRange(selectionEnd, selectionEnd);
                }
            }
        };

        var borderAnimation = function (event) {
            var $border = event.target.parentElement.getElementsByClassName(
                CONSTANTS.BORDER_ANIMATION_SELECTOR
            )[0];

            if (query.hasClass($border, CONSTANTS.BORDER_ANIMATION_CLASS)) {
                query.removeClass($border, CONSTANTS.BORDER_ANIMATION_CLASS);
            } else {
                query.addClass($border, CONSTANTS.BORDER_ANIMATION_CLASS);
            }
        };

        var checkedAnimation = function (event) {
            var $target = event.target,
                $parentTarget;

            if ($target.classList.contains(CONSTANTS.CHECKBOX_EQUAL_HIERARCHY)) {
                $parentTarget = event.target.nextElementSibling;
            } else {
                $parentTarget = event.target.parentElement;
            }

            if ($target.checked) {
                query.addClass($parentTarget, CONSTANTS.CHECKBOX_CHECKED_CLASS);
            } else {
                query.removeClass($parentTarget, CONSTANTS.CHECKBOX_CHECKED_CLASS);
            }
        };

        /**
         *  To do: For next approch review this functionality
         * @param {Event JS} event
         */
        var addFocusLabelCheckbox = function (event) {
            //Using for distinguish between checkbox and checkbox-equal-hierarchy
            var target = event.target.classList.contains(CONSTANTS.CHECKBOX_EQUAL_HIERARCHY)
                ? event.target.nextElementSibling
                : event.target.parentElement;
            query.addClass(target, CONSTANTS.FOCUS_LABEL_CHECKBOX);
        };

        /**
         *  To do: For next approch review this functionality
         * @param {Event JS} event
         */
        var deleteFocusLabelCheckbox = function (event) {
            //Using for distinguish between checkbox and checkbox-equal-hierarchy
            var target = event.target.classList.contains(CONSTANTS.CHECKBOX_EQUAL_HIERARCHY)
                ? event.target.nextElementSibling
                : event.target.parentElement;
            query.removeClass(target, CONSTANTS.FOCUS_LABEL_CHECKBOX);
        };

        /**
         * Adds focus border to checkbox switch wrapper
         * @param {Event JS} event
         */
        var addFocusLabelCheckboxSwitch = function (event) {
            var $target = event.target.parentElement;

            query.addClass($target, CONSTANTS.CHECKBOX_SWITCH_FOCUS);
        };

        /**
         * Removes focus border from checkbox switch wrapper
         * @param {Event JS} event
         */
        var deleteFocusLabelCheckboxSwitch = function (event) {
            var $target = event.target.parentElement;

            query.removeClass($target, CONSTANTS.CHECKBOX_SWITCH_FOCUS);
        };

        var currencyFormatNumber = function (number) {
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        };

        var currencyFormat = function (input) {
            var currencyInput = input.value.replace(/[^\d.-]/g, ''),
                decimals = '',
                trimDecimals = '',
                val;

            currencyInput = currencyInput.replace(/[-\s]+/g, '');
            currencyInput = currencyInput.split('.', 2);
            val = parseInt(currencyInput[0]) * 1;

            if (typeof currencyInput[1] != 'undefined') {
                if (currencyInput[1].length > 2) {
                    trimDecimals = currencyInput[1][0];

                    if (typeof currencyInput[1][1] != 'undefined') {
                        trimDecimals += currencyInput[1][1];
                    }
                } else {
                    trimDecimals = currencyInput[1];
                }

                decimals = '.' + trimDecimals;
            }

            if (currencyInput[0].length > 0) {
                input.value = '$' + currencyFormatNumber(val) + decimals;
            } else {
                input.value = '';
                query.removeClass(input, CONSTANTS.INPUT_FILLED_CLASS);
            }
        };

        var currencyClearDollarSign = function (event) {
            var $input = event.target;
            
            if ($input.value === '$') {
                $input.value = '';
                query.removeClass($input, CONSTANTS.INPUT_FILLED_CLASS);
                query.removeClass($input, CONSTANTS.INPUT_VALID_CLASS);
            }
        };

        var timeFormat = function (input) {
            var timeInput = input.value.split(':', 2);

            if (
                timeInput[0].length === 3 &&
                (typeof timeInput[1] == 'undefined' || timeInput[1] === '')
            ) {
                input.value = timeInput[0].slice(0, 2) + ':' + timeInput[0].slice(2, 4);
            } else if (
                timeInput[0].length === 1 &&
                typeof timeInput[1] != 'undefined' &&
                timeInput[1] !== '' &&
                timeInput[1].length > 2
            ) {
                input.value = timeInput[0][0] + timeInput[1][0] + ':' + timeInput[1].slice(1, 3);
            } else if (
                timeInput[0].length > 2 ||
                (typeof timeInput[1] != 'undefined' &&
                    timeInput[1] !== '' &&
                    timeInput[1].length > 2)
            ) {
                input.value = timeInput[0].slice(-2) + ':' + timeInput[1].slice(-2);
            }
        };

        var numberFormat = function (event) {
            if (coned.utils.preventBehaviourError(event)) return;

            var number = event.target.value;

            event.target.value = number.replace(/\D/g, '');
        };

        var trimSpaces = function (event) {
            if (coned.utils.preventBehaviourError(event)) return;

            var value = event.target.value;

            event.target.value = value.replace(' ', '');
        };

        var addressFormat = function (event) {
            if (coned.utils.preventBehaviourError(event)) return;

            var address = event.target.value;

            event.target.value = address.replace(/[^\a-z\d\-\s\&]/gi, '');
        };

        var initializeData = function () {
            $pageHeader = document.getElementsByClassName(CONSTANTS.PAGE_HEADER)[0];
            $messageWrapper = $formValidation.getElementsByClassName(CONSTANTS.MESSAGE_WRAPPER)[0];
            $successMessage = $formValidation.getElementsByClassName(CONSTANTS.MESSAGE_SUCESS)[0];
            $successAltMessage = $formValidation.getElementsByClassName(CONSTANTS.MESSAGE_SUCCESS_ALT)[0];
            $errorMessage = $formValidation.getElementsByClassName(CONSTANTS.MESSAGE_ERROR)[0];
            $contactCopy = $formValidation.getElementsByClassName(CONSTANTS.CONTACT_COPY)[0];
            $captchaElement = $formValidation.getElementsByClassName(CONSTANTS.CAPTCHA_ELEMENT);
            $resetButton = $formValidation.getElementsByClassName(CONSTANTS.RESET_BUTTON)[0];
            $tryAgainButton = $formValidation.getElementsByClassName(CONSTANTS.TRY_AGAIN_BUTTON)[0];
            $inputsFilledSelector = $formValidation.getElementsByClassName(
                CONSTANTS.INPUT_TEXT_SELECTOR
            );
            $inputsValidateNoInBetweenLengthSelector = $formValidation.getElementsByClassName(CONSTANTS.VALIDATE_IN_BETWEEN_LENGTH_SELECTOR);
            $inputsValidateStartMinLengthSelector = $formValidation.getElementsByClassName(CONSTANTS.VALIDATE_START_MIN_LENGTH_SELECTOR);
            $inputsValidateStartKeyupSelector = $formValidation.getElementsByClassName(CONSTANTS.VALIDATE_START_KEY_UP_SELECTOR);
            $inputsValidateStartMinEmailSelector = $formValidation.getElementsByClassName(CONSTANTS.VALIDATE_START_MIN_EMAIL_SELECTOR);
            $inputsValidateStartBlurSelector = $formValidation.getElementsByClassName(CONSTANTS.VALIDATE_START_BLUR_SELECTOR);
            $selectsValidateTabSelector = $formValidation.getElementsByClassName(CONSTANTS.VALIDATE_TAB_SELECTOR);
            $selectsValidateStartChangeSelector = $formValidation.getElementsByClassName(CONSTANTS.VALIDATE_START_CHANGE_SELECTOR);
            $inputsValidateStartTwelveDigitsSelector = $formValidation.getElementsByClassName(CONSTANTS.VALIDATE_START_TWELVE_DIGITS_SELECTOR);
            $datePickersValidateChangeFilledSelector = $formValidation.getElementsByClassName(CONSTANTS.VALIDATE_CHANGE_FILLED_SELECTOR);
            $textareasSelector = $formValidation.getElementsByClassName(
                CONSTANTS.TEXTAREA_SELECTOR
            );
            $checkboxesSelector = $formValidation.getElementsByClassName(
                CONSTANTS.CHECKBOX_SELECTOR
            );
            $checkboxesSwitchFocusSelector = $formValidation.getElementsByClassName(
                CONSTANTS.CHECKBOX_SWITCH_FOCUS_SELECTOR
            );
            $dropdownLabels = $formValidation.getElementsByClassName(CONSTANTS.DROPDOWN_LABEL);
            $datesSelector = $formValidation.getElementsByClassName(CONSTANTS.DATE_SELECTOR);
            $radioButtonsSelector = $formValidation.getElementsByClassName(
                CONSTANTS.RADIO_BUTTON_SELECTOR
            );
            $dropdownsSelector = $formValidation.getElementsByClassName(
                CONSTANTS.DROPDOWN_SELECTOR
            );
            $currencyInputsSelector = $formValidation.getElementsByClassName(
                CONSTANTS.CURRENCY_INPUT_SELECTOR
            );
            $timeInputsSelector = $formValidation.getElementsByClassName(
                CONSTANTS.TIME_INPUT_SELECTOR
            );
            $zipcodeUSAInputsSelector = $formValidation.getElementsByClassName(
                CONSTANTS.ZIPCODE_USA_INPUT_SELECTOR
            );
            $a11yFormSelector = document.getElementsByClassName(CONSTANTS.ACCESSIBILITY_FORM);
            $zipcodeUSASelector = $formValidation.getElementsByClassName(
                CONSTANTS.ZIPCODE_USA_SELECTOR
            );
            $noSpacesInputSelector = $formValidation.getElementsByClassName(
                CONSTANTS.NO_SPACES_SELECTOR
            );
            $phoneExtensionInputs = $formValidation.getElementsByClassName(
                CONSTANTS.PHONE_EXTENSION_SELECTOR
            );
            $numbersFormatSelector = $formValidation.getElementsByClassName(
                CONSTANTS.NUMBER_FORMAT_SELECTOR
            );
            $conedNumberAccounts = $formValidation.getElementsByClassName(
                CONSTANTS.CONED_NUMBER_ACCOUNT
            );
            $oruNumberAccounts = $formValidation.getElementsByClassName(
                CONSTANTS.ORU_NUMBER_ACCOUNT
            );
            $conedAddressInputs = $formValidation.getElementsByClassName(
                CONSTANTS.CONED_ADDRESS_INPUT
            );
            $limitedLengthInputs = $formValidation.getElementsByClassName(
                CONSTANTS.LIMITED_LENGTH_INPUT
            );
            $totalResults = $formValidation.getElementsByClassName(
                CONSTANTS.TOTAL_AMOUNT_RESULT_SELECTOR
            );
            $totalResultHiddenInputs = $formValidation.getElementsByClassName(
                CONSTANTS.TOTAL_RESULT_HIDDEN_INPUTS_SELECTOR
            );
            $noTransactionalForms = $formValidation.getElementsByClassName(CONSTANTS.FORM_SELECTOR);
            $formLoading = $formValidation.getElementsByClassName(
                CONSTANTS.FORM_LOADING_SELECTOR
            )[0];
            $loadingImage = document.getElementsByClassName(CONSTANTS.FORM_LOADING_IMAGE)[0];
            $headerWrapper = document.getElementsByClassName(CONSTANTS.HEADER_WRAPPER)[0];
            $inlineErrorMessage = $formValidation.getElementsByClassName(CONSTANTS.TOP_ERROR_MESSAGE)[0];
            _hasCaptcha = $captchaElement.length;
            _response = '';
        };

        var initializeAriaAttributes = function () {

            if ($successMessage && query.hasClass($successMessage, CONSTANTS.MESSAGE_SUCESS_FOCUS)) {
                $successMessage.setAttribute(CONSTANTS.TABINDEX, '-1');
            }

            if ($errorMessage && query.hasClass($errorMessage, CONSTANTS.MESSAGE_ERROR_FOCUS)) {
                $errorMessage.setAttribute(CONSTANTS.TABINDEX, '-1');
            }

            if ($successAltMessage && query.hasClass($successAltMessage, CONSTANTS.MESSAGE_SUCCESS_ALT_FOCUS)) {
                $successAltMessage.setAttribute(CONSTANTS.TABINDEX, '-1');
            }
        };

        var initializeEvents = function () {
            labelPattern();
            checkInputDefaultValues();

            if ($resetButton !== undefined) {
                coned.utils.addGeneralListeners($resetButton, resetForm);
            }

            if ($tryAgainButton) {
                coned.utils.addGeneralListeners($tryAgainButton, tryAgain);
            }

            if ($noTransactionalForms) {
                for (var formIndex = 0; formIndex < $noTransactionalForms.length; formIndex++) {
                    var $noTransactionalForm = $noTransactionalForms[formIndex];

                    new coned.components.ValidateForm($noTransactionalForm, submitAction);
                    $activeForm = $formValidation.getElementsByClassName(
                        CONSTANTS.FORM_SELECTOR
                    )[0];

                    //Check the data-init-recaptcha attribute to see if recaptcha should be initialized
                    if(!$formValidation.dataset.initRecaptcha || $formValidation.dataset.initRecaptcha === coned.constants.TRUE) {                  
                        recaptcha = new coned.components.Recaptcha(
                            $activeForm,
                            recaptchaValidation,
                            recaptchaValidation
                        );
                    }
                }
            }

            for (
                var inputsFilledIndex = 0;
                inputsFilledIndex < $inputsFilledSelector.length;
                inputsFilledIndex++
            ) {
                var $inputFilledSelector = $inputsFilledSelector[inputsFilledIndex];

                $inputFilledSelector.addEventListener('focus', borderAnimation);
                $inputFilledSelector.addEventListener('focusout', borderAnimation);
            }

            // This loop validates that the input doesn't accept a length between two values
            // Inputs need to have js-validate-no-in-between-length class
            for (
                var inputsValidateNoInBetweenLengthIndex = 0;
                inputsValidateNoInBetweenLengthIndex < $inputsValidateNoInBetweenLengthSelector.length;
                inputsValidateNoInBetweenLengthIndex++
            ) {
                var inputValidateNoInBetweenLengthIndex = $inputsValidateNoInBetweenLengthSelector[inputsValidateNoInBetweenLengthIndex];

                inputValidateNoInBetweenLengthIndex.addEventListener(CONSTANTS.KEYUP_EVENT, initInputValidationInBetweenLength);
            }

            // Trigger validation on change for date pickers when not empty, to validate when user enters dates by keyboard
            // Datepicker inputs need to have js-validate-change-filled class
            for (
                var datePickersValidateChangeFilledIndex = 0;
                datePickersValidateChangeFilledIndex < $datePickersValidateChangeFilledSelector.length;
                datePickersValidateChangeFilledIndex++
            ) {
                var $datePickerValidateChangeFilledSelector = $datePickersValidateChangeFilledSelector[datePickersValidateChangeFilledIndex];

                $datePickerValidateChangeFilledSelector.addEventListener(CONSTANTS.CHANGE_EVENT, datePickerValidationOnChangeFilled);
            }

            // The following 7 for loops are used for cases where we want to prevent some inputs to be validated right away on keyup, so we must start by preventing
            // all elements validation on keyup.
            // Their form must be initialized with the ValidateForm() method's 4th parameter added as true (don't forget to include the ':hidden' selector as the 3rd parameter)

            // #1 Start validation when reaching min length on keyup for inputs
            // Inputs need to have js-validate-start-min-length class
            for (
                var inputsValidateStartMinLengthIndex = 0;
                inputsValidateStartMinLengthIndex < $inputsValidateStartMinLengthSelector.length;
                inputsValidateStartMinLengthIndex++
            ) {
                var inputValidateStartMinLengthSelector = $inputsValidateStartMinLengthSelector[inputsValidateStartMinLengthIndex];

                inputValidateStartMinLengthSelector.addEventListener(CONSTANTS.KEYUP_EVENT, initInputValidationOnMinLength);
            }

            // #2 Start validation right away on keyup for inputs that still need this behavior
            // Inputs need to have js-validate-start-key-up class
            for (
                var inputsValidateOnKeyupIndex = 0;
                inputsValidateOnKeyupIndex < $inputsValidateStartKeyupSelector.length;
                inputsValidateOnKeyupIndex++
            ) {
                var $inputValidateOnKeyupSelector = $inputsValidateStartKeyupSelector[inputsValidateOnKeyupIndex];

                $inputValidateOnKeyupSelector.addEventListener(CONSTANTS.KEYUP_EVENT, initInputValidationOnKeyUp);
            }

            // #3 Validate on key tab away for selects where we force error message to hide when active on errorPlacement on validate-form.js
            // Selects need to have js-validate-tab class
            for (
                var selectsValidateOnTabIndex = 0;
                selectsValidateOnTabIndex < $selectsValidateTabSelector.length;
                selectsValidateOnTabIndex++
            ) {
                var $selectValidateTabSelector = $selectsValidateTabSelector[selectsValidateOnTabIndex];

                $selectValidateTabSelector.addEventListener(CONSTANTS.KEYUP_EVENT, selectValidationOnTabKey);
            }

            // #4 Start validation on blur for inputs 
            // Inputs need to have js-validate-start-blur class
            for (
                var inputsValidateStartBlurIndex = 0;
                inputsValidateStartBlurIndex < $inputsValidateStartBlurSelector.length;
                inputsValidateStartBlurIndex++
            ) {
                var $inputValidateStartBlurSelector = $inputsValidateStartBlurSelector[inputsValidateStartBlurIndex];

                $inputValidateStartBlurSelector.addEventListener(CONSTANTS.BLUR_EVENT, initElementValidationOnEvent);
            }

            // #5 Start validation for email inputs as soon as a minimal email format is reached ie a@a.a
            // Inputs need to have js-validate-start-min-email class
            for (
                var inputsValidateStartMinEmailIndex = 0;
                inputsValidateStartMinEmailIndex < $inputsValidateStartMinEmailSelector.length;
                inputsValidateStartMinEmailIndex++
            ) {
                var $inputValidateStartMinEmailSelector = $inputsValidateStartMinEmailSelector[inputsValidateStartMinEmailIndex];

                $inputValidateStartMinEmailSelector.addEventListener(CONSTANTS.KEYUP_EVENT, initInputValidationOnMinEmail);
            }

            // #6 Start validation on change for selects
            // Inputs need to have js-validate-start-change class
            for (
                var selectsValidateStartChangeIndex = 0;
                selectsValidateStartChangeIndex < $selectsValidateStartChangeSelector.length;
                selectsValidateStartChangeIndex++
            ) {
                var $selectValidateStartChangeSelector = $selectsValidateStartChangeSelector[selectsValidateStartChangeIndex];

                $selectValidateStartChangeSelector.addEventListener(CONSTANTS.CHANGE_EVENT, initElementValidationOnEvent);
            }

            // #7 Start validation when reaching 12 digits format for US phone inputs
            // Inputs need to have js-validate-start-twelve-digits class
            for (
                var inputsValidateStartTwelveDigitsIndex = 0;
                inputsValidateStartTwelveDigitsIndex < $inputsValidateStartTwelveDigitsSelector.length;
                inputsValidateStartTwelveDigitsIndex++
            ) {
                var $inputValidateStartStartTwelveDigitsSelector = $inputsValidateStartTwelveDigitsSelector[inputsValidateStartTwelveDigitsIndex];

                $inputValidateStartStartTwelveDigitsSelector.addEventListener(CONSTANTS.KEYUP_EVENT, initInputValidationOnTwelveDigits);
            }

            for (
                var textareaIndex = 0;
                textareaIndex < $textareasSelector.length;
                textareaIndex++
            ) {
                var $textareaSelector = $textareasSelector[textareaIndex];

                $textareaSelector.addEventListener('focus', borderAnimation);
                $textareaSelector.addEventListener('focusout', borderAnimation);
            }

            for (
                var checkboxIndex = 0;
                checkboxIndex < $checkboxesSelector.length;
                checkboxIndex++
            ) {
                var $checkboxSelector = $checkboxesSelector[checkboxIndex];

                if (
                    !query.hasClass($checkboxSelector.parentElement, CONSTANTS.CHECKBOX_AUTO_CLASS)
                ) {
                    $checkboxSelector.addEventListener('change', checkedAnimation);
                }

                //Add Listener for active focus style in label element
                $checkboxSelector.addEventListener('focusin', addFocusLabelCheckbox);
                $checkboxSelector.addEventListener('focusout', deleteFocusLabelCheckbox);
            }

            // Checkbox switches focus handler
            for (
                var checkboxSwitchFocusIndex = 0;
                checkboxSwitchFocusIndex < $checkboxesSwitchFocusSelector.length;
                checkboxSwitchFocusIndex++
            ) {
                var $checkboxSwitchFocusSelector = $checkboxesSwitchFocusSelector[checkboxSwitchFocusIndex];

                // Add Listener for active focus style in element wrapper
                $checkboxSwitchFocusSelector.addEventListener('focusin', addFocusLabelCheckboxSwitch);
                $checkboxSwitchFocusSelector.addEventListener('focusout', deleteFocusLabelCheckboxSwitch);
            }

            for (var usZipIndex = 0; usZipIndex < $zipcodeUSAInputsSelector.length; usZipIndex++) {
                var $zipcodeUSAInputSelector = $zipcodeUSAInputsSelector[usZipIndex];

                $zipcodeUSAInputSelector.addEventListener('keyup', numberFormat);
                $zipcodeUSAInputSelector.addEventListener('keypress', function (event) {
                    limitInput(event, 5);
                });
            }
            //Uses max-length value
            for (
                var usZipCodeIndex = 0;
                usZipCodeIndex < $zipcodeUSASelector.length;
                usZipCodeIndex++
            ) {
                var $zipcodeInputSelector = $zipcodeUSASelector[usZipCodeIndex];
                $zipcodeInputSelector.addEventListener('keyup', numberFormat);
            }

            for (
                var numbersIndex = 0;
                numbersIndex < $numbersFormatSelector.length;
                numbersIndex++
            ) {
                var $numberFormatSelector = $numbersFormatSelector[numbersIndex];

                $numberFormatSelector.addEventListener('keyup', numberFormat);
            }

            for (
                var currencyIndex = 0;
                currencyIndex < $currencyInputsSelector.length;
                currencyIndex++
            ) {
                var $currencyInputSelector = $currencyInputsSelector[currencyIndex];

                $currencyInputSelector.addEventListener('keyup', function (event) {
                    if (coned.utils.preventBehaviourError(event)) return;

                    currencyFormat(event.currentTarget);
                });

                if (query.hasClass($currencyInputSelector, CONSTANTS.CURRENCY_INPUT_OPTIONAL_SELECTOR)) {
                    $currencyInputSelector.addEventListener(CONSTANTS.BLUR_EVENT, currencyClearDollarSign);
                }
            }

            for (var timeIndex = 0; timeIndex < $timeInputsSelector.length; timeIndex++) {
                var $timeInputSelector = $timeInputsSelector[timeIndex];

                $timeInputSelector.addEventListener('keyup', function (event) {
                    if (coned.utils.preventBehaviourError(event)) return;

                    event.currentTarget.value = event.currentTarget.value.replace(/[^\d:]/g, '');
                    timeFormat(event.currentTarget);
                });
                // $timeInputSelector.addEventListener('blur', function(event) {
                //     if (coned.utils.preventBehaviourError(event)) return;

                //     event.currentTarget.value = event.currentTarget.value.replace(/[^\d:]/g, '');
                //     // timeFormatZeros(event.currentTarget);
                // });
            }

            for (
                var addressesIndex = 0;
                addressesIndex < $conedAddressInputs.length;
                addressesIndex++
            ) {
                var $conedAddressInput = $conedAddressInputs[addressesIndex];

                $conedAddressInput.addEventListener('keyup', addressFormat);
                if ($($conedAddressInput).hasClass(CONSTANTS.ORU_ADDRESS_INPUT)) {
                    $conedAddressInput.addEventListener('keypress', function (event) {
                        limitInput(event, 28);
                    });
                } else {
                    $conedAddressInput.addEventListener('keypress', function (event) {
                        limitInput(event, 21);
                    });
                }
            }

            // Text limit key press events
            for (var phoneIndex = 0; phoneIndex < $phoneExtensionInputs.length; phoneIndex++) {
                var $phoneExtensionInput = $phoneExtensionInputs[phoneIndex];

                $phoneExtensionInput.addEventListener('keypress', function (event) {
                    limitInput(event, 4);
                });
            }

            for (
                var conedAccountIndex = 0;
                conedAccountIndex < $conedNumberAccounts.length;
                conedAccountIndex++
            ) {
                var $conedNumberAccount = $conedNumberAccounts[conedAccountIndex];

                $conedNumberAccount.addEventListener('keypress', function (event) {
                    limitInput(event, 15);
                });
            }

            for (
                var oruAccountIndex = 0;
                oruAccountIndex < $oruNumberAccounts.length;
                oruAccountIndex++
            ) {
                var $oruNumberAccount = $oruNumberAccounts[oruAccountIndex];

                $oruNumberAccount.addEventListener('keypress', function (event) {
                    limitInput(event, 11);
                });

                $oruNumberAccount.addEventListener('paste', function (event) {
                    limitInputNumbersOnPaste(event, 11);
                    initInputValidationInBetweenLength(event);

                    // Need to manually set the filled state class for Safari iOS
                    if ($(this).val() !== '') {
                        $(this).addClass(CONSTANTS.INPUT_FILLED_CLASS);
                    } else {
                        $(this).removeClass(CONSTANTS.INPUT_FILLED_CLASS);
                    }
                });
            }

            for (
                var inputToLimitIndex = 0;
                inputToLimitIndex < $limitedLengthInputs.length;
                inputToLimitIndex++
            ) {
                var $limitedLengthInput = $limitedLengthInputs[inputToLimitIndex];

                $limitedLengthInput.addEventListener('keypress', function (event) {
                    var maxLength = event.target.dataset.ruleMaxlength;

                    limitInput(event, maxLength);
                });
            }

            /**
             * A11y function.
             * In order to ease the navigation for keyboard users,
             * after the error is blurred,
             * focus the first input field of the current Form
             * */
            for (var a11yFormIndex = 0; a11yFormIndex < $a11yFormSelector.length; a11yFormIndex++) {
                (function () {
                    var $currentForm = $a11yFormSelector[a11yFormIndex];
                    var $formErrors = $currentForm.getElementsByClassName(
                        CONSTANTS.ACCESSIBILITY_FORM_ERROR
                    );
                    for (
                        var formErrorIndex = 0;
                        formErrorIndex < $formErrors.length;
                        formErrorIndex++
                    ) {
                        var $submitFormError = $formErrors[formErrorIndex];
                        $submitFormError.addEventListener('blur', function () {
                            coned.utils.focusFirstFormInputField($currentForm);
                        });
                    }
                })();
            }

            $formValidation.addEventListener('submit-success', submitFocusHandler);
            $formValidation.addEventListener('submit-error', submitFocusHandler);

            for (var radioButtonsIndex = 0; radioButtonsIndex < $radioButtonsSelector.length; radioButtonsIndex++) {

                $radioButtonsSelector[radioButtonsIndex].addEventListener('focusin', function () {
                    query.addClass(this.nextElementSibling, 'coned-radio__indicator--focus');
                });

                $radioButtonsSelector[radioButtonsIndex].addEventListener('focusout', function () {
                    query.removeClass(this.nextElementSibling, 'coned-radio__indicator--focus');
                });
            }

            //Remove blank spaces
            for (
                var noSpacesIndex = 0;
                noSpacesIndex < $noSpacesInputSelector.length;
                noSpacesIndex++
            ) {
                var $noSpacesInput = $noSpacesInputSelector[noSpacesIndex];
                $noSpacesInput.addEventListener('keyup', trimSpaces);
            }
        };

        // GENERAL CUSTOM VALIDATION METHODS
        // First name and last name validations
        $.validator.addMethod(
            'customName',
            function (value, element) {
                return (
                    this.optional(element) ||
                    /[a-zA-Z]+\s+[a-zA-Z]+/g.test(value)
                );
            },
            'Error: First name and last name required.'
        );
        
        // Email validations
        $.validator.addMethod(
            'customEmail',
            function (value, element) {
                return (
                    this.optional(element) ||
                    /^\w+[-+.'\w]*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
                );
            },
            'Error: Please enter a valid email address.'
        );

        $.validator.addMethod(
            'customEmailReduced',
            function (value, element) {
                return (
                    this.optional(element) ||
                    /^\w+([-.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
                );
            },
            'Error: Please enter a valid email address.'
        );

        // CAN zip code
        $.validator.addMethod(
            'canadaZipCode',
            function (value, element) {
                return (
                    this.optional(element) ||
                    /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i.test(value)
                );
            },
            'Error: The specified Canada ZIP Code is invalid.'
        );

        // US zip code
        $.validator.addMethod(
            'USAZipCode',
            function (value, element) {
                return this.optional(element) || /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(value);
            },
            'Error: Please enter a valid ZIP code.'
        );

        // SSN validation
        $.validator.addMethod(
            'ssnValidation',
            function (value) {
                return /^\d{3}-\d{2}-\d{4}$/.test(value);
            },
            'Error: Please enter a valid SSN number.'
        );

        // SSN validation
        $.validator.addMethod(
            'nameAllowedCharactersValidation',
            function (value, element) {
                return this.optional(element) || /^[a-zA-Z0-9\s'\-\&]+$/.test(value);
            },
            'Error: Please enter a valid name.'
        );

        // Full name validation
        $.validator.addMethod(
            'fullNameAllowedCharactersValidation',
            function (value, element) {
                if(this.optional(element) || /^[a-zA-Z-'\s]+$/.test(value)) {
                    return true;
                } else {
                    // Analytics data building
                    dataLayer.push({
                        event: 'foreign.char.error',
                        field: element.name
                    });
                    return false;
                }
            },
            'Error: Please enter a valid name.'
        );

        // Business name validation
        $.validator.addMethod(
            'businessNameAllowedCharactersValidation',
            function (value, element) {
                if(this.optional(element) || /^[a-zA-Z0-9-'/&?!\s]+$/.test(value)) {
                    return true;
                } else {
                    // Analytics data building
                    dataLayer.push({
                        event: 'foreign.char.error',
                        field: element.name
                    });
                    return false;
                }
            },
            'Error: Please enter a valid name.'
        );

        // Account input amount limit
        $.validator.addMethod(
            'accountLimit',
            function (value, element) {
                var maxAccountAmount =
                    element.dataset.maxAccounts && element.dataset.maxAccounts !== 0
                        ? parseFloat(element.dataset.maxAccounts)
                        : 50;

                value = value.split(/[,\s]/).filter(function (item) {
                    return item !== '';
                }).length;

                return value >= 0 && value <= maxAccountAmount;
            },
            function (params, element) {
                return (
                    'Error: Please limit your request to a maximum of ' +
                    element.dataset.maxAccounts +
                    ' account numbers at a time.'
                );
            }
        );

        // Amount limits
        $.validator.addMethod(
            'limitAmount',
            function (value, element) {
                var minAmount = element.dataset.limitMin
                    ? parseFloat(element.dataset.limitMin.replace(/[^\d.-]/g, ''))
                    : 0,
                    maxAmount = element.dataset.limitMax
                        ? parseFloat(element.dataset.limitMax.replace(/[^\d.-]/g, ''))
                        : 0;

                value = parseFloat(value.replace(/[^\d.-]/g, ''));

                return (
                    (minAmount <= 0 || value >= minAmount) && (maxAmount <= 0 || value <= maxAmount)
                );
            },
            function (params, element) {
                if (element.dataset.limitAmountErrorMsg) {
                    return element.dataset.limitAmountErrorMsg
                        .replace('@MIN', element.dataset.limitMin)
                        .replace('@MAX', element.dataset.limitMax);
                } else {
                    return 'Error: Please enter a value between' + element.dataset.limitMin
                        ? element.dataset.limitMin
                        : 0 + ' and ' + element.dataset.limitMax
                            ? element.dataset.limitMax
                            : 0;
                }
            }
        );

        /*
         * Lets you say "either at least X inputs that match selector Y must be filled,
         * OR they must all be skipped (left blank)."
         *
         * The end result, is that none of these inputs:
         *
         *	<input class="productinfo" name="partnumber">
         *	<input class="productinfo" name="description">
         *	<input class="productinfo" name="color">
         *
         *	...will validate unless either at least two of them are filled,
         *	OR none of them are.
         *
         * partnumber:	{skipOrFillMinimum: [2,".productinfo"]},
         * description: {skipOrFillMinimum: [2,".productinfo"]},
         * color:		{skipOrFillMinimum: [2,".productinfo"]}
         *
         * options[0]: number of fields that must be filled in the group
         * options[1]: CSS selector that defines the group of conditionally required fields
         *
         */
        $.validator.addMethod(
            'skipOrFillMinimum',
            function (value, element, options) {
                var numberOfFields =
                    typeof options === 'string'
                        ? parseInt(options.substring(1, options.length - 1).split(',')[0])
                        : options[0],
                    selector =
                        typeof options === 'string'
                            ? options
                                .substring(1, options.length - 1)
                                .split(',')
                                .slice(
                                    1,
                                    options.substring(1, options.length - 1).split(',').length
                                )
                                .join(',')
                            : options[1];

                selector = selector.substring(1, selector.length - 1);

                var $fields = $(selector, element.form),
                    $fieldsFirst = $fields.eq(0),
                    validator = $fieldsFirst.data('valid_skip')
                        ? $fieldsFirst.data('valid_skip')
                        : $.extend({}, this),
                    numberFilled = $fields.filter(function () {
                        return validator.elementValue(this);
                    }).length,
                    isValid =
                        numberFilled === 0 ||
                        numberFilled >= numberOfFields ||
                        element.value !== '';

                // Store the cloned validator for future validation
                $fieldsFirst.data('valid_skip', validator);

                // If element isn't being validated, run each skip_or_fill_minimum field's validation rules
                if (!$(element).data('being_validated')) {
                    $fields.data('being_validated', true);
                    $fields.each(function () {
                        validator.element(this);
                    });
                    $fields.data('being_validated', false);
                }
                return isValid;
            },
            function (params, element) {
                var options = element.dataset.ruleSkiporfillminimum,
                    numberOfFields =
                        typeof options === 'string'
                            ? parseInt(options.substring(1, options.length - 1).split(',')[0])
                            : options[0];

                return (
                    'Error: Please either skip these fields or fill at least ' +
                    numberOfFields +
                    ' of them.'
                );
            }
        );

        // Payment amount limits
        $.validator.addMethod('paymentLimits', function (value, element) {
            var paymentMinAmount = element.dataset.paymentMin
                ? parseFloat(element.dataset.paymentMin.replace(/[^\d.-]/g, ''))
                : 0,
                paymentMaxAmount = element.dataset.paymentMax
                    ? parseFloat(element.dataset.paymentMax.replace(/[^\d.-]/g, ''))
                    : 0;

            value = parseFloat(value.replace(/[^\d.-]/g, ''));

            return (
                (paymentMinAmount <= 0 || value >= paymentMinAmount) &&
                (paymentMaxAmount <= 0 || value <= paymentMaxAmount)
            );
        });

        // Payment amount higher than amount, excluding zero
        $.validator.addMethod(
            'paymentHigherNoZero',
            function (value, element) {
                var paymentMinAmount = element.dataset.paymentMin
                    ? parseFloat(element.dataset.paymentMin)
                    : 0;

                value = parseFloat(value.replace(/[^\d.-]/g, ''));

                return value == 0 || (paymentMinAmount > 0 && value >= paymentMinAmount);
            },
            "Error: Final Payment can't be less than $1. Please adjust your downpayment or number of installment."
        );

        // Payment amount higher than zero
        $.validator.addMethod(
            'paymentHigherThanZero',
            function (value) {
                if (value === '') return true;

                value = parseFloat(value.replace(/[^\d.-]/g, ''));

                return value > 0;
            },
            "Error: Final Payment can't be 0. Please adjust your downpayment."
        );

        // Installment amount limit
        $.validator.addMethod(
            'installmentLimit',
            function (value, element) {
                return parseFloat(element.dataset.installmentBase) / parseFloat(value) >= 5.0;
            },
            'Error: Installment amount has to be higher than $5 per month.'
        );

        // Letters and Spaces only
        $.validator.addMethod(
            'lettersSpacesOnly',
            function (value) {
                return /^[a-zA-Z ]*$/.test(value);
            },
            'Error: Please enter only letters.'
        );

        $.validator.addMethod(
            'addressInput',
            function (value, element) {
                return this.optional(element) || /^[a-z\d\-\s\&]+$/i.test(value);
            },
            'Error: Please enter only numbers, letters, spaces or dashes.'
        );

        // Custom Required check for file inputs
        $.validator.addMethod(
            'fileRequired',
            function (value, element) {
                return element.dataset.ruleFilerequired === 'false' || !!element.fileList.length;
            },
            'Error: This field is required.'
        );

        // checksum for routing number
        $.validator.addMethod(
            'routingNumber',
            function (value) {
                var n = 0;
                for (var i = 0; i < value.length; i += 3) {
                    n +=
                        parseInt(value.charAt(i), 10) * 3 +
                        parseInt(value.charAt(i + 1), 10) * 7 +
                        parseInt(value.charAt(i + 2), 10);
                }
                if (n != 0 && n % 10 == 0) {
                    return true;
                } else {
                    return false;
                }
            },
            'Error: Please enter a valid routing/ABA number.'
        );

        // DATES CUSTOM VALIDATION METHODS
        // Start service date format
        $.validator.addMethod(
            'dateFormat',
            function (value) {
                var date = Date.parse(value),
                    dateComponents = value.split('/'),
                    dateMonth = parseInt(dateComponents[0], 10),
                    dateDay = parseInt(dateComponents[1], 10),
                    dateYear = parseInt(dateComponents[2], 10),
                    validFormat,
                    validDate;

                if (isNaN(date)) {
                    return false;
                }

                if (dateComponents.length !== 3) {
                    return false;
                }

                // Validating date is correct in terms of days within the month of the selected year
                date = new Date(dateYear, dateMonth - 1, dateDay);
                validDate =
                    date.getFullYear() == dateYear &&
                    date.getMonth() + 1 == dateMonth &&
                    date.getDate() == dateDay;
                // Validating the format of the date
                validFormat = value.match(/^\d\d?\/\d\d?\/\d\d\d\d$/);

                return validDate && validFormat;
            },
            'Error: Please enter a valid date in the format mm/dd/yyyy'
        );

        // End date limit
        $.validator.addMethod(
            'endDateTime',
            function (value, element, params) {
                var startDate = $(params.split(',')[0]).val(),
                    startTime = $(params.split(',')[1]).val(),
                    startDayTime = $(params.split(',')[2]).val(),
                    endDate = $(params.split(',')[3]).val(),
                    endTime = $(params.split(',')[4]).val(),
                    endDayTime = $(params.split(',')[5]).val(),
                    startDateTime = startDate + ' ' + startTime + ' ' + startDayTime,
                    endDateTime = endDate + ' ' + endTime + ' ' + endDayTime;

                if (
                    !/Invalid|NaN/.test(new Date(startDateTime)) &&
                    !/Invalid|NaN/.test(new Date(endDateTime))
                ) {
                    return new Date(startDateTime) < new Date(endDateTime);
                } else {
                    return true;
                }
            },
            'Error: End date must be greater than start date.'
        );

        // Past date with 3 inputs
        $.validator.addMethod(
            'todayPastDateMultiple',
            function (value, element, params) {
                var today = new Date(),
                    startDate = $(params.split(',')[0]).val(),
                    startTime = $(params.split(',')[1]).val(),
                    startDayTime = $(params.split(',')[2]).val(),
                    startDateTime = startDate + ' ' + startTime + ' ' + startDayTime;

                if (!/Invalid|NaN/.test(new Date(startDateTime))) {
                    return new Date(startDateTime) <= today;
                } else {
                    return true;
                }
            },
            'Error: End date must be greater than start date.'
        );

        // End date time limit
        $.validator.addMethod(
            'endDate',
            function (value, element, params) {
                if (!/Invalid|NaN/.test(new Date(value))) {
                    return new Date(value) >= new Date($(params).val());
                }

                return (
                    (isNaN(value) && isNaN($(params).val())) ||
                    Number(value) > Number($(params).val())
                );
            },
            'Error: End date must be greater than start date.'
        );

        // End date time limit
        $.validator.addMethod(
            'endDateStrict',
            function (value, element, params) {
                if (!/Invalid|NaN/.test(new Date(value))) {
                    return new Date(value) > new Date($(params).val());
                }

                return (
                    (isNaN(value) && isNaN($(params).val())) ||
                    Number(value) > Number($(params).val())
                );
            },
            'Error: End date must be greater than start date.'
        );

        // Days range validation
        $.validator.addMethod(
            'limitDays',
            function (value, element, params) {
                var oneDay = 24 * 60 * 60 * 1000,
                    startDate = $(params) ? new Date($(params).val()) : new Date(),
                    endDate = new Date(value),
                    diffDays = Math.round(
                        Math.abs((startDate.getTime() - endDate.getTime()) / oneDay)
                    );

                if (diffDays < 30) {
                    return diffDays < 30;
                }
            },
            'Error: Date range cannot be greater than 30 days.'
        );

        // Days range validation
        $.validator.addMethod(
            'rangeDays',
            function (value, element, params) {
                params = params.split(',');
                var oneDay = 24 * 60 * 60 * 1000,
                    startDate = $(params) ? new Date($(params[0]).val()) : new Date(),
                    endDate = $(params) ? new Date($(params[1]).val()) : new Date(),
                    diffDays = Math.round(
                        Math.abs((startDate.getTime() - endDate.getTime()) / oneDay)
                    );                

                if($(params[0]).val() === '' || $(params[1]).val() === '') {
                    return true;
                }

                if (diffDays < parseInt(params[2])) {
                    if($(params[0]).hasClass(CONSTANTS.INPUT_ERROR_CLASS)) {
                        query.removeClass($(params[0]), CONSTANTS.INPUT_ERROR_CLASS);
                        $(params[0])[0].parentElement.parentElement.getElementsByClassName('coned-field-error-wrapper')[0].remove();
                    }
                    if($(params[1]).hasClass(CONSTANTS.INPUT_ERROR_CLASS)) {
                        query.removeClass($(params[1]), CONSTANTS.INPUT_ERROR_CLASS);
                        $(params[1])[0].parentElement.parentElement.getElementsByClassName('coned-field-error-wrapper')[0].remove();
                    }
                    return diffDays < parseInt(params[2]);
                }
            },             
            'Error: Date range is invalid.'
        );


        // pay bill range validation
        $.validator.addMethod(
            'payLimitDays',
            function (value) {
                var startDate = new Date(),
                    payDate = new Date(value),
                    timeDiff = Math.abs(startDate.getTime() - payDate.getTime()),
                    diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

                if (diffDays <= 5) {
                    return diffDays <= 5;
                }
            },
            'Error: Date range cannot be greater than 5 days.'
        );

        // Validate date is after set parameter
        $.validator.addMethod(
            'startDate',
            function (value, element, params) {
                var startDate = new Date(params),
                    valueDate = new Date(value);

                return startDate.getTime() <= valueDate.getTime();
            },
            $.validator.format('Error: Date must be after {0}.')
        );

        // Beyond tomorrow
        $.validator.addMethod(
            'tomorrowLimit',
            function (value) {
                var today = new Date(),
                    selectedValue = new Date(value),
                    dateLimit = new Date(
                        today.setDate(today.getDate() + 1)
                    )

                return selectedValue < dateLimit || selectedValue.toString() === dateLimit.toString();
            },
            'Error: Real Time Prices are unavailable beyond tomorrow date'
        );

        // Future date validation
        $.validator.addMethod('futureDate', function (value, element) {
            var now = new Date(),
                myDate = new Date(value);

            return this.optional(element) || myDate > now;
        });

        // From today date validation
        $.validator.addMethod('todayDate', function (value, element) {
            var now = new Date(),
                today = now.setDate(now.getDate() - 1),
                myDate = new Date(value);

            myDate = myDate.setDate(myDate.getDate());

            return this.optional(element) || myDate > today;
        });

        // Past date validation
        $.validator.addMethod('pastDate', function (value, element) {
            var now = new Date(),
                today = now.setDate(now.getDate() - 1),
                myDate = new Date(value);

            myDate = myDate.setDate(myDate.getDate());

            return this.optional(element) || myDate < today;
        });

        // Past or today date validation
        $.validator.addMethod('pastTodayDate', function (value, element) {
            var now = new Date(),
                today = now.setDate(now.getDate()),
                myDate = new Date(value);

            myDate = myDate.setDate(myDate.getDate());

            return this.optional(element) || myDate <= today;
        });

        // Date limit. Can be future or past. 
        // Dates are relative to today
        // Params: month, day, year
        // ie: to limit date to 2 years in the past add params 0,0,-2
        // Set zero values if param should be from actual date
        // For past use negative numbers
        $.validator.addMethod(
            'customDateLimit',
            function (value, element, params) {
                params = params.split(',');
                var today = new Date(),
                    selectedValue = new Date(value),                    
                    dateLimit = new Date(
                        today.setFullYear(
                            today.getFullYear() + parseFloat(params[2]),
                            today.getMonth() + parseFloat(params[0]),
                            today.getDate() + parseFloat(params[1])
                        )
                    ),
                    dateLimitZeroHours = new Date(dateLimit.setHours(0, 0, 0));

                return selectedValue > dateLimitZeroHours || selectedValue.toString() === dateLimitZeroHours.toString();
            },
            'Error: Please enter a valid date.'
        );

        // Transactional start date
        $.validator.addMethod(
            'transactionalStartDate',
            function (value, element, params) {
                if (!/Invalid|NaN/.test(new Date(value))) {
                    return new Date(value) <= new Date($(params).val());
                }

                return (
                    (isNaN(value) && isNaN($(params).val())) ||
                    Number(value) > Number($(params).val())
                );
            },
            'Error: The service start date must be before or the same day the service end date.'
        );

        $.validator.addMethod(
            'daytimeFormat',
            function (value) {
                return /^(0?[1-9]|1[0-2]):([0-5]?[0-9])$/.test(value);
            },
            'Error: Please enter a valid day time in the format hh:mm'
        );

        // Days range validation
        $.validator.addMethod(
            'transferLimitDays',
            function (value, element, params) {
                var oneDay = 24 * 60 * 60 * 1000,
                    startDate = new Date($(params).val()),
                    endDate = new Date(value),
                    diffDays = Math.round(
                        Math.abs((startDate.getTime() - endDate.getTime()) / oneDay)
                    );

                return diffDays < 30;
            },
            'Error: Dates within 30 calendar days'
        );

        // Due bill date limit
        $.validator.addMethod(
            'nextPaymentDue',
            function (value, element) {
                var extensionDays = element.dataset.extensionDays,
                    dueDay = element.dataset.paymentDay,
                    day = 24 * 60 * 60 * 1000,
                    startDate = dueDay !== '' ? new Date(dueDay) : new Date(),
                    endDate = new Date(value),
                    diffDays = Math.ceil((endDate.getTime() - startDate.getTime()) / day);

                return diffDays > 0 && diffDays <= extensionDays;
            },
            'Error: Date can’t be more than 10 days after bill is due'
        );

        // Services date limits validation
        $.validator.addMethod(
            'servicesLimitDates',
            function (value, element) {
                var minDate,
                    maxDate,
                    userValueDate = new Date(value).getTime();

                if (!element.dataset.minDateDays && !element.dataset.maxDateDays) {
                    (minDate = new Date().setHours(0, 0, 0, 0)), (maxDate = new Date());

                    maxDate.setDate(maxDate.getDate() + 5);
                    maxDate = maxDate.setHours(0, 0, 0, 0);
                } else {
                    (minDate = element.dataset.minDateDays
                        ? new Date(element.dataset.minDateDays).getTime()
                        : new Date().setHours(0, 0, 0, 0)),
                        (maxDate = element.dataset.maxDateDays
                            ? new Date(element.dataset.maxDateDays).getTime()
                            : new Date().setHours(0, 0, 0, 0));
                }

                return userValueDate >= minDate && userValueDate <= maxDate;
            },
            'Error: Dates range is invalid for this account'
        );

        // Request extension dates limit
        $.validator.addMethod(
            'extensionLimitDates',
            function (value, element) {
                var minDate = new Date(element.dataset.minDateDays).getTime(),
                    maxDate = new Date(element.dataset.maxDateDays).getTime(),
                    userValueDate = new Date(value).getTime();

                return userValueDate >= minDate && userValueDate <= maxDate;
            },
            function (params, element) {
                var minDate = new Date(element.dataset.minDateDays).getTime(),
                    maxDate = new Date(element.dataset.maxDateDays).getTime(),
                    userValueDate = new Date(element.value).getTime(),
                    locale = element.dataset.locale,
                    localeDateObj = { year: 'numeric', month: 'long', day: 'numeric' };

                if (userValueDate < minDate) return element.dataset.dateBeforePaymentMsg;
                else if (userValueDate > maxDate) {
                    return locale
                        ? element.dataset.dateAfterPaymentMsg.replace(
                            '@DATE',
                            new Date(element.dataset.maxDateDays).toLocaleString(
                                locale,
                                localeDateObj
                            )
                        )
                        : element.dataset.dateAfterPaymentMsg.replace(
                            '@DATE',
                            element.dataset.maxDateDays
                        );
                } else return element.dataset.defaultErrorMsg;
            }
        );

        // Letters and numbers only
        $.validator.addMethod(
            'lettersNumbersOnly',
            function (value, element) {
                return this.optional(element) || /^[0-9a-zA-Z]+$/i.test(value);
            },
            'Error: Please enter letters and numbers only.'
        );

        // Autocomplete Input MUST match one of the options
        $.validator.addMethod(
            'autocompleteMustMatchOption',
            function (value, element) {
                var options;

                if (element.dataset.ruleAutocompletemustmatchoption.includes('|')) {
                    /*
                     * listType should create a list with 3 elements that were separated with an "|" character.
                     *    Ex. data|unitsList|JSON or class|js-autocomplete-list-input|comma
                     *
                     * listType[0]: Type of selector to look for
                     * listType[1]: Name of the selector for the list
                     * listType[2]: How should the list be parsed
                     *
                     */
                    var listType = element.dataset.ruleAutocompletemustmatchoption.split('|');

                    switch (listType[0]) {
                        case 'data':
                            options = element.dataset[listType[1]];
                            break;
                        case 'class':
                            options = element.parentElement.getElementsByClassName(listType[1])[0]
                                .value;
                            break;
                        // More to be added as needed
                    }

                    switch (listType[2]) {
                        case 'JSON':
                            options = _.keys(JSON.parse(options));
                            break;
                        case 'comma':
                            options = options.split(',');
                            break;
                        // More to be added as needed
                    }
                }

                for (var i = 0; i < options.length; i++) {
                    if (value === coned.utils.entitiesDecode(options[i])) return true;
                }

                return false;
            },
            'Error: Please enter a correct option.'
        );

        // Tax ID validation
        $.validator.addMethod(
            'taxId',
            function (value, element) {
                return this.optional(element) || /^[1-9]\d?-\d{7}$/i.test(value);
            },
            'Error: Please enter valid Tax ID number.'
        );

        // Birth date is 18 or over
        $.validator.addMethod(
            'over18',
            function (value) {
                var selectedDate = new Date(value),
                    birthDateDay = selectedDate.getDate(),
                    birthDateMonth = selectedDate.getMonth(),
                    birthDateYear = selectedDate.getFullYear(),
                    minimumDate = new Date(birthDateYear + 18, birthDateMonth, birthDateDay);

                return minimumDate <= new Date();
            },
            'Error: We’re sorry, you must be at least 18 years old to start service.'
        );

        // Birth date is 150 or under
        $.validator.addMethod(
            'under150',
            function (value) {
                var selectedDate = new Date(value),
                    birthDateDay = selectedDate.getDate(),
                    birthDateMonth = selectedDate.getMonth(),
                    birthDateYear = selectedDate.getFullYear(),
                    maximumDate = new Date(birthDateYear + 150, birthDateMonth, birthDateDay);

                return maximumDate >= new Date();
            },
            'Error: We’re sorry, you must be under a 150 years old to start service.'
        );

        var updateDefaultMessages = function () {
            $.extend($.validator.messages, {
                required: 'Error: This field is required.',
                email: 'Error: Please enter a valid email address.',
                phoneUS: 'Error: Please specify a valid phone number'
            });
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeAriaAttributes();
            initializeEvents();
            updateDefaultMessages();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    FormValidationModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return FormValidationModule;
})();
