// ==================== VIDEO MODULE COMPONENT =========================
/* global _ */
/* global gsap */
/* global videojs */
/* global Swiper */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.VideoModule = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        VIDEO_PLAYER: 'js-video-player',
        VIDEO_LIST_WRAPPER: 'js-videos-wrapper',
        VIDEO_TITLE: 'js-video-title',
        VIDEO_DESCRIPTION: 'js-video-description',
        VIDEO_DESCRIPTION_PARAGRAPH: 'js-video-paragraph',
        VIDEO_ITEM_CLASS: 'coned-video__video-item',
        VIDEO_THUMBNAIL_SELECTOR: 'js-video-item',
        VIDEO_THUMBNAIL_CLASS: 'coned-video__video-item-thumbnail',
        VIDEO_ACTIVE_CLASS: 'coned-video--video-item--active',
        THUMBNAIL_ACTIVE_CLASS: 'coned-video__thumbnail--active ',
        DISABLE_STATE: 'gallery__button--disable',
        CONTROLS_SELECTOR: 'js-list-controls',
        NEXT_BUTTON: '.js-next',
        PREV_BUTTON: '.js-prev',
        SHORT_LIST_MODIFIER: 'coned-video__video-item--shortlist',
        MAX_DESCRIPTION: '230',
        DESCRIPTION_ELLIPSIS_CLASS: 'coned-video__paragraph--ellipsis',
        DESCRIPTION_OPEN_CLASS: 'coned-video__description--open',
        SWIPER_CONTAINER_CLASS: 'swiper-container',
        SLIDES_PER_VIEW_DESKTOP: 4,
        SLIDES_PER_VIEW_TABLET: 3,
        SLIDES_PER_VIEW_MOBILE: 2
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var VideoModule = function ($videoModule) {
        /**
         * PRIVATE METHODS
         */
        var $brigthCovePlayer,
            $playListWrapper,
            $videoTitle,
            $videoDescription,
            $videoDescriptionParagraph,
            $controls,
            videoItem;

        var descriptionEllipsis = function () {
            var descriptionHeight = $videoDescriptionParagraph.offsetHeight;

            if (descriptionHeight > CONSTANTS.MAX_DESCRIPTION) {
                $videoDescription.classList.add(CONSTANTS.DESCRIPTION_ELLIPSIS_CLASS);

                // adding ellipsis associated events
                coned.utils.addGeneralListeners($videoDescription, showHideDescription);
                window.addEventListener('resize', hideDescription);
            } else {
                $videoDescription.classList.remove(CONSTANTS.DESCRIPTION_ELLIPSIS_CLASS);
            }
        };

        var showHideDescription = function (event) {
            if (event != undefined) {
                event.preventDefault();
            }

            $videoDescription.classList.toggle(CONSTANTS.DESCRIPTION_OPEN_CLASS);

            var isDescriptionOpen = query.hasClass(
                    $videoDescription,
                    CONSTANTS.DESCRIPTION_OPEN_CLASS
                ),
                heightAnimation = isDescriptionOpen
                    ? $videoModule.offsetHeight - 160
                    : CONSTANTS.MAX_DESCRIPTION;

            // animate the video description
            gsap.to($videoDescription, {
                duration: 0.4,
                maxHeight: heightAnimation
            });
        };

        var hideDescription = function () {
            if (!query.hasClass($videoDescription, CONSTANTS.DESCRIPTION_OPEN_CLASS)) return;

            $videoDescription.classList.toggle(CONSTANTS.DESCRIPTION_OPEN_CLASS);

            // animate the video description
            gsap.to($videoDescription, {
                duration: 0.4,
                maxHeight: CONSTANTS.MAX_DESCRIPTION
            });
        };

        var getVideoDescription = function (videoItem) {
            var videoLongDescription, videoShortDescription, videoDescription;

            if (videoItem) {
                videoLongDescription = videoItem.longDescription ? videoItem.longDescription : '';
                videoShortDescription = videoItem.description ? videoItem.description : '';
                videoDescription = videoLongDescription
                    ? videoLongDescription
                    : videoShortDescription;

                return videoDescription;
            }
        };

        var singleVideoInformation = function () {
            var video = $videoModule.querySelectorAll('[data-video-id]')[0],
                videoNumber = video.dataset.videoId,
                videoId = 'video-' + videoNumber,
                videoDescription;

            // handle load metadata
            videojs(videoId).one('loadedmetadata', function () {
                var myPlayer = this;

                videoItem = myPlayer.mediainfo;
                videoDescription = getVideoDescription(videoItem);

                $videoTitle.innerHTML = videoItem.name ? videoItem.name : '';
                $videoDescriptionParagraph.innerHTML = videoDescription;

                descriptionEllipsis();
            });
        };

        /**
         * Creates the video thumbnails for the playlist.
         * @param {Array} playlistData - The data for the playlist.
         */
        var createVideoThumbnails = function (playlistData) {
            var playlistItem,
                thumbnailImg,
                thumbnailTitle,
                videoDescription;

            _.each(playlistData, function (videoItem, videoListIndex) {
                videoItem = playlistData[videoListIndex];
                videoDescription = getVideoDescription(videoItem);

                playlistItem = document.createElement('li');
                playlistItem.classList.add(CONSTANTS.VIDEO_ITEM_CLASS);
                playlistItem.classList.add(CONSTANTS.VIDEO_THUMBNAIL_SELECTOR);
                playlistItem.setAttribute('data-playlist-index', videoListIndex);
                playlistItem.setAttribute('data-video-title', videoItem.name);
                playlistItem.setAttribute('data-video-description', videoDescription);

                // Create the thumbnail img and set its class
                thumbnailImg = document.createElement('img');
                thumbnailImg.setAttribute('class', CONSTANTS.VIDEO_THUMBNAIL_CLASS);
                thumbnailImg.setAttribute('src', videoItem.thumbnail);
                thumbnailImg.setAttribute('alt', videoItem.name);

                thumbnailTitle = document.createElement('span');
                thumbnailTitle.classList.add('coned-video__thumbnail-title');
                thumbnailTitle.innerHTML = videoItem.name;

                // Append the img to the item, and the item to the playlist
                playlistItem.appendChild(thumbnailImg);
                playlistItem.appendChild(thumbnailTitle);
                $playListWrapper.appendChild(playlistItem);
            });
        };

        /**
         * Creates the video list for the playlist.
         */
        var createVideoList = function () {
            // Handle loadedmetadata just once, it fires again with each video load
            videojs($brigthCovePlayer).one('loadedmetadata', function () {
                var myPlayer = this,
                    videoListTotal,
                    $playlistItems,
                    playlistData = myPlayer.playlist();

                videoListTotal = playlistData.length;

                createVideoThumbnails(playlistData);

                // set first video title and description
                var activeVideo = $videoModule.getElementsByClassName(
                    CONSTANTS.VIDEO_THUMBNAIL_SELECTOR
                )[0];
                $videoTitle.innerHTML = activeVideo.dataset.videoTitle
                    ? activeVideo.dataset.videoTitle
                    : '';
                $videoDescriptionParagraph.innerHTML = activeVideo.dataset.videoDescription;

                // short description if need it
                descriptionEllipsis();

                // set active class for first video
                activeVideo.classList.add(CONSTANTS.VIDEO_ACTIVE_CLASS);

                // function to load playlist items on click
                function loadPlaylistItem(event) {
                    event.preventDefault();

                    var index = this.dataset.playlistIndex;

                    // remove active class
                    _.each($playlistItems, function (videoItem) {
                        videoItem.classList.remove(CONSTANTS.VIDEO_ACTIVE_CLASS);
                    });

                    // set active video class
                    this.classList.add(CONSTANTS.VIDEO_ACTIVE_CLASS);

                    // change video title and description
                    $videoTitle.innerHTML = this.dataset.videoTitle ? this.dataset.videoTitle : '';
                    $videoDescriptionParagraph.innerHTML = this.dataset.videoDescription
                        ? this.dataset.videoDescription
                        : '';

                    // short description if need it
                    descriptionEllipsis();
                    $videoDescription.classList.remove(CONSTANTS.DESCRIPTION_OPEN_CLASS);

                    // play selected video
                    myPlayer.playlist.currentItem(parseInt(index, 10));
                    myPlayer.play();
                }

                // Init the slider
                if (videoListTotal > 2) {
                    initializeSlider();
                } else {
                    $controls.style.display = 'none';
                    _.each($playlistItems, function (videoItem) {
                        videoItem.classList.add(CONSTANTS.SHORT_LIST_MODIFIER);
                    });
                }

                // Get video items
                $playlistItems = $videoModule.getElementsByClassName(
                    CONSTANTS.VIDEO_THUMBNAIL_SELECTOR
                );

                // Add event listener to thumbnails
                _.each($playlistItems, function (videoItem) {
                    coned.utils.addGeneralListeners(videoItem, loadPlaylistItem);
                });
            });
        };

        /**
         * Initializes the slider for the video thumbnails.
         */
        var initializeSlider = function () {
            var $swiper = $videoModule.getElementsByClassName(
                CONSTANTS.SWIPER_CONTAINER_CLASS
            )[0];

            new Swiper($swiper, {
                slideClass: 'js-video-item',
                wrapperClass: 'js-videos-wrapper',
                navigation: {
                    nextEl: CONSTANTS.NEXT_BUTTON,
                    prevEl: CONSTANTS.PREV_BUTTON
                },
                slidesPerView: CONSTANTS.SLIDES_PER_VIEW_MOBILE,
                loop: true,
                // Responsive breakpoints
                breakpoints: {
                    // When window width is >= 480px
                    481: {
                        slidesPerView: CONSTANTS.SLIDES_PER_VIEW_TABLET
                    },
                    // When window width is >= 900px
                    901: {
                        slidesPerView: CONSTANTS.SLIDES_PER_VIEW_DESKTOP
                    }
                }
            });
        };

        /**
         * Initialize the data in the module.
         */
        var initializeData = function () {
            $brigthCovePlayer = $videoModule.getElementsByClassName(CONSTANTS.VIDEO_PLAYER)[0];
            $playListWrapper = $videoModule.getElementsByClassName(CONSTANTS.VIDEO_LIST_WRAPPER)[0];
            $videoTitle = $videoModule.getElementsByClassName(CONSTANTS.VIDEO_TITLE)[0];
            $videoDescription = $videoModule.getElementsByClassName(CONSTANTS.VIDEO_DESCRIPTION)[0];
            $videoDescriptionParagraph = $videoModule.getElementsByClassName(
                CONSTANTS.VIDEO_DESCRIPTION_PARAGRAPH
            )[0];
            $controls = $videoModule.getElementsByClassName(CONSTANTS.CONTROLS_SELECTOR)[0];
        };

        /**
         * Initialize the events in the module.
         */
        var initializeEvents = function () {
            // check if video source is playlist or single video
            if ($videoModule.querySelectorAll('[data-video-id]').length > 0) {
                singleVideoInformation();
            } else {
                createVideoList();
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    VideoModule.prototype.isLoaded = function () {
        return isLoaded;
    };

    return VideoModule;
})();
