// ==================== REGISTER VALIDATION COMPONENT =========================
/* global _ */
/* global $ */
/* global dataLayer */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.RegisterValidation = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        SHOW_PASSWORD_BUTTON: 'js-show-password',
        REMEMBER_ME_COOKIE_NAME: 'rememberme',
        PASSWORD_INPUT: 'js-password-input',
        CONFIRM_PASSWORD_INPUT: 'js-confirm-password-input',
        EMAIL_INPUT: 'js-email-input',
        MESSAGE_CHARACTERS: 'js-register-characters',
        MESSAGE_UPPERCASE: 'js-register-uppercase',
        MESSAGE_NUMBER: 'js-register-number',
        MESSAGE_PASSWORD: 'js-register-password',
        SUBMIT_BUTTON: 'js-transactional-submit-selector',
        SUBMIT_ANIMATION_BUTTON: 'js-submit-progress-animation',
        REGISTER_FORM: 'js-register-form',
        USERNAME_SELECTOR: 'js-username-selector',
        ERROR_CLASS: 'register__validation--error',
        ERROR_MESSAGE_CLASS: 'coned-input-message--error',
        INPUT_CLASS_SELECTOR: 'js-coned-input',
        HIDDEN_CLASS: 'hidden',
        IGNORE_CLASS: 'js-validate-ignore',
        SELECT_CLASS_SELECTOR: 'js-coned-select',
        LABEL_CLASS_SELECTOR: 'js-coned-label',
        SECURITY_QUESTION_CONTAINER: 'js-question-container',
        SERVICE_ERROR: 'js-service-error',
        SERVICE_ERROR_MESSAGE: 'js-error-message',
        REGISTER_SITECORE_ID: 'ScId',
        REGISTER_USER_ID: 'userId',
        REGISTER_MOBILE_PHONE: 'mfaMobilePhone',
        REGISTER_VERIFICATON_BY: 'verificationOption',
        REGISTER_PASSWORD: 'registerPasword',
        REGISTER_QUESTION: 'securityQuestion',
        REGISTER_ANSWER: 'securityAnswer',
        REGISTER_VERIFICATION_CODE: 'verificationCode',
        REGISTER_ACCESS_CODE: 'accessCode',
        FORM_MFA_VALID: 'mfa-is-valid',
        SET_PASSWORD_STEP: 'js-set-password',
        SET_MULTIFACTOR_STEP: 'js-set-multifactor',
        SUCCESS_BANNER_SINGLE: 'js-finish-single',
        SUCCESS_BANNER_SINGLE_RETIREE: 'js-finish-single-retiree',
        LOGIN_RETIREE_BUTTON: 'js-single-retiree-link',
        FORM_CONTAINER_SELECTOR: 'js-register-container',
        LOGIN_FORM_COMPONENT_NAME: 'LoginFormComponent',
        FROM_URI_PARAMETER: '&fromURI=',
        CHECK_ICON: 'icon-check-status',
        CLOSE_ICON: 'icon-close',

        // Tagging
        TAGGING_PAGE_LOAD: 'Step04.Page.Load',
        TAGGING_SUBMIT: 'Step04.Submit'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]} [description]
     * @return {}        Encapsulated modules with its function.
     */
    var RegisterValidation = function ($register) {
        /**
         * PRIVATE METHODS
         */
        var $showPasswordButtons,
            $passwordInput,
            $confirmPasswordInput,
            $messageCharacters,
            $messageUppercase,
            $messageNumber,
            $messagePassword,
            $submitButton,
            $submitAnimationButton,
            $usernameSelector,
            $serviceErrorMessage,
            $serviceError,
            _dataUsername,
            _data;

        var showPassword = function (event) {
            event.preventDefault();

            var $selectedInput = this.parentNode.getElementsByClassName(
                    CONSTANTS.INPUT_CLASS_SELECTOR
                )[0],
                inputType = $selectedInput.type;

            if (inputType == 'password') {
                $selectedInput.type = 'text';
                event.target.innerHTML = this.dataset.replaceText;
            } else {
                $selectedInput.type = 'password';
                event.target.innerHTML = this.dataset.text;
            }
        };

        var testValue = function ($text_error, testCase, value) {
            var testExpression,
                testExpressionValue,
                testMinMax = /^.{8,30}$/,
                testUppercaseLowercase = /(?=.*[a-z])(?=.*[A-Z])/,
                testNumber = /[0-9\s]+/;

            // Case test
            switch (testCase) {
                case 'minMax':
                    testExpression = testMinMax;
                    break;
                case 'uppercase':
                    testExpression = testUppercaseLowercase;
                    break;
                case 'number':
                    testExpression = testNumber;
                    break;
                case 'password':
                    testExpressionValue = testUsername(value, _dataUsername);
                    break;
            }

            if (testCase != 'password') {
                testExpressionValue = testExpression.test(value);
            }

            // Show message
            if (testExpressionValue) {
                $text_error.classList.remove(CONSTANTS.ERROR_CLASS);
                query.removeClass($text_error.children[0], CONSTANTS.CLOSE_ICON);
                query.addClass($text_error.children[0], CONSTANTS.CHECK_ICON);
            } else {
                $text_error.classList.add(CONSTANTS.ERROR_CLASS);
                query.removeClass($text_error.children[0], CONSTANTS.CHECK_ICON);
                query.addClass($text_error.children[0], CONSTANTS.CLOSE_ICON);
            }

            return testExpressionValue;
        };

        var passwordValidation = function () {
            var inputValue = $passwordInput.value;

            // Flags
            // min 8 - max 30
            var minMax = testValue($messageCharacters, 'minMax', inputValue);

            // Uppercase letter
            var uppercase = testValue($messageUppercase, 'uppercase', inputValue);

            // Number
            var number = testValue($messageNumber, 'number', inputValue);

            // Exist Username on Password
            var userOnPassword = testValue($messagePassword, 'password', inputValue);

            if (minMax && uppercase && number && userOnPassword) {
                $passwordInput.classList.add(CONSTANTS.VALID_CONFIRMATION);
            } else {
                $passwordInput.classList.remove(CONSTANTS.VALID_CONFIRMATION);
            }

            checkFormStatus();
        };

        var testUsername = function (password, username) {
            var lengthString = username.length,
                existOnPassword = true,
                subStringPiece,
                indexOfString;

            for (var index = 0; index + 3 <= lengthString; index++) {
                subStringPiece = username.substring(index, index + 3);
                indexOfString = password.indexOf(subStringPiece);

                if (indexOfString > 0) {
                    existOnPassword = false;
                }
            }

            return existOnPassword;
        };

        var checkFormStatus = function () {
            var isPasswordValid = query.hasClass($passwordInput, CONSTANTS.VALID_CONFIRMATION),
                validator = $($register).validate();

            if (isPasswordValid && validator.element($confirmPasswordInput)) {
                $submitButton.disabled = false;
            } else {
                $submitButton.disabled = true;
            }
        };

        var successRegisterCallback = function (data) {
            $submitAnimationButton.classList.add(coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS);
            _data = data;

            successPasswordCallback();
            authenticateUserOnUpdate();
        };

        var errorRegisterCallback = function (data) {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            _data = data;
        };

        var errorLoginCallback = function () {
            $submitAnimationButton.classList.add(coned.constants.ERROR_SERVICE_RESPONSE_CLASS);
            setTimeout(function () {
                showError('');
            }, 1500);
        };

        function successPostLoginDataCallback(data) {
            if (coned.utils.isPatternLab()) {
                var serviceUrl;

                if (coned.utils.isOru()) {
                    serviceUrl = coned.plConstants.GET_LOGIN_ORU;
                } else {
                    serviceUrl = coned.plConstants.GET_LOGIN;
                }

                query.getData(
                    serviceUrl,
                    function (mockedData) {
                        mockedData.authRedirectUrl = coned.utils.isOru()
                            ? '/?p=pages-oru-dashboard'
                            : '/?p=pages-dashboard';
                        checkLogin(mockedData);
                    },
                    function () {
                        setTimeout(function () {
                            showError('');
                        }, 1500);
                    }
                );
            } else {
                checkLogin(data);
            }
        }

        var checkLogin = function (data) {
            if (data.login) {
                // login was successful
                // Add Set MFA page redirect
                if (data.noMfa) {
                    var fromURIParam = _data.LoginPageUrl
                        ? CONSTANTS.FROM_URI_PARAMETER + _data.LoginPageUrl
                        : '';
                    window.location.href = data.noMfaRedirectUrl + fromURIParam;
                    return;
                } else {
                    // Device is not new
                    // Analytics data building
                    dataLayer.push({
                        isLoggedIn: 'true'
                    });

                    window.location.href = data.authRedirectUrl;
                    return;
                }
            } else {
                // Login was unsucessful
                // Stop animation manually, since now the animation is not stopped by the success scenario
                $submitAnimationButton.classList.add(
                    coned.constants.SUCCESS_SERVICE_RESPONSE_CLASS
                );
                setTimeout(function () {
                    showError('');
                }, 1500);
            }
        };

        var authenticateUserOnUpdate = function () {
            var loginUrl = $register.dataset.loginUrl,
                params = {
                    LoginEmail: _dataUsername,
                    LoginPassword: query.getFormInputValue($register, CONSTANTS.REGISTER_PASSWORD),
                    LoginRememberMe: false,
                    ReturnUrl: '',
                    OpenIdRelayState: ''
                };
            params = JSON.stringify(params);

            query.deleteCookie(CONSTANTS.REMEMBER_ME_COOKIE_NAME);

            // Delete login cookie before request
            var req = new XMLHttpRequest();

            req.open('DELETE', $register.dataset.logoutUrl);
            req.withCredentials = true;
            req.setRequestHeader('Accept', 'application/json');
            req.send(null);

            query.postData(
                loginUrl,
                successPostLoginDataCallback,
                errorLoginCallback,
                params,
                true
            );
        };

        var registerSubmit = function () {
            if ($register.dataset.tagging) {
                dataLayer.push({
                    event: $register.dataset.tagging + CONSTANTS.TAGGING_SUBMIT
                });
            }

            new coned.components.SubmitAnimation(
                $submitAnimationButton,
                passwordRegisterCall,
                function () {},
                errorServiceCallback,
                false,
                false
            );
        };

        var passwordRegisterCall = function () {
            var setPasswordService = $register.dataset.setPasswordService,
                params;

            params = {
                ScId: query.getFormInputValue($register, CONSTANTS.REGISTER_SITECORE_ID),
                AccessCode: query.getFormInputValue($register, CONSTANTS.REGISTER_ACCESS_CODE),
                UserId: query.getFormInputValue($register, CONSTANTS.REGISTER_USER_ID),
                Password: query.getFormInputValue($register, CONSTANTS.REGISTER_PASSWORD)
            };

            params = JSON.stringify(params);
            query.postData(
                setPasswordService,
                successRegisterCallback,
                errorRegisterCallback,
                params,
                true
            );
        };

        var successPasswordCallback = function () {
            // Analytics data building
            dataLayer.push({
                event: 'registration.process',
                registration: 'step04b'
            });

            // Qualtrics survey triggering
            coned.utils.qualtricsTriggering($register);

            hideError();

            // Analytics data building
            dataLayer.push({
                event: 'registration.process',
                registration: 'step04c'
            });

            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/register/create-profile/set-mfa'
            });
        };

        var errorServiceCallback = function () {
            if (coned.utils.isPatternLab()) {
                query.getData(coned.plConstants.DEFAULT_SERVICE_ERROR, showError, showError);
            } else {
                showError(_data);
            }
        };

        var showError = function (data) {
            var errorMsg = data.errorMsg ? data.errorMsg : coned.constants.ERROR_MESSAGE;

            _.each($serviceError, function (serviceError) {
                serviceError.classList.remove(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorMessage, function (serviceErrorMessage) {
                serviceErrorMessage.innerHTML = errorMsg;
            });

            $serviceError[0].focus();
        };

        var hideError = function () {
            _.each($serviceError, function (serviceError) {
                serviceError.classList.add(CONSTANTS.HIDDEN_CLASS);
            });

            _.each($serviceErrorMessage, function (serviceErrorMessage) {
                serviceErrorMessage.innerHTML = '';
            });
        };

        var initializeData = function () {
            $showPasswordButtons = $register.getElementsByClassName(CONSTANTS.SHOW_PASSWORD_BUTTON);
            $passwordInput = $register.getElementsByClassName(CONSTANTS.PASSWORD_INPUT)[0];
            $confirmPasswordInput = $register.getElementsByClassName(
                CONSTANTS.CONFIRM_PASSWORD_INPUT
            )[0];
            $messageCharacters = $register.getElementsByClassName(CONSTANTS.MESSAGE_CHARACTERS)[0];
            $messageUppercase = $register.getElementsByClassName(CONSTANTS.MESSAGE_UPPERCASE)[0];
            $messageNumber = $register.getElementsByClassName(CONSTANTS.MESSAGE_NUMBER)[0];
            $messagePassword = $register.getElementsByClassName(CONSTANTS.MESSAGE_PASSWORD)[0];
            $usernameSelector = $register.getElementsByClassName(CONSTANTS.USERNAME_SELECTOR)[0];
            $submitAnimationButton = $register.getElementsByClassName(
                CONSTANTS.SUBMIT_ANIMATION_BUTTON
            )[0];
            $serviceError = $register.parentNode.parentNode.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR
            );
            $serviceErrorMessage = $register.parentNode.parentNode.getElementsByClassName(
                CONSTANTS.SERVICE_ERROR_MESSAGE
            );
            $submitButton = $register.getElementsByClassName(CONSTANTS.SUBMIT_BUTTON)[0];
            _dataUsername = $usernameSelector.dataset.username;
        };

        var initializeEvents = function () {
            for (var index = 0; index < $showPasswordButtons.length; index++) {
                coned.utils.addGeneralListeners($showPasswordButtons[index], showPassword);
            }

            $passwordInput.addEventListener('keyup', passwordValidation);
            $confirmPasswordInput.addEventListener('keyup', checkFormStatus);

            // Forms validation
            new coned.components.ValidateForm('.' + CONSTANTS.REGISTER_FORM, registerSubmit);

            if ($passwordInput.value !== '') {
                $passwordInput.classList.add(CONSTANTS.FILLED_CLASS);
                passwordValidation();
                checkFormStatus();
            }

            if ($confirmPasswordInput.value !== '') {
                $confirmPasswordInput.classList.add(CONSTANTS.FILLED_CLASS);
                passwordValidation();
                checkFormStatus();
            }

            // Analytics data building
            dataLayer.push({
                event: 'registration.process',
                registration: 'step04a'
            });

            // Analytics data building virtual page
            dataLayer.push({
                event: 'virtualPageView',
                virtualPageUrl: '/en/register/create-profile/set-password'
            });

            if ($register.dataset.tagging) {
                dataLayer.push({
                    event: $register.dataset.tagging + CONSTANTS.TAGGING_PAGE_LOAD
                });
            }
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    RegisterValidation.prototype.isLoaded = function () {
        return isLoaded;
    };

    return RegisterValidation;
})();
