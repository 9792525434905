// ==================== CARE OPTIONS COMPONENT =========================
/* global _ */
/* global $ */
/* global gsap */

var query = query || {},
    coned = coned || {};
coned.components = coned.components || {};

/**
 * @return the init function to start the module.
 */
coned.components.CareOptions = (function () {
    /**
     * Constants used in the module.
     * @type {Object}
     */
    var CONSTANTS = {
        CHECK_OPTION: 'js-checkbox-selector',
        CARE_LIST: 'js-care-list',
        OPTIONS_MASK: 'js-care-mask',
        CARE_INPUT: 'js-care-input',
        INPUT_ITEM: 'js-item-validate',
        FORM_ITEM_IGNORE: 'js-validate-ignore',
        INPUT_ERROR_CLASS: 'coned-input-message--error',
        FORM: '.transactional__form'
    };

    var isLoaded = false;

    /**
     * Constructor
     * @param  {[type]}  Element
     * @return {}        Encapsulated modules with its function.
     */
    var CareOptions = function ($careOptionsModule) {
        /**
         * PRIVATE METHODS
         */
        var $checkOption, $inputOptions, $careMask;

        var changeHandler = function () {
            var validator = $(CONSTANTS.FORM).validate();

            if ($checkOption.checked) {
                _.each($inputOptions, function ($inputOption) {
                    $inputOption.classList.add(CONSTANTS.INPUT_ITEM);
                    $inputOption.classList.remove(CONSTANTS.FORM_ITEM_IGNORE);
                });
                // animate the form fields
                gsap.to($careMask, {
                    duration: 0.4,
                    display: 'block'
                });
                gsap.to($careMask, {
                    duration: 0.4,
                    height: 'auto'
                });

                validator.resetForm();
            } else {
                _.each($inputOptions, function ($inputOption) {
                    $inputOption.classList.add(CONSTANTS.FORM_ITEM_IGNORE);
                    $inputOption.classList.remove(CONSTANTS.INPUT_ITEM);
                });
                // animate the form fields
                gsap.to($careMask, {
                    duration: 0.4,
                    height: 0
                });
                gsap.to($careMask, {
                    duration: 0.4,
                    display: 'none'
                });
                validator.resetForm();
            }
        };

        var initializeData = function () {
            $checkOption = $careOptionsModule.getElementsByClassName(CONSTANTS.CHECK_OPTION)[0];
            $careMask = $careOptionsModule.getElementsByClassName(CONSTANTS.OPTIONS_MASK)[0];
            $inputOptions = $careOptionsModule.getElementsByClassName(CONSTANTS.CARE_INPUT);
        };

        var initializeEvents = function () {
            $checkOption.addEventListener('change', changeHandler);
            changeHandler();
        };

        /**
         * Inits functionality in the module.
         */
        var init = function () {
            initializeData();
            initializeEvents();
            isLoaded = true;
        };

        init();
    };

    /**
     *  PUBLIC METHODS
     */

    /**
     * Returns true if the Module is loaded
     * @param {Element}
     * @param {Function}
     */
    CareOptions.prototype.isLoaded = function () {
        return isLoaded;
    };

    return CareOptions;
})();
